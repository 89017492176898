//Import react dependencies
import React from "react";
import { Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { getMlrStatusIcon, isModularContent } from "../../../Uitls/Util";

import TextContent from "./TextContent";

const Optouts = (props) => {
  const { optoutsData, assetDataType } = props;
  return (
    <div className="channel-attr-container customScrollBar">
      {optoutsData.map((optout, index) => {
        return (
          <div className="channelTabTextboxBlock" key={index}>
            {!isModularContent(assetDataType) && (
              <div className=" mb-4 justify-content-end veevaIdMlrSatusRow">
                {optout.documentNumber && (
                  <div className=" mlrVeevaId" style={{ float: "left" }}>
                    <span>Veeva ID : </span> {optout.documentNumber}
                  </div>
                )}
                <div style={{ float: "right" }}>
                  {optout.mlrStatus && (
                    <img
                      src={getMlrStatusIcon(optout.mlrStatus)}
                      alt="MLR Status"
                    />
                  )}{" "}
                  {optout.mlrStatus || "Not Submitted"}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4 mt2 mb-2">
                {optout.visual && (
                  <img src={optout.visual} alt="Visual" className="img-fluid" />
                )}
              </div>
              <div
                className={`${
                  optout.visual ? "col-md-8" : "col-md-12"
                } mt2 mb-2`}
              >
                <TextContent
                  //heading={`optOuts ${index + 1}`}
                  content={parse(optout.text || "")}
                />
              </div>
            </div>

            <div className="d-flex mb-2 justify-content-between veevaIdMlrSatusRow">
              <div className="mlrVeevaId">
                <span>ID :</span> {optout.id}
              </div>
              {!isModularContent(assetDataType) && (
                <div className="mlrVeevaId">
                  <span>Source :</span> {optout.source}{" "}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Optouts;
