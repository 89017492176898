import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

//Import custom components
import ErrComponentToRender from "../../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";

import backButton from "../../../images/chevron-left-matrix.svg";
import infoIcon from "../../../images/infoIcon.svg";
import ChannelTemplateCard from "./ChannelTemplateCard";
import { showError, templateTypes } from "../../../Uitls/Util";
import {
  getSamplesUsingTemplateId,
  getTemplatesFromCsEmail,
} from "../../../services/apis";
import CustomModal from "../../customModal";
import TemplateDetail from "./TemplateDetail";

export const ChannelTemplates = ({
  templateType,
  brand,
  campaignName,
  channelType,
  sourceType,
  backToPrviousScreen,
  handleProceed,
  cancelCreatingGrid,
  channelGridStepCount,
  stepNumber,
  selectedTemplate,
  setSelectedTemplate,
  selectedSample,
  setSelectedSample,
}) => {
  const [templates, setTemplates] = useState(undefined);
  const [filteredTemplates, setFilteredTemplates] = useState(undefined);
  const [templateToBeViewed, setTemplateToBeViewed] = useState(undefined);
  const [searchedResult, setSearchedResult] = useState(undefined);
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    if (
      templateType.type.toLowerCase() ===
      templateTypes.emailTemplate.type.toLowerCase()
    ) {
      retrieveTemplates();
    } else {
      retrieveSamples();
    }
  }, []);
  const retrieveSamples = () => {
    setIsloading(true);
    getSamplesUsingTemplateId(
      selectedTemplate.id,
      selectedTemplate.campaign,
      (res, err) => {
        setIsloading(false);
        if (res) {
          setTemplates(res.data);
          setFilteredTemplates(res.data);

          return;
        }
        showError(err, "topright");
      }
    );
  };

  const retrieveTemplates = () => {
    setIsloading(true);
    getTemplatesFromCsEmail(
      { brand: brand, campaign: campaignName },
      (res, err) => {
        setIsloading(false);
        if (res) {
          setTemplates(res.data);
          setFilteredTemplates(res.data);

          return;
        }
        showError(err, "topright");
      }
    );
  };

  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onSelectTemplate = (template) => {
    if (
      setSelectedSample &&
      templateType.type.toLowerCase() !==
        templateTypes.emailTemplate.getType().toLowerCase()
    ) {
      setSelectedSample(template);
      return;
    }
    setSelectedTemplate(template);
  };

  const showTemplateDetailsModal = (templateDetails) => {
    setTemplateToBeViewed(templateDetails);
  };

  const filterTemplates = (text = searchText) => {
    const data = JSON.parse(JSON.stringify(templates));
    text = text.toLowerCase();
    const filtered = data.filter((el) => {
      return (
        el.name?.toLowerCase().includes(text) ||
        el.serial_no?.toLowerCase().includes(text) ||
        el.dams?.pdf_no?.toLowerCase().includes(text)
      );
    });
    setFilteredTemplates(filtered);
    setSearchedResult("searched");
    setSearchTriggered(true);
  };
  const resetSearch = () => {
    setSearchText("");
    setFilteredTemplates(templates);
    setSearchedResult(undefined);
    setSearchTriggered(false);
  };

  const getSelectedId = () => {
    if (
      setSelectedSample &&
      templateType.type.toLowerCase() !==
        templateTypes.emailTemplate.getType().toLowerCase()
    ) {
      return selectedSample?.id;
    }
    return selectedTemplate?.id;
  };

  const shouldProceedButtonDisabled = () => {
    if (
      setSelectedSample &&
      templateType.type.toLowerCase() !==
        templateTypes.emailTemplate.getType().toLowerCase()
    ) {
      return !selectedSample?.id;
    }
    return !selectedTemplate?.id;
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <>
        <div className="row mt-3">
          <div className="col-md-5 matrixSearchRowTitle">
            <div className="d-flex pl-2">
              <div>
                <img
                  className="grid-header-back-button mt-0"
                  src={backButton}
                  alt=""
                  width="10"
                  onClick={() => backToPrviousScreen()}
                />
              </div>
              <div className="pl-2">
                <strong>Create</strong> Submission Document ({stepNumber}/
                {channelGridStepCount}){" "}
                <div className="subtitle-text">
                  <img
                    className="grid-header-subtitle-icon"
                    src={infoIcon}
                    alt=""
                  />
                  {templateType.getMessage()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 matrixSearchRowBrand">
            <div className="d-flex justify-content-end pr-2">
              <div className="mr-5">
                <span>Product/Brand</span>
                {brand}
              </div>
              <div className="mr-5">
                <span>Campaign</span>
                {campaignName}
              </div>
              <div>
                <span>Channel</span>
                {channelType}
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mt-4 align-items-center"
          style={{
            //borderBottom: "1px solid #e1e1e1",
            paddingBottom: "15px",
          }}
        >
          <div className="col-md-12">
            <div className="d-flex">
              <div className="channelGridMsgSearch pl-2 pr-4">
                <TextField
                  label={templateType.getSearchHint()}
                  variant="outlined"
                  fullWidth={true}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    //  filterByText(event.target.value);
                  }}
                  autocomplete="new-password"
                  autoComplete="off"
                  classes="p-0"
                  className="searchIconInput"
                />
              </div>
              <div>
                <div className="d-flex">
                  <button
                    className="btn btn-primary btn-search-template search-marketing-msg-in-modal"
                    onClick={(event) => {
                      filterTemplates(searchText);
                    }}
                    disabled={!searchText?.trim().length > 0}
                  >
                    search
                  </button>
                  <button
                    className="btn btn-link p-0 clear-search template-search-btn-reset"
                    onClick={(event) => {
                      resetSearch();
                    }}
                    disabled={
                      searchedResult !== true &&
                      !searchTriggered &&
                      !searchText?.trim()
                    }
                  >
                    {t("coreClaim.reset").toUpperCase()}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div
            style={{
              marginLeft: "5px",
              marginTop: "-8px",
              fontSize: "10px",
              color: "#333",
            }}
          >
            {!searchedResult ? (
              <>
                <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  All{" "}
                  <span style={{ fontWeight: "normal" }}>
                    ({filteredTemplates?.length})
                  </span>
                </p>
              </>
            ) : (
              <>
                <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                  Search Results{" "}
                  <span style={{ fontWeight: "normal" }}>
                    ({filteredTemplates?.length})
                  </span>
                </p>
              </>
            )}
          </div>
          <div
            style={{
              borderBottom: "1px solid #e1e1e1",
              height: "1px",
              width: "100%",
              marginLeft: "5px",
            }}
          ></div>
        </div>
        <div className="row channel-templates customScrollBar">
          {filteredTemplates?.map((el) => {
            return (
              <ChannelTemplateCard
                onSelectTemplate={onSelectTemplate}
                channelTemplate={el}
                selectedTemplateId={getSelectedId()}
                templateType={templateType}
                viewTemplate={showTemplateDetailsModal}
              />
            );
          })}
        </div>
        <div className="row pt-3 createGroupResultBtnRow display-block">
          <div className="text-right pr-4">
            <button
              className="btn btn-link text-uppercase"
              onClick={() => {
                cancelCreatingGrid();
              }}
              style={{ marginRight: "64px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary text-uppercase"
              disabled={shouldProceedButtonDisabled()}
              // onClick={() => {
              //   handleContentMatrixModal();
              // }}
              onClick={() => {
                handleProceed();
              }}
              style={{ width: "136px" }}
            >
              {templateType.type === templateTypes.emailTemplate.type
                ? t("generalText.proceed")
                : t("generalText.publish")}
            </button>
          </div>
        </div>
      </>
      <CustomModal
        displayModal={templateToBeViewed}
        hideModal={showTemplateDetailsModal}
        title={""}
        size="lg"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <TemplateDetail
          templateType={templateType}
          template={templateToBeViewed}
          closeTemplateDetail={showTemplateDetailsModal}
        />
      </CustomModal>
    </div>
  );
};

export default ChannelTemplates;
