import grapesjs from "grapesjs";
//import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesJsexportPlugin from "grapesjs-plugin-export";
import grapejsPresetNewsletterPlugin from "grapesjs-preset-newsletter";
import "../../styles/components/editor.scss";

import { defaultBlocks, getBlock, styleManager } from "./editorUtils";
import instance from "../../services/axiosConfig";
import { saveStylizedModule } from "../../services/apis";
import {
  capitalizeString,
  showError,
  getCurrentUser,
  moduleTypes,
} from "../../Uitls/Util";

function getGrapeJsEditor(
  module,
  moduleId,
  campaign,
  brand,
  channelType,
  sourceType,
  assetDataType,
  setStylizedModuleInfo,
  stylizedModuleInfo,
  setSavingData,
  setBrandStyleHGuideline,
  setAlreadySelectedTextElements,
  moduleDataRef
) {
  let stylizedModule = undefined;

  const editor = grapesjs.init({
    container: "#gjsEditor",
    //dragMode: "absolute",
    blockManager: defaultBlocks,
    styleManager: styleManager,
    storageManager: {
      type: "remote",
      stepsBeforeSave: 1,
      contentTypeJson: true,
      storeComponents: true,
      storeStyles: true,
      storeHtml: true,
      storeCss: true,
    },
    traitManager: {
      appendTo: "#traitBlock",
    },

    //This is for selected selector info display
    // selectorManager: {
    //   appendTo: "#selectors-container",
    // },

    plugins: [
      // "gjs-blocks-basic",
      (editor) =>
        grapejsPresetNewsletterPlugin(editor, {
          blocks: [
            "sect100",
            "sect50",
            "sect30",
            "sect37",
            "grid-items",
            "list-items",
          ],
          block: (id) => {
            return getBlock(id);
          },

          // blocks: blocks,
          updateStyleManager: true,
        }),
      grapesJsexportPlugin,
    ],
    pluginsOpts: {
      grapesJsexportPlugin: {},
    },

    showDevices: false,
    showPreview: false,
    //showToolbar: false,
    devicePreviewMode: false,
    assetManager: {
      // ...
      custom: {
        open(props) {
          //setState(true);
        },
        close(props) {},
      },
    },
  });

  let updateText = document.getElementsByClassName("gjs-toolbar");
  let changeText = document.getElementsByClassName(
    "gjs-rte-toolbar gjs-one-bg"
  );

  editor.on("rte:enable", () => {
    if (changeText && changeText[0]) {
      updateText[0].style.display = "none";
    }
  });

  editor.on("rte:disable", () => {
    if (updateText && updateText[0]) {
      updateText[0].style.display = "block";
    }
  });

  editor.RichTextEditor.add("orderedList", {
    icon: "",
    attributes: { title: "Ordered List", class: "gjs-rte-action ordered-list" },
    result: (rte) => {
      const rteEl = editor.RichTextEditor.getToolbarEl();
      rteEl.firstChild.childNodes.forEach((child, idx) => {});
      rte.exec("insertOrderedList");
    },
  });

  editor.RichTextEditor.add("unorderedList", {
    icon: "",
    attributes: {
      title: "Unordered List",
      class: "gjs-rte-action unordered-list",
    },
    result: (rte) => rte.exec("insertUnorderedList"),
  });

  editor.RichTextEditor.add("superscript", {
    icon: "<b>S<sup>s</sup></b>",
    attributes: { title: "Superscript" },
    result: (rte) => rte.exec("superscript"),
  });

  editor.RichTextEditor.add("subscript", {
    icon: "<b>S<sub>s</sub></b>",
    attributes: { title: "Subscript" },
    result: (rte) => rte.exec("subscript"),
  });

  editor.Commands.add("set-device-desktop", {
    run: (editor) => editor.setDevice("Desktop"),
  });
  editor.Commands.add("set-device-mobile", {
    run: (editor) => editor.setDevice("Mobile"),
  });
  // Save Button
  editor.Commands.add("saveDb", {
    run: (editor, sender) => {
      //sender && sender.set("active");
      // console.log("HTML with Inline css =========", withInlineCss);
      editor.store();
    },
  });

  //Clear Button
  editor.Commands.add("cmd-clear", {
    run: (editor) => {
      editor.DomComponents.clear();
      editor.CssComposer.clear();
    },
  });
  //Undo
  editor.UndoManager.getInstance().set("maximumStackLength", 10);
  editor.Commands.add("undo", {
    run: (editor) => editor.UndoManager.undo(),
  });

  // Redo
  editor.Commands.add("redo", {
    run: (editor) => editor.UndoManager.redo(),
  });
  // editor.Commands.add("export", {
  //   run: (editor, type) => editor.runCommand("gjs-export-zip"),
  // });

  //Info

  // Show guides
  editor.Commands.add("visibility", {
    run: (editor) => editor.runCommand("sw-visibility"),
  });

  editor.Storage.add("remote", {
    async load() {
      try {
        let res = await instance.get(
          `/api/modulems/${sourceType}/modules/${moduleId}/stylized?channelType=${channelType}`
        );
        if (res && res.data && res.data.length > 0) {
          setBrandStyleHGuideline(res.data[0].styleGuideline);
          if (res.data[0].stylisedVersion) {
            setStylizedModuleInfo(res.data[0]);
            let textElements = res.data[0].stylisedVersion?.textElements;
            setAlreadySelectedTextElements(textElements);
            const content = res.data[0].stylisedVersion.editorData;
            return content;
          }
        }
        return;
      } catch (err) {
        showError(err);
      }
    },

    store(data) {
      const html = editor.runCommand("gjs-get-inlined-html");
      const payLoad = {
        channelType: capitalizeString(channelType),
        brand: brand,
        campaigm: campaign,
        cretedByname: stylizedModule?.cretedByname || getCurrentUser(),
        updatedByname: getCurrentUser(),
        stylisedVersion: {
          editorData: data,
          htmlData: html,
          textElements: moduleDataRef?.current?.module?.textElements,
        },
      };
      setSavingData(true);
      saveStylizedModule(
        moduleId,
        sourceType,
        assetDataType === moduleTypes.denovo ? "De-nuovo" : assetDataType,
        payLoad,
        (res, err) => {
          if (err) {
            showError(err);
          } else {
            setTimeout(() => {
              setSavingData(false);
            }, 700);

            setStylizedModuleInfo((prevState) => ({
              ...prevState,
              updatedByname: getCurrentUser(),
              upatedDateTime: new Date(),
            }));
          }
        }
      );
    },
  });
  editor.on("load", function () {
    editor.runCommand("sw-visibility");
  });
  editor.on("component:selected", function (e) {
    console.log(e);
  });
  return editor;
}

export default getGrapeJsEditor;
