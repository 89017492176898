/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import "../styles/components/DropDownWithSearch.scss";

const DropDownWithSearch = (props) => {
  setTimeout(async () => {
    const close = await document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (close) {
      close.addEventListener("click", () => {
        const handleChnage = props?.options?.handleChange;
        if (handleChnage) {
          handleChnage(undefined);
        }
      });
    }
  }, 0);

  const getValue = () => {
    const options = props.options;
    if (
      options &&
      options.items &&
      options.items.length > 0 &&
      options.value &&
      options.items.indexOf(options.value) !== -1
    ) {
      return options.value;
    }
    return "";
  };

  const useStyles = makeStyles((theme) => ({
    popupIndicator: {
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
          },
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <Autocomplete
      disabled={props.options.disabled}
      id="search-and-select"
      style={{ width: "100%" }}
      options={props.options.items}
      value={getValue()}
      onChange={(event, value) => {
        props.options.handleChange(value);
      }}
      closeIcon={""}
      className={`drop-down-with-search ${props.class}`}
      autoHighlight
      getOptionLabel={(option) =>
        props.options.placeHolder
          ? props.options.placeHolder
          : option.displayName
      }
      classes={{
        clearIndicatorDirty: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      getOptionDisabled={(option) => option.isNotSelectable}
      renderOption={(option) => (
        <React.Fragment>
          <span className="display-name">{option.displayName}</span>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.options.label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // "new-password" disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default DropDownWithSearch;
