import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../../styles/pages/MLRSubmissionPackage.scss";
import {
  htmlToString,
  moduleTypes,
  getModuleInfoFromLocalStorage,
} from "../../Uitls/Util";
import parse from "html-react-parser";

const ClaimAndVisualsSpecificInfo = (props) => {
  const { t } = useTranslation();
  const [module, setModule] = useState(undefined);
  const [pageJsx, setPageJsx] = useState(undefined);

  useEffect(() => {
    if (props.module) {
      if (module && module.id && module.id !== props.module.id) {
        setPageJsx(undefined);
      }
      setModule(props.module);
    }
  }, [props]);

  useEffect(() => {
    if (props.setJsx) {
      props.setJsx(pageJsx);
    }
  }, [pageJsx]);

  const getClaims = (type) => {
    let claim = undefined;
    if (module) {
      const moduleClaims = module.moduleClaims;
      if (moduleClaims && moduleClaims.length > 0) {
        claim = moduleClaims.filter((claim) => {
          return claim.type === type;
        });
      }
    }

    return claim;
  };

  const [moduleType, setModuleType] = useState(undefined);
  useEffect(() => {
    const moduleInfo = props.getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      //console.log(moduleInfo.moduleId);

      setModuleType(moduleInfo.creationMethodType);
    } else {
      setModuleType(props?.module?.creationMethodType?.toLowerCase());
    }
  }, []);
  const renderCitatedHtml = (html) => {
    if (!html) {
      return undefined;
    }

    let str = parse(html);
    return str;
  };
  const renderPersonaAndTone = (claim) => {
    return (
      <div className="persona-and-tone" style={{ opacity: "0.5" }}>
        {claim?.persona && <div>Persona : {parse(claim?.persona || "")}</div>}
        {claim?.tone && <div>Tone : {parse(claim?.tone || "")}</div>}
      </div>
    );
  };
  const renderClaimVarients = (claim) => {
    if (!claim) {
      return;
    }

    let varients = [
      { claim: claim.claim, persona: claim.persona, tone: claim.tone },
    ];
    if (varients && varients.length === 0) {
      return <div></div>;
    }

    const claimVarients = claim.claimVariants.filter((variant, index) => {
      return !(
        index === 0 &&
        claim.type === "Supporting" &&
        moduleType === moduleTypes?.denovo
      );
    });

    //const claimVarients = claim.claimVariants;
    if (claimVarients && claimVarients.length > 0) {
      for (const varient of claimVarients) {
        varients = varients.concat({
          claim: varient.moduleClaim,
          persona: varient.persona,
          tone: varient.tone,
        });
      }
    }
    if (varients && varients.length > 1) {
      return varients.map((varient, index) => {
        return (
          <div className="varient" style={{ overflow: "hidden" }} key={varient}>
            {/* <Row> */}
            <span className="varient-title">Variant {index + 1}: </span>
            <span className="text-blue">
              {/* {htmlToString(varient)} */}
              {renderCitatedHtml(varient.claim)}
              {renderPersonaAndTone(varient)}
            </span>
            {/* </Row> */}
          </div>
        );
      });
    } else {
      return (
        varients &&
        varients.length === 1 && (
          <div className="text-blue">
            {/* {htmlToString(varients[0])} */}
            {renderCitatedHtml(varients[0].claim)}
            {renderPersonaAndTone(varients[0])}
          </div>
        )
      );
    }
    //}
    // }
  };

  const getSupportingComponentToDisplay = (comp, className) => {
    let string = comp.text || "";
    switch (comp.componentType) {
      case "Abbreviation":
        return (
          <div>
            <div
              style={{ color: "#666666", marginBottom: "-10px" }}
              className={`supportingComponent`}
            >
              {comp.prefix || ""}
            </div>
            <div className={`supportingComponent ${className}`}>
              {htmlToString(string)}
            </div>
          </div>
        );
      case "Footnote":
        //string = renderCitatedHtml((comp.prefix + "  ").concat(string))

        string = renderCitatedHtml(string);
        return (
          <div className={`supportingComponent ${className} d-flex`}>
            {/* <p style = {{marginRight : '0ps 5px 0px 0px', display : 'inline-block'}}>{comp.prefix || ''}</p> */}
            <span
              className="d-inline-block text-blue"
              style={{ marginRight: "5px" }}
            >
              {comp.prefix || ""}{" "}
            </span>
            <div
              style={{
                height: "100%",
                width: "100%",
                margin: "0px",
              }}
            >
              {string}
            </div>
          </div>
        );
        break;
      case "Reference":
        return (
          <div id={comp.id}>
            <div
              className={`supportingComponent ${className}`}
              id={comp.id}
              style={{ wordBreak: "break-all" }}
            >
              {`${comp.refId + ". " || ""}${htmlToString(comp.reference)}`}
            </div>
            {comp.annotationList &&
              comp.annotationList.map((annonation) => {
                return (
                  <div
                    id={annonation.id}
                    style={{ color: "#666666" }}
                    className={`supportingComponent`}
                  >
                    {/* {htmlToString(annonation.annotText) || ""} */}
                    Page no {annonation.pageNumber}/ Column {annonation.column}/
                    Para {annonation.para}/ Line {annonation.line}
                  </div>
                );
              })}
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <div className={`supportingComponent ${className}`}>
        {htmlToString(string)}
      </div>
    );
  };

  const getReferences = (claim, annonations) => {
    const componentLinks = module.componentLinks;
    let references =
      claim &&
      claim.supportingTextComponents
        .filter((item) => {
          if (item.componentType.toLowerCase() === "reference") {
            return true;
          }
          return false;
        })
        .map((item) => {
          const annotationList = [];
          const anchors = item.anchors || [];
          anchors.forEach((link) => {
            annotationList.push({
              //annotText: link.anchorText,
              pageNumber: link.page,
              column: link.column,
              para: link.paragraph,
              line: link.line,
              id: link.id,
              //record_index: "",
            });
          });

          return {
            annotationList: annotationList,
            id: item.id,
            refId: item.prefix,
            reference: item.text,
            componentType: "Reference",
          };
        });

    references = [...references].sort(function (a, b) {
      return a.refId - b.refId;
    });
    return references;
  };
  const renderClaimSupportingText = (claim, supTextType, className) => {
    const types = supTextType.split("/");

    let supTextComps = claim.supportingTextComponents;
    if (supTextComps && supTextComps.length > 0) {
      let compTypes = [];
      if (supTextType.toLowerCase() !== "reference") {
        compTypes = compTypes.concat(
          [...supTextComps].filter((supTextCompType) => {
            if (types.length > 1) {
              return (
                supTextCompType.componentType === types[0] ||
                supTextCompType.componentType === types[1]
              );
            } else {
              return supTextCompType.componentType === types[0];
            }
          })
        );
      } else {
        const annotations = supTextComps.filter((comp) => {
          return comp.componentType === "Annotation";
        });
        compTypes = getReferences(claim, annotations);
      }

      if (compTypes && compTypes.length > 0) {
        // compTypes = [...compTypes].map((comp) => {
        //     return comp.text
        // })
        if (compTypes && compTypes.length > 0) {
          return compTypes.map((comp) => {
            return (
              <div key={comp.id || ""}>
                {getSupportingComponentToDisplay(comp, className)}
              </div>
            );
          });
        }
      }
    }
    //}
    //}
  };

  const renderPrimaryClaims = () => {
    const claims = getClaims("Primary");
    let removeSpan = /<\/?span[^>]*>/g;
    claims[0].claim = claims[0].claim.replace(removeSpan, "");
    //let pattern = /\[(.*?)\]/g;
    let pattern = /(\[(?:\[[^\]]*\]|[^\[\]])*\])/g;
    let replaceElement = '<span style="color:hotpink">$1</span>';
    claims[0].claim = claims[0].claim.replace(pattern, replaceElement);
    if (claims[0].claimVariants.length > 0) {
      claims[0].claimVariants.forEach((item, index) => {
        item.moduleClaim = item.moduleClaim.replace(removeSpan, "");
        item.moduleClaim = item.moduleClaim.replace(pattern, replaceElement);
      });
    }

    return (
      claims &&
      claims.map((claim) => {
        return (
          claim && (
            <tr key={claim.id}>
              <td className="text-black">
                <p style={{ marginBottom: "0px", wordBreak: "break-all" }}>
                  {claim.componentId || ""}
                </p>
                {i18next.t("primaryClaim.name")} (Content Module Section_00)
                <div
                  style={{
                    marginTop: "20px",
                    display: "none",
                  }}
                >
                  {claim?.isMustUse === false || claim?.isMustUse === null
                    ? "Optional"
                    : "Must Use"}
                </div>
              </td>
              <td>{renderClaimVarients(claim)} </td>
              <td>
                {renderClaimSupportingText(
                  claim,
                  "Abbreviation/Footnote",
                  "text-blue"
                )}
              </td>
              <td>
                {renderClaimSupportingText(claim, "Reference", "text-blue")}
              </td>
              <td>{claim?.usageGuidelines}</td>
            </tr>
          )
        );
      })
    );
  };
  const getSupportingClaimLink = (supClaim, needBoolean = false) => {
    let title = "";
    const moduleGroups = module.moduleGroups;
    if (moduleGroups && moduleGroups.length > 0) {
      let claimGroups = [];
      for (const moduleGroup of moduleGroups) {
        const groupClaims = moduleGroup.groupClaims;
        if (groupClaims && groupClaims.length > 0) {
          for (const groupClaim of groupClaims) {
            const obj = {
              moduleClaimId: groupClaim.moduleClaimId,
              requirementType: moduleGroup.requirementType,
            };
            claimGroups = claimGroups.concat(obj);
          }
        }
      }
      if (claimGroups && claimGroups.length > 0) {
        const foundGroupClaims = claimGroups.filter((claim) => {
          return claim.moduleClaimId === supClaim.id;
        });

        if (foundGroupClaims && foundGroupClaims.length > 0) {
          let isMendatory =
            foundGroupClaims.filter((groupClaim) => {
              return groupClaim.requirementType === "Mandatory";
            }).length > 0;
          if (needBoolean) {
            return isMendatory;
          }
          title = `${supClaim.componentId} can be used ${
            isMendatory ? "Mandatorily" : "Optionally"
          } <span style="word-break: break-word">with</span> ${
            getClaims("Primary")[0].componentId
          }`;
          if (module && module.groupToggle) {
            title = `${supClaim.componentId} and ${
              getClaims("Primary")[0].componentId
            } can be used 
            separately or together`;
          }
        }
      }
    }
    if (needBoolean) {
      return undefined;
    }
    return title;
  };

  const renderSupportingClaim = (claim, index) => {
    return (
      <tr key={claim.id}>
        <td
          className={getMandatoryClasses(
            getSupportingClaimLink(claim, true),
            true
          )}
        >
          <div>
            <p style={{ marginBottom: "0px", wordBreak: "break-all" }}>
              {claim.componentId}
            </p>
            {i18next.t("supportingClaim.name")} (Content Module Section_
            {String(index + 1).padStart(2, "0")}).
          </div>
          <p style={{ marginTop: "20px" }}>
            {" "}
            {claim?.isMustUse === false || claim?.isMustUse === null
              ? "Optional"
              : "Must Use"}
          </p>
          <div style={{ marginTop: "5px", wordBreak: "break-all" }}>
            {parse(getSupportingClaimLink(claim) || "")}
          </div>
        </td>
        <td>{renderClaimVarients(claim)}</td>
        <td>
          {renderClaimSupportingText(
            claim,
            "Abbreviation/Footnote",
            "text-blue"
          )}
        </td>
        <td>{renderClaimSupportingText(claim, "Reference", "text-blue")}</td>
        <td>{claim?.usageGuidelines}</td>
      </tr>
    );
  };

  const renderReusableTexts = (item, index) => {
    // const ctaCheck =
    //   item.reusableTextType === "CTA"
    //     ? item?.labels?.join(",")
    //     : item.description;
    const ctaItems = module?.moduleReusableTexts.filter(
      (item) => item?.reusableTextType === "CTA"
    );
    return (
      <>
        <tr key={index}>
          <td>
            <div className=" text-black">
              <p style={{ marginBottom: "0px", wordBreak: "break-all" }}>
                {/* {claim.componentId} */}
              </p>

              {/* {String(index + 1).padStart(2, "0")}. */}
              <p>
                {/* {item?.reusableTextType === "CTA"
                  ? `${item?.reusableTextType}  ${index + 1} `
                  : */}
                {item?.reusableTextType}
                {/* {item && (
                  <>
                    {item?.reusableTextType === "CTA" &&
                      ctaItems.length > 0 && (
                        <div>{`CTA ${ctaItems.indexOf(item) + 1}`}</div>
                      )}
                    {item?.reusableTextType !== "CTA" && item?.reusableTextType}
                  </>
                )} */}
              </p>
              <p style={{ marginTop: "20px" }}>
                {item?.isMustUse === false || item?.isMustUse === null
                  ? "Optional"
                  : "Must Use"}
              </p>
            </div>
          </td>
          <td>
            <div className="text-black ctacheck">
              {item.reusableTextType !== "CTA" ? (
                <>
                  <span>Description:</span>
                  <br />
                </>
              ) : (
                " "
              )}
              {/* <p className="ctacheck">{ctaCheck ? ctaCheck : "  "}</p> */}
              <div className="ctacheck">
                {item.reusableTextType === "CTA" ? (
                  <>
                    {item.reusableTextLabels &&
                    item.reusableTextLabels.length > 0 ? (
                      item.reusableTextLabels.map((label, index) => (
                        <p key={index}>
                          Label {index + 1} :<div>{label.ctaLabel}</div>
                        </p>
                      ))
                    ) : (
                      <p>-</p>
                    )}
                  </>
                ) : (
                  <>
                    {item.labels && item.labels.length > 0 ? (
                      item.labels.map((label, index) => (
                        <p key={index}>
                          Label {index + 1} : <div>{label}</div>
                        </p>
                      ))
                    ) : (
                      <p>
                        {""}
                        {item.description ? item.description : ""}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className=" text-black  ctacheck">
              {" "}
              URL:{" "}
              <div className="ctacheck ">{item.link ? item.link : "-"}</div>
            </div>
          </td>
          <td>
            <p>{item.usageGuidelines ? item.usageGuidelines : ""}</p>
          </td>
        </tr>
      </>
    );
  };
  const renderSupportingVisual = (visual, index) => {
    return (
      <tr key={visual.id}>
        <td className={getMandatoryClasses(getVisualLink(visual, true))}>
          <div>
            <p style={{ marginBottom: "0px", wordBreak: "break-all" }}>
              {visual.componentId}{" "}
            </p>
            {i18next.t("supportinVisuals.supportTitle")}.
          </div>
          <div style={{ marginTop: "10px", wordBreak: "break-all" }}>
            {parse(getVisualLink(visual) || "")}
          </div>
          <p style={{ marginTop: "20px" }}>
            {" "}
            {visual?.isMustUse === false || visual?.isMustUse === null
              ? "Optional"
              : "Must Use"}
          </p>
        </td>
        <td style={{ overflow: "hidden" }}>{renderVisualVarients(visual)}</td>
        <td style={{ overflow: "hidden" }}>
          {renderClaimSupportingText(
            visual,
            "Abbreviation/Footnote",
            "text-blue"
          )}
        </td>
        <td>{renderClaimSupportingText(visual, "Reference", "text-blue")}</td>
        <td>{visual?.usageGuidelines}</td>
      </tr>
    );
  };
  const renderSupportingClaimsAndVisuals = () => {
    if (!module) {
      return;
    }
    let claims = getClaims("Supporting");
    claims = claims.map((claim) => {
      return { ...claim, componentType: "supportingClaim" };
    });
    let visuals = module.moduleVisuals;
    visuals = visuals.map((visual) => {
      return { ...visual, componentType: "supportingVisual" };
    });
    let claimsAndVisuals = claims.concat(visuals);

    claimsAndVisuals =
      props.sortClaimsAndVisualsByRecordIndex(claimsAndVisuals);

    return (
      claimsAndVisuals &&
      claimsAndVisuals.map((el, index) => {
        if (el.componentType === "supportingClaim") {
          return renderSupportingClaim(el, index);
        } else if (el.componentType === "supportingVisual") {
          return renderSupportingVisual(el, index);
        }
        return <></>;
      })
    );
  };
  const renderReusablearray = () => {
    return (
      module?.moduleReusableTexts &&
      module?.moduleReusableTexts
        ?.slice()
        .sort((a, b) => a.record_index - b.record_index)
        .map((el, index) => {
          return renderReusableTexts(el, index);
        })
    );
  };
  const renderVisualCaptions = (visual) => {
    const supportingTexts = visual.supportingTextComponents;
    if (supportingTexts && supportingTexts.length > 0) {
      const captions = supportingTexts.filter((supportingText) => {
        return supportingText.componentType === "Caption";
      });
      if (captions && captions.length > 0) {
        if (captions.length === 1) {
          return (
            <div className="visual-captions">
              <span className="caption-title">Image caption: </span>
              {/* {htmlToString(captions[0].text)} */}
              {renderCitatedHtml(captions[0].text)}
            </div>
          );
        } else {
          return (
            <div className="visual-captions">
              <span className="caption-title">Image Captions: </span>
              {
                <ol type="1">
                  {captions.map((caption) => {
                    return (
                      // <li>{htmlToString(caption.text)}</li>
                      <li>{renderCitatedHtml(caption.text)}</li>
                    );
                  })}
                </ol>
              }
            </div>
          );
        }
      }
    }
  };

  const renderVisualVarientsImages = (visual) => {
    let varients = visual.visualVariants;
    if (varients && varients.length > 1) {
      return (
        <div>
          {varients.map((varient, index) => {
            return (
              <div className="visual-varient" key={varient.id}>
                {/* <Row> */}
                <span className="varient-title">Variant {index + 1}: </span>
                <img
                  //style={{ width: "200px" }}
                  style={{ width: "100%" }}
                  src={varient.visualUrl}
                />
                {/* </Row> */}
              </div>
            );
          })}
          {/* {renderVisualCaptions(visual)} */}
        </div>
      );
    } else {
      return (
        varients &&
        varients.length === 1 && (
          <div>
            <img
              //style={{ width: "200px" }}
              style={{ width: "100%" }}
              src={varients[0].visualUrl}
            />
            {/* {renderVisualCaptions(visual)} */}
          </div>
        )
      );
    }
  };
  const renderVisualVarients = (visual) => {
    return (
      <div>
        {renderVisualVarientsImages(visual)}
        {renderVisualCaptions(visual)}
      </div>
    );
  };
  const getVisualLink = (visual, needBoolean = false) => {
    //debugger
    let linkTitle = "";
    const claimVisuals = visual.claimVisuals;
    if (claimVisuals && claimVisuals.length > 0) {
      let visualLinks = {
        claim: [],
        dependency: claimVisuals[0].dependency,
      };

      visual.claimVisuals.forEach((claimVisual) => {
        visualLinks.dependency = claimVisual.dependency;
        visualLinks.claim.push({
          moduleClaimId: claimVisual.moduleClaimId,
        });
      });
      if (visualLinks.claim.length > 0 && needBoolean) {
        const isMandatory =
          visualLinks.dependency === "Optional" ? false : true;
        return isMandatory;
      }
      let claims = [];
      let linkedClaims = [];
      claims = claims
        .concat(getClaims("Primary"))
        .concat(getClaims("Supporting"));
      visualLinks.claim.forEach((claim) => {
        const moduleClaimId = claim.moduleClaimId;
        claims.forEach((moduleClaim) => {
          if (moduleClaim.id === moduleClaimId) {
            if (moduleClaim.type === "Primary") {
              linkedClaims = linkedClaims.concat(moduleClaim);
            } else if (moduleClaim.type === "Supporting") {
              getClaims("Supporting").forEach((supClaim, index) => {
                if (supClaim.id === moduleClaim.id) {
                  linkedClaims = linkedClaims.concat(supClaim);
                }
              });
            }
          }
        });
      });
      linkedClaims.sort();
      if (linkedClaims.length > 0) {
        linkedClaims = [...linkedClaims].map((claim) => {
          return claim.componentId;
        });
        linkTitle = `${visual.componentId} can be used ${
          visualLinks.dependency === "Optional" ? "Optionally" : "Mandatorily"
        } <span style="word-break: break-word">with</span> ${linkedClaims
          .toString()
          .replace(/,/g, ", \n")}`;
      }
    }
    if (needBoolean) {
      return undefined;
    }
    return linkTitle;
  };
  console.log("moduleTypes?.denovo", moduleType);
  const getMandatoryClasses = (isMandatory, groupToggle = false) => {
    if (isMandatory === undefined) {
      return " text-black";
    }
    if (module && groupToggle && module.groupToggle) {
      return "text-black background-amber";
    }
    if (isMandatory === true) {
      return "text-black background-red";
    } else if (isMandatory === false) {
      return " text-black background-green";
    }
  };

  // const renderSupportingVisuals = () => {
  //   if (!module) {
  //     return;
  //   }
  //   let moduleVisuals = module.moduleVisuals;
  //   if (moduleVisuals && moduleVisuals.length > 0) {
  //     return moduleVisuals.map((visual, index) => {
  //       return (
  //         <tr key={visual.id}>
  //           <td className={getMandatoryClasses(getVisualLink(visual, true))}>
  //             <div>
  //               <p style={{ marginBottom: "0px", wordBreak: "break-all" }}>
  //                 {visual.componentId}{" "}
  //               </p>
  //               {i18next.t("supportinVisuals.supportTitle")}.
  //             </div>
  //             <div style={{ marginTop: "10px", wordBreak: "break-all" }}>
  //               {parse(getVisualLink(visual) || "")}
  //             </div>
  //           </td>
  //           <td>{renderVisualVarients(visual)}</td>
  //           <td>
  //             {renderClaimSupportingText(
  //               visual,
  //               "Abbreviation/Footnote",
  //               "text-blue"
  //             )}
  //           </td>
  //           <td>
  //             {renderClaimSupportingText(visual, "Reference", "text-blue")}
  //           </td>
  //         </tr>
  //       );
  //     });
  //   }
  // };

  const renderData = () => {
    if (!module) {
      return <div></div>;
    }
    const jsx = (
      <div className="allow-border-in-first-row">
        <div
          className="pdfHeader"
          style={{
            marginBottom: "15px",
            fontFamily: " Arial, Helvetica, sans-serif;",
            fontSize: " 16px",
            color: "#707070",
          }}
        >
          {module && module.name}
        </div>
        <div
          style={{
            fontSize: "20px",
            color: "#222222",
            fontWeight: "bold",
            marginBottom: "10px",
            marginTop: "50px",
          }}
          className="mandoryCoponent"
        >
          Module Content : {module.name}
        </div>

        <table
          className="table allow-border-in-first-row claim-specific"
          style={{ tableLayout: "fixed" }}
        >
          <tbody>
            <tr className="header">
              <td style={{ width: "20%" }}>
                {i18next.t("mlrSubmission.componentID")}
              </td>
              <td className="claim-specific">
                {i18next.t("mlrSubmission.coreComponent")}
              </td>
              <td style={{ width: "20%" }}>
                {i18next.t("mlrSubmission.associated")}
              </td>
              <td style={{ width: "20%" }}>
                {i18next.t("mlrSubmission.refsAndAnnot")}
              </td>
              {moduleType === moduleTypes?.denovo && (
                <td style={{ width: "20%" }}>
                  {i18next.t("mlrSubmission.usageInstructions")}
                </td>
              )}
            </tr>
            {renderPrimaryClaims()}
            {renderSupportingClaimsAndVisuals()}
            {/* {renderSupportingVisuals()} */}
            {/* {renderReusablearray()} */}
          </tbody>
        </table>
        {moduleType === moduleTypes?.denovo &&
          module?.moduleReusableTexts?.length > 0 && (
            <table
              className="table allow-border-in-first-row claim-specific reusableTextTable"
              style={{ tableLayout: "fixed" }}
            >
              <tbody>
                <tr className="header">
                  <td style={{ width: "10%" }}></td>
                  <td className="claim-specific" style={{ width: "20%" }}>
                    {i18next.t("reusableText.content")}
                  </td>
                  <td style={{ width: "20%" }}>
                    {i18next.t("reusableText.usageInstructions")}
                  </td>
                </tr>
                {renderReusablearray()}
              </tbody>
            </table>
          )}
        {moduleType !== moduleTypes?.denovo && (
          <table
            ref={(node) => {
              if (node) {
                node.style.setProperty("border", "none", "important");
              }
            }}
            style={{ width: "100%", marginBottom: "19px" }}
            className="mandoryCoponentTbl"
          >
            <tr style={{ border: "none" }}>
              <td style={{ border: "none", fontSize: "15px" }}>
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    backgroundColor: "#ff0000",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#000",
                    float: "left",
                    marginRight: "10px",
                  }}
                ></span>{" "}
                <span
                  style={{
                    display: "inline-block",
                  }}
                >
                  {t("moduleDetails.mandatoryRecommended")}
                </span>
              </td>
              <td style={{ border: "none", fontSize: "15px" }}>
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    backgroundColor: "#23cd32",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#000",
                    float: "left",
                    marginRight: "10px",
                  }}
                ></span>{" "}
                {t("moduleDetails.mandatoryOptional")}
              </td>
              <td style={{ border: "none", fontSize: "15px" }}>
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    backgroundColor: "#fae3d4",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#000",
                    float: "left",
                    marginRight: "10px",
                  }}
                ></span>{" "}
                {t("moduleDetails.mandatoryBoth")}
              </td>
            </tr>
          </table>
        )}
      </div>
    );

    if (!pageJsx) {
      setPageJsx(jsx);
    }
    return jsx;
  };

  return <div>{renderData()}</div>;
};

export default ClaimAndVisualsSpecificInfo;
