import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ImcLogo from "../images/NEXT-Module-logo.svg";
import alertIcon from "../images/alertIcon.svg";
import searchIcon from "../images/search.svg";
import { searchModules } from "../services/apis";
import { Row, Col } from "react-bootstrap";
import "../styles/components/HeaderCreateModule.scss";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { routes } from "../Uitls/Util";

function HeaderCreateModule(props) {
  const [dataSource, setDataSource] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const history = useHistory();

  //  document.getElementById('global-search-module')?.onkeyup = (event) => {
  //   enterClickedInsideInput(event)
  // }
  // document.addEventListener(
  //   "keyup",
  //   (event) => {
  //     if (event.key === "Enter") {
  //       if (
  //         event.target.id === "global-search-module"
  //       ) {
  //         document.getElementById('global-search-module')?.onBlur()
  //       } else {
  //         setDataSource([]);
  //       }
  //     }
  //   },
  //   false
  // );

  const enterClickedInsideInput = (event) => {
    if (event.key === "Enter") {
      if (
        event.target.id === "global-search-module" &&
        event.target.value.trim() !== ""
      ) {
        searchModulesBySearchParam(event.target.value.trim());
      } else {
        setDataSource([]);
      }
    }
  };

  const onSearchIconClicked = () => {
    const searchInputBox = document.getElementById("global-search-module");
    const enteredText = searchInputBox?.value;
    if (enteredText && enteredText.trim() !== "") {
      searchModulesBySearchParam(enteredText);
    }
  };

  const onModuleClick = (moduleId, brandId) => {
    if (props.onModuleSelect) {
      setDataSource([]);
      props.onModuleSelect(moduleId, brandId);
    } else {
      history.push({
        pathname: routes.moduleDetails,
        state: {
          moduleId: moduleId,
          brandId: brandId,
        },
      });
    }
  };

  const searchModulesBySearchParam = (searchParam) => {
    setIsloading(true);
    setDataSource([]);
    searchModules(searchParam, (res, err, errorCode) => {
      if (res && res.data) {
        setDataSource(res.data);
      }
      setIsloading(false);
    });
  };

  const renderModules = () => {
    return (
      <div className="custonScrollBar">
        {dataSource.length > 0 && (
          <div>
            {dataSource.map((module) => {
              return (
                <div
                  key={module.id}
                  className="module-list-item"
                  onClick={() => {
                    onModuleClick(module.id, module.brandId);
                  }}
                >
                  {module.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <header className="navbar flex-column flex-md-row pt-0">
        <div className="logoCol">
          <img src={ImcLogo} alt="IMC - Module Manager" />
        </div>
        {/* <div className="myPreferences">
          <div className="d-flex justify-content-between">
            <div className="mt-2">My Preferences (0)</div>
            <button className="btn btn-link myPreferencesAddBtn">Add</button>
          </div>
        </div> */}

        <div className="alertIconCol d-flex">
          {props.searchBarRequired && (
            <div className="searchCol">
              <div className="d-flex">
                <Col className="col-md-12">
                  <Row>
                    <Col className="col-md-2">
                      <img
                        src={searchIcon}
                        alt=""
                        className="searchIcon"
                        onClick={onSearchIconClicked}
                      />
                    </Col>
                    <Col className="col-md-10">
                      <input
                        id="global-search-module"
                        list="modules"
                        type="text"
                        placeholder="Search by Module"
                        className="globalSearchInputNav"
                        onChange={(event) => {
                          if (event.target.value.trim() === "") {
                            setDataSource([]);
                          }
                        }}
                        onKeyUp={enterClickedInsideInput}
                      />
                    </Col>
                  </Row>
                  <CircularProgressBar isloading={isloading} />
                </Col>
              </div>
            </div>
          )}
          <img src={alertIcon} alt="Alert" className="bellIcon" />
        </div>
        {props.searchBarRequired && (
          <div className="module-list custonScrollBar">{renderModules()}</div>
        )}
      </header>
    </>
  );
}

export default HeaderCreateModule;
