import React, { useEffect, useState } from "react";

//Import styles
import "../styles/components/atoms.scss";
//Import images
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
import { getAssetDetails } from "../services/apis";
import { getModuleInfoFromLocalStorage } from "../Uitls/Util";

const Atoms = ({
  atomType,
  shouldBeDisbaled = false,
  handleAtomDoubleClick,
}) => {
  const [selectedPage, setSelectedPage] = useState(0);
  const [filteredPages, setFiletredPages] = useState([]);

  useEffect(() => {
    fetchAssetDetails();
  }, []);
  useEffect(() => {
    navigateToEnteredText(undefined, selectedPage);
  }, [selectedPage]);
  const navigateToEnteredText = (event, value = undefined) => {
    if (event) {
      const parsedInt = parseInt(event.target.value);
      if (parsedInt && parsedInt > 0 && parsedInt <= filteredPages.length) {
        setSelectedPage(parsedInt - 1);
        event.target.value = parsedInt;
      }
    } else {
      const input = document.getElementById("pageination-input");
      if (input) {
        input.value = value + 1;
      }
    }
  };

  const fetchAssetDetails = () => {
    getAssetDetails((response, err, errCode) => {
      if (err || errCode) {
        return;
      }
      const children = response.data.children;
      if (children) {
        let pages = [];
        pages = children.filter((child) => {
          return child.type === "Page";
        });
        pages = pages.map((page) => {
          const atoms = children.filter((child) => {
            return child.type === "Atom" && child.parent === page._id;
          });
          page = { ...page, atoms };
          return page;
        });
        //Sort the array
        pages.sort((page1, page2) => {
          return page1.MetaData.page - page2.MetaData.page;
        });
        setFiletredPages(pages);
      }
    }, getModuleInfoFromLocalStorage().assetId);
  };

  const atomsNavigation = () => {
    return (
      <div className=" align-items-center mt-3 atoms-container">
        <div className="atom-type">
          {atomType === "text" ? "Text Elements" : "Graphic Elements"}
        </div>
        <div className="">
          <button
            onClick={() => {
              if (selectedPage > 0) {
                setSelectedPage(selectedPage - 1);
              }
            }}
            className="btn btn-link m-0 p-0 atomBtnLeft"
            disabled={shouldBeDisbaled || selectedPage === 0}
            style={{ opacity: `${selectedPage === 0 ? 0.4 : ""}` }}
          >
            <img src={arrowLeft} alt="Previous" className="opacity50" />
          </button>
          Page
          <input
            disabled={shouldBeDisbaled}
            id="pageination-input"
            type="text"
            className="paginationInput"
            onBlur={(e) => {
              navigateToEnteredText(e);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                navigateToEnteredText(e);
              }
            }}
            defaultValue={selectedPage + 1}
            // value={selectedPage + 1}
          />
          Of {filteredPages.length}{" "}
          <button
            onClick={() => {
              if (selectedPage < filteredPages.length - 1) {
                setSelectedPage(selectedPage + 1);
              }
            }}
            className="btn btn-link m-0 p-0 atomBtnRight"
            style={{
              opacity: `${
                selectedPage === filteredPages.length - 1 ? 0.4 : ""
              }`,
            }}
            disabled={
              shouldBeDisbaled || selectedPage === filteredPages.length - 1
            }
          >
            <img src={arrowRight} alt="Next" className="opacity50" />
          </button>
        </div>
      </div>
    );
  };

  const renderAtoms = () => {
    return (
      filteredPages.length > 0 &&
      filteredPages[selectedPage].atoms.map((atom) => {
        var atomeToUse = atom;
        if (atomType) {
          if (atomType === "text" && atom.MetaData.type !== "text") {
            atomeToUse = null;
          }
          if (atomType === "graphic" && atom.MetaData.type !== "graphic") {
            atomeToUse = null;
          }
        }
        return (
          atomeToUse && (
            <li
              onDoubleClick={() => {
                if (shouldBeDisbaled) {
                  return;
                }
                handleAtomDoubleClick(atomeToUse);
              }}
              className="d-flex justify-content-center align-items-center"
              key={atomeToUse._id}
            >
              <div className="asset-with-expand">
                <img
                  id={atomeToUse._id}
                  src={atomeToUse.document}
                  className="mr-3"
                  alt=""
                />
                {/* <div className="claim-page-atom-expand-icon">
                  <img
                    src={expandIcon}
                    alt=""
                    onClick={() => {
                      showExpandedAssetModal(atomeToUse.document);
                    }}
                  />
                </div> */}
              </div>
            </li>
          )
        );
      })
    );
  };

  return (
    <div style={{ opacity: `${shouldBeDisbaled ? "0.6" : "1"}` }}>
      {atomsNavigation()}
      <div className="atom-list">
        <div
          className="lefColAtomsDisplay"
          style={{ marginRight: "0px", maxHeight: "50vh" }}
        >
          <ul>{renderAtoms()}</ul>
        </div>
      </div>
    </div>
  );
};

export default Atoms;
