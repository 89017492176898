import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import infoIcon from "../../images/infoIcon.svg";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import {
  channelTypesAndAttributes,
  isModularContent,
  marketingMessageSourceType,
} from "../../Uitls/Util";
import CustomModal from "../customModal";
import MarketingMessageSelection from "./MarketingMessageSelection";
import ModalHeader from "../../Uitls/ModalHeader";
import { channelGridStepCount } from "../ChannelGrid/ChannelGridHome";

const ChannelSelectionComponent = ({
  closeChannelSelectionModal,
  sourceType,
  brand,
  setSelectedBrand,
  brands,
  campaignName,
  campaigns,
  setSelectedChannel,
  selectedChannel,
  cancelCreatingChannelMatrix,
  handleContentMatrixModal,
  assetDataType,
  moduleId,
  startBusinessRulesValidation,
  channelGridSelectionModal,
}) => {
  const { t } = useTranslation();
  const [channelTypeList, setChannelTypeList] = useState([]);

  const ChannelAttributeAndType = [
    {
      channelType: channelTypesAndAttributes.email.name,
      isSelected: false,
    },
    {
      channelType: channelTypesAndAttributes.banner.name,
      isSelected: true,
    },
    {
      channelType: channelTypesAndAttributes.alert.name,
      isSelected: true,
    },
  ];
  useEffect(() => {
    const data = ChannelAttributeAndType.map((item, index) => {
      return {
        value: item.channelType,
        isSelected: index === 0 ? false : true,
      };
    });
    if (channelGridSelectionModal) {
      let edetail = { value: "E-Detail", isSelected: true };
      data.splice(2, 0, edetail);
    }
    setChannelTypeList(data);
  }, []);

  const onDropDownListChange = (value) => {
    setSelectedChannel(value);
  };

  return (
    <>
      <div className="cmHeaderRow">
        <ModalHeader
          title={t(
            channelGridSelectionModal
              ? "channelGrid.createChannelGrid"
              : "channelMatrix.createChannelMatrixStepHead",
            {
              currentStep: 1,
              totalSteps: channelGridSelectionModal ? channelGridStepCount : 4,
            }
          )}
          subtitle={
            isModularContent(assetDataType)
              ? "Select Brand and Channel Type to Proceed"
              : "Select Channel Type to Proceed"
          }
          subtitleIcon={infoIcon}
          headerData={{}}
          isBottomBorderNeeded={true}
        />
      </div>
      <div className="row align-items-center">
        {!isModularContent(assetDataType) && (
          <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
            <TextField
              className=""
              id="brand"
              label="Brand"
              variant="outlined"
              fullWidth={true}
              disabled={true}
              value={brand}
            />
          </div>
        )}
        {isModularContent(assetDataType) && (
          <div className="col-md-12 mt-3 channelTypeDropDown">
            <DropDownWithLabel
              class="mt-0"
              options={{
                name: "Brand",
                label: "Brand",
                value: brand,
                items: brands,
                handleChange: (e) => {
                  setSelectedBrand(e.target.value);
                },
                disabled: false,
                isDataTypeObject: false,
              }}
            />
          </div>
        )}
        {brand && !brands && (
          <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
            <TextField
              className=""
              id="Campaign"
              label="Campaign"
              variant="outlined"
              fullWidth={true}
              disabled={true}
              value={campaignName}
            />
          </div>
        )}
        <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
          <TextField
            className=""
            id="DAMS"
            label="DAMS"
            variant="outlined"
            fullWidth={true}
            disabled={true}
            value={marketingMessageSourceType[sourceType]?.name || sourceType}
          />
        </div>
        <div className="col-md-12 mt-3 channelTypeDropDown">
          <DropDownWithLabel
            class="mt-0"
            options={{
              name: "Channel Type",
              label: "Channel Type",
              value: selectedChannel,
              items: channelTypeList,
              handleChange: (e) => {
                onDropDownListChange(e.target.value);
              },
              disabled: false,
              isDataTypeObject: true,
            }}
          />
        </div>
      </div>

      <div className="row align-items-center mt-3">
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-link text-uppercase"
            onClick={() => {
              closeChannelSelectionModal();
            }}
          >
            CANCEL
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            type="button"
            className="btn btn-primary text-uppercase"
            disabled={!(selectedChannel && brand)}
            onClick={() => {
              startBusinessRulesValidation(true);
            }}
            style={{ width: "136px" }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </>
  );
};

export default ChannelSelectionComponent;
