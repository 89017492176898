import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import errComponentToRender from "../Uitls/apiErrorHandling";
import PageAtoms from "../components/pageAtoms";
import CoreClaimCategoryList from "../components/CoreClaimCategoryList";
import Typography from "@material-ui/core/Typography";
import { getAssetDetails } from "../services/apis";
import FilterListIcon from "@material-ui/icons/FilterList";
import ImcLogo from "../images/NEXT-Module-logo.svg";
import alertIcon from "../images/alertIcon.svg";
import "../styles/pages/CreateModule.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { prepareDataForValidation } from "formik";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

function CreateModule(props) {
  console.log(props);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [assetDetails, setAssetDetails] = useState({});
  const [pages, setPages] = useState([]);
  let [responseData, setResponseData] = useState([]);
  const [sliderData, setsliderData] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  const assetId = "4dd6be19-c57c-4403-a02c-00a0eb381d0a";
  const getCreatedAssetDetails = () => {
    getAssetDetails((response, err, errorCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        getCreatedAssetDetailsErr(errCoponent);
        setErrorMsg(true);
      } else {
        setAssetDetails(response.data);
      }
    }, assetId);
  };

  const getCreatedAssetDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useLayoutEffect(() => {
    prepareAssetDetailsData();
  }, [assetDetails]);

  const prepareAssetDetailsData = () => {
    const children = assetDetails.children;
    if (children) {
      let pages = [];
      pages = children.filter((child) => {
        return child.type === "Page";
      });
      pages = pages.map((page) => {
        const atoms = children.filter((child) => {
          return child.type === "Atom" && child.parent === page._id;
        });
        page = { ...page, atoms };
        return page;
      });
      console.log("no of pages", pages.length);
      setPages(pages);
    }
  };

  useEffect(() => {
    getCreatedAssetDetails();
  }, []);

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderAtomsPage = () => {
    if (pages.length > 0 && pages[page].atoms) {
      return <PageAtoms atoms={pages[page].atoms} />;
    }
  };

  const renderCoreClaimCategoriesList = () => {
    if (pages.length > 0) {
      let pageCategories = pages.map((page) => {
        return page.metadata.topMessageCategories;
      });

      //Get Arrays of claim category names
      let rawCoreClaimCategories = [];
      rawCoreClaimCategories = rawCoreClaimCategories.concat(
        pageCategories.map((category) => {
          return Object.keys(category);
        })
      );

      //Create an array of core claim categories(duplicates  allowed)
      let coreClaimCategories = [];
      for (const category of rawCoreClaimCategories) {
        console.log("and", category);
        coreClaimCategories = coreClaimCategories.concat(category);
      }
      //Remove Duplicates
      coreClaimCategories = coreClaimCategories.filter((value, index) => {
        return coreClaimCategories.indexOf(value) === index;
      });
      return (
        <CoreClaimCategoryList coreClaimCategories={coreClaimCategories} />
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [IsFilter, setIsFilter] = useState(false);
  const slideImages = new Array(pages.length);
  const handlePagination = (event, value) => {
    setPage(value + 1);
  };
  const handleFilter = () => {
    setIsFilter(!IsFilter);
  };

  return (
    <>
      <header className="navbar flex-column flex-md-row pt-0">
        <div className="logoCol">
          <img src={ImcLogo} alt="IMC - Module Manager" />
        </div>
        <div className="myPreferences">
          <div className="d-flex justify-content-between">
            <div className="mt-2">My Preferences (0)</div>
            <button className="btn btn-link">Add</button>
          </div>
        </div>
        <div className="searchCol">
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="alertIconCol">
          <img src={alertIcon} alt="Alert" />
        </div>
        {/* <div className="row no-gutters">
            <div className="col-md-1">
              <img src={ImcLogo} alt="" />
            </div>
            <div className="col-md-5">
              <img src={ImcLogo} alt="" />
            </div>
            <div className="col-md-5">
              <img src={ImcLogo} alt="" />
            </div>
            <div className="col-md-1">
              <img src={ImcLogo} alt="" />
            </div>
          </div> */}
      </header>

      <div className="fluid-container">
        <div className="row">
          <div className="col-md-4">
            <Typography
              component="div"
              style={{
                backgroundColor: "#FFFFFF",
                width: "300%",
                height: "85vh",
                borderRadius: "8px",
              }}
            >
              <div className="container-select-page">
                <div className="div-select-page">
                  <h4 style={{ fontSize: "16px" }}>
                    Select Page {responseData.name}
                  </h4>
                  <div style={{ display: "flex" }}>
                    <p style={{ fontSize: "14px" }}>
                      Identify the Core Claim Category and proceed
                    </p>
                    <span>{responseData}</span>
                    <p className="Page-core-category">Page core category</p>
                    <div className="button-select-page">
                      <label className="topMessageCategories">Eﬃcac</label>
                      <label className="topMessageCategories">Dosage</label>
                      <FilterListIcon
                        style={{ color: "royalblue" }}
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={handleFilterClick}
                      />
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <StyledMenuItem></StyledMenuItem>
                      </StyledMenu>
                    </div>
                  </div>
                </div>
                <div>
                  <Slide easing="ease" onChange={handlePagination}>
                    {pages.map((page) => {
                      return (
                        <div className="each-slide">
                          <div
                            style={{ backgroundImage: `url(${page.document})` }}
                          ></div>
                        </div>
                      );
                    })}
                  </Slide>
                </div>
                <div>
                  {" "}
                  <Typography
                    style={{
                      backgroundcolor: "white",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    Page: {page} of {slideImages.length}{" "}
                  </Typography>
                </div>
              </div>
            </Typography>
          </div>
          <div className="col-md-4">{/* {renderAtomsPage()} */}</div>
          <div className="col-md-4">
            {/* {renderCoreClaimCategoriesList()} */}
          </div>
        </div>
      </div>
    </>
    // <div className="container-fluid">

    // </div>
  );
}

export default CreateModule;
