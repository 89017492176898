import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import parseHtml from "html-react-parser";
import CheckBox from "../../Uitls/CheckBox";
import {
  fetchLocalizedModules,
  LocalizedBusinessrulesModules,
} from "../../services/apis";
import i18next from "i18next";

const BusinessRulesValidationForMLRModal = ({
  showModal,
  moduleIds,
  handleProceed,
}) => {
  const [curentSelectedModule, setCurentSelectedModule] = useState({});

  const [localizeBusinerulesData, setLocalizeBusinerulesData] = useState([]);

  const [selectedTabPanel, setSelectedTabPanel] = useState("must");

  useEffect(() => {
    if (moduleIds && moduleIds.length > 0) {
      fetchBusinessRules();
    }
  }, [moduleIds]);

  const fetchBusinessRules = () => {
    LocalizedBusinessrulesModules(moduleIds, (response, err, errCode) => {
      if (err) {
        //const errCoponent = ErrComponentToRender(errCode);
        //showError(errCoponent);
      } else {
        setLocalizeBusinerulesData(response.data);
        const bussinessRulesData = [];
        response.data.forEach((item) => {
          let allModuleData = [];
          allModuleData.push({
            parentModuleId: item.moduleId,
          });
          if (item.mustExistWith.length > 0) {
            item.mustExistWith.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "mustExist",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.precedes.length > 0) {
            item.precedes.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "preceeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.relatedTo.length > 0) {
            item.relatedTo.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "related",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.succeeds.length > 0) {
            item.succeeds.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "succeeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          bussinessRulesData.push.apply(bussinessRulesData, allModuleData);
        });

        const selectedModule = response.data[0];
        setCurentSelectedModule(selectedModule);
      }
    });
  };

  const setSelectedModuleHandler = (moduleId) => {
    const result = localizeBusinerulesData.find(function (element) {
      if (element.moduleId === moduleId) {
        return true;
      } else {
        return false;
      }
    });
    setCurentSelectedModule(result);
  };

  const getLeftSideBRuleModules = (parentModule, ruleType) => {
    let finalModules = [];

    switch (ruleType) {
      case "mustExist":
        finalModules = parentModule.mustExistWith;
        break;
      case "preceeds":
        finalModules = parentModule.precedes;
        if (finalModules.length > 0) {
          const mustExistWith = parentModule.mustExistWith;
          finalModules = [...finalModules].filter((module) => {
            return (
              mustExistWith.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "succeeds":
        finalModules = parentModule.succeeds;
        if (finalModules.length > 0) {
          const otherModules = parentModule.mustExistWith.concat(
            parentModule.precedes
          );
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "related":
        finalModules = parentModule.relatedTo;
        if (finalModules.length > 0) {
          const otherModules = parentModule.mustExistWith
            .concat(parentModule.precedes)
            .concat(parentModule.succeeds);
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      default:
        break;
    }
    return finalModules;
  };

  const togglePanel = (type) => {
    if (type === selectedTabPanel) {
      setSelectedTabPanel("");
    } else {
      setSelectedTabPanel(type);
    }
  };

  const handleClose = () => {
    showModal(false);
  };
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-12 " style={{ margin: "24px 20px 4px 20px" }}>
          <p className="associatedModuleTitle" style={{ display: "inline" }}>
            Create Submission Package |
          </p>
          <p
            className="associatedModuleTitle"
            style={{ fontWeight: "400", display: "inline" }}
          >
            {" "}
            Select Associated Modules
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 selectedModuleIdColumn customScrollBar">
          {localizeBusinerulesData &&
            localizeBusinerulesData.map((item) => {
              return (
                <>
                  <div
                    className={
                      curentSelectedModule.moduleId === item.moduleId
                        ? "associatedModuleActiveBrdr"
                        : ""
                    }
                  >
                    <button
                      onClick={() => {
                        setSelectedModuleHandler(item.moduleId);
                      }}
                      className={`btn btn-link btn-block associatedModuleIdTitle ${
                        curentSelectedModule.moduleId === item.moduleId
                          ? "associatedModuleActive"
                          : ""
                      }`}
                    >
                      {item.moduleCode}
                    </button>
                  </div>
                  {curentSelectedModule.moduleId === item.moduleId && (
                    <ul className="associatedBsModuleTitle">
                      {getLeftSideBRuleModules(item, "mustExist")?.map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "preceeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "succeeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "related").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                    </ul>
                  )}
                </>
              );
            })}
        </div>
        <div className="col-md-9">
          <div className="row align-items-center mb-3">
            <div className="col-md-5">
              <span className="associatedBsModuleSubtitle">Campaign Name</span>
              <p className="associatedBsModuleSubText">
                {curentSelectedModule.campaignName}
              </p>
            </div>
            <div className="col-md-4">
              <span className="associatedBsModuleSubtitle">Module Name</span>
              <p className="associatedBsModuleSubText font-weight-bold">
                {curentSelectedModule.moduleName}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 associatedModuleTitle mb-2">
              Business Rules
            </div>
          </div>
          <div className="row localizedAccordion customScrollBar">
            <Accordion defaultActiveKey="0">
              {curentSelectedModule &&
                curentSelectedModule.mustExistWith &&
                curentSelectedModule.mustExistWith.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="0"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("must");
                        }}
                      >
                        {selectedTabPanel === "must" ? "-" : "+"} Must Exist
                        With (
                        {`${curentSelectedModule.mustExistWith.length} Module${
                          curentSelectedModule.mustExistWith.length > 1
                            ? "s"
                            : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="row">
                        {curentSelectedModule &&
                          curentSelectedModule.mustExistWith &&
                          curentSelectedModule.mustExistWith.map((sm) => {
                            return (
                              <div className="col-md-4 mb-4">
                                <div className="d-flex align-items-center">
                                  <div className="moduleCardBusinessRules">
                                    <p className="bsRulesCardCode">
                                      {sm.moduleCode}
                                    </p>
                                    <p className="bsRulesCardModuleName">
                                      Module Name <span>{sm.moduleName}</span>
                                    </p>
                                    <p className="bsRulesCardClaim">
                                      Promotional Claim{" "}
                                      <span>
                                        {parseHtml(sm.primaryClaim || "")}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.precedes &&
                curentSelectedModule.precedes.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="1"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("precedes");
                        }}
                      >
                        {selectedTabPanel === "precedes" ? "-" : "+"} Precedes (
                        {`${curentSelectedModule.precedes.length} Module${
                          curentSelectedModule.precedes.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="row">
                        {curentSelectedModule.precedes &&
                          curentSelectedModule.precedes.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.succeeds &&
                curentSelectedModule.succeeds.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="3"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("succeeds");
                        }}
                      >
                        {selectedTabPanel === "succeeds" ? "-" : "+"} Succeeds (
                        {`${curentSelectedModule.succeeds.length} Module${
                          curentSelectedModule.succeeds.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="row">
                        {curentSelectedModule.succeeds &&
                          curentSelectedModule.succeeds.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.relatedTo &&
                curentSelectedModule.relatedTo.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="4"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("related");
                        }}
                      >
                        {selectedTabPanel === "related" ? "-" : "+"} Related
                        Modules (
                        {`${curentSelectedModule.relatedTo.length} Module${
                          curentSelectedModule.relatedTo.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="4">
                      <Card.Body className="row">
                        {curentSelectedModule.relatedTo &&
                          curentSelectedModule.relatedTo.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
            </Accordion>
          </div>
        </div>
      </div>
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-10 ">
          <button
            className="btn btn-link"
            onClick={() => {
              handleClose();
            }}
          >
            {i18next.t("generalText.close")}
          </button>
        </div>
        <div className="col-md-2 text-right">
          <button
            className="btn btn-primary btnBsRulesProceed"
            onClick={() => {}}
          >
            {i18next.t("generalText.submit")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default BusinessRulesValidationForMLRModal;
