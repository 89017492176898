import React, { useEffect, useState } from "react";

//Import Images
import searchIcon from "../../images/search.svg";
import infoIcon from "../../images/infoIcon.svg";
import { searchInArray } from "../../Uitls/Util";
const SearchModules = ({
  allowedToAddRule = true,
  modules,
  addBusinessRule,
  selectedBox,
  businessRules,
  setContentExists,
  setCannotExistWith,
  ...props
}) => {
  const [filteredModules, setFilteredModules] = useState([]);
  useEffect(() => {
    setFilteredModules(modules);
    const input = document.getElementById("search-brules-modules");
    if (input && input.value && input.value.trim().length > 0) {
      searchModules(input.value);
    }
  }, [modules]);

  const checkIfAlreadyExists = (modules, id, keyToCheck) => {
    let arr = [];
    modules.forEach((module) => {
      if (module.condition === keyToCheck) {
        arr.push(module.targetModuleId);
      }
    });

    return arr.indexOf(id) === -1 ? false : true;
  };

  const addRule = (bRule) => {
    if (selectedBox === "MustExistWith") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        ) ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
      ) {
        setContentExists("MustExistWith");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
        ) {
          setCannotExistWith("Module already added under Optional");
        }
        return;
      }
    }

    if (selectedBox === "InCompatibleWith") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "MustExistWith"
        ) ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds") ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes") ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
      ) {
        setContentExists("InCompatibleWith");
        setCannotExistWith("Module already added under other rules");
        return;
      }
    }

    if (selectedBox === "Precedes") {
      if (
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds") ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        )
      ) {
        setContentExists("Precedes");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds")
        ) {
          setCannotExistWith("Module already added under Succeeds");
        }
        return;
      }
    }

    if (selectedBox === "Succeeds") {
      if (
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes") ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        )
      ) {
        setContentExists("Succeeds");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes")
        ) {
          setCannotExistWith("Module already added under Precedes");
        }
        return;
      }
    }
    if (selectedBox === "RelatedTo") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        ) ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "MustExistWith"
        )
      ) {
        setContentExists("RelatedTo");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "MustExistWith"
          )
        ) {
          setCannotExistWith("Module already added under Mandatory");
        }
        return;
      }
    }

    addBusinessRule(bRule);
    setContentExists("");
    setCannotExistWith(undefined);
  };

  const searchModules = (searchParam) => {
    if (searchParam.trim().length > 0) {
      let searchedModules = searchInArray(
        modules,
        ["moduleCode", "name"],
        searchParam,
        "or"
      );
      searchedModules = [...searchedModules].filter((module) => {
        return !module.standalone;
      });
      setFilteredModules(searchedModules);
    } else {
      setFilteredModules(modules);
    }
  };
  return (
    <>
      <div className="suppClaimSearch">
        <div className="d-flex">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            id="search-brules-modules"
            type="text"
            placeholder="Search for a module"
            className={`globalSearchInput ${
              allowedToAddRule ? "" : "frozen-brule-box"
            }`}
            onChange={(e) => {
              searchModules(e.target.value);
            }}
            disabled={!allowedToAddRule}
          />
        </div>
      </div>
      <div className="placeholderBusinessRules custonScrollBar mt-4">
        {filteredModules.length === 0 && (
          <div className="no-results">No Results</div>
        )}
        {filteredModules.length > 0 && (
          <ul
            className={`supClaimList  ${
              allowedToAddRule ? "" : "frozen-brule-box"
            }`}
          >
            {filteredModules.map((item, index) => (
              <>
                {!item.standalone && item.primaryClaim ? (
                  <li
                    key={index}
                    onDoubleClick={() =>
                      addRule({
                        category: "Module-To-Module",
                        condition: selectedBox,
                        targetModuleId: item.id,
                        name: item.name,
                        moduleCode: item.moduleCode,
                      })
                    }
                    className="moduleListBusiness"
                  >
                    <div className="leftAtomsList">
                      <p className="listModuleName">{item.name}</p>{" "}
                      {item.moduleCode && (
                        <p className="listModuleId">
                          <span>Module ID</span> {item.moduleCode}
                        </p>
                      )}{" "}
                      {item.count === 0 ? (
                        ""
                      ) : (
                        <>
                          {item.count && (
                            <span className="usedModuleCount">
                              Used in {item.count} rules
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                ) : null}
              </>
            ))}
          </ul>
        )}
        <p className="mt-3">
          Promotional claim field is empty, cannot be added to business rule
          <img
            src={infoIcon}
            alt="Promotional claim field is empty"
            class="ml-2"
          />
        </p>
        <ul className="supClaimList mt-3">
          {filteredModules.map((item) => (
            <>
              {!item.standalone && !item.primaryClaim ? (
                <li>
                  <div className="leftAtomsList">
                    <p className="listModuleName">{item.name}</p>{" "}
                    {item.moduleCode && (
                      <p className="listModuleId">
                        <span>Module ID</span> {item.moduleCode}
                      </p>
                    )}
                  </div>
                </li>
              ) : null}
            </>
          ))}
        </ul>
        <p className="mt-3">
          Stand Alone Modules{" "}
          <img src={infoIcon} alt="Stand Alone Modules" class="ml-2" />
        </p>
        <ul className="supClaimList mt-3">
          {filteredModules.map((item) => (
            <>
              {item.standalone && (
                <li>
                  <div className="leftAtomsList">
                    <p className="listModuleName">{item.name}</p>{" "}
                    {item.moduleCode && (
                      <p className="listModuleId">
                        <span>Module ID</span> {item.moduleCode}
                      </p>
                    )}
                  </div>
                </li>
              )}
            </>
          ))}
        </ul>
      </div>
    </>
  );
};
export default SearchModules;
