import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import lockIconGrey from "../../images/lockIconGrey.svg";
import unlockGreyIcon from "../../images/unlockGrey.svg";
import mlrStatusInDraft from "../../images/mlr_status_draft.svg";
import mlrStatusApproved from "../../images/mlr_status_approved.svg";
import mlrStatusAppChanges from "../../images/mlr_status_approvedwithchanges.svg";
import mlrStatusReSubmit from "../../images/mlr_status_revise_resubmit.svg";
import mlrStatusContentCreatorUpload from "../../images/mlr_status_contentcreatorupload.svg";
import mlrStatusInReview from "../../images/mlr_status_inreview.svg";
import mlrStatusDistribution from "../../images/mrl_status_approved_distribution.svg";
import ThreeDotIcon from "../../images/editor/ThreedotsIcon.svg";
import IconStylised from "../../images/editor/icon_stylised.svg";
import "../../styles/components/ExistingTatics.scss";
import { OutsideClickHandler } from "../../Uitls/Util";

const ModuleCard = ({
  module,
  colWidth = 6,
  assetLockStatus,
  selectModule,
  isSelected,
  unLockModule,
  viewModuleDetail,
  modifyModule,
  translateModule,
  localizeModule,
  typeofModule,
  stylizeModule,
}) => {
  const { t } = useTranslation();
  const [shouldShowCreateStylizedOption, setShouldShowCreateStylizedOption] =
    useState(false);
  const kebabRef = useRef();
  const hidePopup = () => {
    setShouldShowCreateStylizedOption(false);
  };
  OutsideClickHandler(kebabRef, hidePopup, setShouldShowCreateStylizedOption);
  const renderModuleCard = () => {
    return (
      <div className={`col-md-${colWidth} module-card`} key={module.id}>
        <div
          id={`${module.moduleCode}`}
          className={`currentModuleTactics ${
            isSelected
              ? "denovo-module-card-selected-parent denovo-module-card-selected"
              : ""
          }`}
          onClick={(event) => {
            selectModule(module);
          }}
        >
          <div className="d-flex justify-content-between">
            <span className="moduleCodeBox">{module.moduleCode}</span>

            {module.mlrStatus === "In Draft" || module.mlrStatus === "Draft" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusInDraft}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Content creator uploading" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusContentCreatorUpload}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "In-review" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusInReview}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Revise & Resubmit-MLR Review" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusReSubmit}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "MLR Approved with changes" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusAppChanges}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "MLR Approved" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusApproved}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Approved for Distribution" ? (
              <span className="mlrStatusText">
                <img
                  src={mlrStatusDistribution}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : (
              <>
                {module.mlrStatus && (
                  <>
                    <span className="mlrStatusText">{module.mlrStatus}</span>
                  </>
                )}
              </>
            )}
            <div
              className={`${
                module.stylizedModuleChannelList?.length > 0
                  ? "stylised-icon"
                  : "no-stylise-icon"
              }`}
            >
              {module.stylizedModuleChannelList?.length > 0 && (
                <img src={IconStylised} alt="stylised" className="img-fluid" />
              )}
            </div>

            {module.locked === "Locked" ? (
              <button
                className="btn btn-link moduleLockStatus"
                onClick={(e) => {
                  e.stopPropagation();
                  if (unLockModule) {
                    unLockModule(module.id, module.locked);
                  }
                }}
              >
                <img src={lockIconGrey} alt="" />
              </button>
            ) : (
              <button
                className="btn btn-link moduleLockStatus"
                onClick={(e) => {
                  e.stopPropagation();
                  if (unLockModule) {
                    unLockModule(module.id, module.locked);
                  }
                }}
              >
                <img src={unlockGreyIcon} alt="" />
              </button>
            )}
            <div className="kebabDiv" ref={kebabRef}>
              <button
                className="btn btn-link recentCardKebab"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setShouldShowCreateStylizedOption(true);
                }}
              >
                <img
                  src={ThreeDotIcon}
                  alt="Kebab"
                  className="img-fluid kebabButton"
                />
              </button>
            </div>
            {shouldShowCreateStylizedOption && (
              <>
                <div className="kebabMenu" onClick={(e) => e.stopPropagation()}>
                  <div
                    className={`kebabMenu--option ${
                      module.stylizedModuleChannelList?.length > 0 ||
                      module.locked === "Locked"
                        ? "disabled"
                        : ""
                    }`}
                    onClick={(e) => {
                      stylizeModule(module);
                    }}
                  >
                    Create Stylized Module
                  </div>
                </div>
              </>
            )}
          </div>
          <p className="modNameTitle mt-1 mb-0">Campaign Name</p>
          <p className="modNameText modCampName">{module.campaign}</p>
          <p className="modNameTitle mb-0">Module Name</p>
          <p className="modNameText modNameTextTitle">{module.name}</p>
          <p className="modCategoryText">
            {module.coreCategories &&
              module.coreCategories.map((item) => <span>{item}</span>)}
          </p>

          <p className="modNameTitle mb-0">{t("primaryClaim.name")}</p>
          <div className="modNameText mb-0">
            {module.primaryClaim && parse(module.primaryClaim)}
          </div>

          <div className="moduleDetailsBrdr">
            {module.locked === "Locked" ? (
              <div className="d-flex justify-content-center moduleDetailsBtnRow">
                <button
                  className="btn btn-link btnApprove pl-0 moduleActionBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewModuleDetail(module.id, module.locked === "Locked");
                  }}
                >
                  View
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-between moduleDetailsBtnRow">
                {/* <span className="d-flex pr-1 verticalLine">|</span> */}

                {/* <button
                  className="btn btn-link btnApprove pl-2 moduleActionBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    modifyModule(module.id);
                  }}
                >
                  Modify
                </button> */}
                {typeofModule === "Localize" && (
                  <>
                    {/* <span className="d-flex pr-1 verticalLine">|</span> */}

                    <button
                      className="btn btn-link btnApprove pl-2 moduleActionBtn"
                      style={{ paddingLeft: "0" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (translateModule) {
                          translateModule(module.id);
                        }
                      }}
                    >
                      Translate
                    </button>
                  </>
                )}
                {typeofModule !== "Localize" && (
                  <>
                    {/* <span className="d-flex pr-1 verticalLine">|</span> */}
                    <button
                      className="btn btn-link btnApprove pr-0 moduleActionBtn"
                      style={{ paddingLeft: "0" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        localizeModule(module.id);
                      }}
                    >
                      Localize
                    </button>
                  </>
                )}
                <button
                  className="btn btn-link btnApprove pl-0 moduleActionBtn"
                  style={{ paddingRight: "0" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    viewModuleDetail(module.id, false);
                  }}
                >
                  {!module.groupModulesVeevaPublishFlag
                    ? "View"
                    : "View/Publish"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <>{renderModuleCard()}</>;
};

export default ModuleCard;
