import React, { useRef } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { getMlrStatusIcon } from "../../Uitls/Util";
import "../../styles/components/ChannelGridPreview.scss";
import RenderMlrFeedback from "../MlrFeedback/RenderMlrFeedback";

const ChannelGridpreview = ({
  brand,
  campaignName,
  channelGridToView,
  cancelCreatingGrid,
}) => {
  const mlrStatusIcon = () => {
    return (
      <img
        src={getMlrStatusIcon()}
        alt=""
        style={{ marginBottom: "3px" }}
      ></img>
    );
  };
  const elementRef = useRef(null);

  const downloadPdf = (pdfURL) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = pdfURL;
    anchor.target = "_blank";
    anchor.download = "channelGrid";
    // Append to the DOM
    document.body.appendChild(anchor);
    // Trigger `click` event
    anchor.click();
    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  return (
    <>
      <div className="row mt-3 channel-pdf-view-row">
        <div className="col-md-4">
          <div style={{ marginLeft: "10px" }}>
            <span className="title-channel-grid">Submission Document ID</span>
            <span className="title-channel-grid-id">
              {channelGridToView.submissionDocId}
            </span>
          </div>
        </div>
        <div className="col-md-8 view-pdf-channel-grid-header">
          <div className="d-flex justify-content-end pr-2">
            <div className="mr-5">
              <span>Product/Brand</span>
              {brand}
            </div>
            <div className="mr-5">
              <span>Campaign</span>
              {campaignName}
            </div>
            <div className="mr-5">
              <span>MLR Status</span>
              {mlrStatusIcon(channelGridToView.gridMlrStatus)}{" "}
              {channelGridToView.gridMlrStatus || "Not Submitted"}
            </div>
            <div>
              <DropdownButton
                id="dropdown-basic-button"
                title="I want to"
                className="module-button"
              >
                <Dropdown.Item as="button" onClick={() => {}} disabled={true}>
                  Publish to DAMS
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => {}} disabled={true}>
                  Address MLR Feedback
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => {}}>
                  Re-generate
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12 text-left">
          <button
            className="btn btn-link download-button-pdf-view"
            onClick={() => {
              downloadPdf(channelGridToView.downloadUrl);
            }}
          >
            Download
          </button>
        </div>
        <div className="col-md-12 pdfPreviewContainer" ref={elementRef}>
          <div className="customScrollBar pdf-view-container-Scroll">
            <RenderMlrFeedback
              pdfFileUrl={channelGridToView.downloadUrl}
              pdfWidth={
                elementRef.current?.getBoundingClientRect().width * 0.95 ||
                undefined
              }
              textLayer={false}
            />
          </div>
        </div>
      </div>
      <div className="row pt-3 createGroupResultBtnRow">
        <div className="col-md-4 pl-3"></div>
        <div className="col-md-8 text-right pr-4">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              cancelCreatingGrid();
            }}
            style={{ width: "160px" }}
          >
            Back to home
          </button>
        </div>
      </div>
    </>
  );
};
export default ChannelGridpreview;
