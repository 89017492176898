import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
const DropDownWithCheck = (props) => {
  const useStyles = makeStyles((theme) => ({
    truncatedChip: {
      display: "inline-flex",
      alignItems: "center",
      maxWidth: "calc(100% - 40px)",
      height: "18px",
      padding: "2px",
      margin: "0 1px 4px 0",
      borderRadius: "9px",
      backgroundColor: "#1c75bb",
      color: "#FFFFFF",
      fontSize: "12px",
    },
    selectedChip: {
      backgroundColor: "#FFFFFF",
      margin: "0 1px 4px 0",
      fontSize: "13px",
    },
    autocomplete: {
      "& .MuiAutocomplete-inputRoot": {
        height: "40px",
      },
      "& .MuiChip-deleteIcon": {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (event, value) => {
    props.options.handleChange(value);
  };

  const getSelectedOptionsDisplay = () => {
    if (props.options.value.length === 0) {
      return "";
    } else if (props.options.value.length === 1) {
      return props.options.value[0].displayName;
    } else {
      return `${props.options.value[0].displayName} +${
        props.options.value.length - 1
      }`;
    }
  };

  return (
    <Autocomplete
      disabled={props.options.disabled}
      id="search-and-select"
      style={{ width: "100%" }}
      options={props.options.items}
      value={props.options.value}
      onChange={handleChange}
      closeIcon={""}
      className={`${classes.autocomplete} drop-down-with-search ${classes.dropdownMenu}  ${props.class}`}
      autoHighlight
      getOptionLabel={(option) =>
        props.options.placeHolder
          ? props.options.placeHolder
          : option.displayName
      }
      getOptionSelected={(option, value) => option === value}
      getOptionDisabled={(option) => option.isNotSelectable}
      renderOption={(option) => (
        <React.Fragment>
          <input
            type="checkbox"
            className="mr-2 ml-0"
            key={option.displayName}
            checked={props.options.value.includes(option)}
            classes={{ root: classes.checkboxRoot }}
            onChange={(event) => {
              if (props.options.value.includes(option)) {
                handleChange(
                  event,
                  props.options.value.filter((item) => item !== option)
                );
              } else {
                handleChange(event, props.options.value.concat(option));
              }
            }}
          />
          <span>{option.displayName}</span>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.options.label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            readOnly: props.options.value.length > 0,
            className: classes.input,
          }}
          value={getSelectedOptionsDisplay()}
        />
      )}
      renderTags={(value, getTagProps) => {
        if (value.length > 1) {
          const selectedOptions = value.slice(0, 1);
          const truncatedOptions = value.slice(1);
          return [
            ...selectedOptions.map((option, index) => (
              <Chip
                key={option.displayName}
                label={option.displayName}
                classes={{ root: classes.selectedChip }}
                {...getTagProps({ index })}
              />
            )),
            <Chip
              key="truncated"
              label={`+${truncatedOptions.length}`}
              classes={{ root: classes.truncatedChip }}
              {...getTagProps({ index: value.length })}
            />,
          ];
        } else if (value.length === 1) {
          return [
            <Chip
              key={value[0].displayName}
              label={value[0].displayName}
              classes={{ root: classes.selectedChip }}
              {...getTagProps({ index: 0 })}
            />,
          ];
        }
        return null;
      }}
      multiple
      disableCloseOnSelect={true}
    />
  );
};

export default DropDownWithCheck;
