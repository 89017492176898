import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import FroalaEditor from "../../Uitls/CommonFroalEditorComponent";
import TextField from "@material-ui/core/TextField";
import { uploadMarketinMessageOptoutVisual } from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import uploadFiles from "../../images/uploadFilePlaceholder.svg";
import { capitalizeString } from "../../Uitls/Util";

const OptoutComponent = ({
  optOutData,
  setOptOutData,
  selectedChannelType,
  setIsloading,
  canCreateOptout,
  setOpenImportImageFromDamsModal,
  selectedProduct,
  setUploadError,
  uploadError,
  damsSource,
}) => {
  const [optoutText, setOptoutText] = useState("");
  const [optOutVisualUrl, setOptOutVisualUrl] = useState("");
  const { t } = useTranslation();
  const referencesValid = useRef(null);
  const frInstanceOn = React.createRef();

  useEffect(() => {
    setOptOutData({
      optOutVisual: optOutVisualUrl,
      channelType: selectedChannelType,
      optOutText: optoutText,
    });
  }, [optoutText, optOutVisualUrl]);

  useEffect(() => {
    setOptoutText(optOutData.optOutText);
    setOptOutVisualUrl(optOutData.optOutVisual);
  }, []);

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const uploadImageToS3 = (file) => {
    setIsloading(true);
    const formData = new FormData();
    formData.append("file", file);
    uploadMarketinMessageOptoutVisual(formData, (response, err, errorCode) => {
      setIsloading(false);
      if (err || errorCode) {
        const errorMessage = err.message;
        if (errorMessage === "FILE_SIZE_EXCEED_500KB") {
          setUploadError(`${t("marketingMessagesOptout.errorMessage")}`);
        } else {
          const errCoponent = errComponentToRender(errorCode);
          showError(errCoponent);
        }
      } else if (response.data) {
        const S3Url = response.data;
        setOptOutVisualUrl(S3Url);
      } else {
        const errCoponent = errComponentToRender();
        showError(errCoponent);
        setOptOutVisualUrl("");
      }
    });
  };

  const removeOptoutImage = () => {
    setOptOutVisualUrl("");
  };

  const onChange = (TextChanged) => {
    referencesValid.current.innerHTML = TextChanged;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      setOptoutText(TextChanged);
    } else {
      setOptoutText("");
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    let file_size = acceptedFiles[0].size;
    if (file_size <= 500000) {
      uploadImageToS3(acceptedFiles[0]);
      setUploadError(undefined);
    } else {
      setUploadError(`${t("marketingMessagesOptout.errorMessage")}`);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    onDrop,
  });

  const onBoxSelected = (event, id) => {
    if (id && id !== null) {
      setTimeout(() => {
        setCssOnSelectedBox(id);
      }, 1);
    }
  };

  const setCssOnSelectedBox = (boxId) => {
    const moduleUses = document.getElementById("module-uses");
    switch (boxId) {
      case "module-uses":
        if (moduleUses !== null) {
          moduleUses.className = "optout-editor-box optout-selected-editor-box";
        }
        break;
      default:
        if (moduleUses !== null) {
          moduleUses.className = "optout-editor-box";
        }
        break;
    }
  };

  return (
    <>
      <div className="validate-optout-err">
        {!canCreateOptout && (
          <p className="mb-0">{t("marketingMessagesOptout.alreadyExists")}</p>
        )}
      </div>
      <div
        className={`row mt-1 ${!canCreateOptout ? "disabled-optout-box" : ""}`}
      >
        <div className="col-md-3">
          <div className="optout-image-box">
            {optOutData.optOutVisual ? (
              <div>
                <div className="row" style={{ height: "125px" }}>
                  <img
                    alt=""
                    src={optOutVisualUrl && optOutVisualUrl}
                    className="img-fluid optOutImageBox pl-2 pr-2"
                  />
                </div>
                <div className="row justify-content-center">
                  <button
                    onClick={() => removeOptoutImage()}
                    className="btn btn-link"
                    disabled={!canCreateOptout}
                  >
                    {t("marketingMessagesOptout.remove")}
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex optout-image-inside-box">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        height: "61px",
                        paddingLeft: "50px",
                        paddingTop: "32px",
                      }}
                    >
                      <img
                        alt=""
                        src={uploadFiles}
                        className="img-fluid pl-2 pr-2"
                      />
                    </div>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          marginLeft: "7px",
                          fontSize: "10px",
                          color: "#666666",
                        }}
                      >
                        {t("marketingMessagesOptout.dragAndDrop")}
                      </span>
                      <br />
                      <span
                        className="browseButton pl-2"
                        onClick={() => {
                          if (canCreateOptout) {
                            open();
                          }
                        }}
                        style={{
                          fontSize: "10px !important",
                        }}
                      >
                        {t("marketingMessagesOptout.browse")}
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-block importFromDamsBtnInOptout"
                  disabled={!selectedProduct || !canCreateOptout}
                  onClick={() => {
                    setOpenImportImageFromDamsModal(true);
                  }}
                >
                  {t("marketingMessagesOptout.import", {
                    dams: capitalizeString(damsSource || "DAMS"),
                  })}
                </button>
              </>
            )}
          </div>
        </div>
        <div
          className={`col-md-9 optout-message ${
            !canCreateOptout ? "disabled-optout-box" : ""
          }`}
          onClick={(event) => {
            onBoxSelected(event, "module-uses");
          }}
        >
          <div>
            {optoutText && optoutText !== "" && (
              <span className="optout-editor-label">Enter Message</span>
            )}

            <div id="module-uses" className="optout-editor-box">
              {!canCreateOptout ? (
                <span className="d-block ml-2 mt-3">Enter Message</span>
              ) : (
                <FroalaEditor
                  forwardedRef={frInstanceOn}
                  placeHolder="Enter Messsage"
                  variant="outlined"
                  tag="textarea"
                  handleModelChange={onChange}
                  selectedAtom={optoutText && optoutText}
                  charCounter={250}
                  insertLink={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="image-upload-text">
            {uploadError && <span style={{ color: "red" }}>{uploadError}</span>}
          </div>
        </div>
      </div>
      <div className="d-none" ref={referencesValid}></div>
    </>
  );
};
export default OptoutComponent;
