import React, { useState } from "react";

import CircularProgressBar from "../../../Uitls/CircularProgressBar";

import { searchReferenceDocs } from "../../../services/apis";

import docPlaceholder from "../../../images/anchor_thumb_pdf.svg";
import searchIcon from "../../../images/search.svg";
import "../../../styles/pages/UplaodMasterAsset.scss";
import "../../../styles/CustomToastify.scss";

import clearTextIcon from "../../../images/clear_text.svg";
import errComponentToRender from "../../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";

const BrowseGenericInfoDocument = (props) => {
  const { browseDocType, hideBrowseGenInfoDocModal, updateGenericInfoUrl } =
    props;
  const [isloading, setIsloading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentLink, setSelectedDocumentLink] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(true);
  const showError = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const searchDocument = () => {
    const element = document.getElementById("input-search-gen-documents");
    if (!element) {
      return;
    }

    const searchText = element.value;
    if (searchText.length === 0) {
      return;
    }
    setSelectedDocumentLink(undefined);
    setIsloading(true);
    let payLoad = {
      docType: browseDocType,
      ISI: "ISI - Important Safety Information",
      PI: "FPI - Full Prescribing Information",
      MG: "Patient Labeling",
      sourceType: props.sourceType,
    };
    searchReferenceDocs(searchText, payLoad, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        if (response.data && response.data.length > 0) {
          setErrorMessage(true);
        } else {
          setErrorMessage(false);
        }
        setDocuments(response.data);
        return;
      }
      const errCoponent = errComponentToRender(errCode);
      showError(errCoponent);
    });
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <div className="row align-items-center">
        <div className="col-md-6 gendocTitle">
          Search {browseDocType} document
        </div>
        <div className="col-md-6 bulkModalValidationTitle">
          <div className="searchColAnchor">
            <div className="input-group mb-3">
              <img src={searchIcon} alt="" className="searchIconAnchor" />
              <input
                id="input-search-gen-documents"
                type="text"
                className="form-control searchInputAnchor"
                placeholder="Search document"
                aria-describedby="button-addon2"
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    searchDocument();
                  }
                }}
              />
              <button
                className="anchorSearchBtn"
                type="button"
                id="button-addon2"
                onClick={() => {
                  searchDocument();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12 ">
          <div className="gendocBrdr">All {browseDocType} Documents</div>
        </div>
      </div>
      <div className="genericScrollDocList customScrollBar">
        {documents.length > 0 ? (
          documents.map((document) => {
            return (
              <div className="row align-items-center">
                <div className="col-md-1 customRadioCol-1">
                  <input
                    type="radio"
                    name="docurl"
                    className="docRadioBtn"
                    checked={selectedDocumentLink === document.refDocUrl}
                    onChange={() => {
                      setSelectedDocumentLink(document.refDocUrl);
                    }}
                  />
                </div>
                <div className="col-md-11 customRadioCol-2">
                  <div className="d-flex align-items-center docBrdrBtmRow">
                    <div>
                      <img className="mr-4" alt="" src={docPlaceholder} />
                    </div>
                    <div className="pd-3">
                      <p className="mb-0 genDocTitle">{document.name__v}</p>
                      <p className="gendocUrl mb-0">{document.refDocUrl}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : errorMessage ? (
          ""
        ) : (
          <div className="emptyDoucmentMessage">
            <div className="emptyMessageHead">
              No {browseDocType} document Found
            </div>
            <div className="emptyMessageSubhead">Try using another Keyword</div>
          </div>
        )}
      </div>
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-6">
          <button
            className="btn btn-link p-0"
            onClick={hideBrowseGenInfoDocModal}
          >
            CLOSE
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-primary btnNextModule"
            onClick={() => {
              updateGenericInfoUrl(browseDocType, selectedDocumentLink);
            }}
            disabled={selectedDocumentLink ? false : true}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrowseGenericInfoDocument;
