import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import i18next from "i18next";

import searchIcon from "../../images/masterTacticSearchIcon.svg";
import { importImageFromDAMSForOptout } from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { capitalizeString, damsSources } from "../../Uitls/Util";

const ImportImageFromDams = ({
  setOpenImportImageFromDamsModa,
  selectedCampaign,
  selectedProduct,
  setIsloading,
  handleProceedCTA,
  damsSource,
}) => {
  const [searchText, setSearchText] = useState("");
  const [imagesFromDams, setImagesFromDams] = useState([]);
  const [imageUrl, setImageUrl] = useState(undefined);

  const onTextChange = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    let payload = {
      brand: selectedProduct,
      searchText: searchText || "",
      campaign: selectedCampaign || "",
    };
    searchImageInDAMS(payload);
  }, []);

  const isSearchText = () => {
    if (searchText.trim().length === 0) {
      return true;
    }
    return false;
  };

  const searchImageInDAMS = (payload) => {
    setIsloading(true);

    importImageFromDAMSForOptout(
      damsSource?.toLowerCase(),
      payload,
      (response, err, errorCode) => {
        setIsloading(false);
        if (err || errorCode) {
          const errCoponent = errComponentToRender(errorCode);
          showError(errCoponent);
        } else {
          setImagesFromDams(response.data);
        }
      }
    );
  };

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleOnclick = (visualUrl) => {
    if (visualUrl === imageUrl) {
      setImageUrl("");
    } else {
      setImageUrl(visualUrl);
    }
  };

  const isImageSelected = () => {
    if (imageUrl && imageUrl) {
      return false;
    }
    return true;
  };

  const getDamsIdLabel = () => {
    switch (damsSource.toLowerCase()) {
      case damsSources.veeva.name.toLowerCase():
        return `/ ${damsSource} document number `;
      case damsSources.aprimo.name.toLowerCase():
        return `/ ${damsSource} ID `;

      case damsSources.aem.name.toLowerCase():
        return `/ ${damsSource} ID `;

      default:
        break;
    }
    return "";
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 CreateNewMessageTitle">
          Import Image from {capitalizeString(damsSource || "DAMS")}
        </div>
        <div className="col-md-6">
          <div
            className={`d-flex ${
              selectedCampaign.length === 0 ? "justify-content-center" : ""
            } `}
          >
            <div
              className="textBlockHeaderProduct"
              style={{
                marginLeft:
                  selectedCampaign && selectedCampaign.length < 20
                    ? "80px"
                    : "",
              }}
            >
              <span className="d-block">Product/Brand</span>
              <span style={{ fontSize: "14px", color: "#252529" }}>
                {selectedProduct && selectedProduct}
              </span>
            </div>
            {selectedCampaign && (
              <div
                className={`${
                  selectedCampaign.length < 20
                    ? "selectedCampaignWidth"
                    : "textBlockHeaderCampaign"
                } `}
              >
                <span className="d-block">Campaign</span>
                <div
                  className={`${
                    selectedCampaign.length < 20
                      ? ""
                      : "selectedCampaignTextTruncate"
                  } `}
                  style={{ fontSize: "14px", color: "#252529" }}
                  title={selectedCampaign}
                >
                  {selectedCampaign && selectedCampaign}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          borderBottom: "1px solid #e1e1e1",
          paddingBottom: "15px",
        }}
      >
        <div className="col-md-9">
          <div className="marketingMsgSearchRow">
            <div className="d-flex align-items-center">
              <img src={searchIcon} alt="" className="search-icon-icb-tactic" />
              <TextField
                className=""
                id="searchText"
                label={`Search by Image name ${getDamsIdLabel()}`}
                variant="outlined"
                fullWidth={true}
                disabled={false}
                value={searchText}
                onChange={(e) => {
                  onTextChange(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary btn-search-import mmMessageTop24"
            onClick={() =>
              searchImageInDAMS({
                brand: selectedProduct,
                searchText: searchText,
                campaign: selectedCampaign || "",
              })
            }
            disabled={isSearchText()}
          >
            search
          </button>
          <button
            className="btn btn-link clear-search marketingMessagesBtnLink resetButton"
            onClick={() => {
              setSearchText("");
              searchImageInDAMS({
                brand: selectedProduct,
                searchText: "",
                campaign: selectedCampaign || "",
              });
            }}
          >
            {i18next.t("generalText.resetAll").toUpperCase()}
          </button>
        </div>
      </div>
      <div className="row mt-2 mb-2 import-image-modal-body custonScrollBar">
        {imagesFromDams &&
          imagesFromDams.map((item, index) => {
            return (
              <div className="col-md-3 mb-3" key={index}>
                <div
                  className={`import-image-card mb-1 ${
                    imageUrl === item.imageUrl ? "highlightSelectedImage" : ""
                  }`}
                  onClick={() => handleOnclick(item.imageUrl)}
                >
                  <img src={item.imageUrl} alt="" className="img-fluid" />
                </div>
                <span style={{ fontSize: "10px", color: "#222" }}>
                  {item.imageName}
                </span>
              </div>
            );
          })}
      </div>
      <div className="row mmBrdrTop align-items-center">
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-link text-uppercase"
            onClick={() => {
              setOpenImportImageFromDamsModa(false);
            }}
          >
            BACK
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            type="button"
            className="btn btn-primary text-uppercase"
            disabled={isImageSelected()}
            onClick={() => handleProceedCTA(imageUrl)}
          >
            PROCEED
          </button>
        </div>
      </div>
    </>
  );
};
export default ImportImageFromDams;
