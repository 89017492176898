//Import react dependencies
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
//Import apis
import { getCompositeAssetData } from "../../../services/apis";

//Import custom components
import CircularProgressBar from "../../../Uitls/CircularProgressBar";
import errComponentToRender from "../../../Uitls/apiErrorHandling";
import { damsSources, htmlToString } from "../../../Uitls/Util";
import { sortClaimsAndVisualsByRecordIndex } from "../../../Pages/ModuleDetails";
import { Col, Row } from "react-bootstrap";
import i18next from "i18next";
import { renderToString } from "react-dom/server";

const CompositeAssetView = ({
  data,
  isCompositeAsset = true,
  setJsx,
  isView = false,
  sourceType,
  assetDataType,
}) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [compositeAssetData, setCompositeAssetData] = useState([]);
  const [pageJsx, setPageJsx] = useState(undefined);
  const [globalOptOuts, setGlobalOptouts] = useState(undefined);
  const [htmlData, setHtmlData] = useState(undefined);

  useEffect(() => {
    if (!pageLoaded) {
      if (!isView && data.modules) {
        fetchCompositeAssetData();
      } else if (data && isView) {
        setCompositeAssetData(data);
        prepareOptouts(data);
        renderTemplate(data, data.templateHtml);
      }

      setPageLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (!isCompositeAsset) {
      setJsx(pageJsx);
    }
  }, [pageJsx]);

  const prepareOptouts = (compositeAsset) => {
    let optOuts = [];
    const bodyModules = compositeAsset.bodyModules;
    if (!compositeAsset.marketingMessages) {
      if (bodyModules && bodyModules.length > 0) {
        bodyModules.forEach((bodyModule) => {
          optOuts = optOuts.concat(bodyModule.optOut);
        });
      }
    } else {
      const marketingMessages = compositeAsset.marketingMessages;
      if (marketingMessages) {
        optOuts = marketingMessages.optOuts || [];
      }
    }
    setGlobalOptouts(optOuts);
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const fetchCompositeAssetData = () => {
    setIsloading(true);
    getCompositeAssetData(
      sourceType,
      assetDataType,
      data.compositeId,
      (response, err, errCode) => {
        if (err) {
          setIsloading(false);
          showError(errCode);
        } else {
          setIsloading(false);
          setCompositeAssetData(response.data);
          prepareOptouts(response.data);
          renderTemplate(response.data, response.data.templateHtml);
        }
      }
    );
  };
  const renderCitatedHtml = (html) => {
    if (!html) {
      return undefined;
    }

    let str = parse(html);
    return str;
  };

  const renderprimaryClaim = (primaryClaim) => {
    let texts = [primaryClaim.claim];
    texts = texts.concat(
      primaryClaim.claimVariants?.map((variant) => variant.moduleClaim)
    );
    let footnotes = primaryClaim.supportingTextComponents?.filter((el) => {
      return el.componentType.toLowerCase() === "footnote";
    });
    return (
      <div className="main-content">
        {texts.map((text, index) => {
          return (
            <div
              className="claim-or-caption-text"
              style={{ fontSize: "14px", margin: "10px 0px" }}
              key={"pclaim" + index}
            >
              {parse(text || "")}
            </div>
          );
        })}
        {footnotes?.map((footnote) => {
          return (
            <div
              className="supporting-text"
              style={{
                fontSize: "12px",
                margin: "10px 0px",
                display: "flex",
              }}
              key={footnote.id}
            >
              <span style={{ marginRight: "5px" }}>
                {footnote.prefix || ""}{" "}
              </span>
              <span style={{ display: "inline" }}>
                {renderCitatedHtml(footnote.text)}
              </span>
            </div>
          );
        })}
      </div>
    );
  };
  const renderSupportingVisual = (visual, index) => {
    const images = visual.visualVariants;
    let captions = [];
    if (visual.supportingTextComponents) {
      captions = visual.supportingTextComponents?.filter((el) => {
        return el.componentType.toLowerCase() === "caption";
      });
    }

    let footnotes = [];
    if (visual.supportingTextComponents) {
      footnotes = visual.supportingTextComponents.filter((el) => {
        return el.componentType.toLowerCase() === "footnote";
      });
    }

    return (
      <div>
        {images?.map((image, index) => {
          const jsx = (
            <div className=" mt-4" style={{ border: "none" }}>
              <img
                src={image.visualUrl}
                alt=""
                id={image.id}
                key={image.id}
                // style={{ width: "300px" }}
                onLoad={(event) => {
                  let width = event.target.width;
                  if (isCompositeAsset) {
                    event.target.width = "100%";
                    event.target.style.width = "100%";
                  } else {
                    width = width > 300 ? 300 : width;
                    event.target.width = width;
                    event.target.style.width = `${width}px`;
                  }
                }}
                className="img-prev-pdf img-fluid"
              />

              {renderCaptions(captions, images.length === index + 1)}
            </div>
          );
          if (captions.length > 0 && images.length > index + 1) {
            captions.splice(0, 1);
          }

          return jsx;
        })}
        {renderFootnotes(footnotes)}
      </div>
    );
  };

  const renderFootnotes = (footnotes) => {
    return (
      <div>
        {footnotes?.map((footnote) => {
          return (
            <div
              className="supporting-text"
              style={{
                fontSize: "12px",
                margin: "10px 0px",
                display: "flex",
              }}
              key={footnote.id}
            >
              <span style={{ marginRight: "5px" }}>
                {footnote.prefix || ""}{" "}
              </span>
              <span style={{ display: "inline" }}>
                {renderCitatedHtml(footnote.text)}
              </span>
            </div>
          );
        })}
      </div>
    );
  };
  const renderSupportingClaimsAndVisuals = (claimsAndVisuals) => {
    return (
      <div
        className="main-content"
        style={{ margin: "50px", display: "contents" }}
      >
        {claimsAndVisuals?.map((el) => {
          if (el.componentType === "supportingClaim") {
            let footnotes = [];
            if (el.supportingTextComponents) {
              footnotes = el.supportingTextComponents.filter((el) => {
                return el.componentType.toLowerCase() === "footnote";
              });
            }

            return (
              <>
                <div
                  className="claim-or-caption-text"
                  style={{ fontSize: "14px", margin: "10px 0px" }}
                  key={el.id}
                >
                  {parse(el.claim || "")}
                </div>
                {renderFootnotes(footnotes)}
              </>
            );
          } else if (el.componentType === "supportingVisual") {
            return renderSupportingVisual(el);
          }
          return <></>;
        })}
      </div>
    );
  };
  const renderCaptions = (captions, isLast) => {
    if (isLast && captions.length > 0) {
      return (
        <>
          {captions.map((caption) => {
            return (
              <div
                className="claim-or-caption-text"
                style={{ fontSize: "14px", margin: "10px 0px" }}
                key={caption.id}
              >
                {parse(caption.text || "")}
              </div>
            );
          })}
        </>
      );
    } else if (!isLast && captions.length > 0) {
      const jsx = (
        <div
          className="claim-or-caption-text"
          style={{ fontSize: "14px", margin: "10px 0px" }}
          key={captions[0].id}
        >
          {parse(captions[0].text || "")}
        </div>
      );

      return jsx;
    }
  };

  const renderSupportingTexts = (supportingTexts, type) => {
    return (
      <div className="mt-3">
        {type && <span className="head-lhs">{type} : </span>}
        {supportingTexts?.map((supText) => {
          return (
            <div
              className="supporting-text"
              style={{
                fontSize: "12px",
                margin: "10px 0px",
                display: "flex",
              }}
              key={supText.id}
            >
              <span style={{ marginRight: "5px" }}>
                {supText.prefix || ""}
                {type && type.trim().toLowerCase() === "references"
                  ? "."
                  : " ="}
              </span>
              <span style={{ marginRight: "5px", display: "inline" }}>
                {renderCitatedHtml(supText.text)}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderOptOut = (optOut) => {
    // let elements = optOut.moduleChannelSubtexts.filter((el) => {
    //   return el.subtextType.toLowerCase().trim() === "optouttext";
    // });
    const elements = optOut.moduleChannelSubtexts || [];
    let visual = elements.find(
      (el) => el.subtextType.toLowerCase().trim() === "optoutvisual"
    );
    let text = elements.find(
      (el) => el.subtextType.toLowerCase().trim() === "optouttext"
    );
    return (
      <Row
        className={`${visual ? "marketing-msg-optout" : ""}`}
        key={optOut.id || optOut.cmDocumentNumber}
      >
        {visual && (
          <Col className="col-md-3">
            <img src={visual.text} alt="" className="img-fluid"></img>
          </Col>
        )}

        <Col
          className={`${
            visual ? "col-md-8" : "col-md-12"
          } claim-or-caption-text`}
          style={{ fontSize: "14px", margin: "10px 0px" }}
          key={text.id}
        >
          {parse(text.text || "")}
        </Col>
      </Row>
    );
  };

  const renderCtas = (ctas) => {
    return (
      <div className="comp-ctas">
        <div className="head-lhs claim-or-caption-text"> CTAs : </div>
        {ctas.map((cta) => {
          const texts = cta.texts;
          return (
            <div className="claim-or-caption-text">
              {texts && texts.length > 0 && (
                <div>
                  {/* CTA Label : */}
                  {texts.map((text) => {
                    return <div>{text.text !== "null" ? text.text : ""}</div>;
                  })}
                </div>
              )}
              {/* Text Element Content : */}
              {/* <div>{cta.text !== "null" ? cta.text : ""}</div> */}
              <div>{cta.url !== "null" ? cta.url : ""}</div>
            </div>
          );
        })}
      </div>
    );
  };
  const getModuleSectionInTemplate = (data, sectionBgStyle) => {
    const module = data.bodyModules.find((module) => {
      const section = `Module-${module.bodyModuleSection}`;
      return sectionBgStyle.includes(section);
    });
    if (module) {
      if (
        !module.stylizedContent ||
        module.stylizedContent ===
          i18next.t("stylisedModule.stylisedVersionNotAvilable")
      ) {
        const moduleJsx = renderOriginalModuleData(module);
        return renderToString(moduleJsx);
      } else {
        return module.stylizedContent;
      }
    }
    return undefined;
  };
  const getReferenceList = (refdata, bgStyle) => {
    if (bgStyle.includes("Ref.svg")) {
      let refGlobalList = [];
      const reflist = refdata.bodyModules.forEach((item) =>
        item.references.map((data) => {
          const index = refGlobalList.findIndex(
            (object) => object.text === data.text
          );
          if (index === -1) {
            refGlobalList.push(data);
          }
        })
      );
      const olElement = document.createElement("ol");
      olElement.style.fontSize = "12px";
      olElement.className = isCompositeAsset
        ? "compViewContainer"
        : "module-preview";
      olElement.setAttribute("type", "1");
      refGlobalList &&
        refGlobalList.forEach((item) => {
          const li = document.createElement("li");
          li.innerText = item.text;
          olElement.appendChild(li);
        });
      return olElement.outerHTML;
    }
    return undefined;
  };
  const getOptoutSectionInTemplate = (data, bgStyle) => {
    if (bgStyle.includes("Opt-out.svg")) {
      let optOuts = [];
      let optOutVisual = [];
      const channelAttributes = data.marketingMessages;
      if (channelAttributes) {
        optOuts = channelAttributes.optOuts || [];
        optOuts = optOuts.map((optout) => {
          if (optout.moduleChannelSubtexts?.length > 0) {
            optout.moduleChannelSubtexts[0]["subtextType"] = "OptOutText";
          }

          return optout;
        });
        optOutVisual =
          channelAttributes.optOuts[0].moduleChannelSubtexts.filter(
            (optout) => {
              return optout.subtextType === "OptOutVisual";
            }
          );
      }
      const moduleJsx = renderToString(
        optOuts && optOuts.length > 0 && (
          <div
            className={`${
              isCompositeAsset ? "compViewContainer" : "module-preview"
            } `}
            style={{ fontSize: "12px" }}
          >
            {optOutVisual?.map((visual) => {
              return (
                <img
                  src={visual.text}
                  alt="Opt-out Visual"
                  className="img-fluid"
                />
              );
            })}
            {optOuts?.map((optOut) => {
              const elements = optOut.moduleChannelSubtexts || [];
              let text = elements.find(
                (el) => el.subtextType.toLowerCase().trim() === "optouttext"
              );
              return parse(text?.text || "");
            })}
          </div>
        )
      );
      return moduleJsx;
    }
  };
  const removeEmptySectionsInTemplate = (bgStyle, div) => {
    const regex = new RegExp("Module-[a-zA-Z]");
    if (
      regex.test(bgStyle) ||
      bgStyle.includes("Opt-out.svg") ||
      bgStyle.includes("Ref.svg")
    ) {
      div.bgStyle = "";
      div.style.display = "none";
    }
  };
  const renderTemplate = (data, html) => {
    let temp = document.createElement("template");
    html = html?.trim(); // Never return a space text node as a result
    temp.innerHTML = html;
    const allDivs = temp.content.querySelectorAll("div");
    allDivs.forEach((div) => {
      const bgStyle = div.style.background;
      let content = getModuleSectionInTemplate(data, bgStyle);
      if (!content) {
        content = getOptoutSectionInTemplate(data, bgStyle);
      }
      if (!content) {
        content = getReferenceList(data, bgStyle);
      }
      if (content) {
        div.innerHTML = content;
        div.style = "";
      }
      if (!content) {
        removeEmptySectionsInTemplate(bgStyle, div);
      }
    });
    // temp = `<html> ${temp.innerHTML}</html>`;
    let outerHtml = temp.outerHTML
      .replaceAll("<template>", "<!DOCTYPE html>")
      .replaceAll("</template>", "</html>")
      //.replaceAll("font-size:0px;", "")
      .replaceAll("text-align:center;", "");
    setHtmlData(outerHtml);
  };
  const renderOriginalModuleData = (el) => {
    const primaryClaim = el.claims?.find((claim) => {
      return claim.type.toLowerCase() === "primary";
    });
    let supportingClaims = el.claims?.filter((claim) => {
      return claim.type.toLowerCase() === "supporting";
    });
    supportingClaims = supportingClaims?.map((el) => {
      return { ...el, componentType: "supportingClaim" };
    });
    let supportingVisuals = el.visuals || [];
    supportingVisuals = supportingVisuals?.map((el) => {
      return { ...el, componentType: "supportingVisual" };
    });
    // supportingClaims =
    //   sortClaimsAndVisualsByRecordIndex(supportingClaims);
    // supportingVisuals =
    //   sortClaimsAndVisualsByRecordIndex(supportingVisuals);
    let supportingClaimsAndVisuals = supportingClaims.concat(supportingVisuals);
    supportingClaimsAndVisuals = sortClaimsAndVisualsByRecordIndex(
      supportingClaimsAndVisuals
    );

    const references = el.references.sort((record1, record2) => {
      return parseInt(record1.prefix) < parseInt(record2.prefix) ? -1 : 1;
    });
    let abbreviations = sortClaimsAndVisualsByRecordIndex(el.abbreviations);
    const ctas = el.ctas;
    const headlines = el.headLines;
    const copies = el.copies;
    const summaries = el.summaries;
    const teasers = el.teasers;
    return (
      <div
        className={`${
          isCompositeAsset ? "compViewContainer" : "module-preview"
        }`}
      >
        {primaryClaim && renderprimaryClaim(primaryClaim)}
        {headlines && renderCopyHeadLineTeaserSummury(headlines)}
        {teasers && renderCopyHeadLineTeaserSummury(teasers)}
        {summaries && renderCopyHeadLineTeaserSummury(summaries)}
        {copies && renderCopyHeadLineTeaserSummury(copies)}

        {renderSupportingClaimsAndVisuals(supportingClaimsAndVisuals || [])}

        {abbreviations.length > 0 && renderSupportingTexts(abbreviations || [])}
        {sourceType?.toLowerCase() === damsSources.veeva.name.toLowerCase() &&
          assetDataType.toLowerCase() === "modularcontent" &&
          ctas &&
          ctas.length > 0 &&
          renderCtas(ctas)}

        {/* {references.length > 0 &&
          renderSupportingTexts(references || [], "References")} */}
      </div>
    );
  };

  const renderCopyHeadLineTeaserSummury = (data) => {
    return (
      <div className="main-content">
        {data.map((item, index) => {
          return (
            <div
              className="claim-or-caption-text"
              style={{ fontSize: "14px", margin: "10px 0px" }}
              key={"pclaim" + index}
            >
              {htmlToString(item.text)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderData = () => {
    if (
      !compositeAssetData.bodyModules ||
      compositeAssetData.bodyModules.length === 0
    ) {
      return;
    }
    const jsx = (
      <div className="view-composite-asset">
        <div>
          {data?.subjectLineText && (
            <div className="head">
              <span className="head-lhs pr-1">Subject Line : </span>
              {htmlToString(data?.subjectLineText)}
            </div>
          )}
          {data?.preHeaderText && (
            <div className="head">
              <span className="head-lhs pr-1">Pre-Header : </span>
              {htmlToString(data?.preHeaderText)}
            </div>
          )}
        </div>
        <div
          id="content"
          className={` ${
            isCompositeAsset ? "body-content customScrollBar" : ""
          }`}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: htmlData,
            }}
          ></div>
          {/* {compositeAssetData.bodyModules.map((el, index) => {
            if (el.stylizedContent) {
              return renderStylisedModuleData(el.stylizedContent);
            }
            return renderOriginalModuleData(el, index);
          })}*/}
          {/* {
            <div
              className={`${
                isCompositeAsset ? "compViewContainer" : "module-preview"
              } `}
            >
              {globalOptOuts?.map((optOut) => {
                return renderOptOut(optOut);
              })}
            </div>
          } */}
        </div>
      </div>
    );
    if (!pageJsx) {
      setPageJsx(jsx);
    }
    return jsx;
  };
  return (
    <>
      {isloading && <CircularProgressBar isloading={isloading} />}{" "}
      {renderData()}
    </>
  );
};

export default CompositeAssetView;
