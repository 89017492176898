import React from "react";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import { Row, Col } from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../styles/components/emailHeader.scss";
const EmailHeaderElement = ({
  upDateHeaderText,
  row,
  rowIndex,
  removeEmailHeaderRow,
  upDateHeaderRestrictions,
  upDateHeaderRules,
  addEmailHeaderRow,
  emailHeaderLength,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="mt-4 align-items-center">
        <Col xs md="4" lg="4">
          <div className="emailHeaderBox custonScrollBar">
            {row && row && row.headerText && row.headerText !== "" ? (
              <span className="editorHeaderActive-heading d-block mt-2">
                {t("emailHeaders.headerTitle")}
              </span>
            ) : (
              <span className="selectBoxText">
                {t("emailHeaders.headerText")}
              </span>
            )}
            <FroalaEditor
              tag="textarea"
              selectedAtom={row.headerText}
              handleModelChange={(event) => {
                upDateHeaderText(event, rowIndex);
              }}
            />
          </div>
        </Col>
        <Col xs md="4" lg="4">
          <div className="emailHeaderBox">
            {row &&
            row &&
            row.restrictionForUse &&
            row.restrictionForUse !== "" ? (
              <span className="editorHeaderActive-heading d-block mt-2">
                {t("emailHeaders.restrictionTitle")}
              </span>
            ) : (
              <span className="selectBoxText">
                {t("emailHeaders.restriction")}
              </span>
            )}
            <FroalaEditor
              tag="textarea"
              selectedAtom={row.restrictionForUse}
              handleModelChange={(event) => {
                upDateHeaderRestrictions(event, rowIndex);
              }}
            />
          </div>
        </Col>
        <Col xs md="4" lg="4">
          <div className="emailHeaderBox">
            {row && row && row.rules && row.rules !== "" ? (
              <span className="editorHeaderActive-heading d-block mt-2">
                {t("emailHeaders.rulesTitle")}
              </span>
            ) : (
              <span className="selectBoxText">{t("emailHeaders.rules")}</span>
            )}
            <FroalaEditor
              tag="textarea"
              selectedAtom={row.rules}
              handleModelChange={(event) => {
                upDateHeaderRules(event, rowIndex);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="no-gutters mt-1 buttonHeaderRow">
        <Col xs className="col-md-6 emailHeaderRowDivider">
          <button
            className="btn btn-link pl-0"
            onClick={(e) => {
              removeEmailHeaderRow(rowIndex);
            }}
          >
            <span>{i18next.t("emailHeaders.remove")}</span>
          </button>
        </Col>
        <Col xs className="col-md-6 text-right emailHeaderRowDivider">
          {emailHeaderLength.length === rowIndex + 1 ? (
            <button
              className="btn btn-link pr-0"
              style={{ float: "right" }}
              onClick={() => {
                addEmailHeaderRow();
              }}
            >
              <span>{i18next.t("emailHeaders.add")}</span>
            </button>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
};
export default EmailHeaderElement;
