import React, { useState } from "react";
import closeButton from "../../images/close_with_bg.svg";
import "../../styles/components/ViewBrandStyleGuidelines.scss";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

const ViewBrandStyleGuideline = ({ brandStyleGuideline, closeView }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };
  const [totalPages, setTotalPages] = useState(0);
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setTotalPages(nextNumPages);
  }
  return (
    <div className="view-brand-style-guideline-container">
      <div className="d-flex align-items-center view-brand-style-guideline-head">
        <span className="mr-auto text-uppercase">Brand Style Guidelines</span>
        <img
          src={closeButton}
          alt="close"
          className="btn-close float-right"
          style={{ cursor: "pointer" }}
          height="32"
          onClick={() => closeView(true)}
        />
      </div>
      <div className="divider"></div>
      <div>
        <div
          id="view-brand-style-guideline"
          className="brand-style-guideline customScrollBar"
        >
          <Document
            file={brandStyleGuideline}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(totalPages), (el, index) => (
              <div className="react-pdfPage" key={`page_${index + 1}`}>
                <Page pageNumber={index + 1} />
              </div>
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default ViewBrandStyleGuideline;
