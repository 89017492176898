import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import "../styles/pages/CreateModuleStep2.scss";
import "../styles/components/SupportingVisual.scss";
import Switch from "@material-ui/core/Switch";
import arrowpolygonRight from "../images/arrowpolygon-right.svg";
import BussinessRules from "../Uitls/CommonFroalEditorComponent";
const AddReusabletextRow = (props) => {
  const { t } = useTranslation();
  const {
    row,
    rowIndex,

    reusableTextAdded,
    setReusableTextAdded,
    reusBussinessRules,
    setReusBussinessRules,
    deleteReusabletextRow,
  } = props;
  // const [box1, box2, box3, box4] = row;

  const handleBussinessRulesToggle = (index) => {
    setReusableTextAdded((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = {
        ...newArray[index],
        reusMustUse: !newArray[index].reusMustUse,
      };
      return newArray;
    });
  };

  const handleChange = (event, index) => {
    const value = event.target.value;

    setReusableTextAdded((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = {
        ...newArray[index],
        reusBussinessRules: value,
      };
      return newArray;
    });
  };

  return (
    <>
      {reusableTextAdded
        ?.slice()
        .sort((a, b) => parseInt(a.record_index) - parseInt(b.record_index))
        .map((item, index) => {
          return (
            <>
              <div className="row mt-5">
                <div className="col ">
                  <div className="d-flex">
                    <img
                      src={arrowpolygonRight}
                      alt=""
                      className="arrowCurrentRow  arrowresuabletext mr-1"
                    />
                    <span className="catLabelTitle">
                      {t("reusableText.reusableText")}
                      {index + 1}
                      {/* {String(supportingClaimPosition + 1)} */}
                    </span>
                    <span className="catLabelType">|</span>
                    <span className="catLabelType">
                      Type: {item.reusableTextType}
                    </span>
                    <span className="catLabelType">|</span>
                    <div className="disabled-musttoggle">
                      Must Use
                      <Switch
                        className="filterRightMargin  reusabletextToggle mr-2"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        checked={item.reusMustUse}
                        onChange={() => {
                          handleBussinessRulesToggle(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <DeleteIcon
                    color="primary"
                    onClick={(e) => {
                      deleteReusabletextRow(item, index);
                    }}
                  />
                </div>
              </div>
              <Row className="mt-4 align-items-center">
                <Col
                  id={`reusable-text${rowIndex}`}
                  className={
                    props.displaySupportingAlert && row[0].isEmpty
                      ? "selectclaimBlockAlert custonScrollBar col-md-6"
                      : "selectclaimBlock custonScrollBar col-md-7"
                  }
                >
                  <span class="editorActive-heading d-block mt-2 pt-2 mb-3">
                    {t("Description")}
                  </span>

                  <div className="supportingClaimEditor">
                    {item?.reusableTextType === "CTA" ? (
                      <div>
                        {(item.reusableTextLabels &&
                          item.reusableTextLabels.length > 0) ||
                        (item.labels && item.labels.length > 0) ? (
                          <>
                            {item.reusableTextLabels
                              ? item.reusableTextLabels.map((label, index) => (
                                  <p key={index}>
                                    Label {index + 1} : {label.ctaLabel}
                                  </p>
                                ))
                              : item.labels &&
                                item.labels.map((label, index) => (
                                  <p key={index}>
                                    Label {index + 1} : {label}
                                  </p>
                                ))}
                          </>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    ) : item?.description ? (
                      <p>{item.description}</p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </Col>
                <Col
                  id={`reusable-text${rowIndex}`}
                  className={
                    props.displaySupportingAlert && row[0].isEmpty
                      ? "selectclaimBlockAlert custonScrollBar col-md-2"
                      : "selectclaimBlock custonScrollBar col-md-2"
                  }
                  // onChange={handleAlertRemove}
                >
                  {/* {row && row[0]?.data?.length !== 0 && row[0]?.data !== "" ? ( */}
                  <span class="editorActive-heading d-block mt-2 pt-2 mb-3">
                    {t("reusableText.link")}
                  </span>

                  {/* ) : (
            ""
          )} */}
                  <div className="supportingClaimEditor">
                    {" "}
                    {item?.link ? item?.link : "-"}
                  </div>
                </Col>
                <Col
                  id={`reusable-text${rowIndex}`}
                  className={
                    props.displaySupportingAlert && row[0].isEmpty
                      ? "selectclaimBlockAlert usageInformation  col-md-2"
                      : "selectclaimBlock usageInformation col-md-2"
                  }
                >
                  {/* {row && row[0]?.data?.length !== 0 && row[0]?.data !== "" ? ( */}
                  <span class="editorActive-heading d-block pt-2 mt-2 mb-3">
                    {t("reusableText.usageInstructions")}
                  </span>
                  <TextField
                    className="usageInfoinput"
                    id="moduleName"
                    variant="outlined"
                    fullWidth={true}
                    placeholder="Type here..."
                    InputProps={{
                      className: "usageInfo",
                    }}
                    multiline
                    rows={2}
                    onChange={(event) => handleChange(event, index)}
                    value={item.reusBussinessRules}
                  />
                  {/* ) : (
            ""
          )} */}
                  <div className="supportingClaimEditor"></div>
                </Col>
              </Row>{" "}
            </>
          );
        })}
    </>
  );
};
export default AddReusabletextRow;
