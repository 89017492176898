/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import "../../styles/components/DropDownWithSearch.scss";
import "../../styles/components/TemplateSelectionWithSearch.scss";
import searchIcon from "../../images/search.svg";

const TemplateSelection = ({ templates, handleChnage, togglePopoverOpen }) => {
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    togglePopoverOpen(true);
  }, []);
  const searchTemplates = (searchText) => {
    searchText = searchText.toLowerCase().trim();
    if (searchText.length === 0) {
      setFilteredTemplates(templates);
      return;
    }
    let data = JSON.parse(JSON.stringify(templates));
    data = data.filter((template) => {
      return (
        template.templateId.toLowerCase().includes(searchText) ||
        template.templateName.toLowerCase().includes(searchText)
      );
    });
    setFilteredTemplates(data);
  };

  return (
    <div id="template-selection-with-search">
      <div className="tf-search-templates">
        <div className="d-flex">
          <img src={searchIcon} alt="" className="searchIcon" />

          <input
            id="search-templates"
            type={`text`}
            placeholder="Search by template ID / template name"
            className="input-search-templates"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchTemplates(event.target.value);
            }}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="template-list customScrollBar pr-1">
        {filteredTemplates.map((template) => {
          return (
            <TemplateCard template={template} handleChnage={handleChnage} />
          );
        })}
      </div>
    </div>
  );
};

export default TemplateSelection;

const TemplateCard = ({ template, handleChnage }) => {
  return (
    <div
      class={`d-flex row template-card ${
        template.isSelected ? "template-card-selected" : ""
      }`}
      onClick={() => {
        handleChnage(template);
      }}
    >
      <div className="col-md-3 pl-0">
        <div className="label">Template ID</div>
        <div className="value">{template.templateId}</div>
      </div>

      <div className="col-md-4 pl-0">
        <div className="label">Template Name</div>
        <div className="value">{template.templateName}</div>
      </div>
      <div className="col-md-5 pl-0 pr-0">
        <div className="label">Approved on</div>
        <div className="value">{template.aprovalTimeStamp}</div>
      </div>
    </div>
  );
};
