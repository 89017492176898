//Import react dependencies
import React from "react";

//Import custom compnents
import TextContent from "./TextContent";

const Preheaders = ({ preheaders }) => {
  return (
    <div className="channel-attr-container customScrollBar">
      {preheaders &&
        preheaders.map((preheader, index) => {
          return (
            <div className="channel-attr-box">
              <div>
                <TextContent
                  heading={`${preheader.cmDocumentNumber || ""} | Preheader ${
                    index + 1
                  }`}
                  content={preheader.text}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Preheaders;
