import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const OverlayPopOver = ({
  element,
  overlayData,
  trigger,
  classId,
  placement,
}) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement ? placement : "auto"}
      rootClose={true}
      overlay={<Popover id={classId}>{overlayData}</Popover>}
    >
      {element}
    </OverlayTrigger>
  );
};

export default OverlayPopOver;
