import React from "react";

const LockUnlockAlertModal = ({ approvalData, handleYes, handleNo }) => {
  const handleClickNo = () => {
    handleNo();
  };
  const handleClickYes = () => {
    handleYes();
  };
  return (
    <div>
      <div
        className="d-flex justify-content-center"
        style={{ fontSize: "14px" }}
      >
        Do you want to{" "}
        {approvalData && approvalData.locked === "Locked" ? "Lock" : "Un-lock"}{" "}
        the{" "}
        {approvalData && approvalData.type === "Asset" ? "Tactic" : "Module"}?
      </div>
      <div>
        <button
          type="button"
          className="btn btn-link closeAnchor"
          style={{
            marginLeft: "0px",
            fontSize: "12px",
            float: "left",
            marginTop: "20px",
            fontWeight: "600",
          }}
          onClick={handleClickNo}
        >
          NO
        </button>
        <button
          type="button"
          className="btn btn-link closeAnchor"
          style={{
            float: "right",
            marginRight: "0px",
            marginTop: "20px",
            fontWeight: "600",
            fontSize: "12px",
          }}
          onClick={handleClickYes}
        >
          YES
        </button>
      </div>
    </div>
  );
};
export default LockUnlockAlertModal;
