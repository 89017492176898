import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AssetCard from "./CommonAssetCard";
import { getAssetsStatus, retrieveTacticsToMM } from "../../services/apis";
import { addProcessIds } from "../../Uitls/polling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
const SearchResults = ({
  assets,
  clearAllSearch,
  titleType,
  redirectToSearchInMM,
  selectedDamsSource,
  getAssetDataType,
}) => {
  const [tacticsToBeRetrieved, setTacticsToBeRetrieved] = useState([]);
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    if (assets.length === 0) {
      setTacticsToBeRetrieved([]);
    }
  }, [assets]);
  const onSelectTactic = (tactic) => {
    if (tactic.tacticRetrievedFlag) {
      return;
    }
    const index = tacticsToBeRetrieved.findIndex((item) => {
      return item.externalAssetId === tactic.externalAssetId;
    });
    if (index < 0) {
      let finalTactic = {
        externalAssetId: tactic.externalAssetId,
        assetName: tactic.assetName,
        assetThumbnailUrl: tactic.assetThumbnailUrl,
        brand: tactic.brand,
        assetDataType: getAssetDataType(),
      };
      let newArray = [...tacticsToBeRetrieved].concat(finalTactic);
      setTacticsToBeRetrieved(newArray);
    } else if (index > -1) {
      setTacticsToBeRetrieved(
        [...tacticsToBeRetrieved].filter((item) => {
          return item.externalAssetId !== tactic.externalAssetId;
        })
      );
    }
  };
  const retrieveTactics = () => {
    setIsloading(true);
    retrieveTacticsToMM(
      tacticsToBeRetrieved,
      (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          retrieveTacticsErr(errCoponent);
          setIsloading(false);
          setTacticsToBeRetrieved([]);
        } else {
          let filteredData = response.data.filter(function (item) {
            if (item.tacticRetrievedFlag === false) {
              return true;
            }
          });
          const sucessAssetName = filteredData.map((item) => item.assetName);

          let duplicatedAssetIdList = response.data.filter(function (item) {
            if (item.tacticRetrievedFlag === true) {
              return true;
            }
          });
          const duplicatedAssetName = duplicatedAssetIdList.map(
            (item) => item.assetName
          );
          getInProgressIds();
          setIsloading(false);
          retrieveTacticsSuccess(sucessAssetName, duplicatedAssetName);
          setTimeout(() => {
            setTacticsToBeRetrieved([]);
            clearAllSearch(true);
            redirectToSearchInMM(true);
          }, 5000);
        }
      },
      selectedDamsSource?.sourceType?.toLowerCase()
    );
  };
  const retrieveTacticsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const retrieveTacticsSuccess = (sucessAssetName, duplicatedAssetName) =>
    toast.success(
      <>
        {duplicatedAssetName &&
        duplicatedAssetName.length > 0 &&
        sucessAssetName &&
        sucessAssetName.length < 1 ? (
          ""
        ) : (
          <p className="harvesetedToastTitle mb-0">Retrieval Process Started</p>
        )}

        {sucessAssetName && sucessAssetName.length > 0 ? (
          <>
            <p className="retrievalStatusText mb-0">
              Retrieval process for the selected tactics
            </p>
            {sucessAssetName &&
              sucessAssetName.map((item, index) => (
                <p
                  className="retrievalStatusText mb-0"
                  style={{ wordBreak: "break-all" }}
                  key={index}
                >
                  {item}
                </p>
              ))}
            <p className="retrievalStatusText">
              has started. You will be notified once the same are available in
              Module Manager
            </p>
          </>
        ) : (
          ""
        )}

        {duplicatedAssetName && duplicatedAssetName.length > 0 ? (
          <>
            {duplicatedAssetName &&
              duplicatedAssetName.map((item, index) => (
                <p
                  className="retrievalStatusText  mb-0"
                  style={{ wordBreak: "break-all" }}
                  key={index}
                >
                  {item}
                </p>
              ))}
            <p className="retrievalStatusText">
              Already exists! The same tactic cannot be downloaded again.
            </p>
          </>
        ) : (
          ""
        )}
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0 text-right"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: false,
      }
    );
  const getInProgressIds = () => {
    getAssetsStatus((response, err, errorCode) => {
      if (err) {
        console.log(err);
      } else {
        addProcessIds(response.data);
      }
    });
  };
  return (
    <>
      <h2 className="masterTacticDamsTitle" style={{}}>
        {/* {titleType === "initial" ? `All Tactic` : "Search Results"}{" "} */}
        All Tactics
      </h2>
      <p className="search-itemsDams">
        {assets.length} {assets.length === 1 ? "Item" : "Items"}
      </p>
      <CircularProgressBar isloading={isloading} />
      <div className="row master-tactic-dams custonScrollBar">
        {assets.map((tactic, index) => {
          return (
            <AssetCard
              key={index}
              tactic={tactic}
              onSelected={onSelectTactic}
              tacticsToBeRetrieved={tacticsToBeRetrieved}
            />
          );
        })}
      </div>
      <div className="row">
        <div
          className="col-md-6"
          style={{ borderTop: "solid 1px #e1e1e1", paddingTop: "30px" }}
        >
          <span style={{ fontSize: "12px", color: "#666" }}>
            {tacticsToBeRetrieved.length} Item(s) selected
          </span>
        </div>
        <div className="col-md-6" style={{ borderTop: "solid 1px #e1e1e1" }}>
          <button
            className="btn btn-primary btn-retreive"
            disabled={
              tacticsToBeRetrieved && tacticsToBeRetrieved.length > 0
                ? false
                : true
            }
            onClick={() => {
              retrieveTactics();
            }}
          >
            RETRIEVE TACTICS
          </button>
        </div>
      </div>
    </>
  );
};
export default SearchResults;
