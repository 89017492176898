import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getNotifications,
  getNotificationsReadStatus,
} from "../../services/apis";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { routes } from "../../Uitls/Util";

import Imc from "../../images/NEXT-Module-logo.svg";
import BellActive from "../../images/bell_active.svg";
import Bell from "../../images/bell.svg";
import successTick from "../../images/translateTick.svg";

import "../../styles/components/Headers/HeaderDefaultDashboard.scss";

function HeaderTranslateModule(props) {
  let history = useHistory();
  const [isloading, setIsloading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationActive, setNotificationActive] = useState(false);
  const [userType, setUserType] = useState("");

  const getNotificationsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getNotificagtionsData = () => {
    setIsloading(true);
    getNotifications((response, err, errCode) => {
      if (err) {
        setIsloading(false);
        const errCoponent = ErrComponentToRender(errCode);
        getNotificationsErr(errCoponent);
      } else {
        setIsloading(false);
        setNotificationData(response.data);
      }
    });
  };

  const notificationsReadStatus = (id) => {
    getNotificationsReadStatus(id, (response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        getNotificationsErr(errCoponent);
      } else {
        if (userType.includes("users")) {
          history.push({
            pathname: routes.reviewerDashboard,
          });
        } else {
          history.push({
            pathname: routes.translatorDashboard,
          });
        }
        getNotificagtionsData();
      }
    });
  };

  const tranformDueDate = (inputDate) => {
    let result = "";
    if (inputDate) {
      let parts = inputDate.split("-");
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return result;
  };

  const notifyToaster = (value) => {
    toast.success(
      <>
        {userType && userType.includes("users") ? (
          <p className="notificationTitle mb-2">
            <img src={successTick} alt="" class="mr-1" />
            Translation Complete
          </p>
        ) : (
          <p className="notificationTitle mb-2">Translation Job Received</p>
        )}

        <div className="notificationsScroll customScrollBar">
          {notificationData.map((item) => {
            return (
              <div key={item.id}>
                <button
                  className="btn btn-link mb-2 pl-0 pt-0"
                  onClick={() => {
                    toast.dismiss();
                    setNotificationActive(false);
                    notificationsReadStatus(item.id);
                  }}
                >
                  <span>
                    Job ID: <strong>{item.jobCode}</strong>
                  </span>
                  <span>
                    {item.assignedToName && (
                      <span>
                        Assigned to : <strong>{item.assignedToName}</strong>
                      </span>
                    )}
                  </span>
                  <span>
                    Due Date: <strong>{tranformDueDate(item.dueDate)}</strong>
                  </span>
                </button>
              </div>
            );
          })}
        </div>

        <div className="btn-close-toast">
          {/* <button className="btn btn-link pl-0 btn-close-toast pr-0">
              Get Started
            </button> */}
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0"
            onClick={() => {
              toast.dismiss();
              setNotificationActive(false);
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        className: "notificationToast",
        limit: 1,
      }
    );
  };

  useEffect(() => {
    setUserType(window.localStorage.getItem("userRole"));
    getNotificagtionsData();
  }, []);

  const showNotificaions = () => {
    if (notificationData.length === 0 || notificationActive === true) {
      return;
    }
    setNotificationActive(true);
    notifyToaster();
  };

  return (
    <>
      <CircularProgressBar />
      <header className="navbar flex-column flex-md-row pt-0 pb-0">
        <div className="logoCol">
          {!props.logoNotRequired && (
            <img src={Imc} alt="IMC - Module Manager" />
          )}
        </div>

        <div className="alertIconCol">
          <button
            className="btn btn-link pr-0 position-relative"
            type="button"
            onClick={showNotificaions}
          >
            {notificationData.length > 0 ? (
              <img src={BellActive} alt="Alert" width="16" />
            ) : (
              <img src={Bell} alt="Alert" width="16" />
            )}
          </button>
        </div>
      </header>
    </>
  );
}

export default HeaderTranslateModule;
