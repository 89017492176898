import React, { useEffect, useState } from "react";
import ImcLogo from "../../images/imcMmLogo.svg";
import alertIcon from "../../images/alertIcon.svg";
import BellActive from "../../images/bell_active.svg";
import Bell from "../../images/bell.svg";

function HeaderTranslateModule(props) {
  return (
    <>
      <header className="navbar flex-column flex-md-row pt-0 pb-0">
        <div className="">
          <img src={ImcLogo} alt="IMC - Module Manager" width="46" />
        </div>

        <div className="alertIconCol">
          <button className="btn btn-link pr-0" type="button">
            <img src={Bell} alt="Alert" width="16" />
          </button>
        </div>
      </header>
    </>
  );
}

export default HeaderTranslateModule;
