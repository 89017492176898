import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import "../styles/pages/CreateModuleStep4.scss";
import AddCatchline from "./AddCatchline";
import EmailSubjectLine from "../components/EmailSubjectLine";
import EmailHeader from "./emailHeader";
import { Col, Row } from "react-bootstrap";
import Atoms from "./Atoms";
import Optouts from "./Optouts";
const Email = (props) => {
  const [preHeader, setPreHeader] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [subjectLines, setSubjectLines] = useState([]);
  const [isNavSubjrctLine, setIsNavSubjrctLine] = useState(true);
  const [headerEmail, setHeaderEmail] = useState([]);
  const [emailHeaders, setEmailHeaders] = useState([]);
  const [isNavHeader, setIsNavHeader] = useState(false);
  const [isOptout, setIsOptOut] = useState(false);
  const [optOuts, setOptouts] = useState([]);
  const [selectedEmailElement, setSelectedEmailElement] = useState("dams");
  const [emailType, setEmailType] = useState(undefined);
  const [atomToBeUsed, setAtomToBeUsed] = useState(undefined);
  useEffect(() => {
    setAppropriateCssClass(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (props.emailElements) {
      setSubjectLines(props.emailElements.subjectLines);
      setPreHeader(props.emailElements.preheaders);
      setHeaderEmail(props.emailElements.emailHeaders);
      const emailHeaders = removeEmptyObjects(props.emailElements.emailHeaders);
      setEmailHeaders(emailHeaders);
      setOptouts(props.emailElements.optOut);
    }
  }, [props]);
  const removeEmptyObjects = (newArray) => {
    let data = [...newArray];
    let idices = [];
    data.forEach((element, index) => {
      if (
        element.headerText === "" &&
        element.restrictionForUse === "" &&
        element.rules === ""
      ) {
        return idices.push(index);
      }
    });
    let filteredData = data.filter((val, index) => !idices.includes(index));
    return filteredData;
  };
  const setAppropriateCssClass = (index, parentType) => {
    var emailSections = document.getElementById("email-sections");
    var spans = emailSections.getElementsByClassName("catclineTitle");
    for (var i = 0; i < spans.length; i++) {
      var current = document.getElementsByClassName(
        "selected-email-segmented-control"
      );
      if (current && current.length > 0) {
        current[0].className = current[0].className.replace(
          "selected-email-segmented-control",
          "non-selected-segmented-control"
        );
      }
    }
    spans[index].className = "catclineTitle selected-email-segmented-control";
  };
  const onSelect = (type) => {
    let index = 0;
    if (type === "preheader") {
      index = 1;
      setIsNavSubjrctLine(false);
      setIsNavHeader(false);
      setIsOptOut(false);
    } else if (type === "subjectline") {
      setIsNavSubjrctLine(true);
      setIsNavHeader(false);
      setIsOptOut(false);
    } else if (type === "header") {
      index = 2;
      setIsNavHeader(true);
      setIsNavSubjrctLine(false);
      setIsOptOut(false);
    } else if (type === "optout") {
      index = 3;
      setIsNavSubjrctLine(false);
      setIsNavHeader(false);
      setIsOptOut(true);
    }
    reset();
    setSelectedIndex(index);
  };
  const onPreHeaderChange = (preHeader) => {
    setPreHeader(preHeader);
    //update parent
    const emailData = {
      subjectLines: subjectLines,
      preheaders: preHeader,
      emailHeaders: emailHeaders,
      optOut: optOuts,
    };
    updateParent(emailData);
  };
  const onSubjectLineChange = (data) => {
    setSubjectLines(subjectLines.concat(data));
    const emailData = {
      subjectLines: subjectLines.concat(data),
      preheaders: preHeader,
      emailHeaders: headerEmail,
      optOut: optOuts,
    };
    updateParent(emailData);
  };

  const updateParent = (data) => {
    if (props.onDataChange) {
      props.onDataChange(data);
    }
  };
  const removeSubjectLineEmail = (item, indexToBeRemoved) => {
    if (subjectLines.length > 0) {
      const tempArray = subjectLines.filter((catchline, index) => {
        return index !== indexToBeRemoved;
      });
      if (item.id && item.id !== "" && props.onDeleteEmail) {
        props.onDeleteEmail([item.id]);
      } else {
        setSubjectLines(tempArray);
        const emailData = {
          subjectLines: tempArray,
          preheaders: preHeader,
          emailHeaders: headerEmail,
          optOut: optOuts,
        };
        updateParent(emailData);
      }
    }
  };

  const removePreheader = (preheader) => {
    if (props.onDeleteEmail) {
      props.onDeleteEmail([preheader.id]);
    }
  };

  const upDateEmailHeaderData = (data) => {
    setHeaderEmail(data);
    const emailData = {
      subjectLines: subjectLines,
      preheaders: preHeader,
      emailHeaders: data,
      optOut: optOuts,
    };
    updateParent(emailData);
  };

  const handleEmailElementsOptionChange = (event) => {
    setSelectedEmailElement(event.target.value);
    //update the Atoms enable/disable flag based value
  };
  const handleAtomDoubleClick = (atom) => {
    setAtomToBeUsed(atom);
  };
  const reset = () => {
    setAtomToBeUsed(undefined);
    setEmailType(undefined);
  };
  const upDateOptOutData = (data) => {
    setOptouts([].concat(data));
    const emailData = {
      subjectLines: subjectLines,
      preheaders: preHeader,
      emailHeaders: emailHeaders,
      optOut: data,
    };
    updateParent(emailData);
  };
  return (
    <div className="emails">
      <Form>
        {["radio"].map((type) => (
          <div
            key={`inline-${type}`}
            className="form-check form-check-inline mb-2"
          >
            <span className="title mr-5">Email Elements</span>
            <Form.Check
              inline
              label="DAMS"
              name="emailElementsOption"
              type={type}
              id={`inline-${type}-1`}
              checked={selectedEmailElement === "dams"}
              value="dams"
              onChange={handleEmailElementsOptionChange}
            />
            <Form.Check
              inline
              label="Custom Content"
              name="emailElementsOption"
              type={type}
              id={`inline-${type}-2`}
              value="customContent"
              checked={selectedEmailElement === "customContent"}
              onChange={handleEmailElementsOptionChange}
            />
          </div>
        ))}
      </Form>

      <div id="email-sections" className="section-head email-sections">
        <span
          className="catclineTitle selected-email-segmented-control"
          onClick={() => {
            onSelect("subjectline");
          }}
        >
          {(subjectLines && subjectLines.length) <= 0
            ? "Subject Line (0)"
            : `Subject Line (${subjectLines.length})`}
        </span>
        <span
          className="catclineTitle non-selected-segmented-control"
          onClick={() => {
            onSelect("preheader");
          }}
        >
          {preHeader.length <= 0
            ? "PreHeader (0)"
            : `PreHeader (${preHeader.length})`}
        </span>
        <span
          className="catclineTitle non-selected-segmented-control"
          onClick={() => {
            onSelect("header");
          }}
        >
          {emailHeaders.length <= 0
            ? "Header (0)"
            : `Header (${emailHeaders.length})`}
        </span>
        <span
          className="catclineTitle non-selected-segmented-control"
          onClick={() => {
            onSelect("optout");
          }}
        >
          {(optOuts && optOuts.length) <= 0
            ? "Opt-out (0)"
            : `Opt-out (${optOuts.length})`}
        </span>
      </div>
      <Row>
        <Col className="col-md-4">
          <Atoms
            shouldBeDisbaled={
              isNavHeader || selectedEmailElement === "customContent"
            }
            handleAtomDoubleClick={handleAtomDoubleClick}
            atomType={emailType === "OptOutVisual" ? "graphic" : "text"}
          />
        </Col>
        <Col className="col-md-8">
          {isNavSubjrctLine && (
            <EmailSubjectLine
              handleData={onSubjectLineChange}
              subjectLines={subjectLines}
              removeSubjectLineEmail={removeSubjectLineEmail}
              setEmailType={setEmailType}
              emailType={emailType}
              atomToBeUsed={atomToBeUsed}
              setAtomToBeUsed={setAtomToBeUsed}
            />
          )}
          {isNavHeader && (
            <EmailHeader
              upDateEmailHeaderData={upDateEmailHeaderData}
              emailHeadersData={props.emailElements.emailHeaders}
            />
          )}
          {!isNavSubjrctLine && !isNavHeader && !isOptout && (
            <AddCatchline
              onCatchlineChange={onPreHeaderChange}
              removeCatchline={removePreheader}
              catchlines={preHeader}
              placeholder={"Enter Pre header text"}
              setEmailType={setEmailType}
              emailType={emailType}
              atomToBeUsed={atomToBeUsed}
              emailElementFlag={true}
              setAtomToBeUsed={setAtomToBeUsed}
            />
          )}
          {isOptout && (
            <Optouts
              optoutData={optOuts}
              setEmailType={setEmailType}
              emailType={emailType}
              atomToBeUsed={atomToBeUsed}
              upDateOptOutData={upDateOptOutData}
              deleteOptOut={props.onDeleteEmail}
              setAtomToBeUsed={setAtomToBeUsed}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Email;
