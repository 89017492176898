//Import react dependencies
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

//Import From Util
import { damsSources } from "../../Uitls/Util";

const DamsSourceFilter = ({
  setSelectedDamsSource,
  retrieveSelectedDamsCountryList,
}) => {
  const [activePanel, setActivePanel] = useState({
    id: "",
    selectedOption: "",
  });

  const selectPanel = (selectedPanel) => {
    let tmp = {
      id: "",
      selectedOption: "",
    };
    if (activePanel.id !== selectedPanel) {
      tmp = {
        id: selectedPanel,
        selectedOption:
          damsSources[selectedPanel.toLowerCase()].options.length > 0
            ? damsSources[selectedPanel.toLowerCase()].options[0]
            : "",
      };
    }
    setActivePanel(tmp);
    if (tmp.id && tmp.selectedOption) {
      setSelectedDamsSource({
        sourceType: tmp.id,
        assetDataType: tmp.selectedOption,
      });
    } else {
      setSelectedDamsSource(undefined);
    }
  };
  return (
    <>
      <h1 className="filterBy">Source</h1>
      <Accordion>
        {Object.keys(damsSources)
          .filter((damsSource) => {
            return (
              damsSources[damsSource].name.toLowerCase() !==
              damsSources.aem.name.toLowerCase()
            );
          })
          .map((damsSource) => {
            const sourceType = damsSources[damsSource].name;
            const options = damsSources[damsSource].options;
            return (
              <div key={sourceType}>
                <div className="accordionTitle ">
                  <Accordion.Toggle
                    variant="link"
                    eventKey={sourceType}
                    onClick={() => {
                      selectPanel(sourceType);
                    }}
                  >
                    <span
                      className={
                        activePanel.id === sourceType
                          ? "displayActiveIcon activCategory"
                          : "displayActiveIcon inActivCategory"
                      }
                    >
                      {activePanel.id === sourceType ? "-" : "+"}
                    </span>
                    {
                      <span
                        className={`dams-source-accrdion-title ${
                          activePanel.id === sourceType
                            ? "activCategory"
                            : "inActivCategory"
                        }
                      `}
                      >
                        {sourceType}
                      </span>
                    }
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={sourceType}>
                    <Card.Body style={{ marginLeft: "18px" }}>
                      {options.map((sourceOption) => {
                        return (
                          <div
                            className={`dams-source-accrdion-item ${
                              activePanel.id === sourceType &&
                              activePanel.selectedOption === sourceOption
                                ? "dams-source-accrdion-item-selected"
                                : ""
                            }`}
                            onClick={(e) => {
                              setActivePanel({
                                ...activePanel,
                                selectedOption: sourceOption,
                              });
                              setSelectedDamsSource(() => {
                                retrieveSelectedDamsCountryList(sourceOption);
                              });
                              setSelectedDamsSource({
                                sourceType: activePanel.id,
                                assetDataType: sourceOption,
                              });
                            }}
                          >
                            {sourceOption}
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="lineHorizontal"></div>
                </div>
              </div>
            );
          })}
      </Accordion>
    </>
  );
};

export default DamsSourceFilter;
