import React from "react";
import {
  getMlrStatusIcon,
  templateTypes,
  capitalizeString,
} from "../../../Uitls/Util";

const ChannelTemplateCard = ({
  channelTemplate,
  onSelectChannelTemplate,
  templateType = "Email Template",
  sourceType = "VEEVA",
  onSelectTemplate,
  selectedTemplateId,
  viewTemplate,
}) => {
  return (
    <div className="col-md-3 template-card-parent">
      <input
        class="form-check-input template-radio-button"
        type="radio"
        checked={channelTemplate.id === selectedTemplateId}
        // value={language.language}
        onChange={(e) => {
          onSelectTemplate(channelTemplate);
        }}
      />
      <div
        className={`template-card-card ${
          channelTemplate.id === selectedTemplateId
            ? "template-card-card-selected"
            : ""
        }`}
        // onClick={() => {
        //   onSelectTemplate(channelTemplate);
        // }}
      >
        <div>
          <span className="template-card-name">{channelTemplate.name}</span>
          {(channelTemplate.dams?.pdf_status?.trim().toLowerCase() ===
            "approved" ||
            channelTemplate.dams?.html_status?.trim().toLowerCase() ===
              "approved") && (
            <img
              src={getMlrStatusIcon("Approved")}
              className="template-card-status"
              alt=""
            ></img>
          )}
        </div>
        <div className="d-flex">
          <div style={{ marginRight: "24px" }}>
            <div className="template-card-id-label">
              {capitalizeString(sourceType)} ID
            </div>
            <div className="template-card-id">
              {channelTemplate.dams?.pdf_no
                ? channelTemplate.dams.pdf_no
                : channelTemplate.dams?.html_no
                ? channelTemplate.dams.html_no
                : "-"}
            </div>
          </div>

          <div className="">
            <div className="template-card-id-label">NEXT ID</div>
            <div className="template-card-id">{channelTemplate.serial_no}</div>
          </div>
        </div>
        <img
          src={channelTemplate.image}
          alt=""
          className="template-card-thumb"
        ></img>
        <div className="text-center">
          <button
            className="btn btn-link"
            onClick={() => {
              viewTemplate(channelTemplate);
            }}
          >
            {" "}
            View
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChannelTemplateCard;
