import React from "react";
import { Row } from "react-bootstrap";

const ViewTextWithHeading = ({ heading, text }) => {
  return (
    <>
      <Row style={{ margin: "10px 0px 15px 0px", wordBreak: "break-all" }}>
        <div className="view-text-heading">{heading} </div>{" "}
        <div style={{ textAlign: "left" }}>{text}</div>
      </Row>
    </>
  );
};

export default ViewTextWithHeading;
