import React, { useState } from "react";
import i18next from "i18next";

import Catchlines from "./TranslateCatchlines";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

import commentIcon from "../../../images/icon-comment.svg";

const TranslateAlerts = ({
  alerts,
  isAnyToggleOn,
  initiateTranslationFor,
  addComment,
  module,
  getCommentsData,
}) => {
  //for Comments
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [comments, setComments] = useState([]);
  const [rowIndex, setRowIndex] = useState(-1);

  const consolidateComments = (primaryComponentId) => {
    const allComments = module.comments;
    return allComments.filter((comment) => {
      return (
        comment.primaryComponentId === primaryComponentId &&
        comment.componentId === primaryComponentId
      );
    });
  };

  const getCommentBubbleFor = (boxName, primaryComponentId) => {
    const comments = consolidateComments(primaryComponentId);
    if (!comments || comments.length === 0) {
      return;
    }
    return (
      <div
        style={{
          float: "right",
          marginRight: "0px",
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          style={{ margin: "-5px", cursor: "pointer" }}
          onClick={() => {
            if (comments && comments.length > 0) {
              const clickedIndex = alerts.findIndex((banner) => {
                return banner.id === primaryComponentId;
              });
              setComments(() => {
                setShowCommentsDialog(
                  clickedIndex === rowIndex ? !showCommentsDialog : true
                );
              });
              setRowIndex(clickedIndex);
              setComments(comments);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className="banner">
      <span className="title banner-and-alert-title">Alert</span>
      <div className="translate-section-head">
        <span className="catclineTitle">
          {alerts.length <= 0
            ? i18next.t("channels.tagline")
            : `${i18next.t("channels.tagline")} (${alerts.length})`}
        </span>
      </div>
      <div>
        <Catchlines
          catchlines={alerts}
          isAnyToggleOn={isAnyToggleOn}
          initiateTranslationFor={initiateTranslationFor}
          getCommentBubbleFor={getCommentBubbleFor}
          addComment={addComment}
          comments={comments}
          showCommentsFlag={showCommentsDialog}
          setShowCommentsFlag={setShowCommentsDialog}
          rowIndex={rowIndex}
          getCommentsData={getCommentsData}
        />
      </div>
    </div>
  );
};

export default TranslateAlerts;
