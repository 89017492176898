import React, { useState, useEffect } from "react";
import "../styles/pages/CreateModuleStep4.scss";
import AddCatchline from "./AddCatchline";
import "../styles/components/CTA.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import { validateUrl } from "../Uitls/Util";

const CTA = props => {
  const { t } = useTranslation();
  const [ctas, setCtas] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    //setIntialData(props)
  }, []);

  useEffect(() => {
    setIntialData(props, false);
  }, [props]);

  const setIntialData = (props, isInital = true) => {
    if (!isInital && props.ctas) {
      setCtas(props.ctas);
    } else if (isInital) {
      newCTA();
    }
    if (props.ctaErrors && props.ctaErrors.length > 0) {
      setErrors(props.ctaErrors);
    } else {
      setErrors([]);
    }
  };

  const newCTA = () => {
    const ctaID = uuidv4();
    const newCTAObj = { ctaID, url: "", ctaTexts: [] };
    if (ctas.length > 0) {
      setCtas(ctas.concat(newCTAObj));
    } else {
      setCtas([newCTAObj]);
    }
    updateParent(ctas.concat(newCTAObj));
  };

  const updateParent = ctas => {
    if (props.onDataChange) {
      props.onDataChange(ctas);
    }
  };

  // useEffect(() => {
  //     console.log('state changed', ctas)
  // }, [ctas])
  const linkInputCss = id => {
    if (errors) {
      //debugger
      const found = errors.filter(err => {
        return err && err.id === id;
      });
      if (found && found.length > 0) {
        return "form-control err-input-cta-link";
      }
    }
    return "form-control input-cta-link";
  };

  const renderCTAs = () => {
    return (
      ctas &&
      ctas.length > 0 &&
      ctas.map(cta => {
        return (
          <div
            id={cta.ctaID || cta.id}
            key={cta.ctaID || cta.id}
            className="cta-item"
          >
            {/* <span className = 'error-message'>{error}</span> */}
            <div className="div-cta-link input-group">
              <input
                type="text"
                className={linkInputCss(cta.ctaID || cta.id)}
                id={`input-url${cta.ctaID || cta.id}`}
                aria-label="url"
                aria-describedby="button-addon2"
                placeholder="Enter URL"
                value={cta.url}
                onBlur={event => {
                  validateEnteredLink(event, cta.ctaID || cta.id);
                }}
                onChange={event => {
                  onCTALinkChange(event, cta.ctaID || cta.id);
                }}
              />
              <DeleteIcon
                className="remove-cta"
                color="primary"
                id={`remove-cta${cta.ctaID || cta.id}`}
                onClick={e => {
                  onRemoveCTABox(cta.ctaID || cta.id);
                }}
              />
            </div>

            <AddCatchline
              placeholder={i18next.t("channels.cta.enterCTA")}
              catchlines={
                cta.ctaTexts && cta.ctaTexts.length > 0 ? cta.ctaTexts : []
              }
              removeCatchline={removeCTAText}
              onCatchlineChange={onCTAChange}
              options={{ id: cta.ctaID || cta.id }}
            ></AddCatchline>
          </div>
        );
      })
    );
  };

  const isCTATextalreadyAdded = () => {};
  //Called once user chages cta text
  const onCTAChange = (data, id) => {
    let existingCTAs = ctas;
    let objectToBeUpdated = ctas.filter(cta => {
      return cta.ctaID === id || cta.id === id;
    })[0];
    const indexToBeUpdated = ctas.indexOf(objectToBeUpdated);
    if (objectToBeUpdated) {
      objectToBeUpdated.ctaTexts = data;
    }
    existingCTAs[indexToBeUpdated] = objectToBeUpdated;
    setCtas([].concat(existingCTAs)); // setting only with existingCTAs not changing the state and re-rendering the UI
    updateParent(existingCTAs);
  };

  const validateEnteredLink = (event, id) => {
    if (!event || !event.target) {
      return;
    }
    //validate entered url first
    const enteredUrl = event.target.value;
    if (enteredUrl && !validateUrl(enteredUrl)) {
      //setError(i18next.t('channels.cta.pleaseEnterValidUrl'))
      const found = errors.filter(err => {
        return err && err.id === id;
      });
      if (!found || found.length <= 0) {
        const errObj = {
          id: id,
          error: i18next.t("channels.cta.pleaseEnterValidUrl")
        };
        const errs = errors.concat(errObj);
        setErrors(errs);
        UpdateParentForErrors(errs);
      }
    } else {
      const errs = errors.filter(err => {
        return err && err.id !== id;
      });
      setErrors(errs);
      UpdateParentForErrors(errs);
    }
  };

  const UpdateParentForErrors = errs => {
    if (props.onCTAError) {
      props.onCTAError(errs);
    }
  };

  // useEffect((errors) => {

  // })

  const onCTALinkChange = (event, id) => {
    if (!event || !event.target) {
      return;
    }
    const enteredUrl = event.target.value;
    const found = errors.filter(err => {
      return err && err.id === id;
    });
    if (found && found.length > 0) {
      validateEnteredLink(event, id);
    }
    let existingCTAs = ctas;
    let objectToBeUpdated = ctas.filter(cta => {
      return cta.ctaID === id || cta.id === id;
    })[0];
    const indexToBeUpdated = ctas.indexOf(objectToBeUpdated);
    if (objectToBeUpdated) {
      objectToBeUpdated.url = enteredUrl || "";
    }
    existingCTAs[indexToBeUpdated] = objectToBeUpdated;
    setCtas([].concat(existingCTAs)); // setting only with existingCTAs not changing the state and re-rendering the UI
    updateParent(existingCTAs);
  };

  const removeCTAText = ctaText => {
    //onRemoveCTABox(ctaText.id)
    // if(ctas.length > 0){
    //     for(const cta of ctas){
    //         if(cta.ctaTexts && cta.ctaTexts.length > 0){
    //             const found = [...cta.ctaTexts].filter((text) => {
    //                 return text.id === ctaText.id
    //             })
    //             if(fou)
    //         }
    //     }
    // }
    if (ctaText.moduleCtaId) {
      props.onDeleteCTAText([ctaText.id], ctaText.moduleCtaId);
    }
  };
  const onRemoveCTABox = ctaID => {
    const remainingCTAs = ctas.filter(cta => {
      if (cta.id) {
        return cta.id !== ctaID;
      } else {
        return cta.ctaID !== ctaID;
      }
    });

    if (props.onDeleteCTA && ctaID) {
      setCtas(remainingCTAs);
      const filtered = ctas.filter(cta => {
        return cta.id === ctaID;
      });
      if (filtered && filtered.length > 0) {
        // const filtered = ctas.filter((cta) => {
        //     return cta.id === ctaID
        // })[0]
        // props.onDeleteCTA(filtered.ctaTexts.map((ctaText) => {
        //     return ctaText.id
        // }))
        props.onDeleteCTA([ctaID]);
      } else {
        updateParent(remainingCTAs);
      }
    } else {
      setCtas(remainingCTAs);
      updateParent(remainingCTAs);
    }
  };

  const title = () => {
    if (ctas && ctas.length > 0) {
      const actualCTAs = ctas.filter(cta => {
        return cta.url && cta.url.trim() !== "";
      });
      if (actualCTAs && actualCTAs.length > 0) {
        return `${i18next.t("channels.cta.cta")} (${actualCTAs.length})`;
      }
    }
    return `${i18next.t("channels.cta.cta")}`;
  };

  return (
    <div className="banner">
      <span className="title">{title()}</span>

      <div className="cta-head" onClick={newCTA}>
        {i18next.t("channels.cta.newCTA")}
      </div>
      {renderCTAs()}
    </div>
  );
};

export default CTA;
