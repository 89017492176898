import React from "react";

const ChannelMatrixGenerationToast = ({ data, closeButtonClicked }) => {
  return (
    <div className="create-message-toast">
      <p className="title">
        <strong>Channel Matrix Generation Completed</strong>
      </p>

      {data.channelMatrixId && (
        <p className="subtitle">
          <p className="subtitle">
            ID{" "}
            <strong style={{ marginLeft: "50px" }}>
              {" "}
              {data.channelMatrixId}{" "}
            </strong>
          </p>
        </p>
      )}
      <p className="subtitle">
        Brand <strong style={{ marginLeft: "31px" }}> {data.brand}</strong>
      </p>
      {data.campaign && (
        <p className="subtitle">
          Campaign{" "}
          <strong style={{ marginLeft: "10px" }}> {data.campaign} </strong>
        </p>
      )}
      <div className="btn-close-toast">
        <button
          className="btn btn-link pl-0 btn-close-toast pr-0"
          onClick={() => {
            //closeButtonClicked();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ChannelMatrixGenerationToast;
