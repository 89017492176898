//Import React dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//Import Custom components
import CompositeAssetList from "./CompositeAssetList";
import ChannelMatrixGenerationToast from "../ChannelMatrixGenerationToast";

const GeneratedCompositeAssets = (props) => {
  const { t } = useTranslation();
  const {
    proceedToNextScreen,
    previousPage,
    generatedCompositeAssetDetails,
    setCompositeAssetToView,
    moduleDetails,
    sourceType,
    publishMatrixToDams,
    closeModal,
    generateChannelMatrixSpreadSheet,
    assetDataType,
  } = props;

  const openCompositeAsset = (compositeAsset) => {
    setCompositeAssetToView(() => {
      proceedToNextScreen("pageFour");
    });
    setCompositeAssetToView(compositeAsset);
  };

  return (
    <div>
      <CompositeAssetList
        proceedToNextScreen={proceedToNextScreen}
        previousPage={previousPage}
        compositeAssetsDetails={
          generatedCompositeAssetDetails?.compositeAssetDetails
        }
        viewCompositeAsset={openCompositeAsset}
        moduleDetails={moduleDetails}
        sourceType={sourceType}
        publishMatrixToDams={publishMatrixToDams}
        closeModal={closeModal}
        generateChannelMatrixSpreadSheet={generateChannelMatrixSpreadSheet}
        assetDataType={assetDataType}
      />
    </div>
  );
};

export default GeneratedCompositeAssets;
