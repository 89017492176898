import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

import LogoutCTA from "../Pages/LogoutCTA";
import TranslateHeader from "../components/Headers/HeaderTranslateModule";
import TranslateModuleNav from "../components/Headers/TranslateModuleNav";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { toast } from "react-toastify";
import { getTranslationJobType, routes } from "../Uitls/Util";
import TranslatePrimaryClaim from "../components/TranslateFlow/ClaimsAndVisuals/TranslatePrimaryClaim";
import AddComment from "../components/TranslateFlow/AddComment";

import ErrComponentToRender from "../Uitls/apiErrorHandling";
import {
  getModuleDetailsById,
  saveTranslatedModule,
  addNewComment,
} from "../services/apis";
import errComponentToRender from "../Uitls/apiErrorHandling";

import "../styles/pages/TranslateClaims.scss";
import TranslateSupportingClaims from "../components/TranslateFlow/ClaimsAndVisuals/TranslateSupportingClaims";
import TranslateSupportingVisuals from "../components/TranslateFlow/ClaimsAndVisuals/TranslateSupportingVisuals";
import CustomModal from "../components/customModal";

const TranslateClaims = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [showOriginalToggle, setShowOriginalToggle] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [module, setModule] = useState({});

  //For Comment
  const [showAddCommentBox, setShowAddCommentBox] = useState(false);
  const [commentObject, setCommentObject] = useState(undefined);

  //Source module toggle
  const [sourceModuleToggle, updateSourceModuleToggle] = useState(false);
  const [hasSourceRefModuleId, setHasSourceRefModuleId] = useState(false);

  useEffect(() => {
    fetchModuleDetails();
  }, []);
  const getModuleIdFromLocalStorage = () => {
    //Fetch the Module Details here
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let moduleId = undefined;
    if (props && props.location && props.location.state) {
      moduleId = props.location.state.moduleId;
    } else {
      moduleId =
        moduleInfoFromLocalStorage &&
        moduleInfoFromLocalStorage.translateModuleId;
    }
    return moduleId;
  };
  const fetchModuleDetails = () => {
    const moduleId = getModuleIdFromLocalStorage();
    if (!moduleId) {
      return;
    }
    getModuleDetails(moduleId);
  };

  const getModuleDetails = (moduleId, isCurrentModule = true) => {
    setIsLoading(true);
    getModuleDetailsById(moduleId, (response, err, errCode) => {
      setIsLoading(false);
      if (response) {
        setModule(response.data);
        if (isCurrentModule && response.data.translationRefModuleId) {
          setHasSourceRefModuleId(true);
        }

        return;
      }
      const errCoponent = errComponentToRender(errCode);
      toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
    });
  };

  const isLastPageToReviewOrTranslate = () => {
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    ).translateComponents;
    if (selectedPages[selectedPages.length - 1] === "claims") {
      return true;
    }
    return false;
  };

  const handleProceed = (option) => {
    if (option) {
      return;
    }
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    // if (selectedPages.translateComponents.includes("channel")) {
    //   pageRedirect(routes.translateChannel);
    // }
    if (selectedPages.translateComponents.includes("additionalInfo")) {
      pageRedirect(routes.translateAdditionalInfo);
    } else {
      pageRedirect(routes.moduleDetails);
    }
  };

  const pageRedirect = (pageURL) => {
    history.push({
      pathname: pageURL,
      state: {
        moduleId: getModuleIdFromLocalStorage(),
        flowType: "previewTranslate",
      },
    });
  };

  const handleClose = () => {
    window.history.back();
  };

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleSave = () => {
    //create payload
    const payload = {
      id: module.id,
      name: module.name,
      pageNumber: module.pageNumber,
      moduleClaims: module.moduleClaims,
      moduleVisuals: module.moduleVisuals,
    };
    setIsLoading(true);
    saveTranslatedModule(module.id, payload, (response, err, errorCode) => {
      setIsLoading(false);
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        claimsSaveErr(errCoponent);
      } else {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
      }
    });
  };

  const handleLanguageToggleBtn = () => {
    const newToggleState = !showOriginalToggle;
    setShowOriginalToggle(newToggleState);
    if (newToggleState) {
      getModuleDetails(module.moduleIdEnglish, false);
    } else {
      getModuleDetails(getModuleIdFromLocalStorage());
    }
  };
  const handleSourceModuleToggle = () => {
    const updatedToggleState = !sourceModuleToggle;
    updateSourceModuleToggle(updatedToggleState);
    if (updatedToggleState) {
      getModuleDetails(module.translationRefModuleId, false);
    } else {
      getModuleDetails(getModuleIdFromLocalStorage());
    }
  };

  const getProceedButtonTitle = () => {
    if (isLastPageToReviewOrTranslate()) {
      const jobType = getTranslationJobType();
      if (jobType && jobType.trim().toLowerCase() === "self") {
        return i18next.t("translated.preview").toUpperCase();
      }
    }
    return undefined;
  };

  const showAddCommentsModal = (show) => {
    setShowAddCommentBox(show);
    if (!show) {
      setCommentObject(undefined);
    }
  };

  const addComment = (commentObject) => {
    setCommentObject(() => {
      showAddCommentsModal(true);
    });
    setCommentObject(commentObject);
  };

  const saveComment = (commentText) => {
    if (commentObject) {
      commentObject["comment"] = commentText;
      commentObject["moduleId"] = module.id;
    }
    if (commentObject) {
      setIsLoading(true);
      addNewComment(commentObject, (response, errMessage, errCode) => {
        setIsLoading(false);
        if (response) {
          getModuleDetails(module.id);
        } else {
          const errCoponent = ErrComponentToRender(errCode);
          claimsSaveErr(errCoponent);
        }
        showAddCommentsModal(false);
      });
    }
  };
  const isAnyToggleOn = () => {
    return sourceModuleToggle || showOriginalToggle;
  };
  return (
    <>
      <LogoutCTA />
      <div className="trasnlateHeaderBg">
        <CircularProgressBar isloading={isloading} />
        <TranslateHeader />
        <TranslateModuleNav
          btnProceed={
            getProceedButtonTitle() || i18next.t("generalText.proceed")
          }
          btnClose={i18next.t("generalText.close")}
          btnSave={i18next.t("generalText.save")}
          disableProceed={false}
          handleProceed={handleProceed}
          handlePageClose={handleClose}
          handlePageSave={handleSave}
          pageIndex={1}
          toggle={showOriginalToggle}
          handleLanguageToggleBtn={handleLanguageToggleBtn}
          moduleName={module.name || ""}
          moduleCode={module.moduleCode || ""}
          sourceLanguage={module.translatedFrom || ""}
          targetLanguage={module.language || ""}
          shouldHaveMultipleOptions={
            isLastPageToReviewOrTranslate() ? true : false
          }
          proceedOptions={
            isLastPageToReviewOrTranslate()
              ? ["Revision Required", "Approve"]
              : undefined
          }
          hasSourceModuleId={hasSourceRefModuleId}
          toggleSourceModule={sourceModuleToggle}
          handleSourceModuleToggle={handleSourceModuleToggle}
        />
      </div>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col">
            <div className="trasnlateContentContainer">
              <div className="translateContentBox customScrollBar">
                <TranslatePrimaryClaim
                  module={module}
                  isAnyToggleOn={isAnyToggleOn()}
                  saveTranslated={handleSave}
                  discardTranslated={fetchModuleDetails}
                  addComment={addComment}
                />

                <TranslateSupportingClaims
                  module={module}
                  isAnyToggleOn={isAnyToggleOn()}
                  saveTranslated={handleSave}
                  discardTranslated={fetchModuleDetails}
                  addComment={addComment}
                />

                <TranslateSupportingVisuals
                  module={module}
                  isAnyToggleOn={isAnyToggleOn()}
                  saveTranslated={handleSave}
                  discardTranslated={fetchModuleDetails}
                  addComment={addComment}
                />
              </div>
              <CustomModal
                displayModal={showAddCommentBox}
                hideModal={showAddCommentsModal}
                title=""
                size="lg"
              >
                <AddComment
                  selectedText={commentObject?.selectedText}
                  handleClose={showAddCommentsModal}
                  saveComment={saveComment}
                />
              </CustomModal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslateClaims;
