import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const PasswordStrengthIndicator = ({
  validity: { minChar, number, specialChar, upperCase },
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="forgotTitle mb-3">
        {t("login.resetPassword.passIndicatorLabel")}
      </h1>
      <ul className="pwdIndicatorList">
        <PasswordStrengthIndicatorItem
          isValid={minChar}
          text={t("login.resetPassword.sixCharErr")}
        />
        <PasswordStrengthIndicatorItem
          isValid={number}
          text={t("login.resetPassword.alphanumeric")}
        />
        <PasswordStrengthIndicatorItem
          isValid={specialChar}
          text={t("login.resetPassword.specialCharErr")}
        />
        <PasswordStrengthIndicatorItem
          isValid={upperCase}
          text={t("login.resetPassword.capCharErr")}
        />
      </ul>
    </>
  );
};

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
  const highlightClass = isValid ? "active" : isValid !== null ? "normal" : "";
  return (
    <li className={highlightClass}>
      {" "}
      <ListItemIcon>
        <CheckIcon />
      </ListItemIcon>
      {text}
    </li>
  );
};

export default PasswordStrengthIndicator;
