import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { useHistory } from "react-router-dom";
import "../../styles/pages/UplaodMasterAsset.scss";
import "../../styles/CustomToastify.scss";

import {
  fetchLocalizedModules,
  getAllOrSearchDenovoModules,
  getModuleDetailsById,
} from "../../services/apis";
import SearchTacticsAndModules from "../ExistingTactics/SearchTacticsAndModules";
import TabPanel from "../../Uitls/TabPanel";

import ExistingTacticsFilter from "../ExistingTactics/ExistingTacticsFilter";
import { damsSources, moduleTypes, routes, showError } from "../../Uitls/Util";
import DenovoModulesList from "./DenovoModulesList";
import DenovoGenericDetails from "./DenovoGenericDetails";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import CreateNewModuleCard from "../ExistingTactics/CreateNewModuleCard";
import LocalizedModules from "../Localized/LocalizedModules";
import CustomModal from "../customModal";
import ModulePlaceHolder from "../ModulePlaceHolder/ModulePlaceHolder";
import { prepareModuleDataForEditing } from "../Dams/DamsHome";
import ChannelSelectionModalForStylizedModule from "../../components//ModuleEditor/ChannelSelectionModal";
import CtaSelection from "../ModuleEditor/CtaSelection";

const DenovoModules = (props) => {
  const { t } = useTranslation();
  const [denovoModules, setDenovoModules] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [moduleTactic, setModuleTactic] = useState([]);
  const [alertForBrands, setAlertForBrands] = useState(false);
  const [filteredMarket, setFilteredMarket] = useState([]);
  const [filteredMarketList, setFilteredMarketList] = useState([]);

  let history = useHistory();
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [campaignFilterList, setCampaignFilterList] = useState([]);
  const [titleType, setTitleType] = useState("initial");
  const [brandsList, setBrandsList] = useState([]);
  const [marketSearchTeram, setMarketSearchTeram] = useState("");

  const [isCreateNewClicked, setIsCreateNewClicked] = useState(false);
  const [selectedModule, setSelectedModule] = useState(undefined);
  const [searchTriggerd, setSearchTriggerd] = useState(false);

  // // For Localized/Translated
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [isLocalizedModulesSelected, setIsLocalizedModulesSelected] =
    useState(false);
  const [localizedModules, setLocalizedModules] = useState([]);
  const [brandErrorFlag, setBrandErrorFlag] = useState(false);
  const [localizedModuleGroups, setLocalizedModuleGroups] = useState([]);
  const [isSearchGroupChecked, setIsSearchGroupChecked] = useState(false);

  //This is for stylised modules
  const [
    stylizeChannelSelectionModalData,
    setstylizeChannelSelectionModalData,
  ] = useState(undefined);
  const [isStylisedModuleInitiated, setIsStylisedModuleInitiated] =
    useState(false);
  const [stylizeModuleChannelSelection, setStylizeModuleChannelSelection] =
    useState(false);
  const [selectedChannel, setSelectedChannel] = useState(undefined);

  useEffect(() => {
    if (isLocalizedModulesSelected) {
      if (filteredBrands.length > 0 && filteredCampaigns.length > 0) {
        setBrandErrorFlag(false);
      } else {
        setBrandErrorFlag(true);
      }
    }
  }, [filteredBrands, filteredCampaigns, isLocalizedModulesSelected]);
  useEffect(() => {
    fetchDenovoModules();
  }, []);

  useEffect(() => {
    setLocalizedModules([]);
    if (!isLocalizedModulesSelected) {
      setIsSearchGroupChecked(false);
    }
    if (isLocalizedModulesSelected) {
      searchLocalizedModules(false, "Grouped");
    }
  }, [isLocalizedModulesSelected]);

  useEffect(() => {
    setCampaigns(getCampaignList());
  }, [filteredBrands]);

  useEffect(() => {}, [isCreateNewClicked]);

  const getCampaignList = (brands = [...filteredBrands]) => {
    let campaignList = [];
    brands.forEach((brand) => {
      let brandAndCampaign = brandsAndCampaigns.find(
        (el) => el.brandName === brand
      );
      if (brandAndCampaign) {
        campaignList = campaignList.concat(
          brandAndCampaign.campaigns.map((el) => el.campaignName)
        );
      }
    });
    return campaignList;
  };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const fetchDenovoModules = (isReset = false) => {
    if (isLocalizedModulesSelected) {
      if (filteredBrands.length < 1 || filteredCampaigns.length < 1) {
        setBrandErrorFlag(true);
      } else {
        searchLocalizedModules(
          isReset,
          isSearchGroupChecked ? "Grouped" : "List"
        );
      }
      return;
    }
    let searchParams = {
      brands: filteredBrands || [],
      markets: filteredMarket || [],
      campaigns: filteredCampaigns || [],

      searchText: document
        .getElementById("search-tactics-from-icb")
        ?.value.trim(),
    };

    if (isReset) {
      searchParams = {
        brands: [],
        markets: [],
        campaigns: [],
        searchText: "",
      };
    }

    setIsloading(true);
    getAllOrSearchDenovoModules(searchParams, (response, err, errorCode) => {
      setIsloading(false);
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        const modules = response.data;
        //sort based on deconstruction time
        modules.sort(function (a, b) {
          return new Date(b.createdDateTime) - new Date(a.createdDateTime);
        });
        setDenovoModules(modules);
        setIsCreateNewClicked(false);
        setSelectedModule(undefined);
      }
    });
  };

  const searchLocalizedModules = (
    isReset = false,
    responseType = "List",
    originalResults = false
  ) => {
    let payload = {
      searchText: originalResults
        ? ""
        : document.getElementById("search-tactics-from-icb")?.value.trim(),
      brands: originalResults ? [] : filteredBrands || [],
      channelTypes: filteredChannels || [],
      markets: filteredMarket || [],
      campaigns: originalResults ? [] : filteredCampaigns || [],
      languages: filteredLanguages || [],
    };
    if (isReset) {
      setLocalizedModules([]);
      setLocalizedModuleGroups([]);
      payload = {
        searchText: "",
        brands: [],
        channelTypes: [],
        markets: [],
        campaigns: [],
        languages: [],
      };
    }

    setIsloading(true);
    fetchLocalizedModules(
      payload,
      responseType,
      "DeNouvo",
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          const resObj = response.data;
          if (resObj) {
            if (responseType === "Grouped") {
              if (resObj.groups) {
                setLocalizedModuleGroups(resObj.groups);
              } else {
                setLocalizedModuleGroups([]);
              }
            } else {
              setLocalizedModules(resObj.modules);
            }
          }
          return;
        }

        const errCoponent = ErrComponentToRender(errCode);
        getmoduleDetailsErr(errCoponent);
      }
    );
  };

  const removeFromFilteredLanguageList = (language) => {
    const position = filteredLanguages.indexOf(language);
    const newFilteredLanguages = [...filteredLanguages];
    newFilteredLanguages.splice(position, 1);
    setFilteredLanguages(newFilteredLanguages);
  };

  const removeitemFromFilteredList = (item) => {
    if (
      filteredBrands.length === 1 &&
      (filteredChannels.length != 0 ||
        moduleTactic.length != 0 ||
        filteredMarket.length != 0)
    ) {
      setAlertForBrands(true);
    } else {
      const index = filteredBrands.indexOf(item);
      const newFilteredBrands = [...filteredBrands];
      newFilteredBrands.splice(index, 1);
      setFilteredBrands(newFilteredBrands);
      setFilteredList(newFilteredBrands);
    }
  };

  const removeitemFromFilteredCampaignList = (item) => {
    const position = filteredCampaigns.indexOf(item);
    const newFilteredCampaigns = [...filteredCampaigns];
    newFilteredCampaigns.splice(position, 1);
    setFilteredCampaigns(newFilteredCampaigns);
    setCampaignFilterList(newFilteredCampaigns);
  };

  const removeitemFromFilteredListMarket = (item) => {
    const position = filteredMarket.indexOf(item);
    const newFilteredMarket = [...filteredMarket];
    newFilteredMarket.splice(position, 1);
    setFilteredMarket(newFilteredMarket);
    setFilteredMarketList(newFilteredMarket);
  };

  const searchTactics = () => {
    setTitleType("searched");
    setSearchTriggerd(true);
    fetchDenovoModules();
  };

  const clearAllSearch = (event) => {
    const element = document.getElementById("search-tactics-from-icb");
    if (
      event &&
      element.value === "" &&
      filteredBrands.length === 0 &&
      filteredMarket.length === 0 &&
      filteredCampaigns.length === 0 &&
      filteredLanguages.length === 0
    ) {
      return;
    }
    if (element) {
      element.value = "";
      setIsSearchBarEmpty(true);
      setTitleType("initial");
    }
    setFilteredBrands([]);
    setFilteredChannels([]);
    setFilteredCampaigns([]);
    setFilteredMarket([]);
    setFilteredList([]);
    setFilteredMarketList([]);
    setFilteredCampaigns([]);
    setCampaignFilterList([]);
    setMarketSearchTeram("");
    if (titleType !== "initial") {
      if (isLocalizedModulesSelected) {
        searchLocalizedModules(true, "Grouped");
        setBrandErrorFlag(false);
      } else {
        fetchDenovoModules(true);
      }
    }
    setSearchTriggerd(false);
    setFilteredLanguages([]);
    setBrandErrorFlag(false);
  };

  const resetFilters = () => {
    setFilteredBrands([]);
    setFilteredChannels([]);
    setFilteredCampaigns([]);
    setFilteredMarket([]);
    setFilteredList([]);
    setFilteredMarketList([]);
    setFilteredCampaigns([]);
    setCampaignFilterList([]);
    setMarketSearchTeram("");
    setFilteredLanguages([]);
  };

  const closeGenericDetails = () => {
    setSelectedModule(undefined);
    setIsCreateNewClicked(false);
  };

  const goToPrimaryClaimPage = (data) => {
    history.push({
      pathname: routes.addPrimaryClaim,
      state: data,
    });
  };

  const [createModuleModal, setCreateModuleModal] = useState(false);
  const [addedGenericInfo, setAddedGenericInfo] = useState(undefined);
  const handleCreateModuleModal = (flag = false) => {
    setCreateModuleModal(flag);
    if (!flag) {
      setAddedGenericInfo(undefined);
    }
  };
  const startCreatingPlaceholders = (genericInfo) => {
    setIsCreateNewClicked(false);
    setAddedGenericInfo(() => {
      handleCreateModuleModal(true);
    });
    setAddedGenericInfo(genericInfo);
  };

  const startStylizingModule = (module) => {
    setstylizeChannelSelectionModalData(() => {
      setStylizeModuleChannelSelection(true);
    });
    setstylizeChannelSelectionModalData({
      module: module,
      brand: module.brand,
      campaign: module.campaign,
      sourceType: damsSources.veeva.name.toLowerCase(),
    });
    // history.push({
    //   pathname: routes.moduleDetails,
    //   state: {
    //     sourceType: damsSources.veeva.name.toLowerCase(),
    //   },
    // });
  };
  const closestylizeChannelSelectionModal = () => {
    setSelectedChannel(undefined);
    setstylizeChannelSelectionModalData(undefined);
    setStylizeModuleChannelSelection(false);
  };

  const proceedToCtaSelectionScreen = () => {
    setIsStylisedModuleInitiated(true);
    setStylizeModuleChannelSelection(false);
  };

  const cancelCreatingStylisedModule = () => {
    setStylizeModuleChannelSelection(false);
    setIsStylisedModuleInitiated(false);
    setSelectedChannel(undefined);
  };

  const fetchModuleToStylize = (selectedCtas) => {
    setIsloading(true);

    getModuleDetailsById(
      stylizeChannelSelectionModalData.module.id,
      (res, err) => {
        setIsloading(false);

        if (res) {
          let data = res.data;
          history.push({
            pathname: routes.moduleEditor,
            state: {
              module: prepareModuleDataForEditing(data, selectedCtas),
              channelType: selectedChannel,
              sourceType: damsSources.veeva.name.toLowerCase(),
              assetDataType: moduleTypes.denovo,
              moduleReusableTexts: data?.moduleReusableTexts,
            },
          });
          setstylizeChannelSelectionModalData(undefined);
          return;
        }
        showError(err);
      }
    );
  };

  const showCreateModal = () => {
    return (
      <CustomModal
        displayModal={createModuleModal}
        hideModal={handleCreateModuleModal}
        size="xl"
        dilogClassName="createPlaceholderModal"
        backdrop={false}
      >
        <ModulePlaceHolder
          // asset={undefined}
          orignalModules={undefined}
          handleCloseCreateModuleModal={handleCreateModuleModal}
          type={moduleTypes.denovo}
          addedGenericInfo={addedGenericInfo}
          asset={{
            harvestedAssetId: "",
            storageAssetId: "",
            brandId: "",
            brandName: "",
            campaignName: "",
            campaignId: "",
          }}
          sourceType={damsSources.veeva.name}
        />
      </CustomModal>
    );
  };

  const filterModules = (parameter = "original") => {
    const modules = [...denovoModules];
    let filteredModules = [];
    if (modules && modules.length > 0) {
      filteredModules = modules.filter((module) => {
        const moduleType = module.moduleType.trim().toLowerCase();
        if (parameter === "original") {
          return moduleType === "original" || moduleType === "cloned";
        }
        //return only approved translated modules
        if (moduleType.toLowerCase() === "translated") {
          return (
            moduleType === parameter &&
            module.translationStatus?.toLowerCase() === "complete"
          );
        }
        return moduleType === parameter;
      });
    }
    return filteredModules;
  };
  const originalAndBasedOnModules = useMemo(
    () => (
      <div className={`${selectedModule ? "" : "col-md-12"} tacticsModules`}>
        <DenovoModulesList
          titleType={titleType}
          dModules={filterModules("original")}
          isCreateNewClicked={isCreateNewClicked}
          createNewClicked={setIsCreateNewClicked}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          searchTriggerd={searchTriggerd}
          mainModules={filterModules("original").filter(
            (module) => module.moduleType.trim().toLowerCase() === "original"
          )}
          auxModules={filterModules("cloned")}
          title="Modules"
          auxTitle="Based On Modules"
          typeofModule="original"
          stylizeModule={startStylizingModule}
          moduleTypes={moduleTypes}
          // closestylizeChannelSelectionModal={closestylizeChannelSelectionModal}
          // stylizeChannelSelectionModalData={stylizeChannelSelectionModalData}
          // proceedToCtaSelectionScreen={proceedToCtaSelectionScreen}
          // setstylizeChannelSelectionModalData={
          //   setstylizeChannelSelectionModalData
          // }
          stylizeChannelSelectionModalData={stylizeChannelSelectionModalData}
        />
      </div>
    ),
    [denovoModules, selectedModule]
  );
  const translatedModules = useMemo(
    () => (
      <div className={`${selectedModule ? "" : "col-md-12"} tacticsModules`}>
        <DenovoModulesList
          titleType={titleType}
          dModules={filterModules("translated")}
          isCreateNewClicked={isCreateNewClicked}
          createNewClicked={setIsCreateNewClicked}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          searchTriggerd={searchTriggerd}
          typeofModule="translated"
          moduleTypes={moduleTypes}
        />
      </div>
    ),
    [denovoModules, selectedModule]
  );
  const localizedOnModules = useMemo(
    () => (
      <div
        // className={`${
        //   selectedModule ? "col-md-12 denovo-modules-v-list" : "col-md-12"
        // } tacticsModules`}
        className={`${selectedModule ? "" : "col-md-12"} tacticsModules`}
      >
        <DenovoModulesList
          titleType={titleType}
          dModules={filterModules("localized")}
          isCreateNewClicked={isCreateNewClicked}
          createNewClicked={setIsCreateNewClicked}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          searchTriggerd={searchTriggerd}
          typeofModule="Localize"
          moduleTypes={moduleTypes}
        />
      </div>
    ),
    [denovoModules, selectedModule]
  );

  const renderTabSelectedModules = () => {
    const moduleType = selectedModule.moduleType.trim().toLowerCase();
    switch (moduleType) {
      case "original":
        return originalAndBasedOnModules;
      case "translated":
        return translatedModules;
      case "localized":
        return localizedOnModules;
      default:
        return originalAndBasedOnModules;
    }
  };

  const drawModulesHeading = () => {
    const moduleType = selectedModule.moduleType.trim().toLowerCase();
    let title = "Modules";
    if (moduleType === "translated") {
      title = "Translated Modules";
    } else if (moduleType === "localized") {
      title = "Localized Modules";
    }
    let finalModules = filterModules(moduleType);
    if (moduleType === "original" || moduleType === "cloned") {
      finalModules = filterModules("original");
      finalModules = [...finalModules].filter(
        (module) => module.moduleType.trim().toLowerCase() === "original"
      );
    }
    return (
      <h2
        className="masterTacticTitle pl-3 assetDetailColTitle "
        style={{ marginTop: "20px" }}
      >
        <span className="moduleNormalShow" style={{ fontWeight: "600" }}>
          {title} ({finalModules.length})
        </span>
        <p
          class="search-items"
          style={{ paddingLeft: "0px", marginTop: "5px" }}
        >
          Click on module card to view the generic information
        </p>
      </h2>
    );
  };

  const getFinalModulesCount = () => {
    return (
      filterModules("original").length +
      filterModules("translated").length +
      filterModules("localized").length
    );
  };

  const updateLocalModule = (updatedModule) => {
    const moduleIndex = denovoModules.findIndex((denovoModule) => {
      return denovoModule.id === updatedModule.id;
    });
    //const modules = [...denovoModules];
    denovoModules[moduleIndex] = updatedModule;
    selectedModule.genericBusinessRules = updatedModule.genericBusinessRules;
    selectedModule.isi = updatedModule.isi;
    selectedModule.pi = updatedModule.pi;
    selectedModule.mg = updatedModule.mg;
    //setSelectedModule(updatedModule);
  };

  useEffect(() => {
    filterResults();
  }, [
    filteredList,
    filteredMarketList,
    filteredCampaigns,
    filteredLanguages,
    filteredBrands,
    isSearchGroupChecked,
  ]);

  const filterResults = () => {
    if (isLocalizedModulesSelected) {
      if (filteredBrands.length < 1 || filteredCampaigns.length < 1) {
        if (
          filteredBrands.length < 1 &&
          filteredCampaigns.length < 1 &&
          titleType !== "searched"
        ) {
          searchLocalizedModules(
            false,
            isSearchGroupChecked || titleType !== "searched"
              ? "Grouped"
              : "List",
            true
          );
        }
      } else {
        searchLocalizedModules(
          false,
          isSearchGroupChecked || titleType !== "searched" ? "Grouped" : "List"
        );
      }
    } else {
      fetchDenovoModules();
    }
  };

  return (
    <div>
      <div className="contentContainer denovoModules">
        {!isStylisedModuleInitiated && (
          <div className="row DenovoModuleCard">
            <div className="col-md-2 existingSearhPanel SearchDenovo">
              <ExistingTacticsFilter
                filteredBrands={filteredBrands}
                setFilteredBrands={setFilteredBrands}
                setFilteredList={setFilteredList}
                filteredChannels={filteredChannels}
                setFilteredChannels={setFilteredChannels}
                marketDataSource={props.data.marketDataSource}
                setFilteredMarket={setFilteredMarket}
                filteredMarket={filteredMarket}
                setFilteredMarketList={setFilteredMarketList}
                setBrandsAndCampaigns={setBrandsAndCampaigns}
                campaigns={campaigns}
                filteredCampaigns={filteredCampaigns}
                setFilteredCampaigns={setFilteredCampaigns}
                setCampaignFilterList={setCampaignFilterList}
                resetFilters={resetFilters}
                brandsList={brandsList}
                marketSearchTeram={marketSearchTeram}
                setMarketSearchTeram={setMarketSearchTeram}
                showSource={true}
                showChannel={true}
                filteredLanguageList={filteredLanguages}
                setFilteredLanguages={setFilteredLanguages}
                languageDataSource={props.data.languageDataSource}
                isLocalizedModulesSelected={isLocalizedModulesSelected}
                setIsLocalizedModulesSelected={setIsLocalizedModulesSelected}
                brandErrorFlag={brandErrorFlag}
                clearAllSearch={clearAllSearch}
              />
            </div>
            <div className="col-md-10">
              <CircularProgressBar isloading={isloading} />
              <SearchTacticsAndModules
                setTitleType={setTitleType}
                startSearching={searchTactics}
                searchBarPlaceholder={
                  isLocalizedModulesSelected
                    ? t("tacticSource.searchLocalizedPlaceHolder")
                    : t("denovoModule.DenovoPlaceHolder")
                }
                clearAllSearch={clearAllSearch}
                filteredBrandList={filteredList}
                filteredMarketList={filteredMarketList}
                campaignFilterList={campaignFilterList}
                removeitemFromBrandFilteredList={removeitemFromFilteredList}
                removeitemFromFilteredListMarket={
                  removeitemFromFilteredListMarket
                }
                removeitemFromFilteredCampaignList={
                  removeitemFromFilteredCampaignList
                }
                languageFilterList={filteredLanguages}
                removeFromFilteredLanguageList={removeFromFilteredLanguageList}
                brandErrorFlag={brandErrorFlag}
                isLocalizedModulesSelected={isLocalizedModulesSelected}
                isSearchGroupChecked={isSearchGroupChecked}
                setIsSearchGroupChecked={setIsSearchGroupChecked}
                isDenovo={true}
              />
              <div className="row">
                {!isLocalizedModulesSelected && !isCreateNewClicked && (
                  <div
                    className={`${
                      selectedModule
                        ? "col-md-4 denovo-modules-v-list"
                        : "col-md-12"
                    } tacticsModules`}
                  >
                    {!selectedModule && (
                      <div>
                        <h2 className="masterTacticTitle">
                          {titleType === "initial"
                            ? `All Modules`
                            : "Search Results"}{" "}
                        </h2>
                        <p class="search-items">
                          {getFinalModulesCount()}{" "}
                          {getFinalModulesCount() === 1 ? "Item" : "Items"} |{" "}
                          {t("denovoModule.clickToViewGenInfo")}
                        </p>
                      </div>
                    )}

                    {!selectedModule && denovoModules.length > 0 && (
                      <TabPanel
                        titles={[
                          `Modules (${filterModules("original").length})`,
                          `Translated (${filterModules("translated").length})`,
                          `Localized (${filterModules("localized").length})`,
                        ]}
                        children={[
                          originalAndBasedOnModules,
                          translatedModules,
                          localizedOnModules,
                        ]}
                      />
                    )}
                    {!selectedModule && denovoModules.length === 0 && (
                      <div
                        className={`${
                          selectedModule || isCreateNewClicked
                            ? "col-md-12"
                            : "col-md-4"
                        } denovo-module-card`}
                      >
                        <CreateNewModuleCard
                          content={<span>Create New Module </span>}
                          colWidth={12}
                          onClickCreateCard={() => {
                            setIsCreateNewClicked(true);
                          }}
                          moduleTypes={moduleTypes}
                        />
                      </div>
                    )}
                    {selectedModule && drawModulesHeading()}
                    {selectedModule && renderTabSelectedModules()}
                  </div>
                )}
                {!isLocalizedModulesSelected &&
                  (selectedModule || isCreateNewClicked) && (
                    <div
                      className={`${
                        selectedModule
                          ? "col-md-8"
                          : isCreateNewClicked
                          ? "col-md-12"
                          : "col-md-8"
                      }`}
                    >
                      <DenovoGenericDetails
                        mode={
                          isCreateNewClicked && !selectedModule
                            ? "write"
                            : !isCreateNewClicked && selectedModule
                            ? "read"
                            : "write"
                        }
                        module={selectedModule}
                        data={props.data}
                        //onProceedClick={goToPrimaryClaimPage}
                        onProceedClick={startCreatingPlaceholders}
                        close={closeGenericDetails}
                        updateLocalModule={updateLocalModule}
                      />
                    </div>
                  )}
              </div>
              {isLocalizedModulesSelected && (
                <LocalizedModules
                  titleType={titleType}
                  localizedModules={localizedModules}
                  localizedModuleGroups={localizedModuleGroups}
                  data={props.data}
                  clearAllSearch={clearAllSearch}
                  searchGroupsOnly={
                    isLocalizedModulesSelected &&
                    (isSearchGroupChecked || titleType !== "searched")
                  }
                />
              )}
              {createModuleModal && showCreateModal()}
            </div>
          </div>
        )}
        {isStylisedModuleInitiated && (
          <>
            <div className="contentContainer existingTacticsContainer">
              <div className="row">
                <div className="col-md-12">
                  <CtaSelection
                    brand={stylizeChannelSelectionModalData?.brand}
                    customClassName="row pl-2 selectable-Cta-cards customScrollBar m-0"
                    campaignName={stylizeChannelSelectionModalData?.campaign}
                    channelType={selectedChannel}
                    cancelCreatingStylisedModule={cancelCreatingStylisedModule}
                    handleProceed={fetchModuleToStylize}
                    isStylisedModuleInitiated={isStylisedModuleInitiated}
                    sourceType={stylizeChannelSelectionModalData?.sourceType}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <CustomModal
          displayModal={stylizeModuleChannelSelection}
          hideModal={closestylizeChannelSelectionModal}
          size="sm"
          dilogClassName="alertModalSmall"
          backdrop={false}
        >
          <ChannelSelectionModalForStylizedModule
            closestylizeChannelSelectionModal={
              closestylizeChannelSelectionModal
            }
            sourceType={stylizeChannelSelectionModalData?.sourceType}
            brand={stylizeChannelSelectionModalData?.brand}
            campaign={stylizeChannelSelectionModalData?.campaign}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            cancelCreatingChannelMatrix={closestylizeChannelSelectionModal}
            assetDataType={undefined}
            //moduleId={moduleIdToCreateMatrix}
            onClickProceed={(flag) => {
              proceedToCtaSelectionScreen();
            }}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default DenovoModules;
