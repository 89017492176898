import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Container, Row, Col } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import BusinessRules from "../components/BusinessRules/BusinessRules";
import BusinessRulesSearch from "../components/BusinessRules/BusinessRulesSearch";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import {
  routes,
  getModuleInfoFromLocalStorage,
  moduleTypes,
  isThisMLRFeedbackFlow,
} from "../Uitls/Util";
import {
  getModulesBusinessRules,
  getExcludeModulesBusinessRules,
  saveBusinessRules,
  saveBusinessRuleNotes,
  getModuleDetailsById,
  getDenovoModulesByBrandAndCampaign,
  getLatestAnnotatedPdfFromVeeva,
} from "../services/apis";
import { toast } from "react-toastify";
import errComponentToRender from "../Uitls/apiErrorHandling";
import CustomModal from "../components/customModal";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { useHistory } from "react-router-dom";
import LogoutCTA from "../Pages/LogoutCTA";
import "../styles/pages/CreateModuleBusinessRules.scss";
import MlrFeedbackNav from "../components/MlrFeedback/MlrFeedbackNav";
import fullScreenIcon from "../images/fullScreenIcon.svg";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";
function CreateModuleGroups(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const frInstanceOthBrules = React.createRef();
  const [currentPage, setCurrentPage] = useState(routes.addBusinessRules);
  const [moduleId, setModuleId] = useState(undefined);
  //, setAssetId] = useState(undefined)
  const [moduleCode, setModuleCode] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [supportingClaimAtoms, setSupportingClaimAtoms] = useState([]);
  const [supportingClaimCol, setSupportingClaimCol] = useState([]);
  const [businessRulesData, setBusinessRulesData] = useState([]);
  const [selectedBox, setSelectedBox] = useState("");
  //const [standAloneModule, setStandAloneModule] = useState(false);

  const [displayStandAloneModal, setdisplayStandAloneModal] = useState(false);
  const [businessRulesNote, setBusinessRulesNote] = useState([]);
  const [contentExists, setContentExists] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [mlrModuleStatus, setMlrModuleStatus] = useState("");
  const [veevaDocId, setVeevaDocId] = useState(undefined);
  const [isAllowedToAddRule, toggleAllowedToAddRule] = useState(true);
  //For Addressing MLR Feedback scenarios only
  const [module, setModule] = useState(undefined);
  const [isMainContentExpanded, setMaincontentExpanded] = useState(false);
  const [isPdfContentExpanded, setPdfcontentExpanded] = useState(false);
  const pdfElementRef = useRef(null);
  const [pdfwidth, setpdfWidth] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(undefined);
  const [additionalInfo, setAdditionalInfo] = useState({
    note: "",
    noteCondition: "UsageInstructionsGuidelines",
    moduleId: "",
  });
  const [cannotExistWith, setCannotExistWith] = useState(undefined);

  //For save button enable disable while network in progress
  const [enableSave, setEnableSave] = useState(true);

  useEffect(() => {
    if (isMainContentExpanded) {
      setPdfcontentExpanded(false);
    } else if (isPdfContentExpanded) {
      setMaincontentExpanded(false);
    }
    calculatePdfWidth();
  }, [isMainContentExpanded, isPdfContentExpanded]);

  const calculatePdfWidth = () => {
    if (pdfElementRef && pdfElementRef.current) {
      setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    }
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        if (res) {
          setPdfUrl(res.data);
          return;
        }
        const errCoponent = errComponentToRender(errCode);
        claimsSaveErr(errCoponent);
      });
    }
  };

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  const getMoudlesData = () => {
    if (!moduleId) {
      return;
    }
    getModuleDetails();
  };

  const getTacticBusinessRuleModels = (
    brandName,
    campaign,
    businessRules,
    moduleType,
    sourceModuleId
  ) => {
    let payLoad = {
      brandName: brandName,
      campaign: campaign,
      moduleType: moduleType,
      excludeModules: [moduleId],
    };
    if (moduleType !== "Original") {
      payLoad.sourceModuleId = sourceModuleId;
    }
    if (payLoad) {
      getExcludeModulesBusinessRules(
        payLoad,
        moduleId,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(errorCode);
          } else {
            setSupportingClaimAtoms(response.data);
            parseAndSaveBusinessRules(businessRules, response.data);
          }
        }
      );
    } else {
      setIsloading(false);
    }
  };
  const setModuleStatus = (module) => {
    let status = "";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };
  const denovoBusinessRuleModules = (
    brand,
    campaign,
    market,
    businessRules
  ) => {
    if (!brand || !campaign || !market) {
      setIsloading(false);
      return;
    }
    getDenovoModulesByBrandAndCampaign(
      { brand, campaign, market, moduleId },
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          setSupportingClaimAtoms(response.data);
          parseAndSaveBusinessRules(businessRules, response.data);
          return;
        }
        showError(errCode);
      }
    );
  };

  const checkIshouldShowTheStandAlonePopupRequired = (businessRules) => {
    if (!businessRules || businessRules.length <= 0) {
      setdisplayStandAloneModal(true);
    }
  };
  const getModuleDetails = () => {
    setIsloading(true);
    getModuleDetailsById(moduleId, (response, err, errorCode) => {
      setModuleStatus(response.data);
      if (err) {
        //const errCoponent = errComponentToRender(errorCode);
        setIsloading(false);

        if (errorCode) {
          showError(errorCode);
        }
      } else {
        const data = response.data;
        if (data) {
          // toggleAllowedToAddRule(
          //   data.moduleType?.toLowerCase() === "translated"
          //     ? data.groupModulesVeevaPublishFlag
          //     : true
          // );
          if (isThisMLRFeedbackFlow()) {
            setModule(data);
            fetchAnnotatedPdf(data);
            calculatePdfWidth();
          }

          toggleAllowedToAddRule(!data.readOnlyBRFlag);
          if (data.moduleCode) {
            setModuleCode(data.moduleCode);
          }
          if (data.name) {
            setModuleName(data.name);
          }
          const businessRulesNotes = data.businessRuleNotes.filter(function (
            item
          ) {
            return item.noteCondition !== "UsageInstructionsGuidelines";
          });

          const guidelInesData = data.businessRuleNotes.filter(function (item) {
            return item.noteCondition === "UsageInstructionsGuidelines";
          });
          if (guidelInesData.length > 0) {
            setAdditionalInfo(guidelInesData[0]);
          }
          const businessRules = data.moduleBusinessRules;
          checkIshouldShowTheStandAlonePopupRequired(businessRules);
          if (businessRulesNotes) {
            setBusinessRulesNote(businessRulesNotes);
          }
          const moduleCreationMethod = data.creationMethodType;
          const moduleType = data.moduleType;
          const sourceModuleId = data.sourceModuleId;
          if (
            moduleCreationMethod &&
            moduleCreationMethod.toLowerCase() === moduleTypes.denovo
          ) {
            denovoBusinessRuleModules(
              data.brandName,
              data.campaign,
              data?.assetDetails?.market,
              businessRules
            );
          } else {
            const assetId = data.storageAssetId;
            getTacticBusinessRuleModels(
              data.brandName,
              data.campaign,
              businessRules,
              moduleType,
              sourceModuleId
            );
          }
        } else {
          setIsloading(false);
        }
      }
    });
  };

  const filterLeftSideDataById = (id, alreadyAddedModules) => {
    return alreadyAddedModules.filter((item) => {
      return id === item.id;
    });
  };

  const parseAndSaveBusinessRules = (data, alreadyAddedModules) => {
    let businessRules = [];
    if (data) {
      businessRules = data.map((item) => {
        let filtered = filterLeftSideDataById(
          item.targetModuleId,
          alreadyAddedModules
        );
        if (filtered && filtered.length > 0) {
          filtered = filtered[0];
        } else {
          filtered = {};
        }
        return {
          id: item.id,
          createdBy: item.createdBy,
          createdDateTime: item.createdDateTime,
          requestId: "",
          category: item.ruleCategory,
          condition: item.ruleCondition,
          targetModuleId: item.targetModuleId,
          status: item.status,
          moduleId: item.moduleId,
          name: filtered.name,
          moduleCode: filtered.moduleCode,
        };
      });
    }
    setBusinessRulesData(businessRules);
    setCountOnLeftPane(businessRules, alreadyAddedModules);
  };

  useEffect(() => {
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      setModuleId(moduleInfo.moduleId);
    }
  }, []);

  useEffect(() => {
    if (moduleId) {
      getMoudlesData();
    }
  }, moduleId);

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.data
    ) {
      setSupportingClaimAtoms(props.location.state.data);
    }
  }, []);

  const setCountOnLeftPane = (
    filteredSupportingClaimCol,
    supportingClaimAtoms
  ) => {
    const formattedData = supportingClaimAtoms.map((claim) => {
      let count = 0;
      filteredSupportingClaimCol.forEach((supportingClaim) => {
        if (supportingClaim.targetModuleId === claim.id) {
          count++;
        }
      });
      return { ...claim, count };
    });

    setSupportingClaimAtoms([].concat(formattedData));
  };

  const checkIfAllModulesRemovedFromTheBox = (boxName, businessRules) => {
    const remainingBusinessRules = businessRules.filter(function (item) {
      return item.condition === boxName;
    });
    if (remainingBusinessRules && remainingBusinessRules.length > 0) {
      return false;
    }
    return true;
  };
  const handleDelete = (e, itemToBeDeleted) => {
    e.stopPropagation();
    // const filteredSupportingClaimCol = businessRulesData.filter((claim) => {
    //   if (claim.requestId === itemToBeDeleted) {
    //     return false;
    //   }
    //   return true;
    // });

    // const formattedData = supportingClaimAtoms.map((claim) => {
    //   let count = 0;
    //   filteredSupportingClaimCol.forEach((supportingClaim) => {
    //     if (supportingClaim.targetModuleId === claim.id) {
    //       count++;
    //     }
    //   });
    //   return { ...claim, count };
    // });

    // setSupportingClaimAtoms(formattedData);

    // setBusinessRulesData([...filteredSupportingClaimCol]);

    const filteredSupportingClaimCol = businessRulesData.filter((claim) => {
      if (
        claim.targetModuleId === itemToBeDeleted.targetModuleId &&
        claim.condition === itemToBeDeleted.condition
      ) {
        return false;
      }
      return true;
    });

    setCountOnLeftPane(filteredSupportingClaimCol, supportingClaimAtoms);
    if (
      checkIfAllModulesRemovedFromTheBox(
        itemToBeDeleted.condition,
        filteredSupportingClaimCol
      )
    ) {
      setBusinessRulesNote(
        [...businessRulesNote].filter((note) => {
          return note.noteCondition !== itemToBeDeleted.condition;
        })
      );
    }

    setBusinessRulesData([...filteredSupportingClaimCol]);
  };

  const handleGroupHideModal = () => {
    setdisplayStandAloneModal(false);
  };

  const standAloneModal = async () => {
    //setStandAloneModule(true);
    setdisplayStandAloneModal(false);
    await postDatatoServer(true);
    // history.push(routes.additionalInfo);
  };

  const handleBusinessRulesSave = (textChange) => {
    let isNew = true;
    let data;
    businessRulesNote.forEach((note) => {
      if (note.noteCondition === selectedBox) {
        isNew = false;
      }
    });

    if (isNew) {
      data = [
        ...businessRulesNote,
        { note: textChange, noteCondition: selectedBox },
      ];
    } else {
      data = businessRulesNote.map((note) => {
        if (note.noteCondition === selectedBox) {
          return {
            id: note.id,
            note: textChange,
            noteCondition: note.noteCondition,
          };
        } else {
          return note;
        }
      });
    }
    setBusinessRulesNote(data);
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const createBusinessRulesPayload = () => {
    //debugger
    if (businessRulesData && businessRulesData.length > 1) {
      return [...businessRulesData].filter((rule) => {
        return (
          rule.ruleCondition !== "Standalone" && rule.condition !== "Standalone"
        );
      });
      //return businessRules
    } else {
      return businessRulesData;
    }
  };
  const postDatatoServer = async (isStandAlone = false) => {
    setIsloading(true);
    let payload = createBusinessRulesPayload();
    if (isStandAlone) {
      payload = [
        {
          category: "Module-To-Module",
          condition: "Standalone",
          targetModuleId: moduleId,
          status: "Active",
        },
      ];
    }
    setEnableSave(false);
    await saveBusinessRules(moduleId, payload)
      .then((response) => {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
        setIsloading(false);
        setEnableSave(true);
        //add/remove added/removed modules on left side
        if (!isStandAlone) {
          getMoudlesData();
        }
      })
      .catch((err) => {
        setIsloading(false);
        const errorCode = err.response && err.response.status;
        showError(errorCode);
        setEnableSave(true);
      });

    if (!isStandAlone) {
      setEnableSave(false);
      const dataWithInstructions = [...businessRulesNote, additionalInfo];
      await saveBusinessRuleNotes(moduleId, dataWithInstructions)
        .then((response) => {
          setIsloading(false);
          //if(!isStandAlone){
          getMoudlesData();
          setEnableSave(true);
          //}
        })
        .catch((err) => {
          setIsloading(false);
          const errorCode = err.response && err.response.status;
          showError(errorCode);
          setEnableSave(true);
        });
    }
  };

  const handleProceed = async () => {
    await postDatatoServer();
    // history.push(routes.additionalInfo);
    goToPublishModule();
  };
  const goToPublishModule = () => {
    if (moduleId) {
      history.push({
        pathname: isThisMLRFeedbackFlow()
          ? routes.mlrFeedbackPreview
          : routes.moduleDetails,
        state: {
          moduleId: moduleId,
          sourceType: props?.location?.state?.sourceType,
        },
      });
    }
  };

  const handleClose = () => {
    window.history.back();
  };
  const handleSave = () => {
    postDatatoServer();
  };

  const onBoxSelected = (event, id) => {
    if (id && id !== null) {
      setSelectedBox("");
      setTimeout(() => {
        setCssOnSelectedBox(id);
      }, 1);
    }
  };

  const setCssOnSelectedBox = (boxId) => {
    const othRules = document.getElementById("oth-rules");
    if (othRules !== null) {
      othRules.className = "editor-box bsrulexGuidelines selected-editor-box";
    }
  };

  const onChangeOtherRule = (event) => {
    let information = { ...additionalInfo };
    information.moduleId = moduleId;
    information.noteCondition = "UsageInstructionsGuidelines";
    information.note = event;
    setAdditionalInfo(information);
  };
  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        <HeaderCreateModule />
        {!isThisMLRFeedbackFlow() && (
          <CreateModuleNav
            currentPage={currentPage}
            moduleName={moduleName}
            moduleCode={moduleCode}
            btnSave={i18next.t("generalText.save")}
            MLRStatatus="true"
            handleProceed={handleProceed}
            handlePageClose={handleClose}
            handlePageSave={handleSave}
            moduleStatus={mlrModuleStatus}
            moduleId={moduleId}
            veevaDocId={veevaDocId}
            setMlrModuleStatus={setMlrModuleStatus}
            enableSave={enableSave}
            isNotDenovoModule={
              getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
              moduleTypes.denovo
            }
          />
        )}

        {isThisMLRFeedbackFlow() && (
          <MlrFeedbackNav
            module={module}
            currentPage={currentPage}
            handleDone={handleProceed}
            handlePageClose={handleClose}
          />
        )}
      </div>
      <Container fluid>
        <CircularProgressBar isloading={isloading} />
        <Row className="mt-3 ml-0 mr-0 mb-4">
          <Col
            className={
              isThisMLRFeedbackFlow()
                ? isMainContentExpanded
                  ? "col-12 col-md-12"
                  : "col-12 col-md-8"
                : "col-12 col-md-12"
            }
            style={{
              display:
                isThisMLRFeedbackFlow() && isPdfContentExpanded ? "none" : "",
            }}
          >
            <Row>
              <Col className="col-12 col-md-3 pageAtomsCol">
                <div
                  className={`${
                    isThisMLRFeedbackFlow()
                      ? isMainContentExpanded
                        ? ""
                        : "add-mlr-businessrules-scrollbale custonScrollBar"
                      : ""
                  }`}
                >
                  <BusinessRulesSearch
                    moduleCode={moduleCode}
                    supportingClaimAtoms={supportingClaimAtoms}
                    selectedBox={selectedBox}
                    businessRulesData={businessRulesData}
                    setBusinessRulesData={setBusinessRulesData}
                    setSupportingClaimAtoms={setSupportingClaimAtoms}
                    setContentExists={setContentExists}
                    allowedToAddRule={isAllowedToAddRule}
                    setCannotExistWith={setCannotExistWith}
                  />
                </div>
              </Col>

              <Col className="pageContentCol">
                {isThisMLRFeedbackFlow() && (
                  <div style={{ float: "right" }}>
                    <img
                      src={fullScreenIcon}
                      alt=""
                      className="expandGroupsIcon"
                      onClick={() =>
                        setMaincontentExpanded(!isMainContentExpanded)
                      }
                    />
                  </div>
                )}
                <div
                  className={`${
                    isThisMLRFeedbackFlow()
                      ? isMainContentExpanded
                        ? ""
                        : "add-mlr-businessrules-scrollbale custonScrollBar"
                      : ""
                  }`}
                >
                  <Row className="">
                    <Col>
                      <h1 className="rulesTitle">
                        <span className="rulesBrdr">Define Business Rule</span>{" "}
                        <button className="btn btn-link pl-0">Preview</button>
                        <span className="headerModuleInfo">
                          {/* Module #321 | Efficacy in both primary and Secondary CV
                    prevention patients */}
                          {moduleCode} | {moduleName}
                        </span>
                      </h1>
                    </Col>
                    <Col className="text-right">
                      <p className="groupSubTitleText">
                        {i18next.t(
                          "businessRules.doubleClickOnModuleToAddbRule"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <BusinessRules
                      row={businessRulesData}
                      setSelectedBox={setSelectedBox}
                      handleDelete={handleDelete}
                      selectedBoxIndex={selectedBox}
                      businessRulesNote={businessRulesNote}
                      handleBusinessRulesSave={handleBusinessRulesSave}
                      contentExists={contentExists}
                      setContentExists={setContentExists}
                      allowedToAddRule={isAllowedToAddRule}
                      setCannotExistWith={setCannotExistWith}
                      cannotExistWith={cannotExistWith}
                    />
                    <Col
                      className="mb-3  col-lg-4 col-md-4 col-12"
                      onClick={(event) => {
                        onBoxSelected(event, "oth-rules");
                      }}
                    >
                      {additionalInfo.note && (
                        <span className="editor-label">
                          {i18next.t("additionalInfo.usageOrGuidelines")}
                        </span>
                      )}
                      <div
                        id="oth-rules"
                        className="editor-box bsrulexGuidelines"
                      >
                        <FroalaEditor
                          forwardedRef={frInstanceOthBrules}
                          placeHolder={i18next.t(
                            "additionalInfo.usageOrGuidelines"
                          )}
                          selectedAtom={additionalInfo && additionalInfo.note}
                          handleModelChange={onChangeOtherRule}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          {isThisMLRFeedbackFlow() ? (
            <Col
              className={` ${
                isPdfContentExpanded ? "col-md-12" : "col-12 col-md-4"
              }`}
              style={{ display: isMainContentExpanded ? "none" : "" }}
              ref={pdfElementRef}
            >
              <img
                src={fullScreenIcon}
                alt=""
                className="groupPdfExpand"
                onClick={() => setPdfcontentExpanded(!isPdfContentExpanded)}
              />
              <div className="previewFeedbackBussinessRulesScroll customScrollBar mlr-groups-pdf">
                <RenderMlrFeedback pdfFileUrl={pdfUrl} pdfWidth={pdfwidth} />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>

      <CustomModal
        displayModal={displayStandAloneModal}
        hideModal={handleGroupHideModal}
        title={"Is this a stand alone module"}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <p className="titleStandAlone">
          <strong>Is this a stand alone module?</strong>
        </p>
        <p>Note: Stand alone modules cannot be used with other modules</p>
        <div className="d-flex justify-content-between">
          <button className="btn btn-link pl-0" onClick={standAloneModal}>
            YES
          </button>
          <button className="btn btn-primary" onClick={handleGroupHideModal}>
            NO
          </button>
        </div>
      </CustomModal>
    </>
  );
}
export default CreateModuleGroups;
