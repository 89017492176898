import React from "react";

const CreateNewModuleCard = ({
  content,
  colWidth,
  onClickCreateCard,
  shouldBeDisbaled, //
  moduleTypes,
}) => {
  return (
    <div
      className={`col-md-${colWidth} module-card`}
      id="create-new-module-card"
    >
      <div
        className={`createNewModuleTactics d-flex align-items-center justify-content-center ${
          shouldBeDisbaled ? "createNewModuleTactics-disabled" : ""
        }`}
      >
        <button
          className="btn btn-link"
          onClick={() => {
            if (!shouldBeDisbaled) {
              onClickCreateCard();
            }
          }}
          disabled={shouldBeDisbaled}
        >
          <p className="unlockCreateModule">
            <span className="unlockAddModule">+</span>
            {/* {moduleTypes?.denovo === "denovo" && (
              <span>Unlock Master Tactic And </span>
            )} */}
            {content}
          </p>
        </button>
      </div>
    </div>
  );
};

export default CreateNewModuleCard;
