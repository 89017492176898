import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Menu from "../TranslateClaims/Menu";

import translateIcon from "../../../images/translateIcon.svg";
import translateTick from "../../../images/translateTick.svg";
import commentIcon from "../../../images/icon-comment.svg";

import "../../../styles/components/EmailSubjectLine.scss";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import Comments from "../Comments";

const EmailSubjectLine = ({
  subjectLines,
  isAnyToggleOn,
  initiateTranslationFor,
  getCommentBubbleFor,
  addComment,
  comments,
  showCommentsFlag,
  setShowCommentsFlag,
  rowIndex,
  getCommentsData,
}) => {
  const subjectlineRef = useRef(null);
  const [sunjectLineId, setSunjectLineId] = useState();

  const addAComment = (selectedText) => {
    const componentId = subjectLines[sunjectLineId].id;
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: componentId,
      componentId: componentId,
    };
    addComment(commentObject);
  };

  return (
    <div className="" ref={subjectlineRef}>
      {subjectLines.map((item, index) => (
        <li className="catchline" key={index}>
          <Row className="align-items-center mt-1 mb-1">
            <Col className="col-md-10">
              <div className="catchline-itm d-flex align-items-center">
                {!isAnyToggleOn && item.translate === "2" && (
                  <div>
                    <img src={translateTick} alt="Translate" />
                  </div>
                )}
                <div
                  className="ml-2"
                  onClick={() => setSunjectLineId(index)}
                  id={`subject-line${index}`}
                >
                  <div>
                    {/* {isAnyToggleOn ? item.originalText : item.text}  */}
                    {item.text}
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      display: item.disclaimer ? "block" : "none",
                    }}
                  >
                    Disclaimer:
                    {/* {isAnyToggleOn ? item.originalDisclaimer : item.disclaimer} */}
                    {item.disclaimer}
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-md-2">
              {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                <button
                  className="btn btn-link fab-translate "
                  onClick={() => {
                    initiateTranslationFor([item], "subjectline");
                  }}
                >
                  <img src={translateIcon} alt="Translate" />
                </button>
              )}
              {getCommentBubbleFor("subjectline", item.id)}
              {showCommentsFlag && rowIndex === index && (
                <Comments
                  moduleId={module.id}
                  componentId={item.id}
                  childComponentId=""
                  comments={comments}
                  currentClass={
                    hasUserLoggedInAsReviewer()
                      ? "commentsCatchline"
                      : "commentsCatchline-translator"
                  }
                  commentsScrollClass={
                    rowIndex === 0 ? "scrollCommentschannelAttrPos1" : undefined
                  }
                  commentsPopupCurrentState={showCommentsFlag}
                  commentsPopupUpdateState={setShowCommentsFlag}
                  getCommentsData={getCommentsData}
                />
              )}
            </Col>
          </Row>
        </li>
      ))}
      {!isAnyToggleOn && (
        <Menu
          outerRef={subjectlineRef}
          compClass={`subject-line${sunjectLineId}`}
          addComment={addAComment}
        />
      )}
    </div>
  );
};
export default EmailSubjectLine;
