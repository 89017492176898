import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Uitls/PrivateRoute";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ForgotPassword from "./Pages/ForgotPassword";
import Dashboard from "./Pages/Dashboard";
import ExistingTacticsAndModules from "./Pages/ExistingTacticsAndModules";
//import ExistingTacticsAndModules from "./Pages/UploadMasterAsset";
import CreateModule from "./Pages/CreateModule";
import CreateModule1 from "./Pages/CreateModule1";
import CreateModulePrimaryClaim from "./Pages/CreateModuleStep2";
import CreateModuleStep3Grouping from "./Pages/CreateModuleStep3Grouping";
import AddChannels from "./Pages/CreateModuleStep4";
import BusinessRules from "./Pages/CreateModuleBusinessRules";
import AdditionalInfo from "./Pages/AdditionalInfo";
import MLRSubmissionPackage from "./Pages/MLRSubmissionPackage";
import ModuleDetails from "./Pages/ModuleDetails";
import DashboardTranslate from "./Pages/DashboardTranslate";
import DashboardReviewer from "./Pages/DashboardReviewer";
import TranslateClaims from "./Pages/TranslateClaims";
import TranslateChannel from "./Pages/TranslateChannel";
import NotFound from "./Pages/NotFound";
import ModulePreviewMlrFeedbackFlow from "./Pages/ModulePreviewMlrFeedbackFlow";
import TranslateAdditionalInfo from "./Pages/TranslateAdditionalInfo";
import { addProcessIds, clearProcessIds } from "./Uitls/polling";
import { getInProgressIds, getSession } from "./Uitls";
import ErrComponentToRender from "./Uitls/apiErrorHandling";
import {
  AssetDeconstructSucess,
  AssetDeconstructFailur,
} from "./components/toastify";
import "./App.scss";
import { routes } from "./Uitls/Util";
import AppContext from "./Uitls/AppContext";
import AppContextProvider from "./Uitls/AppContextProvider";
import Search from "./Pages/Search";
import { Trans } from "react-i18next";
import TranslateBusinessRules from "./Pages/TranslateBusinessRules";
import TemplatePreview from "./Pages/TemplatePreivew";
import Editor from "./components/ModuleEditor/Editor";
export const notify = (id, status, type, assetname) => {
  if (type === "success") {
    toast.success(
      <AssetDeconstructSucess id={id} status={status} assetname={assetname} />
    );
  } else if (type === "error") {
    toast.error(
      <AssetDeconstructFailur id={id} status={status} assetname={assetname} />,
      { autoClose: 8000 }
    );
  }
};

export const apiAuthError = (errorCode) => {
  getSession()
    .then(() => {
      const errCoponent = ErrComponentToRender(errorCode);
      toast.error(errCoponent);
    })
    .catch((err) => {});
};

function App() {
  const [userType, setUserType] = useState("");

  useEffect(() => {
    setUserType(window.localStorage.getItem("userRole"));
    getSession()
      .then(() => {
        const getPeningIds = async () => {
          const ids = await getInProgressIds();
          addProcessIds(ids);
        };
        getPeningIds();
      })
      .catch((err) => {
        console.log("Error");
      });
    return () => {
      clearProcessIds();
    };
  }, []);

  return (
    //<AppContextProvider>
    <div className="App">
      <StylesProvider injectFirst>
        <ToastContainer />
        <Switch>
          <Route path="/" component={Login} exact />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute
            path={routes.search}
            component={Search}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/upload-master-asset"
            component={ExistingTacticsAndModules}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/create-module"
            component={CreateModule1}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/create-module-primary-claim"
            component={CreateModulePrimaryClaim}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/create-module-grouping"
            component={CreateModuleStep3Grouping}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/add-channels"
            component={AddChannels}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/business-rules"
            component={BusinessRules}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path={routes.additionalInfo}
            component={AdditionalInfo}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path={routes.mlrSubmission}
            component={MLRSubmissionPackage}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path="/module-details"
            component={ModuleDetails}
            canAccessedBy={["users", "translator", "agency"]}
          />
          <PrivateRoute
            path={routes.mlrFeedbackPreview}
            component={ModulePreviewMlrFeedbackFlow}
            canAccessedBy={["users"]}
          />

          <PrivateRoute
            path="/dashboard-translate"
            component={DashboardTranslate}
            canAccessedBy={["translator", "agency"]}
          />

          <PrivateRoute
            path="/dashboard-reviewer"
            component={DashboardReviewer}
            canAccessedBy={["users"]}
          />

          <PrivateRoute
            path={routes.translateClaims}
            component={TranslateClaims}
            canAccessedBy={["users", "translator", "agency"]}
          />
          <PrivateRoute
            path={routes.translateChannel}
            component={TranslateChannel}
            canAccessedBy={["users", "translator", "agency"]}
          />
          <PrivateRoute
            path={routes.translateBusinessRules}
            component={TranslateBusinessRules}
            canAccessedBy={["users", "translator", "agency"]}
          />
          <PrivateRoute
            path={routes.translateAdditionalInfo}
            component={TranslateAdditionalInfo}
            canAccessedBy={["users", "translator", "agency"]}
          />
          <Route path="/signup" component={Signup} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute
            path={routes.templatePreview}
            component={TemplatePreview}
            canAccessedBy={["users"]}
          />
          <PrivateRoute
            path={routes.moduleEditor}
            component={Editor}
            canAccessedBy={["users"]}
          />
          <PrivateRoute component={NotFound} />
        </Switch>
      </StylesProvider>
    </div>
    //</AppContextProvider>
  );
}
export default App;
