import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import { translatorRating } from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import ReactStars from "react-rating-stars-component";
import "../styles/components/RateTranslator.scss";

import ratingsFilled from "../images/ratingsFilled.svg";
import ratingsHalf from "../images/ratingsHalf.svg";
import ratingsEmpty from "../images/ratingsEmpty.svg";

const RateTranslator = (props) => {
  const { hideRatingsModalPopup } = props;
  const { t } = useTranslation();
  const [currentRating, setCurrentRating] = useState("");

  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const submitTranslatorRating = () => {
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );

    translatorRating(
      moduleInfoFromLocalStorage.jobId,
      currentRating,
      (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          showError(errCoponent);
        } else {
          hideRatingsModalPopup();
          window.localStorage.removeItem("translateModuleInfo");
        }
      }
    );
  };

  const ratingsIcon = {
    classNames: "translatorStars",
    size: 24,
    count: 5,
    value: 0,
    isHalf: true,
    emptyIcon: <img src={ratingsEmpty} alt="" className="mr-2" />,
    halfIcon: <img src={ratingsHalf} alt="" className="mr-2" />,
    filledIcon: <img src={ratingsFilled} alt="" className="mr-2" />,
    onChange: (newValue) => {
      setCurrentRating(newValue);
    },
  };

  console.log(currentRating, currentRating);
  return (
    <>
      <h1 className="rateTranslatorTitle">Rate Translator</h1>
      <p>How happy were you with the quality of translation?</p>

      <div className="text-center">
        <ReactStars {...ratingsIcon} />

        <button
          className="btn btn-primary translateButton"
          onClick={() => {
            submitTranslatorRating();
          }}
          disabled={currentRating === "" ? true : false}
        >
          PROCEED
        </button>
      </div>
    </>
  );
};

export default RateTranslator;
