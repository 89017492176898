import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import parse from "html-react-parser";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import Switch from "@material-ui/core/Switch";
import { Container, Row, Col } from "react-bootstrap";
import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import SupportingGroup from "../components/addSuppClaimGroup";
import {
  routes,
  getModuleInfoFromLocalStorage,
  isThisMLRFeedbackFlow,
  moduleTypes,
} from "../Uitls/Util";
import expandIcon from "../images/expandIcon.svg";
import searchIcon from "../images/search.svg";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import errComponentToRender from "../Uitls/apiErrorHandling";
import LogoutCTA from "../Pages/LogoutCTA";
import {
  getModuleDetailsById,
  saveGroupsWithClaims,
  deleteClaimGroups,
  getLatestAnnotatedPdfFromVeeva,
} from "../services/apis";

import "../styles/pages/CreateModuleStep3Grouping.scss";
import { object } from "prop-types";
import MlrFeedbackNav from "../components/MlrFeedback/MlrFeedbackNav";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";
import fullScreenIcon from "../images/fullScreenIcon.svg";
function CreateModuleGroups(props) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(routes.addGroups);
  const [supportingClaimAtoms, setSupportingClaimAtoms] = useState([]);
  const [supportingClaimCol, setSupportingClaimCol] = useState([]);
  const [selectedBox, setSelectedBox] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [searchInAtom, setSearchInAtom] = useState(null);
  const [masterToggle, setMasterToggle] = useState(false);
  const [groupType, setGroupType] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [supportingClaimList, setSupportingClaimList] = useState([]);
  let history = useHistory();
  const [isloading, setIsloading] = useState(false);
  //const [moduleId, setModuleId] = useState(window.$moduleId);
  const [moduleCode, setModuleCode] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [shouldDisableProceedButton, setShouldDisableProceedButton] =
    useState(true);
  const [moduleId, setModuleId] = useState(undefined);

  const [mlrModuleStatus, setMlrModuleStatus] = useState("");
  const [veevaDocId, setVeevaDocId] = useState(undefined);

  //For Addressing MLR Feedback scenarios only
  const [module, setModule] = useState(undefined);
  const [isMainContentExpanded, setMaincontentExpanded] = useState(false);
  const [isPdfContentExpanded, setPdfcontentExpanded] = useState(false);
  const pdfElementRef = useRef(null);
  const [pdfwidth, setpdfWidth] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(undefined);
  //For save button enable disable while network in progress
  const [enableSave, setEnableSave] = useState(true);

  useEffect(() => {
    if (isMainContentExpanded) {
      setPdfcontentExpanded(false);
    } else if (isPdfContentExpanded) {
      setMaincontentExpanded(false);
    }
    calculatePdfWidth();
  }, [isMainContentExpanded, isPdfContentExpanded]);

  const calculatePdfWidth = () => {
    if (pdfElementRef && pdfElementRef.current) {
      setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    }
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        if (res) {
          setPdfUrl(res.data);
          return;
        }
        const errCoponent = errComponentToRender(errCode);
        claimsSaveErr(errCoponent);
      });
    }
  };

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useEffect(() => {
    // setBtnOptions(buttonOptions())
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      setModuleId(moduleInfo.moduleId);
    }
  }, []);

  useEffect(() => {
    if (moduleId) {
      getGroupingData(moduleId);
    }
  }, [moduleId]);

  const getGroupingData = (currentModuleId) => {
    getModuleDetailsById(currentModuleId, (response, error, errorCode) => {
      if (response && !error && !errorCode) {
        setModuleStatus(response.data);
        handleModuleData(response.data);
        if (isThisMLRFeedbackFlow()) {
          setModule(response.data);
          fetchAnnotatedPdf(response.data);
          calculatePdfWidth();
        }
      }
    });
  };
  const setModuleStatus = (module) => {
    let status = "";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };

  const handleModuleData = (response) => {
    if (!response) {
      return;
    }
    if (isThisMLRFeedbackFlow()) {
      setModule(response);
    }
    if (response.id) {
      setModuleId(response.id);
    }
    if (response.moduleCode) {
      setModuleCode(response.moduleCode);
    }

    if (response.name) {
      setModuleName(response.name);
    }
    setSupportingClaimList(
      response.moduleClaims.filter((x) => x.type === "Supporting")
    );

    let resoponseData = response.moduleGroups.filter((group) => {
      if (group.groupClaims) {
        return group;
      }
    });
    //const resoponseData = response.moduleGroups.map((item) => {
    resoponseData = [...resoponseData].map((item) => [
      {
        id: item.id,
        group: item.name,
        type: item.requirementType,
        color:
          response.groupToggle === true
            ? "#FAE3D4"
            : item.requirementType === "Mandatory"
            ? "#FFC7C7"
            : "#BFDE9B",
        currentId: item.id,
        data: item.groupClaims.map((item) => ({
          id: item.id,
          claim: item.claim,
          moduleClaimId: item.moduleClaimId,
        })),
      },
    ]);
    setSupportingClaimCol(resoponseData);
    setMasterToggle(response.groupToggle);
  };

  // useEffect(() => {
  //   getGroupingData();
  // }, []);
  const handleSave = () => {
    postDataToServer();
  };

  const postDataToServer = async () => {
    const payload = groupClaimsPayload();
    if (payload) {
      setIsloading(true);
      setEnableSave(false);
      await saveGroupsWithClaims(moduleId, payload)
        .then((response) => {
          //cb(response);
          toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
            autoClose: 2000,
            position: "top-center",
          });
          setIsloading(false);
          //handleCreateChannels(response.data)
          getGroupingData(moduleId);
          setEnableSave(true);
        })
        .catch((err) => {
          setIsloading(false);
          const errorCode = err.response && err.response.status;
          setEnableSave(true);
          // showError(errorCode)
          //cb("", err, errorCode);
        });
    }
  };
  const groupClaimsPayload = () => {
    let groups = [];
    for (const sLine of supportingClaimCol) {
      const groupObject = {
        //id : sLine[0].currentId,
        name: sLine[0].group,
        requirementType: sLine[0].type,
        requestId: sLine[0].id,
        claims: sLine[0].data.map((item) => {
          return {
            moduleClaimId: item.moduleClaimId ? item.moduleClaimId : item.id,
          };
        }),
      };
      groups = groups.concat(groupObject);
    }
    if (groups.length > 0) {
      return { groupToggle: masterToggle, groups: groups };
    }
    return { groupToggle: masterToggle, groups: [] };
  };

  const buttonOptions = () => {
    const options = {
      moduleName: moduleName,
      moduleId: moduleId,
      moduleCode: moduleCode,
      btnSave: i18next.t("generalText.save"),
      disableProceed: shouldDisableProceedButton,
      handleProceed: handleProceed,
      handlePageClose: handleClose,
      handlePageSave: handleSave,
    };
    return options;
  };

  const handleClose = () => {
    window.history.back();
  };
  const handleProceed = async () => {
    postDataToServer();

    // await postDataToServer()
    //move to the next page
    history.push(routes.addBusinessRules);
  };

  const addSuppClaimCol = () => {
    //setShouldDisableProceedButton(true);
    setSupportingClaimCol([
      ...supportingClaimCol,
      [
        {
          id: supportingClaimCol.length + 1,
          currentId: null,
          group: `Group ${supportingClaimCol.length + 1}`,
          type: supportingClaimCol.length === 0 ? "Mandatory" : "Optional",
          color: supportingClaimCol.length === 0 ? "#ffc7c7" : "#BFDE9B",
          data: [],
        },
      ],
    ]);
  };
  const onSuppAtomDoubleClick = (item) => {
    const { id, claim } = item;
    let isAvailable = false;
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated =
        supportingClaimCol && supportingClaimCol[selectedRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated[selectedBox];
      setShouldDisableProceedButton(false);
      boxToBeUpdated.data.forEach((item) => {
        if (item.id === id) {
          isAvailable = true;
        }
      });
      if (isAvailable) {
        return;
      }

      const newText = { id: id, claim: claim };
      boxToBeUpdated.data.push(newText);
      setSupportingClaimCol([...supportingClaimCol]);
    }
  };

  const handleDelete = (e, itemToBeDeleted, rowIndex) => {
    e.stopPropagation();
    deleteClaimGroups(
      moduleId,
      [itemToBeDeleted.id],

      (response, error, errorCode) => {
        setIsloading(false);

        if (!error && !errorCode) {
          //getGroupingData();

          const row = supportingClaimCol[rowIndex][0];
          const data = row.data;
          const filteredData = data.filter((item) => {
            return item.id !== itemToBeDeleted.id;
          });
          row.data = filteredData;

          if (filteredData.length === 0) {
            supportingClaimCol.splice(rowIndex, 1);
            setSelectedBox(-1);
            setSelectedRow(-1);
          }
          setSupportingClaimCol([...supportingClaimCol]);
          return;
        }

        //show error message
        // showError(errorCode)
      }
    );
  };
  useEffect(() => {
    if (supportingClaimCol.length === 0) {
      setMasterToggle(false);
      setShouldDisableProceedButton(true);
    } else {
      const allValues = Object.values(supportingClaimCol);
      if (allValues.length !== 0) {
        let isEmpty = true;
        for (const values of allValues) {
          if (values && values.length > 0) {
            for (const value of values) {
              if (value && value.data && value.data.length > 0) {
                isEmpty = false;
                break;
              }
            }
          }
        }
        setShouldDisableProceedButton(isEmpty);
        // setMasterToggle(isEmpty);
      } else {
        setShouldDisableProceedButton(true);
        //setMasterToggle(false);
      }
    }
  }, [supportingClaimCol]);

  const handleOnChange = (e, rowIndex) => {
    if (e.target.value === "Mandatory") {
      supportingClaimCol.forEach((claim) => {
        const row = claim[0];
        row.type = "Optional";
        row.color = "#BFDE9B";
      });
    }
    const row = supportingClaimCol[rowIndex][0];
    row.type = e.target.value;
    row.color = e.target.value === "Optional" ? "#BFDE9B" : "#ffc7c7";

    //setGroupType(row.type);
    //setGroupType(row.color);
    setSupportingClaimCol([...supportingClaimCol]);
  };

  const makeAllOptional = () => {
    const newMasterToggleState = !masterToggle;
    if (newMasterToggleState) {
      const claims = [...supportingClaimCol];
      claims.forEach((claim) => {
        const obj = claim[0];
        obj.type = "Optional";
        obj.color = "#FAE3D4";
      });
      setMasterToggle(newMasterToggleState);
      setSupportingClaimCol([...claims]);
    } else {
      const claims = [...supportingClaimCol];
      claims.forEach((claim, index) => {
        const obj = claim[0];
        obj.type = "Optional";
        obj.color = "#BFDE9B";
        // if (index === 0) {
        //   const obj = claim[0];
        //   obj.type = "Mandatory";
        //   obj.color = "#ffc7c7";
        // } else {
        //   const obj = claim[0];
        //   obj.type = "Optional";
        //   obj.color = "#BFDE9B";
        // }
      });
      setMasterToggle(newMasterToggleState);
      setSupportingClaimCol([...claims]);
    }
  };
  const searchSuppAtoms = (event) => {
    setSearchedText(event.target.value);
  };
  const filteredSupportingClaimAtoms = supportingClaimAtoms.filter((claim) => {
    if (searchedText === "" || searchedText.trim() === "") {
      return true;
    }
    // const text = searchedText.trim();
    return claim &&
      claim.atomText &&
      claim.atomText.toLowerCase().indexOf(searchedText.toLowerCase()) > -1
      ? true
      : false;
  });

  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        <HeaderCreateModule />
        {!isThisMLRFeedbackFlow() && (
          <CreateModuleNav
            currentPage={currentPage}
            moduleName={moduleName}
            moduleCode={moduleCode}
            btnProceed={i18next.t("generalText.proceed")}
            btnClose={i18next.t("generalText.close")}
            btnSave={i18next.t("generalText.save")}
            disableProceed={shouldDisableProceedButton}
            handleProceed={handleProceed}
            handlePageClose={handleClose}
            handlePageSave={handleSave}
            moduleStatus={mlrModuleStatus}
            moduleId={moduleId}
            veevaDocId={veevaDocId}
            setMlrModuleStatus={setMlrModuleStatus}
            enableSave={enableSave}
            isNotDenovoModule={
              getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
              moduleTypes.denovo
            }
          />
        )}

        {isThisMLRFeedbackFlow() && (
          <MlrFeedbackNav
            module={module}
            disableDone={shouldDisableProceedButton}
            currentPage={currentPage}
            handleDone={handleProceed}
            handlePageClose={handleClose}
          />
        )}
      </div>

      <Container fluid>
        <CircularProgressBar isloading={isloading} />
        <Row className="mt-3 ml-0 mr-0 mb-4">
          <Col
            className={
              isThisMLRFeedbackFlow()
                ? isMainContentExpanded
                  ? "col-12 col-md-12"
                  : "col-12 col-md-8"
                : "col-12 col-md-12"
            }
            style={{
              display:
                isThisMLRFeedbackFlow() && isPdfContentExpanded ? "none" : "",
            }}
          >
            <Row className="add-grouping-content">
              <Col className="col-12 col-md-3 pageAtomsCol">
                <p className="groupSupportClaimTitle">Supporting Claims</p>
                <p>Double click to Select</p>
                <div className="suppClaimSearch">
                  <div className="d-flex">
                    <img src={searchIcon} alt="" className="searchIconGroup" />
                    <input
                      type="text"
                      placeholder="Search for supporting claim"
                      className="globalSearchInput"
                      value={searchedText}
                      onChange={(e) => searchSuppAtoms(e)}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    isThisMLRFeedbackFlow()
                      ? isMainContentExpanded
                        ? ""
                        : "add-mlr-group-supporting-scrollbale custonScrollBar"
                      : ""
                  }`}
                >
                  <ul className="supClaimList mt-4">
                    {supportingClaimList.map((item, index) => (
                      <li
                        key={item.id}
                        onDoubleClick={() => onSuppAtomDoubleClick(item)}
                      >
                        <div className="leftAtomsList">
                          <img
                            src={expandIcon}
                            alt=""
                            className="groupAtomsExpand"
                          />{" "}
                          {parse(item.claim)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col className="pageContentCol">
                {isThisMLRFeedbackFlow() && (
                  <div style={{ float: "right" }}>
                    <img
                      src={fullScreenIcon}
                      alt=""
                      className="expandGroupsIcon"
                      onClick={() =>
                        setMaincontentExpanded(!isMainContentExpanded)
                      }
                    />
                  </div>
                )}
                <div
                  className={`${
                    isThisMLRFeedbackFlow()
                      ? isMainContentExpanded
                        ? ""
                        : "add-mlr-group-scrollbale custonScrollBar"
                      : ""
                  }`}
                >
                  <Row>
                    <Col>
                      <h1 className="groupsTitle">
                        Groups{" "}
                        <span className="groupSubTitleText">
                          Click on the desired Supporting claims
                        </span>
                      </h1>
                    </Col>
                    <Col className="text-right">
                      <div>
                        <span className="groupMarkAll">
                          Mark all as - Any 1
                        </span>
                        <Switch
                          className="filterRightMargin"
                          inputProps={{ "aria-label": "primary checkbox" }}
                          checked={masterToggle}
                          onChange={makeAllOptional}
                        />
                      </div>
                      <p className="groupSubTitleText">
                        Note: This will over ride any selected dependency
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {supportingClaimCol.map((row, index) => (
                      <SupportingGroup
                        row={row}
                        rowIndex={index}
                        key={index}
                        setSelectedBox={setSelectedBox}
                        setSelectedRow={setSelectedRow}
                        handleDelete={handleDelete}
                        handleOnChange={handleOnChange}
                        groupType={groupType}
                        selectedBoxIndex={selectedBox}
                        selectedRowIndex={selectedRow}
                        masterToggle={masterToggle}
                      />
                    ))}
                    <Col xs md="4" lg="4">
                      <div className="addGroupBtn">
                        <button
                          className="btn btn-link addGroupBtnText"
                          onClick={() => {
                            addSuppClaimCol();
                          }}
                        >
                          <AddIcon fontSize="large" />
                          <span>Add Group</span>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          {isThisMLRFeedbackFlow() ? (
            <Col
              className={` ${
                isPdfContentExpanded ? "col-md-12" : "col-12 col-md-4"
              }`}
              style={{ display: isMainContentExpanded ? "none" : "" }}
              ref={pdfElementRef}
            >
              <img
                src={fullScreenIcon}
                alt=""
                className="groupPdfExpand"
                onClick={() => setPdfcontentExpanded(!isPdfContentExpanded)}
              />
              <div className="previewFeedbackGroupsScroll customScrollBar mlr-groups-pdf">
                <RenderMlrFeedback pdfFileUrl={pdfUrl} pdfWidth={pdfwidth} />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
}
export default CreateModuleGroups;
