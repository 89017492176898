import React from "react";
import backButton from "../images/chevron-left-matrix.svg";
import "../styles/components/ChannelMatrixContentBlock.scss";
import "../styles/components/Headers/ModalHeader.scss";
const ModalHeader = (props) => {
  const {
    title,
    subtitle,
    subtitleIcon,
    previous,
    headerData,
    handleBack,
    isBottomBorderNeeded = false,
  } = props;

  const getFirstWordFromTitle = () => {
    return title?.split(" ")[0] + " ";
  };

  const getTitleAfterFirstSpace = () => {
    const indexOfSpace = title.indexOf(" ");

    if (indexOfSpace === -1) {
      return title;
    }

    return title.substring(indexOfSpace + 1);
  };

  const isBackButtonNeeded = () => {
    if (!handleBack || !backButton) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <div
        className={`row align-items-center ${
          isBottomBorderNeeded ? "modal-header-container" : ""
        }`}
      >
        <div
          className={`${
            Object.keys(headerData).length > 0 ? "col-md-5" : "col-md-12"
          }`}
        >
          <div className="d-flex ">
            {isBackButtonNeeded() && (
              <div>
                <img
                  className="modal-header-back-button"
                  src={backButton}
                  alt=""
                  width="10"
                  onClick={() => {
                    handleBack(previous);
                  }}
                />
              </div>
            )}
            <div className={`${isBackButtonNeeded() ? "pl-2" : ""}`}>
              <>
                <div className="modal-header-title">
                  <strong>{getFirstWordFromTitle()} </strong>
                  {getTitleAfterFirstSpace()}
                </div>
                <span className="text-nowrap pl-1 modal-header-subtitle">
                  <img
                    className="modal-header-subtitle-icon"
                    src={subtitleIcon}
                    alt=""
                  />
                  {subtitle}
                </span>
              </>
            </div>
          </div>
        </div>

        {Object.keys(headerData).length > 0 && (
          <div className="col-md-7">
            <div className="d-flex justify-content-end matrixModalHeaderInfo">
              {Object.keys(headerData)?.map((el, index) => {
                return (
                  <div className="textBlockHeader">
                    <span className="d-block">{el}</span>
                    {headerData[el]}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalHeader;
