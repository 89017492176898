import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";

const SubjectlineAndDisclaimerColumn = ({ dataItems, disableSave }) => {
  const [arbitararyVar, setArbitraryVar] = useState(Math.random());
  const [isSubjectlineAdded, setIsSubjectlineAdded] = useState(false);
  const [isDisclaimerAdded, setIsDisclaimerAdded] = useState(false);

  useEffect(() => {
    const allFilled = checkIfAllFilled(dataItems[0]);
    disableSave(!allFilled);
    setIsSubjectlineAdded(allFilled);
    if (dataItems[0].originalDisclaimer) {
      setIsDisclaimerAdded(allFilled);
    } else if (!dataItems[0].originalDisclaimer) {
      setIsDisclaimerAdded(true);
    }
  }, [dataItems]);

  useEffect(() => {
    if (isSubjectlineAdded && isDisclaimerAdded) {
      if (dataItems.length > 0 && dataItems[0]) {
        dataItems[0].translate = "2";
      }
    } else {
      if (dataItems.length > 0 && dataItems[0]) {
        dataItems[0].translate = "1";
      }
    }
    if (dataItems.length > 0 && dataItems[0]) {
      disableSave(!checkIfAllFilled(dataItems[0]));
    }
  }, [isSubjectlineAdded, isDisclaimerAdded]);

  const checkIfAllFilled = (dataItem) => {
    if (
      dataItem.text &&
      dataItem.originalDisclaimer &&
      dataItem.disclaimer &&
      dataItem.translate === "2"
    ) {
      return true;
    } else if (
      dataItem.text &&
      !dataItem.originalDisclaimer &&
      dataItem.translate === "2"
    ) {
      return true;
    }
    return false;
  };

  const handleTextChnage = (event, dataItem, type) => {
    const text = event.target.value;
    if (text && text.trim().length > 0) {
      if (type === "subjectline") {
        dataItem.text = text;
        setIsSubjectlineAdded(true);
      } else {
        dataItem.disclaimer = text;
        setIsDisclaimerAdded(true);
      }
    } else {
      if (type === "subjectline") {
        dataItem.text = "";
        setIsSubjectlineAdded(false);
      } else {
        dataItem.disclaimer = "";
        setIsDisclaimerAdded(false);
      }
    }
    setArbitraryVar(Math.random());
  };

  const setStyle = (elementId, className) => {
    const elemnet = document.getElementById(elementId);
    if (elemnet) {
      elemnet.className = className;
    }
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      {dataItems.map((dataItem, index) => {
        return (
          <div className="translate-subjectline" key={dataItem.id}>
            {
              <div style={{ marginTop: "10px" }}>
                <span className="translateAbbrText">Subject</span>
                <div id="tf_trans_subjectline" className="subjectline">
                  <TextField
                    onChange={(e) => {
                      handleTextChnage(e, dataItem, "subjectline");
                    }}
                    fullWidth={true}
                    value={!isSubjectlineAdded ? "" : dataItem.text}
                    rows={4}
                    rowsMax={4}
                    multiline
                    InputProps={{ disableUnderline: true }}
                    onFocus={(e) => {
                      setStyle(
                        "tf_trans_subjectline",
                        "subjectline subjectline-selected"
                      );
                    }}
                    onBlur={() => {
                      setStyle("tf_trans_subjectline", "subjectline");
                    }}
                    inputProps={{
                      style: {
                        height: "115px",
                      },
                    }}
                  />
                </div>
              </div>
            }
            {dataItem.originalDisclaimer && (
              <div style={{ marginTop: "25px" }}>
                <span className="translateAbbrText">Disclaimer</span>

                <div id="tf_trans_disclaimer" className="subjectline">
                  <TextField
                    onChange={(e) => {
                      handleTextChnage(e, dataItem, "disclaimer");
                    }}
                    onFocus={(e) => {
                      setStyle(
                        "tf_trans_disclaimer",
                        "subjectline subjectline-selected"
                      );
                    }}
                    onBlur={() => {
                      setStyle("tf_trans_disclaimer", "subjectline");
                    }}
                    fullWidth={true}
                    value={!isDisclaimerAdded ? "" : dataItem.disclaimer}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        height: "130px",
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SubjectlineAndDisclaimerColumn;
