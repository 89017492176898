import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../styles/components/ReferenceDocumentsList.scss";
import Checkbox from "@material-ui/core/Checkbox";
import thumbImage from "../../images/anchor_thumb_pdf.svg";
import { damsSources } from "../../Uitls/Util";

const ReferenceDocumentsList = ({
  documents,
  emptyMessage,
  onDocumentSelect,
  sourceType,
  moduleTypes,
  moduleType,
}) => {
  //const [selectedDocuments, setSelectedDocuments] = useState([])

  const renderDocumentList = () => {
    return (
      <div>
        {documents.map((document, index) => {
          return (
            <Row className="ref-document">
              <Col lg={2}>
                <div className="div-thumb mt-3 mb-2">
                  <Checkbox
                    key={`${document.name} - ${index}`}
                    name="checkedB"
                    color="primary"
                    style={{ borderRadius: "1px" }}
                    onChange={(event) => {
                      onDocumentSelect(event, document?.id);
                    }}
                  />
                  {moduleType === moduleTypes?.denovo ? (
                    <img
                      className="doc-thumb"
                      alt=""
                      src={
                        sourceType.toLowerCase() ===
                        damsSources.veeva.name.toLowerCase()
                          ? thumbImage
                          : document.url
                      }
                    />
                  ) : (
                    <img
                      className="doc-thumb"
                      alt=""
                      src={
                        sourceType.toLowerCase() ===
                        damsSources.veeva.name.toLowerCase()
                          ? thumbImage
                          : document.refDocUrdocl
                      }
                    />
                  )}
                </div>
              </Col>
              <Col lg={10} className="doc-head">
                {/* <div> */}
                {/* <div className="doc-head"></div> */}
                <div className="doc-head">
                  {moduleType === moduleTypes?.denovo
                    ? document.name
                    : document.name__v}
                </div>
                {/* <span className="doc-subhead">{document.name}</span> */}
                {/* <span className="doc-subhead">{document.docSubHeadTwo}</span> */}
                {/* </div> */}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className="emptyMessageAnchor">
        <div className="empty-message-head">{emptyMessage.head}</div>
        <div className="empty-message-subhead">{emptyMessage.subHead}</div>
      </div>
    );
  };

  return (
    <div>
      {documents && documents.length > 0 && <div>{renderDocumentList()}</div>}
      {documents && documents.length === 0 && (
        <div className="no-doc-empty-message">{renderEmptyMessage()}</div>
      )}
    </div>
  );
};

export default ReferenceDocumentsList;
