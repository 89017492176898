import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  updateModuleInfo,
  removeModuleInfo,
  moduleTypes,
} from "../../Uitls/Util";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import {
  createDenovoModule,
  upDateModuleAssetLinks,
} from "../../services/apis";
import CustomModal from "../../components/customModal";
import BrowseGenericInfoDocument from "../ExistingTactics/Harvested/BrowseGenericInfoDocument";

import closeIcon from "../../images/clear_text.svg";
import "../../styles/pages/UplaodMasterAsset.scss";
import "../../styles/CustomToastify.scss";
import "../../styles/components/DenovoModules.scss";

const DenovoGenericDetails = (props) => {
  const [audienceDataSource, setAudienceDataSource] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const { t } = useTranslation();
  const formRef = useRef();
  const [disbleProceed, setDisableProceed] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [othersfilled, setOthersFilled] = useState(false);
  const [mode, setMode] = useState("write");
  const [genreicInfoData, setGenericInfoData] = useState({
    isi: "",
    pi: "",
    mg: "",
    masa: "",
  });

  const [showBrowseGenInfoDocModal, setShowBrowseGenInfoDocModal] =
    useState(false);
  const [browseDocType, setBrowseDocType] = useState("");
  useEffect(() => {
    resetForm();
    resetAudience();
    resetMarketData();
    if (props.data) {
      const brandsAndCampaigns = props.data.brandsAndCampaigns;
      const audience = props.data.audience;
      if (brandsAndCampaigns) {
        setBrandsAndCampaigns(brandsAndCampaigns);
      }
      if (audience) {
        setAudienceDataSource(audience);
      }
    }
    if (props.mode) {
      setMode(props.mode);
      if (props.mode === "read") {
        setDisableProceed(false);
      }
    }
    if (props.module) {
      setGenericInfoData({
        isi: module.isi,
        pi: module.pi,
        mg: module.mg,
        masa: module.masa,
      });
      setSelectedMarket(getMarketByName(props.module.market));
      setSelectedAudience(Array.from(new Set(props.module.audience)) || []);
    } else {
      setSelectedMarket(undefined);
    }
  }, [props]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const getMarketByName = (marketName) => {
    const marketDataSource = props.data.marketDataSource;
    let selectedMarket = undefined;
    if (marketDataSource && marketDataSource.length > 0 && marketName) {
      selectedMarket = marketDataSource.find((market) => {
        return (
          market.displayName === marketName ||
          market.name === marketName ||
          market.code === marketName ||
          market.shortName === marketName
        );
      });
    }
    return selectedMarket;
  };

  //Get Brand Object based on the selectd brand name
  const getBrandByName = (brand) => {
    if (brandsAndCampaigns && brandsAndCampaigns.length > 0) {
      return [
        ...brandsAndCampaigns?.filter((object) => {
          return object["brandName"] === brand;
        }),
      ][0];
    }
    return { brandId: "", brandName: "" };
  };

  //Get Campaign Object based on the selectd Campaign name
  const getCampaignByBrandAndName = (brand, campaign) => {
    if (brandsAndCampaigns && brandsAndCampaigns.length > 0) {
      const selectedBrand = [
        ...brandsAndCampaigns?.filter((object) => {
          return object["brandName"] === brand;
        }),
      ][0];

      if (selectedBrand) {
        let campaigns = selectedBrand["campaigns"];
        if (campaigns && campaigns.length > 0) {
          return campaigns?.filter((object) => {
            return object["campaignName"] === campaign;
          })[0];
        }
      }
    }
    return { campaignId: "", campaignName: "" };
  };

  //Returns Brands list to populate in Drop down
  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns].map((object) => {
      return object.brandName;
    });
  };

  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (values) => {
    if (values.Brand) {
      const campaigns = [...brandsAndCampaigns]?.filter((object) => {
        return object.brandName === values.Brand;
      })[0]?.campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };
  //Check whether Audience is selected or not
  const validateSelectedAudience = (values) => {
    if (props.module) {
      return selectedAudience;
    }
    const valueKeys = Object.keys(values);
    const commonKeys = valueKeys.filter((item1) => {
      return audienceDataSource.some(
        (item2) => item1.trim().toLowerCase() === item2.trim().toLowerCase()
      );
    });
    let selectedOnes = [];
    for (let key of commonKeys) {
      if (
        values[key] &&
        values[key].length > 0 &&
        values[key][0].toString().toLowerCase() === "on"
      ) {
        selectedOnes.push(key);
      }
    }
    setSelectedAudience(selectedOnes);
    return selectedOnes;
  };

  const handleMarketChange = (market) => {
    setSelectedMarket(market);
    if (market && othersfilled) {
      setDisableProceed(false);
    } else {
      setDisableProceed(true);
    }
  };
  const validateMarket = () => {
    if (!selectedMarket) {
      return false;
    }
    return true;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Brand) {
      // errors.campaignUsed = "Campaign it was used required";
      errors.brand = i18next.t("uploadAssetLabel.brandError");
    }

    const campaignErr = i18next.t("uploadAssetLabel.campaignError");
    if (!values.Campaign) {
      errors.campaign = campaignErr;
    } else if (values.Campaign.trim() === "") {
      errors.campaign = campaignErr;
    } else {
      if (values.Brand) {
        const campaign = getCampaignByBrandAndName(
          values.Brand,
          values.Campaign
        );
        if (!campaign || campaign.campaignId === "") {
          errors.campaign = campaignErr;
        }
      }
    }

    const selectedAudience = validateSelectedAudience(values);
    if (!selectedAudience || selectedAudience.length <= 0) {
      errors.audience = i18next.t("uploadAssetLabel.audienceError");
    }
    if (!values.description || values.description.trim() === "") {
      errors.description = i18next.t("uploadAssetLabel.desError");
    }
    if (!values.dosing || values.dosing.trim() === "") {
      errors.dosing = i18next.t("uploadAssetLabel.dosingError");
    }
    if (!values.audienceSegment || values.audienceSegment.trim() === "") {
      errors.audienceSegment = i18next.t(
        "uploadAssetLabel.audienceSegmentError"
      );
    }

    if (Object.keys(errors).length === 0) {
      setOthersFilled(true);
      if (validateMarket()) {
        setDisableProceed(false);
      } else {
        setDisableProceed(true);
      }
    } else {
      setOthersFilled(false);
      setDisableProceed(true);
    }
    return errors;
  };

  const updateModuleInfLocally = (module) => {
    removeModuleInfo();
    updateModuleInfo({
      assetId: undefined,
      moduleId: module.id,
      moduleName: module.name,
      moduleCode: module.moduleCode,
      moduleType: "newModule",
      creationMethodType: moduleTypes.denovo,
    });
  };

  //It will make audience check boxes false
  const resetAudience = () => {
    for (const aud of audienceDataSource) {
      const docs = document.getElementsByName(aud);
      if (docs && docs.length > 0) {
        docs[0].checked = false;
      }
    }
  };

  const resetMarketData = () => {
    setSelectedMarket(undefined);
  };

  const fileUplodedErr = (value) =>
    toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  const isThisAudienceAdded = (audience) => {
    if (audience) {
      const addedAudience = props.module?.audience;
      if (
        addedAudience &&
        addedAudience.findIndex(
          (aud) => aud.trim().toLowerCase() === audience.trim().toLowerCase()
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const resetForm = () => {
    if (formRef) {
      formRef.current.resetForm();
    }
  };

  const getDefaultValue = (type) => {
    if (!props.module) {
      return "";
    }
    const module = props.module;
    let value = "";
    switch (type) {
      case "brand":
        value = module.brand || "";
        break;
      case "campaign":
        value = module.campaign || "";
        break;
      case "market":
        break;
      case "audience":
        value = module.audience || "";
        break;
      case "description":
        value = module.description || "";
        break;
      case "dosing":
        value = module.dosing || "";
        break;
      case "audienceSegment":
        value = module.audienceSegment || "";
        break;
      case "channel":
        value = module.channelType || "";
        break;
      case "genericBusinessRules":
        value = module.genericBusinessRules || "";
        break;
      case "therapeuticArea":
        value = module.therapeuticArea || "";
        break;
      default:
        value = "";
    }
    return value;
  };

  const getInitialvalues = () => {
    let initailValues = {
      Brand: props.module ? getDefaultValue("brand") : "",
      Campaign: getDefaultValue("campaign"),
      description: getDefaultValue("description") || "",
      dosing: getDefaultValue("dosing") || "",
      audienceSegment: getDefaultValue("audienceSegment") || "",
      genericBusinessRules: getDefaultValue("genericBusinessRules") || "",
      therapeuticArea: getDefaultValue("therapeuticArea") || "",
    };
    for (let selAud of selectedAudience) {
      if (selAud.trim().toLowerCase() === "consumer") {
        selAud = "Consumer";
      }
      if (selAud.trim().toLowerCase() === "payer") {
        selAud = "Payer";
      }
      initailValues[selAud] = ["on"];
    }
    return initailValues;
  };

  const addProceedButton = () => {
    return (
      <button
        type="submit"
        className={`btn btn-primary text-uppercase denovo-btn-create-module ${
          props.module ? "denovo-btn-create-module-top" : ""
        }`}
        onClick={(e) => {
          if (!props.module) {
            handleSubmit();
          }
        }}
        disabled={disbleProceed}
      >
        {!props.module
          ? // ? t("generalText.proceed").toUpperCase()
            t("generalText.createModule").toUpperCase()
          : t("generalText.createModule").toUpperCase()}
      </button>
    );
  };
  const addCloseButton = () => {
    return (
      <button
        className="btn btn-link p-0 m-0 btn-close-gen-details"
        onClick={() => {
          if (props.close) {
            props.close();
          }
        }}
      >
        <img src={closeIcon} alt="" width="25" />
      </button>
    );
  };

  const handleChangeOtherGenInfo = (event, type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: event.target.value });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: event.target.value });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: event.target.value });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: event.target.value });
    }
  };
  const clearOtherGenInfo = (type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: "" });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: "" });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: "" });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: "" });
    }
  };
  const browseOtherGenInfo = (type) => {
    setBrowseDocType(type);
    setShowBrowseGenInfoDocModal(true);
  };
  const hideBrowseGenInfoDocModal = () => {
    setShowBrowseGenInfoDocModal(false);
  };
  const updateGenericInfoUrl = (type, documentLink) => {
    setShowBrowseGenInfoDocModal(false);
    const obj = { ...genreicInfoData };
    obj[type.toLowerCase()] = documentLink;
    setGenericInfoData(obj);
  };

  useEffect(() => {
    // updated data
    if (props.module) {
      setGenericInfoData({
        isi: props.module.isi,
        pi: props.module.pi,
        mg: props.module.mg,
        masa: "",
      });
    }
  }, [props.module]);
  const handleUpdate = (genericBusinessRules) => {
    const payload = {
      moduleId: props.module.id,
      isi: genreicInfoData.isi,
      pi: genreicInfoData.pi,
      mg: genreicInfoData.mg,
      genericBusinessRules:
        genericBusinessRules.trim().length > 0 ? genericBusinessRules : "",
    };
    upDateModuleAssetLinks(payload, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
        module.genericBusinessRules =
          genericBusinessRules.trim().length > 0 ? genericBusinessRules : "";
        module.isi =
          genreicInfoData.isi.trim().length > 0 ? genreicInfoData.isi : "";
        module.pi =
          genreicInfoData.pi.trim().length > 0 ? genreicInfoData.pi : "";
        module.mg =
          genreicInfoData.mg.trim().length > 0 ? genreicInfoData.mg : "";
        if (props.updateLocalModule) {
          props.updateLocalModule(module);
        }
      }
    });
  };
  console.log("he", formRef?.current?.values);
  return (
    <div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="denovo-gen-details">
            {!props.module && addCloseButton()}
            <div className="row mt-3">
              <CircularProgressBar isloading={isloading} />

              <div className={`${mode === "read" ? "col-md-12" : "col-md-12"}`}>
                <Formik
                  initialValues={getInitialvalues()}
                  validate={validate}
                  onSubmit={(values, { resetForm }, initialValues) => {
                    const formData = new FormData();

                    formData.append(
                      "campaignId",
                      getCampaignByBrandAndName(values.Brand, values.Campaign)
                        .campaignId
                    );
                    formData.append("campaign", values.Campaign);
                    formData.append(
                      "brandId",
                      getBrandByName(values.Brand).brandId
                    );
                    formData.append("brand", values.Brand);
                    formData.append(
                      "audience",
                      JSON.stringify(selectedAudience)
                    );
                    formData.append("description", values.description);
                    formData.append("dosing", values.dosing);
                    formData.append("audienceSegment", values.audienceSegment);
                    formData.append("market", selectedMarket.displayName);
                    formData.append("isi", genreicInfoData.isi);
                    formData.append("pi", genreicInfoData.pi);
                    formData.append("mg", genreicInfoData.mg);
                    formData.append("therapeuticArea", values.therapeuticArea);
                    formData.append(
                      "genericBusinessRules",
                      values.genericBusinessRules
                    );
                    // const options = {
                    //   headers: {
                    //     "Content-Type": "application/x-www-form-urlencoded",
                    //     // Authorization: "Bearer " + token,
                    //     "Accept-Language": "en-US,en;q=0.9",
                    //     "Access-Control-Allow-Origin": "*",
                    //   },
                    // };
                    // setIsloading(true);
                    // createDenovoModule(
                    //   formData,
                    //   options,
                    //   (res, err, errCode) => {
                    //     setIsloading(false);
                    //     if (res) {
                    //       if (res.data) {
                    //         updateModuleInfLocally(res.data);
                    //         resetForm(initialValues);
                    //         resetMarketData();
                    //         resetAudience();
                    //         //Go to Primary claim page
                    //         if (props.onProceedClick) {
                    //           props.onProceedClick({
                    //             moduleName: res.data.name,
                    //             moduleCode: res.data.moduleCode,
                    //             moduleId: res.data.id,
                    //             brandId: res.data.brandId,
                    //           });
                    //         }
                    //       }
                    //     } else {
                    //       //show error
                    //       const errCoponent = ErrComponentToRender(errCode);
                    //       fileUplodedErr(errCoponent);
                    //     }
                    //   }
                    // );
                    if (props.onProceedClick) {
                      resetForm(initialValues);
                      resetMarketData();
                      resetAudience();
                      props.onProceedClick(
                        Object.fromEntries(formData.entries())
                      );
                    }
                  }}
                  innerRef={formRef}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div
                        className="title-generic-info"
                        style={{ display: "inline-flex" }}
                      >
                        <p className="mb-0">
                          Create Module
                          <span className="d-block">
                            {t("uploadAssetLabel.enterGenericInfo")}
                          </span>
                        </p>
                      </div>
                      {props.module && (
                        <div className="denovo-btns-top">
                          {addProceedButton()}
                          {addCloseButton()}
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-4">
                          <DropDownWithLabel
                            class={
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }
                            options={{
                              value: values.Brand, //t("uploadAssetLabel.brand"),
                              name: t("uploadAssetLabel.brand"),
                              label: t("uploadAssetLabel.brand"),
                              items: getBrandsToPopulate(),
                              handleChange: handleChange,
                              disabled: mode === "read" ? true : false,
                            }}
                          />

                          <DropDownWithLabel
                            class={
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }
                            options={{
                              value: values.Campaign,
                              name: t("uploadAssetLabel.campaignItWasUsed"),
                              label: t("uploadAssetLabel.campaignItWasUsed"),
                              items: getCampaignListToPopulate(values),
                              // handleChange: (value) => {
                              //   setFieldValue("Campaign", value);
                              // },
                              handleChange: handleChange,
                              disabled: mode === "read" ? true : false,
                            }}
                            // tick="denuovoCampaign"
                          />

                          <DropDownWithSearch
                            class={
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }
                            options={{
                              value: selectedMarket,
                              name: t("uploadAssetLabel.market"),
                              label: t("uploadAssetLabel.market"),
                              items: props.data?.marketDataSource,
                              handleChange: handleMarketChange,
                              disabled: mode === "read" ? true : false,
                            }}
                          />
                          <TextField
                            className={`add-geninfo-textfield ${
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }`}
                            id="dosing"
                            label={t("uploadAssetLabel.dosing")}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth={true}
                            value={values.dosing}
                            helperText={
                              errors.dosing && touched.dosing && errors.dosing
                            }
                            error={
                              errors.dosing && touched.dosing ? true : false
                            }
                            disabled={mode === "read" ? true : false}
                          />
                          <div>
                            <div className="audience">
                              <span className="audience">
                                {t("uploadAssetLabel.audience")}
                              </span>
                            </div>
                            <div>
                              {audienceDataSource.map((item, index) => {
                                return (
                                  <div
                                    className={`custom-control custom-checkbox custom-control-inline ${
                                      mode === "read"
                                        ? "aud-check-box-with-label-denovo"
                                        : "aud-check-box-with-label"
                                    }`}
                                    key={item}
                                  >
                                    {
                                      //For read mode
                                      mode === "read" && (
                                        <input
                                          type="checkbox"
                                          name={item}
                                          className="custom-control-input audience-check-box"
                                          id={item}
                                          onChange={handleChange}
                                          disabled={
                                            mode === "read" ? true : false
                                          }
                                          checked={isThisAudienceAdded(item)}
                                        ></input>
                                      )
                                    }
                                    {
                                      //For write mode
                                      mode !== "read" && (
                                        <input
                                          type="checkbox"
                                          name={item}
                                          className="custom-control-input audience-check-box"
                                          id={item}
                                          onChange={handleChange}
                                        ></input>
                                      )
                                    }
                                    <label
                                      className="custom-control-label aud-check-label"
                                      for={item}
                                    >
                                      {item}
                                    </label>
                                  </div>
                                  // <CheckBox />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mt-0.5">
                          <div className="genricInfoBtnContainer">
                            <TextField
                              className={`add-geninfo-textfield master-asset-name asset-link-color`}
                              id="genericISI"
                              label="ISI"
                              variant="outlined"
                              fullWidth={true}
                              // handleChange={handleChange}
                              onChange={(e) => {
                                handleChangeOtherGenInfo(e, "isi");
                              }}
                              value={genreicInfoData.isi}
                              //disabled={mode === "read" ? true : false}
                            />

                            {!genreicInfoData ||
                            !genreicInfoData.isi ||
                            genreicInfoData.isi.length <= 0 ? (
                              <button
                                type="button"
                                className="btn btn-link genricInfoBtn"
                                onClick={() => {
                                  browseOtherGenInfo("ISI");
                                }}
                              >
                                Browse
                              </button>
                            ) : (
                              <>
                                {
                                  <button
                                    type="button"
                                    className="btn btn-link genricInfoBtn"
                                    onClick={() => {
                                      clearOtherGenInfo("isi");
                                    }}
                                  >
                                    <img src={closeIcon} alt="" width="12" />
                                  </button>
                                }
                              </>
                            )}
                          </div>
                          <div className="genricInfoBtnContainer">
                            <TextField
                              className={`add-geninfo-textfield master-asset-name asset-link-color`}
                              id="genericPI"
                              label="PI"
                              variant="outlined"
                              fullWidth={true}
                              onChange={(e) => {
                                handleChangeOtherGenInfo(e, "pi");
                              }}
                              value={genreicInfoData.pi}
                              //disabled={mode === "read" ? true : false}
                            />
                            {!genreicInfoData ||
                            !genreicInfoData.pi ||
                            genreicInfoData.pi.length <= 0 ? (
                              <button
                                type="button"
                                className="btn btn-link genricInfoBtn"
                                onClick={() => {
                                  browseOtherGenInfo("PI");
                                }}
                              >
                                Browse
                              </button>
                            ) : (
                              <>
                                {
                                  <button
                                    type="button"
                                    className="btn btn-link genricInfoBtn"
                                    onClick={() => {
                                      clearOtherGenInfo("pi");
                                    }}
                                  >
                                    <img src={closeIcon} alt="" width="12" />
                                  </button>
                                }
                              </>
                            )}
                          </div>
                          <div className="genricInfoBtnContainer">
                            <TextField
                              className={`add-geninfo-textfield master-asset-name asset-link-color`}
                              id="genericMG"
                              label="MG"
                              variant="outlined"
                              fullWidth={true}
                              //handleChange={handleChange}
                              onChange={(e) => {
                                handleChangeOtherGenInfo(e, "mg");
                              }}
                              value={genreicInfoData.mg}
                              //disabled={mode === "read" ? true : false}
                            />
                            {!genreicInfoData ||
                            !genreicInfoData.mg ||
                            genreicInfoData.mg.length <= 0 ? (
                              <button
                                type="button"
                                className="btn btn-link genricInfoBtn"
                                onClick={() => {
                                  browseOtherGenInfo("MG");
                                }}
                              >
                                Browse
                              </button>
                            ) : (
                              <>
                                {
                                  <button
                                    type="button"
                                    className="btn btn-link genricInfoBtn"
                                    onClick={() => {
                                      clearOtherGenInfo("mg");
                                    }}
                                  >
                                    <img src={closeIcon} alt="" width="12" />
                                  </button>
                                }
                              </>
                            )}
                          </div>
                          <TextField
                            className={`add-geninfo-textfield ${
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }`}
                            id="audienceSegment"
                            label={t("uploadAssetLabel.audienceSegment")}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth={true}
                            value={values.audienceSegment}
                            helperText={
                              errors.audienceSegment &&
                              touched.audienceSegment &&
                              errors.audienceSegment
                            }
                            error={
                              errors.audienceSegment && touched.audienceSegment
                                ? true
                                : false
                            }
                            disabled={mode === "read" ? true : false}
                          />
                          <TextField
                            className={`add-geninfo-textfield ${
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }`}
                            id="therapeuticArea"
                            label="Therapeutic Area"
                            variant="outlined"
                            disabled={mode === "read" ? true : false}
                            fullWidth={true}
                            value={values.therapeuticArea}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className="col-md-4 mt-0.5">
                          <TextField
                            className={`add-geninfo-textfield description description-height denovoDescription ${
                              mode === "read"
                                ? "tf-and-drp-dwn-with-label-disabled"
                                : ""
                            }`}
                            id="description"
                            label={t("uploadAssetLabel.description")}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth={true}
                            value={values.description}
                            rows={9}
                            rowsMax={9}
                            multiline
                            helperText={
                              errors.description &&
                              touched.description &&
                              errors.description
                            }
                            error={
                              errors.description && touched.description
                                ? true
                                : false
                            }
                            disabled={mode === "read" ? true : false}
                          />
                          <TextField
                            className="add-geninfo-textfield description description-height denovoDescription"
                            id="genericBusinessRules"
                            label="Generic Business Rules"
                            variant="outlined"
                            //disabled={mode === "read" ? true : false}
                            fullWidth={true}
                            value={values.genericBusinessRules}
                            rows={9}
                            rowsMax={4}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            multiline
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6"></div>
                      </div>

                      <div className="row">
                        {mode === "read" && (
                          <div className="col-md-4 offset-md-8">
                            <div className=" text-right mt-4">
                              <div className="form-group">
                                <button
                                  type="button"
                                  className={`btn btn-block btn-primary text-uppercase denovo-btn-create-module ${
                                    props.module ? "" : ""
                                  }`}
                                  onClick={(e) => {
                                    handleUpdate(values.genericBusinessRules);
                                  }}
                                >
                                  UPDATE
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.module && (
        <div className="row mt-4">
          <div className="col-md-12 text-right">
            <div className="form-group">{addProceedButton()}</div>
          </div>
        </div>
      )}
      <CustomModal
        displayModal={showBrowseGenInfoDocModal}
        hideModal={hideBrowseGenInfoDocModal}
        title={"Existing Modules detected"}
        size="xl"
        dilogClassName="alertModalSmall modalNoborder"
        //keyboard={false}
      >
        <BrowseGenericInfoDocument
          browseDocType={browseDocType}
          hideBrowseGenInfoDocModal={hideBrowseGenInfoDocModal}
          updateGenericInfoUrl={updateGenericInfoUrl}
          sourceType="veeva"
        />
      </CustomModal>
    </div>
  );
};

export default DenovoGenericDetails;
