import React from "react";
import parse from "html-react-parser";
import OverlayPopOver from "../../Uitls/OverlayPopOver";
import { templateTypes } from "../../Uitls/Util";
const TemplateEmailPreview = ({
  title,
  data,
  attribute,
  templateType,
  onClick,
}) => {
  return (
    <div className="Channel-grid-sub-pre-preview">
      <span className="channel-type-head">{title}</span>
      <table className="table-style">
        <tbody>
          <tr className="header">
            <td>{attribute === "templateid" ? "TEMPLATE ID" : "EMAIL ID"}</td>
            <td>DAMS ID</td>
            <td>MLR STATUS</td>
            <td>
              {attribute === "templateid" ? "TEMPLATE LINK" : "EMAIL LINK"}
            </td>
          </tr>

          <tr key={data.serial_no}>
            <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
              {data.serial_no}
            </td>
            <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
              {attribute.toLowerCase() === "templateid"
                ? data.dams?.pdf_no || "-"
                : data?.dams?.html_no || "-"}
            </td>
            <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
              {attribute.toLowerCase() === "templateid"
                ? data?.dams?.pdf_status || "Not Submitted"
                : data?.dams?.html_status || "Not Submitted"}
            </td>
            <td className="tbl-row-txt-bold" style={{ width: "55%" }}>
              {data.image || data.fullscreen ? (
                <button
                  // href={data.fullscreen ? data.fullscreen : data.image}
                  //target="_blank"
                  className="btn btn-link pl-0"
                  onClick={() => {
                    onClick();
                  }}
                >
                  {data.fullscreen ? data.fullscreen : data.image}
                </button>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default TemplateEmailPreview;
