import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import zIndex from '@material-ui/core/styles/zIndex';
import '../styles/components/FloatingButton.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    button : {
        color : '#1c75bb',
        float : 'right',
        display:'inline-block',
        overflow: 'auto',
        margin:'0px auto'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FloatingButton = (props) =>  {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <Fab className = {classes.button} aria-label="add" onClick = {props.onPlusButtonClick}>
//         <AddIcon />
//       </Fab>
//     </div>
//   );

return (
  <div className=" float fa fa-plus my-float" onClick = {props.onClick}>
      
  </div>
)
}


export default FloatingButton
