import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import DropDownWithLabel from "./DropDownWithLabel";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import { getBrandsAndCampaigns, cloneModule } from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import "../styles/components/CloneModule.scss";
const CloneModule = ({ module, hideModal, modifyClonedModule }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [disbleProceed, setDisableProceed] = useState(true);
  const [audienceDataSource, setAudienceDataSource] = useState([
    "Consumer",
    "HCP",
    "Payer",
  ]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    fetchBrandsAndCampaigns();
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const handleCancel = () => {
    hideModal();
  };

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          const brands = data.filter((brand) => {
            return brand.brandId === module.brandId;
          });
          if (brands.length > 0) {
            setCampaigns(brands[0].campaigns);
          }
        }
      }
    });
  };

  const getCampaigns = () => {
    if (campaigns) {
      let campaignData = module.assetDetails;
      const data = [...campaigns]
        .filter((element) => {
          if (element.campaignId !== campaignData.campaignId) {
            return true;
          }
          return false;
        })
        .map((campaign) => {
          return campaign.campaignName;
        });
      return data;
    }
    return [];
  };

  //Check whether Audience is selected or not
  const validateSelectedAudience = (values) => {
    const valueKeys = Object.keys(values);
    const commonKeys = valueKeys.filter((item1) => {
      return audienceDataSource.some((item2) => item1 === item2);
    });
    let selectedOnes = [];
    for (let key of commonKeys) {
      if (
        values[key] &&
        values[key].length > 0 &&
        values[key][0].toString().toLowerCase() === "on"
      ) {
        selectedOnes.push(key);
      }
    }
    setSelectedAudience(selectedOnes);
    return selectedOnes;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.moduleName || values.moduleName.trim() === "") {
      errors.moduleName = i18next.t("cloneModule.moduleNameError");
    }

    const campaignErr = i18next.t("uploadAssetLabel.campaignError");
    if (!values.Campaign) {
      errors.campaign = campaignErr;
    } else if (values.Campaign.trim() === "") {
      errors.campaign = campaignErr;
    }
    // else {
    //   if (values.Brand) {
    //     const campaign = getCampaignByBrandAndName(
    //       values.Brand,
    //       values.Campaign
    //     );
    //     if (!campaign || campaign.campaignId === "") {
    //       errors.campaign = campaignErr;
    //     }
    //   }
    // }

    const selectedAudience = validateSelectedAudience(values);
    if (!selectedAudience || selectedAudience.length <= 0) {
      errors.audience = i18next.t("uploadAssetLabel.audienceError");
    }
    if (!values.description || values.description.trim() === "") {
      errors.description = i18next.t("uploadAssetLabel.desError");
    }
    if (!values.dosing || values.dosing.trim() === "") {
      errors.dosing = i18next.t("uploadAssetLabel.dosingError");
    }

    if (Object.keys(errors).length === 0) {
      // setOthersFilled(true)
      // if(validateMarket()){
      //   setDisableProceed(false);
      // }
      // else{
      //   setDisableProceed(true);
      // }
      setDisableProceed(false);
    } else {
      //setOthersFilled(false)
      setDisableProceed(true);
    }
    return errors;
  };

  // const updateModuleInfLocally = (module) => {
  //   removeModuleInfo()
  //   updateModuleInfo({
  //     assetId: undefined,
  //     moduleId: module.id,
  //     moduleName: module.name,
  //     moduleCode: module.moduleCode,
  //     moduleType: 'newModule',
  //     creationMethodType : moduleTypes.denovo
  //   });
  // };
  //It will make audience check boxes false
  const resetAudience = () => {
    for (const aud of audienceDataSource) {
      const docs = document.getElementsByName(aud);
      if (docs && docs.length > 0) {
        docs[0].checked = false;
      }
    }
  };

  const getCampaignByName = (campaignName) => {
    if (campaigns.length > 0) {
      return campaigns.filter((campaign) => {
        return campaign.campaignName === campaignName;
      })[0]?.campaignId;
    }
    return "";
  };

  const showErrorMessage = (message) => {
    toast.error(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">{message}</p>
      </>,
      {
        position: "top-right",
        autoClose: 3000,
      }
    );
  };

  return (
    <>
      <div className="modalClonePopUp">
        <div className="clone-module-pop-title">
          {i18next.t("cloneModule.cloneAs")}
        </div>
        <div>
          <CircularProgressBar isloading={isloading} />
          <Formik
            initialValues={{
              //  Brand : values.Brand,
              //  Campaign :values.Campaign,
              // masterAssetName : "",
              //audience : selectedAudience,
              market: module?.assetDetails?.market || "",
              description: "",
              dosing: "",
            }}
            //   validationSchema={validationSchema}
            validate={validate}
            onSubmit={(values, { resetForm }, initialValues) => {
              const formData = new FormData();
              formData.append(
                "name",
                values.moduleName.replace(/\s+/g, " ").trim()
              );
              formData.append("audience", JSON.stringify(selectedAudience));
              formData.append("campaignId", getCampaignByName(values.Campaign));
              formData.append("campaign", values.Campaign);
              // formData.append(
              //   "brandId",
              //   getBrandByName(values.Brand).brandId
              // );
              // formData.append("brand", values.Brand);

              formData.append("description", values.description);
              formData.append("dosing", values.dosing);
              // formData.append("market", selectedMarket.displayName)
              const options = {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  // Authorization: "Bearer " + token,
                  "Accept-Language": "en-US,en;q=0.9",
                  "Access-Control-Allow-Origin": "*",
                },
              };
              setIsloading(true);
              cloneModule(
                formData,
                module.id,
                //module.assetId,
                (res, err, errCode) => {
                  setIsloading(false);
                  if (res) {
                    if (res.data) {
                      modifyClonedModule(res.data);
                      handleCancel();
                    }
                  } else {
                    //show error
                    const errData = err.response?.data;
                    if (errData && !errData?.error) {
                      showErrorMessage(errData?.message);
                      return;
                    }
                    const errCoponent = ErrComponentToRender(errCode);
                    showError(errCoponent);
                  }
                }
              );
            }}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              status,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  className="clone-module-text-field"
                  id="moduleName"
                  label={t("cloneModule.moduleName")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.moduleName}
                  helperText={
                    errors.moduleName && touched.moduleName && errors.moduleName
                  }
                  error={errors.moduleName && touched.moduleName ? true : false}
                />
                <DropDownWithLabel
                  options={{
                    value: values.Campaign, //t("uploadAssetLabel.campaignItWasUsed"),
                    name: t("uploadAssetLabel.campaignItWasUsed"),
                    label: t("uploadAssetLabel.campaignItWasUsed"),
                    items: getCampaigns(),
                    handleChange: handleChange,
                  }}
                />
                <TextField
                  className="clone-module-text-field clone-module-market-field"
                  id="market"
                  label={t("uploadAssetLabel.market")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.market}
                  helperText={errors.market && touched.market && errors.market}
                  error={errors.market && touched.market ? true : false}
                  disabled={true}
                />
                <div className="audience">
                  <span className="audience">
                    {t("uploadAssetLabel.audience")}
                  </span>
                </div>
                <div>
                  {audienceDataSource.map((item) => {
                    return (
                      <div
                        className="custom-control custom-checkbox custom-control-inline aud-check-box-with-label"
                        key={item}
                      >
                        <input
                          type="checkbox"
                          name={item}
                          className="custom-control-input audience-check-box"
                          id={item}
                          onChange={handleChange}
                        ></input>
                        <label
                          className="custom-control-label aud-check-label"
                          for={item}
                        >
                          {item}
                        </label>
                      </div>
                      // <CheckBox />
                    );
                  })}
                </div>
                <TextField
                  className="clone-module-text-field"
                  id="dosing"
                  label={t("uploadAssetLabel.dosing")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.dosing}
                  helperText={errors.dosing && touched.dosing && errors.dosing}
                  error={errors.dosing && touched.dosing ? true : false}
                />
                <TextField
                  className="clone-module-text-field"
                  id="description"
                  label={t("uploadAssetLabel.description")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.description}
                  helperText={
                    errors.description &&
                    touched.description &&
                    errors.description
                  }
                  error={
                    errors.description && touched.description ? true : false
                  }
                />
              </Form>
            )}
          </Formik>
        </div>

        <div className="form-group cloneBtn">
          <button
            className="btn text-uppercase btn-cancel-clone"
            onClick={handleCancel}
          >
            {t("generalText.cancel").toUpperCase()}
          </button>

          <button
            type="submit"
            className="btn btn-clone text-uppercase"
            onClick={handleSubmit}
            disabled={disbleProceed}
          >
            {t("generalText.proceed").toUpperCase()}
          </button>
        </div>
      </div>
    </>
  );
};

export default CloneModule;
