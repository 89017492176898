import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";

import AddComment from "./AddComment";
import CustomModal from "../customModal";
import replyImg from "../../images/reply.svg";

import "../../styles/components/Comments.scss";
import {
  hasUserLoggedInAsReviewer,
  OutsideClickHandler,
} from "../../Uitls/Util";
import {
  modifyCommentSave,
  replyToComment,
  markCommentResolved,
  deleteCommentsAndReply,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";

const Comments = ({
  primaryClaimCommentsRef,
  moduleId,
  componentId,
  childComponentId,
  comments,
  currentClass,
  commentsPopupCurrentState,
  commentsPopupUpdateState,
  getCommentsData,
  commentsScrollClass,
}) => {
  const [arbitararyVar, setArbitararyVar] = useState(0);
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [commentsForModify, setCommentsForModify] = useState(undefined);
  const [isActionButtonClicked, setIsActionButtonClicked] = useState(false);
  const [showReplyCommentModal, setShowReplyCommentModal] = useState(false);
  const [replyInputValue, setReplyInputValue] = useState("");
  const [sendReplyBtn, setSendReplyBtn] = useState(true);
  const [currentReplyData, setCurrentReplyData] = useState({});
  const [replyShown, setReplyShown] = useState("");
  const [cancelBtn, setCancelBtn] = useState(false);

  //To delete comment/Reply
  const [displayDeleteAlert, setDisplayDeleteAlert] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(undefined);

  useEffect(() => {
    setArbitararyVar(Math.random());
  }, [comments]);

  useEffect(() => {
    if (isActionButtonClicked) {
      setShowAddCommentsModal(true);
    }

    commonCommentsRef.current.setAttribute(
      "actiopnButtonClicked",
      isActionButtonClicked
    );
  }, [isActionButtonClicked]);

  useEffect(() => {
    commonCommentsRef.current.setAttribute(
      "actiopnButtonClicked",
      displayDeleteAlert
    );
  }, [displayDeleteAlert]);

  useEffect(() => {
    commonCommentsRef.current.setAttribute("cancelbtnClicked", cancelBtn);
  }, [cancelBtn]);

  const editModifyReplyError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const modifyComment = (comment, id, type) => {
    setCommentsForModify({ comment: comment, id: id, type: type });
    setIsActionButtonClicked(true);
  };

  const resolveComment = (comment) => {
    markCommentResolved(comment.id, (response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        editModifyReplyError(errCoponent);
      } else {
        getCommentsData();
        commentsPopupUpdateState();
      }
    });
  };

  const getReplyCtas = (reply, commentResolutionStatus) => {
    if (
      !commentResolutionStatus &&
      hasUserLoggedInAsReviewer() &&
      reply.repliedBy.toLowerCase() === "reviewer"
    ) {
      return (
        <div className="commentsbtnRow ml-0">
          <button
            className="btn btn-link commentsBtn p-0 m-0 mr-3"
            onClick={(e) => modifyComment(reply.reply, reply.id, "Reply")}
          >
            Modify
          </button>

          <button
            className="btn btn-link commentsBtn p-0 m-0 "
            onClick={() => {
              deleteCommentOrReply(reply.id, "Reply");
            }}
          >
            Delete
          </button>
        </div>
      );
    } else if (
      !commentResolutionStatus &&
      !hasUserLoggedInAsReviewer() &&
      reply.repliedBy.toLowerCase() === "translator"
    ) {
      return (
        <div className="commentsbtnRow ml-0">
          <button
            className="btn btn-link commentsBtn p-0 m-0 mr-3"
            onClick={(e) => modifyComment(reply.reply, reply.id, "Reply")}
          >
            Modify
          </button>

          <button
            className="btn btn-link commentsBtn p-0 m-0"
            onClick={() => {
              deleteCommentOrReply(reply.id, "Reply");
            }}
          >
            Delete
          </button>
        </div>
      );
    }
  };
  const commonCommentsRef = useRef();

  const commentsPopupHide = () => {
    if (
      commonCommentsRef.current?.getAttribute("actiopnButtonClicked") ===
        "true" ||
      commonCommentsRef.current?.getAttribute("cancelbtnClicked") === "true"
    ) {
      return;
    }
    if (commentsPopupUpdateState) {
      commentsPopupUpdateState();
    }
  };

  OutsideClickHandler(
    commonCommentsRef,
    commentsPopupHide,
    commentsPopupCurrentState
  );
  const handleCloseAddComment = () => {
    setShowAddCommentsModal(false);
    setIsActionButtonClicked(false);
  };
  const saveComment = (modifiedCommentText) => {
    if (
      commentsForModify.type === "Comment" ||
      commentsForModify.type === "Reply"
    ) {
      let commentObject = {
        id: commentsForModify.id,
        modifyText: modifiedCommentText,
        type: commentsForModify.type,
      };
      modifyCommentSave(commentObject, (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          editModifyReplyError(errCoponent);
        } else {
          setShowAddCommentsModal(false);
          setCommentsForModify(undefined);
          getCommentsData();
          commentsPopupUpdateState();
        }
      });
    }
  };

  const handleTextFieldChange = (event) => {
    if (event && event.target) {
      const text = event.target.value;
      setReplyInputValue(text);
      if (text.trim().length === 0) {
        return setSendReplyBtn(true);
      }
    }
    return setSendReplyBtn(false);
  };

  const sendCommentReply = () => {
    const replyObj = {
      commentId: currentReplyData.id,
      reply: replyInputValue,
      repliedBy: !hasUserLoggedInAsReviewer() ? "Translator" : "Reviewer",
    };

    replyToComment(replyObj, (response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        editModifyReplyError(errCoponent);
      } else {
        getCommentsData();
        commentsPopupUpdateState();
      }
    });
  };

  // OutsideClickHandler(replyPopupRef, hideReplyPopup, showReplyCommentModal);
  const toggleReplyComment = (id, comment) => {
    setReplyShown((prev) => (prev.replyShown !== id ? id : ""));
    setCurrentReplyData(comment);
    setCancelBtn(true);
  };

  const updateReplyCancel = () => {
    setReplyShown("");
    setCurrentReplyData({});
    setReplyInputValue("");
    setCancelBtn(false);
  };

  const deleteCommentOrReply = (itemID, type) => {
    setToBeDeleted(() => {
      setDisplayDeleteAlert(true);
    });
    setToBeDeleted({ id: itemID, type: type.toLowerCase() });
  };
  const handleDeleteCommentModal = () => {
    setDisplayDeleteAlert(false);
    setToBeDeleted(undefined);
  };
  const deleteComment = () => {
    if (!toBeDeleted) {
      return;
    }
    deleteCommentsAndReply(toBeDeleted, (response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        editModifyReplyError(errCoponent);
        setToBeDeleted(undefined);
      } else {
        setToBeDeleted(undefined);
        getCommentsData();
        commentsPopupUpdateState();
      }
      setDisplayDeleteAlert(false);
    });
  };

  return (
    <div
      ref={commonCommentsRef}
      className={`commentsPopupContainer ${
        currentClass
          ? currentClass
          : hasUserLoggedInAsReviewer()
          ? "commentsPosition1"
          : "commentsPosition1-translator"
      }`}
    >
      <p className="commentsTitle">{i18next.t("comments.title")}</p>
      {/* <div className="scorllingComments customScrollBar"> */}
      <div
        className={`${
          commentsScrollClass || "scorllingComments"
        } customScrollBar`}
      >
        {comments.map((comment, index) => {
          const replies = comment.replies;
          return (
            <div key={comment.id}>
              <p
                className={`commentsReplyTitle ${
                  comment.resolutionStatus ? "resolved-comment" : ""
                }`}
              >
                <span className="commentsNumber">{index + 1}.</span>{" "}
                {hasUserLoggedInAsReviewer() ? "You" : "Reviewer"}
              </p>
              <p className="commentsSelectedText">{comment.selectedText}</p>
              <p
                className={`commentText ${
                  comment.resolutionStatus ? "resolved-comment" : ""
                }`}
              >
                {comment.comment}
              </p>

              {replies.map((reply, currentIndex) => {
                const isByReviewer =
                  reply.repliedBy.toLowerCase() === "reviewer";
                return (
                  <div
                    // className={`${
                    //   comment.resolutionStatus ? "resolved-comment" : ""
                    // }`}
                    className={
                      isByReviewer
                        ? `reviewerReplyBlock ${
                            comment.resolutionStatus ? "resolved-comment" : ""
                          }`
                        : `translatorReplyBlock ${
                            comment.resolutionStatus ? "resolved-comment" : ""
                          }`
                    }
                    key={currentIndex}
                  >
                    <p
                      className={
                        isByReviewer
                          ? "reviewerCommentTitle"
                          : "translaterCommentTitle"
                      }
                    >
                      {isByReviewer && hasUserLoggedInAsReviewer()
                        ? "You"
                        : !isByReviewer && !hasUserLoggedInAsReviewer()
                        ? "You "
                        : reply.repliedBy}
                    </p>
                    <p
                      className={
                        isByReviewer
                          ? "reviewerCommentReply"
                          : "translaterCommentReply"
                      }
                    >
                      {reply.reply}
                    </p>
                    {currentIndex === replies.length - 1 &&
                      getReplyCtas(reply, comment.resolutionStatus)}
                  </div>
                );
              })}
              {!comment.resolutionStatus && (
                <div className="commentsbtnRow">
                  {/* {replies.length > 0 && ( */}

                  {replyShown === comment.id && (
                    <div className="replyCommentPopup">
                      <TextField
                        className="recommentInputStyle"
                        onChange={handleTextFieldChange}
                        fullWidth={true}
                        value={replyInputValue}
                        variant="outlined"
                      />

                      <div className="text-right">
                        <button
                          className="btn btn-link pr-0 replySendBtnText"
                          type="button"
                          onClick={() => {
                            updateReplyCancel();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-link pr-0 replySendBtnText"
                          type="button"
                          onClick={() => {
                            sendCommentReply();
                          }}
                          disabled={sendReplyBtn}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  )}
                  {!hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link commentsBtn p-0 m-0 mr-3"
                      onClick={() => toggleReplyComment(comment.id, comment)}
                    >
                      <img src={replyImg} alt="" width="12px" /> Reply
                    </button>
                  )}

                  {replies.length > 0 && hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link commentsBtn p-0 m-0 mr-3"
                      onClick={() => toggleReplyComment(comment.id, comment)}
                    >
                      <img src={replyImg} alt="" width="12px" /> Reply
                    </button>
                  )}

                  {replies.length === 0 && hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link commentsBtn p-0 m-0 mr-3 "
                      onClick={(e) =>
                        modifyComment(comment.comment, comment.id, "Comment")
                      }
                    >
                      Modify
                    </button>
                  )}
                  {replies.length === 0 && hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link commentsBtn p-0 m-0 "
                      onClick={() => {
                        deleteCommentOrReply(comment.id, "Comment");
                      }}
                    >
                      Delete
                    </button>
                  )}
                  {hasUserLoggedInAsReviewer() && replies.length > 0 && (
                    <button
                      className="btn btn-link commentsBtn p-0 m-0 mr-3"
                      onClick={() => {
                        resolveComment(comment);
                      }}
                    >
                      Resolve
                    </button>
                  )}
                </div>
              )}
              <div className="commmentDivider"></div>
            </div>
          );
        })}

        <CustomModal
          displayModal={showAddCommentsModal}
          hideModal={handleCloseAddComment}
          title=""
          size="lg"
        >
          <AddComment
            //selectedText={commentObject?.selectedText}
            handleClose={handleCloseAddComment}
            saveComment={saveComment}
            comment={commentsForModify}
          />
        </CustomModal>

        <CustomModal
          displayModal={displayDeleteAlert}
          hideModal={handleDeleteCommentModal}
          title=""
          size="md"
          dilogClassName="alertModalSmall"
        >
          <span className="delete-alert-title">
            Are you sure you want to delete comment?
          </span>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-link pl-0 delete-comment-no"
              onClick={handleDeleteCommentModal}
            >
              NO
            </button>
            <button
              className="btn btn-primary delete-comment-yes"
              onClick={deleteComment}
            >
              YES
            </button>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default Comments;
