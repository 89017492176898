import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Menu from "../TranslateClaims/Menu";

import translateIcon from "../../../images/translateIcon.svg";
import translateTick from "../../../images/translateTick.svg";

import "../../../styles/pages/CreateModuleStep4.scss";
import "../../../styles/pages/TranslateChannel.scss";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import Comments from "../Comments";

const TranslateCatchlines = ({
  catchlines,
  forCTAs,
  isAnyToggleOn,
  initiateTranslationFor,
  getCommentBubbleFor,
  addComment,
  comments,
  showCommentsFlag,
  rowIndex,
  setShowCommentsFlag,
  //For Ctas only
  componentId,
  primaryComponentRowIndex,
  getCommentsData,
}) => {
  const catchlineRef = useRef(null);
  const [catchLineId, setCatchLineId] = useState();

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: catchLineId,
      componentId: catchLineId,
    };
    addComment(commentObject);
  };

  return (
    <div>
      <ul
        className={`custonScrollBar ${forCTAs ? "catch-lines" : ""}`}
        style={{ paddingLeft: "0px" }}
        ref={catchlineRef}
      >
        {catchlines.map((item, index) => {
          return (
            item.text && (
              <li className="catchline" key={item.id}>
                <Row className="align-items-center mt-1 mb-1">
                  <Col className="col-md-10">
                    <div
                      className="catchline-itm"
                      id={`catch-line${item.id}`}
                      onClick={() => setCatchLineId(item.id)}
                    >
                      {!isAnyToggleOn && item.translate === "2" && (
                        <img
                          src={translateTick}
                          alt="Translate"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {/* {isAnyToggleOn ? item.originalText : item.text} */}
                      {item.text}
                    </div>
                  </Col>

                  <Col
                    className="col-md-2"
                    style={{
                      display: !hasUserLoggedInAsReviewer()
                        ? "inline-block"
                        : "",
                    }}
                  >
                    {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                      <button
                        className="btn btn-link fab-translate"
                        onClick={() => {
                          initiateTranslationFor([item], "tagline");
                        }}
                      >
                        <img src={translateIcon} alt="Translate" />
                      </button>
                    )}
                    {getCommentBubbleFor("catchline", item.id)}
                    {!forCTAs && showCommentsFlag && rowIndex === index && (
                      <Comments
                        moduleId={module.id}
                        componentId={item.id}
                        childComponentId=""
                        comments={comments}
                        currentClass={
                          hasUserLoggedInAsReviewer()
                            ? "commentsCatchline"
                            : "commentsCatchline-translator"
                        }
                        commentsScrollClass={
                          rowIndex === 0
                            ? "scrollCommentschannelAttrPos1"
                            : undefined
                        }
                        commentsPopupCurrentState={showCommentsFlag}
                        commentsPopupUpdateState={setShowCommentsFlag}
                        getCommentsData={getCommentsData}
                      />
                    )}
                    {forCTAs &&
                      showCommentsFlag &&
                      rowIndex === index &&
                      item.id === componentId && (
                        <Comments
                          moduleId={module.id}
                          componentId={item.id}
                          childComponentId=""
                          comments={comments}
                          currentClass={
                            hasUserLoggedInAsReviewer()
                              ? "commentsCatchline"
                              : "commentscta-translator"
                          }
                          commentsScrollClass={
                            primaryComponentRowIndex === 0 && rowIndex === 0
                              ? "scrollCommentschannelAttrPos1"
                              : undefined
                          }
                          commentsPopupCurrentState={showCommentsFlag}
                          commentsPopupUpdateState={setShowCommentsFlag}
                          getCommentsData={getCommentsData}
                        />
                      )}
                  </Col>
                </Row>
              </li>
            )
          );
        })}
      </ul>
      {!isAnyToggleOn && (
        <Menu
          outerRef={catchlineRef}
          compClass={`catch-line${catchLineId}`}
          addComment={addAComment}
        />
      )}
    </div>
  );
};

export default TranslateCatchlines;
