//Import React dependencies
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//Import custom components
import CustomChip from "../Chip";
import CustomizedSelects from "../DropDown";
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
  moduleTypes,
  damsSources,
} from "../../Uitls/Util";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
//Import Util functions/apis
import {
  getCoreClaimCategories,
  createPlaceholderModules,
  createDenovoModule,
} from "../../services/apis";

//Import styles
import "../../styles/components/ModulePlaceholder.scss";

const AddModulePlaceHolder = ({
  brandName = "",
  assetId,
  campaignName,
  handleCreateModulePlaceHolderModal,
  applyBusinessRules,
  assetData,
  type,
  addedGenericInfo,
  sourceType,
}) => {
  let history = useHistory();
  const [placeholderModules, setPlaceholderModules] = useState([
    { name: "", coreclaimcategories: [] },
    { name: "", coreclaimcategories: [] },
    { name: "", coreclaimcategories: [] },
  ]);

  const [scientificCoreCategories, setScientificCoreCategories] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    fetchScientificCoreClaimCategories();
  }, []);

  const fetchScientificCoreClaimCategories = () => {
    getCoreClaimCategories(
      (response, error) => {
        if (response && !error) {
          setScientificCoreCategories(response.data);
        }
      },
      sourceType
        ? sourceType?.toLowerCase() === damsSources.aprimo.name.toLowerCase()
          ? brandName
          : type === moduleTypes.denovo
          ? addedGenericInfo.brandId
          : assetData.brandId
        : brandName,
      sourceType
    );
  };

  const onCategorySelect = (
    selectedCategory,
    placeholderModule,
    placeholderIndex,
    shouldBeAdded
  ) => {
    let modules = [...placeholderModules];
    let categories = modules[placeholderIndex].coreclaimcategories;
    if (
      shouldBeAdded &&
      selectedCategory &&
      selectedCategory !== "base" &&
      categories.findIndex((category) => {
        return (
          //category.id === selectedCategory.id &&
          category.name === selectedCategory.name
        );
      }) === -1
    ) {
      categories.push(selectedCategory);
    } else if (
      !shouldBeAdded &&
      selectedCategory &&
      selectedCategory !== "base" &&
      categories.findIndex((category) => {
        return (
          //category.id === selectedCategory.id &&
          category.name === selectedCategory.name
        );
      }) > -1
    ) {
      categories = categories.filter((category) => {
        return (
          //category.id !== selectedCategory.id &&
          category.name !== selectedCategory.name
        );
      });
    }
    modules[placeholderIndex].coreclaimcategories = categories;
    setPlaceholderModules(modules);
  };

  const shouldProceedBeEnabled = () => {
    let shouldEnable = false;
    placeholderModules.forEach((module) => {
      if (module.name && module.name.trim().length > 0) {
        shouldEnable = true;
      }
    });
    return shouldEnable;
  };

  const onModuleNameChange = (newName, placeholderIndex) => {
    let modules = [...placeholderModules];
    if (newName.trim().length === 0) {
      newName = "";
    }
    modules[placeholderIndex].name = newName;
    //setHelper(Math.random());
    setPlaceholderModules(modules);
  };

  const addAnotherModule = () => {
    const newModule = { name: "", coreclaimcategories: [] };
    setPlaceholderModules(placeholderModules.concat(newModule));
  };

  const removePlaceholder = (placeholderIndex) => {
    let modules = [...placeholderModules];
    modules.splice(placeholderIndex, 1);
    setPlaceholderModules(modules);
  };

  const createPlaceholderModuleErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const createDenuovoModules = (redirectType) => {
    let removeEmptyArray = [];
    placeholderModules.forEach((element) => {
      if (element.name !== "") {
        const moduleObj = {
          name: element.name,
          //  coreCategories: element.coreclaimcategories,
          coreCategories: element.coreclaimcategories.map((item) => {
            return { coreCategoryId: item.id, coreCategory: item.name };
          }),
        };
        removeEmptyArray.push(moduleObj);
      }
    });
    const createBannerObj = {
      audience: JSON.parse(addedGenericInfo.audience),
      campaignId: addedGenericInfo.campaignId,
      campaign: addedGenericInfo.campaign,
      brandId: addedGenericInfo.brandId,
      brand: addedGenericInfo.brand,
      description: addedGenericInfo.description,
      dosing: addedGenericInfo.dosing,
      market: addedGenericInfo.market,
      isi: addedGenericInfo.isi,
      pi: addedGenericInfo.pi,
      mg: addedGenericInfo.mg,
      genericBusinessRules: addedGenericInfo.genericBusinessRules,
      therapeuticArea: addedGenericInfo.therapeuticArea,
      audienceSegment: addedGenericInfo.audienceSegment,
      modules: removeEmptyArray,
    };

    createDenovoModule(createBannerObj, (response, err, errorCode) => {
      if (err) {
        if (errorCode === 500) {
          toast.error(
            <p className="notificationText">
              Error creating modules. Please try after sometime
            </p>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
        } else {
          const errCoponent = ErrComponentToRender(errorCode);
          createPlaceholderModuleErr(errCoponent);
        }
      } else {
        removeModuleInfo();
        updateModuleInfo({
          assetId: undefined,
          moduleId: response.data.modules[0].id,
          moduleName: response.data.modules[0].name,
          moduleCode: response.data.modules[0].moduleCode,
          moduleType: "newModule",
          creationMethodType: moduleTypes.denovo,
        });
        if (redirectType === "asset") {
          const obj = {
            moduleName: response.data.modules[0].name,
            moduleCode: response.data.modules[0].moduleCode,
            moduleId: response.data.modules[0].id,
            brandName: addedGenericInfo.brand,
            isCategoriesAdded: response.data.modules[0].coreCategoriesFlag,
          };
          history.push({
            pathname: routes.addPrimaryClaim,
            state: obj,
          });
        } else {
          applyBusinessRules(response.data);
        }
      }
    });
  };

  const createModularisedModules = (redirectType) => {
    let removeEmptyArray = [];
    placeholderModules.forEach((element) => {
      if (element.name !== "") {
        const moduleObj = {
          name: element.name,
          //  coreCategories: element.coreclaimcategories,
          coreCategories: element.coreclaimcategories.map((item) => {
            return { coreCategoryId: item.id, coreCategory: item.name };
          }),
        };
        removeEmptyArray.push(moduleObj);
      }
    });
    const createBannerObj = {
      harvestedAssetId: assetData.harvestedAssetId,
      storageAssetId: assetData.storageAssetId,
      brandId: assetData.brandId,
      brandName: assetData.brandName,
      campaignName: assetData.campaignName,
      campaignId: assetData.campaignId,
      modules: removeEmptyArray,
    };

    createPlaceholderModules(createBannerObj, (response, err, errorCode) => {
      if (err) {
        if (errorCode === 500) {
          toast.error(
            <p className="notificationText">
              Error creating modules. Please try after sometime
            </p>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
        } else {
          const errCoponent = ErrComponentToRender(errorCode);
          createPlaceholderModuleErr(errCoponent);
        }
      } else {
        if (redirectType === "asset") {
          history.push({
            pathname: routes.assetDetails,
            state: {
              assetid: response.data.storageAssetId,
              moduleName: response.data.modules[0].name,
              moduleCode: response.data.modules[0].moduleCode,
              moduleId: response.data.modules[0].id,
              brandName: assetData.brandName,
              isCategoriesAdded: response.data.modules[0].coreCategoriesFlag,
            },
          });
        } else {
          applyBusinessRules(response.data);
        }
      }
    });
  };

  const proceedModuleCreation = (redirectType) => {
    if (type === "denovo") {
      createDenuovoModules(redirectType);
    } else {
      createModularisedModules(redirectType);
    }
  };

  return (
    <div className="add-module-placeholder">
      <div className="head-add-module-placeholder">
        <div className="title-create-module"> Create Module</div>

        <div className="row">
          <Col className="col-md-6 campaign-name ">
            Campaign Name : {campaignName}
          </Col>
          <Col className="col-md-6" style={{ paddingRight: "8px" }}>
            <button
              className="btn-apply-b-rules btn btn-link"
              disabled={!shouldProceedBeEnabled()}
              onClick={() => {
                proceedModuleCreation("bsRules");
              }}
              type="button"
            >
              Apply Business rules
            </button>
          </Col>
        </div>
      </div>
      <div>
        <p className="label-create-placeholder">Create Placeholders</p>
        <div className="placeholders customScrollBar">
          {placeholderModules.map((placeholderModule, index) => {
            return (
              <div
                className={`row ${
                  index === placeholderModules.length - 1
                    ? "row-module-placeholder-last"
                    : "row-module-placeholder"
                }`}
                key={placeholderModule.name + index}
              >
                <Col className="col-md-6">
                  <TextField
                    id={placeholderModule.name + index}
                    label="Enter Module name"
                    variant="outlined"
                    fullWidth={true}
                    value={placeholderModule.name}
                    autoFocus={selectedIndex === index}
                    onBlur={(event) => {
                      setSelectedIndex(-1);
                    }}
                    onChange={(event) => {
                      onModuleNameChange(event.target.value, index);
                      setSelectedIndex(index);
                    }}
                    autocomplete="new-password"
                    autoComplete="off"
                  />
                </Col>
                <Col className="col-md-6">
                  <CustomizedSelects
                    data={scientificCoreCategories.map((coreClaimCategory) => {
                      return {
                        //id: coreClaimCategory.claimCategoryId,
                        name: coreClaimCategory.claimCategory,
                      };
                    })}
                    default={i18next.t("coreClaim.selectCoreClaimCategory")}
                    handleSelection={(selectedCategory) => {
                      onCategorySelect(
                        selectedCategory,
                        placeholderModule,
                        index,
                        true
                      );
                    }}
                  />
                  {placeholderModule.coreclaimcategories.length > 0 && (
                    <CustomChip
                      data={placeholderModule.coreclaimcategories}
                      handleDelete={(category) => {
                        onCategorySelect(
                          category,
                          placeholderModule,
                          index,
                          false
                        );
                      }}
                    />
                  )}
                  <button
                    className="btn-remove-placeholder btn btn-link"
                    disabled={placeholderModule.id || !placeholderModule.name}
                    onClick={() => {
                      removePlaceholder(index);
                    }}
                  >
                    Remove Placeholder
                  </button>
                </Col>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dmodalBtnRow">
        <button
          className="btn btn-link btn-add-another-placeholder"
          onClick={addAnotherModule}
        >
          Add another Module
        </button>
        <div style={{ float: "right", marginRight: "8px" }}>
          <button
            className="btn btn-link"
            onClick={(event) => {
              handleCreateModulePlaceHolderModal(false);
            }}
          >
            CANCEL
          </button>
          <button
            className=" btn btn-primary"
            disabled={!shouldProceedBeEnabled()}
            onClick={() => {
              proceedModuleCreation(
                type?.type === "denovo" ? "denovo" : "asset"
              );
            }}
          >
            Proceed with Module Creation
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModulePlaceHolder;
