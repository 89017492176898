import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/components/ReferenceAnchor.scss";
import searchIcon from "../../images/search.svg";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ReferenceDocumentsList from "./ReferenceDocumentsList";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { fontWeight } from "@material-ui/system";
import docPlaceholder from "../../images/anchor_thumb_pdf.svg";
import { searchReferenceDocs, searchReferences } from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import { getModuleInfoFromLocalStorage, moduleTypes } from "../../Uitls/Util";
import PdfTRonDemo from "./PDFTron";
const Reference = (props) => {
  const { getModuledetails, moduleType } = props;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(undefined);
  const [shouldPopulateSearchBar, setShouldPopulateSearchBar] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [refDocuments, setRefDocuments] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState({ head: "", subHead: "" });
  const [listHead, setListHead] = useState("Recent References");
  const [shouldProceedDisabled, setShouldProceedDisabled] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [moduleId, setModuleId] = useState(undefined);
  // const [brandName, setbrandName] = useState(undefined);
  useEffect(() => {
    setSearchInput(undefined);
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo) {
      setModuleId(moduleInfo.moduleId);
    }
  }, []);

  useEffect(() => {
    if (props.reference && shouldPopulateSearchBar) {
      setSearchInput(props.reference.reference);
    } else {
      setSearchInput("");
    }
  }, [props.reference]);

  useEffect(() => {
    if (!searchInput || searchInput.trim() === "") {
      setListHead("Recent References");
      setRefDocuments([]);
      setAfterSearchEmptyMessage(false);
    }
  }, [searchInput]);

  useEffect(() => {
    if (selectedDocuments && selectedDocuments.length > 0) {
      setShouldProceedDisabled(false);
    } else {
      setShouldProceedDisabled(true);
    }
  }, [selectedDocuments]);

  // useEffect(() => {
  //   getModuledetails();
  // }, []);
  const setAfterSearchEmptyMessage = (flag = true) => {
    setEmptyMessage({
      head: flag ? t("generalText.noRefFound") : "",
      subHead: flag ? t("generalText.tryUsinganotherKeyword") : "",
    });
  };

  const searchVeevaDocuments = (inputSearchField) => {
    if (moduleId && inputSearchField && inputSearchField.trim() !== "") {
      setIsloading(true);
      //Sending "" as parameter. Please don't remove it.
      searchReferenceDocs(
        inputSearchField,
        {
          docType: "Reference",
          sourceType: props.sourceType,
        },
        (response, error, errCode) => {
          setIsloading(false);
          if (response && response.data) {
            const arr = response.data.map((item) => {
              return { ...item, docThumbUrl: docPlaceholder };
            });
            setRefDocuments(arr);
            if (arr.length > 0) {
              setAfterSearchEmptyMessage(false);
            } else {
              setAfterSearchEmptyMessage();
            }
            setListHead(`Search Results (${response.data.length})`);
            return;
          }
          //Show failure toast
          const errCoponent = ErrComponentToRender(errCode);
          toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
        }
      );
    }
  };

  const searchVeevaDocumentdenovo = (inputSearchField) => {
    if (moduleId && inputSearchField && inputSearchField.trim() !== "") {
      const encodedInputSearchField = encodeURIComponent(inputSearchField);

      let searchPayload = {
        input: encodedInputSearchField, //inputSearchField,
        brandName: props.brandName,
      };
      setIsloading(true);
      //Sending "" as parameter. Please don't remove it.
      searchReferences(searchPayload, (response, error, errCode) => {
        setIsloading(false);
        if (error) {
          const errCoponent = ErrComponentToRender(errCode);
          //toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
        } else if (response && response.data) {
          const arr = response.data.map((item) => {
            return { ...item, url: docPlaceholder };
          });
          setRefDocuments(arr);
          if (arr.length > 0) {
            setAfterSearchEmptyMessage(false);
          } else {
            setAfterSearchEmptyMessage();
          }
          setListHead(`Search Results (${response.data.length})`);
          return;
        }
      });
    }
  };

  const executeSearch = () => {
    const inputSearchField = document.getElementById(
      "input-search-ref-documents"
    );
    if (moduleType === moduleTypes?.denovo) {
      searchVeevaDocumentdenovo(inputSearchField?.value);
    } else {
      searchVeevaDocuments(inputSearchField?.value);
    }
  };

  const onDocumentSelect = (event, id) => {
    const isChecked = event.target.checked;
    const document = refDocuments.find((document) => {
      return document.id === id;
    });
    const isAlreadyAdded = selectedDocuments.find((selDocument) => {
      return selDocument.id === id;
    });

    if (isChecked && !isAlreadyAdded) {
      setSelectedDocuments(selectedDocuments.concat(document));
    } else if (!isChecked && isAlreadyAdded) {
      setSelectedDocuments(
        selectedDocuments.filter((document) => {
          return document.id !== id;
        })
      );
    }
  };

  const handleNext = () => {
    if (props.refAnchorId === "supportingClaim-ReferenceAnchor") {
      props.updateSupportingClaimReference(selectedDocuments);
    } else if (props.refAnchorId === "supportingVisual-ReferenceAnchor") {
      props.updateSupportingVisualReference(selectedDocuments);
    } else {
      props.handleSelectedReference(selectedDocuments);
    }
  };

  return (
    <>
      <div className="modalCustomCommon refNAnchorModal custonScrollBar">
        <div className="headerconAnchor">
          <CircularProgressBar isloading={isloading} />
          <div className="row">
            <div className="col-md-5">
              <div className="ReferenceAnchorTitle">
                <span>References</span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="searchColAnchor">
                <div className="input-group mb-3">
                  <img src={searchIcon} alt="" className="searchIconAnchor" />
                  <input
                    id="input-search-ref-documents"
                    type="text"
                    className="form-control searchInputAnchor"
                    placeholder="Search Reference"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onFocus={(event) => {
                      setShouldPopulateSearchBar(true);
                      if (props.setFilterType) {
                        props.setFilterType(
                          props.filterType || "search-ref-doc"
                        );
                      }
                    }}
                    onChange={(event) => {
                      setSearchInput(event.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        executeSearch();
                      }
                    }}
                    value={shouldPopulateSearchBar ? searchInput : ""}
                  />
                  <button
                    className="anchorSearchBtn"
                    type="button"
                    id="button-addon2"
                    onClick={executeSearch}
                    disabled={searchInput?.length <= 0 ? true : false}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="recentReferencesLabel">{listHead}</p>
        <div className="body-Select custonScrollBar">
          <ReferenceDocumentsList
            documents={refDocuments}
            emptyMessage={emptyMessage}
            onDocumentSelect={onDocumentSelect}
            sourceType={props.sourceType}
            moduleTypes={moduleTypes}
            moduleType={moduleType}
          />
        </div>
        <div className="headerLinebottom"></div>
        <div className="footerButtonAnchor">
          <button
            type="button"
            className="btn btn-primary btn-lg refAnchorNext"
            style={{
              float: "right",
              marginRight: "30px",
              width: "157px",
              fontWeight: "bold",
            }}
            disabled={shouldProceedDisabled}
            onClick={handleNext}
          >
            NEXT
          </button>
          <button
            type="button"
            className="btn btn-link closeAnchor"
            style={{
              // marginLeft: "435px",
              fontSize: "12px",
              float: "right",
              marginRight: "20px",
              marginTop: "3px",
              fontWeight: "600",
            }}
            onClick={props.handleCloseReferenceAnchor}
          >
            CLOSE
          </button>
        </div>
      </div>
    </>
  );
};

export default Reference;
