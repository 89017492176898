import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import ChannelTabs from "./ChannelAttributes/ChannelTabs";
import ContentMatrixModalHeader from "./ContentMatrixModalHeader";
import ContentMatrixModalFooter from "./ContentMatrixModalFooter";
import GeneratedCompositeAssets from "./CompositeAssets/GeneratedCompositeAssets";
import { getActiveClassStatus } from "../../Uitls/Util";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import CompositeModuleSelection from "./ModuleSelection/CompositeAssetModuleSelection";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { capitalizeString } from "../../Uitls/Util";
import {
  generateChannelMatrix,
  publishMatrix,
  reGenerateContentMatrix,
} from "../../services/apis";
import CompositeAssetView from "./CompositeAssets/CompositeAssetView";
import CustomModal from "../../components/customModal";
//Import styles
import "../../styles/components/ContentMatrixModal.scss";
//Import images
import searchIcon from "../../images/search.svg";

const ContentMatrixModal = (props) => {
  const {
    hideContentMatrixModal,
    moduleDetails,
    templates,
    matrixData,
    contentMatrix,
    sourceType,
    assetDataType,
  } = props;
  const { t } = useTranslation();
  const [moduleContentData, setModuleContentData] = useState(false);
  const [activeClass, setActiveClass] = useState(null);
  const [showCurrentModule, setShowCurrentModule] = useState(false);
  const [selectedTemplate, setSelectedTamplate] = useState({});
  const [pageSwitch, setPageSwitch] = useState({
    pageOne: true,
    pageTwo: false,
    pageThree: false,
    pageFour: false,
  });
  const [previousPage, setPreviousPage] = useState("gotoModule");

  const [isloading, setIsloading] = useState(false);
  const [channelAttrElements, setChannelAttrElements] = useState([]);
  const [generateMatrixData, setGenerateMatrixData] = useState({});
  const [generatedCompositeAssetDetails, setGeneratedCompositeAssetDetails] =
    useState(undefined);
  const [compositeAssetToView, setCompositeAssetToView] = useState(undefined);
  const [bodyModuleListView, setBodyModuleListView] = useState(false);
  const [bodyModuleGroup, setBodyModuleGroup] = useState("");
  const [selectedModuleIds, setSelectedModuleIds] = useState([]);

  useEffect(() => {
    const defaultData = filteredModules("Mandatory");
    if (defaultData.length > 0) {
      showChannelAttributes(defaultData[0]);
    }
  }, [matrixData]);

  useEffect(() => {
    if (contentMatrix && templates && templates.length > 0) {
      onTemplateTypeChange(templates[0].templateType);
    }
  }, [templates, contentMatrix]);

  const apiError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const filteredModules = (moduleType) => {
    let filtered = matrixData.filter(
      (item) =>
        item.contentModuleType.toLowerCase() === moduleType.toLowerCase()
    );
    return filtered;
  };

  const showChannelAttributes = (data) => {
    if (data) {
      setChannelAttrElements(data);
      setActiveClass(data.moduleId);
    } else {
      setChannelAttrElements([]);
      setActiveClass(null);
    }
  };
  const handleCurrentModuleChannelAttr = (event) => {
    //send current Module data - Channel  Attribute Elements data
    let modules = [];
    if (event.target.checked) {
      modules = filteredModules("original");
    } else {
      modules = filteredModules("mandatory");
    }
    setShowCurrentModule(event.target.checked);
    if (modules.length > 0) {
      showChannelAttributes(modules[0]);
    } else {
      showChannelAttributes(undefined);
    }
  };

  const generateMatrixSpreadSheet = (callback) => {
    generateMatrixData.templateName = getSelectedTemplateName();
    setIsloading(true);
    generateChannelMatrix(
      generateMatrixData,
      sourceType,
      assetDataType,
      (response, err, errorCode) => {
        setIsloading(false);
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          apiError(errCoponent);
          return;
        } else if (response) {
          setGeneratedCompositeAssetDetails(() => {
            callback(true);
          });
          setGeneratedCompositeAssetDetails({
            compositeAssetDetails: response.data,
          });
        }
      }
    );
  };

  const reGenerateMatrixSpreadSheet = (callback) => {
    generateMatrixData.templateName = getSelectedTemplateName();
    setIsloading(true);
    reGenerateContentMatrix(
      generateMatrixData,
      sourceType,
      assetDataType,
      contentMatrix.contentMatrixId,
      (response, err, errorCode) => {
        setIsloading(false);
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          apiError(errCoponent);
          return;
        } else if (response) {
          setGeneratedCompositeAssetDetails(() => {
            callback(true);
          });
          setGeneratedCompositeAssetDetails({
            compositeAssetDetails: response.data,
          });
        }
      }
    );
  };

  const onTemplateTypeChange = (templateName) => {
    const selected = JSON.parse(JSON.stringify(templates)).find((template) => {
      return template.templateType === templateName;
    });
    let PageTwoData = {
      brand: moduleDetails.brandName,
      templateType: selected.templateType,
      campaign: moduleDetails.campaign,
      contentModules: matrixData.map((element) => {
        let addModuleSection = { ...element };
        addModuleSection["bodyModuleSection"] = "";
        return addModuleSection;
      }),
    };
    setSelectedTamplate(() => {
      if (contentMatrix) {
        onTemplateIdChange(
          selected.templates[0].templateId,
          selected,
          PageTwoData
        );
      }
    });
    setSelectedTamplate(selected);
    if (!contentMatrix) {
      setGenerateMatrixData(PageTwoData);
    }
  };

  const notifyPublishMatrixToDamsError = () => {
    toast.error(
      <>
        <p className="notificationHeading">{capitalizeString(sourceType)} Upload Un-successful</p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const notifyPublishMatrixToDamsSucess = () => {
    toast.success(
      <>
        <p className="notificationHeading">{capitalizeString(sourceType)} Upload successful</p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const publishMatrixToDams = () => {
    if (pageSwitch.pageOne) {
      hideContentMatrixModal();
      return;
    }
    setIsloading(true);
    publishMatrix(
      sourceType,
      assetDataType,
      generatedCompositeAssetDetails?.compositeAssetDetails.contentMatrixId,
      (response, err, errorCode) => {
        if (err) {
          notifyPublishMatrixToDamsError();
          setIsloading(false);
        } else {
          setIsloading(false);
          //hideContentMatrixModal();
          notifyPublishMatrixToDamsSucess();
        }
      }
    );
  };

  const proceedToNextScreen = (actionType, cameBack) => {
    switch (actionType) {
      case "pageOne":
        setPageSwitch({
          ...pageSwitch,
          pageOne: true,
          pageTwo: false,
          pageThree: false,
          pageFour: false,
        });
        setPreviousPage("gotoModule");
        break;
      case "pageTwo":
        setPageSwitch({
          ...pageSwitch,
          pageOne: false,
          pageTwo: true,
          pageThree: false,
          pageFour: false,
        });
        setPreviousPage("pageOne");
        break;
      case "pageThree":
        if (cameBack) {
          setPageSwitch({
            ...pageSwitch,
            pageOne: false,
            pageTwo: false,
            pageThree: true,
            pageFour: false,
          });
        } else {
          if (contentMatrix) {
            reGenerateMatrixSpreadSheet((matrixGenerated) => {
              if (matrixGenerated) {
                setPageSwitch({
                  ...pageSwitch,
                  pageOne: false,
                  pageTwo: false,
                  pageThree: true,
                  pageFour: false,
                });
              }
            });
          } else {
            generateMatrixSpreadSheet((matrixGenerated) => {
              if (matrixGenerated) {
                setPageSwitch({
                  ...pageSwitch,
                  pageOne: false,
                  pageTwo: false,
                  pageThree: true,
                  pageFour: false,
                });
              }
            });
          }
        }

        setPreviousPage("pageTwo");
        break;
      case "pageFour":
        setPageSwitch({
          ...pageSwitch,
          pageOne: false,
          pageTwo: false,
          pageThree: false,
          pageFour: true,
        });
        setPreviousPage("pageThree");
        break;
      default:
        publishMatrixToDams();
    }
  };

  const checkForEnableDisableButtonStatus = () => {
    if (pageSwitch.pageOne === true) {
      return !getSelectedTemplateId() ? true : false;
    } else if (pageSwitch.pageTwo === true) {
      let data = getUniqueContentModules();
      if (data.length > 0) {
        let result = false;
        for (var i in data) {
          if (data[i].bodyModuleSection === "") {
            result = true;
            break;
          }
        }
        return result;
      }
      return true;
    } else {
      return false;
    }
  };

  const getUniqueContentModules = () => {
    return [
      ...new Map(
        generateMatrixData.contentModules.map((v) => [v.moduleId, v])
      ).values(),
    ];
  };

  const addBodyModules = (bodyModuleGroup) => {
    setBodyModuleListView(true);
    setBodyModuleGroup(bodyModuleGroup);
    setFilteredModulesToBeAdded(getUniqueContentModules);
  };

  const hideAddBodyModules = () => {
    setBodyModuleListView(false);
    setBodyModuleGroup("");
    setSelectedModuleIds([]);
    setFilteredModulesToBeAdded([]);
  };
  const handleModuleSelectionCheckBox = (event, item) => {
    if (event.target.checked) {
      if (selectedModuleIds && selectedModuleIds.length > 0) {
        if (selectedModuleIds.indexOf(item.moduleId) === -1) {
          setSelectedModuleIds([...selectedModuleIds, item.moduleId]);
        }
      } else {
        setSelectedModuleIds([item.moduleId]);
      }
    } else {
      if (selectedModuleIds && selectedModuleIds.length > 0) {
        if (selectedModuleIds.indexOf(item.moduleId) !== -1) {
          setSelectedModuleIds(
            selectedModuleIds.filter((existingItem) => {
              return existingItem !== item.moduleId;
            })
          );
        }
      }
    }
  };

  const addSelectedModules = () => {
    let data = { ...generateMatrixData };
    data.contentModules = getUniqueContentModules().map((element) => {
      let index = selectedModuleIds.findIndex(
        (item) => item === element.moduleId
      );
      if (index > -1) {
        element.bodyModuleSection = bodyModuleGroup;
      }
      return element;
    });
    setGenerateMatrixData(data);
    hideAddBodyModules();
  };
  const [filteredModulesToBeAdded, setFilteredModulesToBeAdded] = useState([]);
  const searchModulesToBeAdded = (ev) => {
    let filtered = [];
    filtered = getUniqueContentModules().filter((module) => {
      return (
        module.moduleCode
          .toLowerCase()
          .includes(ev.target.value.toLowerCase()) ||
        module.moduleName.toLowerCase().includes(ev.target.value.toLowerCase())
      );
    });
    setFilteredModulesToBeAdded(filtered);
  };

  const validateChannelAttributes = () => {
    let isAdded = true;
    matrixData.forEach((element) => {
      if (
        element.optOut.length === 0 ||
        element.preheaders.length === 0 ||
        element.subjectlines.length === 0
      ) {
        isAdded = false;
      }
    });
    return isAdded;
  };

  const getTemplateIds = () => {
    const templates = selectedTemplate?.templates;
    let templateNames = templates?.map((template) => {
      return {
        value: template.templateId,
        isSelected: false,
      };
    });
    return templateNames || [];
  };
  const onTemplateIdChange = (
    templateId,
    template = { ...selectedTemplate },
    genMatrixObject = { ...generateMatrixData }
  ) => {
    //const template = { ...selectedTemplate };
    if (template && template?.templates) {
      const associatedTemplates = template.templates.map((template) => {
        if (template.templateId === templateId) {
          template.isSelected = true;
        } else {
          template.isSelected = false;
        }
        return template;
      });
      template.templates = associatedTemplates;
      setSelectedTamplate(template);
      genMatrixObject.templateId = templateId;
      setGenerateMatrixData(genMatrixObject);
    }
  };

  const getSelectedTemplateId = () => {
    const templates = selectedTemplate?.templates;
    const selected = templates?.find((template) => template.isSelected);
    if (selected) {
      return selected.templateId;
    }
    return undefined;
  };

  const getSelectedTemplateName = () => {
    const templates = selectedTemplate?.templates;
    const selected = templates?.find((template) => template.isSelected);
    if (selected) {
      return selected.templateName;
    }
    return undefined;
  };
  return (
    <div>
      {!(pageSwitch.pageThree || pageSwitch.pageFour) && (
        <ContentMatrixModalHeader
          moduleDetails={moduleDetails}
          templates={templates?.map((template) => template.templateType)}
          selectedTemplate={selectedTemplate}
          onTemplateTypeChange={onTemplateTypeChange}
          getTemplateIds={getTemplateIds}
          onTemplateIdChange={onTemplateIdChange}
          getSelectedTemplateId={getSelectedTemplateId}
          getSelectedTemplateName={getSelectedTemplateName}
          proceedToNextScreen={proceedToNextScreen}
          previousPage={previousPage}
          dropDownStatus={pageSwitch.pageOne === false || contentMatrix}
          pageSwitch={pageSwitch}
        />
      )}
      <CircularProgressBar isloading={isloading} />
      <div className="mb-4">
        {pageSwitch.pageOne && (
          <Row className="mt-3">
            <Col className="col-md-6">
              <div className="associatedModulesContainer associateModalHeight mt-3 customScrollBar">
                <div className="moduleListPanelTitle">
                  Mandatory (Must Exist with)
                </div>
                {filteredModules("Mandatory").map((item) => (
                  <div
                    className={`d-block moduleCodeRow ${
                      getActiveClassStatus(item.moduleId, activeClass)
                        ? "moduleCodeRowSelected"
                        : "inactive"
                    }`}
                    key={item.moduleId}
                    onClick={() => {
                      showChannelAttributes(item);
                      setShowCurrentModule(false);
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <span>{item.moduleName}</span>{" "}
                      <button className="btn btn-link p-0 m-0">View</button>
                    </div>
                  </div>
                ))}

                <div className="moduleListPanelTitle mt-3">
                  Optional (Related)
                </div>
                {filteredModules("optional").map((item) => (
                  <div
                    className={`d-block moduleCodeRow ${
                      getActiveClassStatus(item.moduleId, activeClass)
                        ? "moduleCodeRowSelected"
                        : "inactive"
                    }`}
                    key={item.moduleId}
                    onClick={() => {
                      showChannelAttributes(item);
                      setShowCurrentModule(false);
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <span>{item.moduleName}</span>{" "}
                      <button className="btn btn-link p-0 m-0">View</button>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col className="col-md-6 channelsAttrTabs">
              <Row className="mt-3 mb-2 align-items-center">
                <Col className="col-md-7 font-weight-bold">
                  Channel Attribute Elements | {channelAttrElements?.moduleCode}
                </Col>
                <Col className="text-right col-md-5 pl-0 pr-0">
                  Show for Current Module
                  <Switch
                    className="filterRightMargin mr-2"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    toggle={showCurrentModule}
                    checked={showCurrentModule}
                    onChange={handleCurrentModuleChannelAttr}
                  />
                </Col>
              </Row>
              <ChannelTabs channelAttrElements={channelAttrElements} />
            </Col>
          </Row>
        )}
        {pageSwitch.pageTwo && (
          <div className="associatedModulesContainer mt-5  customScrollBar composite-module-screen-height">
            <CompositeModuleSelection
              pageTwoData={generateMatrixData}
              setPageTwoData={setGenerateMatrixData}
              addBodyModules={addBodyModules}
              contentMatrix={contentMatrix}
            />
          </div>
        )}
        {pageSwitch.pageThree && (
          <GeneratedCompositeAssets
            proceedToNextScreen={proceedToNextScreen}
            previousPage={previousPage}
            generatedCompositeAssetDetails={generatedCompositeAssetDetails}
            setCompositeAssetToView={setCompositeAssetToView}
            sourceType={sourceType}
            publishMatrixToDams={publishMatrixToDams}
            closeModal={hideContentMatrixModal}
          />
        )}
        {pageSwitch.pageFour && (
          <CompositeAssetView
            proceedToNextScreen={proceedToNextScreen}
            data={compositeAssetToView}
            previousPage={previousPage}
            sourceType={sourceType}
            assetDataType={assetDataType}
          />
        )}
      </div>

      {!pageSwitch.pageThree && (
        <ContentMatrixModalFooter
          hideContentMatrixModal={() => {
            if (contentMatrix) {
              hideContentMatrixModal(
                generatedCompositeAssetDetails?.compositeAssetDetails
                  ?.compositeDetails
              );
              return;
            }
            hideContentMatrixModal();
          }}
          proceedToNextScreen={proceedToNextScreen}
          disablBtn={checkForEnableDisableButtonStatus()}
          previousPage={pageSwitch.pageFour ? previousPage : undefined}
          buttonsData={{
            closeBtnLabel: pageSwitch.pageFour ? "CLOSE" : "CANCEL",
            actionBtnLabel:
              pageSwitch.pageOne === true
                ? "Proceed"
                : pageSwitch.pageTwo === true
                ? contentMatrix
                  ? "Re-generate"
                  : "Generate Channel Matrix"
                : pageSwitch.pageThree
                ? "Publish matrix to DAMS"
                : "",
            actionType:
              pageSwitch.pageOne === true
                ? "pageTwo"
                : pageSwitch.pageTwo === true
                ? "pageThree"
                : pageSwitch.Three === true
                ? "pageFour"
                : "",
          }}
          footerContent={
            pageSwitch.pageOne === true && !contentMatrix
              ? "Select Template Type from the Dropdown"
              : pageSwitch.pageTwo === true
              ? "Select a sequence of arrangement"
              : pageSwitch.pageThree
              ? "Content Matrix Generation Complete (100%)"
              : ""
          }
        />
      )}
      {bodyModuleListView && (
        <CustomModal
          displayModal={bodyModuleListView}
          hideModal={hideAddBodyModules}
          title={"List of Associated Modules"}
          size="md"
          dilogClassName="alertModalSmall"
          // backdrop="false"
          keyboard={false}
        >
          <div className="bodyModuleSelctList">
            <div
              className="row"
              style={{ paddingRight: "1rem", marginBottom: "12px" }}
            >
              <div className="col-md-6">
                <p className="font-weight-bold">Body Modules</p>
              </div>
              <div
                className="col-md-6 suppClaimSearch"
                style={{ paddingRight: "5px" }}
              >
                <div className="d-flex">
                  <img src={searchIcon} alt="" className="searchIcon" />
                  <input
                    id="input-search-module"
                    type="text"
                    placeholder="Enter Module ID / Module Name"
                    className="create-matrix-search-module"
                    onChange={(e) => {
                      searchModulesToBeAdded(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <ul className="pl-0 pr-0">
              {filteredModulesToBeAdded.map((item, index) => (
                <li key={item.moduleId}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`moduleName${index}`}
                      disabled={item.bodyModuleSection === "" ? "" : "disabled"}
                      value={item.bodyModuleSection}
                      onChange={(event) =>
                        handleModuleSelectionCheckBox(event, item)
                      }
                      defaultChecked={
                        item.bodyModuleSection !== "" ? true : false
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`moduleName${index}`}
                    >
                      <span>
                        {item.moduleName}{" "}
                        {item.moduleName !== item.moduleCode ? (
                          <>
                            | <span>{item.moduleCode}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="row align-items-center martrixModalFooter matrxiFooterBrdr no-gutters">
            <div className="col-md-12 text-right">
              <button
                onClick={() => {
                  hideAddBodyModules();
                }}
                className="btn btn-link"
              >
                CANCEL
              </button>

              <button
                className="btn btn-primary"
                onClick={() => addSelectedModules()}
              >
                Add
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ContentMatrixModal;
