import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import parse from "html-react-parser";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import LogoutCTA from "../Pages/LogoutCTA";
import CoreClaimCategoryList from "../components/CoreClaimCategoryList";
import ArrowLeftOutlinedIcon from "@material-ui/icons/ArrowLeftOutlined";
import PageAtoms from "../components/pageAtoms";
import SupportingVisualRowToAdd from "../components/addSuppVisualRow.js";
import ReusableTextrowToAdd from "../components/addReusabletext.js";
import Modal from "react-bootstrap/Modal";
import searchIcon from "../images/search.svg";
import arrowpolygonRight from "../images/arrowpolygon-right.svg";
import Abbreviation from "../components/Abbreviation";
import PersonaToneModal from "../components/PersonaToneModal";
import abbreviationSupportingClaim from "../components/abbreviationSupportingClaim";
import AppContext from "../Uitls/AppContext";
import expandIcon from "../images/fullScreenIcon.svg";
import modalCloseIcon from "../images/modalCloseIcon.svg";
import ReusableTextModal from "../components/ReusableTextModal";
import {
  citationColors,
  getRefSuperscriptStyle,
  htmlToString,
  rgbToHex,
  addFootnoteSuperScript,
  sortSuperScriptNumbers,
  isThisMLRFeedbackFlow,
  damsSources,
  removeDuplicatesBasedOnMultipleKeys,
} from "../Uitls/Util";
import {
  saveClaims,
  getModuleDetailsById,
  deleteClaims,
  getLatestAnnotatedPdfFromVeeva,
  getCoreClaimCategories,
  addReusableTextList,
  deleteReusableText,
} from "../services/apis";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
import { areRefArraysEqual } from "../components/PrimaryClaim";
import SupportingClaimRowToAdd from "../components/addSuppClaimRow";
import {
  routes,
  getModuleInfoFromLocalStorage,
  moduleTypes,
  updateModuleInfo,
} from "../Uitls/Util";

import "../styles/pages/CreateModule.scss";
import "../styles/pages/CreateModuleStep2.scss";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import PrimaryClaim from "../components/PrimaryClaim";
import FroalaEditor from "react-froala-wysiwyg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { display } from "@material-ui/system";
import FloatingButton from "../Uitls/FloatingButton";
import { froalaEditor } from "../Uitls/FroalaConfig";
import { black } from "color-name";
import { currentPage } from "../Uitls/Util";
import FooteNotesModal from "../components/FooteNoteModal";
import { SpaceBar, UsbOutlined } from "@material-ui/icons";
import filterIcon from "../images/filterIcon.svg";
import { v4 as uuidv4 } from "uuid";
import { find } from "domutils";
import FootNoteEditor from "../Uitls/CommonFroalEditorComponent";
import ContextMenu from "../components/contextMenu";
import ReferenceContainer from "../components/ReferencesAndAnnotation/ReferenceContainer";
import ReferenceAnchor from "../components/ReferencesAndAnnotation/ReferenceAnchor";
import CustomModal from "../components/customModal";
import ModuleDetails, {
  sortClaimsAndVisualsByRecordIndex,
} from "./ModuleDetails";
import MlrFeedbackNav from "../components/MlrFeedback/MlrFeedbackNav";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";
import ImportImagesExternalModal from "../components/SupportingVisual/ImportImagesExternalModal";

function CreateModulePrimaryClaim(props) {
  let history = useHistory();
  const [pages, setPages] = useState([]);
  const [filterdPages, setFilteredPages] = useState([]);
  const [brandName, setBrandName] = useState(undefined);
  //const [brandId, setBrandId] = useState(undefined);
  //const [selectedPage, setSelectedPage] = useState(0)
  const [selectedPage, setSelectedPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(routes.addPrimaryClaim);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState(undefined);
  const { t } = useTranslation();
  //const atoms = props.location.state.step1.atoms || [];
  const [filterType, setFilterType] = useState("");
  const [atomsHeader, setAtomsHeader] = useState("Claims");
  const [selectedAtom, setSelectedAtom] = useState({});
  const [selectedBox, setSelectedBox] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedSuppBox, setSelectedSuppBox] = useState(-1);
  const [selectedSuppRow, setSelectedSuppRow] = useState(-1);
  const [atomsHeaderVisual, setAtomsHeaderVisual] = useState("");
  const [currentTextReplace, setCurrentTextReplace] = useState(0);
  const [currentVisualTextReplace, SetCurrentVisualTextReplace] = useState(0);

  const [currentTransTextReplace, setCurrentTransTextReplace] = useState(0);
  const [boxSeletedBoxType, SetBoxSeletedBoxType] = useState("");
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [arrCountCheckBox, setArrCountCheckBox] = useState([]);
  const [isAnnotationBtnDisable, setIsAnnotationBtnDisable] = useState(true);
  const [abbrNoteText, setAbbrNoteText] = useState([]);
  //const [isFreeze, setIsFreeze] = useState(false);
  const [displayProcedGroupsAlert, setDisplayProcedGroupsAlert] =
    useState(false);

  const [addSupportingBtnDisable, setAddSupportingBtnDisable] = useState(false);
  const [displayReferenceAlert, setDisplayReferenceAlert] = useState(false);
  const [displaySupportingAlert, setDisplaySupportingAlert] = useState(false);
  const [displaySupportingVisualAlert, setDisplaySupportingVisualAlert] =
    useState(false);
  const [displayReusableAlert, setDisplayReusableAlert] = useState(false);
  const [reusBussinessRules, setReusBussinessRules] = useState("");
  const [isCaptionAdded, setIsCaptionAdded] = useState(true);
  const [isAnnotationBtnDisableButtun, setIsAnnotationBtnDisableButtun] =
    useState(true);
  const [primaryClaimSelectedIndex, setPrimaryClaimSelectedIndex] = useState(0);
  const [primaryClaimSelectedAtom, setPrimaryClaimSelectedAtom] =
    useState(undefined);
  const [filteredReusableTexts, setFilteredReusableTexts] = useState([]);
  const [
    shouldDisableModuleProceedButton,
    setShouldDisableModuleProceedButton,
  ] = useState(true);
  const [footNoteIndex, setFootNoteIndex] = useState(-1);
  const [assetModuleId, setAssetModuleId] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [moduleAssetId, setModuleAssetId] = useState("");
  const [moduleType, setModuleType] = useState("");
  const [mlrModuleStatus, setMlrModuleStatus] = useState("");
  const [veevaDocId, setVeevaDocId] = useState("");
  const [eventType, setEventType] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [currentImageReplace, SetCurrentImageReplace] = useState(0);
  const [primaryClaim, setPrimaryClaim] = useState([]); // it will have varients, so using array
  const [
    selectedPageNumberForPrimaryClaim,
    setSelectedPageNumberForPrimaryClaim,
  ] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [selectedTextPrimaryClaimValue, setSelectedTextPrimaryClaimValue] =
    useState("");

  //Selected Text to add references, abbr etc...
  const [selectedText, setSelectedText] = useState(undefined);
  const [selectedTextIdxRange, setSelectedTextIdxRange] = useState([0, 0]);
  const [globalAbbrList, setGlobalAbbrList] = useState([]);
  const config1 = froalaEditor.config({
    placeHolder: "Enter Reference Information",
    shouldHaveToolbar: true,
    color: "white",
  });
  const referencesValid = useRef(null);
  const [abbrStatsu, setAbbrStatus] = useState(true);
  const [viewAssetModal, setViewAssetModal] = useState(false);
  const [assetUrl, setAssetUrl] = useState("");
  const [bgBlurForDenovo, setBgBlurForDenovo] = useState(false); // To make the bg blur for denovo module

  //For Addressing MLR Feedback scenarios only
  const [module, setModule] = useState(undefined);
  const [addedReusableTextIds, setAddedReusableTextIds] = useState(undefined);
  const [pdfUrl, setPdfUrl] = useState(undefined);
  const [isMainContentExpanded, setMaincontentExpanded] = useState(false);
  const [isPdfContentExpanded, setPdfcontentExpanded] = useState(false);
  const pdfElementRef = useRef(null);
  const [pdfwidth, setpdfWidth] = useState(0);
  const [personaModal, setPersonaModal] = useState(false);
  const [showReusableTextModal, setShowReusableTextModal] = useState(false);
  //For save button enable disable while network in progress
  const [enableSave, setEnableSave] = useState(true);
  const [reusableTextOption, setReusableTextOption] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedRowsDetails, setSelectedRowsDetails] = useState([]);
  const [selectedReusableTextIds, setSelectedReusableTextIds] = useState([]);
  const [suppVisualMustUse, setSuppVisualMustUse] = useState(true);
  const [isMustUseValues, setIsMustUseValues] = useState([]);
  const [suppVisualBussinessRules, setSuppVisualBussinessRules] =
    useState(undefined);
  const hideReusableTextModal = () => {
    setShowReusableTextModal(false);
    setSelectedReusableTextIds([]);
    setSelectedRowsDetails([]);
  };
  useEffect(() => {
    if (isMainContentExpanded) {
      setPdfcontentExpanded(false);
    } else if (isPdfContentExpanded) {
      setMaincontentExpanded(false);
    }
    calculatePdfWidth();
  }, [isMainContentExpanded, isPdfContentExpanded]);

  const calculatePdfWidth = () => {
    if (pdfElementRef && pdfElementRef.current) {
      setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    }
    // setTimeout(() => {
    //   setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    // }, 200);
  };

  const shouldShowTheAtomsCol = () => {
    if (moduleType !== moduleTypes.denovo) {
      if (isThisMLRFeedbackFlow()) {
        if (isMainContentExpanded) {
          return true;
        }
        return false;
      }
      return true;
    } else if (filterType === "graphic" && moduleType === moduleTypes.denovo) {
      if (isThisMLRFeedbackFlow()) {
        if (isMainContentExpanded) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  const showExpandedAssetModal = (image) => {
    setAssetUrl(image);
    setViewAssetModal(true);
  };

  const hideModal = () => {
    setViewAssetModal(false);
  };

  const renderExpandedAssetModal = () => {
    return (
      <CustomModal
        displayModal={viewAssetModal}
        hideModal={hideModal}
        title={"View Asset Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop="false"
      >
        <button
          className="btn btn-link pl-0 assetModalCloseBtn"
          onClick={hideModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
        <div className="assetImageModalBlock custonScrollBar">
          <img src={assetUrl} alt="" className="img-fluid" />
        </div>
      </CustomModal>
    );
  };

  useEffect(() => {
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      //console.log(moduleInfo.moduleId);
      setAssetModuleId(moduleInfo.moduleId);
      setModuleCode(moduleInfo.moduleCode);
      setModuleName(moduleInfo.moduleName);
      setModuleAssetId(moduleInfo.moduleId);

      setModuleType(moduleInfo.creationMethodType);
    }
  }, []);

  useEffect(() => {
    navigateToEnteredText(undefined, selectedPage);
  }, [selectedPage]);
  //Footenotes Code Start
  const [showFootNote, setFootNoteModal] = useState(false);
  const [footNoteText, setFootNoteText] = useState([]);
  const [addBtnStatus, setAddBtnStatus] = useState(false);
  const [addIconStatus, setAddIconStatus] = useState(false);
  const [selectedFootNotes, setSelectedFootNotes] = useState([]);
  const [footNoteIcon, setFootNoteIcon] = useState([]);
  const [suppClaimfootNoteText, setSuppClaimFootNoteText] = useState([]);
  const [isAbbrDisable, setIsAbbrDisable] = useState(false);
  const [rightClickTriggered, setRightClickTriggered] = useState(false);
  const [contextMenuOptions, setContextMenuOptions] = useState(undefined);
  const [addOrModifyanchorData, setAddOrModifyAnchorData] = useState(undefined);
  const [froalaPlaceHolder, setFrroalaPlaceHolder] = useState("Primary Claim");
  const [footNoteCheckBox, setFootNoteCheckBox] = useState([]);
  const [iconForSuperScript, setIconForSuperScript] = useState([]);
  const [iconArray, setIconArray] = useState([]);
  const [flag, setFlag] = useState(false);
  const [superScriptRefData, setSuperScriptRefData] = useState([]);
  const handleFootNoteMOdalClose = () => {
    setFootNoteText([]);
    setFootNoteIcon([]);
    setFootNoteModal(false);
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setIconForSuperScript([]);
    setFootNoteCheckBox([]);
    setIconArray([]);
  };

  const handleFootNoteInputChange = (TextChanged) => {
    referencesValid.current.innerHTML = TextChanged;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(true);
    } else if (TextChanged.trim().length !== 0) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    } else {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    }
  };

  const getSuperScriptsFromFootnote = () => {
    let uniqueFootNoteRef = [];
    let myArray = [];
    selectedFootNotes.map((object) => {
      var para = object.footNoteText;
      para = para.replaceAll(/\s/g, "");
      if (para.includes("<sup")) {
        var Result = para.includes("<sup")
          ? Object.values(para.match(/<sup(.*?)<\/sup>/g))
          : Object.values(para.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    uniqueFootNoteRef = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return uniqueFootNoteRef;
  };

  const handleFootNoteTextChangeDirect = (event, rowId) => {
    if (rowId > -1) {
      var data = [...selectedFootNotes];
      var index = data.findIndex((obj, index) => index === rowId);
      data[index].footNoteText = event;
      setSelectedFootNotes(data);
    }
    //setFootNoteIndex(-1);
    setReferenceNumber([]);
    setSelectedTextPrimaryClaimValue("");
    let temp = [];
    selectedFootNotes.forEach((el) => temp.push(el.footNoteText));
    let newModel = temp.join().toLowerCase();
    let primarClaimString = [];
    primaryClaim.forEach((el) => primarClaimString.push(el.claim));
    let finalStringToBeCheckedForAbbreviation = newModel.concat(
      primarClaimString.join().toLowerCase()
    );
    arrAbbr &&
      arrAbbr.forEach((element, index) => {
        let result = finalStringToBeCheckedForAbbreviation.search(
          element.title.toLowerCase().trim()
        );
        if (result < 0) {
          arrAbbr.splice(index, 1);
        }
      });
    setSuperScriptRefData(newModel);
    //handleDelete(newModel);
  };

  useEffect(() => {
    let linkedFalse = primaryReferenceAnchor.filter(
      (o) => o.veevaLinked == false
    );
    let superScriptsFootNote = getSuperScriptsFromFootnote();
    let uniqueFootNoteRef = getSuperScriptsFromPrimaryClaim();
    let finalResult = [...uniqueFootNoteRef, ...superScriptsFootNote];
    let finalRef = [...new Set(finalResult)];
    let tempPrimary = [];
    finalRef.forEach((el) => {
      const obj = primaryReferenceAnchor.filter((o) => o.refId == el);
      tempPrimary.push.apply(tempPrimary, obj);
    });

    let tempResult = [];
    superScriptsFootNote.forEach((element) => {
      if (parseInt(element)) {
        const obj = tempPrimary.find((o) => o.refId === element);
        tempResult.push(obj);
      }
    });
    let notPresentInFootNoteBox = tempPrimary.filter(
      checkForUnique(tempResult)
    );
    notPresentInFootNoteBox.forEach((element) => {
      let itemPosition = element.annotationList.findIndex(
        (obj) =>
          obj.compIndex === footNoteIndex &&
          obj.linkType === "primary-claim-footnote"
      );
      if (itemPosition > -1) {
        element.annotationList.splice(itemPosition, 1);
      }
      return element;
    });
    let finalList = tempResult.concat(notPresentInFootNoteBox, linkedFalse);
    //let finalList = tempPrimary.concat(linkedFalse);
    setFootNoteIndex(-1);
    setPrimaryReferenceAnchor(finalList);
  }, [superScriptRefData]);

  const getSuperScriptsFromPrimaryClaim = () => {
    let uniqueFootNoteRef = [];
    let myArray = [];
    primaryClaim.map((claim) => {
      let object = claim.claim;
      if (object && object.includes("<sup")) {
        object = object.replaceAll(/\s/g, "");
        var Result = object.includes("<sup")
          ? Object.values(object.match(/<sup(.*?)<\/sup>/g))
          : Object.values(object.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    uniqueFootNoteRef = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return uniqueFootNoteRef;
  };

  const handleAddFootNote = () => {
    setFlag(true);
  };

  useEffect(() => {
    if (flag) {
      const fDataOne = footNoteText;
      const fDataTwo = footNoteIcon;
      const finalFootNoteData = {
        footNoteText: fDataOne.footText,
        footNoteIcon: fDataTwo.footNoteIconValue,
      };
      setIconForSuperScript(footNoteIcon);
      let temp = [...selectedFootNotes];
      temp.push(finalFootNoteData);
      setSelectedFootNotes(temp);
      setAddBtnStatus(false);
      setAddIconStatus(false);
      setFootNoteText([]);
      setFootNoteModal(false);
    }
    setFlag(false);
  }, [flag]);

  const getIconValue = (IconValue) => {
    if (IconValue) {
      const newIconValue = { footNoteIconValue: IconValue };
      setFootNoteIcon(newIconValue);
      setAddIconStatus(true);
    }
  };
  const setIconValueToInitial = () => {
    setFootNoteIcon([]);
    setAddIconStatus(false);
  };
  const [checkBoxHelper, setCheckBoxHelper] = useState([]);
  const handleFootNoteCheckBox = (event, index, icon) => {
    if (event.target.checked) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) == -1) {
          setFootNoteCheckBox([...footNoteCheckBox, icon]);
        }
      } else {
        setFootNoteCheckBox([icon]);
      }
      setCheckBoxHelper(checkBoxHelper.concat(icon));
    } else {
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) !== -1) {
          setFootNoteCheckBox(
            footNoteCheckBox.filter((existingItem) => {
              return existingItem !== icon;
            })
          );
        }
      }
      setCheckBoxHelper(
        checkBoxHelper.filter((item) => {
          return item !== icon;
        })
      );
    }
  };

  useEffect(() => {
    if (checkBoxHelper && checkBoxHelper.length > 0) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
    } else {
      setAddIconStatus(false);
      setAddBtnStatus(false);
      setIconValueToInitial();
      setFootNoteCheckBox([]);
    }
  }, [checkBoxHelper]);

  const setAllToInitial = () => {
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setIconValueToInitial();
    setFootNoteCheckBox([]);
    setFootNoteText([]);
  };

  const handleAddFootNoteCheckBox = () => {
    const temp = [];
    for (let i of footNoteCheckBox) {
      temp.push(i);
    }
    setIconArray(temp);
    setAddBtnStatus(false);
    setAddIconStatus(false);
    setFootNoteText([]);
    setFootNoteModal(false);
    setIconForSuperScript([]);
  };

  let froalaInstance = useRef([]);
  let ind = froalaInstance.current.indexOf(
    (obj, index) => index === footNoteIndex
  );
  const [anchorAddedIn, setAnchorAddedIn] = useState(undefined);

  const handleCont = (event) => {
    if (event && event.target.nodeName === "SUP") {
      onFootnoteSupClick(event);
      return;
    }
    let SelectedText = "";
    try {
      if (froalaInstance.current) {
        let y = froalaInstance.current[footNoteIndex]
          ?.getEditor()
          ?.toolbar?.hide();
        setTimeout(function () {
          SelectedText = froalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.selection?.text();
          let x = froalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.toolbar?.hide();
          setSelectedTextPrimaryClaimValue(SelectedText);
          //setRightClickTriggered(true);
        }, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  document.addEventListener("mouseup", (event) => {
    event.preventDefault();
    if (rightClickTriggered) {
      setTimeout(function () {
        hideEditorToolBar();
      }, 2);
    }
  });

  useEffect(() => {
    if (!rightClickTriggered) {
      hideEditorToolBar(false);
    }
  }, [rightClickTriggered]);

  const hideEditorToolBar = (flag = true) => {
    if (
      froalaInstance &&
      froalaInstance.current[footNoteIndex] &&
      froalaInstance.current[footNoteIndex].getEditor() &&
      froalaInstance.current[footNoteIndex].getEditor().toolbar
    ) {
      if (flag) {
        froalaInstance.current[footNoteIndex].getEditor().toolbar.hide();
      } else {
        froalaInstance.current[footNoteIndex].getEditor().toolbar.show();
      }
    }
  };
  //Footenote Code Ends

  //New Reference and anchors starts here
  const [primaryReferenceAnchor, setPrimaryReferenceAnchor] = useState([]);

  useEffect(() => {
    if (props && props.location && props.location.state) {
      if (props.location.state.filteredPages) {
        setPages(props.location.state.filteredPages);
      }
      const brandName = props.location.state.brandName;
      if (brandName && brandName.trim() !== "") {
        setBrandName(brandName);
      }
      // if(props.location.state.moduleId){
      //   window.$moduleId = props.location.state.moduleId
      // }
    }
  }, []);
  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedSlider
    ) {
      setSelectedPage(props.location.state.selectedSlider - 1);
    }
  }, []);
  useEffect(() => {
    setFilteredPages(pages);
  }, [pages]);

  useEffect(() => {
    if (filterdPages.length > 0) {
      const topCategories = filterdPages[
        selectedPage
      ].MetaData.topMessageCategories.map((topCat) => {
        return {
          claimCategoryId: topCat.messageCategoryId,
          claimCategory: topCat.messageCategory,
        };
      });
      setSelectedCategories(topCategories);
    }
  }, [filterdPages]);

  useEffect(() => {
    setSelectedAtom(null);
  }, [selectedRow, selectedBox]);

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  const onCoreClaimAndCategorySelect = ({ categories, claim }) => {
    setSelectedCategories(categories);
    if (Object.keys(claim).length <= 0) {
      setSelectedClaim(undefined);
    } else {
      setSelectedClaim(claim);
    }
    setSelectedAtom(null);
  };

  const onAtomDoubleClick = (event) => {
    setRefValue("");
    setAbbrNoteText("");
    const atom = getAtomById(event.target.id);
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated = rowToBeUpdated.rowData[selectedBox];
      if (boxSeletedBoxType === "graphic") {
        const newImage = {
          imgSrc: atom.document,
          sourceId: atom._id,
          sourceType: "Atom",
          cmDocumentNumber: atom.cmDocumentNumber,
        };

        boxToBeUpdated.data.splice(currentImageReplace, 1, newImage);
        setDisplaySupportingVisualAlert(false);
      } else if (boxSeletedBoxType === "text" && filterType === "text") {
        const newText = {
          cpationText: atom.text,
          cmDocumentNumber: atom.cmDocumentNumber,
        };
        boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
      }

      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      if (filterType === "supporting-claim") {
        const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
        const boxToBeUpdated = rowToBeUpdated.rowData[selectedSuppBox];
        const newText = {
          atomText: atom.text ? atom.text : " ",
          sourceId: atom._id || undefined,
          cmDocumentNumber: atom.cmDocumentNumber,
        };
        boxToBeUpdated.data.splice(currentTextReplace, 1, newText);
        setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
      }
    }
    if (filterType === "footnote-modal") {
      const newText = { footText: atom.text ? atom.text : " " };
      setFootNoteText(newText);
      setAddBtnStatus(true);
    }
    if (filterType === "supporting-footnote") {
      const newText = { footText: atom.text ? atom.text : " " };
      setSuppClaimFootNoteText(newText);
      setAddBtnStatus(true);
    }
    if (filterType === "supporting-abbreviationModal") {
      const newText = { atomText: atom.text ? atom.text : " " };
      setSuppClaimFootNoteText(newText);
      setAbbrStatus(false);
    }
    if (filterType === "referenceModal") {
      const newText = { reference: atom.text ? atom.text : " " };
      setRefText(newText);
      setrefAddStatus(true);
    }
    if (filterType === "abbreviationModal") {
      const newText = { abbrText: atom.text ? atom.text : " " };
      setAbbrNoteText(newText);
      setAbbrStatus(false);
    }
    if (
      filterType === "supporting-referenceModal" ||
      filterType === "visual-referenceModal"
    ) {
      const newText = { reference: atom.text ? atom.text : " " };
      setAtomTextForRef(newText);
      //setRefText(newText);
      //setrefAddStatus(true);
    }
    if (filterType === "search-ref-doc") {
      const newText = { reference: atom.text ? atom.text : " " };
      setRefText(newText);
      setrefAddStatus(true);
    }

    setSelectedAtom(atom);
  };

  const checkSelectCoreClaim = () => {
    if (selectedCategories.length > 0 && !selectedClaim) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (
      filterType === "primary-claim" &&
      checkSelectCoreClaim() &&
      selectedAtom &&
      selectedAtom !== null
    ) {
      if (primaryClaim && primaryClaim.length > 0) {
        if (primaryClaimSelectedIndex <= 0) {
          setPrimaryClaim([
            {
              claim: selectedAtom ? selectedAtom.text : "",
              cmDocumentNumber: selectedAtom.cmDocumentNumber,
              persona: null,
              tone: null,
            },
          ]);
          setPrimaryClaimSelectedAtom(selectedAtom);
          setSelectedPageNumberForPrimaryClaim(selectedPage + 1);
        } else {
          let existingData = [...primaryClaim];
          const obj = {
            claim: selectedAtom ? selectedAtom.text : "",
            cmDocumentNumber: selectedAtom.cmDocumentNumber,
            persona: null,
            tone: null,
          };
          existingData[primaryClaimSelectedIndex] = obj;
          setPrimaryClaim(existingData);
        }
      } else {
        setPrimaryClaim([
          {
            claim: selectedAtom ? selectedAtom.text : "",
            cmDocumentNumber: selectedAtom.cmDocumentNumber,
            persona: null,
            tone: null,
          },
        ]);
        setPrimaryClaimSelectedAtom(selectedAtom);
        setSelectedPageNumberForPrimaryClaim(selectedPage + 1);
      }
    }
  }, [selectedAtom]);

  useEffect(() => {
    if (primaryClaim && primaryClaim.length > 0) {
      //Freeze the core claim
      const mainPrimaryClaim = primaryClaim[0].claim;
      //compare this text with all the atoms available and see if matches
      const selectedAtom = primaryClaimSelectedAtom;
      const isMatching = isMatchingWithAnAtomText(
        removeAllSuperscriptTags(mainPrimaryClaim)
      );

      if (!isMatching) {
        setPrimaryClaimSelectedAtom(undefined);
        if (primaryClaimSelectedIndex === 0) {
          setSelectedPageNumberForPrimaryClaim(selectedPage + 1);
        }
      } else {
      }
    } else {
      //unfreeze core claim and reset the selected atom
      setSelectedAtom(null);
      setPrimaryClaimSelectedAtom(undefined);
      setSelectedPageNumberForPrimaryClaim("");
    }
  }, [primaryClaim]);

  const getAtomById = (id) => {
    return filterdPages[selectedPage].atoms.filter((atom) => {
      return atom._id === id;
    })[0];
  };

  const renderAtoms = () => {
    return (
      filterdPages.length > 0 &&
      filterdPages[selectedPage].atoms.map((atom) => {
        var atomeToUse = atom;
        if (filterType) {
          if (filterType === "primary-claim" && atom.MetaData.type !== "text") {
            atomeToUse = null;
          }
          if (filterType === "filterType" && atom.MetaData.type !== "text") {
            atomeToUse = null;
          }
          if (filterType === "text" && atom.MetaData.type !== "text") {
            atomeToUse = null;
          }
          if (
            filterType === "referenceModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "abbreviationModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "supporting-claim" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "footnote-modal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "supporting-footnote" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "supporting-abbreviationModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "supportingVisual-abbreviationModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (filterType === "graphic" && atom.MetaData.type !== "graphic") {
            atomeToUse = null;
          }
          if (
            filterType === "supporting-referenceModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "visual-referenceModal" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
          if (
            filterType === "search-ref-doc" &&
            atom.MetaData.type !== "text"
          ) {
            atomeToUse = null;
          }
        }
        return (
          atomeToUse && (
            <li
              onDoubleClick={onAtomDoubleClick}
              className="d-flex justify-content-center align-items-center"
              key={atomeToUse._id}
            >
              <div className="asset-with-expand">
                <img
                  id={atomeToUse._id}
                  src={atomeToUse.document}
                  className="mr-3"
                  alt=""
                />
                <div className="claim-page-atom-expand-icon">
                  <img
                    src={expandIcon}
                    alt=""
                    onClick={() => {
                      showExpandedAssetModal(atomeToUse.document);
                    }}
                  />
                </div>
              </div>
              <TurnedInNotIcon className="bookmarkOutline" />
            </li>
          )
        );
      })
    );
  };

  const updatePrimaryClaim = (
    updatedPrimaryClaim,
    selectedIndex,
    superScriptData
  ) => {
    let supClaimsAndVisuals = [...supportingClaimsAndVisuals];
    supClaimsAndVisuals.forEach((el) => {
      if (el.type === "supportingVisual") {
        const claims = (el.rowData[5] && el.rowData[5].claim) || [];

        claims.forEach((claim) => {
          if (claim.moduleClaimId === primaryClaimId) {
            claim.claimText =
              updatedPrimaryClaim && updatedPrimaryClaim[0].claim;
          }
        });
      }
    });
    setSupportingClaimsAndVisuals(supClaimsAndVisuals);
    setTimeout(() => {
      setPrimaryClaim(updatedPrimaryClaim);
    });
    setPrimaryClaimSelectedIndex(selectedIndex);
    setSelectedAtom(null);
    setIconArray([]);
    setFootNoteIcon([]);
    setReferenceNumber([]);
    setIconForSuperScript([]);
    setFootNoteCheckBox([]);
    let primarySuperScript = getSuperScriptsFromPrimaryClaim();
    let linkedFalse = primaryReferenceAnchor.filter(
      (o) => o && o.veevaLinked === false
    );
    let finalRef = commmonFilterReferences(primarySuperScript);
    let tempPrimary = [];
    finalRef.forEach((el) => {
      const obj = primaryReferenceAnchor.filter((o) => o && o.refId == el);
      tempPrimary.push.apply(tempPrimary, obj);
    });

    let tempResult = [];
    primarySuperScript.forEach((element) => {
      if (parseInt(element)) {
        const obj = tempPrimary.find((o) => o.refId == element);
        tempResult.push(obj);
      }
    });
    let notPresentInPrimaryClaimBox = tempPrimary.filter(
      checkForUnique(tempResult)
    );
    notPresentInPrimaryClaimBox.forEach((element) => {
      let itemPosition = element.annotationList.findIndex(
        (obj) =>
          obj.compIndex === selectedIndex &&
          obj.linkType === "primary-claim-varient"
      );
      if (itemPosition > -1) {
        element.annotationList.splice(itemPosition, 1);
      }
      return element;
    });
    let finalList = tempResult.concat(notPresentInPrimaryClaimBox, linkedFalse);
    setPrimaryReferenceAnchor(finalList);

    setTimeout(() => {
      let tempFootnote = [];
      primarySuperScript.forEach((el) => {
        const objFoot = selectedFootNotes.filter((o) => o.footNoteIcon === el);
        tempFootnote.push.apply(tempFootnote, objFoot);
      });
      setSelectedFootNotes(tempFootnote);
      //Deleting Abbreviation
      let primaryClaimString = [];
      updatedPrimaryClaim.forEach((el) => primaryClaimString.push(el.claim));
      let footNoteValue = [];
      tempFootnote.forEach((element) =>
        footNoteValue.push(element.footNoteText)
      );
      let footNoteTextString = footNoteValue.join().toLowerCase();
      let finalStringToBeChecked = primaryClaimString
        .join()
        .toLowerCase()
        .concat(footNoteTextString);
      if (finalStringToBeChecked === "") {
        setArrAbbr([]);
      } else {
        arrAbbr &&
          arrAbbr.forEach((element, index) => {
            let result = finalStringToBeChecked.search(
              element.title.toLowerCase().trim()
            );
            if (result < 0) {
              arrAbbr.splice(index, 1);
            }
          });
        setArrAbbr(arrAbbr);
      }
      //Ends here
    }, 0);
  };

  const checkForUnique = (otherArray) => {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other && other.refId == current.refId;
        }).length == 0
      );
    };
  };
  const commmonFilterReferences = (data) => {
    let uniqueFootNoteRef = getSuperScriptsFromFootnote();
    //console.log("uniqueFootNoteRef",uniqueFootNoteRef,data);
    let mySet = new Set(uniqueFootNoteRef);
    let finalResult = [...uniqueFootNoteRef, ...data];
    let result = [...new Set(finalResult)];
    return result;
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalAnchor, setShowModalAnchor] = useState(false);
  const [referenceMasterList, setReferenceMasterList] = useState([]);
  const [getSupprortingClaimReferences, setGetSupprortingClaimReferences] =
    useState([]);
  const [getSupprortingVisualReferences, setGetSupprortingVisualReferences] =
    useState([]);
  const [getAbbrMasterList, setGetAbbrMasterList] = useState([]);
  const [getPrimaryAbbrList, setGetPrimaryAbbrList] = useState([]);
  const [getSupprtingClaimAbbrList, setGetSupprtingClaimAbbrList] = useState(
    []
  );
  const [getSupprtingVisualAbbrList, setGetSupprtingVisualAbbrList] = useState(
    []
  );

  const [suppClaimRefAdded, setSuppClaimRefAdded] = useState(false);
  const [suppVisualRefAdded, setSuppVisualRefAdded] = useState(false);

  const [refText, setRefText] = useState([]);
  const [refAddStatus, setrefAddStatus] = useState(false);
  const [primaryReference, setPrimaryReference] = useState([]);
  const [tempMasterList, setTempMasterList] = useState([]);
  const [tempPrimaryRef, setTempPrimaryRef] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState([]);
  const [atomTextForRef, setAtomTextForRef] = useState([]);
  const [primaryMustUse, setPrimaryMustUse] = useState(true);
  const [primaryBussinessRules, setPrimaryBussinessRules] = useState(undefined);

  // const handleSourceModuleToggle = (index) => {
  //   setPrimaryMustUse(!primaryMustUse);
  // };
  const handlePrimaryClaimMustuse = () => {
    setPrimaryMustUse((prevPrimaryMustUse) => !prevPrimaryMustUse);
    setPrimaryClaim((prevClaims) => {
      return prevClaims.map((claim) => ({
        ...claim,
        isMustUse: !primaryMustUse,
      }));
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setPrimaryBussinessRules(value);

    setPrimaryClaim((prevClaims) => {
      return prevClaims.map((claim) => ({
        ...claim,
        usageGuidelines: value,
      }));
    });
  };
  let prevProps = usePrevious({
    selectedTextPrimaryClaimValue,
    referenceNumber,
  });
  const handleCloseRefModal = () => {
    setShowModal(false);
    setAddOrModifyAnchorData(undefined);
    setAnchorAddedIn(undefined);
  };

  const handleCloseReferenceAnchor = () => {
    setShowModalAnchor(false);
  };

  const updateInMasterList = (selectedIds, Data) => {
    let result = [];
    selectedIds.forEach(function (entry) {
      let obj = Data.find((o) => o.refId === entry);
      result.push(obj.refId);
    });
    setReferenceNumber(result);
    const newArray = [...referenceMasterList];
    const refTempArray = [...primaryReference];
    newArray.push.apply(newArray, Data);
    const arrayHashmap = newArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    const finalListmergedArray = removeDuplicates(mergedArray, "refId");
    let filteredArray = removeDuplicateAnnotation(finalListmergedArray);
    setTempMasterList(filteredArray);
    refTempArray.push.apply(refTempArray, Data);
    const arrayTemp = refTempArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const helperArray = Object.values(arrayTemp);
    const finalMergedArray = removeDuplicates(helperArray, "refId");
    let filteredFinalArray = removeDuplicateAnnotation(finalMergedArray);
    setTempPrimaryRef(filteredFinalArray);
  };

  useEffect(() => {
    const master = removeDuplicates(tempMasterList, "refId");
    setReferenceMasterList(master);
    const primaryList = removeDuplicates(tempPrimaryRef, "refId");
    setPrimaryReference(primaryList);
    setShowModal(false);
  }, [tempMasterList]);

  const removeDuplicates = (originalArray, refId) => {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][refId]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  const removeDuplicateAnnotation = (finalListmergedArray) => {
    const filteredData = finalListmergedArray.map((item) => {
      const includedText = new Map();
      const annotationList = item.annotationList.filter((content) => {
        if (includedText.has(content.annotText)) return false;
        includedText.set(content.annotText);
        return true;
      });
      return { ...item, annotationList };
    });
    return filteredData;
  };

  useEffect(() => {
    if (
      referenceNumber &&
      referenceNumber.length !== 0 &&
      (selectedTextPrimaryClaimValue || eventType) &&
      typeof froalaInstance.current[footNoteIndex] != "undefined" &&
      froalaInstance.current[footNoteIndex].editor &&
      froalaInstance.current[footNoteIndex].editor != "undefined"
    ) {
      // let replaceNum = referenceNumber.map(el => `<sup>[${el}]</sup>`);
      // if (prevProps.referenceNumber !== referenceNumber) {--------Previous code
      if (
        eventType &&
        referenceNumber &&
        referenceNumber.length !== 0 &&
        froalaInstance.current[footNoteIndex].editor
      ) {
        if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
          referenceNumber.forEach((el) => {
            const style = getRefSuperscriptStyle(el.color);
            const greenStyle = getRefSuperscriptStyle("r");

            //Remove old superscripts with the same value
            let oldText =
              froalaInstance.current[footNoteIndex].editor.html.get();
            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${greenStyle}" >${el.refId}</sup>`,
              `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
            );

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${greenStyle}">${el.refId}</sup>`,
              `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
            );
            froalaInstance.current[footNoteIndex].editor.html.set(oldText);
            froalaInstance.current[footNoteIndex].editor.selection.save();
            const html =
              froalaInstance.current[footNoteIndex].editor.html.get(true);
            froalaInstance.current[footNoteIndex].editor.selection.clear();
            handleFootNoteTextChangeDirect(oldText, footNoteIndex);
          });
          setEventType(undefined);
        }
      } else {
        if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
          //process here
          let replaceNum = referenceNumber.map((el) => {
            //const color = el.color === "r" ? "red" : "green";
            const style = getRefSuperscriptStyle(el.color);
            const redStyle = getRefSuperscriptStyle("r");
            let oldText =
              froalaInstance.current[footNoteIndex].editor.html.get();

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
              ""
            );
            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
              ""
            );

            froalaInstance.current[footNoteIndex].editor.html.set(oldText);
            return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
          });

          let currentString =
            froalaInstance.current[footNoteIndex].editor.html.get();
          var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
          var suptext = [];
          words.forEach((item, index, array) => {
            let elIndex = words.indexOf(item);
            let regex = /(<([^>]+)>)/gi;
            let regex2 = /(\[.+\])/gi;
            let result = item.replace(regex, "").trim();
            let tempResult = result.replace(regex2, "").trim();
            const selectedTextForCompare = selectedTextPrimaryClaimValue.trim();
            if (tempResult === selectedTextForCompare) {
              if (elIndex < words.length - 1) {
                let suptext1 = Object.values(
                  array[index + 1].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index + 1].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
                let suptext2 = Object.values(
                  array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
                suptext = suptext.concat(suptext1, suptext2);
              } else if (elIndex === words.length - 1) {
                suptext = Object.values(
                  array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
              } else {
                return;
              }
            }
          });
          if (suptext) {
            replaceNum = replaceNum.filter((val) => !suptext.includes(val));
          }
          const textToBeReplaced = selectedTextPrimaryClaimValue.concat(
            suptext.toString().replaceAll(",", "")
          );
          suptext = suptext.concat(replaceNum);
          // const textToReplaceWith = selectedTextPrimaryClaimValue.concat(
          //   suptext.toString().replaceAll(",", "")
          // );
          const textToReplaceWith = suptext.toString().replaceAll(",", "");
          // let froalaString = froalaInstance.current[footNoteIndex].editor.html
          //   .get()
          //   .replace(textToBeReplaced, textToReplaceWith);
          let froalaString = sortSuperScriptNumbers(
            currentString,
            textToBeReplaced,
            textToReplaceWith,
            selectedTextPrimaryClaimValue
          );
          froalaInstance.current[footNoteIndex].editor.html.set(froalaString);
          //console.log("useEffect", froalaString, selectedTextPrimaryClaimValue);
          froalaInstance.current[footNoteIndex].editor.selection.save();
          const html =
            froalaInstance.current[footNoteIndex].editor.html.get(true);
          froalaInstance.current[footNoteIndex].editor.selection.clear();
          handleFootNoteTextChangeDirect(froalaString, footNoteIndex);
        }
      }
    }
  }, [referenceNumber]);
  //CODE- Custom hook to compare two Props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  //Reference code Ends

  const [show, setShow] = useState(false);
  const [isAbbr, setIsAbbr] = useState(false);
  const [refValue, setRefValue] = useState("");
  const [isAnnotation, setIsAnnotation] = useState(false);
  const [isRefDisable, setIsRefDisable] = useState(true);
  const [arrAnnot, setArrAnnot] = useState([]);
  const [references, setReferences] = useState([]);
  const [selectedReferences, setSelectedreferences] = useState({});
  const [checked, setchecked] = useState(false);
  const [arrAbbr, setArrAbbr] = useState([]);
  const [masterArrAbbr, setMasterArrAbbr] = useState([]);
  const [displayReferenceAnchor, setDisplayReferenceAnchor] = useState(false);

  const handleClose = () => {
    setRefValue("");
    setArrAnnot([]);
    setIsRefDisable(true);
    setShow(false);
  };

  const handleCloseReference = () => {
    setRefValue("");
    setIsRefDisable(true);
    setchecked([]);
    setArrAnnot([]);
    setIsAnnotation(false);
    setSelectedText(undefined);
  };
  const handleCloseAbbreviation = () => {
    setIsAbbr(false);
    setAbbrStatus(true);
    setSuppClaimFootNoteText([]);
    setSelectedTextPrimaryClaimValue("");
  };
  const addRadioAbbr = (val) => {};

  const handleArrAbbr = (val) => {
    if (val) {
      val[0]["record_index"] = maxAbbrRecordIndex() + 1;
    }
    if (arrAbbr && arrAbbr) {
      let existingAbbriviations = [...(arrAbbr && arrAbbr)];
      for (const abbreviation of existingAbbriviations) {
        if (abbreviation.data.abbrText === val[0].data.abbrText) {
          setIsAbbr(false);
          setAbbrStatus(true);
          setSuppClaimFootNoteText([]);
          setSelectedTextPrimaryClaimValue("");
          return;
        }
      }
      let existingArrAbbrs = [...arrAbbr];
      existingArrAbbrs = existingArrAbbrs.concat(val);
      setArrAbbr(existingArrAbbrs);
      setIsAbbr(false);
      setAbbrStatus(true);
    } else {
      setArrAbbr(val);
      setIsAbbr(false);
      setAbbrStatus(true);
    }

    //Update master Abbrivations list
    if (val) {
      //if(!checkIfRefAlreadyAdded(val)){
      setGlobalAbbrList(globalAbbrList.concat(val));
      //}
    }
    setSuppClaimFootNoteText([]);
    setSelectedTextPrimaryClaimValue("");
  };

  useEffect(() => {
    setShow(false);
  }, false);

  const handleReference = () => {
    const refId = uuidv4();
    if (
      Object.values(references).indexOf(refValue) === -1 &&
      references.filter((reference) => {
        return reference.refValue === refValue;
      }).length <= 0
    ) {
      setReferences(references.concat({ refId, refValue }));
    }
    setShow(false);
    setIsAnnotation(true);
  };
  const handleAddAnnotation = () => {
    setRefValue("");
    setShow(true);
    setIsAnnotation(false);
  };
  const handleBackAnnotation = () => {
    setRefValue("");
    setShow(true);
    //setArrAnnot([]);
    setIsAnnotation(false);
    setArrCountCheckBox([]);
  };
  const handleReferenceChange = (e) => {
    referencesValid.current.innerHTML = e;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      setRefValue(e);
      setIsRefDisable(false);
    } else if (e.length === 0) {
      setRefValue("");
      setIsRefDisable(true);
    }
  };

  const checkIfRefAlreadyAdded = (ref) => {
    let alreadySelected = [];

    if (selectedReferences && selectedReferences[selectedText]) {
      alreadySelected = selectedReferences[selectedText].map((ref) => {
        return ref.refValue.replace(/<\/?[^>]+(>|$)/g, "");
      });
    }

    if (arrAnnot.length > 0 && alreadySelected && alreadySelected.length > 0) {
      //should be common
      if (
        alreadySelected.indexOf(ref.refValue.replace(/<\/?[^>]+(>|$)/g, "")) !==
          -1 &&
        arrAnnot.indexOf(ref.refValue) !== -1
      ) {
        return true;
      } else if (
        arrAnnot &&
        arrAnnot.length > 0 &&
        arrAnnot.indexOf(ref.refValue) !== -1
      ) {
        return true;
      }
    } else if (
      arrAnnot &&
      arrAnnot.length > 0 &&
      arrAnnot.indexOf(ref.refValue) !== -1
    ) {
      return true;
    }
    // else if (alreadySelected && alreadySelected.length > 0 && alreadySelected.indexOf(ref.refValue.replace(/<\/?[^>]+(>|$)/g, "")) !== -1 ) {
    //     return true;
    //   }

    return false;
    // if (selectedReferences && selectedReferences[selectedText]) {
    //   if(arrAnnot.length > 0 && arrAnnot.indexOf(ref.refValue) === -1){
    //     return false
    //   }
    //   const alreadySelected = selectedReferences[selectedText].map((ref) => {
    //     return ref.refValue.replace(/<\/?[^>]+(>|$)/g, "");
    //   });
    //   if (alreadySelected.indexOf(ref.refValue.replace(/<\/?[^>]+(>|$)/g, "")) !== -1) {
    //     return true;
    //   }
    //   else{
    //     if(arrAnnot.indexOf(ref.refValue) !== -1){
    //       return true
    //     }
    //     return false
    //   }
    // }
    // if(arrAnnot.indexOf(ref.refValue) !== -1){
    //   return true
    // }
    // return false;
  };

  const handleCheckBox = (event, item, selectedRef) => {
    setchecked(event.target.checked);

    if (event.target.checked) {
      setIsAnnotationBtnDisable(false);
      setIsAnnotationBtnDisableButtun(false);
      if (arrCountCheckBox && arrCountCheckBox.length > 0) {
        if (arrCountCheckBox.indexOf(item) == -1) {
          setArrCountCheckBox([...arrCountCheckBox, item]);
        }
      } else {
        setArrCountCheckBox([item]);
      }
      setArrAnnot(arrAnnot.concat(selectedRef));
    } else {
      if (arrCountCheckBox && arrCountCheckBox.length > 0) {
        if (arrCountCheckBox.indexOf(item) !== -1) {
          setArrCountCheckBox(
            arrCountCheckBox.filter((existingItem) => {
              return existingItem !== item;
            })
          );
        }
      }

      setArrAnnot(
        arrAnnot.filter((annot) => {
          return annot !== selectedRef;
        })
      );
    }
  };

  useEffect(() => {
    if (arrCountCheckBox.length === 0) {
      setIsAnnotationBtnDisable(true);
      setIsAnnotationBtnDisableButtun(true);
    }
  }, [arrCountCheckBox]);

  useEffect(() => {
    if (primaryReferenceAnchor && primaryReferenceAnchor.length > 0) {
      setAddSupportingBtnDisable(true);
    } else {
      setAddSupportingBtnDisable(false);
    }
  }, [primaryReferenceAnchor]);
  const handleAddAnnotationBtn = () => {
    // const temp = [];
    // for (let i of arrCountCheckBox) {
    //   let x = i + 1;
    //   temp.push(x);
    // }
    //setReferenceNumber(temp);
    arrAnnot.length = arrCountCheckBox.length;

    let refsToBeSaved = [];
    for (const ref of arrAnnot) {
      refsToBeSaved = refsToBeSaved.concat(
        references.filter((reference) => {
          return reference.refValue === ref;
        })
      );
    }
    let object = selectedReferences;
    object[selectedText] = refsToBeSaved;
    let temp = [];
    for (const ref of refsToBeSaved) {
      temp.push(references.indexOf(ref) + 1);
    }
    setReferenceNumber(temp);
    setSelectedreferences(object);
    setArrAnnot([]);
    setSelectedText(undefined);
    setIsAnnotation(false);
  };
  useEffect(() => {
    if (arrAnnot && arrAnnot.length > 0) {
      setIsAnnotationBtnDisable(false);
    } else {
      setIsAnnotationBtnDisable(true);
    }
  }, [arrAnnot]);

  const getTopCategories = () => {
    if (filterdPages && filterdPages.length <= 0) {
      return [];
    }
    // if(selectedCategories && selectedCategories.length > 0){
    //   return selectedCategories
    // }
    return filterdPages[selectedPage].MetaData.topMessageCategories.map(
      (topCat) => {
        return {
          claimCategoryId: topCat.messageCategoryId,
          claimCategory: topCat.claimCategory,
        };
      }
    );
  };

  const coreClaimsAndCategories = useMemo(
    () => (
      <CoreClaimCategoryList
        //coreClaimCategories={getTopCategories()}
        selectedCoreClaimCategories={selectedCategories}
        onCoreClaimSelection={onCoreClaimAndCategorySelect}
        selectedClaim={selectedClaim}
        brand={
          module?.assetDetails?.sourceType?.toLowerCase() ===
          damsSources.aprimo.name.toLowerCase()
            ? module?.brandName || module?.brand
            : module?.brandId
        }
        sourceType={
          module?.creationMethodType?.toLowerCase() !== moduleTypes.denovo
            ? module?.assetDetails?.sourceType
            : damsSources?.veeva?.name?.toLowerCase()
        }
        isloading={isloading}
        setIsloading={setIsloading}
      />
    ),
    [module]
  );

  const renderSelectCoreClaimAlert = () => {
    if (
      filterType === "primary-claim" &&
      ((selectedCategories.length > 0 && selectedClaim == undefined) ||
        selectedClaim === null)
    ) {
      return (
        <Row className="align-items-center">
          <Col md={2} className="catLabelTitle"></Col>
          <Col md={10}>
            <div className="alert alertCoreClaim" role="alert">
              Please Select Core Claim !
            </div>
          </Col>
        </Row>
      );
    }
  };

  //Will be called when text is selected to add refs, abbrs, footnotes etc....
  const onTextSelected = (selectedText, selectedRange) => {
    setSelectedText(selectedText);
    setSelectedTextIdxRange(selectedRange);
  };

  useEffect(() => {
    if (
      selectedText &&
      selectedReferences &&
      selectedReferences[selectedText] &&
      selectedReferences[selectedText].length > 0
    ) {
      let selected = selectedReferences[selectedText];
      selected = [...selected].map((sel) => {
        return sel.refValue;
      });
      setArrAnnot([].concat(selected));
    }
    if (!selectedText) {
      setArrAnnot([]);
    }
  }, [selectedText]);

  const onSuperScriptClick = (docId, event, type, otherInfo) => {
    if (event === "add" || event === "view" || event === "modify") {
      var div = document.createElement("DIV");
      div.id = "referenceModal";
      div.innerHTML = "Reference";
      setAddOrModifyAnchorData({ docId: docId, anchorText: "" });
      switch (type) {
        case "primary-claim":
          onBoxSelected(div, {
            type: "primary-claim-varient",
            index: primaryClaimSelectedIndex,
          });
          break;
        case "primary-claim-footnote":
          onBoxSelected(div, {
            type: "primary-claim-footnote",
            index: footNoteIndex,
          });
          break;
        case "supporting-claim":
          div.id = "supporting-referenceModal";
          onBoxSelected(div, {
            type: "supporting-claim",
            rowIndex: otherInfo.rowIndex,
          });
          break;
        case "supporting-claim-footnote":
          div.id = "supporting-referenceModal";
          onBoxSelected(div, {
            type: "supporting-claim-footnote",
            rowIndex: otherInfo.rowIndex,
            index: otherInfo.index,
          });
          break;
        case "supporting-visual":
          div.id = "visual-referenceModal";
          onBoxSelected(div, {
            type: "supporting-visual",
            rowIndex: otherInfo.rowIndex,
            index: otherInfo.index,
          });
          break;
        case "supporting-visual-footnote":
          div.id = "visual-referenceModal";
          onBoxSelected(div, {
            type: "supporting-visual-footnote",
            rowIndex: otherInfo.rowIndex,
            index: otherInfo.index,
          });
          break;
        default:
          break;
      }
    } else if (event === "delete") {
      if (type === "primary-claim-footnote") {
        //setAnchorAddedIn({type : 'primary-claim-footnote', index : footNoteIndex})
        //skipAnchorPrimaryClaim(docId, "",anchorAddedIn);
        skipAnchorPrimaryClaim(docId, "", {
          type: "primary-claim-footnote",
          index: footNoteIndex,
        });
      } else if (type === "primary-claim") {
        skipAnchorPrimaryClaim(docId, "", {
          type: "primary-claim-varient",
          index: primaryClaimSelectedIndex,
        });
      }

      // if (type === "primary-claim") {
      //   //saveAnchorPrimaryClaim(docId, "");
      // } else if (type === "primary-claim-footnote") {
      //   skipAnchorPrimaryClaim(docId, "",anchorAddedIn);
      // }
    }
  };

  const onFootnoteSupClick = (event) => {
    const el = event.target;
    if (el && el.dataset) {
      setEventType(el);
      const docId = el.dataset.docid;
      if (docId && docId.trim().length > 0) {
        //const color = rgbToHex(el.style.background);
        //if (color === citationColors.red) {
        setContextMenuOptions({
          event: "modify",
          docId: docId,
          options: ["View", "Modify", "Delete"],
          head: "Anchor",
          type: "primary-claim-footnote",
        });
        //}
      }
    }
  };

  const onBoxSelected = (event, boxType = undefined) => {
    if (!event) {
      return;
    }
    let type = undefined;
    if (event.currentTarget && event.currentTarget.id) {
      type = event.currentTarget.id;
      if (event.currentTarget.title) {
        setSelectedTextPrimaryClaimValue(event.currentTarget.title);
      }
    } else {
      type = event.id;
      if (event.innerText) {
        setSelectedTextPrimaryClaimValue(event.innerText);
      }
    }

    switch (type) {
      case "primary-claim":
        setAtomsHeader("Claims");

        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        setFilterType("primary-claim");
        break;
      case "referenceModal":
        if (boxType) {
          setAnchorAddedIn(boxType);
        } else {
          //For Primary claim footnote
          setAnchorAddedIn({
            type: "primary-claim-footnote",
            index: footNoteIndex,
          });
        }
        setAtomsHeader("References");
        setShowModal(true);
        setFilterType("referenceModal");

        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        break;
      case "abbreviationModal":
        setAtomsHeader("Abbrevations");
        setAbbrNoteText("");
        setFilterType("abbreviationModal");
        setGlobalAbbrList(
          globalAbbrList.concat(
            getSupprtingClaimAbbrList,
            getSupprtingVisualAbbrList,
            getPrimaryAbbrList
          )
        );
        setShow(false);
        setIsAbbr(true);
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        setRightClickTriggered(false);
        break;
      case "footnote-modal":
        setAtomsHeader("Footnotes");
        setFilterType("footnote-modal");
        setFootNoteModal(true);
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        break;
      case "supporting-footnote":
        setAtomsHeader("Footnotes");
        setFilterType("supporting-footnote");
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        break;
      case "supporting-abbreviationModal":
        setAtomsHeader("Abbrevations");
        setFilterType("supporting-abbreviationModal");
        setGlobalAbbrList(
          globalAbbrList.concat(
            getSupprtingClaimAbbrList,
            getSupprtingVisualAbbrList,
            getPrimaryAbbrList
          )
        );
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
        break;
      case "supporting-referenceModal":
        if (boxType) {
          setAnchorAddedIn(boxType);
        }
        setAtomsHeader("References");
        setFilterType("supporting-referenceModal");
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);

        break;
      case "visual-referenceModal":
        if (boxType) {
          setAnchorAddedIn(boxType);
        }
        setAtomsHeader("References");
        setFilterType("visual-referenceModal");
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);

      default:
        setFilterType("");
        setSelectedRow(-1);
        setSelectedBox(-1);
        setSelectedSuppRow(-1);
        setSelectedSuppBox(-1);
    }
    //setFilterType(type);
  };
  const [state, setState] = React.useState({
    checkedA: true,
  });
  const getGlobalReferenceList = (tempArray) => {
    const originalArray = Array.from(tempArray);
    let newArray = [];
    let lookupObject = {};

    for (let i in originalArray) {
      let prev = lookupObject[originalArray[i]["refId"]];
      lookupObject[originalArray[i]["refId"]] = prev
        ? {
            ...prev,
            annotationList: [
              ...prev.annotationList,
              ...originalArray[i]["annotationList"],
            ],
          }
        : originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    let filteredFinalArray = removeDuplicateAnnotation(newArray);
    return filteredFinalArray;
  };
  const [supportingClaimsAndVisuals, setSupportingClaimsAndVisuals] = useState(
    []
  );

  const findSupportingClaimOrVisualPosition = (type, object, rowIndex = -1) => {
    let index = -1;
    let filtered = [];
    if (rowIndex > -1 && !object) {
      object = supportingClaimsAndVisuals[rowIndex];
    }
    if (type.toLowerCase() === "supportingclaim") {
      filtered = supportingClaimsAndVisuals.filter((el) => {
        return el?.type === "supportingClaim";
      });
    } else if (type === "visual") {
      filtered = supportingClaimsAndVisuals.filter((el) => {
        return el?.type === "supportingVisual";
      });
    }
    index = filtered.findIndex((el) => {
      return el?.record_index === object.record_index;
    });

    return index;
  };

  const maxAbbrRecordIndex = (abbreviations = globalAbbrList) => {
    const abbrRecordIndices = abbreviations.map((abbr) => {
      return abbr.record_index;
    });
    return abbrRecordIndices.length > 0 ? Math.max(...abbrRecordIndices) : 0;
  };
  const maxRecordIndex = () => {
    let recordIndices = [primaryClaim[0]?.record_index || 1];
    const supClaimsAndVizIndices = supportingClaimsAndVisuals?.map((el) => {
      return el?.record_index;
    });
    recordIndices = recordIndices.concat(supClaimsAndVizIndices);
    return Math.max(...recordIndices);
  };
  // const maxRecordIndexreusableText = () => {
  //   let recordIndices = [primaryClaim[0].record_index || 1];
  //   const reusableTextIndices = reusableTextAdded.map((el) => {
  //     return el.record_index;
  //   });
  //   recordIndices = recordIndices.concat(reusableTextIndices);
  //   return Math.max(...recordIndices);
  // };
  const addVisualRow = () => {
    if (primaryClaim.length > 0) {
      if (primaryReferenceAnchor.length === 0) {
        setDisplayReferenceAlert(true);
        return;
      }
    }
    const visuals = supportingClaimsAndVisuals?.filter((visual) => {
      return visual.type === "supportingVisual";
    });

    //For Supporting Visual Empty Alert
    let isRefForSupCVisualAdded = true;
    if (visuals && visuals.length > 0) {
      for (const visual of visuals) {
        //console.log(visual, "++++++++");
        if (visual && visual.rowData.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupCVisualAdded = false;
            visual.rowData[0].isEmpty = true;
          } else {
            visual.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupCVisualAdded) {
      setDisplaySupportingVisualAlert(true);
      return;
    }
    let isRefForVisualAdded = true;
    if (visuals && visuals.length > 0) {
      for (const visual of visuals) {
        if (visual && visual.rowData.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAdded = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForVisualAdded) {
      setDisplayReferenceAlert(true);
      return;
    }
    let isRefForClaimAdded = true;
    if (supportingClaimsAndVisuals && supportingClaimsAndVisuals.length > 0) {
      const supportingClaims = supportingClaimsAndVisuals.filter((el) => {
        return el.type === "supportingClaim";
      });
      for (const claims of supportingClaims) {
        if (claims && claims.length > 0) {
          const data = claims.rowData[0].data;
          if (data && data.length > 0) {
            let refs = claims.rowData[3];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForClaimAdded = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForClaimAdded) {
      setDisplayReferenceAlert(true);
      return;
    }
    //visual caption
    let isRefForVisualAddedCaption = true;
    if (supportingClaimsAndVisuals && supportingClaimsAndVisuals.length > 0) {
      for (const visual of visuals) {
        if (visual && visual.rowData && visual.rowData.length > 0) {
          const data = visual.rowData[1].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAddedCaption = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForVisualAddedCaption) {
      setDisplayReferenceAlert(true);
      return;
    }

    setSupportingClaimsAndVisuals([
      ...supportingClaimsAndVisuals,
      {
        type: "supportingVisual",
        record_index: maxRecordIndex() + 1,
        isMustUse: true,
        usageGuidelines: "",
        rowData: [
          {
            type: "Image",
            data: [],
            id: uuidv4(),
            isEmpty: false,
          },
          {
            type: "Captions",
            data: [],
          },
          {
            type: "Description / Footnotes",
            data: [],
          },
          {
            type: "Abbrevations",
            data: [],
          },
          {
            type: "References / Annotations",
            data: [],
          },
          {
            type: "Link",
            claim: [],
            dependency: "Optional",
          },
          // {
          //   type: "Must Use",
          //   data: [],
          // },
        ],
      },
    ]);

    setSelectedBox(-1);
    setSelectedRow(-1);
  };

  // update visual links
  const updateVisualLinks = (rowPosition, visualData, dependency) => {
    let supClaimsAndVisuals = [...supportingClaimsAndVisuals];

    const rowToBeUpdated = supClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[5];
    boxToBeUpdated.claim = [...visualData];
    boxToBeUpdated.dependency = dependency;

    setSupportingClaimsAndVisuals([...supClaimsAndVisuals]);
  };
  // add Supporting Visual Row Starts
  const addImage = (rowPosition, type, currentImagePosition, assetUrl, id) => {
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[0];
    setDisplaySupportingVisualAlert(false);
    if (type === "update") {
      const newImage = {
        id: id,
        imgSrc: assetUrl,
        sourceId: id,
        sourceType: selectedDiv === "imageUrl" ? "URL" : "System-Upload",
      };
      boxToBeUpdated.data.splice(currentImagePosition, 1, newImage);
    } else if (type === "add") {
      boxToBeUpdated.data.push({
        id: "",
        imgSrc: "",
        sourceId: "",
        sourceType: "",
      });
    }
    setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
  };

  const addCaptions = (
    rowPosition,
    type,
    currentImagePosition,
    assetUrl,
    id
  ) => {
    let claimsAndVisuls = [...supportingClaimsAndVisuals];
    const rowToBeUpdated = claimsAndVisuls[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[1];

    boxToBeUpdated.data.push({ atomText: "" });
    setSupportingClaimsAndVisuals(claimsAndVisuls);
  };
  const getSuperScriptsForAComponent = (data) => {
    let refs = [];
    let myArray = [];
    data.map((claim) => {
      let object = claim.claim;
      if (object && object.includes("<sup")) {
        object = object.replaceAll(/\s/g, "");
        var Result = object.includes("<sup")
          ? Object.values(object.match(/<sup(.*?)<\/sup>/g))
          : Object.values(object.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    refs = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return refs;
  };

  const getLeftAnchorsAfterDeletion = (rowIndex, selectedBox) => {
    let superScripts = getSuperScriptsForAComponent();
    let linkedFalse = primaryReferenceAnchor.filter(
      (o) => o && o.veevaLinked === false
    );
    let finalRef = commmonFilterReferences(superScripts);
    let tempPrimary = [];
    finalRef.forEach((el) => {
      const obj = primaryReferenceAnchor.filter((o) => o && o.refId === el);
      tempPrimary.push.apply(tempPrimary, obj);
    });

    let tempResult = [];
    superScripts.forEach((element) => {
      if (parseInt(element)) {
        const obj = tempPrimary.find((o) => o.refId == element);
        tempResult.push(obj);
      }
    });
    let notPresentInPrimaryClaimBox = tempPrimary.filter(
      checkForUnique(tempResult)
    );
    notPresentInPrimaryClaimBox.forEach((element) => {
      let itemPosition = element.annotationList.findIndex(
        (obj) =>
          obj.compIndex === selectedBox &&
          obj.linkType === "primary-claim-varient"
      );
      if (itemPosition > -1) {
        element.annotationList.splice(itemPosition, 1);
      }
      return element;
    });
    let finalList = tempResult.concat(notPresentInPrimaryClaimBox, linkedFalse);
    setPrimaryReferenceAnchor(finalList);
  };
  //let alteredData ='';
  const [superScriptDataForClaim, setSuperScriptDataForClaim] = useState([]);
  const [tempData, setTempData] = useState("");
  const handleInputChange = (atomTextChanged, rowIndex) => {
    let SuppBox = 0;
    const visualId = 4;
    const supClaimsAndVisuals = [...supportingClaimsAndVisuals];

    if (rowIndex > -1 && SuppBox > -1) {
      const rowToBeUpdated = supClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[SuppBox];
      const moduleClaimId =
        rowToBeUpdated &&
        rowToBeUpdated.rowData[visualId] &&
        rowToBeUpdated.rowData[visualId].id;
      //Check if this atomText is matching with an text from atom
      const isMatching = isMatchingWithAnAtomText(
        removeAllSuperscriptTags(atomTextChanged)
      );
      const newText = {
        atomText: atomTextChanged,
        sourceId: isMatching ? boxToBeUpdated.data[0].sourceId : undefined,
        cmDocumentNumber: isMatching
          ? boxToBeUpdated.data[0].cmDocumentNumber
          : undefined,
      };

      supClaimsAndVisuals.forEach((el) => {
        if (el.type === "supportingVisual") {
          const claims = (el.rowData[5] && el.rowData[5].claim) || [];

          claims.forEach((claim) => {
            if (claim.moduleClaimId === moduleClaimId) {
              claim.claimText = atomTextChanged;
            }
          });
        }
      });

      if (atomTextChanged === "") {
        boxToBeUpdated &&
          boxToBeUpdated.data &&
          boxToBeUpdated.data.splice(currentTextReplace, 1);
      } else {
        boxToBeUpdated &&
          boxToBeUpdated.data &&
          boxToBeUpdated.data.splice(currentTextReplace, 1, newText);
      }
      setSupportingClaimsAndVisuals(supClaimsAndVisuals);
      setSuperScriptDataForClaim(uuidv4());
      //handleDeleteForSuppClaim(atomTextChanged);
    }
    // debugger
    setDisplaySupportingAlert(false);
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const handleCptionTextChange = (atomTextChanged, rowIndex) => {
    // let SuppBox = 1;
    // const visualId = 4;
    // const supClaimsAndVisuals = [...supportingClaimsAndVisuals];
    // if (rowIndex > -1 && SuppBox > -1) {
    //   const rowToBeUpdated = supClaimsAndVisuals[rowIndex];
    //   const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[SuppBox];
    //   const moduleClaimId =
    //     rowToBeUpdated &&
    //     rowToBeUpdated.rowData[visualId] &&
    //     rowToBeUpdated.rowData[visualId].id;
    //   //Check if this atomText is matching with an text from atom
    //   const isMatching = isMatchingWithAnAtomText(
    //     removeAllSuperscriptTags(atomTextChanged)
    //   );
    //   const newText = {
    //     atomText: atomTextChanged,
    //     sourceId: isMatching ? boxToBeUpdated.data[0].sourceId : undefined,
    //     cmDocumentNumber: isMatching
    //       ? boxToBeUpdated.data[0].cmDocumentNumber
    //       : undefined,
    //   };

    //   // supClaimsAndVisuals.forEach((el) => {
    //   //   if (el.type === "supportingVisual") {
    //   //     const claims = (el.rowData[5] && el.rowData[5].claim) || [];

    //   //     claims.forEach((claim) => {
    //   //       if (claim.moduleClaimId === moduleClaimId) {
    //   //         claim.claimText = atomTextChanged;
    //   //       }
    //   //     });
    //   //   }
    //   // });

    //   if (atomTextChanged === "") {
    //     boxToBeUpdated &&
    //       boxToBeUpdated.data &&
    //       boxToBeUpdated.data.splice(currentVisualTextReplace, 1);
    //   } else {
    //     boxToBeUpdated &&
    //       boxToBeUpdated.data &&
    //       boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
    //   }
    //   setSupportingClaimsAndVisuals(supClaimsAndVisuals);
    //   setSuperScriptDataForClaim(uuidv4());
    //   //handleDeleteForSuppClaim(atomTextChanged);
    // }
    let selectBox = 1;
    if (rowIndex > -1 && selectBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated.rowData[selectBox];
      const newText = { cpationText: atomTextChanged };
      if (atomTextChanged === "") {
        boxToBeUpdated.data.splice(currentVisualTextReplace, 1);
      } else {
        boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
      }

      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
      // let temp = [];
      // boxToBeUpdated.data.forEach((el) => temp.push(el.cpationText));
      // let modelText = temp.join();
      //console.log("modelText",modelText);
      setSuperScriptDataForVisual(uuidv4());
      // handleDeleteForSuppVisual(modelText);
    }
    // setTimeout(() => {
    //   if (!isFreeze) {
    //     if (selectedRow > -1 && selectBox > -1) {
    //       const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
    //       const boxToBeUpdated = rowToBeUpdated.rowData[selectBox];
    //       const newText = { cpationText: atomTextChanged };
    //       if (atomTextChanged === "") {
    //         boxToBeUpdated.data.splice(currentVisualTextReplace, 1);
    //       } else {
    //         boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
    //       }

    //       setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    //       let temp = [];
    //       boxToBeUpdated.data.forEach((el) => temp.push(el.cpationText));
    //       let modelText = temp.join();
    //       //console.log("modelText",modelText);
    //       setSuperScriptDataForVisual(uuidv4());
    //       //handleDeleteForSuppVisual(modelText);
    //     }
    //   } else {
    //     setIsFreeze(false);
    //   }
    // }, 0);
  };
  const saveCaptionSUperScript = (froalaText) => {
    // setTimeout(() => {
    //   if (!isFreeze) {
    //     if (selectedRow > -1) {
    //       const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
    //       const boxToBeUpdated = rowToBeUpdated.rowData[1];
    //       const newText = { cpationText: froalaText };
    //       boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
    //       setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    //     }
    //   } else {
    //     setIsFreeze(false);
    //   }
    // }, 0);
    if (selectedRow > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated = rowToBeUpdated.rowData[1];
      const newText = { cpationText: froalaText };
      boxToBeUpdated.data.splice(currentVisualTextReplace, 1, newText);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
  };

  const deleteImage = (rowPosition, imgPosition) => {
    if (imgPosition === -1) {
      return;
    }
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[0];
    boxToBeUpdated.data.splice(imgPosition, 1);
    setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    setDisplaySupportingVisualAlert(false);
  };

  const deleteCaption = (rowPosition, captionPosition) => {
    if (captionPosition === -1) {
      return;
    }
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[1];
    boxToBeUpdated.data.splice(captionPosition, 1);
    setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    setSuperScriptDataForVisual(uuidv4());
  };

  const deleteSuppVisualRow = (rowPosition, rowId) => {
    if (rowPosition === -1) {
      return;
    }
    const supVisualToBeDeleted = JSON.parse(
      JSON.stringify(supportingClaimsAndVisuals)
    ).find((item, itemIndex) => {
      return itemIndex === rowPosition;
    });
    const newArray = JSON.parse(
      JSON.stringify(supportingClaimsAndVisuals)
    ).filter((item, itemIndex) => rowPosition !== itemIndex);

    const anchors = supVisualToBeDeleted.rowData[4].data;
    let supVizAnchors = JSON.parse(
      JSON.stringify(getSupprortingVisualReferences)
    );
    if (anchors && anchors.length > 0) {
      anchors.forEach((anchor) => {
        supVizAnchors = supVizAnchors.filter((el) => {
          return el.id !== anchor.id && el.refId !== anchor.refId;
        });
      });
    }

    setGetSupprortingVisualReferences(supVizAnchors);
    setDisplaySupportingVisualAlert(false);
    setSupportingClaimsAndVisuals(newArray);
  };

  const addBoxContent = (rowPosition, boxNumber) => {
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[boxNumber]; // boxNumber 1 means its second box
    boxToBeUpdated.data.push({ label: "<p>Update content</p>" });
    setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
  };

  const saveSuppVisualRef = (SuppRefList) => {
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[4];
      //boxToBeUpdated &&
      boxToBeUpdated.data = [...SuppRefList];
      //boxToBeUpdated.data.push.apply(boxToBeUpdated.data, SuppRefList);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);

      let updatedrefs = [...getSupprortingVisualReferences].concat(SuppRefList);
      setGetSupprortingVisualReferences(
        [].concat(concatAnnotationsForSameRefId(updatedrefs))
      );
    }
    setAtomTextForRef([]);
    setSelectedBox(-1);
    setSelectedRow(-1);
    SetBoxSeletedBoxType("");
  };

  const saveVisualRefInMasterList = (Data) => {
    const newArray = [...referenceMasterList];
    newArray.push.apply(newArray, Data);
    //const finalList = removeDuplicates(newArray, "id");
    const arrayHashmap = newArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    const finalListmergedArray = removeDuplicates(mergedArray, "refId");
    let filteredFinalArray = removeDuplicateAnnotation(finalListmergedArray);
    setReferenceMasterList(filteredFinalArray);
    setAtomTextForRef([]);
  };

  const addAbbrToMasterVisual = (abbrData) => {
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedBox];
      const newArray = {
        abbrText: abbrData[0].abbrText,
        title: abbrData[0].title,
        record_index: abbrData[0].record_index,
      };
      boxToBeUpdated &&
        boxToBeUpdated.data &&
        boxToBeUpdated.data.push(newArray);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    //Upddate global list of abbreviations
    if (abbrData && abbrData.length > 0) {
      let newAbbrArray = [];
      for (const abbr of abbrData) {
        if (abbr.abbrText /*&& abbr.title*/) {
          const newObj = {
            title: abbr.title && abbr.title,
            record_index: abbr.record_index,
            data: { abbrText: abbr.abbrText },
          };
          newAbbrArray = newAbbrArray.concat(newObj);
        }
      }
      setGlobalAbbrList(globalAbbrList.concat(newAbbrArray));
    }
    setSuppClaimFootNoteText([]);
    setSelectedTextPrimaryClaimValue("");
    //updateMasterAbbrList(abbrData)
  };

  const addVisualFootNotesToMasterArray = (finalFootNoteData) => {
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedBox];
      boxToBeUpdated &&
        boxToBeUpdated.data &&
        boxToBeUpdated.data.push(finalFootNoteData);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    setAddBtnStatus(false);
    setSuppClaimFootNoteText([]);
  };

  const footNoteDirectEditSaveVisual = (data) => {
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedBox];
      //boxToBeUpdated &&
      boxToBeUpdated.data = [...data];
      //boxToBeUpdated.data.push(finalFootNoteData);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    setSelectedSuppBox(-1);
  };

  const saveFootNoteRefSupVisual = (alteredData) => {
    if (selectedRow > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[2];
      boxToBeUpdated.data = [...alteredData];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    //setSelectedRow(-1);
    setSelectedBox(-1);
  };

  const resetReferenceVisual = (resetRefData, rowIndex) => {
    if (rowIndex > -1) {
      const supClaimsAndVisuals = [...supportingClaimsAndVisuals];
      const rowToBeUpdated = supClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[4];
      boxToBeUpdated.data = [...resetRefData];
      setSupportingClaimsAndVisuals([].concat(supClaimsAndVisuals));
      let refs = [];
      supClaimsAndVisuals.forEach((el) => {
        if (el.type === "supportingVisual") {
          refs = refs.concat(el.rowData[4].data);
        }
      });
      setGetSupprortingVisualReferences([].concat(refs));
    }
    //setSelectedRow(-1);
    setSelectedBox(-1);
  };

  const [superScriptDataForVisual, setSuperScriptDataForVisual] = useState([]);
  const handleDeleteForSuppVisual = (model) => {
    let modelText = model.replace(/&nbsp;/gi, "");
    let afterDeleted = Object.values(
      modelText.match(/<sup>(.*?)<\/sup>/g)
        ? modelText.match(/<sup>(.*?)<\/sup>/g)
        : ""
    );

    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      setSuperScriptDataForVisual(unique);
    }
  };

  const resetFootNotesVisual = (resetFootNoteData, rowIndex) => {
    if (rowIndex > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[2];
      boxToBeUpdated.data = [...resetFootNoteData];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    //setSelectedRow(-1);
    setSelectedBox(-1);
  };
  //add supporting visual row ends
  //  add Reusable Text row  row starts
  const [isReusabletextAdd, setIsReusabletextAdd] = useState(false);

  //add reusable text row ends
  const addSuppClaimRow = () => {
    //primary claim

    if (primaryClaim.length > 0) {
      if (primaryReferenceAnchor.length === 0) {
        setDisplayReferenceAlert(true);
        return;
      }
    }
    const supportingClaims = [...supportingClaimsAndVisuals].filter((el) => {
      return el?.type === "supportingClaim";
    });
    //For Supporting claim Empty Alert
    let isRefForSupClaimAdded = true;
    if (supportingClaims && supportingClaims.length > 0) {
      for (const claim of supportingClaims) {
        //console.log(claims, "++++++++");
        if (claim && claim.rowData.length > 0) {
          const data = claim.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupClaimAdded = false;
            claim.rowData[0].isEmpty = true;
          } else {
            claim.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupClaimAdded) {
      setDisplaySupportingAlert(true);
      return;
    }
    //add supporting claim
    let isRefForClaimAdded = true;
    if (supportingClaims && supportingClaims.length > 0) {
      for (const claim of supportingClaims) {
        if (claim && claim.rowData.length > 0) {
          const data = claim.rowData[0].data;
          if (data && data.length > 0) {
            let refs = claim.rowData[3];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForClaimAdded = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForClaimAdded) {
      setDisplayReferenceAlert(true);
      return;
    }
    //supporting visual
    const visuals = [...supportingClaimsAndVisuals].filter((el) => {
      return el?.type === "supportingVisual";
    });
    let isRefForVisualAdded = true;
    if (supportingClaimsAndVisuals && supportingClaimsAndVisuals.length > 0) {
      for (const visual of visuals) {
        if (visual && visual.rowData.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAdded = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForVisualAdded) {
      setDisplayReferenceAlert(true);
      return;
    }

    //visual caption
    let isRefForVisualAddedCaption = true;
    if (visuals && visuals.length > 0) {
      for (const visual of visuals) {
        if (visual && visual.rowData.length > 0) {
          const data = visual.rowData[1].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAddedCaption = false;
              break;
            }
          }
        }
      }
    }
    if (!isRefForVisualAddedCaption) {
      setDisplayReferenceAlert(true);
      return;
    }

    setSupportingClaimsAndVisuals([
      ...supportingClaimsAndVisuals,

      {
        type: "supportingClaim",
        record_index: maxRecordIndex() + 1,
        isMustUse: true,
        usageGuidelines: "",
        rowData: [
          {
            type: "Supporting Claim",
            data: [],
            isEmpty: false,
          },
          {
            type: "Description / Footnotes",
            data: [],
          },
          {
            type: "Abbrevations",
            data: [],
          },
          {
            type: "References / Annotations",
            data: [],
          },
          {
            id: null,
          },
        ],
      },
    ]);
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };
  //Add Supporting claim Row Starts
  const [reusableTextAdded, setReusableTextAdded] = useState([]);

  const addReusableTextrow = () => {
    hideReusableTextModal();
    setIsReusabletextAdd(true);
    const disabledIds = reusableTextAdded?.map((reusableText) => {
      return reusableText.reusableTextId;
    });
    const filteredRows = selectedRowsDetails.filter(
      (row) => !disabledIds.includes(row.reusableTextId)
    );

    const initialMaxRecordIndex = reusableTextAdded.reduce((maxIndex, item) => {
      const currentIndex = parseInt(item.record_index || 0);
      return Math.max(maxIndex, currentIndex);
    }, 0);
    const filteredRowsWithToggle = filteredRows.map((row, index) => ({
      ...row,
      reusMustUse: true,
      reusBussinessRules: reusBussinessRules,
      record_index: parseInt(initialMaxRecordIndex) + index + 1,
    }));
    setReusableTextAdded([...reusableTextAdded, ...filteredRowsWithToggle]);

    // if (primaryClaim.length > 0) {
    //   if (primaryReferenceAnchor.length === 0) {
    //     setDisplayReferenceAlert(true);
    //     return;
    //   }
    // }
    // const reusableTexts = [...supportingClaimsAndVisuals].filter((el) => {
    //   return el.type === "reusableText";
    // });
    // //For Supporting claim Empty Alert
    // let isRefForSupClaimAdded = true;
    // if (reusableTexts && reusableTexts.length > 0) {
    //   for (const claim of reusableTexts) {
    //     //console.log(claims, "++++++++");
    //     if (claim && claim.rowData.length > 0) {
    //       const data = claim.rowData[0].data;
    //       if (data && data.length === 0) {
    //         isRefForSupClaimAdded = false;
    //         claim.rowData[0].isEmpty = true;
    //       } else {
    //         claim.rowData[0].isEmpty = false;
    //       }
    //     }
    //   }
    // }

    // if (!isRefForSupClaimAdded) {
    //   setDisplaySupportingAlert(true);
    //   return;
    // }
    // //add supporting claim
    // let isRefForClaimAdded = true;
    // if (reusableTexts && reusableTexts.length > 0) {
    //   for (const claim of reusableTexts) {
    //     if (claim && claim.rowData.length > 0) {
    //       const data = claim.rowData[0].data;
    //       if (data && data.length > 0) {
    //         let refs = claim.rowData[3];
    //         if (refs && refs.data && refs.data.length <= 0) {
    //           isRefForClaimAdded = false;
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }
    // if (!isRefForClaimAdded) {
    //   setDisplayReferenceAlert(true);
    //   return;
    // }
    // //supporting visual

    // setSupportingClaimsAndVisuals([
    //   ...supportingClaimsAndVisuals,

    //   {
    //     type: "reusableText",
    //     record_index: maxRecordIndexreusableText() + 1,
    //     rowData: [
    //       {
    //         type: "Description ",
    //         data: [],
    //       },
    //       {
    //         type: "Link",
    //         data: [],
    //       },
    //       {
    //         type: "Must Use",
    //         data: [],
    //       },
    //       {
    //         type: "Usage Guidelines",
    //         data: [],
    //       },
    //     ],
    //   },
    // ]);
    // setSelectedSuppRow(-1);
    // setSelectedSuppBox(-1);
  };

  //Delete
  const deleteReusabletextRow = (item, index) => {
    const itemArray = [item?.reusableTextId];
    const updatedReusableTextAdded = reusableTextAdded.filter(
      (reusableText) => reusableText.reusableTextId !== item.reusableTextId
    );
    const updatedSelectedIds = selectedReusableTextIds.filter(
      (id) => id !== item.reusableTextId
    );
    const updatedSelectedRowsDetails = selectedRowsDetails.filter(
      (row) => row.reusableTextId !== item.reusableTextId
    );
    setSelectedReusableTextIds(updatedSelectedIds);
    setReusableTextAdded(updatedReusableTextAdded);
    setSelectedRowsDetails(updatedSelectedRowsDetails);
    deleteReusableText(assetModuleId, itemArray, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        claimsSaveErr(errCoponent);
      } else {
        console.log("");
      }
    });
  };

  const deleteSuppClaimRow = (rowPosition, rowId) => {
    if (rowPosition === -1) {
      return;
    }
    const supClaimToBeDeleted = JSON.parse(
      JSON.stringify(supportingClaimsAndVisuals)
    ).find((item, itemIndex) => {
      return itemIndex === rowPosition;
    });
    const newArray = JSON.parse(
      JSON.stringify(supportingClaimsAndVisuals)
    ).filter((item, itemIndex) => rowPosition !== itemIndex);
    //setSupportingClaimsAndVisuals([...newArray]);

    newArray.forEach((visualRow) => {
      const claims = (visualRow.rowData[5] && visualRow.rowData[5].claim) || [];

      const newClaims = claims.filter((claim) => {
        if (claim.moduleClaimId === rowId) {
          return false;
        }
        return true;
      });

      if (visualRow.rowData[5]) {
        visualRow.rowData[5].claim = newClaims;
      }
    });

    const anchors = supClaimToBeDeleted.rowData[3].data;
    let supClaimAnchors = JSON.parse(
      JSON.stringify(getSupprortingClaimReferences)
    );
    if (anchors && anchors.length > 0) {
      anchors.forEach((anchor) => {
        supClaimAnchors = supClaimAnchors.filter((el) => {
          return el.id !== anchor.id && el.refId !== anchor.refId;
        });
      });
    }

    setGetSupprortingClaimReferences(supClaimAnchors);
    setDisplaySupportingAlert(false);
    setSupportingClaimsAndVisuals(newArray);
    if (rowId) {
      // const moduleId = "eea687d4-e3e2-4e7b-b5fe-94abf7d7a548";
      // const moduleId = props.location.state.moduleId;
      deleteClaims(assetModuleId, [rowId], (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          claimsSaveErr(errCoponent);
        } else {
          console.log(response.data);
        }
      });
    }
  };

  const handleDeleteForSuppClaim = (model) => {
    let modelText = model;
    let afterDeleted = Object.values(
      modelText.match(/<sup>(.*?)<\/sup>/g)
        ? modelText.match(/<sup>(.*?)<\/sup>/g)
        : ""
    );
    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      //return unique;
      setSuperScriptDataForClaim(unique);
    }
    setTempData("");
  };

  function handleInputChangeFootNote(atomTextChanged) {
    let boxNo = 0;
    if (selectedSuppRow > -1 && boxNo > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[boxNo];
      const newText = {
        atomText: atomTextChanged,
        sourceId: boxToBeUpdated.data[0].sourceId || undefined,
      };
      boxToBeUpdated &&
        boxToBeUpdated.data &&
        boxToBeUpdated.data.splice(currentTextReplace, 1, newText);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
  }

  const addFootNotesToMasterArray = (footNoteData) => {
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedSuppBox];
      boxToBeUpdated &&
        boxToBeUpdated.data &&
        boxToBeUpdated.data.push(footNoteData);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    setAddBtnStatus(false);
    setSuppClaimFootNoteText([]);
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const footNoteDirectEditSave = (alteredData) => {
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedSuppBox];
      boxToBeUpdated.data = [...alteredData];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    //setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const saveFootNoteRefSup = (alteredData) => {
    if (selectedSuppRow > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[1];
      boxToBeUpdated.data = [...alteredData];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const resetReference = (resetRefData, rowIndex) => {
    if (rowIndex > -1) {
      // const supClaimsAndVisuals = JSON.parse(
      //   JSON.stringify(supportingClaimsAndVisuals)
      // );
      const supClaimsAndVisuals = [...supportingClaimsAndVisuals];
      const rowToBeUpdated = supClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[3];
      boxToBeUpdated.data = [...resetRefData];
      setSupportingClaimsAndVisuals([].concat(supClaimsAndVisuals));
      let refs = [];
      supClaimsAndVisuals.forEach((el) => {
        if (el?.type === "supportingClaim") {
          refs = refs.concat(el?.rowData[3].data);
        }
      });
      setGetSupprortingClaimReferences([].concat(refs));
    }
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const resetFootNotes = (resetFootNoteData, rowIndex) => {
    if (rowIndex > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[1];
      boxToBeUpdated.data = [...resetFootNoteData];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };
  const addAbbrToMaster = (abbrData) => {
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedSuppBox];
      const newArray = {
        abbrText: abbrData[0].abbrText,
        title: abbrData[0].title,
        record_index: abbrData[0].record_index,
      };
      boxToBeUpdated &&
        boxToBeUpdated.data &&
        boxToBeUpdated.data.push(newArray);
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
      setSuppClaimFootNoteText([]);
      setSelectedTextPrimaryClaimValue("");
    }
    //Upddate global list of abbreviations
    updateMasterAbbrList(abbrData);
  };

  const updateMasterAbbrList = (abbrData) => {
    if (abbrData && abbrData.length > 0) {
      let newAbbrArray = [];
      for (const abbr of abbrData) {
        if (abbr.abbrText && abbr.title) {
          const newObj = {
            title: abbr.title,
            record_index: abbr.record_index,
            data: { abbrText: abbr.abbrText },
          };
          //if(!checkIfAbbrAlreadyAdded(newObj)){
          newAbbrArray = newAbbrArray.concat(newObj);
          //}
        }
      }
      setGlobalAbbrList(globalAbbrList.concat(newAbbrArray));
    }
  };

  const concatAnnotationsForSameRefId = (references) => {
    // let unique = refernces
    //   .map((e) => e["refId"])
    //   .map((e, i, final) => final.indexOf(e) === i && i)
    //   .filter((obj) => refernces[obj])
    //   .map((e) => refernces[e]);

    // const duplicateIds = refernces
    //   .map((e) => e["refId"])
    //   .map((e, i, final) => final.indexOf(e) !== i && i)
    //   .filter((obj) => refernces[obj])
    //   .map((e) => refernces[e]["refId"]);

    // const duplicateObjs = refernces.filter((obj) =>
    //   duplicateIds.includes(obj.refId)
    // );
    // let uniqueRem = [];
    // duplicateIds.forEach((duplicateId) => {
    //   uniqueRem = unique.filter((el) => {
    //     return el.refId !== duplicateId;
    //   });
    // });
    // let finalData = duplicateObjs.length > 0 ? [duplicateObjs[0]] : [];
    // duplicateObjs.splice(0, 1);
    // if (duplicateObjs.length > 0) {
    //   duplicateObjs.forEach((el) => {
    //     const foundIndex = finalData.findIndex((obj) => obj.refId === el.refId);
    //     if (foundIndex > -1) {
    //       let annots = finalData[foundIndex].annotationList;
    //       finalData[foundIndex].annotationList = annots.concat(
    //         el.annotationList
    //       );
    //     } else {
    //       finalData = finalData.concat(el);
    //     }
    //   });
    // }
    // uniqueRem.forEach((el) => {
    //   const foundIndex = finalData.findIndex((obj) => obj.refId === el.refId);
    //   if (foundIndex > -1) {
    //     let annots = finalData[foundIndex].annotationList;
    //     finalData[foundIndex].annotationList = annots.concat(el.annotationList);
    //   } else {
    //     finalData = finalData.concat(el);
    //   }
    // });
    // finalData = finalData.concat(uniqueRem);

    let finalData = references.length > 0 ? [references[0]] : [];
    references.splice(0, 1);
    if (references.length > 0) {
      references.forEach((el) => {
        const foundIndex = finalData.findIndex((obj) => obj.refId === el.refId);
        if (foundIndex > -1) {
          let annots = finalData[foundIndex].annotationList;
          finalData[foundIndex].annotationList = annots.concat(
            el.annotationList
          );
        } else {
          finalData = finalData.concat(el);
        }
      });
    }

    finalData.forEach((el) => {
      const arr = el.annotationList,
        keys = ["rowIndex", "compIndex", "linkType", "id"];
      // filtered = arr.filter(
      //   (
      //     (s) => (o) =>
      //       ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
      //   )(new Set())
      // );
      el.annotationList = removeDuplicatesBasedOnMultipleKeys(arr, keys);
    });

    return finalData;
  };

  const saveSuppRef = (SuppRefList) => {
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      const claimsAndVisuals = [...supportingClaimsAndVisuals]; //JSON.parse(
      //   JSON.stringify(supportingClaimsAndVisuals)
      // );
      const rowToBeUpdated = claimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[3];
      boxToBeUpdated.data = [...SuppRefList];
      setSupportingClaimsAndVisuals(claimsAndVisuals);
      let updatedrefs = [...getSupprortingClaimReferences].concat(SuppRefList);

      setGetSupprortingClaimReferences(
        [].concat(concatAnnotationsForSameRefId(updatedrefs))
      );
    }

    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };
  const saveInMasterList = (Data) => {
    const newArray = [...referenceMasterList];
    newArray.push.apply(newArray, Data);
    const arrayHashmap = newArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    const finalListmergedArray = removeDuplicates(mergedArray, "refId");
    let filteredFinalArray = removeDuplicateAnnotation(finalListmergedArray);
    setReferenceMasterList(filteredFinalArray);
  };
  //Add Supporting claim Row Ends
  const transformedArray = supportingClaimsAndVisuals
    .filter((el) => {
      return el?.type === "supportingClaim";
    })
    .map((item) => {
      return item.rowData[0] && item.rowData[0]["data"][0];
    });

  const formattedArray = transformedArray.map((item, index) => {
    if (item === undefined) {
      return item;
    } else {
      return { ...item, id: index + 1 };
    }
  });

  const formattedArrayGroup = formattedArray.filter((item) => {
    if (item === undefined) {
      return false;
    } else {
      return true;
    }
  });

  const findRefIndexInMasterDataSource = (ref) => {
    return references.indexOf(ref) + 1;
  };

  const renderSelectedRefs = () => {
    if (
      primaryReference &&
      Object.keys(primaryReference).length > 0 &&
      Object.values(primaryReference).length > 0
    ) {
      let refsToBeRendered = [];
      let refsKey = Object.keys(selectedReferences);

      for (const key of Object.keys(selectedReferences)) {
        refsToBeRendered = refsToBeRendered.concat(selectedReferences[key]);
      }
      let i = 0;
      let tempArray = [];
      for (i = 0; i < refsToBeRendered.length; i++) {
        tempArray.push(findRefIndexInMasterDataSource(refsToBeRendered[i]));
      }
      tempArray = tempArray.sort();
      let set = new Set(refsToBeRendered);
      refsToBeRendered = [...set];
      let j = 0;
      let tempArray2 = [];
      for (j = 0; j < tempArray.length; j++) {
        for (i = 0; i < refsToBeRendered.length; i++) {
          if (
            findRefIndexInMasterDataSource(refsToBeRendered[i]) === tempArray[j]
          )
            tempArray2.push(refsToBeRendered[i]);
        }
      }
      refsToBeRendered = tempArray2;
      // console.log("short", refsKey, refsToBeRendered, tempArray);
      if (primaryReference && primaryReference.length > 0) {
        return (
          <div>
            <span className="editorActive-heading d-block mt-2 mb-3">
              References / Annotations
            </span>
            {primaryReference
              .sort(function (a, b) {
                return a.refId - b.refId;
              })
              .map((x, index) => {
                return (
                  <div className="d-flex">
                    {
                      <div className="referenceBoxIndex">
                        <span className="d-inline-block refNumberDisplay">
                          {x.refId && x.refId}
                        </span>
                      </div>
                    }
                    {
                      <div className="referenceBoxLabelN">
                        {parse(x.reference && x.reference)}
                        <span style={{ color: "#666666", paddingTop: "15px" }}>
                          {x.annotationList
                            .sort(function (a, b) {
                              if (a.annotText < b.annotText) {
                                return -1;
                              }
                              if (a.annotText > b.annotText) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((list, index) => (
                              <div key={index}>
                                {parse(list.annotText || "")}
                              </div>
                            ))}
                        </span>
                      </div>
                    }
                  </div>
                );
              })}
          </div>
        );
      }
    }
    return <span className="selectClaimBoxText">References / Annotations</span>;
  };
  const [shouldDisable, setShouldDisable] = useState(false);
  const [btnOptions, setBtnOptions] = useState({});

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleProceed = () => {
    const supportingClaims = supportingClaimsAndVisuals.filter((el) => {
      return el.type === "supportingClaim";
    });
    const supportingVisuals = supportingClaimsAndVisuals.filter((el) => {
      return el.type === "supportingVisual";
    });
    //for supporting claim
    setIsCaptionAdded(true);
    let isRefForClaimAdded = true;
    if (supportingClaims && supportingClaims.length > 0) {
      for (const claim of supportingClaims) {
        if (claim && claim.rowData.length > 0) {
          const data = claim.rowData[0].data;
          if (data && data.length > 0) {
            let refs = claim.rowData[3];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForClaimAdded = false;
              break;
            }
          }
        }
      }
    }
    //For Supporting claim Empty Alert
    let isRefForSupClaimAdded = true;
    if (supportingClaims && supportingClaims.length > 0) {
      for (const claim of supportingClaims) {
        if (claim && claim.rowData.length > 0) {
          const data = claim.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupClaimAdded = false;
            claim.rowData[0].isEmpty = true;
          } else {
            claim.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupClaimAdded) {
      setDisplaySupportingAlert(true);
      return;
    }

    //For Supporting Visual Empty Alert
    let isRefForSupCVisualAdded = true;
    if (supportingVisuals && supportingVisuals.length > 0) {
      for (const visual of supportingVisuals) {
        if (visual && visual.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupCVisualAdded = false;
            visual.rowData[0].isEmpty = true;
          } else {
            visual.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupCVisualAdded) {
      setDisplaySupportingVisualAlert(true);
      return;
    }

    let isRefForVisualAdded = true;
    if (supportingVisuals && supportingVisuals.length > 0) {
      for (const visual of supportingVisuals) {
        if (visual && visual.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAdded = false;
              break;
            }
          }
        }
      }
    }
    let isRefForVisualAddedCaption = true;
    if (supportingVisuals && supportingVisuals.length > 0) {
      for (const visual of supportingVisuals) {
        if (visual && visual.length > 0) {
          const data = visual.rowData[1].data;
          if (data && data.length > 0) {
            let refs = visual.rowData[4];
            if (refs && refs.data && refs.data.length <= 0) {
              isRefForVisualAddedCaption = false;
              break;
            }
          } else {
            isRefForVisualAddedCaption = false;
            setIsCaptionAdded(false);
            break;
          }
        }
      }
    }

    if (primaryClaim.length > 0) {
      if (primaryReferenceAnchor.length === 0) {
        setDisplayReferenceAlert(true);
        return;
      }
    }
    if (footNoteCheckBox.length > 0) {
      if (primaryReferenceAnchor.length === 0) {
        setDisplayReferenceAlert(true);
        return;
      }
    }

    if (!isRefForVisualAdded) {
      setDisplayReferenceAlert(true);
      return;
    }

    if (!isRefForVisualAddedCaption) {
      setDisplayReferenceAlert(true);
      return;
    }

    if (formattedArrayGroup.length === 0) {
      setDisplayProcedGroupsAlert(true);
      if (moduleType === moduleTypes.denovo) {
        handlePageSave(() => {
          history.push(routes?.addBusinessRules);
        });
      }
      return;
    }
    if (!isRefForClaimAdded) {
      setDisplayReferenceAlert(true);
      return;
    }

    handlePageSave((proceedSave) => {
      if (proceedSave) {
        history.push({
          pathname:
            moduleType === moduleTypes?.denovo
              ? routes.addBusinessRules
              : routes.addGroups,
          state: {
            formattedArrayGroup,
            sourceType: damsSources?.veeva?.name.toLowerCase(),
          },
        });
      }
    });

    //}
  };

  const handleGroupHideModal = () => {
    setDisplayProcedGroupsAlert(false);
  };
  const handleReferenceHideModal = () => {
    setDisplayReferenceAlert(false);
  };

  const handleGroupsPrcd = () => {
    handlePageSave(() => {
      history.push(routes?.addBusinessRules);
    });
  };
  const handlePageClose = () => {
    if (moduleType === moduleTypes.denovo) {
      window.history.back();
      return;
    }
    history.push({
      pathname: "/create-module",
      state: {
        assetId: moduleAssetId,
      },
    });
  };

  const [primaryClaimId, setPrimaryClaimId] = useState("");
  const [getCoreClaimId, setGetCoreClaimId] = useState("");

  useEffect(() => {
    setSuppVisualRefAdded(false);
    setSuppClaimRefAdded(false);
    if (assetModuleId) {
      getModuledetails();
    }
  }, [assetModuleId]);
  const setGlobalAbbrs = (abbreviations) => {
    //Remove Duplicates
    let uniq = {};
    //abbreviations = [...abbreviations].filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true));
    setGlobalAbbrList(abbreviations);
  };

  const keepSelectedAtomForClaimsAndVisuals = (sourceId, type) => {
    if (pages && pages.length > 0) {
      let atoms = [];
      pages.forEach((page) => {
        if (page.atoms && page.atoms.length > 0) {
          atoms = atoms.concat(page.atoms);
        }
      });
      //  atoms = atoms.concat([...pages].map((page) => {
      //   return page.atoms || []
      // }))
      const addedAtom = atoms.find((atom) => {
        return atom._id === sourceId;
      });

      if (addedAtom) {
        switch (type) {
          case "primary":
            setPrimaryClaimSelectedAtom(addedAtom);
            break;
          case "supporting":
            break;
          case "visual":
            break;
          default:
            break;
        }
      }
    }
  };

  const removeAllSuperscriptTags = (htmlString) => {
    // create a new dov container
    var div = document.createElement("div");

    // assing your HTML to div's innerHTML
    div.innerHTML = htmlString;

    // get all <a> elements from div
    var elements = div.getElementsByTagName("sup");
    // remove all <a> elements
    while (elements[0]) elements[0].parentNode.removeChild(elements[0]);

    // get div's innerHTML into a new variable
    var plainString = htmlToString(div.innerHTML);
    return plainString;
  };

  const isMatchingWithAnAtomText = (text) => {
    if (pages && pages.length > 0) {
      let atoms = [];
      pages.forEach((page) => {
        if (page.atoms && page.atoms.length > 0) {
          atoms = atoms.concat(page.atoms);
        }
      });
      const addedAtom = atoms.find((atom) => {
        return atom.text === text;
      });
      if (addedAtom) {
        return true;
      }
    }
    return false;
  };

  const matchingAtom = (text) => {
    if (pages && pages.length > 0) {
      let atoms = [];
      pages.forEach((page) => {
        if (page.atoms && page.atoms.length > 0) {
          atoms = atoms.concat(page.atoms);
        }
      });
      const addedAtom = atoms.find((atom) => {
        return atom.text === text;
      });
      if (addedAtom) {
        return addedAtom;
      }
    }
    return undefined;
  };
  const setModuleStatus = (module) => {
    let status = "";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        if (res) {
          setPdfUrl(res.data);
          return;
        }
        const errCoponent = ErrComponentToRender(errCode);
        claimsSaveErr(errCoponent);
      });
    }
  };

  const getModuledetails = () => {
    // const moduleId = assetModuleId;
    getModuleDetailsById(assetModuleId, (response, err, errCode) => {
      if (err) {
        //    const errCoponent = ErrComponentToRender(errCode);
        // fileUplodedErr(errCoponent);
      } else {
        // console.log(response);

        if (isThisMLRFeedbackFlow()) {
          fetchAnnotatedPdf(response.data);
          calculatePdfWidth();
        }
        setModuleStatus(response.data);
        if (response.data.pageNumber) {
          setSelectedPageNumberForPrimaryClaim(response.data.pageNumber);
        }
        if (response.data.name) {
          setModuleName(response.data.name);
          updateModuleInfo({ moduleName: response.data.name });
        }

        const primaryClaims = response.data.moduleClaims.filter((claim) => {
          if (claim.type.toLowerCase() === "primary") {
            return true;
          }
          return false;
        });

        const primaryMustuseValue = response.data.moduleClaims.map((claim) => {
          return claim.isMustUse;
        });
        if (primaryMustuseValue.length === 0) {
          setPrimaryMustUse(true);
        } else {
          setPrimaryMustUse(primaryMustuseValue[0]);
        }
        const primaryUsageGuidelines = response?.data?.moduleClaims.map(
          (claim) => {
            return claim.usageGuidelines;
          }
        );
        if (primaryMustuseValue.length === 0) {
          setPrimaryBussinessRules("");
        } else {
          setPrimaryBussinessRules(primaryUsageGuidelines[0]);
        }

        const coreClaimCategories = response.data.moduleCoreCategories.map(
          (categories) => ({
            claimCategory: categories.coreCategory,
            //claimCategoryId: categories.coreCategoryId,
            claims: [],
          })
        );
        if (coreClaimCategories) {
          const moduleInfo = getModuleInfoFromLocalStorage();
          if (
            moduleInfo.moduleType === "newModule" &&
            primaryClaims.length > 0
          ) {
            setSelectedCategories(coreClaimCategories);
          } else if (
            moduleInfo.moduleType === "newModule" &&
            props.location.state.isCategoriesAdded
          ) {
            setSelectedCategories(coreClaimCategories);
          } else if (
            moduleInfo.moduleType === "editModule" ||
            moduleInfo.moduleType === "addressMLRFeedback"
          ) {
            setSelectedCategories(coreClaimCategories);
          }
        }
        // else {
        //   setDefaultCategories();
        // }

        const coreClaim = response.data.moduleClaims
          .filter((claim) => {
            if (claim.type.toLowerCase() === "core") {
              return true;
            }
            return false;
          })
          .map((coreClaim) => ({
            id: coreClaim.id,
            claim: coreClaim.claim,
            sourceId: coreClaim.sourceId,
            claimsManagerId: coreClaim.claimsManagerId,
          }));
        if (coreClaim && coreClaim.length > 0) {
          setSelectedClaim(coreClaim[0]);
          setGetCoreClaimId(coreClaim[0].id);
        }
        //setBrandId(response.data.brandId);
        setModule(response.data);
        const primaryClaimsText = primaryClaims.map((claim) => {
          return {
            claim: claim.claim,
            cmDocumentNumber: claim.cmDocumentNumber,
            persona: claim.persona,
            tone: claim.tone,
            record_index: parseInt(claim.record_index),
          };
        });
        //const primaryClaimsVariant = primaryClaims.map((claim) => claim.claim);

        const primaryClaimVariant = primaryClaims.flatMap((item) => {
          return [...item.claimVariants];
        });

        const text = primaryClaimVariant.map((variants) => {
          return {
            claim: variants.moduleClaim,
            cmDocumentNumber: variants.cmDocumentNumber,
            persona: variants.persona,
            tone: variants.tone,
          };
        });

        setPrimaryClaim([...primaryClaimsText].concat([...text]));
        setPrimaryClaimId(primaryClaims[0] ? primaryClaims[0].id : "");
        setPrimaryClaimSelectedIndex(0);
        let footNotes =
          primaryClaims[0] &&
          primaryClaims[0]["supportingTextComponents"].filter((item) => {
            if (item.componentType.toLowerCase() === "footnote") {
              return true;
            }
            return false;
          });

        const abbrivations =
          primaryClaims[0] &&
          primaryClaims[0]["supportingTextComponents"]
            .filter((item) => {
              if (item.componentType.toLowerCase() === "abbreviation") {
                return true;
              }
              return false;
            })
            .map((item) => ({
              data: { abbrText: item.text },
              title: item.prefix,
              record_index: item.record_index
                ? parseInt(item.record_index)
                : undefined,
            }));
        setArrAbbr(abbrivations);
        let abbreviationList = abbrivations;
        const finalMasterAbbrList = abbrivations.map((item) => {
          return item.data.abbrText;
        });

        setGetPrimaryAbbrList(abbrivations);
        const componentLinks = response.data.componentLinks;

        const annonations =
          primaryClaims[0] &&
          primaryClaims[0]["supportingTextComponents"].filter((item) => {
            if (item.componentType.toLowerCase() === "annotation") {
              return true;
            }
            return false;
          });

        const references =
          primaryClaims[0] &&
          primaryClaims[0]["supportingTextComponents"]
            .filter((item) => {
              if (item.componentType.toLowerCase() === "reference") {
                return true;
              }
              return false;
            })
            .map((item) => {
              const annotationList = [];
              const anchors = item.anchors || [];
              anchors.forEach((link) => {
                //debugger
                let compIndex = 0;
                if (link.linkType === "Footnote-Anchor") {
                  footNotes.forEach((footnote, index) => {
                    if (link.anchorComponentId === footnote.id) {
                      compIndex = index;
                    }
                  });
                } else {
                  const pClaims = primaryClaims.concat(primaryClaimVariant);
                  pClaims.forEach((claim, index) => {
                    if (link.anchorComponentId === claim.id) {
                      compIndex = index;
                    }
                  });
                }
                annotationList.push({
                  pageNumber: link.page,
                  column: link.column,
                  para: link.paragraph,
                  line: link.line,
                  anchorLinkFlag: link.anchorLinkFlag,
                  // annotText: link.anchorText,
                  id: link.id,
                  linkType:
                    link.linkType === "Footnote-Anchor"
                      ? "primary-claim-footnote"
                      : "primary-claim-varient",
                  compIndex: compIndex,
                });
              });

              return {
                annotationList: annotationList,
                id: item.veevaReferenceId,
                refId: item.prefix,
                veevaReferenceName: item.text,
                veevaLinked: item.veevaLinked,
              };
            });

        setPrimaryReferenceAnchor(references);
        footNotes = footNotes.map((item) => ({
          footNoteText: item.text,
          footNoteIcon: item.prefix,
        }));
        setSelectedFootNotes(footNotes);
        //get the selected Atom ID and keep it in local var
        keepSelectedAtomForClaimsAndVisuals(
          primaryClaims[0].sourceId,
          "primary"
        );
        //setReferenceMasterList(references);

        // Supporting Claim Starts

        const referencesListSuppporting = [];
        let claimsAndVisuals = [];
        const supportingClaims = response.data.moduleClaims.filter((claim) => {
          if (claim.type.toLowerCase() === "supporting") {
            return true;
          }
          return false;
        });
        let supportingVisuals = response.data.moduleVisuals;
        claimsAndVisuals = claimsAndVisuals
          .concat(supportingClaims)
          .concat(supportingVisuals);
        claimsAndVisuals = sortClaimsAndVisualsByRecordIndex(claimsAndVisuals);
        let allSuppVisualReferences = [];
        console.log("claimsAndVisuals", claimsAndVisuals);
        claimsAndVisuals = claimsAndVisuals.map((item, rowIndex) => {
          if (
            item.type.toLowerCase() === "supporting" &&
            item.claim &&
            item.claimVariants
          ) {
            if (!item.supportingTextComponents) {
              return [];
            }
            const footNotes = item.supportingTextComponents.filter((item) => {
              if (item.componentType.toLowerCase() === "footnote") {
                return true;
              }
              return false;
            });
            const abbreviations = item.supportingTextComponents
              .filter((item) => {
                if (item.componentType.toLowerCase() === "abbreviation") {
                  return true;
                }
                return false;
              })
              .map((item) => ({
                data: { abbrText: item.text },
                title: item.prefix,
                record_index: item.record_index
                  ? parseInt(item.record_index)
                  : undefined,
              }));
            abbreviationList = abbreviationList.concat(abbreviations);
            // const finalMasterSupportingAbbrList = abbreviations.map((item) => {
            //   return item.text;
            // });
            const finalMasterSupportingAbbrList = abbrivations.map((item) => {
              return item.data.abbrText;
            });
            setGetSupprtingClaimAbbrList(abbrivations);

            const componentLinksSupportingClaim = response.data.componentLinks;

            const annonationsSupportingClaim =
              item.supportingTextComponents.filter((item) => {
                if (item.componentType.toLowerCase() === "annotation") {
                  return true;
                }
                return false;
              });
            const referencesSupportingClaim = item.supportingTextComponents
              .filter((item) => {
                if (item.componentType.toLowerCase() === "reference") {
                  return true;
                }
                return false;
              })
              .map((item) => {
                let annotationList = [];
                const anchors = item.anchors || [];
                anchors.forEach((link) => {
                  let compIndex = 0;
                  let linkType = "";
                  if (link.linkType === "Footnote-Anchor") {
                    linkType = "supporting-claim-footnote";
                    footNotes.forEach((footnote, index) => {
                      if (link.anchorComponentId === footnote.id) {
                        compIndex = index;
                      }
                    });
                  } else {
                    linkType = "supporting-claim";
                  }
                  annotationList.push({
                    // annotText: link.anchorText,
                    pageNumber: link.page,
                    column: link.column,
                    para: link.paragraph,
                    line: link.line,
                    anchorLinkFlag: link.anchorLinkFlag,
                    id: link.id,
                    compIndex: compIndex,
                    rowIndex: rowIndex,
                    linkType: linkType,
                  });
                });

                return {
                  annotationList: annotationList,
                  id: item.veevaReferenceId,
                  refId: item.prefix,
                  veevaReferenceName: item.text,
                  veevaLinked: item.veevaLinked,
                };
              });

            referencesListSuppporting.push(...referencesSupportingClaim);

            return {
              type: "supportingClaim",
              record_index: parseInt(item.record_index),
              isMustUse: item?.isMustUse,
              usageGuidelines: item?.usageGuidelines,
              rowData: [
                {
                  data:
                    //  [
                    //   {
                    //     atomText: item.claim,
                    //     sourceId: item.sourceId || undefined,
                    //   },
                    // ],
                    item.claimVariants.map((item) => ({
                      atomText: item.moduleClaim,
                      sourceId: item.sourceId || undefined,
                    })),
                  type: "Supporting Claim",
                },
                {
                  data: footNotes.map((item) => ({
                    footNoteText: item.text,
                    footNoteIcon: item.prefix,
                  })),
                  type: "Description / Footnotes",
                },
                {
                  data: abbreviations.map((item) => {
                    return {
                      abbrText: item.data.abbrText, //item.text,
                      title: item.title, //item.prefix,
                      record_index: item.record_index
                        ? parseInt(item.record_index)
                        : undefined,
                    };
                  }),
                  type: "Abbrevations",
                },
                {
                  data: concatAnnotationsForSameRefId(
                    referencesSupportingClaim
                  ),
                  type: "References / Annotations",
                },
                {
                  id: item.id,
                },
              ],
            };
          } else if (
            item.type.toLowerCase() === "supporting" &&
            item.visualVariants
          ) {
            const captions = item.supportingTextComponents.filter((item) => {
              if (item.componentType.toLowerCase() === "caption") {
                return true;
              }
              return false;
            });

            const footNotes = item.supportingTextComponents.filter((item) => {
              if (item.componentType.toLowerCase() === "footnote") {
                return true;
              }
              return false;
            });

            const abbreviations = item.supportingTextComponents
              .filter((item) => {
                if (item.componentType.toLowerCase() === "abbreviation") {
                  return true;
                }
                return false;
              })
              .map((item) => ({
                data: { abbrText: item.text },
                title: item.prefix,
                record_index: item.record_index
                  ? parseInt(item.record_index)
                  : undefined,
              }));
            abbreviationList = abbreviationList.concat(abbreviations);
            setGlobalAbbrs(abbreviationList);
            // const finalMasterVisualAbbrList = abbreviations.map((item) => {
            //   return item.text;
            // });
            const finalMasterVisualAbbrList = abbrivations.map((item) => {
              return item.data.abbrText;
            });

            setGetSupprtingVisualAbbrList(abbreviationList);

            const componentLinksSupportingVisual = response.data.componentLinks;

            const annonationsSupportingVisual =
              item.supportingTextComponents.filter((item) => {
                if (item.componentType.toLowerCase() === "annotation") {
                  return true;
                }
                return false;
              });

            const referencesSupportingVisual = item.supportingTextComponents
              .filter((item) => {
                if (item.componentType.toLowerCase() === "reference") {
                  return true;
                }
                return false;
              })
              .map((item) => {
                let annotationList = [];
                const anchors = item.anchors || [];
                anchors.forEach((link) => {
                  let compIndex = undefined;
                  let linkType = "";
                  if (link.linkType === "Footnote-Anchor") {
                    linkType = "supporting-visual-footnote";
                    footNotes.forEach((footnote, index) => {
                      if (link.anchorComponentId === footnote.id) {
                        compIndex = index;
                      }
                    });
                  } else {
                    linkType = "supporting-visual";
                    captions.forEach((caption, index) => {
                      if (link.anchorComponentId === caption.id) {
                        compIndex = index;
                      }
                    });
                  }
                  annotationList.push({
                    // annotText: link.anchorText,
                    pageNumber: link.page,
                    column: link.column,
                    para: link.paragraph,
                    line: link.line,
                    anchorLinkFlag: link.anchorLinkFlag,
                    id: link.id,
                    linkType: linkType,
                    rowIndex: rowIndex,
                    compIndex: compIndex,
                  });
                });

                return {
                  annotationList: annotationList,
                  id: item.veevaReferenceId,
                  refId: item.prefix,
                  veevaReferenceName: item.text,
                  veevaLinked: item.veevaLinked,
                };
              });

            const trasnscript = item.supportingTextComponents.filter((item) => {
              if (item.componentType.toLowerCase() === "transcript") {
                return true;
              }
              return false;
            });
            allSuppVisualReferences.push(...referencesSupportingVisual);

            let visualLinks = {
              claim: [],
              dependency: "Optional",
            };

            item.claimVisuals.forEach((claimVisual) => {
              visualLinks.dependency = claimVisual.dependency;
              // visualLinks.claim.push({
              //   moduleClaimId: claimVisual.moduleClaimId,
              // });
              visualLinks.claim.push(claimVisual.moduleClaimId);
            });

            // visualLinks.claim.forEach((claim) => {
            //   const moduleClaimId = claim.moduleClaimId;
            //   let claimText = "";
            //   response.data.moduleClaims.forEach((moduleClaim) => {
            //     if (moduleClaim.id === moduleClaimId) {
            //       claimText = moduleClaim.claim;
            //     }
            //   });
            //   claim.claimText = claimText;
            // });

            // const isMustUseArray = response?.data.moduleVisuals.map(
            //   (visual) => visual.isMustUse
            // );
            // const usageGuidelinesArr = response?.data.moduleVisuals.map(
            //   (visual) => visual.usageGuidelines
            // );

            return {
              type: "supportingVisual",
              record_index: parseInt(item.record_index),
              isMustUse: item?.isMustUse,
              usageGuidelines: item?.usageGuidelines,
              rowData: [
                {
                  data: item.visualVariants.map((item) => ({
                    imgSrc: item.visualUrl,
                    sourceId: item.sourceId,
                    sourceType: item.sourceType,
                  })),
                  id: item.id,
                  type: "Image",
                },
                {
                  data: captions.map((item) => ({
                    cpationText: item.text,
                  })),
                  type: "Captions",
                },
                {
                  data: footNotes.map((item) => ({
                    footNoteText: item.text,
                    footNoteIcon: item.prefix,
                  })),
                  type: "Description / Footnotes",
                },
                {
                  data: abbreviations.map((item) => ({
                    abbrText: item.data.abbrText, //item.text,
                    title: item.title, //item.prefix,
                    record_index: item.record_index
                      ? parseInt(item.record_index)
                      : undefined,
                  })),
                  type: "Abbrevations",
                },
                {
                  data: concatAnnotationsForSameRefId(
                    referencesSupportingVisual
                  ),
                  type: "References / Annotations",
                },
                // {
                //   data: [],
                //   type: "Must Use",
                // },

                visualLinks,
              ],
            };
          }
        });

        setGetSupprortingClaimReferences(referencesListSuppporting);

        //Supporting Visual Starts

        //sort based on record index
        //claimsAndVisuals = claimsAndVisuals.concat(supportingVisuals);
        setSupportingClaimsAndVisuals(
          sortClaimsAndVisualsByRecordIndex(claimsAndVisuals)
        );
        setGetSupprortingVisualReferences(allSuppVisualReferences);
        setPrimaryClaimSelectedIndex(0);

        const reusablegetDetails = response?.data?.moduleReusableTexts?.map(
          (addedText, index) => ({
            record_index: addedText?.record_index,
            id: addedText.id,
            description: addedText.description,
            link: addedText.link,
            reusBussinessRules: addedText.usageGuidelines,
            reusableTextLabels: addedText?.reusableTextLabels,
            reusMustUse: addedText.isMustUse,
            reusableTextType: addedText.reusableTextType,
            reusableTextId: addedText.reusableTextId,
          })
        );
        const sortedReusableGetDetails = reusablegetDetails
          .slice()
          .sort((a, b) => {
            return parseInt(a.record_index) - parseInt(b.record_index);
          });

        setReusableTextAdded(sortedReusableGetDetails);
      }
    });
  };
  const handleClickAnchor = () => {
    setShowModalAnchor(true);

    //getModuledetails();
  };
  const onEditName = (newname) => {
    setModuleName(newname);
  };

  const handlePageSave = (callback) => {
    if (primaryClaim.length === 0) {
      return;
    }
    //For Supporting claim Empty Alert

    const supportingClaims = [...supportingClaimsAndVisuals].filter((el) => {
      return el.type === "supportingClaim";
    });
    let isRefForSupClaimAdded = true;

    if (supportingClaims && supportingClaims.length > 0) {
      for (const claim of supportingClaims) {
        if (claim && claim.rowData.length > 0) {
          const data = claim.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupClaimAdded = false;
            claim.rowData[0].isEmpty = true;
          } else {
            claim.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupClaimAdded) {
      setDisplaySupportingAlert(true);
      return;
    }
    //For Supporting Visual Empty Alert
    const visuals = [...supportingClaimsAndVisuals].filter((el) => {
      return el.type === "supportingVisual";
    });
    let isRefForSupCVisualAdded = true;
    if (visuals && visuals.length > 0) {
      for (const visual of visuals) {
        // console.log(visual, "++++++++");
        if (visual.rowData && visual.rowData.length > 0) {
          const data = visual.rowData[0].data;
          if (data && data.length === 0) {
            isRefForSupCVisualAdded = false;
            visual.rowData[0].isEmpty = true;
          } else {
            visual.rowData[0].isEmpty = false;
          }
        }
      }
    }

    if (!isRefForSupCVisualAdded) {
      setDisplaySupportingVisualAlert(true);
      return;
    }

    //Remove All the varinets which are blank
    const primaryClaimData = [...primaryClaim].filter((claim) => {
      if (claim) {
        if (
          typeof claim === "object" &&
          Object.keys(claim).length > 0 &&
          Object.values(claim).length > 0
        ) {
          return true;
        }
        if (typeof claim === "string" && claim.trim() !== "") {
          return true;
        }
      }
    });
    if (primaryClaimData.length === 0) {
      return;
    }

    const formattedCategory = selectedCategories.map((item) => {
      if (item === undefined) {
        return item;
      } else {
        return {
          coreCategory: item.claimCategory,
          //coreCategoryId: item.claimCategoryId,
        };
      }
    });
    let formattedCoreClaim;
    if (selectedClaim != null && selectedClaim !== undefined) {
      formattedCoreClaim = {
        id: getCoreClaimId,
        claim: selectedClaim && selectedClaim.claim,
        sourceId: selectedClaim && selectedClaim.sourceId,
        sourceType: "External",
        type: "Core",
        claimsManagerId: selectedClaim.claimsManagerId,
      };
    }

    // if (!formattedCoreClaim) {
    //   return;
    // }

    const moduleID = assetModuleId;

    let primaryClaimsSupportingTextComponents = [];
    const maxAbbrValue = globalAbbrList;
    selectedFootNotes.forEach((footnote) => {
      const addedAtom = matchingAtom(
        removeAllSuperscriptTags(footnote.footNoteText)
      );
      primaryClaimsSupportingTextComponents = [
        ...primaryClaimsSupportingTextComponents,
        {
          componentType: "Footnote",
          prefix: footnote.footNoteIcon,
          primaryComponentType: "Claim",
          text: footnote.footNoteText,
          sourceId: addedAtom ? addedAtom._id : "",
          sourceType: addedAtom ? "Atom" : "External",
          sourceUrl: addedAtom ? addedAtom.document : "",
        },
      ];
    });

    arrAbbr &&
      arrAbbr.forEach((abbrivation) => {
        const addedAtom = matchingAtom(
          removeAllSuperscriptTags(
            abbrivation.data && abbrivation.data.abbrText
          )
        );
        primaryClaimsSupportingTextComponents = [
          ...primaryClaimsSupportingTextComponents,
          {
            componentType: "Abbreviation",
            prefix: abbrivation.title,
            primaryComponentType: "Claim",
            text: abbrivation.data && abbrivation.data.abbrText,
            sourceId: addedAtom ? addedAtom._id : "",
            sourceType: addedAtom ? "Atom" : "External",
            sourceUrl: addedAtom ? addedAtom.document : "",
            record_index: abbrivation.record_index,
          },
        ];
      });

    primaryReferenceAnchor.forEach((reference) => {
      primaryClaimsSupportingTextComponents = [
        ...primaryClaimsSupportingTextComponents,
        {
          componentType: "Reference",
          prefix: reference.refId || "",
          primaryComponentType: "Claim",
          text: reference.veevaReferenceName,
          veevaReferenceId: reference.id,
          veevaLinked: reference.veevaLinked,
        },
      ];
    });

    const annotationList = primaryReferenceAnchor.flatMap((item) => {
      return [...item.annotationList];
    });
    const componentLinks = [];
    primaryReferenceAnchor.forEach((primaryReferenceAnchor) => {
      primaryReferenceAnchor.annotationList.forEach((annonation) => {
        componentLinks.push({
          referenceText: primaryReferenceAnchor.veevaReferenceName,

          linkType:
            annonation.linkType === "primary-claim-varient"
              ? "Claim-Anchor"
              : "Footnote-Anchor",
          anchorComponentText:
            annonation.linkType === "primary-claim-varient"
              ? primaryClaim[annonation.compIndex].claim
              : froalaInstance.current[annonation.compIndex].props.model,
          page: annonation.pageNumber,
          column: annonation.column,
          paragraph: annonation.para,
          line: annonation.line,
          anchorLinkFlag: annonation.anchorLinkFlag,
        });
      });
    });

    let addedAtom = undefined;
    if (!primaryClaimSelectedAtom) {
      addedAtom = matchingAtom(removeAllSuperscriptTags(primaryClaimData[0]));
    } else {
      addedAtom = primaryClaimSelectedAtom;
    }

    const primaryClaims = [
      {
        id: primaryClaimId ? primaryClaimId : null,
        record_index: primaryClaimData[0].record_index || 1,
        claim: primaryClaimData[0].claim, //primaryClaim[0],
        cmDocumentNumber:
          module?.assetDetails?.sourceType?.toLowerCase() ===
          damsSources.veeva.name.toLowerCase()
            ? module.moduleCode
            : primaryClaimData[0].cmDocumentNumber,
        persona: primaryClaimData[0].persona,
        tone: primaryClaimData[0].tone,
        sourceId: addedAtom ? addedAtom._id : "", //primaryClaimSelectedAtom ? primaryClaimSelectedAtom._id : "",
        sourceType: addedAtom ? "Atom" : "External", //primaryClaimSelectedAtom ? "Atom" : "External",
        sourceUrl: addedAtom ? addedAtom.document : "", //primaryClaimSelectedAtom ? primaryClaimSelectedAtom.document : "",
        type: "Primary",
        claimVariants: primaryClaimData //primaryClaim
          .filter((variant, index) => {
            if (index === 0) {
              return false;
            }
            return true;
          })
          .map((variant) => {
            return {
              moduleClaim: variant.claim,
              cmDocumentNumber: variant.cmDocumentNumber,
              persona: variant.persona,
              tone: variant.tone,
            };
          }),
        supportingTextComponents: primaryClaimsSupportingTextComponents,
        //componentLinks: [...componentLinks]
        componentLinks:
          componentLinks && componentLinks.length > 0
            ? componentLinks
            : undefined,
        isMustUse: primaryMustUse,
        usageGuidelines: primaryBussinessRules,
      },
    ];

    const supportingClaimsData = supportingClaims.map((row, index) => {
      const suppFootNoteList =
        row.rowData[1] &&
        row.rowData[1].data &&
        row.rowData[1].data.map((footNote) => {
          const addedAtom = matchingAtom(
            removeAllSuperscriptTags(footNote.footNoteText)
          );
          return {
            componentType: "Footnote",
            prefix: footNote.footNoteIcon,
            primaryComponentType: "Claim",
            text: footNote.footNoteText,
            sourceId: addedAtom ? addedAtom._id : "",
            sourceType: addedAtom ? "Atom" : "External",
            sourceUrl: addedAtom ? addedAtom.document : "",
          };
        });

      const suppAbbrList =
        row.rowData[2] &&
        row.rowData[2].data &&
        row.rowData[2].data.map((abbrivation) => {
          const addedAtom = matchingAtom(
            removeAllSuperscriptTags(abbrivation.abbrText)
          );
          return {
            componentType: "Abbreviation",
            prefix: abbrivation.title,
            primaryComponentType: "Claim",
            text: abbrivation.abbrText,
            sourceId: addedAtom ? addedAtom._id : "",
            sourceType: addedAtom ? "Atom" : "External",
            sourceUrl: addedAtom ? addedAtom.document : "",
            record_index: abbrivation.record_index,
          };
        });

      let supportingRowID = row.rowData[4] && row.rowData[4].id;

      const referenceListSupporting =
        row.rowData[3] &&
        row.rowData[3].data &&
        row.rowData[3].data.map((references) => {
          return {
            componentType: "Reference",
            prefix: references.refId || "",
            primaryComponentType: "Claim",
            text: references.veevaReferenceName,
            veevaReferenceId: references.id,
            veevaLinked: references.veevaLinked,
          };
        });

      const componentLinksSuppporting = [];

      row.rowData[3] &&
        row.rowData[3].data &&
        row.rowData[3].data.forEach((supportingReference) => {
          supportingReference.annotationList.forEach((annonation) => {
            let anchorComponentText = "";
            if (annonation.linkType === "supporting-claim") {
              // const variants = row.rowData[0].data.map((claim) => ({
              //   moduleClaim: claim.atomText,
              //   cmDocumentNumber: claim.cmDocumentNumber,
              // }));
              anchorComponentText =
                // moduleType === moduleTypes.denovo
                //   ? row.rowData[0].data.map((claim) => ({
                //       moduleClaim: claim.atomText,
                //     }))
                //   :
                row.rowData[0].data[0].atomText;
            } else {
              anchorComponentText = suppFootNoteList[annonation.compIndex].text;
            }

            componentLinksSuppporting.push({
              referenceText: supportingReference.veevaReferenceName,
              //anchorText: annonation.annotText || "",
              linkType:
                annonation.linkType === "supporting-claim"
                  ? "Claim-Anchor"
                  : "Footnote-Anchor",
              anchorComponentText: anchorComponentText,
              page: annonation.pageNumber,
              column: annonation.column,
              paragraph: annonation.para,
              line: annonation.line,
              anchorLinkFlag: annonation.anchorLinkFlag,
            });
          });
        });
      let addedAtoms = [];
      // if (moduleType === moduleTypes?.denovo) {
      //   const suppClaimAtomText =
      //     row.rowData[0] &&
      //     row.rowData[0].data &&
      //     row.rowData[0].data.map((atom) => {
      //       const addedAtom = matchingAtom(
      //         removeAllSuperscriptTags(atom?.atomText)
      //       );
      //       let payLoad = {
      //         claim: moduleType === moduleTypes?.denovo ? atom?.atomText : "",

      //         record_index: row.record_index,
      //         cmDocumentNumber: addedAtom?.cmDocumentNumber,
      //         sourceId: addedAtom ? addedAtom._id : "",
      //         sourceType: addedAtom ? "Atom" : "External",
      //         sourceUrl: addedAtom ? addedAtom.document : "",
      //         type: "Supporting",
      //         supportingTextComponents: [
      //           ...suppFootNoteList,
      //           ...suppAbbrList,
      //           ...referenceListSupporting,
      //           // ...annotationListSupporting,
      //         ],
      //         //componentLinks: componentLinksSuppporting
      //         componentLinks:
      //           componentLinksSuppporting &&
      //           componentLinksSuppporting.length > 0
      //             ? componentLinksSuppporting
      //             : undefined,
      //         isMustUse: row?.isMustUse,
      //         usageGuidelines: row?.usageGuidelines,
      //       };

      //       return payLoad;
      //     });
      // } else {
      addedAtoms = matchingAtom(
        removeAllSuperscriptTags(
          row.rowData[0] &&
            row.rowData[0].data &&
            row.rowData[0].data[0] &&
            row.rowData[0].data[0].atomText
        )
      );
      //}

      // const supportingClaimVariants = supportingClaims.map((claim) =>
      //   claim.rowData[0].data
      //     .filter((variant, index) => index !== 0)
      //     .map((variant) => ({
      //       moduleClaim: variant.atomText,
      //       cmDocumentNumber: variant.cmDocumentNumber,
      //     }))
      // );

      const variants = row.rowData[0].data.map((claim) => ({
        moduleClaim: claim.atomText,
        cmDocumentNumber: claim.cmDocumentNumber,
      }));

      let payLoad = {
        claim:
          (row.rowData[0] &&
            row.rowData[0].data &&
            row.rowData[0].data[0] &&
            row.rowData[0].data[0].atomText) ||
          "",
        record_index: row.record_index,
        cmDocumentNumber: addedAtom?.cmDocumentNumber,
        sourceId: addedAtom ? addedAtom._id : "",
        sourceType: addedAtom ? "Atom" : "External",
        sourceUrl: addedAtom ? addedAtom.document : "",
        type: "Supporting",

        claimVariants: variants,
        supportingTextComponents: [
          ...suppFootNoteList,
          ...suppAbbrList,
          ...referenceListSupporting,
          // ...annotationListSupporting,
        ],
        //componentLinks: componentLinksSuppporting
        componentLinks:
          componentLinksSuppporting && componentLinksSuppporting.length > 0
            ? componentLinksSuppporting
            : undefined,
        isMustUse: row?.isMustUse,
        usageGuidelines: row?.usageGuidelines,
      };

      if (supportingRowID) {
        payLoad["id"] = supportingRowID;
      }
      return payLoad;
    });

    // SUPPORTING VISUAL STARTS FROM HERE //

    const supportingVisualData = visuals.map((row) => {
      let suppVisualList =
        row.rowData[0] &&
        row.rowData[0].data &&
        row.rowData[0].data
          .map((imageList) => {
            if (imageList.imgSrc) {
              return {
                sourceId: imageList.sourceId, //imageList.id ? imageList.id : "",
                sourceType: imageList.sourceType, //imageList.id ? "Atom" : "URL",
                visualUrl: imageList.imgSrc,
                cmDocumentNumber: imageList?.cmDocumentNumber,
              };
            }
          })
          .filter((item) => item); // to remove undefined values

      const suppVisualCaptionsList =
        row.rowData[1] &&
        row.rowData[1].data &&
        row.rowData[1].data
          .filter((caption) => {
            if (
              caption &&
              caption.cpationText &&
              caption.cpationText.trim() !== ""
            ) {
              return true;
            }
          })
          .map((caption) => {
            const addedAtom = matchingAtom(
              removeAllSuperscriptTags(caption.cpationText)
            );
            return {
              componentType: "Caption",
              prefix: "",
              primaryComponentType: "Visual",
              text: caption.cpationText,
              cmDocumentNumber: addedAtom?.cmDocumentNumber,
              sourceId: addedAtom ? addedAtom._id : "",
              sourceType: addedAtom ? "Atom" : "External",
              sourceUrl: addedAtom ? addedAtom.document : "",
            };
          });

      const suppVisualFootNoteList =
        row.rowData[2] &&
        row.rowData[2].data &&
        row.rowData[2].data.map((footNote) => {
          const addedAtom = matchingAtom(
            removeAllSuperscriptTags(footNote.footNoteText)
          );
          return {
            componentType: "Footnote",
            prefix: footNote.footNoteIcon,
            primaryComponentType: "Visual",
            text: footNote.footNoteText,
            sourceId: addedAtom ? addedAtom._id : "",
            sourceType: addedAtom ? "Atom" : "External",
            sourceUrl: addedAtom ? addedAtom.document : "",
          };
        });

      const suppVisualAbbrList =
        row.rowData[3] &&
        row.rowData[3].data &&
        row.rowData[3].data.map((abbrivation) => {
          const addedAtom = matchingAtom(
            removeAllSuperscriptTags(abbrivation.abbrText)
          );
          return {
            componentType: "Abbreviation",
            prefix: abbrivation.title,
            primaryComponentType: "Visual",
            text: abbrivation.abbrText,
            sourceId: addedAtom ? addedAtom._id : "",
            sourceType: addedAtom ? "Atom" : "External",
            sourceUrl: addedAtom ? addedAtom.document : "",
            record_index: abbrivation.record_index,
          };
        });

      const referenceListVisual =
        row.rowData[4] &&
        row.rowData[4].data &&
        row.rowData[4].data.map((references) => {
          return {
            componentType: "Reference",
            prefix: references.refId || "",
            primaryComponentType: "Claim",
            text: references.veevaReferenceName,
            veevaReferenceId: references.id,
            veevaLinked: references.veevaLinked,
          };
        });

      const componentLinksVisual = [];

      row.rowData[4] &&
        row.rowData[4].data &&
        row.rowData[4].data.forEach((supportingReference) => {
          supportingReference.annotationList.forEach((annonation) => {
            let anchorComponentText = "";
            if (annonation.linkType === "supporting-visual") {
              anchorComponentText =
                row.rowData[1].data[annonation.compIndex].cpationText;
            } else {
              anchorComponentText =
                suppVisualFootNoteList[annonation.compIndex].text;
            }
            componentLinksVisual.push({
              referenceText: supportingReference.veevaReferenceName,
              //anchorText: annonation.annotText,
              linkType:
                annonation.linkType === "supporting-visual"
                  ? "Caption-Anchor"
                  : "Footnote-Anchor",
              anchorComponentText: anchorComponentText,
              page: annonation.pageNumber,
              column: annonation.column,
              paragraph: annonation.para,
              line: annonation.line,
              anchorLinkFlag: annonation.anchorLinkFlag,
            });
          });
        });

      let supportingVisualLinks;
      if (
        row.rowData[5] &&
        row.rowData[5].claim &&
        row.rowData[5].claim.length
      ) {
        supportingVisualLinks = {
          claim:
            (row.rowData[5] &&
              row.rowData[5].claim &&
              row.rowData[5].claim.map((linkedId, index) => {
                const supportingClaims = [...supportingClaimsAndVisuals].filter(
                  (el) => {
                    return el.type === "supportingClaim";
                  }
                );
                let claimText: "";
                if (primaryClaimId === linkedId) {
                  claimText = primaryClaim[0]?.claim;
                } else {
                  const supClaim = supportingClaims.find((claim) => {
                    return claim.rowData[4].id === linkedId;
                  });
                  if (supClaim) {
                    claimText = supClaim.rowData[0].data[index].atomText; //supClaim.rowData[0].data[0].atomText;
                  }
                }

                return {
                  moduleClaimId: linkedId,
                  claimText: claimText,
                };
              })) ||
            [],
          dependency: (row.rowData[5] && row.rowData[5].dependency) || "",
        };
      } else {
        supportingVisualLinks = null;
      }

      // const finalVisualCompLinks = [...componentLinksVisual].filter((comp) => {
      //   return comp.anchorText && comp.anchorText.trim().length > 0;
      // });
      // const idelines = suppVisualBussinessRules;

      const supportingVisual = {
        type: "Supporting",
        visualVariants: [...suppVisualList],
        record_index: row.record_index,
        supportingTextComponents: [
          ...suppVisualCaptionsList,
          ...suppVisualFootNoteList,
          ...suppVisualAbbrList,
          ...referenceListVisual,
        ],
        componentLinks:
          componentLinksVisual && componentLinksVisual.length > 0
            ? componentLinksVisual
            : undefined,

        visualLinks: supportingVisualLinks,
        isMustUse: row?.isMustUse,
        usageGuidelines: row?.usageGuidelines,
      };

      if (!supportingVisual.visualLinks) {
        delete supportingVisual.visualLinks;
      }

      return supportingVisual;
    });

    const addedReusableTexts = [...reusableTextAdded];
    const reusableTextDataPayload = [];

    const updatedReusableTextDataPayload = reusableTextDataPayload.concat(
      addedReusableTexts?.map((addedText, index) => {
        let reusableTextLabels = [];

        if (addedText.reusableTextType === "CTA") {
          if (
            addedText.reusableTextLabels &&
            addedText.reusableTextLabels.length > 0
          ) {
            reusableTextLabels = addedText.reusableTextLabels.map((label) => ({
              record_index: label.record_index,
              ctaLabel: label.ctaLabel.trim(),
            }));
          } else if (addedText.labels && addedText.labels.length > 0) {
            reusableTextLabels = addedText.labels.map((label, labelIndex) => ({
              record_index: labelIndex + 1,
              ctaLabel: label.trim(),
            }));
          }
        }
        return {
          moduleId: assetModuleId,
          record_index: addedText?.record_index,
          id: addedText.id,
          reusableTextId: addedText.reusableTextId,
          description: addedText.description,
          reusableTextLabels: reusableTextLabels,
          link: addedText.link,
          usageGuidelines: addedText.reusBussinessRules,
          isMustUse: addedText.reusMustUse,
          reusableTextType: addedText.reusableTextType,
        };
      })
    );
    const saveClaimsData = {
      id: assetModuleId,
      name: moduleName,
      pageNumber: selectedPageNumberForPrimaryClaim,
      moduleClaims: !formattedCoreClaim
        ? [...primaryClaims, ...supportingClaimsData]
        : [formattedCoreClaim, ...primaryClaims, ...supportingClaimsData],
      moduleCoreCategories:
        formattedCategory.length === 0 ? undefined : formattedCategory,
      moduleVisuals: [...supportingVisualData],
      moduleReusableTexts: [...updatedReusableTextDataPayload],
    };
    setEnableSave(false);
    saveClaims(assetModuleId, saveClaimsData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        claimsSaveErr(errCoponent);
      } else {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
        setSelectedBox(-1);
        setSelectedRow(-1);
        getModuledetails();
        callback && callback(true);
        setSuppVisualRefAdded(false);
        setSuppClaimRefAdded(false);
        setSuppVisualRefAdded(false);
        setSuppClaimRefAdded(false);
      }
      setEnableSave(true);
    });
  };

  const dialogMessage = () => {
    if (!isCaptionAdded) {
      return "The Caption field cannot be blank";
    }
    return "The Reference field cannot be blank";
  };
  const navigateToEnteredText = (event, value = undefined) => {
    if (event) {
      const parsedInt = parseInt(event.target.value);
      if (parsedInt && parsedInt > 0 && parsedInt <= filterdPages.length) {
        setSelectedPage(parsedInt - 1);
        event.target.value = parsedInt;
      }
    } else {
      const input = document.getElementById("claim-page-pageination-input");
      if (input) {
        input.value = value + 1;
      }
    }
  };

  //Reference and Anchors starts here
  const [shouldMakeSaveApiCallValue, setShouldMakeSaveApiCallValue] =
    useState(0); //Created to make save
  //claim family api to update the linked documents
  useEffect(() => {
    if (shouldMakeSaveApiCallValue > 0) {
      handlePageSave();
    }
  }, [shouldMakeSaveApiCallValue]);

  const getNewRefId = () => {
    const linkedDocuments = getLinkedDocumentsList();
    if (linkedDocuments.length > 0) {
      let refList = [...linkedDocuments].map((doc) => {
        return doc.refId;
      });
      refList = refList.map((ref) => {
        return parseInt(ref);
      });
      const maxRefId = Math.max(...refList);
      if (maxRefId && maxRefId > 0) {
        return maxRefId + 1;
      }
    }
    return 1;
  };

  const handleSelectedReference = (selectedReferences) => {
    const tempResult = [];
    selectedReferences.forEach((el) => {
      const obj = {
        id: el?.id,
        veevaReferenceName:
          moduleType === moduleTypes?.denovo ? el?.name : el?.name__v,
        refId: el.refId,
        annotationList: el.annotationList || [],
        veevaLinked: false,
      };
      tempResult.push(obj);
    });
    const refDocs = getLinkedDocumentsList();
    for (let i = 0, len = refDocs.length; i < len; i++) {
      for (let j = 0, len2 = tempResult.length; j < len2; j++) {
        if (refDocs[i].id === tempResult[j].id) {
          tempResult.splice(j, 1);
          len2 = tempResult.length;
        }
      }
    }
    const newArray = primaryReferenceAnchor.concat(tempResult);
    const data = [];
    newArray.forEach((el, index) => {
      let refId = getNewRefId();
      if (selectedReferences.length > 0) {
        selectedReferences.find((ref, index) => {
          if (ref.id === el.id) {
            refId = getNewRefId() + index;
          }
        });
      }
      const obj = {
        id: el.id,
        veevaReferenceName: el.veevaReferenceName,
        refId: el.refId || refId.toString(),
        annotationList: el.annotationList || [],
        veevaLinked: el.veevaLinked || false,
      };
      data.push(obj);
    });

    setShowModalAnchor(false);
    setPrimaryReferenceAnchor(data);
    setShouldMakeSaveApiCallValue(shouldMakeSaveApiCallValue + 1);
  };

  const updateSupportingClaimReference = (data) => {
    if (selectedSuppRow > -1 && selectedSuppBox > -1) {
      let supClaimsAndVisuals = [...supportingClaimsAndVisuals];
      const rowToBeUpdated = supClaimsAndVisuals[selectedSuppRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedSuppBox];
      boxToBeUpdated.data = [...data];
      //setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);

      setSupportingClaimsAndVisuals(supClaimsAndVisuals);
      let updatedrefs = [...getSupprortingClaimReferences].concat(data);
      // const finalListmergedArray = removeDuplicates(updatedrefs, "refId");
      // let filteredArray = removeDuplicateAnnotation(finalListmergedArray);
      setGetSupprortingClaimReferences(updatedrefs);
    }
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
    setShouldMakeSaveApiCallValue(shouldMakeSaveApiCallValue + 1);
  };

  const updateSupportingVisualReference = (data) => {
    if (selectedRow > -1 && selectedBox > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[selectedRow];
      const boxToBeUpdated =
        rowToBeUpdated && rowToBeUpdated.rowData[selectedBox];
      boxToBeUpdated.data = [...data];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);

      let updatedrefs = [...getSupprortingVisualReferences].concat(data);
      // const finalListmergedArray = removeDuplicates(updatedrefs, "refId");
      // let filteredArray = removeDuplicateAnnotation(finalListmergedArray);
      setGetSupprortingVisualReferences(updatedrefs);
    }
    setSelectedRow(-1);
    setSelectedBox(-1);
    setShouldMakeSaveApiCallValue(shouldMakeSaveApiCallValue + 1);
  };
  const getReferenceDocument = (docId) => {
    const docs = getLinkedDocumentsList();
    if (docs.length > 0) {
      const foundDoc = docs.find((doc) => doc.id === docId);
      if (foundDoc) {
        return foundDoc;
      }
    }
    return undefined;
  };

  const saveAnchorPrimaryClaim = (docId, anchorCoordinates, isLast) => {
    const temp1 = {
      pageNumber: anchorCoordinates.pageNumber,
      column: anchorCoordinates.column,
      para: anchorCoordinates.para,
      line: anchorCoordinates.line,
      anchorLinkFlag: anchorCoordinates.anchorLinkFlag,
      id: uuidv4(),
      linkType: anchorAddedIn ? anchorAddedIn.type : "",
      compIndex: anchorAddedIn ? anchorAddedIn.index : undefined,
    };

    let data = [...primaryReferenceAnchor];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    if (index > -1) {
      data[index].veevaLinked = true;
      if (addOrModifyanchorData) {
        const indexToBeModified = data[index].annotationList.findIndex((el) => {
          return (
            el.compIndex === temp1.compIndex && el.linkType === temp1.linkType
          );
        });
        if (indexToBeModified > -1) {
          data[index].annotationList[indexToBeModified] = temp1;
        }
      } else {
        data[index].annotationList.push(temp1);
      }

      data[index].annotationList = data[index].annotationList.filter(
        (coordinate, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.pageNumber === coordinate.pageNumber &&
              t.column === coordinate.column &&
              t.para === coordinate.para &&
              t.line === coordinate.line
          )
      );

      setReferenceNumber([
        {
          refId: data[index].refId,
          color: "r",
          veevaDocId: docId,
        },
      ]);
    } else {
      //Do linking and add anchor simultaneously
      let doc = getReferenceDocument(docId);
      if (!doc) {
        return;
      }
      doc["veevaLinked"] = true;
      doc["annotationList"] = [temp1];
      data = data.concat(doc);
      let result = [];
      let obj = data.find((o) => o.id === docId);
      obj.annotationList.forEach((annot) => {
        result.push({ refId: obj.refId, color: "r", veevaDocId: docId });
      });
      setReferenceNumber(result);
    }

    setPrimaryReferenceAnchor(data);
    if (isLast) {
      setShowModal(false);
    }

    setAddOrModifyAnchorData(undefined);
    setAnchorAddedIn(undefined);
  };

  //Return the consolidated list of all the linked reference documents
  const getLinkedDocumentsList = () => {
    let linkedDocs = primaryReferenceAnchor
      .concat(getSupprortingClaimReferences)
      .concat(getSupprortingVisualReferences);
    if (linkedDocs) {
      linkedDocs = [...linkedDocs].filter((doc) => doc); // remove undefined
      linkedDocs = [...linkedDocs].map((doc) => {
        return {
          id: doc.id,
          refId: doc.refId,
          veevaReferenceName: doc.veevaReferenceName,
        };
      });
      //remove the duplicates
      linkedDocs = linkedDocs.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      return linkedDocs;
    }
    return [];
  };

  const skipAnchorPrimaryClaim = (docId, anchorText, anchorAddedFrom) => {
    let data = [...primaryReferenceAnchor];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    //Check doc if already exist.
    if (index > -1) {
      data[index].veevaLinked = true;
      if (!anchorText) {
        let obj = data.find((o) => o.id === docId);
        let result = obj.annotationList;
        let itemPosition = result.findIndex(
          (obj, index) =>
            obj.compIndex === anchorAddedFrom.index &&
            obj.linkType === anchorAddedFrom.type
        );
        if (itemPosition > -1) {
          result.splice(itemPosition, 1);
        }
        data[index].annotationList = result;
        setReferenceNumber([
          { refId: data[index].refId, color: "r", veevaDocId: docId },
        ]);
      }
      // if (
      //   data[index].annotationList.length === 0 &&
      //   data[index].annotationList[0] !== undefined
      // ) {
      //   setReferenceNumber([
      //     {
      //       refId: data[index].refId,
      //       color:
      //         data[index].annotationList.length === 0 &&
      //         data[index].annotationList[0].annotText != ""
      //           ? "r"
      //           : "g",
      //       veevaDocId: docId,
      //     },
      //   ]);
      // } else {
      //   setReferenceNumber([
      //     { refId: data[index].refId, color: "r", veevaDocId: docId },
      //   ]);
      // }
      // setReferenceNumber([{ refId: data[index].refId,
      //                       color: data[index].annotationList.length === 0 && data[index].annotationList[0].annotText != ""  ? "r" : 'g',
      // veevaDocId : docId }]);
    } else {
      //Adding new document from global list.
      let doc = getReferenceDocument(docId);
      if (!doc) {
        return;
      }
      doc["veevaLinked"] = true;
      doc["annotationList"] = [];
      data = data.concat(doc);
      let obj = data.find((o) => o.id === docId);
      setReferenceNumber([{ refId: obj.refId, color: "r", veevaDocId: docId }]);
    }
    setPrimaryReferenceAnchor(data);
    setShowModal(false);
    setAddOrModifyAnchorData(undefined);
    setAnchorAddedIn(undefined);
  };

  const resetSupportingClaimAbbreviations = (data, rowIndex) => {
    if (rowIndex > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[2];
      boxToBeUpdated.data = [...data];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
  };

  const resetSupportingVisualAbbreviations = (data, rowIndex) => {
    if (rowIndex > -1) {
      const rowToBeUpdated = supportingClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[3];
      boxToBeUpdated.data = [...data];
      setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    }
  };

  const expandIconForAddressMLRFeedback = (type) => {
    return (
      <img
        src={expandIcon}
        alt=""
        className={`${
          type === "pdf" ? "mlr-feedback-expand-pdf" : "mlr-feedback-expand"
        }`}
        onClick={() => {
          if (type === "pdf") {
            setPdfcontentExpanded(!isPdfContentExpanded);
          } else {
            setMaincontentExpanded(!isMainContentExpanded);
          }
        }}
      />
    );
  };

  const showPersonaToneModal = () => {
    setPersonaModal(true);
  };
  const hidePersonaToneModal = () => {
    setPersonaModal(false);
  };
  const savePersonaToneData = async (selectedComponent, selectedIndex) => {
    setPersonaModal(false);
    handlePageSave();
  };
  const froalaRef = useRef(null);
  const isPrimaryClaimEmpty = () => {
    froalaRef.current.innerHTML =
      primaryClaim[primaryClaimSelectedIndex]?.claim;
    let claimText = froalaRef.current.innerText;
    if (claimText && claimText.trim().length > 0) {
      return false;
    }
    return true;
  };
  //Rendering VVPM Images
  const [vvpmImageTab, setVvpmImageTab] = useState([
    { tabName: "Harvester", isSelected: true },
    { tabName: "DAMS", isSelected: false },
  ]);
  const selectModuleTab = (tabIndex) => {
    const modules = [...vvpmImageTab];
    modules.forEach((item, index) => {
      if (index === tabIndex) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setVvpmImageTab(modules);
  };

  const getSelectedModuleTab = () => {
    return vvpmImageTab.find((tab) => tab.isSelected);
  };
  const [initialReusableTexts, setInitialReusableTexts] = useState([]);
  const getAllreusableTextList = () => {
    let searchPayload = {
      brandName: brandName,
    };
    setIsloading(true);
    addReusableTextList(searchPayload, (response, error, errCode) => {
      setIsloading(false);
      if (error) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      } else if (response && response.data) {
        const modifiedData = response.data.map((item) => {
          if (item.reusableTextType === "PreHeader") {
            return { ...item, reusableTextType: "Pre Header" };
          } else if (item.reusableTextType === "SubjectLine") {
            return { ...item, reusableTextType: "Subject Line" };
          }
          return item;
        });
        setInitialReusableTexts(modifiedData);
      }
    });
  };
  console.log("supportingClaimsAndVisuals", supportingClaimsAndVisuals);
  const handleShowReusableTextModal = async () => {
    setShowReusableTextModal(true);
    setReusableTextOption("");
    setSearchInput("");
    setSelectedReusableTextIds([]);
    await getAllreusableTextList();
  };
  const renderVVPMImagesForSupportingVisual = () => {
    return (
      shouldShowTheAtomsCol() &&
      filterType === "graphic" && (
        <Col className="col-12 col-md-3 pageAtomsCol">
          <div className="d-flex justify-content-between align-items-center">
            {/* <div className="atomsColTitle">
              {t("vvpm.title")}
              <span className="atomClick">{t("vvpm.subtitle")}</span>
            </div> */}
            <div className="atomsColTitle">
              Atoms
              {/* <span className="atomClick">Double click on atoms</span> */}
            </div>
            <div className="atomsColSubTitle">
              <span className="atomSubtTitle" style={{ fontWeight: "bold" }}>
                {atomsHeaderVisual}
              </span>
            </div>
          </div>
          <Row className="mb-3 ml-0 mr-0">
            <Col xs="12" md="12" lg="12" className="vvmp-tab">
              {vvpmImageTab &&
                vvpmImageTab.map((item, index) => {
                  let tabName;
                  if (
                    moduleType === moduleTypes.denovo &&
                    item.tabName === "Harvester"
                  ) {
                    return tabName;
                  } else {
                    tabName = item.tabName;
                  }
                  return (
                    <button
                      key={index}
                      className={`btn-harvest-dams ${
                        item.isSelected ? "btn-sel-module" : ""
                      }`}
                      onClick={() => {
                        selectModuleTab(index);
                      }}
                    >
                      {" "}
                      {tabName}
                    </button>
                  );
                })}
            </Col>
          </Row>
          {getSelectedModuleTab().tabName.toLowerCase() === "dams" && (
            <Row className="mt-4 align-items-center no-gutters">
              <Col md="12 pt-4 pr-3">
                <button
                  className="btn btn-block addClaimsBtn"
                  onClick={() => {
                    showImportVisualsModal();
                  }}
                >
                  {t("vvpm.importCTA")}
                </button>
              </Col>
            </Row>
          )}
          {getSelectedModuleTab().tabName.toLowerCase() === "dams" && (
            <div className="lefColImageDisplay custonScrollBar">
              {<ul>{renderImportedVVPMImages()}</ul>}
            </div>
          )}
          {getSelectedModuleTab().tabName.toLowerCase() === "harvester" && (
            <>
              {renderAtaomsonleftPane()}
              {semanticType()}
              <div className="lefColAtomsDisplay">
                <ul>{renderAtoms()}</ul>
              </div>
            </>
          )}
          {renderExpandedAssetModal()}
        </Col>
      )
    );
  };

  const semanticType = () => {
    let topCategories = [];
    if (filterdPages[selectedPage]) {
      topCategories = filterdPages[selectedPage].MetaData?.topMessageCategories;
    }

    return (
      <div className="row " style={{ marginTop: "24px" }}>
        <div className="col-md-4 col-semantic-type">
          <div className="semantic-type">
            {i18next.t("generalText.semanticType")}
          </div>
          <span className="atomSubtTitle atom-top-categories ">Visuals</span>
        </div>
        <div className="col-md-8">
          <div className="semantic-type">
            {i18next.t("coreClaim.coreClaimCategory")}
          </div>
          {topCategories.map((cat) => {
            return (
              <span className="atomSubtTitle atom-top-categories">
                {cat.messageCategory}
              </span>
            );
          })}
        </div>
      </div>
    );
  };
  const renderImportedVVPMImages = () => {
    return (
      <li
        onDoubleClick={onAtomDoubleClick}
        className="d-flex justify-content-center align-items-center"
        //key={atomeToUse._id}
      >
        <div className="asset-with-expand">
          <img
            //id={atomeToUse._id}
            //src={atomeToUse.document}
            className="mr-3"
            alt="VVPM Image"
          />
        </div>
      </li>
    );
  };

  const renderAtaomsonleftPane = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="">
          <button
            onClick={() => {
              if (selectedPage > 0) {
                setSelectedPage(selectedPage - 1);
              }
            }}
            className="btn btn-link m-0 p-0 atomBtnLeft"
            disabled={selectedPage === 0}
            style={{ opacity: `${selectedPage === 0 ? 0.4 : ""}` }}
          >
            <img src={arrowLeft} alt="Previous" className="opacity50" />
          </button>
          Page
          <input
            id="claim-page-pageination-input"
            type="text"
            className="paginationInput"
            onBlur={(e) => {
              navigateToEnteredText(e);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                navigateToEnteredText(e);
              }
            }}
            defaultValue={selectedPage + 1}
            // value={selectedPage + 1}
          />
          Of {filterdPages.length}{" "}
          <button
            onClick={() => {
              if (selectedPage < filterdPages.length - 1) {
                setSelectedPage(selectedPage + 1);
              }
            }}
            className="btn btn-link m-0 p-0 atomBtnRight"
            style={{
              opacity: `${selectedPage === filterdPages.length - 1 ? 0.4 : ""}`,
            }}
            disabled={selectedPage === filterdPages.length - 1}
          >
            <img src={arrowRight} alt="Next" className="opacity50" />
          </button>
        </div>
        <div>
          <button className="btn btn-link pd-0">
            <img src={filterIcon} alt="" />
          </button>
        </div>
      </div>
    );
  };
  const [importSupportingVisuals, setimportSupportingVisuals] = useState(false);
  const refs = () => {
    const refs = primaryReferenceAnchor
      .concat(getSupprortingClaimReferences)
      .concat(getSupprortingVisualReferences);

    return refs;
  };
  const showImportVisualsModal = () => {
    setimportSupportingVisuals(true);
  };
  const hideImportVisualsModal = () => {
    setimportSupportingVisuals(false);
  };
  return (
    <>
      <LogoutCTA />

      <div className="headeBgWhite">
        <HeaderCreateModule />

        {!isThisMLRFeedbackFlow() && (
          <CreateModuleNav
            handleGroupsPrcd={handleGroupsPrcd}
            currentPage={currentPage}
            data={formattedArrayGroup}
            moduleCode={moduleCode && moduleCode}
            moduleName={moduleName && moduleName}
            btnProceed={i18next.t("generalText.proceed")}
            btnClose={i18next.t("generalText.close")}
            btnSave={i18next.t("generalText.save")}
            disableProceed={
              !(
                primaryClaim &&
                primaryClaim.length > 0 &&
                primaryClaim[0] !== "" &&
                checkSelectCoreClaim()
              )
            }
            handleProceed={handleProceed}
            handlePageClose={handlePageClose}
            handlePageSave={handlePageSave}
            onEditName={onEditName}
            moduleStatus={mlrModuleStatus}
            refresh={getModuledetails}
            moduleId={assetModuleId}
            veevaDocId={veevaDocId}
            setMlrModuleStatus={setMlrModuleStatus}
            enableSave={enableSave}
            isNotDenovoModule={
              getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
              moduleTypes.denovo
            }
          />
        )}
        {isThisMLRFeedbackFlow() && (
          <MlrFeedbackNav
            module={module}
            disableDone={
              !(
                primaryClaim &&
                primaryClaim.length > 0 &&
                primaryClaim[0] !== "" &&
                checkSelectCoreClaim()
              )
            }
            currentPage={currentPage}
            onEditName={onEditName}
            handleDone={handleProceed}
            handlePageClose={handlePageClose}
          />
        )}
      </div>

      <Container
        fluid
        style={{
          paddingRight:
            isThisMLRFeedbackFlow() &&
            !isMainContentExpanded &&
            !isPdfContentExpanded
              ? "35px"
              : "",
        }}
      >
        <Row
          className="mt-3 ml-0 mr-0 mb-4"
          style={{
            flexWrap: isThisMLRFeedbackFlow() ? "nowrap" : "",
          }}
        >
          {shouldShowTheAtomsCol() && filterType !== "graphic" && (
            <Col className="col-12 col-md-3 pageAtomsCol">
              <div className="d-flex justify-content-between align-items-center">
                <div className="atomsColTitle">
                  Atoms <span className="atomClick">Double click on atoms</span>
                </div>
              </div>

              {renderAtaomsonleftPane()}
              <div className="atomsColSubTitle mt-3">
                <span className="atomSubtTitle">
                  {filterType === "graphic" ? atomsHeaderVisual : atomsHeader}
                </span>
              </div>
              <div className="lefColAtomsDisplay">
                <ul>{renderAtoms()}</ul>
              </div>
              {renderExpandedAssetModal()}
            </Col>
          )}
          {renderVVPMImagesForSupportingVisual()}
          <Col
            className={`pageContentCol ${
              isThisMLRFeedbackFlow()
                ? isMainContentExpanded
                  ? ""
                  : "col-12 col-md-7 add-mlr-fdbck-container"
                : ""
            }`}
            style={{
              display:
                isThisMLRFeedbackFlow() && isPdfContentExpanded ? "none" : "",
            }}
          >
            {isThisMLRFeedbackFlow() && expandIconForAddressMLRFeedback()}
            <div
              className={`${
                isThisMLRFeedbackFlow()
                  ? isMainContentExpanded
                    ? ""
                    : " add-mlr-fdbck-scrollbale custonScrollBar"
                  : ""
              }`}
              style={{ paddingTop: isThisMLRFeedbackFlow() ? "10px" : "" }}
            >
              {coreClaimsAndCategories}
              {renderSelectCoreClaimAlert()}

              <Row className="mt-4 align-items-center">
                <Col className="d-flex">
                  <img
                    src={arrowpolygonRight}
                    alt=""
                    className={
                      moduleType === moduleTypes.denovo
                        ? "arrowCurrentRow arrowresuabletext mr-1"
                        : "arrowCurrentRow  mr-1"
                    }
                  />
                  <span className="catLabelTitle">
                    {t("primaryClaim.name")}
                  </span>
                  {/* {moduleType === moduleTypes.denovo && (
                    <>
                      {" "}
                      <span className="catLabelType verticalLine">|</span>
                      <div className="disabled-musttoggle ">
                        Must Use
                        <Switch
                          className="filterRightMargin  reusabletextToggle mr-2"
                          inputProps={{ "aria-label": "primary checkbox" }}
                          checked={primaryMustUse}
                          onChange={handlePrimaryClaimMustuse}
                          //  disabled={sourceModuleToggle}
                        />
                      </div>
                    </>
                  )} */}
                </Col>
              </Row>
              <Row className="mt-4 align-items-center">
                <Col
                  id="primary-claim"
                  className="selectclaimBlock selectedClaimBlock"
                  onClick={onBoxSelected}
                >
                  <div className="d-none" ref={froalaRef}></div>
                  {primaryClaim.length > 0 && primaryClaim[0]?.claim !== "" ? (
                    <div className="editorActive-heading-pclaim d-block mt-2 mb-3">
                      <span style={{ opacity: "0.5" }}>
                        {t("primaryClaim.name")}
                      </span>
                      {!isPrimaryClaimEmpty() &&
                        !primaryClaim[primaryClaimSelectedIndex]?.persona &&
                        !primaryClaim[primaryClaimSelectedIndex]?.tone && (
                          <span className="persona-cta">
                            <p>Add</p>{" "}
                            <button
                              className="btn-persona"
                              onClick={() => showPersonaToneModal()}
                            >
                              Persona|Tone
                            </button>
                          </span>
                        )}
                    </div>
                  ) : (
                    <span className="selectClaimBoxText">
                      {t("primaryClaim.name")}
                    </span>
                  )}
                  <div className="supportingClaimEditor">
                    <PrimaryClaim
                      primaryClaim={primaryClaim}
                      onBoxSelected={onBoxSelected}
                      updatePrimaryClaim={updatePrimaryClaim}
                      footNoteIcon={iconForSuperScript}
                      setIconForSuperScript={setIconForSuperScript}
                      setReferenceNumber={setReferenceNumber}
                      referenceNumber={referenceNumber}
                      iconArray={iconArray}
                      setIconArray={setIconArray}
                      // shouldEnableEditing={
                      //   selectedCategories.length > 0 && !selectedClaim
                      //     ? false
                      //     : true
                      // }
                      shouldEnableEditing={true}
                      onTextSelected={onTextSelected}
                      selectedIndex={primaryClaimSelectedIndex}
                      onSuperScriptClick={onSuperScriptClick}
                      setAnchorAddedIn={setAnchorAddedIn}
                      showPersonaToneModal={showPersonaToneModal}
                      handlePageSave={handlePageSave}
                    />
                  </div>
                </Col>
                <Col
                  className="selectclaimRefBlock custonScrollBar"
                  id="primary-FootNote"
                >
                  {selectedFootNotes && selectedFootNotes.length !== 0 ? (
                    <span className="editorActive-heading d-block mt-2 mb-3">
                      Footnote / Disclaimer
                    </span>
                  ) : (
                    <span className="selectClaimBoxText">
                      Footnote / Disclaimer
                    </span>
                  )}
                  <div className="selectclaimBlockFoot custonScrollBar">
                    {selectedFootNotes &&
                      selectedFootNotes.map((footnote, index) => {
                        return (
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              setFootNoteIndex(index);
                            }}
                            onContextMenu={(e) => {
                              setFootNoteIndex(index);
                            }}
                          >
                            {
                              <div className="footBoxIndex">
                                <span className="d-inline-block iconDisplay">
                                  {footnote.footNoteIcon}
                                </span>
                              </div>
                            }
                            {
                              <FootNoteEditor
                                forwardedRef={(el) =>
                                  (froalaInstance.current[index] = el)
                                }
                                className="footNoteLabelN"
                                tag="textarea"
                                handleModelChange={(event) =>
                                  handleFootNoteTextChangeDirect(event, index)
                                }
                                abbriviationId={"abbreviationModal"}
                                selectedAtom={footnote.footNoteText}
                                //onSupClick={onFootnoteSupClick}
                              />
                            }
                          </div>
                        );
                      })}
                  </div>
                  <ContextMenu
                    handleAbbriviation={onBoxSelected}
                    handleCont={handleCont}
                    handleReference={onBoxSelected}
                    abbriviationId={"abbreviationModal"}
                    compClass={`primary-FootNote`}
                    disableClass={`disable-FootNote`}
                    referenceId={"referenceModal"}
                    selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
                    setRightClickTriggered={setRightClickTriggered}
                    contextMenuOptions={contextMenuOptions}
                    setContextMenuOptions={setContextMenuOptions}
                    onSuperScriptClick={onSuperScriptClick}
                  ></ContextMenu>
                </Col>
                <Col className="selectclaimRefBlock">
                  {/* <button className="btn btn-light" onClick={handleShow} /> */}
                  {/* <span className="selectClaimBoxText">Abbreviations</span> */}
                  {arrAbbr && arrAbbr.length !== 0 ? (
                    <span className="editorActive-heading d-block mt-2 mb-3">
                      Abbreviations
                    </span>
                  ) : (
                    <span className="selectClaimBoxText">Abbreviations</span>
                  )}
                  <div className="selectclaimBlockRef custonScrollBar">
                    {arrAbbr &&
                      arrAbbr.map((x) => (
                        <span>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#666666",
                              margin: "auto",
                              marginTop: "",
                            }}
                          >
                            {x.title && parse(x.title)}
                          </p>
                          <div style={{ paddingBottom: "5px" }}>
                            {x.data.abbrText && parse(x.data.abbrText)}
                          </div>
                        </span>
                      ))}
                  </div>
                </Col>
                <Col className="selectclaimRefBlock">
                  <div>
                    <p className="refStar">*</p>
                    {primaryReferenceAnchor &&
                    primaryReferenceAnchor.length !== 0 ? (
                      <span className="editorActive-heading d-block mt-2 mb-3">
                        References / Annotations
                      </span>
                    ) : (
                      <span className="selectClaimBoxText">
                        References / Annotations
                      </span>
                    )}
                  </div>
                  <div className="selectclaimBlockRef custonScrollBar">
                    {primaryReferenceAnchor &&
                      primaryReferenceAnchor.length > 0 &&
                      primaryReferenceAnchor
                        .sort(function (a, b) {
                          return a.refId - b.refId;
                        })
                        .map((x, index) => {
                          if (!x) {
                            return <></>;
                          }
                          let color = "black";
                          // x.annotationList.length != 0 &&
                          // x.veevaLinked === true
                          //   ? "green"
                          //   : "red";
                          return (
                            <div className="d-flex">
                              {x.annotationList.length !== 0 ||
                              x.veevaLinked === true ? (
                                <div className="referenceBoxIndex">
                                  <span
                                    className="d-inline-block refNumberDisplay"
                                    style={{ color: color }}
                                  >
                                    {x.refId && x.refId}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              {
                                <div className="referenceBoxLabelN">
                                  {x.veevaReferenceName &&
                                    parse(
                                      x.veevaReferenceName &&
                                        x.veevaReferenceName
                                    )}
                                  <span
                                    style={{
                                      color: "#666666",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    {x.annotationList &&
                                      x.annotationList.map((list, index) => (
                                        <div key={index}>
                                          Page no {list.pageNumber}/ Column{" "}
                                          {list.column}/ Para {list.para}/ Line{" "}
                                          {list.line}
                                        </div>
                                      ))}
                                  </span>
                                </div>
                              }
                            </div>
                          );
                        })}
                  </div>
                  {/* {renderSelectedRefs()} */}
                  <div
                    className=" float fa fa-plus my-float"
                    onClick={handleClickAnchor}
                  ></div>
                </Col>
                {moduleType === moduleTypes?.denovo && (
                  <Col
                    id="primaryClaim"
                    className="selectclaimBlock usageInformation col-md-2"
                  >
                    {/* {row && row[0]?.data?.length !== 0 && row[0]?.data !== "" ? ( */}
                    <span class="editorActive-heading d-block pt-2 mt-2 mb-3">
                      {t("reusableText.usageInstructions")}
                    </span>
                    <TextField
                      className="usageInfoinput"
                      id="moduleName"
                      variant="outlined"
                      fullWidth={true}
                      placeholder="Type here..."
                      //inputProps={{ style: { textAlign: "left", paddingTop: "2px" } }}
                      InputProps={{
                        className: "usageInfo",
                      }}
                      multiline
                      rows={2}
                      onChange={handleChange}
                      value={primaryBussinessRules}
                    />
                    {/* ) : (
            ""
          )} */}
                    <div className="supportingClaimEditor"></div>
                  </Col>
                )}
              </Row>

              {supportingClaimsAndVisuals?.map((row, index) => {
                if (row?.type === "supportingClaim") {
                  return (
                    <SupportingClaimRowToAdd
                      setSuperScriptDataForClaim={setSuperScriptDataForClaim}
                      super
                      setSupportingClaimsAndVisuals={
                        setSupportingClaimsAndVisuals
                      }
                      supportingClaimsAndVisuals={supportingClaimsAndVisuals}
                      brandName={module?.brandName}
                      getModuledetails={getModuledetails}
                      key={row.rowData[0].id}
                      row={row.rowData}
                      rowIndex={index}
                      supportingClaimPosition={findSupportingClaimOrVisualPosition(
                        "supportingclaim",
                        row
                      )}
                      findSupportingClaimOrVisualPosition={
                        findSupportingClaimOrVisualPosition
                      }
                      //addBoxContent={addBoxContent}
                      deleteSuppClaimRow={deleteSuppClaimRow}
                      setSelectedSuppBox={setSelectedSuppBox}
                      setSelectedSuppRow={setSelectedSuppRow}
                      masterAbbrs={globalAbbrList}
                      setFilterType={setFilterType}
                      setAtomsHeader={setAtomsHeader}
                      currentTextReplace={currentTextReplace}
                      setCurrentTextReplace={setCurrentTextReplace}
                      handleAtomTextChange={handleInputChange}
                      setDisplaySupportingAlert={setDisplaySupportingAlert}
                      setSuperScriptDataForVisual={setSuperScriptDataForVisual}
                      onBoxSelected={onBoxSelected}
                      selectedText={footNoteText}
                      suppClaimfootNoteText={suppClaimfootNoteText}
                      addFootNotesToMasterArray={addFootNotesToMasterArray}
                      addAbbrToMaster={addAbbrToMaster}
                      handleInputChangeFootNote={handleInputChangeFootNote}
                      textSelectedAbbr={selectedTextPrimaryClaimValue}
                      abbrStatsu={abbrStatsu}
                      handleAddRadioAbbr={addRadioAbbr}
                      addButtonStatusfromStep2={addBtnStatus}
                      referenceMasterList={referenceMasterList}
                      atomTextForRef={atomTextForRef}
                      saveInMasterList={saveInMasterList}
                      saveSuppRef={saveSuppRef}
                      footNoteDirectEditSave={footNoteDirectEditSave}
                      saveFootNoteRefSup={saveFootNoteRefSup}
                      resetReference={resetReference}
                      superScriptDataForClaim={superScriptDataForClaim}
                      resetFootNotes={resetFootNotes}
                      updateSupportingClaimReference={
                        updateSupportingClaimReference
                      }
                      setBgBlurForDenovo={setBgBlurForDenovo}
                      referenceDocuments={getLinkedDocumentsList()}
                      onSuperScriptClick={onSuperScriptClick}
                      setAnchorAddedIn={setAnchorAddedIn}
                      anchorAddedIn={anchorAddedIn}
                      displaySupportingAlert={displaySupportingAlert}
                      resetSupportingClaimAbbreviations={
                        resetSupportingClaimAbbreviations
                      }
                      referenceList={refs()}
                      maxAbbrRecordIndex={maxAbbrRecordIndex}
                      sourceType={
                        module?.creationMethodType?.toLowerCase() !==
                        moduleTypes.denovo
                          ? module?.assetDetails?.sourceType
                          : damsSources.veeva.name?.toLowerCase()
                      }
                      moduleType={moduleType}
                      //  addSupportingClaims={addSupportingClaims}
                    />
                  );
                } else if (row?.type === "supportingVisual") {
                  return (
                    <SupportingVisualRowToAdd
                      isMustUseValues={isMustUseValues}
                      setIsMustUseValues={setIsMustUseValues}
                      setSupportingClaimsAndVisuals={
                        setSupportingClaimsAndVisuals
                      }
                      supportingClaimsAndVisuals={supportingClaimsAndVisuals}
                      setSuppVisualBussinessRules={setSuppVisualBussinessRules}
                      suppVisualBussinessRules={suppVisualBussinessRules}
                      suppVisualMustUse={suppVisualMustUse}
                      setSuppVisualMustUse={setSuppVisualMustUse}
                      brandName={module?.brandName}
                      key={row.rowData[0].id}
                      row={row.rowData}
                      rowValue={row}
                      rowIndex={index}
                      supportingVisualPosition={findSupportingClaimOrVisualPosition(
                        "visual",
                        row
                      )}
                      findSupportingClaimOrVisualPosition={
                        findSupportingClaimOrVisualPosition
                      }
                      selectedDiv={selectedDiv}
                      setSelectedDiv={setSelectedDiv}
                      addImage={addImage}
                      deleteImage={deleteImage}
                      addBoxContent={addBoxContent}
                      setSelectedBox={setSelectedBox}
                      setSelectedRow={setSelectedRow}
                      setFilterType={setFilterType}
                      currentImageReplace={currentImageReplace}
                      SetCurrentImageReplace={SetCurrentImageReplace}
                      SetCurrentVisualTextReplace={SetCurrentVisualTextReplace}
                      SetBoxSeletedBoxType={SetBoxSeletedBoxType}
                      addCaptions={addCaptions}
                      deleteCaption={deleteCaption}
                      handleCptionTextChange={handleCptionTextChange}
                      // handleTranscriptTextChange={handleTranscriptTextChange}
                      setCurrentTransTextReplace={setCurrentTransTextReplace}
                      currentTransTextReplace={currentTransTextReplace}
                      deleteSuppVisualRow={deleteSuppVisualRow}
                      setIsDelete={setIsDelete}
                      setAtomsHeaderVisual={setAtomsHeaderVisual}
                      //setIsFreeze={setIsFreeze}
                      onBoxSelected={onBoxSelected}
                      handleAddRadioAbbr={addRadioAbbr}
                      abbrStatsu={abbrStatsu}
                      textSelectedAbbr={selectedTextPrimaryClaimValue}
                      selectedText={footNoteText}
                      addAbbrToMasterVisual={addAbbrToMasterVisual}
                      suppClaimfootNoteText={suppClaimfootNoteText}
                      masterAbbrs={globalAbbrList}
                      referenceMasterList={referenceMasterList}
                      atomTextForRef={atomTextForRef}
                      saveInMasterList={saveVisualRefInMasterList}
                      saveSuppVisualRef={saveSuppVisualRef}
                      addFootNotesToMasterArray={
                        addVisualFootNotesToMasterArray
                      }
                      footNoteDirectEditSaveVisual={
                        footNoteDirectEditSaveVisual
                      }
                      saveFootNoteRefSupVisual={saveFootNoteRefSupVisual}
                      resetReferenceVisual={resetReferenceVisual}
                      saveCaptionSUperScript={saveCaptionSUperScript}
                      superScriptDataForVisual={superScriptDataForVisual}
                      resetFootNotesVisual={resetFootNotesVisual}
                      moduleType={moduleType}
                      primaryClaim={
                        primaryClaim && primaryClaim.length > 0
                          ? { ...primaryClaim[0], id: primaryClaimId }
                          : undefined
                      }
                      supportingClaims={supportingClaimsAndVisuals.filter(
                        (el) => {
                          return el.type === "supportingClaim";
                        }
                      )}
                      updateVisualLinks={updateVisualLinks}
                      selectedRow={selectedRow}
                      updateSupportingVisualReference={
                        updateSupportingVisualReference
                      }
                      setBgBlurForDenovo={setBgBlurForDenovo}
                      referenceDocuments={getLinkedDocumentsList()}
                      onSuperScriptClick={onSuperScriptClick}
                      anchorAddedIn={anchorAddedIn}
                      setAnchorAddedIn={setAnchorAddedIn}
                      displaySupportingVisualAlert={
                        displaySupportingVisualAlert
                      }
                      resetSupportingVisualAbbreviations={
                        resetSupportingVisualAbbreviations
                      }
                      referenceList={refs()}
                      maxAbbrRecordIndex={maxAbbrRecordIndex}
                      sourceType={
                        module?.creationMethodType?.toLowerCase() !==
                        moduleTypes.denovo
                          ? module?.assetDetails?.sourceType
                          : damsSources.veeva.name?.toLowerCase()
                      }
                      getModuledetails={getModuledetails}
                    />
                  );
                }
              })}
              {moduleType === moduleTypes.denovo && (
                <ReusableTextrowToAdd
                  reusableTextOption={reusableTextOption}
                  getModuledetails={getModuledetails}
                  deleteReusabletextRow={deleteReusabletextRow}
                  // key={row.rowData[0].id}
                  // row={row.rowData}
                  // rowIndex={index}
                  selectedRowsDetails={selectedRowsDetails}
                  setSelectedRowsDetails={setSelectedRowsDetails}
                  setReusableTextAdded={setReusableTextAdded}
                  reusableTextAdded={reusableTextAdded}
                />
              )}
              <Row className="mt-4 align-items-center rowBrdrTop no-gutters">
                <Col md="3 pt-4 pr-3">
                  <button
                    className="btn btn-block addClaimsBtn"
                    disabled={addSupportingBtnDisable ? "" : "disable"}
                    onClick={() => {
                      addSuppClaimRow();
                    }}
                  >
                    {t("supportingClaim.addSupportingClaim")}
                  </button>
                </Col>

                <Col md="3 pt-4  pr-3">
                  <button
                    className="btn btn-block addClaimsBtn"
                    disabled={addSupportingBtnDisable ? "" : "disable"}
                    onClick={() => {
                      addVisualRow();
                    }}
                  >
                    {t("supportinVisuals.addVisual")}
                  </button>
                </Col>
                {moduleType === moduleTypes.denovo ? (
                  <Col md="3 pt-4">
                    <button
                      className="btn btn-block addClaimsBtn"
                      disabled={addSupportingBtnDisable ? "" : "disable"}
                      onClick={() => {
                        handleShowReusableTextModal();
                      }}
                    >
                      {t("reusableText.addReusabletext")}
                    </button>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Col>
          {isThisMLRFeedbackFlow() && (
            <Col
              ref={pdfElementRef}
              className={` ${
                isPdfContentExpanded
                  ? "col-md-12 add-mlr-fdbck-container-pdf"
                  : "col-12 col-md-5 add-mlr-fdbck-container-pdf"
              }`}
              style={{
                display: isMainContentExpanded ? "none" : "",
                paddingLeft: isPdfContentExpanded ? "0px" : "",
                backgroundColor: "white",
                marginLeft:
                  !isPdfContentExpanded && !isMainContentExpanded ? "15px" : "",
              }}
            >
              {expandIconForAddressMLRFeedback("pdf")}
              <div className="mlr-feedback-pdf add-mlr-pdf-scrollbale custonScrollBar">
                <RenderMlrFeedback pdfFileUrl={pdfUrl} pdfWidth={pdfwidth} />
              </div>
            </Col>
          )}
        </Row>
      </Container>

      {showModal ? (
        <ReferenceContainer
          handleClose={handleCloseRefModal}
          referenceList={primaryReferenceAnchor
            .concat(getSupprortingClaimReferences)
            .concat(getSupprortingVisualReferences)}
          id="primary-claim"
          saveAnchorPrimaryClaim={saveAnchorPrimaryClaim}
          referenceDocuments={getLinkedDocumentsList()}
          addOrModifyanchorData={addOrModifyanchorData} // this is how can we pass the document id inorder to directly go to pdf tron page
          skipAnchorPrimaryClaim={skipAnchorPrimaryClaim}
          anchorAddedIn={anchorAddedIn}
          sourceType={
            module?.creationMethodType?.toLowerCase() !== moduleTypes.denovo
              ? module?.assetDetails?.sourceType
              : damsSources.veeva.name?.toLowerCase()
          }
          findSupportingClaimOrVisualPosition={
            findSupportingClaimOrVisualPosition
          }
          moduleType={moduleType}
        />
      ) : (
        ""
      )}
      {showModalAnchor === true ? (
        <ReferenceAnchor
          getModuledetails={getModuledetails}
          handleClickAnchor={handleClickAnchor}
          handleCloseReferenceAnchor={handleCloseReferenceAnchor}
          setFilterType={setFilterType}
          reference={refText}
          brandName={module?.brandName}
          getModuledetails={getModuledetails}
          handleSelectedReference={handleSelectedReference}
          sourceType={
            module?.creationMethodType?.toLowerCase() !== moduleTypes.denovo
              ? module?.assetDetails?.sourceType
              : damsSources?.veeva?.name?.toLowerCase()
          }
          moduleType={moduleType}
        />
      ) : (
        ""
      )}

      {isAbbr === true ? (
        <Abbreviation
          handleCloseAbbreviation={handleCloseAbbreviation}
          selectedText={abbrNoteText}
          handleArrAbbr={handleArrAbbr}
          //arrRadioData={arrAbbr}
          arrRadioData={globalAbbrList}
          abbrStatsu={abbrStatsu}
          isAbbrDisable={isAbbrDisable}
          setIsAbbrDisable={setIsAbbrDisable}
          selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
        />
      ) : (
        ""
      )}
      <div className="d-none" ref={referencesValid}></div>
      {showFootNote ? (
        <FooteNotesModal
          handleFootNoteMOdalClose={handleFootNoteMOdalClose}
          handleFootNoteTextChange={handleFootNoteInputChange}
          selectedText={footNoteText}
          addBtnStatus={addBtnStatus}
          addIconStatus={addIconStatus}
          handleAddFootNote={handleAddFootNote}
          selectedFootNotes={selectedFootNotes}
          getIconValue={getIconValue}
          setIconValueToInitial={setIconValueToInitial}
          handleFootNoteCheckBox={handleFootNoteCheckBox}
          handleAddFootNoteCheckBox={handleAddFootNoteCheckBox}
          setAllToInitial={setAllToInitial}
        />
      ) : (
        " "
      )}
      {moduleType !== moduleTypes.denovo && (
        <CustomModal
          displayModal={displayProcedGroupsAlert}
          hideModal={handleGroupHideModal}
          title={"Group Confirmation"}
          size="sm"
          dilogClassName="alertModalSmall"
        >
          <p>
            You have not selected any supporting claim. If you proceed then you
            cannot apply supporting claim dependency. Do you want to proceed ?
          </p>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-link pl-0"
              onClick={handleGroupHideModal}
            >
              NO
            </button>
            <button className="btn btn-primary" onClick={handleGroupsPrcd}>
              YES
            </button>
          </div>
        </CustomModal>
      )}
      <CustomModal
        displayModal={displayReferenceAlert}
        hideModal={handleReferenceHideModal}
        title={"Group Confirmation"}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <p
          style={{
            textAlign: "center",
          }}
        >
          {dialogMessage()}
        </p>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-link pl-0"
            style={{
              marginLeft: "49%",
            }}
            onClick={handleReferenceHideModal}
          >
            OK
          </button>
        </div>
      </CustomModal>
      {(showModalAnchor || bgBlurForDenovo) &&
        moduleType === moduleTypes.denovo && (
          <div class="modal-backdrop fade show denovo-ref-modal"></div>
        )}
      {personaModal && (
        <CustomModal
          displayModal={personaModal}
          hideModal={hidePersonaToneModal}
          title={"Add persona | Tone"}
          size="lg"
          dilogClassName=""
        >
          <PersonaToneModal
            hidePersonaToneModal={hidePersonaToneModal}
            savePersonaToneData={savePersonaToneData}
            selectedComponent={primaryClaim[primaryClaimSelectedIndex]}
            selectedIndex={primaryClaimSelectedIndex}
          />
        </CustomModal>
      )}

      {importSupportingVisuals && (
        <CustomModal
          displayModal={importSupportingVisuals}
          hideModal={hideImportVisualsModal}
          title={"Search Visuals"}
          size="xl"
          dilogClassName=""
        >
          <ImportImagesExternalModal
            hideImportVisualsModal={hideImportVisualsModal}
          />
        </CustomModal>
      )}
      {showReusableTextModal && moduleType === moduleTypes.denovo ? (
        <CustomModal
          displayModal={showReusableTextModal}
          hideModal={hideReusableTextModal}
          title={""}
          size="lg"
          dilogClassName="reusableTextmodal"
          keyboard={false}
          enforceFocus={false}
        >
          <ReusableTextModal
            setSelectedReusableTextIds={setSelectedReusableTextIds}
            selectedReusableTextIds={selectedReusableTextIds}
            setFilteredReusableTexts={setFilteredReusableTexts}
            filteredReusableTexts={filteredReusableTexts}
            reusableTextAdded={reusableTextAdded}
            reusBussinessRules={reusBussinessRules}
            setReusBussinessRules={setReusBussinessRules}
            handleShowReusableTextModal={handleShowReusableTextModal}
            hideReusableTextModal={hideReusableTextModal}
            addedReusableTextIds={reusableTextAdded.map((reusableText) => {
              return reusableText.reusableTextId;
            })}
            // {module?.reusableTexts?.map((reusableText) => {
            //   return reusableText.reusableTextId;
            // })}
            addReusableTextrow={addReusableTextrow}
            selectedRowsDetails={selectedRowsDetails}
            setSelectedRowsDetails={setSelectedRowsDetails}
            reusableTextOption={reusableTextOption}
            setReusableTextOption={setReusableTextOption}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            brandName={brandName}
            initialReusableTexts={initialReusableTexts}
            isloading={isloading}
          />
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
}
export default CreateModulePrimaryClaim;
