import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import i18next from "i18next";

import BusinessRulesBox from "../../BusinessRules/BusinessRulesBox";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import FroalaEditor from "../../../Uitls/CommonFroalEditorComponent";

import "../../../styles/pages/TranslateBusinessRules.scss";

import translateIcon from "../../../images/translateIcon.svg";
import CustomModal from "../../customModal";
import TranslateClaimsGenericModal from "../TranslateClaims/TranslateClaimGenericModal";

const BusinessRules = ({
  businessRules,
  sourceModuleToggle,
  showOriginalToggle,
  usageInstructions,
  handleSave,
}) => {
  const frInstanceUsage = React.createRef();
  const boxData = [...businessRules];
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);
  const [showGenericTranslateModal, setShowGenericTranslateModal] =
    useState(false);

  let formattedDataMust = boxData.filter(function (item) {
    return item.condition === "MustExistWith";
  });

  let formattedDataInCompatible = boxData.filter(function (item) {
    return item.condition === "InCompatibleWith";
  });

  let formattedDataRelatedTo = boxData.filter(function (item) {
    return item.condition === "RelatedTo";
  });

  let formattedDataPrecedes = boxData.filter(function (item) {
    return item.condition === "Precedes";
  });

  let formattedDataSucceeds = boxData.filter(function (item) {
    return item.condition === "Succeeds";
  });

  const handleCloseGenericModal = () => {
    setShowGenericTranslateModal(false);
  };

  const intiateTranslation = () => {
    setDataInputForTranslation(() => {
      // setShowGenericTranslateModal(true);
    });
    setDataInputForTranslation([usageInstructions]);
  };

  return (
    <>
      <Row className="translate-b-rules-row">
        <BusinessRulesBox
          boxKey="MustExistWith"
          boxTitle="Must Exist With"
          data={formattedDataMust}
          businessRulesNote={[]}
          className="translate-b-rule-box"
          hideDeleteButton={true}
        />

        <BusinessRulesBox
          boxKey="InCompatibleWith"
          boxTitle="Cannot Exist With"
          data={formattedDataInCompatible}
          businessRulesNote={[]}
          className="translate-b-rule-box"
          hideDeleteButton={true}
        />

        <BusinessRulesBox
          boxKey="Precedes"
          boxTitle="Precedes"
          data={formattedDataPrecedes}
          businessRulesNote={[]}
          className="translate-b-rule-box"
          hideDeleteButton={true}
        />
      </Row>

      <Row className="translate-b-rules-row" style={{ marginTop: "20px" }}>
        <BusinessRulesBox
          boxKey="Succeeds"
          boxTitle="Succeeds"
          data={formattedDataSucceeds}
          businessRulesNote={[]}
          className="translate-b-rule-box"
          hideDeleteButton={true}
        />

        <BusinessRulesBox
          boxKey="RelatedTo"
          boxTitle="Related Modules"
          data={formattedDataRelatedTo}
          businessRulesNote={[]}
          className="translate-b-rule-box"
          hideDeleteButton={true}
        />
        <Col className="mb-3  col-lg-4 col-md-4 col-12 ">
          {usageInstructions && usageInstructions.note !== "" && (
            <span className="editor-label">
              {i18next.t("additionalInfo.usageOrGuidelines")}
            </span>
          )}
          <div
            id="oth-rules"
            className="editor-box bsrulexGuidelines translate-b-rule-box"
          >
            <FroalaEditor
              forwardedRef={frInstanceUsage}
              placeHolder={i18next.t("additionalInfo.usageOrGuidelines")}
              selectedAtom={usageInstructions?.note}
              editable="off"
            />
            <div className="floating-buttons">
              {!(sourceModuleToggle || showOriginalToggle) &&
                !hasUserLoggedInAsReviewer() && (
                  <div>
                    <button
                      className="btn btn-link translate-icon"
                      style={{ marginRight: "-12px", bottom: "0" }}
                      onClick={() => {
                        intiateTranslation();
                      }}
                    >
                      <img src={translateIcon} alt="Translate" />
                    </button>
                  </div>
                )}
              {/* {getCommentBubbleFor("primaryClaim")} */}
            </div>
          </div>
        </Col>
      </Row>
      <CustomModal
        displayModal={showGenericTranslateModal}
        hideModal={handleCloseGenericModal}
        title="Select the components you would like to translate"
        size="xl"
      >
        <TranslateClaimsGenericModal
          handleClose={handleCloseGenericModal}
          sourceLanguage={module.translatedFrom}
          targetLanguage={module.language}
          dataInput={dataInputForTranslation}
          dataType="usageInstructions"
          saveTranslated={handleSave}
        />
      </CustomModal>
    </>
  );
};

export default BusinessRules;
