import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import i18next from "i18next";

import "../../styles/components/AddComment.scss";

const AddComment = ({ selectedText, handleClose, saveComment, comment }) => {
  const [shouldDisableAddButton, setShouldDisableAddButton] = useState(true);
  const handleTextFieldChange = (event) => {
    if (event && event.target) {
      const text = event.target.value;
      if (text.trim().length === 0) {
        return setShouldDisableAddButton(true);
      }
    }
    return setShouldDisableAddButton(false);
  };
  useEffect(() => {
    if (comment && comment.comment) {
      let element = document.getElementById("add-comment-box");
      element.value = comment.comment;
      setShouldDisableAddButton(false);
    }
  }, [comment]);
  return (
    <div style={{ margin: "-16px" }}>
      <p className="add-comment-title">{i18next.t("comments.title")}</p>
      <div className="add-comment-title-divider"></div>
      <p className="add-comment-selected-text">{selectedText || ""}</p>
      <TextField
        className="add-comment-box"
        id="add-comment-box"
        label={i18next.t("comments.placeholder")}
        onChange={handleTextFieldChange}
        variant="outlined"
        fullWidth={true}
        rows={6}
        rowsMax={6}
        multiline
      />
      <div className="footer-button-abbr">
        <button
          type="button"
          className="btn btn-link"
          style={{ marginLeft: "30px" }}
          onClick={() => {
            handleClose();
          }}
        >
          CLOSE
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg"
          style={{ float: "right", marginRight: "30px", width: "157px" }}
          disabled={shouldDisableAddButton}
          onClick={() => {
            const element = document.getElementById("add-comment-box");
            if (element) {
              const text = element.value;
              saveComment(text);
            }
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddComment;
