import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import parse from "html-react-parser";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import { Col } from "react-bootstrap";
import deleteIcon from "../images/closeIcon.svg";
const SupportingGroup = (props) => {
  const { t } = useTranslation();
  const {
    row,
    rowIndex,
    setSelectedBox,
    setSelectedRow,
    handleDelete,
    handleOnChange,
    selectedRowIndex,
    selectedBoxIndex,
    masterToggle,
  } = props;
  const boxRow = [...row];
  const box1 = boxRow[0];
  const claimCount = box1.data && box1.data.length;

  return (
    <>
      <Col
        xs="12"
        md="4"
        lg="4"
        className="mb-3"
        onClick={() => {
          setSelectedBox(0);
          setSelectedRow(rowIndex);
        }}
      >
        <div
          className={
            selectedRowIndex === rowIndex && selectedBoxIndex === 0
              ? "addGroupingBox groupSelected"
              : "addGroupingBox"
          }
        >
          <p className="groupName">Group {rowIndex + 1}</p>
          <FormControl component="fieldset" className="addGroupRadio">
            <RadioGroup
              row
              name="groupOptMand"
              value={box1.type}
              onChange={(event) => handleOnChange(event, rowIndex)}
            >
              <FormControlLabel
                value="Optional"
                control={<Radio color="primary" style={{opacity:masterToggle ? ".5" :""}}/>}
                label="Optional"
                labelPlacement="end"
                className="pl-0 ml-0"
                disabled={masterToggle ?"disable":""} 
              />

              <FormControlLabel
                value="Mandatory"
                control={<Radio color="primary" />}
                label="Mandatory"
                labelPlacement="end"
                disabled={masterToggle ?"disable":""} 
              />
            </RadioGroup>
          </FormControl>
          <p className="supClaimCount">
            {claimCount && claimCount !== 0 ? claimCount : ""} Supporting Claim
          </p>
          <ul className="supClaimList groupListScroll custonScrollBar">
            {box1.data &&
              box1.data.map((item) => {
                return (
                  <li key={item.id} style={{ backgroundColor: box1.color }}>
                    <div className="groupAtomsList">{parse(item.claim)}</div>
                    <button
                      className="btn btn-link p-0 m-0 deleteGroupItem"
                      onClick={(e) => handleDelete(e, item, rowIndex)}
                    >
                      <img src={deleteIcon} alt="" width="32" />
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </Col>
    </>
  );
};

export default SupportingGroup;
