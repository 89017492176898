import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import visualImagePlaceholder from "../../images/visualSearhPlaceholder.png";
const ImportImagesExternalModal = (props) => {
  const { t } = useTranslation();
  const { hideImportVisualsModal } = props;

  const validationSchema = yup.object().shape({
    assetnameId: yup.string().required(""),
  });

  const placeHolderData = 10;

  return (
    <div className="veevaImageSearchModal">
      <div className="row">
        <div className="col-md-12 modalTitle">Import Image from Veeva</div>
      </div>

      <Formik
        initialValues={{}}
        validate=""
        onSubmit={(values, { resetForm }, initialValues) => {
          //Make API call
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <TextField
                  className="add-geninfo-textfield"
                  id="componentNameID"
                  label="Document Name / ID"
                  variant="outlined"
                  fullWidth={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <DropDownWithLabel
                  class="tf-and-drp-dwn-with-label-disabled"
                  options={{
                    name: "Document Type",
                    label: "Document Type",
                    //value:,
                    //items:
                    handleChange: handleChange,
                  }}
                />
              </div>
              <div className="col-md-4">
                <DropDownWithLabel
                  class="tf-and-drp-dwn-with-label-disabled"
                  options={{
                    name: "Document Sub-Type",
                    label: "Document Sub-Type",
                    //value:,
                    //items:
                    handleChange: handleChange,
                  }}
                />
              </div>
              <div className="col-md-4">
                <DropDownWithLabel
                  class="tf-and-drp-dwn-with-label-disabled"
                  options={{
                    name: "Document Classification",
                    label: "Document Classification",
                    //value:,
                    //items:
                    handleChange: handleChange,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <DropDownWithLabel
                  class="tf-and-drp-dwn-with-label-disabled"
                  options={{
                    name: "Market",
                    label: "Market",
                    //value:,
                    //items:
                    handleChange: handleChange,
                  }}
                />
              </div>
              <div className="col-md-4">
                <DropDownWithLabel
                  class="tf-and-drp-dwn-with-label-disabled"
                  options={{
                    name: "Campaign Name",
                    label: "Campaign Name",
                    //value:,
                    //items:
                    handleChange: handleChange,
                  }}
                />
              </div>
              <div className="col-md-4">
                <button
                  type="submit"
                  className="btn mt-2 btn-outline-primary text-uppercase btnsearchVeevaDoc"
                >
                  SEARCH
                </button>
                <button
                  type="submit"
                  className="btn mt-2 btn-link text-uppercase btnResetVeevaDoc"
                  disabled
                >
                  RESET
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="vvpmImageListBlock">
        <div className="row">
          <div className="col-md-12">
            <div className="searchResultsCountRow">
              <div className="searchResultsContent">
                Recently Viewed <span>(8)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="imgResultsScrollContiner customScrollBar">
          <div className="row">
            {[...Array(placeHolderData)].map((item, index) => (
              <div className="col-md-3" key={index}>
                <div className="d-flex align-items-center">
                  <div class="form-check mr-3 pl-0">
                    <input
                      class="form-check-input mt-0 ml-0"
                      type="checkbox"
                      value=""
                    />
                  </div>
                  <img
                    src={visualImagePlaceholder}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="vvpmImageListBlock">
        <div className="row align-items-center imgResultBtnBrdr">
          <div className="col-md-6 imgBtnPl">
            <button
              className="btn btn-link pl-0 mt-3"
              onClick={() => {
                hideImportVisualsModal();
              }}
            >
              {i18next.t("generalText.close")}
            </button>
          </div>
          <div className="col-md-6 text-right pt-3 imgBtnPr">
            <button className="btn btn-primary importBtn" onClick={() => {}}>
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ImportImagesExternalModal;
