import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomModal from "../customModal";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { getChannelGridIds } from "../../services/apis";

import "../../styles/components/ContentMatrixTab.scss";
import "../../styles/components/ChannelGridHome.scss";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { getMlrStatusIcon } from "../../Uitls/Util";

const ChannelGrid = (props) => {
  const { t } = useTranslation();
  const {
    modulesAvail,
    setChannelSelectionModal,
    setChannelGridSelectionModal,
    selectedAssetDetails,
    setactivePage,
    setIschannelGrid,
    setChannelGridToView,
  } = props;
  const [isloading, setIsloading] = useState(false);

  //For Content MAtrix Regeneration
  const [channelGrids, setChannelGrids] = useState([]);
  const [selectedChannelGridId, setChannelGridId] = useState("12345");
  const [channelGridIdList, setChannelGridIdList] = useState([]);
  const [masterChannelGridData, setMasterChannelGridData] = useState([]);
  const mlrStatusIcon = (mlrStatus) => {
    return (
      <img
        src={getMlrStatusIcon(mlrStatus)}
        alt=""
        style={{ marginBottom: "3px" }}
      ></img>
    );
  };

  useEffect(() => {
    getChannelGridData();
  }, [selectedAssetDetails]);

  const getChannelGridDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getChannelGridData = () => {
    setIsloading(true);
    getChannelGridIds(
      selectedAssetDetails.modularisedAssetId,
      (response, err, errCode) => {
        setIsloading(false);
        if (err) {
          const errCoponent = errComponentToRender(errCode);
          getChannelGridDetailsErr(errCoponent);
        } else {
          setChannelGrids(response.data);
          setMasterChannelGridData(response.data);
          setChannelGridIdList(
            response.data?.map((item) => {
              return { displayName: item.submissionDocId };
            })
          );
        }
      }
    );
  };

  const filterChannelGridBasedOnChannelId = (submissionDocumentId) => {
    let filteredGrid = masterChannelGridData;
    if (submissionDocumentId) {
      filteredGrid = [...masterChannelGridData].filter((item) => {
        return item.submissionDocId === submissionDocumentId;
      });
    }
    setChannelGrids(filteredGrid);
  };

  const downloadPdf = (url) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.download = "channelGrid";
    // Append to the DOM
    document.body.appendChild(anchor);
    // Trigger `click` event
    anchor.click();
    // Remove element from DOM
    document.body.removeChild(anchor);
  };
  return (
    <div className="col-md-12">
      <CircularProgressBar isloading={isloading} />
      <div>
        {modulesAvail && (
          <>
            <div className="channlegridtab">
              <div className="text-left drp-dwn-Submission-doc">
                <DropDownWithSearch
                  class="drp-dwn-Submission-doc"
                  options={{
                    value: selectedChannelGridId,
                    name: "Submission DocumentId",
                    label: "Submission Document ID",
                    items: channelGridIdList,
                    handleChange: (grid) => {
                      const value = grid?.displayName;
                      setChannelGridId(grid);
                      filterChannelGridBasedOnChannelId(value);
                    },
                  }}
                />
              </div>
              <div className=" text-right">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setChannelSelectionModal(true);
                    setChannelGridSelectionModal(true);
                  }}
                >
                  Create SUBMISSION DOCUMENT
                </button>
              </div>
            </div>
            <div className="Channel-grid-home customScrollBar">
              <table className="table-style">
                <tbody>
                  <tr className="header">
                    <td className="header">
                      Submission Document ID ({channelGrids.length})
                    </td>
                    <td className="header">MLR Status</td>
                    <td className="header">Actions</td>
                  </tr>
                  {channelGrids.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <span
                            className={
                              !item.gridMlrStatus
                                ? "mlr-notsubmitted-text "
                                : "tbl-row-txt"
                            }
                          >
                            <b>{item.submissionDocId} </b>
                          </span>{" "}
                          |{" "}
                          <button
                            className="btn btn-link p-0 m-0 "
                            onClick={() => {
                              setactivePage("7");
                              setIschannelGrid(true);
                              setChannelGridToView(item);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td
                          className="tbl-row-txt-mlr-status"
                          //style={{ width: "15%" }}
                        >
                          {mlrStatusIcon(item.gridMlrStatus)}
                          <span style={{ marginLeft: "6px" }}>
                            {item.gridMlrStatus || "Not Submitted"}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-link p-0 m-0"
                            onClick={() => downloadPdf(item.downloadUrl)}
                          >
                            Download
                          </button>{" "}
                          |{" "}
                          <button
                            className="btn btn-link p-0 m-0 channel-grid-publish btnDisableStaus"
                            disabled={
                              item.gridMlrStatus === "draft" ? true : false
                            }
                          >
                            {" "}
                            Publish
                          </button>{" "}
                          |{" "}
                          <button className="btn btn-link p-0 m-0 btnDisableStaus">
                            MLR feedback
                          </button>
                          |{" "}
                          <button className="btn btn-link p-0 m-0">
                            Re-generate
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChannelGrid;
