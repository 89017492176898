import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { damsSources, marketingMessageSourceType } from "../../Uitls/Util";

const MarketingMessagesFooter = (props) => {
  const { hadnleCreateNewMessage, damsSource, setDamsSource } = props;
  const damsOptions = Object.keys(damsSources).filter((source) => {
    return (
      damsSources[source]?.name?.toLowerCase() !==
      damsSources.aem.name.toLowerCase()
    );
  });

  const renderDamsOptions = () => {
    return (
      <div className="rb-dams-options">
        <p className="lb-dams-source">DAMS</p>
        {damsOptions.map((damsOption) => {
          return (
            <div className="rb-search-option">
              <input
                id="damsOption"
                type="radio"
                name="damsOption"
                className="form-check-input"
                disabled={
                  damsSources.aprimo.name.toLowerCase() ===
                  damsSources[damsOption].name.toLowerCase()
                }
                checked={
                  damsSource?.toLowerCase() ===
                  damsSources[damsOption].name.toLowerCase()
                }
                onChange={() => {
                  setDamsSource(damsSources[damsOption].name);
                }}
              />
              <label htmlFor="damsOption" className="form-check-label">
                {damsSources[damsOption].name.toUpperCase()}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="row mmBrdrTop align-items-center">
        <div className="col-md-3">
          <button
            type="button"
            className="btn btn-link text-uppercase marketingMessagesBtnLink"
            disabled
          >
            View Associated Modules
          </button>
        </div>
        <div className="col-md-9 text-right newMessageBtn">
          <div style={{ float: "right", display: "flex" }}>
            {/* <button
              type="button"
              className="btn btn-link text-uppercase marketingMessagesBtnLink"
              disabled
            >
              Submit All DRAFTs to MLR
            </button> */}
            <div>{renderDamsOptions()}</div>

            <div>
              <DropdownButton
                id="dropdown-basic-button"
                title="New Attribute"
                className="new-message-button"
                style={{ display: "inline" }}
                disabled={props.isThisModuleLocked}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() =>
                    hadnleCreateNewMessage(
                      marketingMessageSourceType.denovo.name
                    )
                  }
                >
                  Create De-nuovo
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  /// onClick={() => hadnleCreateNewMessage("asset")}
                >
                  Create from Asset + component
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingMessagesFooter;
