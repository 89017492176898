import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "../../styles/components/ExistingTatics.scss";
import { routes } from "../../Uitls/Util";
import { useHistory } from "react-router-dom";

const ModuleCard = ({
  module,
  colWidth = 6,
  selectModule,
  isSelected,
  isThisModuleSelected,
  cannotExistWithOtherModules,
  localizeModule,
  modifyModule,
  translateModule,
  isSelectable,
  groupInfo,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getCardCssClass = () => {
    if (isTranslationInProgress()) {
      return "currentModuleTactics disabled-card-cta";
    }
    if (isThisModuleSelected) {
      if (cannotExistWithOtherModules) {
        return "currentModuleTactics denovo-module-card-selected-parent localized-module-card-error ";
      }
      return "currentModuleTactics denovo-module-card-selected-parent denovo-module-card-selected";
    } else {
      return "currentModuleTactics";
    }
  };

  const viewModule = () => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: module.moduleId,
      },
    });
  };

  const isTranslationInProgress = () => {
    if (
      module &&
      module.moduleType.toLowerCase() === "translated" &&
      module.translationStatus.toLowerCase() !== "complete"
    ) {
      return true;
    }
    return false;
  };

  const getTranslationStatus = () => {
    if (module && module.moduleType.toLowerCase() === "translated") {
      let status = module.reviewerStatus;
      let className = "";
      if (module.translationStatus.toLowerCase() !== "complete") {
        className = className.concat("translation-in-progress");
      }
      return (
        <div className={`translation-status-on-card ${className}`}>
          <p>{status}</p>
        </div>
      );
    }
  };

  const renderModuleCard = () => {
    return (
      <div
        className={`col-md-${colWidth}`}
        key={module.id}
        style={{ cursor: "pointer" }}
      >
        <div
          id={`${module.moduleCode}`}
          className={getCardCssClass()}
          onClick={(event) => {
            if (!isSelectable || isTranslationInProgress()) {
              return;
            }
            selectModule(module);
          }}
        >
          <div className="d-flex justify-content-between moduleParentDiv">
            <div className="localize-module-code-box">
              <p>{module.moduleCode}</p>
            </div>
            {getTranslationStatus()}
            {cannotExistWithOtherModules && (
              <div className="module-cannot-exist">
                Cannot Exist <InfoOutlinedIcon color="error" />
              </div>
            )}
          </div>
          <p className="modNameTitle mt-1 mb-0">Campaign Name</p>
          <p className="modNameText modCampName">{module.campaign}</p>
          <p className="modNameTitle mb-0">Module Name</p>
          <p className="modNameText modNameTextTitle">{module.moduleName}</p>
          <p className="modCategoryText">
            {module.coreCategories &&
              module.coreCategories.map((item) => <span>{item}</span>)}
          </p>

          <p className="modNameTitle mb-0">{t("primaryClaim.name")}</p>
          <div className="modNameText mb-0">
            {module.promotionalClaim && parse(module.promotionalClaim)}
          </div>

          <div className="moduleDetailsBrdr">
            <div className="d-flex justify-content-between moduleDetailsBtnRow">
              <button
                className={`btn btn-link btnApprove pl-0 moduleActionBtn ${
                  isTranslationInProgress() ? "disabled-card-cta" : ""
                }`}
                onClick={(e) => {
                  if (isTranslationInProgress()) {
                    return;
                  }
                  e.stopPropagation();
                  viewModule();
                }}
              >
                {module.groupModulesVeevaPublishFlag ? "View/Publish" : "View"}
              </button>
              <span className="d-flex pr-1 verticalLine">|</span>
              <button
                className={`btn btn-link btnApprove pl-2 moduleActionBtn ${
                  isTranslationInProgress() ? "disabled-card-cta" : ""
                }`}
                onClick={(e) => {
                  if (isTranslationInProgress()) {
                    return;
                  }
                  e.stopPropagation();
                  modifyModule(module);
                }}
              >
                Modify
              </button>

              <span className="d-flex pr-1 verticalLine">|</span>

              {localizeModule && (
                <button
                  className={`btn btn-link btnApprove pr-0 moduleActionBtn ${
                    isTranslationInProgress() ? "disabled-card-cta" : ""
                  }`}
                  onClick={(e) => {
                    if (isTranslationInProgress()) {
                      return;
                    }
                    e.stopPropagation();
                    localizeModule(module.moduleId);
                  }}
                >
                  Localize
                </button>
              )}
              {translateModule && (
                <button
                  className={`btn btn-link btnApprove pr-0 moduleActionBtn ${
                    isTranslationInProgress() ? "disabled-card-cta" : ""
                  }`}
                  onClick={(e) => {
                    if (isTranslationInProgress()) {
                      return;
                    }
                    e.stopPropagation();
                    translateModule(
                      {
                        moduleId: module.moduleId,
                        sourceModuleId: module.sourceModuleId,
                        language: module.language,
                      },
                      groupInfo
                    );
                  }}
                >
                  Translate
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderModuleCard()}</>;
};

export default ModuleCard;
