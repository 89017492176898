import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

//Import custom components
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import {
  channelTypesAndAttributes,
  getChannelAttributes,
  marketingMessageSourceType,
  globalAttributes,
  damsSources,
} from "../../Uitls/Util";
//Import apis
import {
  searchChannelGridMarketingMessages,
  getBrandsAndCampaigns,
} from "../../services/apis";
import SelectableMarketingMessages from "./SelectableMarketingMessages";

import backButton from "../../images/chevron-left-matrix.svg";
import infoIcon from "../../images/infoIcon.svg";
import CreateNewMarketingMessage from "../MarketingMessages/CreateNewMarketingMessage";
import CustomModal from "../customModal";

import "../../styles/components/ChannelGrid.scss";

const MarketingMessageSelection = ({
  brand,
  campaignName,
  channelType,
  sourceType,
  backToPrviousScreen,
  setSelectedMMessages,
  selectedMMessges,
  cancelCreatingGrid,
  handleProceed,
  channelGridStepCount,
  stepNumber,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchedResult, setSearchedResult] = useState(undefined);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [marketingMessages, setMarketingMessages] = useState(undefined);
  const msgSources = [
    marketingMessageSourceType[sourceType].name,
    marketingMessageSourceType.moduleManager.name,
  ];
  const [selectedMsgSource, setSelectedMsgSource] = useState(
    marketingMessageSourceType.moduleManager.name
  );
  const [selectedMarketingMessges, setSelectedMarketingMessages] =
    useState(undefined);
  const [filteredMarketingMessages, setFilteredMarketingMessages] =
    useState(undefined);
  const [isAllSubjectLineChecked, setIsAllSubjectLineChecked] = useState(false);
  const [isAllPreHeaderChecked, setIsAllPreHeaderChecked] = useState(false);
  const [isAllCtasChecked, setIsAllCtasChecked] = useState(false);
  const [isAllOptoutsChecked, setIsAllOptoutsChecked] = useState(false);

  //Create Markeing Message
  const [openCreateMarketMessageModal, setOpenCreateMarketMessageModal] =
    useState(undefined);
  const [brandsAndCampaignsData, setBrandsAndCampaignsData] = useState({});
  const [productList, setProductList] = useState([]);
  const [damsSource, setDamsSource] = useState(damsSources.veeva.name);
  const [marketingMsgToBeEdited, setMarketingMsgToBeEdited] =
    useState(undefined);
  const [marketingMsgTypeToBeCreated, setMarketingMsgTypeToBeCreated] =
    useState(undefined);
  const openCreateNewMessageModal = (type) => {
    setMarketingMsgTypeToBeCreated(type);
    setOpenCreateMarketMessageModal(type);
  };

  useEffect(() => {
    if ((brand, campaignName, channelType)) {
      search();
    }
  }, [brand, campaignName, channelType]);
  //console.log("selectedMarketingMessges", selectedMarketingMessges);
  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getOverlappingElements = (firstArray, secondArray) => {
    var filteredArray = firstArray.filter(function (el1) {
      return secondArray.some((id) => {
        return el1.id === id;
      });
    });
    return filteredArray;
  };

  const setupDefaultSelected = async (
    marketingMessages,
    newlyCreatedAttributeIds
  ) => {
    if (selectedMMessges && selectedMMessges && !marketingMsgToBeEdited) {
      const messages = await JSON.parse(JSON.stringify(selectedMMessges));
      if (
        channelType.trim() === channelTypesAndAttributes.email.name &&
        newlyCreatedAttributeIds !== undefined
      ) {
        messages.subjectLines = messages?.subjectLines?.concat(
          getOverlappingElements(
            marketingMessages.marketingMsgChannels.subjectLines,
            newlyCreatedAttributeIds.subjectLineIds
          )
        );
        messages.preHeaders = messages?.preHeaders?.concat(
          getOverlappingElements(
            marketingMessages.marketingMsgChannels?.preHeaders,
            newlyCreatedAttributeIds.preHeaderIds
          )
        );
        messages.optOuts = messages?.optOuts?.concat(
          getOverlappingElements(
            marketingMessages.marketingMsgChannels.optOuts,
            newlyCreatedAttributeIds.optoutIds
          )
        );
        messages.ctas = messages?.ctas?.concat(
          getOverlappingElements(
            marketingMessages.ctas,
            newlyCreatedAttributeIds.ctaIds
          )
        );
      }
      setSelectedMMessages(messages);
      setSelectedMarketingMessages(messages);
      return;
    }
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let optouts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    let tagLines = marketingMessages?.marketingMsgChannels?.tagLines || [];
    let ctas = marketingMessages?.ctas || [];
    if (marketingMsgToBeEdited) {
      subjectLines = getOverlappingElements(
        subjectLines,
        selectedMMessges.subjectLines.map((el) => el.id)
      );
      preheaders = getOverlappingElements(
        preheaders,
        selectedMMessges.preHeaders.map((el) => el.id)
      );
      optouts = getOverlappingElements(
        optouts,
        selectedMMessges.optOuts.map((el) => el.id)
      );
      // tagLines = getOverlappingElements(
      //   tagLines,
      //   selectedMMessges.subjectLines.map((el) => el.id)
      // );
      ctas = getOverlappingElements(
        ctas,
        selectedMMessges.ctas.map((el) => el.id)
      );
    }
    if (channelType.trim() === channelTypesAndAttributes.email.name) {
      setSelectedMarketingMessages({
        subjectLines: subjectLines,
        preHeaders: preheaders,
        optOuts: optouts,
        ctas: ctas,
      });
      setSelectedMMessages({
        subjectLines: subjectLines,
        preHeaders: preheaders,
        optOuts: optouts,
        ctas: ctas,
      });
    } else {
      setSelectedMarketingMessages({
        tagLines: tagLines,
        optOuts: optouts,
      });
      setSelectedMMessages({
        tagLines: tagLines,
        optOuts: optouts,
      });
    }
    if (marketingMsgToBeEdited) {
      startEditingMessage(undefined, false);
    }
  };

  const search = (obj) => {
    const payload = {
      brand: brand,
      campaign: campaignName,
      channelType: channelType,
      searchText: "",
      channelSource: selectedMsgSource,
    };
    setIsloading(true);
    searchChannelGridMarketingMessages(
      payload,
      "grid",
      (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          showError(errCoponent);
          // callback(undefined, err);
          setIsloading(false);
        } else {
          setIsloading(false);
          const data = response.data;
          prepareData(data, obj);
          if (openCreateMarketMessageModal) {
            setOpenCreateMarketMessageModal(undefined);
          }
        }
      }
    );
  };

  const prepareData = (data, newlyCreatedAttributeIds) => {
    setMarketingMessages(data);
    setupDefaultSelected(data, newlyCreatedAttributeIds);

    if (searchText.trim().length > 0) {
      filterByText(searchText, data);
    } else {
      setFilteredMarketingMessages(data);
    }
  };

  const onSelectMarketingMessage = (marketingMessage, selected) => {
    let data = JSON.parse(JSON.stringify(selectedMarketingMessges));
    let subjectLines = data?.subjectLines || [];
    let preheaders = data?.preHeaders || [];
    let tagLines = data?.tagLines || [];
    let ctas = data?.ctas || [];
    let optOuts = data?.optOuts || [];
    const attribute = marketingMessage.attribute.toLowerCase();
    const channelAttributes = getChannelAttributes(channelType.toLowerCase());
    if (attribute === channelAttributes?.subjectLine?.toLowerCase()) {
      const index = subjectLines.findIndex((subjectLine) => {
        return subjectLine.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        subjectLines = subjectLines.concat(marketingMessage);
      } else if (index > -1) {
        subjectLines = subjectLines.filter((subjectline) => {
          return subjectline.id !== marketingMessage.id;
        });
      }
      data.subjectLines = subjectLines;
    }
    if (attribute === channelAttributes?.preheader?.toLowerCase()) {
      const index = preheaders.findIndex((preheader) => {
        return preheader.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        preheaders = preheaders.concat(marketingMessage);
      } else if (index > -1) {
        preheaders = preheaders.filter((preheader) => {
          return preheader.id !== marketingMessage.id;
        });
      }
      data.preHeaders = preheaders;
    }
    if (attribute === channelAttributes?.tagLine?.toLowerCase()) {
      const index = tagLines.findIndex((tagLine) => {
        return tagLine.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        tagLines = tagLines.concat(marketingMessage);
      } else if (index > -1) {
        tagLines = tagLines.filter((tagLine) => {
          return tagLine.id !== marketingMessage.id;
        });
      }
      data.tagLines = tagLines;
    }
    if (attribute === channelAttributes?.optOut?.toLowerCase()) {
      const index = optOuts.findIndex((optout) => {
        return optout.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        optOuts = optOuts.concat(marketingMessage);
      } else if (index > -1) {
        optOuts = optOuts.filter((optout) => {
          return optout.id !== marketingMessage.id;
        });
      }
      data.optOuts = optOuts;
    }
    if (attribute === globalAttributes.cta.toLowerCase()) {
      const index = ctas.findIndex((cta) => {
        return cta.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        ctas = ctas.concat(marketingMessage);
      } else if (index > -1) {
        ctas = ctas.filter((cta) => {
          return cta.id !== marketingMessage.id;
        });
      }
      data.ctas = ctas;
    }
    setSelectedMarketingMessages(data);
    setSelectedMMessages(data);
  };

  const shouldProceedButtonBeEnabled = () => {
    if (searchText.trim().length > 0) {
      return false;
    }
    let subjectLines = selectedMarketingMessges?.subjectLines || [];
    let preheaders = selectedMarketingMessges?.preHeaders || [];
    let tagLines = selectedMarketingMessges?.tagLines || [];
    let optOuts = selectedMarketingMessges?.optOuts || [];
    let ctas = selectedMarketingMessges?.ctas || [];
    if (channelType?.trim() === channelTypesAndAttributes.email.name) {
      if (
        subjectLines.length > 0 &&
        preheaders.length > 0 &&
        optOuts.length > 0 &&
        ctas.length > 0
      ) {
        return true;
      }
      return false;
    } else {
      if (tagLines.length > 0 && optOuts.length > 0) {
        return true;
      }
      return false;
    }
  };

  const filterByText = (text = searchText, messages = marketingMessages) => {
    let data = JSON.parse(JSON.stringify(messages)); //Object.assign({}, marketingMessages);
    let subjectLines = data?.marketingMsgChannels?.subjectLines;
    let preHeaders = data?.marketingMsgChannels?.preHeaders;
    let tagLines = data?.marketingMsgChannels?.tagLines;
    let optOuts = data?.marketingMsgChannels?.optOuts;
    let ctas = data?.ctas || [];
    const searchParam = text.toLowerCase();
    subjectLines = subjectLines.filter((subjectLine) => {
      return (
        subjectLine.id.toLowerCase().includes(searchParam) ||
        subjectLine.text.toLowerCase().includes(searchParam)
      );
    });
    preHeaders = preHeaders.filter((preheader) => {
      return (
        preheader.id.toLowerCase().includes(searchParam) ||
        preheader.text.toLowerCase().includes(searchParam)
      );
    });
    tagLines = tagLines.filter((tagLine) => {
      return (
        tagLine.id.toLowerCase().includes(searchParam) ||
        tagLine.text.toLowerCase().includes(searchParam)
      );
    });
    optOuts = optOuts.filter((optOut) => {
      return (
        optOut.id.toLowerCase().includes(searchParam) ||
        optOut.text.toLowerCase().includes(searchParam)
      );
    });
    ctas = ctas.filter((cta) => {
      const data = JSON.stringify(cta.labels).toLowerCase();
      return (
        cta.id.toLowerCase().includes(searchParam) ||
        data.toLowerCase().includes(searchParam)
      );
    });
    data.marketingMsgChannels.subjectLines = subjectLines;
    data.marketingMsgChannels.preHeaders = preHeaders;
    data.marketingMsgChannels.tagLines = tagLines;
    data.marketingMsgChannels.optOuts = optOuts;
    data.ctas = ctas;
    setFilteredMarketingMessages(data);
    setSearchedResult("searched");
    setSearchTriggered(true);
  };
  const resetModuleList = () => {
    setSearchText("");
    setFilteredMarketingMessages(marketingMessages);
    setSearchedResult(undefined);
    setSearchTriggered(false);
  };
  useEffect(() => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let ctas = marketingMessages?.ctas || [];
    let optOuts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    if (selectedMarketingMessges?.subjectLines.length === subjectLines.length) {
      setIsAllSubjectLineChecked(true);
    } else {
      setIsAllSubjectLineChecked(false);
    }
    if (selectedMarketingMessges?.preHeaders.length === preheaders.length) {
      setIsAllPreHeaderChecked(true);
    } else {
      setIsAllPreHeaderChecked(false);
    }
    if (selectedMarketingMessges?.ctas.length === ctas.length) {
      setIsAllCtasChecked(true);
    } else {
      setIsAllCtasChecked(false);
    }
    if (selectedMarketingMessges?.optOuts.length === optOuts.length) {
      setIsAllOptoutsChecked(true);
    } else {
      setIsAllOptoutsChecked(false);
    }
  }, [selectedMarketingMessges, marketingMessages]);

  const handleSelectAll = (event, type) => {
    let checked = event.target.checked;
    let data = JSON.parse(JSON.stringify(selectedMarketingMessges));
    if (type === "subjectline") {
      if (checked) {
        let subjectLines =
          marketingMessages?.marketingMsgChannels?.subjectLines || [];
        data.subjectLines = subjectLines;
      } else {
        data.subjectLines = [];
      }
    } else if (type === "cta") {
      if (checked) {
        let ctas = marketingMessages?.ctas || [];
        data.ctas = ctas;
      } else {
        data.ctas = [];
      }
    } else if (type === "optout") {
      if (checked) {
        let optOuts = marketingMessages?.marketingMsgChannels.optOuts || [];
        data.optOuts = optOuts;
      } else {
        data.optOuts = [];
      }
    } else {
      if (checked) {
        let preheaders =
          marketingMessages?.marketingMsgChannels?.preHeaders || [];
        data.preHeaders = preheaders;
      } else {
        data.preHeaders = [];
      }
    }
    setSelectedMarketingMessages(data);
    setSelectedMMessages(data);
  };

  const hadnleCreateNewMessage = (type) => {
    setMarketingMsgTypeToBeCreated(type);
    setOpenCreateMarketMessageModal(true);
  };
  // __________________edit marketing message____________
  const startEditingMessage = (marketingMessage, editFlag = true) => {
    setMarketingMsgToBeEdited(() => {
      openCreateNewMessageModal(editFlag);
    });
    setMarketingMsgToBeEdited(marketingMessage);
  };

  const updateEditedRecord = (updatedData) => {
    let messages = JSON.parse(JSON.stringify(marketingMessages));
  };

  const hideopenCreateNewMessageModal = (
    shouldRefresh,
    selectedProduct,
    selectedCampaign,
    selectedChannelType,
    selectedChannelAttribute,
    newlyCreatedAttributeIds,
    updatedData
  ) => {
    // if (updatedData && marketingMsgToBeEdited) {
    //   updateEditedRecord(updatedData);
    //   setOpenCreateMarketMessageModal(undefined);
    //   return;
    // }
    if (!newlyCreatedAttributeIds) {
      setOpenCreateMarketMessageModal(undefined);
      if (marketingMsgToBeEdited) {
        startEditingMessage(undefined, false);
      }
      return;
    }
    const obj = {
      subjectLineIds:
        newlyCreatedAttributeIds.channels.length > 0
          ? newlyCreatedAttributeIds.channels[0].subjectLineIds
          : [],
      preHeaderIds:
        newlyCreatedAttributeIds.channels.length > 0
          ? newlyCreatedAttributeIds.channels[0].preHeaderIds
          : [],
      headerIds:
        newlyCreatedAttributeIds.channels.length > 0
          ? newlyCreatedAttributeIds.channels[0].headerIds
          : [],
      optoutIds:
        newlyCreatedAttributeIds.channels.length > 0
          ? newlyCreatedAttributeIds.channels[0].optoutIds
          : [],
      tagLineIds:
        newlyCreatedAttributeIds.channels.length > 0
          ? newlyCreatedAttributeIds.channels[0].tagLineIds
          : [],
      ctaIds: newlyCreatedAttributeIds.ctaIds || [],
    };
    search(obj);

    // search((data, err) => {
    //   if (data) {
    //     prepareData(data, obj);
    //   }
    //   setOpenCreateMarketMessageModal(undefined);
    // });
  };

  useEffect(() => {
    fetchBrandsAndCampaigns();
  }, []);

  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        setBrandsAndCampaignsData(response.data);
        const data = response.data.map((item) => item.brandName);
        setProductList(data);
      }
    });
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <>
        <div className="row mt-3">
          <div className="col-md-5 matrixSearchRowTitle">
            <div className="d-flex pl-2">
              <div>
                <img
                  className="grid-header-back-button mt-0"
                  src={backButton}
                  alt=""
                  width="10"
                  onClick={() => backToPrviousScreen()}
                />
              </div>
              <div className="pl-2">
                <strong>Create</strong> Submission Document ({stepNumber}/
                {channelGridStepCount}){" "}
                <div className="subtitle-text">
                  <img
                    className="grid-header-subtitle-icon"
                    src={infoIcon}
                    alt=""
                  />
                  Select Channel Attributes
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 matrixSearchRowBrand">
            <div className="d-flex justify-content-end pr-2">
              <div className="mr-5">
                <span>Product/Brand</span>
                {brand}
              </div>
              <div className="mr-5">
                <span>Campaign</span>
                {campaignName}
              </div>
              <div>
                <span>Channel</span>
                {channelType}
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mt-4 align-items-center"
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "15px",
          }}
        >
          <div className="col-md-12">
            <div className="d-flex">
              <div className="channelGridMsgSearch pl-2 pr-4">
                <TextField
                  label="Search by Attribute ID/Attribute Text"
                  variant="outlined"
                  fullWidth={true}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    //  filterByText(event.target.value);
                  }}
                  autocomplete="new-password"
                  autoComplete="off"
                  classes="p-0"
                  className="searchIconInput"
                />
              </div>
              <div className="channelGridMsgbtns pr-2">
                <div className="d-flex">
                  <button
                    className="btn btn-primary btn-search-grid-message search-marketing-msg-in-modal"
                    onClick={(event) => {
                      filterByText();
                    }}
                    disabled={!searchText.trim()}
                  >
                    search
                  </button>
                  <button
                    className="btn btn-link p-0 clear-search text-center mr-4"
                    onClick={(event) => {
                      resetModuleList();
                    }}
                    disabled={
                      searchedResult !== true &&
                      !searchTriggered &&
                      !searchText.trim()
                    }
                  >
                    {t("coreClaim.reset").toUpperCase()}
                  </button>
                  <div className="common-box-legend">
                    <div className="d-flex ">
                      <span className="chLegendBg lpurple"></span>{" "}
                      <span className="chLegendText">Campaign Level</span>
                      <span className="chLegendBg lgrey"></span>{" "}
                      <span className="chLegendText no-right-padding">
                        Product Level
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelectableMarketingMessages
          marketingMessages={filteredMarketingMessages}
          onSelectMarketingMessage={onSelectMarketingMessage}
          selectedMarketingMessages={selectedMarketingMessges}
          channelType={channelType}
          isAllSubjectLineChecked={isAllSubjectLineChecked}
          isAllPreHeaderChecked={isAllPreHeaderChecked}
          isAllCtasChecked={isAllCtasChecked}
          handleSelecteAll={handleSelectAll}
          isAllOptoutsChecked={isAllOptoutsChecked}
          searchedResult={searchedResult}
          startEditingMessage={startEditingMessage}
        />
        <div className="row pt-3 createGroupResultBtnRow">
          <div className="col-md-4 pl-4">
            <DropdownButton
              id="dropdown-basic-button"
              title="Create New Channel Attributes"
              className="new-channel-attr"
              style={{ display: "inline" }}
            >
              <Dropdown.Item
                as="button"
                onClick={() => hadnleCreateNewMessage("De-nuovo")}
              >
                Create De-nuovo
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                disabled={true}
                /// onClick={() => hadnleCreateNewMessage("asset")}
              >
                Create from Asset + component
              </Dropdown.Item>
            </DropdownButton>
          </div>
          {/* <div className="col-md-4 pl-4">
            <img
              className="icon_chevron_left"
              src={upArrow}
              alt=""
              style={{ marginTop: "2px" }}
            />
            <button
              className="btn btn-link text-uppercase"
              onClick={() => {}}
              style={{ paddingLeft: "8px", marginTop: "4px" }}
            >
              Create New Channel Attributes
            </button>
          </div> */}
          <div className="col-md-8 text-right pr-4">
            <button
              className="btn btn-link text-uppercase"
              onClick={() => {
                cancelCreatingGrid();
              }}
              style={{ marginRight: "64px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary text-uppercase"
              disabled={!shouldProceedButtonBeEnabled()}
              // onClick={() => {
              //   handleContentMatrixModal();
              // }}
              onClick={() => {
                handleProceed();
              }}
              style={{ width: "136px" }}
            >
              {t("generalText.proceed")}
            </button>
          </div>
        </div>
      </>
      <CustomModal
        displayModal={openCreateMarketMessageModal}
        hideModal={hideopenCreateNewMessageModal}
        title={""}
        size="lg"
        dilogClassName="alertModalSmall marketingMsgModalBlk"
        keyboard={false}
        enforceFocus={false}
      >
        <CreateNewMarketingMessage
          hideopenCreateNewMessageModal={hideopenCreateNewMessageModal}
          productList={productList}
          brandsAndCampaignsData={brandsAndCampaignsData}
          damsSource={damsSource}
          marketingMessage={marketingMsgToBeEdited}
          otherPreDefinedMetaData={{
            brand: brand,
            campaign: campaignName,
            channel: channelType,
          }}
          marketingMsgTypeToBeCreated={marketingMsgTypeToBeCreated}
        />
      </CustomModal>
    </div>
  );
};

export default MarketingMessageSelection;
