import axios from "axios";

import {
  instance,
  instanceStorage,
  instanceUpload,
} from "../services/axiosConfig";

import { poolData, indentityPoolId } from "../Uitls/Userpools";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { awsRegion, damsSources } from "../Uitls/Util";
import { csEmailInstance } from "./axiosConfigCSEmail";

export const saveAdditionalInfo = (moduleId, payload) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/additionalinformation`,
    payload,
    {}
  );
};

export const deleteCTAs = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/cta/bulk`, { data: payload })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const deleteCTATexts = (moduleId, ctaID, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/cta/${ctaID}/bulk`, {
      data: payload,
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const deleteClaimGroups = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/groups/bulk`, { data: payload })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const deleteChannels = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/channels/bulk`, {
      data: payload,
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const createChannels = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/channels/bulk`,
    payload,
    {}
  );

  // .then((response) => {
  //   cb(response);
  // })
  // .catch((err) => {
  //   const errorCode = err.response && err.response.status;
  //   cb("", err, errorCode);
  // });
};

export const createCTAs = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/cta/bulk`,
    payload,
    {}
  );
  // .then((response) => {
  //   cb(response);
  // })
  // .catch((err) => {
  //   const errorCode = err.response && err.response.status;
  //   cb("", err, errorCode);
  // });
};
export const saveGroupsWithClaims = (moduleId, payload, cb) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/groups/bulk`,

    payload,
    {}
  );

  // .then((response) => {
  //   cb(response);
  // })
  // .catch((err) => {
  //   const errorCode = err.response && err.response.status;
  //   cb("", err, errorCode);
  // });
};

export const getModuleDetailsById = (moduleId, cb) => {
  instance
    .get(`/api/modulems/modules/${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getBrandsAndCampaigns = (cb) => {
  instance
    .get("/api/modulems/assets/brandcampaigns", {
      headers: { Some: "Something" },
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getPosts = (cb) => {
  instance
    .get("/posts", { headers: { Some: "Something" } })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const uploadAsset = (formData, options, cb) => {
  instanceUpload
    .post("/api/modulems/assets/upload", formData, options)
    .then((response) => {
      cb(response);
      console.log(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      // console.log(errorCode);
      cb("", err, errorCode);
    });
};

//4dd6be19-c57c-4403-a02c-00a0eb381d0a
export const getAssetDetails = (cb, assetID) => {
  instanceStorage
    .get(`api/storagems/assets/${assetID}?fetchHierarchy=true`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//GET CORE CLAIM CATEGORIES
// export const getCoreClaimCategories = (cb, brandId) => {
//   instance
//     //.get(`/api/storagems/assets/coreclaimscategories?brand=${brand}`)
//     .get(`/api/modulems/brands/${brandId}/categoryclaims`)
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       cb("", err, err.response && err.response.status);
//     });
// };

export const getCoreClaimCategories = (cb, brand, sourceType) => {
  let url = `/api/modulems/brands/${brand}/categoryclaims`;
  if (sourceType?.toLowerCase() === damsSources.aprimo.name.toLowerCase()) {
    url = `/api/modulems/aprimo/claimsmanager/claims/${brand}`;
  }
  instance
    //.get(`/api/storagems/assets/coreclaimscategories?brand=${brand}`)
    .get(url)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb("", err, err.response && err.response.status);
    });
};

export const getDeConstStatus = (ids, cb) => {
  // console.log("REquest IDs", ids);
  instance
    // .post("/api/modulems/harvestedtactic/status", ids)
    .post("/api/modulems/assets/retrieval/status", ids)
    .then((response) => {
      cb(response);
      // console.log("Response IDs", response.data);
    })
    .catch((err) => {
      console.log(err);
      // const errorCode = err.response && err.response.status;
      // cb("", err, errorCode);
    });
};
//http://Imcmoduleapi-env.eba-qmc6suy8.ap-south-1.elasticbeanstalk.com/api/modulems/assets/status
export const getAssetsStatus = (cb) => {
  return instance
    .get("api/modulems/harvestedtactic/inprogress")
    .then((response) => {
      cb(response);
      console.log("Pending", response.data);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const uploadSuppVisual = (formData, options, cb) => {
  instanceStorage
    .post("/api/storagems/assets", formData, options)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getModulesBusinessRules = (assetId, cb) => {
  return instance
    .get(`api/modulems/assets/${assetId}/modules`)
    .then((response) => {
      cb(response);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getExcludeModulesBusinessRules = (payLoad, moduleId, cb) => {
  return instance
    .post(`api/modulems/modules/${moduleId}`, payLoad)
    .then((response) => {
      cb(response);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const saveBusinessRules = (moduleId, payload) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/businessrules/bulk`,
    payload
  );
};

export const saveBusinessRuleNotes = (moduleId, payload) => {
  return instance.put(
    `/api/modulems/modules/${moduleId}/businessrulenotes/bulk`,
    payload
  );
};

export const createModuleDetails = (payLoad, cb) => {
  instance
    .post("/api/modulems/modules", payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const saveClaims = (moduleId, payload, cb) => {
  instance
    .put(`/api/modulems/modules/${moduleId}/claimfamily`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const deleteClaims = (moduleId, payload, cb) => {
  instance
    .delete(`/api/modulems/modules/${moduleId}/claims/bulk`, { data: payload })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const searchModules = (searchParam, cb) => {
  instance
    .get(`/api/modulems/modules?search=${searchParam}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const downloadModule = (moduleId, formData, cb) => {
  instance
    .post(`/api/modulems/modules/${moduleId}/mlr`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, err.response && err.response.status);
    });
};

export const createDenovoModule = (formData, cb) => {
  instance
    .post(`/api/modulems/denovo/module`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, err.response && err.response.status);
    });
};

export const getAssetsAndModules = (data, cb) => {
  let endPoint = "/api/modulems/assets/modules";
  instance
    .post(endPoint, data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getAllOrSearchDenovoModules = (data, cb) => {
  let endPoint = "/api/modulems/denovo/modules/search";
  instance
    .post(endPoint, data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const lockAssetOrModule = (data, cb) => {
  instance
    .post("/api/modulems/approval", data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//?brand=${params.brand}&campaign=${params.campaign}
export const getDenovoModulesByBrandAndCampaign = (params, cb) => {
  let url = `/api/modulems/denovo/modules?brand=${params.brand}&campaign=${params.campaign}`;
  if (params.market !== undefined) {
    url += `&market=${params.market}`;
  }
  if (params.moduleId) {
    url = `${url}&moduleId=${params.moduleId}&excludeModules=${params.moduleId}`;
  }
  return instance
    .get(
      url
      // {
      //   params : params
      // }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const publishModuleToVvpm = (moduleId, sourceType, formData, cb) => {
  instance
    .post(`/api/modulems/modules/${moduleId}/${sourceType}/publish`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, err.response && err.response.status);
    });
};

export const searchReferenceDocs = (searchText, payLoad, cb) => {
  let url = `api/modulems/${payLoad.sourceType}/reference/search?search=${searchText}`;
  if (payLoad.docType === "ISI") {
    url = `${url}&docType=${payLoad.ISI}`;
  } else if (payLoad.docType === "PI") {
    url = `${url}&docType=${payLoad.PI}`;
  } else if (payLoad.docType === "MG") {
    url = `${url}&docType=${payLoad.MG}`;
  } else {
    if (
      payLoad.sourceType?.toLowerCase() !== damsSources.veeva.name.toLowerCase()
    ) {
      url = `${url}&docType=${payLoad.docType}`;
    }
  }
  return instance
    .get(url)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getVeevaDownloadAnnotated = (moduleId, veevaDocId, cb) => {
  return instance
    .get(
      `api/modulems/modules/${moduleId}/veeva/download?docId=${veevaDocId}&docType=Module`,
      { responseType: "blob" }
    )
    .then((response) => {
      cb(response);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

//MLR Status
export const getVeevaLatestStatus = (veevaModuleId, veevaDocId, cb) => {
  return instance
    .get(`api/modulems/modules/${veevaModuleId}/veeva/mlrStatus/${veevaDocId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const cloneModule = (formData, moduleId, cb) => {
  return instance
    .put(`/api/modulems/asset/modules/${moduleId}/clone`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getPDFUrlForRendering = (moduleId, docId, sourceType, cb) => {
  return instance
    .get(
      `/api/modulems/modules/${moduleId}/${sourceType}/download?docId=${docId}&docType=Reference`,
      sourceType?.toLowerCase() === damsSources?.veeva?.name?.toLowerCase()
        ? { responseType: "blob" }
        : undefined
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const getPDFUrlForRenderingdenovo = (docId, cb) => {
  return instance
    .get(`/api/modulems/reference/download/${docId}`, {
      responseType: "blob",
    })
    .then((response) => {
      cb(response);
      return response.data;
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getCognitoSession = (cb) => {
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err, session) {
      if (!err) {
        cb(session);
        return;
      }
      cb(undefined);
    });
  } else {
    cb(undefined);
  }
};

export const getCognitoIndentityCredentials = (session, AWS, cb) => {
  AWS.config.region = awsRegion;
  let tokenKey = `cognito-idp.${AWS.config.region}.amazonaws.com/${poolData.UserPoolId}`;
  let logins = {};
  logins[tokenKey] = session.getIdToken().getJwtToken();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: indentityPoolId,
    Logins: logins,
  });
  AWS.config.credentials.get((err) => {
    if (!err) {
      cb(AWS.config.credentials);
      return;
    }
    cb(undefined);
  });
};

export const getICBToken = (callback) => {
  const endPoint =
    "https://kc-prod.indegene.com/auth/realms/ICB-IMC/protocol/openid-connect/token";
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", "icb_server");
  params.append("client_secret", "07e00a33-9a7d-4a4f-bd9e-733279a61624");
  params.append("scope", "openid");
  params.append("username", "imcuser");
  params.append("password", "indegene@123");
  axios
    .post(endPoint, params)
    .then((res, error) => {
      callback(error, res);
    })
    .catch((err) => {
      callback(err, undefined);
    });
};

export const searchHarvestedTactics = (
  icbToken,
  start,
  count,
  brand,
  searchText,
  cb
) => {
  const endPoint = `https://icb-imc.indegene.com/api/assets?start=${start}&page=${count}&status=Reviewed&search=${searchText}&brand=${brand}`;
  const config = {
    headers: { Authorization: `Bearer ${icbToken}` },
  };

  axios
    .get(endPoint, config)
    .then((res, error) => {
      cb(error, res);
    })
    .catch((err) => {
      cb(err);
    });
};

export const getHarvestedTactics = (cb) => {
  return instance
    .get(`/api/modulems/harvestedtactic`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const retrieveHarvetsedTactics = (data, cb) => {
  instance
    .post("api/modulems/harvestedtactic/retrieve", data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const storeGenericInfo = (payload, cb) => {
  instance
    .patch("/api/modulems/harvestedtactic/genericinfo", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const localizeModule = (formData, moduleId, cb) => {
  return instance
    .put(`/api/modulems/asset/modules/${moduleId}/localize`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getTranslatorsAndAgencies = (cb) => {
  instance
    .get("/api/modulems/modules/translatoragency")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const createTranslationJob = (payload, cb) => {
  instance
    .put(`/api/modulems/translation/bulk`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response;
      cb("", errorMessage, errorCode);
    });
};

export const getTrnaslateDashboardDetails = (cb) => {
  instance
    .get("/api/modulems/translator/dashboard")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const saveTranslatedModule = (moduleId, payload, cb) => {
  instance
    .patch(`/api/modulems/modules/${moduleId}/translation`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getNotifications = (cb) => {
  instance
    .get("/api/modulems/dashboard/notification")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getNotificationsReadStatus = (id, cb) => {
  instance
    .patch(`/api/modulems/dashboard/notification/${id}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const translatePendingStatus = (payload, cb) => {
  instance
    .patch("/api/modulems/job/translation", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const translatorSubmitForReview = (payload, cb) => {
  instance
    .patch("/api/modulems/job/translation/status", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const createAdditionalInfoTranslate = (moduleId, payload, cb) => {
  instance
    .put(`/api/modulems/modules/${moduleId}/additionalinformation`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response;
      cb("", errorMessage, errorCode);
    });
};

export const addNewComment = (payload, cb) => {
  instance
    .post("/api/modulems/translation/comment", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response;
      cb(undefined, errorMessage, errorCode);
    });
};

export const modifyCommentSave = (payload, cb) => {
  instance
    .patch("/api/modulems/translation/comment/modify", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response;
      cb(undefined, err, errorCode);
    });
};

export const replyToComment = (payload, cb) => {
  instance
    .post("/api/modulems/translation/comment/reply", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response;
      cb(undefined, err, errorCode);
    });
};

export const markCommentResolved = (commentId, cb) => {
  instance
    .post(`/api/modulems/translation/comment/${commentId}/resolve`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const getJobDetails = (jobId, cb) => {
  instance
    .get(`/api/modulems/job/${jobId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const updateRevisionRequired = (moduleId, payload, cb) => {
  instance
    .put(`api/modulems/modules/${moduleId}/translation`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const deleteCommentsAndReply = (payload, cb) => {
  instance
    .delete(
      `api/modulems/translation/comment/${payload.id}?type=${payload.type}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const translatorRating = (jobId, ratings, cb) => {
  instance
    .post(`api/modulems/job/${jobId}?rating=${ratings}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const fetchLocalizedModules = (params, responseType, searchType, cb) => {
  instance
    .post(
      `/api/modulems/asset/modules/localize?responseType=${responseType}&searchType=${searchType}`,
      params
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const LocalizedBusinessrulesModules = (payload, cb) => {
  instance
    .post("/api/modulems/modules/businessrules", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const createTranslationGroup = (payload, cb) => {
  instance
    .post("/api/modulems/translation/groups", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const createTranslationDuplicationCheck = (payload, cb) => {
  instance
    .post("/api/modulems/groups/modules/validation", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getGroupsModulesLangauge = (moduleIds, cb) => {
  instance
    .post(`/api/modulems/groups/language/modules`, moduleIds)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const getSourceModuleLanguages = (payload, cb) => {
  instance
    .post("/api/modulems/modules/languages", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const upDateModuleAssetLinks = (payload, cb) => {
  instance
    .patch(`/api/modulems/assets/modules/genericinfo`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getLatestAnnotatedPdfFromVeeva = (docId, cb) => {
  instance
    .get(`/api/modulems/veeva/${docId}/annotation/download`, {
      responseType: "blob",
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getJobInstructions = (jobId, cb) => {
  instance
    .get(`/api/modulems/job/${jobId}/instructions`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined);
    });
};
export const getBusinessRulesForView = (moduleId, cb) => {
  instance
    .get(`/api/modulems/modules/businessrules?moduleIds=${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const createPlaceholderModules = (payLoad, cb) => {
  instance
    .post("/api/modulems/modules", payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      console.log(err);
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const saveBusinessRulesAndUsageInstructions = (payload, cb) => {
  instance
    .post(
      `/api/modulems/modules/placeholder/businessrulesusageguideline`,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getModulesListByAssetId = (assetId, cb) => {
  instance
    .get(`/api/modulems/assets/modules?assetIds=${assetId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const searchAssetsFromDamsInMM = (
  data,
  sourceType,
  assetDataType,
  cb
) => {
  let endPoint = `/api/modulems/assets/components?sourceType=${sourceType}&assetDataType=${assetDataType}`;
  if (
    sourceType.toLowerCase() === damsSources.veeva.name.toLowerCase() &&
    assetDataType.toLowerCase() === "modularcontent"
  ) {
    endPoint = `/api/modulems/contentmodules/components`;
  }
  instance
    .post(endPoint, data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const searchAssetsInDams = (sourceType, payload, cb) => {
  instance
    //.post(`/api/modulems/aprimo/assets`, payload)
    .post(`/api/modulems/${sourceType}/assets/search`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const retrieveTacticsToMM = (data, cb, sourceType = "icb") => {
  instance
    .post(`/api/modulems/source/${sourceType}/assets/retrieve`, data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getTemplateTypes = (brandName, sourceType, assetDataType, cb) => {
  instance
    .get(
      `/api/modulems/search/templates?brand=${brandName}&sourceType=${sourceType}&assetDataType=${assetDataType}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getContentmatrix = (moduleId, sourceType, assetDataType, cb) => {
  instance
    .get(
      `/api/modulems/contentmatrix/modules/${moduleId}?sourceType=${sourceType}&assetDataType=${assetDataType}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getCompositeAssetData = (
  sourceType,
  assetDataType,
  compsiteAssetId,
  cb
) => {
  instance
    .get(
      `api/modulems/${sourceType}/compositeassets/${compsiteAssetId}?assetDataType=${assetDataType}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const generateChannelMatrix = (data, sourceType, assetDataType, cb) => {
  instance
    .post(
      `api/modulems/${sourceType}/contentmatrix?assetDataType=${assetDataType}`,
      data
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const publishMatrix = (
  sourceType,
  assetDataType,
  contentMatrixId,
  cb
) => {
  instance
    .post(
      `/api/modulems/${sourceType}/contentmatrix/${contentMatrixId}/publish?assetDataType=${assetDataType}
    `
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

// export const getCompositeAssetDetails = (composite_asset_id, cb) => {
//   return instance
//     .get(`api/modulems/compositeassets/${composite_asset_id}`)
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb(undefined, err, errorCode);
//     });
// };

export const searchContentModules = (sourceType, payload, cb) => {
  instance
    .post(`/api/modulems/${sourceType}/contentmodules/search`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const retrieveContentModules = (data, cb, sourceType = "veeva") => {
  instance
    .post(`/api/modulems/${sourceType}/contentmodule/import`, data)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const publishContentMatrix = (
  sourceType,
  contentMatrixId,
  assetDataType,
  cb
) => {
  instance
    .post(
      `api/modulems/${sourceType}/contentmatrix/${contentMatrixId}/publish?assetDataType=${assetDataType}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const reGenerateContentMatrix = (
  payload,
  sourceType,
  assetDataType,
  contentMatrixId,
  cb
) => {
  instance
    .put(
      `/api/modulems/${sourceType}/${contentMatrixId}/contentmatrix?assetDataType=${assetDataType}`,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getBusinessRulesForVeeva = (moduleId, payLoad, cb) => {
  instance
    .post(`/api/modulems/contentmodule/businessrules/${moduleId}`, payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const saveBusinessRulesForVeeva = (payLoad, cb) => {
  instance
    .post("/api/modulems/contentmodule/businessrules", payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

// export const getVeevaContentmatrix = (moduleId, cb) => {
//   instance
//     .get(`/api/modulems/contentmatrix/contentmodules/${moduleId}`)
//     .then((response) => {
//       cb(response);
//     })
//     .catch((err) => {
//       const errorCode = err.response && err.response.status;
//       cb("", err, errorCode);
//     });
// };

export const saveMarketingMessages = (payLoad, cb) => {
  instance
    .post("/api/modulems/marketingmessage", payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const uploadMarketinMessageOptoutVisual = (formData, cb) => {
  instance
    .post("/api/modulems/image/upload", formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      const errorMessage = err.response && err.response.data;
      cb("", errorMessage, errorCode);
    });
};

export const validateOptout = (payload, cb) => {
  instance
    .post("/api/modulems/optout/validation", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const importImageFromDAMSForOptout = (damsSource, payLoad, cb) => {
  instance
    .post(`/api/modulems/${damsSource}/image/search`, payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const searchMarketingMessages = (payload, cb) => {
  instance
    .post("/api/modulems/marketingmessage/search", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getVeevaMlrStatus = (id, cb) => {
  instance
    .patch(`/api/modulems/mlrstatus/${id}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const updateMarketingMessages = (
  mktMsgId,
  payLoad,
  cb,
  attributeType = "marketingmessage"
) => {
  instance
    .put(`/api/modulems/${attributeType}/${mktMsgId}`, payLoad)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const publishMarketingMessageToDAMS = (
  mktMsgId,
  channel,
  sourceType,
  cb
) => {
  instance
    .post(
      `api/modulems/marketingmessage/${mktMsgId}/${sourceType}/publish?marketingMessageType=${channel}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};
export const searchChannelGridMarketingMessages = (payload, type, cb) => {
  instance
    .post(`/api/modulems/marketingmessage/search?type=${type}`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const createGrid = (payLoad, assetDataType, sourceType, cb) => {
  instance
    .post(
      `/api/modulems/submission-document?assetDataType=${assetDataType}&sourceType=${sourceType}`,
      payLoad
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb(undefined, err, errorCode);
    });
};

export const getChannelGridIds = (modularisedAssetId, cb) => {
  instance
    .get(`/api/modulems/assets/${modularisedAssetId}/submission-documents`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const getCSEmailCognitoToken = () => {
  const endPoint = "/api/modulems/cse/auth-token";
  return instance.get(endPoint);
};

export const getTemplatesFromCsEmail = (params, callback) => {
  const apiUrl = `/builder/cse/mm_templates?brand=${params.brand}&campaign=${params.campaign}`;

  csEmailInstance
    .get(apiUrl)
    .then((response) => {
      callback(response, undefined, undefined);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};
export const getSamplesUsingTemplateId = (templateId, campaign, callback) => {
  const apiUrl = `/builder/cse/mm_emails?template_id=${templateId}&campaign=${campaign}`;
  csEmailInstance
    .get(apiUrl)
    .then((response) => {
      callback(response, undefined);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const saveStylizedModule = (
  moduleId,
  sourceType,
  assetDataType,
  payload,
  callback
) => {
  console.log("moduleId", moduleId);
  instance
    .post(
      `/api/modulems/${sourceType}/modules/${moduleId}/stylized?assetDataType=${assetDataType}`,
      payload
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const getStylizedModuleDetails = (moduleId, callback) => {
  instance
    .get(`/api/modulems/contentmodules/stylized/${moduleId}`)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};
export const exportStylizedModule = (payload, sourceType, callback) => {
  instance
    .post(
      `/api/modulems/${sourceType}/modules/stylized/export`,
      payload,
      payload.type?.toLowerCase() === "pdf"
        ? {
            responseType: "blob",
          }
        : undefined
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const uploadBrandStyleGuideline = (assetId, formData, cb) => {
  instance
    .patch(`/api/modulems/${assetId}/style-guideline`, formData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getStylizedModuleForView = (moduleId, sourceType, callback) => {
  instance
    .get(`/api/modulems/${sourceType}/modules/${moduleId}/stylized`)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};
export const deleStyleGuidelines = (harvestedAssetId, callback) => {
  instance
    .delete(`/api/modulems/${harvestedAssetId}/style-guideline`)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const getSmartTemplatesFromCSEmail = (brand, callback) => {
  const apiUrl = `/builder/cse/mm_smart_templates?brand=${brand}`;
  csEmailInstance
    .get(apiUrl)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const getTemplateUsingTemplateDamsId = (damsId, callback) => {
  const apiUrl = `/builder/cse/mm_getdetail_veevaid/${damsId}`;

  csEmailInstance
    .get(apiUrl)
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(undefined, err);
    });
};

export const retrieveCountryListForSelectedDams = (selectedDams, cb) => {
  const endPoint = `/api/modulems/${selectedDams}/countries`;
  instance
    .get(endPoint)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const uploadAnImageLink = (payload, cb) => {
  instanceStorage
    .post(`/api/storagems/assets/url/store`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const searchReferences = (searchPayload, cb) => {
  instance
    .post(
      `/api/modulems/denovo/references/search?search=${searchPayload.input}&path=${searchPayload?.brandName}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
export const addReusableTextList = (searchPayload, cb) => {
  instance
    .post(
      `/api/modulems/denovo/reusableText/search?brand=${searchPayload.brandName}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};

export const deleteReusableText = (moduleId, addedReusableTextIds, cb) => {
  instance
    .delete(`api/modulems/modules/${moduleId}/reusableTexts`, {
      data: addedReusableTextIds,
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      const errorCode = err.response && err.response.status;
      cb("", err, errorCode);
    });
};
