import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import backButton from "../../images/chevron-left-matrix.svg";
import "../../styles/components/ChannelMatrixContentBlock.scss";
const ContentMatrixModalHeader = (props) => {
  const {
    moduleDetails,
    templates,
    selectedTemplate,
    onTemplateTypeChange,
    getTemplateIds,
    onTemplateIdChange,
    getSelectedTemplateId,
    getSelectedTemplateName,
    previousPage,
    proceedToNextScreen,
    dropDownStatus,
    pageSwitch,
  } = props;
  const { t } = useTranslation();
  const [moduleContentData, setModuleContentData] = useState(false);

  return (
    <div>
      <div className="row align-items-center">
        <div className="col-md-5 martrixModalTitle">
          <div className="d-flex">
            <div>
              <img
                src={backButton}
                alt=""
                width="10"
                onClick={() => {
                  proceedToNextScreen(previousPage);
                }}
              />
            </div>
            <div className="pl-2">
              {dropDownStatus ? (
                <span className="text-nowrap">
                  Composite Asset - Initial Module Selection
                </span>
              ) : (
                <>
                  <span className="font-weight-normal">
                    {moduleDetails.moduleCode} |
                  </span>
                  <span className="text-nowrap pl-1">
                    List of Associated Modules
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <div className="d-flex justify-content-end">
            <div className="textBlockHeader">
              <span className="d-block">Product/Brand</span>
              {moduleDetails.brandName}
            </div>
            <div className="textBlockHeader">
              <span className="d-block">Campaign</span>
              {moduleDetails.campaign}
            </div>
          </div>
        </div>
      </div>
      {!pageSwitch.pageTwo ? (
        <>
          {!dropDownStatus && (
            <div
              className="row ml-0 mr-0"
              style={{ fontSize: "14px", fontWeight: "normal" }}
            >
              Select Template Type
            </div>
          )}
          <div className="row ml-0 mr-0 mt-3">
            <div className="textBlockHeader templateSelectionRow mr-4">
              <DropDownWithLabel
                class="read mt-0"
                disabled="disabled"
                options={{
                  name: "Template Type",
                  label: "Template Type",
                  value: selectedTemplate?.templateType,
                  items: templates,
                  handleChange: (e) => {
                    onTemplateTypeChange(e.target.value);
                  },
                  disabled: dropDownStatus,
                }}
              />
            </div>
            <div className="textBlockHeader templateSelectionRow mr-4">
              <DropDownWithLabel
                class="read mt-0"
                disabled="disabled"
                options={{
                  name: "Template ID",
                  label: "Template ID",
                  value: getSelectedTemplateId(),
                  items: getTemplateIds(),
                  handleChange: (e) => {
                    onTemplateIdChange(e.target.value);
                  },
                  isDataTypeObject: true,
                  disabled: dropDownStatus,
                }}
              />
            </div>
            {getSelectedTemplateName() && (
              <div className="textBlockHeader templateSelectionRow mr-4">
                <span className="d-block">Template Name</span>
                {selectedTemplate ? getSelectedTemplateName() : "-----"}
              </div>
            )}
            {/* <div className="textBlockHeader templateSelectionRow mr-4">
          <TextField
            className="tf-and-drp-dwn-with-label-disabled"
            //key={getSelectedTemplateId()}
            label="Template Name"
            variant="outlined"
            fullWidth={true}
            disabled={true}
            value={[getSelectedTemplateName()]}
          />
        </div> */}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentMatrixModalHeader;
