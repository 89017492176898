import React, { useEffect, useState } from "react";
import parseHtml from "html-react-parser";
import { useHistory } from "react-router-dom";
import { LocalizedBusinessrulesModules } from "../../services/apis";
import { routes } from "../../Uitls/Util";

const BulkModulesValidation = (props) => {
  const history = useHistory();
  const {
    backToGenericInfoSlectionModal,
    bulkModulesValidationModalClose,
    createNewTranslationGroup,
    validationData,
    dataForTranslationCreationGroup,
  } = props;

  const getListOfLang = () => {
    const finalData = [];
    validationData.forEach((item) => {
      item.moduleList.forEach((res) => {
        finalData.push(res.language);
      });
    });
    const filterdFinalData = [...new Set(finalData)].toString();
    return filterdFinalData;
  };

  const removeDuplicateModuleIdsN = () => {
    const clonedModulesWithGenInfo = JSON.parse(
      JSON.stringify(dataForTranslationCreationGroup)
    );
    validationData.forEach((data) => {
      data.moduleList.forEach((res) => {
        const { language, sourceModuleId } = res;
        const { modulesByLanguage } = clonedModulesWithGenInfo;
        const matchedModule = modulesByLanguage.find(
          (module) => module.language === language
        );
        if (matchedModule) {
          const index = matchedModule.moduleIdList.indexOf(sourceModuleId);
          if (index > -1) {
            matchedModule.moduleIdList.splice(index, 1);
          }
        }
      });
    });
    createNewTranslationGroup(clonedModulesWithGenInfo);
  };

  const viewModule = (moduleId) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
      },
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 bulkModalValidationTitle">
          <p className="mb-1">
            Existing {getListOfLang()} modules are displayed Below
          </p>
          Do you want to consider these modules in bulk translation request?
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-link p-0"
            onClick={bulkModulesValidationModalClose}
          >
            CLOSE
          </button>
        </div>
      </div>
      <div className="validationModalContainer mt-4 customScrollBar pr-3">
        {validationData &&
          validationData.map((item, index) => {
            return (
              <div key={index}>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="groupTitleBulkErrorModal">
                      <div className="groupContentErrorModal">
                        Market :{" "}
                        <span>
                          {item.moduleList[0] &&
                            item.moduleList[0].market.split("-")[0].trim()}
                        </span>{" "}
                        Group id : <span>{item.groupCode}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {item.moduleList.map((module, moduleIndex) => {
                    return (
                      <div className="col-md-4" key={moduleIndex}>
                        <div className="currentModuleTactics">
                          <div className="d-flex justify-content-between moduleParentDiv">
                            <div className="localize-module-code-box">
                              <p className="mb-0">{module.moduleCode}</p>
                            </div>
                            <div className="translationComplete">
                              Translation Done
                            </div>
                          </div>
                          <p className="modNameTitle mt-1 mb-0">
                            Campaign Name
                          </p>
                          <p className="modNameText modCampName">
                            {module.campaign}
                          </p>
                          <p className="modNameTitle mb-0">Module Name</p>
                          <p className="modNameText modNameTextTitle">
                            {module.moduleName}
                          </p>
                          <p className="modCategoryText">
                            {module.coreCategories.map((catData, catIndex) => {
                              return <span key={catIndex}>{catData}</span>;
                            })}
                          </p>
                          <p className="modNameTitle mb-0">Promotional Claim</p>
                          <div className="modNameText mb-0">
                            {parseHtml(module.promotionalClaim || "")}
                          </div>
                          <div className="moduleDetailsBrdr">
                            <div className="d-flex justify-content-center moduleDetailsBtnRow">
                              <button
                                className="btn btn-link btnApprove pl-0 moduleActionBtn"
                                onClick={() => {
                                  viewModule(module.moduleId);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-6">
          <button
            className="btn btn-link p-0"
            onClick={backToGenericInfoSlectionModal}
          >
            BACK
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-outline-primary text-uppercase btnNextModule btn-lg mr-4"
            onClick={() => {
              //removeDuplicateModuleIds()
              removeDuplicateModuleIdsN();
            }}
          >
            No
          </button>
          <button
            className="btn btn-primary btnNextModule"
            onClick={() => {
              createNewTranslationGroup(dataForTranslationCreationGroup);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
export default BulkModulesValidation;
