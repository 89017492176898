//Import React dependency
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import i18next from "i18next";
//Import custom compoenents
import {
  getDenovoModulesByBrandAndCampaign,
  getExcludeModulesBusinessRules,
  saveBusinessRulesAndUsageInstructions,
} from "../../services/apis";
import SearchComponent from "./SearchModules";
import BusineesRulesCards from "./BusineesRulesCards";
import FroalaEditor from "../../Uitls/CommonFroalEditorComponent";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
//import styles

//import images
import doubleClickIcon from "../../images/doubleClickIcon.svg";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import {
  moduleTypes,
  routes,
  updateModuleInfo,
  removeModuleInfo,
} from "../../Uitls/Util";
import { useHistory } from "react-router";

const AddBussinessRules = ({
  campaignName,
  handleCloseCreateModuleModal,
  type,
  asset,
  modulesData,
  addedGenericInfo, // for denovo only
  ...props
}) => {
  const history = useHistory();
  const frInstanceInstructions = React.createRef();
  const [selectedBox, setSelectedBox] = useState("");
  //const [isAllowedToAddRule, toggleAllowedToAddRule] = useState(true);
  const [eligibleForBrulesModules, setEligibleForBrulesModules] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [contentExists, setContentExists] = useState("");
  const [cannotExistWith, setCannotExistWith] = useState(undefined);
  //Remove this after making it dynamic
  const [moduleTabs, setmoduleTabs] = useState([]);

  const selectModuleTab = (moduleId) => {
    const modules = [...moduleTabs];
    modules.forEach((module) => {
      if (module.id === moduleId) {
        module.isSelected = true;
      } else {
        module.isSelected = false;
      }
    });
    setmoduleTabs(modules);
    fetchModulesEligibleForBusinessRules(moduleId);
    setSelectedBox("");
    setContentExists("");
  };

  const selectedModule = () => {
    if (moduleTabs.length === 0) {
      return {};
    }
    return moduleTabs.find((module) => {
      return module.isSelected;
    });
  };

  useEffect(() => {
    if (modulesData && moduleTabs.length === 0) {
      let modules = modulesData.modules;
      modules.forEach((module, index) => {
        if (index === 0) {
          module.isSelected = true;
        } else {
          module.isSelected = false;
        }
        module.businessRules = [];
        module.usageInstructionsGuidelines = "";
      });
      setmoduleTabs(modules);
      fetchModulesEligibleForBusinessRules(modules[0].id);
    }
  }, [modulesData]);

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const fetchModulesEligibleForBusinessRules = (forModuleId) => {
    let payload;
    if (type === moduleTypes.denovo) {
      setIsloading(true);

      getDenovoModulesByBrandAndCampaign(
        {
          brand: addedGenericInfo.brand,
          campaign: addedGenericInfo.campaign,
          market: addedGenericInfo.market,
          moduleId: forModuleId,
        },
        (response, err, errCode) => {
          setIsloading(false);
          if (response) {
            setEligibleForBrulesModules([].concat(response.data));
            return;
          }
          showError(errCode);
        }
      );
    } else {
      payload = {
        brandName: asset.brand,
        campaign: asset.campaignName,
        // market: asset.market,
        moduleType: "Original",
        excludeModules: [forModuleId],
      };
      if (payload) {
        setIsloading(true);
        getExcludeModulesBusinessRules(
          payload,
          forModuleId,
          (response, err, errorCode) => {
            setIsloading(false);
            if (err) {
              showError(errorCode);
            } else {
              setEligibleForBrulesModules([].concat(response.data));
            }
          }
        );
      }
    }
  };

  const addBusinessRule = (bRule) => {
    let modules = [...moduleTabs];
    const index = modules.findIndex(
      (module) => module.id === selectedModule().id
    );
    if (index > -1) {
      let businessRules = modules[index].businessRules;
      if (
        businessRules.findIndex(
          (rule) =>
            rule.condition === bRule.condition &&
            rule.targetModuleId === bRule.targetModuleId
        ) === -1
      ) {
        businessRules.push(bRule);
        module.businessRules = businessRules;
        setmoduleTabs(modules);
      }
    }
  };

  const getAddedBusinessRules = (condition) => {
    if (moduleTabs.length > 0) {
      return selectedModule().businessRules.filter((rule) => {
        return rule.condition === condition;
      });
    }
    return [];
  };

  const deleteBusinessRule = (bRule) => {
    let modules = [...moduleTabs];
    const index = modules.findIndex(
      (module) => module.id === selectedModule().id
    );
    if (index > -1) {
      let businessRules = modules[index].businessRules;
      let toBeFilteredBusinessRules = [...businessRules].filter((rule) => {
        return (
          rule.condition === bRule.condition &&
          rule.targetModuleId !== bRule.targetModuleId
        );
      });
      let otherRules = [...businessRules].filter((rule) => {
        return rule.condition !== bRule.condition;
      });
      modules[index].businessRules = otherRules.concat(
        toBeFilteredBusinessRules
      );
      setmoduleTabs(modules);
    }
  };

  const handleChangeGuideLiines = (event) => {
    let modules = [...moduleTabs];
    const index = modules.findIndex(
      (module) => module.id === selectedModule().id
    );
    if (index > -1) {
      modules[index].usageInstructionsGuidelines = event;
      setmoduleTabs(modules);
    }
  };

  const getGuidelineText = () => {
    return selectedModule().usageInstructionsGuidelines;
  };
  const makeModuleStandalone = (flag) => {
    const modules = [...moduleTabs];
    const currentModule = selectedModule();
    const currentIndex = moduleTabs.findIndex((module) => {
      return module.id === selectedModule().id;
    });
    if (flag) {
      if (currentIndex > -1) {
        modules[currentIndex].businessRules = [
          {
            category: "Module-To-Module",
            condition: "Standalone",
            targetModuleId: currentModule.id,
          },
        ];
      }
      //toggleAllowedToAddRule(true);
    } else {
      modules[currentIndex].businessRules = [];
      //toggleAllowedToAddRule(false);
    }
    setSelectedBox("");
    setmoduleTabs(modules);
  };
  const isThisModuleStandalone = () => {
    if (!moduleTabs || moduleTabs.length === 0) {
      return false;
    }
    const currentModule = selectedModule();
    const bRules = currentModule.businessRules;
    let isStandalone = false;
    bRules.forEach((rule) => {
      if (rule.condition.trim().toLowerCase() === "standalone") {
        isStandalone = true;
        return;
      }
    });
    return isStandalone;
  };

  const saveBusinessRules = () => {
    const payload = [...moduleTabs].map((module) => {
      return {
        moduleId: module.id,
        usageInstructionsGuidelines: module.usageInstructionsGuidelines,
        businessRules: module.businessRules,
      };
    });
    setIsloading(true);
    saveBusinessRulesAndUsageInstructions(payload, (response, err, errCode) => {
      setIsloading(false);
      if (err || errCode) {
        showError(errCode);
        return;
      }
      //move to the asset browsing/promational claim page
      const moduleToProceedWith = selectedModule();
      const index = response.data.modules.findIndex((module) => {
        return module.id === moduleToProceedWith.id;
      });
      if (type === moduleTypes.denovo) {
        proceedToPromationalClaimPage(response.data.modules[index]);
      } else {
        proceedToAssetBrowsingPage(response.data.modules[index]);
      }
    });
  };

  const proceedToAssetBrowsingPage = (moduleToProceedWith) => {
    history.push({
      pathname: routes.assetDetails,
      state: {
        assetid: asset.storageAssetId,
        moduleName: moduleToProceedWith.name,
        moduleCode: moduleToProceedWith.moduleCode,
        moduleId: moduleToProceedWith.id,
        brandName: asset.brand,
        isCategoriesAdded: moduleToProceedWith.coreCategoriesFlag,
      },
    });
  };

  const skipToAssetBrowsingPage = () => {
    const module = selectedModule();
    const moduleFromLastScreen = modulesData.modules.find((moduleEl) => {
      return moduleEl.id === module.id;
    });
    if (type === moduleTypes.denovo) {
      proceedToPromationalClaimPage(moduleFromLastScreen);
    } else {
      proceedToAssetBrowsingPage(moduleFromLastScreen);
    }
  };
  const proceedToPromationalClaimPage = (moduleToProceedWith) => {
    let pageCount = "";
    removeModuleInfo();
    updateModuleInfo({
      moduleId: moduleToProceedWith.id,
      moduleName: moduleToProceedWith.name,
      moduleCode: moduleToProceedWith.moduleCode,
      moduleType: "newModule",
      numberOfPages: pageCount,
      creationMethodType: "denovo",
    });
    history.push({
      pathname: routes.addPrimaryClaim,
      state: {
        brandName: addedGenericInfo.brand,
        isCategoriesAdded: moduleToProceedWith.coreCategoriesFlag,
      },
    });
  };
  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };
  const isNotDenovoModule =
    getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
    moduleTypes.denovo;
  return (
    <>
      <div className="addBusinessRulesHeading">
        <span>Create Module</span>
        <h2
          className={
            !isNotDenovoModule
              ? "addBusinessRulesSubTitle d-none"
              : "addBusinessRulesSubTitle"
          }
        >
          Campaign Name : {campaignName}
        </h2>
        <CircularProgressBar isloading={isloading} />
      </div>
      <Row>
        <Col xs="12" md="6" lg="6">
          <h2 className="createModalSubHeading">Define Business Rules</h2>
        </Col>
        <Col xs="12" md="6" lg="6" className="doubleClick">
          <img
            src={doubleClickIcon}
            alt="Double Click"
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          Double Click on the module to apply business rules
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs="12" md="6" lg="6">
          {selectedModule().moduleCode} | {selectedModule().name}
        </Col>
        <Col xs="12" md="6" lg="6" className="doubleClick">
          <input
            type="checkbox"
            name=""
            className="stand-alone-checkbox"
            id=""
            checked={isThisModuleStandalone()}
            onChange={(event) => {
              makeModuleStandalone(event.target.checked);
            }}
          ></input>
          <label className="stand-alone" htmlFor="">
            Standalone Module
          </label>
        </Col>
      </Row>
      <Row
        className="mb-3 ml-0 mr-0"
        style={{ borderBottom: "solid 1px #e1e1e1" }}
      >
        <Col xs="12" md="12" lg="12" className="module-tab">
          {moduleTabs &&
            moduleTabs.map((module, index) => {
              return (
                <button
                  key={module.moduleCode}
                  className={`btn-module ${
                    module.isSelected ? "btn-sel-module" : ""
                  }`}
                  onClick={() => {
                    selectModuleTab(module.id);
                  }}
                >
                  {" "}
                  {module.moduleCode}
                </button>
              );
            })}
        </Col>
      </Row>

      <Row className="mb-3 addBsRlesRow customScrollBar">
        <Col xs="12" md="3" lg="3" className="businessRulesBrdr">
          <SearchComponent
            modules={eligibleForBrulesModules}
            businessRules={selectedModule().businessRules}
            addBusinessRule={addBusinessRule}
            selectedBox={selectedBox}
            allowedToAddRule={!isThisModuleStandalone()}
            setContentExists={setContentExists}
            setCannotExistWith={setCannotExistWith}
          />
        </Col>
        <Col xs="12" md="9" lg="9">
          <Row className="bsRulesBoxContainer">
            <BusineesRulesCards
              boxKey="MustExistWith"
              boxTitle="Must Exist With"
              data={getAddedBusinessRules("MustExistWith")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="InCompatibleWith"
              boxTitle="Cannot Exist With"
              data={getAddedBusinessRules("InCompatibleWith")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="Precedes"
              boxTitle="Precedes"
              data={getAddedBusinessRules("Precedes")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="Succeeds"
              boxTitle="Succeeds"
              data={getAddedBusinessRules("Succeeds")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              allowedToAddRule={!isThisModuleStandalone()}
              customCName="rulexBox0"
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="RelatedTo"
              boxTitle="Related Modules"
              data={getAddedBusinessRules("RelatedTo")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              allowedToAddRule={!isThisModuleStandalone()}
              customCName="rulexBox0"
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <div
              id="instructions-guidelines"
              className="instructions-box rulexBox0 mb-3  col-lg-4 col-md-4 col-12"
            >
              {getGuidelineText() && getGuidelineText() !== "" && (
                <span className="guidelines-editor-label">
                  {i18next.t("additionalInfo.usageOrGuidelines")}
                </span>
              )}
              <FroalaEditor
                forwardedRef={frInstanceInstructions}
                placeHolder={i18next.t("additionalInfo.usageOrGuidelines")}
                selectedAtom={getGuidelineText() || ""}
                handleModelChange={handleChangeGuideLiines}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <div className="dmodalBtnRow">
        <button
          className="btn btn-link btn-add-another-placeholder"
          onClick={() => {
            handleCloseCreateModuleModal();
          }}
        >
          CLOSE
        </button>
        <div style={{ float: "right", marginRight: "8px" }}>
          <button
            className="btn btn-link"
            onClick={(event) => {
              skipToAssetBrowsingPage();
            }}
          >
            SKIP
          </button>
          <button
            className="btn btn-primary"
            //disabled
            onClick={(event) => {
              saveBusinessRules();
            }}
          >
            Save and Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default AddBussinessRules;
