//Import React components
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

//Import Custom components
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";

//Import pais and helpers
import { downloadPdfFromByteArray, routes } from "../../Uitls/Util";

//Import icon and images
import videoIcon from "../../images/videoColor.svg";

//Import Styles
import "../../styles/components/CreateModuleNav.scss";
import "../../styles/components/ModuleDetails.scss";
import "../../styles/components/ModuleDetails.scss";
import { getLatestAnnotatedPdfFromVeeva } from "../../services/apis";
import CircularProgressBar from "../../Uitls/CircularProgressBar";

function MlrFeedbackNav({
  module,
  disableDone,
  currentPage,
  onEditName,
  handleDone,
  handlePageClose,
}) {
  const { t } = useTranslation();

  const [activeClasses, setActiveClasses] = useState([]);
  const inputModuleName = document.getElementById("edit-module-name");
  const [startEditing, setstartEditing] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (inputModuleName) {
      resize();
    }
  }, []);

  useEffect(() => {
    if (activeClasses.length > 0) {
      let currentSpan = undefined;
      activeClasses[activeClasses.length - 1] = "";
      const pages = Object.values(routes);
      const currentOptIndex = activeClasses.length - 1;
      switch (currentOptIndex) {
        case pages.indexOf(routes.additionalInfo):
          activeClasses[activeClasses.length - 1] = "active";
          break;
        case pages.indexOf(routes.addBusinessRules):
          currentSpan = document.getElementById("nav-opt-brules");
          break;
        // case pages.indexOf(routes.addChannels):
        //   currentSpan = document.getElementById("nav-opt-channels");
        //   break;
        case pages.indexOf(routes.addGroups):
          currentSpan = document.getElementById("nav-opt-group");
          break;
        case pages.indexOf(routes.addPrimaryClaim):
          currentSpan = document.getElementById("nav-opt-claim");
          break;
        case pages.indexOf(routes.assetDetails):
          currentSpan = document.getElementById("nav-opt-import");
          break;
        default:
          break;
      }
      if (currentSpan) {
        currentSpan.className = "selected-nav-item";
      }
    }
  }, [activeClasses]);

  const resize = (isEditing = false) => {
    if (isEditing) {
      inputModuleName.style.width = " 172px";
      return;
    }

    const text = inputModuleName.value;
    if (text.trim().length * 7 < 172) {
      inputModuleName.style.width = text.trim().length * 7 + 9 + "px";
    } else {
      inputModuleName.style.width = " 172px";
    }
  };

  useEffect(() => {
    const pages = Object.values(routes);
    let activeClasses = [];
    switch (currentPage) {
      case routes.additionalInfo:
        activeClasses[pages.indexOf(routes.additionalInfo)] = "active";
      case routes.addBusinessRules:
        activeClasses[pages.indexOf(routes.addBusinessRules)] = "active";
      // case routes.addChannels:
      //   activeClasses[pages.indexOf(routes.addChannels)] = "active";

      case routes.addGroups:
        activeClasses[pages.indexOf(routes.addGroups)] = "active";

      case routes.addPrimaryClaim:
        activeClasses[pages.indexOf(routes.addPrimaryClaim)] = "active";

      case routes.assetDetails:
        activeClasses[pages.indexOf(routes.assetDetails)] = "active";
        break;
      case routes.additionalInfo:
        activeClasses[pages.indexOf(routes.additionalInfo)] = "active";
        break;
      default:
        break;
    }
    setActiveClasses(activeClasses);
  }, [currentPage]);

  const getCssClassToHighLight = (page) => {
    const pages = Object.values(routes);
    if (activeClasses && activeClasses.length > 0) {
      const cssClass = activeClasses[pages.indexOf(page)];
      return cssClass || "";
    }
    return "";
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const downloadModuleFromVeeva = () => {
    if (!module || module.moduleMlr.length === 0) {
      return;
    }

    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (!veevaDocId) {
      return;
    }
    setIsloading(true);
    getLatestAnnotatedPdfFromVeeva(veevaDocId, (response, err, errorCode) => {
      setIsloading(false);
      if (err) {
        //setIsloading(false);
        showError(errorCode);
      } else {
        downloadPdfFromByteArray([response.data], veevaDocId);
      }
    });
  };

  return (
    <>
      <Container fluid>
        <CircularProgressBar isloading={isLoading} />
        <Row className="mt-2 align-items-center">
          <Col>
            <div className="d-flex">
              {module && (
                <div className="createModuleTitle">
                  {/* <p> */}
                  <span id="hide" style={{ fontSize: "0px" }}></span>
                  <input
                    id="edit-module-name"
                    defaultValue={module.name || ""}
                    className="input-module-name createModuleTitle"
                    type="text"
                    fluid="true"
                    readOnly={true}
                    onDoubleClick={(event) => {
                      if (onEditName) {
                        event.target.readOnly = false;
                        event.target.className =
                          "input-module-name createModuleTitle input-module-name-editable";
                        setstartEditing(true);
                        resize(true);
                      }
                    }}
                    onBlur={(event) => {
                      if (onEditName) {
                        event.target.readOnly = true;
                        onEditName(event.target.value);
                        event.target.className =
                          "input-module-name createModuleTitle";
                      }
                      setstartEditing(false);
                    }}
                    onKeyUp={(event) => {
                      if (onEditName && event.key === "Enter") {
                        event.target.readOnly = true;
                        onEditName(event.target.value);
                        event.target.className =
                          "input-module-name createModuleTitle";
                        setstartEditing(false);
                      }
                    }}
                  />
                  {/* </p> */}
                  {module.moduleCode && (
                    <span>
                      | {""} {module.moduleCode}
                    </span>
                  )}
                </div>
              )}
              <div className="videoIconCm">
                <img src={videoIcon} alt="" />
                <span>View Tutorial</span>
              </div>
            </div>
          </Col>
          <Col className="text-right">
            <div className="d-flex justify-content-end">
              <Button
                variant=" mr-4 download-module nav-buttons-margin"
                onClick={downloadModuleFromVeeva}
              >
                {i18next.t("generalText.downloadModule")}
              </Button>

              <Button
                variant="outline-secondary mr-4 btnClose nav-buttons-margin"
                onClick={handlePageClose}
              >
                {i18next.t("generalText.close")}
              </Button>

              <Button
                variant="primary btnProceed"
                disabled={disableDone}
                onClick={handleDone}
              >
                {i18next.t("generalText.done")}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ paddingRight: "0px", paddingLeft: "0px" }}>
            <ul className="createModuleNav">
              <li className={getCssClassToHighLight(routes.assetDetails)}>
                <span id="nav-opt-import" className="selected-nav-item">
                  1. Import{" "}
                </span>
              </li>
              <li className={getCssClassToHighLight(routes.addPrimaryClaim)}>
                <span id="nav-opt-claim">2. {t("primaryClaim.navLabel")} </span>
              </li>
              <li className={getCssClassToHighLight(routes.addGroups)}>
                <span id="nav-opt-group">3. Grouping </span>
              </li>
              {/* <li className={getCssClassToHighLight(routes.addChannels)}>
                <span id="nav-opt-channels">4. Channel </span>
              </li> */}
              <li className={getCssClassToHighLight(routes.addBusinessRules)}>
                <span id="nav-opt-brules">4. Business Rules</span>
              </li>
              <li className={getCssClassToHighLight(routes.additionalInfo)}>
                <span id="nav-opt-addinfo">5. Additional Info</span>
              </li>
              {/* <li>7. Labelling & Mandatory Info</li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MlrFeedbackNav;
