import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import { isValidHttpUrl, checkURL } from "../../Uitls/Util";
import "../../styles/components/SupportingVisual.scss";
import WarningIcon from "../../images/Warningicon.svg";
import { uploadAnImageLink } from "../../services/apis";
import { toast } from "react-toastify";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
const UploadOnlineImageModal = (props) => {
  const { t } = useTranslation();
  const {
    hideOnlineurlModal,
    setUploadedFileName,
    uploadedFileName,
    currentImageReplaceLocal,
    addImage,
    rowIndex,
    setSelectedDiv,
  } = props;
  const [uploadUrl, setUploadUrl] = useState("");
  const [wrongUrl, setWrongUrl] = useState(false);
  const [uploadDisable, setUploaddisable] = useState(false);
  const handleChange = (event) => {
    const url = event.target.value;
    setUploadUrl(url);
    setWrongUrl(false);
    setUploaddisable(url.trim() === "");
  };
  const handleImageurlUpload = () => {
    setSelectedDiv("");
    if (
      !isValidHttpUrl(uploadUrl) ||
      uploadUrl === "" ||
      !checkURL(uploadUrl)
    ) {
      setWrongUrl(true);
      setUploaddisable(true);
      return;
    }
    const urlData = {
      imageUrl: uploadUrl,
      assetType: "Atom",
      source: "ModuleManager",
    };

    uploadAnImageLink(urlData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
        setUploaddisable(true);
      } else {
        const res = response?.data;
        setUploaddisable(false);
        addImage(
          rowIndex,
          "update",
          currentImageReplaceLocal,
          res?.assetUrl,
          res?.id
        );
        setUploadedFileName(res?.assetUrl);
        hideOnlineurlModal();
        setSelectedDiv("imageUrl");
      }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-12 uploadModaltitle">Upload : Image URL</div>
      </div>
      <div className="textFieldContainer">
        <TextField
          className="uploadUrl"
          id="moduleName"
          variant="outlined"
          fullWidth={true}
          placeholder="Type here ..."
          //inputProps={{ style: { textAlign: "left", paddingTop: "2px" } }}
          InputProps={{
            className: "uploadUrlInput",
            classes: { input: "hiddenScrollbar" },
          }}
          multiline
          rows={2}
          onChange={handleChange}
          value={uploadUrl}
        />
      </div>
      <div className="warnignIcon">
        {wrongUrl ? (
          <>
            {" "}
            <img src={WarningIcon} alt="" className="img-fluid" />
            <span className="wrongUrl">Wrong URL </span>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="row modalfooter">
        <div className="col-md-9 d-flex ">
          <button
            type="button"
            className="btn btn-link text-uppercase mr-3 "
            onClick={hideOnlineurlModal}
          >
            CANCEL
          </button>
        </div>
        <div className="col-md-3 d-flex ">
          <button
            type="button"
            className="btn btn-primary uploadbtn"
            disabled={uploadUrl.length > 0 && !uploadDisable ? false : true}
            onClick={handleImageurlUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};
export default UploadOnlineImageModal;
