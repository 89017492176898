import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ContentMatrixModalFooter = (props) => {
  const {
    hideContentMatrixModal,
    buttonsData,
    proceedToNextScreen,
    footerContent,
    disablBtn,
    previousPage,
  } = props;
  const { t } = useTranslation();
  const [moduleContentData, setModuleContentData] = useState(false);

  return (
    <div className="row align-items-center martrixModalFooter matrxiFooterBrdr no-gutters">
      <div className="col-md-5">{footerContent}</div>
      <div className="col-md-7 text-right">
        <button
          onClick={() => {
            if (previousPage) {
              proceedToNextScreen(previousPage, true);
              return;
            }
            hideContentMatrixModal();
          }}
          className="btn btn-link"
        >
          {buttonsData.closeBtnLabel}
        </button>
        {buttonsData.actionBtnLabel && (
          <button
            className="btn btn-primary"
            onClick={() => proceedToNextScreen(buttonsData.actionType)}
            disabled={disablBtn}
          >
            {buttonsData.actionBtnLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default ContentMatrixModalFooter;
