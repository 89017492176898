import React from "react";
import parse from "html-react-parser";

const TranslateReferences = ({ references, isAnyToggleOn }) => {
  const renderReferences = () => {
    if (references.length > 0) {
      if (references && references.length > 0) {
        return references.map((reference) => (
          <div className="d-flex" disabled={true} key={reference.id}>
            <div className="referenceBoxIndex">
              <span className="d-inline-block refNumberDisplay">
                {parse(reference.prefix)}
              </span>
            </div>

            {
              <div className="transReferenceBoxLabelN">
                {/* {isAnyToggleOn
                  ? reference.originalText && parse(reference.originalText)
                  : reference.text && parse(reference.text)} */}
                {reference.text && parse(reference.text)}
              </div>
            }
          </div>
        ));
      }
    }
    return <div></div>;
  };

  return (
    <div className="translateBox translateBoxType1">
      <p className="translateBox-head d-flex justify-content-between">
        References / Annotations <span className="refStar m-0 mt-1">*</span>
      </p>
      {renderReferences()}
    </div>
  );
};

export default TranslateReferences;
