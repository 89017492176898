import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Row, Col } from "react-bootstrap";
import CustomModal from "../customModal";
import FrolaTextEditor from "../../Uitls/CommonFroalEditorComponent";
import BusinessRulesBox from "./BusinessRulesBox";

const SupportingGroup = (props) => {
  const [rulesDetailsModal, setRulesDetailsModal] = useState(false);
  const [updatedRulesDetails, setUpdatedRulesDetails] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const addDetailsValid = useRef(null);

  const { t } = useTranslation();

  const {
    row,
    setSelectedBox,
    handleDelete,
    selectedBoxIndex,
    businessRulesNote,
    handleBusinessRulesSave,
    contentExists,
    setContentExists,
    allowedToAddRule,
    setCannotExistWith,
    cannotExistWith,
  } = props;

  const boxData = [...row];

  let formattedDataMust = boxData.filter(function (item) {
    return item.condition === "MustExistWith";
  });

  let formattedDataInCompatible = boxData.filter(function (item) {
    return item.condition === "InCompatibleWith";
  });

  let formattedDataRelatedTo = boxData.filter(function (item) {
    return item.condition === "RelatedTo";
  });

  let formattedDataPrecedes = boxData.filter(function (item) {
    return item.condition === "Precedes";
  });

  let formattedDataSucceeds = boxData.filter(function (item) {
    return item.condition === "Succeeds";
  });

  const showModal = (selectedBox, modalTitle) => {
    if (selectedBox) {
      let filteredData = businessRulesNote.filter(function (item) {
        return item.noteCondition === selectedBox;
      });

      const label = filteredData[0] && filteredData[0].note;

      setUpdatedRulesDetails(label);
    }
    setRulesDetailsModal(true);
    setModalTitle(modalTitle);
  };

  const hideModal = () => {
    setRulesDetailsModal(false);
  };

  useEffect(() => {
    if (selectedBoxIndex) {
      let formattedDataMust = businessRulesNote.filter(function (item) {
        return item.noteCondition === selectedBoxIndex;
      });
      const label = formattedDataMust[0] && formattedDataMust[0].note;
      setUpdatedRulesDetails(label);
    }
  }, [row, businessRulesNote]);

  const editRulesTextChanges = (updatedText) => {
    // setUpdatedRulesDetails(updatedText);
    addDetailsValid.current.innerHTML = updatedText;
    const text = addDetailsValid.current.innerText;
    if (text && text.trim().length) {
      setUpdatedRulesDetails(updatedText);
    } else {
      setUpdatedRulesDetails("");
    }
  };

  return (
    <>
      <BusinessRulesBox
        boxKey="MustExistWith"
        boxTitle="Must Exist With (Mandatory)"
        data={formattedDataMust}
        selectedBoxIndex={selectedBoxIndex}
        setSelectedBox={setSelectedBox}
        showModal={showModal}
        handleDelete={handleDelete}
        businessRulesNote={businessRulesNote}
        contentExists={contentExists}
        setContentExists={setContentExists}
        allowedToAddRule={allowedToAddRule}
        setCannotExistWith={setCannotExistWith}
        cannotExistWith={cannotExistWith}
      />

      <BusinessRulesBox
        boxKey="InCompatibleWith"
        boxTitle="Cannot Exist With"
        data={formattedDataInCompatible}
        selectedBoxIndex={selectedBoxIndex}
        setSelectedBox={setSelectedBox}
        showModal={showModal}
        handleDelete={handleDelete}
        businessRulesNote={businessRulesNote}
        contentExists={contentExists}
        setContentExists={setContentExists}
        allowedToAddRule={allowedToAddRule}
        setCannotExistWith={setCannotExistWith}
        cannotExistWith={cannotExistWith}
      />

      <BusinessRulesBox
        boxKey="Precedes"
        boxTitle="Precedes"
        data={formattedDataPrecedes}
        selectedBoxIndex={selectedBoxIndex}
        setSelectedBox={setSelectedBox}
        showModal={showModal}
        handleDelete={handleDelete}
        businessRulesNote={businessRulesNote}
        contentExists={contentExists}
        setContentExists={setContentExists}
        allowedToAddRule={allowedToAddRule}
        setCannotExistWith={setCannotExistWith}
        cannotExistWith={cannotExistWith}
      />

      <BusinessRulesBox
        boxKey="Succeeds"
        boxTitle="Succeeds"
        data={formattedDataSucceeds}
        selectedBoxIndex={selectedBoxIndex}
        setSelectedBox={setSelectedBox}
        showModal={showModal}
        handleDelete={handleDelete}
        businessRulesNote={businessRulesNote}
        contentExists={contentExists}
        setContentExists={setContentExists}
        allowedToAddRule={allowedToAddRule}
        setCannotExistWith={setCannotExistWith}
        cannotExistWith={cannotExistWith}
      />

      <BusinessRulesBox
        boxKey="RelatedTo"
        boxTitle="Related Modules (Optional)"
        data={formattedDataRelatedTo}
        selectedBoxIndex={selectedBoxIndex}
        setSelectedBox={setSelectedBox}
        showModal={showModal}
        handleDelete={handleDelete}
        businessRulesNote={businessRulesNote}
        contentExists={contentExists}
        setContentExists={setContentExists}
        allowedToAddRule={allowedToAddRule}
        setCannotExistWith={setCannotExistWith}
        cannotExistWith={cannotExistWith}
      />

      <CustomModal
        displayModal={rulesDetailsModal}
        hideModal={hideModal}
        title={"Is this a stand alone module"}
        size="lg"
        dilogClassName=""
      >
        <h1 className="modalTitle rulesModalCustomTitle">
          Add details - {modalTitle && modalTitle}
        </h1>
        <div className="modalEditorContainer bRulesEditor-space">
          <FrolaTextEditor
            placeHolder="Add Details"
            selectedAtom={updatedRulesDetails}
            handleModelChange={editRulesTextChanges}
          />
        </div>
        <div className="d-flex justify-content-between rulesButnCustomRow">
          <button className="btn btn-link pl-0" onClick={hideModal}>
            CLOSE
          </button>
          <button
            className="btn btn-primary btnBrulesSave"
            onClick={() => {
              handleBusinessRulesSave(updatedRulesDetails);
              hideModal();
            }}
            disabled={
              updatedRulesDetails && updatedRulesDetails.trim() ? false : true
            }
          >
            SAVE
          </button>
        </div>
      </CustomModal>
      <div className="d-none" ref={addDetailsValid}></div>
    </>
  );
};

export default SupportingGroup;
