//Import React dependency
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//Import Custom Components
import TabPanel from "../../../Uitls/TabPanel";
import ContentModules from "./ContentModules";
import ModularContentMatrices from "./ModularContentMatrices";
import CustomModal from "../../customModal";
import ApplyBusinessrules from "./ApplyBusinessRules";
import UsageGuidelines from "./UsageGuidelines";
import ContentMatrixTab from "../../ContentMatrix/ContentMatrixTab";
import errComponentToRender from "../../../Uitls/apiErrorHandling";
import {
  getBusinessRulesForVeeva,
  saveBusinessRulesForVeeva,
  getTemplateTypes,
  getContentmatrix,
  searchAssetsFromDamsInMM,
} from "../../../services/apis";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";
import ContentMatrixModal from "../../../components/ContentMatrix/ContentMatrixModal";
import ChannelSelectionComponent from "../../ContentMatrix/ChannelSelectionModal";
import BusinessRulesValidation from "../../ContentMatrix/BusinessRulesValidation";

const VeevaDefaultPage = ({
  modules,
  sourceType,
  assetDataType,
  setAssetsAndModules,
  brandsList,
  updateContentModulesMatrix,
}) => {
  const { t } = useTranslation();
  const buttonList = [
    { btnLabel: "Apply Business Rules", type: "businessRules" },
    { btnLabel: "View", type: "view" },
  ];
  //Variables for business rules validation
  // const [isCreateContentMatriCtaClicked, setIsCreateContentMatrixCtaClicked] =
  //   useState(false);
  const [selectedContentModuleId, setSelectedContentModuleId] =
    useState(undefined);
  const [selectedMmId, setSelectedMmId] = useState(undefined);
  const [cannotExistModuleIds, setCannotExistModuleIds] = useState([]);
  const [openBusinesRulesModal, setOpenBusinesRulesModal] = useState(false);
  const [businessRulesData, setBusinessRulesData] = useState(undefined);
  const [moduleNameAndCampaign, setModuleNameAndCampaign] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [matrixModalData, setMatrixModalData] = useState([]);
  const [contentMatrix, setContentMatrix] = useState(false);
  const [selectedModuleData, setSelectedModuleData] = useState({});
  const [openUsageInstructionModal, setOpenUsageInstructionModal] =
    useState(false);

  //For newly introduced channel selection modal
  const [channelSelectionModal, setChannelSelectionModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [isBRulesValidationOpen, setBRulesValidationOpen] = useState(false);
  const [modulesForBRulesValidation, setModulesForBRulesValidation] = useState(
    []
  );

  const hideContentMatrixModal = () => {
    setSelectedContentModuleId(undefined);
    setSelectedMmId(undefined);
    setSelectedModuleData({});
    setCannotExistModuleIds([]);
    setContentMatrix(false);
  };
  const moduleCardBtnActions = (actionType, module) => {
    switch (actionType) {
      case "view":
        window.open(module.contentModuleUiUrl, "_blank");

        break;
      case "businessRules":
        getBusinessRules(module);
        setModuleNameAndCampaign({
          name: module.moduleName,
          campaign: module.campaign,
          cmId: module.cmId,
          id: module.id,
        });
        break;
      default:
        break;
    }
  };

  const getBusinessRules = (module) => {
    let payLoad = {
      brand: module.brand,
      campaign: module.campaign,
      excludeCmIds: [module.cmId],
    };
    setIsloading(true);
    getBusinessRulesForVeeva(
      module.cmId,
      payLoad,
      (response, err, errorCode) => {
        if (err) {
          if (errorCode) {
            showError(errorCode);
          }
        } else {
          const data = response.data;
          setBusinessRulesData(data);
          setOpenBusinesRulesModal(true);
        }
        setIsloading(false);
      }
    );
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  // const startBRulesValidation = (flag) => {
  //   tabPanelTabChange.current(undefined, 0);
  //   setIsCreateContentMatrixCtaClicked(flag);
  // };

  // const onModuleSelect = (contentModule) => {
  //   if (isCreateContentMatriCtaClicked) {
  //     if (selectedContentModuleId === contentModule.id) {
  //       setSelectedContentModuleId(undefined);
  //       setSelectedMmId(undefined);
  //       setSelectedModuleData({});
  //       setCannotExistModuleIds([]);
  //     } else {
  //       setSelectedContentModuleId(contentModule.id);
  //       setSelectedMmId(contentModule.cmId);
  //       setSelectedModuleData(contentModule);
  //       // let cannotExistBussinessModuleIds = [
  //       //   ...contentModule.cannotExistRuleList,
  //       // ];
  //       // setCannotExistModuleIds(cannotExistBussinessModuleIds);
  //       let cannotExistBussinessModuleIds = [];
  //       modules.forEach((item) => {
  //         let result = item.cannotExistRuleList?.indexOf(contentModule.cmId);
  //         if (result > -1) {
  //           cannotExistBussinessModuleIds.push(item.cmId);
  //         }
  //       });
  //       setCannotExistModuleIds(cannotExistBussinessModuleIds);
  //     }
  //   }
  // };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleContentMatrixModal = () => {
    setIsloading(true);

    getContentmatrix(
      selectedMmId,
      sourceType,
      assetDataType,
      (response, err, errCode) => {
        if (err) {
          //  setIsloading(false);
          const errCoponent = errComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          setMatrixModalData(response.data);
          getTemplateTypesData();
          //   setIsloading(false);
        }
      }
    );
  };

  const getTemplateTypesData = (id) => {
    setIsloading(true);
    // getTemplateTypes(moduleDetails.brandName, (res, err, errCode) => {
    getTemplateTypes(
      selectedModuleData.brand,
      sourceType,
      assetDataType,
      (res, err, errCode) => {
        setIsloading(false);
        if (!res) {
          showError(errCode);
          return;
        }
        setTemplates(() => {
          setContentMatrix(true);
        });
        setTemplates(res.data);
      }
    );
  };

  // const footerButtons = (
  //   <div className="row pt-3 pb-3 createGroupResultBtnRow">
  //     <div className="col-md-6">
  //       <p>
  //         {selectedContentModuleId ? "" : "Select Module to begin"}
  //         {cannotExistModuleIds && cannotExistModuleIds.length > 0 ? (
  //           <span className="complianceError">
  //             Modules highlighted in red have a CANNOT EXIST business rule with
  //             the selected module. Click the View CTA on the module card for
  //             details.
  //           </span>
  //         ) : (
  //           ""
  //         )}
  //       </p>
  //     </div>
  //     <div className="col-md-6 text-right">
  //       <button
  //         className="btn btn-link text-uppercase"
  //         onClick={() => {
  //           setIsCreateContentMatrixCtaClicked(false);
  //           setSelectedContentModuleId(undefined);
  //           setCannotExistModuleIds([]);
  //           setSelectedMmId(undefined);
  //           setSelectedModuleData({});
  //           tabPanelTabChange.current(undefined, 1);
  //         }}
  //       >
  //         cancel
  //       </button>
  //       <button
  //         className="btn btn-primary text-uppercase"
  //         disabled={!selectedContentModuleId}
  //         onClick={handleContentMatrixModal}
  //       >
  //         Proceed
  //       </button>
  //     </div>
  //   </div>
  // );

  const contentModules = (
    <>
      <ContentModules
        modules={modules}
        buttonList={buttonList}
        moduleCardBtnActions={moduleCardBtnActions}
        selectedContentModuleIds={[selectedContentModuleId]}
        cannotExistModuleIds={cannotExistModuleIds}
      />
      {/* {isCreateContentMatriCtaClicked && footerButtons} */}
    </>
  );

  const getContentMatrices = () => {
    let contentMatrices = [];
    modules.forEach((module) => {
      contentMatrices = contentMatrices.concat(module.contentMatrices);
    });
    const sorter = (a, b) => {
      return (
        new Date(b.createdDateTime).getTime() -
        new Date(a.createdDateTime).getTime()
      );
    };
    return contentMatrices.sort(sorter);
  };

  const contentModulesMatrix = () => (
    <>
      <ContentMatrixTab
        matrixData={getContentMatrices()}
        setChannelSelectionModal={setChannelSelectionModal}
        sourceType={sourceType}
        assetDataType={assetDataType}
        modules={modules}
      />
    </>
  );

  const tabPanelTabChange = React.useRef(null);
  const renderTabs = () => {
    let titles = [
      `Modules (${modules.length})`,
      `${t("channelMatrix.channelMatrices")} (${getContentMatrices().length})`,
    ];
    let children = [contentModules, contentModulesMatrix];

    return (
      <TabPanel
        titles={titles}
        children={children}
        tabPanelTabChange={tabPanelTabChange}
        notifyParentForTabChange={() => {
          // setIsCreateContentMatrixCtaClicked(false);
          setSelectedContentModuleId(undefined);
          setCannotExistModuleIds([]);
          setSelectedMmId(undefined);
          setSelectedModuleData({});
        }}
      />
    );
  };

  const hideBusinessRulesModal = () => {
    setOpenBusinesRulesModal(false);
    setBusinessRulesData(undefined);
  };

  const handleSaveBusinessRules = (bRuleData, type) => {
    setIsloading(true);
    let payLoad = [
      {
        cmId: moduleNameAndCampaign.cmId,
        businessRules: bRuleData,
      },
    ];
    saveBusinessRulesForVeeva(payLoad, (response, err, errorCode) => {
      if (err) {
        if (errorCode) {
          showError(errorCode);
        }
      } else {
        if (type === "save") {
          setBusinessRulesData(undefined);
          setOpenBusinesRulesModal(false);
          toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
            autoClose: 2000,
            position: "top-center",
          });
        }
      }
      setIsloading(false);
    });
  };
  const openUsageGuidelineModal = () => {
    setOpenUsageInstructionModal(true);
  };
  const closeUsageGuidelineModal = () => {
    setOpenUsageInstructionModal(false);
  };

  const updateAssetModules = (
    InCompatibleWithModuleIds,
    connotExistsIdsArray
  ) => {
    let assetModules = [...modules];
    const index = assetModules.findIndex(
      (item) => item.cmId === moduleNameAndCampaign.cmId
    );
    if (index > -1) {
      let connotExistsIds = [...connotExistsIdsArray];
      if (InCompatibleWithModuleIds.length === 0) {
        assetModules[index].cannotExistRuleList = [];
        setAssetsAndModules(assetModules);
      } else {
        InCompatibleWithModuleIds.forEach((item) => {
          let indexResult = connotExistsIds.findIndex(
            (ele) => ele === item.targetCmId
          );
          if (indexResult < 0) {
            connotExistsIds.push(item.targetCmId);
          }
        });
        assetModules[index].cannotExistRuleList = connotExistsIds;
        setAssetsAndModules(assetModules);
      }
      if (selectedContentModuleId && selectedContentModuleId) {
        let cannotExistBussinessModuleIds = [];
        assetModules.forEach((item) => {
          let result = item.cannotExistRuleList?.indexOf(selectedMmId);
          if (result > -1) {
            cannotExistBussinessModuleIds.push(item.cmId);
          }
        });
        setCannotExistModuleIds(cannotExistBussinessModuleIds);
      }
    }
  };

  const showBusinessRulesModal = () => {
    return (
      <CustomModal
        displayModal={openBusinesRulesModal}
        hideModal={hideBusinessRulesModal}
        size="xl"
        dilogClassName="createPlaceholderModal"
        backdrop={false}
      >
        {openUsageInstructionModal ? (
          <UsageGuidelines
            handleClose={closeUsageGuidelineModal}
            usageGuidelinesData={
              businessRulesData[0]?.cmBusinessRuleList[0]
                ?.usageInstructionsGuidelines
            }
          />
        ) : (
          <ApplyBusinessrules
            hideBusinessRulesModal={hideBusinessRulesModal}
            moduleNameAndCampaign={moduleNameAndCampaign}
            modulesData={businessRulesData}
            saveBusinessRules={handleSaveBusinessRules}
            handleUsageGuidelineModal={openUsageGuidelineModal}
            updateAssetModules={updateAssetModules}
            modules={modules}
          />
        )}
      </CustomModal>
    );
  };

  const closeChannelSelectionModal = () => {
    setSelectedChannel(undefined);
    setChannelSelectionModal(false);
    setSelectedBrand(undefined);
    //setModuleIdToCreateMatrix(undefined);
  };
  const cancelCreatingChannelMatrix = (data) => {
    if (data) {
      updateContentModulesMatrix(data);
    }
    setBRulesValidationOpen(false);
    setModulesForBRulesValidation([]);
    closeChannelSelectionModal();
    //startBRulesValidation(false);
    //setModuleIdToCreateMatrix(undefined);
  };

  const fetchContentModulesForSelectedBrand = (callback) => {
    let searchParams = {
      brands: [selectedBrand],
      channelTypes: [],
      markets: [],
      campaigns: [],
      searchText: "",
    };
    setIsloading(true);
    searchAssetsFromDamsInMM(
      searchParams,
      sourceType,
      assetDataType,
      (response, err, errorCode) => {
        setIsloading(false);
        let data = modules;
        if (response) {
          data = response.data;
        }
        data = [...data].filter((el) => {
          return el.brand.toLowerCase() === selectedBrand.toLowerCase();
        });
        setModulesForBRulesValidation(() => {
          callback();
        });
        setModulesForBRulesValidation(data);
      }
    );
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      {renderTabs()}
      {openBusinesRulesModal && showBusinessRulesModal()}
      {contentMatrix && (
        <CustomModal
          displayModal={contentMatrix}
          hideModal={hideContentMatrixModal}
          title={"List of Associated Modules"}
          size="xl"
          dilogClassName="alertModalSmall"
          // backdrop="false"
          keyboard={false}
        >
          <ContentMatrixModal
            hideContentMatrixModal={hideContentMatrixModal}
            moduleDetails={{
              brandName: selectedModuleData.brand,
              campaign: selectedModuleData.campaign
                ? selectedModuleData.campaign
                : "",
              moduleCode: selectedModuleData.moduleName,
            }}
            templates={templates}
            matrixData={matrixModalData}
            sourceType={selectedModuleData.sourceType}
            assetDataType={selectedModuleData.assetDataType}
          />
        </CustomModal>
      )}

      <CustomModal
        displayModal={channelSelectionModal}
        hideModal={closeChannelSelectionModal}
        size="sm"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <ChannelSelectionComponent
          closeChannelSelectionModal={closeChannelSelectionModal}
          sourceType={sourceType}
          brand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          brands={brandsList}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          cancelCreatingChannelMatrix={cancelCreatingChannelMatrix}
          assetDataType={assetDataType}
          //moduleId={moduleIdToCreateMatrix}
          startBusinessRulesValidation={(flag) => {
            fetchContentModulesForSelectedBrand(() => {
              setBRulesValidationOpen(flag);
            });
          }}
        />
      </CustomModal>

      <CustomModal
        displayModal={isBRulesValidationOpen}
        hideModal={(flag) => {
          setBRulesValidationOpen(flag);
        }}
        size="xl"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <BusinessRulesValidation
          setBRulesValidationOpen={(flag) => {
            setBRulesValidationOpen(flag);
          }}
          modules={modulesForBRulesValidation}
          assetDataType={assetDataType}
          sourceType={sourceType}
          brand={selectedBrand}
          channelType={selectedChannel}
          cancelCreatingChannelMatrix={cancelCreatingChannelMatrix}
          //handleProceedForChannelSelection={handleProceedForChannelSelection}
        />
      </CustomModal>
    </>
  );
};

export default VeevaDefaultPage;
