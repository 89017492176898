import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";

const Optouts = ({
  optoutData,
  setEmailType,
  emailType,
  atomToBeUsed,
  setAtomToBeUsed,
  upDateOptOutData,
  deleteOptOut,
}) => {
  const [selectedOptout, setSelectedOptout] = useState(undefined);
  const [optouts, setOptOuts] = useState([]);
  const referencesValid = useRef(null);

  const getDefaultOptOut = (rowId = 0) => {
    const defaultOptout = {
      channelType: "Email",
      textType: "OptOut",
      id: uuidv4(),
      isNewlyAdded: true,
      moduleChannelSubtexts: [
        {
          id: uuidv4(),
          text: "",
          subTextType: "OptOutText",
        },
        {
          id: uuidv4(),
          text: "",
          subTextType: "OptOutVisual",
        },
      ],
    };
    return defaultOptout;
  };

  useEffect(() => {
    if (!optoutData || optoutData.length === 0) {
      setOptOuts([getDefaultOptOut()]);
    } else {
      setOptOuts(optoutData);
    }
  }, [optoutData]);
  useEffect(() => {
    if (selectedOptout && atomToBeUsed) {
      let newArray = [...optouts];

      let index = newArray.findIndex((element) => {
        return element.id === selectedOptout.id;
      });
      if (emailType === "OptOutText") {
        const { text, cmDocumentNumber } = atomToBeUsed;
        newArray[index].moduleChannelSubtexts[0].text = text;
        newArray[index].moduleChannelSubtexts[0].cmDocumentNumber =
          cmDocumentNumber;
        setOptOuts(newArray);
      } else if (emailType === "OptOutVisual") {
        const { document, cmDocumentNumber } = atomToBeUsed;
        newArray[index].moduleChannelSubtexts[1].text = document;
        newArray[index].moduleChannelSubtexts[1].cmDocumentNumber =
          cmDocumentNumber;
        setOptOuts(newArray);
      } else {
        return;
      }
    }
    setAtomToBeUsed(undefined);
  }, [atomToBeUsed]);
  useEffect(() => {
    if (!selectedOptout) {
      setEmailType(undefined);
      setAtomToBeUsed(undefined);
    }
  }, [selectedOptout]);

  const onBoxClicked = (optoutId, boxId) => {
    // if (selectedOptout) {
    //   if (selectedOptout.id === optoutId && selectedOptout.boxId === boxId) {
    //     setSelectedOptout(undefined);
    //     return;
    //   }
    // }
    setSelectedOptout({ id: optoutId, boxId: boxId });
  };
  const isBoxSelected = (optoutId, boxId) => {
    if (selectedOptout) {
      if (selectedOptout.id === optoutId && selectedOptout.boxId === boxId) {
        return true;
      }
    }
    return false;
  };

  const addNewOptout = () => {
    const data = [...optouts].concat(getDefaultOptOut(`${optouts.length}`));
    setOptOuts(data);
    //upDateOptOutData(data);
  };
  const removeLastOptout = () => {
    const data = [...optouts];
    const deletedOptOut = data.splice(optouts.length - 1, 1);
    removeOptout(deletedOptOut.length > 0 ? deletedOptOut[0].id : undefined);
  };

  const removeOptout = (optoutId) => {
    let data = [...optouts].filter((optout) => {
      return optout.id !== optoutId;
    });
    const optoutToBeDeleted = [...optouts].find((optout) => {
      return optout.id === optoutId;
    });
    upDateOptOutData(data);
    if (!optoutToBeDeleted.isNewlyAdded) {
      deleteOptOut([optoutId]);
    }
    setSelectedOptout(undefined);
    setEmailType(undefined);
    setAtomToBeUsed(undefined);
  };
  const getBoxData = (optout, type) => {
    let data = optout.moduleChannelSubtexts;
    data = data.filter((el) => {
      return el.subTextType.toLowerCase() === type;
    });
    if (data && data.length > 0) {
      return data[0];
    }
    return {};
  };
  const upDateOptOutText = (event, rowIndex) => {
    if (rowIndex < 0) {
      return;
    } else {
      referencesValid.current.innerHTML = event;
      const text = referencesValid.current.innerText;
      let newArray = [...optouts];
      if (text && text.trim().length) {
        newArray[rowIndex].moduleChannelSubtexts[0].text = event;
        setOptOuts(newArray);
        upDateOptOutData(newArray);
      } else {
        newArray[rowIndex].moduleChannelSubtexts[0].text = "";
        setOptOuts(newArray);
        upDateOptOutData(newArray);
      }
    }
  };
  return (
    <div className="optouts-container customScrollBar">
      {optouts.map((optout, index) => {
        return (
          <div className="optouts">
            <Row>
              <OptOutTextBox
                optout={getBoxData(optout, "optouttext")}
                index={index}
                onBoxClicked={(boxId) => {
                  onBoxClicked(optout.id, boxId);
                }}
                isSelected={isBoxSelected(
                  optout.id,
                  optout.moduleChannelSubtexts[0].id
                )}
                upDateOptOutText={upDateOptOutText}
                setEmailType={setEmailType}
              />
              <OptOutVisualBox
                optout={getBoxData(optout, "optoutvisual")}
                index={index}
                onBoxClicked={(boxId) => {
                  onBoxClicked(optout.id, boxId);
                }}
                isSelected={isBoxSelected(
                  optout.id,
                  optout.moduleChannelSubtexts[1].id
                )}
                setEmailType={setEmailType}
              />
            </Row>
            {index < optouts.length - 1 && (
              <Row>
                <Col className="col-md-6">
                  <button
                    className="remove btn btn-link"
                    onClick={(e) => {
                      removeOptout(optout.id);
                    }}
                  >
                    REMOVE
                  </button>
                </Col>
                <Col className="col-md-6"></Col>
              </Row>
            )}
          </div>
        );
      })}
      <Row>
        <Col className="col-md-6">
          <button
            className="remove btn btn-link"
            onClick={(e) => {
              removeLastOptout();
            }}
          >
            REMOVE
          </button>
        </Col>
        <Col className="col-md-6">
          <button
            className="add btn btn-link"
            onClick={(e) => {
              addNewOptout();
            }}
          >
            ADD
          </button>
        </Col>
      </Row>
      <div className="d-none" ref={referencesValid}></div>
    </div>
  );
};

export default Optouts;

export const OptOutTextBox = ({
  optout,
  index,
  onBoxClicked,
  isSelected = false,
  upDateOptOutText,
  setEmailType,
  title = undefined,
  readOnly = false,
}) => {
  const addHeading = () => {
    return (
      <span className="optout-head">{`${
        title ? title : `Opt-out Text -  ${index + 1}`
      }`}</span>
    );
  };
  return (
    <Col className="col-md-6">
      <div
        className={`optout-box ${isSelected ? "optout-box-selected" : ""}`}
        onClick={() => {
          if (!readOnly) {
            onBoxClicked(optout.id);
            setEmailType("OptOutText");
          }
        }}
      >
        {addHeading()}
        <div className=" optout-text customScrollBar">
          <FroalaEditor
            tag="textarea"
            selectedAtom={optout.text}
            handleModelChange={(event) => {
              upDateOptOutText(event, index);
            }}
            editable={readOnly ? "off" : undefined}
          />
        </div>
      </div>
    </Col>
  );
};

export const OptOutVisualBox = ({
  optout,
  index,
  onBoxClicked,
  isSelected = false,
  setEmailType,
  title = undefined,
  readOnly = false,
}) => {
  const addHeading = () => {
    return (
      <span className="optout-head">{`${
        title ? title : `Opt-out Visual -  ${index + 1}`
      }`}</span>
    );
  };
  return (
    <Col className="col-md-6">
      <div
        className={`optout-box  ${isSelected ? "optout-box-selected" : ""}`}
        onClick={() => {
          if (!readOnly) {
            onBoxClicked(optout.id);
            setEmailType("OptOutVisual");
          }
        }}
      >
        {addHeading()}
        <div className="optout-visual customScrollBar">
          <img src={optout.text} alt="" />
        </div>
      </div>
    </Col>
  );
};
