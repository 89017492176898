import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const CustomModal = (props) => {
  const {
    displayModal,
    hideModal,
    size,
    centered,
    dilogClassName,
    keyboard,
    enforceFocus = true,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(displayModal);
  }, [displayModal]);

  const setModalHide = () => {
    setShow(false);
    hideModal(false);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={setModalHide}
        size={size ? size : ""}
        centered={centered ? centered : "false"}
        className={dilogClassName}
        backdrop={props.backdrop || "static"}
        keyboard={keyboard}
        enforceFocus={enforceFocus}
      >
        <Modal.Body> {props.children}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
