// Import React depedencies

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import "../../styles/components/MarketingMessages.scss";

//Import styles
import "../../styles/components/MarketingMessages.scss";

const CreateHeader = ({ headerData, setHeaderData, editMode }) => {
  const defaultHeaders = {
    message: "",
    restriction: "",
    rule: "",
  };

  useEffect(() => {
    if (!headerData || headerData.length === 0) {
      setHeaderData([defaultHeaders]);
    }
  }, [headerData]);

  const updateHeaders = (header, currentIndex) => {
    const headers = [...headerData];

    headers[currentIndex] = header;

    setHeaderData(headers);
  };

  const addNewHeader = (newHeaders) => {
    const headers = [...headerData];
    setHeaderData(headers.concat(newHeaders));
  };
  const removeHeader = (atIndex) => {
    let headers = [...headerData];
    headers.splice(atIndex, 1);
    setHeaderData(headers);
  };
  // const [errors, setErrors] = useState(undefined);

  // const getTextsFromAllTheBoxes = () => {
  //   const message = document.getElementById("header-message").value;
  //   const restriction = document.getElementById(
  //     "header-restrictions-for-use"
  //   ).value;
  //   const rules = document.getElementById("rules-for-header").value;
  //   return {
  //     message: message,
  //     restriction: restriction,
  //     rules: rules,
  //   };
  // };

  // const onTextChange = (e, type) => {
  //   const text = e.target.value.trim();
  //   let errs = { ...errors };
  //   if (text.length > 250) {
  //     errs[type] = "More than 250 characters";
  //   } else {
  //     errs[type] = undefined;
  //   }
  //   setErrors(errs);
  //   let havingError =
  //     errs?.message || errs?.restriction || errs?.rules ? true : false;
  //   setHeaderData({ havingError, header: getTextsFromAllTheBoxes() });
  // };
  return (
    <>
      {headerData &&
        headerData.map((header, index) => {
          return (
            <CreateMultipleHeaders
              key={index}
              header={{ ...header }}
              updateHeaders={updateHeaders}
              editMode={editMode}
              currentIndex={index}
              addNewHeader={addNewHeader}
              removeHeader={removeHeader}
              isLastIndex={index === headerData.length - 1}
              headerslength={headerData.length}
            />
          );
        })}
    </>
  );
};

export default CreateHeader;

const CreateMultipleHeaders = ({
  header,
  updateHeaders,
  editMode,
  currentIndex,
  addNewHeader,
  removeHeader,
  isLastIndex,
  headerslength,
}) => {
  const [shouldHighlight, setShouldHighlight] = useState(false);

  const onTextChange = (value, type) => {
    if (type === "message") {
      header.message = value;
    } else if (type === "restriction") {
      header.restriction = value;
    } else if (type === "rules") {
      header.rule = value;
    }
    updateHeaders(header, currentIndex);
  };
  return (
    <>
      {!editMode && (
        <div className="row attribute-head">
          <div className="col-md-6" style={{ paddingTop: "5px" }}>
            {" "}
            <span className="marketing-message-label">
              Header {currentIndex + 1}
            </span>
          </div>

          <div className="col-md-6 text-right">
            <button
              className="btn btn-link"
              onClick={() => {
                removeHeader(currentIndex);
                setShouldHighlight(false);
              }}
              disabled={headerslength && headerslength === 1 ? true : false}
            >
              Remove
            </button>

            {isLastIndex && (
              <button
                className="btn btn-link"
                onClick={() => {
                  addNewHeader({ message: "", restriction: "", rule: "" });
                }}
                disabled={header?.message.trim().length === 0}
              >
                Add
              </button>
            )}
          </div>
        </div>
      )}
      <Row>
        <Col className="col-md-4">
          <TextField
            id="header-message"
            label="Enter Message here"
            variant="outlined"
            fullWidth={true}
            autocomplete="new-password"
            autoComplete="off"
            multiline
            rows={9}
            rowsMax={9}
            className="marketing-msg-header-box customScrollBar"
            inputProps={{ maxLength: 250 }}
            value={header?.message}
            onChange={(e) => {
              let flag = false;
              if (
                header?.message?.trim().length > 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              } else if (
                header?.message?.trim().length === 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              }

              setShouldHighlight(flag);
              onTextChange(e.target.value, "message");
            }}
            helperText={shouldHighlight ? "This field can not be empty" : ""}
            error={shouldHighlight ? true : false}
          />
        </Col>
        <Col className="col-md-4">
          <TextField
            id="header-restrictions-for-use"
            label="Restrictions for use"
            variant="outlined"
            fullWidth={true}
            autocomplete="new-password"
            autoComplete="off"
            multiline
            rows={9}
            rowsMax={9}
            className="marketing-msg-header-box customScrollBar"
            inputProps={{ maxLength: 250 }}
            value={header?.restriction}
            onChange={(e) => {
              onTextChange(e.target.value, "restriction");
            }}
            // error={errors?.restriction ? true : false}
            // helperText={errors?.restriction}
          />
        </Col>
        <Col className="col-md-4">
          <TextField
            id="rules-for-header"
            label="Rules for header"
            variant="outlined"
            fullWidth={true}
            autocomplete="new-password"
            autoComplete="off"
            multiline
            rows={9}
            rowsMax={9}
            className="marketing-msg-header-box customScrollBar"
            inputProps={{ maxLength: 250 }}
            value={header?.rule}
            onChange={(e) => {
              onTextChange(e.target.value, "rules");
            }}
            // error={errors?.rules ? true : false}
            // helperText={errors?.rules}
          />
        </Col>
      </Row>
    </>
  );
};
