import React, { useState } from "react";

import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { froalaEditor } from "../Uitls/FroalaConfig";
import "../styles/components/CommonFroalEditorComponent.scss";

const CommonFroalEditorComponent = (props) => {
  const froalaConfig = froalaEditor.config(
    {
      placeHolder: props.placeHolder,
      shouldHaveToolbar: true,
      shouldUpdateImmediatly: props.shouldUpdateImmediatly || false,
      contentEditable:
        props.editable && props.editable === "off" ? false : true,
      charCounter: props.charCounter,
      insertLink: props.insertLink,
      onChange: props.handleModelChange,
    },
    (onclick = (event) => {
      if (props.onSupClick) {
        props.onSupClick(event);
      }
    })
  );

  //const [showLargerEditor, setShowLargerEditor] = useState(false)
  const [atomText, setAtomText] = useState();

  const newEditor = (defaultText) => {
    return (
      <FroalaEditor
        ref={props.forwardedRef}
        className="common-editor"
        tag="textarea"
        config={froalaConfig}
        //onClick={onEditorClick}
        model={defaultText}
        onModelChange={props.handleModelChange}
      />
    );
  };

  return (
    <div className={`${props.className || "supporting-claim"}`}>
      {props && props.title && (
        <p className="mb-0 box-heading">{props.title}</p>
      )}

      {newEditor(props && props.selectedAtom ? props.selectedAtom : "")}
    </div>
  );
};

export default CommonFroalEditorComponent;
