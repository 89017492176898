import React, { useEffect, useState } from "react";

//Import Images
import searchIcon from "../../../images/search.svg";
import subtitleIcon from "../../../images/infoIcon.svg";

const SearchModules = ({
  modules,
  selectedBox,
  addBodyModuleSection,
  ...props
}) => {
  const [filteredModules, setFilteredModules] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (tabIndex === 0) {
      let filteredModuleList = [...modules].filter((item) => {
        return item.contentModuleType.toLowerCase() !== "optional";
      });
      setFilteredModules(filteredModuleList);
    } else if (tabIndex === 1) {
      let filteredModuleList = [...modules].filter((item) => {
        return item.contentModuleType.toLowerCase() === "optional";
      });
      setFilteredModules(filteredModuleList);
    }
    // setFilteredModules(modules);
    const input = document.getElementById("search-brules-modules");
    if (input && input.value && input.value.trim().length > 0) {
      searchModules(input.value);
    }
  }, [modules, tabIndex]);

  const searchModules = (searchParam) => {
    if (searchParam.trim().length > 0) {
      let filteredModuleList = [...modules].filter((item) => {
        if (tabIndex === 0) {
          return (
            (item.moduleName
              .toLowerCase()
              .includes(searchParam.toLowerCase()) ||
              item.moduleCode
                .toLowerCase()
                .includes(searchParam.toLowerCase())) &&
            item.contentModuleType.toLowerCase() !== "optional"
          );
        } else if (tabIndex === 1) {
          return (
            (item.moduleName
              .toLowerCase()
              .includes(searchParam.toLowerCase()) ||
              item.moduleCode
                .toLowerCase()
                .includes(searchParam.toLowerCase())) &&
            item.contentModuleType.toLowerCase() === "optional"
          );
        }
      });
      setFilteredModules(filteredModuleList);
    } else {
      if (tabIndex === 0) {
        let filteredModuleList = [...modules].filter((item) => {
          return item.contentModuleType.toLowerCase() !== "optional";
        });
        setFilteredModules(filteredModuleList);
      } else if (tabIndex === 1) {
        let filteredModuleList = [...modules].filter((item) => {
          return item.contentModuleType.toLowerCase() === "optional";
        });
        setFilteredModules(filteredModuleList);
      }
    }
  };

  const getLength = (type) => {
    let count = 0;
    if (type !== "optional") {
      let filteredModuleList = [...modules].filter((item) => {
        return item.contentModuleType.toLowerCase() !== "optional";
      });
      count = filteredModuleList.length;
      return count > 0 ? `(${count})` : "";
    } else {
      let filteredModuleList = [...modules].filter((item) => {
        return item.contentModuleType.toLowerCase() === "optional";
      });
      count = filteredModuleList.length;
      return count > 0 ? `(${count})` : "";
    }
  };

  const checkForPrecedsAndSuccedes = (moduleId) => {
    if (modules.length > 0) {
      let temp = [...modules].filter((item) => {
        return item.contentModuleType.toLowerCase() === "original";
      });
      if (temp[0].moduleBusinessRules?.length > 0) {
        let temp2 = [...temp[0].moduleBusinessRules].filter((item) => {
          return (
            item.ruleCondition.toLowerCase() === "precedes" ||
            item.ruleCondition.toLowerCase() === "succeeds"
          );
        });
        let index = temp2.findIndex((item) => item.targetModuleId === moduleId);
        if (index > -1) {
          let ruleCondition = temp2[index].ruleCondition;
          return (
            <div
              style={{
                color: "#707070",
                fontSize: "10px",
                marginTop: "5px",
                fontWeight: "600",
              }}
            >
              ({ruleCondition})
            </div>
          );
        }
      } else {
        return "";
      }
    }
    return "";
  };

  return (
    <>
      <div className="suppClaimSearch" style={{ marginTop: "16px" }}>
        <div className="d-flex">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            id="search-brules-modules"
            type="text"
            placeholder="Enter Module ID / Module Name"
            className={`globalSearchInput`}
            onChange={(e) => {
              searchModules(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="module-list-tab">
        <button
          className={`btn-module ${tabIndex === 0 ? "btn-sel-module" : ""}`}
          onClick={() => {
            setTabIndex(0);
          }}
        >
          Mandatory {getLength("mandatory")}
        </button>
        <button
          className={`btn-module ${tabIndex === 1 ? "btn-sel-module" : ""}`}
          onClick={() => {
            setTabIndex(1);
          }}
        >
          Optional {getLength("optional")}
        </button>
      </div>

      <div style={{ marginTop: "10px" }}>
        <>
          <span
            className="text-nowrap pl-1"
            style={{ color: "#333", opacity: "0.5" }}
          >
            <img
              className="modal-header-subtitle-icon"
              src={subtitleIcon}
              alt=""
            />
            Double click to add {tabIndex === 0 ? "all" : ""} Modules
          </span>
        </>
      </div>
      {filteredModules.length === 0 && (
        <div className="no-results">No Results</div>
      )}
      {filteredModules.length > 0 && (
        <ul className={`contentModules mt-3 search-left-panel customScrollBar`}>
          {filteredModules
            .sort(function (a, b) {
              if (a.contentModuleType < b.contentModuleType) {
                return 1;
              }
              if (a.contentModuleType > b.contentModuleType) {
                return -1;
              }
              return 0;
            })
            .map((item, index) => (
              <>
                <li
                  key={index}
                  onDoubleClick={() => {
                    if (item.bodyModuleSection !== "") return;
                    addBodyModuleSection(item);
                  }}
                  className={`contentModuleList ${
                    item.bodyModuleSection !== "" ? "frozen-module" : ""
                  } ${
                    item.contentModuleType.toLowerCase() === "original"
                      ? "moduleSelected"
                      : ""
                  }`}
                >
                  <div className="leftContentModuleList">
                    {item.moduleName && (
                      <div className="d-flex justify-content-between">
                        <div className="contentModuleId">{item.moduleName}</div>
                        {item.contentModuleType.toLowerCase() === "original" ? (
                          <div
                            style={{
                              color: "#1c75bb",
                              fontSize: "10px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            (Source Module)
                          </div>
                        ) : (
                          ""
                        )}
                        {checkForPrecedsAndSuccedes(item.moduleId)}
                      </div>
                    )}
                  </div>
                </li>
              </>
            ))}
        </ul>
      )}
    </>
  );
};
export default SearchModules;
