import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import "../styles/components/Abbreviation.scss";
import { froalaEditor } from "../Uitls/FroalaConfig";
import FroalaEditor from "react-froala-wysiwyg";
import parse from "html-react-parser";
import "../styles/pages/CreateModuleStep2.scss";
const Abbreviation = (props) => {
  const [isAbbrTextEditor, setIsAbbrTextEditor] = useState(false);
  const [abbrValue, setAbbrValue] = useState("");
  const [arrAbbr, setArrAbbr] = useState([]);
  const [isAbbrDisable, setIsAbbrDisable] = useState(true);
  const [addBtnStatus, setAddBtnStatus] = useState(false);
  const referencesValid = useRef(null);
  useEffect(() => {
    console.log(props.selectedText);
    setAbbrValue(props.selectedText);
    setIsAbbrDisable(props.abbrStatsu);
  }, [props.selectedText]);

  useEffect(() => {
    setIsAbbrDisable(!(abbrValue && abbrValue.abbrText.trim() !== ""));
  }, [abbrValue]);

  const configAbbreviation = froalaEditor.config({
    placeHolder: "Enter Abbreviation",
    shouldHaveToolbar: true,
    color: "white",
    shouldUpdateImmediatly: true,
    contentEditable: true,
  });
  const handleRadioBtn = (e) => {
    if (e.target.value != null) {
      const newText = { abbrText: e.target.value };
      setAbbrValue(newText);
      setAddBtnStatus(true);
    }
  };
  const handleAbbrAdd = () => {
    arrAbbr.push({
      title: props.selectedTextPrimaryClaim,
      data: abbrValue,
    });
    setArrAbbr(arrAbbr);
    props.handleArrAbbr(arrAbbr);
  };
  const handleAbbrChange = (TextChanged) => {
    referencesValid.current.innerHTML = TextChanged;
    const text = referencesValid.current.innerText;
    const newText = { abbrText: TextChanged };
    if (text && text.trim().length) {
      setAbbrValue(newText);
    } else {
      setAbbrValue("");
    }
  };

  const handleAddNewAbbr = () => {
    setIsAbbrTextEditor(!isAbbrTextEditor);
    setIsAbbrDisable(true);
    setAddBtnStatus(false);
    setAbbrValue("");
  };

  const prepareRadiodata = () => {
    const dataSource = props.arrRadioData;
    let values = dataSource.map((item) => {
      return item.data.abbrText;
    });
    let set = new Set(values);
    values = [...set];
    return values;
  };

  return (
    <div>
      {isAbbrTextEditor === false ? (
        <div className="modalCustomCommon">
          <div className="headercon row">
            <Col className="col-md-10">
              <span className="AbbreviationTitle">
                Abbreviation for {props.selectedTextPrimaryClaim}
              </span>
            </Col>
            <Col className="col-md-2">
              <p
                type="button"
                className="Add-New-abbr"
                onClick={handleAddNewAbbr}
              >
                Add New
              </p>
            </Col>
          </div>
          <div className="HeaderLineRef"></div>
          <p className="Select-From-List-Abb">Select from list</p>
          <div class="body-Abb custonScrollBar">
            <div style={{ paddingLeft: "5px", paddingTop: "20px" }}>
              {prepareRadiodata().map((x, index) => (
                <div
                  className="d-flex"
                  onChange={handleRadioBtn}
                  style={{ marginBottom: "10px" }}
                >
                  <input
                    type="radio"
                    className="abbrRadioBtn"
                    id={index + 1}
                    name="abbr"
                    value={x}
                  ></input>
                  <label
                    style={{
                      marginLeft: "20px",
                      fontSize: "14px",
                      marginTop: "-4px",
                    }}
                  >
                    {parse(x)}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="footer-button">
            <button
              type="button"
              className="btn btn-link"
              style={{ marginLeft: "20px" }}
              onClick={props.handleCloseAbbreviation}
            >
              CLOSE
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              disabled={addBtnStatus ? "" : "disable"}
              onClick={() => {
                handleAbbrAdd();
              }}
              style={{ float: "right", marginRight: "31px", width: "157px" }}
            >
              Add
            </button>
          </div>
        </div>
      ) : (
        <div className="modalCustomCommon">
          <div className="headercon row">
            <Col className="col-md-9">
              <span className="AbbreviationTitle">
                Abbreviation for {props.selectedTextPrimaryClaim}
              </span>
            </Col>
            <Col className="col-md-3">
              <p
                type="button"
                className="Select-from-list-abbr-new"
                onClick={handleAddNewAbbr}
              >
                Select From List
              </p>
            </Col>
          </div>
          <div className="HeaderLineRef"></div>
          {isAbbrDisable ? (
            <p
              className="ReferencesInformation"
              style={{ visibility: "hidden" }}
            >
              {" "}
              Enter Abbreviation{" "}
            </p>
          ) : (
            <p className="ReferencesInformation">Enter Abbreviation</p>
          )}
          <div class="AbbreviationBodyText custonScrollBar">
            <div className="text-area ">
              <FroalaEditor
                onModelChange={handleAbbrChange}
                className=""
                tag="textarea"
                config={configAbbreviation}
                model={abbrValue.abbrText}
              ></FroalaEditor>
            </div>
          </div>
          <div className="footer-button-abbr">
            <button
              type="button"
              className="btn btn-link"
              style={{ marginLeft: "20px" }}
              onClick={props.handleCloseAbbreviation}
            >
              CLOSE
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              style={{ float: "right", marginRight: "31px", width: "157px" }}
              disabled={isAbbrDisable}
              onClick={() => {
                handleAbbrAdd();
              }}
            >
              Add
            </button>
          </div>
        </div>
      )}

      <div className="d-none" ref={referencesValid}></div>
    </div>
  );
};
export default Abbreviation;
