import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
//import FroalaEditor from '../../Uitls/CommonFroalEditorComponent';
//import Checkbox from "@material-ui/core/Checkbox";
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";
import "../../styles/components/ReferenceModal.scss";

import {
  getModuleInfoFromLocalStorage,
  isValidInteger,
  moduleTypes,
} from "../../Uitls/Util";

import ViewReferencePdf from "./ViewReferencePdf";
import {
  getPDFUrlForRendering,
  getPDFUrlForRenderingdenovo,
} from "../../services/apis";
import { toast } from "react-toastify";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";

const Reference = (props) => {
  const { t } = useTranslation();
  const [addAnchorStatus, setAddAnchorStatus] = useState(false);
  const [nextStatus, setNextStatus] = useState(false);
  const [documentId, setDocumentId] = useState([]);
  const [moduleId, setModuleId] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [refDocUrl, setRefDocUrl] = useState("");
  const [selectedAnchorText, setSelectedAnchorText] = useState("");

  const [helper, setHelper] = useState(false);
  const [getPdfwidth, setGetPdfWidth] = useState(0);
  const elementRef = useRef(null);
  const [anchorCoordinates, setAnchorCoordinates] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [anchorError, setAnchorError] = useState("");
  const { moduleType } = props;
  useEffect(() => {
    setTimeout(() => {
      setGetPdfWidth(elementRef.current.getBoundingClientRect().width - 50);
    }, 200);
  }, []);
  useEffect(() => {
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo) {
      setModuleId(moduleInfo.moduleId);
    }
    //Set if document id is passed from calling component
    if (props.addOrModifyanchorData) {
      setDocumentId(props.addOrModifyanchorData.docId);
      setSelectedAnchorText(props.addOrModifyanchorData.anchorText);
    } else if (props.referenceDocuments.length > 0) {
      setDocumentId(props.referenceDocuments[0].id);
      setAddAnchorStatus(true);
    }
  }, []);

  const getPosition = (linkType, rowIndex) => {
    let shouldCallPositionFunc = false;
    switch (linkType) {
      case "supporting-claim":
      case "supporting-claim-footnote":
        linkType = "supportingclaim";
        shouldCallPositionFunc = true;
        break;
      case "supporting-visual":
      case "supporting-visual-footnote":
        linkType = "visual";
        shouldCallPositionFunc = true;
        break;
      default:
        break;
    }
    //return rowIndex;
    rowIndex =
      shouldCallPositionFunc && props.findSupportingClaimOrVisualPosition
        ? props.findSupportingClaimOrVisualPosition(
            linkType,
            undefined,
            rowIndex
          )
        : rowIndex;
    return rowIndex;
  };

  useEffect(() => {
    if (documentId || documentId.length !== 0) {
      let foundRefs = props.referenceList.filter(
        (o) => o && o.id === documentId
      );
      if (foundRefs.length > 0) {
        let coordinates = [];
        foundRefs.forEach((ref) => {
          coordinates = coordinates.concat(ref.annotationList);
        });

        let anchors = [];
        coordinates.forEach((coordinate) => {
          if (anchors.length > 0) {
            let foundIndex = anchors.findIndex((anchor) => {
              return (
                coordinate.pageNumber === anchor.pageNumber &&
                coordinate.para === anchor.para &&
                coordinate.column === anchor.column &&
                coordinate.line === anchor.line
              );
            });
            if (foundIndex > -1) {
              let types = anchors[foundIndex].types;
              types.push({
                compIndex: coordinate.compIndex,
                rowIndex: getPosition(coordinate.linkType, coordinate.rowIndex),
                linkType: coordinate.linkType,
              });
              anchors[foundIndex].types = types;
            } else {
              const obj = {
                id: foundRefs[0].id,
                refId: foundRefs[0].refId,
                veevaLinked: foundRefs[0].veevaLinked,
                veevaReferenceName: foundRefs[0].veevaReferenceName,
                coordinateId: coordinate.id,
                pageNumber: coordinate.pageNumber,
                para: coordinate.para,
                column: coordinate.column,
                line: coordinate.line,
                anchorLinkFlag: coordinate.anchorLinkFlag,
                isSelected: false,
                types: [
                  {
                    compIndex: coordinate.compIndex,
                    rowIndex: getPosition(
                      coordinate.linkType,
                      coordinate.rowIndex
                    ),
                    linkType: coordinate.linkType,
                  },
                ],
              };
              anchors.push(obj);
              return;
            }
          } else {
            const obj = {
              id: foundRefs[0].id,
              refId: foundRefs[0].refId,
              veevaLinked: foundRefs[0].veevaLinked,
              veevaReferenceName: foundRefs[0].veevaReferenceName,
              coordinateId: coordinate.id,
              pageNumber: coordinate.pageNumber,
              para: coordinate.para,
              column: coordinate.column,
              line: coordinate.line,
              anchorLinkFlag: coordinate.anchorLinkFlag,
              isSelected: false,
              types: [
                {
                  compIndex: coordinate.compIndex,
                  rowIndex: getPosition(
                    coordinate.linkType,
                    coordinate.rowIndex
                  ),
                  linkType: coordinate.linkType,
                },
              ],
            };
            anchors.push(obj);
          }
        });
        anchors = anchors.filter(
          (coordinate, index, self) =>
            index ===
            self.findIndex((t) => {
              return (
                t.pageNumber === coordinate.pageNumber &&
                t.column === coordinate.column &&
                t.para === coordinate.para &&
                t.line === coordinate.line
              );
            })
        );

        anchors.forEach((anchor) => {
          let uniqueTypes = [];
          anchor.types.forEach((anchorType) => {
            const filledIndex = uniqueTypes.findIndex((type) => {
              return (
                type.linkType === anchorType.linkType &&
                type.rowIndex === anchor.rowIndex
              );
            });
            if (filledIndex === -1) {
              uniqueTypes.push(anchorType);
            }
          });
          anchor.types = uniqueTypes;
        });
        setAnchorCoordinates(anchors);
      } else {
        setAnchorCoordinates([]);
      }

      //TO Check if document id is passed from calling component
      if (props.addOrModifyanchorData) {
        if (moduleType === "denovo") {
          handleAddAnchordenovo();
        } else {
          handleAddAnchor();
        }
      }
    }
  }, [documentId]);

  const handleRadioBtn = (e) => {
    if (e.target.value != null) {
      setDocumentId(e.target.value);
      setAddAnchorStatus(true);
    }
  };

  const handleAnchorCheckBox = (e, index) => {
    let tmpAnchors = [...anchorCoordinates];
    tmpAnchors[index].isSelected = e.target.checked;
    setAnchorCoordinates(tmpAnchors);
  };

  const handleAddAnchor = () => {
    if (moduleId && documentId && documentId.length !== "") {
      setIsloading(true);
      getPDFUrlForRendering(
        moduleId,
        documentId,
        props.sourceType?.toLowerCase(),
        (response, error, errCode) => {
          setIsloading(false);
          if (errCode) {
            const errCoponent = ErrComponentToRender(errCode);
            toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
          } else if (response && response.data) {
            let pdfDocument = response.data.documentUrl
              ? response.data.documentUrl
              : response.data;
            setRefDocUrl(pdfDocument);
            setNextStatus(true);
            setAnchorLabels(defaultAnchorLabels);
          } else {
            return;
          }
        }
      );
    }
  };
  const handleAddAnchordenovo = () => {
    if (moduleId && documentId && documentId.length !== "") {
      setIsloading(true);
      getPDFUrlForRenderingdenovo(
        // moduleId,
        documentId,
        // props.sourceType?.toLowerCase(),
        (response, error, errCode) => {
          setIsloading(false);
          if (errCode) {
            const errCoponent = ErrComponentToRender(errCode);
            toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
          } else if (response && response.data) {
            let pdfDocument = response.data.documentUrl
              ? response.data.documentUrl
              : response.data;
            setRefDocUrl(pdfDocument);
            setNextStatus(true);
            setAnchorLabels(defaultAnchorLabels);
          } else {
            return;
          }
        }
      );
    }
  };
  const addAnchor = () => {
    let coordinates = [...anchorCoordinates].filter((coordinate) => {
      return coordinate.isSelected;
    });

    coordinates = coordinates.map((coordinate) => {
      return {
        pageNumber: parseInt(coordinate.pageNumber),
        column: parseInt(coordinate.column),
        para: parseInt(coordinate.para),
        line: parseInt(coordinate.line),
        anchorLinkFlag: true,
      };
    });

    let isAddedManually = true;

    anchorLabels.forEach((anchorLabel) => {
      if (!anchorLabel.isValid || anchorLabel.value === 0) {
        isAddedManually = false;
        return;
      }
    });
    if (isAddedManually) {
      coordinates = [
        {
          pageNumber: parseInt(anchorLabels[0].value),
          column: parseInt(anchorLabels[1].value),
          para: parseInt(anchorLabels[2].value),
          line: parseInt(anchorLabels[3].value),
          anchorLinkFlag: true,
        },
      ];
    }

    props.saveReferenceAnchor(documentId, coordinates);
  };

  useEffect(() => {
    if (helper) {
      props.saveReferenceAnchor(documentId, selectedAnchorText);
    }
    setHelper(false);
  }, [helper]);

  const sortedData = (array) => {
    return array.sort((a, b) =>
      parseInt(a.refId) > parseInt(b.refId)
        ? 1
        : parseInt(b.refId) > parseInt(a.refId)
        ? -1
        : 0
    );
  };

  const getContentCss = () => {
    if (nextStatus) {
      return "refPdfBody body-Select custonScrollBar render-pdf";
    }
    return "refPdfBody body-Select custonScrollBar";
  };
  //This is sample array for display purpose, once API result available remove this

  const defaultAnchorLabels = [
    { label: "Page no", value: 0, isValid: true },
    { label: "Column", value: 0, isValid: true },
    { label: "Para", value: 0, isValid: true },
    { label: "Line", value: 0, isValid: true },
  ];
  const [anchorLabels, setAnchorLabels] = useState([
    { label: "Page no", value: 0, isValid: true },
    { label: "Column", value: 0, isValid: true },
    { label: "Para", value: 0, isValid: true },
    { label: "Line", value: 0, isValid: true },
  ]);

  const onCoordinatesChange = (event, anchorLabel) => {
    const eneteredValue = event.target.value;
    if (eneteredValue.trim() !== "") {
      if (isNaN(eneteredValue)) {
        anchorLabel.isValid = false;
        setAnchorError("Please enter numeric values only");
      } else if (!isValidInteger(eneteredValue)) {
        anchorLabel.isValid = false;
        setAnchorError("Please enter numeric values only");
      } else {
        if (
          anchorLabel.label === "Page no" &&
          eneteredValue > numberOfPages &&
          numberOfPages
        ) {
          anchorLabel.isValid = false;
          setAnchorError("Page number exceeded total number of pages");
        } else if (parseInt(eneteredValue) === 0) {
          anchorLabel.isValid = false;
          setAnchorError("Please enter value greater than zero");
        } else {
          anchorLabel.isValid = true;
          setAnchorError("");
        }
      }
    } else {
      anchorLabel.isValid = true;
    }
    anchorLabel.value = eneteredValue.trim() === "" ? 0 : eneteredValue;
    const index = anchorLabels.findIndex((label) => {
      return label.label === anchorLabel.label;
    });
    const tmp = [...anchorLabels];
    tmp[index] = anchorLabel;
    setAnchorLabels(tmp);
  };

  const areCoordinatesValid = () => {
    let areValid = true;
    anchorLabels.forEach((anchorLabel) => {
      if (!anchorLabel.isValid) {
        areValid = false;
        return;
      }
    });
    return areValid;
  };
  const shouldEnableTheSaveButton = () => {
    let areValid = true;
    anchorLabels.forEach((anchorLabel) => {
      if (!anchorLabel.isValid || anchorLabel.value === 0) {
        areValid = false;
        return;
      }
    });
    return areValid;
  };

  const isAnyCordinateSelected = () => {
    let isSelected = false;
    anchorCoordinates.forEach((anchorCoordinate) => {
      if (anchorCoordinate.isSelected) {
        isSelected = true;
        return;
      }
    });
    return isSelected;
  };
  const shouldEnableAddButton = () => {
    let areValid = true;
    anchorLabels.forEach((anchorLabel) => {
      if (!anchorLabel.isValid || anchorLabel.value === 0) {
        areValid = false;
        return;
      }
    });
    if (areValid || isAnyCordinateSelected()) {
      return true;
    }
    return false;
  };
  const isDenovoModule =
    (getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() ??
      "") === "denovo";
  const getAnchorLabel = (element) => {
    switch (element.linkType) {
      case "primary-claim-varient":
        let variant = "";
        if (element.compIndex > 0) {
          variant = `, variant ${element.compIndex}`;
        }
        return `Promotional Claim ${variant}| Claim`;
      case "supporting-claim":
        return `Supporting Claim ${element.rowIndex + 1}| Claim`;
      case "supporting-visual":
        let visualvariant = "";
        if (element.compIndex > 0) {
          visualvariant = `, caption ${element.compIndex + 1}`;
        }
        return `Supporting visual ${
          element.rowIndex + 1
        } ${visualvariant}| Visual`;
      case "primary-claim-footnote":
        let footnoteVariant = "";
        if (element.compIndex > 0) {
          footnoteVariant = `, variant ${element.compIndex + 1}, Footnote`;
        }
        return `Promotional Claim ${footnoteVariant}| Footnote`;
      case "supporting-claim-footnote":
        return `Supporting Claim ${element.rowIndex + 1} Footnote| Footnote`;
      case "supporting-visual-footnote":
        let visVariant = "";
        if (element.compIndex > 0) {
          visVariant = `, caption ${element.compIndex + 1}`;
        }
        return `Supporting visual ${
          element.rowIndex + 1
        } ${visVariant} Footnote| Footnote`;
      default:
        return "";
    }
  };
  const upDatePageNumber = (totalPages) => {
    setNumberOfPages(totalPages);
  };

  return (
    <>
      <div className="modalCustomCommon refNAnchorModal ">
        {nextStatus ? (
          <div className="d-flex headerIcon">
            <div className="col-md-12 pl-0">
              <span className="annot-Header">
                {t("referenceAndAnchors.addAnchorTitle")}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div ref={elementRef}>
          <CircularProgressBar isloading={isloading} />
          {!nextStatus ? (
            <Row>
              <Col className="col-12 col-md-6 vl">
                <div className="headerNewCss">
                  <div className="col-md-12 pl-0">
                    <span className="annot-Header">
                      {t("referenceAndAnchors.addReferenceTitle")}
                    </span>
                  </div>
                  <div className="col-md-12 pl-0">
                    <span className="d-block reference-subtitle">
                      {t("referenceAndAnchors.addReferenceSubTitle")}
                    </span>
                  </div>
                </div>

                <div class={getContentCss()}>
                  {sortedData(props.referenceDocuments).map(
                    (document, index) => {
                      return (
                        <div className="d-flex rb-ref-doc">
                          <input
                            checked={document.id === documentId}
                            type="radio"
                            className="abbrRadioBtn"
                            id={index + 1}
                            name="abbr"
                            value={document.id}
                            onChange={handleRadioBtn}
                          ></input>
                          <label
                            style={{
                              marginLeft: "20px",
                              fontSize: "14px",
                              marginTop: "-4px",
                            }}
                            className="d-flex"
                          >
                            <spa>{document && document.refId}</spa>
                            <span
                              className="ml-2"
                              style={{ wordBreak: "break-all" }}
                            >
                              {parse(document.veevaReferenceName)}
                            </span>
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </Col>
              <Col className="col-12 col-md-6 anchorsLeftAlign">
                <div className="headerNewCss">
                  <div className="col-md-12 pl-0">
                    <span className="annot-Header">
                      {t("referenceAndAnchors.associatedAnchors")}
                    </span>
                  </div>
                </div>
                <div class="refPdfBody body-Select anchorsRightPanel custonScrollBar">
                  {anchorCoordinates &&
                    anchorCoordinates.map((element, index) => {
                      return (
                        <>
                          <div className=" rb-ref-doc">
                            <input
                              type="checkbox"
                              className="cb-added-anchor"
                              id={element + 1}
                              name="anchor"
                              value={element.id}
                              onChange={(e) => {
                                handleAnchorCheckBox(e, index);
                              }}
                            ></input>
                            <label
                              style={{
                                marginLeft: "5px",
                                fontSize: "14px",
                                marginTop: "-4px",
                              }}
                            >
                              <span className="ml-2">
                                Page no : {element.pageNumber} column{" "}
                                {element.column} para {element.para} line{" "}
                                {element.line}{" "}
                              </span>
                              {element.types.map((item) => {
                                return (
                                  <div className="d-flex annotationCheckbox">
                                    {getAnchorLabel(item)}
                                  </div>
                                );
                              })}
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div className="footerAnchorsLine"></div>
                <div className="col-md-12 pl-0">
                  <div className="anchorManully">
                    <span className="annot-Header">
                      {t("referenceAndAnchors.anchorCordinate")}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pl-0 d-flex">
                    {anchorLabels.map((item, index) => {
                      let selectedClass =
                        index === 0 ? "inputAnchorDiv1" : "inputAnchorDiv";
                      return (
                        <div className={selectedClass} key={index}>
                          <label>{item.label}</label>
                          <input
                            type="text"
                            className={`anchorInputField ${
                              item.isValid ? "" : "anchor-label-err"
                            }`}
                            onChange={(event) => {
                              onCoordinatesChange(event, item);
                            }}
                            value={item.value === 0 ? "" : item.value}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                {!areCoordinatesValid() && (
                  <div className="row">
                    <div className="col-md-12 pl-0 d-flex only-numeric-err ">
                      {anchorError}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <div class={getContentCss()}>
              {getPdfwidth && (
                <ViewReferencePdf
                  pdfUrl={refDocUrl}
                  moduleId={moduleId}
                  documentId={documentId}
                  getPdfwidth={getPdfwidth}
                  upDatePageNumber={upDatePageNumber}
                />
              )}
            </div>
          )}
        </div>
        <div className="footerReferenceLine"></div>
        <div className="footerButton row">
          <div className="col-md-4">
            <button
              type="button"
              className="btn btn-link close-ref-modal"
              style={{ marginLeft: "16px" }}
              onClick={() => props.handleCloseModal()}
            >
              {t("referenceAndAnchors.Close")}
            </button>
          </div>
          <div className="col-md-8">
            {nextStatus ? (
              <>
                <div className="d-flex">
                  {anchorLabels.map((item, index) => {
                    return (
                      <div className="inputAnchorDivView" key={index}>
                        <label>{item.label}</label>
                        <input
                          type="text"
                          className={`anchorInputField ${
                            item.isValid ? "" : "anchor-label-err"
                          }`}
                          onChange={(event) => {
                            onCoordinatesChange(event, item);
                          }}
                          value={item.value === 0 ? "" : item.value}
                        />
                      </div>
                    );
                  })}

                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    style={{
                      float: "right",
                      marginLeft: "30px",
                      width: "157px",
                    }}
                    disabled={
                      //enableSave || areCoordinatesValid() ? "" : "disable"
                      !shouldEnableTheSaveButton()
                    }
                    onClick={() => {
                      addAnchor();
                    }}
                  >
                    {t("referenceAndAnchors.Save")}
                  </button>
                </div>
                {!areCoordinatesValid() && (
                  <div className="row">
                    <div className="col-md-12 pl-0 d-flex only-numeric-err ">
                      {anchorError}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{
                    float: "right",
                    marginRight: "31px",
                    width: "157px",
                  }}
                  disabled={!shouldEnableAddButton()}
                  onClick={() => {
                    addAnchor();
                  }}
                >
                  {t("referenceAndAnchors.add")}
                </button>
                <button
                  type="button"
                  className="btn btn-link skip-anchor"
                  disabled={addAnchorStatus ? "" : "disable"}
                  onClick={
                    moduleType === "denovo"
                      ? handleAddAnchordenovo
                      : handleAddAnchor
                  }
                >
                  {t("referenceAndAnchors.view")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reference;
