import React from "react";

const Properties = ({}) => {
  return (
    <div className="editorLeftContainer">
      {/* <div id="selectors-container"></div> This is for selected component title */}
      <div id="stylesMangerBlock"></div>
      <div id="traitBlock"></div>
    </div>
  );
};

export default Properties;
