import React, { useEffect, useState } from "react";
import "../../styles/components/editor.scss";
import BusinessRules from "./BusinessRules";
import Properties from "./Properties";
import pdfDownloadIcon from "../../images/pdfDownloadIcon.svg";
import pdfUnavailableIcon from "../../images/editor/pdfUnavailableIcon.svg";
const RightPanel = ({ editor, module, brandStyleGuideline }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);
  useEffect(() => {
    showAndHideTab(selectedTabIndex);
  }, []);
  const showAndHideTab = (selectedIndex) => {
    if (selectedIndex === 1) {
      document.getElementById("properties").style.display = "block";
      document.getElementById("businessrules").style.display = "none";
    } else {
      document.getElementById("properties").style.display = "none";
      document.getElementById("businessrules").style.display = "block";
    }
  };
  return (
    <div className="right-panel-container pt-3">
      <div className="row rhs-panel-tabs no-gutters">
        <div
          className={`col-md-6 text-center rhs-panel-tab ${
            selectedTabIndex === 0 ? "rhs-panel-tab-selected" : ""
          }`}
          onClick={() => {
            setSelectedTabIndex(0);
            showAndHideTab(0);
          }}
        >
          Business Rules
        </div>
        <div
          className={`col-md-6 text-center rhs-panel-tab ${
            selectedTabIndex === 1 ? "rhs-panel-tab-selected" : ""
          }`}
          onClick={() => {
            setSelectedTabIndex(1);
            showAndHideTab(1);
          }}
        >
          Properties
        </div>
      </div>
      <div id="businessrules">
        {" "}
        <div className="row view-brandstyle-guidelines">
          {brandStyleGuideline && brandStyleGuideline ? (
            <>
              <img src={pdfDownloadIcon} className="mr-1" />
              <span>
                <span
                  className="link"
                  onClick={() => {
                    window.open(brandStyleGuideline, "_blank");
                  }}
                >
                  Click here
                </span>
                to view brand style guidelines
              </span>
            </>
          ) : (
            <>
              <img src={pdfUnavailableIcon} className="mr-1" />
              <span>Brand style guidelines not available</span>
            </>
          )}
        </div>
        <span className="businessrules customScrollBar">
          {" "}
          <BusinessRules module={module} />
        </span>
      </div>
      <div id="properties" className="properties customScrollBar">
        {" "}
        <Properties />
      </div>
    </div>
  );
};

export default RightPanel;
