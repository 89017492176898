import React from "react";
import parse from "html-react-parser";
const CheckBox = (props) => {
  const {
    label,
    isChecked,
    handleChange,
    labelClassName,
    checkboxClassName,
    disabled,
  } = props;
  const labelToDisplay = parse(label);
  console.log(labelClassName);
  return (
    <>
      <input
        id={label}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        value={label}
        className={checkboxClassName}
        disabled={disabled}
      />
      <label htmlFor={label} className={labelClassName}>
        {labelToDisplay}
      </label>
    </>
  );
};

export default CheckBox;
