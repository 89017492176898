//Import React Dependecies
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import "../../styles/components/ChannelGridHome.scss";
import infoIcon from "../../images/infoIcon.svg";
import warningIcon from "../../images/warning_icon.svg";
//Import Custom components
import { getContentmatrix, getTemplateTypes } from "../../services/apis";
import CustomModal from "../customModal";
import ModuleCardGeneric from "../ModuleCardGeneric";
import ViewModule from "../ModulePlaceHolder/ViewModule";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { isModularContent } from "../../Uitls/Util";
import ContentModuleCard from "../Dams/Veeva/ContentModuleCard";
import ModalHeader from "../../Uitls/ModalHeader";
import DropDownWithCheck from "../../Uitls/DropDownWithCheck";

const ModulesSelection = ({
  modules,
  setBRulesValidationOpen,
  sourceType,
  assetDataType,
  brand,
  campaignName,
  handleProceedForChannelSelection,
  channelType,
  cancelCreatingGrid,
  proceedToMarketingMessages,
  selectedModule,
  setSelectedModule,
  channelGridStepCount,
  stepNumber,
  categoriesFilterList,
}) => {
  const [buttonList, setButtonList] = useState([
    { btnLabel: "View", type: "view" },
  ]);

  const [selectedModuleIdToView, setSelectedModuleIdToView] =
    useState(undefined);
  const [cannotExistModuleIds, setCannotExistModuleIds] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [moduleList, setModulesList] = useState([]);
  const [selectMarketingMsgFlag, toggleSelectMarketingMsgFlag] =
    useState(false);
  const [showCreateChannelMatrixModal, setShowCreateChannelMatrixModal] =
    useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setCannotExistModuleIds(undefined);
    setModulesList(modules);
    let catList = [...categoriesFilterList];
    setCategoryList(catList);
    setSelectedCategory([]);
  }, [modules]);

  const moduleCardBtnActions = (actionType, module) => {
    switch (actionType) {
      case "view":
        if (isModularContent(assetDataType)) {
          window.open(module.contentModuleUiUrl, "_blank");
          return;
        }
        setSelectedModuleIdToView(module.id);
        break;

      default:
        break;
    }
  };
  const onModuleSelect = (module) => {
    let result = selectedModule.findIndex((item) => item.id === module.id);
    if (result > -1) {
      let newArray = selectedModule.filter((item) => item.id !== module.id);
      setSelectedModule(newArray);
    } else {
      let data = [...selectedModule];
      data.push(module);
      setSelectedModule(data);
    }
  };

  const cannotExistWithOtherSelectedModule = (currentModuleId) => {
    if (selectedModule?.id && cannotExistModuleIds.length > 0) {
      return cannotExistModuleIds.indexOf(currentModuleId) > -1;
    }
    return false;
  };

  const checkSelected = (module_Id) => {
    let index = selectedModule.findIndex((element) => element.id === module_Id);
    return index > -1 ? true : false;
  };
  const getModuleCard = (module, index) => {
    if (isModularContent(assetDataType)) {
      return (
        <ContentModuleCard
          module={module}
          buttonList={buttonList}
          moduleCardBtnActions={moduleCardBtnActions}
          //isSelectable={isCmSelectable}
          showLockIcon={false}
          onModuleSelect={onModuleSelect}
          isSelected={module.id === selectedModule?.id}
          cannotExistWith={cannotExistWithOtherSelectedModule(module.cmId)}
        />
      );
    }
    return (
      <ModuleCardGeneric
        module={module}
        key={index}
        colWidth={3}
        buttonList={buttonList}
        moduleCardBtnActions={moduleCardBtnActions}
        showLockIcon={false}
        isSelected={checkSelected(module.id)}
        onModuleSelect={onModuleSelect}
        cannotExistBusssinessRule={cannotExistWithOtherSelectedModule(
          module.id
        )}
      />
    );
  };

  const [contentMatrix, setContentMatrix] = useState(false);

  const hideContentMatrixModal = () => {
    setSelectedModule(undefined);
    setCannotExistModuleIds(undefined);
    setContentMatrix(false);
  };
  const [templates, setTemplates] = useState([]);
  const [matrixData, setMatrixData] = useState([]);

  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleContentMatrixModal = () => {
    setIsloading(true);

    getContentmatrix(
      selectedModule.id,
      sourceType,
      assetDataType,
      (response, err, errCode) => {
        if (err) {
          //  setIsloading(false);
          const errCoponent = ErrComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          setMatrixData(response.data);
          getTemplateTypesData();
          //   setIsloading(false);
        }
      }
    );
  };
  const getTemplateTypesData = (id) => {
    setIsloading(true);
    getTemplateTypes(
      selectedModule.brandName,
      sourceType,
      assetDataType,
      (res, err, errCode) => {
        setIsloading(false);
        if (!res) {
          showError(errCode);
          return;
        }
        setTemplates(() => {
          setContentMatrix(true);
        });
        setTemplates(res.data);
      }
    );
  };
  const [searchedText, setSearhedText] = useState("");
  const [searchedResult, setSearchedResult] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const filterInModuleList = () => {
    let filteredModuleList = [...modules].filter((item) => {
      return (
        ((!isModularContent(assetDataType) ? item.name : item.moduleName)
          ?.toLowerCase()
          .includes(searchedText.toLowerCase()) ||
          (isModularContent(assetDataType) ? item.id : item.moduleCode)
            ?.toLowerCase()
            .includes(searchedText.toLowerCase())) &&
        (selectedCategory.length === 0 ||
          selectedCategory.some((category) =>
            item.coreCategories?.includes(category.displayName)
          ))
      );
    });
    setModulesList(filteredModuleList);
    setSearchedResult(true);
    setSearchTriggered(true);
  };

  const resetModuleList = () => {
    setModulesList(modules);
    setSearhedText("");
    setSearchedResult(false);
    setSearchTriggered(false);
    setSelectedCategory([]);
  };
  const handleSearchInputChange = (e) => {
    setSearhedText(e.target.value);
  };

  const handleSearchClick = () => {
    filterInModuleList();
  };

  const checkIfAllCanNotExistBeingDisplayed = () => {
    let found = [];
    cannotExistModuleIds.forEach((el1) => {
      const idx = moduleList.findIndex((el2) => {
        return !isModularContent(assetDataType)
          ? el2.id === el1
          : el2.cmId === el1;
      });
      if (idx > -1) {
        found.push(el1);
      }
    });
    return found.length === cannotExistModuleIds.length;
  };

  const getCannotExistWarning = () => {
    let warning = `Modules highlighted in red have a CANNOT EXIST business rule with the selected module. Click the View CTA on the module card for details.`;
    if (!checkIfAllCanNotExistBeingDisplayed()) {
      warning = `The selected module has a CANNOT EXIST business rule with other modules. Reset the filters to view the details.`;
    }
    return warning;
  };

  const getHeaderData = () => {
    let obj = {};
    obj["Product/Brand"] = brand;
    if (campaignName) {
      obj["Campaign"] = campaignName;
    }
    obj["Channel"] = channelType;
    return obj;
  };
  return (
    <div className="mt-3 channelModuleSlection">
      <CircularProgressBar isloading={isloading} />

      <ModalHeader
        title={t("channelGrid.createChannelGrid", {
          currentStep: stepNumber,
          totalSteps: channelGridStepCount,
        })}
        subtitle={t("channelMatrix.selectModuleToProceed")}
        subtitleIcon={infoIcon}
        previous={0}
        headerData={getHeaderData()}
        handleBack={(previous) => {
          setBRulesValidationOpen(false);
        }}
      />
      <div
        className="row mt-4 mb-2"
        style={{
          //borderBottom: "1px solid #e1e1e1",
          paddingBottom: "15px",
        }}
      >
        <div className="col-md-7">
          <div className="pl-2">
            <TextField
              label="Search by Module ID / Name"
              variant="outlined"
              fullWidth={true}
              value={searchedText}
              onKeyPress={(e) => {
                if (e.key === "Enter" && searchedText.trim()) {
                  if (e.target.value.trim().length > 0) {
                    filterInModuleList();
                  }
                }
              }}
              onChange={(event) => {
                handleSearchInputChange(event);
              }}
              autocomplete="new-password"
              autoComplete="off"
              className="searchIconInput"
            />
          </div>
        </div>
        <div className="col-md-3 drop-down-check">
          <DropDownWithCheck
            class="read mt-0"
            options={{
              value: selectedCategory,
              name: "Category",
              label: "Filter by Category",
              items: categoryList,
              handleChange: (category) => {
                setSelectedCategory(category);
              },
            }}
          />
        </div>
        <div className="col-md-2">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-primary text-uppercase btn-search-tactic channelBtn"
              onClick={(event) => {
                filterInModuleList();
                handleSearchClick();
              }}
              disabled={
                searchedResult !== true &&
                !searchTriggered &&
                !searchedText.trim() &&
                selectedCategory.length === 0
              }
              style={{ width: "215px" }}
            >
              search
            </button>
            <button
              className="btn btn-link pr-0 clear-search"
              onClick={(event) => {
                resetModuleList();
              }}
              disabled={
                searchedResult !== true &&
                !searchTriggered &&
                !searchedText.trim() &&
                selectedCategory.length === 0
              }
            >
              RESET
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          style={{
            marginLeft: "5px",
            marginTop: "-8px",
            fontSize: "10px",
            color: "#333",
          }}
        >
          {!searchedResult ? (
            <>
              <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                All{" "}
                <span style={{ fontWeight: "normal" }}>
                  ({moduleList?.length})
                </span>
              </p>
            </>
          ) : (
            <>
              <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Search Results{" "}
                <span style={{ fontWeight: "normal" }}>
                  ({moduleList?.length})
                </span>
              </p>
            </>
          )}
        </div>
        <div
          style={{
            borderBottom: "1px solid #e1e1e1",
            height: "1px",
            width: "100%",
            marginLeft: "5px",
          }}
        ></div>
      </div>

      <div className="row channeMatrixSearchModules modules-val-brules custonScrollBar">
        {moduleList?.map((module, index) => {
          return getModuleCard(module, index);
        })}
      </div>
      <div className="row pt-3 createGroupResultBtnRow align-items-center">
        <div className="col-md-7">
          {modules.length > 0 ? (
            <div className="d-flex align-items-center">
              <span className="selected-text pl-2 pr-1">Selected </span>
              <span className="view-pdf-channel-grid-header">
                ({selectedModule?.length}/{modules.length})
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-5 text-right pr-4">
          <button
            className="btn btn-link text-uppercase pl-0 btn-cancel-rhs"
            onClick={() => {
              cancelCreatingGrid(false);
            }}
          >
            cancel
          </button>
          <button
            className="btn btn-primary text-uppercase"
            disabled={selectedModule?.length == 0}
            onClick={() => {
              // if (isModularContent(assetDataType)) {
              proceedToMarketingMessages();
              //   return;
              // }
              //  toggleSelectMarketingMsgFlag(true);
            }}
            style={{ width: "128px" }}
          >
            Proceed
          </button>
        </div>
      </div>
      <CustomModal
        displayModal={selectedModuleIdToView}
        hideModal={() => {
          setSelectedModuleIdToView(undefined);
        }}
        title={""}
        size="xl"
        dilogClassName="alertModalSmall"
        // backdrop="false"
        keyboard={false}
      >
        <ViewModule
          moduleID={selectedModuleIdToView}
          campaign=""
          handleClose={() => {
            setSelectedModuleIdToView(undefined);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default ModulesSelection;
