//Import react dependecies
import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import editedIcon from "../../images/pencilIcon.svg";
import moment from "moment";

//Import from Utils
import {
  getAllChannelAttributes,
  getChannelAttributes,
  getMlrStatusIcon,
  getClickableLink,
  globalAttributes,
  getLockIcon,
} from "../../Uitls/Util";

//Import styles
import "../../styles/components/MarketingMessages.scss";

//Import Resources
import CheckBox from "../../Uitls/CheckBox";
import { isMarketingMessageEditable } from "../MarketingMessages/MarketingMessageCard";

export const isOptOut = (marketingMessage) => {
  return (
    marketingMessage?.attribute?.toLowerCase() ===
    getAllChannelAttributes().optOut.toLowerCase()
  );
};
export const isCTA = (marketingMessage) => {
  return (
    marketingMessage.attribute.toLowerCase() ===
    globalAttributes.cta.toLowerCase()
  );
};
const SelectableMarketingMessageCard = ({
  marketingMessage,
  isSelected,
  onSelectMarketingMessage,
  startEditingMessage,
  marketingMsgToBeEdited,
  isStylisedModuleInitiated,
}) => {
  const mlrStatusIcon = (marketingMessage) => {
    const mlrStatus = marketingMessage.mlrStatus;
    return <img src={getMlrStatusIcon(mlrStatus)} alt=""></img>;
  };
  /* ______________to display edited for updated message_______
 const getModifiedStatus = (createdTime, updatedTime) => {
    let edited;
    const beginningTime = moment(createdTime, "YYYY-MM-DD HH:mm:ss");
    const endTime = moment(updatedTime, "YYYY-MM-DD HH:mm:ss");
    edited = beginningTime.isBefore(endTime);
    return edited;
  };*/
  return (
    <>
      <div
        className={`marketing-msg-card ${
          marketingMessage.campaign ? "" : "product-level-bg-clr"
        }`}
      >
        <Row>
          <Col className="col-md-6">
            <CheckBox
              isChecked={isSelected}
              //disabled={isOptOut(marketingMessage) ? true : false}
              handleChange={(e) => {
                onSelectMarketingMessage(marketingMessage, e.target.checked);
              }}
              labelClassName=""
              checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
            />

            <div style={{ display: "inline" }}>
              <span className="label-font-size" style={{ marginRight: "6px" }}>
                Veeva ID
              </span>
              {marketingMessage.documentNumber ? (
                <span className="value-font-size">
                  {marketingMessage.documentNumber}
                </span>
              ) : (
                <span className="value-font-size">-</span>
              )}
            </div>
          </Col>
          <Col className="col-md-6">
            <div className="status-and-lock">
              <span className="mlr-status">
                {mlrStatusIcon(marketingMessage)}{" "}
                {marketingMessage.mlrStatus || "Not Submitted"}
                {/* {getLockIcon(marketingMessage)} */}
              </span>
            </div>
          </Col>
        </Row>

        {marketingMessage.attribute.toLowerCase() !==
          getAllChannelAttributes().optOut.toLowerCase() &&
          !isCTA(marketingMessage) && (
            <Row className="grid-m-message ">
              <Col
                className={`${
                  marketingMessage.visual ? "col-md-9" : "col-md-12"
                }`}
                style={{ padding: "0px" }}
              >
                {parse(marketingMessage.text || "")}
              </Col>
            </Row>
          )}

        {/* <--------------------  For Optout -------------------------> */}
        {marketingMessage.attribute.toLowerCase() ===
          getAllChannelAttributes().optOut.toLowerCase() && (
          <Row className=" marketing-msg-optout">
            <Col className="col-md-3">
              <img
                src={marketingMessage.visual}
                alt=""
                className="img-fluid"
              ></img>
            </Col>
            <Col
              className={`${
                marketingMessage.visual ? "col-md-8" : "col-md-12"
              }`}
            >
              {parse(marketingMessage.text || "")}
            </Col>
          </Row>
        )}
        {/* <-------------------- For disclaimer -------------------------> */}
        {marketingMessage.disclaimer?.trim().length > 0 && (
          <Row className="grid-m-message">
            <span
              className="metadata label-font-size"
              style={{ width: "100%" }}
            >
              Disclaimer
            </span>{" "}
            <div>{parse(marketingMessage.disclaimer || "")}</div>
          </Row>
        )}
        {/*---------------------For CTAs-------------------------------- */}
        {isCTA(marketingMessage) && (
          <Row className="grid-m-message">
            <span className="metadata" style={{ width: "100%" }}>
              CTA Link
            </span>{" "}
            <a
              href={getClickableLink(marketingMessage.url)}
              className="cta-link-channel-grid"
              target="_blank"
              rel="noopener noreferrer"
            >
              {marketingMessage.url}
            </a>
            <span className="metadata" style={{ width: "100%" }}>
              Label (s)
            </span>{" "}
            <div>
              {marketingMessage.labels.length > 0
                ? marketingMessage.labels.map((label, index) => {
                    return <div key={index}>{label}</div>;
                  })
                : "-"}
            </div>
          </Row>
        )}
        <Row>
          <Col className="col-md-8">
            <div>
              <span
                className="cardMetaData label-font-size"
                style={{ marginRight: "6px" }}
              >
                ID
              </span>
              <span className="value-font-size">{marketingMessage.id}</span>
            </div>
          </Col>
          <Col className="col-md-4 text-right">
            {/* <span className="mr-4">    
                {getModifiedStatus(
                  marketingMessage.createdDateTime,
                  marketingMessage.updatedDateTime
                ) ? (
                  <>
                    <img src={editedIcon} alt="Edited" /> Edited  //---->edited icon    
                  </>
                ) : null}
                </span>  */}
            {!isStylisedModuleInitiated && (
              <button
                type="button"
                className="btn btn-link text-uppercase pr-0"
                onClick={() => {
                  // if (isCTA(marketingMessage)) {
                  //   return;
                  // }
                  startEditingMessage(marketingMessage);
                }}
                disabled={!isMarketingMessageEditable(marketingMessage)}
              >
                EDIT
              </button>
            )}
          </Col>
        </Row>

        {/* <span className="cardMetaData">
            Source: <strong>{marketingMessage.source}</strong>
          </span> */}
      </div>
    </>
  );
};

export default SelectableMarketingMessageCard;
