import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const CreateSubjectLines = ({ subjectLines, setSubjectLines, editMode }) => {
  const defaultSubjectLine = {
    text: "",
    disclaimer: "",
  };

  useEffect(() => {
    if (!subjectLines || subjectLines.length === 0) {
      setSubjectLines([defaultSubjectLine]);
    }
  }, [subjectLines]);

  const updateSubjectlines = (subjectline, currentIndex) => {
    const subjectlines = [...subjectLines];

    subjectlines[currentIndex] = subjectline;

    setSubjectLines(subjectlines);
  };

  const addNewSubjectLine = (newSubjectline) => {
    const subjectlines = [...subjectLines];
    setSubjectLines(subjectlines.concat(newSubjectline));
  };
  const removeSubjectline = (atIndex) => {
    let subjectlines = [...subjectLines];
    subjectlines.splice(atIndex, 1);
    setSubjectLines(subjectlines);
  };
  return (
    <div>
      {subjectLines.map((subjectline, index) => {
        return (
          <CreateSubjectLine
            subjectline={{ ...subjectline }}
            updateSubjectlines={updateSubjectlines}
            editMode={editMode}
            currentIndex={index}
            addNewSubjectLine={addNewSubjectLine}
            removeSubjectline={removeSubjectline}
            isLastIndex={index === subjectLines.length - 1}
            subjectLinesLength={subjectLines.length}
          />
        );
      })}
    </div>
  );
};
export default CreateSubjectLines;

const CreateSubjectLine = ({
  subjectline,
  updateSubjectlines,
  currentIndex,
  editMode,
  removeSubjectline,
  addNewSubjectLine,
  isLastIndex,
  subjectLinesLength,
}) => {
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const handleTextChange = (value, type) => {
    if (type === "subjectline") {
      subjectline.text = value;
    } else if (type === "disclaimer") {
      subjectline.disclaimer = value;
    }
    updateSubjectlines(subjectline, currentIndex);
  };

  return (
    <div>
      {!editMode && (
        <div className="row attribute-head">
          <div className="col-md-6" style={{ paddingTop: "5px" }}>
            {" "}
            <span className="marketing-message-label">
              Subject Line {currentIndex + 1}
            </span>
          </div>

          <div className="col-md-6 text-right">
            <button
              className="btn btn-link"
              onClick={() => {
                removeSubjectline(currentIndex);
                setShouldHighlight(false);
              }}
              disabled={
                subjectLinesLength && subjectLinesLength === 1 ? true : false
              }
            >
              Remove
            </button>

            {isLastIndex && (
              <button
                className="btn btn-link"
                onClick={() => {
                  addNewSubjectLine({ text: "", disclaimer: "" });
                }}
                disabled={subjectline.text.trim().length === 0}
              >
                Add
              </button>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 MarketingMessageSubjectLine">
          <TextField
            className="SubjectLineMessage"
            id={`subjectLineMessage${currentIndex}`}
            label="Enter Message"
            variant="outlined"
            fullWidth={true}
            rows={9}
            rowsMax={9}
            multiline
            value={subjectline.text}
            onChange={(e) => {
              let flag = false;
              if (
                subjectline?.text?.trim().length > 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              } else if (
                subjectline?.text?.trim().length === 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              }
              setShouldHighlight(flag);
              handleTextChange(e.target.value, "subjectline");
            }}
            inputProps={{ maxLength: 250 }}
            helperText={shouldHighlight ? "This field can not be empty" : ""}
            error={shouldHighlight ? true : false}
          />
        </div>

        <div className="col-md-12 MarketingMessageSubjectLine">
          <TextField
            className="SubjectLineDisclaimer"
            id={`subjectLineDisclaimer${currentIndex}`}
            label="Enter Disclaimer"
            variant="outlined"
            fullWidth={true}
            rows={9}
            rowsMax={9}
            multiline
            value={subjectline.disclaimer}
            onChange={(e) => handleTextChange(e.target.value, "disclaimer")}
            disabled={subjectline.text.trim().length === 0}
            inputProps={{ maxLength: 250 }}
          />
        </div>
      </div>
    </div>
  );
};
