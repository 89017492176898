import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomModal from "../customModal";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import {
  getContentmatrix,
  publishContentMatrix,
  getCompositeAssetData,
  getVeevaMlrStatus,
} from "../../services/apis";
import CompositeAssetView from "./CompositeAssets/CompositeAssetView";
import ModularContentMatrices from "../../components/Dams/Veeva/ModularContentMatrices";

import "../../styles/components/ContentMatrixTab.scss";
import ContentMatrixModal from "./ContentMatrixModal";
import {
  capitalizeString,
  damsSources,
  isModularContent,
} from "../../Uitls/Util";
import DropDownWithLabel from "../DropDownWithLabel";

//Import resources
import refresh from "../../images/refresh.svg";
import mlrStatusInDraft from "../../images/mlr_status_draft.svg";
import mlrStatusInReview from "../../images/mlr_status_inreview.svg";
import mlrStatusApproved from "../../images/mlr_status_approved.svg";
import { gridColumnsTotalWidthSelector } from "@material-ui/data-grid";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { capitalize } from "@material-ui/core";

const ContentMatrix = (props) => {
  const { t } = useTranslation();
  const {
    matrixData,
    brand,
    campaignName,
    modules,
    assetDataType,
    sourceType,
    startBRulesValidation,
    isBRulesValidationOpen,
    setChannelSelectionModal,
  } = props;
  const [isloading, setIsloading] = useState(false);

  //For module details
  const [compositeAssetToView, setCompositeAssetToView] = useState(undefined);

  //For Business rules validation
  //const [isBRulesValidationOpen, setBRulesValidationOpen] = useState(false);

  //For Content MAtrix Regeneration
  const [contentMatrices, setContentMatrices] = useState([]);
  const [
    contentMatrixToBeRegeneratedData,
    setContentMatrixToBeRegeneratedData,
  ] = useState(undefined);
  const [masterContentMatrices, setMasterContentMatrices] = useState([]);
  const [matrixIdSource, setMatrixIdSource] = useState([]);
  const [selectedContentMatrixID, setSelectedContentMatrixID] =
    useState(undefined);

  useEffect(() => {
    setContentMatrices(matrixData);
    setMasterContentMatrices(matrixData);
    setMatrixIdSource(
      matrixData?.map((matrix) => {
        return { displayName: matrix.contentMatrixId };
      })
    );
  }, [matrixData]);

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const viewCompositeAssetDetails = (composit_asset_id) => {
    setIsloading(true);
    getCompositeAssetData(
      sourceType,
      assetDataType,
      composit_asset_id,
      (response, err, errCode) => {
        if (err) {
          setIsloading(false);
          showError(errCode);
        } else {
          setIsloading(false);
          let data = response.data;
          // let compostiteAssetDetails = {
          //   subjectLineText: data.subjectLineText,
          //   preHeaderText: data.preHeaderText,
          //   bodyModules: data.bodyModules,

          // };
          setCompositeAssetToView(data);
        }
      }
    );
  };

  const notifyPublishMatrixToDamsError = () => {
    toast.error(
      <>
        <p className="notificationHeading">
          {" "}
          {capitalizeString(sourceType)} Upload Un-successful
        </p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const notifyPublishMatrixToDamsSucess = () => {
    toast.success(
      <>
        <p className="notificationHeading">
          {capitalizeString(sourceType)} Upload successful
        </p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const publishMatrixToDams = (contentMatrixId, id) => {
    setIsloading(true);
    publishContentMatrix(
      sourceType,
      contentMatrixId,
      assetDataType,
      (response, err, errorCode) => {
        setIsloading(false);
        if (err || errorCode) {
          notifyPublishMatrixToDamsError();
        } else {
          refreshMatrix(id, undefined, response.data);
          notifyPublishMatrixToDamsSucess();
        }
      }
    );
  };

  const openCreateContentMatrixModal = (contentMatrix) => {
    let data = { matrix: contentMatrix };
    setIsloading(true);
    if (
      sourceType.toLowerCase() === damsSources.aprimo.name.toLowerCase() ||
      (sourceType.toLowerCase() === damsSources.veeva.name.toLowerCase() &&
        assetDataType.toLowerCase() === "assetcomponent")
    ) {
      getContentmatrix(
        contentMatrix.mouduleId,
        sourceType,
        assetDataType,
        (response, err, errCode) => {
          setIsloading(false);
          if (err) {
            const errCoponent = errComponentToRender(errCode);
            toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
          } else {
            data["modulesData"] = response.data;
            // setMatrixData(response.data);
            const originalModule = response.data.find((module) => {
              return module.contentModuleType.toLowerCase() === "original";
            });
            data.campaign = campaignName;
            data.brand = brand;
            data.moduleCode = originalModule.moduleCode;
            setContentMatrixToBeRegeneratedData(data);
          }
        }
      );
    } else {
      getContentmatrix(
        contentMatrix.mouduleId,
        sourceType,
        assetDataType,
        (response, err, errCode) => {
          setIsloading(false);
          if (err) {
            const errCoponent = errComponentToRender(errCode);
            toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
          } else {
            data["modulesData"] = response.data;
            let selectedModule = modules.find(
              (element) => element.cmId === contentMatrix.mouduleId
            );
            const originalModule = response.data.find((module) => {
              return module.contentModuleType.toLowerCase() === "original";
            });
            data.campaign = selectedModule.campaign;
            data.brand = selectedModule.brand;
            data.moduleCode = originalModule.moduleCode;
            setContentMatrixToBeRegeneratedData(data);
          }
        }
      );
    }
  };

  const refreshCompositeAssets = (updatedCompositeAssets) => {
    if (!updatedCompositeAssets) {
      return;
    }
    const matrices = [...contentMatrices];
    const contentMatrixId =
      contentMatrixToBeRegeneratedData?.matrix?.contentMatrixId;
    const index = matrices.findIndex((matrix) => {
      return matrix.contentMatrixId === contentMatrixId;
    });
    if (index > -1) {
      matrices[index].compositIds = updatedCompositeAssets.map(
        (compositeAsset) => compositeAsset.compositeId
      );
    }
    setContentMatrices(matrices);
    setMasterContentMatrices(matrices);
  };

  const filterContentMatricesBasedOnMatrixId = (matrixId) => {
    let filteredMatrix = matrixData;
    if (matrixId) {
      filteredMatrix = [...masterContentMatrices].filter((item) => {
        return item.contentMatrixId === matrixId;
      });
    }
    setContentMatrices(filteredMatrix);
  };

  const getMlrStatusIcon = (status) => {
    switch (status) {
      case "Draft":
        return <img src={mlrStatusInDraft} alt="" className="mlrStatusIconM" />;
      case "In MLR Review":
        return (
          <img src={mlrStatusInReview} alt="" className="mlrStatusIconM" />
        );
      case "Approved":
        return (
          <img src={mlrStatusApproved} alt="" className="mlrStatusIconM" />
        );
      default:
        return;
    }
  };
  const refreshMatrix = (matrixId, updatedStatus, updatedMatrix) => {
    const matrices = JSON.parse(JSON.stringify(contentMatrices));
    let index = matrices.findIndex((item) => item.id === matrixId);
    if (updatedStatus) {
      matrices[index].matrixMlrStatus = updatedStatus;
    } else if (updatedMatrix) {
      matrices[index] = updatedMatrix;
    }
    setContentMatrices(matrices);
  };

  const getVeevaModuleStatus = (Id) => {
    setIsloading(true);
    getVeevaMlrStatus(Id, (response, err, errCode) => {
      if (err) {
        setIsloading(false);
        showError(errCode);
      } else {
        setIsloading(false);
        let data = response.data;
        refreshMatrix(Id, data);
      }
    });
  };

  const isChannelMatrixEdiatable = (mlrStatus) => {
    if (!mlrStatus || mlrStatus.toLowerCase() === "draft") {
      return true;
    }
    return false;
  };

  const moduleCardBtnActions = (module) => {
    window.open(module.damsUrl, "_blank");
  };

  return (
    <div className="col-md-12">
      <CircularProgressBar isloading={isloading} />
      {
        <div className="contentMatrixTabBlock customScrollBar">
          {assetDataType.toLowerCase() === "assetcomponent" ? (
            <>
              {modules && modules.length > 0 && (
                <div
                  className="matrixRowBtmBrdr"
                  style={{ paddingBottom: "16px" }}
                >
                  <div className="text-left drp-dwn-channel-matrix">
                    <DropDownWithSearch
                      class="drp-dwn-channel-matrix"
                      options={{
                        value: selectedContentMatrixID,
                        name: t("channelMatrix.channelMatrixId"),
                        label: t("channelMatrix.channelMatrixId"),
                        items: matrixIdSource,
                        handleChange: (channelMatrix) => {
                          const value = channelMatrix?.displayName;
                          setSelectedContentMatrixID(channelMatrix);
                          filterContentMatricesBasedOnMatrixId(value);
                        },
                      }}
                    />
                  </div>
                  <div className=" text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        //startBRulesValidation(true);
                        setChannelSelectionModal(true);
                      }}
                    >
                      {t("channelMatrix.createChannelMatrix")}
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <ModularContentMatrices
              setChannelSelectionModal={setChannelSelectionModal}
              contentMatrices={contentMatrices}
              modules={modules}
              filterContentMatricesBasedOnMatrixId={
                filterContentMatricesBasedOnMatrixId
              }
              selectedContentMatrixID={selectedContentMatrixID}
              setSelectedContentMatrixID={setSelectedContentMatrixID}
              matrixIdSource={matrixIdSource}
            />
          )}
          {!isBRulesValidationOpen &&
            !compositeAssetToView &&
            contentMatrices?.map((item) => {
              return (
                <>
                  <div
                    className="row matrix-head"
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                  >
                    <div
                      // className={`${
                      //   isChannelMatrixEdiatable(item.matrixMlrStatus)
                      //     ? "col-md-4"
                      //     : "col-md-6"
                      // } matrixBrandDetails`}
                      className={`col-md-6
                       matrixBrandDetails`}
                    >
                      {t("channelMatrix.channelMatrixId")} :{" "}
                      {item.damsUrl && item.damsUrl ? (
                        <button
                          className="btn btn-link pl-0"
                          style={{ textDecoration: "underline" }}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            moduleCardBtnActions(item);
                          }}
                        >
                          {item.contentMatrixId}
                        </button>
                      ) : (
                        <span style={{ marginRight: "10px" }}>
                          {item.contentMatrixId}
                        </span>
                      )}
                      |{" "}
                      <button
                        className="btn btn-link p-0"
                        onClick={() => window.open(item.downloadUrl)}
                      >
                        Download
                      </button>
                    </div>
                    <div
                      className={`${
                        isChannelMatrixEdiatable(item.matrixMlrStatus)
                          ? "col-md-3"
                          : "col-md-6"
                      } text-right`}
                    >
                      {item.matrixMlrStatus && (
                        <>
                          <span className="label-mlr-status">MLR Status</span>
                          <span>{getMlrStatusIcon(item.matrixMlrStatus)}</span>
                          <span>{item.matrixMlrStatus}</span>

                          <img
                            className="refreshIcon"
                            src={refresh}
                            alt=""
                            onClick={() => {
                              getVeevaModuleStatus(item.id);
                            }}
                          />
                        </>
                      )}
                      {!item.matrixMlrStatus && (
                        <>
                          <span className="label-mlr-status">MLR Status</span>
                          <span>{item.matrixMlrStatus || "Not Submitted"}</span>
                        </>
                      )}
                    </div>
                    {isChannelMatrixEdiatable(item.matrixMlrStatus) && (
                      <div className="col-md-3 text-right">
                        <button
                          className="btn btn-link p-0"
                          onClick={() =>
                            publishMatrixToDams(item.contentMatrixId, item.id)
                          }
                        >
                          Publish
                        </button>{" "}
                        |{" "}
                        <button
                          className="btn btn-link p-0"
                          onClick={() => openCreateContentMatrixModal(item)}
                        >
                          Re-generate
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="matrixRowBtmBrdr">
                    <div className="row pb-4">
                      {item?.compositIds.map((matrix) => (
                        <div className="col-md-3">
                          <div className="matrixBox">
                            <div className="d-block">
                              <span>Composite Asset ID</span> {matrix}
                            </div>
                            <div className="d-block text-center mt-2">
                              <button
                                className="btn btn-link p-0"
                                onClick={() =>
                                  viewCompositeAssetDetails(matrix)
                                }
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      }

      {contentMatrixToBeRegeneratedData && (
        <CustomModal
          displayModal={contentMatrixToBeRegeneratedData}
          hideModal={(updatedCompositeAssets) => {
            setContentMatrixToBeRegeneratedData(undefined);
            refreshCompositeAssets(updatedCompositeAssets);
          }}
          title={"List of Associated Modules"}
          size="xl"
          dilogClassName="alertModalSmall"
          // backdrop="false"
          keyboard={false}
        >
          <ContentMatrixModal
            hideContentMatrixModal={(updatedCompositeAssets) => {
              setContentMatrixToBeRegeneratedData(undefined);
              refreshCompositeAssets(updatedCompositeAssets);
            }}
            moduleDetails={{
              brandName: contentMatrixToBeRegeneratedData.brand,
              campaign: contentMatrixToBeRegeneratedData.campaign,
              moduleCode: contentMatrixToBeRegeneratedData.moduleCode,
            }}
            sourceType={sourceType}
            assetDataType={assetDataType}
            templates={[
              {
                templateType:
                  contentMatrixToBeRegeneratedData.matrix.templateType,
                templates: [
                  {
                    templateName:
                      contentMatrixToBeRegeneratedData.matrix.templateName,
                    templateId:
                      contentMatrixToBeRegeneratedData.matrix.templateId,
                    isSelected: true,
                  },
                ],
              },
            ]}
            matrixData={contentMatrixToBeRegeneratedData.modulesData}
            contentMatrix={contentMatrixToBeRegeneratedData.matrix}
          />
        </CustomModal>
      )}
      {compositeAssetToView && (
        <CustomModal
          displayModal={compositeAssetToView}
          hideModal={() => {
            setCompositeAssetToView(undefined);
          }}
          title={""}
          size="xl"
          dilogClassName="alertModalSmall"
          // backdrop="false"
          keyboard={false}
        >
          <CompositeAssetView
            hideContentMatrixModal={() => {
              setCompositeAssetToView(undefined);
            }}
            data={compositeAssetToView}
            isView={true}
            sourceType={sourceType}
            assetDataType={assetDataType}
          />

          <div>
            <button
              className="btn btn-link"
              style={{ float: "right" }}
              onClick={() => {
                setCompositeAssetToView(undefined);
              }}
            >
              CLOSE
            </button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ContentMatrix;
