import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import TranslateFootnotes from "./TranslateFootnotes";
import TranslateAbbreviations from "./TranslateAbbreviations";
import TranslateReferences from "./TraslateReferences";
import CustomModal from "../../customModal";
import TranslateClaimsGenericModal from "../../TranslateFlow/TranslateClaims/TranslateClaimGenericModal";
import Menu from "../TranslateClaims/Menu";
import Comments from "../Comments";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

import translateTick from "../../../images/translateTick.svg";
import arrowpolygonRight from "../../../images/arrowpolygon-right.svg";
import arrowLeft from "../../../images/arrowLeft.svg";
import arrowRight from "../../../images/arrowRight.svg";
import translateIcon from "../../../images/translateIcon.svg";
import commentIcon from "../../../images/icon-comment.svg";

import "../../../styles/pages/TranslateClaims.scss";
import "../../../styles/components/PrimaryClaim.scss";

const TranslateSupportingVisuals = ({
  module,
  isAnyToggleOn,
  saveTranslated,
  discardTranslated,
  addComment,
}) => {
  const { t } = useTranslation();
  const [arbitararyVar, setArbitraryVar] = useState(0); //just to update the layout
  const [showGenericTranslateModal, setShowGenericTranslateModal] =
    useState(false);
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);
  const [dataTypeSelectedForTranslation, setDtaTypeSelectedForTranslation] =
    useState("visual");
  const [supportingVisualId, setSupportingVisualId] = useState();
  //let froalaInstance = React.createRef();
  const captionRef = useRef(null);

  //For Comments
  const [boxComments, setBoxComments] = useState([]);
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showFootnotesCommentsDialog, setShowFootnotesCommentsDialog] =
    useState(false);
  const [showAbbreviationsCommentsDialog, setShowAbbreviationsCommentsDialog] =
    useState(false);
  const [visualRowIndex, setVisualRowIndex] = useState(-1);

  // useEffect(() => {
  //   if (isAnyToggleOn && froalaInstance && froalaInstance.current) {
  //     froalaInstance.current.editor.edit.off();
  //   } else {
  //     if (froalaInstance && froalaInstance.current) {
  //       froalaInstance.current.editor.edit.on();
  //     }
  //   }
  // }, [isAnyToggleOn]);

  const getSupportingVisuals = () => {
    if (module && module.moduleVisuals) {
      return module.moduleVisuals.filter((visual) => {
        return visual.translate !== "0";
      });
    }
    return [];
  };

  const getCaptions = (visual) => {
    if (visual) {
      if (visual.supportingTextComponents.length > 0) {
        return visual.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "caption";
        });
      }
    }
    return [];
  };

  const getFootnotes = (visual) => {
    if (visual) {
      if (visual.supportingTextComponents.length > 0) {
        return visual.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "footnote";
        });
      }
    }
    return [];
  };

  const getAbbreviations = (visual) => {
    if (visual) {
      if (visual.supportingTextComponents.length > 0) {
        return visual.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "abbreviation";
        });
      }
    }
    return [];
  };

  const getReferences = (visual) => {
    if (visual) {
      if (visual.supportingTextComponents.length > 0) {
        return visual.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "reference";
        });
      }
    }
    return [];
  };

  const setVariantIndex = (visual, stepValue) => {
    const captions = getCaptions(visual);
    const selectedIndex = visual.selectedIndex || 1;
    if (selectedIndex >= 1 && selectedIndex <= captions.length) {
      if (
        (selectedIndex === 1 && stepValue === -1) ||
        (selectedIndex === captions.length && stepValue === 1)
      ) {
        return;
      }
      visual.selectedIndex = selectedIndex + stepValue;
      setArbitraryVar(visual.selectedIndex);
    }
  };
  const intiateTranslationFor = (data, type) => {
    setDataInputForTranslation(() => {
      openGenericTranslateModal();
    });
    setDataInputForTranslation(data);
    setDtaTypeSelectedForTranslation(type);
  };

  const handleCloseGenericModal = () => {
    setShowGenericTranslateModal(false);
    discardTranslated();
  };
  const openGenericTranslateModal = () => {
    setShowGenericTranslateModal(true);
  };

  const handleSave = () => {
    setShowGenericTranslateModal(false);
    //Check if all boxes are translated
    module.moduleVisuals.forEach((visual) => {
      let isAllTranslated = true;
      visual.supportingTextComponents.forEach((comp) => {
        if (comp.translate === "1") {
          isAllTranslated = false;
        }
      });
      if (isAllTranslated) {
        visual.translate = "2";
      } else {
        visual.translate = "1";
      }
    });

    saveTranslated();
  };
  const checkForTranslate = (visual) => {
    if (isAnyToggleOn) {
      return false;
    }
    if (visual.supportingTextComponents.length > 0) {
      let captions = visual.supportingTextComponents.filter((supText) => {
        return supText.componentType.trim().toLowerCase() === "caption";
      });
      let result = true;
      for (var i in captions) {
        if (captions[i].translate !== "2") {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  };

  const consolidateComments = (boxName, primaryComponentId) => {
    let comments = [];
    const allComments = module.comments;
    let supportingVisual = getSupportingVisuals().filter((visual) => {
      return visual.id === primaryComponentId;
    })[0];
    switch (boxName) {
      case "visual":
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === primaryComponentId &&
            comment.componentId === primaryComponentId
          );
        });
        break;
      case "footnote":
        const footnotes = supportingVisual.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "footnote";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            footnotes.findIndex((footnote) => {
              return (
                comment.primaryComponentId === primaryComponentId &&
                footnote.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      case "abbreviation":
        const abbreviations = supportingVisual.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "abbreviation";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            abbreviations.findIndex((abbreviation) => {
              return (
                comment.primaryComponentId === primaryComponentId &&
                abbreviation.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      default:
        break;
    }

    return comments;
  };
  const handleShowCommentsDialog = (boxName, showFlag) => {
    switch (boxName) {
      case "abbreviation":
        setShowAbbreviationsCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
      case "footnote":
        setShowFootnotesCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowAbbreviationsCommentsDialog(false);
        break;
      default:
        setShowCommentsDialog(showFlag);
        setShowAbbreviationsCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
    }
  };

  const getCommentBubbleFor = (boxName, primaryComponentId) => {
    const comments = consolidateComments(boxName, primaryComponentId);
    if (!comments || comments.length === 0) {
      return;
    }
    return (
      <div
        style={{
          float: "right",
          marginRight: `${hasUserLoggedInAsReviewer() ? "0px" : "50px"}`,
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          className="commentIcon"
          onClick={() => {
            if (comments && comments.length > 0) {
              const rowIndex = getSupportingVisuals().findIndex((claim) => {
                return claim.id === primaryComponentId;
              });
              //hide the comments on click if already opened
              let flag = !showCommentsDialog;
              if (boxName === "abbreviation") {
                flag = !showAbbreviationsCommentsDialog;
              }
              if (boxName === "footnote") {
                flag = !showFootnotesCommentsDialog;
              }
              if (flag) {
                setVisualRowIndex(rowIndex);
              } else {
                setVisualRowIndex(-1);
              }
              setBoxComments(() => {
                handleShowCommentsDialog(boxName, flag);
              });
              setBoxComments(comments);
            }
          }}
        />
      </div>
    );
  };

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: supportingVisualId,
      componentId: supportingVisualId,
    };
    addComment(commentObject);
  };
  const getCommentsData = () => {
    setShowCommentsDialog(false);
    setShowAbbreviationsCommentsDialog(false);
    setShowFootnotesCommentsDialog(false);
    discardTranslated();
  };
  return (
    <>
      <div ref={captionRef}>
        {getSupportingVisuals().length > 0 && (
          <div>
            {getSupportingVisuals().map((visual, index) => {
              return (
                <div key={visual.id}>
                  <Row className="mb-4 align-items-center no-gutters mt-4">
                    <Col className="col-md-3">
                      <img
                        src={arrowpolygonRight}
                        alt=""
                        className="claimTitleImg"
                      />
                      <span className="claimTitle">
                        {t("supportinVisuals.supportTitle")}{" "}
                        {getSupportingVisuals().length > 1 ? index + 1 : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className="align-items-center translateRow"
                    key={visual.id}
                  >
                    <Col className="col-12 col-md-3">
                      <div className="translateBox translateBoxType1 translateBoxSelected custonScrollBar">
                        <p className="translateBox-head d-flex justify-content-between">
                          {t("supportinVisuals.image")}
                          {!isAnyToggleOn && (
                            <span className="translatedBoxSuccess">
                              <img src={translateTick} alt="Translate" />
                            </span>
                          )}
                        </p>
                        <div className="translateClaimsContainer customScrollBar">
                          {visual.visualVariants.map((variant) => {
                            return (
                              <img
                                src={variant.visualUrl}
                                alt="Suporting Visual"
                                className="img-fluid mb-1"
                                key={variant.id}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col className="col-12 col-md-3">
                      <div className="translateBox translateBoxType2 translateSuppCaption">
                        <p className="translateBox-head d-flex justify-content-between">
                          {t("supportinVisuals.captions")}
                          {checkForTranslate(visual) && (
                            <span className="translatedBoxSuccess">
                              <img src={translateTick} alt="Translate" />
                            </span>
                          )}
                        </p>
                        <div
                          className="translateClaimsContainer2 customScrollBar"
                          id={`supporting-visual${visual.id}`}
                          onClick={() => setSupportingVisualId(visual.id)}
                        >
                          <span className="translateClaimText">
                            {/* {parse(
                              isAnyToggleOn
                                ? getCaptions(visual)[
                                    visual.selectedIndex - 1 || 0
                                  ].originalText
                                : getCaptions(visual)[
                                    visual.selectedIndex - 1 || 0
                                  ].text
                            )} */}
                            {parse(
                              getCaptions(visual).length > 0
                                ? getCaptions(visual)[
                                    visual.selectedIndex - 1 || 0
                                  ].text
                                : ""
                            )}
                          </span>
                        </div>
                        {getCaptions(visual).length > 1 && (
                          <div className="button-row" style={{ zIndex: "100" }}>
                            <button
                              className="leftBtn"
                              onClick={() => {
                                setVariantIndex(visual, -1);
                              }}
                            >
                              <img
                                src={arrowLeft}
                                alt="Previous"
                                className="opacity50"
                              />
                            </button>
                            <span className="slideCount">
                              {visual.selectedIndex || 1}/
                              {getCaptions(visual).length}
                            </span>
                            <button
                              className="rightBtn"
                              onClick={() => {
                                setVariantIndex(visual, 1);
                              }}
                            >
                              <img
                                src={arrowRight}
                                alt="Next"
                                className="opacity50"
                              />
                            </button>
                          </div>
                        )}
                        <div className="floating-buttons">
                          {!isAnyToggleOn &&
                            !hasUserLoggedInAsReviewer() &&
                            getCaptions(visual).length > 0 && (
                              <div style={{ zIndex: "1" }}>
                                <button
                                  className="btn btn-link translate-icon"
                                  style={{ marginRight: "-12px", bottom: "0" }}
                                  onClick={() => {
                                    intiateTranslationFor(
                                      getCaptions(visual),
                                      "visual"
                                    );
                                  }}
                                >
                                  <img src={translateIcon} alt="Translate" />
                                </button>
                              </div>
                            )}
                          {getCommentBubbleFor("visual", visual.id)}
                        </div>
                      </div>
                      {showCommentsDialog && index === visualRowIndex && (
                        <Comments
                          moduleId={module.id}
                          componentId={visual.id}
                          childComponentId=""
                          comments={boxComments}
                          commentsPopupCurrentState={showCommentsDialog}
                          commentsPopupUpdateState={setShowCommentsDialog}
                          currentClass={
                            hasUserLoggedInAsReviewer()
                              ? "commentsPosition2"
                              : "commentsPosition2-translator"
                          }
                          getCommentsData={getCommentsData}
                        />
                      )}
                      <TranslateFootnotes
                        footnotes={getFootnotes(visual)}
                        isAnyToggleOn={isAnyToggleOn}
                        containerClass="translateClaimsContainer2"
                        classBoxType="translateBoxType2"
                        targetLanguage={module.language}
                        intiateTranslationFor={intiateTranslationFor}
                        addComment={addComment}
                        getCommentBubbleFor={getCommentBubbleFor}
                        primaryComponentId={visual.id}
                        comments={boxComments}
                        showCommentsFlag={
                          index === visualRowIndex
                            ? showFootnotesCommentsDialog
                            : false
                        }
                        updateShowCommentsFlag={setShowFootnotesCommentsDialog}
                        getCommentsData={getCommentsData}
                      />
                    </Col>

                    <Col className="col-12 col-md-3">
                      <TranslateAbbreviations
                        abbreviations={getAbbreviations(visual)}
                        isAnyToggleOn={isAnyToggleOn}
                        targetLanguage={module.language}
                        intiateTranslationFor={intiateTranslationFor}
                        addComment={addComment}
                        getCommentBubbleFor={getCommentBubbleFor}
                        primaryComponentId={visual.id}
                        comments={boxComments}
                        showCommentsFlag={
                          index === visualRowIndex
                            ? showAbbreviationsCommentsDialog
                            : false
                        }
                        updateShowCommentsFlag={
                          setShowAbbreviationsCommentsDialog
                        }
                        getCommentsData={getCommentsData}
                      />
                    </Col>
                    <Col className="col-12 col-md-3" disabled={true}>
                      <TranslateReferences
                        references={getReferences(visual)}
                        isAnyToggleOn={isAnyToggleOn}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        )}
        {!isAnyToggleOn && (
          <Menu
            outerRef={captionRef}
            compClass={`supporting-visual${supportingVisualId}`}
            addComment={addAComment}
          />
        )}
        <CustomModal
          displayModal={showGenericTranslateModal}
          hideModal={handleCloseGenericModal}
          title="Select the components you would like to translate"
          size="xl"
        >
          <TranslateClaimsGenericModal
            handleClose={handleCloseGenericModal}
            sourceLanguage={module.translatedFrom}
            targetLanguage={module.language}
            dataInput={dataInputForTranslation}
            dataType={dataTypeSelectedForTranslation}
            saveTranslated={handleSave}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default TranslateSupportingVisuals;
