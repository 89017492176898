import React, { useState, useEffect, useRef } from "react";
import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import {
  routes,
  getModuleInfoFromLocalStorage,
  isThisMLRFeedbackFlow,
  moduleTypes,
} from "../Uitls/Util";
import Banner from "../components/Banner";
import Alert from "../components/Alert";
import Email from "../components/Email";
import CTA from "../components/CTA";
import { v4 as uuidv4 } from "uuid";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/pages/CreateModuleStep4.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import LogoutCTA from "../Pages/LogoutCTA";
import MlrFeedbackNav from "../components/MlrFeedback/MlrFeedbackNav";
import {
  getModuleDetailsById,
  createChannels,
  createCTAs,
  deleteCTAs,
  deleteCTATexts,
  deleteChannels,
  getLatestAnnotatedPdfFromVeeva,
} from "../services/apis";
import fullScreenIcon from "../images/fullScreenIcon.svg";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";
const AddChannels = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [moduleId, setModuleId] = useState(undefined);
  const [moduleCode, setModuleCode] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [currentPage, setCurrentPage] = useState(routes.addChannels);
  const [pages, setPages] = useState([]);
  const [options, setOptions] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [emailElements, setEmailElements] = useState(null);
  const [banners, setBanners] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [ctas, setCtas] = useState([]);
  const [ctaErrors, setCtaErros] = useState([]);
  //const [btnOptions, setBtnOptions] = useState({})
  const [isloading, setIsloading] = useState(false);
  const [shouldDisableProceedButton, setShouldDisableProceedButton] =
    useState(true);
  const [mlrModuleStatus, setMlrModuleStatus] = useState("");
  const [veevaDocId, setVeevaDocId] = useState(undefined);

  //For Addressing MLR Feedback scenarios only
  const [module, setModule] = useState(undefined);
  const [isMainContentExpanded, setMaincontentExpanded] = useState(false);
  const [isPdfContentExpanded, setPdfcontentExpanded] = useState(false);
  const pdfElementRef = useRef(null);
  const [pdfwidth, setpdfWidth] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(undefined);

  //For save button enable disable while network in progress
  const [enableSave, setEnableSave] = useState(true);

  useEffect(() => {
    if (isMainContentExpanded) {
      setPdfcontentExpanded(false);
    } else if (isPdfContentExpanded) {
      setMaincontentExpanded(false);
    }
    calculatePdfWidth();
  }, [isMainContentExpanded, isPdfContentExpanded]);

  const calculatePdfWidth = () => {
    if (pdfElementRef && pdfElementRef.current) {
      setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    }
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        if (res) {
          setPdfUrl(res.data);
          return;
        }
        const errCoponent = ErrComponentToRender(errCode);
        claimsSaveErr(errCoponent);
      });
    }
  };

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.step1
    ) {
      setPages(props.location.state.step1);
    }
    setOptions({ channels: ["Email", "Banner", "Alert"], others: ["CTA"] });
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      setModuleId(moduleInfo.moduleId);
    }
  }, []);

  useEffect(() => {
    if (moduleId) {
      getModuleDetails();
    }
  }, [moduleId]);

  useEffect(() => {
    if (
      (emailElements &&
        ((emailElements.subjectLines &&
          emailElements.subjectLines.length > 0) ||
          (emailElements.preheaders && emailElements.preheaders.length > 0) ||
          (emailElements.emailHeaders &&
            emailElements.emailHeaders.length > 0) ||
          (emailElements.optOut && emailElements.optOut.length > 0))) ||
      (banners && banners.length > 0) ||
      (alerts && alerts.length > 0)
    ) {
      setShouldDisableProceedButton(false);
    } else if (ctas.length > 0) {
      const validCtas = [...ctas].filter((cta) => {
        if (
          cta &&
          cta.url &&
          cta.url.trim() !== "" &&
          cta.ctaTexts &&
          cta.ctaTexts.length > 0
        ) {
          return cta;
        }
      });
      if (validCtas.length > 0) {
        setShouldDisableProceedButton(false);
      } else {
        setShouldDisableProceedButton(true);
      }
    } else {
      setShouldDisableProceedButton(true);
    }
  }, [alerts, emailElements, banners, ctas]);

  const buttonOptions = () => {
    const options = {
      moduleName: moduleName,
      moduleId: moduleId,
      moduleCode: moduleCode,
      btnSave: i18next.t("generalText.save"),
      disableProceed: shouldDisableProceedButton,
      //handleProceed : handleProceed,
      handlePageClose: handleClose,
      handlePageSave: handleSave,
    };
    return options;
  };
  const setModuleStatus = (module) => {
    let status = "";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };
  const getModuleDetails = () => {
    if (!moduleId) {
      return;
    }
    setIsloading(true);
    getModuleDetailsById(moduleId, (response, error, errorCode) => {
      setIsloading(false);
      if (response && !error) {
        setModuleStatus(response.data);
        handleModuleData(response.data);
        //handleCTAdata(response.moduleCtas)
        if (isThisMLRFeedbackFlow()) {
          setModule(response.data);
          fetchAnnotatedPdf(response.data);
          calculatePdfWidth();
        }
      }
    });
  };
  const handleModuleData = (response) => {
    if (!response) {
      return;
    }
    if (isThisMLRFeedbackFlow()) {
      setModule(response);
    }
    if (response.moduleCode) {
      setModuleCode(response.moduleCode);
    }
    if (response.name) {
      setModuleName(response.name);
    }
    const channelsData = response.moduleChannels;
    if (channelsData) {
      let subjectLines = [];
      let preheaders = [];
      let banners = [];
      let alerts = [];
      let emailHeaders = [];
      let optOut = [];
      if (channelsData.length <= 0) {
        setEmailElements({ subjectLines, preheaders, emailHeaders, optOut });
        setBanners(banners);
        setAlerts(alerts);
      } else {
        for (const channel of channelsData) {
          switch (channel.channelType) {
            case "Email":
              if (channel.textType === "Subject") {
                subjectLines = subjectLines.concat({
                  ...channel,
                  subjectLineValue: channel.text,
                  subectLineValueDisclamer: channel.disclaimer,
                });
              } else if (channel.textType === "PreHeader") {
                preheaders = preheaders.concat(channel);
              } else if (channel.textType === "Header") {
                const obj = [];
                let subTexts = channel.moduleChannelSubtexts;
                subTexts.forEach((itemList) => {
                  const test = itemList.text;
                  obj.push(test);
                  return obj;
                });
                let itemObject = {
                  headerText: obj[0],
                  restrictionForUse: obj[1],
                  rules: obj[2],
                };
                emailHeaders = emailHeaders.concat(itemObject);
              } else if (channel.textType === "OptOut") {
                const obj = [];
                let subTexts = channel.moduleChannelSubtexts;
                subTexts.forEach((itemList) => {
                  let temp = {
                    ...itemList,
                    subTextType: itemList.subtextType || itemList.subTextType,
                  };
                  delete temp.subtextType;
                  obj.push(temp);
                  return obj;
                });
                let itemObject = {
                  channelType: "Email",
                  textType: "OptOut",
                  id: channel.id,
                  moduleChannelSubtexts: obj,
                };
                optOut = optOut.concat(itemObject);
              }
              break;
            case "Banner":
              banners = banners.concat(channel);
              break;
            case "Alert":
              alerts = alerts.concat(channel);
              break;
            default:
              break;
          }
        }
        setEmailElements({ subjectLines, preheaders, emailHeaders, optOut });
        setBanners(banners);
        setAlerts(alerts);
      }
    } else {
      setEmailElements({
        subjectLines: [],
        preheaders: [],
        emailHeaders: [],
        optOut: [],
      });
      setBanners([]);
      setAlerts([]);
    }
    const ctaData = response.moduleCtas;
    if (ctaData && ctaData.length > 0) {
      handleCTAdata(ctaData);
      //setCtas(ctaData)
    } else {
      setCtas([{ ctaID: uuidv4(), url: "", ctaTexts: [] }]);
      //setCtas([])
    }
  };

  const handleCTAdata = (ctaData = []) => {
    let array = [];
    if (ctaData.length > 0) {
      array = ctaData.map((cta) => {
        if (cta.texts) {
          cta.ctaTexts = [...cta.texts].map((text) => {
            if (!text.moduleCtaId) {
              text["moduleCtaId"] = cta.id;
            }
            return text;
          });
        } else {
          cta.ctaTexts = [];
        }

        return cta;
      });
    }
    setCtas([].concat(array));
  };
  const createChannelsPayload = () => {
    let payload = [];
    if (emailElements) {
      const subjectLines = emailElements.subjectLines;
      const preheaders = emailElements.preheaders;
      const emailHeaders = removeEmptyObjects(emailElements.emailHeaders);
      const optOutData = emailElements.optOut;
      if (subjectLines && subjectLines.length > 0) {
        for (const sLine of subjectLines) {
          payload = payload.concat({
            channelType: "Email",
            textType: "Subject",
            text: sLine.subjectLineValue,
            disclaimer: sLine.subectLineValueDisclamer,
            cmDocumentNumber: sLine.cmDocumentNumber,
          });
        }
      }
      if (preheaders && preheaders.length > 0) {
        for (const pheader of preheaders) {
          payload = payload.concat({
            channelType: "Email",
            textType: "PreHeader",
            text: pheader.text,
            cmDocumentNumber: pheader.cmDocumentNumber,
          });
        }
      }
      if (emailHeaders && emailHeaders.length > 0) {
        for (const emailHeader of emailHeaders) {
          payload = payload.concat({
            channelType: "Email",
            textType: "Header",
            moduleChannelSubtexts: [
              {
                subTextType: "Text",
                text: emailHeader.headerText || "",
              },
              {
                subTextType: "Restriction",
                text: emailHeader.restrictionForUse || "",
              },
              {
                subTextType: "Rule",
                text: emailHeader.rules || "",
              },
            ],
          });
        }
      }
      if (optOutData && optOutData.length > 0) {
        for (const optOuts of optOutData) {
          if (optOuts.isNewlyAdded) {
            optOuts.id = undefined;
            optOuts.moduleChannelSubtexts = optOuts.moduleChannelSubtexts.map(
              (item) => {
                item.id = undefined;
                return item;
              }
            );
            optOuts.isNewlyAdded = undefined;
          }
        }
      }
      payload = payload.concat(optOutData);
    }

    if (banners && banners.length > 0) {
      for (const banner of banners) {
        payload = payload.concat({
          channelType: "Banner",
          textType: "Tagline",
          text: banner.text,
        });
      }
    }

    if (alerts && alerts.length > 0) {
      for (const alert of alerts) {
        payload = payload.concat({
          channelType: "Alert",
          textType: "Tagline",
          text: alert.text,
        });
      }
    }
    // if (payload.length > 0) {
    //   return payload;
    // } else {
    //   return undefined;
    // }
    return payload;
  };
  const areCtasValid = () => {
    const ctasErrors = [...ctas].map((cta) => {
      const ctaUrl = cta.url;
      if (!ctaUrl || ctaUrl.trim() === "") {
        if (
          cta.ctaTexts &&
          cta.ctaTexts.length > 0 &&
          !cta.ctaTexts.every((element) => element.text === undefined)
        ) {
          return {
            id: cta.ctaID || cta.id,
            error: i18next.t("channels.cta.pleaseEnterValidUrl"),
          };
        } else {
          return undefined;
        }
      }
    });
    if (
      ctasErrors &&
      ctasErrors.length > 0 &&
      !ctasErrors.every((element) => element === undefined)
    ) {
      setCtaErros(ctasErrors);
      return false;
    }
    return true;
  };

  const createCTAPayload = () => {
    let payload = [];
    if (ctas && ctas.length > 0) {
      const ctaData = [...ctas]
        .map((cta) => {
          if (cta.url && cta.url.trim() !== "") {
            return {
              atomId: "",
              texts: cta.ctaTexts,
              url: cta.url,
            };
          }
        })
        .filter((cta) => {
          if (cta) {
            return cta;
          }
        });
      payload = ctaData;
    }
    if (payload.length > 0) {
      return payload;
    } else {
      return undefined;
    }
  };

  const handleProceed = async () => {
    await postDataToServer();
    //move to the next page
    history.push(routes.additionalInfo);
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleCreateChannels = (response) => {
    setShouldDisableProceedButton(false);
    if (!response) {
      return;
    }
    handleModuleData({ moduleChannels: response });
  };
  const handleCreateCTAs = (response) => {
    setShouldDisableProceedButton(false);
    if (!response) {
      return;
    }
    handleCTAdata(response);
  };
  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };
  const handleSave = () => {
    postDataToServer();
  };

  const postDataToServer = async () => {
    if (!areCtasValid()) {
      setEnableSave(true);
      return;
    }
    const channelsPayload = createChannelsPayload();
    const ctaPayload = createCTAPayload();
    if (channelsPayload) {
      setIsloading(true);
      setEnableSave(false);
      await createChannels(moduleId, channelsPayload)
        .then((response) => {
          toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
            autoClose: 2000,
            position: "top-center",
          });
          setIsloading(false);
          handleCreateChannels(response.data);
          setEnableSave(true);
        })
        .catch((err) => {
          setIsloading(false);
          const errorCode = err.response && err.response.status;
          showError(errorCode);
          setEnableSave(true);
        });
    }
    if (ctaPayload) {
      setIsloading(true);
      setEnableSave(false);
      await createCTAs(moduleId, ctaPayload)
        .then((response) => {
          setIsloading(false);
          handleCreateCTAs(response.data);
          setEnableSave(true);
        })
        .catch((err) => {
          setIsloading(false);
          const errorCode = err.response && err.response.status;
          showError(errorCode);
          setEnableSave(true);
        });
    }
  };

  const deleteCTA = (ctaIDs) => {
    const deletePayload = ctaIDs;
    setIsloading(true);
    deleteCTAs(moduleId, deletePayload, (response, error, errorCode) => {
      setIsloading(false);
      if (!error && !errorCode) {
        getModuleDetails();
        return;
      }
      showError(errorCode);
    });
  };
  const deleteCTAText = (textIds, ctaID) => {
    const deletePayload = textIds;
    setIsloading(true);
    deleteCTATexts(
      moduleId,
      ctaID,
      deletePayload,
      (response, error, errorCode) => {
        setIsloading(false);
        if (!error && !errorCode) {
          getModuleDetails();
          return;
        }
        showError(errorCode);
      }
    );
  };

  const deleteChannel = (channelIds) => {
    setIsloading(true);
    deleteChannels(moduleId, channelIds, (response, error, errorCode) => {
      setIsloading(false);
      if (!error && !errorCode) {
        getModuleDetails();
        return;
      }
      showError(errorCode);
    });
  };

  const handleSelect = (type) => {
    if (!type) {
      return;
    }
    if (options.channels.length > 0 || options.channels.others.length > 0) {
      let index = options.channels.indexOf(type);
      if (index !== -1 && index < options.channels.length) {
        setAppropriateCssClass(index, "channels");
      } else {
        index = options.others.indexOf(type);
        if (index !== -1 && index < options.others.length) {
          setAppropriateCssClass(index, "others");
        }
      }
    }
    setSelectedOption(type.toString().toLowerCase());
  };

  const onChangeAlert = (alerts) => {
    setAlerts(alerts);
  };

  const onChangeBanner = (banners) => {
    setBanners([].concat(banners));
  };
  const onHandleEmailElementChange = (emailElements) => {
    if (emailElements && emailElements.length > 0) {
      setEmailElements(emailElements[0]);
    } else {
      setEmailElements(emailElements);
    }
  };

  const onChnageCTAs = (ctas) => {
    setCtas([].concat(ctas));
  };

  const onCTAError = (errors) => {
    setCtaErros(errors);
  };

  const renderRightPane = () => {
    switch (selectedOption) {
      case "email":
        return (
          <Email
            onDataChange={onHandleEmailElementChange}
            emailElements={emailElements}
            onDeleteEmail={deleteChannel}
          />
        );
      case "banner":
        return (
          <Banner
            onDataChange={onChangeBanner}
            banners={banners}
            onDeleteBanner={deleteChannel}
          />
        );
      case "alert":
        return (
          <Alert
            onDataChange={onChangeAlert}
            alerts={alerts}
            onDeleteAlert={deleteChannel}
          />
        );
      case "cta":
        return (
          <CTA
            onDataChange={onChnageCTAs}
            ctas={ctas}
            ctaErrors={ctaErrors}
            onCTAError={onCTAError}
            onDeleteCTA={deleteCTA}
            onDeleteCTAText={deleteCTAText}
          />
        );
      default:
        return (
          <Email
            onDataChange={onHandleEmailElementChange}
            emailElements={emailElements}
            onDeleteEmail={deleteChannel}
          />
        );
      //Do nothing
    }
    return <div> </div>;
  };

  const getItemsCount = (type) => {
    switch (type.toLowerCase()) {
      case "email":
        let count = 0;
        if (emailElements) {
          if (emailElements.subjectLines) {
            count += emailElements.subjectLines.length;
          }
          if (emailElements.preheaders) {
            count += emailElements.preheaders.length;
          }
          if (emailElements.emailHeaders) {
            let filteredArray = removeEmptyObjects(emailElements.emailHeaders);
            count += filteredArray.length;
          }
          if (emailElements.optOut) {
            count += emailElements.optOut.length;
          }
        }
        return count;
      case "banner":
        return banners.length;
      case "alert":
        return alerts.length;
      case "cta":
        const actualCTAs = ctas.filter((cta) => {
          return cta.url && cta.url.trim() !== "";
        });
        return actualCTAs.length;
      default:
        return 0;
    }
  };

  const setAppropriateCssClass = (index, parentType) => {
    var sectionChanels = document.getElementById(parentType);
    var lis = sectionChanels.getElementsByClassName("channel");
    for (var i = 0; i < lis.length; i++) {
      var current = document.getElementsByClassName("selected-channel");
      if (current && current.length > 0) {
        current[0].className = current[0].className.replace(
          "selected-channel",
          ""
        );
      }
    }
    lis[index].className = "channel selected-channel";
  };

  const renderChannels = () => {
    const channels = options.channels || [];
    return (
      channels.length > 0 &&
      channels.map((option, index) => {
        let classToBeApplied = "channel";
        if (index === 0) {
          classToBeApplied = "channel selected-channel";
        }
        return (
          <li
            className={classToBeApplied}
            data-toggle="list"
            role="tab"
            key={option}
            onClick={() => {
              handleSelect(option);
            }}
          >
            <Row className="align-items-center mt-1 mb-1 row-channel">
              <Col>
                <div>{option}</div>
              </Col>
              <Col>
                <div className="count-catch-line">
                  {" "}
                  {getItemsCount(option)} Items
                </div>
              </Col>
            </Row>
          </li>
        );
      })
    );
  };

  const renderOthers = () => {
    const others = options.others || [];
    return (
      others.length > 0 &&
      others.map((option) => {
        return (
          <li
            className="channel"
            data-toggle="list"
            role="tab"
            key={option}
            onClick={() => {
              handleSelect(option);
            }}
          >
            <Row className="align-items-center mt-1 mb-1 row-channel">
              <Col>
                <div>{option}</div>
              </Col>
              <Col>
                <div className="count-catch-line">
                  {" "}
                  {getItemsCount(option)} Items
                </div>
              </Col>
            </Row>
          </li>
        );
      })
    );
  };

  const removeEmptyObjects = (newArray) => {
    let data = [...newArray];
    let idices = [];
    data.forEach((element, index) => {
      if (
        element.headerText === "" &&
        element.restrictionForUse === "" &&
        element.rules === ""
      ) {
        return idices.push(index);
      }
    });
    let filteredData = data.filter((val, index) => !idices.includes(index));
    return filteredData;
  };

  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        <HeaderCreateModule />
        {!isThisMLRFeedbackFlow() && (
          <CreateModuleNav
            currentPage={currentPage}
            moduleName={moduleName}
            moduleCode={moduleCode}
            btnSave={i18next.t("generalText.save")}
            MLRStatatus="true"
            disableProceed={shouldDisableProceedButton}
            handleProceed={handleProceed}
            handlePageClose={handleClose}
            handlePageSave={handleSave}
            moduleStatus={mlrModuleStatus}
            moduleId={moduleId}
            veevaDocId={veevaDocId}
            setMlrModuleStatus={setMlrModuleStatus}
            enableSave={enableSave}
            isNotDenovoModule={
              getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
              moduleTypes.denovo
            }
          />
        )}

        {isThisMLRFeedbackFlow() && (
          <MlrFeedbackNav
            module={module}
            disableDone={shouldDisableProceedButton}
            currentPage={currentPage}
            handleDone={handleProceed}
            handlePageClose={handleClose}
          />
        )}
      </div>
      <Container fluid>
        <CircularProgressBar isloading={isloading} />
        <Row className=" row mt-3 ml-0 mr-0 mb-4">
          <Col
            className={
              isThisMLRFeedbackFlow()
                ? isMainContentExpanded
                  ? "col-12 col-md-12"
                  : "col-12 col-md-8"
                : "col-12 col-md-12"
            }
            style={{
              display:
                isThisMLRFeedbackFlow() && isPdfContentExpanded ? "none" : "",
            }}
          >
            <Row className="add-channels-content">
              <Col className="col-md-3 pageChannelsCol">
                <Row className="mt-3 ml-0 mr-0 mb-4">
                  <span className="section-head">Channels</span>
                </Row>
                <Row>
                  <div className="section">
                    <ul id="channels">{renderChannels()}</ul>
                  </div>
                </Row>

                <Row className="mt-3 ml-0 mr-0 mb-4">
                  <span className="section-head">Others</span>
                </Row>
                <Row>
                  <div className="section">
                    <ul id="others">{renderOthers()}</ul>
                  </div>
                </Row>
              </Col>

              <Col className="pageContentCol">
                <div>
                  {isThisMLRFeedbackFlow() && (
                    <div style={{ float: "right" }}>
                      <img
                        src={fullScreenIcon}
                        alt=""
                        className="expandGroupsIcon"
                        onClick={() =>
                          setMaincontentExpanded(!isMainContentExpanded)
                        }
                      />
                    </div>
                  )}
                  <div
                    className={`${
                      isThisMLRFeedbackFlow()
                        ? isMainContentExpanded
                          ? ""
                          : "add-mlr-channels-scrollbale custonScrollBar"
                        : ""
                    }`}
                  >
                    {renderRightPane()}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {isThisMLRFeedbackFlow() ? (
            <Col
              className={` ${
                isPdfContentExpanded ? "col-md-12" : "col-12 col-md-4"
              }`}
              style={{ display: isMainContentExpanded ? "none" : "" }}
              ref={pdfElementRef}
            >
              <img
                src={fullScreenIcon}
                alt=""
                className="groupPdfExpand"
                onClick={() => setPdfcontentExpanded(!isPdfContentExpanded)}
              />
              <div className="previewFeedbackGroupsScroll customScrollBar mlr-groups-pdf">
                <RenderMlrFeedback pdfFileUrl={pdfUrl} pdfWidth={pdfwidth} />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </>
  );
};

export default AddChannels;
