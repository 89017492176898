import React, { useState, useEffect } from "react";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import Checkbox from "@material-ui/core/Checkbox";
import parse from "html-react-parser";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import "../styles/components/SuppClaimFootNote.scss";

const FooteNotes = props => {
  const [addCheckBtn, setAddCheckBtn] = useState(false);
  const [iconA, setIconA] = useState(false);
  const [iconB, setIconB] = useState(false);
  const [iconC, setIconC] = useState(false);
  const [iconD, setIconD] = useState(false);
  const [iconE, setIconE] = useState(false);
  const [iconF, setIconF] = useState(false);
  const [iconG, setIconG] = useState(false);
  const [iconI, setIconI] = useState(false);
  const [iconJ, setIconJ] = useState(false);
  const [iconK, setIconK] = useState(false);
  const [errorrMsg, setErrorMessage] = useState("");
  const [rowBox, setRowBox] = useState(false);
  const [helper, setHelper] = useState(false);

  const handleIconChange = e => {
    const icon = props.selectedFootNotes.some(
      item => e.target.innerHTML === item.footNoteIcon
    );
    if (!icon) {
      const type = e.target.id;
      switch (type) {
        case "A":
          setIconA(true);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "B":
          setIconA(false);
          setIconB(true);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "C":
          setIconA(false);
          setIconB(false);
          setIconC(true);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "D":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(true);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "E":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(true);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "F":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(true);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "G":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(true);
          setIconI(false);
          setIconJ(false);
          setIconK(false);
          break;
        case "I":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(true);
          setIconJ(false);
          setIconK(false);
          break;
        case "J":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(true);
          setIconK(false);
          break;
        case "K":
          setIconA(false);
          setIconB(false);
          setIconC(false);
          setIconD(false);
          setIconE(false);
          setIconF(false);
          setIconG(false);
          setIconI(false);
          setIconJ(false);
          setIconK(true);
          break;
        default:
          break;
      }
      setErrorMessage("");
      props.getIconValue(e.target.innerHTML);
    } else {
      setErrorMessage(
        "This icon already selected, please select another icon."
      );
      setIconA(false);
      setIconB(false);
      setIconC(false);
      setIconD(false);
      setIconE(false);
      setIconF(false);
      setIconG(false);
      setIconI(false);
      setIconJ(false);
      setIconK(false);
      props.setIconValueToInitial();
    }
  };
  const handleToggle = () => {
    setAddCheckBtn(!addCheckBtn);
    props.setAllToInitial();
    setIconA(false);
    setIconB(false);
    setIconC(false);
    setIconD(false);
    setIconE(false);
    setIconF(false);
    setIconG(false);
    setIconI(false);
    setIconJ(false);
    setIconK(false);
    setErrorMessage("");
    props.setIconValueToInitial();
  };

  const handleAddForText = () => {
    if (props.id === "visual-footNote") {
      props.setSelectedBox(2);
      props.setSelectedRow(props.rowIndex);
      setRowBox(true);
    } else {
      props.setSelectedSuppBox(1);
      props.setSelectedSuppRow(props.rowIndex);
      setRowBox(true);
    }
  };

  useEffect(() => {
    if (rowBox) {
      props.handleAddFootNote();
    }
    setRowBox(false);
  }, [rowBox]);

  const handleSelect = () => {
    if (props.id === "visual-footNote") {
      props.setSelectedBox(2);
      props.setSelectedRow(props.rowIndex);
      setHelper(true);
    } else {
      props.setSelectedSuppBox(1);
      props.setSelectedSuppRow(props.rowIndex);
      setHelper(true);
    }
  };

  useEffect(() => {
    if (helper) {
      props.handleAddFootNoteCheckBox();
    }
    setHelper(false);
  }, [helper]);

  return (
    <>
      <div className="modalCustomCommon">
        <div className="headerIcon1">
          <p class="footNotes">Footnote (s)</p>
          {addCheckBtn ? (
            <p
              type="button"
              className="Select-from-list"
              onClick={handleToggle}
            >
              Select From List
            </p>
          ) : (
            <p
              type="button"
              className="Select-from-list-Add"
              onClick={handleToggle}
            >
              Add New
            </p>
          )}
        </div>
        <div className="headerLine"></div>
        {addCheckBtn ? (
          <>
            <p className="footNoteLabel">Add Footnotes</p>
            {errorrMsg && errorrMsg.length && (
              <p className="alert alert-warning" role="alert">
                {errorrMsg}
              </p>
            )}
            <div className="footNoteIcon">
              <p className="commonStyle">
                <span
                  className="rect"
                  id="A"
                  onClick={handleIconChange}
                  style={{ border: iconA ? "solid 1px #1c75bb" : "" }}
                >
                  &#42;
                </span>
                <span
                  className="rect"
                  id="B"
                  onClick={handleIconChange}
                  style={{ border: iconB ? "solid 1px #1c75bb" : "" }}
                >
                  &#8224;
                </span>
                <span
                  className="rect"
                  id="C"
                  onClick={handleIconChange}
                  style={{ border: iconC ? "solid 1px #1c75bb" : "" }}
                >
                  {/* &#8224;&#8224; */}&#8364;
                </span>
                <span
                  className="rect"
                  id="D"
                  onClick={handleIconChange}
                  style={{ border: iconD ? "solid 1px #1c75bb" : "" }}
                >
                  &#182;
                </span>
                <span
                  className="rect"
                  id="E"
                  onClick={handleIconChange}
                  style={{ border: iconE ? "solid 1px #1c75bb" : "" }}
                >
                  &#167;
                </span>
                <span
                  className="rect"
                  id="F"
                  onClick={handleIconChange}
                  style={{ border: iconF ? "solid 1px #1c75bb" : "" }}
                >
                  {/* || */}&#181;
                </span>
                <span
                  className="rect"
                  id="G"
                  onClick={handleIconChange}
                  style={{ border: iconG ? "solid 1px #1c75bb" : "" }}
                >
                  &#35;
                </span>
                <span
                  className="rect"
                  id="I"
                  onClick={handleIconChange}
                  style={{ border: iconI ? "solid 1px #1c75bb" : "" }}
                >
                  {/* &#42;&#42; */}&#64;
                </span>
                <span
                  className="rect"
                  id="J"
                  onClick={handleIconChange}
                  style={{ border: iconJ ? "solid 1px #1c75bb" : "" }}
                >
                  {/* &#8224;&#8224;&#8224; */}&#36;
                </span>
                <span
                  className="rect"
                  id="K"
                  onClick={handleIconChange}
                  style={{ border: iconK ? "solid 1px #1c75bb" : "" }}
                >
                  {/* &#8224;&#8224;&#8224;&#8224; */}&#33;
                </span>
              </p>
            </div>
            {props.addBtnStatus ? (
              <div className="enterFootNote">Enter Footnotes</div>
            ) : (
              ""
            )}
            <div
              className="textArea"
              // onClick={()=>{
              //   props.setSelectedSuppBox(1);
              //   props.setSelectedSuppRow(props.rowIndex);
              //  }
              //}
            >
              <FroalaEditor
                placeHolder="Enter Footnote"
                variant="outlined"
                tag="textarea"
                handleModelChange={props.handleFootNoteTextChange}
                selectedAtom={props.selectedText.footText}
              />
            </div>
          </>
        ) : (
          <>
            <p className="footNoteLabel">Select from list</p>
            <div class="body-Select custonScrollBar">
              {props.selectedFootNotes.map((x, index) => (
                <div className="d-flex">
                  <FormControlLabel
                    style={{ paddingLeft: "28px" }}
                    control={
                      <Checkbox
                        key={`${index}-${x}`}
                        onChange={event =>
                          props.handleFootNoteCheckBox(
                            event,
                            index,
                            x.footNoteIcon
                          )
                        }
                        name="checkedB"
                        color="primary"
                        style={{ marginLeft: "10px", borderRadius: "2px" }}
                      />
                    }
                  />
                  <div className="footBoxIndex">
                    <span className="d-inline-block iconList">
                      {x && x.footNoteIcon}
                    </span>
                  </div>
                  <div className="footTextLabal">
                    {x.footNoteText && parse(x.footNoteText)}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="footer-button-sup">
          <button
            type="button"
            className="btn btn-link"
            style={{ marginLeft: "20px" }}
            onClick={props.handleFootNoteMOdalClose}
          >
            CLOSE
          </button>
          {addCheckBtn ? (
            <button
              type="button"
              className="btn btn-primary btn-lg"
              style={{ float: "right", marginRight: "31px", width: "157px" }}
              disabled={
                props.addBtnStatus && props.addIconStatus ? "" : "disable"
              }
              onClick={() => {
                handleAddForText();
              }}
            >
              ADD
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-lg"
              style={{ float: "right", marginRight: "31px", width: "157px" }}
              disabled={
                props.addBtnStatus && props.addIconStatus ? "" : "disable"
              }
              onClick={() => {
                handleSelect();
              }}
            >
              ADD
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default FooteNotes;
