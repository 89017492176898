//import react dependency
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//import custom components
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import DamsFilter from "./DamsFilter";
import SearchResultsPage from "./SearchResultsPage";
import SearchTacticsAndModules from "../ExistingTactics/SearchTacticsAndModules";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import ModularisedTactics from "../ExistingTactics/Modularised/ModularisedTactics";
import ChannelSelectionModalForStylizedModule from "../../components//ModuleEditor/ChannelSelectionModal";
//import apis and utils
import {
  searchAssetsFromDamsInMM,
  storeGenericInfo,
  searchAssetsInDams,
  getAssetDetails,
  searchContentModules,
  getModuleDetailsById,
  retrieveCountryListForSelectedDams,
} from "../../services/apis";
//import styles
import "../../styles/components/dams.scss";
import DamsSourceFilter from "./DamsSourceFilter";
import {
  damsSources,
  isModularContent,
  removeDuplicateFromLiteralsArray,
  routes,
  showError,
} from "../../Uitls/Util";
import VeevaSearchPage from "./Veeva/VeevaSearchPage";
import VeevaDefaultPage from "./Veeva/VeevaDefaultPage";
import ChannelGridHome from "../ChannelGrid/ChannelGridHome";
import CtaSelection from "../ModuleEditor/CtaSelection";
import { useHistory } from "react-router-dom";
import CustomModal from "../customModal";
import { sortClaimsAndVisualsByRecordIndex } from "../../Pages/ModuleDetails";

//Export  functions

const getClaims = (module, type) => {
  let claims = undefined;
  if (module) {
    const moduleClaims = module.moduleClaims;
    if (moduleClaims && moduleClaims.length > 0) {
      claims = moduleClaims.filter((claim) => {
        return claim.type.toLowerCase() === type.toLowerCase();
      });
    }
  }

  return claims || [];
};
const findSupportingClaimOrVisualPosition = (claimsOrVisuals, type, object) => {
  let index = -1;
  let filtered = [];
  if (type.toLowerCase() === "supportingclaim") {
    filtered = claimsOrVisuals.filter((el) => {
      return el.type.toLowerCase() === "supporting";
    });
  } else if (type === "visual") {
    filtered = claimsOrVisuals.filter((el) => {
      return el.type.toLowerCase() === "supporting";
    });
  }
  index = filtered.findIndex((el) => {
    return el.record_index === object.record_index;
  });

  return index;
};

const getVisualBusinessRules = (module) => {
  let mandatoryWithProClaim = [];
  let optionalWithProClaim = [];
  let mandatoryWithSupClaim = [];
  let optionalWithSupClaim = [];
  const claimVisuals = module.claimVisuals;
  const proClaims = getClaims(module, "primary");
  const supClaims = getClaims(module, "supporting");
  const visuals = sortClaimsAndVisualsByRecordIndex(module.moduleVisuals);
  if (visuals?.length > 0 && claimVisuals?.length > 0) {
    for (const claimVisual of claimVisuals) {
      let visual = visuals.find((el) => {
        return el.id === claimVisual.moduleVisualId;
      });
      let visPos =
        findSupportingClaimOrVisualPosition(visuals, "visual", visual) + 1;
      if (visual) {
        let found = proClaims.find((el) => {
          return el.id === claimVisual.moduleClaimId;
        });
        if (!found) {
          found = supClaims.find((el) => {
            return el.id === claimVisual.moduleClaimId;
          });
        }
        if (found) {
          if (claimVisual.dependency.toLowerCase() === "optional") {
            if (found.type.toLowerCase() === "primary") {
              optionalWithProClaim = optionalWithProClaim.concat(
                `Visual ${visPos}`
              );
            } else {
              optionalWithSupClaim = optionalWithSupClaim.concat(
                `Visual ${visPos}`
              );
            }
          } else {
            if (found.type.toLowerCase() === "primary") {
              mandatoryWithProClaim = mandatoryWithProClaim.concat(
                `Visual ${visPos}`
              );
            } else {
              mandatoryWithSupClaim = mandatoryWithSupClaim.concat(
                `Visual ${visPos}`
              );
            }
          }
        }
      }
    }
  }
  return {
    ruleType: "supportingVisual",
    mandatoryWithProClaim: removeDuplicateFromLiteralsArray(
      mandatoryWithProClaim
    ).sort(),
    mandatoryWithSupClaim: removeDuplicateFromLiteralsArray(
      mandatoryWithSupClaim
    ).sort(),
    optionalWithProClaim:
      removeDuplicateFromLiteralsArray(optionalWithProClaim).sort(),
    optionalWithSupClaim:
      removeDuplicateFromLiteralsArray(optionalWithSupClaim).sort(),
  };
};
const getSupportingBusinessRules = (module) => {
  let mandatory = [];
  let optional = [];
  let eitherOne = [];
  const moduleGroups = module.moduleGroups;
  const supClaims = sortClaimsAndVisualsByRecordIndex(
    getClaims(module, "supporting")
  );
  if (moduleGroups && moduleGroups.length > 0) {
    for (const moduleGroup of moduleGroups) {
      const groupClaims = moduleGroup.groupClaims;
      // eslint-disable-next-line no-loop-func
      groupClaims.forEach((claim) => {
        const supClaim = supClaims.find((el) => {
          return el.id === claim.moduleClaimId;
        });
        const position = findSupportingClaimOrVisualPosition(
          supClaims,
          "supportingclaim",
          supClaim
        );
        if (module.groupToggle) {
          eitherOne = eitherOne.concat(`Claim ${position + 1}`);
          return;
        }
        if (moduleGroup.requirementType.toLowerCase() === "optional") {
          optional = optional.concat(`Claim ${position + 1}`);
        } else {
          mandatory = mandatory.concat(`Claim ${position + 1}`);
        }
      });
    }
  }

  let supClaimWithProClaim = {
    ruleType: "supportingGroup",
    optional: removeDuplicateFromLiteralsArray(optional).sort(),
    mandatory: removeDuplicateFromLiteralsArray(mandatory).sort(),
    eitherOne: removeDuplicateFromLiteralsArray(eitherOne).sort(),
  };
  return supClaimWithProClaim;
};

const filterOutSupportingTexts = (data, type) => {
  return data.filter((el) => {
    return el.componentType.toLowerCase() === type;
  });
};

export const prepareModuleDataForEditing = (module, textElements) => {
  let data = {};
  let claims = [];
  let promotionalClaim = module.moduleClaims.filter((module) => {
    return module.type.toLowerCase() === "primary";
  });
  if (promotionalClaim.length > 0) {
    promotionalClaim = promotionalClaim[0];
    let primaryClaim = {};
    primaryClaim["id"] = promotionalClaim.id;
    primaryClaim["claim"] = promotionalClaim.claim;
    primaryClaim["claimName"] = "Promotional Claim";
    const variants = promotionalClaim.claimVariants.map((el) => {
      return { id: el.id, claim: el.moduleClaim };
    });
    primaryClaim["variants"] = variants;
    let footnotes = filterOutSupportingTexts(
      promotionalClaim.supportingTextComponents,
      "footnote"
    );
    primaryClaim["footnotes"] = footnotes;
    let abbreviations = filterOutSupportingTexts(
      promotionalClaim.supportingTextComponents,
      "abbreviation"
    );
    primaryClaim["abbreviations"] = abbreviations;
    let references = filterOutSupportingTexts(
      promotionalClaim.supportingTextComponents,
      "reference"
    );
    primaryClaim["references"] = references;
    claims = claims.concat(primaryClaim);
  }
  let supportingClaims = sortClaimsAndVisualsByRecordIndex(
    module.moduleClaims.filter((module) => {
      return module.type.toLowerCase() === "supporting";
    })
  );
  supportingClaims = supportingClaims.map((el) => {
    return {
      id: el.id,
      claim: el.claim,
      claimName: "Supporting Claim",
      footnotes: filterOutSupportingTexts(
        el.supportingTextComponents,
        "footnote"
      ),
      abbreviations: filterOutSupportingTexts(
        el.supportingTextComponents,
        "abbreviation"
      ),
      references: filterOutSupportingTexts(
        el.supportingTextComponents,
        "reference"
      ),
    };
  });

  let supportingVisuals = sortClaimsAndVisualsByRecordIndex(
    module.moduleVisuals.filter((module) => {
      return module.type.toLowerCase() === "supporting";
    })
  );
  supportingVisuals = supportingVisuals.map((el) => {
    return {
      id: el.id,
      claimName: "Supporting Visual",
      footnotes: filterOutSupportingTexts(
        el.supportingTextComponents,
        "footnote"
      ),
      abbreviations: filterOutSupportingTexts(
        el.supportingTextComponents,
        "abbreviation"
      ),
      references: filterOutSupportingTexts(
        el.supportingTextComponents,
        "reference"
      ),
      caption: filterOutSupportingTexts(el.supportingTextComponents, "caption"),
      visuals: el.visualVariants,
    };
  });

  data.claims = claims;
  data.textElements = textElements;
  data.id = module.id;
  data.moduleCode = module.moduleCode;
  data.name = module.name;
  data.brandName = module.brandName;
  data.campaign = module.campaign;
  let rules = [];
  const supportingClaimBusinessRules = getSupportingBusinessRules(module);
  const supportingVisualBusinessRules = getVisualBusinessRules(module);
  rules = rules
    .concat(supportingClaimBusinessRules)
    .concat(supportingVisualBusinessRules);
  data.rules = rules;
  data.supportingClaims = supportingClaims;
  data.supportingVisuals = supportingVisuals;
  return data;
};

const DamsHome = ({
  brandsAndCampaigns,
  audience,
  marketDataSource,
  channels,
  fetchBrandsAndCampaigns,
}) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [searchInDams, toggleSearchInDams] = useState(false);
  const [searchInMM, toggleSearchInMM] = useState(true);
  const [titleType, setTitleType] = useState("initial");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [assetsAndModules, setAssetsAndModules] = useState([]);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState(undefined);
  const [assetsToBeRetrievedFromDams, setAssetsToBeRetrievedFromDams] =
    useState([]);
  const [filteredPages, setFiletredPages] = useState([]);
  const [searchMarketTerm, setSearchMarketTerm] = useState("");
  const [selectedDamsSource, setSelectedDamsSource] = useState(undefined);
  const [
    selectedDamsSourceMarketDataSource,
    setSelectedDamsSourceMarketDataSource,
  ] = useState(undefined);

  //This is for content matrix and content grid
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [activePage, setactivePage] = useState(undefined);
  const [channelGridToView, setChannelGridToView] = useState(undefined);

  //This is for stylised modules
  const [
    stylizeChannelSelectionModalData,
    setstylizeChannelSelectionModalData,
  ] = useState(undefined);
  const [isStylisedModuleInitiated, setIsStylisedModuleInitiated] =
    useState(false);
  const [stylizeModuleChannelSelection, setStylizeModuleChannelSelection] =
    useState(false);

  useEffect(() => {
    if (brandsAndCampaigns && brandsAndCampaigns.length > 0) {
      segregateBrand();
    }
  }, [brandsAndCampaigns]);

  const segregateBrand = () => {
    const brands = brandsAndCampaigns.map((brandAndCampaign) => {
      return brandAndCampaign.brandName;
    });
    setBrandList(brands);
  };

  const getCampaignsFromSelectedBrands = (brands = selectedBrands) => {
    let campaigns = [];
    brands.forEach((selectedBrand) => {
      const campList = getCampaignsbyBrandName(selectedBrand);
      campaigns = campaigns.concat(
        campList.map((campaign) => {
          return campaign.campaignName;
        })
      );
    });
    return campaigns;
  };

  const getCampaignsbyBrandName = (brandName) => {
    const selectedBrand = brandsAndCampaigns.find((brand) => {
      return brand.brandName === brandName;
    });
    if (selectedBrand) {
      return selectedBrand.campaigns;
    }
    return [];
  };

  const getCampaignList = (brands) => {
    let campaigns = [];
    brands.forEach((brand) => {
      campaigns = campaigns.concat(getCampaignsbyBrandName(brand));
    });
    return campaigns.map((campaign) => {
      return campaign.campaignName;
    });
  };

  const fetchAssets = (callback, isReset = false) => {
    let searchParams = {
      brands: selectedBrands || [],
      channelTypes: selectedChannels || [],
      markets: selectedMarkets || [],
      campaigns: selectedCampaigns || [],
      searchText: document
        .getElementById("search-tactics-from-icb")
        ?.value.trim(),
    };

    if (isReset) {
      searchParams = {
        brands: [],
        channelTypes: [],
        markets: [],
        campaigns: [],
        searchText: "",
      };
    }

    const assetDataType = getAssetDataType();
    //Will remove this if condition later
    // if (
    //   assetDataType === "AssetComponent" &&
    //   selectedDamsSource.sourceType.toLowerCase() ===
    //     damsSources.veeva.name.toLowerCase()
    // ) {
    //   return;
    // }
    setIsloading(true);
    searchAssetsFromDamsInMM(
      searchParams,
      selectedDamsSource.sourceType.toLowerCase(),
      assetDataType,
      (response, err, errorCode) => {
        setIsloading(false);
        if (err) {
          showError(err);
        } else {
          const assets = response.data;

          //sort based on deconstruction time
          assets.sort(function (a, b) {
            return new Date(b.createdDateTime) - new Date(a.createdDateTime);
          });

          callback(assets);

          setAssetsAndModules(assets);
        }
      }
    );
  };
  useEffect(() => {
    if (searchInMM && selectedDamsSource) {
      fetchAssetsFromMM();
    }
  }, [
    searchInMM,
    selectedBrands,
    selectedChannels,
    selectedMarkets,
    selectedCampaigns,
  ]);
  useEffect(() => {
    if (searchInDams && selectedDamsSource) {
      if (
        selectedBrands.length === 0 &&
        selectedChannels.length === 0 &&
        selectedMarkets.length === 0 &&
        selectedCampaigns.length === 0 &&
        document.getElementById("search-tactics-from-icb")?.value.trim()
          .length === 0
      ) {
        setAssetsToBeRetrievedFromDams([]);
        resetSearchBox();
      } else {
        searchTactics();
      }
    }
  }, [
    searchInDams,
    selectedBrands,
    selectedChannels,
    selectedMarkets,
    selectedCampaigns,
  ]);

  useEffect(() => {
    if (selectedDamsSource) {
      toggleSearchInMM(true);
      toggleSearchInDams(false);
      resetSearchBox();
      setAssetsAndModules(() => {
        fetchAssetsFromMM();
      });
      setAssetsAndModules([]);
    }
  }, [selectedDamsSource]);

  const fetchAssetsFromMM = () => {
    fetchAssets((assets) => {
      if (assets && assets.length > 0) {
        setSelectedAssetHandler(assets);
      } else {
        setSelectedAssetDetails(undefined);
      }
    });
    if (
      selectedBrands.length > 0 ||
      selectedChannels.length > 0 ||
      selectedMarkets.length > 0 ||
      selectedCampaigns.length > 0 ||
      document.getElementById("search-tactics-from-icb")?.value.trim().length >
        0
    ) {
      setTitleType("searched");
    } else {
      setTitleType("initial");
    }
  };
  const getCreatedAssetDetailsById = (assetId, callback) => {
    setIsloading(true);
    fetchBrandsAndCampaigns();
    getAssetDetails((response, err, errorCode) => {
      setIsloading(false);
      if (err) {
        showError(err);
        callback(false);
      } else {
        const children = response.data.children;
        if (children) {
          let pages = [];
          pages = children.filter((child) => {
            return child.type === "Page";
          });
          pages = pages.map((page) => {
            const atoms = children.filter((child) => {
              return child.type === "Atom" && child.parent === page._id;
            });
            page = { ...page, atoms };
            return page;
          });
          //Sort the array
          pages.sort((page1, page2) => {
            return page1.MetaData.page - page2.MetaData.page;
          });
          //setPages(pages);
          setFiletredPages(pages);
        }
        // setAssetDetailsById(response.data);
        callback(true);
      }
    }, assetId);
  };
  const setSelectedAssetHandler = (assets, assetId) => {
    if (
      selectedDamsSource.sourceType.toLowerCase() ===
        damsSources.veeva.name.toLowerCase() &&
      getAssetDataType().toLowerCase() === "modularcontent"
    ) {
      return;
    }
    if (!assetId) {
      const selectedAsset = assets[0];
      if (selectedAsset) {
        setSelectedAssetDetails(selectedAsset);
        getCreatedAssetDetailsById(assets[0].storageAssetId, (loaded) => {
          setSelectedAssetDetails(loaded ? selectedAsset : undefined);
        });
      }

      return;
    }

    const result = assets.find(function (element) {
      if (element.storageAssetId === assetId) {
        return true;
      } else {
        return false;
      }
    });
    setSelectedAssetDetails(result);
    getCreatedAssetDetailsById(assetId, (loaded) => {
      setSelectedAssetDetails(loaded ? result : undefined);
    });
  };
  const resetSearchBox = () => {
    const element = document.getElementById("search-tactics-from-icb");
    if (element) {
      element.value = "";
    }
    setTitleType("initial");
  };
  const onSearchOptionChange = (event) => {
    if (event) {
      const id = event.target.id;
      const isChecked = event.target.checked;
      resetFilters();
      resetSearchBox();
      setSelectedAssetDetails(undefined);
      if (id === "search-in-dams") {
        toggleSearchInDams(isChecked);
        toggleSearchInMM(!isChecked);
        setAssetsAndModules([]);
      } else if (id === "search-in-mm") {
        toggleSearchInDams(!isChecked);
        toggleSearchInMM(isChecked);
      }
    }
  };

  const resetFilters = () => {
    setSelectedBrands([]);
    setSelectedChannels([]);
    setSelectedMarkets([]);
    setSearchMarketTerm("");
    setSelectedCampaigns([]);
    setAssetsToBeRetrievedFromDams([]);
  };
  const getAssetDataType = () => {
    switch (selectedDamsSource?.assetDataType?.toLowerCase()) {
      case "asset + component":
        return "AssetComponent";
      case "modular content":
        return "ModularContent";
      default:
        break;
    }
    return "";
  };

  const searchFuncRef = useRef(null);
  const fetchAssetsFromDams = () => {
    let searchParams = {
      brands: selectedBrands || [],
      channelTypes: selectedChannels || [],
      markets: selectedMarkets || [],
      campaigns: selectedCampaigns || [],
      searchText: document
        .getElementById("search-tactics-from-icb")
        ?.value.trim(),
      assetDataType: getAssetDataType(),
    };
    setIsloading(true);
    if (
      selectedDamsSource &&
      selectedDamsSource.sourceType?.toLowerCase() ===
        damsSources.veeva.name.toLowerCase() &&
      getAssetDataType().toLowerCase() === "modularcontent"
    ) {
      if (searchFuncRef) {
        searchFuncRef.current();
      }
      searchParams.markets = undefined;
      searchParams.channelTypes = undefined;
      searchContentModules(
        selectedDamsSource?.sourceType?.toLowerCase(),
        searchParams,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(err);
          } else {
            const assets = response.data;
            setAssetsToBeRetrievedFromDams(assets);
          }
          setTitleType("searched");
        }
      );
    } else {
      searchAssetsInDams(
        selectedDamsSource?.sourceType?.toLowerCase(),
        searchParams,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(err);
          } else {
            const assets = response.data;
            setAssetsToBeRetrievedFromDams(assets);
          }
          setTitleType("searched");
        }
      );
    }
  };
  const searchTactics = () => {
    if (searchInDams) {
      setAssetsToBeRetrievedFromDams([]);
      fetchAssetsFromDams();
    } else {
      fetchAssets((assets) => {
        if (assets && assets.length > 0) {
          setSelectedAssetHandler(assets);
        } else {
          setSelectedAssetDetails(undefined);
        }

        setTitleType("searched");
      });
    }
  };
  const clearAllSearch = () => {
    resetFilters();
    resetSearchBox();
    fetchAssets((assets) => {
      if (assets && assets.length > 0) {
        setSelectedAssetHandler(assets);
      } else {
        setSelectedAssetDetails(undefined);
      }
    }, true);
  };
  const removeSelectedBrand = (brandToBeRemoved) => {
    const index = selectedBrands.indexOf(brandToBeRemoved);
    const newFilteredBrands = [...selectedBrands];
    newFilteredBrands.splice(index, 1);
    setSelectedBrands(newFilteredBrands);
    setSelectedCampaigns(
      removeSelectedCampaignforRemovedBrand(brandToBeRemoved)
    );
  };
  const removeSelectedCampaignforRemovedBrand = (brand) => {
    let campaigns = [...selectedCampaigns];
    const campaignsForRemovedBrand = getCampaignsbyBrandName(brand);
    campaignsForRemovedBrand.forEach((campaignToBeRemoved) => {
      campaigns = campaigns.filter((campaign) => {
        return campaign !== campaignToBeRemoved.campaignName;
      });
    });
    return campaigns;
  };
  const removeSelectedChannel = (channelToBeRemoved) => {
    const index = selectedChannels.indexOf(channelToBeRemoved);
    const newFilteredChannels = [...selectedChannels];
    newFilteredChannels.splice(index, 1);
    setSelectedChannels(newFilteredChannels);
  };
  const removeSelectedMarket = (marketToBeRemoved) => {
    const index = selectedMarkets.indexOf(marketToBeRemoved);
    const newFilteredMarkets = [...selectedMarkets];
    newFilteredMarkets.splice(index, 1);
    setSelectedMarkets(newFilteredMarkets);
  };
  const removeSelectedCampaign = (campaignToBeRemoved) => {
    const index = selectedCampaigns.indexOf(campaignToBeRemoved);
    const newFilteredCampaigns = [...selectedCampaigns];
    newFilteredCampaigns.splice(index, 1);
    setSelectedCampaigns(newFilteredCampaigns);
  };
  const applyFilters = () => {};
  const renderSearchOptions = () => {
    return (
      selectedDamsSource &&
      selectedDamsSource?.sourceType.toLowerCase() !==
        damsSources.aem.name.toLowerCase() && (
        <div className="rb-search-options">
          <div className="rb-search-option">
            <input
              id="search-in-dams"
              type="radio"
              name="search-in-dams"
              className="form-check-input"
              checked={searchInDams}
              onChange={onSearchOptionChange}
            />
            <label htmlFor="search-in-dams" className="form-check-label">
              Search in DAMS
            </label>
          </div>
          <div className="rb-search-option">
            <input
              id="search-in-mm"
              type="radio"
              name="search-in-mm"
              className="form-check-input"
              checked={searchInMM}
              onChange={onSearchOptionChange}
            />
            <label htmlFor="search-in-mm" className="form-check-label">
              Search in Module Manager
            </label>
          </div>
        </div>
      )
    );
  };
  const saveGenericInfo = (genericInfo) => {
    let payload = {
      storageAssetId: selectedAssetDetails.storageAssetId,
      externalAssetId: selectedAssetDetails.externalAssetId,
      assetName: selectedAssetDetails.assetName,
      assetThumbnailUrl: selectedAssetDetails.assetThumbnailUrl,
      harvestedAssetId: selectedAssetDetails.harvestedAssetId,
      channelType: genericInfo.channelType,
      campaignId: genericInfo.campaignId,
      campaign: genericInfo.campaign,
      audience: genericInfo.audience,
      brand: genericInfo.brand,
      brandId: genericInfo.brandId,
      description: genericInfo.description,
      dosing: genericInfo.dosing,
      audienceSegment: genericInfo.audienceSegment,
      market: genericInfo.market,
      isi: genericInfo.isi,
      pi: genericInfo.pi,
      mg: genericInfo.mg,
      genericBusinessRules: genericInfo.genericBusinessRules,
      therapeuticArea: genericInfo.therapeuticArea,
      assetType: genericInfo.assetType,
    };

    setIsloading(true);
    storeGenericInfo(payload, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        fetchAssets((assets) => {
          setSelectedAssetHandler(assets, selectedAssetDetails.storageAssetId);
        });
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };
  const refreshSelectedAsset = () => {
    fetchAssets((assets) => {
      setSelectedAssetHandler(assets, selectedAssetDetails.storageAssetId);
    });
  };
  const redirectToSearchInMM = () => {
    toggleSearchInDams(false);
    resetFilters();
    resetSearchBox();
    toggleSearchInMM(true);
  };

  const shouldMarketAndCampaignFiltersBeHidden = (option) => {
    return (
      getAssetDataType()?.toLowerCase() === "modularcontent" &&
      searchInDams &&
      !searchInMM
      // (option === "campaigns" || option === "markets")
    );
  };

  const addLatestMatrixData = (matrixdata) => {
    if (!matrixdata) {
      return;
    }
    const currentSelectedAsset = { ...selectedAssetDetails };
    currentSelectedAsset.contentMatrices.splice(0, 0, matrixdata);
    setSelectedAssetDetails(currentSelectedAsset);
  };

  const updateGridCout = () => {
    let currentCout = parseInt(selectedAssetDetails.gridCount);
    selectedAssetDetails.gridCount = currentCout + 1;
  };

  const updateContentModulesMatrix = (newMatrixData) => {
    if (!newMatrixData) {
      return;
    }
    const updatedModuleData = [...assetsAndModules];
    const currentObject = assetsAndModules.find(
      (id) => id.cmId === newMatrixData.mouduleId
    );
    currentObject.contentMatrices.splice(0, 0, newMatrixData);
    setAssetsAndModules(updatedModuleData);
  };

  const [ischannelGrid, setIschannelGrid] = useState(false);

  const filterModules = (parameter = "original") => {
    const modules = selectedAssetDetails.modules;
    let filteredModules = [];
    if (modules && modules.length > 0) {
      filteredModules = modules.filter((module) => {
        const moduleType = module.moduleType.trim().toLowerCase();
        if (parameter === "original") {
          // if (shouldShowOnlyOriginalModules) {
          //   return moduleType === "original";
          // }
          return moduleType === "original" || moduleType === "cloned";
        }
        //return only approved translated modules
        if (moduleType.toLowerCase() === "translated") {
          return (
            moduleType === parameter &&
            module.translationStatus?.toLowerCase() === "complete"
          );
        }

        return moduleType === parameter;
      });
    }
    return filteredModules;
  };

  const cancelCreatingGrid = (flag) => {
    setIschannelGrid(flag);
    setSelectedChannel(undefined);
    setactivePage(undefined);
    setChannelGridToView(undefined);
  };

  const startStylizingModule = (module) => {
    setstylizeChannelSelectionModalData(() => {
      setStylizeModuleChannelSelection(true);
    });
    setstylizeChannelSelectionModalData({
      module: module,
      brand: module.brandName,
      campaign: module.campaign,
      sourceType: selectedAssetDetails.sourceType,
    });
  };
  const closestylizeChannelSelectionModal = () => {
    setSelectedChannel(undefined);
    setstylizeChannelSelectionModalData(undefined);
    setStylizeModuleChannelSelection(false);
  };

  const proceedToCtaSelectionScreen = () => {
    setIsStylisedModuleInitiated(true);
    setStylizeModuleChannelSelection(false);
  };

  const cancelCreatingStylisedModule = () => {
    setStylizeModuleChannelSelection(false);
    setIsStylisedModuleInitiated(false);
    setSelectedChannel(undefined);
  };

  const fetchModuleToStylize = (selectedCtas) => {
    setIsloading(true);
    getModuleDetailsById(
      stylizeChannelSelectionModalData.module.id,
      (res, err) => {
        setIsloading(false);

        if (res) {
          let data = res.data;

          history.push({
            pathname: routes.moduleEditor,
            state: {
              module: prepareModuleDataForEditing(data, selectedCtas),
              channelType: selectedChannel,
              sourceType: selectedDamsSource.sourceType?.toLowerCase(),
              assetDataType: getAssetDataType(),
              brandStyleGuideline: selectedAssetDetails.styleGuideline,
            },
          });
          setstylizeChannelSelectionModalData(undefined);
          return;
        }
        showError(err);
      }
    );
  };
  const checkIfVeevaModularContent = () => {
    return (
      selectedDamsSource?.sourceType.toLowerCase() ===
        damsSources.veeva.name.toLowerCase() &&
      getAssetDataType().toLowerCase() === "modularcontent"
    );
  };
  const retrieveSelectedDamsCountryList = () => {
    if (checkIfVeevaModularContent() || !selectedDamsSourceMarketDataSource) {
      setIsloading(true);
      retrieveCountryListForSelectedDams(
        selectedDamsSource.sourceType.toLowerCase(),
        (res, err) => {
          setIsloading(false);
          if (err) {
            showError(err);
            return;
          }
          setSelectedDamsSourceMarketDataSource(
            res.data.map((country) => {
              return { id: country.id, displayName: country.name__v };
            })
          );
        }
      );
    }
  };
  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <CircularProgressBar isloading={isloading} />
          {!ischannelGrid && !isStylisedModuleInitiated && (
            <div className="contentContainer existingTacticsContainer">
              <div className="row">
                <div className="col-md-2 existingSearhPanel ">
                  <DamsSourceFilter
                    setSelectedDamsSource={setSelectedDamsSource}
                    retrieveSelectedDamsCountryList={
                      retrieveSelectedDamsCountryList
                    }
                  />
                  <DamsFilter
                    brands={brandList}
                    channels={channels}
                    markets={
                      checkIfVeevaModularContent()
                        ? selectedDamsSourceMarketDataSource || []
                        : marketDataSource
                    }
                    campaigns={getCampaignsFromSelectedBrands()}
                    selectedBrands={selectedBrands}
                    selectedChannels={selectedChannels}
                    selectedMarkets={selectedMarkets}
                    selectedCampaigns={selectedCampaigns}
                    setSelectedBrands={setSelectedBrands}
                    setSelectedChannels={setSelectedChannels}
                    setSelectedMarkets={setSelectedMarkets}
                    setSelectedCampaigns={setSelectedCampaigns}
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                    searchMarketTerm={searchMarketTerm}
                    setSearchMarketTerm={setSearchMarketTerm}
                    removeSelectedCampaignforRemovedBrand={
                      removeSelectedCampaignforRemovedBrand
                    }
                    channelsRequired={
                      selectedDamsSource?.sourceType.toLowerCase() !==
                      damsSources.veeva.name.toLowerCase()
                    }
                    shouldMarketAndCampaignFiltersBeHidden={
                      shouldMarketAndCampaignFiltersBeHidden
                    }
                  />
                </div>

                <div className="col-md-10">
                  {renderSearchOptions()}
                  {selectedDamsSource &&
                    selectedDamsSource?.sourceType.toLowerCase() !==
                      damsSources.aem.name.toLowerCase() && (
                      <SearchTacticsAndModules
                        setTitleType={setTitleType}
                        startSearching={searchTactics}
                        searchBarPlaceholder={
                          getAssetDataType().toLowerCase() === "modularcontent"
                            ? "Search by Module Name"
                            : searchInDams
                            ? "Search by Asset Name"
                            : t("tacticSource.searchDamsAndMMPlaceHolder")
                        }
                        clearAllSearch={clearAllSearch}
                        filteredBrandList={selectedBrands}
                        filteredChannelList={selectedChannels}
                        filteredMarketList={selectedMarkets}
                        campaignFilterList={selectedCampaigns}
                        removeitemFromBrandFilteredList={removeSelectedBrand}
                        removeitemFromFilteredListChannel={
                          removeSelectedChannel
                        }
                        removeitemFromFilteredListMarket={removeSelectedMarket}
                        removeitemFromFilteredCampaignList={
                          removeSelectedCampaign
                        }
                      />
                    )}
                  {searchInDams &&
                    selectedDamsSource?.sourceType.toLowerCase() ===
                      damsSources.veeva.name.toLowerCase() &&
                    getAssetDataType().toLowerCase() === "modularcontent" && (
                      <VeevaSearchPage
                        contentModules={assetsToBeRetrievedFromDams}
                        clearAllSearch={clearAllSearch}
                        titleType={titleType}
                        redirectToSearchInMM={redirectToSearchInMM}
                        selectedDamsSource={selectedDamsSource}
                        getAssetDataType={getAssetDataType}
                        searchFuncRef={searchFuncRef}
                        searchInDams={searchInDams}
                      />
                    )}
                  {!searchInDams &&
                    selectedDamsSource?.sourceType.toLowerCase() ===
                      damsSources.veeva.name.toLowerCase() &&
                    isModularContent(getAssetDataType()) && (
                      <>
                        <VeevaDefaultPage
                          modules={assetsAndModules}
                          sourceType={selectedDamsSource.sourceType.toLowerCase()}
                          assetDataType={getAssetDataType()}
                          setAssetsAndModules={setAssetsAndModules}
                          brandsList={brandList}
                          updateContentModulesMatrix={
                            updateContentModulesMatrix
                          }
                        />
                      </>
                    )}
                  {!searchInDams &&
                    (selectedDamsSource?.sourceType.toLowerCase() ===
                      damsSources.aprimo.name.toLowerCase() ||
                      getAssetDataType().toLowerCase() ===
                        "assetcomponent") && (
                      <ModularisedTactics
                        titleType={titleType}
                        assetAndModuleDetails={assetsAndModules}
                        selectedAssetDetails={selectedAssetDetails}
                        setSelectedAssetDetails={setSelectedAssetDetails}
                        setSelectedAssetHandler={setSelectedAssetHandler}
                        fetchBrandsAndCampaigns={fetchBrandsAndCampaigns}
                        filteredPages={filteredPages}
                        brandsList={brandList}
                        getCampaignList={getCampaignList}
                        refreshSelectAsset={refreshSelectedAsset}
                        addLatestMatrixData={addLatestMatrixData}
                        shouldShowOnlyOriginalModules={true}
                        genericData={{
                          brandsAndCampaigns: brandsAndCampaigns,
                          audience: audience,
                          marketDataSource: marketDataSource,
                        }}
                        isDamsFlow={true}
                        saveGenericInfo={saveGenericInfo}
                        setIschannelGrid={setIschannelGrid}
                        selectedChannel={selectedChannel}
                        setSelectedChannel={setSelectedChannel}
                        setactivePage={setactivePage}
                        setChannelGridToView={setChannelGridToView}
                        startStylizingModule={startStylizingModule}
                        closestylizeChannelSelectionModal={
                          closestylizeChannelSelectionModal
                        }
                        stylizeChannelSelectionModalData={
                          stylizeChannelSelectionModalData
                        }
                        proceedToCtaSelectionScreen={
                          proceedToCtaSelectionScreen
                        }
                        setstylizeChannelSelectionModalData={
                          setstylizeChannelSelectionModalData
                        }
                        setAssetsAndModules={setAssetsAndModules}
                      />
                    )}
                  {searchInDams &&
                    getAssetDataType().toLowerCase() === "assetcomponent" && (
                      <>
                        <SearchResultsPage
                          assets={assetsToBeRetrievedFromDams}
                          clearAllSearch={clearAllSearch}
                          titleType={titleType}
                          redirectToSearchInMM={redirectToSearchInMM}
                          selectedDamsSource={selectedDamsSource}
                          getAssetDataType={getAssetDataType}
                        />
                      </>
                    )}
                </div>
              </div>
            </div>
          )}

          {ischannelGrid && (
            <>
              <div className="contentContainer existingTacticsContainer">
                <div className="row">
                  <div className="col-md-12">
                    <ChannelGridHome
                      modules={filterModules("original")}
                      sourceType={selectedDamsSource?.sourceType.toLowerCase()}
                      selectedBrands={selectedBrands}
                      selectedChannels={selectedChannels}
                      selectedMarkets={selectedMarkets}
                      selectedCampaigns={selectedCampaigns}
                      selectedAssetDetails={selectedAssetDetails}
                      selectedChannel={selectedChannel}
                      cancelCreatingGrid={cancelCreatingGrid}
                      assetDataType={getAssetDataType()}
                      activePage={activePage}
                      setactivePage={setactivePage}
                      channelGridToView={channelGridToView}
                      updateGridCout={updateGridCout}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {isStylisedModuleInitiated && (
            <>
              <div className="contentContainer existingTacticsContainer">
                <div className="row">
                  <div className="col-md-12">
                    <CtaSelection
                      brand={selectedAssetDetails?.brand}
                      customClassName="row pl-2 selectable-Cta-cards customScrollBar m-0"
                      campaignName={selectedAssetDetails?.campaignName}
                      channelType={selectedChannel}
                      cancelCreatingStylisedModule={
                        cancelCreatingStylisedModule
                      }
                      handleProceed={fetchModuleToStylize}
                      isStylisedModuleInitiated={isStylisedModuleInitiated}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <CustomModal
            displayModal={stylizeModuleChannelSelection}
            hideModal={closestylizeChannelSelectionModal}
            size="sm"
            dilogClassName="alertModalSmall"
            backdrop={false}
          >
            <ChannelSelectionModalForStylizedModule
              closestylizeChannelSelectionModal={
                closestylizeChannelSelectionModal
              }
              sourceType={stylizeChannelSelectionModalData?.sourceType}
              brand={stylizeChannelSelectionModalData?.brand}
              campaign={stylizeChannelSelectionModalData?.campaign}
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              cancelCreatingChannelMatrix={closestylizeChannelSelectionModal}
              assetDataType={selectedAssetDetails?.assetDataType}
              //moduleId={moduleIdToCreateMatrix}
              onClickProceed={(flag) => {
                proceedToCtaSelectionScreen();
              }}
            />
          </CustomModal>
        </div>
      </div>
    </>
  );
};

export default DamsHome;
