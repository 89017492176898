import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CustomizedSelects from "./DropDown";
import CustomChip from "./Chip";
import "../styles/components/CoreClaim.scss";
import { Container, Row, Col } from "react-bootstrap";

const CoreClaim = (props) => {
  const { t } = useTranslation();
  const [coreClaims, setCoreClaims] = useState([]);
  const [selectedCoreClaim, setSelectedCoreClaim] = useState({});
  const [shouldUpdateParent, setShouldUpdateParent] = useState(false);
  //const [isSelectedByDefault, setIsSelectedByDefault] = useState(false)

  useEffect(() => {
    if (props.data.length === 0 && coreClaims.length === 0) {
      // Do nothing as of now
    } else {
      setCoreClaims(props.data);
    }
    if (props.selectedCoreClaim) {
      setSelectedCoreClaim(props.selectedCoreClaim);
    }
  });

  // useEffect(() => {
  //   if(props.selectedCoreClaim){
  //     setSelectedCoreClaim(props.selectedCoreClaim)
  //   }
  // }, [props])

  //console.log('OMG, this is the default selected claim', selectedCoreClaim)

  useEffect(() => {
    //debugger
    if (
      !coreClaims ||
      coreClaims.length <= 0 ||
      !props.selectedCoreClaim ||
      Object.keys(props.selectedCoreClaim).length <= 0
    ) {
      setSelectedCoreClaim({});
      //updateParent({})
    }
  }, [coreClaims]);

  // useEffect(()=>{
  //   // debugger
  //     if(props.onCoreClaimSelect){
  //       if(selectedCoreClaim){
  //         props.onCoreClaimSelect(selectedCoreClaim)
  //       }
  //       else{
  //         props.onCoreClaimSelect({})
  //       }
  //     }
  // }, [shouldUpdateParent])

  const updateParent = (data) => {
    if (props.onCoreClaimSelect) {
      if (data) {
        props.onCoreClaimSelect(data);
      } else {
        props.onCoreClaimSelect({});
      }
    }
  };

  const onCoreClaimSelect = (coreClaim) => {
    if (coreClaim && coreClaim !== "base") {
      // if(isSelectedByDefault){
      //   setIsSelectedByDefault(false)
      // }
      // setSelectedCoreClaim({ id: coreClaim.id, claim: coreClaim.name });
      // updateParent({ id: coreClaim.id, claim: coreClaim.name });
      setSelectedCoreClaim({
        id: coreClaim.id,
        claim: coreClaim.name,
        claimsManagerId: coreClaim.claimsManagerId,
      });
      updateParent({
        id: coreClaim.id,
        claim: coreClaim.name,
        claimsManagerId: coreClaim.claimsManagerId,
      });
    }
  };

  const onRemoveSelectedCoreClaim = (categoryToBeDeleted) => {
    setSelectedCoreClaim({});
    updateParent({});
    //setShouldUpdateParent(!shouldUpdateParent)
  };

  const renderClaims = () => {
    // if(props.shouldFreeze){
    //   return (<div></div>)
    // }
    // else{
    return (
      selectedCoreClaim &&
      selectedCoreClaim.id &&
      selectedCoreClaim.claim && (
        <CustomChip
          data={[{ id: selectedCoreClaim.id, name: selectedCoreClaim.claim }]}
          handleDelete={onRemoveSelectedCoreClaim}
        />
      )
    );
    //}
  };

  const renderClaimsDropDown = () => {
    // if(props.shouldFreeze){
    // return (<div className = 'frozen-claim'>{selectedCoreClaim.claim}</div>)
    // }
    // else{
    return (
      <CustomizedSelects
        data={coreClaims.map((coreClaim) => {
          if (coreClaim) {
            return {
              id: coreClaim.id,
              name: coreClaim.claim,
              claimsManagerId: coreClaim.claimsManagerId,
            };
          }
        })}
        value={selectedCoreClaim && selectedCoreClaim.claim}
        default={i18next.t("coreClaim.selectCoreClaim")}
        handleSelection={onCoreClaimSelect}
      />
    );
    //}
  };

  return (
    <>
      <Row className="align-items-center mt-4">
        <Col md={2} className="catLabelTitle">
          {/* <div className = "row head-core-claim"> */}
          {i18next.t("coreClaim.coreClaim")}
          {/* <button className = "col-md-4 btn-add" onClick = {onAddManualClick}>{i18next.t("coreClaim.addManually")}</button> */}
          {/* </div> */}
        </Col>
        <Col md={10}>{renderClaimsDropDown()}</Col>
      </Row>
      {selectedCoreClaim.claimsManagerId && (
        <Row className="align-items-center mt-4">
          <Col md={2} className="catLabelTitle">
            {i18next.t("coreClaim.scientificClaimId")}
          </Col>
          <Col md={10}>{selectedCoreClaim.claimsManagerId}</Col>
        </Row>
      )}
      {/* <Row>
        <Col md={2}></Col>
        <Col md={10}>
          {renderClaims()}
        </Col>
      </Row> */}
    </>
  );
};

export default CoreClaim;
