// export function froalaEditor() {
//     return {
//          config : function({ placeHolder = '', shouldHaveToolbar } = options){

import FroalaEditor from "react-froala-wysiwyg";

//             return {
//                 placeholderText: placeHolder,
//                 pluginsEnabled: ['align', 'link'],
//                 toolbarInline: shouldHaveToolbar,
//                 language: 'ro',
//                 events: {
//                     initialized: function (e) {
//                         var editor = this;
//                     }
//                 }
//             }

//         }
//     }
// }

export const froalaEditor = (function () {
  return {
    config: function (options, onSupClick) {
      return {
        key: "pe1G2wD1D1A2B1A5B5nDe1YRTYKd1Dd1f1JVVD1VJKKYLMPvC2B1F1G1B4D4E3B1C8E7==",
        attribution: false,
        pastePlain: true,
        placeholderText: options.placeHolder,
        pluginsEnabled: ["align", "link", "charCounter"],
        charCounterCount: options.charCounter ? true : false,
        charCounterMax: options.charCounter || -1,
        toolbarInline: options.shouldHaveToolbar,
        immediateReactModelUpdate: options.shouldUpdateImmediatly || false,
        language: "ro",
        color: options.color,
        ignoreEmptyParagraph: false,
        htmlUntouched: true,
        // pastePlain: true,
        toolbarButtons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "outdent",
          "indent",
          "clearFormatting",
          "redo",
          options.insertLink ? "insertLink" : "",
        ],
        linkEditButtons: [
          "linkOpen",
          "linkStyle",
          "linkEdit",
          "linkRemove",
          "underline",
        ],
        shortcutsEnabled: [],
        shortcuts: false,
        initOnClick: true,
        linkList: [],
        height: "100%",
        events: {
          initialized: function (e) {
            var editor = this;
            if (!options.contentEditable) {
              editor.edit.off();
            }
            editor.events.on(
              "drop",
              function (e) {
                e.preventDefault();
                return false;
              },
              true
            );

            //   let el = document.getElementsByClassName("fr-view")[0];
            //   el.addEventListener("keydown", function (key) {
            //     if (key.keyCode == 8 || key.keyCode == 46) {
            //       if (editor.selection.element().tagName === "SUP") {
            //         alert("do something");
            //       }
            //     }
            //   });

            this.events.on(
              "keydown",
              function (e) {
                if (e.ctrlKey || e.metaKey) {
                  if (e.which === 88) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    return false;
                  }
                }
              },
              true
            );
            this.events.on(
              "paste.before",
              function (e) {
                if (options.charCounter && options.charCounter) {
                  let copiedData = e.clipboardData.getData("text/plain");
                  let text = this.html.get();
                  let regex = /(<([^>]+)>)/gi;
                  let plainText = text.replace(regex, "").trim();
                  let selectedText = this.selection.text();
                  let currentTextcount = plainText.length;
                  currentTextcount = currentTextcount - selectedText.length;
                  let count =
                    selectedText.length === currentTextcount
                      ? 250
                      : selectedText.length > 0 && currentTextcount === 250
                      ? selectedText.length
                      : 250 - currentTextcount;
                  if (copiedData.length > 250);
                  copiedData = copiedData.substring(0, count);
                  const selection = e.target.ownerDocument.getSelection();

                  selection.deleteFromDocument();
                  selection
                    .getRangeAt(0)
                    .insertNode(document.createTextNode(copiedData.trim()));
                  selection.collapseToEnd();
                  if (options.onChange) {
                    options.onChange(copiedData);
                  }
                  this.selection.clear();
                  return false; // Prevent the default handler from running.
                }
              },
              true
            );
          },
        },
      };
    },
  };
})();
