import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18next from "i18next";
import { makeStyles } from "@material-ui/core/styles";
import LogoutCTA from "../Pages/LogoutCTA";
import {
  getBrandsAndCampaigns,
  getCognitoSession,
  getCognitoIndentityCredentials,
  createDenovoModule,
} from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import ImcLogo from "../images/NEXT-Module-logo.svg";
import "../styles/pages/UplaodMasterAsset.scss";
import "../styles/CustomToastify.scss";
//Denovo related imports
//import DenovoGenericDetails from "../components/denovo-flow/DenovoGenericDetails";
import ExistingTactics from "../components/ExistingTactics/ExistingTactics";
import MarketingMessagesHome from "../components/MarketingMessages/MarketingMessagesHome";
import {
  filterOutGlobalMarket,
  imcLanguageTableName,
  isDamsEnabled,
  isDenovoEnabled,
  isHarvesterEnabled,
  isMarketingMessagesEnabled,
  routes,
} from "../Uitls/Util";
import { useHistory } from "react-router-dom";

//MArket Data returnlated imports and varaihle declaration
import {
  updateDynamicDBConfig,
  getDyanamoDBInstance,
  isoCountryTableName,
  awsRegion,
} from "../Uitls/Util";
import DenovoModules from "../components/denovo-flow/DenovoModules";
import DamsHome from "../components/Dams/DamsHome";

var dynamo = require("dynamodb");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#1c75bb",
  },
}));

function ExistingTacticsAndModules(props) {
  const [isloading, setIsloading] = useState(false);
  const [audienceDataSource, setAudienceDataSource] = useState([
    "Consumer",
    "HCP",
    "Payer",
  ]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [channels, setChannels] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();

  //Denovo flow related variable declatation
  const [isDamsTabSelected, setIsDamsTabSelected] = useState(true);
  const [isMarketingMessages, setIsMarketingMessages] = useState(false);
  const [isDenovo, setisDenovo] = useState(false);
  const [existingTactic, setExistingTactic] = useState(false);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [globalMarket, setGlobalMarket] = useState(undefined);
  const [languageDataSource, setLanguageDataSource] = useState(undefined);

  let history = useHistory();
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
    setChannelsData();
    // if (props && props.location && props.location.state) {
    //   const assetId = props.location.state.assetId;
    //   if (assetId) {
    //     setExistingTactic(true);
    //     setisDenovo(false);
    //     setAppropriateCssClass("existing-tactic");
    //   }
    // }
    setAppropriateCssClass("dams");
  }, []);

  const setChannelsData = () => {
    let channelDataSource = [];
    channelDataSource.push(i18next.t("channelTypeSelect.detailingAid"));
    channelDataSource.push(i18next.t("channelTypeSelect.email"));
    channelDataSource.push(i18next.t("channelTypeSelect.banner"));
    channelDataSource.push(i18next.t("channelTypeSelect.socialMediaAdv"));
    channelDataSource.push(i18next.t("channelTypeSelect.print"));
    channelDataSource.push(i18next.t("channelTypeSelect.alert"));
    setChannels(channelDataSource);
  };

  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        fileUplodedErr(errCoponent);
      } else {
        //parse and save
        console.log(response);
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };

  const fetchLanguages = () => {
    var params = {
      TableName: imcLanguageTableName,
    };
    var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
      region: awsRegion,
    });
    docClient.scan(params, function (err, data) {
      if (data && data.Items) {
        let languages = [];
        data.Items.reduce((acc, curr) => {
          if (
            acc.findIndex((itm) => {
              return (
                itm.languageCode === curr.languageCode &&
                itm.language === curr.language
              );
            }) === -1
          ) {
            acc.push(curr);
            languages.push(curr);
          }
          return acc;
        }, []);
        setLanguageDataSource(languages);
      } else {
        setLanguageDataSource(undefined);
      }
    });
  };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          fetchLanguages();
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  const global = marketData.find((market) => {
                    return market.name.toLowerCase() === "global";
                  });
                  if (global) {
                    setGlobalMarket(global);
                  }
                  marketData = filterOutGlobalMarket(marketData);
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const setAppropriateCssClass = (id) => {
    let selectedButton = document.getElementById(id);
    let otherButtons = [];
    let className = "segment-button";
    switch (id) {
      case "existing-tactic":
        className = className + " active-segment-button";
        otherButtons.push(document.getElementById("create-denovo"));
        otherButtons.push(document.getElementById("dams"));
        otherButtons.push(document.getElementById("marketing-messages"));
        break;
      case "create-denovo":
        className = className + " active-segment-button";
        otherButtons.push(document.getElementById("existing-tactic"));
        otherButtons.push(document.getElementById("dams"));
        otherButtons.push(document.getElementById("marketing-messages"));
        break;
      case "dams":
        className = className + " active-segment-button";
        otherButtons.push(document.getElementById("existing-tactic"));
        otherButtons.push(document.getElementById("create-denovo"));
        otherButtons.push(document.getElementById("marketing-messages"));
        break;
      case "marketing-messages":
        className = className + " active-segment-button";
        otherButtons.push(document.getElementById("create-denovo"));
        otherButtons.push(document.getElementById("dams"));
        otherButtons.push(document.getElementById("existing-tactic"));
        break;
      default:
        break;
    }
    selectedButton.className = className;
    for (const othButton of otherButtons) {
      if (othButton) {
        othButton.className = "segment-button";
      }
    }
  };

  const onClickSegmentButton = (event) => {
    if (event && event.target) {
      setAppropriateCssClass(event.target.id);
      if (event.target.id === "create-denovo") {
        setisDenovo(true);
      } else {
        setisDenovo(false);
      }
      if (event.target.id === "existing-tactic") {
        setExistingTactic(true);
      } else {
        setExistingTactic(false);
      }
      if (event.target.id === "dams") {
        setIsDamsTabSelected(true);
      } else {
        setIsDamsTabSelected(false);
      }
      if (event.target.id === "marketing-messages") {
        setIsMarketingMessages(true);
      } else {
        setIsMarketingMessages(false);
      }
    }
  };
  const fileUplodedErr = (value) =>
    toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  return (
    <>
      <LogoutCTA />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="logoCol">
              <img src={ImcLogo} alt="IMC - Module Manager" />
            </div>
          </div>
        </div>
        <div className="row mt-3 segemneted-control">
          {isDamsEnabled() && (
            <button
              id="dams"
              className="segment-button"
              onClick={onClickSegmentButton}
            >
              {t("uploadAssetLabel.dams").toUpperCase()}
            </button>
          )}
          {/* {isHarvesterEnabled() && (
            <button
              id="existing-tactic"
              className="segment-button active-segment-button"
              onClick={onClickSegmentButton}
            >
              {t("uploadAssetLabel.deconstructedTactics").toUpperCase()}
            </button>
          )} */}
          {isDenovoEnabled() && (
            <button
              id="create-denovo"
              className="segment-button"
              onClick={onClickSegmentButton}
            >
              {t("uploadAssetLabel.denovo").toUpperCase()}
            </button>
          )}
          {isMarketingMessagesEnabled() && (
            <button
              id="marketing-messages"
              className="segment-button"
              onClick={onClickSegmentButton}
            >
              {t("uploadAssetLabel.marketingMessages").toUpperCase()}
            </button>
          )}
        </div>
        {existingTactic && (
          <ExistingTactics
            data={{
              brandsAndCampaigns: brandsAndCampaigns,
              audience: audienceDataSource,
              marketDataSource: marketDataSource,
              languageDataSource: languageDataSource || [],
            }}
            showSource={false}
            showChannel={false}
          />
        )}
        {isDamsTabSelected && (
          <DamsHome
            brandsAndCampaigns={brandsAndCampaigns}
            audience={audienceDataSource}
            marketDataSource={marketDataSource}
            channels={channels}
            fetchBrandsAndCampaigns={fetchBrandsAndCampaigns}
          />
        )}
        {isDenovo && (
          <DenovoModules
            data={{
              brandsAndCampaigns: brandsAndCampaigns,
              audience: audienceDataSource,
              marketDataSource: globalMarket
                ? [globalMarket].concat(
                    JSON.parse(JSON.stringify(marketDataSource))
                  )
                : marketDataSource,
              languageDataSource: languageDataSource || [],
            }}
          />
        )}

        {isMarketingMessages && <MarketingMessagesHome />}
      </div>
    </>
  );
}

export default ExistingTacticsAndModules;
