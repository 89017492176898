import { Switch } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import parse from "html-react-parser";

import "../../../styles/components/InstructionsOrGuidelines.scss";
import { getMonth, OutsideClickHandler } from "../../../Uitls/Util";

const InstructionsOrGuidelines = ({
  instructions,
  popupCurrentState,
  popupUpdateState,
  //event,
  styleClass,
  hideShowAll = false,
}) => {
  const [showAll, toggleShowAll] = useState(false);
  const instructionsRef = useRef();

  const getInstructionsToShow = () => {
    if (!showAll && instructions && instructions.length > 0) {
      return [instructions[0]];
    }
    return instructions;
  };
  const hidePopup = () => {
    if (popupCurrentState) {
      popupUpdateState(false);
    }
  };

  OutsideClickHandler(instructionsRef, hidePopup, popupCurrentState);

  const getDateCreated = (dateString) => {
    const date = new Date(dateString);
    const dateStr = `${date.getDate()} ${getMonth(
      date.getMonth()
    )} ${date.getFullYear()}`;
    const time = moment(`${date.getHours()}:${date.getMinutes()}`, [
      "HH.mm",
    ]).format("hh:mm a");
    return `${dateStr}, ${time.toUpperCase()}`;
  };

  return (
    <div
      id="pop-show-instrutions"
      ref={instructionsRef}
      className={` poup-position ${styleClass}`}
    >
      {!hideShowAll && (
        <div className="toggle-showInstructions">
          Show all instructions
          <Switch
            className="filterRightMargin"
            inputProps={{ "aria-label": "primary checkbox" }}
            toggle={showAll.toString()}
            onChange={() => {
              toggleShowAll(!showAll);
            }}
          />
        </div>
      )}

      <div className="instructions-title">Instructions / Guidelines</div>
      <div className="scorllingInstructions customScrollBar">
        {getInstructionsToShow().map((instruction, index) => {
          return (
            <div key={instruction.id + index}>
              <span className="ins-version">{instruction.version}</span>
              <span className="ins-creator">
                <span>
                  {instruction.reviewer} {"|"}
                </span>
                <span>{getDateCreated(instruction.createdDateTime)}</span>
              </span>

              <div className="ins-item">
                {parse(instruction.instruction || "")}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InstructionsOrGuidelines;
