//import dependencies
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
//import Components
import HarvestedTacticCard from "./HarvestedTacticCard";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";
import ErrComponentToRender from "../../../Uitls/apiErrorHandling";
import ExistingTacticsAsset from "../ExistingTacticsAsset";
import { addProcessIds } from "../../../Uitls/polling";
//import api functions
import {
  getHarvestedTactics,
  getICBToken,
  searchHarvestedTactics,
  getAssetsStatus,
  storeGenericInfo,
  createPlaceholderModules,
  retrieveTacticsToMM,
} from "../../../services/apis";
//import styles
import "../../../styles/components/HarvestedTactics.scss";
import HarvestedGenericDetails from "./HarvestedGenericDetails";
import { routes } from "../../../Uitls/Util";

const HarvestedTactics = ({
  data,
  titleType,
  isICBSearchStarted,
  setIsICBSearchStarted,
  isHarvestedsearchReset,
  setIsHarvestedSearchReset,
  brandsList,
  allBrands,
  searchText,
  clearAllSearch,
}) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [isloading, setIsloading] = useState(false);
  const [tactics, setTactics] = useState([]);
  const [selectedTactic, setSelectedTactic] = useState(undefined);
  const [tacticsToBeRetrieved, setTacticsToBeRetrieved] = useState([]);
  const [isDefaultList, setIsDefaultList] = useState(true);
  useEffect(() => {
    //Start loading harvested tactics
    fetchHarvestedTactics();
  }, []);

  const [duplicateAssetsList, setDuplicateAssetsList] = useState([]);

  const getICBTokenFromLocal = () => {
    return window.localStorage.getItem("icbToken");
  };
  useEffect(() => {
    if (isICBSearchStarted) {
      doICBSearch();
    }
    if (isHarvestedsearchReset) {
      fetchHarvestedTactics();
      setIsHarvestedSearchReset(false);
      setIsDefaultList(true);
    }
  }, [isICBSearchStarted, isHarvestedsearchReset]);

  useEffect(() => {
    if (!isDefaultList && !isHarvestedsearchReset) {
      searchTactics();
    }
  }, [brandsList]);

  const doICBSearch = async () => {
    setIsloading(true);
    setIsDefaultList(false);
    if (!getICBTokenFromLocal()) {
      await fecthICBToken();
    }
    await searchTactics();
    setIsICBSearchStarted(false);
  };

  const fetchHarvestedTactics = () => {
    setIsloading(true);
    setIsDefaultList(true);
    getHarvestedTactics((response, error, errorCode) => {
      if (response) {
        let data = response.data;
        data = [...data].map((item) => {
          item["thumbnailUrl"] = item.assetThumbnailUrl;
          item["assetId"] = item.harvestedAssetId;
          //item["assetName"] = item.masterAssetName; //assetName
          return item;
        });

        if (data.length > 0) {
          setSelectedTactic(data[0]);
        }
        setTactics(data);
      }
      if (errorCode) {
        const errCoponent = ErrComponentToRender(errorCode);
        showError(errCoponent);
      }
      setIsloading(false);
    });
  };

  const fecthICBToken = async () => {
    getICBToken((err, response) => {
      if (response) {
        const icbToken = response.data.access_token;
        window.localStorage.setItem("icbToken", icbToken);
      }
    });
  };

  const searchTactics = async () => {
    const icbToken = getICBTokenFromLocal();
    searchHarvestedTactics(
      icbToken,
      0,
      1500,
      brandsList && brandsList.length > 0
        ? brandsList.join()
        : allBrands.join(),
      searchText,
      async (error, response) => {
        if (response) {
          setTactics(response.data.items);
        }
        if (error) {
          if (
            error.response.status === 401 &&
            error.response.statusText === "UNAUTHORIZED"
          ) {
            await fecthICBToken();
            await searchTactics();
            return;
          }
          const errCoponent = ErrComponentToRender(
            error.response && error.response.status
          );
          showError(errCoponent);
        }
        setIsloading(false);
      }
    );
  };

  const onSelectTactic = (tactic, isChecked) => {
    const index = tacticsToBeRetrieved.findIndex((item) => {
      return item.externalAssetId === tactic.id;
    });
    if (isChecked && index < 0) {
      let brandName = undefined;
      if (tactic.corrected && tactic.corrected.brand) {
        brandName = tactic.corrected.brand.Text;
      } else {
        const metadata = tactic.meta_data.entities;
        if (metadata && metadata.length > 0) {
          let brands = metadata.filter((el) => el.Type === "BRAND_NAME");
          if (brands && brands.length > 0) {
            brandName = brands[0].Text;
          }
        }
      }
      let finalTactic = {
        externalAssetId: tactic.id,
        assetName: tactic.name,
        assetThumbnailUrl: tactic.url,
        brand: brandName,
        assetDataType: "AssetComponent",
      };

      setTacticsToBeRetrieved([...tacticsToBeRetrieved].concat(finalTactic));
    } else if (!isChecked && index > -1) {
      setTacticsToBeRetrieved(
        [...tacticsToBeRetrieved].filter((item) => {
          return item.externalAssetId !== tactic.id;
        })
      );
    }
  };

  const showError = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const onRetrievedTacticCardClick = (tactics, selectedTacticId) => {
    const selectedTactic = tactics.find((tac) => {
      return tac.storageAssetId === selectedTacticId;
    });
    setSelectedTactic(selectedTactic);
  };

  const renderTactics = () => {
    if (isDefaultList) {
      return (
        <div className=" masterTactic">
          <ExistingTacticsAsset
            titleType={titleType}
            assetAndModuleDetails={tactics}
            selectedAssetDetails={selectedTactic}
            setSelectedAssetHandler={onRetrievedTacticCardClick}
          />
        </div>
      );
    } else {
      return (
        <div className="row masterAssetBlock align-items-center main-content custonScrollBar">
          {tactics.map((tactic) => {
            return (
              <HarvestedTacticCard
                key={tactic.assetId}
                tactic={tactic}
                onSelected={onSelectTactic}
                tacticsToBeRetrieved={tacticsToBeRetrieved}
                duplicateAssetsList={duplicateAssetsList}
              />
            );
          })}
        </div>
      );
    }
  };

  const getInProgressIds = () => {
    getAssetsStatus((response, err, errorCode) => {
      if (err) {
        console.log(err);
      } else {
        addProcessIds(response.data);
      }
    });
  };

  const retrieveTacticsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const retrieveTacticsSuccess = (sucessAssetName, duplicatedAssetName) =>
    toast.success(
      <>
        {duplicatedAssetName &&
        duplicatedAssetName.length > 0 &&
        sucessAssetName &&
        sucessAssetName.length < 1 ? (
          ""
        ) : (
          <p className="harvesetedToastTitle mb-0">Retrieval Process Started</p>
        )}

        {sucessAssetName && sucessAssetName.length > 0 ? (
          <>
            <p className="retrievalStatusText mb-0">
              Retrieval process for the selected tactics
            </p>
            {sucessAssetName &&
              sucessAssetName.map((item, index) => (
                <p
                  className="retrievalStatusText mb-0"
                  style={{ wordBreak: "break-all" }}
                  key={index}
                >
                  {item}
                </p>
              ))}
            <p className="retrievalStatusText">
              has started. You will be notified once the same are available in
              Module Manager
            </p>
          </>
        ) : (
          ""
        )}

        {duplicatedAssetName && duplicatedAssetName.length > 0 ? (
          <>
            {duplicatedAssetName &&
              duplicatedAssetName.map((item, index) => (
                <p
                  className="retrievalStatusText  mb-0"
                  style={{ wordBreak: "break-all" }}
                  key={index}
                >
                  {item}
                </p>
              ))}
            <p className="retrievalStatusText">
              Already exists! The same tactic cannot be downloaded again.
            </p>
          </>
        ) : (
          ""
        )}
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0 text-right"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: false,
      }
    );

  const retrieveTactics = () => {
    setIsloading(true);
    retrieveTacticsToMM(tacticsToBeRetrieved, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        retrieveTacticsErr(errCoponent);
        setIsloading(false);
        setTacticsToBeRetrieved([]);
        setDuplicateAssetsList([]);
      } else {
        let filteredData = response.data.filter(function (item) {
          if (item.tacticRetrievedFlag === false) {
            return true;
          }
        });
        const finalData = filteredData.map((item) => item.externalAssetId);
        const sucessAssetName = filteredData.map((item) => item.assetName);

        let duplicatedAssetIdList = response.data.filter(function (item) {
          if (item.tacticRetrievedFlag === true) {
            return true;
          }
        });
        const duplicatedAssetIds = duplicatedAssetIdList.map(
          (item) => item.externalAssetId
        );
        const duplicatedAssetName = duplicatedAssetIdList.map(
          (item) => item.assetName
        );
        setDuplicateAssetsList(duplicatedAssetIds);
        getInProgressIds();
        setIsloading(false);
        retrieveTacticsSuccess(sucessAssetName, duplicatedAssetName);
        setTimeout(() => {
          setTacticsToBeRetrieved([]);
          setDuplicateAssetsList([]);
          setIsDefaultList(() => {
            clearAllSearch(true);
          });
          setIsDefaultList(true);
        }, 5000);
      }
    });
  };
  const saveGenericInfo = (genericInfo) => {
    let payload = {
      storageAssetId: selectedTactic.storageAssetId,
      externalAssetId: selectedTactic.externalAssetId,
      assetName: selectedTactic.assetName,
      assetThumbnailUrl: selectedTactic.assetThumbnailUrl,
      harvestedAssetId: selectedTactic.harvestedAssetId,
      channelType: genericInfo.channelType,
      campaignId: genericInfo.campaignId,
      campaign: genericInfo.campaign,
      audience: genericInfo.audience,
      brand: genericInfo.brand,
      brandId: genericInfo.brandId,
      description: genericInfo.description,
      dosing: genericInfo.dosing,
      audienceSegment: genericInfo.audienceSegment,
      market: genericInfo.market,
      isi: genericInfo.isi,
      pi: genericInfo.pi,
      mg: genericInfo.mg,
      genericBusinessRules: genericInfo.genericBusinessRules,
      therapeuticArea: genericInfo.therapeuticArea,
    };

    setIsloading(true);
    storeGenericInfo(payload, (response, err, errCode) => {
      if (response) {
        const createModulePayload = {
          harvestedAssetId: selectedTactic.harvestedAssetId,
          storageAssetId: selectedTactic.storageAssetId,
          brandId: genericInfo.brandId,
          brandName: genericInfo.brand,
          campaignName: genericInfo.campaign,
          campaignId: genericInfo.campaignId,
          modules: [{ name: "Untitled_module", coreCategories: [] }],
        };

        createPlaceholderModules(
          createModulePayload,
          (response, err, errorCode) => {
            setIsloading(false);
            if (err) {
              if (errorCode === 500) {
                toast.error(
                  <p className="notificationText">
                    Error creating modules. Please try after sometime
                  </p>,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  }
                );
              } else {
                const errCoponent = ErrComponentToRender(errorCode);
                showError(errCoponent);
              }
            } else {
              history.push({
                pathname: routes.assetDetails,
                state: {
                  assetid: response.data.storageAssetId,
                  moduleName: response.data.modules[0].name,
                  moduleCode: response.data.modules[0].moduleCode,
                  moduleId: response.data.modules[0].id,
                  brandId: response.data.brandId,
                  isCategoriesAdded:
                    response.data.modules[0].coreCategoriesFlag,
                },
              });
            }
          }
        );
      } else {
        setIsloading(false);
        const errCoponent = ErrComponentToRender(
          err.response && err.response.status
        );
        showError(errCoponent);
      }
    });
  };

  return (
    <>
      {!isDefaultList && (
        <>
          <h2 className="masterTacticTitle">
            {/* {titleType === "initial" ? `All Tactic` : "Search Results"}{" "} */}
            All Tactics
          </h2>
          <p class="search-items">
            {tactics.length} {tactics.length === 1 ? "Item" : "Items"}
          </p>
        </>
      )}
      <div className="row">
        <div className={`${!isDefaultList ? "col-md-12 " : "col-md-3"}`}>
          <CircularProgressBar isloading={isloading} />
          {renderTactics()}
        </div>
        {isDefaultList && selectedTactic && (
          <div className="col-md-9">
            <p class="moduleAssetTitle">{selectedTactic.assetName}</p>
            <HarvestedGenericDetails
              data={data}
              selectedTactic={selectedTactic}
              onClickSubmit={saveGenericInfo}
            />
          </div>
        )}
      </div>

      {!isDefaultList && (
        <button
          className="btn btn-primary btn-retreive"
          disabled={
            tacticsToBeRetrieved && tacticsToBeRetrieved.length > 0
              ? false
              : true
          }
          onClick={() => {
            retrieveTactics();
          }}
        >
          {t("harvestedTactics.retrieveTactics")}
        </button>
      )}
    </>
  );
};

export default HarvestedTactics;
