//Import react dependecies
import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";

//Import from Utils
import {
  getClickableLink,
  globalAttributes,
  getAllChannelAttributes,
  getMlrStatusIcon,
  capitalizeString,
  getLockIcon,
  getChannelAttributes,
} from "../../../Uitls/Util";

//Import styles
import "../../../styles/components/MarketingMessages.scss";
import { isCTA, isMarketingMessageEditable } from "../MarketingMessageCard";
import ViewTextWithHeading from "../../../Uitls/ViewTextWithHeading";
import { isOptOut } from "../../ContentMatrix/SelectableMarketingMessageCard";

export function isSubjectline(marketingMessage) {
  return (
    marketingMessage?.attribute?.toLowerCase() ===
    getChannelAttributes(
      marketingMessage?.channel?.toLowerCase()
    )?.subjectLine?.toLowerCase()
  );
}
const ViewChannelAttribute = ({
  marketingMessage,
  startEditingMessage,
  startPublishing,
}) => {
  const { t } = useTranslation();

  const getModifiedStatus = (createdTime, updatedTime) => {
    let edited;
    const beginningTime = moment(createdTime, "YYYY-MM-DD HH:mm:ss");
    const endTime = moment(updatedTime, "YYYY-MM-DD HH:mm:ss");
    edited = beginningTime.isBefore(endTime);
    return edited;
  };

  const mlrStatusIcon = (marketingMessage) => {
    return (
      <img src={getMlrStatusIcon(marketingMessage?.mlrStatus)} alt=""></img>
    );
  };

  const isSubjectline = () => {
    return (
      marketingMessage?.attribute?.toLowerCase() ===
      getChannelAttributes(
        marketingMessage?.channel?.toLowerCase()
      )?.subjectLine?.toLowerCase()
    );
  };

  const isPreheader = () => {
    return (
      marketingMessage?.attribute?.toLowerCase() ===
      getChannelAttributes(
        marketingMessage?.channel?.toLowerCase()
      )?.preheader?.toLowerCase()
    );
  };
  const isHeader = () => {
    return (
      marketingMessage?.attribute?.toLowerCase() ===
      getChannelAttributes(
        marketingMessage?.channel?.toLowerCase()
      )?.header?.toLowerCase()
    );
  };
  const isTagline = () => {
    return (
      marketingMessage?.attribute?.toLowerCase() ===
      getChannelAttributes(
        marketingMessage?.channel?.toLowerCase()
      )?.tagLine?.toLowerCase()
    );
  };
  return (
    <>
      <div
        className={
          (!marketingMessage?.mlrStatus
            ? "marketing-msg-card-background-not-submitted-to-dams"
            : "") +
          " " +
          "marketing-msg-card marketing-message-card-margin-top"
        }
      >
        <Row>
          <Col className="col-md-6" style={{ textAlign: "left" }}>
            {marketingMessage?.documentNumber ? "Veeva ID:" : ""}{" "}
            <span className="metadata">{marketingMessage?.documentNumber}</span>
          </Col>
          <Col className="col-md-6">
            <div className="status-and-lock">
              <span className="mlr-status">
                {mlrStatusIcon(marketingMessage)}{" "}
                {marketingMessage?.mlrStatus || "Not Submitted"}
              </span>
            </div>
          </Col>
        </Row>

        {isSubjectline(marketingMessage) && (
          <>
            <ViewTextWithHeading
              heading=""
              text={parse(marketingMessage?.text || "")}
            />
            {marketingMessage?.disclaimer &&
              marketingMessage?.disclaimer?.trim().length > 0 && (
                <ViewTextWithHeading
                  heading="Disclaimer"
                  text={parse(marketingMessage?.disclaimer || "")}
                />
              )}
          </>
        )}

        {isPreheader() && (
          <ViewTextWithHeading
            heading=""
            text={parse(marketingMessage?.text || "")}
          />
        )}

        {isHeader() && (
          <>
            <ViewTextWithHeading
              heading="Message"
              text={parse(marketingMessage?.message || "")}
            />
            <ViewTextWithHeading
              heading="Restrictions for use"
              text={parse(marketingMessage?.restriction || "")}
            />
            <ViewTextWithHeading
              heading="Rules for header"
              text={parse(marketingMessage?.rule || "")}
            />
          </>
        )}

        {isTagline() && (
          <ViewTextWithHeading
            heading=""
            text={parse(marketingMessage?.text || "")}
          />
        )}

        {isCTA(marketingMessage) && (
          <>
            <ViewTextWithHeading
              heading="CTA link"
              text={marketingMessage?.url}
            />

            <ViewTextWithHeading
              heading="Labels"
              text={
                <ol type="1" className="cta-labels">
                  {marketingMessage.labels.map((label) => {
                    return (
                      <li>
                        <div>{label}</div>
                      </li>
                    );
                  })}
                </ol>
              }
            />
          </>
        )}

        {isOptOut(marketingMessage) && (
          <ViewTextWithHeading
            heading=""
            text={parse(marketingMessage?.text || "")}
          />
        )}

        <div class="d-flex justify-content-between">
          <span className="cardMetaData">
            ID: <strong>{marketingMessage?.id}</strong>
          </span>{" "}
          <span className="cardMetaData">
            {marketingMessage && isCTA(marketingMessage)
              ? "Global Attribute: "
              : "Attribute: "}{" "}
            <strong>{marketingMessage?.attribute}</strong>
          </span>
          <span className="cardMetaData">
            Type: <strong>{marketingMessage?.type}</strong>
          </span>
        </div>
      </div>
    </>
  );
};

export default ViewChannelAttribute;
