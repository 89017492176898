//Import React dependency
import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
//Import custom compoenents
import {} from "../../services/apis";
import GenericBusinessRulesBox from "./GenericBusinessRulesBox";
//import styles

//import images
import BackArrow from "../../images/back_arrow.svg";
import i18next from "i18next";

const ShowBussinessRules = ({
  businessRulesData,
  campaign,
  moduleNameAndCampaign,
  handleCloseCreateModuleModal,
  ...props
}) => {
  const updatedUsageGuidelines =
    businessRulesData[0].usageInstructionsGuidelines &&
    parse(businessRulesData[0].usageInstructionsGuidelines);
  const categories = businessRulesData[0].ruleCategories || [];
  const formattedDataMust = categories[0]?.ruleConditions.filter(function (
    item
  ) {
    return item.ruleCondition === "MustExistWith";
  });
  const formattedDataInCompatible = categories[0]?.ruleConditions.filter(
    function (item) {
      return item.ruleCondition === "InCompatibleWith";
    }
  );
  const formattedDataPrecedes = categories[0]?.ruleConditions.filter(function (
    item
  ) {
    return item.ruleCondition === "Precedes";
  });
  const formattedDataSucceeds = categories[0]?.ruleConditions.filter(function (
    item
  ) {
    return item.ruleCondition === "Succeeds";
  });
  const formattedDataRelatedTo = categories[0]?.ruleConditions.filter(function (
    item
  ) {
    return item.ruleCondition === "RelatedTo";
  });
  return (
    <>
      <h1 className="createModalHeading">
        <img
          src={BackArrow}
          alt="Back"
          onClick={props.handleBack}
          style={{ cursor: "pointer" }}
        />
        <span style={{ paddingLeft: "10px" }}>View Business Rules</span>
        <button
          className="btn btn-link text-uppercase bussinessrules-btn-close"
          onClick={() => handleCloseCreateModuleModal()}
        >
          CLOSE
        </button>
        <h2 className="businessRulesSubTitle">Campaign Name : {campaign}</h2>
      </h1>
      <h2 className="createModalSubHeading">
        {moduleNameAndCampaign.moduleCode} | {moduleNameAndCampaign.name}
      </h2>
      {/* <h2 className="businessRuleSubHeading">
        Module {moduleNameAndCampaign.moduleCode} |{" "}
        {moduleNameAndCampaign.campaign}
      </h2> */}
      <Row className="custonScrollBar mt-4" style={{ overflowY: "scroll" }}>
        <Col xs="12" md="9" lg="9" className="businessRulesBrdr">
          <Row>
            <GenericBusinessRulesBox
              boxKey="MustExistWith"
              boxTitle="Must Exist With"
              data={
                (formattedDataMust && formattedDataMust[0]?.ruleModules) || []
              }
            />

            <GenericBusinessRulesBox
              boxKey="InCompatibleWith"
              boxTitle="Cannot Exist With"
              data={
                (formattedDataInCompatible &&
                  formattedDataInCompatible[0]?.ruleModules) ||
                []
              }
            />

            <GenericBusinessRulesBox
              boxKey="Precedes"
              boxTitle="Precedes"
              data={
                (formattedDataPrecedes &&
                  formattedDataPrecedes[0]?.ruleModules) ||
                []
              }
            />

            <GenericBusinessRulesBox
              boxKey="Succeeds"
              boxTitle="Succeeds"
              data={
                (formattedDataSucceeds &&
                  formattedDataSucceeds[0]?.ruleModules) ||
                []
              }
              customCName="rulexBox0"
            />

            <GenericBusinessRulesBox
              boxKey="RelatedTo"
              boxTitle="Related Modules"
              data={
                (formattedDataRelatedTo &&
                  formattedDataRelatedTo[0]?.ruleModules) ||
                []
              }
              customCName="rulexBox0"
            />
          </Row>
        </Col>
        <Col xs="12" md="3" lg="3" className="pt-1">
          {businessRulesData &&
            businessRulesData[0].usageInstructionsGuidelines && (
              <span className="usage-label">
                {i18next.t("additionalInfo.usageOrGuidelines")}
              </span>
            )}

          <div className="usageBox custonScrollBar">
            {updatedUsageGuidelines}
            {businessRulesData &&
            businessRulesData[0].usageInstructionsGuidelines ? (
              ""
            ) : (
              <div className="label-verticle">
                {i18next.t("additionalInfo.usageOrGuidelines")}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ShowBussinessRules;
