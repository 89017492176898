import React from "react";
import parse from "html-react-parser";
import OverlayPopOver from "../../Uitls/OverlayPopOver";
const SelectedModulesPreview = ({ title, data, attribute }) => {
  return (
    <div className="Channel-grid-sub-pre-preview">
      <span className="channel-type-head">
        {title} ({`${data.length}`})
      </span>
      <table className="table-style">
        <tbody>
          <tr className="header">
            <td>MODULE MANAGER ID</td>
            <td>DAMS ID</td>
            <td>MLR STATUS</td>
            <td>PROMOTIONAL CLAIM</td>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={item.id}>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.moduleCode}{" "}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.damsId || "-"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.mlrStatus || "Not Submitted"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "55%" }}>
                  {/* {item.primaryClaim?.length > 0 ? (
                    <OverlayPopOver
                      element={
                        <div className="ellipsis">
                          {parse(item.primaryClaim || "")}
                        </div>
                      }
                      overlayData={parse(item.primaryClaim || "")}
                      trigger={"hover"}
                      classId="composite-asset-hover"
                      placement="bottom"
                    />
                  ) : (
                    "-"
                  )} */}
                  {parse(item.primaryClaim || "-")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default SelectedModulesPreview;
