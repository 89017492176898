import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@material-ui/core/TextField";
import closeIcon from "../../../images/clear_text.svg";
import DropDownWithLabel from "../../../components/DropDownWithLabel";

import "../../../styles/pages/UplaodMasterAsset.scss";
import "../../../styles/CustomToastify.scss";
import "../../../styles/components/DenovoModules.scss";
import { Form } from "react-bootstrap";

const ModuleGenericDetails = ({ module, close }) => {
  const [audienceDataSource, setAudienceDataSource] = useState([
    "Consumer",
    "HCP",
    "Payer",
  ]);
  const { t } = useTranslation();

  const isThisAudienceAdded = (audience) => {
    if (audience) {
      const addedAudience = module?.audience;
      if (
        addedAudience &&
        addedAudience.findIndex(
          (aud) => aud.trim().toLowerCase() === audience.trim().toLowerCase()
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const addCloseButton = () => {
    return (
      <button
        className="btn btn-link p-0 m-0 btn-close-gen-details"
        onClick={() => {
          if (close) {
            close();
          }
        }}
      >
        <img src={closeIcon} alt="" width="25" />
      </button>
    );
  };

  return (
    <>
      <div>
        <div className="col-md-12">
          <div>
            <div>
              <h2
                className="masterTacticTitle assetDetailColTitle "
                style={{ paddingLeft: "0px" }}
              >
                <span
                  className="moduleNormalShow"
                  style={{ fontWeight: "600" }}
                >
                  {module.name}
                </span>
              </h2>
              {addCloseButton()}
            </div>
            <p
              class="search-items"
              style={{ fontSize: "14px", paddingLeft: "0px", marginTop: "0px" }}
            >
              Metadata Information
            </p>
          </div>

          <div className="row">
            <div className="col-md-6 ">
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.brand"),
                  label: t("uploadAssetLabel.brand"),
                  value: module.brandName,
                  items: [module.brandName],
                  disabled: true,
                }}
              />
              <TextField
                className="add-geninfo-textfield master-asset-name"
                id="masterAssetName"
                label={t("uploadAssetLabel.masterAssetName")}
                variant="outlined"
                fullWidth={true}
                disabled={true}
                value={[`${module.assetName}`]}
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.channelTypeItWasUsed"),
                  label: t("uploadAssetLabel.channelTypeItWasUsed"),
                  value: module.channelType,
                  items: [module.channelType],
                  disabled: true,
                }}
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.campaignItWasUsed"),
                  label: t("uploadAssetLabel.campaignItWasUsed"),
                  value: module.campaignName,
                  items: [module.campaignName],
                  disabled: true,
                }}
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.market"),
                  label: t("uploadAssetLabel.market"),
                  value: module.market,
                  items: [module.market],
                  disabled: true,
                }}
              />
              <TextField
                className="add-geninfo-textfield tf-and-drp-dwn-with-label-disabled"
                id="dosing"
                label={t("uploadAssetLabel.dosing")}
                variant="outlined"
                fullWidth={true}
                value={[`${module.dosing}`]}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield tf-and-drp-dwn-with-label-disabled"
                id="masterAssetLink"
                label="Master Asset Link"
                variant="outlined"
                fullWidth={true}
                //value={[`${module.dosing}`]}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield tf-and-drp-dwn-with-label-disabled"
                id="audienceSegment"
                label={t("uploadAssetLabel.audienceSegment")}
                variant="outlined"
                fullWidth={true}
                value={[`${module.audienceSegment}`]}
                disabled={true}
              />
            </div>

            <div className="col-md-6 mt-0.5">
              <TextField
                className="add-geninfo-textfield description description-height denovoDescription tf-and-drp-dwn-with-label-disabled"
                id="description"
                label={t("uploadAssetLabel.description")}
                variant="outlined"
                fullWidth={true}
                rows={9}
                rowsMax={9}
                multiline
                value={[`${module.description}`]}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield description description-height denovoDescription tf-and-drp-dwn-with-label-disabled"
                id="genericBusinessRules"
                label="Generic Business Rules"
                variant="outlined"
                fullWidth={true}
                rows={9}
                rowsMax={9}
                multiline
                value={[`${module.genericBusinessRules}`]}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield master-asset-name"
                id="isi"
                label="ISI"
                variant="outlined"
                fullWidth={true}
                value={module.isi}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield master-asset-name"
                id="pi"
                label="PI"
                variant="outlined"
                fullWidth={true}
                value={module.pi}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield master-asset-name"
                id="mg"
                label="MG"
                variant="outlined"
                fullWidth={true}
                value={module.mg}
                disabled={true}
              />
              <TextField
                className="add-geninfo-textfield tf-and-drp-dwn-with-label-disabled"
                id="therapeuticArea"
                label="Therapeutic Area"
                variant="outlined"
                fullWidth={true}
                value={[`${module.therapeuticArea}`]}
                disabled={true}
              />
            </div>

            <div className="col-md-6 mb-3">
              <div className="audience">
                <span className="audience">
                  {t("uploadAssetLabel.audience")}
                </span>
              </div>

              <div>
                {audienceDataSource.map((item) => {
                  return (
                    <div
                      className="custom-control custom-checkbox custom-control-inline aud-check-box-with-label"
                      key={item}
                    >
                      <input
                        type="checkbox"
                        name={item}
                        className="custom-control-input audience-check-box"
                        id={item}
                        checked={isThisAudienceAdded(item)}
                        disabled={true}
                      ></input>
                      <label
                        className="custom-control-label aud-check-label"
                        htmlFor={item}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            {module.assetType && (
              <div className="col-md-6">
                <div className="asset-types">
                  <Form>
                    {["radio"].map((type) => (
                      <div
                        key={`inline-${type}`}
                        className="form-check form-check-inline mb-2"
                      >
                        <span
                          className="title mr-5"
                          style={{
                            opacity: "0.5",
                          }}
                        >
                          Asset Type
                        </span>
                        <Form.Check
                          inline
                          label="Branded"
                          name="assetTypeOption"
                          type={type}
                          id={`inline-${type}-1`}
                          value="branded"
                          checked={module.assetType === "branded"}
                          disabled={true}
                        />
                        <Form.Check
                          inline
                          label="Unbranded"
                          name="assetTypeOption"
                          type={type}
                          id={`inline-${type}-2`}
                          value="unbranded"
                          checked={module.assetType === "unbranded"}
                          disabled={true}
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleGenericDetails;
