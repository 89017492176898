//Import React dependency
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//Import custom compoenents
import ModuleCard from "../ModuleCard";
import ErrComponentToRender from "../../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";
import LockUnlockAlertModal from "../LockUnlockAlertModal";
import CustomModal from "../../../components/customModal";
//Import apis
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
} from "../../../Uitls/Util";

import {
  lockAssetOrModule,
  getModuleDetailsById,
} from "../../../services/apis";

//import styles
import "../../../styles/components/ExistingTatics.scss";
import CreateNewModuleCard from "../CreateNewModuleCard";

//import images

const Modules = ({
  createNewCardMessage,
  asset,
  assetModules,
  createNewModule,
  selected,
  selectModule,
  mainModules,
  auxModules,
  auxTitle,
  filteredPages,
  typeofModule,
  disableCreateNew,
  stylizeModule,
  assetDataType,
}) => {
  let history = useHistory();
  const [modules, setModules] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [lockUnlockAlertModal, setLockUnlockAlertModal] = useState(false);
  const [approvalData, setApprovalData] = useState(undefined);

  useEffect(() => {
    if (mainModules) {
      setModules(mainModules);
    } else if (assetModules) {
      setModules(assetModules);
    } else {
      setModules([]);
    }
  }, [assetModules, mainModules]);

  const scrollToSelectedCard = () => {
    if (selected) {
      setTimeout(() => {
        var element = document.getElementById(selected.moduleCode);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
      }, 1);
    } else {
      var element = document.getElementById("create-new-module-card");
      if (element) {
        element.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    }
  };

  const drawAuxModulesHeading = (isDefault = true) => {
    return (
      <h2
        className="masterTacticTitle pl-3 assetDetailColTitle "
        style={{ marginTop: "20px" }}
      >
        <span className="moduleNormalShow" style={{ fontWeight: "600" }}>
          {auxTitle} ({auxModules.length})
        </span>
      </h2>
    );
  };

  const unlockAssetOrmoduleErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const unLockModule = (moduleId, lockStatus) => {
    const data = {
      locked: lockStatus === "Locked" ? "Unlocked" : "Locked",
      type: "Module",
      typeId: moduleId,
    };
    setApprovalData(data);
    setLockUnlockAlertModal(true);
  };
  const unlockModuleAndAsset = () => {
    setLockUnlockAlertModal(false);
    lockAssetOrModule(approvalData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        unlockAssetOrmoduleErr(errCoponent);
        setApprovalData(undefined);
      } else {
        toast.success(
          <div style={{ color: "#000" }}>
            {approvalData.locked === "Unlocked"
              ? `Request sent to Un-lock the ${
                  approvalData.type === "Asset" ? "Tactic" : "Module"
                }`
              : `{approvalData.type === "Asset" ? "Tactic" : "Module"}{" "}
            {approvalData.locked}`}
          </div>,
          {
            autoClose: 2000,
            position: "top-center",
          }
        );
        setApprovalData(undefined);
        console.log(response.data);
      }
    });
  };
  const handleCloseAlertModal = () => {
    setLockUnlockAlertModal(false);
    setApprovalData(undefined);
  };
  const viewModuleDetail = (moduleId, isModuleLocked) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        isLocked: isModuleLocked,
        sourceType: asset?.sourceType,
        assetDataType: assetDataType,
      },
    });
  };

  const modifyModule = (id) => {
    setIsloading(true);
    getModuleDetailsById(id, (response, err, errCode) => {
      if (err) {
        setIsloading(false);
        const errCoponent = ErrComponentToRender(errCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        setIsloading(false);
        let pageCount = "";
        removeModuleInfo();
        updateModuleInfo({
          assetId: response.data.storageAssetId,
          moduleId: response.data.id,
          moduleName: response.data.name,
          moduleCode: response.data.moduleCode,
          moduleType: "editModule",
          numberOfPages: pageCount,
          creationMethodType: response.data.creationMethodType
            ? response.data.creationMethodType.toLowerCase()
            : undefined,
        });
        history.push({
          pathname: routes.addPrimaryClaim,
          state: {
            filteredPages,
            brandName: response.data.brandName,
          },
        });
      }
    });
  };

  const translateModule = (moduleId) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        flowType: "Translate",
      },
    });
  };
  const localizeModule = (moduleId) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        flowType: "Localize",
      },
    });
  };

  const getModuleCard = (module) => {
    return (
      <ModuleCard
        module={module}
        colWidth={selected ? 12 : 6}
        assetLockStatus={asset.locked}
        selectModule={selectModule}
        isSelected={selected && selected.id === module.id ? true : false}
        unLockModule={unLockModule}
        viewModuleDetail={viewModuleDetail}
        modifyModule={modifyModule}
        translateModule={translateModule}
        localizeModule={localizeModule}
        typeofModule={typeofModule}
        stylizeModule={stylizeModule}
      />
    );
  };

  return (
    <>
      <div className="row pl-3 existingTacticsModDetails custonScrollBar">
        <CircularProgressBar isloading={isloading} />
        {asset && asset.locked?.toLowerCase() === "unlocked" && !selected && (
          <CreateNewModuleCard
            content={
              <span
                className={`${
                  disableCreateNew
                    ? "create-new-module-card-message-disabled"
                    : ""
                }`}
              >
                {createNewCardMessage
                  ? createNewCardMessage
                  : "Create New Module"}
              </span>
            }
            colWidth={6}
            onClickCreateCard={createNewModule}
            shouldBeDisbaled={disableCreateNew}
          />
        )}

        {modules &&
          modules.map((module, index) => {
            scrollToSelectedCard();
            return getModuleCard(module);
          })}

        {/* IF Aux Modules present */}
        {selected &&
          auxModules &&
          auxModules.length > 0 &&
          auxTitle &&
          drawAuxModulesHeading(false)}
        {auxModules &&
          auxModules.map((module, index) => {
            scrollToSelectedCard();
            return getModuleCard(module);
          })}
      </div>
      <CustomModal
        displayModal={lockUnlockAlertModal}
        hideModal={handleCloseAlertModal}
        size="md"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <LockUnlockAlertModal
          approvalData={approvalData}
          handleYes={unlockModuleAndAsset}
          handleNo={handleCloseAlertModal}
        />
      </CustomModal>
    </>
  );
};

export default Modules;
