import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import searchIcon from "../../images/masterTacticSearchIcon.svg";
import clearTextIcon from "../../images/clear_text.svg";
import "../../styles/components/ExistingTatics.scss";

const SearchTacticsAndModules = (props) => {
  const {
    setTitleType,
    startSearching,
    searchBarPlaceholder,
    clearAllSearch,
    filteredBrandList,
    filteredChannelList,
    filteredMarketList,
    campaignFilterList,
    languageFilterList,
    removeitemFromBrandFilteredList,
    removeitemFromFilteredListChannel,
    removeitemFromFilteredListMarket,
    removeitemFromFilteredCampaignList,
    removeFromFilteredLanguageList,
    brandErrorFlag,
    isLocalizedModulesSelected,
    isSearchGroupChecked,
    setIsSearchGroupChecked,
    isModularisedTacticsSelected,
    isDenovo,
  } = props;
  const { t } = useTranslation();
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);

  useEffect(() => {
    const element = document.getElementById("search-tactics-from-icb");
    if (element && element.value.trim().length === 0) {
      setIsSearchBarEmpty(true);
      //setTitleType("initial");
    }
  }, [props]);

  const clearSearchBar = () => {
    const element = document.getElementById("search-tactics-from-icb");
    if (element) {
      element.value = "";
      setIsSearchBarEmpty(true);
      //setTitleType("initial");
    }
  };

  // const shouldEnableSearch = () => {
  //   if (isLocalizedModulesSelected) {
  //     if (
  //       filteredBrandList &&
  //       filteredBrandList.length > 0 &&
  //       campaignFilterList &&
  //       campaignFilterList.length > 0
  //     ) {
  //       return true;
  //     }
  //   } else {
  //     if (
  //       (filteredBrandList && filteredBrandList.length > 0) ||
  //       (filteredChannelList && filteredChannelList.length > 0) ||
  //       (filteredMarketList && filteredMarketList.length > 0) ||
  //       document.getElementById("search-tactics-from-icb")?.value.trim()
  //         .length > 0
  //     ) {
  //       return true;
  //     }
  //   }
  //   // if (
  //   //   isModularisedTacticsSelected ||
  //   //   (isDenovo && !isLocalizedModulesSelected)
  //   // )
  // };

  const renderSearchTactics = () => {
    return (
      <div className="d-flex">
        <div className="search-icb-tactics search-icb-tactic">
          <div className="d-flex align-items-center">
            <img src={searchIcon} alt="" className="search-icon-icb-tactic" />
            <input
              id="search-tactics-from-icb"
              type="text"
              onKeyUp={(e) => {
                if (e.key === "Enter" && !isSearchBarEmpty && !brandErrorFlag) {
                  if (e.target.value.trim().length > 0) {
                    startSearching();
                  }
                }
              }}
              placeholder={searchBarPlaceholder}
              className="search-input-icb-tactic"
              onChange={(event) => {
                const value = event.target.value;
                if (value && value.trim().length > 0) {
                  setIsSearchBarEmpty(false);
                  return;
                }
                setIsSearchBarEmpty(true);
              }}
              autoComplete="off"
            />
            {!isSearchBarEmpty && (
              <img
                src={clearTextIcon}
                alt=""
                className="close-icon"
                onClick={(event) => {
                  clearSearchBar();
                }}
              />
            )}

            {isLocalizedModulesSelected && (
              <div class="form-check search-groups-only">
                <input
                  class="form-check-input searchGroup"
                  type="checkbox"
                  value=""
                  id="searchGroupOnly"
                  //checked={isSearchGroupChecked}
                  onChange={(event) => {
                    setIsSearchGroupChecked(event.target.checked);
                  }}
                />
                <label class="form-check-label" for="searchGroupOnly">
                  {t("generalText.searchGroupsOnly")}
                </label>
              </div>
            )}
          </div>
        </div>

        <button
          className="btn btn-primary btn-search-tactic"
          onClick={(event) => {
            startSearching();
          }}
          // disabled={isSearchBarEmpty || brandErrorFlag? "disable" : ""}
          disabled={isSearchBarEmpty || brandErrorFlag}
          //disabled={!shouldEnableSearch()}
        >
          {" "}
          search
        </button>
        <button
          className="btn btn-link clear-search"
          onClick={(event) => {
            clearAllSearch(event);
            //clearSearchBar();
          }}
        >
          {" "}
          {t("generalText.resetAll").toUpperCase()}
        </button>
      </div>
    );
  };

  return (
    <div className="masterTacticModule">
      {renderSearchTactics()}
      {brandErrorFlag && (
        <div className="error-message">
          Product/Brand and Campaign must be selected for Searching Modules
        </div>
      )}
      <div className="col-md-12 selected-filterlist">
        {filteredBrandList.map((item, index) => {
          return (
            <button
              className="btn selectedBrandsChip mr-1"
              onClick={() => removeitemFromBrandFilteredList(item)}
              key={index}
            >
              {item} <span className="closeChipSymbol">X</span>
            </button>
          );
        })}

        {filteredChannelList?.map((item, index) => {
          return (
            <button
              className="btn selectedBrandsChip mr-1"
              onClick={() => removeitemFromFilteredListChannel(item)}
              key={index}
            >
              {item} <span className="closeChipSymbol">X</span>
            </button>
          );
        })}
        {filteredMarketList &&
          filteredMarketList.map((item, index) => {
            return (
              <button
                className="btn selectedBrandsChip mr-1"
                onClick={() => removeitemFromFilteredListMarket(item)}
                key={index}
              >
                {item} <span className="closeChipSymbol">X</span>
              </button>
            );
          })}

        {campaignFilterList &&
          campaignFilterList.map((item, index) => {
            return (
              <button
                className="btn selectedBrandsChip mr-1"
                onClick={() => removeitemFromFilteredCampaignList(item)}
                key={index}
              >
                {item} <span className="closeChipSymbol">X</span>
              </button>
            );
          })}
        {languageFilterList &&
          languageFilterList.map((item, index) => {
            return (
              <button
                className="btn selectedBrandsChip mr-1"
                onClick={() => removeFromFilteredLanguageList(item)}
                key={index}
              >
                {item} <span className="closeChipSymbol">X</span>
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default SearchTacticsAndModules;
