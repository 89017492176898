import React from "react";

import closeIcon from "../../../images/closeIcon.svg";
import { templateTypes } from "../../../Uitls/Util";

const TemplateDetail = ({
  templateType,
  template,
  closeTemplateDetail,
  sourceType = "Veeva",
  fromPreviewPage = false,
}) => {
  const getHeading = () => {
    if (templateType.type === templateTypes.emailTemplate.type) {
      return templateTypes.emailTemplate.getType();
    } else if (templateType.type === templateTypes.sampleEmail.type) {
      return templateTypes.sampleEmail.getType();
    }
  };
  return (
    <div className="template-detail-container">
      <div className={`${!fromPreviewPage ? "heading" : "pb-5"}`}>
        {!fromPreviewPage && <span>{getHeading()} Preview</span>}
        <img
          src={closeIcon}
          alt=""
          onClick={() => {
            closeTemplateDetail(undefined);
          }}
          width="20"
          className="close-icon"
        ></img>
      </div>
      {!fromPreviewPage && (
        <div className="d-flex template-ids">
          <div style={{ marginRight: "64px" }}>
            <div className="template-card-id-label prev-template-id-label">
              Name
            </div>
            <div className="template-card-id ml-1 prev-template-id-value">
              {template?.name}
            </div>
          </div>

          <div style={{ marginRight: "64px" }}>
            <div className="template-card-id-label prev-template-id-label text-right">
              ID
            </div>
            <div className="template-card-id prev-template-id-value">
              {template?.serial_no}
            </div>
          </div>
          <div>
            <div className="template-card-id-label prev-template-id-label text-right">
              {sourceType} ID
            </div>
            <div className="template-card-id prev-template-id-value">
              {template?.dams?.pdf_no
                ? template.dams.pdf_no
                : template?.dams?.html_no
                ? template.dams.html_no
                : "-"}
            </div>
          </div>
        </div>
      )}
      <div className="temlate-detail customScrollBar">
        <img
          src={template?.fullscreen ? template.fullscreen : template?.image}
          alt=""
          className="image"
        ></img>
      </div>
    </div>
  );
};

export default TemplateDetail;
