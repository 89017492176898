import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function DefaultApiErrorModal(props) {
  const { t } = useTranslation();
  return <p className="notificationText">{t("apiErrors.errDefault")}</p>;
}

export default DefaultApiErrorModal;
