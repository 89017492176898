import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  StylesProvider,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextareaAutosize } from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 11,
    color: "#333",
    padding: "0 26px 0 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 8,
      borderColor: "#80bdff",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  default: {
    width: "100%"
  },

  item: {
    height: 60,
    border: "1px solid lightgray",
    whiteSpace: "normal",
    maxWidth: "100%",
  },
  itemDefault: {
    height: 60,
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState("");

  const handleChange = (event) => {
    //setSelectedItem(event.target.value);
    setSelectedItem(
      props.data.filter((item) => {
        if (item) {
          return item.name === event.target.value;
        }
      })[0]
    );
  };

  useEffect(() => {
    setSelectedItem("base");
  });

  useEffect(() => {
    props.handleSelection(selectedItem);
  }, [selectedItem]);
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className} />;
  };
  return (
    <div>
      <FormControl className={(classes.margin, classes.default)}>
        <Select

          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={props.value || selectedItem}
          //value={selectedItem}
          onChange={handleChange}
          input={<BootstrapInput />}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={iconComponent}
        >
          <MenuItem value="base" key="base" className="selectCategory">
            {props.default}
          </MenuItem>
          {props.data.map((item) => {
            if (item) {
              return (
                <MenuItem
                  value={item.name}
                  key={item.id ? item.id : item.name}
                  className="selectCategory"
                >
                  {item.name}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
}
