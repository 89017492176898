import React, { useState } from "react";

import Catchlines from "./TranslateCatchlines";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

import commentIcon from "../../../images/icon-comment.svg";

import "../../../styles/pages/CreateModuleStep4.scss";
import "../../../styles/components/CTA.scss";

const TranslateCTA = ({
  ctas,
  isAnyToggleOn,
  initiateTranslationFor,
  addComment,
  module,
  getCommentsData,
}) => {
  //for Comments
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [comments, setComments] = useState([]);
  const [rowIndex, setRowIndex] = useState(-1);
  const [selectedComponenetId, setSelectedComponenetId] = useState(undefined);
  const [primaryComponentRowIndex, setPrimaryComponentRowIndex] = useState(-1);

  const consolidateComments = (primaryComponentId, componentId) => {
    if (!module || !module.comments) {
      return [];
    }
    const allComments = module.comments;
    return allComments.filter((comment) => {
      return (
        comment.primaryComponentId === primaryComponentId &&
        comment.componentId === componentId
      );
    });
  };
  const getCommentBubbleFor = (boxName, primaryComponentId, componentId) => {
    const comments = consolidateComments(primaryComponentId, componentId);
    if (!comments || comments.length === 0) {
      return;
    }
    return (
      <div
        style={{
          float: "right",
          marginRight: "0px",
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          style={{ margin: "-5px", cursor: "pointer" }}
          onClick={() => {
            let ctaIndex = ctas.findIndex((cta) => {
              return cta.id === primaryComponentId;
            });
            // const currentCTA = ctas.filter((cta) => {
            //   return cta.id === primaryComponentId;
            // })[0];
            const currentCTA = ctas[ctaIndex];
            const textIndex = currentCTA.texts.findIndex((text) => {
              return text.id === componentId;
            });
            setRowIndex(textIndex);
            if (
              componentId === selectedComponenetId &&
              textIndex === rowIndex &&
              showCommentsDialog
            ) {
              setSelectedComponenetId(undefined);
              setPrimaryComponentRowIndex(-1);
            } else {
              setSelectedComponenetId(componentId);
              setPrimaryComponentRowIndex(ctaIndex);
            }
            if (comments && comments.length > 0) {
              setComments(() => {
                setShowCommentsDialog(
                  textIndex === rowIndex && componentId === selectedComponenetId
                    ? !showCommentsDialog
                    : true
                );
              });
              setComments(comments);
            }
          }}
        />
      </div>
    );
  };
  return (
    <div className="banner">
      <div className="title banner-and-alert-title">CTA ({ctas.length})</div>
      <div>
        {ctas &&
          ctas.length > 0 &&
          ctas.map((cta, index) => {
            return (
              <div
                id={cta.ctaID || cta.id}
                key={cta.ctaID || cta.id}
                className="cta-item"
              >
                <div className="cta-url">{cta.url}</div>

                <Catchlines
                  catchlines={
                    cta.texts && cta.texts.length > 0 ? cta.texts : []
                  }
                  forCTAs={true}
                  isAnyToggleOn={isAnyToggleOn}
                  initiateTranslationFor={initiateTranslationFor}
                  getCommentBubbleFor={(boxName, componentId) => {
                    return getCommentBubbleFor(boxName, cta.id, componentId);
                  }}
                  addComment={(commentObject) => {
                    if (commentObject) {
                      if (commentObject.primaryComponentId) {
                        commentObject.primaryComponentId = cta.id;
                      } else {
                        commentObject["primaryComponentId"] = cta.id;
                      }
                      addComment(commentObject);
                    }
                  }}
                  comments={comments}
                  showCommentsFlag={showCommentsDialog}
                  setShowCommentsFlag={setShowCommentsDialog}
                  rowIndex={rowIndex}
                  componentId={selectedComponenetId}
                  primaryComponentRowIndex={primaryComponentRowIndex}
                  getCommentsData={getCommentsData}
                ></Catchlines>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TranslateCTA;
