import React, { useState } from "react";
import CustomModal from "../../components/customModal";
import expandIcon from "../../images/fullScreenIcon.svg";
import modalCloseIcon from "../../images/modalCloseIcon.svg";
const ModuleCard = ({ onSelected, tacticsToBeRetrieved, tactic }) => {
  const [expandedTacticModal, setExpandedTacticModal] = useState(false);
  const hideExpandedTacticModal = () => {
    setExpandedTacticModal(false);
  };
  const [tacticThumbUrl, setTacticThumbUrl] = useState("");
  const showExpandedTacticModal = (event, image) => {
    event.stopPropagation();
    setTacticThumbUrl(image);
    setExpandedTacticModal(true);
  };
  const handleAddSelectClass = (externalAssetId) => {
    let selectedTactic = [];
    tacticsToBeRetrieved &&
      tacticsToBeRetrieved.forEach((element, index) => {
        selectedTactic.push(element.externalAssetId);
      });
    let result = selectedTactic.includes(externalAssetId);
    return result;
  };
  return (
    <>
      {tactic && (
        <div
          className="col-md-4"
          onClick={(event) => {
            onSelected(tactic);
          }}
        >
          <div
            className={`row ml-0 mr-0 master-asset-block-dams ${
              handleAddSelectClass(tactic.externalAssetId) === true
                ? "assetCardSelected"
                : ""
            } ${tactic.tacticRetrievedFlag ? "tactic-dams-alert" : ""}`}
          >
            <div className="masterAssePdfPreveiw col-md-5">
              {tactic.assetThumbnailUrl && (
                <>
                  <div className="expandIcon">
                    <img
                      src={expandIcon}
                      alt=""
                      onClick={(e) => {
                        showExpandedTacticModal(
                          e,
                          tactic.assetPreviewUrl || tactic.assetThumbnailUrl
                        );
                      }}
                    />
                  </div>
                  <div
                    className="mr-3 assetImgThumb"
                    style={{
                      backgroundImage: `url(${tactic.assetThumbnailUrl})`,
                    }}
                  >
                    {/* <img
                      src={tactic.assetThumbnailUrl}
                      alt=""
                      className="img-fluid tacticsAssetThumb"
                    /> */}
                  </div>
                </>
              )}
            </div>
            <div className="col-md-7">
              <p className="masterAssetTitle mb-1 ml-3">{tactic.assetName}</p>
            </div>
            {tactic.tacticRetrievedFlag && (
              <div className="row">
                <div className="col-12">
                  <span className="tactic-errormsg d-block ml-3">
                    Tactic already exists.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <CustomModal
        displayModal={expandedTacticModal}
        hideModal={hideExpandedTacticModal}
        title={"View Asset Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop="false"
      >
        <div className="assetImageModalBlock custonScrollBar">
          <img src={tacticThumbUrl} alt="" className="img-fluid" />
        </div>
        <button
          className="btn btn-link pl-0 imageModalCloseBtn"
          onClick={hideExpandedTacticModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
      </CustomModal>
    </>
  );
};
export default ModuleCard;
