//Import react dependencies
import React from "react";
import parse from "html-react-parser";
import { getMlrStatusIcon, isModularContent } from "../../../Uitls/Util";
//Import custom compnents
import TextContent from "./TextContent";

const SubjectLines = (props) => {
  const { subjectlinesElements, assetDataType } = props;
  return (
    <div className="channelTabTextContainer customScrollBar">
      {subjectlinesElements &&
        subjectlinesElements.map((item, index) => {
          return (
            <div className="channelTabTextboxBlock" key={index}>
              {!isModularContent(assetDataType) && (
                <div className=" mb-4 veevaIdMlrSatusRow">
                  {item.documentNumber && (
                    <div className=" mlrVeevaId" style={{ float: "left" }}>
                      <span>Veeva ID : </span> {item.documentNumber}
                    </div>
                  )}
                  <div style={{ float: "right" }}>
                    {item.mlrStatus && (
                      <img
                        src={getMlrStatusIcon(item.mlrStatus)}
                        alt="MLR Status"
                      />
                    )}{" "}
                    {item.mlrStatus || "Not Submitted"}
                  </div>
                </div>
              )}
              <div className={`${item.disclaimer ? "subjectline" : ""}`}>
                <TextContent
                  //heading={`Subject Line ${index + 1}`}
                  content={parse(item.text || "")}
                />
              </div>
              {item.disclaimer && (
                <TextContent
                  heading="Disclaimer"
                  content={parse(item.disclaimer || "")}
                />
              )}

              <div className="d-flex mb-2 justify-content-between veevaIdMlrSatusRow">
                <div className="mlrVeevaId">
                  <span>ID :</span>{" "}
                  {isModularContent(assetDataType)
                    ? item.cmDocumentNumber
                    : item.id}
                </div>
                {!isModularContent(assetDataType) && (
                  <div className="mlrVeevaId">
                    <span className="ml-3">Source :</span> {item.source}{" "}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SubjectLines;
