import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { renderToString } from "react-dom/server";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import CustomModal from "../components/customModal";
import ChannelSelectionComponent from "../components/ModuleEditor/ChannelSelectionModal";
import CtaSelectionScreen from "../components/ModuleEditor/CtaSelection";
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
  moduleTypes,
  getDownloadModuleCss,
  getCurrentUser,
  isoCountryTableName,
  awsRegion,
  imcLanguageTableName,
  getTranslationJobType,
  hasUserLoggedInAsReviewer,
  channelTypes,
  capitalizeString,
  downloadPdfFromByteArray,
  damsSources,
  filterOutGlobalMarket,
} from "../Uitls/Util";
import HeaderCreateModule from "../components/HeaderCreateModule";
import TranslateHeader from "../components/Headers/HeaderTranslateModule";
import ModuleDetailsNav from "../components/ModuleDetailsNav";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import {
  getAssetDetails,
  getModuleDetailsById,
  getModulesBusinessRules,
  downloadModule,
  getDenovoModulesByBrandAndCampaign,
  publishModuleToVvpm,
  getVeevaDownloadAnnotated,
  getCognitoSession,
  getCognitoIndentityCredentials,
  getBrandsAndCampaigns,
  createTranslationJob,
  translatorSubmitForReview,
  getExcludeModulesBusinessRules,
  getSourceModuleLanguages,
  getGroupsModulesLangauge,
  createTranslationDuplicationCheck,
  createTranslationGroup,
  getStylizedModuleForView,
  exportStylizedModule,
  getVeevaLatestStatus,
} from "../services/apis";
import LogoutCTA from "../Pages/LogoutCTA";
import CloneModule from "../components/CloneModule";
import LocalizeModule from "../components/Localized/BulkLocalizationModal";
import RateTranslator from "../components/RateTranslator";

import "../styles/pages/CreateModule.scss";
import "../styles/pages/MLRSubmissionPackage.scss";
import "../styles/components/ModuleDetails.scss";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import ModuleSpecificInfo from "../components/MlrSubmission/ModuleSpecificInfo";
import BusinessRulesSpecificInfo from "../components/MlrSubmission/BusinessRulesSpecificInfo";
import ClaimAndVisualsSpecificInfo from "../components/MlrSubmission/ClaimAndVisualsSpecificInfo";
import ChannelSpecificInfo from "../components/MlrSubmission/ChannelSpecificInfo";
import SupportingVisualsImage from "../components/MlrSubmission/SupportingVisualsImage";
import Tick from "../images/Tick.svg";
import successTick from "../images/translateTick.svg";
import ComponentSelection from "../components/TranslateFlow/ComponentSelection";
import TranslateModule from "../components/translateModule";
import GenericBussinessRules from "../components/Localized/genericBussinessModal";
import BulkLocalizationModal from "../components/Localized/BulkLocalizationModal";

// For Translation
import SelectSourceModuleLanguageModal from "../components/Localized/SelectSourceModuleLanguageModal";
import BulkModulesValidation from "../components/Localized/BulkModulesValidationModal";
import ContentMatrixModal from "../components/ContentMatrix/ContentMatrixModal";
import CompositeAssetView from "../components/ContentMatrix/CompositeAssets/CompositeAssetView";

import ImcLogo from "../images/NEXT-Module-logo.svg";
import pdfIcon from "../images/pdfDownloadIcon.svg";
import ApprovedIcon from "../images/mlr_status_approved.svg";
import { prepareModuleDataForEditing } from "../components/Dams/DamsHome";
import CheckBox from "../Uitls/CheckBox";
import closeButton from "../images/close_with_bg.svg";
import mlrStatusInDraft from "../images/mlr_status_draft.svg";
import mlrStatusApproved from "../images/mlr_status_approved.svg";
import mlrStatusAppChanges from "../images/mlr_status_approvedwithchanges.svg";
import mlrStatusReSubmit from "../images/mlr_status_revise_resubmit.svg";
import mlrStatusInReview from "../images/mlr_status_inreview.svg";
import mlrRefreshIcon from "../images/mlrRefreshIcon.svg";

var dynamo = require("dynamodb");
export const sortClaimsAndVisualsByRecordIndex = (data) => {
  data.sort((record1, record2) => {
    return parseInt(record1.record_index) < parseInt(record2.record_index)
      ? -1
      : 1;
  });
  return data;
};
const ModuleDetails = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [assetDetails, setAssetDetails] = useState({});
  const [moduleId, setModuleId] = useState(undefined);
  const [moduleDetails, setModuleDetails] = useState({});
  const [pages, setPages] = useState([]);
  const [filteredPages, setFiletredPages] = useState([]);
  const [bModules, setbModules] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [contentModuleJsx, setContentModuleJsx] = useState(undefined);
  const [moduleSpecificJsx, setModuleSpecificJsx] = useState(undefined);
  const [businessSpecificJsx, setBusinessSpecificJsx] = useState(undefined);
  const [claimsSpecificJsx, setClaimsSpecificJsx] = useState(undefined);
  const [channelSpecificJsx, setChannelSpecificJsx] = useState(undefined);
  const [visualImageSpecificJsx, setvisualImageSpecificJsx] =
    useState(undefined);
  const [brandId, setBrandId] = useState(undefined);
  const [showCloneModuleModal, setShowCloneModuleModal] = useState(false);
  const [showSelectLanguageModal, setShowSelectLanguageModal] = useState(false);
  const [showTranslatePopup, setShowTranslatePopup] = useState(false);
  const [showLocalizeModuleModal, setShowLocalizeModuleModal] = useState(false);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [languageDataSource, setLanguageDataSource] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [
    componentsSelectedforTranslation,
    setComponentsSelectedforTranslation,
  ] = useState([]);
  const [
    selectedComponentTypesForTranslation,
    setSelectedComponentTypesForTranslation,
  ] = useState([]);

  const [showRatingsModal, setShowRatingsModal] = useState(false);
  const [localizeBusinsessrulsModal, setLocalizeBusinsessrulsModal] =
    useState(false);
  const [bulkLocalizationModal, setBulkLocalizationModal] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [audienceDataSource, setAudienceDataSource] = useState([
    "Consumer",
    "HCP",
    "Payer",
  ]);
  const [languageDataSourceForLocalize, setLanguageDataSourceForLocalize] =
    useState([]);

  //Variables for Localization flow
  const [genericInfo, setGenericInfo] = useState(undefined);
  const [prevSelectedData, setPrevSelectedData] = useState(undefined);
  const [dataForTranslationCreationGroup, setDataForTranslationCreationGroup] =
    useState(undefined);
  const [
    selectedModuleIdForGroupCreation,
    setSelectedModuleIdForGroupCreation,
  ] = useState([]);

  //For Stylized Modules
  const [channelSelectionModal, setChannelSelectionModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [isStylisedModuleInitiated, setIsStylisedModuleInitiated] =
    useState(false);
  const [
    isStylisedModuleInitiatedfromview,
    setIsStylisedModuleInitiatedfromview,
  ] = useState(true);
  const [stylizeModuleChannelSelection, setStylizeModuleChannelSelection] =
    useState(false);
  const [
    stylizeChannelSelectionModalData,
    setstylizeChannelSelectionModalData,
  ] = useState(undefined);
  const [selectedTabIndex, setSelectedTabIndex] = useState(undefined);
  const [stylizedModules, setStylizedModules] = useState(undefined);
  const [selectedChannelType, setSelectedChannelType] = useState("Email");
  const [showExportOptions, setshowExportOptions] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    pdf: false,
    html: false,
  });

  //For mlr Status
  const [veevaDocId, setVeevaDocId] = useState("");
  const [mlrModuleStatus, setMlrModuleStatus] = useState("");

  const isTranslatorPreview = () => {
    if (props.location.state.flowType === "previewTranslate") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (props.location && props.location.state.rateTranslator === true) {
      setShowRatingsModal(true);
      history.replace({
        state: {
          moduleId: props.location.state.moduleId.toString(),
          rateTranslator: false,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (props && props.location && props.location.state) {
      const moduleId = props.location.state.moduleId;
      const brandId = props.location.state.brandId;
      onSelectSearchedModule(moduleId, brandId);
      let sourceType =
        props?.location?.state?.sourceType ||
        moduleDetails?.assetDetails?.sourceType;
      setIsloading(true);
      getStylizedModuleForView(moduleId, sourceType, (res, err) => {
        let data = res?.data;
        if (err) {
          showError(err);
          setSelectedTabIndex(1);
        } else {
          setStylizedModules(data);
          if (data.length > 0 && data[0].stylisedVersion) {
            setSelectedTabIndex(0);
          } else {
            setSelectedTabIndex(1);
          }
        }
        setIsloading(false);
      });
    }

    //removeModuleInfo();
  }, []);

  useEffect(() => {
    fetchBrandsAndCampaigns();

    if (props.location.state && props.location.state.flowType === "Translate") {
      if (moduleDetails && Object.keys(moduleDetails).length > 0) {
        translateModuleGroup(
          [
            {
              moduleId: moduleDetails.id,
              sourceModuleId: moduleDetails.sourceModuleId,
              language: moduleDetails.language,
            },
          ],
          {
            groupId: moduleDetails.translationGroup[0].translationGroupId,
          }
        );
        history.replace({
          state: {
            moduleId: props.location.state.moduleId.toString(),
            flowType: "",
          },
        });
      }
    }

    if (props.location.state && props.location.state.flowType === "Localize") {
      const moduleId = props.location.state.moduleId;

      initiateLocalization(moduleId);
      history.replace({
        state: {
          moduleId: props.location.state.moduleId.toString(),
          flowType: "",
        },
      });
    }
  }, [moduleDetails]);

  // <<------------------- For Translation : Code starts here ------------------------------>>

  const [moduleIdsTobeTranslated, setModuleIdsTobeTranslated] =
    useState(undefined);
  const [sourceModules, setSourceModules] = useState(undefined);
  const [groupToBeTranslatedData, setGroupToBeTranslatedData] = useState([]);
  const [modulesWithSourceLanguages, setModulesWithSourceLanguages] = useState(
    []
  );
  // const [showTranslatorSelectionMdal, setShowTranslatorSelectionMdal] =
  //   useState(false);

  const fetchSourceLanguageModules = (moduleIds, cb) => {
    const payload = moduleIds.map((obj) => {
      return obj.sourceModuleId;
    });
    setIsloading(true);
    getSourceModuleLanguages(payload, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        let sourceModules = response.data;
        let allowToSelectSourceModule = false;
        sourceModules = sourceModules.map((sourceModule) => {
          if (sourceModule.languages.length > 0) {
            allowToSelectSourceModule = true;
          }
          sourceModule.languages.unshift({
            language: sourceModule.language,
            moduleId: sourceModule.moduleId,
            isSelected: true,
          });
          return sourceModule;
        });

        cb(sourceModules, allowToSelectSourceModule);
        return;
      }
      const errCoponent = ErrComponentToRender(errCode);
      toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      cb(undefined);
    });
  };

  const handleShowSourceLanguageModal = (flag) => {
    setShowSelectLanguageModal(flag);
    if (!flag) {
      setModuleIdsTobeTranslated(undefined);
      setSourceModules([]);
    }
  };

  const handleShowTranslatorSelection = (
    moduleIds = moduleIdsTobeTranslated,
    modulesDetailsWithSourceLanguages
  ) => {
    fetchMarketData("translate", (isFetched) => {
      fetchLanguageData((isFetched) => {
        let ModuleIds = [];
        moduleIds.forEach((element) => {
          ModuleIds.push(element.moduleId);
        });
        getGroupsModulesLangauge(
          ModuleIds,
          async (response, err, errorCode) => {
            if (err) {
              console.log(err);
            } else {
              await setGroupToBeTranslatedData(response.data);
              await setModulesWithSourceLanguages(
                modulesDetailsWithSourceLanguages
              );
              setShowTranslatePopup(true);
              setShowSelectLanguageModal(false);
            }
          }
        );
      });
    });
  };

  const translateModuleGroup = (moduleIds, groupInfo) => {
    //Go to the modal where source module displayed

    fetchSourceLanguageModules(
      moduleIds,
      async (sourceModules, allowToSelectSourceModule) => {
        await setModuleIdsTobeTranslated(moduleIds);
        if (
          sourceModules &&
          sourceModules.length > 0 &&
          allowToSelectSourceModule
        ) {
          await setSourceModules(sourceModules);
          handleShowSourceLanguageModal(true);
        } else {
          let moduleDetails = [];
          moduleIds.forEach((element) => {
            const module = {
              moduleId: element.moduleId,
              language: element.language,
              sourceModuleId: element.sourceModuleId,
            };
            moduleDetails.push(module);
          });
          handleShowTranslatorSelection(moduleIds, moduleDetails);
        }
      }
    );
  };

  const notifyTranslateJobCreatedSucess = () => {
    toast.success(
      <>
        <p className="notificationHeading">
          Translation Job created Successfully
        </p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const handleTranslationJobCreation = (data) => {
    const payload = {
      groupId: moduleDetails.translationGroup[0].translationGroupId,
      jobCreateDetails: data,
    };
    createTranslationJob(payload, (response, err, errorCode) => {
      if (err) {
        setIsloading(false);
        setShowTranslatePopup(false);
        setGroupToBeTranslatedData([]);
        setModuleIdsTobeTranslated([]);
        const errCoponent = ErrComponentToRender(errorCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      } else {
        setIsloading(false);
        notifyTranslateJobCreatedSucess();
        setShowTranslatePopup(false);
        setGroupToBeTranslatedData([]);
        setModuleIdsTobeTranslated([]);
        onSelectSearchedModule(moduleDetails.id, moduleDetails.brandId);
      }
    });
  };

  const goToSourceLanguageModal = () => {
    if (sourceModules && sourceModules.length > 0) {
      setShowTranslatePopup(false);
      setShowSelectLanguageModal(true);
    } else {
      setShowTranslatePopup(false);
      setShowSelectLanguageModal(false);
    }
  };

  // <<------------------- For Translation : Code ends here ------------------------------>>

  const initiateLocalization = async (moduleId) => {
    await setSelectedModuleId([moduleId]);
    fetchMarketData("localize", (isFetched) => {
      fetchLanguageData((isFetched) => {
        setLocalizeBusinsessrulsModal(true);
      });
    });
  };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const onSelectSearchedModule = (id, brandId) => {
    if (id) {
      setModuleId(id);
      setBrandId(brandId);
      setIsloading(true);

      getModuleDetailsById(id, (response, err, errCode) => {
        if (err) {
          setIsloading(false);
          const errCoponent = ErrComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          if (response.data.storageAssetId) {
            getCreatedAssetDetails(response.data.storageAssetId);
          }

          setModuleDetails(response.data);
          mlrStatusSet(response.data);
          const moduleType = response.data.creationMethodType;
          if (moduleType && moduleType.toLowerCase() === moduleTypes.denovo) {
            denovoBusinessRuleModules(
              response.data.brandName,
              response.data.campaign,
              response.data.assetDetails.market,
              response.data.id
            );
          } else {
            const module = response.data;
            getTacticBusinessRuleModules(
              module.brandName,
              module.campaign,
              module.moduleType,
              module.sourceModuleId,
              module.id
            );
          }
        }
      });
    }
  };
  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const getTacticBusinessRuleModules = (
    brandName,
    campaign,
    moduleType,
    sourceModuleId,
    moduleId
  ) => {
    let payLoad = {
      brandName: brandName,
      campaign: campaign,
      moduleType: moduleType,
      excludeModules: [moduleId],
    };
    if (moduleType !== "Original") {
      payLoad.sourceModuleId = sourceModuleId;
    }
    if (payLoad) {
      getExcludeModulesBusinessRules(
        payLoad,
        moduleId,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(errorCode);
          } else {
            setbModules(response.data);
          }
        }
      );
    } else {
      setIsloading(false);
    }
  };

  const denovoBusinessRuleModules = (brand, campaign, market, moduleId) => {
    if (!brand || !campaign) {
      setIsloading(false);
      return;
    }

    getDenovoModulesByBrandAndCampaign(
      { brand, campaign, market, moduleId },
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          setbModules(response.data);
          return;
        }
        showError(errCode);
      }
    );
  };

  const getCreatedAssetDetails = (assetId) => {
    getAssetDetails((response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        setAssetDetails(response.data);
      }
    }, assetId);
  };

  const prepareAssetDetailsData = () => {
    const children = assetDetails.children;
    if (children) {
      let pages = [];
      pages = children.filter((child) => {
        return child.type === "Page";
      });
      pages = pages.map((page) => {
        const atoms = children.filter((child) => {
          return child.type === "Atom" && child.parent === page._id;
        });
        page = { ...page, atoms };
        return page;
      });
      //Sort the array
      pages.sort((page1, page2) => {
        return page1.MetaData.page - page2.MetaData.page;
      });
      setPages(pages);
      setFiletredPages(pages);
    }
  };

  useLayoutEffect(() => {
    prepareAssetDetailsData();
  }, [assetDetails]);

  const startStylizingModule = (module) => {
    setIsloading(true);
    getModuleDetailsById(moduleDetails.id, (res, err) => {
      setIsloading(false);

      if (res) {
        let data = res.data;
        history.push({
          pathname: routes.moduleEditor,
          state: {
            module: prepareModuleDataForEditing(data, []),
            channelType: selectedChannelType,
            sourceType: props?.location?.state?.sourceType?.toLowerCase(),
            assetDataType:
              props?.location?.state?.assetDataType ||
              moduleDetails?.creationMethodType?.toLowerCase() ===
                moduleTypes.denovo
                ? moduleTypes.denovo
                : undefined,
            moduleReusableTexts: data?.moduleReusableTexts,
          },
        });

        return;
      }
      showError(err, "topright");
    });
  };

  const modifyModule = (isMLRFeedback = false) => {
    let pageCount = "";
    removeModuleInfo();
    if (moduleDetails.id) {
      updateModuleInfo({
        assetId: moduleDetails.storageAssetId,
        moduleId: moduleDetails.id,
        moduleName: moduleDetails.name,
        moduleCode: moduleDetails.moduleCode,
        moduleType: !isMLRFeedback ? "editModule" : "addressMLRFeedback",
        numberOfPages: pageCount,
        creationMethodType: moduleDetails.creationMethodType
          ? moduleDetails.creationMethodType.toLowerCase()
          : undefined,
      });
      if (isMLRFeedback) {
        history.push({
          pathname: routes.mlrFeedbackPreview,
          state: {
            moduleId: moduleDetails.id,
          },
        });
      } else {
        history.push({
          pathname: routes.addPrimaryClaim,
          state: {
            filteredPages,
            brandName: moduleDetails.brandName,
          },
        });
      }
    }
  };

  const adressMLRFeedback = () => {
    modifyModule(true);
  };

  const showCloneModulePopup = () => {
    setShowCloneModuleModal(true);
  };

  const handleModal = () => {
    setShowCloneModuleModal(false);
  };

  const showLocalizeModulePopup = () => {
    let moduleId = props.location.state.moduleId;
    initiateLocalization(moduleId);
  };

  const handleCloseTranslateModal = () => {
    setShowTranslatePopup(false);
  };
  const downloadPDFModule = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return;
    }
    let htmlString = "";
    if (contentModuleJsx) {
      htmlString = htmlString + renderToString(contentModuleJsx);
    }
    if (moduleSpecificJsx) {
      htmlString = htmlString + renderToString(moduleSpecificJsx);
    }
    if (businessSpecificJsx) {
      htmlString = htmlString + renderToString(businessSpecificJsx);
    }
    if (claimsSpecificJsx) {
      htmlString = htmlString + renderToString(claimsSpecificJsx);
    }
    if (channelSpecificJsx) {
      htmlString = htmlString + renderToString(channelSpecificJsx);
    }
    if (visualImageSpecificJsx) {
      htmlString = htmlString + renderToString(visualImageSpecificJsx);
    }

    if (!htmlString || htmlString.trim() !== "") {
      const finalHtml =
        "<html><head> <style>body {padding-left: 20px; padding-right: 20px} @page { size: A4 portrait; margin:0;}" +
        getDownloadModuleCss() +
        "</style></head><body>" +
        htmlString +
        (isDenovoModule
          ? getStylizedModuleForSelectedTab()?.stylisedVersion?.htmlData
            ? '<div style="font-size: 20px; color: rgb(34, 34, 34); font-weight: bold; margin-bottom: 10px; margin-top: 50px; font-family: Arial,sans-serif;">Stylized version</div>' +
              (getStylizedModuleForSelectedTab()?.stylisedVersion?.htmlData.replace(
                /<img([^>]+)>/g,
                "<img$1/>"
              ) || "")
            : ""
          : "") +
        "</body></html>";
      let formData = new FormData();
      formData.append("html", finalHtml);
      formData.append("fileName", moduleDetails.moduleCode);
      setIsloading(true);
      downloadModule(moduleId, formData, (res, err, errorCode) => {
        setIsloading(false);
        if (res && res.data) {
          window.open(res.data, "_blank");
        } else {
          if (errorCode) {
            showError(errorCode);
          }
        }
      });
    }
  };

  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };
  const isDenovoModule =
    getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() ===
      moduleTypes.denovo ||
    moduleDetails?.creationMethodType?.toLowerCase() === moduleTypes.denovo;

  const renderModuleSpecificInfo = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return <div></div>;
    }
    const jsx = (
      <div>
        {moduleDetails && Object.keys(moduleDetails).length > 0 && (
          <div className="module-specific-info">
            {/* <span className="head">Module-specific information</span> */}
            <CompositeAssetView
              data={{
                modules: [
                  { moduleId: moduleDetails.id, bodyModuleSection: "A" },
                ],
              }}
              isCompositeAsset={false}
              setJsx={setContentModuleJsx}
            />

            <ModuleSpecificInfo
              module={moduleDetails}
              setJsx={setModuleSpecificJsx}
            />
            {moduleDetails && moduleDetails.moduleBusinessRules && (
              <BusinessRulesSpecificInfo
                module={moduleDetails}
                bModules={bModules}
                setJsx={setBusinessSpecificJsx}
              />
            )}
            {moduleDetails &&
              ((moduleDetails.moduleClaims &&
                moduleDetails.moduleClaims.length > 0) ||
                (moduleDetails.moduleVisuals &&
                  moduleDetails.moduleVisuals.length > 0)) && (
                <ClaimAndVisualsSpecificInfo
                  module={moduleDetails}
                  setJsx={setClaimsSpecificJsx}
                  sortClaimsAndVisualsByRecordIndex={
                    sortClaimsAndVisualsByRecordIndex
                  }
                  getModuleInfoFromLocalStorage={getModuleInfoFromLocalStorage}
                />
              )}

            {/* {moduleDetails &&
              ((moduleDetails.moduleChannels &&
                moduleDetails.moduleChannels.length > 0) ||
                (moduleDetails.moduleCtas &&
                  moduleDetails.moduleCtas.length > 0)) && (
                <ChannelSpecificInfo
                  module={moduleDetails}
                  setJsx={setChannelSpecificJsx}
                />
              )} */}

            {!isTranslatorPreview() &&
              moduleDetails &&
              moduleDetails.moduleVisuals &&
              moduleDetails.moduleVisuals.length > 0 && (
                <SupportingVisualsImage
                  module={moduleDetails}
                  setJsx={setvisualImageSpecificJsx}
                />
              )}
            {isDenovoModule && (
              <>
                {getStylizedModuleForSelectedTab() ? (
                  <div>
                    <div className="showStylizedVersion">Stylized version </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          getStylizedModuleForSelectedTab().stylisedVersion
                            ?.htmlData,
                      }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
    return jsx;
  };

  const goToExistingTactics = () => {
    if (moduleDetails && moduleDetails.storageAssetId) {
      removeModuleInfo();
      history.push({
        pathname: routes.uploadAsset,
        state: {
          assetId: moduleDetails.storageAssetId,
        },
      });
    }
  };

  const notifyPublishSucess = (data) => {
    const date = new Date();
    toast.success(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">DAMS Submission Successful</p>
        <p className="notificationText">Document ID:-{data.docId || ""}</p>
        <p className="notificationText">Module Name:- {moduleDetails.name}</p>
        <p className="notificationText">
          Module Manager ID:-{moduleDetails.moduleCode}
        </p>
        <p className="notificationText">Submitted on:-{date.toString()}</p>
        <p className="notificationText">Submitted by:-{getCurrentUser()}</p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: false,
      }
    );
  };

  const notifyPublishFailure = (value) => {
    toast.error(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">VVPM Submission Un-successful</p>
        <p className="notificationText">{value}</p>
        <p className="notificationText">Please, try again</p>
      </>,
      {
        position: "top-right",
        autoClose: 3000,
      }
    );
  };

  const moduleClaimCheck = () => {
    return moduleDetails.moduleClaims?.every((item) => item.type !== "Primary");
  };

  const publishToVVPM = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return;
    }
    let htmlString = "";
    if (moduleSpecificJsx) {
      htmlString = htmlString + renderToString(moduleSpecificJsx);
    }
    if (businessSpecificJsx) {
      htmlString = htmlString + renderToString(businessSpecificJsx);
    }
    if (claimsSpecificJsx) {
      htmlString = htmlString + renderToString(claimsSpecificJsx);
    }
    if (channelSpecificJsx) {
      htmlString = htmlString + renderToString(channelSpecificJsx);
    }
    if (visualImageSpecificJsx) {
      htmlString = htmlString + renderToString(visualImageSpecificJsx);
    }

    if (!htmlString || htmlString.trim() !== "") {
      const finalHtml =
        "<html><head> <style>" +
        getDownloadModuleCss() +
        "</style></head><body> " +
        htmlString +
        "</body></html>";
      let formData = new FormData();
      formData.append("html", finalHtml);
      formData.append("fileName", moduleDetails.moduleCode);

      let { sourceType } = moduleDetails.assetDetails;
      if (
        moduleDetails.creationMethodType?.toLowerCase() === moduleTypes.denovo
      ) {
        sourceType = damsSources.veeva.name.toLowerCase();
      }
      setIsloading(true);
      publishModuleToVvpm(moduleId, sourceType, formData, (response, error) => {
        setIsloading(false);
        if (error) {
          notifyPublishFailure(error.message);
          return;
        }
        notifyPublishSucess(response.data);
        onSelectSearchedModule(moduleId, moduleDetails.brandId);
      });
    }
  };

  const getVeevaDownload = () => {
    setIsloading(true);
    const veevaModuleId = moduleDetails.moduleMlr[0].moduleId;
    const veevaDocId = moduleDetails.moduleMlr[0].mlrReviews[0].veevaDocId;
    getVeevaDownloadAnnotated(
      veevaModuleId,
      veevaDocId,
      (response, err, errorCode) => {
        if (err) {
          setIsloading(false);
          showError(errorCode);
        } else {
          setIsloading(false);
          const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
          const downlodLink = document.createElement("a");
          downlodLink.href = pdfUrl;
          downlodLink.setAttribute("download", `${veevaDocId}.pdf`); //or any other extension
          document.body.appendChild(downlodLink);
          downlodLink.click();
        }
      }
    );
  };
  const modifyClonedModule = (clonedModule) => {
    let pageCount = "";
    removeModuleInfo();
    if (clonedModule.id) {
      updateModuleInfo({
        assetId: clonedModule.storageAssetId,
        moduleId: clonedModule.id,
        moduleName: clonedModule.name,
        moduleCode: clonedModule.moduleCode,
        moduleType: "editModule",
        numberOfPages: pageCount,
        creationMethodType: clonedModule.creationMethodType
          ? clonedModule.creationMethodType.toLowerCase()
          : undefined,
      });

      history.push({
        pathname: routes.addPrimaryClaim,
        state: {
          filteredPages,
          brandName: clonedModule.brandName,
        },
      });
    }
  };

  //Localize Modal Market Data

  const fetchMarketData = (button, cb) => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  marketData = filterOutGlobalMarket(marketData);
                  setMarketDataSource(marketData);
                  if (button && button.toLowerCase() === "localize") {
                    //setLocalizeBusinsessrulsModal(true);
                    //setShowLocalizeModuleModal(true);
                    cb(true);
                  } else if (button && button.toLowerCase() === "translate") {
                    cb(true);
                  }
                }
              }
            }
          });
        });
      }
    });
  };
  // Localize Language

  const fetchLanguageData = async (cb) => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: imcLanguageTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let languageData = data.Items;
              if (languageData && languageData.length > 0) {
                languageData = [...languageData].map((language) => {
                  let object = {
                    languageCode: language.languageCode,
                    icbCode: language.icbCode,
                    language: language.language,
                    market: language.market,
                  };
                  object[
                    "displayName"
                  ] = `${object.languageCode} - ${object.language}`;

                  return object;
                });
                if (languageData && languageData.length > 0) {
                  //remove duplicates first then sort
                  let languages = [];
                  languageData.reduce((acc, curr) => {
                    if (
                      acc.findIndex((itm) => {
                        return (
                          itm.languageCode === curr.languageCode &&
                          itm.language === curr.language
                        );
                      }) === -1
                    ) {
                      acc.push(curr);
                      languages.push(curr);
                    }
                    return acc;
                  }, []);

                  languageData = languages;

                  languageData = [...languageData].sort(
                    (languageOne, languageTwo) => {
                      if (languageOne.language < languageTwo.language) {
                        return -1;
                      }
                      if (languageOne.language > languageTwo.language) {
                        return 1;
                      }
                      return 0;
                    }
                  );
                  setLanguageDataSource(languageData);
                  cb(true);
                }
              }
            }
          });
        });
      }
    });
  };

  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          const brands = data.filter((brand) => {
            return brand.brandId === moduleDetails.brandId;
          });
          if (brands.length > 0) {
            setCampaigns(brands[0].campaigns);
          }
        }
      }
    });
  };

  const submitForReviewBtn = () => {
    const translateClaimsData = moduleDetails.moduleClaims || [];
    const translateVisualsData = moduleDetails.moduleVisuals || [];
    const translateChanneslData = moduleDetails.moduleChannels || [];
    const translateChannelCta = moduleDetails.moduleCtas || [];
    const translateAditionalInfData =
      moduleDetails.additionalInformations || [];
    for (let i = 0; i < translateClaimsData.length; i++) {
      if (
        translateClaimsData[i]["translate"] === "1" &&
        translateClaimsData[i]["type"] !== "Core"
      ) {
        return false;
      }

      const supportingTextComponents =
        translateClaimsData[i].supportingTextComponents;

      if (supportingTextComponents) {
        for (let i = 0; i < supportingTextComponents.length; i++) {
          if (
            supportingTextComponents[i]["translate"] === "1" &&
            supportingTextComponents[i]["componentType"] !== "Reference"
          ) {
            return false;
          }
        }
      }
    }
    for (let i = 0; i < translateVisualsData.length; i++) {
      // if (translateVisualsData[i]["translate"] === "1") {
      //   return false;
      // }

      const supportingTextComponents =
        translateVisualsData[i].supportingTextComponents;

      if (supportingTextComponents) {
        for (let i = 0; i < supportingTextComponents.length; i++) {
          if (
            supportingTextComponents[i]["translate"] === "1" &&
            supportingTextComponents[i]["componentType"] !== "Reference"
          ) {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < translateChanneslData.length; i++) {
      if (translateChanneslData[i]["translate"] === "1") {
        return false;
      }

      const moduleChannelSubtexts =
        translateChanneslData[i].moduleChannelSubtexts;

      if (moduleChannelSubtexts) {
        for (let i = 0; i < moduleChannelSubtexts.length; i++) {
          if (moduleChannelSubtexts[i]["translate"] === "1") {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < translateChannelCta.length; i++) {
      const channelCtaText = translateChannelCta[i].texts;
      if (channelCtaText) {
        for (let i = 0; i < channelCtaText.length; i++) {
          if (channelCtaText[i]["translate"] === "1") {
            return false;
          }
        }
      }
    }

    for (let i = 0; i < translateAditionalInfData.length; i++) {
      if (translateAditionalInfData[i]["translate"] === "1") {
        return false;
      }
      const additionalInformations =
        translateAditionalInfData[i].additionalInformations;

      if (additionalInformations) {
        for (let i = 0; i < additionalInformations.length; i++) {
          if (
            additionalInformations[i]["dosageTranslate"] === "1" ||
            additionalInformations[i]["moduleRatingTranslate"] === "1" ||
            additionalInformations[i][
              "usageInstructionsGuidelinesTranslate"
            ] === "1" ||
            additionalInformations[i]["moduleUsageRecommendationTranslate"] ===
              "1" ||
            additionalInformations[i]["stageInCustomerJourneyTranslate"] ===
              "1" ||
            additionalInformations[i]["audienceSegmentTranslate"] === "1"
          ) {
            return false;
          }
        }
      }
    }

    // returning true is enabled
    return true;
  };

  const translateRedirect = (isReviewer) => {
    window.localStorage.removeItem("translateModuleInfo");
    history.push({
      pathname: isReviewer
        ? routes.reviewerDashboard
        : routes.translatorDashboard,
    });
  };

  const submitForReview = () => {
    let translateModuelInfo = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let successMessage = "Successfully sent for Review";
    let reviewerStatus = "Ready For Review";
    let isReviewer = false;
    const jobType = getTranslationJobType();
    if (jobType && jobType.trim().toLowerCase() === "self") {
      reviewerStatus = "Approved";
      successMessage = "Translation successfull";
      isReviewer = true;
    }
    let payload = {
      jobId: translateModuelInfo.jobId,
      translatorStatus: "Complete",
      reviewerStatus: reviewerStatus,
      jobType: jobType,
      moduleId: moduleDetails.id,
    };
    translatorSubmitForReview(payload, (response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        translateRedirect(isReviewer);
        notifyReviewSucess(response.data, successMessage);
      }
    });
  };

  const notifyReviewSucess = (data, successMessage) => {
    toast.success(
      <>
        <p className="notificationTitle mb-2">
          <img
            src={successTick}
            alt="Successfully sent for Review"
            className="mr-1"
          />{" "}
          {successMessage}
        </p>
        <p className="reviewNotifciationText">
          Job ID: <strong>{data.jobId}</strong>
        </p>
        <p className="reviewNotifciationText">
          Due Date:{" "}
          <strong>
            {/* {data.dueDate} */}
            {tranformDueDate(data.dueDate)}
          </strong>
        </p>

        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: false,
      }
    );
  };
  const tranformDueDate = (inputDate) => {
    let result = "";
    if (inputDate) {
      let parts = inputDate.split("-");
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return result;
  };
  const hideRatingsModalPopup = () => {
    setShowRatingsModal(false);
  };
  const hideLocalizeBusinsessrulsModal = () => {
    setLocalizeBusinsessrulsModal(false);
    setGenericInfo(undefined);
    setPrevSelectedData(undefined);
    setDataForTranslationCreationGroup(undefined);
  };

  const handleBulkLocalizationModal = async (
    flag = false,
    genericInfoData,
    selectedData,
    finalFilteredModuleIds
  ) => {
    if (flag) {
      await setLocalizeBusinsessrulsModal(false);
      await setGenericInfo(genericInfoData);
      await setPrevSelectedData(selectedData);
      setSelectedModuleIdForGroupCreation(() => {
        setBulkLocalizationModal(flag);
      });
      setSelectedModuleIdForGroupCreation(finalFilteredModuleIds);
    } else {
      hideLocalizeBusinsessrulsModal();
      setGenericInfo(undefined);
      setPrevSelectedData(undefined);
      setDataForTranslationCreationGroup(undefined);
      // if (singleSelectedModuleId) {
      //   setSingleSelectedModuleId(undefined);
      // } else {
      //   setSelectedModuleIds([]);
      // }
      setBulkLocalizationModal(flag);
    }
  };
  const handleBackToBussinessRulesModal = () => {
    setLocalizeBusinsessrulsModal(true);
    setBulkLocalizationModal(false);
    setDataForTranslationCreationGroup(undefined);
  };
  const [validtionModalData, setValidtionModalData] = useState([]);
  const [showBulkValidationModal, setShowBulkValidationModal] = useState(false);

  const showBulkModulesValidaionScreen = (data, validationData) => {
    if (validationData.length < 1) {
      setBulkLocalizationModal(false);
      return;
    }
    setDataForTranslationCreationGroup(data);
    createTranslationDuplicationCheck(
      validationData,
      (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          showError(errCoponent);
        } else {
          if (response.data.length > 0) {
            setValidtionModalData(response.data);
            setBulkLocalizationModal(false);
            setShowBulkValidationModal(true);
          } else {
            createNewTranslationGroup(data);
          }
          setPrevSelectedData(undefined);
        }
      }
    );
  };

  const backToGenericInfoSlectionModal = () => {
    setBulkLocalizationModal(true);
    setShowBulkValidationModal(false);
  };

  const bulkModulesValidationModalClose = () => {
    setShowBulkValidationModal(false);
    hideLocalizeBusinsessrulsModal();
    setGenericInfo(undefined);
    setPrevSelectedData(undefined);
    setDataForTranslationCreationGroup(undefined);
    // if (singleSelectedModuleId) {
    //   setSingleSelectedModuleId(undefined);
    // } else {
    //   setSelectedModuleIds([]);
    // }
    setBulkLocalizationModal(false);
  };

  const createNewTranslationGroup = (payload) => {
    setIsloading(true);
    createTranslationGroup(payload, (response, err, errCode) => {
      setIsloading(false);
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        setShowBulkValidationModal(false);
        setBulkLocalizationModal(false);
        hideLocalizeBusinsessrulsModal(); //Remove this once bulkvalidation screen is available
      }
    });
  };

  const isThisModuleLocked = () => {
    if (props.location.state.isLocked) {
      return true;
    }
    return false;
  };

  const getStylizedModuleForSelectedTab = () => {
    if (!stylizedModules || !stylizedModules[0].stylisedVersion) {
      return;
    }
    let channelType = "";
    switch (selectedChannelType) {
      case "Email":
        channelType = channelTypes.email;
        break;
      case "Banner":
        channelType = channelTypes.banner;
        break;
      case "e-Detail":
        channelType = channelTypes.eDetail;
        break;
      default:
        return;
    }
    const stylizedModule = stylizedModules.find((el) => {
      return el.channelType.toLowerCase() === channelType.toLowerCase();
    });
    return stylizedModule;
  };

  const hideExportOptionModal = () => {
    const obj = JSON.parse(JSON.stringify(exportOptions));
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (exportOptions[key]) {
        obj[key] = false;
      }
    });
    setExportOptions(obj);
    setshowExportOptions(false);
  };

  const shouldExportProceedBeDisabled = () => {
    const keys = Object.keys(exportOptions);
    let shouldDisable = true;
    keys.forEach((key) => {
      if (exportOptions[key]) {
        shouldDisable = false;
      }
    });
    return shouldDisable;
  };

  const handleChange = (event, name) => {
    const obj = JSON.parse(JSON.stringify(exportOptions));
    obj[name] = event.target.checked;
    setExportOptions(obj);
  };

  const exportModule = (exportTypes, callback) => {
    setIsloading(true);
    exportTypes.forEach((type, index) => {
      const fileName =
        moduleDetails.moduleCode +
        "_" +
        capitalizeString(selectedChannelType || "") +
        "_" +
        "Stylized Module";
      const extension = `.${type.toLowerCase()}`;
      const payload = {
        type: type,
        pdfWidth: "600",
        fileName: fileName + extension,
        html: `<!DOCTYPE html> <html><head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /> 
  </head>${getStylizedModuleForSelectedTab().stylisedVersion?.htmlData}</html>`,
      };

      exportStylizedModule(
        payload,
        props?.location?.state?.sourceType,
        (res, err) => {
          setIsloading(false);
          if (res) {
            if (type.toLowerCase() === "pdf") {
              downloadPdfFromByteArray([res.data], fileName);
            }
            if (type.toLowerCase() === "html") {
              var file = new File(["\ufeff" + res.data], fileName + extension, {
                type: "text/plain:charset=UTF-8",
              });

              //create a ObjectURL in order to download the created file
              const url = window.URL.createObjectURL(file);

              //create a hidden link and set the href and click it
              var a = document.createElement("a");
              a.style = "display: none";
              a.href = url;
              a.download = file.name;
              a.click();
              window.URL.revokeObjectURL(url);
            }
          }
          if (index === exportTypes.length - 1) {
            callback();
          }
          if (err) {
            showError(err.response && err.response.status);
          }
        }
      );
    });
  };

  const closeChannelSelectionModal = () => {
    setSelectedChannel(undefined);
    setChannelSelectionModal(false);
    setSelectedBrand(undefined);
    setStylizeModuleChannelSelection(false);
    setstylizeChannelSelectionModalData(undefined);
  };
  const cancelCreatingChannelMatrix = () => {
    setIsStylisedModuleInitiated(false);
    setSelectedChannel(undefined);
    setStylizeModuleChannelSelection(false);
    closeChannelSelectionModal();
  };
  const proceedToCtaSelectionScreen = () => {
    setIsStylisedModuleInitiated(true);
    setStylizeModuleChannelSelection(false);
    setChannelSelectionModal(false);
    setIsStylisedModuleInitiatedfromview(true);
  };

  const fetchModuleToStylize = (selectedCtas) => {
    setIsloading(true);

    getModuleDetailsById(moduleDetails.id, (res, err) => {
      setIsloading(false);
      if (res) {
        let data = res.data;
        history.push({
          pathname: routes.moduleEditor,
          state: {
            module: prepareModuleDataForEditing(data, selectedCtas),
            channelType: selectedChannel,
            //damsSources.veeva.name.toLowerCase(),
            sourceType:
              moduleDetails?.assetDetails?.sourceType !== undefined &&
              moduleDetails?.assetDetails?.sourceType !== ""
                ? moduleDetails?.assetDetails?.sourceType?.toLowerCase()
                : damsSources.veeva.name.toLowerCase(),
            //   props?.location?.state?.sourceType?.toLowerCase(),
            // damsSources.veeva.name.toLowerCase(),
            // ? damsSources.veeva.name.toLowerCase()

            assetDataType:
              props?.location?.state?.assetDataType ||
              moduleDetails?.creationMethodType?.toLowerCase() ===
                moduleTypes.denovo
                ? moduleTypes.denovo
                : undefined,
            moduleReusableTexts: data?.moduleReusableTexts,
          },
        });
        //setstylizeChannelSelectionModalData(undefined);
        return;
      }
      showError(err, "topright");
    });
  };
  const getVeevaModuleStatus = () => {
    let veevaModuleId = moduleDetails?.id;

    getVeevaLatestStatus(
      veevaModuleId,
      veevaDocId,
      (response, err, errorCode) => {
        if (response) {
          setMlrModuleStatus(response.data.mlrStatus);
        }
      }
    );
  };
  const mlrStatusSet = (module) => {
    let status = "Not Submitted";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };

  const returnMlrIcons = (mlrStatus) => {
    switch (mlrStatus) {
      case "In Draft":
        return mlrStatusInDraft;
      case "Draft":
        return mlrStatusInDraft;
      case "Revise & Resubmit-MLR Review":
        return mlrStatusReSubmit;
      case "In-review":
        return mlrStatusInReview;
      case "In MLR Review":
        return mlrStatusInReview;
      case "MLR Approved":
        return mlrStatusApproved;
      case "Approved for Production":
        return mlrStatusApproved;
      case "MLR Approved with changes":
        return mlrStatusAppChanges;
      default:
        return mlrStatusInDraft;
    }
  };

  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        {isTranslatorPreview() ? (
          <TranslateHeader />
        ) : (
          <row className="no-gutters">
            <div className="top-panel">
              <div className="mm-logo">
                <img src={ImcLogo} alt="IMC - Module Manager" />
              </div>
              <div className="module-view-action-btns-right">
                <img
                  src={closeButton}
                  alt="close"
                  className="btn-close"
                  onClick={() =>
                    history.push({
                      pathname: routes.uploadAsset,
                    })
                  }
                />
              </div>
            </div>
          </row>
        )}
      </div>
      {isStylisedModuleInitiated ? (
        <>
          <div className="view-main-div mt-3 existingTacticsContainer">
            <div className="row">
              <div className="col-md-12">
                <CtaSelectionScreen
                  brand={moduleDetails.brandName}
                  campaignName={moduleDetails.campaign}
                  channelType={selectedChannel}
                  cancelCreatingStylisedModule={cancelCreatingChannelMatrix}
                  handleProceed={fetchModuleToStylize}
                  isStylisedModuleInitiated={isStylisedModuleInitiated}
                  isStylisedModuleInitiatedfromview={
                    isStylisedModuleInitiatedfromview
                  }
                  setstylizeChannelSelectionModalData={
                    setstylizeChannelSelectionModalData
                  }
                  sourceType={
                    moduleDetails?.assetDetails?.sourceType
                      ? moduleDetails?.assetDetails?.sourceType
                      : damsSources.veeva.name.toLowerCase()
                    // moduleDetails?.assetDetails?.sourceType ||
                    // moduleDetails?.creationMethodType?.toLowerCase() ===
                    //   moduleTypes.denovo
                    //   ? damsSources.veeva.name.toLowerCase()
                    //   : undefined
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`view-main-div mt-3 customScrollBar ${
            selectedTabIndex === 1 ? "source-module-custom-class" : ""
          }`}
        >
          <ModuleDetailsNav
            modifyModule={modifyModule}
            onClickBasedOn={showCloneModulePopup}
            translateModule={translateModuleGroup}
            module={moduleDetails}
            publish={publishToVVPM}
            getVeevaDownload={getVeevaDownload}
            localizeModuleModal={showLocalizeModulePopup}
            isTranslatorPreview={isTranslatorPreview()}
            submitForReview={submitForReview}
            submitForReviewBtn={submitForReviewBtn}
            adressMLRFeedback={adressMLRFeedback}
            isThisModuleLocked={isThisModuleLocked()}
            modifyStylizedModule={startStylizingModule}
            selectedTabIndex={selectedTabIndex}
            primaryClaimStatus={moduleClaimCheck()}
            setChannelSelectionModal={setChannelSelectionModal}
            mlrModuleStatus={mlrModuleStatus}
          />
          <div className="view-container">
            <div className="row div-border-bottom ml-0 mr-0">
              <div className="col-md-6 pl-0">
                <div className="d-flex">
                  {stylizedModules && stylizedModules[0]?.stylisedVersion && (
                    <div
                      className={`text-center tab-panel ${
                        selectedTabIndex === 0 ? "panel-tab-selected" : ""
                      } ${
                        stylizedModules && !stylizedModules[0]?.stylisedVersion
                          ? "panel-tab-disabled"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          stylizedModules &&
                          stylizedModules[0]?.stylisedVersion
                        ) {
                          setSelectedTabIndex(0);
                        }
                      }}
                    >
                      Stylized Module
                    </div>
                  )}
                  <div
                    className={` text-center tab-panel ${
                      selectedTabIndex === 1 ? "panel-tab-selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedTabIndex(1);
                    }}
                  >
                    Source Module
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div
                  className="d-flex justify-content-end"
                  style={{ marginTop: "8px" }}
                >
                  <div className="d-flex mlr-status-main">
                    <div>
                      <img
                        className="mlr-refresh-icon"
                        src={mlrRefreshIcon}
                        alt="refresh"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getVeevaModuleStatus();
                        }}
                      />
                      <span className="mlr-status-label">MLR Status</span>
                    </div>
                    {mlrModuleStatus && mlrModuleStatus && (
                      <>
                        <div>
                          <img
                            src={returnMlrIcons(mlrModuleStatus)}
                            alt="modulestatus"
                            className="mlr-refresh-icon"
                          />
                        </div>
                        <div
                          className="mlr-status-value"
                          title={mlrModuleStatus}
                        >
                          {mlrModuleStatus && mlrModuleStatus}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <img
                      src={ApprovedIcon}
                      alt="downloadPDF"
                      className="img-fluid"
                      style={{ marginRight: "6px" }}
                    />
                    <button
                      className="btn btn-link pl-0 pr-0"
                      disabled={true}
                      style={{ marginRight: "16px" }}
                      onClick={() => {}}
                    >
                      Master Asset Link
                    </button>
                  </div>
                  <div>
                    {/* <img
                    src={pdfIcon}
                    alt="Download PDF"
                    className="img-fluid"
                    style={{ marginRight: "6px" }}
                  /> */}
                    <button
                      className="btn btn-link edit-btn pl-0 pr-0"
                      onClick={() => {
                        if (selectedTabIndex === 1) {
                          downloadPDFModule();
                        } else {
                          setshowExportOptions(true);
                        }
                      }}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="styleized_preview_bg">
              {selectedTabIndex === 0 ? (
                <div className="d-flex mb-3" style={{ marginLeft: "12px" }}>
                  <button
                    class="btn btn-link stylizedTabBtn mr-3"
                    onClick={() => {
                      setSelectedChannelType("Email");
                    }}
                  >
                    Email
                  </button>
                  <button disabled class="btn btn-link stylizedTabBtn mr-3">
                    e-Detail
                  </button>
                  <button disabled class="btn btn-link stylizedTabBtn">
                    Banner
                  </button>
                </div>
              ) : (
                ""
              )}
              {selectedTabIndex === 0 && (
                <div
                  id="module-email"
                  className="module-view-stylised  editorFixedBlock customScrollBar"
                >
                  {selectedTabIndex === 0 &&
                    getStylizedModuleForSelectedTab() && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            getStylizedModuleForSelectedTab().stylisedVersion
                              ?.htmlData,
                        }}
                      ></div>
                    )}
                  {selectedTabIndex === 1 && renderModuleSpecificInfo()}
                </div>
              )}

              {selectedTabIndex === 1 && (
                <div className="pageContentCol mlr-main-content ">
                  {renderModuleSpecificInfo()}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <Container fluid>
        {!isTranslatorPreview() && (
          <Row className="mlr-main">
            {moduleDetails && moduleDetails.storageAssetId && (
              <div className="master-asset-link">
                <img src={Tick} alt="Dashboard" className="img-fluid" />
                <button
                  onClick={() => {
                    goToExistingTactics();
                  }}
                >
                  {i18next.t("generalText.masterAssetLink")}
                </button>
              </div>
            )}
            <button
              className="btn-download"
              onClick={() => {
                downloadPDFModule();
              }}
            >
              {i18next.t("generalText.download")}
            </button>
          </Row>
        )}
      </Container> */}
      <Container fluid>
        <CustomModal
          displayModal={showCloneModuleModal}
          hideModal={handleModal}
          title={"Filter By Alert"}
          size="sm"
          dilogClassName="alertModalSmall"
          backdrop="false"
        >
          <CloneModule
            module={moduleDetails}
            hideModal={handleModal}
            modifyClonedModule={modifyClonedModule}
          />
        </CustomModal>

        <CustomModal
          displayModal={showSelectLanguageModal}
          hideModal={handleShowSourceLanguageModal}
          title={"Select Source Language"}
          size="md"
          dilogClassName="alertModalSmall"
          keyboard={false}
        >
          <SelectSourceModuleLanguageModal
            moduleIds={moduleIdsTobeTranslated}
            sourceModulesList={sourceModules}
            showModal={handleShowSourceLanguageModal}
            handleProceed={handleShowTranslatorSelection}
          />
        </CustomModal>
        <CustomModal
          displayModal={showTranslatePopup}
          hideModal={handleCloseTranslateModal}
          title={"Filter By Alert"}
          size="lg"
          dilogClassName="alertModalSmall"
        >
          <TranslateModule
            marketDataSource={marketDataSource}
            groupToBeTranslatedData={groupToBeTranslatedData}
            hideModal={handleCloseTranslateModal}
            modulesDetailsWithSourceLanguages={modulesWithSourceLanguages}
            languageDataSource={languageDataSource || []}
            handleSaveTranslation={handleTranslationJobCreation}
            goToComponentSelection={goToSourceLanguageModal}
          />
        </CustomModal>

        <CircularProgressBar isloading={isloading} />

        <CustomModal
          displayModal={showRatingsModal}
          hideModal={hideRatingsModalPopup}
          title={"Rate the Translator/Agency"}
          size="sm"
          dilogClassName="alertModalSmall"
          // backdrop="false"
          setShowRatingsModal={setShowRatingsModal}
          keyboard={false}
        >
          <RateTranslator
            module={moduleDetails}
            hideRatingsModalPopup={hideRatingsModalPopup}
          />
        </CustomModal>
        {localizeBusinsessrulsModal && (
          <CustomModal
            displayModal={localizeBusinsessrulsModal}
            hideModal={hideLocalizeBusinsessrulsModal}
            title={"Business Rules Validation"}
            size="xl"
            dilogClassName="alertModalSmall"
            keyboard={false}
          >
            <GenericBussinessRules
              hideLocalizeBusinsessrulsModal={hideLocalizeBusinsessrulsModal}
              selectModuleIds={selectedModuleId}
              handleProceed={handleBulkLocalizationModal}
              prevSelectedData={prevSelectedData}
            />
          </CustomModal>
        )}
        <CustomModal
          displayModal={bulkLocalizationModal}
          hideModal={handleBulkLocalizationModal}
          title={"Enter Generic Info Details"}
          size="sm"
          dilogClassName="alertModalSmall"
          backdrop="false"
        >
          <BulkLocalizationModal
            hideModal={handleBulkLocalizationModal}
            marketDataSource={marketDataSource || []}
            campaigns={campaigns || []}
            languageDataSource={languageDataSource || []}
            audienceDataSource={audienceDataSource || []}
            handleBack={handleBackToBussinessRulesModal}
            showBulkModulesValidaionScreen={showBulkModulesValidaionScreen}
            genericInformation={genericInfo}
            selectedModuleIdForGroupCreation={selectedModuleIdForGroupCreation}
            dataForTranslationCreationGroup={dataForTranslationCreationGroup}
          />
        </CustomModal>

        <CustomModal
          displayModal={showBulkValidationModal}
          hideModal={hideLocalizeBusinsessrulsModal}
          title={"Existing Modules detected"}
          size="xl"
          dilogClassName="alertModalSmall error"
          keyboard={false}
        >
          <BulkModulesValidation
            backToGenericInfoSlectionModal={backToGenericInfoSlectionModal}
            bulkModulesValidationModalClose={bulkModulesValidationModalClose}
            createNewTranslationGroup={createNewTranslationGroup}
            validationData={validtionModalData}
            dataForTranslationCreationGroup={dataForTranslationCreationGroup}
          />
        </CustomModal>
        <CustomModal
          displayModal={channelSelectionModal}
          hideModal={closeChannelSelectionModal}
          size="sm"
          dilogClassName="alertModalSmall"
          backdrop={false}
        >
          <ChannelSelectionComponent
            closestylizeChannelSelectionModal={closeChannelSelectionModal}
            brand={moduleDetails.brandName}
            campaign={moduleDetails.campaign}
            sourceType={
              moduleDetails?.assetDetails?.sourceType ||
              moduleDetails?.creationMethodType?.toLowerCase() ===
                moduleTypes.denovo
                ? damsSources.veeva.name.toLowerCase()
                : undefined
            }
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            cancelCreatingChannelMatrix={cancelCreatingChannelMatrix}
            onClickProceed={(flag) => {
              proceedToCtaSelectionScreen();
            }}
          />
        </CustomModal>
        <CustomModal
          displayModal={showExportOptions}
          hideModal={hideExportOptionModal}
          title={""}
          size="sm"
          dilogClassName="alertModalSmall modal-with-top-border"
          keyboard={false}
          backdrop="true"
        >
          <div className="export-row1 ml-2">
            <span>Download</span>
          </div>
          <div className="pt-3">
            <CheckBox
              label="PDF"
              isChecked={exportOptions.pdf}
              handleChange={(event) => {
                handleChange(event, "pdf");
              }}
              labelClassName="pdflabelcheckbox "
              checkboxClassName="form-check-input ml-2"
            />
          </div>
          <div className="pt-3 pb-3">
            <CheckBox
              label="HTML"
              isChecked={exportOptions.html}
              handleChange={(event) => {
                handleChange(event, "html");
              }}
              labelClassName="pdflabelcheckbox"
              checkboxClassName="form-check-input ml-2"
            />
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-6 pl-2 pb-2">
              <button
                type="button"
                className="btn btn-link text-uppercase"
                style={{
                  color: "#2162d5 ",
                  fontWeight: 600,
                }}
                onClick={() => hideExportOptionModal()}
              >
                CANCEL
              </button>
            </div>
            <div className="col-md-6 text-right pr-4 pb-2">
              <button
                type="button"
                className="btn btn-clone  export-proceed"
                disabled={shouldExportProceedBeDisabled()}
                onClick={() => {
                  let selected = [];
                  const keys = Object.keys(exportOptions);
                  keys.forEach((key) => {
                    if (exportOptions[key]) {
                      selected = selected.concat(key);
                    }
                  });
                  exportModule(selected, () => {
                    hideExportOptionModal();
                  });
                }}
              >
                PROCEED
              </button>
            </div>
          </div>
        </CustomModal>

        {/* <Row className="mt-2">
          <Col>
            <div className="bodyContentBg mt-2">
              <Container fluid>
                <div className="pageContentCol mlr-main-content ">
                  {renderModuleSpecificInfo()}
                </div>
              </Container>
            </div>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default ModuleDetails;
