import React from "react";
import { getClickableLink } from "../../Uitls/Util";
const PreviewForCTA = ({ title, data, attribute }) => {
  return (
    <div className="Channel-grid-sub-pre-preview">
      <span className="channel-type-head">
        {title} ({`${data.length}`})
      </span>
      <table className="table-style">
        <tbody>
          <tr className="header">
            <td>MODULE MANAGER ID</td>
            <td>DAMS ID</td>
            <td>MLR STATUS</td>
            <td>LINK</td>
            <td>LABEL(S)</td>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={item.id}>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.id}{" "}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.documentNumber || "-"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.mlrStatus || "Not Submitted"}
                </td>
                <td
                  className="tbl-row-txt-bold hover-effect"
                  style={{ width: "30%" }}
                >
                  <a
                    href={getClickableLink(item.url)}
                    className="cta-link-channel-grid"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.url}
                  </a>
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "25%" }}>
                  <ol>
                    {item.labels.length > 0
                      ? item.labels.map((label, index) => {
                          return <li key={index}>{label}</li>;
                        })
                      : "-"}
                  </ol>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default PreviewForCTA;
