import { useEffect, useCallback, useState } from "react";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
const useContextMenu = (outerRef, compClass) => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [menu, showMenu] = useState(false);
  const [selectedText, setSelectedText] = useState("");

  const handleContextMenu = useCallback(
    (event) => {
      let selectedText = window.getSelection().toString();

      if (
        selectedText &&
        selectedText !== "" &&
        hasUserLoggedInAsReviewer() &&
        outerRef
        //  &&
        // outerRef?.current?.contains(event.target)
      ) {
        setSelectedText(selectedText);
        event.preventDefault();
        let s = window.getSelection();
        let oRange = s.getRangeAt(0); //get the text range
        let oRect = oRange.getBoundingClientRect();
        const posX = oRect.left + oRect.width / 2 - 86; //Keeping the context menu pointer center of the text.
        const clickX = posX < 0 ? event.clientX : posX;
        const clickY = event.clientY;
        setXPos(`${clickX}px`);
        setYPos(`${clickY + 20}px`);
        showMenu(true);
      } else {
        setSelectedText("");
        showMenu(false);
      }
    },
    [showMenu, outerRef, setXPos, setYPos]
  );

  const handleClick = useCallback(() => {
    showMenu(false);
  }, [showMenu]);
  const handleScroll = useCallback(() => {
    showMenu(false);
  }, [showMenu]);
  useEffect(() => {
    let element = document.getElementById(compClass);
    if (element) {
      document.addEventListener("click", handleClick);
      document
        .getElementById(compClass)
        .addEventListener("contextmenu", handleContextMenu);
      document.addEventListener("scroll", handleScroll);
    }
    return () => {
      document.addEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return { xPos, yPos, menu, selectedText };
};

export default useContextMenu;
