import React, { useEffect, useRef } from "react";

//Import custom compoennents
import TabPanel from "../../Uitls/TabPanel";
import {
  channelTypesAndAttributes,
  getChannelAttributes,
  globalAttributes,
} from "../../Uitls/Util";
import CheckBox from "../../Uitls/CheckBox";
import SelectableMarketingMessageCard from "./SelectableMarketingMessageCard";

const SelectableMarketingMessages = ({
  marketingMessages,
  onSelectMarketingMessage,
  selectedMarketingMessages,
  channelType,
  isAllSubjectLineChecked,
  isAllPreHeaderChecked,
  handleSelecteAll,
  isAllCtasChecked,
  isAllOptoutsChecked,
  searchedResult,
  startEditingMessage,
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to the top of the scrollable content when marketing messages are updated
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [marketingMessages]);
  const renderColumns = () => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let optouts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    let tagLines = marketingMessages?.marketingMsgChannels?.tagLines || [];
    let ctas = marketingMessages?.ctas || [];

    if (
      marketingMessages?.marketingMsgChannels.channelType.trim() ===
        channelTypesAndAttributes.email.name ||
      marketingMessages.ctas
    ) {
      return (
        <div>
          <div
            className="row slctble-marketing-sg-cols-head customScrollBar"
            style={{ overflowY: "scroll" }}
          >
            <div
              className="col-md-3 pl-0 marketing-msg-column chaneelGridColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll" }}
            >
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllSubjectLineChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "subjectline");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>

              <div style={{ marginLeft: "25px" }}>{`Subject Line (${
                searchedResult
                  ? subjectLines.length
                  : selectedMarketingMessages?.subjectLines.length +
                    "/" +
                    subjectLines.length
              })`}</div>
            </div>
            <div
              className="col-md-3 marketing-msg-column chaneelGridColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll" }}
            >
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllPreHeaderChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "preheader");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>
              <div style={{ marginLeft: "25px" }}>
                {`PreHeader (${
                  searchedResult
                    ? preheaders.length
                    : selectedMarketingMessages?.preHeaders.length +
                      "/" +
                      preheaders.length
                })`}
              </div>
            </div>
            <div
              className="col-md-3 marketing-msg-column chaneelGridColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll" }}
            >
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllCtasChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "cta");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>
              <div style={{ marginLeft: "25px" }}>{`CTAs (${
                searchedResult
                  ? ctas.length
                  : selectedMarketingMessages?.ctas.length + "/" + ctas.length
              })`}</div>
            </div>
            <div className="col-md-3 chaneelGridColumnPdng marketing-msg-column customScrollBar d-flex right-border-none">
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllOptoutsChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "optout");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>
              <div style={{ marginLeft: "25px" }}>{`Opt-out (${
                searchedResult
                  ? optouts.length
                  : selectedMarketingMessages?.optOuts.length +
                    "/" +
                    optouts.length
              })`}</div>
            </div>
          </div>
          <div
            className="row selectable-marketing-messages marketing-grid customScrollBar m-0"
            ref={scrollRef}
          >
            {renderMarketingMessages(subjectLines, false, 3, "pl-0")}
            {renderMarketingMessages(preheaders, false, 3)}
            {renderMarketingMessages(ctas, false, 3)}
            {renderMarketingMessages(optouts, true, 3)}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-md-6 pl-0 marketing-msg-column">
              {" "}
              {`Tagline (${tagLines.length})`}
            </div>

            <div className="col-md-6">{`Opt-out (${optouts.length})`}</div>
          </div>
          <div className="row marketing-messages customScrollBar m-0">
            {renderMarketingMessages(tagLines, false, 6, "pl-0")}
            {renderMarketingMessages(optouts, true, 6)}
          </div>
        </div>
      );
    }
  };

  const checkIfMarketingMessageSelected = (marketingMessage) => {
    let subjectLines = selectedMarketingMessages?.subjectLines || [];
    let preheaders = selectedMarketingMessages?.preHeaders || [];
    let tagLines = selectedMarketingMessages?.tagLines || [];
    let ctas = selectedMarketingMessages?.ctas || [];
    let optOuts = selectedMarketingMessages?.optOuts || [];
    const attribute = marketingMessage.attribute.toLowerCase();
    const channelAttributes = getChannelAttributes(channelType?.toLowerCase());
    let index = -1;
    if (attribute === channelAttributes?.subjectLine?.toLowerCase()) {
      index = subjectLines.findIndex((subjectLine) => {
        return subjectLine.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.preheader?.toLowerCase()) {
      index = preheaders.findIndex((preheader) => {
        return preheader.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.tagLine?.toLowerCase()) {
      index = tagLines.findIndex((tagLine) => {
        return tagLine.id === marketingMessage.id;
      });
    }
    if (attribute === globalAttributes.cta.toLowerCase()) {
      index = ctas.findIndex((cta) => {
        return cta.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.optOut?.toLowerCase()) {
      index = optOuts.findIndex((optOut) => {
        return optOut.id === marketingMessage.id;
      });
    }

    return index > -1;
  };
  const renderMarketingMessages = (
    messages,
    isLast,
    columnWidth,
    firstcolwidth
  ) => {
    return (
      <div
        className={`${firstcolwidth} col-md-${columnWidth} ${
          isLast ? "" : "marketing-msg-column"
        }`}
      >
        {messages.map((marketingMessage) => {
          return (
            <SelectableMarketingMessageCard
              marketingMessage={marketingMessage}
              isSelected={checkIfMarketingMessageSelected(marketingMessage)}
              onSelectMarketingMessage={onSelectMarketingMessage}
              startEditingMessage={startEditingMessage}
            />
          );
        })}
      </div>
    );
  };
  return (
    <>
      <div className="pl-2 pr-2">
        {!marketingMessages && (
          <div
            className={`marketing-messages-body-in-modal marketingMsgBg `}
          ></div>
        )}

        {marketingMessages && renderColumns()}
      </div>
    </>
  );
};

export default SelectableMarketingMessages;
