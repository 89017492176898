import { Input } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../styles/components/AddCatchline.scss";

const AddCatchline = (props) => {
  const [catchlines, setCatchlines] = useState([]);
  //const [shouldRenderCatchlines, setShouldRenderCatchlines] = useState(false)

  useEffect(() => {
    setCatchlines(props.catchlines || []);
  }, [props]);
  useEffect(() => {
    if (props.atomToBeUsed && props.atomToBeUsed && props.emailElementFlag) {
      if (props.emailType === "catchline") {
        const { text, cmDocumentNumber } = props.atomToBeUsed;
        document.getElementById("input-catchline").value = text;
        if (props.options && props.options.id) {
          let input = document.getElementById(
            `input-catchline${props.options.id}`
          );
          input.value = text;
        }
      } else {
        return;
      }
    }
  }, [props.atomToBeUsed]);
  const renderCatchLines = () => {
    const items = [...catchlines] || [];
    return (
      //(items.length > 0) && (

      catchlines.map((item, index) => {
        return (
          item.text &&
          item.text.trim() !== "" && (
            <li className="catchline-item" key={item.text}>
              <Row className="align-items-center mt-1 mb-1">
                <Col className="col-md-10">
                  <div className="catch-line-item">{item.text}</div>
                </Col>
                <Col className="btn-remove">
                  <button
                    className="btn-remove"
                    id={index}
                    key={index}
                    onClick={() => removeCatchline(item, index)}
                  >
                    Remove
                  </button>
                </Col>
              </Row>
            </li>
          )
        );
      })

      //)
    );
  };

  const updateCaller = (data) => {
    //Some Special cases e.g. Add CTA Texts
    if (props.onCatchlineChange && props.options) {
      if (props.options.id) {
        props.onCatchlineChange(data, props.options.id);
      }
      return;
    }
    if (props.onCatchlineChange) {
      props.onCatchlineChange(data);
    }
  };
  const addCatchline = () => {
    let input = document.getElementById("input-catchline");

    if (props.options && props.options.id) {
      input = document.getElementById(`input-catchline${props.options.id}`);
    }
    //This condition is only for preheaders.
    if (props.emailElementFlag && props.emailElementFlag) {
      props.setEmailType(undefined);
    }
    if (!input) {
      return;
    }
    const text = input.value;
    input.value = "";
    if (text === "" || text.trim() === "") {
      return;
    }
    //Check if duplicate before inserting
    if (text) {
      const alreadyAdded = catchlines.filter((item) => {
        if (item.text) {
          return item.text.trim() === text.trim();
        }
      });
      if (alreadyAdded && alreadyAdded.length > 0) {
        return;
      }
      if (props.emailElementFlag && props.emailElementFlag) {
        setCatchlines(
          catchlines.concat({
            text: text,
            cmDocumentNumber: props.atomToBeUsed?.cmDocumentNumber,
          })
        );
        updateCaller(
          catchlines.concat({
            text: text,
            cmDocumentNumber: props.atomToBeUsed?.cmDocumentNumber,
          })
        );
        props.setAtomToBeUsed(undefined);
        return;
      }

      setCatchlines(catchlines.concat({ text }));
      updateCaller(catchlines.concat({ text }));
    }
  };

  const removeCatchline = (item, indexToBeRemoved) => {
    if (catchlines.length > 0) {
      const tempArray = catchlines.filter((catchline, index) => {
        //return (catchline !== item && index !== indexToBeRemoved)
        return index !== indexToBeRemoved;
      });
      if (props.removeCatchline && item.id && item.id !== "") {
        // if(props.options && props.options.id){
        //     props.removeCatchline(item, props.options.id)
        //     return
        // }
        props.removeCatchline(item);
      } else {
        setCatchlines(tempArray);
        updateCaller(tempArray);
      }
    }
  };

  const inputCatchLineID = () => {
    if (props.options && props.options.id) {
      return `input-catchline${props.options.id}`;
    }
    return "input-catchline";
  };

  return (
    <div>
      <div className="input-group mb-3 catch-line add-catchline">
        <input
          type="text"
          className="form-control input-catchline"
          id={inputCatchLineID()}
          aria-label="catchline"
          aria-describedby="button-addon2"
          placeholder={props.placeholder}
          onClick={() => {
            if (props.emailElementFlag && props.emailElementFlag) {
              props.setEmailType("catchline");
            }
          }}
        />
        {/* <div class="input-group-append"> */}
        <button
          type="button"
          className="button-add"
          id="add-catchline"
          onClick={() => {
            addCatchline();
          }}
        >
          Add
        </button>
        {/* </div> */}
      </div>
      <ul className="catch-lines custonScrollBar">{renderCatchLines()}</ul>
    </div>
  );
};

// fa className="btn btn-outline-secondary "
// onClick = {(() => {
//     addCatchline();})}

export default AddCatchline;
