import React, { useState, useRef, useEffect } from "react";
import ReferenceModal from "./ReferenceModal";
//import { v4 as uuidv4 } from "uuid";

const ReferenceContainer = (props) => {
  const [referenceList, setReferenceList] = useState([]);
  const [helper, setHelper] = useState(false);
  const [documentId, setDocumentId] = useState([]);
  const [anchorText, setAnchorText] = useState("");
  const [skipHelper, setSkipHelper] = useState(false);

  useEffect(() => {
    setReferenceList(props.referenceList);
  }, [props.referenceList]);

  const saveReferenceAnchor = (docId, anchorCoordinates) => {
    if (props.id === "supporting-claim") {
      props.setSelectedSuppBox(3);
      props.setSelectedSuppRow(props.rowIndex);
      setDocumentId(docId);
      setAnchorText(anchorCoordinates);
      setHelper(true);
    } else if (props.id === "supporting-visual") {
      props.setSelectedBox(4);
      props.setSelectedRow(props.rowIndex);
      setDocumentId(docId);
      setAnchorText(anchorCoordinates);
      setHelper(true);
    } else if (props.id === "primary-claim") {
      setAnchorText(anchorCoordinates);
      setDocumentId(docId);
      setHelper(true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (helper) {
      if (props.id === "supporting-claim") {
        anchorText.forEach((coordinate, index) => {
          props.saveAnchorSupportingClaim(
            documentId,
            coordinate,
            index === anchorText.length - 1 ? true : false
          );
        });
      } else if (props.id === "supporting-visual") {
        anchorText.forEach((coordinate, index) => {
          props.saveAnchorSupportingVisual(
            documentId,
            coordinate,
            index === anchorText.length - 1 ? true : false
          );
        });
      } else if (props.id === "primary-claim") {
        anchorText.forEach((coordinate, index) => {
          props.saveAnchorPrimaryClaim(
            documentId,
            coordinate,
            index === anchorText.length - 1 ? true : false
          );
        });
      } else {
        return;
      }
    }
    setHelper(false);
  }, [helper]);

  const handleSkipAnchor = (docId, selectedAnchorText) => {
    if (props.id === "supporting-claim") {
      props.setSelectedSuppBox(3);
      props.setSelectedSuppRow(props.rowIndex);
      setDocumentId(docId);
      setAnchorText(selectedAnchorText);
      setSkipHelper(true);
    } else if (props.id === "supporting-visual") {
      props.setSelectedBox(4);
      props.setSelectedRow(props.rowIndex);
      setDocumentId(docId);
      setAnchorText(selectedAnchorText);
      setSkipHelper(true);
    } else if (props.id === "primary-claim") {
      setDocumentId(docId);
      setSkipHelper(true);
      setAnchorText(selectedAnchorText);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (skipHelper) {
      if (props.id === "supporting-claim") {
        props.skipAnchorSupportingClaim(
          documentId,
          anchorText,
          props.anchorAddedIn
        );
      } else if (props.id === "supporting-visual") {
        props.skipAnchorSupportingVisual(
          documentId,
          anchorText,
          props.anchorAddedIn
        );
      } else if (props.id === "primary-claim") {
        props.skipAnchorPrimaryClaim(
          documentId,
          anchorText,
          props.anchorAddedIn
        );
      } else {
        return;
      }
    }
    setSkipHelper(false);
  }, [skipHelper]);

  return (
    <>
      <ReferenceModal
        handleCloseModal={props.handleClose}
        referenceList={referenceList}
        saveReferenceAnchor={saveReferenceAnchor}
        id={props.id}
        setSelectedSuppBox={props.setSelectedSuppBox}
        setSelectedSuppRow={props.setSelectedSuppRow}
        setSelectedBox={props.setSelectedBox}
        setSelectedRow={props.setSelectedRow}
        referenceDocuments={props.referenceDocuments}
        addOrModifyanchorData={props.addOrModifyanchorData}
        handleSkipAnchor={handleSkipAnchor}
        anchorAddedIn={props.anchorAddedIn}
        sourceType={props.sourceType}
        findSupportingClaimOrVisualPosition={
          props.findSupportingClaimOrVisualPosition
        }
        moduleType={props.moduleType}
      />
    </>
  );
};
export default ReferenceContainer;
