import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import "../../styles/components/MlrFeedback/Pdf.scss";

const RenderMlrFeedback = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const { pdfFileUrl, pdfWidth, showPageNumber, textLayer } = props;
  const [numPages, setNumPages] = useState(null);
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    //This is only for reference anchor, so added flag.
    if (props.referenceFlag && props.referenceFlag) {
      props.getNumberOfPages(nextNumPages);
    }
  }
  return (
    <>
      <Document
        file={pdfFileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        //options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div className="react-pdfPage" key={`page_${index + 1}`}>
            <Page
              pageNumber={index + 1}
              //renderMode="svg"
              renderTextLayer={textLayer}
            />
            {showPageNumber && (
              <div className="pdfPageNumBlock">
                Page {index + 1} of {numPages}
              </div>
            )}
          </div>
        ))}
      </Document>
    </>
  );
};

export default RenderMlrFeedback;
