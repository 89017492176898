import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import HeaderCreateModule from "../components/HeaderCreateModule";
import videoIcon from "../images/videoColor.svg";
import "../styles/pages/MLRSubmissionPackage.scss";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import {
  getModuleDetailsById,
  getModulesBusinessRules,
  downloadModule,
  getDenovoModulesByBrandAndCampaign,
} from "../services/apis";
import { toast } from "react-toastify";
import errComponentToRender from "../Uitls/apiErrorHandling";
import { useHistory } from "react-router-dom";
import ModuleSpecificInfo from "../components/MlrSubmission/ModuleSpecificInfo";
import BusinessRulesSpecificInfo from "../components/MlrSubmission/BusinessRulesSpecificInfo";
import ClaimAndVisualsSpecificInfo from "../components/MlrSubmission/ClaimAndVisualsSpecificInfo";
import ChannelSpecificInfo from "../components/MlrSubmission/ChannelSpecificInfo";
import {
  routes,
  getModuleInfoFromLocalStorage,
  moduleTypes,
  getDownloadModuleCss,
} from "../Uitls/Util";
import LogoutCTA from "../Pages/LogoutCTA";
import { sortClaimsAndVisualsByRecordIndex } from "./ModuleDetails";
const styles = require("../styles/pages/MLRSubmissionPackage.scss");
const MLRSubmissionPackage = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [moduleId, setModuleId] = useState(undefined); //useState('515a2f16-156a-4316-bd52-2d5b8237c3e8');
  //const [assetId, setAssetId] = useState(undefined)
  const [module, setModule] = useState(undefined);
  const [bModules, setbModules] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [moduleSpecificJsx, setModuleSpecificJsx] = useState(undefined);
  const [businessSpecificJsx, setBusinessSpecificJsx] = useState(undefined);
  const [claimsSpecificJsx, setClaimsSpecificJsx] = useState(undefined);
  const [channelSpecificJsx, setChannelSpecificJsx] = useState(undefined);
  //const [moduleType, setModuleType] = useState(undefined)
  useEffect(() => {
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      setModuleId(moduleInfo.moduleId);
    }
    // if (moduleInfo && moduleInfo.assetId) {
    //   setAssetId(moduleInfo.assetId);
    // }
    // if(moduleInfo && moduleInfo.creationMethodType){
    //   setModuleType(moduleInfo.creationMethodType)
    // }
  }, []);

  useEffect(() => {
    getModuleDetails();
  }, [moduleId]);

  const getModuleDetails = () => {
    if (!moduleId) {
      return;
    }

    setIsloading(true);
    getModuleDetailsById(moduleId, (res, error, errorCode) => {
      setIsloading(false);
      if (!error && !errorCode && res) {
        setModule(res.data);
        const moduleType = res.data.creationMethodType;
        if (moduleType && moduleType.toLowerCase() === moduleTypes.denovo) {
          denovoBusinessRuleModules(
            res.data.brandName,
            // res.data.campaign
            res?.data?.assetDetails?.market
          );
        } else {
          getTacticBusinessRuleModules(res.data.storageAssetId);
        }
      } else {
        if (errorCode) {
          showError(errorCode);
        }
      }
    });

    // if(assetId){
    //   getModulesBusinessRules(assetId, (response, err, errorCode) => {
    //     setIsloading(false);
    //     if (err) {
    //       if (errorCode) {
    //         showError(errorCode);
    //       }
    //     } else {
    //       setbModules(response.data);
    //       // setSupportingClaimAtoms(response.data);
    //     }
    //   });
    // }
  };

  const getTacticBusinessRuleModules = (assetId) => {
    if (assetId) {
      getModulesBusinessRules(assetId, (response, err, errorCode) => {
        setIsloading(false);
        if (err) {
          showError(errorCode);
        } else {
          setbModules(response.data);
        }
      });
    } else {
      setIsloading(false);
    }
  };

  const denovoBusinessRuleModules = (brand, market) => {
    if (!brand || !market) {
      setIsloading(false);
      return;
    }

    getDenovoModulesByBrandAndCampaign(
      { brand, market },
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          setbModules(response.data);
          return;
        }
        showError(errCode);
      }
    );
  };

  const getCss = () => {
    return getDownloadModuleCss();
  };

  const downloadPDFModule = () => {
    let htmlString = "";
    if (moduleSpecificJsx) {
      htmlString = htmlString + renderToString(moduleSpecificJsx);
    }
    if (businessSpecificJsx) {
      htmlString = htmlString + renderToString(businessSpecificJsx);
    }
    if (claimsSpecificJsx) {
      htmlString = htmlString + renderToString(claimsSpecificJsx);
    }
    // if (channelSpecificJsx) {
    //   htmlString = htmlString + renderToString(channelSpecificJsx);
    // }

    if (!htmlString || htmlString.trim() !== "") {
      // htmlString = htmlString.replaceAll(`<table class="table allow-border-in-first-row claim-specific"`,
      // `<table class='table allow-border-in-first-row claim-specific' style = "tableLayout : 'fixed'" `)
      // htmlString = htmlString.replaceAll(`<td class="claim-specific"`,
      // "<td width = '250' ")
      // htmlString = htmlString.replaceAll("<img", `<img style="width:200px"`)
      const finalHtml =
        "<html><head> <style>" +
        getCss() +
        "</style></head><body> " +
        htmlString +
        "</body></html>";
      console.log("Final HTML", finalHtml);
      let formData = new FormData();
      formData.append("html", finalHtml);
      formData.append("fileName", module.moduleCode);
      setIsloading(true);
      downloadModule(moduleId, formData, (res, err, errorCode) => {
        setIsloading(false);
        if (res && res.data) {
          window.open(res.data, "_blank");
        } else {
          if (errorCode) {
            showError(errorCode);
          }
        }
      });
    }
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const renderModuleSpecificInfo = () => {
    if (!module) {
      return <div></div>;
    }
    const jsx = (
      <div className="module-specific-info">
        <span className="head">Module-specific information</span>
        <ModuleSpecificInfo module={module} setJsx={setModuleSpecificJsx} />
        {module && module.moduleBusinessRules && (
          <BusinessRulesSpecificInfo
            module={module}
            bModules={bModules}
            setJsx={setBusinessSpecificJsx}
          />
        )}
        {module &&
          ((module.moduleClaims && module.moduleClaims.length > 0) ||
            (module.moduleVisuals && module.moduleVisuals.length > 0)) && (
            <ClaimAndVisualsSpecificInfo
              module={module}
              setJsx={setClaimsSpecificJsx}
              sortClaimsAndVisualsByRecordIndex={
                sortClaimsAndVisualsByRecordIndex
              }
            />
          )}
        {/* 
        {module &&
          ((module.moduleChannels && module.moduleChannels.length > 0) ||
            (module.moduleCtas && module.moduleCtas.length > 0)) && (
            <ChannelSpecificInfo
              module={module}
              setJsx={setChannelSpecificJsx}
            />
          )} */}
      </div>
    );
    return jsx;
  };

  const onSelectSearchedModule = (id) => {
    //debugger
    if (id && moduleId !== id) {
      setModuleSpecificJsx(undefined);
      setBusinessSpecificJsx(undefined);
      setClaimsSpecificJsx(undefined);
      setChannelSpecificJsx(undefined);
      setModuleId(id);
    }
  };
  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        <HeaderCreateModule onModuleSelect={onSelectSearchedModule} />
      </div>
      <CircularProgressBar isloading={isloading} />

      <Container fluid className="headeBgWhite">
        <Row className="align-items-center headeBgWhite mlr-sub-head no-gutters">
          <Col>
            <div className="d-flex text-mlr-pkg-sub">
              <div className="createModuleTitle text-mlr-pkg-sub">
                {i18next.t("generalText.mlrSubmissionPackage")}
              </div>
              <div className="videoIconCm">
                <img src={videoIcon} alt="" />
                <span>View Tutorial</span>
              </div>
            </div>
          </Col>
          <Col className="text-right">
            <Button
              variant="outline-secondary btnClose"
              onClick={(event) => {
                history.push(routes.dashboard);
              }}
            >
              {props.btnClose || i18next.t("generalText.close")}
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="mlr-main">
          <button
            className="btn-download"
            onClick={() => {
              downloadPDFModule();
            }}
          >
            {i18next.t("generalText.download")}
          </button>
        </Row>
      </Container>

      <Container fluid>
        <div className="pageContentCol mlr-main-content ">
          {renderModuleSpecificInfo()}
        </div>
      </Container>
    </>
  );
};

export default MLRSubmissionPackage;
