import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import infoIcon from "../../images/infoIcon.svg";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import {
  channelTypesAndAttributes,
  isModularContent,
  marketingMessageSourceType,
} from "../../Uitls/Util";
import ModalHeader from "../../Uitls/ModalHeader";

const ChannelSelectionComponent = ({
  assetDataType,
  closestylizeChannelSelectionModal,
  sourceType,
  brand,
  campaign,
  setSelectedChannel,
  selectedChannel,
  onClickProceed,
}) => {
  const { t } = useTranslation();

  const [channelTypeList, setChannelTypeList] = useState([]);
  const ChannelAttributeAndType = [
    {
      channelType: channelTypesAndAttributes.email.name,
      isSelected: false,
    },
    {
      channelType: channelTypesAndAttributes.eDetail.name,
      isSelected: false,
    },
    {
      channelType: channelTypesAndAttributes.banner.name,
      isSelected: false,
    },
  ];
  useEffect(() => {
    const data = ChannelAttributeAndType.map((item, index) => {
      return {
        value: item.channelType,
        isSelected: index === 0 ? false : true,
      };
    });
    setChannelTypeList(data);
  }, []);

  const onDropDownListChange = (value) => {
    setSelectedChannel(value);
  };

  return (
    <>
      <div className="cmHeaderRow">
        <ModalHeader
          title={t("Create  Stylized Module")}
          subtitle={"Select Channel Type to Proceed"}
          subtitleIcon={infoIcon}
          headerData={{}}
          isBottomBorderNeeded={true}
        />
      </div>
      <div className="row align-items-center">
        {!isModularContent(assetDataType) && (
          <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
            <TextField
              className=""
              id="brand"
              label="Brand"
              variant="outlined"
              fullWidth={true}
              disabled={true}
              value={brand}
            />
          </div>
        )}
        {isModularContent(assetDataType) && (
          <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
            <TextField
              className=""
              id="Brand"
              label="Brand"
              variant="outlined"
              fullWidth={true}
              disabled={true}
              value={brand}
            />
          </div>
        )}
        <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
          <TextField
            className=""
            id="campaign"
            label="Campaign"
            variant="outlined"
            fullWidth={true}
            disabled={true}
            value={campaign}
          />
        </div>
        <div className="col-md-12 mt-3 tf-and-drp-dwn-with-label-disabled">
          <TextField
            className=""
            id="DAMS"
            label="DAMS"
            variant="outlined"
            fullWidth={true}
            disabled={true}
            value={marketingMessageSourceType[sourceType]?.name || sourceType}
          />
        </div>
        <div className="col-md-12 mt-3 channelTypeDropDown">
          <DropDownWithLabel
            class="mt-0"
            options={{
              name: "Channel Type",
              label: "Channel Type",
              value: selectedChannel,
              items: channelTypeList,
              handleChange: (e) => {
                onDropDownListChange(e.target.value);
              },
              disabled: false,
              isDataTypeObject: true,
            }}
          />
        </div>
      </div>

      <div className="row align-items-center mt-3">
        <div className="col-md-6 pl-1">
          <button
            type="button"
            className="btn btn-link text-uppercase"
            onClick={() => {
              closestylizeChannelSelectionModal();
            }}
          >
            CANCEL
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            type="button"
            className="btn btn-primary text-uppercase"
            disabled={!(selectedChannel && brand && campaign)}
            onClick={() => {
              onClickProceed(true);
            }}
            style={{ width: "136px" }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </>
  );
};

export default ChannelSelectionComponent;
