import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import DropDownWithLabel from "../DropDownWithLabel";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import "../../styles/components/CloneModule.scss";
import "../../styles/components/BulkLocalizationModal.scss";

const LocalizeModules = ({
  hideModal,
  marketDataSource,
  campaigns,
  languageDataSource,
  audienceDataSource,
  handleBack,
  genericInformation,
  showBulkModulesValidaionScreen,
  selectedModuleIdForGroupCreation,
  dataForTranslationCreationGroup,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [disbleProceed, setDisableProceed] = useState(true);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);
  const [othersfilled, setOthersFilled] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langaugeSelected, setLangaugeSelected] = useState([]);

  const handleSubmit = () => {
    let market = selectedMarket;
    let data = {
      brand: genericInformation.brand,
      campaign: genericInformation.campaign,
      market: market.displayName,
      audience: genericInformation.audience,
      dosing: genericInformation.dosing,
      description: genericInformation.description,
      modulesByLanguage: langaugeSelected,
      brandId: genericInformation.brandId,
      campaignId: genericInformation.campaignId,
    };
    let validationData = {
      brand: genericInformation.brand,
      language: langaugeSelected.map((item) => item.language),
      moduleList: selectedModuleIdForGroupCreation,
    };
    showBulkModulesValidaionScreen(data, validationData);
  };
  const handleCancel = () => {
    hideModal();
  };

  useEffect(() => {
    const lang = [...languageDataSource].map((lang) => {
      const displayName = `${lang.languageCode} - ${lang.language}`;
      lang["displayName"] = displayName;
      return lang;
    });
    setLanguages(lang);
    if (genericInformation) {
      setSelectedAudience(genericInformation.audience);
    }
    let defaultLanguage = {
      displayName: "Select langauage",
      id: "dflg1234",
      language: "default",
    };
    lang.splice(0, 0, defaultLanguage);
    if (dataForTranslationCreationGroup) {
      let prevSelectedLanguages = [
        ...dataForTranslationCreationGroup.modulesByLanguage,
      ];
      setLangaugeSelected(prevSelectedLanguages);
    }
  }, [languageDataSource, genericInformation, dataForTranslationCreationGroup]);

  // const showError = (value) =>
  //   toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  // const getCampaigns = () => {
  //   if (campaigns) {
  //     const data = [...campaigns].map((campaign) => {
  //       return campaign.campaignName;
  //     });
  //     return data;
  //   }
  //   return [];
  // };

  const validateMarket = () => {
    if (!selectedMarket) {
      return false;
    }
    return true;
  };

  const validate = (values) => {
    const errors = {};

    if (!values.moduleName || values.moduleName.trim() === "") {
      errors.moduleName = i18next.t("cloneModule.moduleNameError");
    }

    const campaignErr = i18next.t("uploadAssetLabel.campaignError");
    if (!values.Campaign) {
      errors.campaign = campaignErr;
    } else if (values.Campaign.trim() === "") {
      errors.campaign = campaignErr;
    }

    if (Object.keys(errors).length === 0) {
      setOthersFilled(true);
      if (validateMarket()) {
        setDisableProceed(false);
      } else {
        setDisableProceed(true);
      }
    } else {
      setOthersFilled(false);
      setDisableProceed(true);
    }

    return errors;
  };

  const getInitialvalues = () => {
    let initailValues = {
      Campaign: getDefaultValue("campaign"),
      audience: getDefaultValue("audience"),
      market: dataForTranslationCreationGroup
        ? getMarketByName(dataForTranslationCreationGroup.market)
        : genericInformation
        ? getMarketByName(genericInformation.market)
        : "", //getDefaultValue("market") || "",
      dosing: getDefaultValue("dosing") || "",
      description: getDefaultValue("description") || "",
    };

    return initailValues;
  };

  const getDefaultValue = (type) => {
    if (!genericInformation) {
      return undefined;
    }
    let value = "";
    switch (type) {
      case "campaign":
        value = genericInformation.campaign || "";
        break;
      case "market":
        value = getMarketByName(genericInformation.market) || "";
        break;
      case "audience":
        value = genericInformation.audience ? genericInformation.audience : [];
        break;
      case "description":
        value = genericInformation.description || "";
        break;
      case "dosing":
        value = genericInformation.dosing || "";
        break;
      default:
        value = "";
    }
    return value;
  };

  const getMarketByName = (marketName) => {
    const marketDataSourceData = marketDataSource;
    let selectedMarket = undefined;
    if (marketDataSourceData && marketDataSourceData.length > 0 && marketName) {
      selectedMarket = marketDataSourceData.find((market) => {
        return (
          market.displayName === marketName ||
          market.name === marketName ||
          market.code === marketName ||
          market.shortName === marketName
        );
      });
    }
    return selectedMarket;
  };

  const handleMarketChange = (market) => {
    setSelectedMarket(market);
    if (market && othersfilled) {
      setDisableProceed(false);
    } else {
      setDisableProceed(true);
    }
  };

  const isThisAudienceAdded = (audience) => {
    if (audience) {
      if (
        selectedAudience &&
        selectedAudience.findIndex(
          (aud) => aud.trim().toLowerCase() === audience.trim().toLowerCase()
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const handleLanguageChange = (languageData) => {
    if (!languageData) return;
    let defualtLanguage = languages.find(
      (Element) => Element.language === "default"
    );
    setSelectedLanguage(defualtLanguage);
    if (
      langaugeSelected.findIndex((item) => {
        return item.icbLanguageCode === languageData.icbCode;
      }) === -1 &&
      languageData.language !== "default"
    ) {
      let data = [...langaugeSelected];
      let language = {
        language: languageData.language,
        languageCode: languageData.languageCode,
        icbLanguageCode: languageData.icbCode,
        moduleIdList: selectedModuleIdForGroupCreation,
      };
      data.push(language);
      setLangaugeSelected(data);
    }
  };
  const removeFromLanguageList = (index) => {
    let data = [...langaugeSelected];
    data.splice(index, 1);
    setLangaugeSelected(data);
  };
  const checkForAllValues = () => {
    if (!genericInformation) return;
    let market = selectedMarket;
    if (
      langaugeSelected.length !== 0 &&
      market &&
      genericInformation.campaign &&
      genericInformation.description &&
      genericInformation.dosing &&
      genericInformation.audience.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getMarketDataOptions = () => {
    return marketDataSource.map((market) => {
      if (
        genericInformation &&
        market.displayName === genericInformation.market
      ) {
        market.isNotSelectable = true;
      }
      return market;
    });
  };

  return (
    <>
      <div className="modalClonePopUp bulk-lang-dropdown mb-0">
        <div className="d-flex bulkLocalizationTitle">
          <div className="clone-module-pop-title">
            {i18next.t("generalText.localize")}
          </div>
          <div className="bulk-close-button">
            <button
              className="btn text-uppercase btn-cancel-clone cancel-clone-btn"
              onClick={() => {
                handleCancel();
              }}
            >
              {t("generalText.close").toUpperCase()}
            </button>
          </div>
        </div>

        <div>
          <CircularProgressBar isloading={isloading} />
          <Formik
            initialValues={getInitialvalues()}
            validate={validate}
            onSubmit={(values, { resetForm }, initialValues) => {}}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              status,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  className="clone-module-text-field"
                  id="Campaign"
                  label={t("uploadAssetLabel.campaignItWasUsed")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.Campaign}
                  disabled={true}
                />
                <DropDownWithSearch
                  options={{
                    value: selectedMarket,
                    name: t("uploadAssetLabel.market"),
                    label: t("uploadAssetLabel.market"),
                    items: getMarketDataOptions(),
                    handleChange: handleMarketChange,
                  }}
                />
                <DropDownWithSearch
                  options={{
                    //value: selectedLanguage,
                    name: t("generalText.language"),
                    label: t("generalText.language"),
                    items: languages,
                    handleChange: handleLanguageChange,
                    placeHolder: "Select Language",
                  }}
                />
                {langaugeSelected &&
                  langaugeSelected.map((item, index) => {
                    return (
                      <button
                        className="btn selectedBrandsChipBulkLocalization mr-1"
                        onClick={() => removeFromLanguageList(index)}
                        key={index}
                      >
                        {item.language}
                        <span className="closeChipSymbolBulkLocalization">
                          X
                        </span>
                      </button>
                    );
                  })}

                <div className="audience">
                  <span className="audience">
                    {t("uploadAssetLabel.audience")}
                  </span>
                </div>
                <div>
                  {audienceDataSource.map((item) => {
                    return (
                      <div
                        className="custom-control custom-checkbox custom-control-inline aud-check-box-with-label"
                        key={item}
                      >
                        <input
                          type="checkbox"
                          name={item}
                          className="custom-control-input audience-check-box"
                          id={item}
                          checked={isThisAudienceAdded(item)}
                          onChange={(event) => {
                            handleChange(event);
                            if (event.target.checked) {
                              setSelectedAudience(
                                selectedAudience.concat(item)
                              );
                            } else {
                              const newSelected = [...selectedAudience].filter(
                                (aud) => {
                                  return (
                                    aud.trim().toLowerCase() !==
                                    item.trim().toLowerCase()
                                  );
                                }
                              );
                              setSelectedAudience(newSelected);
                            }
                            event.target.checked = !event.checked;
                          }}
                          disabled={true}
                        ></input>
                        <label
                          className="custom-control-label aud-check-label localizeAudience"
                          for={item}
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <TextField
                  className="clone-module-text-field"
                  id="dosing"
                  label={t("uploadAssetLabel.dosing")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.dosing}
                  helperText={errors.dosing && touched.dosing && errors.dosing}
                  error={errors.dosing && touched.dosing ? true : false}
                  disabled={true}
                />
                <TextField
                  className="clone-module-text-field"
                  id="description"
                  label={t("uploadAssetLabel.description")}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  value={values.description}
                  helperText={
                    errors.description &&
                    touched.description &&
                    errors.description
                  }
                  error={
                    errors.description && touched.description ? true : false
                  }
                  disabled={true}
                />
              </Form>
            )}
          </Formik>
        </div>

        <div className="row cloneBtn mt-5">
          <div className="col-12">
            <button
              className="btn text-uppercase btn-cancel-clone ml-1"
              onClick={handleBack}
            >
              {t("generalText.back").toUpperCase()}
            </button>
            <button
              type="submit"
              className="btn btn-clone text-uppercase mr-3"
              onClick={handleSubmit}
              disabled={checkForAllValues() ? "" : "disable"}
            >
              {t("generalText.proceed").toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalizeModules;
