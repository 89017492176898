import React, { useRef } from "react";
import BackButton from "../../images/BackButton.svg";
import { getMlrStatusIcon } from "../../Uitls/Util";
import "../../styles/components/ChannelGridPreview.scss";
import RenderMlrFeedback from "../../components/MlrFeedback/RenderMlrFeedback";

const ChannelGridpreview = ({ brand, campaignName, ...props }) => {
  const { pdfPreviewData, goToDamsScreen } = props;
  const mlrStatusIcon = () => {
    return (
      <img
        src={getMlrStatusIcon()}
        alt=""
        style={{ marginBottom: "3px" }}
      ></img>
    );
  };
  const elementRef = useRef(null);
  const downloadPdf = () => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = pdfPreviewData.pdfURL;
    anchor.target = "_blank";
    anchor.download = "channelGrid";
    // Append to the DOM
    document.body.appendChild(anchor);
    // Trigger `click` event
    anchor.click();
    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row ch-grid-preview-container pdfPreviewRowmarign align-items-center">
          <div className="col-md-6">
            <div className="d-flex pdfPreviewPl">
              <img
                className="previewBack"
                src={BackButton}
                alt=""
                onClick={() => goToDamsScreen()}
              />
              <span className="ch-grd-prv-txt">Submission Document </span>
              <span className="preview-text-style-1">PREVIEW</span>
            </div>
          </div>
          <div className="col-md-6 text-right pdfPreviewPr">
            <button
              className="btn btnDownloadPdf"
              type="button"
              onClick={downloadPdf}
            >
              DOWNLOAD
            </button>
          </div>
        </div>
        <div className="row pdfPreviewRowmarign pdfPreviewRow2">
          <div className="col-md-12">
            <div className="d-flex">
              <div>
                <span className="headers-text mr-5">
                  Submission Document ID
                </span>
                <div>
                  <span className="headers-data-text">
                    {pdfPreviewData.name}
                  </span>
                </div>
              </div>
              <div>
                <span className="headers-text mr-5">Brand</span>
                <div>
                  <span className="headers-data-text">
                    {pdfPreviewData.brand}
                  </span>
                </div>
              </div>
              <div>
                <span className="headers-text mr-5">Campaign</span>
                <div>
                  <span className="headers-data-text">
                    {pdfPreviewData.campaign}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 pdfPreviewContainer" ref={elementRef}>
            <div className="customScrollBar pdfPreviewContainerScroll">
              <RenderMlrFeedback
                pdfFileUrl={pdfPreviewData.pdfURL}
                pdfWidth={
                  elementRef.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
                textLayer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChannelGridpreview;
