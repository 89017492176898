import React, { useState, useEffect, useRef } from "react";
import EmailHeaderElement from "./EmailHeaderElement";
import { Col } from "react-bootstrap";
import i18next from "i18next";
import "../styles/components/emailHeader.scss";

const EmailHeader = (props) => {
  const referencesValid = useRef(null);
  const [emailHeader, setEmailHeader] = useState([
    {
      headerText: "",
      restrictionForUse: "",
      rules: "",
    },
  ]);
  useEffect(() => {
    if (props.emailHeadersData.length) {
      setEmailHeader(props.emailHeadersData);
    }
  }, [props.emailHeadersData]);
  const addEmailHeaderRow = () => {
    setEmailHeader([
      ...emailHeader,
      {
        headerText: "",
        restrictionForUse: "",
        rules: "",
      },
    ]);
  };

  const removeEmailHeaderRow = (rowPosition) => {
    if (rowPosition < 0) {
      return;
    } else {
      const newArray = emailHeader.filter(
        (item, index) => rowPosition !== index
      );
      setEmailHeader([...newArray]);
      props.upDateEmailHeaderData(newArray);
    }
  };
  const handleHeaderText = (event, rowIndex) => {
    if (rowIndex < 0) {
      return;
    } else {
      referencesValid.current.innerHTML = event;
      const text = referencesValid.current.innerText;
      let newArray = [...emailHeader];
      if (text && text.trim().length) {
        newArray[rowIndex].headerText = event;
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      } else {
        newArray[rowIndex].headerText = "";
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      }
    }
  };

  const handleHeaderRestrictions = (event, rowIndex) => {
    if (rowIndex < 0) {
      return;
    } else {
      referencesValid.current.innerHTML = event;
      const text = referencesValid.current.innerText;
      let newArray = [...emailHeader];
      if (text && text.trim().length) {
        newArray[rowIndex].restrictionForUse = event;
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      } else {
        newArray[rowIndex].restrictionForUse = "";
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      }
    }
  };

  const handleHeaderRules = (event, rowIndex) => {
    if (rowIndex < 0) {
      return;
    } else {
      referencesValid.current.innerHTML = event;
      const text = referencesValid.current.innerText;
      let newArray = [...emailHeader];
      if (text && text.trim().length) {
        newArray[rowIndex].rules = event;
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      } else {
        newArray[rowIndex].rules = "";
        setEmailHeader(newArray);
        props.upDateEmailHeaderData(newArray);
      }
    }
  };

  return (
    <>
      {emailHeader.length !== 0 ? (
        emailHeader.map((row, index) => (
          <EmailHeaderElement
            key={index}
            rowIndex={index}
            row={row}
            upDateHeaderText={handleHeaderText}
            removeEmailHeaderRow={removeEmailHeaderRow}
            upDateHeaderRestrictions={handleHeaderRestrictions}
            upDateHeaderRules={handleHeaderRules}
            addEmailHeaderRow={addEmailHeaderRow}
            emailHeaderLength={emailHeader}
          />
        ))
      ) : (
        <Col xs className="col-md-12 text-right">
          <button
            className="btn btn-link pr-0"
            style={{ float: "right" }}
            onClick={() => {
              addEmailHeaderRow();
            }}
          >
            <span>{i18next.t("emailHeaders.add")}</span>
          </button>
        </Col>
      )}
      <div className="d-none" ref={referencesValid}></div>
    </>
  );
};
export default EmailHeader;
