import React from "react";
import imgBusinessRulesNotDefind from "../../images/business_rules_not_defined.svg";
import { useTranslation } from "react-i18next";

const BusinessRules = ({ module }) => {
  const { t } = useTranslation();
  const openVeeva = (url) => {
    window.open(url, "_blank");
  };

  const checkIfModuleHasRules = () => {
    if (!module?.rules || module?.rules?.length === 0) {
      return false;
    }
    let rules = [];
    const supClaimsBRules = module.rules?.filter((el) => {
      return el.ruleType === "supportingGroup";
    })[0];
    const supportingVisRules = module.rules?.filter((el) => {
      return el.ruleType === "supportingVisual";
    })[0];
    rules = rules
      .concat(supportingVisRules.optionalWithProClaim)
      .concat(supportingVisRules.mandatoryWithProClaim)
      .concat(supportingVisRules.optionalWithSupClaim)
      .concat(supportingVisRules.mandatoryWithSupClaim)
      .concat(supClaimsBRules.mandatory)
      .concat(supClaimsBRules.optional)
      .concat(supClaimsBRules.eitherOne);
    return rules.length > 0;
  };

  const renderBusinessRules = () => {
    const supClaimsBRules = module.rules?.filter((el) => {
      return el.ruleType === "supportingGroup";
    })[0];
    const supportingVisRules = module.rules?.filter((el) => {
      return el.ruleType === "supportingVisual";
    })[0];
    return (
      <div className="business-rules">
        {supportingVisRules.optionalWithProClaim?.length > 0 && (
          <ul className="rule">
            {" "}
            <li className="">
              <span className="rule-head">
                Supporting visuals - Optional with promotional claim
              </span>
              {supportingVisRules.optionalWithProClaim.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supportingVisRules.mandatoryWithProClaim?.length > 0 && (
          <ul className="rule">
            {" "}
            <li>
              <span className="rule-head">
                Supporting visual  - Mandatory with promotional claim
              </span>
              {supportingVisRules.mandatoryWithProClaim.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supportingVisRules.optionalWithSupClaim?.length > 0 && (
          <ul className="rule">
            {" "}
            <li className="">
              <span className="rule-head">
                Supporting visuals - Optional with supporting claim
              </span>
              {supportingVisRules.optionalWithSupClaim.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supportingVisRules.mandatoryWithSupClaim?.length > 0 && (
          <ul className="rule">
            {" "}
            <li className="">
              <span className="rule-head">
                Supporting visuals - Mandatory with supporting claim
              </span>
              {supportingVisRules.mandatoryWithSupClaim.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supClaimsBRules.optional?.length > 0 && (
          <ul className="rule">
            {" "}
            <li>
              <span className="rule-head">
                Supporting Claim group - Optional
              </span>
              {supClaimsBRules.optional.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supClaimsBRules.mandatory?.length > 0 && (
          <ul className="rule">
            {" "}
            <li>
              <span className="rule-head">
                Supporting Claim group - Mandatory{" "}
              </span>
              {supClaimsBRules.mandatory.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
        {supClaimsBRules.eitherOne?.length > 0 && (
          <ul className="rule">
            {" "}
            <li>
              <span className="rule-head">
                Supporting Claim group -  At least one from the group is
                mandatory
              </span>
              {supClaimsBRules.eitherOne.map((el, index) => {
                return <div className="rule-name">{el}</div>;
              })}
            </li>
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className={`${checkIfModuleHasRules() ? "" : "b-rules-not-defined"}`}>
      {!checkIfModuleHasRules() && (
        <div className="text-center">
          <img src={imgBusinessRulesNotDefind} alt="" className="image" />
          <span className="message">
            {t("generalText.businessRulesNotDefinedForThisModule")}
          </span>
        </div>
      )}
      {checkIfModuleHasRules() && renderBusinessRules()}
    </div>
  );
};

export default BusinessRules;
