import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer as MUITableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import styled from "styled-components";
import variables from "../styles/base/_universal.scss";

const TableContainer = styled(MUITableContainer).attrs(() => ({
  className: "customScrollBar",
}))`
  overflow: auto;
  max-height: 13rem;
  margin-top: 12px;
`;

const Th = styled(TableCell)`
  padding: 8px 10px 8px 10px;
  text-align: left;
  background-color: #dae5f2;
  font-size: 12px;
  color: ${variables.darkGrayColor};
  font-weight: bold;
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "")};
`;

const Td = styled(TableCell)`
  padding: 8px 10px 8px 10px;
  text-align: left;
  background-color: ${(props) => (props.isOdd ? "#f3f6fa" : "transparent")};
  font-size: 12px;
  color: ${variables.searchTextColor};
  font-weight: normal;
  border: none;
  word-break: break-all;
`;

const CheckboxTh = styled(Th)`
  width: 1rem;
  input {
    border-radius: 1px;
    border: solid 1px #949494;
    display: inline-block;
    vertical-align: middle;
  }
`;

const CheckboxTd = styled(Td)`
  input {
    border-radius: 1px;
    border: solid 1px #949494;
    display: inline-block;
    vertical-align: middle;
    cursor: ${(props) =>
      props.isDisabled
        ? "not-allowed"
        : "pointer"}; // Set cursor based on isDisabled prop
    pointer-events: ${(props) =>
      props.isDisabled
        ? "none"
        : "auto"}; // Disable pointer events based on isDisabled prop
  }
  &:hover {
    input:disabled {
      border-color: #949494; /* Optional: change border color on hover */
      /* Show tooltip on hover */
      &:hover {
        &:before {
          content: attr(title);
          position: absolute;
          background-color: #333;
          color: #fff;
          padding: 4px 8px;
          border-radius: 4px;
          z-index: 1;
          white-space: nowrap;
        }
      }
    }
  }
`;

const StylizedTable = ({
  headers,
  rows,
  onCheckboxChange,
  unselectAll,
  selectedRows,
  disabledIds,
  initialReusableTexts,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  //   const handleCheckboxChange = (checked) => {
  //     setSelectAll(!selectAll);
  //     unselectAll(checked);
  //   };
  const handleCheckboxChange = (checked) => {
    setSelectAll(!selectAll);
    unselectAll(checked);
  };
  useEffect(() => {
    if (
      selectedRows?.length + disabledIds?.length ===
        initialReusableTexts?.length &&
      initialReusableTexts?.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, initialReusableTexts, disabledIds]);

  const isTableRowSelected = (rowId) => {
    return (
      selectedRows?.findIndex((elId) => rowId === elId) > -1 ||
      disabledIds?.findIndex((elId) => rowId === elId) > -1
    );
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <CheckboxTh>
              <input
                type="checkbox"
                checked={
                  disabledIds?.length > 0 &&
                  disabledIds?.length === initialReusableTexts?.length
                    ? true
                    : selectAll
                }
                onChange={(e) => handleCheckboxChange(e.target.checked)}
                disabled={
                  disabledIds?.length > 0 &&
                  disabledIds?.length === initialReusableTexts?.length
                }
              />
            </CheckboxTh>
            {headers?.map((header, index) => (
              <Th key={index} width={header.width}>
                {header.lable}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((rowData, rowIndex) => (
            <TableRow key={rowIndex}>
              <CheckboxTd
                isOdd={rowIndex % 2 !== 0}
                isDisabled={disabledIds?.includes(rowData[headers[0]?.field])}
                title={
                  disabledIds?.includes(rowData[headers[0]?.field])
                    ? "Already added in the Module"
                    : ""
                }
              >
                <input
                  type="checkbox"
                  checked={isTableRowSelected(rowData[headers[0]?.field])}
                  //checked={selectedReusableTextIds.includes(item.reusableTextId)}
                  onChange={(e) => onCheckboxChange(rowData, e.target.checked)}
                  disabled={disabledIds?.includes(rowData[headers[0]?.field])}
                />
              </CheckboxTd>
              {headers.map(({ field }, cellIndex) => (
                <Td key={cellIndex} isOdd={rowIndex % 2 !== 0}>
                  {/* {rowData[field] ? rowData[field] : "-"} */}
                  {/* {field === "id"
                    ? rowData?.reusableTextType
                    : rowData[field]
                    ? rowData[field]
                    : "-"} */}
                  {field === "reusableTextId"
                    ? rowData?.reusableTextType
                    : field === "description" &&
                      rowData?.reusableTextType === "CTA"
                    ? rowData?.labels?.join(", ")
                    : rowData[field]
                    ? rowData[field]
                    : "-"}
                </Td>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StylizedTable;
