//Import React Dependecies
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { getContentmatrix, getTemplateTypes } from "../../services/apis";

import infoIcon from "../../images/infoIcon.svg";
import warningIcon from "../../images/warning_icon.svg";
//Import Custom components
import CustomModal from "../customModal";
import ModuleCardGeneric from "../ModuleCardGeneric";
import ViewModule from "../ModulePlaceHolder/ViewModule";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { isModularContent } from "../../Uitls/Util";
import ContentModuleCard from "../Dams/Veeva/ContentModuleCard";
import ModalHeader from "../../Uitls/ModalHeader";
import MarketingMessageSelection from "./MarketingMessageSelection";
import CreateChannelMatrix from "./CreateChannelMatrix";

const BusinessRulesValidation = ({
  modules,
  setBRulesValidationOpen,
  sourceType,
  assetDataType,
  brand,
  campaignName,
  handleProceedForChannelSelection,
  channelType,
  cancelCreatingChannelMatrix,
}) => {
  const [buttonList, setButtonList] = useState([
    { btnLabel: "View", type: "view" },
  ]);

  const [selectedModuleIdToView, setSelectedModuleIdToView] =
    useState(undefined);
  const [selectedModule, setSelectedModule] = useState(undefined);
  const [cannotExistModuleIds, setCannotExistModuleIds] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [moduleList, setModulesList] = useState([]);
  const [selectMarketingMsgFlag, toggleSelectMarketingMsgFlag] =
    useState(false);
  const [showCreateChannelMatrixModal, setShowCreateChannelMatrixModal] =
    useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedModule(undefined);
    setCannotExistModuleIds(undefined);
    setModulesList(modules);
  }, [modules]);

  const moduleCardBtnActions = (actionType, module) => {
    switch (actionType) {
      case "view":
        if (isModularContent(assetDataType)) {
          window.open(module.contentModuleUiUrl, "_blank");
          return;
        }
        setSelectedModuleIdToView(module.id);
        break;

      default:
        break;
    }
  };
  const onModuleSelect = (module) => {
    if (module.id !== selectedModule?.id) {
      setSelectedModule(module);
      let cannotExistBussinessModuleIds = module.cannotExistRuleList;
      // modules.forEach((item) => {
      //   let result = item.cannotExistRuleList?.indexOf(
      //     !isModularContent(assetDataType) ? module.id : module.cmId
      //   );
      //   if (result > -1) {
      //     cannotExistBussinessModuleIds.push(
      //       !isModularContent(assetDataType) ? item.id : item.cmId
      //     );
      //   }
      // });
      setCannotExistModuleIds(cannotExistBussinessModuleIds);
    } else {
      setSelectedModule(undefined);
      setCannotExistModuleIds(undefined);
    }
  };

  const cannotExistWithOtherSelectedModule = (currentModuleId) => {
    if (selectedModule?.id && cannotExistModuleIds?.length > 0) {
      return cannotExistModuleIds.indexOf(currentModuleId) > -1;
    }
    return false;
  };
  const getModuleCard = (module, index) => {
    if (isModularContent(assetDataType)) {
      return (
        <ContentModuleCard
          module={module}
          buttonList={buttonList}
          moduleCardBtnActions={moduleCardBtnActions}
          //isSelectable={isCmSelectable}
          showLockIcon={false}
          onModuleSelect={onModuleSelect}
          isSelected={module.id === selectedModule?.id}
          cannotExistWith={cannotExistWithOtherSelectedModule(module.cmId)}
        />
      );
    }
    return (
      <ModuleCardGeneric
        module={module}
        key={index}
        colWidth={4}
        buttonList={buttonList}
        moduleCardBtnActions={moduleCardBtnActions}
        showLockIcon={false}
        isSelected={module.id === selectedModule?.id}
        onModuleSelect={onModuleSelect}
        cannotExistBusssinessRule={cannotExistWithOtherSelectedModule(
          module.id
        )}
      />
    );
  };

  const [contentMatrix, setContentMatrix] = useState(false);

  const hideContentMatrixModal = () => {
    setSelectedModule(undefined);
    setCannotExistModuleIds(undefined);
    setContentMatrix(false);
  };
  const [templates, setTemplates] = useState([]);
  const [matrixData, setMatrixData] = useState([]);

  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleContentMatrixModal = () => {
    setIsloading(true);

    getContentmatrix(
      selectedModule.id,
      sourceType,
      assetDataType,
      (response, err, errCode) => {
        if (err) {
          //  setIsloading(false);
          const errCoponent = ErrComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          setMatrixData(response.data);
          getTemplateTypesData();
          //   setIsloading(false);
        }
      }
    );
  };
  const getTemplateTypesData = (id) => {
    setIsloading(true);
    getTemplateTypes(
      selectedModule.brandName,
      sourceType,
      assetDataType,
      (res, err, errCode) => {
        setIsloading(false);
        if (!res) {
          showError(errCode);
          return;
        }
        setTemplates(() => {
          setContentMatrix(true);
        });
        setTemplates(res.data);
      }
    );
  };
  const [searchedText, setSearhedText] = useState("");
  const [searchedResult, setSearchedResult] = useState(false);

  const filterInModuleList = () => {
    let filteredModuleList = [...modules].filter((item) => {
      const searchText = searchedText.toLowerCase().trim();
      if (isModularContent(assetDataType)) {
        return item.moduleName?.toLowerCase().includes(searchText);
      } else {
        return (
          item.name?.toLowerCase().includes(searchText) ||
          item.moduleCode?.toLowerCase().includes(searchText)
        );
      }
    });
    setModulesList(filteredModuleList);
    setSearchedResult(true);
  };
  console.log("modules", modules);
  const resetModuleList = () => {
    setModulesList(modules);
    setSearhedText("");
    setSearchedResult(false);
  };
  const handleSearchInputChange = (e) => {
    setSearhedText(e.target.value);
  };
  const checkIfAllCanNotExistBeingDisplayed = () => {
    let found = [];
    cannotExistModuleIds.forEach((el1) => {
      const idx = moduleList.findIndex((el2) => {
        return !isModularContent(assetDataType)
          ? el2.id === el1
          : el2.cmId === el1;
      });
      if (idx > -1) {
        found.push(el1);
      }
    });
    return found.length === cannotExistModuleIds.length;
  };

  const getCannotExistWarning = () => {
    let warning = `Modules highlighted in red have a CANNOT EXIST business rule with the selected module. Click the View CTA on the module card for details.`;
    if (!checkIfAllCanNotExistBeingDisplayed()) {
      warning = `The selected module has a CANNOT EXIST business rule with other modules. Reset the filters to view the details.`;
    }
    return warning;
  };

  const getHeaderData = () => {
    let obj = {};
    obj["Product/Brand"] = brand;
    if (campaignName) {
      obj["Campaign"] = campaignName;
    }
    obj["Channel"] = channelType;
    return obj;
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />

      <ModalHeader
        title={t("channelMatrix.createChannelMatrixStepHead", {
          currentStep: 2,
          totalSteps: 4,
        })}
        subtitle={t("channelMatrix.selectModuleToProceed")}
        subtitleIcon={infoIcon}
        previous={1}
        headerData={getHeaderData()}
        handleBack={(previous) => {
          setBRulesValidationOpen(false);
        }}
      />
      <div
        className="row mt-4"
        style={{
          //borderBottom: "1px solid #e1e1e1",
          paddingBottom: "15px",
        }}
      >
        <div className="col-md-10">
          <TextField
            label={
              isModularContent(assetDataType)
                ? "Module Name"
                : "Module Name/Module ID"
            }
            variant="outlined"
            fullWidth={true}
            value={searchedText}
            onKeyPress={(e) => {
              if (e.key === "Enter" && searchedText.trim()) {
                if (e.target.value.trim().length > 0) {
                  filterInModuleList();
                }
              }
            }}
            onChange={(event) => {
              handleSearchInputChange(event);
            }}
            autocomplete="new-password"
            autoComplete="off"
          />
        </div>
        <div className="col-md-2 pl-0">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-primary text-uppercase btn-search-tactic"
              onClick={(event) => {
                filterInModuleList();
              }}
              disabled={!searchedText.trim()}
            >
              search
            </button>
            <button
              className="btn btn-link pr-0"
              onClick={(event) => {
                resetModuleList();
              }}
            >
              RESET
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          style={{
            marginLeft: "5px",
            marginTop: "-8px",
            fontSize: "10px",
            color: "#333",
          }}
        >
          {!searchedResult ? (
            <>
              <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                All{" "}
                <span style={{ fontWeight: "normal" }}>
                  ({moduleList?.length})
                </span>
              </p>
            </>
          ) : (
            <>
              <p style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Results{" "}
                <span style={{ fontWeight: "normal" }}>
                  ({moduleList?.length})
                </span>
              </p>
            </>
          )}
        </div>
        <div
          style={{
            borderBottom: "1px solid #e1e1e1",
            height: "1px",
            width: "100%",
            marginLeft: "5px",
          }}
        ></div>
      </div>

      <div className="row channeMatrixSearchModules modules-val-brules custonScrollBar">
        {moduleList?.map((module, index) => {
          return getModuleCard(module, index);
        })}
      </div>
      <div className="row pt-3 createGroupResultBtnRow align-items-center">
        <div className="col-md-7">
          {cannotExistModuleIds && cannotExistModuleIds.length > 0 && (
            <div className="d-flex align-items-center">
              <span className="complianceError d-flex">
                <span>
                  {" "}
                  <img src={warningIcon} alt="" />
                </span>
                <span className="pl-2">{getCannotExistWarning()}</span>
              </span>
            </div>
          )}
        </div>
        <div className="col-md-5 text-right">
          <button
            className="btn btn-link text-uppercase pl-0 btn-cancel-rhs"
            onClick={() => {
              cancelCreatingChannelMatrix();
            }}
          >
            cancel
          </button>
          <button
            className="btn btn-primary text-uppercase"
            disabled={selectedModule === undefined}
            onClick={() => {
              // if (isModularContent(assetDataType)) {
              setShowCreateChannelMatrixModal(true);
              //   return;
              // }
              //  toggleSelectMarketingMsgFlag(true);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
      <CustomModal
        displayModal={selectedModuleIdToView}
        hideModal={() => {
          setSelectedModuleIdToView(undefined);
        }}
        title={""}
        size="xl"
        dilogClassName="alertModalSmall"
        // backdrop="false"
        keyboard={false}
      >
        <ViewModule
          moduleID={selectedModuleIdToView}
          campaign=""
          handleClose={() => {
            setSelectedModuleIdToView(undefined);
          }}
        />
      </CustomModal>

      <CustomModal
        displayModal={selectMarketingMsgFlag}
        hideModal={toggleSelectMarketingMsgFlag}
        size="xl"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <MarketingMessageSelection
          closeMarketingMsgSelectionModal={toggleSelectMarketingMsgFlag}
          sourceType={sourceType}
          brand={brand}
          campaignName={campaignName}
          channelType={channelType}
          cancelCreatingChannelMatrix={() => {
            toggleSelectMarketingMsgFlag(false);
            cancelCreatingChannelMatrix();
          }}
          assetDataType={assetDataType}
          moduleId={selectedModule?.id}
        />
      </CustomModal>

      <CustomModal
        displayModal={showCreateChannelMatrixModal}
        hideModal={setShowCreateChannelMatrixModal}
        size="xl"
        dilogClassName="alertModalSmall"
        backdrop={false}
        enforceFocus={false}
      >
        <CreateChannelMatrix
          moduleDetails={{
            brandName: brand,
            campaign: campaignName,
            channel: channelType,
            moduleId: isModularContent(assetDataType)
              ? selectedModule?.cmId
              : selectedModule?.id,
          }}
          sourceType={sourceType}
          assetDataType={assetDataType}
          setShowChannelMatrix={setShowCreateChannelMatrixModal}
          cancelCreateChannelMatrixModal={(item) => {
            setShowCreateChannelMatrixModal(false);
            cancelCreatingChannelMatrix(item);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default BusinessRulesValidation;
