import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { getSession } from "./index";

// handle the private routes
function PrivateRoute({ component: Component, canAccessedBy, ...rest }) {
  const [isUserAuthentic, SetIsUserAuthentic] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  const currentLocation = useLocation();

  useEffect(() => {
    let mounted = true;

    getSession()
      .then((sesion) => {
        if (mounted) {
          SetIsUserAuthentic(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (mounted) {
          SetIsUserAuthentic(false);
          setIsLoading(false);
        }
      });

    return () => (mounted = false);
  });

  const hasAccess = () => {
    // not having canAccessedBy as props, will be treated as a whitelisted route for all
    if (!canAccessedBy) {
      return true;
    }
    const userRole = window.localStorage.getItem("userRole") || "";
    const parsedUserRoles = userRole.split(",");
    for (let i = 0; i < parsedUserRoles.length; i++) {
      if (canAccessedBy.includes(parsedUserRoles[i])) {
        return true;
      }
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isloading) {
          return "";
        } else if (!isUserAuthentic) {
          return (
            <Redirect to={{ pathname: "/", state: currentLocation || "" }} />
          );
        } else if (!hasAccess()) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
}

export default PrivateRoute;
