import React, { useRef } from "react";

import FroalaEditor from "../../../Uitls/CommonFroalEditorComponent";

const FootnoteAndAbbreviationColumn = ({
  dataItems,
  disableSave,
  checkIfAllFilled,
}) => {
  const referencesValid = useRef(null);

  const handleTextChnage = (event, dataItem) => {
    referencesValid.current.innerHTML = event;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      dataItem.text = event;
      dataItem.translate = "2";
    } else {
      dataItem.text = "";
      dataItem.translate = "1";
    }
    disableSave(!checkIfAllFilled());
  };
  const setStyle = (elementId, className) => {
    const elemnet = document.getElementById(elementId);
    if (elemnet) {
      elemnet.className = className;
    }
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      {dataItems.map((dataItem, index) => {
        return (
          <div className="" key={dataItem.id}>
            {
              <div className="translateAbbr" style={{ marginTop: "10px" }}>
                <span className="translateAbbrText">{dataItem.prefix}</span>
                <div
                  className="trans-abbr-right-col"
                  style={{ height: "50px", marginTop: "12px" }}
                  id={dataItem.id}
                  onFocus={(e) => {
                    setStyle(
                      dataItem.id,
                      "trans-abbr-right-col trans-abbr-right-col-selected"
                    );
                  }}
                  onBlur={() => {
                    setStyle(dataItem.id, "trans-abbr-right-col");
                  }}
                >
                  <FroalaEditor
                    className="footNoteLabelN"
                    tag="textarea"
                    selectedAtom={
                      dataItem.translate === "1" ? "" : dataItem.text
                    }
                    handleModelChange={(event) => {
                      handleTextChnage(event, dataItem);
                    }}
                    className="translate-footnote-edior-box"
                  />
                </div>
              </div>
            }
          </div>
        );
      })}
      <div className="d-none" ref={referencesValid}></div>
    </div>
  );
};

export default FootnoteAndAbbreviationColumn;
