import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import i18next from "i18next";

import DropDownWithLabel from "../../components/DropDownWithLabel";
import { getBrandsAndCampaigns } from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";

import searchIcon from "../../images/masterTacticSearchIcon.svg";
import { reset } from "dynamodb";

const MarketingMessagesSearch = (props) => {
  const [productList, setProductList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);

  const [brandsAndCampaignsData, setBrandsAndCampaignsData] = useState({});
  const [channels, setChannels] = useState([]);
  const [mmStatusSelected, setMmStatusSelected] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    props.setSelectedCampaign("");
  }, [props.isCampaignSpecific]);

  useEffect(() => {
    if (props.selectedProduct) {
      const listOfCampagin = brandsAndCampaignsData.filter((brand) => {
        return brand.brandName === props.selectedProduct;
      });
      const finalList = listOfCampagin[0].campaigns.map(
        (item) => item.campaignName
      );
      setCampaignList(finalList);
    }
  }, [props.selectedProduct]);

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        setBrandsAndCampaignsData(response.data);
        const data = response.data.map((item) => item.brandName);
        setProductList(data);
      }
    });
  };

  const setChannelsData = () => {
    let channelDataSource = [];
    channelDataSource.push(i18next.t("channelTypeSelect.email"));
    channelDataSource.push(i18next.t("channelTypeSelect.banner"));
    channelDataSource.push(i18next.t("channelTypeSelect.alert"));
    setChannels(channelDataSource);
  };

  useEffect(() => {
    fetchBrandsAndCampaigns();
    setChannelsData();
  }, []);

  const onDropDownListChange = (value, type) => {
    if (type === "product") {
      props.setSelectedCampaign("");
      props.setSelectedProduct(value);
    } else if (type === "campaign") {
      props.setSelectedCampaign(value);
    } else if (type === "searchText") {
      setSearchText(value);
    } else if (type === "status") {
      setMmStatusSelected(value);
    } else {
      props.setSelectedChannelType(value);
    }
  };
  const disableSearchButton = () => {
    let disable = true;
    if (props.isCampaignSpecific) {
      if (
        props.selectedProduct &&
        props.selectedProduct.length > 0 &&
        props.selectedCampaign &&
        props.selectedCampaign.length > 0 &&
        props.selectedChannelType
      ) {
        disable = false;
      }
    } else if (
      props.selectedProduct &&
      props.selectedProduct.length > 0 &&
      props.selectedChannelType
    ) {
      disable = false;
    }

    return disable;
  };
  const search = () => {
    let campaign = props.selectedCampaign;
    if (!props.isCampaignSpecific) {
      campaign = undefined;
    }

    props.startSearchingMarketingMessages(
      props.selectedProduct,
      campaign,
      props.selectedChannelType,
      searchText.trim()
    );
  };
  const reset = () => {
    props.setSelectedProduct("");
    props.setSelectedCampaign("");
    props.setSelectedChannelType("");
    setSearchText("");
    props.resetMarketingMessagesSearch();
  };
  return (
    <>
      <div className="row align-items-center matrixFilterRow">
        <div
          className={`${props.isCampaignSpecific ? "col-md-2" : "col-md-8"} `}
        >
          <DropDownWithLabel
            class="read mt-0"
            options={{
              name: "Product",
              label: "Product",
              value: props.selectedProduct,
              items: productList,
              handleChange: (e) => {
                onDropDownListChange(e.target.value, "product");
              },
              disabled: false,
            }}
          />
        </div>
        {props.isCampaignSpecific && (
          <div className="col-md-8">
            <DropDownWithLabel
              class="read mt-0"
              options={{
                name: "Campaign",
                label: "Campaign",
                value: props.selectedCampaign,
                items: campaignList,
                handleChange: (e) => {
                  onDropDownListChange(e.target.value, "campaign");
                },
                disabled: false,
              }}
            />
          </div>
        )}
        <div
          className={`${!props.isCampaignSpecific ? "col-md-4" : "col-md-2"} `}
        >
          <DropDownWithLabel
            class="read mt-0"
            options={{
              name: "Channel",
              label: "Channel",
              value: props.selectedChannelType,
              items: channels,
              handleChange: (e) => {
                onDropDownListChange(e.target.value, "channels");
              },
              disabled: false,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex">
            <div className="marketingMsgSearchRow flex80 mr-4">
              <div className="d-flex align-items-center">
                <img
                  src={searchIcon}
                  alt=""
                  className="search-icon-icb-tactic"
                />
                <TextField
                  className=""
                  id="tf-search-marketing-msg"
                  label="Search by Attribute Text / ID / CTA"
                  variant="outlined"
                  fullWidth={true}
                  disabled={false}
                  value={searchText}
                  onChange={(e) => {
                    onDropDownListChange(e.target.value, "searchText");
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && searchText.trim().length > 0) {
                      if (!disableSearchButton()) {
                        search();
                      }
                    }
                  }}
                />
              </div>
            </div>

            <button
              className="btn btn-primary btn-search-tactic mmMessageTop24"
              onClick={() => {
                search();
              }}
              disabled={disableSearchButton()}
            >
              search
            </button>
            <button
              className="btn btn-link clear-search marketingMessagesBtnLink mmMessageTop24"
              onClick={() => {
                reset();
              }}
            >
              {i18next.t("generalText.resetAll").toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingMessagesSearch;
