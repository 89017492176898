import React, { useEffect, useState } from "react";

//Import Images
import searchIcon from "../../../images/search.svg";
import { searchInArray } from "../../../Uitls/Util";
const SearchModules = ({
  allowedToAddRule = true,
  modules,
  addBusinessRule,
  selectedBox,
  businessRules,
  setContentExists,
  setCannotExistWith,
  ...props
}) => {
  const [filteredModules, setFilteredModules] = useState([]);
  useEffect(() => {
    setFilteredModules(modules);
    const input = document.getElementById("search-brules-modules");
    if (input && input.value && input.value.trim().length > 0) {
      searchModules(input.value);
    }
  }, [modules]);

  const checkIfAlreadyExists = (modules, id, keyToCheck) => {
    const index = modules.findIndex(
      (rule) => rule.ruleCondition === keyToCheck
    );
    if (index > -1) {
      let bRuleModules = modules[index].ruleModules;
      const result = bRuleModules.findIndex((element) => element.cmId === id);

      return result === -1 ? false : true;
    }
    return false;
  };

  const addRule = (bRule) => {
    if (selectedBox === "MustExistWith") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        ) ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
      ) {
        setContentExists("MustExistWith");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
        ) {
          setCannotExistWith("Module already added under Optional");
        }
        return;
      }
    }

    if (selectedBox === "InCompatibleWith") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "MustExistWith"
        ) ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds") ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes") ||
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "RelatedTo")
      ) {
        setContentExists("InCompatibleWith");
        setCannotExistWith("Module already added under other rules");
        return;
      }
    }

    if (selectedBox === "Precedes") {
      if (
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds") ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        )
      ) {
        setContentExists("Precedes");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Succeeds")
        ) {
          setCannotExistWith("Module already added under Succeeds");
        }
        return;
      }
    }

    if (selectedBox === "Succeeds") {
      if (
        checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes") ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        )
      ) {
        setContentExists("Succeeds");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRules, bRule.targetModuleId, "Precedes")
        ) {
          setCannotExistWith("Module already added under Precedes");
        }
        return;
      }
    }
    if (selectedBox === "RelatedTo") {
      if (
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "InCompatibleWith"
        ) ||
        checkIfAlreadyExists(
          businessRules,
          bRule.targetModuleId,
          "MustExistWith"
        )
      ) {
        setContentExists("RelatedTo");
        if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "InCompatibleWith"
          )
        ) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(
            businessRules,
            bRule.targetModuleId,
            "MustExistWith"
          )
        ) {
          setCannotExistWith("Module already added under Mandatory");
        }
        return;
      }
    }

    addBusinessRule(bRule);
    setContentExists("");
    setCannotExistWith(undefined);
  };

  const searchModules = (searchParam) => {
    if (searchParam.trim().length > 0) {
      let searchedModules = searchInArray(
        modules,
        ["moduleName"],
        searchParam,
        "or"
      );
      searchedModules = [...searchedModules].filter((module) => {
        return !module.standalone;
      });
      setFilteredModules(searchedModules);
    } else {
      setFilteredModules(modules);
    }
  };

  const getCount = (cmId) => {
    let count = [];
    businessRules.forEach((rule) => {
      let resultArr = rule.ruleModules?.filter((rule) => {
        return rule.cmId === cmId;
      });
      count.push.apply(count, resultArr);
    });
    return count.length > 0 ? (
      <span className="usedModuleCount">Used in {count.length} rules</span>
    ) : (
      ""
    );
  };

  return (
    <>
      <div className="suppClaimSearch">
        <div className="d-flex">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            id="search-brules-modules"
            type="text"
            placeholder="Search for a module"
            className={`globalSearchInput ${
              allowedToAddRule ? "" : "frozen-brule-box"
            }`}
            onChange={(e) => {
              searchModules(e.target.value);
            }}
            disabled={!allowedToAddRule}
          />
        </div>
      </div>
      {filteredModules.length === 0 && (
        <div className="no-results">No Results</div>
      )}
      {filteredModules.length > 0 && (
        <ul
          className={`supClaimList custonScrollBar mt-4 ${
            allowedToAddRule ? "" : "frozen-brule-box"
          }`}
          style={{ overflowY: "scroll", height: "350px" }}
        >
          {filteredModules.map((item, index) => (
            <>
              {!item.standalone && (
                <li
                  key={index}
                  onDoubleClick={() =>
                    addRule({
                      condition: selectedBox,
                      targetModuleId: item.cmId,
                      name: item.moduleName,
                      claim: item.claim,
                    })
                  }
                  className="moduleListBusiness"
                >
                  <div className="leftAtomsList">
                    {/* <p className="listModuleName">{item.name}</p>{" "} */}
                    {item.moduleName && (
                      <p className="listModuleId">
                        <span>{item.moduleName}</span>
                      </p>
                    )}
                    {item.moduleName && (
                      <p className="listModuleId">{item.claim}</p>
                    )}
                    {getCount(item.cmId)}
                  </div>
                </li>
              )}
            </>
          ))}
        </ul>
      )}
    </>
  );
};
export default SearchModules;
