import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import backButton from "../../images/chevron-left-matrix.svg";
import infoIcon from "../../images/infoIcon.svg";
import "../../styles/components/ChannelGrid.scss";
import "../../styles/components/ChannelGridHome.scss";
import { searchChannelGridMarketingMessages } from "../../services/apis";
import { showError } from "../../Uitls/Util";
import SelectableMarketingMessageCard from "../../components/ChannelGrid/SelectableMarketingMessageCard";

const CtaSelectionScreen = ({
  brand,
  campaignName,
  channelType,
  sourceType,
  cancelCreatingStylisedModule,
  handleProceed,
  isStylisedModuleInitiated,
  selectedCtasFromEditor,
  openCtaSelectionModal,
  isStylisedModuleInitiatedfromview,
}) => {
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedResult, setSearchedResult] = useState(undefined);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [masterCtaData, setMasterCtaData] = useState(undefined);
  const [filteredCtas, setFilteredCtas] = useState(undefined);
  const [selectedCtas, setSelectedCtas] = useState([]);

  useEffect(() => {
    fetchCtas();
    if (selectedCtasFromEditor && selectedCtasFromEditor) {
      setSelectedCtas(selectedCtasFromEditor);
    }
  }, []);

  const fetchCtas = () => {
    const payload = {
      brand: brand,
      campaign: campaignName,
    };
    setIsloading(true);
    searchChannelGridMarketingMessages(payload, "cta", (res, err) => {
      setIsloading(false);
      if (err) {
        showError(err, "topright");
        return;
      }
      if (res?.data) {
        setMasterCtaData(res.data?.ctas || []);
        setFilteredCtas(res.data.ctas || []);
      }
    });
  };

  const checkIfCtaSelected = (marketingMessage) => {
    let index = -1;
    if (marketingMessage && marketingMessage) {
      index = selectedCtas.findIndex((cta) => {
        return cta.id === marketingMessage.id;
      });
    }
    return index > -1;
  };

  const onSelectCtas = (marketingMessage, selected) => {
    let ctas = [...selectedCtas];
    const index = ctas.findIndex((cta) => {
      return cta.id === marketingMessage.id;
    });
    if (selected && index === -1) {
      ctas = ctas.concat(marketingMessage);
    } else if (index > -1) {
      ctas = ctas.filter((cta) => {
        return cta.id !== marketingMessage.id;
      });
    }
    setSelectedCtas(ctas);
  };

  const filterByText = (text = searchText) => {
    let ctas = [...masterCtaData] || [];
    const searchParam = text.toLowerCase();
    ctas = ctas.filter((cta) => {
      const data = JSON.stringify(cta.labels).toLowerCase();
      return (
        cta.id.toLowerCase().includes(searchParam) ||
        data.toLowerCase().includes(searchParam)
      );
    });
    setFilteredCtas(ctas);
    setSearchedResult("searched");
    setSearchTriggered(true);
  };
  const resetModuleList = () => {
    setSearchText("");
    setFilteredCtas(masterCtaData);
    setSearchedResult(undefined);
    setSearchTriggered(false);
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div
        className={`channelGridHome ${
          isStylisedModuleInitiatedfromview
            ? "channelGridHome-stylize-view"
            : ""
        }`}
      >
        <div
          className={`row ${selectedCtasFromEditor?.length > 0 ? "" : "mt-3"} ${
            isStylisedModuleInitiatedfromview ? "pt-3" : ""
          }`}
        >
          <div className="col-md-5 matrixSearchRowTitle">
            <div className="d-flex">
              <div className="pl-2">
                <strong>Create</strong> Stylized Module{" "}
                <div className="subtitle-text">
                  <img
                    className="grid-header-subtitle-icon"
                    src={infoIcon}
                    alt=""
                  />
                  Select CTAs
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 matrixSearchRowBrand">
            <div className="d-flex justify-content-end pr-2">
              <div className="mr-5">
                <span>Product/Brand</span>
                {brand}
              </div>
              <div className="mr-5">
                <span>Campaign</span>
                {campaignName}
              </div>
              <div>
                <span>Channel</span>
                {channelType}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`row mt-4 align-items-center ${
            isStylisedModuleInitiatedfromview ? "m-0" : ""
          }`}
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "15px",
          }}
        >
          <div className="col-md-12">
            <div className="d-flex">
              <div className="channelGridMsgSearch pl-2 pr-4">
                <TextField
                  label="Search by ID/Text"
                  variant="outlined"
                  fullWidth={true}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    //  filterByText(event.target.value);
                  }}
                  autocomplete="new-password"
                  autoComplete="off"
                  classes="p-0"
                  className="searchIconInput"
                />
              </div>
              <div className="channelGridMsgbtns pr-2">
                <div className="d-flex">
                  <button
                    className="btn btn-primary btn-search-grid-message search-marketing-msg-in-modal"
                    onClick={(event) => {
                      filterByText();
                    }}
                    disabled={!searchText.trim()}
                  >
                    search
                  </button>
                  <button
                    className="btn btn-link p-0 clear-search text-center mr-4"
                    onClick={(event) => {
                      resetModuleList();
                    }}
                    disabled={
                      searchedResult !== true &&
                      !searchTriggered &&
                      !searchText.trim()
                    }
                  >
                    {t("coreClaim.reset").toUpperCase()}
                  </button>
                  <div className="common-box-legend">
                    <div className="d-flex ">
                      <span className="chLegendBg lpurple"></span>{" "}
                      <span className="chLegendText">Campaign Level</span>
                      <span className="chLegendBg lgrey"></span>{" "}
                      <span className="chLegendText no-right-padding">
                        Product Level
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`row pl-2 selectable-Cta-cards-common ${
            isStylisedModuleInitiatedfromview
              ? "selectable-Cta-cards-view"
              : "selectable-Cta-cards"
          } customScrollBar m-0`}
        >
          {filteredCtas &&
            filteredCtas.map((marketingMessage) => {
              return (
                <div className="pl-0 col-md-3">
                  <SelectableMarketingMessageCard
                    marketingMessage={marketingMessage}
                    isSelected={checkIfCtaSelected(marketingMessage)}
                    onSelectMarketingMessage={onSelectCtas}
                    //startEditingMessage={startEditingMessage}
                    isStylisedModuleInitiated={isStylisedModuleInitiated}
                  />
                </div>
              );
            })}
        </div>

        <div
          className={`row pt-3 createGroupResultBtnRow ${
            isStylisedModuleInitiatedfromview ? "m-0" : ""
          }`}
        >
          <div className="col-md-4 pl-4"></div>
          <div className="col-md-8 text-right pr-4">
            <button
              className="btn btn-link text-uppercase"
              onClick={() => {
                cancelCreatingStylisedModule();
              }}
              style={{ marginRight: "64px" }}
            >
              CANCEL
            </button>
            <button
              className="btn btn-primary text-uppercase"
              //disabled={!shouldProceedButtonBeEnabled()}
              onClick={() => {
                handleProceed(selectedCtas);
              }}
              style={{ width: "136px" }}
            >
              {openCtaSelectionModal ? "Update" : "GO TO EDITOR"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CtaSelectionScreen;
