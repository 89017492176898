//Import react dependencies
import React from "react";

const TextContent = ({ heading, content }) => {
  return (
    <div className="text-content">
      <div className="head">{heading}</div>
      <div className="content">{content}</div>
    </div>
  );
};

export default TextContent;
