import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import backButton from "../../images/chevron-left-matrix.svg";
import exportButton from "../../images/editor/icon_export.svg";
import saveButton from "../../images/editor/SaveIcon.svg";
import undoButton from "../../images/editor/icon_undo.svg";
import redoButton from "../../images/editor/icon_redo.svg";
import previewButton from "../../images/editor/preview.svg";
import infoButton from "../../images/editor/icon_info.svg";
import closeButton from "../../images/close_with_bg.svg";
import saveSuccessIcon from "../../images/save-cloud-success.svg";
import saveLoadingIcon from "../../images/save-cloud.svg";
import saveRotateIcon from "../../images/save-rotate.svg";
import { useTranslation } from "react-i18next";
import CustomModal from "../customModal";
import { routes } from "../../Uitls/Util";
import OverlayPopOver from "../../Uitls/OverlayPopOver";
import StylizedModuleInfo from "./StylizedModuleInfo";
import CheckBox from "../../Uitls/CheckBox";

const TopPanel = ({ module, channelType, runCommand, editor, savingData }) => {
  const { t } = useTranslation();
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [showExportOptions, setshowExportOptions] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    pdf: false,
    html: false,
  });
  let history = useHistory();
  const [selectedExportOptions, setSelectedExportOptions] = useState([]);
  const hideHideSucessModal = () => {
    setShowConfirmationAlert(false);
  };
  const hideExportOptionModal = () => {
    const obj = JSON.parse(JSON.stringify(exportOptions));
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (exportOptions[key]) {
        obj[key] = false;
      }
    });
    setExportOptions(obj);
    setshowExportOptions(false);
  };
  const handleChange = (event, name) => {
    const obj = JSON.parse(JSON.stringify(exportOptions));
    obj[name] = event.target.checked;
    setExportOptions(obj);
  };
  const shouldExportProceedBeDisabled = () => {
    const keys = Object.keys(exportOptions);
    let shouldDisable = true;
    keys.forEach((key) => {
      if (exportOptions[key]) {
        shouldDisable = false;
      }
    });
    return shouldDisable;
  };
  // const getUndoManagerStackDepth = () => {
  //   const undoManager = editor?.UndoManager;
  //   const stack = undoManager?.getStack();
  //   return stack?.length;
  // };
  return (
    <row className="no-gutters">
      <div className="top-panel justify-content-between align-items-center">
        <div className="d-flex exportleftPanel align-items-center">
          <div className="title-with-back">
            <div style={{ fontWeight: "normal" }}>
              <strong>{t("stylisedModule.styliseModule")}</strong> |{" "}
              {channelType}{" "}
              {/* <div className="subtitle-text">{module?.name || ""} </div> */}
              <div>
                {
                  <OverlayPopOver
                    element={
                      <div className="subtitle-text">{module?.name || ""}</div>
                    }
                    overlayData={
                      <ModuleNamePopupOverlay
                        name={module?.name || ""}
                        id={module?.moduleCode || ""}
                      />
                    }
                    trigger={"hover"}
                    classId="module-on-hover-popover"
                    placement="bottom"
                  />
                }
              </div>
            </div>
          </div>

          <div id="editor-actions-btns-left" className="panel__editor-btns">
            <div className="export">
              <div
                id="export"
                onClick={() => {
                  setshowExportOptions(true);
                  // runCommand("export");
                }}
              >
                <img src={exportButton} alt="" />
                <div className="title">Export</div>
              </div>
              <CustomModal
                displayModal={showExportOptions}
                hideModal={hideExportOptionModal}
                title={""}
                size="sm"
                dilogClassName="alertModalSmall modal-with-top-border"
                keyboard={false}
                backdrop="true"
              >
                <div className="export-row1 ml-2">
                  <span>Export</span>
                </div>
                <div className="pt-3">
                  <CheckBox
                    label="PDF"
                    isChecked={exportOptions.pdf}
                    handleChange={(event) => {
                      handleChange(event, "pdf");
                    }}
                    labelClassName="pdflabelcheckbox "
                    checkboxClassName="form-check-input ml-2"
                  />
                </div>
                <div className="pt-3 pb-3">
                  <CheckBox
                    label="HTML"
                    isChecked={exportOptions.html}
                    handleChange={(event) => {
                      handleChange(event, "html");
                    }}
                    labelClassName="pdflabelcheckbox"
                    checkboxClassName="form-check-input ml-2"
                  />
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-md-6 pl-2 pb-2">
                    <button
                      type="button"
                      className="btn btn-link text-uppercase"
                      style={{
                        color: "#2162d5 ",
                        fontWeight: 600,
                      }}
                      onClick={() => hideExportOptionModal()}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div className="col-md-6 text-right pr-4 pb-2">
                    <button
                      type="button"
                      className="btn btn-clone  export-proceed"
                      disabled={shouldExportProceedBeDisabled()}
                      onClick={() => {
                        let selected = [];
                        const keys = Object.keys(exportOptions);
                        keys.forEach((key) => {
                          if (exportOptions[key]) {
                            selected = selected.concat(key);
                          }
                        });
                        runCommand("export", selected, () => {
                          hideExportOptionModal();
                        });
                      }}
                    >
                      PROCEED
                    </button>
                  </div>
                </div>
              </CustomModal>
            </div>
            <div className="tool-button ml-0">
              <div
                id="undo"
                onClick={() => {
                  runCommand("undo");
                }}
              >
                <img src={undoButton} alt="" />
                <div className="title">Undo</div>
              </div>
            </div>
            <div className="tool-button">
              <div
                id="redo"
                onClick={() => {
                  runCommand("redo");
                }}
              >
                <img src={redoButton} alt="" />
                <div className="title">Redo</div>
              </div>
            </div>
          </div>
        </div>
        <div id="editor-actions-btns-right" className="panel__editor-btns">
          {!savingData && (
            <div className="editor-save-notification d-flex align-items-center">
              <img src={saveSuccessIcon} className="mr-2" alt="" />
              All changes saved
            </div>
          )}
          {savingData && (
            <div className="editor-save-notification d-flex align-items-center">
              <div className="d-inline-block mr-2 position-relative">
                <img src={saveLoadingIcon} alt="" />
                <img src={saveRotateIcon} alt="" className="saveRotate" />
              </div>
              Saving changes…
            </div>
          )}

          <div className="tool-button">
            <div
              id="saveDb"
              onClick={() => {
                runCommand("saveDb");
              }}
            >
              <img src={saveButton} alt="" />
              <div className="title">Save</div>
            </div>
          </div>
          <div className="tool-button">
            <div
              id="preview"
              onClick={() => {
                runCommand("preview");
              }}
            >
              <img src={previewButton} alt="" />
              <div className="title">Preview</div>
            </div>
          </div>
          <div className="tool-button info">
            {
              <OverlayPopOver
                element={
                  <div id="info" onClick={() => {}}>
                    <img src={infoButton} alt="" />
                    <div className="title">Info</div>
                  </div>
                }
                overlayData={<StylizedModuleInfo data={runCommand("info")} />}
                trigger={"click"}
                classId="stylized-module-info-popover"
                placement="bottom"
              />
            }
          </div>
          {/* <button
            className="btn-publish"
            disabled={true}
            onClick={() => {
              runCommand("publish");
            }}
          >
            Publish To VVPM
          </button> */}
          <img
            src={closeButton}
            alt="close"
            className="btn-close"
            onClick={() => setShowConfirmationAlert(true)}
          />
          <CustomModal
            displayModal={showConfirmationAlert}
            hideModal={hideHideSucessModal}
            title={""}
            size="md"
            dilogClassName="alertModalSmall modal-with-top-border"
            keyboard={false}
            backdrop="true"
          >
            <div className="alertmsgrow1 ml-2">
              Are you sure you want to close the editor?
            </div>
            <div className="row align-items-center mt-3 pt-5">
              <div className="col-md-6 pl-2 pb-2">
                <button
                  type="button"
                  className="btn btn-link text-uppercase"
                  style={{ color: "#034ea2", fontWeight: 600 }}
                  onClick={() => setShowConfirmationAlert(false)}
                >
                  CANCEL
                </button>
              </div>
              <div className="col-md-6 text-right pr-4 pb-2">
                <button
                  type="button"
                  className="btn btn-primary text-uppercase"
                  style={{
                    width: "127px",
                    backgroundColor: "#034ea2",
                  }}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  PROCEED
                </button>
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    </row>
  );
};

export const ModuleNamePopupOverlay = ({ name, id }) => {
  return (
    <div className="module-on-hover">
      <div>
        <div className="label">Module name </div>
        <div className="value">{name}</div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div className="label">Module ID </div>
        <div className="value">{id}</div>
      </div>
    </div>
  );
};

export default TopPanel;
