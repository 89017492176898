//Import react dependencies
import React from "react";
import {
  getMlrStatusIcon,
  htmlToString,
  isModularContent,
} from "../../../Uitls/Util";
import parse from "html-react-parser";

//Import custom compnents
import TextContent from "./TextContent";

const Preheaders = ({ preheaders, assetDataType }) => {
  return (
    <div className="channelTabTextContainer customScrollBar">
      {preheaders &&
        preheaders.map((preheader, index) => {
          return (
            <div className="channelTabTextboxBlock" key="index">
              {!isModularContent(assetDataType) && (
                <div className=" mb-4  veevaIdMlrSatusRow">
                  {preheader.documentNumber && (
                    <div className=" mlrVeevaId" style={{ float: "left" }}>
                      <span>Veeva ID : </span> {preheader.documentNumber}
                    </div>
                  )}
                  <div style={{ float: "right" }}>
                    {preheader.mlrStatus && (
                      <img
                        src={getMlrStatusIcon(preheader.mlrStatus)}
                        alt="MLR Status"
                      />
                    )}{" "}
                    {preheader.mlrStatus || "Not Submitted"}
                  </div>
                </div>
              )}
              <div>
                <TextContent
                  //heading={`Preheader ${index + 1}`}
                  content={parse(preheader.text || "")}
                />
              </div>

              <div className="d-flex mb-2 justify-content-between veevaIdMlrSatusRow">
                <div className="mlrVeevaId">
                  <span>ID :</span>{" "}
                  {isModularContent(assetDataType)
                    ? preheader.cmDocumentNumber
                    : preheader.id}
                </div>
                {!isModularContent(assetDataType) && (
                  <div className="mlrVeevaId">
                    <span>Source :</span> {preheader.source}{" "}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Preheaders;
