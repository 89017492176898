import React from "react";
import "../../styles/components/editor.scss";
import { Accordion, Card } from "react-bootstrap";
import parse from "html-react-parser";
import TopPanel from "./TopPanel";
import { useState, useEffect } from "react";
import {
  accordionTitles,
  cleanHtml,
  getClickableLink,
  htmlToString,
} from "../../Uitls/Util";
import { getModuleDetailsById } from "../../services/apis";
const LeftPanel = (props) => {
  const { module, isOpenPanel, openPanel, onDragContent, editCtas, ctaLabels } =
    props;

  const [activeTab, setActiveTab] = useState("1");
  const [activePanel, setActivePanel] = useState("");
  const [claimChildAccordion, setClaimChildAccordion] = useState("");
  const [textChildAccordion, setTextChildAccordion] = useState("");
  const [suppClaimChildAccordion, setSuppClaimChildAccordion] = useState("");
  const [suppVisualChildAccordion, setSuppVisualChildAccordion] = useState("");
  // const [ctaLabels, setCtaLabels] = useState(ctadetails);
  const filterOptions = [
    {
      id: "claims",
      category: "Promotional Claim",
    },
    {
      id: "suppotingClaim",
      category: "Supporting Claim",
    },
    {
      id: "supportingVisual",
      category: "Supporting Visual",
    },
    {
      id: "ctas",
      category: "CTAs",
    },
  ];

  const selectedPanel = (selectedPanel) => {
    if (activePanel === selectedPanel) {
      setActivePanel("");
    } else {
      setActivePanel(selectedPanel);
    }
  };

  const selectedClaimChildAccordion = (selectedPanel) => {
    if (claimChildAccordion === selectedPanel) {
      setClaimChildAccordion("");
    } else {
      setClaimChildAccordion(selectedPanel);
    }
  };

  const selectedTextChildAccordion = (selectedPanel) => {
    if (textChildAccordion === selectedPanel) {
      setTextChildAccordion("");
    } else {
      setTextChildAccordion(selectedPanel);
    }
  };

  const getCtaLinkToBeDragged = (cta, index = 0) => {
    return `<a
href=${getClickableLink(cta.link) || "#"}
style=
  "background-color: #1c75bb;
  padding: 10px 20px;
  text-align: center;
  text-decoration:none;
  color:#fff;
  font-weight:semi-bold;
  display : inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size:14px;"
data-gjs-highlightable="true"
data-gjs-type="link"
draggable="true"
target="_blank"
rel="noopener noreferrer">
${
  cta.reusableTextLabels.length > 0
    ? cta.reusableTextLabels[index].ctaLabel
    : "" || getClickableLink(cta.link)
}
</a>`;
  };

  const selectedSuppClaimChildAccordion = (selectedPanel) => {
    if (suppClaimChildAccordion === selectedPanel) {
      setSuppClaimChildAccordion("");
    } else {
      setSuppClaimChildAccordion(selectedPanel);
    }
  };

  const selectedSuppVisualChildAccordion = (selectedPanel) => {
    if (suppVisualChildAccordion === selectedPanel) {
      setSuppVisualChildAccordion("");
    } else {
      setSuppVisualChildAccordion(selectedPanel);
    }
  };
  const prepareSupportingTextToDrag = (prefix, text) => {
    let html = text
      .replaceAll(`<p>`, `<span class="inline-block">`)
      .replaceAll("</p>", "</span>");

    // let brokenText = text.split("<p>");
    // const prefixWithTag = `<p>` + prefix + brokenText[0] + " ";
    // brokenText.splice(0, 1);
    // return prefixWithTag + brokenText.join("");

    return `<span>${prefix} </span> ` + html;
  };
  const [ctaDetails, setCtaDetails] = useState([]);

  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };

  const renderRelatedData = (id) => {
    if (id === accordionTitles.claims) {
      return (
        <Accordion>
          {module?.claims?.map((claim, index) => {
            if (!claim) {
              return <></>;
            }
            return (
              <div id={claim.id}>
                {
                  <div className="accordion-title accordion-inner accordion-sub-menu">
                    <Accordion.Toggle
                      variant="link"
                      eventKey={claim.id}
                      onClick={() => {
                        selectedClaimChildAccordion(claim.id);
                      }}
                    >
                      <span
                        className={
                          claimChildAccordion === claim.id
                            ? "displayActiveIcon activCategory"
                            : "displayActiveIcon inActivCategory"
                        }
                      >
                        {claimChildAccordion === claim.id ? "-" : "+"}
                      </span>
                      {
                        <span
                          className={
                            claimChildAccordion === claim.id
                              ? "activCategory"
                              : "inActivCategory"
                          }
                        >
                          Claim {index + 1}
                        </span>
                      }
                    </Accordion.Toggle>
                  </div>
                }
                <Accordion.Collapse eventKey={claim.id}>
                  <Card.Body>
                    <div
                      className="primary-claim"
                      draggable={true}
                      onDragStart={(e) =>
                        onDragContent("text", cleanHtml(claim.claim), e)
                      }
                      data-gjs-highlightable="true"
                      data-gjs-type="text"
                    >
                      {parse(claim.claim || "")}
                    </div>
                    {claim.variants?.length > 0 ? (
                      <>
                        <div className="variant-label">Variants</div>
                        <ul>
                          {claim.variants?.map((item, index) => {
                            return (
                              <li key={item}>
                                <div
                                  className="variant-value"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(item.claim),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  {parse(item.claim || "")}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                    {claim.footnotes?.length > 0 && (
                      <>
                        <div className="variant-label">Footnotes</div>
                        <ul>
                          {claim.footnotes?.map((footnote, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          footnote.prefix,
                                          footnote.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(footnote.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(footnote.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.abbreviations?.length > 0 && (
                      <>
                        <div className="variant-label">Abbreviations</div>
                        <ul>
                          {claim.abbreviations?.map((abbr, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          abbr.prefix,
                                          abbr.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(abbr.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(abbr.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.references?.length > 0 && (
                      <>
                        <div className="variant-label">References</div>
                        <ul>
                          {claim.references?.map((reference, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      reference.prefix + " " + reference.text,
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {reference.prefix}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {reference.text}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      );
    }
    if (id === accordionTitles.suppotingClaim) {
      return (
        <Accordion>
          {module?.supportingClaims?.map((claim, index) => {
            if (!claim) {
              return <></>;
            }
            return (
              <div id={claim.id}>
                {
                  <div className="accordion-title accordion-inner accordion-sub-menu">
                    <Accordion.Toggle
                      variant="link"
                      eventKey={claim.id}
                      onClick={() => {
                        selectedSuppClaimChildAccordion(claim.id);
                      }}
                    >
                      <span
                        className={
                          suppClaimChildAccordion === claim.id
                            ? "displayActiveIcon activCategory"
                            : "displayActiveIcon inActivCategory"
                        }
                      >
                        {suppClaimChildAccordion === claim.id ? "-" : "+"}
                      </span>
                      {
                        <span
                          className={
                            suppClaimChildAccordion === claim.id
                              ? "activCategory"
                              : "inActivCategory"
                          }
                        >
                          Claim {index + 1}
                        </span>
                      }
                    </Accordion.Toggle>
                  </div>
                }
                <Accordion.Collapse eventKey={claim.id}>
                  <Card.Body>
                    <div
                      className="primary-claim"
                      draggable={true}
                      onDragStart={(e) =>
                        onDragContent("text", cleanHtml(claim.claim), e)
                      }
                      data-gjs-highlightable="true"
                      data-gjs-type="text"
                    >
                      {parse(claim.claim)}
                    </div>
                    {claim.footnotes?.length > 0 && (
                      <>
                        <div className="variant-label">Footnotes</div>
                        <ul>
                          {claim.footnotes?.map((footnote, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",

                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          footnote.prefix,
                                          footnote.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(footnote.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(footnote.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.abbreviations?.length > 0 && (
                      <>
                        <div className="variant-label">Abbreviations</div>
                        <ul>
                          {claim.abbreviations?.map((abbr, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          abbr.prefix,
                                          abbr.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(abbr.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(abbr.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.references?.length > 0 && (
                      <>
                        <div className="variant-label">References</div>
                        <ul>
                          {claim.references?.map((reference, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      reference.prefix + " " + reference.text,
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {reference.prefix}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {reference.text}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      );
    }
    if (id === accordionTitles.supportingVisual) {
      return (
        <Accordion>
          {module?.supportingVisuals?.map((claim, index) => {
            if (!claim) {
              return <></>;
            }
            return (
              <div id={claim.id}>
                {
                  <div className="accordion-title accordion-inner accordion-sub-menu">
                    <Accordion.Toggle
                      variant="link"
                      eventKey={claim.id}
                      onClick={() => {
                        selectedSuppVisualChildAccordion(claim.id);
                      }}
                    >
                      <span
                        className={
                          suppVisualChildAccordion === claim.id
                            ? "displayActiveIcon activCategory"
                            : "displayActiveIcon inActivCategory"
                        }
                      >
                        {suppVisualChildAccordion === claim.id ? "-" : "+"}
                      </span>
                      {
                        <span
                          className={
                            suppVisualChildAccordion === claim.id
                              ? "activCategory"
                              : "inActivCategory"
                          }
                        >
                          Visual {index + 1}
                        </span>
                      }
                    </Accordion.Toggle>
                  </div>
                }
                <Accordion.Collapse eventKey={claim.id}>
                  <Card.Body>
                    {claim.visuals?.length > 0 ? (
                      <>
                        {claim.visuals?.map((item, index) => {
                          return (
                            <div className="variant-value">
                              <img
                                src={item.visualUrl}
                                className="img-fluid"
                                draggable={true}
                                onDragStart={(e) =>
                                  onDragContent("image", item.visualUrl, e)
                                }
                                data-gjs-highlightable="true"
                                data-gjs-type="image"
                                width="100%"
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                    {claim.caption?.length > 0 && (
                      <>
                        <div className="variant-label">Captions</div>
                        <ul>
                          {claim.caption?.map((captions, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(captions.text),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  {parse(captions.text)}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.footnotes?.length > 0 && (
                      <>
                        <div className="variant-label">Footnotes</div>
                        <ul>
                          {claim.footnotes?.map((footnote, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",

                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          footnote.prefix,
                                          footnote.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(footnote.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(footnote.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.abbreviations?.length > 0 && (
                      <>
                        <div className="variant-label">Abbreviations</div>
                        <ul>
                          {claim.abbreviations?.map((abbr, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      cleanHtml(
                                        prepareSupportingTextToDrag(
                                          abbr.prefix,
                                          abbr.text
                                        )
                                      ),
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {parse(abbr.prefix)}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {parse(abbr.text)}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {claim.references?.length > 0 && (
                      <>
                        <div className="variant-label">References</div>
                        <ul>
                          {claim.references?.map((reference, index) => {
                            return (
                              <li key={index}>
                                <div
                                  className="variant-value d-flex"
                                  draggable={true}
                                  onDragStart={(e) =>
                                    onDragContent(
                                      "text",
                                      reference.prefix + " " + reference.text,
                                      e
                                    )
                                  }
                                  data-gjs-highlightable="true"
                                  data-gjs-type="text"
                                >
                                  <span className="abbreviations">
                                    {reference.prefix}
                                  </span>
                                  <span style={{ marginLeft: "3px" }}>
                                    {reference.text}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      );
    }
    if (id === accordionTitles.ctas) {
      return (
        <Accordion>
          {ctaLabels?.map((element, index) => {
            if (!element) {
              return <></>;
            }
            return (
              <div id={element.id}>
                <div className="accordion-title accordion-sub-menu">
                  {/* {element.reusableTextType === "CTA" && ( */}
                  <Accordion.Toggle
                    variant="link"
                    eventKey={element.id}
                    onClick={() => {
                      selectedTextChildAccordion(element.id);
                    }}
                  >
                    <span
                      className={
                        textChildAccordion === element.id
                          ? "displayActiveIcon activCategory"
                          : "displayActiveIcon inActivCategory"
                      }
                    >
                      {textChildAccordion === element.id ? "-" : "+"}
                    </span>

                    <span
                      className={
                        textChildAccordion === element.id
                          ? "activCategory"
                          : "inActivCategory"
                      }
                    >
                      {/* {element.attribute} {index + 1} */}
                      {element?.reusableTextType} {index + 1}
                    </span>
                  </Accordion.Toggle>
                  {/* )} */}
                </div>

                <Accordion.Collapse eventKey={element.id}>
                  <Card.Body>
                    <div
                    // draggable={true}
                    // onDragStart={(e) =>
                    //   onDragContent("text", getCtaLinkToBeDragged(element), e)
                    // }
                    // data-gjs-highlightable="true"
                    // data-gjs-type="link"
                    >
                      {element.link && (
                        <div
                          draggable={true}
                          onDragStart={(e) =>
                            onDragContent(
                              "text",
                              getCtaLinkToBeDragged(element),
                              e,
                              "cta"
                            )
                          }
                          data-gjs-highlightable="true"
                          data-gjs-type="link"
                        >
                          <div className="link-label">Link</div>
                          {/* <div className="variant-value">{element.url}</div> */}
                          <div
                            className="variant-value"
                            draggable={true}
                            onDragStart={(e) =>
                              onDragContent(
                                "text",
                                getCtaLinkToBeDragged(element),
                                e,
                                "cta"
                              )
                            }
                          >
                            {element.link}
                          </div>
                        </div>
                      )}

                      {element.reusableTextLabels.length > 0 && (
                        <>
                          <div className="link-label mt-2">Label(s)</div>
                          {/* <div className="variant-value">
                            {element.labels[0]}
                          </div> */}
                          <ul>
                            {element.reusableTextLabels?.map((item, index) => {
                              return (
                                <li className="variant-label" key={index}>
                                  <div
                                    className="variant-value"
                                    draggable={true}
                                    onDragStart={(e) =>
                                      onDragContent(
                                        "text",
                                        getCtaLinkToBeDragged(element, index),
                                        e,
                                        "cta"
                                      )
                                    }
                                    data-gjs-highlightable="true"
                                    data-gjs-type="link"
                                  >
                                    {item?.ctaLabel}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      );
    }
  };

  const getCount = (id) => {
    if (id === accordionTitles.claims) {
      return module?.claims?.length;
    } else if (id === accordionTitles.suppotingClaim) {
      return module?.supportingClaims?.length;
    } else if (id === accordionTitles.ctas) {
      return ctaLabels?.length ? ctaLabels?.length : 0;
      //module?.textElements?.length;
    } else if (id === accordionTitles.supportingVisual) {
      return module?.supportingVisuals?.length;
    } else {
      return 0;
    }
  };
  // useEffect(() => {
  //   const moduleReusableTexts = props?.location?.state?.moduleReusableTexts;

  //   if (!moduleReusableTexts || moduleReusableTexts.length === 0) {
  //     getModuledetails();
  //   }
  // }, [props?.location?.state?.moduleReusableTexts]);
  return (
    <div className="editorLeftPanel">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6 text-center left-panel-tab-class">
            <div
              className={activeTab === "1" ? "activeTab" : "inactiveTab"}
              onClick={() => {
                setActiveTab("1");
                setActivePanel("");
                setClaimChildAccordion("");
                setTextChildAccordion("");
                setSuppClaimChildAccordion("");
                setSuppVisualChildAccordion("");
              }}
            >
              Format
            </div>
          </div>
          <div className="col-md-6 text-center left-panel-tab-class">
            <div
              className={activeTab === "2" ? "activeTab" : "inactiveTab"}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              Content
            </div>
          </div>
        </div>

        <div
          className={`row   ${
            activeTab === "2" ? "editor-panel-show" : "editor-panel-hide"
          }`}
        >
          <div className="col-md-12 pr-0 accordion-main-div leftPanelscrollbar customScrollBar">
            {activeTab === "2" && (
              <Accordion>
                {filterOptions.map((filterOption) => {
                  if (!filterOption) {
                    return <></>;
                  }
                  return (
                    <div
                      id={filterOption.id}
                      className="accordion-border-bottom"
                    >
                      {
                        <div className="accordion-title">
                          <Accordion.Toggle
                            variant="link"
                            eventKey={filterOption.id}
                            onClick={() => {
                              selectedPanel(filterOption.id);
                            }}
                          >
                            <span
                              className={
                                activePanel === filterOption.id
                                  ? "displayActiveIcon activCategory"
                                  : "displayActiveIcon inActivCategory"
                              }
                            >
                              {activePanel === filterOption.id ? "-" : "+"}
                            </span>
                            {
                              <span
                                className={
                                  activePanel === filterOption.id
                                    ? "activCategory"
                                    : "inActivCategory"
                                }
                              >
                                {filterOption.category}
                              </span>
                            }
                            <span className="count">
                              ({getCount(filterOption.id)} items)
                            </span>
                          </Accordion.Toggle>
                          {filterOption.id === "ctas" && (
                            <button
                              className="btn btn-link text-uppercase"
                              onClick={() => {
                                editCtas();
                              }}
                            >
                              EDIT SELECTION
                            </button>
                          )}
                        </div>
                      }
                      <Accordion.Collapse eventKey={filterOption.id}>
                        <Card.Body>
                          {renderRelatedData(filterOption.id)}
                        </Card.Body>
                      </Accordion.Collapse>
                    </div>
                  );
                })}
              </Accordion>
            )}
          </div>
        </div>
      </div>
      <div
        className={`row no-gutters ${
          activeTab === "1" ? "editor-panel-show" : "editor-panel-hide"
        }`}
      >
        <div className="col-md-12 leftPanelscrollbar customScrollBar">
          <div id="blocks" className={`row no-gutters ml-0`}></div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
