//Import react dependecies
import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

//Import from Utils
import {
  getAllChannelAttributes,
  getChannelAttributes,
  getMlrStatusIcon,
} from "../../Uitls/Util";

//Import styles
import "../../styles/components/MarketingMessages.scss";

//Import Resources
import CheckBox from "../../Uitls/CheckBox";

export const isOptOut = (marketingMessage) => {
  return (
    marketingMessage?.attribute?.toLowerCase() ===
    getAllChannelAttributes().optOut.toLowerCase()
  );
};
const SelectableMarketingMessageCard = ({
  marketingMessage,
  isSelected,
  onSelectMarketingMessage,
}) => {
  const mlrStatusIcon = (marketingMessage) => {
    const mlrStatus = marketingMessage.mlrStatus;
    return <img src={getMlrStatusIcon(mlrStatus)} alt=""></img>;
  };
  return (
    <>
      <div className={`marketing-msg-card }`}>
        <Row>
          <Col className="col-md-6">
            {marketingMessage.attribute.toLowerCase() !==
              getAllChannelAttributes().optOut.toLowerCase() && (
              <CheckBox
                isChecked={isSelected}
                //disabled={isOptOut(marketingMessage) ? true : false}
                handleChange={(e) => {
                  // if (!isOptOut(marketingMessage)) {
                  //   onSelectMarketingMessage(marketingMessage, e.target.checked);
                  // }
                  onSelectMarketingMessage(marketingMessage, e.target.checked);
                }}
                labelClassName=""
                checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
              />
            )}
            {marketingMessage.attribute.toLowerCase() ===
              getAllChannelAttributes().optOut.toLowerCase() && (
              <input
                id={marketingMessage.documentNumber}
                type="radio"
                name="optout"
                className="form-check-input slctble-mrktng-msg-chk-bx"
                checked={isSelected}
                onChange={(e) => {
                  onSelectMarketingMessage(marketingMessage, e.target.checked);
                }}
              />
            )}

            {marketingMessage.documentNumber && (
              <div style={{ display: "inline" }}>
                Veeva ID :{" "}
                <span className="metadata">
                  {marketingMessage.documentNumber}
                </span>
              </div>
            )}
          </Col>
          <Col className="col-md-6">
            <div className="status-and-lock">
              <span className="mlr-status">
                {mlrStatusIcon(marketingMessage)}{" "}
                {marketingMessage.mlrStatus || "Not Submitted"}
              </span>
            </div>
          </Col>
        </Row>

        <Row className="m-message ">
          {marketingMessage.attribute.toLowerCase() !==
            getAllChannelAttributes().optOut.toLowerCase() && (
            <Col
              className={`${
                marketingMessage.visual ? "col-md-9" : "col-md-12"
              }`}
              style={{ padding: "0px" }}
            >
              {parse(marketingMessage.text || "")}
            </Col>
          )}
        </Row>
        {/* <--------------------  For Optout -------------------------> */}
        {marketingMessage.attribute.toLowerCase() ===
          getAllChannelAttributes().optOut.toLowerCase() && (
          <Row className=" marketing-msg-optout">
            <Col className="col-md-3">
              <img
                src={marketingMessage.visual}
                alt=""
                className="img-fluid"
              ></img>
            </Col>
            <Col
              className={`${
                marketingMessage.visual ? "col-md-8" : "col-md-12"
              }`}
            >
              {parse(marketingMessage.text || "")}
            </Col>
          </Row>
        )}
        {/* <-------------------- For disclaimer -------------------------> */}
        {marketingMessage.disclaimer && (
          <Row className="m-message">
            <span className="metadata" style={{ width: "100%" }}>
              Disclaimer
            </span>{" "}
            <div>{parse(marketingMessage.disclaimer || "")}</div>
          </Row>
        )}
        <div class="d-flex justify-content-between">
          <span className="cardMetaData">
            ID: <strong>{marketingMessage.id}</strong>
          </span>{" "}
          <span className="cardMetaData">
            Type: <strong>{marketingMessage.type}</strong>
          </span>
        </div>
      </div>
    </>
  );
};

export default SelectableMarketingMessageCard;
