import i18next from "i18next";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";

import "../../styles/components/Headers/TranslateModuleNav.scss";
import {
  hasUserLoggedInAsReviewer,
  routes,
  imcLanguageTableName,
  isoCountryTableName,
  awsRegion,
  getTranslationJobType,
  filterOutGlobalMarket,
} from "../../Uitls/Util";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import {
  translatorSubmitForReview,
  getCognitoSession,
  getCognitoIndentityCredentials,
  getJobDetails,
  updateRevisionRequired,
  getJobInstructions,
} from "../../services/apis";
import successTick from "../../images/translateTick.svg";
import homeIcon from "../../images/homeIcon.svg";
import viewInstructions from "../../images/view-instructions.svg";
import CustomModal from "../customModal";
import TranslatorAgencyScreen from "../translateModule";
import InstructionsOrGuidelines from "../Instructions/Guidelines/InstructionsOrGuidelines";
import TranslateChannel from "../../Pages/TranslateChannel";
var dynamo = require("dynamodb");
const TranslateModuleNav = (props) => {
  const {
    handleProceed,
    pageIndex,
    toggle,
    handleLanguageToggleBtn,
    moduleName,
    moduleCode,
    sourceLanguage,
    targetLanguage,
    hasSourceModuleId,
    toggleSourceModule,
    handleSourceModuleToggle,
  } = props;
  let history = useHistory();
  //const [isloading, setIsloading] = useState(false);
  const [showTranslatePopup, setShowTranslatePopup] = useState(false);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [languageDataSource, setLanguageDataSource] = useState([]);
  const [jobInfo, setJobInfo] = useState([]);
  let dataToBeUpdated = JSON.parse(
    window.localStorage.getItem("translateModuleInfo")
  );

  useEffect(() => {
    getInstructions();
  }, []);

  const [instructions, setInstructions] = useState(undefined);
  const [showInstructions, toggleShowInstructions] = useState(false);

  const getInstructions = () => {
    let jobInfo = localStorage.getItem("translateModuleInfo");
    jobInfo = JSON.parse(jobInfo);
    if (jobInfo) {
      const jobId = jobInfo.jobId;
      if (jobId) {
        getJobInstructions(jobId, (response) => {
          if (response) {
            setInstructions(response.data);
          }
        });
      }
    }
  };

  const handleInstructionsPopup = (flag) => {
    toggleShowInstructions(flag);
  };

  const handleStatusChange = async (option) => {
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let successMessage = "Status Updated Successfully";
    let translationStatusPayload = {
      jobId: moduleInfoFromLocalStorage.jobId,
      moduleId: moduleInfoFromLocalStorage.translateModuleId,
      jobType: "Translator-Agency",
    };
    if (option === "Revision Required") {
      await fetchLanguageData();
      fetchMarketData(() => {
        getJobDetails(
          translationStatusPayload.jobId,
          (response, err, errCode) => {
            if (err) {
              const errCoponent = ErrComponentToRender(errCode);
              showError(errCoponent);
            } else {
              setJobInfo(response.data);
              setShowTranslatePopup(true);
            }
          }
        );
      });
    } else if (option === "Approve") {
      translationStatusPayload.reviewerStatus = "Approved";
      translationStatusPayload.translatorStatus = "Approved";
      successMessage = "Review Successfull";
      translatorSubmitForReview(
        translationStatusPayload,
        (response, err, errCode) => {
          if (err) {
            const errCoponent = ErrComponentToRender(errCode);
            showError(errCoponent);
          } else {
            history.push({
              pathname: routes.moduleDetails,
              state: {
                moduleId: dataToBeUpdated.translateModuleId,
                rateTranslator: true,
              },
            });
            // window.localStorage.removeItem("translateModuleInfo");
            notifyReviewSucess(response.data, successMessage);
          }
        }
      );
    } else {
      return;
    }
  };
  const translateRedirect = (isReviewer) => {
    window.localStorage.removeItem("translateModuleInfo");
    history.push({
      pathname: isReviewer
        ? routes.reviewerDashboard
        : routes.translatorDashboard,
    });
  };
  const notifyReviewSucess = (data, successMessage) => {
    toast.success(
      <>
        <p className="notificationTitle mb-2">
          <img
            src={successTick}
            alt="Successfully sent for Review"
            className="mr-1"
          />{" "}
          {successMessage}
        </p>
        {data.jobId && (
          <p className="reviewNotifciationText">
            <span>
              Job ID:<strong>{data.jobId}</strong>
            </span>
          </p>
        )}
        {data.assignedTo && (
          <p className="reviewNotifciationText">
            <span>
              Assigned To:<strong>{data.assignedTo}</strong>
            </span>
          </p>
        )}
        <p className="reviewNotifciationText">
          Due Date: <strong>{tranformDueDate(data.dueDate)}</strong>
        </p>

        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };
  const tranformDueDate = (inputDate) => {
    let result = "";
    if (inputDate) {
      let parts = inputDate.split("-");
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return result;
  };
  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };
  const handleCloseTranslateModal = () => {
    setShowTranslatePopup(false);
  };
  const fetchLanguageData = async () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: imcLanguageTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let languageData = data.Items;
              if (languageData && languageData.length > 0) {
                languageData = [...languageData].map((language) => {
                  let object = {
                    languageCode: language.languageCode,
                    icbCode: language.icbCode,
                    language: language.language,
                    market: language.market,
                  };
                  object[
                    "displayName"
                  ] = `${object.languageCode} - ${object.language}`;

                  return object;
                });
                if (languageData && languageData.length > 0) {
                  languageData = [...languageData].sort(
                    (languageOne, languageTwo) => {
                      if (languageOne.language < languageTwo.language) {
                        return -1;
                      }
                      if (languageOne.language > languageTwo.language) {
                        return 1;
                      }
                      return 0;
                    }
                  );
                  setLanguageDataSource(languageData);
                }
              }
            }
          });
        });
      }
    });
  };

  const fetchMarketData = (cb) => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, async function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  marketData = filterOutGlobalMarket(marketData);
                  await setMarketDataSource(marketData);
                  cb(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleFinishCTA = (data) => {
    let payLoad = {
      language: data[0].language,
      languageCode: data[0].languageCode,
      market: data[0].market,
      marketCode: data[0].marketCode,
      dueDate: data[0].dueDate,
      jobType: data[0].jobType,
      jobDetails: data[0].jobDetails,
      id: jobInfo.id,
      jobCreator: data[0].jobCreator,
      jobCreatorEmail: data[0].jobCreatorEmail,
      translateComponents: jobInfo.translateComponents,
      instruction: data[0].instruction,
    };
    let successMessage = "Status Updated Successfully";
    updateRevisionRequired(
      jobInfo.sourceModuleId,
      payLoad,
      (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          showError(errCoponent);
        } else {
          translateRedirect(true);
          notifyReviewSucess(response.data, successMessage);
        }
      }
    );
  };
  const redirectToDashBoard = () => {
    if (hasUserLoggedInAsReviewer()) {
      translateRedirect(true);
    } else if (!hasUserLoggedInAsReviewer()) {
      if (getTranslationJobType() === "Self") {
        translateRedirect(true);
      } else {
        translateRedirect(false);
      }
    }
  };
  return (
    <>
      <Container fluid>
        <Row className="align-items-center">
          <Col className="col-md-5">
            <span
              className="translateModuleName"
              style={{ display: "inline-flex" }}
            >
              <img
                src={homeIcon}
                alt=""
                onClick={(event) => {
                  redirectToDashBoard();
                }}
              />
            </span>{" "}
            /{" "}
            <span
              className="translateModuleName"
              style={{ display: "inline-block" }}
            >
              {moduleName}
            </span>{" "}
            | <span className="translateModuleCode">{moduleCode}</span>
          </Col>

          <Col className="col-md-7 text-right">
            {hasSourceModuleId && (
              <div className={`${!toggle ? "" : "disabled-toggle"} toggle`}>
                Show Source Module
                <Switch
                  className="filterRightMargin mr-2"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  toggle={toggleSourceModule}
                  onChange={handleSourceModuleToggle}
                  disabled={toggle}
                />
              </div>
            )}
            <div
              className={`${
                !toggleSourceModule ? "" : "disabled-toggle"
              } toggle`}
            >
              Show English Version
              <Switch
                className="filterRightMargin mr-2"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={toggle}
                onChange={handleLanguageToggleBtn}
                disabled={hasSourceModuleId && toggleSourceModule}
              />
            </div>
            <Button
              variant="outline-secondary mr-4 btnClose nav-buttons-margin"
              onClick={props.handlePageClose}
              disabled={props.disableClose || false}
            >
              {props.btnClose || i18next.t("generalText.close")}
            </Button>
            {!hasUserLoggedInAsReviewer() && props.btnSave && (
              <Button
                variant="outline-secondary mr-4 btnClose nav-buttons-margin"
                onClick={() => props.handlePageSave()}
                disabled={!toggleSourceModule && !toggle ? false : true}
              >
                {props.btnSave}
              </Button>
            )}
            {!hasUserLoggedInAsReviewer() && (
              <Button
                variant="primary btnProceed"
                onClick={() => {
                  handleProceed();
                }}
                disabled={props.disableProceed}
              >
                {props.btnProceed || i18next.t("generalText.proceed")}
              </Button>
            )}
            {hasUserLoggedInAsReviewer() &&
              !props.shouldHaveMultipleOptions && (
                <Button
                  variant="primary btnProceed"
                  onClick={() => {
                    handleProceed();
                  }}
                  disabled={props.disableProceed}
                >
                  {props.btnProceed || i18next.t("generalText.proceed")}
                </Button>
              )}
            {hasUserLoggedInAsReviewer() && props.shouldHaveMultipleOptions && (
              <DropdownButton
                title="Translation Status"
                className="btn-trans-status btn-primary"
                style={{ float: "right" }}
              >
                {props.proceedOptions.map((option) => {
                  return (
                    <Dropdown.Item
                      key={option}
                      as="button"
                      className="btn-trans-status-option"
                      onClick={() => {
                        handleStatusChange(option);
                      }}
                    >
                      {option}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            )}
          </Col>
        </Row>
        <Row className="">
          <Col>
            <span className="translateModuleName">
              {dataToBeUpdated && dataToBeUpdated.sourceLanguage
                ? dataToBeUpdated.sourceLanguage
                : sourceLanguage}{" "}
              to{" "}
              {dataToBeUpdated && dataToBeUpdated.targetLanguage
                ? dataToBeUpdated.targetLanguage
                : targetLanguage}
            </span>
            <div style={{ display: "inline-block" }}>
              {instructions && instructions.length > 0 && (
                <span
                  className="view-instructions"
                  onClick={() => {
                    handleInstructionsPopup(true);
                  }}
                >
                  <img
                    src={viewInstructions}
                    alt=""
                    className="view-instructions-icon"
                  />
                  {showInstructions && (
                    <InstructionsOrGuidelines
                      styleClass="instructionsPopupContainer view-instructions-popup-nav"
                      instructions={instructions}
                      popupCurrentState={toggleShowInstructions}
                      popupUpdateState={handleInstructionsPopup}
                    />
                  )}
                  View Instructions
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <ul className="translateModuleNav">
              {dataToBeUpdated &&
              dataToBeUpdated.translateComponents.includes("claims") ? (
                <li
                  className={pageIndex === 1 ? "active" : ""} //pageIndex >= progress bar active
                  onClick={() => {
                    history.push({
                      pathname: routes.translateClaims,
                      //state: { moduleId: "translateId" },
                    });
                  }}
                >
                  <span>Promotional & Supporting Claims</span>
                </li>
              ) : (
                ""
              )}
              {/* {dataToBeUpdated &&
              dataToBeUpdated.translateComponents.includes("channel") ? (
                <li
                  className={pageIndex === 2 ? "active" : ""}
                  onClick={() => {
                    history.push({
                      pathname: routes.translateChannel,
                      // state: { moduleId: "translateId" },
                    });
                  }}
                >
                  <span>Channel</span>
                </li>
              ) : (
                ""
              )} */}
              {/* <li
                className={pageIndex === 3 ? "active" : ""}
                onClick={() => {
                  history.push({
                    pathname: routes.translateBusinessRules,
                    // state: { moduleId: "translateId" },
                  });
                }}
              >
                <span>Business Rules</span>
              </li> */}
              {dataToBeUpdated &&
              dataToBeUpdated.translateComponents.includes("additionalInfo") ? (
                <li
                  className={pageIndex === 2 ? "active" : ""}
                  onClick={() => {
                    history.push({
                      pathname: routes.translateAdditionalInfo,
                      // state: { moduleId: "translateId" },
                    });
                  }}
                >
                  <span>Additional Info</span>
                </li>
              ) : (
                ""
              )}
            </ul>
          </Col>
        </Row>
      </Container>
      <CustomModal
        displayModal={showTranslatePopup}
        hideModal={handleCloseTranslateModal}
        title={"Filter By Alert"}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <TranslatorAgencyScreen
          marketDataSource={marketDataSource}
          hideModal={handleCloseTranslateModal}
          languageDataSource={languageDataSource}
          handleSaveTranslation={handleFinishCTA}
          jobInfo={jobInfo}
        />
      </CustomModal>
    </>
  );
};

export default TranslateModuleNav;
