import React from "react";
import { Col } from "react-bootstrap";
import deleteIcon from "../../images/closeIcon.svg";
import InfoIconError from "../../images/Info-selection-error.svg";

const BusinessRulesBox = (props) => {
  const {
    boxKey,
    boxTitle,
    data,
    selectedBoxIndex,
    setSelectedBox,
    showModal,
    handleDelete,
    businessRulesNote,
    contentExists,
    setContentExists,
    allowedToAddRule,
    setCannotExistWith,
    cannotExistWith,
  } = props;

  let filteredData = businessRulesNote.filter(function (item) {
    return item.noteCondition === boxKey;
  });

  const label = filteredData[0] && filteredData[0].note;

  return (
    <>
      <Col
        xs="12"
        md="4"
        lg="4"
        className={`mb-3 ${!allowedToAddRule ? "frozen-brule-box" : ""} ${
          props.className
        }`}
        onClick={() => {
          if (!allowedToAddRule) {
            return;
          }
          setSelectedBox(boxKey);
          setContentExists("");
          setCannotExistWith(undefined);
        }}
      >
        <div
          className={
            "businessRulesBox " +
            (selectedBoxIndex === boxKey ? "businessRulesBoxSelected " : "") +
            (contentExists && contentExists === boxKey
              ? "businessRulesBoxErr"
              : "") +
            (!allowedToAddRule ? "frozen-brule-box" : "") +
            ` ${props.className}`
          }
        >
          <div className="businessRulesBoxTitle d-flex justify-content-between">
            {boxTitle}{" "}
            {data.length !== 0 && (
              <button
                className="btn btn-link p-0"
                onClick={() => {
                  setSelectedBox(boxKey);
                  showModal(boxKey, boxTitle);
                }}
                disabled={!allowedToAddRule}
              >
                {label ? "View Details" : "Add Details"}
              </button>
            )}
          </div>

          {data.length ? (
            <p className="moduleListCount">
              {data.length} Modules / 0 Category
            </p>
          ) : (
            <div className="boxLableVerticalMiddle moduleCountPlaceHolder">
              0 Modules / Category
            </div>
          )}

          <ul className={`supClaimList listScrollContainer custonScrollBar`}>
            {data.map((item) => {
              return (
                <li key={item.requestId} className="rulesBoxModuleList">
                  <div className="groupAtomsList">
                    <p className="listModuleName">{item.name}</p>
                    <p className="listModuleId">{item.moduleCode}</p>
                  </div>
                  {!props.hideDeleteButton && (
                    <button
                      className="btn btn-link p-0 m-0 deleteGroupItem"
                      disabled={!allowedToAddRule}
                      onClick={(e) => {
                        handleDelete(e, item);
                        setCannotExistWith(undefined);
                        setSelectedBox(boxKey);
                        setContentExists("");
                      }}
                    >
                      <img src={deleteIcon} alt="" width="32" />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
          {contentExists === boxKey && cannotExistWith && (
            <div className="bussinessrule-error-message-note">
              <img
                src={InfoIconError}
                alt=""
                style={{ marginBottom: "3px", marginRight: "5px" }}
              />
              {cannotExistWith}
            </div>
          )}
        </div>
      </Col>
    </>
  );
};

export default BusinessRulesBox;
