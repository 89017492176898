import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowLeftOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import { uploadSuppVisual } from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import SupportingClaim from "../components/SupportingClaim";
import FrolaTextEditor from "../Uitls/CommonFroalEditorComponent";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
import uploadImage from "../images/Union 85.svg";
import CustomModal from "./customModal";
import addIcon1 from "../images/addIcon.svg";
import arrowpolygonRight from "../images/arrowpolygon-right.svg";
import "../styles/components/SupportingVisual.scss";
import ContextMenu from "./contextMenu";
import Abbreviation from "./abbreviationSupportingClaim";
import FooteNotesModal from "./SuppClaimFootNote";
import ReferenceContainer from "./ReferencesAndAnnotation/ReferenceContainer";
import { moduleTypes } from "../Uitls/Util";
import SupportingVisualLink from "./SupportingVisual/SupportingVisualLink";
import ReferenceAnchor from "../components/ReferencesAndAnnotation/ReferenceAnchor";
import { v4 as uuidv4 } from "uuid";
import { areRefArraysEqual } from "../components/PrimaryClaim";
import Switch from "@material-ui/core/Switch";
import {
  citationColors,
  rgbToHex,
  getRefSuperscriptStyle,
  sortSuperScriptNumbers,
  addFootnoteSuperScript,
} from "../Uitls/Util";
import UploadOnlineImageModal from "./SupportingVisual/UploadOnlineImageModal";
const Row = (props) => {
  const { t } = useTranslation();
  const {
    row,
    rowValue,
    rowIndex,
    supportingVisualPosition,
    addImage,
    deleteImage,
    addBoxContent,
    setSelectedBox,
    setSelectedRow,
    setFilterType,
    currentImageReplace,
    SetCurrentImageReplace,
    SetCurrentVisualTextReplace,
    SetBoxSeletedBoxType,
    addCaptions,
    deleteCaption,
    handleCptionTextChange,
    currentTransTextReplace,
    // handleTranscriptTextChange,
    setCurrentTransTextReplace,
    deleteSuppVisualRow,
    setIsDelete,
    setAtomsHeaderVisual,
    //setIsFreeze,
    setSelectedSuppBox,
    suppClaimfootNoteText,
    addAbbrToMasterVisual,
    setIsAbbrDisable,
    isAbbrDisable,
    setSelectedSuppRow,
    textSelectedAbbr,
    handleAddRadioAbbr,
    abbrStatsu,
    referenceMasterList,
    atomTextForRef,
    moduleType,
    primaryClaim,
    updateVisualLinks,
    selectedRow,
    anchorAddedIn,
    resetSupportingVisualAbbreviations,
    supportingClaims,
    maxAbbrRecordIndex,
    sourceType,
    findSupportingClaimOrVisualPosition,
    selectedDiv,
    setSelectedDiv,
    getModuledetails,
    suppVisualBussinessRules,
    suppVisualMustUse,
    setSuppVisualBussinessRules,
    setSuppVisualMustUse,
    setSupportingClaimsAndVisuals,
    supportingClaimsAndVisuals,
    isMustUseValues,
    setIsMustUseValues,
  } = props;

  const formikRef = useRef();
  const [selectedImage, setSelectedimage] = useState(-1);
  const [box1, box2, box3, box4, box5, box6] = row;
  const [uploadedFileName, setUploadedFileName] = useState(false);
  const [rownumber, setRownumber] = useState(0);
  const [currentImageReplaceLocal, setCurrentImageReplaceLocal] = useState(0);
  const [currentTextReplaceLocal, setCurrentTextReplaceLocal] = useState(0);

  const [isAbbr, setIsAbbr] = useState(false);
  const [rightClickTriggered, setRightClickTriggered] = useState(false);
  const [arrAnnot, setArrAnnot] = useState([]);
  const [references, setReferences] = useState([]);
  const [arrAbbr, setArrAbbr] = useState([]);
  const [abbrStatus1, setAbbrStatus] = useState();
  const [abbrNoteText, setAbbrNoteText] = useState([]);
  const [selectedTextPrimaryClaimValue, setSelectedTextPrimaryClaimValue] =
    useState("");
  let froalaInstance = useRef(); //useRef();
  let footNoteFroalaInstance = useRef([]);
  const [footNoteIndex, setFootNoteIndex] = useState(-1);
  const [claimId, setClaimId] = useState("");
  const [superScriptRefData, setSuperScriptRefData] = useState([]);
  const [showModalAnchor, setShowModalAnchor] = useState(false);
  const [contextMenuOptions, setContextMenuOptions] = useState(undefined);
  const [addOrModifyanchorData, setAddOrModifyAnchorData] = useState(undefined);
  const [eventType, setEventType] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);

  const [showUploadUrlModal, setShowUploadUrlModal] = useState(false);
  const [deleteImg, setDeleteImg] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  let froalaTran = useRef();

  useEffect(() => {
    //to make back ground blur for denovo module
    if (props.setBgBlurForDenovo) {
      props.setBgBlurForDenovo(showModalAnchor);
    }
  }, [showModalAnchor]);

  useEffect(() => {
    setAbbrStatus(abbrStatsu);
  }, [abbrStatsu]);
  useEffect(() => {
    setSelectedTextPrimaryClaimValue(textSelectedAbbr);
  }, [textSelectedAbbr]);
  useEffect(() => {
    setFootNoteText(suppClaimfootNoteText);
  }, [suppClaimfootNoteText]);
  useEffect(() => {
    setSelectedFootNotes(row[2].data);
  }, [row[2].data]);
  //Footenotes Code Start
  const [showFootNote, setFootNoteModal] = useState(false);
  const [footNoteText, setFootNoteText] = useState([]);
  const [addBtnStatus, setAddBtnStatus] = useState(false);
  const [addIconStatus, setAddIconStatus] = useState(false);
  const [selectedFootNotes, setSelectedFootNotes] = useState([]);
  const [footNoteIcon, setFootNoteIcon] = useState([]);
  const [footNoteCheckBox, setFootNoteCheckBox] = useState([]);
  const [error, setError] = useState("");
  //const [selectText, getSelectText] = useState(-1);
  const referencesValid = useRef(null);

  const handleShowOnlineurlModal = () => {
    setSelectedDiv("imageUrl");
    setShowUploadUrlModal(true);
    setIsUpload(false);
  };
  const hideOnlineurlModal = () => {
    setShowUploadUrlModal(false);
  };

  const handleClickOutside = (event) => {
    const uploadimage = document.querySelector(".uploadimage");
    if (uploadimage && !uploadimage.contains(event.target)) {
      setIsUpload(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickAnchor = () => {
    setShowModalAnchor(true);
  };
  const handleCloseReferenceAnchor = () => {
    setShowModalAnchor(false);
  };

  const handleAlertRemove = () => {
    props.handleAlertRemove();
  };

  const handleFootNote = (event) => {
    setFootNoteModal(true);
    event.stopPropagation();
    props.onBoxSelected(event);
    setRightClickTriggered(false);
  };

  const handleFootNoteMOdalClose = () => {
    setFootNoteText([]);
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setFootNoteModal(false);
    setFootNoteCheckBox([]);
  };

  const handleFootNoteInputChange = (TextChanged) => {
    referencesValid.current.innerHTML = TextChanged;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(true);
    } else if (TextChanged.trim().length !== 0) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    } else {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    }
  };

  const handleAddFootNote = () => {
    const fDataOne = footNoteText;
    const fDataTwo = footNoteIcon;
    const finalFootNoteData = {
      footNoteText: fDataOne.footText,
      footNoteIcon: fDataTwo.footNoteIconValue,
    };
    setSelectedFootNotes(selectedFootNotes.concat(finalFootNoteData));
    props.addFootNotesToMasterArray(finalFootNoteData);
    addSupScript();
    setAddBtnStatus(false);
    setAddIconStatus(false);
    setFootNoteText([]);
    setFootNoteModal(false);
  };

  const getIconValue = (IconValue) => {
    if (IconValue) {
      const newIconValue = { footNoteIconValue: IconValue };
      setFootNoteIcon(newIconValue);
      setAddIconStatus(true);
    }
  };

  const [checkBoxHelper, setCheckBoxHelper] = useState([]);
  const handleFootNoteCheckBox = (event, index, icon) => {
    if (event.target.checked) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) == -1) {
          setFootNoteCheckBox([...footNoteCheckBox, icon]);
        }
      } else {
        setFootNoteCheckBox([icon]);
      }
      setCheckBoxHelper(checkBoxHelper.concat(icon));
    } else {
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) !== -1) {
          setFootNoteCheckBox(
            footNoteCheckBox.filter((existingItem) => {
              return existingItem !== icon;
            })
          );
        }
      }
      setCheckBoxHelper(
        checkBoxHelper.filter((item) => {
          return item !== icon;
        })
      );
    }
  };

  useEffect(() => {
    if (checkBoxHelper && checkBoxHelper.length > 0) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
    } else {
      setAddIconStatus(false);
      setAddBtnStatus(false);
      setIconValueToInitial();
      setFootNoteCheckBox([]);
    }
  }, [checkBoxHelper]);

  const setAllToInitial = () => {
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setIconValueToInitial();
    setFootNoteCheckBox([]);
    setFootNoteText([]);
  };

  const handleAddFootNoteCheckBox = () => {
    addSupScript1();
    setAddBtnStatus(false);
    setAddIconStatus(false);
    setFootNoteText([]);
    setFootNoteModal(false);
  };

  const setIconValueToInitial = () => {
    setFootNoteIcon([]);
    setAddIconStatus(false);
  };

  const addSupScript = () => {
    /* CODE FOR foot nOTES*/
    //<sup>superscript</sup>
    if (
      footNoteIcon &&
      selectedTextPrimaryClaimValue &&
      froalaInstance.current.editor
    ) {
      // var froalaString = froalaInstance.current.editor.html
      //   .get()
      //   .replace(
      //     selectedTextPrimaryClaimValue,
      //     `${selectedTextPrimaryClaimValue}<sup>${footNoteIcon.footNoteIconValue}</sup>`
      //   );
      let currentString = froalaInstance.current.editor.html.get();
      const froalaString = addFootnoteSuperScript(
        currentString,
        footNoteCheckBox,
        footNoteIcon.footNoteIconValue,
        selectedTextPrimaryClaimValue
      );
      froalaInstance.current.editor.html.set(froalaString);
      froalaInstance.current.editor.selection.save();
      //getSelectText("");
      const html = froalaInstance.current.editor.html.get(true);
      froalaInstance.current.editor.selection.clear();
      //setIsFreeze(true);
      props.saveCaptionSUperScript(froalaString);
      setSelectedTextPrimaryClaimValue("");
      setFootNoteIcon([]);
      setFootNoteCheckBox([]);
      //props.handleInputChangeFootNote(froalaString);
    }
  };

  const addSupScript1 = () => {
    /* CODE FOR foot nOTES*/
    //<sup>superscript</sup>
    if (
      footNoteCheckBox &&
      footNoteCheckBox.length !== 0 &&
      selectedTextPrimaryClaimValue &&
      froalaInstance.current.editor
    ) {
      let currentString = froalaInstance.current.editor.html.get();

      const froalaString = addFootnoteSuperScript(
        currentString,
        footNoteCheckBox,
        footNoteIcon.footNoteIconValue,
        selectedTextPrimaryClaimValue
      );
      froalaInstance.current.editor.html.set(froalaString);
      // setIsFreeze(true);
      props.saveCaptionSUperScript(froalaString);
      froalaInstance.current.editor.selection.save();
      setSelectedTextPrimaryClaimValue("");
      const html = froalaInstance.current.editor.html.get(true);
      froalaInstance.current.editor.selection.clear();
      setFootNoteIcon([]);
      setFootNoteCheckBox([]);
      //props.handleInputChangeFootNote(froalaString);
    }
  };
  //Footnote Code Ends

  //const [currentImage, setCurrentImage] = useState(0);

  //const handleCont = () => {};
  const onSuperScriptOptionClick = (docId, event, type) => {
    setShowModal(true);
    setAddOrModifyAnchorData({ docId: docId, anchorText: "" });
    if (event !== "delete") {
      if (type === "supporting-visual") {
        props.onSuperScriptClick(docId, event, type, {
          rowIndex: rowIndex,
          index: currentTextReplaceLocal,
        });
      } else if (type === "supporting-visual-footnote") {
        props.onSuperScriptClick(docId, event, type, {
          rowIndex: rowIndex,
          index: footNoteIndex,
        });
      }
    } else if (event === "delete") {
      if (type === "supporting-visual") {
        //saveAnchorSupportingVisual(docId, "")
        skipAnchorSupportingVisual(docId, "", {
          type: "supporting-visual",
          rowIndex: rowIndex,
          index: currentTextReplaceLocal,
        });
      } else if (type === "supporting-visual-footnote") {
        //saveAnchorSupportingVisual(docId, "")
        skipAnchorSupportingVisual(docId, "", {
          type: "supporting-visual-footnote",
          rowIndex: rowIndex,
          index: footNoteIndex,
        });
      }
    }
  };
  const onSupClick = (event, type) => {
    const el = event.target;
    if (el && el.dataset) {
      setEventType(el);
      const docId = el.dataset.docid;
      if (docId && docId.trim().length > 0) {
        setContextMenuOptions({
          event: "modify",
          docId: docId,
          options: ["View", "Modify", "Delete"],
          head: "Anchor",
          type: type,
        });
      }
    }
  };

  const handleCont = (event) => {
    if (event && event.target.nodeName === "SUP") {
      props.setAnchorAddedIn({
        type: "supporting-visual",
        rowIndex: rowIndex,
        index: currentTextReplaceLocal,
      });
      onSupClick(event, "supporting-visual");
      return;
    }
    let SelectedText = "";

    try {
      if (froalaInstance.current) {
        let y = froalaInstance.current?.getEditor()?.toolbar?.hide();
        setTimeout(function () {
          SelectedText = froalaInstance.current?.getEditor()?.selection?.text();
          let x = froalaInstance.current?.getEditor()?.toolbar?.hide();
          setSelectedTextPrimaryClaimValue(SelectedText);
          //setRightClickTriggered(true);
        }, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleContFootNote = (event) => {
    if (event && event.target.nodeName === "SUP") {
      props.setAnchorAddedIn({
        type: "supporting-visual-footnote",
        rowIndex: rowIndex,
        index: footNoteIndex,
      });
      onSupClick(event, "supporting-visual-footnote");
      return;
    }

    let SelectedText = "";
    try {
      if (footNoteFroalaInstance.current[footNoteIndex]) {
        console.log(
          footNoteFroalaInstance.current[footNoteIndex],
          "froalaInstance.current"
        );
        let y = footNoteFroalaInstance.current[footNoteIndex]
          ?.getEditor()
          ?.toolbar?.hide();
        setTimeout(function () {
          SelectedText = footNoteFroalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.selection?.text();
          let x = footNoteFroalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.toolbar?.hide();
          setSelectedTextPrimaryClaimValue(SelectedText);
          //setRightClickTriggered(true);
        }, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  document.addEventListener("mouseup", (event) => {
    event.preventDefault();
    if (rightClickTriggered) {
      setTimeout(function () {
        hideEditorToolBar(true);
      }, 2);
    }
  });

  // useEffect(() => {
  //     if(!rightClickTriggered){
  //       hideEditorToolBar(false)
  //     }
  // }, [rightClickTriggered])

  const hideEditorToolBar = (flag) => {
    if (
      froalaInstance &&
      froalaInstance.current &&
      froalaInstance.current.getEditor() &&
      froalaInstance.current.getEditor().toolbar
    ) {
      if (flag) {
        froalaInstance.current.getEditor().toolbar.hide();
      } else {
        froalaInstance.current.getEditor().toolbar.show();
      }
    }
  };
  const onContextMenuItemSelected = (event) => {};
  useEffect(() => {
    setSelectedTextPrimaryClaimValue(textSelectedAbbr);
  }, [textSelectedAbbr]);
  const prevBtnDisabled = currentImageReplaceLocal <= 0;

  const nextbtnDisabled =
    (row && row[0] && row[0].data && row[0].data.length === 0) ||
    (row &&
      row[0] &&
      row[0].data &&
      row[0].data.length - 1 === currentImageReplaceLocal);

  const changeCurrentImageSelection = (val) => {
    if (val === -1 && currentImageReplaceLocal === 0) {
      return;
    }
    const newCurrentImage = currentImageReplaceLocal + val;
    setCurrentImageReplaceLocal(newCurrentImage);
    SetCurrentImageReplace(newCurrentImage);
  };

  const changeCurrentImageSelectionToLast = () => {
    const newCurrentImage =
      row && row[0] && row[0].data && row[0].data.length - 1;

    setCurrentImageReplaceLocal(newCurrentImage);
    SetCurrentImageReplace(newCurrentImage);
  };

  const changeCurrentVisualTextSelection = (val) => {
    // setIsFreeze(true);
    if (val === -1 && currentTextReplaceLocal === 0) {
      return;
    }

    const newCurrentCaption = currentTextReplaceLocal + val;
    //setCurrentImage(newCurrentImage);

    setCurrentTextReplaceLocal(newCurrentCaption);
    SetCurrentVisualTextReplace(newCurrentCaption);
  };

  const changeCurrentVisualTextSelectionToLast = () => {
    // setIsFreeze(true);
    const newCurrentCaption =
      row && row[1] && row[1].data && row[1].data.length - 1;
    setCurrentTextReplaceLocal(newCurrentCaption);
    SetCurrentVisualTextReplace(newCurrentCaption);
  };

  const handleAbbriviation = (event) => {
    setIsAbbr(true);
    event.stopPropagation();
    props.onBoxSelected(event);
    setRightClickTriggered(false);
  };
  const handleCloseAbbreviation = () => {
    setIsAbbr(false);

    const newReferences = arrAnnot.map((value) => {
      value = "<p>" + value + "</p>";
      return value;
    });
    setReferences(newReferences);
    setSelectedTextPrimaryClaimValue("");
  };
  // const [
  //   selectedTextPrimaryClaimValue,
  //   setSelectedTextPrimaryClaimValue,
  // ] = useState("");
  const handleArrAbbr = (val) => {
    if (val) {
      val[0]["record_index"] = maxAbbrRecordIndex() + 1;
    }
    let existingAbbriviations = [...row[3].data];
    for (const abbreviation of existingAbbriviations) {
      if (abbreviation.abbrText === val[0].abbrText) {
        setIsAbbr(false);
        setSelectedTextPrimaryClaimValue("");
        return;
      }
    }
    if (val) {
      setArrAbbr(arrAbbr.concat(val));
    }
    setIsAbbr(false);
    addAbbrToMasterVisual(val);
    setSelectedTextPrimaryClaimValue("");
  };
  const handleAbbrDataUpdate = (val) => {
    handleAddRadioAbbr(val);
    setSelectedTextPrimaryClaimValue("");
  };
  const prevBtnDisabledText = currentTextReplaceLocal <= 0;

  const nextbtnDisabledText =
    (row && row[1] && row[1].data && row[1].data.length === 0) ||
    (row &&
      row[1] &&
      row[1].data &&
      row[1].data.length - 1 === currentTextReplaceLocal);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const validationSchema = yup.object().shape({
    file: yup

      .mixed()
      .required("Please select file")
      .test("fileFormat", "Unsupported Format", (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type);
      }),
  });

  const imgData = row[0].data;
  const imgDataLength = imgData.length;
  const isImgBtnDisabled =
    imgData[imgDataLength - 1] && imgData[imgDataLength - 1].imgSrc === ""
      ? true
      : false;
  const captionData = row[1].data;
  const captionDataLength = captionData.length;
  const isCapBtnDisabled =
    captionData[captionDataLength - 1] &&
    captionData[captionDataLength - 1].cpationText === ""
      ? true
      : false;

  const fileUplodedErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  //Reference and Annotation(new code)
  const [showModal, setShowModal] = useState(false);
  const [refText, setRefText] = useState([]);
  const [refAddStatus, setrefAddStatus] = useState(false);
  const [tempMasterList, setTempMasterList] = useState([]);
  const [tempSuppRef, setTempSuppRef] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState([]);
  let prevProps = usePrevious({
    selectedTextPrimaryClaimValue,
    referenceNumber,
  });

  const handleSourceModuleToggle = (rowIndex, checked) => {
    setSupportingClaimsAndVisuals((prevSupportingClaimsAndVisuals) => {
      const newArray = [...prevSupportingClaimsAndVisuals];
      newArray[rowIndex] = {
        ...newArray[rowIndex],
        isMustUse: checked,
      };
      return newArray;
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;

    setSupportingClaimsAndVisuals((prevSupportingClaimsAndVisuals) => {
      const newArray = [...prevSupportingClaimsAndVisuals];
      newArray[rowIndex] = {
        ...newArray[rowIndex],
        usageGuidelines: value,
      };
      return newArray;
    });
  };
  useEffect(() => {
    setRefText(atomTextForRef);
    setrefAddStatus(true);
  }, [atomTextForRef]);

  const handleCloseRefModal = () => {
    setShowModal(false);
    setReferenceNumber([]);
    props.setAnchorAddedIn(undefined);
    setAddOrModifyAnchorData(undefined);
  };

  const handleReference = (event) => {
    setShowModal(true);
    event.stopPropagation();
    props.onBoxSelected(event, {
      type: "supporting-visual",
      rowIndex: rowIndex,
      index: currentTextReplaceLocal,
    });
  };
  const handleFootnoteReference = (event) => {
    setShowModal(true);
    event.stopPropagation();
    props.onBoxSelected(event, {
      type: "supporting-visual-footnote",
      rowIndex: rowIndex,
      index: footNoteIndex,
    });
  };

  const updateInMasterList = (selectedRefCount, finalData) => {
    let result = [];
    selectedRefCount.forEach(function (entry) {
      let obj = finalData.find((o) => o.refId === entry);
      result.push(obj.refId);
    });
    setReferenceNumber(result);
    const newArray = [...referenceMasterList];
    const refTempArray = [...row[4].data];
    newArray.push.apply(newArray, finalData);
    setTempMasterList(newArray);
    refTempArray.push.apply(refTempArray, finalData);
    const arrayTemp = refTempArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const helperArray = Object.values(arrayTemp);
    const finalMergedArray = removeDuplicates(helperArray, "refId");
    let filteredFinalArray = removeDuplicateAnnotation(finalMergedArray);
    // for (let i = 0; i < finalMergedArray.length; i++) {
    //   for (let j = 0; j < finalMergedArray[i]["annotationList"].length; j++) {
    //     var newResult = [
    //       ...new Map(
    //         finalMergedArray[i]["annotationList"].map((o) => [
    //           JSON.stringify(o),
    //           o,
    //         ])
    //       ).values(),
    //     ];
    //     finalMergedArray[i]["annotationList"] = newResult;
    //   }
    // }
    setTempSuppRef(filteredFinalArray);
  };

  useEffect(() => {
    props.saveSuppVisualRef(tempSuppRef);
    setShowModal(false);
    setReferenceNumber([]);
    //setTempSuppRef([]);
  }, [tempSuppRef]);

  const removeDuplicates = (originalArray, refId) => {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][refId]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  const removeDuplicateAnnotation = (finalListmergedArray) => {
    const filteredData = finalListmergedArray.map((item) => {
      const includedText = new Map();
      const annotationList = item.annotationList.filter((content) => {
        if (includedText.has(content.annotText)) return false;
        includedText.set(content.annotText);
        return true;
      });
      return { ...item, annotationList };
    });
    return filteredData;
  };

  useEffect(() => {
    if (claimId === "footNoteVisual") {
      if (
        referenceNumber &&
        referenceNumber.length !== 0 &&
        (selectedTextPrimaryClaimValue || eventType) &&
        typeof footNoteFroalaInstance.current[footNoteIndex] != "undefined" &&
        footNoteFroalaInstance.current[footNoteIndex].editor &&
        footNoteFroalaInstance.current[footNoteIndex].editor != "undefined"
      ) {
        // let replaceNum = referenceNumber.map((el) => `<sup>[${el}]</sup>`);
        // if (prevProps.referenceNumber !== referenceNumber) {
        if (
          eventType &&
          referenceNumber &&
          referenceNumber.length !== 0 &&
          footNoteFroalaInstance.current[footNoteIndex].editor
        ) {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            referenceNumber.forEach((el) => {
              const style = getRefSuperscriptStyle(el.color);
              const greenStyle = getRefSuperscriptStyle("r");

              //Remove old superscripts with the same value
              let oldText =
                footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}" >${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}">${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );
              footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
                oldText
              );
              footNoteFroalaInstance.current[
                footNoteIndex
              ].editor.selection.save();
              const html =
                footNoteFroalaInstance.current[footNoteIndex].editor.html.get(
                  true
                );
              footNoteFroalaInstance.current[
                footNoteIndex
              ].editor.selection.clear();
              let data = [...row[2].data];
              let index = data.findIndex(
                (obj, index) => index === footNoteIndex
              );
              data[index].footNoteText = oldText;
              props.saveFootNoteRefSupVisual(data);
            });
            setEventType(undefined);
          }
        } else {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            //process here
            let replaceNum = referenceNumber.map((el) => {
              const style = getRefSuperscriptStyle(el.color);

              const redStyle = getRefSuperscriptStyle("r");
              let oldText =
                footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
                ""
              );

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
                ""
              );

              footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
                oldText
              );
              return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
            });
            let currentString =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
            var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
            var suptext = [];
            words.forEach((item, index, array) => {
              let elIndex = words.indexOf(item);
              let regex = /(<([^>]+)>)/gi;
              let regex2 = /(\[.+\])/gi;
              let result = item.replace(regex, "").trim();
              let tempResult = result.replace(regex2, "").trim();
              const selectedTextForCompare =
                selectedTextPrimaryClaimValue.trim();
              if (tempResult === selectedTextForCompare) {
                if (elIndex < words.length - 1) {
                  let suptext1 = Object.values(
                    array[index + 1]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index + 1]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  let suptext2 = Object.values(
                    array[index]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  suptext = suptext.concat(suptext1, suptext2);
                } else if (elIndex === words.length - 1) {
                  suptext = Object.values(
                    array[index]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                } else {
                  return;
                }
              }
            });

            if (suptext) {
              replaceNum = replaceNum.filter((val) => !suptext.includes(val));
            }
            const textToBeReplaced = selectedTextPrimaryClaimValue.concat(
              suptext.toString().replaceAll(",", "")
            );
            suptext = suptext.concat(replaceNum);
            // const textToReplaceWith = selectedTextPrimaryClaimValue.concat(
            //   suptext.toString().replaceAll(",", "")
            // );
            const textToReplaceWith = "".concat(
              suptext.toString().replaceAll(",", "")
            );
            let currentClaimString =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
            const froalaString = sortSuperScriptNumbers(
              currentClaimString,
              textToBeReplaced,
              textToReplaceWith,
              selectedTextPrimaryClaimValue
            );

            footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
              froalaString
            );
            footNoteFroalaInstance.current[
              footNoteIndex
            ].editor.selection.save();
            //console.log("froalaString", froalaString);
            setSelectedTextPrimaryClaimValue("");
            const html =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get(
                true
              );
            footNoteFroalaInstance.current[
              footNoteIndex
            ].editor.selection.clear();
            let data = [...row[2].data];
            let index = data.findIndex((obj, index) => index === footNoteIndex);
            data[index].footNoteText = froalaString;
            props.saveFootNoteRefSupVisual(data);
            //handleFootNoteTextChangeDirect(froalaString,footNoteIndex);
          }
        }
      }
    } else {
      if (
        referenceNumber &&
        referenceNumber.length !== 0 &&
        (selectedTextPrimaryClaimValue || eventType) &&
        typeof froalaInstance.current != "undefined" &&
        froalaInstance.current.editor &&
        froalaInstance.current.editor != "undefined"
      ) {
        // let replaceNum = referenceNumber.map((el) => `<sup>[${el}]</sup>`);
        // if (prevProps.referenceNumber !== referenceNumber) { -----------_Prevois code
        if (
          referenceNumber &&
          referenceNumber.length !== 0 &&
          eventType &&
          typeof froalaInstance.current != "undefined" &&
          froalaInstance.current.editor &&
          froalaInstance.current.editor != "undefined"
        ) {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            referenceNumber.forEach((el) => {
              const style = getRefSuperscriptStyle(el.color);
              const greenStyle = getRefSuperscriptStyle("r");

              //Remove old superscripts with the same value
              let oldText = froalaInstance.current.editor.html.get();
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}" >${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}">${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              froalaInstance.current.editor.html.set(oldText);
              froalaInstance.current.editor.selection.save();
              const html = froalaInstance.current.editor.html.get(true);
              froalaInstance.current.editor.selection.clear();
              //setIsFreeze(true);
              props.saveCaptionSUperScript(oldText);
            });
            setEventType(undefined);
          }
        } else {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            //process here
            let replaceNum = referenceNumber.map((el) => {
              const style = getRefSuperscriptStyle(el.color);

              const redStyle = getRefSuperscriptStyle("r");
              let oldText = froalaInstance.current.editor.html.get();
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
                ""
              );

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
                ""
              );

              froalaInstance.current.editor.html.set(oldText);
              return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
            });
            let currentString = froalaInstance.current.editor.html.get();
            var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
            var suptext = [];
            words.forEach((item, index, array) => {
              let elIndex = words.indexOf(item);
              let regex = /(<([^>]+)>)/gi;
              let regex2 = /(\[.+\])/gi;
              let result = item.replace(regex, "").trim();
              let tempResult = result.replace(regex2, "").trim();
              const selectedTextForCompare =
                selectedTextPrimaryClaimValue.trim();
              if (tempResult === selectedTextForCompare) {
                if (elIndex < words.length - 1) {
                  let suptext1 = Object.values(
                    array[index + 1]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index + 1]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  let suptext2 = Object.values(
                    array[index]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  suptext = suptext.concat(suptext1, suptext2);
                } else if (elIndex === words.length - 1) {
                  suptext = Object.values(
                    array[index]
                      .replaceAll(/\s/g, "")
                      .match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index]
                          .replaceAll(/\s/g, "")
                          .match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                } else {
                  return;
                }
              }
            });
            if (suptext) {
              replaceNum = replaceNum.filter((val) => !suptext.includes(val));
            }

            const textToBeReplaced = selectedTextPrimaryClaimValue.concat(
              suptext.toString().replaceAll(",", "")
            );
            suptext = suptext.concat(replaceNum);
            // const textToReplaceWith = selectedTextPrimaryClaimValue.concat(
            //   suptext.toString().replaceAll(",", "")
            // );
            const textToReplaceWith = "".concat(
              suptext.toString().replaceAll(",", "")
            );
            let currentClaimString = froalaInstance.current.editor.html.get();
            const froalaString = sortSuperScriptNumbers(
              currentClaimString,
              textToBeReplaced,
              textToReplaceWith,
              selectedTextPrimaryClaimValue
            );
            // let froalaString = froalaInstance.current.editor.html
            //   .get()
            //   .replace(
            //     selectedTextPrimaryClaimValue,
            //     `${selectedTextPrimaryClaimValue}${replaceNum} `
            //   );
            // let froalaString = froalaInstance.current.editor.html
            //   .get()
            //   .replace(textToBeReplaced, textToReplaceWith);
            froalaInstance.current.editor.html.set(froalaString);
            froalaInstance.current.editor.selection.save();
            //console.log("froalaString", froalaString);
            //setIsFreeze(true);
            props.saveCaptionSUperScript(froalaString);
            setSelectedTextPrimaryClaimValue("");
            const html = froalaInstance.current.editor.html.get(true);
            froalaInstance.current.editor.selection.clear();
            //handleFootNoteTextChangeDirect(froalaString,footNoteIndex);
          }
          //setReferenceNumber([]);
        }
      }
    }
    setClaimId("");
  }, [referenceNumber]);
  //CODE- Custom hook to compare two Props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleFootNoteTextChangeDirect = (event, rowId) => {
    if (rowId > -1) {
      var data = [...row[2].data];
      var index = data.findIndex((obj, index) => index === rowId);
      data[index].footNoteText = event;
      //setSelectedFootNotes(data);
      props.footNoteDirectEditSaveVisual(data);
    }
    //setFootNoteIndex(-1);
    setReferenceNumber([]);
    //getSelectText("");
    let temp = [];
    row[2].data.forEach((el) => temp.push(el.footNoteText));
    let newModel = temp.join();
    setSuperScriptRefData(newModel);
    setSelectedRow(rowIndex);
    //handleDelete(newModel);
  };
  const handleDelete = (model) => {
    let modelText = model;
    let afterDeleted = Object.values(
      modelText.replaceAll(/\s/g, "").match(/<sup>(.*?)<\/sup>/g)
        ? modelText.replaceAll(/\s/g, "").match(/<sup>(.*?)<\/sup>/g)
        : ""
    );

    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      setSuperScriptRefData(unique);
      setSelectedRow(rowIndex);
    }
  };

  useEffect(() => {
    let uniqueFootNoteRef = getSuperScriptsFromCaption();
    let citationFromFootNote = getSuperScriptsFromFootnote();
    let linkedFalse = row[4].data.filter((o) => o.veevaLinked == false);
    let finalResult = [...uniqueFootNoteRef, ...citationFromFootNote];
    let finalRef = [...new Set(finalResult)];
    let tempPrimary = [];
    finalRef.forEach((el) => {
      const obj = row[4].data.filter((o) => o.refId == el);
      tempPrimary.push.apply(tempPrimary, obj);
    });
    let tempResult = [];
    citationFromFootNote.forEach((element) => {
      if (parseInt(element)) {
        const obj = tempPrimary.find((o) => o.refId === element);
        tempResult.push(obj);
      }
    });
    let notPresentInVisualFootNoteBox = tempPrimary.filter(
      checkForUnique(tempResult)
    );
    notPresentInVisualFootNoteBox.forEach((element) => {
      let itemPosition = element.annotationList.findIndex(
        (obj) =>
          obj.rowIndex === rowIndex &&
          obj.linkType === "supporting-visual-footnote" &&
          obj.compIndex === footNoteIndex
      );
      if (itemPosition > -1) {
        element.annotationList.splice(itemPosition, 1);
      }
      return element;
    });
    let data = tempResult.concat(notPresentInVisualFootNoteBox, linkedFalse);
    setFootNoteIndex(-1);
    //let data = tempPrimary.concat(linkedFalse);
    props.resetReferenceVisual(data, rowIndex);
    //For Abbreviation delete
    let tempCaption = [];
    row[1].data.forEach((el) => tempCaption.push(el.cpationText));
    let captionString = tempCaption.join().toLowerCase();
    let footNoteValue = [];
    row[2].data.forEach((element) => footNoteValue.push(element.footNoteText));
    let footNoteTextString = footNoteValue.join().toLowerCase();
    let finalStringToBeChecked = footNoteTextString.concat(captionString);
    let abbreviations = [...(row[3] && row[3].data)];
    abbreviations.forEach((element, index) => {
      let result = finalStringToBeChecked.search(
        element.title.toLowerCase().trim()
      );
      if (result < 0) {
        abbreviations.splice(index, 1);
      }
    });
    resetSupportingVisualAbbreviations(abbreviations, rowIndex);
  }, [superScriptRefData]);

  const getSuperScriptsFromCaption = () => {
    let temp = [];
    row[1].data.forEach((el) => temp.push(el.cpationText));
    let modelText = temp.join();
    modelText = modelText.replaceAll(/\s/g, "");
    //let modelText = model;
    let afterDeleted = Object.values(
      modelText.match(/<sup(.*?)<\/sup>/g)
        ? modelText.match(/<sup(.*?)<\/sup>/g)
        : ""
    );

    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      return unique;
    }
  };
  const checkForUnique = (otherArray) => {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other && other.refId == current.refId;
        }).length == 0
      );
    };
  };
  const [helperVar, setHelperVar] = useState([]);
  const [fNote, setFNote] = useState([]);
  useEffect(() => {
    let citationFromCaption = getSuperScriptsFromCaption();
    let tempFootnote = [];
    citationFromCaption.forEach((el) => {
      const objFoot = row[2].data.filter((o) => o.footNoteIcon == el);
      tempFootnote.push.apply(tempFootnote, objFoot);
    });
    props.resetFootNotesVisual(tempFootnote, rowIndex);
    setTimeout(() => {
      let uniqueFootNoteRef = getSuperScriptsFromFootnote();
      let linkedFalse = row[4].data.filter((o) => o.veevaLinked == false);
      let finalResult = [...uniqueFootNoteRef, ...citationFromCaption];
      let finalRef = [...new Set(finalResult)];
      let tempPrimary = [];
      finalRef.forEach((el) => {
        const obj = row[4].data.filter((o) => o.refId == el);
        tempPrimary.push.apply(tempPrimary, obj);
      });
      let tempResult = [];
      citationFromCaption.forEach((element) => {
        if (parseInt(element)) {
          const obj = tempPrimary.find((o) => o.refId === element);
          tempResult.push(obj);
        }
      });
      let notPresentInCaptionBox = tempPrimary.filter(
        checkForUnique(tempResult)
      );
      notPresentInCaptionBox.forEach((element) => {
        let itemPosition = element.annotationList.findIndex(
          (obj) =>
            obj.rowIndex === rowIndex &&
            obj.linkType === "supporting-visual" &&
            obj.compIndex === currentTextReplaceLocal
        );
        if (itemPosition > -1) {
          element.annotationList.splice(itemPosition, 1);
        }
        return element;
      });
      let data = tempResult.concat(notPresentInCaptionBox, linkedFalse);
      props.resetReferenceVisual(data, rowIndex);
      //For Abbreviation delete
      let tempCaption = [];
      row[1].data.forEach((el) => tempCaption.push(el.cpationText));
      let captionString = tempCaption.join().toLowerCase();
      let footNoteValue = [];
      tempFootnote.forEach((element) =>
        footNoteValue.push(element.footNoteText)
      );
      let footNoteTextString = footNoteValue.join().toLowerCase();
      let finalStringToBeChecked = footNoteTextString.concat(captionString);
      let abbreviationTitles = [];
      row[3].data.forEach((element, index) => {
        let result = finalStringToBeChecked.search(
          element.title.toLowerCase().trim()
        );
        if (result < 0) {
          abbreviationTitles.push(element.title);
        }
      });
      abbreviationTitles.forEach((el) => {
        let index = row[3].data.findIndex((obj) => obj.title === el);
        row[3].data.splice(index, 1);
      });
      resetSupportingVisualAbbreviations(row[3].data, rowIndex);
    }, 0);
  }, [props.superScriptDataForVisual]);

  const getSuperScriptsFromFootnote = () => {
    let uniqueFootNoteRef = [];
    let myArray = [];
    row[2].data.map((object) => {
      var para = object.footNoteText;
      if (para && para.includes("<sup")) {
        para = para.replaceAll(/\s/g, "");
        var Result = para.includes("<sup")
          ? Object.values(para.match(/<sup(.*?)<\/sup>/g))
          : Object.values(para.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    uniqueFootNoteRef = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return uniqueFootNoteRef;
  };
  //Reference code Ends
  const [visualLinkModal, setVisualLinkModal] = useState(false);

  useEffect(() => {
    if (selectedRow !== rowIndex) {
      setVisualLinkModal(false);
    }
  }, [selectedRow]);

  const supportingVisualLinkModal = () => {
    setVisualLinkModal(!visualLinkModal);
  };

  const updateVisulLinkData = (visualData, dependency) => {
    updateVisualLinks(rowIndex, visualData, dependency);
  };
  const dependency = row && row[5] && row[5].dependency;
  const selectedLinkClaims = (row && row[5] && row[5].claim) || [];

  const [captionHashKey, setCaptionHashkey] = useState();

  useEffect(() => {
    const hash = Date.now();
    setCaptionHashkey(hash);
  }, [currentTextReplaceLocal]);

  const currentCaptionCount = currentTextReplaceLocal + 1;

  //New Reference and Anchors code starts here
  const getNewRefId = () => {
    const linkedDocuments = props.referenceDocuments || [];
    if (linkedDocuments.length > 0) {
      let refList = [...linkedDocuments].map((doc) => {
        return doc.refId;
      });
      refList = refList.map((ref) => {
        return parseInt(ref);
      });
      const maxRefId = Math.max(...refList);
      if (maxRefId && maxRefId > 0) {
        return maxRefId + 1;
      }
    }
    return 1;
  };
  const updateSupportingVisualReference = (selectedReferences) => {
    const tempResult = [];

    selectedReferences.forEach((el) => {
      const obj = {
        id: el.id,
        veevaReferenceName:
          moduleType === moduleTypes?.denovo ? el?.name : el?.name__v,
        refId: el.refId,
        annotationList: el.annotationList || [],
        veevaLinked: false,
      };
      tempResult.push(obj);
    });
    const refDocs = props.referenceDocuments;
    //for (var i = 0, len = row[4].data.length; i < len; i++) {
    for (var i = 0, len = refDocs.length; i < len; i++) {
      for (var j = 0, len2 = tempResult.length; j < len2; j++) {
        //if (row[4].data[i].id === tempResult[j].id) {
        if (refDocs[i].id === tempResult[j].id) {
          tempResult.splice(j, 1);
          len2 = tempResult.length;
        }
      }
    }
    const newArray = row[4].data.concat(tempResult);
    const refAnchorList = [];
    newArray.forEach((el, index) => {
      let refId = getNewRefId();
      if (selectedReferences.length > 0) {
        selectedReferences.find((ref, index) => {
          if (ref.id === el.id) {
            refId = getNewRefId() + index;
          }
        });
      }

      const obj = {
        id: el.id,
        veevaReferenceName: el.veevaReferenceName,
        refId: el.refId || refId.toString(),
        annotationList: el.annotationList || [],
        veevaLinked: el.veevaLinked || false,
      };
      refAnchorList.push(obj);
    });
    props.updateSupportingVisualReference(refAnchorList);
    setShowModalAnchor(false);
  };
  const getReferenceDocument = (docId) => {
    const docs = props.referenceDocuments || [];
    if (docs.length > 0) {
      const foundDoc = docs.find((doc) => doc.id === docId);
      if (foundDoc) {
        return foundDoc;
      }
    }
    return undefined;
  };

  const saveAnchorSupportingVisual = (docId, anchorCoordinates) => {
    const temp1 = {
      pageNumber: anchorCoordinates.pageNumber,
      column: anchorCoordinates.column,
      para: anchorCoordinates.para,
      line: anchorCoordinates.line,
      anchorLinkFlag: anchorCoordinates.anchorLinkFlag,
      id: uuidv4(),
      linkType: props.anchorAddedIn ? props.anchorAddedIn.type : "",
      compIndex: props.anchorAddedIn ? props.anchorAddedIn.index : undefined,
      rowIndex: props.anchorAddedIn ? props.anchorAddedIn.rowIndex : undefined,
    };
    let data = [...row[4].data];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    if (index > -1) {
      data[index].veevaLinked = true;
      //data[index].annotationList.splice(0, 1, temp1);
      if (addOrModifyanchorData) {
        const indexToBeModified = data[index].annotationList.findIndex((el) => {
          return (
            el.compIndex === temp1.compIndex &&
            el.rowIndex === temp1.rowIndex &&
            el.linkType === temp1.linkType
          );
        });
        if (indexToBeModified > -1) {
          data[index].annotationList[indexToBeModified] = temp1;
        }
      } else {
        data[index].annotationList.push(temp1);
      }

      data[index].annotationList = data[index].annotationList.filter(
        (coordinate, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.pageNumber === coordinate.pageNumber &&
              t.column === coordinate.column &&
              t.para === coordinate.para &&
              t.line === coordinate.line
          )
      );

      setReferenceNumber([
        {
          refId: data[index].refId,
          color: "r",
          veevaDocId: docId,
        },
      ]);
    } else {
      //Do linking and add anchor simultaneously
      let doc = getReferenceDocument(docId);
      if (!doc) return;
      doc["veevaLinked"] = true;
      doc["annotationList"] = [temp1];
      data = data.concat(doc);
      let result = [];
      let obj = data.find((o) => o.id === docId);
      result.push({ refId: obj.refId, color: "r", veevaDocId: docId });
      setReferenceNumber(result);
    }

    //result.push(obj.refId);
    //setReferenceNumber(result);
    setTempSuppRef(data);
    setShowModal(false);
    props.setAnchorAddedIn(undefined);
    setAddOrModifyAnchorData(undefined);
  };

  const skipAnchorSupportingVisual = (docId, anchorText, anchorAddedFrom) => {
    let data = [...row[4].data];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    //Check doc if already exist.
    if (index > -1) {
      data[index].veevaLinked = true;
      if (!anchorText) {
        let obj = data.find((o) => o.id === docId);
        let result = obj.annotationList;
        let itemPosition = result.findIndex((obj, index) => {
          if (anchorAddedFrom.index) {
            return (
              obj.compIndex === anchorAddedFrom.index &&
              obj.rowIndex === anchorAddedFrom.rowIndex &&
              obj.linkType === anchorAddedFrom.type
            );
          }
          return (
            obj.rowIndex === anchorAddedFrom.rowIndex &&
            obj.linkType === anchorAddedFrom.type
          );
        });
        if (itemPosition > -1) {
          result.splice(itemPosition, 1);
        }
        data[index].annotationList = result;
        setReferenceNumber([
          { refId: data[index].refId, color: "r", veevaDocId: docId },
        ]);
      }
    } else {
      //Adding new document from global list.
      let doc = getReferenceDocument(docId);
      if (!doc) return;
      doc["veevaLinked"] = true;
      doc["annotationList"] = [];
      data = data.concat(doc);
      let obj = data.find((o) => o.id === docId);
      setReferenceNumber([{ refId: obj.refId, color: "r", veevaDocId: docId }]);
    }
    setTempSuppRef(data);
    setShowModal(false);
    props.setAnchorAddedIn(undefined);
  };
  const handleUploadIcon = (e) => {
    e.stopPropagation();
    setIsUpload(!isUpload);
    formikRef.current.setErrors({
      file: "",
    });
  };

  const handleChangeImage = (fieldName, value) => {
    setFieldValue({
      ...fieldValue,
      [fieldName]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFileName(
      event.currentTarget.files[0] && event.currentTarget.files[0].name
    );
    handleChangeImage("file", file);
  };
  const clearFormikErrors = () => {
    formikRef.current.setErrors({});
  };
  const handleClickOutsideerr = (event) => {
    const formikForm = formikRef.current;
    const isClickedOutside = formikForm && event.target !== formikForm;
    if (isClickedOutside) {
      clearFormikErrors();
    }
  };

  const handleFileChangedenovo = (event) => {
    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = selectedFile.type;
      setUploadedFileName(fileName);
      setFieldValue("file", selectedFile);
      uploadSuppVisualfromlocal(selectedFile);
      event.target.value = null;
    }
  };

  const uploadSuppVisualfromlocal = (values) => {
    const formData = new FormData();

    formData.append("file", values);
    formData.append("asset_name", values.name);
    formData.append("asset_type", "Atom");
    formData.append("source", "ModuleManager");
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept-Language": "en-US,en;q=0.9",
        "Access-Control-Allow-Origin": "*",
      },
    };
    uploadSuppVisual(formData, options, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        fileUplodedErr(errCoponent);
      } else {
        const { assetUrl, id } = response.data;

        // addImage(rowIndex, "update", currentImageReplaceLocal, assetUrl, id);
        // setUploadedFileName("");
        const isValidFormat = SUPPORTED_FORMATS.includes(values.type);

        if (!isValidFormat) {
          formikRef.current.setErrors({
            file: "Unsupported format",
          });
        } else {
          // formikRef.current.setErrors({});
          addImage(rowIndex, "update", currentImageReplaceLocal, assetUrl, id);
          setUploadedFileName("");
        }
      }
    });
  };
  return (
    <>
      <>
        <div className="row mt-5">
          <div className="col">
            <div className="catLabelTitle d-flex">
              <img
                src={arrowpolygonRight}
                alt=""
                className={
                  moduleType === moduleTypes.denovo
                    ? "arrowCurrentRow arrowresuabletext mr-1"
                    : "arrowCurrentRow  mr-1"
                }
              />
              {t("supportinVisuals.supportTitle")}{" "}
              {supportingVisualPosition + 1}
              <span className="catLabelType verticalLine">|</span>
              <div className="visualLinkModalWrapper">
                {row && row[5] && row[5].claim && row[5].claim.length === 0 ? (
                  <>
                    <button
                      className="btn  p-0 mb-1 linkBtnNormal linkText"
                      onClick={() => {
                        supportingVisualLinkModal();
                        setSelectedRow(rowIndex);
                      }}
                    >
                      Link
                    </button>
                  </>
                ) : (
                  <>
                    <span
                      className={
                        dependency && dependency === "Optional"
                          ? "linkSlectedOptional"
                          : "linkSlectedMandatory"
                      }
                    >
                      {dependency}
                    </span>
                    <button
                      className="btn btn-link p-0 mb-1 linkBtnNormal"
                      onClick={() => {
                        supportingVisualLinkModal();
                        setSelectedRow(rowIndex);
                      }}
                    >
                      | Change link
                    </button>
                  </>
                )}
              </div>
              {moduleType === moduleTypes.denovo && (
                <>
                  {" "}
                  <span className="catLabelType verticalLine">|</span>
                  <div className="disabled-musttoggle">
                    Must Use
                    <Switch
                      className="filterRightMargin  reusabletextToggle mr-2"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={supportingClaimsAndVisuals[rowIndex]?.isMustUse}
                      onChange={(event) =>
                        handleSourceModuleToggle(rowIndex, event.target.checked)
                      }
                    />{" "}
                  </div>
                </>
              )}
              {visualLinkModal && (
                <SupportingVisualLink
                  primaryClaim={primaryClaim}
                  supportingClaims={supportingClaims}
                  updateVisulLinkData={updateVisulLinkData}
                  dependency={dependency}
                  selectedLinkClaims={selectedLinkClaims}
                  setVisualLinkModal={setVisualLinkModal}
                  supportingVisualLinkModal={supportingVisualLinkModal}
                />
              )}
            </div>

            {/* <button
              onClick={() => {
                if (selectedBox > -1) {
                  addBoxContent(rowIndex, selectedBox);
                }
              }}
            >
              Add Content
            </button> */}
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <DeleteIcon
              color="primary"
              onClick={(e) => {
                setIsDelete(true);
                setSelectedRow(rowIndex);
                deleteSuppVisualRow(rowIndex, row[0] && row[0].id);
                setTimeout(() => {
                  setIsDelete(false);
                }, 0);
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-3">
            <div>
              <div
                //className="addvisualBox suppViusalBoxType1 custonScrollBar"
                className={
                  props.displaySupportingVisualAlert && row[0].isEmpty
                    ? "addvisualBoxAlert suppViusalBoxType1 custonScrollBar"
                    : "addvisualBox suppViusalBoxType1 custonScrollBar "
                }
                id={`suppViusalBoxType1${rowIndex}`}
                onClick={() => {
                  // props.handleAlertRemove();
                  setSelectedBox(0);
                  setSelectedRow(rowIndex);
                  //SetCurrentImageReplace(0);
                  setFilterType("graphic");
                  SetCurrentImageReplace(currentImageReplaceLocal);
                  SetBoxSeletedBoxType("graphic");
                  setAtomsHeaderVisual("Images");
                }}
              >
                {row && row[0] && row[0].data && row[0].data.length === 0 ? (
                  <span className="selectClaimBoxText">
                    {t("supportinVisuals.image")}
                  </span>
                ) : (
                  ""
                )}
                <div className="pr-2 pl-3">
                  <img
                    src={
                      box1.data[currentImageReplaceLocal] &&
                      box1.data[currentImageReplaceLocal].imgSrc
                    }
                    className="img-fluid selectClaimBoxText pl-2"
                    alt=""
                  />
                </div>
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    file: undefined,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    const formData = new FormData();
                    formData.append("file", values.file);
                    formData.append("asset_name", values.file.name);
                    formData.append("asset_type", "Atom");
                    formData.append("source", "ModuleManager");
                    const options = {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept-Language": "en-US,en;q=0.9",
                        "Access-Control-Allow-Origin": "*",
                      },
                    };
                    uploadSuppVisual(
                      formData,
                      options,
                      (response, err, errorCode) => {
                        if (err) {
                          const errCoponent = ErrComponentToRender(errorCode);
                          fileUplodedErr(errCoponent);
                        } else {
                          const { assetUrl, id } = response.data;

                          addImage(
                            rowIndex,
                            "update",
                            currentImageReplaceLocal,
                            assetUrl,
                            id
                          );
                          setUploadedFileName("");
                          // setFieldValue();
                        }
                      }
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldError,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit} className="highResText">
                      <>
                        <div className="row mt-5  uploadbtnrow">
                          <div className="col-12">
                            {moduleType === moduleTypes.denovo ? (
                              <input
                                id={`file-${rowIndex}`}
                                name="file"
                                type="file"
                                accept=".jpg, .png, .gif, .jpeg"
                                onChange={(event) => {
                                  handleFileChangedenovo(event);
                                }}
                                // onBlur={() => {
                                //   setFieldError("file", "");
                                // }}
                                // className="form-control d-none"
                                className="hidden-file-input "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsUpload(false);
                                }}
                              />
                            ) : (
                              <input
                                id={`file-${rowIndex}`}
                                name="file"
                                type="file"
                                onChange={(event) => {
                                  setUploadedFileName(
                                    event.currentTarget.files[0] &&
                                      event.currentTarget.files[0].name
                                  );
                                  setFieldValue(
                                    "file",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                className="form-control d-none"
                              />
                            )}
                            <br />
                            {moduleType === moduleTypes.denovo ? (
                              selectedDiv !== "imageUrl" &&
                              // uploadedFileName &&
                              // uploadedFileName
                              ""
                            ) : (
                              <>
                                <label htmlFor={`file-${rowIndex}`}>
                                  {t("supportinVisuals.hiResImg")}
                                </label>
                                {uploadedFileName && uploadedFileName}
                              </>
                            )}

                            {errors.file ? (
                              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                                {errors.file}
                              </p>
                            ) : null}
                          </div>

                          <div className="col-12">
                            {uploadedFileName &&
                              moduleType !== moduleTypes.denovo && (
                                <button
                                  type="submit"
                                  className="btn btn-sm btn-primary boxBtnUpload mt-1"
                                >
                                  {t("supportinVisuals.upload")}
                                </button>
                              )}
                          </div>
                        </div>
                      </>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* {row && row[0] && row[0].data && row[0].data.length !== 0 ? ( */}
              <div className="boxBtnRow">
                {row[0]?.data && row[0]?.data?.length > 0 && (
                  <>
                    <button
                      onClick={(e) => {
                        changeCurrentImageSelection(-1);
                        e.stopPropagation();
                        formikRef.current.setErrors({
                          file: "",
                        });
                      }}
                      disabled={prevBtnDisabled}
                      className={
                        prevBtnDisabled
                          ? "btn disabled pr-1"
                          : "btn btn-link pr-1"
                      }
                    >
                      <img
                        src={arrowLeft}
                        alt="Previous"
                        className="opacity50"
                      />
                    </button>
                    <span className="slideCountCaptions">
                      {/* {currentImageReplaceLocal + 1}/
                  {row && row[0] && row[0].data && row[0].data.length} */}
                      {currentImageReplaceLocal + 1 < 10
                        ? `0${currentImageReplaceLocal + 1}`
                        : currentImageReplaceLocal + 1}
                      /
                      {row && row[0] && row[0].data && row[0].data.length < 10
                        ? `0${row[0].data.length}`
                        : row[0].data.length}
                    </span>

                    <button
                      onClick={(e) => {
                        changeCurrentImageSelection(1);
                        e.stopPropagation();
                        formikRef.current.setErrors({
                          file: "",
                        });
                      }}
                      disabled={nextbtnDisabled}
                      className={
                        nextbtnDisabled
                          ? "btn disabled pl-1"
                          : "btn btn-link pl-1"
                      }
                    >
                      <img
                        src={arrowRight}
                        alt="Next"
                        className="opacity50 frwdicon"
                      />
                    </button>
                    <button
                      onClick={(e) => {
                        deleteImage(rowIndex, currentImageReplaceLocal);
                        setDeleteImg(false);
                        changeCurrentImageSelection(-1);
                        e.stopPropagation();
                        setUploadedFileName(false);
                        if (moduleType === moduleTypes.denovo) {
                          setSelectedDiv("");
                          formikRef.current.setErrors({
                            file: "",
                          });
                        }
                      }}
                      disabled={
                        row && row[0] && row[0].data && row[0].data.length === 0
                      }
                      className="btn btn-link p-0 deleteImg"
                    >
                      {t("supportinVisuals.delete")}
                    </button>
                  </>
                )}
                {moduleType === moduleTypes.denovo && isUpload ? (
                  <div className="uploadimage">
                    <div className=" visualtitle">Upload Visual</div>
                    <hr className="hrtag" />

                    <label
                      className="urlDiv"
                      htmlFor={`file-${rowIndex}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDiv("fromLocal");
                        setUploadedFileName("");
                      }}
                      // onClick={() => handleClickUploadoption("local")}
                    >
                      From Local
                    </label>
                    <div
                      className={`urlDiv imageurl ${
                        selectedDiv === "image" ? "selectedDiv" : ""
                      }`}
                      onClick={() => handleShowOnlineurlModal("imageUrl")}
                    >
                      Image URL
                    </div>
                    {/* <div
                      className={`urlDiv  ${
                        selectedDiv === "image" ? "selectedDiv" : ""
                      }`}
                      // onClick={() => handleShowOnlineurlModal("imageUrl")}
                    >
                      Shared location
                    </div> */}
                  </div>
                ) : (
                  ""
                )}

                {moduleType === moduleTypes.denovo ? (
                  <button
                    className={
                      row[0]?.data && row[0]?.data?.length > 0
                        ? "btn btn-link p-0 ml-2 mr-2 uploadimg"
                        : "btn btn-link p-0 ml-2 mr-2 uploadsvg"
                    }
                    onClick={handleUploadIcon}
                  >
                    {" "}
                    <img src={uploadImage} alt="upload" className="" />
                  </button>
                ) : (
                  ""
                )}

                <button
                  onClick={(e) => {
                    addImage(rowIndex, "add");

                    changeCurrentImageSelectionToLast();
                    setUploadedFileName("");
                    if (moduleType === moduleTypes.denovo) {
                      setSelectedDiv("");
                      formikRef.current.setErrors({
                        file: "",
                      });
                    }
                    setDeleteImg(true);
                    e.stopPropagation();
                  }}
                  disabled={
                    (row &&
                      row[0] &&
                      row[0].data &&
                      row[0].data.length === 0) ||
                    isImgBtnDisabled
                  }
                  className="btn btn-link p-0 ml-2 mr-2"
                >
                  <span className=" float fa fa-plus my-floatImage"></span>
                </button>
              </div>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>

          <div
            className={
              moduleType === moduleTypes?.denovo
                ? "col-md-3 captions"
                : "col-md-3"
            }
          >
            <div
              className="addvisualBox suppViusalBoxType2Minheight mb-2"
              onClick={() => {
                setSelectedBox(1);
                setSelectedRow(rowIndex);
                setFilterType("text");
                SetCurrentVisualTextReplace(currentTextReplaceLocal);
                SetBoxSeletedBoxType("text");
              }}
            >
              {row && row[1] && row[1].data && row[1].data.length !== 0 ? (
                <span className="pl-2 editorActive-heading d-block mb-0">
                  {t("supportinVisuals.captions")}
                </span>
              ) : (
                ""
              )}
              <div
                className="suppViusalBoxType2-inner custonScrollBar"
                id={`suppViusalBoxType2${rowIndex}`}
              >
                {/* <span className="selectClaimBoxText">Captions</span> */}
                {/* <SupportingClaim
                  selectedAtom={
                    box2.data[currentTextReplaceLocal] &&
                    box2.data[currentTextReplaceLocal].cpationText
                  }
                /> */}
                {/* {row && row[1] && row[1].data && row[1].data.length === 0 ? (
                  <span className="selectClaimBoxText">Captions</span>
                ) : (
                  ""
                )} */}
                <FrolaTextEditor
                  forwardedRef={froalaInstance}
                  tag="textarea"
                  selectedAtom={
                    box2.data[currentTextReplaceLocal] &&
                    box2.data[currentTextReplaceLocal].cpationText
                  }
                  handleModelChange={(event) => {
                    handleCptionTextChange(event, rowIndex);
                  }}
                />

                <ContextMenu
                  onContextMenuItemSelected={onContextMenuItemSelected}
                  handleCont={handleCont}
                  handleAbbriviation={handleAbbriviation}
                  footNoteId={"supporting-footnote"}
                  handleFootNote={handleFootNote}
                  abbriviationId={"supporting-abbreviationModal"}
                  compClass={`suppViusalBoxType2${rowIndex}`}
                  handleReference={handleReference}
                  referenceId={"visual-referenceModal"}
                  selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
                  setRightClickTriggered={setRightClickTriggered}
                  contextMenuOptions={contextMenuOptions}
                  setContextMenuOptions={setContextMenuOptions}
                  onSuperScriptClick={onSuperScriptOptionClick}
                ></ContextMenu>
              </div>

              {/* {box2.data.map((d, index) => (
                <div key={index}>{d.label}</div>
              ))} */}
              {row && row[1] && row[1].data && row[1].data.length === 0 ? (
                <span className="selectClaimBoxText">
                  {t("supportinVisuals.captions")}
                </span>
              ) : (
                ""
              )}
              {row && row[1] && row[1].data && row[1].data.length !== 0 ? (
                <div className="boxBtnRowCap">
                  <button
                    onClick={(e) => {
                      changeCurrentVisualTextSelection(-1);
                      e.stopPropagation();
                    }}
                    disabled={prevBtnDisabledText}
                    className={
                      prevBtnDisabledText
                        ? "btn disabled pr-1"
                        : "btn btn-link pr-1"
                    }
                  >
                    <img
                      src={arrowLeft}
                      alt="Previous"
                      className="btnOpacity50"
                    />
                  </button>
                  <span className="slideCountCaptions">
                    {currentCaptionCount && (
                      <span key={captionHashKey}>{currentCaptionCount}</span>
                    )}
                    /{row && row[1] && row[1].data && row[1].data.length}
                  </span>
                  <button
                    onClick={(e) => {
                      changeCurrentVisualTextSelection(1);
                      e.stopPropagation();
                    }}
                    disabled={nextbtnDisabledText}
                    className={
                      nextbtnDisabledText
                        ? "btn disabled pl-1"
                        : "btn btn-link pl-1"
                    }
                  >
                    <img src={arrowRight} alt="Next" className="btnOpacity50" />
                  </button>

                  <button
                    onClick={(e) => {
                      deleteCaption(rowIndex, currentTextReplaceLocal);
                      changeCurrentVisualTextSelection(-1);
                      e.stopPropagation();
                    }}
                    disabled={
                      row && row[1] && row[1].data && row[1].data.length === 0
                    }
                    className="btn btn-link p-0 deleteCap"
                  >
                    {t("supportinVisuals.delete")}
                  </button>

                  <button
                    onClick={(e) => {
                      addCaptions(rowIndex);
                      changeCurrentVisualTextSelectionToLast();
                      e.stopPropagation();
                    }}
                    disabled={
                      //   row && row[1] && row[1].data && row[1].data.length === 0
                      // }
                      // {
                      (row &&
                        row[1] &&
                        row[1].data &&
                        row[1].data.length === 0) ||
                      isCapBtnDisabled
                    }
                    className="btn btn-link p-0 ml-3 mr-2"
                  >
                    <span className=" float fa fa-plus my-floatCaption"></span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="addvisualBox  suppViusalBoxType2 custonScrollBar"
              onClick={() => setSelectedBox(2)}
              id={`suppViusalFootnote${rowIndex}`}
            >
              {row && row[2] && row[2].data && row[2].data.length !== 0 ? (
                <span className="pl-2 editorActive-heading d-block mt-2 mb-3">
                  {t("supportinVisuals.descFootNotes")}
                </span>
              ) : (
                <span className="selectClaimBoxText">
                  {t("supportinVisuals.descFootNotes")}
                </span>
              )}
              {/* <span className="pl-2 editorActive-heading d-block mb-0">{t("supportinVisuals.descFootNotes")}</span> */}
              <div
                className="selectclaimBlockVisFoot custonScrollBar"
                onClick={() => {
                  setSelectedBox(2);
                  setSelectedRow(rowIndex);
                }}
              >
                {row[2].data.map((footnote, index) => {
                  return (
                    <div
                      className="d-flex"
                      onClick={(e) => {
                        setFootNoteIndex(index);
                        setSelectedRow(rowIndex);
                        setClaimId("footNoteVisual");
                      }}
                      onContextMenu={(e) => {
                        setFootNoteIndex(index);
                        setClaimId("footNoteVisual");
                      }}
                    >
                      <div className="footBoxIndex">
                        <span className="d-inline-block iconDisplay">
                          {footnote.footNoteIcon}
                        </span>
                      </div>
                      <FrolaTextEditor
                        className="footNoteLabelN"
                        forwardedRef={(el) =>
                          (footNoteFroalaInstance.current[index] = el)
                        }
                        tag="textarea"
                        handleModelChange={(event) =>
                          handleFootNoteTextChangeDirect(event, index)
                        }
                        selectedAtom={footnote.footNoteText}
                      />
                      {/* <div className="footNoteLabelN">
                        { parse(footnote.footNoteText)}
                    </div> */}
                    </div>
                  );
                })}
                {/* {<FrolaTextEditor forwardedRef={froalaTran} /> } */}
              </div>
              {/* <span className="selectClaimBoxText">
                {t("supportinVisuals.descFootNotes")}
              </span> */}

              <ContextMenu
                onContextMenuItemSelected={onContextMenuItemSelected}
                handleCont={handleContFootNote}
                handleAbbriviation={handleAbbriviation}
                footNoteId={"supporting-footnote"}
                handleFootNote={handleFootNote}
                abbriviationId={"supporting-abbreviationModal"}
                compClass={`suppViusalFootnote${rowIndex}`}
                handleReference={handleFootnoteReference}
                selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
                referenceId={"visual-referenceModal"}
                disableClass={`disable-FootNote`}
                setRightClickTriggered={setRightClickTriggered}
                contextMenuOptions={contextMenuOptions}
                setContextMenuOptions={setContextMenuOptions}
                onSuperScriptClick={onSuperScriptOptionClick}
              ></ContextMenu>
            </div>
          </div>

          <div
            className={
              moduleType === moduleTypes.denovo ? "col-md-2" : "col-md-3"
            }
          >
            <div
              className="addvisualBox suppViusalBoxType1"
              onClick={() => setSelectedBox(3)}
            >
              {row && row[3] && row[3].data && row[3].data.length !== 0 ? (
                <span className="pl-2 editorActive-heading d-block mt-2 mb-3">
                  {t("supportinVisuals.abbrevations")}
                </span>
              ) : (
                <span className="selectClaimBoxText">
                  {t("supportinVisuals.abbrevations")}
                </span>
              )}
              <div className="selectclaimBlockAbbr custonScrollBar">
                {row[3].data.map((x) => (
                  <span>
                    <p
                      className="text-left pl-2"
                      style={{
                        fontSize: "12px",
                        color: "#666666",
                        margin: "auto",
                        textAlign: "left",
                      }}
                    >
                      {x.title && parse(x.title)}
                    </p>
                    <div className="mb-1 text-left pl-2">
                      {x.abbrText && parse(x && x.abbrText)}
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div
            className={
              moduleType === moduleTypes?.denovo ? "col-md-2" : "col-md-3"
            }
          >
            <div
              className="addvisualBox suppViusalBoxType1 custonScrollBar mb-2"
              onClick={() => setSelectedBox(4)}
            >
              <div
                className=" float fa fa-plus my-float"
                onClick={(e) => {
                  setSelectedRow(rowIndex);
                  setSelectedBox(4);
                  handleClickAnchor();
                }}
              ></div>
              {/* {box5.data.map((d) => (
                <div>{d.label}</div>
              ))} */}
              <span className="refStar">*</span>
              {row && row[4] && row[4].data && row[4].data.length !== 0 ? (
                <span className="editorActive-heading d-block mt-2 mb-3 pl-2">
                  {t("supportinVisuals.refAnnotations")}
                </span>
              ) : (
                <span className="selectClaimBoxText">
                  {t("supportinVisuals.refAnnotations")}
                </span>
              )}
              <div className="selectclaimBlocVisualkRef custonScrollBar">
                {/* <span className="selectClaimBoxText">
                {t("supportinVisuals.refAnnotations")}
              </span> */}
                {row[4].data
                  .sort(function (a, b) {
                    return a.refId - b.refId;
                  })
                  .map((x, index) => {
                    if (!x) {
                      return <></>;
                    }
                    let color = "black";
                    // x.annotationList.length != 0 &&
                    // x.annotationList[0].annotText != "" &&
                    // x.veevaLinked === true
                    //   ? "green"
                    //   : "red";
                    return (
                      <div className="d-flex pl-3">
                        <div className="referenceBoxIndex">
                          {x.annotationList.length !== 0 ||
                          x.veevaLinked === true ? (
                            <span
                              className="d-inline-block refNumberDisplay"
                              style={{ color: color }}
                            >
                              {x.refId && x.refId}
                            </span>
                          ) : (
                            ""
                          )}
                          {/* <span className="d-inline-block refNumberDisplay">
                          {x.refId}
                        </span> */}
                        </div>
                        <div className="referenceBoxLabelN">
                          {x.veevaReferenceName &&
                            parse(x.veevaReferenceName && x.veevaReferenceName)}
                          <span
                            style={{
                              color: "#666666",
                              paddingTop: "15px",
                            }}
                          >
                            {x.annotationList &&
                              x.annotationList.map((list, index) => (
                                <div key={index}>
                                  Page no {list.pageNumber}/ Column{" "}
                                  {list.column}/ Para {list.para}/ Line{" "}
                                  {list.line}
                                </div>
                              ))}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* <div
              className="addvisualBox suppViusalBoxType2Minheight"
              onClick={() => {
                setSelectedBox(5);
                setSelectedRow(rowIndex);
                setCurrentTransTextReplace(currentTransTextReplace);
              }}
            > */}
            {/* {row && row[5] && row[5].data && row[5].data.length !== 0 ? (
                <span className="pl-2 editorActive-heading d-block mb-0">
                  {t("supportinVisuals.​​​Transcript")}
                </span>
              ) : (
                ""
              )}
              {row && row[5] && row[5].data && row[5].data.length === 0 ? (
                <span className="selectClaimBoxText">
                  {t("supportinVisuals.​​​Transcript")}
                </span>
              ) : (
                ""
              )} */}

            {/* {box6.data.map((d) => (
                <div>{d.label}</div>
              ))} */}
            {/* <div className="trascriptColumn custonScrollBar">
                <FrolaTextEditor
                  tag="textArea"
                  selectedAtom={
                    box6.data[currentTransTextReplace] &&
                    box6.data[currentTransTextReplace].Transcript
                  }
                  handleModelChange={(e) => {
                    handleTranscriptTextChange(e);
                  }}
                />
              </div> */}
            {/* </div> */}
          </div>
          {moduleType === moduleTypes?.denovo && (
            <div
              id="suppvisual"
              className="selectclaimBlock usageInformation suppDiv col-md-2"
            >
              {/* {row && row[0]?.data?.length !== 0 && row[0]?.data !== "" ? ( */}
              <span class="editorActive-heading d-block pt-2 mt-2 mb-3">
                {t("reusableText.usageInstructions")}
              </span>
              <TextField
                className="usageInfoinput supportingvisul"
                id="moduleName"
                variant="outlined"
                fullWidth={true}
                placeholder="Type here..."
                //inputProps={{ style: { textAlign: "left", paddingTop: "2px" } }}
                InputProps={{
                  className: "usageInfo",
                }}
                multiline
                rows={2}
                onChange={handleChange}
                value={supportingClaimsAndVisuals[rowIndex]?.usageGuidelines}
              />
              {/* ) : (
            ""
          )} */}
              <div className="supportingClaimEditor"></div>
            </div>
          )}
        </div>
        {isAbbr === true ? (
          <Abbreviation
            handleCloseAbbreviation={handleCloseAbbreviation}
            id="visual-abbriviation"
            selectedText={footNoteText}
            handleArrAbbr={handleArrAbbr}
            arrRadioData={props.masterAbbrs}
            isAbbrDisable={isAbbrDisable}
            setIsAbbrDisable={setIsAbbrDisable}
            setSelectedBox={setSelectedBox}
            setSelectedRow={setSelectedRow}
            rowIndex={rowIndex}
            selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
            abbrStatus={abbrStatus1}
            abbrNoteText={abbrNoteText}
            handleAbbrDataUpdate={handleAbbrDataUpdate}
          />
        ) : (
          ""
        )}
        {showFootNote ? (
          <FooteNotesModal
            id="visual-footNote"
            handleFootNoteMOdalClose={handleFootNoteMOdalClose}
            handleFootNoteTextChange={handleFootNoteInputChange}
            selectedText={footNoteText}
            addBtnStatus={addBtnStatus}
            handleAddFootNote={handleAddFootNote}
            selectedFootNotes={selectedFootNotes}
            getIconValue={getIconValue}
            setSelectedBox={setSelectedBox}
            setSelectedRow={setSelectedRow}
            rowIndex={rowIndex}
            addIconStatus={addIconStatus}
            handleFootNoteCheckBox={handleFootNoteCheckBox}
            handleAddFootNoteCheckBox={handleAddFootNoteCheckBox}
            setIconValueToInitial={setIconValueToInitial}
            setAllToInitial={setAllToInitial}
          />
        ) : (
          " "
        )}
        {/* {showModal ? (
          <ReferenceContainer
            handleClose={handleCloseRefModal}
            referenceMasterList={referenceMasterList}
            updateInMasterList={updateInMasterList}
            refAddStatus={refAddStatus}
            refText={refText}
            setSelectedBox={setSelectedBox}
            setSelectedRow={setSelectedRow}
            rowIndex={rowIndex}
            id="supporting-visual"
          />
        ) : (
          ""
        )} */}
        {showModal ? (
          <ReferenceContainer
            handleClose={handleCloseRefModal}
            referenceList={props.referenceList} //{row[4].data}
            setSelectedBox={setSelectedBox}
            setSelectedRow={setSelectedRow}
            rowIndex={rowIndex}
            itemPosition={supportingVisualPosition}
            id="supporting-visual"
            saveAnchorSupportingVisual={saveAnchorSupportingVisual}
            referenceDocuments={props.referenceDocuments || []}
            skipAnchorSupportingVisual={skipAnchorSupportingVisual}
            anchorAddedIn={anchorAddedIn}
            addOrModifyanchorData={addOrModifyanchorData}
            sourceType={sourceType}
            findSupportingClaimOrVisualPosition={
              findSupportingClaimOrVisualPosition
            }
            moduleType={moduleType}
          />
        ) : (
          ""
        )}
        {showModalAnchor ? (
          <ReferenceAnchor
            brandName={props.brandName}
            getModuledetails={getModuledetails}
            handleClickAnchor={handleClickAnchor}
            handleCloseReferenceAnchor={handleCloseReferenceAnchor}
            setFilterType={setFilterType}
            reference={refText}
            filterType="visual-referenceModal"
            refAnchorId="supportingVisual-ReferenceAnchor"
            updateSupportingVisualReference={updateSupportingVisualReference}
            sourceType={sourceType}
            moduleType={moduleType}
          />
        ) : (
          ""
        )}
        <div className="d-none" ref={referencesValid}></div>
        {showUploadUrlModal && moduleType === moduleTypes.denovo ? (
          <CustomModal
            displayModal={showUploadUrlModal}
            hideModal={hideOnlineurlModal}
            title={""}
            size="lg"
            dilogClassName="uploadImageModal"
            keyboard={false}
            enforceFocus={false}
          >
            <UploadOnlineImageModal
              handleShowOnlineurlModal={handleShowOnlineurlModal}
              hideOnlineurlModal={hideOnlineurlModal}
              setUploadedFileName={setUploadedFileName}
              uploadedFileName={uploadedFileName}
              currentImageReplaceLocal={currentImageReplaceLocal}
              addImage={addImage}
              rowIndex={rowIndex}
              setSelectedDiv={setSelectedDiv}
            />
          </CustomModal>
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default Row;
