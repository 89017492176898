import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
  damsSources,
} from "../../Uitls/Util";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ModuleCard from "../ExistingTactics/ModuleCard";
import CreateNewModuleCard from "../ExistingTactics/CreateNewModuleCard";
import LockUnlockAlertModal from "../ExistingTactics/LockUnlockAlertModal";
import CustomModal from "../customModal";
import { lockAssetOrModule, getModuleDetailsById } from "../../services/apis";
import "../../styles/components/ExistingTatics.scss";
import "../../styles/CustomToastify.scss";
import "../../styles/components/DenovoModules.scss";

const DenovoModulesList = (props) => {
  let history = useHistory();
  const {
    dModules,
    isCreateNewClicked,
    createNewClicked,
    selectedModule,
    setSelectedModule,
    searchTriggerd,
    mainModules,
    auxTitle,
    auxModules,
    typeofModule,
    stylizeModule,
    moduleTypes,
  } = props;

  const [isloading, setIsloading] = useState(false);
  const [modules, setModules] = useState([]);
  const [lockUnlockAlertModal, setLockUnlockAlertModal] = useState(false);
  const [approvalData, setApprovalData] = useState(undefined);
  useEffect(() => {
    if (mainModules) {
      setModules(mainModules);
    } else if (dModules) {
      setModules(dModules);
    } else {
      setModules([]);
    }
  }, [dModules, mainModules]);

  const viewModuleDetail = (moduleId, isModuleLocked) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        isLocked: isModuleLocked,
        sourceType: damsSources.veeva.name,
      },
    });
  };

  const unLockModule = (moduleId, lockStatus) => {
    const data = {
      locked: lockStatus === "Locked" ? "Unlocked" : "Locked",
      type: "Module",
      typeId: moduleId,
    };
    setApprovalData(data);
    setLockUnlockAlertModal(true);
  };

  const unlockAssetOrmoduleErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const unlockModuleAndAsset = () => {
    setLockUnlockAlertModal(false);
    lockAssetOrModule(approvalData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        unlockAssetOrmoduleErr(errCoponent);
        setApprovalData(undefined);
      } else {
        toast.success(
          <div style={{ color: "#000" }}>
            {approvalData.locked === "Unlocked"
              ? `Request sent to Un-lock the ${
                  approvalData.type === "Asset" ? "Tactic" : "Module"
                }`
              : `{approvalData.type === "Asset" ? "Tactic" : "Module"}{" "}
            {approvalData.locked}`}
          </div>,
          {
            autoClose: 2000,
            position: "top-center",
          }
        );
        setApprovalData(undefined);
        console.log(response.data);
      }
    });
  };

  const modifyModule = (id) => {
    setIsloading(true);
    getModuleDetailsById(id, (response, err, errCode) => {
      if (err) {
        setIsloading(false);
        const errCoponent = ErrComponentToRender(errCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        setIsloading(false);
        let pageCount = "";
        removeModuleInfo();

        updateModuleInfo({
          assetId: response.data.assetId,
          moduleId: response.data.id,
          moduleName: response.data.name,
          moduleCode: response.data.moduleCode,
          moduleType: "editModule",
          numberOfPages: pageCount,
          creationMethodType: response.data.creationMethodType
            ? response.data.creationMethodType.toLowerCase()
            : undefined,
        });
        history.push({
          pathname: routes.addPrimaryClaim,
          state: {
            brandName: response.data.brandName,
          },
        });
      }
    });
  };

  const scrollToSelectedCard = () => {
    if (selectedModule) {
      setTimeout(() => {
        var element = document.getElementById(selectedModule.moduleCode);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
      }, 1);
    } else {
      var element = document.getElementById("create-new-module-card");
      if (element) {
        element.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    }
  };
  const translateModule = (moduleId) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        flowType: "Translate",
      },
    });
  };

  const localizeModule = (moduleId) => {
    history.push({
      pathname: routes.moduleDetails,
      state: {
        moduleId: moduleId,
        flowType: "Localize",
      },
    });
  };

  const getModuleCard = (module) => {
    return (
      <ModuleCard
        module={module}
        colWidth={selectedModule ? 12 : 4}
        assetLockStatus={module.locked}
        selectModule={setSelectedModule}
        isSelected={
          selectedModule && selectedModule.id === module.id ? true : false
        }
        unLockModule={unLockModule}
        viewModuleDetail={viewModuleDetail}
        modifyModule={modifyModule}
        translateModule={translateModule}
        localizeModule={localizeModule}
        typeofModule={typeofModule}
        stylizeModule={stylizeModule}
      />
    );
  };

  const drawAuxModulesHeading = () => {
    return (
      <h2
        className="masterTacticTitle pl-3 assetDetailColTitle "
        style={{ marginTop: "20px" }}
      >
        <span className="moduleNormalShow" style={{ fontWeight: "600" }}>
          {auxTitle} ({auxModules.length})
        </span>
      </h2>
    );
  };

  const createNewModule = () => {
    setSelectedModule(undefined);
    createNewClicked(true);
  };

  const renderModuleCards = () => {
    return (
      <>
        <>
          {!searchTriggerd && !selectedModule && (
            <div
              className={`${
                selectedModule || isCreateNewClicked ? "col-md-12" : "col-md-4"
              } denovo-module-card`}
            >
              <CreateNewModuleCard
                content={<span>Create New Module</span>}
                colWidth={12}
                onClickCreateCard={createNewModule}
                moduleTypes={moduleTypes}
              />
            </div>
          )}
        </>
        {modules &&
          modules.map((module, index) => {
            scrollToSelectedCard();
            return getModuleCard(module);
          })}

        {/* IF Aux Modules present */}
        {selectedModule &&
          auxModules &&
          auxModules.length > 0 &&
          auxTitle &&
          drawAuxModulesHeading()}
        {auxModules &&
          auxModules.map((module, index) => {
            scrollToSelectedCard();
            return getModuleCard(module);
          })}
      </>
    );
  };
  const handleCloseAlertModal = () => {
    setLockUnlockAlertModal(false);
    setApprovalData(undefined);
  };
  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div>
        {
          <div className={`row pl-3 existingTacticsModDetails custonScrollBar`}>
            {renderModuleCards()}
          </div>
        }
      </div>
      <CustomModal
        displayModal={lockUnlockAlertModal}
        hideModal={handleCloseAlertModal}
        size="md"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <LockUnlockAlertModal
          approvalData={approvalData}
          handleYes={unlockModuleAndAsset}
          handleNo={handleCloseAlertModal}
        />
      </CustomModal>
    </>
  );
};

export default DenovoModulesList;
