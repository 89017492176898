import React from "react";
import { Col } from "react-bootstrap";
import deleteIcon from "../../images/closeIcon.svg";
import InfoIconError from "../../images/Info-selection-error.svg";
const BusineesRulesCards = (props) => {
  const {
    boxKey,
    boxTitle,
    data,
    selectedBoxIndex,
    setSelectedBox,
    deleteBusinessRule,
    contentExists,
    customCName,
    allowedToAddRule = true,
    setContentExists,
    setCannotExistWith,
    cannotExistWith,
  } = props;
  return (
    <>
      <Col
        xs="12"
        md="4"
        lg="4"
        className={`${customCName} mb-3 ${
          !allowedToAddRule ? "frozen-brule-box" : ""
        }`}
        onClick={() => {
          if (!allowedToAddRule) {
            return;
          }
          setSelectedBox(boxKey);
          setContentExists("");
          setCannotExistWith(undefined);
        }}
      >
        <div
          className={
            "businessRulesBox newCustomClass " +
            (selectedBoxIndex === boxKey ? "businessRulesBoxSelected " : "") +
            (contentExists && contentExists === boxKey
              ? "businessRulesBoxErr"
              : "") +
            (!allowedToAddRule ? "frozen-brule-box" : "")
          }
        >
          <div className="businessRulesBoxTitle d-flex justify-content-between">
            {boxTitle}
          </div>

          {data.length ? (
            <p className="moduleListCount">
              {data.length} Modules / 0 Category
            </p>
          ) : (
            <div className="boxLableVerticalMiddle moduleCountPlaceHolder">
              0 Modules / Category
            </div>
          )}

          <ul
            className={`supClaimList listScrollContainer moduleListContainer custonScrollBar`}
          >
            {data.map((item, index) => {
              return (
                <li key={index} className="rulesBoxModuleList">
                  <div className="groupAtomsList">
                    <p className="listModuleName">{item.name}</p>
                    <p className="listModuleId">{item.moduleCode}</p>
                  </div>
                  <button
                    className="btn btn-link p-0 m-0 deleteGroupItem"
                    disabled={!allowedToAddRule}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteBusinessRule(item);
                      setCannotExistWith(undefined);
                      setSelectedBox("");
                      setContentExists("");
                    }}
                  >
                    <img src={deleteIcon} alt="" width="32" />
                  </button>
                </li>
              );
            })}
          </ul>
          {contentExists === boxKey && cannotExistWith && (
            <div className="bussinessrule-error-message-note d-flex">
              <div>
                <img
                  src={InfoIconError}
                  alt=""
                  style={{ marginBottom: "3px", marginRight: "5px" }}
                />
              </div>
              <div>{cannotExistWith}</div>
            </div>
          )}
        </div>
      </Col>
    </>
  );
};
export default BusineesRulesCards;
