import React, { useState, useEffect, useRef } from "react";
//import FroalaEditor from "react-froala-wysiwyg";
import parse from "html-react-parser";
import i18next from "i18next";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import { froalaEditor } from "../Uitls/FroalaConfig";
import "../styles/components/PrimaryClaim.scss";
import { useOuterClick } from "../Uitls/CustomHooks";
import FloatingButton from "../Uitls/FloatingButton";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
import editPenIcon from "../images/editPenIcon.svg";
import deleteIcon from "../images/deleteIcon.svg";
import ContextMenu from "./contextMenu";
import {
  getRefSuperscriptStyle,
  citationColors,
  rgbToHex,
  sortSuperScriptNumbers,
  addFootnoteSuperScript,
} from "../Uitls/Util";
//Compare two arrays of objects
export const areRefArraysEqual = (arr1, arr2) => {
  let isEqual = true;
  if (arr1.length !== arr2.length) {
    isEqual = false;
  } else {
    for (var val1 in arr1) {
      const found = arr2.find(
        (val2) => val2.refId === val1.refId && val2.color === val1.color
      );
      if (!found) {
        isEqual = false;
        break;
      }
    }
  }

  return isEqual;
};

const PrimaryClaim = (props) => {
  const [froalaPlaceHolder, setFrroalaPlaceHolder] = useState(
    i18next.t("primaryClaim.name")
  );
  //const froalaDefaultConfig = froalaEditor.config({ placeHolder: '', shouldHaveToolbar: true})
  const froalaDefaultConfig = froalaEditor.config({
    placeHolder: "",
    shouldHaveToolbar: true,
    shouldUpdateImmediatly: true,
  });
  const [showLargerEditor, setShowLargerEditor] = useState(false);
  const [primaryClaim, setPrimaryClaim] = useState([]);
  //const [editors, setEditors] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(-1);
  let froalaInstance = React.createRef();
  const [shouldEnableEditing, setShouldEnableEditing] = useState(true);
  const [selectText, getSelectText] = useState("");
  const [selectedTextIdxRange, setSelectedTextIdxRange] = useState([0, 0]);
  const footNoteIcon = props.footNoteIcon.footNoteIconValue;
  const refNumber = props.referenceNumber;
  const iconArr = props.iconArray;
  let prevProps = usePrevious({ footNoteIcon, selectText, refNumber, iconArr });
  const [rightClickTriggered, setRightClickTriggered] = useState(false);
  const [superScriptData, setSuperScriptData] = useState([]);
  const [eventType, setEventType] = useState(undefined);

  const onEditorClick = (event) => {
    //event.stopPropagation()
    //props.onBoxSelected(event)
  };
  const [addVarientClicked, setAddVarientClicked] = useState(false);
  const [defaultTyped, setDefaultTyped] = useState(undefined);
  const [contextMenuOptions, setContextMenuOptions] = useState(undefined);
  const onContextMenuItemSelected = (event) => {
    event.stopPropagation();
    props.onBoxSelected(event, {
      type: "primary-claim-varient",
      index: selectedIndex,
    });
    //Tell parent about the text selected to map references with that text
    if (props.onTextSelected) {
      props.onTextSelected(selectText, selectedTextIdxRange);
    }
    setRightClickTriggered(false);
  };
  useEffect(() => {
    if (props.primaryClaim) {
      setPrimaryClaim(props.primaryClaim);
    }
    //setSelectedIndex(0);
  }, []);
  useEffect(() => {
    // if (props.selectedIndex === 0) {
    //   setSelectedIndex(0);
    // }
    setSelectedIndex(props.selectedIndex);
  }, [props.selectedIndex]);

  //CODE- Custom hook to compare two Props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const onSupClick = (event) => {
    const el = event.target;
    setEventType(el);
    if (el && el.dataset) {
      const docId = el.dataset.docid;
      if (docId && docId.trim().length > 0) {
        props.setAnchorAddedIn({
          type: "primary-claim-varient",
          index: selectedIndex,
        });
        setContextMenuOptions({
          event: "modify",
          docId: docId,
          options: ["View", "Modify", "Delete"],
          head: "Anchor",
          type: "primary-claim",
        });
      }
    }
  };

  useEffect(() => {
    if (primaryClaim.length === 0 && props.primaryClaim.length === 0) {
      //
    } else {
      setPrimaryClaim(props.primaryClaim || []);
    }

    // if (primaryClaim.length !== props.primaryClaim.length) {
    //   setTimeout(() => {
    //     setSelectedIndex(0);
    //   }, 0);
    // }
    if (props.shouldEnableEditing !== shouldEnableEditing) {
      setShouldEnableEditing(props.shouldEnableEditing);
    }
    /* CODE FOR foot nOTES*/
    //<sup>superscript</sup>
    if (
      shouldEnableEditing &&
      (footNoteIcon || (iconArr && iconArr.length !== 0)) &&
      selectText &&
      froalaInstance.current.editor
    ) {
      let text = prevProps.selectText.localeCompare(selectText);
      if (
        prevProps.footNoteIcon !== footNoteIcon ||
        prevProps.iconArr !== iconArr
      ) {
        //process here
        let currentString = froalaInstance.current.editor.html.get();

        const froalaString = addFootnoteSuperScript(
          currentString,
          iconArr,
          footNoteIcon,
          selectText
        );
        froalaInstance.current.editor.html.set(froalaString);
        froalaInstance.current.editor.selection.save();
        getSelectText("");
        const html = froalaInstance.current.editor.html.get(true);
        froalaInstance.current.editor.selection.clear();
        //console.log(froalaInstance.current, " editor.cursor.isAtEnd();");
        //Update primary claim and parent
        let existingPrimaryClaim = primaryClaim;
        existingPrimaryClaim[selectedIndex].claim = froalaString;
        //setPrimaryClaim(existingPrimaryClaim);
        updateParent(existingPrimaryClaim, selectedIndex);
      }
    } else if (
      shouldEnableEditing &&
      refNumber &&
      refNumber.length !== 0 &&
      (selectText || eventType) &&
      froalaInstance.current.editor
    ) {
      //if (prevProps.refNumber !== refNumber) { --------Previuos code
      if (
        eventType &&
        shouldEnableEditing &&
        refNumber &&
        refNumber.length !== 0 &&
        froalaInstance.current.editor
      ) {
        if (!areRefArraysEqual(prevProps.refNumber, refNumber)) {
          refNumber.forEach((el) => {
            const style = getRefSuperscriptStyle(el.color);
            const greenStyle = getRefSuperscriptStyle("r");

            //Remove old superscripts with the same value
            let oldText = froalaInstance.current.editor.html.get();
            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${greenStyle}" >${el.refId}</sup>`,
              `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
            );

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${greenStyle}">${el.refId}</sup>`,
              `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
            );

            froalaInstance.current.editor.html.set(oldText);
            froalaInstance.current.editor.selection.save();
            const html = froalaInstance.current.editor.html.get(true);
            froalaInstance.current.editor.selection.clear();
            let existingPrimaryClaim = primaryClaim;
            existingPrimaryClaim[selectedIndex].claim = oldText;
            //setPrimaryClaim(existingPrimaryClaim);
            updateParent(existingPrimaryClaim, selectedIndex);
          });
          setEventType(undefined);
        }
      } else {
        if (!areRefArraysEqual(prevProps.refNumber, refNumber)) {
          let replaceNum = refNumber.map((el) => {
            const style = getRefSuperscriptStyle(el.color);

            const redStyle = getRefSuperscriptStyle("r");
            //Remove old superscripts with the same value
            let oldText = froalaInstance.current.editor.html.get();

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
              ""
            );

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
              ""
            );
            froalaInstance.current.editor.html.set(oldText);
            return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
          });

          //process here
          let currentString = froalaInstance.current.editor.html.get();
          var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
          var suptext = [];
          words.forEach((item, index, array) => {
            let elIndex = words.indexOf(item);
            let regex = /(<([^>]+)>)/gi;
            let regex2 = /(\[.+\])/gi;
            let result = item.replace(regex, "").trim();
            let tempResult = result.replace(regex2, "").trim();
            const selectedTextForCompare = selectText.trim();

            if (tempResult === selectedTextForCompare) {
              if (elIndex < words.length - 1) {
                let suptext1 = Object.values(
                  array[index + 1].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index + 1].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
                let suptext2 = Object.values(
                  array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
                suptext = suptext.concat(suptext1, suptext2);
              } else if (elIndex === words.length - 1) {
                suptext = Object.values(
                  array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                    ? array[index].match(/<sup(.*?)<\/sup>/g)
                    : ""
                );
              } else {
                return;
              }
            }
          });
          if (suptext) {
            replaceNum = replaceNum.filter((val) => !suptext.includes(val));
          }
          const textToBeReplaced = selectText.concat(
            suptext.toString().replaceAll(",", "")
          );
          suptext = suptext.concat(replaceNum);
          // const textToReplaceWith = selectText.concat(
          //   suptext.toString().replaceAll(",", "")
          // );
          const textToReplaceWith = "".concat(
            suptext.toString().replaceAll(",", "")
          );
          let currentClaimString = froalaInstance.current.editor.html.get();
          const froalaString = sortSuperScriptNumbers(
            currentClaimString,
            textToBeReplaced,
            textToReplaceWith,
            selectText
          );

          // let froalaString = froalaInstance.current.editor.html
          //   .get()
          //   .replace(textToBeReplaced, `${textToReplaceWith}`);
          //froalaInstance.current.editor.html.set(froalaString);
          froalaInstance.current.editor.html.set(froalaString);
          froalaInstance.current.editor.selection.save();
          getSelectText("");
          const html = froalaInstance.current.editor.html.get(true);
          froalaInstance.current.editor.selection.clear();
          //console.log(froalaInstance.current," editor.cursor.isAtEnd();")

          //Update primary claim and parent
          let existingPrimaryClaim = primaryClaim;
          existingPrimaryClaim[selectedIndex].claim = froalaString;
          //setPrimaryClaim(existingPrimaryClaim);
          updateParent(existingPrimaryClaim, selectedIndex);
        }
      }
    }
  }, [props]);

  useEffect(() => {
    if (primaryClaim.length > 0 && primaryClaim[0].claim !== "") {
      setFrroalaPlaceHolder("");
    } else {
      setFrroalaPlaceHolder(i18next.t("primaryClaim.name"));
    }
  }, [primaryClaim]);

  const handleCont = (event) => {
    if (event && event.target.nodeName === "SUP") {
      onSupClick(event);
      return;
    }
    let SelectedText = "";
    if (
      froalaInstance &&
      froalaInstance.current &&
      froalaInstance.current.getEditor() &&
      froalaInstance.current.getEditor().toolbar
    ) {
      froalaInstance.current.getEditor().toolbar.hide();
      setTimeout(function () {
        SelectedText = froalaInstance.current.getEditor().selection.text();
        froalaInstance.current.getEditor().toolbar.hide();
        getSelectText(SelectedText);
        setRightClickTriggered(true);
        var selection = window.getSelection();
        var startIndex = selection.anchorOffset;
        var endIndex = selection.focusOffset;
        setSelectedTextIdxRange([startIndex, endIndex]);
      }, 0);
    }
  };

  document.addEventListener("mouseup", (event) => {
    event.preventDefault();
    if (rightClickTriggered) {
      setTimeout(function () {
        hideEditorToolBar();
      }, 2);
    }
  });

  const hideEditorToolBar = () => {
    if (
      froalaInstance &&
      froalaInstance.current &&
      froalaInstance.current.getEditor() &&
      froalaInstance.current.getEditor().toolbar
    ) {
      froalaInstance.current.getEditor().toolbar.hide();
    }
  };

  const newEditor = (defaultText) => {
    return (
      <FroalaEditor
        forwardedRef={froalaInstance}
        selectedAtom={shouldEnableEditing ? defaultText : defaultTyped}
        tag="textarea"
        handleModelChange={handleModelChange}
        //shouldUpdateImmediatly={true}
      />
    );
  };
  const nextEditor = () => {
    if (selectedIndex < primaryClaim.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };
  const prevEditor = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
    //check if last editor does not have any text enetered
    if (
      primaryClaim.length > 1 &&
      selectedIndex === primaryClaim.length - 1 &&
      primaryClaim[selectedIndex].claim === ""
    ) {
      setPrimaryClaim(
        primaryClaim.filter((claim, index) => {
          return index !== primaryClaim.length - 1;
        })
      );
    }
  };
  const innerRef = useOuterClick((e) => {
    // counter state is up-to-date, when handler is called
    setShowLargerEditor(false);
  });
  const onAddVarientClick = () => {
    //setEditors([...editors, newEditor('')])
    if (primaryClaim[primaryClaim.length - 1].claim !== "") {
      setAddVarientClicked(true);
      let existing = JSON.parse(JSON.stringify(primaryClaim));
      existing.push({ claim: "", persona: null, tone: null });
      setPrimaryClaim(existing);
      //primaryClaim.push('')
      setSelectedIndex(existing.length - 1);
      updateParent(existing, existing.length - 1);
    }
  };
  const updateParent = (data, index) => {
    props.updatePrimaryClaim(data, index, superScriptData);
  };

  const handleDelete = (model) => {
    let modelText = model;
    let afterDeleted = Object.values(
      modelText.match(/<sup>(.*?)<\/sup>/g)
        ? modelText.match(/<sup>(.*?)<\/sup>/g)
        : ""
    );

    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      setSuperScriptData(unique);
    }
  };
  const handleModelChange = (model) => {
    if (!shouldEnableEditing) {
      if (model.length > 0) {
        setFrroalaPlaceHolder("");
        setDefaultTyped(model);
      } else {
        setFrroalaPlaceHolder(i18next.t("primaryClaim.name"));
        setDefaultTyped(undefined);
      }

      return;
    }
    setDefaultTyped(undefined);
    let text = model; //document.getElementsByTagName('p').item(itemIndex).innerHTML
    if (
      text === "<br>" ||
      text === "<undefined><br></undefined>" ||
      text === ""
    ) {
      text = "";
    }
    if (text || text !== "" || addVarientClicked) {
      let tempArray = [...primaryClaim];
      if (tempArray[selectedIndex]) {
        tempArray[selectedIndex].claim = text;
      } else {
        const obj = { claim: text, persona: null, tone: null };
        tempArray[selectedIndex] = obj;
      }

      //setPrimaryClaim(tempArray);
      //update the parent
      updateParent(tempArray, selectedIndex);
      setAddVarientClicked(false);
    } else {
      let tempArray = [];
      if (selectedIndex === 0) {
        tempArray = [];
      } else {
        tempArray = primaryClaim.filter((claim, index) => {
          return index !== selectedIndex;
        });
      }
      setPrimaryClaim(tempArray);
      let index = selectedIndex;
      if (selectedIndex > 0) {
        index = index - 1;
      }
      setSelectedIndex(index);
      updateParent(tempArray, index);
    }

    let newModel = primaryClaim.join();
    handleDelete(newModel);
  };

  const handlePersonaToneDelete = (type) => {
    // if (type === "persona") {
    //   primaryClaim[selectedIndex].persona = null;
    // } else {
    //   primaryClaim[selectedIndex].tone = null;
    // }
    if (type === "persona") {
      primaryClaim.forEach((claim) => {
        claim.persona = null;
      });
    } else {
      primaryClaim.forEach((claim) => {
        claim.tone = null;
      });
    }
    setPrimaryClaim([...primaryClaim]);

    props.handlePageSave();
  };
  const renderPersonaAndTone = () => {
    return (
      <div className="persona-and-tone">
        {primaryClaim[selectedIndex]?.persona && (
          <div>
            Persona :{" "}
            <div className="d-flex personaTone">
              <div className="persona-text">
                {parse(primaryClaim[selectedIndex]?.persona || "")}
              </div>

              <div className="edit-delete-icons">
                <img
                  src={editPenIcon}
                  alt="Edit"
                  className=""
                  onClick={props.showPersonaToneModal}
                />
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className="deleteIcon-class"
                  onClick={() => {
                    handlePersonaToneDelete("persona");
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {primaryClaim[selectedIndex]?.tone && (
          <div>
            Tone :{" "}
            <div className="d-flex personaTone">
              <div className="persona-text">
                {parse(primaryClaim[selectedIndex]?.tone || "")}
              </div>
              <div className="edit-delete-icons">
                <img
                  src={editPenIcon}
                  alt="Edit"
                  className=""
                  onClick={props.showPersonaToneModal}
                />
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className="deleteIcon-class"
                  onClick={() => {
                    handlePersonaToneDelete("tone");
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="primary-claim" id="primary-claim">
      {/* { (primaryClaim.length > 0 && primaryClaim[0] !== "") && 
            <p className="editorActive-heading d-block mt-2 mb-0">Primary Claim</p>} */}
      {primaryClaim.length > 1 && (
        <div className="button-row">
          <button onClick={prevEditor} className="leftBtn">
            <img src={arrowLeft} alt="Previous" className=" opacity50" />
          </button>
          <span className="slideCount">
            {selectedIndex + 1}/{primaryClaim.length}
          </span>
          <button onClick={nextEditor} className="rightBtn">
            <img src={arrowRight} alt="Next" className="opacity50" />
          </button>
        </div>
      )}
      {primaryClaim.length > 0 && primaryClaim[0]?.claim !== "" && (
        <FloatingButton onClick={onAddVarientClick} />
      )}

      <div className="promotionWrapper customScrollBar">
        {newEditor(primaryClaim[selectedIndex]?.claim)}
        {renderPersonaAndTone()}
      </div>

      {primaryClaim.length > 0 && primaryClaim[0]?.claim !== "" ? (
        ""
      ) : (
        <span className="selectClaimBoxText">{froalaPlaceHolder}</span>
      )}

      <ContextMenu
        onContextMenuItemSelected={onContextMenuItemSelected}
        handleCont={handleCont}
        compClass={"primary-claim"}
        handleFootNote={onContextMenuItemSelected}
        handleAbbriviation={onContextMenuItemSelected}
        handleReference={onContextMenuItemSelected}
        footNoteId={"footnote-modal"}
        abbriviationId={"abbreviationModal"}
        referenceId={"referenceModal"}
        selectedTextPrimaryClaim={selectText}
        setRightClickTriggered={setRightClickTriggered}
        contextMenuOptions={contextMenuOptions}
        setContextMenuOptions={setContextMenuOptions}
        onSuperScriptClick={props.onSuperScriptClick}
      ></ContextMenu>
    </div>
  ); //
};
const EditorModal = ({ handleClose, show, children }) => {
  // const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    show && <section className="modal-main big-editor">{children}</section>
  );
};
export default PrimaryClaim;
