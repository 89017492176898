import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    justifyContent: "left",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    backgroundColor: "transparent",
    boxShadow: "none",
    flexWrap : "wrap"
  },
  chip: {
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(1.25),
    borderRadius: 5,
    whiteSpace: "normal",
    boxShadow: "none",
    maxWidth: "20rem",
    fontSize: 11,
    color: "#333030",
    backgroundColor: "#bdcaf6",
    marginLeft:"-3px",
    textTransform: "capitalize",
  },
}));

export default function CustomChip(props) {
  const classes = useStyles();
  const handleDelete = (chipToDelete) => () => {
    if (props.handleDelete) {
      props.handleDelete(chipToDelete);
    }
  };

  return (
    <Paper component="ul" className={classes.root}>
      {props.data.map((item) => {
        return (
          <li key={item.id ? item.id : item.name} id={item.id ? item.id : item.name}>
            <Chip
              label={item.name}
              size="small"
              onDelete={handleDelete(item)}
              className={classes.chip}
              deleteIcon={<CloseOutlinedIcon />}
            />
          </li>
        );
      })}
    </Paper>
  );
}
