import React, { useEffect, useRef } from "react";
import { Block } from "@material-ui/icons";
import parse from "html-react-parser";
import { isInteger } from "formik";
import tickImage from "../images/Tick.svg";
import draftStatusIcon from "../images/mlr_status_draft.svg";
import lockIcon from "../images/lockIconGrey.svg";
import errComponentToRender from "./apiErrorHandling";
import { toast } from "react-toastify";
const features = require("./Features.json");

export const getDyanamoDBInstance = (dynamo) => {
  return new dynamo.AWS.DynamoDB({ apiVersion: "2012-08-10" });
};
export const isoCountryTableName = "imc-iso-countrycode";
export const imcLanguageTableName = "imc-language";
export const awsRegion = "ap-south-1";

export const routes = {
  assetDetails: "/create-module",

  addPrimaryClaim: "/create-module-primary-claim",

  addGroups: "/create-module-grouping",

  addBusinessRules: "/business-rules",

  //addChannels: "/add-channels",

  additionalInfo: "/additional-info",

  signup: "/signup",

  forgotPassword: "/forgot-password",

  uploadAsset: "upload-master-asset",

  dashboard: "/dashboard",

  mlrSubmission: "/mlr-submission",

  moduleDetails: "/module-details",
  search: "/search",
  translateClaims: "/translate-claims",
  translateChannel: "/translate-channel",
  translateAdditionalInfo: "/translate-additional-info",
  translatorDashboard: "/dashboard-translate",
  reviewerDashboard: "/dashboard-reviewer",
  mlrFeedbackPreview: "/mlr-preview",
  translateBusinessRules: "/translate-business-rules",
  templatePreview: "/template-preview",
  moduleEditor: "/module-editor",
  styliseModuleView: "/stylise-module-view",
};

export const isDamsEnabled = () => {
  return features.dams.isEnabled;
};
export const isHarvesterEnabled = () => {
  return features.harvester.isEnabled;
};
export const isDenovoEnabled = () => {
  return features.denovo.isEnabled;
};
export const isMarketingMessagesEnabled = () => {
  return features.marketingMessages.isEnabled;
};
export const isThisDamsEnabled = (damsSource) => {
  return features.dams.sources[damsSource].isEnabled;
};
export const isThisAssetDataTypeEnabled = (damsSource, assetDataType) => {
  return features.dams.sources[damsSource].options[assetDataType].isEnabled;
};

// export const damsSources = {
//   veeva: { name: "Veeva", options: ["Asset + Component", "Modular Content"] },
//   aprimo: { name: "Aprimo", options: ["Asset + Component"] },
//   aem: { name: "AEM", options: [] },
// };

export const damsSources = {
  next: {
    name: isThisDamsEnabled("next") ? "NEXT" : "",
    options: [
      isThisAssetDataTypeEnabled("next", "assetComponent")
        ? "Asset + Component"
        : "",
    ],
  },
  veeva: {
    name: isThisDamsEnabled("veeva") ? "Veeva" : "",
    options: [
      isThisAssetDataTypeEnabled("veeva", "assetComponent")
        ? "Asset + Component"
        : "",
      isThisAssetDataTypeEnabled("veeva", "modularContent")
        ? "Modular Content"
        : "",
    ],
  },
  aprimo: {
    name: isThisDamsEnabled("aprimo") ? "Aprimo" : "",
    options: [
      isThisAssetDataTypeEnabled("veeva", "assetComponent")
        ? "Asset + Component"
        : "",
    ],
  },
  aem: { name: isThisDamsEnabled("aem") ? "AEM" : "", options: [] },
};

export const validateUrl = (url) => {
  //((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

export const htmlToString = (html) => {
  //debugger;
  if (html) {
    //return html.replace(/(<([^>]+)>)/ig,"").replace(/[[\]]/g,'')
    // let str = parse(html)
    // if(typeof str === 'object'){
    //   str = html.replace(/(<([^>]+)>)/ig,"").replace(/[[\]]/g,'')
    // }
    // return str;
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }
  return "";
};

export const hasUserLoggedInAsReviewer = () => {
  let info = window.localStorage.getItem("translateModuleInfo");
  if (info) {
    info = JSON.parse(info);
    return info.translateType === "reviewer" ? true : false;
  }
  return true;
};

export const getTranslationJobType = () => {
  let info = window.localStorage.getItem("translateModuleInfo");
  if (info) {
    info = JSON.parse(info);
    return info.jobType;
  }
  return undefined;
};

export const updateModuleInfo = (data) => {
  if (data && Object.keys(data).length > 0) {
    let dataToBeUpdated = window.localStorage.getItem("moduleInfo");
    if (dataToBeUpdated) {
      dataToBeUpdated = JSON.parse(dataToBeUpdated);
      for (const key of Object.keys(data)) {
        dataToBeUpdated[key] = data[key];
      }

      window.localStorage.setItem(
        "moduleInfo",
        JSON.stringify(dataToBeUpdated)
      );
    } else {
      window.localStorage.setItem("moduleInfo", JSON.stringify(data));
    }
  }
};

export const getModuleInfoFromLocalStorage = () => {
  let moduleInfo = window.localStorage.getItem("moduleInfo");
  if (moduleInfo && moduleInfo !== "undefined") {
    moduleInfo = JSON.parse(moduleInfo);
  }
  return moduleInfo;
};

export const getCurrentUser = () => {
  const firstName = window.localStorage.getItem("currentUserName");
  const lastName = window.localStorage.getItem("currentUserFamilyName");
  let curerentUserName = firstName;

  if (lastName) {
    curerentUserName = curerentUserName + ` ${lastName}`;
  }
  return curerentUserName;
};
export const getCurrentUserID = () => {
  const userId = window.localStorage.getItem("userId");
  return userId;
};

export const removeModuleInfo = () => {
  window.localStorage.removeItem("moduleInfo");
};

export const removeTranslateModuleInfo = () => {
  window.localStorage.removeItem("translateModuleInfo");
};

export const moduleTypes = {
  denovo: "denovo",
};

export const citationColors = {
  red: "#eebfc0",
  green: "#b4ffc8",
};
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export const rgbToHex = (rgbString) => {
  //   //return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  //   var a = rgbString.split("(")[1].split(")")[0];
  //   a = a.split(",");
  //   var b = '#'
  //   a.forEach(function(x){             //For each array element
  //     // x = parseInt(x).toString(16);      //Convert to a base16 string
  //     // return (x.length==1) ? "0"+x : x;  //Add zero if we get only one character
  //     return b = b+componentToHex(x)
  // })
  // //b = "0x"+b.join("");
  // return b
  let color = rgbString; //"rgb(51,51,51);";
  color =
    "#" +
    color
      .match(/\d+/g)
      .map(function (x) {
        x = parseInt(x).toString(16);
        return x.length == 1 ? "0" + x : x;
      })
      .join("");
  return color;
};
export const getRefSuperscriptStyle = (color) => {
  const styleColor = color === "r" ? citationColors.red : citationColors.green; //'#eebfc0' : '#b4ffc8'
  return `background:${styleColor}; color:#222222; border-radius: 50%; padding: 5px 0;
  display: inline-block; width: 10px; height: 10px; font-size: 8px; text-align: center;
  margin-right: 1px;`;
};

const getFootnoteSuperscript = (currentString, selectText) => {
  let startIndex = currentString.indexOf(selectText);
  startIndex = startIndex + selectText.length;
  var endIndex = currentString.indexOf("</dfn>", startIndex);
  let dfnStartIndex = currentString.indexOf("<dfn>", startIndex);
  //Check if there is a substring between dfn and the selected word
  let word = currentString.substring(startIndex, dfnStartIndex);
  if (word.trim().length > 0) {
    endIndex = -1;
  }
  if (endIndex == -1) {
    return "";
  }
  endIndex = endIndex + "</dfn>".length;
  let citation = currentString.substring(startIndex, endIndex);
  return citation;
};
export const addFootnoteSuperScript = (
  currentString,
  iconArr,
  footNoteIcon,
  selectText
) => {
  if (footNoteIcon) {
    iconArr = iconArr.concat([footNoteIcon]);
  }

  var textToReplaceWith = iconArr
    .map((icon) => {
      return `<sup>${icon}</sup>`;
    })
    .toString()
    .replaceAll(",", "");

  let startIndex = currentString.indexOf(selectText);
  startIndex = startIndex + selectText.length;
  var endIndex = currentString.indexOf("</dfn>", startIndex);
  const dfnStartIndex = currentString.indexOf("<dfn>", startIndex);
  const word = currentString.substring(startIndex, dfnStartIndex);
  if (word.trim().length > 0) {
    endIndex = -1;
  }

  const textToBeReplaced = selectText;
  let froalaString;
  let newClaimString;
  if (endIndex === -1) {
    froalaString = currentString.replace(
      selectText,
      `${selectText}<dfn>${textToReplaceWith}</dfn>`
    );
  } else {
    endIndex = endIndex + "</dfn>".length;

    newClaimString =
      currentString.substring(0, startIndex) +
      "" +
      currentString.substring(endIndex);

    let citation = currentString.substring(startIndex, endIndex);

    let temp = document.createElement("div");
    temp.innerHTML = citation;
    temp
      .getElementsByTagName("dfn")[0]
      .insertAdjacentHTML("beforeend", textToReplaceWith);

    let children = temp.getElementsByTagName("dfn")[0].children;
    let arr = [...children];
    //Remove duplicates
    arr = [...arr].map((el) => {
      return el.innerText;
    });
    arr = Array.from(new Set(arr));
    arr = arr.map((el) => {
      let sup = document.createElement("SUP");
      sup.innerHTML = el;
      return sup;
    });

    let data = document.createElement("dfn");
    arr.forEach((child) => {
      data.insertAdjacentHTML("beforeend", child.outerHTML);
    });

    froalaString = newClaimString.replace(
      textToBeReplaced,
      `${textToBeReplaced}${data.outerHTML}`
    );
  }
  return froalaString;
};

export const sortSuperScriptNumbers = (
  currentString,
  textToBeReplaced,
  textToReplaceWith,
  selectText
) => {
  let startIndex = currentString.indexOf(selectText);
  startIndex = startIndex + selectText.length;
  var endIndex = currentString.indexOf("</cite>", startIndex);
  let dfnStartIndex = currentString.indexOf("<dfn>", startIndex);
  let dfnEndIndex = currentString.indexOf("</dfn>", startIndex);
  let newClaimString = currentString;
  let words = "";
  if (dfnStartIndex === startIndex) {
    //dfnEndIndex = startIndex
    //do nothing here for now
    dfnEndIndex = dfnEndIndex + "</dfn>".length;
    newClaimString = currentString.replaceAll(
      currentString.substring(startIndex, dfnEndIndex),
      ""
    );
  } else {
    words = currentString.substring(startIndex, dfnStartIndex);
    if (words.trim().length > 0) {
      dfnEndIndex = startIndex;
    }
  }

  let citeStartIndex = currentString.indexOf("<cite>", dfnEndIndex);

  if (citeStartIndex === dfnEndIndex || citeStartIndex === dfnEndIndex + 1) {
    //do nothing here for now
  } else {
    words = currentString.substring(dfnEndIndex, citeStartIndex);
    if (words.trim().length > 0) {
      endIndex = -1;
    }
  }

  let froalaString;
  if (endIndex === -1) {
    const footnotes = getFootnoteSuperscript(currentString, selectText);
    froalaString = newClaimString.replace(
      textToBeReplaced,
      `${textToBeReplaced}${footnotes} <cite>${textToReplaceWith}</cite>`
    );
  } else {
    endIndex = endIndex + "</cite>".length;
    newClaimString =
      currentString.substring(0, dfnEndIndex) +
      "" +
      currentString.substring(endIndex);

    let citation = currentString.substring(dfnEndIndex, endIndex);

    let temp = document.createElement("div");
    temp.innerHTML = citation;
    temp
      .getElementsByTagName("cite")[0]
      .insertAdjacentHTML("beforeend", textToReplaceWith);

    let children = temp.getElementsByTagName("cite")[0].children;
    let arr = [...children];
    arr.sort((a, b) => {
      return Number(a.innerText) > Number(b.innerText) ? 1 : -1;
    });

    let cite = document.createElement("cite");
    arr.forEach((child) => {
      cite.insertAdjacentHTML("beforeend", child.outerHTML);
    });
    const footnotes = getFootnoteSuperscript(currentString, selectText);
    froalaString = newClaimString
      .replace(footnotes, "")
      .replace(
        textToBeReplaced,
        `${textToBeReplaced}${footnotes ? footnotes : ""} ${cite.outerHTML}`
      );
  }
  return froalaString;
};

export const OutsideClickHandler = (ref, callback, when) => {
  const savedCallBack = useRef(callback);

  useEffect(() => {
    savedCallBack.current = callback;
  }, []);

  function handler(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      savedCallBack.current();
    }
  }

  useEffect(() => {
    if (when) {
      document.addEventListener("click", handler);
      return () => document.removeEventListener("click", handler);
    }
  }, [when]);
};

export const getDownloadModuleCss = () => {
  return (
    ".mlr-sub-head {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "  text-align: center;" +
    "  padding: 10px;" +
    "}" +
    ".text-mlr-pkg-sub {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "  font-size: 16px;" +
    " font-weight: 600;" +
    "color: #222222;" +
    "}" +
    ".mlr-main-content {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " margin-left: 40px;" +
    " margin-right: 60px;" +
    " margin-top: 24px;" +
    "}" +
    ".mlr-page-content {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " background-color: #fff;" +
    " border-radius: 8px;" +
    " padding: 50px;" +
    "}" +
    ".btn-download {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 12px;" +
    "color: #1c75bb;" +
    "font-weight: 600;" +
    "cursor: pointer;" +
    "outline: none;" +
    "box-shadow: none;" +
    "border: none;" +
    "background-color: transparent;" +
    "margin-left: 72px;" +
    "margin-right: 72px;" +
    "margin-top: 24px;" +
    "}" +
    ".btn-download:focus {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "outline: none;" +
    "box-shadow: none;" +
    "}" +
    ".head {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 16px;" +
    "color: #222222;" +
    "font-weight: 600;" +
    "border-bottom: solid 2px black;" +
    "}" +
    ".module-specific {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin-top: 24px;" +
    "width: 100%;" +
    "}" +
    "table.business-rules-specific {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin-bottom: 24px;" +
    "width: 100%;" +
    "}" +
    "table {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black;" +
    "border-collapse: collapse;" +
    "}" +
    ".table td {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black;" +
    "padding: 10px;" +
    "word-wrap:break-word;" +
    "}" +
    ".table tr:not(.header):nth-child(odd) {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "background-color: transparent;" +
    "}" +
    ".table tr:first-child {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black;" +
    "background-color: #dcdcdc;" +
    "}" +
    ".table tr:first-child td {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: none;" +
    "}" +
    ".tbl-row-txt-bold {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "color: black;" +
    "font-size: 15px;" +
    // "font-weight: 500;" +
    "}" +
    "table.allow-border-in-first-row {" +
    "width : 100%;" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black !important;" +
    "border-collapse: collapse !important;" +
    "}" +
    ".allow-border-in-first-row{" +
    "width : 100%;" +
    "margin-bottom:15px;" +
    "}" +
    ".table.cta-specific tr:first-child {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black;" +
    "background-color: #ffffff;" +
    "}" +
    "table. claim-and-visual-specific {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black !important;" +
    "border-collapse: collapse !important;" +
    "}" +
    ".claim-and-visual-specific{" +
    "margin-bottom:15px;" +
    "}" +
    ".table-column-width-fixed{width : 250px;}" +
    ".table.allow-border-in-first-row td {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "border: 1px solid black !important;" +
    "padding: 5px !important;" +
    "}" +
    ".table.allow-border-in-first-row" +
    " tr:not(.header):nth-child(odd) {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "background-color: transparent !important;" +
    "}" +
    ".varient {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " border-bottom: 1px solid black;" +
    " margin: 0px;" +
    " padding-top: 10px;" +
    " padding-bottom: 10px;" +
    "}" +
    ".varient .varient-title {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " font-size: 16px;" +
    " font-weight: 600;" +
    "}" +
    ".varient  {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "}" +
    ".visual-varient {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin: 0px;" +
    "padding-top: 10px;" +
    "padding-bottom: 10px;" +
    "}" +
    ".visual-varient .varient-title {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 16px;" +
    "font-weight: 600;" +
    "}" +
    ".visual-captions {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "padding-top: 10px;" +
    "padding-bottom: 10px;" +
    "}" +
    ".visual-captions .caption-title {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 16px;" +
    "font-weight: 600;" +
    "}" +
    ".supportingComponent {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin: 10px;" +
    "}" +
    ".supportingComponent .abbreviation {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin: 10px;" +
    "}" +
    ".channel-specific .heading {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 16px;" +
    "color: #222222;" +
    "font-weight: 600;" +
    "}" +
    ".channel-specific .channel-type-head {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " font-size: 14px;" +
    " color: #222222;" +
    "font-weight: 600;" +
    // "border-bottom: solid 1px black;" +
    "margin-bottom: 5px;" +
    "}" +
    ".channel-specific .channel-related-text {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " color: black;" +
    "font-size: 16px;" +
    "font-weight: 400;" +
    "}" +
    ".text-blue {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " color: #1c75bb;" +
    // " font-weight: 600;"+
    "}" +
    ".text-black {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "color: black;" +
    "font-size: 12px" +
    // "font-weight: 600;"+
    "}" +
    ".background-green {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " background-color: limegreen;" +
    "}" +
    ".background-red {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " background-color: red;" +
    "}" +
    ".background-amber {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " background-color: rgb(250, 227, 212);" +
    "}" +
    ".list-item-margin-0{ " +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "margin: 0px;}" +
    ".mandoryCoponent {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "}" +
    ".mandoryCoponentTbl {" +
    "border: none !important;" +
    "}" +
    ".pdfHeader {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    " display:Block;" +
    "font-size: 12px" +
    "color:#ffffff" +
    "}" +
    ".visualsImageListHeading {" +
    "font-family: Arial, Arial Unicode MS, Helvetica, sans-serif;" +
    "font-size: 16px;" +
    "color: #222222;" +
    "font-weight: 600;" +
    "}" +
    ".firstImagePage {" +
    // "page-break-before: always;" +
    //"height: 892px;" +
    "overflow: hidden; " +
    "}" +
    ".firstImagePage:last-child {" +
    "page-break-before: avoid;" +
    "}" +
    ".view-composite-asset {" +
    "color : #222222" +
    ".module-preview {" +
    " .main-content {" +
    "  margin: 50px 0px;" +
    " display: contents;" +
    "}" +
    ".supporting-content {" +
    "margin: 30px 0px;" +
    "}" +
    " .claim-or-caption-text {" +
    "font-size: 14px;" +
    "margin: 10px 0px;" +
    "}" +
    ".supporting-text {" +
    "font-size: 12px;" +
    "margin: 10px 0px;" +
    " }" +
    "}" +
    "page-break-after: always;" +
    "}" +
    ".img-prev-pdf {" +
    "width:300px;" +
    "max-width:300px;" +
    "}"
  );
};

export const isThisMLRFeedbackFlow = () => {
  const moduleInfo = getModuleInfoFromLocalStorage();
  if (moduleInfo && moduleInfo.moduleType === "addressMLRFeedback") {
    return true;
  }
  return false;
};

export const downloadPdfFromByteArray = (byteArray, name) => {
  const pdfUrl = window.URL.createObjectURL(new Blob(byteArray));
  const downlodLink = document.createElement("a");
  downlodLink.href = pdfUrl;
  downlodLink.setAttribute("download", `${name}.pdf`); //or any other extension
  document.body.appendChild(downlodLink);
  downlodLink.click();
};

export const isValidInteger = (value) => {
  return isInteger(value);
};

export const isStartingWithZero = (value) => {
  return false;
};

export const getMonth = (monthNumber) => {
  switch (monthNumber) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "";
  }
};

export const searchInArray = (
  targetArray,
  keys,
  searchParam,
  conditionType
) => {
  let resultingArray = targetArray;
  let finalArray = [];
  keys.forEach((key, index) => {
    if (conditionType === "or") {
      resultingArray = targetArray;
    }

    resultingArray = [...resultingArray].filter((item) => {
      return item[key].toLowerCase().indexOf(searchParam.toLowerCase()) > -1;
    });
    if (conditionType === "or" && resultingArray.length > 0) {
      finalArray = finalArray.concat(resultingArray);
    }
  });
  if (conditionType === "or") {
    return finalArray;
  }
  return resultingArray;
};

export const getActiveClassStatus = (id, currentid) => {
  if (id === currentid) {
    return true;
  } else {
    return false;
  }
};

export const getRemoteImageDim = (imageUrl, cb) => {
  var img = new Image();
  img.onload = function () {
    cb(this.width, this.height);
  };
  img.src = imageUrl;
};

export const marketingMessageSourceType = {
  veeva: { name: "Veeva" },
  aprimo: { name: "Aprimo" },
  asset: { name: "Asset" },
  denovo: { name: "De-nuovo" },
  newMessage: { name: "NewMessage" },
  moduleManager: { name: "Module Manager" },
};

export const channelTypes = {
  email: "Email",
  banner: "Banner",
  alert: "Alert",
  eDetail: "e-Detail",
};
export const getAllChannelAttributes = () => {
  let allAttributes = {};
  allAttributes = Object.assign(
    allAttributes,
    getChannelAttributes(channelTypes.email.toLowerCase())
  );
  allAttributes = Object.assign(
    allAttributes,
    getChannelAttributes(channelTypes.banner.toLowerCase())
  );
  allAttributes = Object.assign(
    allAttributes,
    getChannelAttributes(channelTypes.alert.toLowerCase())
  );
  return allAttributes;
};
export const getChannelAttributes = (channelType) => {
  return channelTypesAndAttributes[channelType]?.attributes;
};
export const channelTypesAndAttributes = {
  email: {
    name: channelTypes.email,
    attributes: {
      subjectLine: "Subject Line",
      preheader: "PreHeader",
      optOut: "Opt-out",
      header: "Header",
    },
  },
  banner: {
    name: channelTypes.banner,
    attributes: {
      tagLine: "Tagline",
      optOut: "Opt-out",
    },
  },
  alert: {
    name: channelTypes.alert,
    attributes: {
      tagLine: "Tagline",
      optOut: "Opt-out",
    },
  },
  eDetail: {
    name: channelTypes.eDetail,
  },
};
export const globalAttributes = {
  cta: "CTA",
};

export const getClickableLink = (link) => {
  let url = link;
  if (link && (!link.startsWith("http") || !link.startsWith("https"))) {
    url = "https://" + link;
  }
  return url;
};

export const isContentStudioLinkEnabled = () => {
  return features.contentStudio.isEnabled;
};
export const isTranslateFeatureEnabled = () => {
  return features.translate.isEnabled;
};

export const getMlrStatusIcon = (mlrStatus) => {
  let statusIcon = draftStatusIcon;
  if (mlrStatus) {
    if (mlrStatus.trim().toLowerCase() === "approved") {
      statusIcon = tickImage;
    }
    return statusIcon;
  }
  return statusIcon;
};

export const capitalizeString = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const isModularContent = (assetDataType) => {
  return assetDataType?.trim().toLowerCase() === "modularcontent";
};

export const removeDuplicates = (originalArray = [], key) => {
  var newArray = [];
  var lookupObject = {};
  for (var i in originalArray) {
    lookupObject[originalArray[i][key]] = originalArray[i];
  }
  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const removeDuplicatesBasedOnMultipleKeys = (
  originalArray = [],
  keys
) => {
  const newArray = originalArray.filter(
    (
      (s) => (o) =>
        ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
    )(new Set())
  );

  return newArray;
};
export const templateTypes = {
  emailTemplate: {
    name: "email",
    type: "Template",
    getMessage: () => {
      return "Select Approved Template to Proceed ";
    },
    getSearchHint: () => {
      return "Search by Veeva ID/NEXT ID/Template Name";
    },
    getType: () => {
      return "Template";
    },
  },
  sampleEmail: {
    name: "email",
    type: "Sample Email",
    getMessage: () => {
      return "Select Sample Email to Proceed";
    },
    getSearchHint: () => {
      return "Search by NEXT ID/Email Name";
    },
    getType: () => {
      return "Email";
    },
  },
};

export const getLockIcon = (marketingMessage) => {
  if (marketingMessage.lockStatus) {
    return <img src={lockIcon} alt="" className="lock-status"></img>;
  }
};

export const showError = (err, position = "topright") => {
  if (position.toLowerCase() === "topright") {
    position = toast.POSITION.TOP_RIGHT;
  }
  const errCode = err.response && err.response.status;
  const errCoponent = errComponentToRender(errCode);
  toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
};
export const accordionTitles = {
  claims: "claims",
  ctas: "ctas",
  assets: "assets",
  suppotingClaim: "suppotingClaim",
  supportingVisual: "supportingVisual",
};
export const removeDuplicateFromLiteralsArray = (arr) => {
  const resultArr = arr.filter((data, index) => {
    return arr.indexOf(data) === index;
  });
  return resultArr;
};

export const cleanHtml = (html) => {
  html = html
    .replaceAll("<cite>", "")
    .replaceAll("</cite>", "")
    .replaceAll("<dfn>", "")
    .replaceAll("</dfn>", "");
  html = html.replace(/\s*\S*\="[^"]+"\s*/gm, "");
  return html;
};
export const renderChevron = (direction = "up") => {
  if (direction.toLowerCase() === "down") {
    return (
      <svg
        class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ top: "30px", right: "22px" }}
      >
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
      </svg>
    );
  } else if (direction.toLowerCase() === "up") {
    return (
      <svg
        class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconOpen"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ top: "30px", right: "22px" }}
      >
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
      </svg>
    );
  }
};

export const filterOutGlobalMarket = (marketData) => {
  return marketData.filter((market) => {
    return market.name.toLowerCase() !== "global";
  });
};
export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function checkURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}
