import React from "react";
import { Col } from "react-bootstrap";

const GenericBusinessRulesBox = (props) => {
  const { boxKey, boxTitle, data, customCName } = props;
  return (
    <>
      <Col xs="12" md="4" lg="4" className={` ${customCName}  mb-3`}>
        <div className="viewBusinessRulesBox">
          <div className="businessRulesBoxTitle d-flex justify-content-between">
            {boxTitle}
          </div>

          {data.length ? (
            <p className="moduleListCount">
              {data.length} Modules / 0 Category
            </p>
          ) : (
            <div className="boxLableVerticalMiddle moduleCountPlaceHolder">
              0 Modules / Category
            </div>
          )}

          <ul className={`supClaimList listScrollContainer custonScrollBar`}>
            {data.map((item) => {
              return (
                <li key={item.requestId} className="rulesBoxModuleList">
                  <div className="groupAtomsList">
                    <p className="listModuleName">{item.name}</p>
                    <p className="listModuleId">{item.moduleCode}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Col>
    </>
  );
};

export default GenericBusinessRulesBox;
