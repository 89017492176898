//Import react dependencies
import React from "react";

//Import custom compnents
import TextContent from "./TextContent";

const SubjectLines = (props) => {
  const { subjectlinesElements } = props;
  return (
    <div className="channel-attr-container customScrollBar">
      {subjectlinesElements &&
        subjectlinesElements.map((item, index) => {
          return (
            <div className="channel-attr-box" key={index}>
              <div className={`${item.disclaimer ? "subjectline" : ""}`}>
                <TextContent
                  heading={`${item.cmDocumentNumber || ""} |  Subject Line ${
                    index + 1
                  }`}
                  content={item.text}
                />
              </div>
              {item.disclaimer && (
                <TextContent heading="Disclaimer" content={item.disclaimer} />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default SubjectLines;
