import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import SupportingClaim from "../Uitls/CommonFroalEditorComponent";
import SupportingClaimDenovo from "../components/SupportingClaimDenovo";
import DeleteIcon from "@material-ui/icons/Delete";
import "../styles/pages/CreateModuleStep2.scss";
import "../styles/components/SupportingVisual.scss";
import ContextMenu from "./contextMenu";
import arrowpolygonRight from "../images/arrowpolygon.svg";
import FooteNotesModal from "./SuppClaimFootNote";
import Abbreviation from "./abbreviationSupportingClaim";
import ReferenceContainer from "./ReferencesAndAnnotation/ReferenceContainer";
import ReferenceAnchor from "../components/ReferencesAndAnnotation/ReferenceAnchor";
import { v4 as uuidv4 } from "uuid";
import { areRefArraysEqual } from "../components/PrimaryClaim";
import FloatingButton from "../Uitls/FloatingButton";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import {
  citationColors,
  rgbToHex,
  getRefSuperscriptStyle,
  sortSuperScriptNumbers,
  addFootnoteSuperScript,
  moduleTypes,
} from "../Uitls/Util";

const AddSuppClaimRow = (props) => {
  const { t } = useTranslation();
  const {
    setSuperScriptDataForClaim,
    getModuledetails,
    row,
    rowIndex,
    supportingClaimPosition,
    setAtomsHeader,
    setSelectedSuppBox,
    setSelectedSuppRow,
    setFilterType,
    currentTextReplace,
    setCurrentTextReplace,
    deleteSuppClaimRow,
    selectedText,
    suppClaimfootNoteText,
    addFootNotesToMasterArray,
    supportingClaimFooteNote,
    textSelectedAbbr,
    setIsAbbrDisable,
    isAbbrDisable,
    addAbbrToMaster,
    abbrStatsu,
    abbreviations,
    handleAddRadioAbbr,
    addButtonStatusfromStep2,
    referenceMasterList,
    atomTextForRef,
    superScriptDataForClaim,
    handleAtomTextChange,
    anchorAddedIn,
    resetSupportingClaimAbbreviations,
    maxAbbrRecordIndex,
    sourceType,
    findSupportingClaimOrVisualPosition,
    moduleType,
    supportingClaimsAndVisuals,
    setSupportingClaimsAndVisuals,

    // addSupportingClaims,

    handleAtomTextChangevarients,
  } = props;
  const [box1, box2, box3, box4] = row;
  //Footenotes Code Start

  const [showFootNote, setFootNoteModal] = useState(false);
  const [footNoteText, setFootNoteText] = useState([]);
  const [addBtnStatus, setAddBtnStatus] = useState(false);
  const [addIconStatus, setAddIconStatus] = useState(false);
  const [selectedFootNotes, setSelectedFootNotes] = useState([]);
  const [footNoteIcon, setFootNoteIcon] = useState([]);
  const [footNoteCheckBox, setFootNoteCheckBox] = useState([]);
  const [selectText, getSelectText] = useState("");
  const referencesValid = useRef(null);
  const [isAbbr, setIsAbbr] = useState(false);
  const [arrAbbr, setArrAbbr] = useState([]);
  const [abbrStatus1, setAbbrStatus] = useState();
  const [abbrNoteText, setAbbrNoteText] = useState([]);
  const [arrAnnot, setArrAnnot] = useState([]);
  const [references, setReferences] = useState([]);
  const [rightClickTriggered, setRightClickTriggered] = useState(false);
  const [showModalAnchor, setShowModalAnchor] = useState(false);
  const [selectedTextPrimaryClaimValue, setSelectedTextPrimaryClaimValue] =
    useState("");
  let footNoteFroalaInstance = useRef([]);
  const [footNoteIndex, setFootNoteIndex] = useState(-1);
  const [claimId, setClaimId] = useState("");
  const [superScriptData, setSuperScriptData] = useState([]);
  const [superScriptRefData, setSuperScriptRefData] = useState([]);
  const [finalRef, setFinalRef] = useState([]);
  const [contextMenuOptions, setContextMenuOptions] = useState(undefined);
  const [addOrModifyanchorData, setAddOrModifyAnchorData] = useState(undefined);
  const [eventType, setEventType] = useState(undefined);
  const [currentClaimTextReplace, setCurrentClaimTextReplace] = useState(0);
  useEffect(() => {
    setSelectedFootNotes(row[1].data);
  }, [row[1].data]);
  useEffect(() => {
    setAbbrStatus(abbrStatsu);
  }, [abbrStatsu]);
  useEffect(() => {
    setAddBtnStatus(addButtonStatusfromStep2);
  }, [addButtonStatusfromStep2]);

  useEffect(() => {
    setAbbrNoteText(abbreviations || []);
  }, [props]);
  useEffect(() => {
    setSelectedTextPrimaryClaimValue(textSelectedAbbr);
  }, [textSelectedAbbr]);

  useEffect(() => {
    setFootNoteText(suppClaimfootNoteText);
  }, [suppClaimfootNoteText]);

  useEffect(() => {
    //to make back ground blur for denovo module
    if (props.setBgBlurForDenovo) {
      props.setBgBlurForDenovo(showModalAnchor);
    }
  }, [showModalAnchor]);

  const handleClickAnchor = () => {
    setShowModalAnchor(true);
  };
  const handleCloseReferenceAnchor = () => {
    setShowModalAnchor(false);
  };
  const handleFootNoteMOdalClose = () => {
    setFootNoteText([]);
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setFootNoteModal(false);
    setFootNoteCheckBox([]);
  };
  const handleArrAbbr = (val) => {
    if (val) {
      val[0]["record_index"] = maxAbbrRecordIndex() + 1;
    }
    let existingAbbriviations = [...row[2].data];
    for (const abbreviation of existingAbbriviations) {
      if (abbreviation.abbrText === val[0].abbrText) {
        setIsAbbr(false);
        setFootNoteText("");
        setSelectedTextPrimaryClaimValue("");
        return;
      }
    }
    if (val) {
      setArrAbbr(arrAbbr.concat(val));
    }
    setIsAbbr(false);
    addAbbrToMaster(val);
    setFootNoteText("");
    setSelectedTextPrimaryClaimValue("");
  };

  const handleAbbrDataUpdate = (val) => {
    handleAddRadioAbbr(val);
    setSelectedTextPrimaryClaimValue("");
  };
  const handleCloseAbbreviation = () => {
    setIsAbbr(false);

    const newReferences = arrAnnot.map((value) => {
      value = "<p>" + value + "</p>";
      return value;
    });
    setReferences(newReferences);
    setFootNoteText("");
    setSelectedTextPrimaryClaimValue("");
  };

  const handleFootNoteInputChange = (TextChanged) => {
    referencesValid.current.innerHTML = TextChanged;
    const text = referencesValid.current.innerText;
    if (text && text.trim().length) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(true);
    } else if (TextChanged.trim().length !== 0) {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    } else {
      const newText = { footText: TextChanged };
      setFootNoteText(newText);
      setAddBtnStatus(false);
    }
  };

  const handleAddFootNote = () => {
    const fDataOne = footNoteText;
    const fDataTwo = footNoteIcon;
    const finalFootNoteData = {
      footNoteText: fDataOne.footText,
      footNoteIcon: fDataTwo.footNoteIconValue,
    };
    addFootNotesToMasterArray(finalFootNoteData);
    addSupScript();
    setAddBtnStatus(false);
    setAddIconStatus(false);
    setFootNoteText([]);
    setFootNoteModal(false);
  };

  const [checkBoxHelper, setCheckBoxHelper] = useState([]);
  const handleFootNoteCheckBox = (event, index, icon) => {
    if (event.target.checked) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) == -1) {
          setFootNoteCheckBox([...footNoteCheckBox, icon]);
        }
      } else {
        setFootNoteCheckBox([icon]);
      }
      setCheckBoxHelper(checkBoxHelper.concat(icon));
    } else {
      if (footNoteCheckBox && footNoteCheckBox.length > 0) {
        if (footNoteCheckBox.indexOf(icon) !== -1) {
          setFootNoteCheckBox(
            footNoteCheckBox.filter((existingItem) => {
              return existingItem !== icon;
            })
          );
        }
      }
      setCheckBoxHelper(
        checkBoxHelper.filter((item) => {
          return item !== icon;
        })
      );
    }
  };

  useEffect(() => {
    if (checkBoxHelper && checkBoxHelper.length > 0) {
      setAddIconStatus(true);
      setAddBtnStatus(true);
    } else {
      setAddIconStatus(false);
      setAddBtnStatus(false);
      setIconValueToInitial();
      setFootNoteCheckBox([]);
    }
  }, [checkBoxHelper]);

  const setAllToInitial = () => {
    setAddIconStatus(false);
    setAddBtnStatus(false);
    setIconValueToInitial();
    setFootNoteCheckBox([]);
    setFootNoteText([]);
  };

  const setIconValueToInitial = () => {
    setFootNoteIcon([]);
    setAddIconStatus(false);
  };

  const handleAddFootNoteCheckBox = () => {
    addSupScript1();
    setAddBtnStatus(false);
    setAddIconStatus(false);
    setFootNoteText([]);
    setFootNoteModal(false);
  };

  const addSupScript = () => {
    /* CODE FOR foot nOTES*/
    //<sup>superscript</sup>
    if (footNoteIcon && selectText && froalaInstance.current.editor) {
      // var froalaString = froalaInstance.current.editor.html
      //   .get()
      //   .replace(
      //     selectText,
      //     `${selectText}<sup>${footNoteIcon.footNoteIconValue}</sup>`
      //   );
      let currentString = froalaInstance.current.editor.html.get();
      const froalaString = addFootnoteSuperScript(
        currentString,
        footNoteCheckBox,
        footNoteIcon.footNoteIconValue,
        selectText
      );
      froalaInstance.current.editor.html.set(froalaString);
      froalaInstance.current.editor.selection.save();
      props.handleInputChangeFootNote(froalaString);
      getSelectText("");
      const html = froalaInstance.current.editor.html.get(true);
      froalaInstance.current.editor.selection.clear();
      setFootNoteIcon([]);
      setFootNoteCheckBox([]);
    }
  };

  const addSupScript1 = () => {
    /* CODE FOR foot nOTES*/
    //<sup>superscript</sup>
    if (
      footNoteCheckBox &&
      footNoteCheckBox.length !== 0 &&
      selectText &&
      froalaInstance.current.editor
    ) {
      let currentString = froalaInstance.current.editor.html.get();
      // var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
      // var suptext = [];
      // words.forEach((item, index, array) => {
      //   let elIndex = words.indexOf(item);
      //   let regex = /(<([^>]+)>)/gi;
      //   let regex2 = /(\[.+\])/gi;
      //   let result = item.replace(regex, "").trim();
      //   let tempResult = result.replace(regex2, "").trim();
      //   const selectedTextForCompare = selectText.trim();
      //   if (tempResult === selectedTextForCompare) {
      //     if (elIndex < words.length - 1) {
      //       let suptext1 = Object.values(
      //         array[index + 1].match(/<sup>(.*?)<\/sup>/g) != undefined
      //           ? array[index + 1].match(/<sup>(.*?)<\/sup>/g)
      //           : ""
      //       );
      //       let suptext2 = Object.values(
      //         array[index].match(/<sup>(.*?)<\/sup>/g) != undefined
      //           ? array[index].match(/<sup>(.*?)<\/sup>/g)
      //           : ""
      //       );
      //       suptext = suptext.concat(suptext1, suptext2);
      //     } else if (elIndex === words.length - 1) {
      //       suptext = Object.values(
      //         array[index].match(/<sup>(.*?)<\/sup>/g) != undefined
      //           ? array[index].match(/<sup>(.*?)<\/sup>/g)
      //           : ""
      //       );
      //     } else {
      //       return;
      //     }
      //   }
      // });
      // const iconsToAdd = footNoteCheckBox.map(el => `<sup>${el}</sup>`);
      // if (suptext) {
      //   let icons = iconsToAdd.filter(val => !suptext.includes(val));
      //   var froalaString = froalaInstance.current.editor.html
      //     .get()
      //     .replace(selectText, `${selectText}${icons}`);
      // } else {
      //   var froalaString = froalaInstance.current.editor.html
      //     .get()
      //     .replace(selectText, `${selectText}${iconsToAdd}`);
      // }
      // var froalaString = froalaInstance.current.editor.html
      //   .get()
      //   .replace(selectText, `${selectText}${iconsToAdd}`);
      const froalaString = addFootnoteSuperScript(
        currentString,
        footNoteCheckBox,
        footNoteIcon.footNoteIconValue,
        selectText
      );
      froalaInstance.current.editor.html.set(froalaString);
      froalaInstance.current.editor.selection.save();
      getSelectText("");
      const html = froalaInstance.current.editor.html.get(true);
      froalaInstance.current.editor.selection.clear();
      props.handleInputChangeFootNote(froalaString);
      setFootNoteIcon([]);
      setFootNoteCheckBox([]);
    }
  };

  document.addEventListener("mouseup", (event) => {
    event.preventDefault();
    if (rightClickTriggered) {
      setTimeout(function () {
        if (rightClickTriggered) {
          hideEditorToolBar(true);
        }
        // else{
        //   if (
        //     froalaInstance &&
        //     froalaInstance.current &&
        //     froalaInstance.current.getEditor()
        //   ){
        //     const text  = froalaInstance.current.getEditor().selection.text() || ''
        //     console.log('highlighted text', text)
        //     if(text && text !== ''){
        //       hideEditorToolBar(false)
        //     }
        //   }
        // }
      }, 2);
    }
  });

  const hideEditorToolBar = (flag) => {
    if (
      froalaInstance &&
      froalaInstance.current &&
      froalaInstance.current.getEditor() &&
      froalaInstance.current.getEditor().toolbar
    ) {
      if (flag) {
        froalaInstance.current.getEditor().toolbar.hide();
      } else {
        froalaInstance.current.getEditor().toolbar.show();
      }
    }
  };

  const getIconValue = (IconValue) => {
    if (IconValue) {
      const newIconValue = { footNoteIconValue: IconValue };
      setFootNoteIcon(newIconValue);
      setAddIconStatus(true);
    }
  };

  const onSuperScriptOptionClick = (docId, event, type) => {
    setAddOrModifyAnchorData({ docId: docId, anchorText: "" });
    setShowModal(true);
    if (event !== "delete") {
      if (type === "supporting-claim") {
        props.onSuperScriptClick(docId, event, type, {
          rowIndex: rowIndex,
          index: undefined,
        });
      } else if (type === "supporting-claim-footnote") {
        props.onSuperScriptClick(docId, event, type, {
          rowIndex: rowIndex,
          index: footNoteIndex,
        });
      }
    } else if (event === "delete") {
      if (type === "supporting-claim") {
        //skipAnchorSupportingClaim(docId, "",anchorAddedIn)
        skipAnchorSupportingClaim(docId, "", {
          type: "supporting-claim",
          rowIndex: rowIndex,
        });
      } else if (type === "supporting-claim-footnote") {
        //skipAnchorSupportingClaim(docId, "",anchorAddedIn)
        skipAnchorSupportingClaim(docId, "", {
          type: "supporting-claim-footnote",
          rowIndex: rowIndex,
          index: footNoteIndex,
        });
      }
    }
  };

  const onSupClick = (event, type) => {
    const el = event.target;
    if (el && el.dataset) {
      setEventType(el);
      const docId = el.dataset.docid;
      if (docId && docId.trim().length > 0) {
        setContextMenuOptions({
          event: "modify",
          docId: docId,
          options: ["View", "Modify", "Delete"],
          head: "Anchor",
          type: type,
        });
      }
    }
  };

  let froalaInstance = React.createRef(); //useRef();
  const handleCont = (event) => {
    if (event && event.target.nodeName === "SUP") {
      props.setAnchorAddedIn({ type: "supporting-claim", rowIndex: rowIndex });
      onSupClick(event, "supporting-claim");
      return;
    }

    let SelectedText = "";
    try {
      if (froalaInstance.current) {
        let y = froalaInstance.current?.getEditor()?.toolbar?.hide();
        setTimeout(function () {
          SelectedText = froalaInstance.current?.getEditor()?.selection?.text();
          let x = froalaInstance.current?.getEditor()?.toolbar?.hide();
          getSelectText(SelectedText);
          //setRightClickTriggered(true)
        }, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleContFootNote = (event) => {
    if (event && event.target.nodeName === "SUP") {
      props.setAnchorAddedIn({
        type: "supporting-claim-footnote",
        rowIndex: rowIndex,
        index: footNoteIndex,
      });
      onSupClick(event, "supporting-claim-footnote");
      return;
    }

    let SelectedText = "";
    try {
      if (footNoteFroalaInstance.current[footNoteIndex]) {
        let y = footNoteFroalaInstance.current[footNoteIndex]
          ?.getEditor()
          ?.toolbar?.hide();
        setTimeout(function () {
          SelectedText = footNoteFroalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.selection?.text();
          let x = footNoteFroalaInstance.current[footNoteIndex]
            ?.getEditor()
            ?.toolbar?.hide();
          getSelectText(SelectedText);
          //setRightClickTriggered(true)
        }, 0);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const onContextMenuItemSelected = (eventRef) => {
    eventRef.stopPropagation();
    props.onBoxSelected(eventRef);
    setRightClickTriggered(false);
  };
  const handleFootNote = (event) => {
    setFootNoteModal(true);
    event.stopPropagation();
    props.onBoxSelected(event);
  };
  const handleAbbriviation = (event) => {
    setIsAbbr(true);
    event.stopPropagation();
    props.onBoxSelected(event);
  };
  //Reference and Annotation(new code)
  const [showModal, setShowModal] = useState(false);
  const [refText, setRefText] = useState([]);
  const [refAddStatus, setrefAddStatus] = useState(false);
  const [tempMasterList, setTempMasterList] = useState([]);
  const [tempSuppRef, setTempSuppRef] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState([]);
  let prevProps = usePrevious({ selectText, referenceNumber });
  useEffect(() => {
    setRefText(atomTextForRef);
    setrefAddStatus(true);
  }, [atomTextForRef]);

  const handleCloseRefModal = () => {
    setShowModal(false);
    setAddOrModifyAnchorData(undefined);
    props.setAnchorAddedIn(undefined);
  };

  const handleReference = (event) => {
    setShowModal(true);
    event.stopPropagation();
    props.onBoxSelected(event, {
      type: "supporting-claim",
      rowIndex: rowIndex,
    });
  };
  const handleFootnoteReference = (event) => {
    setShowModal(true);
    event.stopPropagation();
    props.onBoxSelected(event, {
      type: "supporting-claim-footnote",
      rowIndex: rowIndex,
      index: footNoteIndex,
    });
  };

  const updateInMasterList = (selectedRefCount, finalData) => {
    let result = [];
    selectedRefCount.forEach(function (entry) {
      let obj = finalData.find((o) => o.refId === entry);
      result.push(obj.refId);
    });
    setReferenceNumber(result);
    const newArray = [...referenceMasterList];
    const refTempArray = [...row[3].data];
    newArray.push.apply(newArray, finalData);
    setTempMasterList(newArray);
    refTempArray.push.apply(refTempArray, finalData);
    const arrayTemp = refTempArray.reduce((obj, item) => {
      obj[item.refId]
        ? obj[item.refId].annotationList.push(...item.annotationList)
        : (obj[item.refId] = { ...item });
      return obj;
    }, {});
    const helperArray = Object.values(arrayTemp);
    const finalMergedArray = removeDuplicates(helperArray, "id");
    let filteredFinalArray = removeDuplicateAnnotation(finalMergedArray);
    // for(let i=0; i < finalMergedArray.length; i++){
    //   for(let j=0;j<finalMergedArray[i]["annotationList"].length ;j++){
    //       var newResult = [...new Map(finalMergedArray[i]["annotationList"].map( o => [JSON.stringify(o), o])).values()];
    //       finalMergedArray[i]["annotationList"]=newResult;
    //   }
    // }
    setTempSuppRef(filteredFinalArray);
  };

  useEffect(() => {
    props.saveSuppRef(tempSuppRef);
    setShowModal(false);
    setReferenceNumber([]);
    //setTempSuppRef([]);
  }, [tempSuppRef]);

  const removeDuplicates = (originalArray, refId) => {
    var newArray = [];
    var lookupObject = {};
    for (var i in originalArray) {
      lookupObject[originalArray[i][refId]] = originalArray[i];
    }
    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };
  const removeDuplicateAnnotation = (finalListmergedArray) => {
    const filteredData = finalListmergedArray.map((item) => {
      const includedText = new Map();
      const annotationList = item.annotationList.filter((content) => {
        if (includedText.has(content.annotText)) return false;
        includedText.set(content.annotText);
        return true;
      });
      return { ...item, annotationList };
    });
    return filteredData;
  };
  useEffect(() => {
    if (claimId === "supporting-claim") {
      if (
        referenceNumber &&
        referenceNumber.length !== 0 &&
        (selectText || eventType) &&
        typeof froalaInstance.current != "undefined" &&
        froalaInstance.current.editor &&
        froalaInstance.current.editor != "undefined"
      ) {
        // let replaceNum = referenceNumber.map(el => `<sup>[${el}]</sup>`);
        // if (prevProps.referenceNumber !== referenceNumber) { ------------Prevois code
        if (
          referenceNumber &&
          referenceNumber.length !== 0 &&
          eventType &&
          typeof froalaInstance.current != "undefined" &&
          froalaInstance.current.editor &&
          froalaInstance.current.editor != "undefined"
        ) {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            referenceNumber.forEach((el) => {
              const style = getRefSuperscriptStyle(el.color);
              const greenStyle = getRefSuperscriptStyle("r");

              //Remove old superscripts with the same value
              let oldText = froalaInstance.current.editor.html.get();
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}" >${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${greenStyle}">${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              froalaInstance.current.editor.html.set(oldText);
              froalaInstance.current.editor.selection.save();
              const html = froalaInstance.current.editor.html.get(true);
              froalaInstance.current.editor.selection.clear();
              props.handleInputChangeFootNote(oldText);
            });
            setEventType(undefined);
          }
        } else {
          let replaceNum = referenceNumber.map((el) => {
            const style = getRefSuperscriptStyle(el.color);

            const redStyle = getRefSuperscriptStyle("r");
            let oldText = froalaInstance.current.editor.html.get();
            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
              ""
            );

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
              ""
            );

            froalaInstance.current.editor.html.set(oldText);
            return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
          });
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            //process here
            let currentString = froalaInstance.current.editor.html.get();
            var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
            var suptext = [];
            words.forEach((item, index, array) => {
              let elIndex = words.indexOf(item);
              let regex = /(<([^>]+)>)/gi;
              let regex2 = /(\[.+\])/gi;
              let result = item.replace(regex, "").trim();
              let tempResult = result.replace(regex2, "").trim();
              const selectedTextForCompare = selectText.trim();
              if (tempResult === selectedTextForCompare) {
                if (elIndex < words.length - 1) {
                  let suptext1 = Object.values(
                    array[index + 1].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index + 1].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  let suptext2 = Object.values(
                    array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  suptext = suptext.concat(suptext1, suptext2);
                } else if (elIndex === words.length - 1) {
                  suptext = Object.values(
                    array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                } else {
                  return;
                }
              }
            });
            if (suptext) {
              replaceNum = replaceNum.filter((val) => !suptext.includes(val));
            }
            const textToBeReplaced = selectText.concat(
              suptext.toString().replaceAll(",", "")
            );
            suptext = suptext.concat(replaceNum);
            // const textToReplaceWith = selectText.concat(
            //   suptext.toString().replaceAll(",", "")
            // );
            const textToReplaceWith = "".concat(
              suptext.toString().replaceAll(",", "")
            );
            let currentClaimString = froalaInstance.current.editor.html.get();
            const froalaString = sortSuperScriptNumbers(
              currentClaimString,
              textToBeReplaced,
              textToReplaceWith,
              selectText
            );

            //let froalaString = froalaInstance.current.editor.html
            //.get()
            //.replace(textToBeReplaced, textToReplaceWith);
            froalaInstance.current.editor.html.set(froalaString);
            froalaInstance.current.editor.selection.save();
            props.handleInputChangeFootNote(froalaString);
            getSelectText("");
            const html = froalaInstance.current.editor.html.get(true);
            froalaInstance.current.editor.selection.clear();
          }
        }
      }
    } else if (claimId === "footnote-supportingclaim") {
      if (
        referenceNumber &&
        referenceNumber.length !== 0 &&
        (selectText || eventType) &&
        typeof footNoteFroalaInstance.current[footNoteIndex] != "undefined" &&
        footNoteFroalaInstance.current[footNoteIndex].editor &&
        footNoteFroalaInstance.current[footNoteIndex].editor != "undefined"
      ) {
        if (
          eventType &&
          referenceNumber &&
          referenceNumber.length !== 0 &&
          footNoteFroalaInstance.current[footNoteIndex].editor
        ) {
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            referenceNumber.forEach((el) => {
              const style = getRefSuperscriptStyle(el.color);

              const redStyle = getRefSuperscriptStyle("g");
              //Remove old superscripts with the same value
              let oldText =
                footNoteFroalaInstance.current[footNoteIndex].editor.html.get();

              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );
              oldText = oldText.replaceAll(
                `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
                `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`
              );

              footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
                oldText
              );
              footNoteFroalaInstance.current[
                footNoteIndex
              ].editor.selection.save();
              const html =
                footNoteFroalaInstance.current[footNoteIndex].editor.html.get(
                  true
                );
              footNoteFroalaInstance.current[
                footNoteIndex
              ].editor.selection.clear();
              let data = [...row[1].data];
              let index = data.findIndex(
                (obj, index) => index === footNoteIndex
              );
              data[index].footNoteText = oldText;
              props.saveFootNoteRefSup(data);
            });
            setEventType(undefined);
          }
        } else {
          // let replaceNum = referenceNumber.map(el => `<sup>[${el}]</sup>`);
          // if (prevProps.referenceNumber !== referenceNumber) {
          let replaceNum = referenceNumber.map((el) => {
            const style = getRefSuperscriptStyle(el.color);

            const redStyle = getRefSuperscriptStyle("r");
            let oldText =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
            //oldText = oldText.replaceAll(/\s/g, '')
            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}" >${el.refId}</sup>`,
              ""
            );

            oldText = oldText.replaceAll(
              `<sup data-docid="${el.veevaDocId}" style="${redStyle}">${el.refId}</sup>`,
              ""
            );

            footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
              oldText
            );
            return `<sup data-docid=${el.veevaDocId} style="${style}" >${el.refId}</sup>`;
          });
          if (!areRefArraysEqual(prevProps.referenceNumber, referenceNumber)) {
            //process here
            let currentString =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
            var words = currentString.replace(/<\/?span[^>]*>/g, "").split(" ");
            var suptext = [];
            words.forEach((item, index, array) => {
              let elIndex = words.indexOf(item);
              let regex = /(<([^>]+)>)/gi;
              let regex2 = /(\[.+\])/gi;
              let result = item.replace(regex, "").trim();
              let tempResult = result.replace(regex2, "").trim();
              const selectedTextForCompare = selectText.trim();
              if (tempResult === selectedTextForCompare) {
                if (elIndex < words.length - 1) {
                  let suptext1 = Object.values(
                    array[index + 1].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index + 1].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  let suptext2 = Object.values(
                    array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                  suptext = suptext.concat(suptext1, suptext2);
                } else if (elIndex === words.length - 1) {
                  suptext = Object.values(
                    array[index].match(/<sup(.*?)<\/sup>/g) != undefined
                      ? array[index].match(/<sup(.*?)<\/sup>/g)
                      : ""
                  );
                } else {
                  return;
                }
              }
            });
            if (suptext) {
              replaceNum = replaceNum.filter((val) => !suptext.includes(val));
            }
            const textToBeReplaced = selectText.concat(
              suptext.toString().replaceAll(",", "")
            );
            suptext = suptext.concat(replaceNum);
            // const textToReplaceWith = selectText.concat(
            //   suptext.toString().replaceAll(",", "")
            // );
            const textToReplaceWith = "".concat(
              suptext.toString().replaceAll(",", "")
            );
            let currentClaimString =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get();
            const froalaString = sortSuperScriptNumbers(
              currentClaimString,
              textToBeReplaced,
              textToReplaceWith,
              selectText
            );
            // let froalaString = footNoteFroalaInstance.current[
            //   footNoteIndex
            // ].editor.html
            //   .get()
            //   .replace(textToBeReplaced, textToReplaceWith);
            // let froalaString = footNoteFroalaInstance.current[footNoteIndex].editor.html
            //   .get()
            //   .replace(selectText, `${selectText}${replaceNum} `);
            footNoteFroalaInstance.current[footNoteIndex].editor.html.set(
              froalaString
            );
            footNoteFroalaInstance.current[
              footNoteIndex
            ].editor.selection.save();
            getSelectText("");
            const html =
              footNoteFroalaInstance.current[footNoteIndex].editor.html.get(
                true
              );
            footNoteFroalaInstance.current[
              footNoteIndex
            ].editor.selection.clear();
            let data = [...row[1].data];
            let index = data.findIndex((obj, index) => index === footNoteIndex);
            data[index].footNoteText = froalaString;
            props.saveFootNoteRefSup(data);
          }
        }
      }
    }
    setClaimId("");
  }, [referenceNumber]);
  //CODE- Custom hook to compare two Props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const handleFootNoteTextChangeDirect = (event, rowId) => {
    if (rowId > -1) {
      var data = [...row[1].data];
      var index = data.findIndex((obj, index) => index === rowId);
      data[index].footNoteText = event;
      //setSelectedFootNotes(data);
      props.footNoteDirectEditSave(data);
    }
    let temp = [];
    row[1].data.forEach((el) => temp.push(el.footNoteText));
    let newModel = temp.join();
    setSuperScriptRefData(newModel);
    setSelectedSuppRow(rowIndex);
    //handleDelete(newModel);
    //setFootNoteIndex(-1);
    setReferenceNumber([]);
    getSelectText("");
  };
  //Reference code Ends

  //Delete Functionality for footnote and Reference.
  const getSuperScriptsFromFootnote = () => {
    let uniqueFootNoteRef = [];
    let myArray = [];
    row[1].data.map((object) => {
      var para = object.footNoteText;
      if (para && para.includes("<sup")) {
        para = para.replaceAll(/\s/g, "");
        var Result = para.includes("<sup")
          ? Object.values(para.match(/<sup(.*?)<\/sup>/g))
          : Object.values(para.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    uniqueFootNoteRef = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return uniqueFootNoteRef;
  };

  useEffect(() => {
    let suppClaimCitation = getSuperScriptsFromSuppClaim();
    let tempFootnote = [];
    suppClaimCitation.forEach((el) => {
      const objFoot = row[1].data.filter((o) => o.footNoteIcon == el);
      tempFootnote.push.apply(tempFootnote, objFoot);
    });
    props.resetFootNotes(tempFootnote, rowIndex);
    setTimeout(() => {
      let uniqueFootNoteRef = [];
      if (row && row[1].data && row[1].data.length > 0) {
        uniqueFootNoteRef = getSuperScriptsFromFootnote();
      }
      let linkedFalse = row[3].data.filter((o) => o.veevaLinked == false);
      let finalResult = [...uniqueFootNoteRef, ...suppClaimCitation];
      let finalRef = [...new Set(finalResult)];
      let tempArray = [];
      finalRef.forEach((el) => {
        const obj = row[3].data.filter((o) => o.refId == el);
        tempArray.push.apply(tempArray, obj);
      });
      let tempResult = [];
      suppClaimCitation.forEach((element) => {
        if (parseInt(element)) {
          const obj = tempArray.find((o) => o.refId === element);
          tempResult.push(obj);
        }
      });
      let notPresentInSupportingClaimBox = tempArray.filter(
        checkForUnique(tempResult)
      );
      notPresentInSupportingClaimBox.forEach((element) => {
        let itemPosition = element.annotationList.findIndex(
          (obj) =>
            obj.rowIndex === rowIndex && obj.linkType === "supporting-claim"
        );
        if (itemPosition > -1) {
          element.annotationList.splice(itemPosition, 1);
        }
        return element;
      });
      let data = tempResult.concat(notPresentInSupportingClaimBox, linkedFalse);
      props.resetReference(data, rowIndex);
      //For Abbreviation delete
      let supportingClaimString = row[0]?.data[0]?.atomText.toLowerCase();
      let footNoteValue = [];
      tempFootnote.forEach((element) =>
        footNoteValue.push(element.footNoteText)
      );
      let footNoteTextString = footNoteValue.join().toLowerCase();
      let finalStringToBeChecked = footNoteTextString.concat(
        supportingClaimString
      );
      let abbreviationTitles = [];
      row[2] &&
        row[2].data &&
        row[2].data.forEach((element, index) => {
          let result = finalStringToBeChecked.search(
            element.title.toLowerCase().trim()
          );
          if (result < 0) {
            abbreviationTitles.push(element.title);
          }
        });
      abbreviationTitles &&
        abbreviationTitles.forEach((el) => {
          let index = row[2].data.findIndex((obj) => obj.title === el);
          row[2].data.splice(index, 1);
        });
      resetSupportingClaimAbbreviations(row[2].data, rowIndex);
    }, 0);
  }, [superScriptDataForClaim]);

  const checkForUnique = (otherArray) => {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other && other.refId == current.refId;
        }).length == 0
      );
    };
  };

  const handleDelete = (model) => {
    let modelText = model;
    let afterDeleted = Object.values(
      modelText.match(/<sup>(.*?)<\/sup>/g)
        ? modelText.match(/<sup>(.*?)<\/sup>/g)
        : ""
    );
    ///This some comment
    if (afterDeleted) {
      let filteredText = afterDeleted.map((word) =>
        word.replace(/(<([^>]+)>)/gi, "")
      );
      let FResult1 = filteredText.map((word) => word.replace(/[[\]]/g, ""));
      let unique = FResult1.filter((item, i, ar) => ar.indexOf(item) === i);
      setSuperScriptRefData(unique);
      setSelectedSuppRow(rowIndex);
    }
  };
  useEffect(() => {
    //if (superScriptRefData && superScriptRefData.length > 0){
    let uniqueFootNoteRef = getSuperScriptsFromSuppClaim();
    let citationFromFootNote = getSuperScriptsFromFootnote();
    let finalResult = [...uniqueFootNoteRef, ...citationFromFootNote];
    let linkedFalse = row[3].data.filter((o) => o.veevaLinked == false);
    let finalRef = [...new Set(finalResult)];
    let tempPrimary = [];
    finalRef.forEach((el) => {
      const obj = row[3].data.filter((o) => o.refId == el);
      tempPrimary.push.apply(tempPrimary, obj);
    });
    let tempResult = [];
    citationFromFootNote.forEach((element) => {
      if (parseInt(element)) {
        const obj = tempPrimary.find((o) => o.refId === element);
        tempResult.push(obj);
      }
    });
    let notPresentInSupportingFootNoteBox = tempPrimary.filter(
      checkForUnique(tempResult)
    );
    notPresentInSupportingFootNoteBox.forEach((element) => {
      let itemPosition = element.annotationList.findIndex(
        (obj) =>
          obj.rowIndex === rowIndex &&
          obj.linkType === "supporting-claim-footnote" &&
          obj.compIndex === footNoteIndex
      );
      if (itemPosition > -1) {
        element.annotationList.splice(itemPosition, 1);
      }
      return element;
    });
    let data = tempResult.concat(
      notPresentInSupportingFootNoteBox,
      linkedFalse
    );
    setFootNoteIndex(-1);
    //let data = tempPrimary.concat(linkedFalse)
    props.resetReference(data, rowIndex);
    //}
    //For Abbreviation delete
    let supportingClaimString = row[0]?.data[0]?.atomText.toLowerCase();
    let footNoteValue = [];
    row[1].data.forEach((element) => footNoteValue.push(element.footNoteText));
    let footNoteTextString = footNoteValue.join().toLowerCase();
    let finalStringToBeChecked = footNoteTextString.concat(
      supportingClaimString
    );
    let abbreviations = [...row[2].data];
    abbreviations.forEach((element, index) => {
      let result = finalStringToBeChecked.search(
        element.title.toLowerCase().trim()
      );
      if (result < 0) {
        abbreviations.splice(index, 1);
      }
    });
    resetSupportingClaimAbbreviations(abbreviations, rowIndex);
  }, [superScriptRefData]);

  const handleInputTextVariants = (atomTextChanged, rowIndex) => {
    let SuppBox = 0;

    const supClaimsAndVisuals = [...supportingClaimsAndVisuals];
    if (rowIndex > -1 && SuppBox > -1) {
      const rowToBeUpdated = supClaimsAndVisuals[rowIndex];
      const boxToBeUpdated = rowToBeUpdated && rowToBeUpdated.rowData[SuppBox];
      const newText = { atomText: atomTextChanged };
      if (atomTextChanged === "") {
        boxToBeUpdated.data.splice(currentClaimTextReplace, 1);
      } else {
        boxToBeUpdated.data.splice(currentClaimTextReplace, 1, newText);
      }
      setSupportingClaimsAndVisuals(supClaimsAndVisuals);
      setSuperScriptDataForClaim(uuidv4());
    }
    props.setDisplaySupportingAlert(false);
    setSelectedSuppRow(-1);
    setSelectedSuppBox(-1);
  };

  const getSuperScriptsFromSuppClaim = () => {
    let uniqueFootNoteRef = [];
    let myArray = [];
    row[0].data.map((object) => {
      var para = object.atomText;
      if (para && para.includes("<sup")) {
        para = para.replaceAll(/\s/g, "");
        var Result = para.includes("<sup")
          ? Object.values(para.match(/<sup(.*?)<\/sup>/g))
          : Object.values(para.match(/<p>(.*?)<\/p>/g));
        var Fresult = Result.map((word) =>
          word.replace(/(<([^>]+)>)/gi, "").replace(/[[\]]/g, "")
        );
        Fresult.forEach((item) => {
          if (item.includes(","))
            item.split(",").forEach((item1) => {
              myArray.push(item1);
            });
          else myArray.push(item);
        });
      }
    });
    uniqueFootNoteRef = myArray.filter((item, i, ar) => ar.indexOf(item) === i);
    return uniqueFootNoteRef;
  };
  //Code Ends
  //New Reference and Anchors code starts here
  const getNewRefId = () => {
    const linkedDocuments = props.referenceDocuments || [];
    if (linkedDocuments.length > 0) {
      let refList = [...linkedDocuments].map((doc) => {
        return doc.refId;
      });
      refList = refList.map((ref) => {
        return parseInt(ref);
      });
      const maxRefId = Math.max(...refList);
      if (maxRefId && maxRefId > 0) {
        return maxRefId + 1;
      }
    }
    return 1;
  };

  const updateSupportingClaimReference = (selectedReferences) => {
    const tempResult = [];

    selectedReferences.forEach((el) => {
      const obj = {
        id: el?.id,
        veevaReferenceName:
          moduleType === moduleTypes?.denovo ? el?.name : el?.name__v,
        refId: el?.refId,
        annotationList: el?.annotationList || [],
        veevaLinked: false,
      };
      tempResult.push(obj);
    });
    const refDocs = props.referenceDocuments;
    //for (var i = 0, len = row[3].data.length; i < len; i++) {
    for (var i = 0, len = refDocs.length; i < len; i++) {
      for (var j = 0, len2 = tempResult.length; j < len2; j++) {
        //if (row[3].data[i].id === tempResult[j].id) {
        if (refDocs[i].id === tempResult[j].id) {
          tempResult.splice(j, 1);
          len2 = tempResult.length;
        }
      }
    }
    const newArray = row[3].data.concat(tempResult);
    const refAnchorList = [];
    newArray.forEach((el, index) => {
      let refId = getNewRefId();
      if (selectedReferences.length > 0) {
        selectedReferences.find((ref, index) => {
          if (ref?.id === el?.id) {
            refId = getNewRefId() + index;
          }
        });
      }

      const obj = {
        id: el?.id,
        veevaReferenceName: el?.veevaReferenceName,
        refId: el?.refId || refId.toString(),
        annotationList: el?.annotationList || [],
        veevaLinked: el?.veevaLinked || false,
      };
      refAnchorList.push(obj);
    });
    props.updateSupportingClaimReference(refAnchorList);
    setShowModalAnchor(false);
  };

  const getReferenceDocument = (docId) => {
    const docs = props.referenceDocuments || [];
    if (docs.length > 0) {
      const foundDoc = docs.find((doc) => doc.id === docId);
      if (foundDoc) {
        return foundDoc;
      }
    }
    return undefined;
  };

  const saveAnchorSupportingClaim = (docId, anchorCoordinates) => {
    const temp1 = {
      pageNumber: anchorCoordinates.pageNumber,
      column: anchorCoordinates.column,
      para: anchorCoordinates.para,
      line: anchorCoordinates.line,
      anchorLinkFlag: anchorCoordinates.anchorLinkFlag,
      id: uuidv4(),
      linkType: props.anchorAddedIn ? props.anchorAddedIn.type : "",
      compIndex: props.anchorAddedIn ? props.anchorAddedIn.index : undefined,
      rowIndex: props.anchorAddedIn ? props.anchorAddedIn.rowIndex : undefined,
    };
    let data = [...row[3].data];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    if (index > -1) {
      data[index].veevaLinked = true;
      if (addOrModifyanchorData) {
        const indexToBeModified = data[index].annotationList.findIndex((el) => {
          return (
            el.compIndex === temp1.compIndex &&
            el.rowIndex === temp1.rowIndex &&
            el.linkType === temp1.linkType
          );
        });
        if (indexToBeModified > -1) {
          data[index].annotationList[indexToBeModified] = temp1;
        }
      } else {
        data[index].annotationList.push(temp1);
      }

      // data[index].annotationList = data[index].annotationList.filter(
      //   (coordinate, index, self) =>
      //     index ===
      //     self.findIndex(
      //       (t) =>
      //         t.pageNumber === coordinate.pageNumber &&
      //         t.column === coordinate.column &&
      //         t.para === coordinate.para &&
      //         t.line === coordinate.line
      //     )
      // );

      setReferenceNumber([
        {
          refId: data[index].refId,
          color: "r",
          veevaDocId: docId,
        },
      ]);
    } else {
      //Do linking and add anchor simultaneously
      let doc = getReferenceDocument(docId);
      if (!doc) return;
      doc["veevaLinked"] = true;
      doc["annotationList"] = [temp1];
      data = data.concat(doc);
      let result = [];
      let obj = data.find((o) => o.id === docId);
      result.push({ refId: obj.refId, color: "r", veevaDocId: docId });
      setReferenceNumber(result);
    }

    //result.push(obj.refId);
    setShowModal(false);
    //setReferenceNumber(result);
    setTempSuppRef(data);
    setAddOrModifyAnchorData(undefined);
    props.setAnchorAddedIn(undefined);
  };

  const skipAnchorSupportingClaim = (docId, anchorText, anchorAddedFrom) => {
    let data = [...row[3].data];
    data = [...data].filter((el) => el);
    let index = data.findIndex((obj, index) => obj.id === docId);
    //Check doc if already exist.
    if (index > -1) {
      data[index].veevaLinked = true;
      if (!anchorText) {
        let obj = data.find((o) => o.id === docId);
        let result = obj.annotationList;
        let itemPosition = result.findIndex((obj, index) => {
          if (anchorAddedFrom.index) {
            return (
              obj.compIndex === anchorAddedFrom.index &&
              obj.rowIndex === anchorAddedFrom.rowIndex &&
              obj.linkType === anchorAddedFrom.type
            );
          }
          return (
            obj.rowIndex === anchorAddedFrom.rowIndex &&
            obj.linkType === anchorAddedFrom.type
          );
        });
        if (itemPosition > -1) {
          result.splice(itemPosition, 1);
        }
        data[index].annotationList = result;
        setReferenceNumber([
          { refId: data[index].refId, color: "r", veevaDocId: docId },
        ]);
      }
    } else {
      //Adding new document from global list.
      let doc = getReferenceDocument(docId);
      if (!doc) return;
      doc["veevaLinked"] = true;
      doc["annotationList"] = [];
      data = data.concat(doc);
      let obj = data.find((o) => o.id === docId);
      setReferenceNumber([{ refId: obj.refId, color: "r", veevaDocId: docId }]);
    }
    setTempSuppRef(data);
    setShowModal(false);
    props.setAnchorAddedIn(undefined);
  };

  const handleSourceModuleToggle = (rowIndex, checked) => {
    setSupportingClaimsAndVisuals((prevSupportingClaimsAndVisuals) => {
      const newArray = [...prevSupportingClaimsAndVisuals];
      newArray[rowIndex] = {
        ...newArray[rowIndex],
        isMustUse: checked,
      };
      return newArray;
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;

    setSupportingClaimsAndVisuals((prevSupportingClaimsAndVisuals) => {
      const newArray = [...prevSupportingClaimsAndVisuals];
      newArray[rowIndex] = {
        ...newArray[rowIndex],
        usageGuidelines: value,
      };
      return newArray;
    });
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col d-flex">
          <img
            src={arrowpolygonRight}
            alt=""
            className={
              moduleType === moduleTypes.denovo
                ? "arrowCurrentRow arrowresuabletext mr-1"
                : "arrowCurrentRow  mr-1"
            }
          />
          <span className="catLabelTitle">
            {t("supportinClaim.supportTitle")}{" "}
            {String(supportingClaimPosition + 1)}
          </span>
          {moduleType === moduleTypes.denovo && (
            <>
              {" "}
              <span className="catLabelType verticalLine">|</span>
              <div className="disabled-musttoggle">
                Must Use
                <Switch
                  className="filterRightMargin  reusabletextToggle mr-2"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  checked={supportingClaimsAndVisuals[rowIndex]?.isMustUse}
                  onChange={(event) =>
                    handleSourceModuleToggle(rowIndex, event.target.checked)
                  }
                  //  disabled={sourceModuleToggle}
                />
              </div>
            </>
          )}
        </div>
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <DeleteIcon
            color="primary"
            onClick={(e) => {
              deleteSuppClaimRow(rowIndex, row[4] && row[4].id);
            }}
          />
        </div>
      </div>
      <Row className="mt-4 align-items-center">
        <Col
          id={`supporting-claim${rowIndex}`}
          className={
            props.displaySupportingAlert && row[0].isEmpty
              ? "selectclaimBlockAlert custonScrollBar"
              : "selectclaimBlock custonScrollBar"
          }
          // onChange={handleAlertRemove}
          onClick={() => {
            setFilterType("supporting-claim");
            setAtomsHeader("Claims");
            setSelectedSuppBox(0);
            setSelectedSuppRow(rowIndex);
            setCurrentTextReplace(
              moduleType === moduleTypes?.denovo
                ? currentClaimTextReplace
                : currentTextReplace
            );
            setCurrentClaimTextReplace(currentClaimTextReplace);
            setClaimId("supporting-claim");
          }}
        >
          {row &&
          row[0] &&
          row[0].data &&
          row[0].data.length !== 0 &&
          row[0].data !== "" ? (
            <span class="editorActive-heading d-block mt-2 mb-3">
              {t("supportinClaim.supportTitle")}
            </span>
          ) : (
            <span className="selectClaimBoxText">
              {t("supportinClaim.supportTitle")}
            </span>
          )}
          <div className="supportingClaimEditor">
            {moduleType === moduleTypes?.denovo ? (
              <SupportingClaimDenovo
                forwardedRef={froalaInstance}
                tag="textarea"
                selectedAtom={
                  box1.data[currentClaimTextReplace] &&
                  box1.data[currentClaimTextReplace].atomText
                }
                handleModelChange={(event) =>
                  handleInputTextVariants(event, rowIndex)
                }
                row={row}
                setCurrentClaimTextReplace={setCurrentClaimTextReplace}
                currentClaimTextReplace={currentClaimTextReplace}
                // addSupportingClaims={addSupportingClaims}
                rowIndex={rowIndex}
                box1={box1}
                supportingClaimsAndVisuals={supportingClaimsAndVisuals}
                setSupportingClaimsAndVisuals={setSupportingClaimsAndVisuals}
                setSuperScriptDataForClaim={setSuperScriptDataForClaim}
              />
            ) : (
              <SupportingClaim
                forwardedRef={froalaInstance}
                tag="textarea"
                selectedAtom={
                  box1.data[currentTextReplace] &&
                  box1.data[currentTextReplace].atomText
                }
                handleModelChange={(event) =>
                  handleAtomTextChange(event, rowIndex)
                }
                row={row}
              />
            )}
          </div>

          <ContextMenu
            onContextMenuItemSelected={onContextMenuItemSelected}
            handleFootNote={handleFootNote}
            handleAbbriviation={handleAbbriviation}
            handleCont={handleCont}
            footNoteId={"supporting-footnote"}
            abbriviationId={"supporting-abbreviationModal"}
            selectedTextPrimaryClaim={selectText}
            compClass={`supporting-claim${rowIndex}`}
            handleReference={handleReference}
            referenceId={"supporting-referenceModal"}
            setRightClickTriggered={setRightClickTriggered}
            contextMenuOptions={contextMenuOptions}
            setContextMenuOptions={setContextMenuOptions}
            onSuperScriptClick={onSuperScriptOptionClick}
          ></ContextMenu>

          {/* {row && row[0] && row[0].data && row[0].data.length === 0 ? (
            <span className="selectClaimBoxText">
              {t("supportinClaim.supportTitle")}
            </span>
          ) : (
            ""
          )} */}
        </Col>

        <Col
          className="selectclaimRefBlock custonScrollBar"
          id={`footnote-supportingclaim${rowIndex}`}
          onClick={() => {
            setSelectedSuppBox(1);
            setSelectedSuppRow(rowIndex);
          }}
        >
          {row && row[1] && row[1].data && row[1].data.length !== 0 ? (
            <span class="editorActive-heading d-block mt-2 mb-3">
              {t("supportinClaim.descFootNotes")}
            </span>
          ) : (
            <span className="selectClaimBoxText">
              {t("supportinClaim.descFootNotes")}
            </span>
          )}
          <div className="selectclaimBlockFoot custonScrollBar">
            {row[1].data.map((footnote, index) => {
              return (
                <div
                  className="d-flex"
                  onClick={(e) => {
                    setFootNoteIndex(index);
                    setClaimId("footnote-supportingclaim");
                  }}
                  onContextMenu={(e) => {
                    setFootNoteIndex(index);
                    setClaimId("footnote-supportingclaim");
                  }}
                >
                  <div className="footBoxIndex">
                    <span className="d-inline-block iconDisplay">
                      {footnote.footNoteIcon}
                    </span>
                  </div>
                  <SupportingClaim
                    className="footNoteLabelN"
                    forwardedRef={(el) =>
                      (footNoteFroalaInstance.current[index] = el)
                    }
                    tag="textarea"
                    handleModelChange={(event) =>
                      handleFootNoteTextChangeDirect(event, index)
                    }
                    selectedAtom={footnote.footNoteText}
                  />
                  {/* <div className="footNoteLabelN">
                    { parse(footnote.footNoteText)}
                </div> */}
                </div>
              );
            })}
          </div>
          <ContextMenu
            onContextMenuItemSelected={onContextMenuItemSelected}
            handleFootNote={handleFootNote}
            handleAbbriviation={handleAbbriviation}
            handleCont={handleContFootNote}
            footNoteId={"supporting-footnote"}
            abbriviationId={"supporting-abbreviationModal"}
            selectedTextPrimaryClaim={selectText}
            compClass={`footnote-supportingclaim${rowIndex}`}
            disableClass={`disable-FootNote`}
            handleReference={handleFootnoteReference}
            referenceId={"supporting-referenceModal"}
            setRightClickTriggered={setRightClickTriggered}
            contextMenuOptions={contextMenuOptions}
            setContextMenuOptions={setContextMenuOptions}
            onSuperScriptClick={onSuperScriptOptionClick}
          ></ContextMenu>
        </Col>
        <Col className="selectclaimRefBlock">
          {row && row[2] && row[2].data && row[2].data.length !== 0 ? (
            <span class="editorActive-heading d-block mt-2 mb-3">
              Abbreviations
            </span>
          ) : (
            <span className="selectClaimBoxText">Abbreviations</span>
          )}
          <div className="selectclaimBlockRef custonScrollBar">
            {row[2].data.map((x) => (
              <span>
                <p
                  className="mb-1"
                  style={{
                    fontSize: "12px",
                    color: "#666666",
                    marginTop: "12px",
                  }}
                >
                  {x.title && parse(x.title)}
                </p>
                <p className="mb-1" style={{ paddingBottom: "" }}>
                  {x.abbrText && parse(x.abbrText)}
                </p>
              </span>
            ))}
          </div>
        </Col>

        <Col className="selectclaimRefBlock">
          <div
            className=" float fa fa-plus my-float"
            onClick={(e) => {
              setSelectedSuppBox(3);
              setSelectedSuppRow(rowIndex);
              handleClickAnchor();
            }}
          ></div>
          <div>
            <span class="refStar">*</span>
            {row && row[3] && row[3].data && row[3].data.length !== 0 ? (
              <span class="editorActive-heading d-block mt-2 mb-3">
                {t("supportinClaim.refAnnotations")}
              </span>
            ) : (
              <span className="selectClaimBoxText">
                {t("supportinClaim.refAnnotations")}
              </span>
            )}
          </div>
          <div className="selectclaimBlockRef custonScrollBar">
            {row[3].data
              .sort(function (a, b) {
                return a.refId - b.refId;
              })
              .map((x, index) => {
                if (!x) {
                  return <></>;
                }
                let color = "black";
                // x.annotationList.length != 0 &&
                // x.annotationList[0].annotText != "" &&
                // x.veevaLinked === true
                //   ? "green"
                //   : "red";
                return (
                  <div className="d-flex">
                    <div className="referenceBoxIndex">
                      {x.annotationList.length !== 0 ||
                      x.veevaLinked === true ? (
                        <span
                          className="d-inline-block refNumberDisplay"
                          style={{ color: color }}
                        >
                          {x.refId && x.refId}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="referenceBoxLabelN">
                      {x.veevaReferenceName &&
                        parse(x.veevaReferenceName && x.veevaReferenceName)}
                      <span
                        style={{
                          color: "#666666",
                          paddingTop: "15px",
                        }}
                      >
                        {x.annotationList &&
                          x.annotationList.map((list, index) => {
                            // if (rowIndex !== list.rowIndex) {
                            //   return <></>;
                            // }
                            return (
                              <div key={index}>
                                Page no {list.pageNumber}/ Column {list.column}/
                                Para {list.para}/ Line {list.line}
                              </div>
                            );
                          })}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        {moduleType === moduleTypes?.denovo && (
          <Col
            id="suppclaim"
            className="selectclaimBlock usageInformation col-md-2"
          >
            {/* {row && row[0]?.data?.length !== 0 && row[0]?.data !== "" ? ( */}
            <span class="editorActive-heading d-block pt-2 mt-2 mb-3">
              {t("reusableText.usageInstructions")}
            </span>
            <TextField
              className="usageInfoinput"
              id="moduleName"
              variant="outlined"
              fullWidth={true}
              placeholder="Type here..."
              //inputProps={{ style: { textAlign: "left", paddingTop: "2px" } }}
              InputProps={{
                className: "usageInfo",
              }}
              multiline
              rows={2}
              onChange={handleChange}
              value={supportingClaimsAndVisuals[rowIndex]?.usageGuidelines}
            />
            {/* ) : (
            ""
          )} */}
            <div className="supportingClaimEditor"></div>
          </Col>
        )}
      </Row>
      {showFootNote ? (
        <FooteNotesModal
          handleFootNoteMOdalClose={handleFootNoteMOdalClose}
          handleFootNoteTextChange={handleFootNoteInputChange}
          selectedText={footNoteText}
          addBtnStatus={addBtnStatus}
          handleAddFootNote={handleAddFootNote}
          selectedFootNotes={selectedFootNotes}
          getIconValue={getIconValue}
          setSelectedSuppBox={setSelectedSuppBox}
          setSelectedSuppRow={setSelectedSuppRow}
          rowIndex={rowIndex}
          addIconStatus={addIconStatus}
          handleFootNoteCheckBox={handleFootNoteCheckBox}
          handleAddFootNoteCheckBox={handleAddFootNoteCheckBox}
          setIconValueToInitial={setIconValueToInitial}
          setAllToInitial={setAllToInitial}
        />
      ) : (
        " "
      )}
      {isAbbr == true ? (
        <Abbreviation
          handleCloseAbbreviation={handleCloseAbbreviation}
          selectedText={footNoteText}
          handleArrAbbr={handleArrAbbr}
          //arrRadioData={arrAbbr}
          arrRadioData={props.masterAbbrs}
          isAbbrDisable={isAbbrDisable}
          setIsAbbrDisable={setIsAbbrDisable}
          setSelectedSuppBox={setSelectedSuppBox}
          setSelectedSuppRow={setSelectedSuppRow}
          rowIndex={rowIndex}
          selectedTextPrimaryClaim={selectedTextPrimaryClaimValue}
          abbrStatus={abbrStatus1}
          abbrNoteText={abbrNoteText}
          handleAbbrDataUpdate={handleAbbrDataUpdate}
        />
      ) : (
        ""
      )}
      {/* {showModal ? (
        <ReferenceContainer
          handleClose={handleCloseRefModal}
          referenceMasterList={referenceMasterList}
          updateInMasterList={updateInMasterList}
          refAddStatus={refAddStatus}
          refText={refText}
          setSelectedSuppBox={setSelectedSuppBox}
          setSelectedSuppRow={setSelectedSuppRow}
          rowIndex={rowIndex}
          id="supporting-claim"
        />
      ) : (
        ""
      )} */}
      {showModal ? (
        <ReferenceContainer
          handleClose={handleCloseRefModal}
          referenceList={props.referenceList} //{row[3].data}
          setSelectedSuppBox={setSelectedSuppBox}
          setSelectedSuppRow={setSelectedSuppRow}
          rowIndex={rowIndex}
          id="supporting-claim"
          saveAnchorSupportingClaim={saveAnchorSupportingClaim}
          referenceDocuments={props.referenceDocuments || []}
          skipAnchorSupportingClaim={skipAnchorSupportingClaim}
          anchorAddedIn={anchorAddedIn}
          addOrModifyanchorData={addOrModifyanchorData}
          sourceType={sourceType}
          findSupportingClaimOrVisualPosition={
            findSupportingClaimOrVisualPosition
          }
          moduleType={moduleType}
        />
      ) : (
        ""
      )}
      {showModalAnchor ? (
        <ReferenceAnchor
          brandName={props?.brandName}
          getModuledetails={getModuledetails}
          handleClickAnchor={handleClickAnchor}
          handleCloseReferenceAnchor={handleCloseReferenceAnchor}
          setFilterType={setFilterType}
          reference={refText}
          filterType="supporting-referenceModal"
          refAnchorId="supportingClaim-ReferenceAnchor"
          updateSupportingClaimReference={updateSupportingClaimReference}
          sourceType={sourceType}
          moduleType={moduleType}
        />
      ) : (
        ""
      )}
      <div className="d-none" ref={referencesValid}></div>
    </>
  );
};
export default AddSuppClaimRow;
