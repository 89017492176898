import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import "../../styles/components/ExistingTatics.scss";
import "../../styles/CustomToastify.scss";
import { getBrandsAndCampaigns } from "../../services/apis";
import CheckBox from "../../Uitls/CheckBox";
import searchIcon from "../../images/masterTacticSearchIcon.svg";
import CustomModal from "../../components/customModal";
const ExistingTacticsFilter = (props) => {
  const { t } = useTranslation();
  const {
    filteredBrands,
    setFilteredBrands,
    setFilteredList,
    setFilteredChannels,
    filteredChannels,
    setFilteredChannelList,
    filteredMarket,
    setFilteredMarket,
    setFilteredMarketList,
    isModularisedTacticsSelected,
    setIsModularisedTacticsSelected,
    isLocalizedModulesSelected,
    setIsLocalizedModulesSelected,
    setBrandsAndCampaigns,
    campaigns,
    filteredCampaigns,
    setFilteredCampaigns,
    setCampaignFilterList,
    resetFilters,
    brandsList,
    marketSearchTeram,
    setMarketSearchTeram,
    filteredLanguageList,
    setFilteredLanguages,
    brandErrorFlag,
    clearAllSearch,
  } = props;
  const [activePanel, setActivePanel] = useState("");
  const [alertForBrands, setAlertForBrands] = useState(false);
  const [filterPanelActive, setFilterPanelActive] = useState();
  const [brandErrorClass, setBrandError] = useState("");
  useEffect(() => {
    if (brandErrorFlag) {
      setBrandError("alert-error");
      setTimeout(() => {
        setBrandError("");
      }, 3000);
    }
  }, [brandErrorFlag]);
  const selectedPanel = (selectedIndex) => {
    if (activePanel === selectedIndex) {
      setActivePanel("");
    } else {
      setActivePanel(selectedIndex);
    }
  };
  const getBrandsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useEffect(() => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        getBrandsErr(errCoponent);
      } else {
        response.data.forEach((brands) => {
          const brandsName = brands.brandName;
          brandsList.push(brandsName);
        });
        setBrandsAndCampaigns(response.data);
      }
    });
  }, []);
  useEffect(() => {
    let newFilteredCampaigns = filteredCampaigns.filter(function (entry1) {
      return campaigns.some(function (entry2) {
        return entry1 === entry2;
      });
    });
    setFilteredCampaigns(newFilteredCampaigns);
    applyFilters(
      filteredBrands,
      filteredChannels,
      filteredMarket,
      newFilteredCampaigns,
      filteredLanguageList
    );
  }, [campaigns]);

  useEffect(() => {
    setActivePanel("");
  }, [isModularisedTacticsSelected]);

  const checkBoxData = [
    {
      id: "a",
      category: "Product/Brand",
      subCategory: brandsList || [],
    },
    {
      id: "c",
      category: "Channel",
      subCategory:
        isModularisedTacticsSelected || isLocalizedModulesSelected
          ? [
              "Detailing-Aid",
              "Email",
              "Banner",
              "Social Media Advertisements",
              "Print",
              "Alert",
            ]
          : [],
    },
    {
      id: "d",
      category: "Market",
      subCategory: [],
    },
    {
      id: "e",
      category: "Campaign",
      subCategory: campaigns || [],
    },
    {
      id: "f",
      category: "Language",
      subCategory: props.languageDataSource || [],
    },
  ];
  const people =
    props.marketDataSource &&
    props.marketDataSource.map((item) => item.displayName);

  const handleChangeMarket = (event) => {
    setMarketSearchTeram(event.target.value);
  };

  const results = !marketSearchTeram
    ? []
    : people.filter((person) => {
        return person.toLowerCase().includes(marketSearchTeram.toLowerCase());
      });

  const applyFilters = (brands, channels, market, campaigns, languages) => {
    setFilteredList(brands);
    if (setFilteredChannelList) {
      setFilteredChannelList(channels);
    }

    setFilteredMarketList(market);
    setCampaignFilterList(campaigns);
    setFilteredLanguages(languages);
  };

  const handleChange = (e) => {
    const type = e.target.name;
    if (e.target.checked) {
      switch (type) {
        case "Product/Brand":
          const newFilteredBrands = isLocalizedModulesSelected
            ? [e.target.value]
            : [...filteredBrands, e.target.value];
          setFilteredBrands(newFilteredBrands);
          applyFilters(
            newFilteredBrands,
            filteredChannels,
            filteredMarket,
            filteredCampaigns,
            filteredLanguageList
          );
          break;
        case "Channel":
          const newFilteredChannels = [...filteredChannels, e.target.value];
          setFilteredChannels(newFilteredChannels);
          applyFilters(
            filteredBrands,
            newFilteredChannels,
            filteredMarket,
            filteredCampaigns,
            filteredLanguageList
          );
          break;
        case "Market":
          const newFilteredMarket = [...filteredMarket, e.target.value];
          setFilteredMarket(newFilteredMarket);
          applyFilters(
            filteredBrands,
            filteredChannels,
            newFilteredMarket,
            filteredCampaigns,
            filteredLanguageList
          );
          break;
        case "Campaign":
          const newFilteredCampaigns = isLocalizedModulesSelected
            ? [e.target.value]
            : [...filteredCampaigns, e.target.value];
          setFilteredCampaigns(newFilteredCampaigns);
          applyFilters(
            filteredBrands,
            filteredChannels,
            filteredMarket,
            newFilteredCampaigns,
            filteredLanguageList
          );
          break;
        case "Language":
          // const newFilteredLanguages = [
          //   ...filteredLanguageList,
          //   e.target.value,
          // ];
          setFilteredLanguages([e.target.value]);
          applyFilters(
            filteredBrands,
            filteredChannels,
            filteredMarket,
            filteredCampaigns,
            [e.target.value]
          );
          break;
        default:
          break;
      }
    } else {
      const value = e.target.value;

      const position = filteredChannels.indexOf(value);
      if (position > -1) {
        const newFilteredChannels = [...filteredChannels];
        newFilteredChannels.splice(position, 1);
        setFilteredChannels(newFilteredChannels);
        applyFilters(
          filteredBrands,
          newFilteredChannels,
          filteredMarket,
          filteredCampaigns,
          filteredLanguageList
        );
      }
      const index = filteredBrands.indexOf(value);
      if (index > -1) {
        const newFilteredBrands = [...filteredBrands];
        newFilteredBrands.splice(index, 1);
        setFilteredBrands(newFilteredBrands);
        applyFilters(
          newFilteredBrands,
          filteredChannels,
          filteredMarket,
          filteredCampaigns,
          filteredLanguageList
        );
      }
      const positionMarket = filteredMarket.indexOf(value);
      if (positionMarket > -1) {
        let newFilteredMarket = [...filteredMarket].filter((market) => {
          return market !== value;
        });
        setFilteredMarket(newFilteredMarket);
        applyFilters(
          filteredBrands,
          filteredChannels,
          newFilteredMarket,
          filteredCampaigns,
          filteredLanguageList
        );
      }

      const campaignIndex = filteredCampaigns.indexOf(value);
      if (campaignIndex > -1) {
        const newFilteredCampaigns = [...filteredCampaigns];
        newFilteredCampaigns.splice(campaignIndex, 1);
        setFilteredCampaigns(newFilteredCampaigns);
        applyFilters(
          filteredBrands,
          filteredChannels,
          filteredMarket,
          newFilteredCampaigns,
          filteredLanguageList
        );
      }

      const languageIndex = filteredLanguageList.indexOf(value);
      if (languageIndex > -1) {
        const newFilteredLanguages = [...filteredLanguageList];
        newFilteredLanguages.splice(languageIndex, 1);
        setFilteredLanguages(newFilteredLanguages);
        applyFilters(
          filteredBrands,
          filteredChannels,
          filteredMarket,
          filteredCampaigns,
          newFilteredLanguages
        );
      }
    }
  };

  const handleModal = () => {
    setAlertForBrands(false);
  };

  const dialogMessage = () => {
    return "Please select Product/Brand";
  };

  const onSourceSelected = (event) => {
    const targetId = event.target.id;

    if (targetId) {
      if (targetId === "modularised-tactics") {
        setFilterPanelActive(null);
        setIsModularisedTacticsSelected(true);
        setIsLocalizedModulesSelected(false);
      } else if (targetId === "localized-modules") {
        setFilterPanelActive(null);
        setIsModularisedTacticsSelected(false);
        setIsLocalizedModulesSelected(true);
      } else {
        setFilterPanelActive(null);
        setIsModularisedTacticsSelected(false);
        setIsLocalizedModulesSelected(false);
        setTimeout(() => {
          setFilterPanelActive("");
        }, 0);
      }
    }
  };
  const renderTacticsTypeFilter = () => {
    return (
      <div className="tactic-source">
        <h1 className="filterBy">{t("tacticSource.source")}</h1>
        <div className="contentBlock form-check">
          <input
            id="modularised-tactics"
            type="radio"
            name="source"
            className="form-check-input"
            value="modularised-tactics"
            checked={isModularisedTacticsSelected}
            onChange={(e) => {
              onSourceSelected(e);
            }}
          />
          <label htmlFor="modularised-tactics" className="form-check-label">
            {t("tacticSource.modularisedTactics")}
          </label>
        </div>
        <div className="contentBlock form-check">
          <input
            id="harvested-tactics"
            type="radio"
            name="source"
            className="form-check-input"
            value="harvested-tactics"
            onChange={(e) => {
              onSourceSelected(e);
            }}
          />
          <label htmlFor="harvested-tactics" className="form-check-label">
            {t("tacticSource.harvestedTactics")}
          </label>
        </div>
        <div className="contentBlock form-check">
          <input
            id="localized-modules"
            type="radio"
            name="source"
            className="form-check-input"
            value="harvested-tactics"
            onChange={(e) => {
              onSourceSelected(e);
            }}
          />
          <label htmlFor="localized-modules" className="form-check-label">
            {t("tacticSource.localized")}
          </label>
        </div>

        <div className="lineHorizontal"></div>
      </div>
    );
  };

  const shouldDisableFilterResetButton = () => {
    if (
      filteredBrands.length > 0 ||
      filteredChannels.length > 0 ||
      filteredMarket.length > 0 ||
      (filteredLanguageList && filteredLanguageList.length > 0)
    ) {
      return false;
    }
    return true;
  };

  const shouldTogglebeDisabled = (filterKey) => {
    if (
      !isModularisedTacticsSelected &&
      !isLocalizedModulesSelected &&
      filterKey !== "a"
    ) {
      return true;
    }

    return false;
  };

  const renderDenovoSourceFilter = () => {
    return (
      <div className="tactic-source">
        <h1 className="filterBy">{t("tacticSource.source")}</h1>
        <div className="contentBlock form-check">
          <input
            id="localized-modules"
            type="radio"
            name="source"
            className="form-check-input"
            value="harvested-tactics"
            onClick={(e) => {
              setIsLocalizedModulesSelected(!e.target.checked);
              e.target.checked = !e.target.checked;
              if (!e.target.checked) {
                clearAllSearch();
              }
            }}
            onChange={(e) => {
              setIsLocalizedModulesSelected(!e.target.checked);
              e.target.checked = !e.target.checked;
            }}
          />
          <label htmlFor="localized-modules" className="form-check-label">
            {t("tacticSource.localized")}
          </label>
        </div>

        <div className="lineHorizontal"></div>
      </div>
    );
  };

  return (
    <>
      {props.showSource === true
        ? renderDenovoSourceFilter()
        : renderTacticsTypeFilter()}

      <h1 className="filterBy">Filter By</h1>
      <Accordion key={filterPanelActive}>
        {props.showChannel === true
          ? checkBoxData
              .filter((item) => item.id !== "c")
              .map((checkbox) => {
                let activeCount = 0;
                if (checkbox.category === "Market") {
                  activeCount = filteredMarket.length;
                }
                checkbox.subCategory.forEach((subCategory) => {
                  if (filteredBrands.indexOf(subCategory) > -1) {
                    activeCount++;
                  }
                  if (filteredChannels.indexOf(subCategory) > -1) {
                    activeCount++;
                  }

                  if (filteredCampaigns.indexOf(subCategory) > -1) {
                    activeCount++;
                  }
                  if (
                    filteredLanguageList &&
                    filteredLanguageList.findIndex((el) => {
                      return el === `${subCategory.language}`;
                    }) > -1
                  ) {
                    activeCount++;
                  }
                });
                return (
                  <div key={checkbox.id}>
                    <div
                      className={
                        brandErrorFlag &&
                        (checkbox.category === "Product/Brand" ||
                          checkbox.category === "Campaign")
                          ? `${brandErrorClass} accordionTitle`
                          : "accordionTitle"
                      }
                    >
                      <Accordion.Toggle
                        variant="link"
                        eventKey={checkbox.id}
                        onClick={() => {
                          selectedPanel(checkbox.id);
                        }}
                        disabled={
                          !isLocalizedModulesSelected &&
                          checkbox.category === "Language"
                            ? true
                            : false
                        }
                      >
                        <span
                          className={
                            activePanel === checkbox.id
                              ? "displayActiveIcon activCategory"
                              : "displayActiveIcon inActivCategory"
                          }
                        >
                          {activePanel === checkbox.id ? "-" : "+"}
                        </span>
                        {
                          <span
                            className={
                              activePanel === checkbox.id
                                ? "activCategory"
                                : "inActivCategory"
                            }
                          >
                            {checkbox.category}
                          </span>
                        }
                        {activeCount === 0 ? "" : ` (${activeCount})`}
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={checkbox.id}>
                      <Card.Body>
                        {checkbox.category !== "Campaign" &&
                          checkbox.category !== "Language" &&
                          checkbox.subCategory.map((subCategory, index) => {
                            let isChecked;
                            if (checkbox.category === "Product/Brand") {
                              isChecked =
                                filteredBrands.indexOf(subCategory) > -1;
                            } else {
                              isChecked =
                                filteredChannels.indexOf(subCategory) > -1;
                            }

                            return (
                              <div
                                className="contentBlock form-check"
                                key={index}
                              >
                                {isLocalizedModulesSelected && (
                                  <>
                                    <input
                                      id={subCategory}
                                      type="radio"
                                      name={checkbox.category}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={`${subCategory}`}
                                      checked={isChecked}
                                    />
                                    <label
                                      htmlFor={subCategory}
                                      className="form-check-label"
                                    >
                                      {`${subCategory}`}
                                    </label>
                                  </>
                                )}
                                {!isLocalizedModulesSelected && (
                                  <CheckBox
                                    label={subCategory}
                                    isChecked={isChecked}
                                    name={checkbox.category}
                                    handleChange={(e) => {
                                      handleChange(e);
                                    }}
                                    labelClassName="form-check-label"
                                    checkboxClassName="form-check-input"
                                  />
                                )}
                              </div>
                            );
                          })}

                        {/* For Market */}
                        {checkbox.category === "Market" && (
                          <>
                            <div className="marketTacticsSearch searchTactic">
                              <div className="d-flex">
                                <img
                                  src={searchIcon}
                                  alt=""
                                  className="searchIconTactic"
                                />
                                <input
                                  id="search-market"
                                  type="text"
                                  placeholder="Search Market"
                                  className="searchInputTactic"
                                  value={marketSearchTeram}
                                  onChange={handleChangeMarket}
                                />
                              </div>
                            </div>
                            <div
                              className={results.length > 0 ? " masterBox" : ""}
                            >
                              <div className="marketCheckBox custonScrollBar">
                                {results.map((item) => {
                                  let isChecked =
                                    filteredMarket.indexOf(item) > -1;
                                  return (
                                    <div className="form-check">
                                      <CheckBox
                                        label={item}
                                        isChecked={isChecked}
                                        name="Market"
                                        handleChange={(e) => {
                                          handleChange(e);
                                        }}
                                        labelClassName="form-check-label"
                                        checkboxClassName="form-check-input"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {/* for campaign */}
                        {checkbox.category === "Campaign" &&
                          checkbox.subCategory.map((subCategory, index) => {
                            let isChecked;

                            isChecked =
                              filteredCampaigns.indexOf(subCategory) > -1;

                            return (
                              <div
                                className="contentBlock form-check"
                                key={index}
                              >
                                {isLocalizedModulesSelected && (
                                  <>
                                    <input
                                      id={subCategory}
                                      type="radio"
                                      name={checkbox.category}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={`${subCategory}`}
                                      checked={isChecked}
                                    />
                                    <label
                                      htmlFor={subCategory}
                                      className="form-check-label"
                                    >
                                      {`${subCategory}`}
                                    </label>
                                  </>
                                )}
                                {!isLocalizedModulesSelected && (
                                  <CheckBox
                                    label={subCategory}
                                    isChecked={isChecked}
                                    name={checkbox.category}
                                    handleChange={(e) => {
                                      handleChange(e);
                                    }}
                                    labelClassName="form-check-label"
                                    checkboxClassName="form-check-input"
                                  />
                                )}
                              </div>
                            );
                          })}

                        {isLocalizedModulesSelected &&
                          checkbox.category === "Language" &&
                          checkbox.subCategory.map((subCategory, index) => {
                            let isChecked;
                            isChecked =
                              filteredLanguageList.findIndex((el) => {
                                return (
                                  el === `${subCategory.language}`
                                  // el ===
                                  // `${subCategory.languageCode} - ${subCategory.language}`
                                );
                              }) > -1;
                            return (
                              <div
                                className="contentBlock form-check"
                                key={index}
                              >
                                <input
                                  id={subCategory.icbCode}
                                  type="radio"
                                  name="Language"
                                  className="form-check-input"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={`${subCategory.language}`}
                                  checked={isChecked}
                                />
                                <label
                                  htmlFor="language"
                                  className="form-check-label"
                                >
                                  {`${subCategory.language}`}
                                  {/* {`${subCategory.languageCode} - ${subCategory.language}`} */}
                                </label>
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Accordion.Collapse>
                    <div className="lineHorizontal"></div>
                  </div>
                );
              })
          : checkBoxData.map((checkbox) => {
              let activeCount = 0;
              if (checkbox.category === "Market") {
                activeCount = filteredMarket.length;
              }
              checkbox.subCategory.forEach((subCategory) => {
                if (filteredBrands.indexOf(subCategory) > -1) {
                  activeCount++;
                }
                if (filteredChannels.indexOf(subCategory) > -1) {
                  activeCount++;
                }

                if (filteredCampaigns.indexOf(subCategory) > -1) {
                  activeCount++;
                }
                if (
                  filteredLanguageList.findIndex((el) => {
                    return (
                      el === `${subCategory.language}`
                      // el ===
                      // `${subCategory.languageCode} - ${subCategory.language}`
                    );
                  }) > -1
                ) {
                  activeCount++;
                }
              });
              return (
                <div key={checkbox.id}>
                  <div
                    className={
                      brandErrorFlag &&
                      (checkbox.category === "Product/Brand" ||
                        checkbox.category === "Campaign")
                        ? `${brandErrorClass} accordionTitle`
                        : "accordionTitle"
                    }
                  >
                    <Accordion.Toggle
                      variant="link"
                      eventKey={checkbox.id}
                      onClick={() => {
                        selectedPanel(checkbox.id);
                      }}
                      disabled={shouldTogglebeDisabled(checkbox.id)}
                    >
                      <span
                        className={
                          activePanel === checkbox.id
                            ? "displayActiveIcon activCategory"
                            : "displayActiveIcon inActivCategory"
                        }
                      >
                        {activePanel === checkbox.id ? "-" : "+"}
                      </span>
                      {
                        <span
                          className={
                            activePanel === checkbox.id
                              ? "activCategory"
                              : "inActivCategory"
                          }
                        >
                          {checkbox.category}
                        </span>
                      }
                      {activeCount === 0 ? "" : ` (${activeCount})`}
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey={checkbox.id}>
                    <Card.Body>
                      {checkbox.category !== "Campaign" &&
                        checkbox.category !== "Language" &&
                        checkbox.subCategory.map((subCategory, index) => {
                          let isChecked;
                          if (checkbox.category === "Product/Brand") {
                            isChecked =
                              filteredBrands.indexOf(subCategory) > -1;
                          } else {
                            isChecked =
                              filteredChannels.indexOf(subCategory) > -1;
                          }

                          return (
                            <div
                              className="contentBlock form-check"
                              key={index}
                            >
                              {isLocalizedModulesSelected &&
                                checkbox.category === "Product/Brand" && (
                                  <>
                                    <input
                                      id={subCategory}
                                      type="radio"
                                      name={checkbox.category}
                                      className="form-check-input"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={`${subCategory}`}
                                      checked={isChecked}
                                    />
                                    <label
                                      htmlFor={subCategory}
                                      className="form-check-label"
                                    >
                                      {`${subCategory}`}
                                    </label>
                                  </>
                                )}
                              {(!isLocalizedModulesSelected ||
                                checkbox.category === "Channel") && (
                                <CheckBox
                                  label={subCategory}
                                  isChecked={isChecked}
                                  name={checkbox.category}
                                  handleChange={(e) => {
                                    handleChange(e);
                                  }}
                                  labelClassName="form-check-label"
                                  checkboxClassName="form-check-input"
                                />
                              )}
                            </div>
                          );
                        })}

                      {/* For Market */}
                      {checkbox.category === "Market" &&
                        (isModularisedTacticsSelected ||
                          isLocalizedModulesSelected) && (
                          <>
                            <div className="marketTacticsSearch searchTactic">
                              <div className="d-flex">
                                <img
                                  src={searchIcon}
                                  alt=""
                                  className="searchIconTactic"
                                />
                                <input
                                  id="search-market"
                                  type="text"
                                  placeholder="Search Market"
                                  className="searchInputTactic"
                                  value={marketSearchTeram}
                                  onChange={handleChangeMarket}
                                />
                              </div>
                            </div>
                            <div
                              className={results.length > 0 ? " masterBox" : ""}
                            >
                              <div className="marketCheckBox custonScrollBar">
                                {results.map((item) => {
                                  let isChecked =
                                    filteredMarket.indexOf(item) > -1;
                                  return (
                                    <div className="form-check">
                                      <CheckBox
                                        label={item}
                                        isChecked={isChecked}
                                        name="Market"
                                        handleChange={(e) => {
                                          handleChange(e);
                                        }}
                                        labelClassName="form-check-label"
                                        checkboxClassName="form-check-input"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      {/* for campaign */}
                      {checkbox.category === "Campaign" &&
                        checkbox.subCategory.map((subCategory, index) => {
                          let isChecked;
                          isChecked =
                            filteredCampaigns.indexOf(subCategory) > -1;

                          return (
                            <div
                              className="contentBlock form-check"
                              key={index}
                            >
                              {isLocalizedModulesSelected && (
                                <>
                                  <input
                                    id={subCategory}
                                    type="radio"
                                    name={checkbox.category}
                                    className="form-check-input"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={`${subCategory}`}
                                    checked={isChecked}
                                  />
                                  <label
                                    htmlFor={subCategory}
                                    className="form-check-label"
                                  >
                                    {`${subCategory}`}
                                  </label>
                                </>
                              )}
                              {!isLocalizedModulesSelected && (
                                <CheckBox
                                  label={subCategory}
                                  isChecked={isChecked}
                                  name={checkbox.category}
                                  handleChange={(e) => {
                                    handleChange(e);
                                  }}
                                  labelClassName="form-check-label"
                                  checkboxClassName="form-check-input"
                                />
                              )}
                            </div>
                          );
                        })}

                      {/* For Language */}

                      {isLocalizedModulesSelected &&
                        checkbox.category === "Language" &&
                        checkbox.subCategory.map((subCategory, index) => {
                          let isChecked;
                          isChecked =
                            filteredLanguageList.findIndex((el) => {
                              return (
                                el === `${subCategory.language}`
                                // el ===
                                // `${subCategory.languageCode} - ${subCategory.language}`
                              );
                            }) > -1;

                          return (
                            <div
                              className="contentBlock form-check"
                              key={index}
                            >
                              <input
                                id={subCategory.icbCode}
                                type="radio"
                                name="Language"
                                className="form-check-input"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                value={`${subCategory.language}`}
                                checked={isChecked}
                              />
                              <label
                                htmlFor={subCategory.icbCode}
                                className="form-check-label"
                              >
                                {`${subCategory.language}`}
                                {/* {`${subCategory.languageCode} - ${subCategory.language}`} */}
                              </label>
                            </div>
                          );
                        })}
                    </Card.Body>
                  </Accordion.Collapse>
                  <div className="lineHorizontal"></div>
                </div>
              );
            })}
      </Accordion>

      <button
        className="resetFilterBtn"
        disabled={shouldDisableFilterResetButton()}
        onClick={() => {
          resetFilters();
        }}
      >
        {t("generalText.clearFilters").toUpperCase()}
      </button>
      <CustomModal
        displayModal={alertForBrands}
        hideModal={handleModal}
        title={"Filter By Alert"}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <p
          style={{
            textAlign: "center",
          }}
        >
          {dialogMessage()}
        </p>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-link pl-0"
            style={{
              marginLeft: "49%",
            }}
            onClick={handleModal}
          >
            OK
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ExistingTacticsFilter;
