import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../styles/components/PageAtoms.scss";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import "../styles/pages/CreateModule.scss";
import filterIcon from "../images/filterIcon.svg";
import Switch from "@material-ui/core/Switch";
import expandIcon from "../images/fullScreenIcon.svg";
import CustomModal from "../components/customModal";
import modalCloseIcon from "../images/modalCloseIcon.svg";
const PageAtoms = (props) => {
  const { t } = useTranslation();
  const atoms = props.atoms || [];
  const [viewAssetModal, setViewAssetModal] = useState(false);
  const [assetUrl, setAssetUrl] = useState("");


  const showModal = (image) => {
    setAssetUrl(image);
    setViewAssetModal(true);
  };

  const hideModal = () => {
    setViewAssetModal(false);
  };


  return (
    // <div className="fluid-container">
    //     <div className = "header">
    //     <p className className = "header-title">{i18next.t("atoms.heading")}</p>
    //     <hr className = "header-bar" />
    //     </div>

    //     <div className="row ">
    //             {atoms.map((atom) => {
    //                 return (<div className="col-md-6 atoms-border atom" key={atom._id}>
    //                <img  className = "atom-thumb" src= {atom.document} />
    //             </div>)
    //             })}
    //     </div>
    // </div>
    // <div>
    // {atoms.map((atom) => {
    //     return (
    //         <Col md={6}>
    //         <div className="atomsBlock">
    //           <TurnedInNotIcon className="bookMarkIcon bookmarkOutline" />
    //           <img src={atom.document} alt="" className="img-fluid" />
    //         </div>
    //       </Col>)
    // })}
    // </div>

    <div>
      {/* <div className="text-right">
        Show bookmarked (4){" "}
        <Switch inputProps={{ "aria-label": "primary checkbox" }} />
      </div> */}
      <Container className="pl-0 pr-0">
        <Row className="no-gutter align-items-center mt-4">
          <Col>
            <p className="mb-0 atomsTitle">Atoms on this Page</p>
          </Col>
        </Row>
        <div className="atomsContainerStep01">
          <Row className="no-gutter align-items-center mt-4">
            {atoms.map((atom) => {
              return (
                <Col md={6} key={atom._id}>
                  <div className="atomsBlock">
                    <TurnedInNotIcon className="bookMarkIcon bookmarkOutline" />
                    <img src={atom.document} alt="" className="img-fluid" />
                    <div className="atom-expandIcon">
                      <img
                        src={expandIcon}
                        alt=""
                        onClick={() => {
                          showModal(atom.document);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <CustomModal
        displayModal={viewAssetModal}
        hideModal={hideModal}
        title={"View Asset Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop="false"
      >
        <button
          className="btn btn-link pl-0 assetModalCloseBtn"
          onClick={hideModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
        <div className="assetImageModalBlock custonScrollBar">
          <img src={assetUrl} alt="" className="img-fluid" />
        </div>
      </CustomModal>
        </div>
      </Container>
    </div>
  );
};

export default PageAtoms;
