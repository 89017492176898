import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import parseHtml from "html-react-parser";
import CheckBox from "../../Uitls/CheckBox";
import { LocalizedBusinessrulesModules } from "../../services/apis";

const GenericBussinessRules = ({
  hideLocalizeBusinsessrulsModal,
  selectModuleIds,
  handleProceed,
  prevSelectedData,
}) => {
  const [curentSelectedModule, setCurentSelectedModule] = useState({});

  const [localizeBusinerulesData, setLocalizeBusinerulesData] = useState([]);

  const [deselectedAssociatedModules, setDeselectedAssociatedModules] =
    useState([]);

  const [selectedTabPanel, setSelectedTabPanel] = useState("must");
  const [genericInfo, setGenericInfo] = useState(undefined);
  const [allModuleIdForGroupCreation, setAllModuleIdForGroupCreation] =
    useState([]);
  useEffect(() => {
    if (prevSelectedData) {
      setCurentSelectedModule(prevSelectedData.curentSelectedModule);
      setLocalizeBusinerulesData(prevSelectedData.localizeBusinerulesData);
      setDeselectedAssociatedModules(
        prevSelectedData.deselectedAssociatedModules
      );
      setSelectedTabPanel(prevSelectedData.selectedTabPanel);
      setGenericInfo(prevSelectedData.genericInfo);
      const bussinessRulesData = [];
      prevSelectedData.localizeBusinerulesData.forEach((item) => {
        let allModuleData = [];
        allModuleData.push({
          parentModuleId: item.moduleId,
        });
        if (item.mustExistWith.length > 0) {
          item.mustExistWith.forEach((element) => {
            allModuleData.push({
              moduleId: element.moduleId,
              type: "mustExist",
              parentModuleId: item.moduleId,
            });
          });
        }
        if (item.precedes.length > 0) {
          item.precedes.forEach((element) => {
            allModuleData.push({
              moduleId: element.moduleId,
              type: "preceeds",
              parentModuleId: item.moduleId,
            });
          });
        }
        if (item.relatedTo.length > 0) {
          item.relatedTo.forEach((element) => {
            allModuleData.push({
              moduleId: element.moduleId,
              type: "related",
              parentModuleId: item.moduleId,
            });
          });
        }
        if (item.succeeds.length > 0) {
          item.succeeds.forEach((element) => {
            allModuleData.push({
              moduleId: element.moduleId,
              type: "succeeds",
              parentModuleId: item.moduleId,
            });
          });
        }
        bussinessRulesData.push.apply(bussinessRulesData, allModuleData);
      });
      setAllModuleIdForGroupCreation(bussinessRulesData);
    } else {
      createLocalizeGroup();
    }
  }, [selectModuleIds, prevSelectedData]);
  const createLocalizeGroup = () => {
    const selectedModules = selectModuleIds;
    LocalizedBusinessrulesModules(selectedModules, (response, err, errCode) => {
      if (err) {
        //const errCoponent = ErrComponentToRender(errCode);
        //showError(errCoponent);
      } else {
        let genericInformation;
        for (let element of response.data) {
          if (element.genericInformation) {
            genericInformation = element.genericInformation;
            break;
          }
        }
        setGenericInfo(genericInformation);
        setLocalizeBusinerulesData(response.data);
        const bussinessRulesData = [];
        response.data.forEach((item) => {
          let allModuleData = [];
          allModuleData.push({
            parentModuleId: item.moduleId,
          });
          if (item.mustExistWith.length > 0) {
            item.mustExistWith.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "mustExist",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.precedes.length > 0) {
            item.precedes.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "preceeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.relatedTo.length > 0) {
            item.relatedTo.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "related",
                parentModuleId: item.moduleId,
              });
            });
          }
          if (item.succeeds.length > 0) {
            item.succeeds.forEach((element) => {
              allModuleData.push({
                moduleId: element.moduleId,
                type: "succeeds",
                parentModuleId: item.moduleId,
              });
            });
          }
          bussinessRulesData.push.apply(bussinessRulesData, allModuleData);
        });
        setAllModuleIdForGroupCreation(bussinessRulesData);
        const selectedModule = response.data[0];
        setCurentSelectedModule(selectedModule);
      }
    });
  };
  const setSelectedModuleHandler = (moduleId) => {
    const result = localizeBusinerulesData.find(function (element) {
      if (element.moduleId === moduleId) {
        return true;
      } else {
        return false;
      }
    });
    setCurentSelectedModule(result);
  };

  const getLeftSideBRuleModules = (parentModule, ruleType) => {
    let finalModules = [];

    switch (ruleType) {
      case "mustExist":
        finalModules = parentModule.mustExistWith;
        break;
      case "preceeds":
        finalModules = parentModule.precedes;
        if (finalModules.length > 0) {
          const mustExistWith = parentModule.mustExistWith;
          finalModules = [...finalModules].filter((module) => {
            return (
              mustExistWith.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "succeeds":
        finalModules = parentModule.succeeds;
        if (finalModules.length > 0) {
          const otherModules = parentModule.mustExistWith.concat(
            parentModule.precedes
          );
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      case "related":
        finalModules = parentModule.relatedTo;
        if (finalModules.length > 0) {
          const otherModules = parentModule.mustExistWith
            .concat(parentModule.precedes)
            .concat(parentModule.succeeds);
          finalModules = [...finalModules].filter((module) => {
            return (
              otherModules.findIndex((mustExist) => {
                return mustExist.moduleId === module.moduleId;
              }) === -1
            );
          });
        }
        break;
      default:
        break;
    }
    return finalModules;
  };

  const onNextModuleButtonClick = () => {
    const curentSelectedModuleId = curentSelectedModule.moduleId;
    const index = localizeBusinerulesData.findIndex((module) => {
      return module.moduleId === curentSelectedModuleId;
    });
    if (index === localizeBusinerulesData.length - 1) {
      setCurentSelectedModule(localizeBusinerulesData[0]);
      return;
    }
    setCurentSelectedModule(localizeBusinerulesData[index + 1]);
  };

  const togglePanel = (type) => {
    if (type === selectedTabPanel) {
      setSelectedTabPanel("");
    } else {
      setSelectedTabPanel(type);
    }
  };

  const handleCheckBoxChange = (moduleId, type, event) => {
    const isChecked = event.target.checked;
    if (isChecked && isDeSelected(moduleId, type)) {
      const index = deselectedAssociatedModules.findIndex((module) => {
        return (
          module.parentModuleId === curentSelectedModule.moduleId &&
          module.moduleId === moduleId &&
          module.type === type
        );
      });
      const modules = [...deselectedAssociatedModules];
      modules.splice(index, 1);
      setDeselectedAssociatedModules(modules);
      let selectedModuleObject = {
        moduleId: moduleId,
        type: type,
        parentModuleId: curentSelectedModule.moduleId,
      };
      const allModulesData = [...allModuleIdForGroupCreation];
      allModulesData.push(selectedModuleObject);
      setAllModuleIdForGroupCreation(allModulesData);
      // [...deselectedAssociatedModules].filter((el) => {
      //   return el.moduleId !== moduleId;
      // })
    } else if (!isChecked && !isDeSelected(moduleId, type)) {
      setDeselectedAssociatedModules(
        [...deselectedAssociatedModules].concat({
          parentModuleId: curentSelectedModule.moduleId,
          type: type,
          moduleId: moduleId,
        })
      );
      const index = allModuleIdForGroupCreation.findIndex((module) => {
        return (
          module.parentModuleId === curentSelectedModule.moduleId &&
          module.moduleId === moduleId &&
          module.type === type
        );
      });
      const allModulesData = [...allModuleIdForGroupCreation];
      allModulesData.splice(index, 1);
      setAllModuleIdForGroupCreation(allModulesData);
    }
  };

  const renderComplianceIssueMessage = () => {
    const totalDeselected = totalMustExistDeselectedModules();
    if (totalDeselected > 0) {
      return `${totalDeselected} Must Exist ${
        totalDeselected > 1 ? "Modules are" : "Module is"
      } de-selected.
      This could result in compliance issues`;
    }
  };

  const handleBulkLocalizationModal = (flag = false) => {
    let selectedData = {
      curentSelectedModule: curentSelectedModule,
      localizeBusinerulesData: localizeBusinerulesData,
      deselectedAssociatedModules: deselectedAssociatedModules,
      selectedTabPanel: selectedTabPanel,
      genericInfo: genericInfo,
    };
    const tempResult = [];
    allModuleIdForGroupCreation.forEach((el) => {
      if (el.moduleId) {
        const obj1 = el.moduleId;
        tempResult.push(obj1);
      }
      const obj2 = el.parentModuleId;
      tempResult.push(obj2);
    });
    let finalFilteredModuleIds = [...new Set(tempResult)];
    handleProceed(flag, genericInfo, selectedData, finalFilteredModuleIds);
  };

  const isNextButtonDisabled = localizeBusinerulesData.length <= 1;
  const totalMustExistDeselectedModules = () => {
    return deselectedAssociatedModules.filter((module) => {
      return module.type === "mustExist";
    }).length;
  };
  const isDeSelected = (moduleId, type) => {
    const index = deselectedAssociatedModules.findIndex((module) => {
      return (
        module.parentModuleId === curentSelectedModule.moduleId &&
        module.moduleId === moduleId &&
        type === module.type
      );
    });
    return index > -1;
  };
  const handleClose = () => {
    setSelectedTabPanel("must");
    setDeselectedAssociatedModules([]);
    hideLocalizeBusinsessrulsModal();
  };
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-6 associatedModuleTitle">
          Select Associated Modules
        </div>
        <div className="col-md-6 text-right">
          <button onClick={handleClose} className="btn btn-link p-0">
            CLOSE
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 selectedModuleIdColumn customScrollBar">
          {localizeBusinerulesData &&
            localizeBusinerulesData.map((item) => {
              return (
                <>
                  <div
                    className={
                      curentSelectedModule.moduleId === item.moduleId
                        ? "associatedModuleActiveBrdr"
                        : ""
                    }
                  >
                    <button
                      onClick={() => {
                        setSelectedModuleHandler(item.moduleId);
                      }}
                      className={`btn btn-link btn-block associatedModuleIdTitle ${
                        curentSelectedModule.moduleId === item.moduleId
                          ? "associatedModuleActive"
                          : ""
                      }`}
                    >
                      {item.moduleCode}
                    </button>
                  </div>
                  {curentSelectedModule.moduleId === item.moduleId && (
                    <ul className="associatedBsModuleTitle">
                      {getLeftSideBRuleModules(item, "mustExist")?.map((sm) => (
                        <li
                          className={`${
                            isDeSelected(sm.moduleId, "mustExist")
                              ? "deselected-business-rule-module-code"
                              : ""
                          }`}
                        >
                          {sm.moduleCode}
                        </li>
                      ))}
                      {getLeftSideBRuleModules(item, "preceeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "succeeds").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                      {getLeftSideBRuleModules(item, "related").map((sm) => (
                        <li>{sm.moduleCode}</li>
                      ))}
                    </ul>
                  )}
                </>
              );
            })}
        </div>
        <div className="col-md-9">
          <div className="row align-items-center mb-3">
            <div className="col-md-5">
              <span className="associatedBsModuleSubtitle">Campaign Name</span>
              <p className="associatedBsModuleSubText">
                {curentSelectedModule.campaignName}
              </p>
            </div>
            <div className="col-md-4">
              <span className="associatedBsModuleSubtitle">Module Name</span>
              <p className="associatedBsModuleSubText font-weight-bold">
                {curentSelectedModule.moduleName}
              </p>
            </div>
            <div className="col-md-3 text-right">
              <button
                onClick={onNextModuleButtonClick}
                disabled={isNextButtonDisabled}
                className="btn btn-outline-primary text-uppercase btnNextModule btn-lg"
              >
                Next Module
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 associatedModuleTitle mb-2">
              Business Rules
            </div>
          </div>
          <div className="row localizedAccordion customScrollBar">
            <Accordion defaultActiveKey="0">
              {curentSelectedModule &&
                curentSelectedModule.mustExistWith &&
                curentSelectedModule.mustExistWith.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="0"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("must");
                        }}
                      >
                        {selectedTabPanel === "must" ? "-" : "+"} Must Exist
                        With (
                        {`${curentSelectedModule.mustExistWith.length} Module${
                          curentSelectedModule.mustExistWith.length > 1
                            ? "s"
                            : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="row">
                        {curentSelectedModule &&
                          curentSelectedModule.mustExistWith &&
                          curentSelectedModule.mustExistWith.map((sm) => {
                            return (
                              <div className="col-md-4 mb-4">
                                <div className="d-flex align-items-center">
                                  <div className="moduleCardBusinessRulesCheckbox">
                                    <CheckBox
                                      name={sm.moduleId}
                                      isChecked={
                                        !isDeSelected(sm.moduleId, "mustExist")
                                      }
                                      handleChange={(event) =>
                                        handleCheckBoxChange(
                                          sm.moduleId,
                                          "mustExist",
                                          event
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`${
                                      isDeSelected(sm.moduleId, "mustExist")
                                        ? "moduleCardDeselectedBusinessRules"
                                        : "moduleCardBusinessRules"
                                    }`}
                                  >
                                    <p className="bsRulesCardCode">
                                      {sm.moduleCode}
                                    </p>
                                    <p className="bsRulesCardModuleName">
                                      Module Name <span>{sm.moduleName}</span>
                                    </p>
                                    <p className="bsRulesCardClaim">
                                      Promotional Claim{" "}
                                      <span>
                                        {parseHtml(sm.primaryClaim || "")}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.precedes &&
                curentSelectedModule.precedes.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="1"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("precedes");
                        }}
                      >
                        {selectedTabPanel === "precedes" ? "-" : "+"} Precedes (
                        {`${curentSelectedModule.precedes.length} Module${
                          curentSelectedModule.precedes.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="row">
                        {curentSelectedModule.precedes &&
                          curentSelectedModule.precedes.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRulesCheckbox">
                                  <CheckBox
                                    name={sm.moduleId}
                                    isChecked={
                                      !isDeSelected(sm.moduleId, "precedes")
                                    }
                                    handleChange={(event) =>
                                      handleCheckBoxChange(
                                        sm.moduleId,
                                        "precedes",
                                        event
                                      )
                                    }
                                  />
                                </div>
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.succeeds &&
                curentSelectedModule.succeeds.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="3"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("succeeds");
                        }}
                      >
                        {selectedTabPanel === "succeeds" ? "-" : "+"} Succeeds (
                        {`${curentSelectedModule.succeeds.length} Module${
                          curentSelectedModule.succeeds.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="row">
                        {curentSelectedModule.succeeds &&
                          curentSelectedModule.succeeds.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRulesCheckbox">
                                  <CheckBox
                                    name={sm.moduleId}
                                    isChecked={
                                      !isDeSelected(sm.moduleId, "succeeds")
                                    }
                                    handleChange={(event) =>
                                      handleCheckBoxChange(
                                        sm.moduleId,
                                        "succeeds",
                                        event
                                      )
                                    }
                                  />
                                </div>
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
              {curentSelectedModule.relatedTo &&
                curentSelectedModule.relatedTo.length > 0 && (
                  <div>
                    <Card.Header>
                      <Accordion.Toggle
                        eventKey="4"
                        variant="link"
                        className="associatedModuleTitle"
                        onClick={() => {
                          togglePanel("related");
                        }}
                      >
                        {selectedTabPanel === "related" ? "-" : "+"} Related
                        Modules (
                        {`${curentSelectedModule.relatedTo.length} Module${
                          curentSelectedModule.relatedTo.length > 1 ? "s" : ""
                        }`}
                        )
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="4">
                      <Card.Body className="row">
                        {curentSelectedModule.relatedTo &&
                          curentSelectedModule.relatedTo.map((sm) => (
                            <div className="col-md-4 mb-4">
                              <div className="d-flex align-items-center">
                                <div className="moduleCardBusinessRulesCheckbox">
                                  <CheckBox
                                    name={sm.moduleId}
                                    isChecked={
                                      !isDeSelected(sm.moduleId, "related")
                                    }
                                    handleChange={(event) =>
                                      handleCheckBoxChange(
                                        sm.moduleId,
                                        "related",
                                        event
                                      )
                                    }
                                  />
                                </div>
                                <div className="moduleCardBusinessRules">
                                  <p className="bsRulesCardCode">
                                    {sm.moduleCode}
                                  </p>
                                  <p className="bsRulesCardModuleName">
                                    Module Name <span>{sm.moduleName}</span>
                                  </p>
                                  <p className="bsRulesCardClaim">
                                    Promotional Claim{" "}
                                    <span>
                                      {parseHtml(sm.primaryClaim || "")}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                )}
            </Accordion>
          </div>
        </div>
      </div>
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-10 text-right bsRulesCardError">
          {renderComplianceIssueMessage()}
        </div>
        <div className="col-md-2 text-right">
          <button
            className="btn btn-primary btnBsRulesProceed"
            onClick={() => {
              handleBulkLocalizationModal(true);
            }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </div>
  );
};
export default GenericBussinessRules;
