import axios from "axios";
const moduleApiEndPoint =
  // "http://Imcmoduleapi-env.eba-qmc6suy8.ap-south-1.elasticbeanstalk.com";
  //"http://Imcmoduleapi-env.eba-qmc6suy8.ap-south-1.elasticbeanstalk.com";
  //"https://imc-test-moduleapi.indegene.com";
  // "https://dev-moduleapi.indegene.com";
  "https://qa-moduleapi.indegene.com";
const storageApiEndPoint =
  // "http://imcstorageapi-env-1.eba-psq3ziwu.ap-south-1.elasticbeanstalk.com";
  //"http://imcstorageapi-env-1.eba-psq3ziwu.ap-south-1.elasticbeanstalk.com";
  //"https://imc-test-storageapi.indegene.com";
  // "https://dev-storageapi.indegene.com";
  "https://qa-storageapi.indegene.com";

export const instance = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 15000,
  // headers: { "X-Custom-Header": "foobar" },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceStorage = axios.create({
  baseURL: storageApiEndPoint,
  timeout: 15000,
  // headers: { "X-Custom-Header": "foobar" },
});

// Add a request interceptor
instanceStorage.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const instanceUpload = axios.create({
  baseURL: moduleApiEndPoint,
  timeout: 460000,
});

// Add a request interceptor
instanceUpload.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("userToken");
    //  const token = "12345678dkdklsdf";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceStorage.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// export default instance;

export default instance;
