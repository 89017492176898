import React from "react";
import deleteIcon from "../../../images/closeIcon.svg";
import InfoIconError from "../../../images/Info-selection-error.svg";
const ModuleSelectionCard = (props) => {
  const {
    boxKey,
    boxTitle,
    data,
    selectedBoxIndex,
    setSelectedBox,
    deleteBodyModule,
    checkForModuleAdded,
    getErroredBucketIds,
    boxIndex,
    boxId,
  } = props;

  const erroredBucketIds = getErroredBucketIds();
  const isBucketErroredOut = erroredBucketIds.includes(boxId);
  return (
    <>
      <div
        className={`col-md-3 mb-3`}
        onClick={() => {
          if (!checkForModuleAdded(boxKey)) return;
          setSelectedBox(boxKey);
        }}
      >
        <div
          className={
            "moduleSelectionCard " +
            " " +
            (isBucketErroredOut
              ? "moduleCardError "
              : selectedBoxIndex === boxKey
              ? "moduleSelectionCardSelected "
              : "")
          }
        >
          <div className="moduleSectionBoxTitle d-flex justify-content-between">
            Module {boxTitle}
          </div>

          {data.length ? (
            ""
          ) : (
            <div className="boxLableVerticalMiddle modulePlaceHolder">
              Drop Module here
            </div>
          )}

          <ul
            className={`addedContentModuleList listScrollContainer moduleListContainer custonScrollBar`}
          >
            {data.map((item, index) => {
              return (
                <li key={index} className="contentSelectionModuleList">
                  <div className="groupAtomsList">
                    <p className="listModuleName">{item.moduleName}</p>
                  </div>
                  <button
                    className="btn btn-link p-0 m-0 deleteGroupItem"
                    onClick={(e) => {
                      e.preventDefault();
                      //if (!checkForModuleAdded(boxKey)) return;
                      deleteBodyModule(item, boxKey);
                    }}
                  >
                    <img src={deleteIcon} alt="" width="32" />
                  </button>
                </li>
              );
            })}
          </ul>
          {isBucketErroredOut && (
            <div className="error-message-note">
              <img
                src={InfoIconError}
                alt=""
                style={{ marginBottom: "3px", marginRight: "5px" }}
              />
              This can't be left blank
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ModuleSelectionCard;
