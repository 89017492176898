import React, { useState } from "react";
import CustomModal from "../../../components/customModal";
import "../../../styles/components/ExistingTatics.scss";
import "../../../styles/CustomToastify.scss";
import "../../../styles/components/HarvestedTacticCard.scss";
import CheckBox from ".././../../Uitls/CheckBox";

import expandIcon from "../../../images/fullScreenIcon.svg";
import modalCloseIcon from "../../../images/modalCloseIcon.svg";

const HarvestedTacticCard = ({
  tactic,
  onSelected,
  duplicateAssetsList,
  tacticsToBeRetrieved,
}) => {
  const [expandedTacticModal, setExpandedTacticModal] = useState(false);
  const hideExpandedTacticModal = () => {
    setExpandedTacticModal(false);
  };
  const [tacticThumbUrl, setTacticThumbUrl] = useState("");
  const showExpandedTacticModal = (image) => {
    setTacticThumbUrl(image);
    setExpandedTacticModal(true);
  };
  const checkDuplicate = (id) => {
    let result = duplicateAssetsList.includes(id);
    return result;
  };
  const handleAddSelectClass = (id) => {
    let selectedTactic = [];
    tacticsToBeRetrieved &&
      tacticsToBeRetrieved.forEach((element, index) => {
        selectedTactic.push(element.externalAssetId);
      });
    let result = selectedTactic.includes(id);
    return result;
  };
  return (
    <>
      {tactic && (
        <div className="col-md-4">
          <div
            className={`row align-items-center harvestCardHeight ml-0 mr-0 ${
              checkDuplicate(tactic.id) === true
                ? "tactic-alert harvestedCardSelected"
                : ""
            } ${
              handleAddSelectClass(tactic.id) === true
                ? "harvestedCardSelected"
                : ""
            }`}
          >
            <div className="col-md-1 check-box harvestedTactics">
              <CheckBox
                handleChange={(event) => {
                  onSelected(tactic, event.target.checked);
                }}
              />
            </div>
            <div className="masterAssePdfPreveiw col-md-5">
              {(tactic.assetThumbnailUrl || tactic.url) && (
                <>
                  <div className="expandIcon">
                    <img
                      src={expandIcon}
                      alt=""
                      onClick={() => {
                        showExpandedTacticModal(
                          tactic.url || tactic.assetThumbnailUrl
                        );
                      }}
                    />
                  </div>
                  <div className="mr-3 assetImgThumb">
                    <img
                      src={tactic.url || tactic.assetThumbnailUrl}
                      alt=""
                      className="img-fluid tacticsAssetThumb"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6">
              <p className="masterAssetTitle mb-1 ml-3">
                {tactic.name || tactic.assetName}
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                {checkDuplicate(tactic.id) === true ? (
                  <span className="tactic-errormsg d-block ml-3">
                    Already exists! The same tactic cannot be downloaded again.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        displayModal={expandedTacticModal}
        hideExpandedTacticModal={hideExpandedTacticModal}
        title={"View Asset Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop="false"
      >
        <div className="assetImageModalBlock custonScrollBar">
          <img src={tacticThumbUrl} alt="" className="img-fluid" />
        </div>
        <button
          className="btn btn-link pl-0 imageModalCloseBtn"
          onClick={hideExpandedTacticModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
      </CustomModal>
    </>
  );
};

export default HarvestedTacticCard;
