import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FroalaEditor from "react-froala-wysiwyg";
import { froalaEditor } from "../Uitls/FroalaConfig";
import "../styles/components/SupportingClaim.scss";
import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";
const SupportingClaim = (props) => {
  const {
    row,
    setCurrentTextReplace,
    currentTextReplace,
    //addSupportingClaims,
    rowIndex,
    box1,
    setSupportingClaimsAndVisuals,
    supportingClaimsAndVisuals,

    setSuperScriptDataForClaim,
    handleModelChange,
    setCurrentClaimTextReplace,
    currentClaimTextReplace,
  } = props;

  const addSupportingClaims = (
    rowPosition,
    type,
    currentImagePosition,
    assetUrl,
    id
  ) => {
    let claimsAndVisuls = [...supportingClaimsAndVisuals];
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[0];

    boxToBeUpdated.data.push({ atomText: "" });
    setSupportingClaimsAndVisuals(claimsAndVisuls);
  };

  const froalaConfig = froalaEditor.config(
    {
      placeHolder: props.placeHolder,
      shouldHaveToolbar: true,
      shouldUpdateImmediatly: props.shouldUpdateImmediatly || false,
      contentEditable:
        props.editable && props.editable === "off" ? false : true,
      charCounter: props.charCounter,
      insertLink: props.insertLink,
      onChange: props.handleModelChange,
    },
    (onclick = (event) => {
      if (props.onSupClick) {
        props.onSupClick(event);
      }
    })
  );

  //const [showLargerEditor, setShowLargerEditor] = useState(false)
  const [atomText, setAtomText] = useState();
  const [currentTextReplaceLocal, setCurrentTextReplaceLocal] = useState(0);
  const [supportingclaimHashKey, setSupportingclaimHashKey] = useState();

  const newEditor = (defaultText) => {
    return (
      //   <FroalaEditor
      //     ref={props.forwardedRef}
      //     className="common-editor"
      //     tag="textarea"
      //     config={froalaConfig}
      //     //onClick={onEditorClick}
      //     model={defaultText}
      //     onModelChange={props.handleModelChange}
      //   />
      <FroalaEditor
        ref={props.forwardedRef}
        tag="textarea"
        className="common-editor"
        selectedAtom={props.selectedAtom}
        onModelChange={(event) => {
          handleModelChange(event, rowIndex);
        }}
        model={defaultText}
        config={froalaConfig}
      />
    );
  };

  const prevBtnDisabledText = currentTextReplaceLocal <= 0;

  const nextbtnDisabledText =
    (row && row[0] && row[0].data && row[0].data.length === 0) ||
    (row &&
      row[0] &&
      row[0].data &&
      row[0].data.length - 1 === currentTextReplaceLocal);

  useEffect(() => {
    const hash = Date.now();
    setSupportingclaimHashKey(hash);
  }, [currentTextReplaceLocal]);
  let currentSupportingClaimCount = currentTextReplaceLocal + 1;

  const changeCurrentClaimTextSelection = (val) => {
    // setIsFreeze(true);
    if (val === -1 && currentTextReplaceLocal === 0) {
      return;
    }

    const newCurrentClaim = currentTextReplaceLocal + val;
    //setCurrentImage(newCurrentImage);

    setCurrentTextReplaceLocal(newCurrentClaim);
    setCurrentClaimTextReplace(newCurrentClaim);
  };
  const supportingClaimData = row[0].data;
  const supportingClaimDataLength = supportingClaimData.length;
  const isClaimBtnDisabled =
    supportingClaimData[supportingClaimDataLength - 1] &&
    supportingClaimData[supportingClaimDataLength - 1].atomText === ""
      ? true
      : false;
  const changeCurrentClaimTextSelectionToLast = () => {
    // setIsFreeze(true);
    const newCurrentClaim =
      row && row[0] && row[0].data && row[0].data.length - 1;
    setCurrentTextReplaceLocal(newCurrentClaim);
    setCurrentClaimTextReplace(newCurrentClaim);
  };

  const deleteSupportingClaim = (rowPosition, claimPosition) => {
    if (claimPosition === -1) {
      return;
    }
    const rowToBeUpdated = supportingClaimsAndVisuals[rowPosition];
    const boxToBeUpdated = rowToBeUpdated.rowData[0];
    boxToBeUpdated.data.splice(claimPosition, 1);
    setSupportingClaimsAndVisuals([...supportingClaimsAndVisuals]);
    setSuperScriptDataForClaim(uuidv4());
  };
  return (
    <div className={`${props.className || "supporting-claim"}`}>
      {" "}
      {props && props.title && (
        <p className="mb-0 box-heading">{props.title}</p>
      )}
      {newEditor(props && props.selectedAtom ? props.selectedAtom : "")}
      {row && row[0] && row[0].data && row[0].data.length > 0 ? (
        <div className="boxBtnRowCap rowCap">
          <button
            onClick={(e) => {
              changeCurrentClaimTextSelection(-1, rowIndex);
              e.stopPropagation();
            }}
            disabled={prevBtnDisabledText}
            className={
              prevBtnDisabledText ? "btn disabled pr-2" : "btn btn-link pr-2"
            }
          >
            <img src={arrowLeft} alt="Previous" className="btnOpacity50" />
          </button>
          <span className="slideCountCaptions">
            {currentSupportingClaimCount && (
              <span key={supportingclaimHashKey}>
                {currentSupportingClaimCount}
              </span>
            )}
            /{row && row[0] && row[0].data && row[0].data.length}
          </span>
          <button
            onClick={(e) => {
              changeCurrentClaimTextSelection(1, rowIndex);
              e.stopPropagation();
            }}
            disabled={nextbtnDisabledText}
            className={
              nextbtnDisabledText ? "btn disabled pl-2" : "btn btn-link pl-2"
            }
          >
            <img src={arrowRight} alt="Next" className="btnOpacity50" />
          </button>

          {/* <button
            onClick={(e) => {
              deleteSupportingClaim(rowIndex, currentTextReplaceLocal);
              changeCurrentClaimTextSelection(-1);
              e.stopPropagation();
            }}
            disabled={row && row[0] && row[0].data && row[0].data.length === 0}
            className="btn btn-link p-0 deleteCap"
          >
            Delete
          </button> */}
        </div>
      ) : (
        ""
      )}
      <button
        onClick={(e) => {
          addSupportingClaims(rowIndex);
          changeCurrentClaimTextSelectionToLast();
          e.stopPropagation();
        }}
        // disabled={
        //   (row && row[0] && row[0].data && row[0].data.length === 0) ||
        //   isClaimBtnDisabled
        // }
        className="btn btn-link p-0 ml-3 mr-2 floatbt"
      >
        <span className=" float fa fa-plus my-floatCaption"></span>
      </button>
    </div>
  );
};

export default SupportingClaim;
