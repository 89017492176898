import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import { Container, Row, Col } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import errComponentToRender from "../Uitls/apiErrorHandling";
import PageAtoms from "../components/pageAtoms";
import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import LogoutCTA from "../Pages/LogoutCTA";
import {
  getAssetDetails,
  createModuleDetails,
  moduleID,
  moduleCode,
} from "../services/apis";
import FilterByCategory from "../components/FilterByCategory";
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
  moduleTypes,
  getModuleInfoFromLocalStorage,
} from "../Uitls/Util";
import { useHistory } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Typography from "@material-ui/core/Typography";

import "../styles/pages/CreateModule.scss";
import { object } from "prop-types";
import CircularProgressBar from "../Uitls/CircularProgressBar";

function CreateModule(props) {
  let history = useHistory();
  const [currentPage, setCurrentPage] = useState(routes.assetDetails);
  //const [currentPage, setCurrentPage] = useState('page2');
  const [btnOptions, setBtnOptions] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [assetDetails, setAssetDetails] = useState({});
  const [pages, setPages] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filteredPages, setFiletredPages] = useState([]); // We will use this to set filtered pages and use every wehre
  const [page, setPage] = useState(0); //useState(1);
  const [catLitItems, setCatLitItems] = useState([]);
  const { t } = useTranslation();
  const [moduleName, setModuleName] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [actualPageNumber, setActualPageNumber] = useState(0);

  let slideImages = new Array(filteredPages.length);

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  //
  const getCreatedAssetDetails = (assetId) => {
    setIsloading(true);
    getAssetDetails((response, err, errorCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        console.log(err);
        getCreatedAssetDetailsErr(errCoponent);
        setErrorMsg(true);
      } else {
        setAssetDetails(response.data);
      }
      setIsloading(false);
    }, assetId);
  };

  const updateModuleInfLocally = (module) => {
    let pageCount = 0;
    if (assetDetails) {
      let children = assetDetails.children;
      if (children && children.length > 0) {
        children = [...children].filter((child) => {
          return child.type === "Page";
        });
        if (children && children.length > 0) {
          pageCount = children.length;
        }
      }
    }
    updateModuleInfo({
      assetId: props.location.state.assetid,
      moduleId: props.location.state.moduleId,
      moduleName: props.location.state.moduleName,
      moduleCode: props.location.state.moduleCode,
      moduleType: "newModule",
      numberOfPages: pageCount,
      creationMethodType: "deconstructed",
    });
  };

  const createModuleErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const handleProceed = () => {
    removeModuleInfo();
    updateModuleInfLocally();
    history.push({
      pathname: routes.addPrimaryClaim,
      state: {
        filteredPages,
        moduleName: props.location.state.moduleName,
        moduleCode: props.location.state.moduleCode,
        assetId: props.location.state.assetid,
        selectedSlider: page,
        moduleId: props.location.state.moduleId,
        brandName: props.location.state.brandName,
        isCategoriesAdded: props.location.state.isCategoriesAdded,
      },
    });

    return;
    let currentassetid = "";
    let currentHarvestedAssetId = "";
    if (
      props.location.state !== null &&
      props.location.state !== undefined &&
      props.location.state.assetid !== undefined
    ) {
      currentassetid = props.location.state.assetid;
      currentHarvestedAssetId = props.location.state.harvestedAssetId;
    }
    // else {
    //   currentassetid = "2b532dbf-2f7d-4499-94e0-3907c1ff837f";
    // }
    const data = {
      harvestedAssetId: currentHarvestedAssetId,
      storageAssetId: currentassetid,
    };
    setIsloading(true);
    createModuleDetails(data, (response, err, errorCode) => {
      setIsloading(false);
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        createModuleErr(errCoponent);
      } else {
        removeModuleInfo();
        updateModuleInfLocally(response.data);
        history.push({
          pathname: routes.addPrimaryClaim,
          state: {
            filteredPages,
            moduleName: response.data.name,
            moduleCode: response.data.moduleCode,
            assetId: response.data.storageAssetId,
            selectedSlider: page,
            moduleId: response.data.id,
            brandId: response.data.brandId,
          },
        });
      }
    });
  };
  const getCreatedAssetDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useLayoutEffect(() => {
    prepareAssetDetailsData();
  }, [assetDetails]);

  useEffect(() => {
    setCatLitItems(pageCategories());
  }, [pages]);

  useEffect(() => {
    setBtnOptions(buttonOptions());
    setActualPageNumber(getActualPageOfRenderedPage());
  }, [filteredPages, page]);

  // useEffect(() =>{

  //     //setActualPageNumber(getActualPageOfRenderedPage())
  //     //navigateToEnteredText(undefined, page - 1)
  // }, [page])

  const pageCategories = () => {
    if (pages.length > 0) {
      let pageCategories = pages.map((page) => {
        return page.MetaData.topMessageCategories;
      });

      //Get Arrays of claim category names
      let rawCoreClaimCategories = pageCategories
        .reduce((c, v) => c.concat(v), [])
        .map((o) => o.messageCategory);
      //    rawCoreClaimCategories = rawCoreClaimCategories.concat(
      //   pageCategories[1].map((category) => {
      //     return category.messageCategory;
      //   })
      // );

      //Create an array of core claim categories(duplicates  allowed)
      let coreClaimCategories = [];
      for (const category of rawCoreClaimCategories) {
        coreClaimCategories = coreClaimCategories.concat(category);
      }
      //Remove Duplicates
      coreClaimCategories = coreClaimCategories.filter((value, index) => {
        return coreClaimCategories.indexOf(value) === index;
      });
      return coreClaimCategories;
    }
    return [];
  };

  const prepareAssetDetailsData = () => {
    const children = assetDetails.children;
    if (children) {
      let pages = [];
      pages = children.filter((child) => {
        return child.type === "Page";
      });
      pages = pages.map((page) => {
        const atoms = children.filter((child) => {
          return child.type === "Atom" && child.parent === page._id;
        });
        page = { ...page, atoms };
        return page;
      });
      //Sort the array
      pages.sort((page1, page2) => {
        return page1.MetaData.page - page2.MetaData.page;
      });
      setPages(pages);
      setFiletredPages(pages);
      setPage(1);
      navigateToEnteredText(undefined, 0);
    }
  };

  const handleFilter = (filterData) => {
    setFilterData(filterData);
  };

  useEffect(() => {
    applyFilter();
  }, [filterData]);

  const getActualPageOfRenderedPage = () => {
    const renderedPage =
      filteredPages.length > 0 ? filteredPages[page - 1] : undefined;
    if (renderedPage) {
      const renderedPageNumber = parseInt(renderedPage.MetaData.page);
      if (renderedPageNumber) {
        return renderedPageNumber;
      }
    }
    return 0;
  };

  const applyFilter = () => {
    if (filterData && filterData.length === 0) {
      setFiletredPages(pages);
      setPage(actualPageNumber);
      navigateToEnteredText(undefined, actualPageNumber - 1);
      return;
    }
    const filteredPages = pages.filter((page) => {
      return page.MetaData.topMessageCategories
        .map((topCat) => {
          return topCat.messageCategory;
        })
        .some((item) => filterData.includes(item));
    });
    filteredPages.sort((page1, page2) => {
      return page1.MetaData.page - page2.MetaData.page;
    });
    setFiletredPages(filteredPages);
    if (filteredPages.length > 0) {
      setPage(1);
      navigateToEnteredText(undefined, 0);
    } else {
      setPage(0);
      navigateToEnteredText(undefined, -1);
    }
    slideImages = new Array(filteredPages.length);
    //console.log("After filter", filteredPages);
  };

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.assetid
    ) {
      getCreatedAssetDetails(props.location.state.assetid);
    } else {
      history.push({
        pathname: routes.dashboard,
      });
    }
    setBtnOptions(buttonOptions());
  }, []);

  const handlePagination = (event, value) => {
    setPage(value + 1);
    navigateToEnteredText(undefined, value);
  };

  const renderAtomsPage = () => {
    if (filteredPages.length > 0 && filteredPages[page - 1]?.atoms) {
      return <PageAtoms atoms={filteredPages[page - 1].atoms} />;
    }
  };

  const getTopCategories = () => {
    return (
      filteredPages.length > 0 &&
      filteredPages[page - 1]?.MetaData.topMessageCategories.map((category) => {
        return (
          <span className="coreCatBg" key={category}>
            {" "}
            {category.messageCategory}
          </span>
        );
      })
    );

    // return filteredPages.length > 0 &&
    //   Object.keys(
    //     filteredPages[page - 1].metaData
    //       .topMessageCategories
    //   ).map((category) => {
    //     return (
    //       <span className="coreCatBg" key={category}>
    //         {" "}
    //         {category}
    //       </span>
    //     );
    //   })
  };
  const handlePageClose = () => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.assetid
    ) {
      history.push({
        pathname: routes.uploadAsset,
        state: {
          assetId: props.location.state.assetid,
        },
      });
    }
  };
  const buttonOptions = () => {
    const options = {
      btnProceed: i18next.t("generalText.proceed"),
      btnClose: i18next.t("generalText.close"),
      handleProceed: handleProceed,
      handlePageClose: handlePageClose,
    };
    return options;
  };

  const navigateToEnteredText = (event, value = undefined) => {
    if (event) {
      const parsedInt = parseInt(event.target.value);
      if (parsedInt && parsedInt > 0 && parsedInt <= slideImages.length) {
        setPage(parsedInt);
        event.target.value = parsedInt;
      }
    } else {
      const input = document.getElementById("import-page-pageination-input");
      if (input) {
        input.value = value + 1;
      }
    }
  };

  return (
    <>
      <LogoutCTA />

      <div className="headeBgWhite">
        <HeaderCreateModule />
        <CreateModuleNav
          currentPage={currentPage}
          handleProceed={handleProceed}
          options={btnOptions}
          handlePageClose={handlePageClose}
          moduleName={i18next.t("generalText.createModule")}
          isImportPage={true}
          isNotDenovoModule={true}
        />
      </div>
      <CircularProgressBar isloading={isloading} />
      <Container fluid>
        <Row className="bodyContB mt-2">
          <Col>
            <div className="bodyContentBg mt-2">
              <Container fluid className="pl-0 pr-0">
                <Row>
                  <Col lg={8} className="page-slide-part">
                    {/* <span className="coreCatTitle">
                      Select page to create Module
                    </span> */}
                    <Container className="pl-0 pr-0">
                      <Row className="no-gutter align-items-center">
                        {/* <Col className="col-12 col-md-6" key="top-left-portion">
                          <p className="mb-0 coreCatSubTitle">
                            {i18next.t("selectPage.identifycoreClaimCategory")}
                          </p>
                        </Col> */}
                        <Col className="col-12 col-md-4" key="top-left-portion">
                          <span className="coreCatTitle">
                            {i18next.t("selectPage.selectPageTitle")}
                          </span>
                        </Col>
                        <Col
                          className="col-12 col-md-8 text-right"
                          key="top-right-portion"
                        >
                          <span className="coreCatText">
                            {i18next.t("selectPage.pageCoreClaimCategory")}
                          </span>{" "}
                          {getTopCategories()}
                          {
                            <FilterByCategory
                              catlistitems={catLitItems}
                              handleFilter={handleFilter}
                              filterData={filterData}
                            />
                          }
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="text-center" key="slider">
                          <Slide
                            easing="ease"
                            onChange={handlePagination}
                            autoplay={false}
                            infinite={false}
                            defaultIndex={page - 1}
                            key={Date.now()}
                          >
                            {filteredPages.map((page) => {
                              return (
                                <div className="each-slide" key={page._id}>
                                  <div
                                    style={{
                                      backgroundImage: `url(${page.document})`,
                                    }}
                                  ></div>
                                </div>
                              );
                            })}
                          </Slide>
                          <div className="text-right mt-3 mr-5 mb-3 selectedPage">
                            Page:
                            {/* <span className="selectedPage"> {page} </span> */}
                            <input
                              id="import-page-pageination-input"
                              type="text"
                              className="paginationInput"
                              onBlur={(e) => {
                                navigateToEnteredText(e);
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  navigateToEnteredText(e);
                                }
                              }}
                              defaultValue={page}
                            />
                            of {slideImages.length}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col lg={4} className="page-atoms-part">
                    {renderAtomsPage()}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>

    // <div className="container-fluid">

    // </div>
  );
}

export default CreateModule;
