//Import react dependencies
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

//Import Custom components

import ContentModules from "./ContentModules";
import { retrieveContentModules } from "../../../services/apis";
import ErrComponentToRender from "../../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";

const VeevaSearchPage = ({
  contentModules,
  clearAllSearch,
  titleType,
  redirectToSearchInMM,
  selectedDamsSource,
  getAssetDataType,
  searchFuncRef,
  searchInDams,
}) => {
  const [selectedContentModuleIds, setSelectedContentModuleIds] = useState([]);
  const buttonList = [{ btnLabel: "View", type: "view" }];
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    searchFuncRef.current = resetSelectedContentModules;
  }, []);

  const resetSelectedContentModules = () => {
    setSelectedContentModuleIds([]);
  };

  useEffect(() => {
    if (titleType === "initial") {
      resetSelectedContentModules();
    }
  }, [titleType]);

  const moduleCardBtnActions = (actionType, module) => {
    switch (actionType) {
      case "view":
        window.open(module.contentModuleUiUrl, "_blank");

        break;
      default:
        break;
    }
  };

  const getTitle = () => {
    if (getAssetDataType() === "AssetComponent") {
      return;
    }
    return (
      <div style={{ margin: "-12px 0px 20px 12px" }}>
        <p className="cm-search-title">
          {" "}
          {titleType.toLowerCase() === "searched"
            ? "Search Results"
            : "Content Modules"}{" "}
        </p>
        <p style={{ fontSize: "11px", color: "#666666" }}>
          {contentModules.length} Items
        </p>
      </div>
    );
  };

  const retrieveContentModuleErr = (value) =>
    toast.error(
      <>
        <p className="retrievalStatusText">
          Import of Veeva Content Module Un-successful
        </p>
        {selectedContentModuleIds &&
          selectedContentModuleIds.map((item, index) => (
            <p
              className="retrievalStatusText  mb-0"
              style={{ wordBreak: "break-all" }}
              key={index}
            >
              {item}
            </p>
          ))}
      </>,
      { position: toast.POSITION.TOP_RIGHT }
    );

  const retrieveTacticsSuccess = (sucessCmName, duplicatedCmName) =>
    toast.success(
      <>
        {sucessCmName && sucessCmName.length > 0 ? (
          <>
            <p className="retrievalStatusText">
              {sucessCmName?.length} Veeva Content Module{" "}
              {sucessCmName.length > 1 ? <span>(s)</span> : ""} Imported
            </p>
          </>
        ) : (
          ""
        )}

        {duplicatedCmName && duplicatedCmName.length > 0 ? (
          <>
            <p className="retrievalStatusText">
              Already exists! The same Content Module cannot be imported again.
            </p>
            {duplicatedCmName &&
              duplicatedCmName.map((item, index) => (
                <p
                  className="retrievalStatusText  mb-0"
                  style={{ wordBreak: "break-all" }}
                  key={index}
                >
                  {item}
                </p>
              ))}
          </>
        ) : (
          ""
        )}
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast pr-0 text-right"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: 9000,
        closeOnClick: false,
      }
    );

  const retrieveContentModulesDams = () => {
    setIsloading(true);
    retrieveContentModules(
      selectedContentModuleIds,
      (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          retrieveContentModuleErr(errCoponent);
          setIsloading(false);
          setSelectedContentModuleIds([]);
        } else {
          let filteredData = response.data.filter(function (item) {
            if (item.imported === false) {
              return true;
            }
          });
          const sucessContModuleName = filteredData.map(
            (item) => item.moduleName
          );

          let duplicatedAssetIdList = response.data.filter(function (item) {
            if (item.imported === true) {
              return true;
            }
          });
          const duplicatedContModuleName = duplicatedAssetIdList.map(
            (item) => item.moduleName
          );
          //getInProgressIds();
          setIsloading(false);
          retrieveTacticsSuccess(
            sucessContModuleName,
            duplicatedContModuleName
          );
          setTimeout(() => {
            setSelectedContentModuleIds([]);
            redirectToSearchInMM();
          }, 5000);
        }
      },
      selectedDamsSource?.sourceType?.toLowerCase()
    );
  };
  const onModuleSelect = (contentModule) => {
    const index = selectedContentModuleIds.indexOf(contentModule.id);
    let selected = [...selectedContentModuleIds];

    if (index > -1) {
      selected = selected.filter((id) => {
        return id !== contentModule.id;
      });
    } else {
      selected = selected.concat(contentModule.id);
    }
    setSelectedContentModuleIds(selected);
  };

  return (
    <>
      {getTitle()}
      <CircularProgressBar isloading={isloading} />
      <ContentModules
        modules={contentModules}
        selectedContentModuleIds={selectedContentModuleIds}
        buttonList={buttonList}
        moduleCardBtnActions={moduleCardBtnActions}
        onModuleSelect={onModuleSelect}
        searchInDams={searchInDams}
      />
      {contentModules && contentModules.length > 0 && (
        <div className="row" style={{ borderTop: "solid 1px #e1e1e1" }}>
          <div className="col-md-6">
            <p style={{ marginTop: "23px", marginLeft: "20px", color: "#666" }}>
              {selectedContentModuleIds.length > 0 &&
                `${selectedContentModuleIds.length} Module${
                  selectedContentModuleIds.length > 1 ? "s" : ""
                } Selected`}
            </p>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-link btn-cm-import"
              disabled={
                selectedContentModuleIds && selectedContentModuleIds.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                retrieveContentModulesDams();
              }}
            >
              IMPORT
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VeevaSearchPage;
