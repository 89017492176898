import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const AssetDeconstructSucess = (props) => {
  const { t } = useTranslation();
  const { id, status, assetname } = props;
  let history = useHistory();
  // const viewAssetDetails = (id) => {
  //   console.log(id);
  //   history.push({
  //     pathname: "/upload-master-asset",
  //     state: { assetid: id },
  //   });
  // };

  return (
    <>
      <p className="notificationHeading mb-0">
        Retrieval Successfully Completed
      </p>
      <p className="notificationText mb-0">{assetname}</p>
      <p className="notificationText mb-0">
        are now available in Module Manager
      </p>
      <div className="btn-close-toast">
        <button
          className="btn btn-link pl-0 btn-close-toast pr-0 text-right"
          onClick={() => {
            toast.dismiss();
          }}
        >
          Close
        </button>
      </div>
      {/* <button
        className="btn btn-link pl-0"
        onClick={() => {
          viewAssetDetails(id);
        }}
      >
        View Asset
      </button> */}
    </>
  );
};

export const AssetDeconstructFailur = (props) => {
  const { t } = useTranslation();
  const { id, status, assetname } = props;
  return (
    <>
      <p className="harvesetedToastTitle mb-0">Retrieval Failed</p>
      <p className="retrievalStatusText mb-0">Retrieval failed for</p>
      <p className="retrievalStatusText mb-0"> {assetname}</p>
      <p className="retrievalStatusText mb-0">Please try again.</p>
      <div className="btn-close-toast">
        <button
          className="btn btn-link pl-0 btn-close-toast pr-0 text-right"
          onClick={() => {
            toast.dismiss();
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export const DeconstructionErr = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="notificationHeading">IMC</p>
      <p className="notificationText">
        Asset De-construction failed. Please try again.
      </p>
    </>
  );
};
