import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import CustomModal from "../../customModal";
import TranslateClaimsGenericModal from "../../TranslateFlow/TranslateClaims/TranslateClaimGenericModal";
import TranslateFootnotes from "./TranslateFootnotes";
import TranslateAbbreviations from "./TranslateAbbreviations";
import TranslateReferences from "./TraslateReferences";
import Menu from "../TranslateClaims/Menu";
import Comments from "../Comments";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

import arrowLeft from "../../../images/arrowLeft.svg";
import arrowRight from "../../../images/arrowRight.svg";
import translateIcon from "../../../images/translateIcon.svg";
import translateTick from "../../../images/translateTick.svg";
import commentIcon from "../../../images/icon-comment.svg";
import arrowpolygonRight from "../../../images/arrowpolygon-right.svg";
import "../../../styles/pages/TranslateClaims.scss";
import "../../../styles/components/PrimaryClaim.scss";

const TranslatePrimaryClaim = ({
  module,
  isAnyToggleOn,
  saveTranslated,
  discardTranslated,
  addComment,
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [showGenericTranslateModal, setShowGenericTranslateModal] =
    useState(false);
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);
  const [dataTypeSelectedForTranslation, setDtaTypeSelectedForTranslation] =
    useState("primary");
  let promotionalRef = useRef(null);

  //For Comments
  const [boxComments, setBoxComments] = useState([]);
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showFootnotesCommentsDialog, setShowFootnotesCommentsDialog] =
    useState(false);
  const [showAbbreviationsCommentsDialog, setShowAbbreviationsCommentsDialog] =
    useState(false);

  const setVariantIndex = (stepValue) => {
    const primaryClaim = getPrimaryClaim();
    const variants = primaryClaim.claimVariants;
    if (selectedIndex >= 1 && selectedIndex <= variants.length + 1) {
      //+1 for primary claim
      if (selectedIndex === 1 && stepValue === -1) {
        return;
      } else if (selectedIndex === variants.length + 1 && stepValue === 1) {
        return;
      }
      setSelectedIndex(selectedIndex + stepValue);
    }
  };
  const getPrimaryClaimsAndVarinat = () => {
    const primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      const variants = primaryClaim.claimVariants;
      return [primaryClaim].concat(variants); //1 for primary claim
    }
    return [];
  };
  const getClaimAndVariantsCount = () => {
    const primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      const variants = primaryClaim.claimVariants;
      return variants.length + 1; //1 for primary claim
    }
  };

  const getPrimaryClaim = () => {
    if (module && module.moduleClaims) {
      const allClaims = module.moduleClaims;
      const primaryClaims = allClaims.filter((claim) => {
        return (
          claim.type.trim().toLowerCase() === "primary" &&
          claim.translate !== "0"
        );
      });
      if (primaryClaims.length > 0) {
        return primaryClaims[0];
      }
    }
    return undefined;
  };

  const getFootnotes = () => {
    const primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      if (primaryClaim.supportingTextComponents.length > 0) {
        return primaryClaim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "footnote";
        });
      }
    }
    return [];
  };

  const getAbbreviations = () => {
    const primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      if (primaryClaim.supportingTextComponents.length > 0) {
        return primaryClaim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "abbreviation";
        });
      }
    }
    return [];
  };

  const getReferences = () => {
    const primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      if (primaryClaim.supportingTextComponents.length > 0) {
        return primaryClaim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "reference";
        });
      }
    }
    return [];
  };

  const getPrimaryClaimBoxData = () => {
    const primaryClaim = getPrimaryClaim();

    if (primaryClaim) {
      // let toBeRendered = isAnyToggleOn
      //   ? primaryClaim.originalClaim
      //   : primaryClaim.claim;
      let toBeRendered = primaryClaim.claim;

      if (selectedIndex > 1) {
        const variants = primaryClaim.claimVariants;
        //-2, because default selected index varibale value is 1 and another 1 is for primary claim
        // toBeRendered = isAnyToggleOn
        //   ? variants[selectedIndex - 2].originalModuleClaim
        //   : variants[selectedIndex - 2].moduleClaim;
        toBeRendered = variants[selectedIndex - 2].moduleClaim;
      }
      return toBeRendered;
    }
    return "";
  };
  const intiateTranslationFor = (data, type) => {
    setDataInputForTranslation(() => {
      openGenericTranslateModal();
    });
    setDataInputForTranslation(data);
    setDtaTypeSelectedForTranslation(type);
  };
  const handleCloseGenericModal = () => {
    setShowGenericTranslateModal(false);
    discardTranslated();
  };
  const openGenericTranslateModal = () => {
    setShowGenericTranslateModal(true);
  };

  const handleSave = () => {
    setShowGenericTranslateModal(false);
    saveTranslated();
  };
  const checkForTranslate = () => {
    if (isAnyToggleOn) {
      return false;
    }
    let primaryClaim = getPrimaryClaim();
    if (primaryClaim) {
      const variants = primaryClaim.claimVariants;
      primaryClaim = [primaryClaim].concat(variants);
      let result = true;
      for (var i in primaryClaim) {
        if (primaryClaim[i].translate !== "2") {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  };

  const addAComment = (selectedText) => {
    const primaryClaimId = getPrimaryClaim().id;
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: primaryClaimId,
      componentId: primaryClaimId,
    };
    addComment(commentObject);
  };
  const consolidateComments = (boxName) => {
    let comments = [];
    const allComments = module.comments;
    const primaryClaim = getPrimaryClaim();
    const primaryClaimId = primaryClaim.id;
    switch (boxName) {
      case "primaryClaim":
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === primaryClaimId &&
            comment.componentId === primaryClaimId
          );
        });
        break;
      case "footnote":
        const footnotes = primaryClaim.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "footnote";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            footnotes.findIndex((footnote) => {
              return (
                comment.primaryComponentId === primaryClaimId &&
                footnote.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      case "abbreviation":
        const abbreviations = primaryClaim.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "abbreviation";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            abbreviations.findIndex((abbreviation) => {
              return (
                comment.primaryComponentId === primaryClaimId &&
                abbreviation.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      default:
        break;
    }
    return comments;
  };

  const getCommentBubbleFor = (boxName) => {
    const comments = consolidateComments(boxName);
    if (!comments || comments.length === 0) {
      return;
    }

    return (
      <div
        style={{
          float: "right",
          marginRight: `${hasUserLoggedInAsReviewer() ? "0px" : "50px"}`,
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          className="commentIcon"
          onClick={() => {
            if (comments && comments.length > 0) {
              setBoxComments(() => {
                //hide the comments on click if already opened
                let flag = !showCommentsDialog;
                if (boxName === "abbreviation") {
                  flag = !showAbbreviationsCommentsDialog;
                }
                if (boxName === "footnote") {
                  flag = !showFootnotesCommentsDialog;
                }
                handleShowCommentsDialog(boxName, flag);
              });
              setBoxComments(comments);
            }
          }}
        />
      </div>
    );
  };

  const handleShowCommentsDialog = (boxName, showFlag) => {
    switch (boxName) {
      case "abbreviation":
        setShowAbbreviationsCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
      case "footnote":
        setShowFootnotesCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowAbbreviationsCommentsDialog(false);
        break;
      default:
        setShowCommentsDialog(showFlag);
        setShowAbbreviationsCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
    }
  };
  const getCommentsData = () => {
    setShowCommentsDialog(false);
    setShowAbbreviationsCommentsDialog(false);
    setShowFootnotesCommentsDialog(false);
    discardTranslated();
  };
  return (
    <>
      {getPrimaryClaim() && (
        <>
          <Row className="mb-4 align-items-center no-gutters">
            <Col className="col-md-3">
              <img src={arrowpolygonRight} alt="" className="claimTitleImg" />
              <span className="claimTitle">{t("primaryClaim.name")}</span>
            </Col>
          </Row>
          <Row className="align-items-center translateRow">
            <Col className="col-12 col-md-3">
              <div className="translateBox translateBoxType1 translateBoxSelected custonScrollBar">
                <p className="translateBox-head d-flex justify-content-between">
                  {t("primaryClaim.name")}
                  {checkForTranslate() && (
                    <span className="translatedBoxSuccess">
                      <img src={translateTick} alt="Translate" />
                    </span>
                  )}
                </p>
                <div className="translateClaimsContainer customScrollBar">
                  <div
                    className="translateClaimText"
                    ref={promotionalRef}
                    id="promotional-claim"
                  >
                    {getPrimaryClaimBoxData() &&
                      parse(getPrimaryClaimBoxData())}
                  </div>
                </div>
                {getPrimaryClaim()?.claimVariants.length > 0 && (
                  <div className="button-row" style={{ zIndex: "100" }}>
                    <button
                      className="leftBtn"
                      onClick={() => {
                        setVariantIndex(-1);
                      }}
                    >
                      <img
                        src={arrowLeft}
                        alt="Previous"
                        className="opacity50"
                      />
                    </button>
                    <span className="slideCount">
                      {selectedIndex}/{getClaimAndVariantsCount()}
                    </span>
                    <button
                      className="rightBtn"
                      onClick={() => {
                        setVariantIndex(1);
                      }}
                    >
                      <img src={arrowRight} alt="Next" className="opacity50" />
                    </button>
                  </div>
                )}
                <div className="floating-buttons">
                  {!isAnyToggleOn &&
                    !hasUserLoggedInAsReviewer() &&
                    getPrimaryClaim() && (
                      <div>
                        <button
                          className="btn btn-link translate-icon"
                          style={{ marginRight: "-12px", bottom: "0" }}
                          onClick={() => {
                            intiateTranslationFor(
                              getPrimaryClaimsAndVarinat(),
                              "primary"
                            );
                          }}
                        >
                          <img src={translateIcon} alt="Translate" />
                        </button>
                      </div>
                    )}
                  {getCommentBubbleFor("primaryClaim")}
                </div>
                {showCommentsDialog && (
                  <Comments
                    moduleId={module.id}
                    componentId={getPrimaryClaim()?.id}
                    childComponentId=""
                    comments={boxComments}
                    commentsPopupCurrentState={showCommentsDialog}
                    commentsPopupUpdateState={setShowCommentsDialog}
                    getCommentsData={getCommentsData}
                  />
                )}
              </div>
              {!isAnyToggleOn && (
                <Menu
                  outerRef={promotionalRef}
                  compClass="promotional-claim"
                  addComment={addAComment}
                />
              )}
            </Col>
            <Col className="col-12 col-md-3">
              <TranslateFootnotes
                footnotes={getFootnotes()}
                isAnyToggleOn={isAnyToggleOn}
                targetLanguage={module.language}
                intiateTranslationFor={intiateTranslationFor}
                addComment={addComment}
                getCommentBubbleFor={getCommentBubbleFor}
                primaryComponentId={getPrimaryClaim().id}
                comments={boxComments}
                showCommentsFlag={showFootnotesCommentsDialog}
                updateShowCommentsFlag={setShowFootnotesCommentsDialog}
                getCommentsData={getCommentsData}
              />
            </Col>
            <Col className="col-12 col-md-3">
              <TranslateAbbreviations
                abbreviations={getAbbreviations()}
                isAnyToggleOn={isAnyToggleOn}
                targetLanguage={module.language}
                intiateTranslationFor={intiateTranslationFor}
                addComment={addComment}
                getCommentBubbleFor={getCommentBubbleFor}
                primaryComponentId={getPrimaryClaim().id}
                comments={boxComments}
                showCommentsFlag={showAbbreviationsCommentsDialog}
                updateShowCommentsFlag={setShowAbbreviationsCommentsDialog}
                getCommentsData={getCommentsData}
              />
            </Col>
            <Col className="col-12 col-md-3" disabled={true}>
              <TranslateReferences
                references={getReferences()}
                isAnyToggleOn={isAnyToggleOn}
              />
            </Col>
            <CustomModal
              displayModal={showGenericTranslateModal}
              hideModal={handleCloseGenericModal}
              title="Select the components you would like to translate"
              size="xl"
            >
              <TranslateClaimsGenericModal
                handleClose={handleCloseGenericModal}
                sourceLanguage={module.translatedFrom}
                targetLanguage={module.language}
                dataInput={dataInputForTranslation}
                dataType={dataTypeSelectedForTranslation}
                saveTranslated={handleSave}
              />
            </CustomModal>
          </Row>
        </>
      )}
    </>
  );
};

export default TranslatePrimaryClaim;
