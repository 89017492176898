//Import react dependencies
import React from "react";
import { Col, Row } from "react-bootstrap";

//Import custom compnents
import { OptOutTextBox, OptOutVisualBox } from "../../Optouts";

const Optouts = ({ optouts = [] }) => {
  const getBoxData = (optout, type) => {
    const data = optout.moduleChannelSubtexts.find((el) => {
      return el.subtextType.toLowerCase() === type;
    });
    if (data) {
      return data;
    }
    return {};
  };

  const getOptoutBoxTitle = (optout, type) => {
    const data = optout.moduleChannelSubtexts.find((el) => {
      return el.subtextType.toLowerCase() === type;
    });
    if (data) {
      return data.cmDocumentNumber || "";
    }
    return "";
  };

  return (
    <div className="channel-attr-container customScrollBar">
      {optouts.map((optout, index) => {
        return (
          <div className="optouts" style={{ marginTop: "0px" }}>
            <Row>
              <OptOutTextBox
                optout={getBoxData(optout, "optouttext")}
                index={index}
                title={getOptoutBoxTitle(optout, "optouttext")}
                readOnly={true}
              />
              {/* <OptOutVisualBox
                optout={getBoxData(optout, "optoutvisual")}
                index={index}
                title={getOptoutBoxTitle(optout, "optoutvisual")}
                readOnly={true}
              /> */}
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Optouts;
