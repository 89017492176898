import i18next from "i18next";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import videoIcon from "../images/videoColor.svg";
import mlrStatusInDraft from "../images/mlr_status_draft.svg";
import mlrStatusApproved from "../images/mlr_status_approved.svg";
import mlrStatusAppChanges from "../images/mlr_status_approvedwithchanges.svg";
import mlrStatusRejected from "../images/mlr_status_rejected.svg";
import mlrStatusReSubmit from "../images/mlr_status_revise_resubmit.svg";
import mlrStatusSubmitted from "../images/mlr_status_submittedtoMLR.svg";
import mlrStatusContentCreatorUpload from "../images/mlr_status_contentcreatorupload.svg";
import mlrStatusInReview from "../images/mlr_status_inreview.svg";
import mlrStatusDistribution from "../images/mrl_status_approved_distribution.svg";
import refresh from "../images/refresh.svg";
import "../styles/components/CreateModuleNav.scss";
import "../styles/components/ModuleDetails.scss";
import { currentPage, routes } from "../Uitls/Util";
import "../styles/components/ModuleDetails.scss";
import { MLRStatus } from "../services/apis";
import { getVeevaLatestStatus } from "../services/apis";
import CircularProgressBar from "../Uitls/CircularProgressBar";
function CreateModuleNav(props) {
  const { t } = useTranslation();
  const isActive = props.currentpage;
  const { postCreateModule } = props;
  const [currentPage, setCurrentPage] = useState("");
  //const [options, setOptions] = useState({})
  let history = useHistory();
  const [activeClasses, setActiveClasses] = useState([]);
  const inputModuleName = document.getElementById("edit-module-name");
  const [startEditing, setstartEditing] = useState(false);
  const [mlrModules, setMlrModules] = useState(undefined);
  //const [isloading, setIsloading] = useState(false);
  const [tabs, setTabs] = useState(undefined);

  useEffect(() => {
    if (inputModuleName) {
      resize();
    }
  }, [props]);

  useEffect(() => {
    configureTabsNames();
  }, [props.isNotDenovoModule]);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setCurrentPage(props.currentPage);
    }
  }, [tabs]);

  const configureTabsNames = () => {
    const { isNotDenovoModule } = props;
    let tabNames = [];

    if (isNotDenovoModule) {
      tabNames.push({
        name: "Import",
        id: "nav-opt-import",
        class: "selected-nav-item",
        path: routes.assetDetails,
      });
    }
    tabNames.push({
      name: `${t("primaryClaim.navLabel")}`,
      id: "nav-opt-claim",
      class: "",
      path: routes.addPrimaryClaim,
    });
    if (isNotDenovoModule) {
      tabNames.push({
        name: "Grouping",
        id: "nav-opt-group",
        class: "",
        path: routes.addGroups,
      });
    }
    tabNames.push({
      name: "Business Rules",
      id: "nav-opt-brules",
      class: "",
      path: routes.addBusinessRules,
    });

    // tabNames.push({
    //   name: "Additional Info",
    //   id: "nav-opt-addinfo",
    //   class: "",
    //   path: routes.additionalInfo,
    // });

    setTabs(tabNames);
  };

  useEffect(() => {
    if (activeClasses?.length > 0) {
      let currentSpan = undefined;
      activeClasses[activeClasses.length - 1] = "";
      const pages = tabs?.map((tab) => tab.path); //Object.values(routes);
      const currentOptIndex = activeClasses.length - 1;
      switch (currentOptIndex) {
        case pages.indexOf(routes.additionalInfo):
          //currentSpan = document.getElementById("nav-opt-addinfo");
          activeClasses[activeClasses.length - 1] = "active";
          break;

        // case pages.indexOf(routes.addChannels):
        //   currentSpan = document.getElementById("nav-opt-channels");
        //   break;
        case pages.indexOf(routes.addBusinessRules):
          activeClasses[activeClasses.length - 1] = "active";
          //currentSpan = document.getElementById("nav-opt-brules");
          break;
        case pages.indexOf(routes.addGroups):
          currentSpan = document.getElementById("nav-opt-group");
          break;
        case pages.indexOf(routes.addPrimaryClaim):
          currentSpan = document.getElementById("nav-opt-claim");
          break;
        case pages.indexOf(routes.assetDetails):
          currentSpan = document.getElementById("nav-opt-import");
          break;
        default:
          break;
      }
      if (currentSpan) {
        currentSpan.className = "selected-nav-item";
      }
    }
  }, [activeClasses]);
  const resize = (isEditing = false) => {
    if (props.isImportPage) {
      return;
    }
    if (isEditing) {
      inputModuleName.style.width = " 172px";
      return;
    }

    const text = inputModuleName.value;
    if (text.trim().length * 7 < 172) {
      inputModuleName.style.width = text.trim().length * 7 + 9 + "px";
    } else {
      inputModuleName.style.width = " 172px";
    }
  };

  // if(inputModuleName){
  //   //resize()
  //   inputModuleName.addEventListener("input", resize);
  // }
  useEffect(() => {
    const pages = tabs?.map((tab) => tab.path); //Object.values(routes);
    let activeClasses = [];
    switch (currentPage) {
      case routes.additionalInfo:
        activeClasses[pages.indexOf(routes.additionalInfo)] = "active";

      // case routes.addChannels:
      //   activeClasses[pages.indexOf(routes.addChannels)] = "active";

      case routes.addBusinessRules:
        activeClasses[pages.indexOf(routes.addBusinessRules)] = "active";

      case routes.addGroups:
        activeClasses[pages.indexOf(routes.addGroups)] = "active";

      case routes.addPrimaryClaim:
        activeClasses[pages.indexOf(routes.addPrimaryClaim)] = "active";

      case routes.assetDetails:
        if (props.isNotDenovoModule) {
          activeClasses[pages.indexOf(routes.assetDetails)] = "active";
        }

      default:
        break;
    }
    setActiveClasses(activeClasses);
  }, [currentPage]);

  const getCssClassToHighLight = (page) => {
    const pages = tabs?.map((tab) => tab.path); //Object.values(routes);
    if (activeClasses && activeClasses.length > 0) {
      const cssClass = activeClasses[pages.indexOf(page)];
      if (!props.isImportPage) {
        return cssClass || "";
      }
    }
    return "";
  };
  const desiredPage = () => {
    let path = "";
    // if(props.nextPage){
    //   path = nextPageUrl(props.nextPage)
    // }
    const currentPage = props.currentPage || "";
    switch (currentPage) {
      case routes.assetDetails:
        path = routes.addPrimaryClaim;
        break;
      case routes.addPrimaryClaim:
        path = routes.addGroups;
        break;
      case routes.addGroups:
        path = routes.addBusinessRules;
        break;
      // case routes.addChannels:
      //   path = routes.addBusinessRules;
      //   break;
      case routes.addBusinessRules:
        path = routes.additionalInfo;
        break;
      default:
        break;
    }
    const data = props.data;
    return {
      pathname: path,
      state: { data },
    };
  };

  const handleProceed = () => {
    if (props.handleProceed) {
      props.handleProceed();
      return;
    }

    if (!props.isNotDenovoModule) {
      props.handleGroupsPrcd();
    }
    // history.push(desiredPage());
  };

  const getVeevaModuleStatus = () => {
    const veevaModuleId = props.moduleId;
    const veevaDocId = props.veevaDocId;
    getVeevaLatestStatus(
      veevaModuleId,
      veevaDocId,
      (response, err, errorCode) => {
        if (response) {
          props.setMlrModuleStatus(response.data.mlrStatus);
        }
      }
    );
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-2 align-items-center">
          <Col>
            <div className="d-flex">
              {/* {(props.moduleName && props.moduleCode) &&
              <div className="createModuleTitle">
                {props.moduleName} | {""}
                {props.moduleCode}
              </div>
              } */}
              {props.moduleName && (
                <div className="createModuleTitle">
                  {/* <p> */}
                  <span id="hide" style={{ fontSize: "0px" }}></span>
                  <input
                    id="edit-module-name"
                    defaultValue={props.moduleName}
                    className="input-module-name createModuleTitle"
                    type="text"
                    fluid="true"
                    readOnly={true}
                    onDoubleClick={(event) => {
                      if (props.onEditName) {
                        event.target.readOnly = false;
                        event.target.className =
                          "input-module-name createModuleTitle input-module-name-editable";
                        setstartEditing(true);
                        resize(true);
                      }
                    }}
                    onBlur={(event) => {
                      if (props.onEditName) {
                        event.target.readOnly = true;
                        props.onEditName(event.target.value);
                        event.target.className =
                          "input-module-name createModuleTitle";
                      }
                      setstartEditing(false);
                    }}
                    onKeyUp={(event) => {
                      if (props.onEditName && event.key === "Enter") {
                        event.target.readOnly = true;
                        props.onEditName(event.target.value);
                        event.target.className =
                          "input-module-name createModuleTitle";
                        setstartEditing(false);
                      }
                    }}
                  />
                  {/* </p> */}
                  {props.moduleCode && (
                    <span>
                      | {""} {props.moduleCode}
                    </span>
                  )}
                </div>
              )}
              <div className="videoIconCm">
                <img src={videoIcon} alt="" />
                <span>View Tutorial</span>
              </div>
            </div>
          </Col>
          <Col className="text-right">
            <div className="d-flex justify-content-end">
              {props.moduleStatus && (
                <div className="MLRStatus">
                  <span className="imgMlr">MLR Status</span>
                  {props.moduleStatus === "In Draft" ||
                  props.moduleStatus === "Draft" ? (
                    <span className="">
                      <img
                        src={mlrStatusInDraft}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : props.moduleStatus === "Revise & Resubmit-MLR Review" ? (
                    <span className="">
                      <img
                        src={mlrStatusReSubmit}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : module.mlrStatus === "In-review" ? (
                    <span className="">
                      <img
                        src={mlrStatusInReview}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : module.mlrStatus === "In-review" ? (
                    <span className="">
                      <img
                        src={mlrStatusContentCreatorUpload}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : module.mlrStatus === "MLR Approved" ? (
                    <span className="">
                      <img
                        src={mlrStatusApproved}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : props.moduleStatus === "MLR Approved with changes" ? (
                    <span className="">
                      <img
                        src={mlrStatusAppChanges}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : props.moduleStatus === "MLR Approved" ? (
                    <span className="">
                      <img
                        src={mlrStatusDistribution}
                        alt={props.moduleStatus}
                        className="mlrStatusIconM"
                      />
                    </span>
                  ) : (
                    <>
                      {props.moduleStatus && (
                        <>
                          <span className="">{props.moduleStatus}</span>
                        </>
                      )}
                    </>
                  )}
                  {props.moduleStatus === "Draft" && (
                    <span className="mlrStatusBg">{props.moduleStatus}</span>
                  )}
                  <img
                    className="refreshIcon"
                    src={refresh}
                    alt=""
                    onClick={() => {
                      getVeevaModuleStatus();
                    }}
                  />
                </div>
              )}
              {props.thirdButton}
              {props.btnSave && (
                <Button
                  variant="outline-secondary mr-4 btnClose nav-buttons-margin"
                  onClick={() => {
                    props.handlePageSave();
                  }}
                  disabled={props.enableSave ? false : true}
                >
                  {props.btnSave}
                </Button>
              )}
              <Button
                variant="outline-secondary mr-4 btnClose nav-buttons-margin"
                onClick={props.handlePageClose}
                disabled={props.disableClose || false}
              >
                {props.btnClose || i18next.t("generalText.close")}
              </Button>
              {!props.shouldHaveMultipleOptions && (
                <Button
                  variant="primary btnProceed"
                  onClick={handleProceed}
                  disabled={props.disableProceed}
                >
                  {props.btnProceed || i18next.t("generalText.proceed")}
                </Button>
              )}
              {props.shouldHaveMultipleOptions && (
                <span>
                  {props.proceedOptions.map((option) => {
                    return (
                      <Button
                        as="button"
                        className="publish-option"
                        onClick={() => {
                          props.handleProceed(option);
                        }}
                      >
                        {i18next.t("generalText.publish")}
                      </Button>
                    );
                  })}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col style={{ paddingRight: "0px", paddingLeft: "0px" }}>
            <ul className="createModuleNav">
              {tabs?.map((tabName, index) => {
                return (
                  <li className={getCssClassToHighLight(tabName.path)}>
                    <span id={tabName.id} className={tabName.class}>
                      {index + 1}. {tabName.name}{" "}
                    </span>
                  </li>
                );
              })}
              {/* <li className={getCssClassToHighLight(routes.assetDetails)}>
                <span id="nav-opt-import" className="selected-nav-item">
                  1. Import{" "}
                </span>
              </li>
              <li className={getCssClassToHighLight(routes.addPrimaryClaim)}>
                <span id="nav-opt-claim">2. {t("primaryClaim.navLabel")} </span>
              </li>
              <li className={getCssClassToHighLight(routes.addGroups)}>
                <span id="nav-opt-group">3. Grouping </span>
              </li>
              <li className={getCssClassToHighLight(routes.addBusinessRules)}>
                <span id="nav-opt-brules">4. Business Rules</span>
              </li> */}
              {/* <li className={getCssClassToHighLight(routes.addChannels)}>
                <span id="nav-opt-channels">5. Channel </span>
              </li>
              <li className={getCssClassToHighLight(routes.additionalInfo)}>
                <span id="nav-opt-addinfo">5. Additional Info</span>
              </li>
              <li>7. Labelling & Mandatory Info</li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateModuleNav;
