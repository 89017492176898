import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import LogoutCTA from "../Pages/LogoutCTA";
import { removeModuleInfo } from "../Uitls/Util";
import HeaderDefaultDashboard from "../components/Headers/HeaderDefaultDashboard";

import mmDashboardLogo from "../images/NEXT-Module-logo.svg";

import "../styles/pages/Dashboard.scss";

function Dashboard() {
  useEffect(() => {
    removeModuleInfo();
  }, []);

  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  return (
    <>
      <div className="mt-2">
        <HeaderDefaultDashboard logoNotRequired={true} />
      </div>
      <div className="dasboardContent text-center">
        <div className="mb-5">
          <img
            src={mmDashboardLogo}
            alt="Dashboard"
            width="350"
            className="img-fluid"
          />
        </div>
        {/* <h1 className="dasboardTitle">Dashboard</h1> */}
      </div>

      <LogoutCTA />
    </>
  );
}

export default Dashboard;
