import axios from "axios";
import { getCSEmailCognitoToken } from "./apis";

const csEmailEndPoint = "https://vcc-builder-qa.indegene.com";
export const csEmailInstance = axios.create({
  baseURL: csEmailEndPoint,

  timeout: 15000,
});

// Add a request interceptor

csEmailInstance.interceptors.request.use(
  async function (config) {
    const res = await getCSEmailCognitoToken(); //  const token = "12345678dkdklsdf";

    if (res && res.data) {
      config.headers["Authorization"] = "Bearer " + res.data;
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor

csEmailInstance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    return Promise.reject(error);
  }
);
