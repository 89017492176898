import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import DropDownWithLabel from "../../../components/DropDownWithLabel";
import DropDownWithSearch from "../../../Uitls/DropDownWithSearch";
import CustomModal from "../../../components/customModal";
import BrowseGenericInfoDocument from "./BrowseGenericInfoDocument";
import { Form as ReactForm } from "react-bootstrap";

import "../../../styles/pages/UplaodMasterAsset.scss";
import "../../../styles/CustomToastify.scss";

import clearTextIcon from "../../../images/clear_text.svg";

const HarvestedGenericDetails = (props) => {
  const [audienceDataSource, setAudienceDataSource] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const { t } = useTranslation();
  const formRef = useRef();
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [othersfilled, setOthersFilled] = useState(false);
  const [disableCreateModule, setDisableCreateModule] = useState(true);
  const [channels, setChannels] = useState([]);
  const [genreicInfoData, setGenericInfoData] = useState({
    isi: "",
    pi: "",
    mg: "",
    masa: "",
  });
  const [showBrowseGenInfoDocModal, setShowBrowseGenInfoDocModal] =
    useState(false);
  const [browseDocType, setBrowseDocType] = useState("");

  //Only for dams flow
  const [assetType, setAssetType] = useState(undefined);

  useEffect(() => {
    resetForm();
    resetAudience();
    resetMarketData();
    if (props.data) {
      const brandsAndCampaigns = props.data.brandsAndCampaigns;
      const audience = props.data.audience;
      if (brandsAndCampaigns) {
        setBrandsAndCampaigns(brandsAndCampaigns);
      }
      if (audience) {
        setAudienceDataSource(audience);
      }
    }
    if (props.selectedTactic) {
      setSelectedMarket(getMarketByName(props.selectedTactic.market));

      setSelectedAudience(
        Array.from(new Set(props.selectedTactic.audience)) || []
      );
      const tactic = props.selectedTactic;
      setGenericInfoData({
        ...genreicInfoData,
        masa: tactic.masterAssetLink || "",
      });

      if (
        tactic.brand &&
        tactic.channelType &&
        tactic.campaign &&
        tactic.audience &&
        tactic.market &&
        tactic.description &&
        tactic.dosing &&
        tactic.audienceSegment
      ) {
        setDisableCreateModule(false);
      } else {
        setDisableCreateModule(true);
      }
    }
  }, [props.selectedTactic]);

  useEffect(() => {
    setChannelsData();
  }, []);

  const setChannelsData = () => {
    let channelDataSource = [];
    channelDataSource.push(i18next.t("channelTypeSelect.detailingAid"));
    channelDataSource.push(i18next.t("channelTypeSelect.email"));
    channelDataSource.push(i18next.t("channelTypeSelect.banner"));
    channelDataSource.push(i18next.t("channelTypeSelect.socialMediaAdv"));
    channelDataSource.push(i18next.t("channelTypeSelect.print"));
    channelDataSource.push(i18next.t("channelTypeSelect.alert"));
    setChannels(channelDataSource);
  };

  //Get Brand Object based on the selectd brand name
  const getBrandByName = (brand) => {
    if (brandsAndCampaigns && brandsAndCampaigns.length > 0) {
      return [
        ...brandsAndCampaigns.filter((object) => {
          return object["brandName"] === brand;
        }),
      ][0];
    }
    return { brandId: "", brandName: "" };
  };

  //Get Campaign Object based on the selectd Campaign name
  const getCampaignByBrandAndName = (brand, campaign) => {
    if (brandsAndCampaigns && brandsAndCampaigns.length > 0) {
      const selectedBrand = [
        ...brandsAndCampaigns.filter((object) => {
          return object["brandName"] === brand;
        }),
      ][0];

      if (selectedBrand) {
        let campaigns = selectedBrand["campaigns"];
        if (campaigns && campaigns.length > 0) {
          return campaigns.filter((object) => {
            return object["campaignName"] === campaign;
          })[0];
        }
      }
    }
    return { campaignId: "", campaignName: "" };
  };

  const getMarketByName = (marketName) => {
    const marketDataSource = props.data.marketDataSource;
    let selectedMarket = undefined;
    if (marketDataSource && marketDataSource.length > 0 && marketName) {
      selectedMarket = marketDataSource.find((market) => {
        return (
          market.displayName === marketName ||
          market.name === marketName ||
          market.code === marketName ||
          market.shortName === marketName
        );
      });
    }
    return selectedMarket;
  };

  //Returns Brands list to populate in Drop down
  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns].map((object) => {
      return object.brandName;
    });
  };

  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (values) => {
    if (values.Brand) {
      const campaigns = [...brandsAndCampaigns].filter((object) => {
        return object.brandName === values.Brand;
      })[0]?.campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };
  //Check whether Audience is selected or not
  const validateSelectedAudience = (values) => {
    const valueKeys = Object.keys(values);
    const commonKeys = valueKeys.filter((item1) => {
      return audienceDataSource.some(
        (item2) => item1.trim().toLowerCase() === item2.trim().toLowerCase()
      );
    });
    let selectedOnes = [];
    for (let key of commonKeys) {
      if (
        values[key] &&
        values[key].length > 0 &&
        values[key][0].toString().toLowerCase() === "on"
      ) {
        selectedOnes.push(key);
      }
    }
    setSelectedAudience(selectedOnes);
    return selectedOnes;
  };

  const handleMarketChange = (market) => {
    setSelectedMarket(market);
    if (market && othersfilled) {
      setDisableCreateModule(false);
    } else {
      setDisableCreateModule(true);
    }
  };
  const validateMarket = () => {
    if (!selectedMarket) {
      return false;
    }
    return true;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Brand) {
      // errors.campaignUsed = "Campaign it was used required";
      errors.brand = i18next.t("uploadAssetLabel.brandError");
    }
    if (!values[i18next.t("uploadAssetLabel.channelTypeItWasUsed")]) {
      // errors.campaignUsed = "Campaign it was used required";
      errors.channel = i18next.t("uploadAssetLabel.channelErr");
    }
    const campaignErr = i18next.t("uploadAssetLabel.campaignError");
    if (!values.Campaign) {
      errors.campaign = campaignErr;
    } else if (values.Campaign.trim() === "") {
      errors.campaign = campaignErr;
    } else {
      if (values.Brand) {
        const campaign = getCampaignByBrandAndName(
          values.Brand,
          values.Campaign
        );
        if (!campaign || campaign.campaignId === "") {
          errors.campaign = campaignErr;
        }
      }
    }

    const selectedAudience = validateSelectedAudience(values);
    if (!selectedAudience || selectedAudience.length <= 0) {
      errors.audience = i18next.t("uploadAssetLabel.audienceError");
    }
    if (!values.description || values.description.trim() === "") {
      errors.description = i18next.t("uploadAssetLabel.desError");
    }
    if (!values.dosing || values.dosing.trim() === "") {
      errors.dosing = i18next.t("uploadAssetLabel.dosingError");
    }
    if (!values.audienceSegment || values.audienceSegment.trim() === "") {
      errors.audienceSegment = i18next.t(
        "uploadAssetLabel.audienceSegmentError"
      );
    }
    if (Object.keys(errors).length === 0) {
      setOthersFilled(true);
      if (validateMarket()) {
        setDisableCreateModule(false);
      } else {
        setDisableCreateModule(true);
      }
    } else {
      setOthersFilled(false);
      setDisableCreateModule(true);
    }
    return errors;
  };

  //It will make audience check boxes false
  const resetAudience = () => {
    for (const aud of audienceDataSource) {
      const auds = document.getElementsByName(aud);
      if (auds && auds.length > 0) {
        auds[0].checked = false;
      }
    }
  };

  const resetMarketData = () => {
    setSelectedMarket(undefined);
  };

  const getDefaultValue = (type) => {
    if (!props.selectedTactic) {
      return undefined;
    }
    const tactic = props.selectedTactic;
    let value = "";
    switch (type) {
      case "brand":
        value = tactic.brand || "";
        break;
      case "campaign":
        value = tactic.campaign || tactic.campaignName || "";
        break;
      case "market":
        break;
      case "audience":
        value = tactic.audience || "";
        break;
      case "description":
        value = tactic.description || "";
        break;
      case "dosing":
        value = tactic.dosing || "";
        break;
      case "audienceSegment":
        value = tactic.audienceSegment || "";
        break;
      case "therapeuticArea":
        value = tactic.therapeuticArea || "";
        break;
      case "channel":
        value = tactic.channelType || "";
        break;
      case "genericBusinessRules":
        value = tactic.genericBusinessRules;
        break;
      default:
        value = "";
    }
    return value;
  };

  const isThisAudienceAdded = (audience) => {
    if (audience) {
      if (
        selectedAudience &&
        selectedAudience.findIndex(
          (aud) => aud.trim().toLowerCase() === audience.trim().toLowerCase()
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const getInitialvalues = () => {
    let initailValues = {
      Brand: props.selectedTactic ? getDefaultValue("brand") : "",
      Campaign: getDefaultValue("campaign"),
      description: getDefaultValue("description") || "",
      dosing: getDefaultValue("dosing") || "",
      audienceSegment: getDefaultValue("audienceSegment") || "",
      genericBusinessRules: getDefaultValue("genericBusinessRules") || "",
      therapeuticArea: getDefaultValue("therapeuticArea") || "",
    };
    initailValues[`${t("uploadAssetLabel.channelTypeItWasUsed")}`] =
      getDefaultValue("channel");
    for (let selAud of selectedAudience) {
      if (selAud.trim().toLowerCase() === "consumer") {
        selAud = "Consumer";
      }
      if (selAud.trim().toLowerCase() === "payer") {
        selAud = "Payer";
      }
      initailValues[selAud] = ["on"];
    }
    return initailValues;
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const resetForm = () => {
    if (formRef) {
      formRef.current.resetForm();
    }
  };

  const handleChangeOtherGenInfo = (event, type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: event.target.value });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: event.target.value });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: event.target.value });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: event.target.value });
    }
  };
  const clearOtherGenInfo = (type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: "" });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: "" });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: "" });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: "" });
    }
  };
  const browseOtherGenInfo = (type) => {
    setBrowseDocType(type);
    setShowBrowseGenInfoDocModal(true);
  };
  const hideBrowseGenInfoDocModal = () => {
    setShowBrowseGenInfoDocModal(false);
  };
  const updateGenericInfoUrl = (type, documentLink) => {
    setShowBrowseGenInfoDocModal(false);
    const obj = { ...genreicInfoData };
    obj[type.toLowerCase()] = documentLink;
    setGenericInfoData(obj);
  };

  return (
    <div>
      <div>
        <div className="col-md-12">
          <div>
            <span className="title-generic-info">
              {t("harvestedTactics.enterGenericInfo")}
            </span>
            {props.isDamsFlow && props.selectedTactic.locked !== "Locked" && (
              <button
                className="btn-save-generic-info"
                // type="submit"
                onClick={handleSubmit}
                disabled={disableCreateModule}
              >
                SAVE
              </button>
            )}
          </div>

          <Formik
            //enableReinitialize={true}
            initialValues={getInitialvalues()}
            validate={validate}
            onSubmit={(values, { resetForm }, initialValues) => {
              const genericInfo = {
                campaignId: getCampaignByBrandAndName(
                  values.Brand,
                  values.Campaign
                ).campaignId,
                campaign: values.Campaign,
                brandId: getBrandByName(values.Brand).brandId,
                brand: values.Brand,
                audience: selectedAudience,
                description: values.description,
                dosing: values.dosing,
                audienceSegment: values.audienceSegment,
                market: selectedMarket.displayName,
                channelType:
                  values[i18next.t("uploadAssetLabel.channelTypeItWasUsed")],
                isi: genreicInfoData.isi,
                pi: genreicInfoData.pi,
                mg: genreicInfoData.mg,
                genericBusinessRules: values.genericBusinessRules,
                therapeuticArea: values.therapeuticArea,
                assetType: assetType,
              };

              if (props.onClickSubmit) {
                props.onClickSubmit(genericInfo);
              }
            }}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 ">
                    <DropDownWithLabel
                      class={`${
                        props.selectedTactic.brand
                          ? "tf-and-drp-dwn-with-label-disabled"
                          : ""
                      }`}
                      options={{
                        value: values.Brand,
                        name: t("uploadAssetLabel.brand"),
                        label: t("uploadAssetLabel.brand"),
                        items: getBrandsToPopulate(),
                        handleChange: handleChange,
                        disabled: props.selectedTactic.brand,
                      }}
                    />
                    <TextField
                      className="add-geninfo-textfield master-asset-name"
                      id="masterAssetName"
                      label={t("uploadAssetLabel.masterAssetName")}
                      variant="outlined"
                      fullWidth={true}
                      handleChange={handleChange}
                      disabled={true}
                      value={props.selectedTactic.assetName}
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                    <DropDownWithLabel
                      options={{
                        value:
                          values[
                            i18next.t("uploadAssetLabel.channelTypeItWasUsed")
                          ],
                        name: t("uploadAssetLabel.channelTypeItWasUsed"),
                        label: t("uploadAssetLabel.channelTypeItWasUsed"),
                        items: channels,
                        handleChange: handleChange,
                      }}
                    />
                    <DropDownWithLabel
                      class={`${
                        props.selectedTactic.campaign ||
                        props.selectedTactic.campaignName
                          ? "tf-and-drp-dwn-with-label-disabled"
                          : ""
                      }`}
                      options={{
                        value: values.Campaign,
                        name: t("uploadAssetLabel.campaignItWasUsed"),
                        label: t("uploadAssetLabel.campaignItWasUsed"),
                        items: getCampaignListToPopulate(values),
                        handleChange: handleChange,
                        disabled:
                          props.selectedTactic.campaign ||
                          props.selectedTactic.campaignName,
                      }}
                    />
                    <DropDownWithSearch
                      class={`${
                        props.selectedTactic.market
                          ? "tf-and-drp-dwn-with-label-disabled"
                          : ""
                      }`}
                      options={{
                        value: selectedMarket,
                        name: t("uploadAssetLabel.market"),
                        label: t("uploadAssetLabel.market"),
                        items: props.data?.marketDataSource,
                        handleChange: handleMarketChange,
                        disabled: props.selectedTactic.market,
                      }}
                    />
                    <TextField
                      className="add-geninfo-textfield"
                      id="dosing"
                      label={t("uploadAssetLabel.dosing")}
                      variant="outlined"
                      fullWidth={true}
                      value={values.dosing}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.dosing && touched.dosing && errors.dosing
                      }
                      error={errors.dosing && touched.dosing ? true : false}
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                    <div className="genricInfoBtnContainer">
                      <TextField
                        className={`add-geninfo-textfield master-asset-name ${
                          genreicInfoData.masa ? "" : "asset-link-color"
                        }`}
                        id="genericMasterAssetLink"
                        label="Master Asset Link"
                        variant="outlined"
                        fullWidth={true}
                        // handleChange={handleChange}
                        onChange={(e) => {
                          handleChangeOtherGenInfo(e, "masa");
                        }}
                        value={genreicInfoData.masa}
                        autocomplete="new-password"
                        autoComplete="off"
                        disabled={genreicInfoData.masa}
                      />
                      {genreicInfoData.masa.length <= 0 ? (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            browseOtherGenInfo("Master Asset Link");
                          }}
                          disabled={genreicInfoData.masa}
                        >
                          Browse
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            clearOtherGenInfo("masa");
                          }}
                          disabled={genreicInfoData.masa}
                        >
                          <img src={clearTextIcon} alt="" width="12" />
                        </button>
                      )}
                    </div>
                    <TextField
                      className="add-geninfo-textfield"
                      id="audienceSegment"
                      label={t("uploadAssetLabel.audienceSegment")}
                      variant="outlined"
                      fullWidth={true}
                      value={values.audienceSegment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.audienceSegment &&
                        touched.audienceSegment &&
                        errors.audienceSegment
                      }
                      error={
                        errors.audienceSegment && touched.audienceSegment
                          ? true
                          : false
                      }
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-md-6 mt-0.5">
                    <TextField
                      className="add-geninfo-textfield description description-height denovoDescription"
                      id="description"
                      label={t("uploadAssetLabel.description")}
                      variant="outlined"
                      fullWidth={true}
                      value={values.description}
                      rows={9}
                      rowsMax={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      helperText={
                        errors.description &&
                        touched.description &&
                        errors.description
                      }
                      error={
                        errors.description && touched.description ? true : false
                      }
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                    <TextField
                      className="add-geninfo-textfield description description-height denovoDescription"
                      id="genericBusinessRules"
                      label="Generic Business Rules"
                      variant="outlined"
                      fullWidth={true}
                      value={values.genericBusinessRules}
                      rows={9}
                      rowsMax={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                    <div className="genricInfoBtnContainer">
                      <TextField
                        className="add-geninfo-textfield master-asset-name asset-link-color"
                        id="genericISI"
                        label="ISI"
                        variant="outlined"
                        fullWidth={true}
                        // handleChange={handleChange}
                        onChange={(e) => {
                          handleChangeOtherGenInfo(e, "isi");
                        }}
                        value={genreicInfoData.isi}
                        autocomplete="new-password"
                        autoComplete="off"
                      />
                      {genreicInfoData.isi.length <= 0 ? (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            browseOtherGenInfo("ISI");
                          }}
                        >
                          Browse
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            clearOtherGenInfo("isi");
                          }}
                        >
                          <img src={clearTextIcon} alt="" width="12" />
                        </button>
                      )}
                    </div>
                    <div className="genricInfoBtnContainer">
                      <TextField
                        className="add-geninfo-textfield master-asset-name asset-link-color"
                        id="genericPI"
                        label="PI"
                        variant="outlined"
                        fullWidth={true}
                        onChange={(e) => {
                          handleChangeOtherGenInfo(e, "pi");
                        }}
                        value={genreicInfoData.pi}
                        autocomplete="new-password"
                        autoComplete="off"
                      />
                      {genreicInfoData.pi.length <= 0 ? (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            browseOtherGenInfo("PI");
                          }}
                        >
                          Browse
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            clearOtherGenInfo("pi");
                          }}
                        >
                          <img src={clearTextIcon} alt="" width="12" />
                        </button>
                      )}
                    </div>
                    <div className="genricInfoBtnContainer">
                      <TextField
                        className="add-geninfo-textfield master-asset-name asset-link-color"
                        id="genericMG"
                        label="MG"
                        variant="outlined"
                        fullWidth={true}
                        //handleChange={handleChange}
                        onChange={(e) => {
                          handleChangeOtherGenInfo(e, "mg");
                        }}
                        value={genreicInfoData.mg}
                        autocomplete="new-password"
                        autoComplete="off"
                      />
                      {genreicInfoData.mg.length <= 0 ? (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            browseOtherGenInfo("MG");
                          }}
                        >
                          Browse
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link genricInfoBtn"
                          onClick={() => {
                            clearOtherGenInfo("mg");
                          }}
                        >
                          <img src={clearTextIcon} alt="" width="12" />
                        </button>
                      )}
                    </div>
                    <TextField
                      className="add-geninfo-textfield"
                      id="therapeuticArea"
                      label="Therapeutic Area"
                      variant="outlined"
                      fullWidth={true}
                      value={values.therapeuticArea}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.therapeuticArea &&
                        touched.therapeuticArea &&
                        errors.therapeuticArea
                      }
                      error={
                        errors.therapeuticArea && touched.therapeuticArea
                          ? true
                          : false
                      }
                      autocomplete="new-password"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="audience">
                      <span className="audience">
                        {t("uploadAssetLabel.audience")}
                      </span>
                    </div>

                    <div>
                      {audienceDataSource.map((item) => {
                        return (
                          <div
                            className="custom-control custom-checkbox custom-control-inline aud-check-box-with-label"
                            key={item}
                          >
                            <input
                              type="checkbox"
                              name={item}
                              className="custom-control-input audience-check-box"
                              id={item}
                              checked={isThisAudienceAdded(item)}
                              onChange={(event) => {
                                handleChange(event);
                                if (event.target.checked) {
                                  setSelectedAudience(
                                    selectedAudience.concat(item)
                                  );
                                } else {
                                  const newSelected = [
                                    ...selectedAudience,
                                  ].filter((aud) => {
                                    return (
                                      aud.trim().toLowerCase() !==
                                      item.trim().toLowerCase()
                                    );
                                  });
                                  setSelectedAudience(newSelected);
                                }
                                event.target.checked = !event.checked;
                              }}
                            ></input>
                            <label
                              className="custom-control-label aud-check-label"
                              htmlFor={item}
                            >
                              {item}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {props.isDamsFlow && (
                    <div className="col-md-6">
                      <div className="asset-types">
                        <ReactForm>
                          {["radio"].map((type) => (
                            <div
                              key={`inline-${type}`}
                              className="form-check form-check-inline mb-2"
                            >
                              <span className="title mr-5">Asset Type</span>
                              <ReactForm.Check
                                inline
                                label="Branded"
                                name="assetTypeOption"
                                type={type}
                                id={`inline-${type}-1`}
                                value="branded"
                                checked={assetType === "branded"}
                                onChange={() => {
                                  setAssetType("branded");
                                }}
                              />
                              <ReactForm.Check
                                inline
                                label="Unbranded"
                                name="assetTypeOption"
                                type={type}
                                id={`inline-${type}-2`}
                                value="unbranded"
                                checked={assetType === "unbranded"}
                                onChange={() => {
                                  setAssetType("unbranded");
                                }}
                              />
                            </div>
                          ))}
                        </ReactForm>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {!props.isDamsFlow && (
        <div className="row mt-4">
          <div className="col-md-12 text-right ">
            <div className="form-group pl-3 pr-3">
              <button
                type="submit"
                className="btn btn-primary text-uppercase btn-block harvested-btn-create-module"
                onClick={handleSubmit}
                disabled={disableCreateModule}
              >
                {t("generalText.createModule").toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        displayModal={showBrowseGenInfoDocModal}
        hideModal={hideBrowseGenInfoDocModal}
        title={"Existing Modules detected"}
        size="xl"
        dilogClassName="alertModalSmall modalNoborder"
        //keyboard={false}
      >
        <BrowseGenericInfoDocument
          browseDocType={browseDocType}
          hideBrowseGenInfoDocModal={hideBrowseGenInfoDocModal}
          updateGenericInfoUrl={updateGenericInfoUrl}
          sourceType={props?.selectedTactic?.sourceType}
        />
      </CustomModal>
    </div>
  );
};

export default HarvestedGenericDetails;
