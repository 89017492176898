import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { renderToString } from "react-dom/server";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import {
  routes,
  updateModuleInfo,
  removeModuleInfo,
  moduleTypes,
  getDownloadModuleCss,
  getCurrentUser,
  isThisMLRFeedbackFlow,
  damsSources,
} from "../Uitls/Util";
import HeaderCreateModule from "../components/HeaderCreateModule";
import ModuleDetailsNav from "../components/ModuleDetailsNav";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import {
  getAssetDetails,
  getModuleDetailsById,
  downloadModule,
  getDenovoModulesByBrandAndCampaign,
  publishModuleToVvpm,
  getVeevaDownloadAnnotated,
  getExcludeModulesBusinessRules,
  getLatestAnnotatedPdfFromVeeva,
} from "../services/apis";

import CircularProgressBar from "../Uitls/CircularProgressBar";
import ModuleSpecificInfo from "../components/MlrSubmission/ModuleSpecificInfo";
import BusinessRulesSpecificInfo from "../components/MlrSubmission/BusinessRulesSpecificInfo";
import ClaimAndVisualsSpecificInfo from "../components/MlrSubmission/ClaimAndVisualsSpecificInfo";
import ChannelSpecificInfo from "../components/MlrSubmission/ChannelSpecificInfo";
import LogoutCTA from "./LogoutCTA";
import SupportingVisualsImage from "../components/MlrSubmission/SupportingVisualsImage";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";

import "../styles/pages/CreateModule.scss";
import "../styles/pages/MLRSubmissionPackage.scss";
import "../styles/pages/ModulePreviewMlrFeedbackFlow.scss";
import "../styles/components/ModuleDetails.scss";

import Tick from "../images/Tick.svg";
import { sortClaimsAndVisualsByRecordIndex } from "./ModuleDetails";

const ModulePreviewMlrFeedbackFlow = (props) => {
  let history = useHistory();
  const [getPdfwidth, setGetPdfWidth] = useState(0);

  const elementRef = useRef(null);
  const [assetDetails, setAssetDetails] = useState({});
  const [moduleId, setModuleId] = useState(undefined);
  const [moduleDetails, setModuleDetails] = useState({});
  const [pages, setPages] = useState([]);
  const [filteredPages, setFiletredPages] = useState([]);
  const [bModules, setbModules] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [moduleSpecificJsx, setModuleSpecificJsx] = useState(undefined);
  const [businessSpecificJsx, setBusinessSpecificJsx] = useState(undefined);
  const [claimsSpecificJsx, setClaimsSpecificJsx] = useState(undefined);
  const [channelSpecificJsx, setChannelSpecificJsx] = useState(undefined);
  const [visualImageSpecificJsx, setvisualImageSpecificJsx] =
    useState(undefined);

  const [brandId, setBrandId] = useState(undefined);
  const [pdfUrl, setPdfUrl] = useState(undefined);
  const [annotatedPdf, setAnnotatedPdf] = useState(undefined);

  useEffect(() => {
    if (props && props.location && props.location.state) {
      const moduleId = props.location.state.moduleId;
      const brandId = props.location.state.brandId;
      onSelectSearchedModule(moduleId, brandId);
    }
  }, []);

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const onSelectSearchedModule = (id, brandId) => {
    if (id) {
      setModuleId(id);
      setBrandId(brandId);
      setIsloading(true);
      getModuleDetailsById(id, (response, err, errCode) => {
        if (err) {
          setIsloading(false);
          const errCoponent = ErrComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          if (response.data.storageAssetId) {
            getCreatedAssetDetails(response.data.storageAssetId);
          }

          setModuleDetails(response.data);
          fetchAnnotatedPdf(response.data);
          const moduleType = response.data.creationMethodType;
          if (moduleType && moduleType.toLowerCase() === moduleTypes.denovo) {
            denovoBusinessRuleModules(
              response.data.brandName,
              response.data.campaign,
              response.data.id
            );
          } else {
            const module = response.data;
            getTacticBusinessRuleModules(
              module.brandName,
              module.campaign,
              module.moduleType,
              module.sourceModuleId,
              module.id
            );
          }
        }
      });
    }
  };
  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      setIsloading(true);
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        setIsloading(false);
        if (res) {
          setAnnotatedPdf(res.data);
          return;
        }
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      });
    }
  };

  const getTacticBusinessRuleModules = (
    brandName,
    campaign,
    moduleType,
    sourceModuleId,
    moduleId
  ) => {
    let payLoad = {
      brandName: brandName,
      campaign: campaign,
      moduleType: moduleType,
      excludeModules: [moduleId],
    };
    if (moduleType !== "Original") {
      payLoad.sourceModuleId = sourceModuleId;
    }
    if (payLoad) {
      getExcludeModulesBusinessRules(
        payLoad,
        moduleId,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(errorCode);
          } else {
            setbModules(response.data);
          }
        }
      );
    } else {
      setIsloading(false);
    }
  };

  const denovoBusinessRuleModules = (brand, campaign, moduleId) => {
    if (!brand || !campaign) {
      setIsloading(false);
      return;
    }
    getDenovoModulesByBrandAndCampaign(
      { brand, campaign, moduleId },
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          setbModules(response.data);

          return;
        }
        showError(errCode);
      }
    );
  };

  const getCreatedAssetDetails = (assetId) => {
    getAssetDetails((response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        setAssetDetails(response.data);
      }
    }, assetId);
  };

  const prepareAssetDetailsData = () => {
    const children = assetDetails.children;
    if (children) {
      let pages = [];
      pages = children.filter((child) => {
        return child.type === "Page";
      });
      pages = pages.map((page) => {
        const atoms = children.filter((child) => {
          return child.type === "Atom" && child.parent === page._id;
        });
        page = { ...page, atoms };
        return page;
      });
      //Sort the array
      pages.sort((page1, page2) => {
        return page1.MetaData.page - page2.MetaData.page;
      });
      setPages(pages);
      setFiletredPages(pages);
    }
  };

  useLayoutEffect(() => {
    prepareAssetDetailsData();
  }, [assetDetails]);

  const modifyModule = (isMLRFeedback = false) => {
    let pageCount = "";
    removeModuleInfo();
    if (moduleDetails.id) {
      updateModuleInfo({
        assetId: moduleDetails.storageAssetId,
        moduleId: moduleDetails.id,
        moduleName: moduleDetails.name,
        moduleCode: moduleDetails.moduleCode,
        moduleType: !isMLRFeedback ? "editModule" : "addressMLRFeedback",
        numberOfPages: pageCount,
        creationMethodType: moduleDetails.creationMethodType
          ? moduleDetails.creationMethodType.toLowerCase()
          : undefined,
      });

      history.push({
        pathname: routes.addPrimaryClaim,
        state: {
          filteredPages,
          brandName: moduleDetails.brandName,
        },
      });
    }
  };

  const downloadPDFModule = (renderOnLeftSide = false) => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return;
    }
    let htmlString = "";
    if (moduleSpecificJsx) {
      htmlString = htmlString + renderToString(moduleSpecificJsx);
    }
    if (businessSpecificJsx) {
      htmlString = htmlString + renderToString(businessSpecificJsx);
    }
    if (claimsSpecificJsx) {
      htmlString = htmlString + renderToString(claimsSpecificJsx);
    }
    if (channelSpecificJsx) {
      htmlString = htmlString + renderToString(channelSpecificJsx);
    }
    if (visualImageSpecificJsx) {
      htmlString = htmlString + renderToString(visualImageSpecificJsx);
    }

    if (!htmlString || htmlString.trim() !== "") {
      const finalHtml =
        "<html><head> <style>" +
        getDownloadModuleCss() +
        "</style></head><body>" +
        htmlString +
        "</body></html>";
      let formData = new FormData();
      formData.append("html", finalHtml);
      formData.append("fileName", moduleDetails.moduleCode);
      setIsloading(true);

      downloadModule(moduleId, formData, (res, err, errorCode) => {
        setIsloading(false);
        if (res && res.data) {
          if (renderOnLeftSide) {
            setPdfUrl(res.data);
            return;
          }
          window.open(res.data, "_blank");
        } else {
          if (errorCode) {
            showError(errorCode);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      moduleDetails.moduleBusinessRules &&
      ((moduleDetails.moduleClaims && moduleDetails.moduleClaims.length > 0) ||
        (moduleDetails.moduleVisuals &&
          moduleDetails.moduleVisuals.length > 0)) &&
      ((moduleDetails.moduleChannels &&
        moduleDetails.moduleChannels.length > 0) ||
        (moduleDetails.moduleCtas && moduleDetails.moduleCtas.length > 0))
    ) {
      if (
        moduleSpecificJsx &&
        claimsSpecificJsx &&
        channelSpecificJsx &&
        businessSpecificJsx
      ) {
        downloadPDFModule(true);
      }
    } else if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      moduleDetails.moduleBusinessRules &&
      ((moduleDetails.moduleClaims && moduleDetails.moduleClaims.length > 0) ||
        (moduleDetails.moduleVisuals && moduleDetails.moduleVisuals.length > 0))
    ) {
      if (moduleSpecificJsx && claimsSpecificJsx && businessSpecificJsx) {
        downloadPDFModule(true);
      }
    } else if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      ((moduleDetails.moduleClaims && moduleDetails.moduleClaims.length > 0) ||
        (moduleDetails.moduleVisuals && moduleDetails.moduleVisuals.length > 0))
    ) {
      if (moduleSpecificJsx && claimsSpecificJsx) {
        downloadPDFModule(true);
      }
    } else if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      moduleDetails.moduleBusinessRules
    ) {
      if (moduleSpecificJsx && businessSpecificJsx) {
        downloadPDFModule(true);
      }
    }
  }, [
    moduleSpecificJsx,
    claimsSpecificJsx,
    channelSpecificJsx,
    businessSpecificJsx,
  ]);
  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };
  const isNotDenovoModule =
    getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
      moduleTypes.denovo ||
    moduleDetails?.creationMethodType?.toLowerCase() !== moduleTypes.denovo;

  const renderModuleSpecificInfo = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return <div></div>;
    }
    const jsx = (
      <div>
        {moduleDetails && Object.keys(moduleDetails).length > 0 && (
          <div className="module-specific-info">
            {/* <span className="head">Module-specific information</span> */}
            <ModuleSpecificInfo
              module={moduleDetails}
              setJsx={setModuleSpecificJsx}
            />
            {moduleDetails && moduleDetails.moduleBusinessRules && (
              <BusinessRulesSpecificInfo
                module={moduleDetails}
                bModules={bModules}
                setJsx={setBusinessSpecificJsx}
              />
            )}
            {moduleDetails &&
              ((moduleDetails.moduleClaims &&
                moduleDetails.moduleClaims.length > 0) ||
                (moduleDetails.moduleVisuals &&
                  moduleDetails.moduleVisuals.length > 0)) && (
                <ClaimAndVisualsSpecificInfo
                  module={moduleDetails}
                  setJsx={setClaimsSpecificJsx}
                  sortClaimsAndVisualsByRecordIndex={
                    sortClaimsAndVisualsByRecordIndex
                  }
                />
              )}

            {/* {moduleDetails &&
              ((moduleDetails.moduleChannels &&
                moduleDetails.moduleChannels.length > 0) ||
                (moduleDetails.moduleCtas &&
                  moduleDetails.moduleCtas.length > 0)) && (
                <ChannelSpecificInfo
                  module={moduleDetails}
                  setJsx={setChannelSpecificJsx}
                />
              )} */}

            {moduleDetails &&
              moduleDetails.moduleVisuals &&
              moduleDetails.moduleVisuals.length > 0 && (
                <SupportingVisualsImage
                  module={moduleDetails}
                  setJsx={setvisualImageSpecificJsx}
                />
              )}
          </div>
        )}
      </div>
    );

    return jsx;
  };

  const goToExistingTactics = () => {
    if (moduleDetails && moduleDetails.storageAssetId) {
      removeModuleInfo();
      history.push({
        pathname: routes.uploadAsset,
        state: {
          assetId: moduleDetails.storageAssetId,
        },
      });
    }
  };

  const notifyPublishSucess = (data) => {
    const date = new Date();
    toast.success(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">
          {isThisMLRFeedbackFlow()
            ? "MLR Re-submission successful"
            : "DAMS Submission Successful"}
        </p>
        <p className="notificationText">Document ID:-{data.docId || ""}</p>
        <p className="notificationText">Module Name:- {moduleDetails.name}</p>
        <p className="notificationText">
          Module Manager ID:-{moduleDetails.moduleCode}
        </p>

        {isThisMLRFeedbackFlow() && (
          <p className="notificationText">
            VVPM version number:- {data.majorVersion + "." + data.minorVersion}{" "}
          </p>
        )}

        <p className="notificationText">Submitted on:-{date.toString()}</p>
        <p className="notificationText">Submitted by:-{getCurrentUser()}</p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: false,
      }
    );
  };

  const notifyPublishFailure = (value) => {
    toast.error(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">VVPM Submission Un-successful</p>
        <p className="notificationText">{value}</p>
        <p className="notificationText">Please, try again</p>
      </>,
      {
        position: "top-right",
        autoClose: 3000,
      }
    );
  };

  const publishToVVPM = () => {
    if (!moduleDetails || Object.keys(moduleDetails).length <= 0) {
      return;
    }
    let htmlString = "";
    if (moduleSpecificJsx) {
      htmlString = htmlString + renderToString(moduleSpecificJsx);
    }
    if (businessSpecificJsx) {
      htmlString = htmlString + renderToString(businessSpecificJsx);
    }
    if (claimsSpecificJsx) {
      htmlString = htmlString + renderToString(claimsSpecificJsx);
    }
    if (channelSpecificJsx) {
      htmlString = htmlString + renderToString(channelSpecificJsx);
    }
    if (visualImageSpecificJsx) {
      htmlString = htmlString + renderToString(visualImageSpecificJsx);
    }

    if (!htmlString || htmlString.trim() !== "") {
      const finalHtml =
        "<html><head> <style>" +
        getDownloadModuleCss() +
        "</style></head><body> " +
        htmlString +
        "</body></html>";
      let formData = new FormData();
      formData.append("html", finalHtml);
      formData.append("fileName", moduleDetails.moduleCode);

      let { sourceType } = moduleDetails.assetDetails;
      if (
        moduleDetails.creationMethodType?.toLowerCase() === moduleTypes.denovo
      ) {
        sourceType = damsSources.veeva.name.toLowerCase();
      }
      setIsloading(true);
      publishModuleToVvpm(moduleId, sourceType, formData, (response, error) => {
        setIsloading(false);
        if (error) {
          notifyPublishFailure(error.message);
          return;
        }
        notifyPublishSucess(response.data);
        setTimeout(() => {
          onSelectSearchedModule(moduleId, moduleDetails.brandId);
        }, 5000);
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setGetPdfWidth(elementRef.current.getBoundingClientRect().width - 50);
    }, 200);
  }, []);

  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite">
        <HeaderCreateModule onModuleSelect={onSelectSearchedModule} />
        <div style={{ display: "none" }}>{renderModuleSpecificInfo()}</div>
        <ModuleDetailsNav
          modifyModule={modifyModule}
          module={moduleDetails}
          publish={publishToVVPM}
        />
      </div>
      <Container fluid>
        <Row className="mlr-feedback-main-content">
          {moduleDetails && moduleDetails.storageAssetId && (
            <div className="master-asset-link">
              <img src={Tick} alt="Dashboard" className="img-fluid" />
              <button
                onClick={() => {
                  goToExistingTactics();
                }}
              >
                {i18next.t("generalText.masterAssetLink")}
              </button>
            </div>
          )}
          <button
            className="btn-download"
            onClick={() => {
              downloadPDFModule();
            }}
          >
            {i18next.t("generalText.download")}
          </button>
        </Row>
      </Container>
      <div className="previewFeedbackScroll customScrollBar">
        <div className="pdfBottomOverlay"></div>
        <Container fluid>
          <Row style={{ color: "#777" }}>
            <Col className="col-md-6">
              {/* <p>Last updated: Today, Sept 22,2020 | 03:34 Pm</p> */}
            </Col>
            <Col className="col-md-6">
              <p>MLR Annotated</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6" ref={elementRef}>
              {getPdfwidth && (
                <RenderMlrFeedback
                  pdfFileUrl={pdfUrl}
                  pdfWidth={
                    elementRef.current?.getBoundingClientRect().width * 0.95 ||
                    undefined
                  }
                />
              )}
            </Col>
            <Col className="col-md-6">
              {getPdfwidth && (
                <RenderMlrFeedback
                  pdfFileUrl={annotatedPdf}
                  pdfWidth={
                    elementRef.current?.getBoundingClientRect().width * 0.95 ||
                    undefined
                  }
                />
              )}
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <CircularProgressBar isloading={isloading} />
        </Container>
      </div>
    </>
  );
};

export default ModulePreviewMlrFeedbackFlow;
