import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";

const GenericTextField = ({ label, data, editMode, setData, title }) => {
  const defaultData = {
    text: "",
  };
  useEffect(() => {
    if (!data || data.length === 0) {
      setData([defaultData]);
    }
  }, [data]);

  const addNewGenericText = (newGenericTextField) => {
    const newData = [...data];
    setData(newData.concat(newGenericTextField));
  };

  const removeGenericText = (atIndex) => {
    let newData = [...data];
    newData.splice(atIndex, 1);
    setData(newData);
  };

  const updateData = (text, currentIndex) => {
    const newData = [...data];
    newData[currentIndex] = text;
    setData(newData);
  };

  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <CreateGenericText
              label={label}
              genericText={{ ...item }}
              updateData={updateData}
              editMode={editMode}
              currentIndex={index}
              addNewGenericText={addNewGenericText}
              removeGenericText={removeGenericText}
              isLastIndex={index === data.length - 1}
              title={title}
              data={data}
            />
          );
        })}
    </div>
  );
};
export default GenericTextField;

const CreateGenericText = ({
  currentIndex,
  editMode,
  removeGenericText,
  addNewGenericText,
  isLastIndex,
  label,
  genericText,
  title,
  updateData,
  data,
}) => {
  const [shouldHighlight, setShouldHighlight] = useState(false);

  const handleTextChange = (e) => {
    genericText.text = e.target.value;
    updateData(genericText, currentIndex);
  };
  return (
    <div>
      {!editMode && (
        <div className="row attribute-head">
          <div className="col-md-6 " style={{ paddingTop: "5px" }}>
            {" "}
            <span className="marketing-message-label">
              {title} {currentIndex + 1}
            </span>
          </div>

          <div className="col-md-6 text-right">
            <button
              className="btn btn-link"
              onClick={() => {
                removeGenericText(currentIndex);
                setShouldHighlight(false);
              }}
              disabled={data && data.length === 1 ? true : false}
            >
              Remove
            </button>

            {isLastIndex && (
              <button
                className="btn btn-link"
                onClick={() => {
                  addNewGenericText({ text: "" });
                }}
                disabled={genericText?.text?.trim().length === 0}
              >
                Add
              </button>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 MarketingMessageSubjectLine">
          <TextField
            className="genericTextField"
            id={`genericTextField${currentIndex}`}
            label={label}
            variant="outlined"
            fullWidth={true}
            rows={9}
            rowsMax={9}
            multiline
            value={genericText.text || ""}
            onChange={(e) => {
              let flag = false;
              if (
                genericText?.text?.trim().length > 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              } else if (
                genericText?.text?.trim().length === 0 &&
                e.target.value.trim().length === 0
              ) {
                flag = true;
              }
              setShouldHighlight(flag);
              handleTextChange(e);
            }}
            inputProps={{ maxLength: 250 }}
            helperText={shouldHighlight ? "This field can not be empty" : ""}
            error={shouldHighlight ? true : false}
          />
        </div>
      </div>
    </div>
  );
};
