import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/components/EmailSubjectLine.scss";
import AddCatchline from "./AddCatchline";
const EmailSubjectLine = (props) => {
  const [storeSubjectLine, setStoreSubectLine] = useState({});
  const [disclaimerSubjectLine, setDisclaimerSubjectLine] = useState({});
  const [subjectLineAddedVal, setSubjectLineAddedVal] = useState([]);
  const [enable, setEnable] = useState(false);
  useEffect(() => {
    setSubjectLineAddedVal(props.subjectLines);
  }, [props.subjectLines]);
  useEffect(() => {
    if (props.atomToBeUsed && props.atomToBeUsed) {
      const { text, cmDocumentNumber } = props.atomToBeUsed;
      if (props.emailType === "subjectLine") {
        setStoreSubectLine({ text: text, cmDocumentNumber: cmDocumentNumber });
        setEnable(true);
      } else if (props.emailType === "disclaimer") {
        setDisclaimerSubjectLine({
          text: text,
          cmDocumentNumber: cmDocumentNumber,
        });
      } else {
        return;
      }
    }
    props.setAtomToBeUsed(undefined);
  }, [props.atomToBeUsed]);
  const handleChangeSubjectLine = (e) => {
    if (e.target.value.trim().length > 0) {
      setStoreSubectLine({ text: e.target.value });
      setEnable(true);
    } else if (e.target.value.trim().length === 0) {
      setStoreSubectLine({ text: e.target.value });
      setDisclaimerSubjectLine("");
      setEnable(false);
    }
  };
  const handleChangeDisclamir = (e) => {
    setDisclaimerSubjectLine(e.target.value);
  };
  const handleSubmitSubjectLine = () => {
    let input = document.getElementById("input-email-val");

    const text = input.value;
    input.value = "";
    document.getElementById("input-email-desclaimer").value = "";
    //Check if duplicate before inserting
    props.setEmailType(undefined);
    if (text) {
      const alreadyAdded = subjectLineAddedVal.filter((item) => {
        return item.subjectLineValue.trim() === text.trim();
      });
      if (alreadyAdded && alreadyAdded.length > 0) {
        setStoreSubectLine({});
        setDisclaimerSubjectLine({});
        setEnable(false);
      } else {
        if (storeSubjectLine) {
          // setSubjectLineAddedVal(subjectLineAddedVal.concat({subjectLineValue: storeSubjectLine, subectLineValueDisclamer: disclaimerSubjectLine }));
          props.handleData({
            subjectLineValue: storeSubjectLine.text,
            subectLineValueDisclamer: disclaimerSubjectLine.text,
            cmDocumentNumber: storeSubjectLine.cmDocumentNumber,
          });
          setStoreSubectLine({});
          setDisclaimerSubjectLine({});
          setEnable(false);
        } else {
          setStoreSubectLine({});
          setDisclaimerSubjectLine({});
          setEnable(false);
        }
      }
    }
  };

  return (
    <div className="">
      <div className="Email-Subject-Box">
        <div style={{ width: "100%" }}>
          <input
            type="text"
            className="form-control input-email"
            id="input-email-val"
            onChange={handleChangeSubjectLine}
            value={storeSubjectLine.text}
            placeholder="Enter Text Here"
            aria-label=""
            aria-describedby="button-addon2"
            onClick={() => props.setEmailType("subjectLine")}
          />
          <div className="Header-line-email"></div>
          <input
            type="text"
            disabled={enable ? "" : "disable"}
            className="form-control input-email"
            id="input-email-desclaimer"
            onChange={handleChangeDisclamir}
            value={disclaimerSubjectLine.text}
            placeholder="Disclaimer (optional)"
            aria-label="catchline"
            aria-describedby="button-addon2"
            onClick={() => props.setEmailType("disclaimer")}
          />
        </div>
        <div className="btnSubject">
          <button
            type="button"
            className="btn btn-primary"
            id="add-subject"
            onClick={handleSubmitSubjectLine}
          >
            ADD
          </button>
        </div>
      </div>
      <div className="">
        {subjectLineAddedVal.map((item, index) => (
          <li className="catchline-item" key={index}>
            <Row className="align-items-center mt-1 mb-1">
              <Col className="col-md-10">
                <div className="catch-line-item">
                  <div style={{ fontSize: "12px" }}>
                    {item.subjectLineValue}{" "}
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "10px",
                      display: item.subectLineValueDisclamer ? "block" : "none",
                    }}
                  >
                    Disclaimer:{item.subectLineValueDisclamer}
                  </div>
                </div>
              </Col>
              <Col className="btn-remove-sub">
                <button
                  className="btn-remove-sub"
                  onClick={() => props.removeSubjectLineEmail(item, index)}
                >
                  Remove
                </button>
              </Col>
            </Row>
          </li>
        ))}
      </div>
    </div>
  );
};
export default EmailSubjectLine;
