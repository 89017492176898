import React, { useState, useEffect } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Redirect, useHistory } from "react-router-dom";
import { authenticate } from "../Uitls/index";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Carousel from "react-bootstrap/Carousel";
import Pool from "../Uitls/Userpools";
import PasswordStrengthIndicator from "../components/passwordIndicator";
import { getSession } from "../Uitls";
import { addProcessIds } from "../Uitls/polling";
import { getInProgressIds } from "../Uitls";

import IndegeneLogo from "../images/IndegeneLogo.svg";
import playIcon from "../images/play_circle_btn.svg";
import logo_module_manager from "../images/NEXT-Module-logo.svg";
import "../styles/pages/Login.scss";
import { routes } from "../Uitls/Util";

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const upperCase = /[A-Z]/;

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setshowErrorMsg] = useState(false);
  const [stage, setStage] = useState(0); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [showForgotErrorMsg, setShowForgotErrorMsg] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    upperCase: null,
  });
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideLoginPassBtn, setShowHideLoginPassBtn] = useState(false);
  const [showHideLoginPassword, setShowHideLoginPassword] = useState(false);
  const [showHidePassBtn, setShowHidePassBtn] = useState(false);
  let history = useHistory();
  useEffect(() => {
    getSession()
      .then((session) => {
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log("");
      });
  }, []);
  const validationSchemaLogin = yup.object().shape({
    email: yup
      .string()
      .email(i18next.t("login.loginFormLabel.emailInvaliderr"))
      .required(i18next.t("login.loginFormLabel.emailerr")),
    password: yup
      .string()
      .required(i18next.t("login.loginFormLabel.passworderr")),
  });

  const validateLogin = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = i18next.t("login.loginFormLabel.emailerr");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = i18next.t("login.loginFormLabel.emailInvaliderr");
    }
    if (!values.password) {
      setShowHideLoginPassBtn(false);
      errors.password = i18next.t("login.loginFormLabel.passworderr");
    } else {
      setShowHideLoginPassBtn(true);
    }
    return errors;
  };

  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const validationSchemaSendEmail = yup.object().shape({
    resetEmail: yup
      .string()
      .email(i18next.t("login.loginFormLabel.emailInvaliderr"))
      .required(i18next.t("login.loginFormLabel.emailerr")),
  });

  const validate = (values) => {
    const errors = {};
    if (!values.verificationCode) {
      errors.verificationCode = i18next.t("login.resetPassword.verfiCodeErr");
    }
    if (!values.resetPassword) {
      errors.resetPassword = i18next.t("login.resetPassword.newpasswordErr");
      setPasswordValidity({
        minChar: false,
        number: false,
        specialChar: false,
        upperCase: false,
      });
    } else if (values.resetPassword) {
      setPasswordValidity({
        minChar:
          values.resetPassword.length >= 6
            ? true
            : ((errors.resetPassword = i18next.t(
                "login.resetPassword.sixCharErr"
              )),
              false),
        number: isNumberRegx.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.alphanumericErr"
            )),
            false),
        specialChar: specialCharacterRegx.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.specialCharErr"
            )),
            false),
        upperCase: upperCase.test(values.resetPassword)
          ? true
          : ((errors.resetPassword = i18next.t(
              "login.resetPassword.capCharErr"
            )),
            false),
      });
    }
    if (!values.confResetPassword) {
      setShowHidePassBtn(false);
      errors.confResetPassword = i18next.t(
        "login.resetPassword.confpasswordErr"
      );
    } else if (values.resetPassword !== values.confResetPassword) {
      setShowHidePassBtn(true);
      errors.confResetPassword = i18next.t(
        "login.resetPassword.confpasswordMatchErr"
      );
    }
    return errors;
  };

  const getUser = (value) => {
    console.log(value);
    setEmail(value);
    const getUserEmail = value;
    return new CognitoUser({
      Username: getUserEmail.toLowerCase(),
      Pool,
    });
  };
  const passwordReset = () => {
    setStage(1);
  };
  const backToSignIn = () => {
    setStage(0);
  };
  return (
    <div className="container-fluid loginBg">
      <div className="row align-itmes-center">
        <div className="col-md-6 col-xl-8">
          <Carousel controls={false}>
            <Carousel.Item>
              <div className="banner01">
                <div className="bannerText">
                  <h3>Enhance</h3>
                  <span>Accuracy | Speed | Reach</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner02">
                <div className="bannerText">
                  <h3>Deliver</h3>
                  <span>Context | Content | Expertise</span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner03">
                <div className="bannerText">
                  <h3>Embrace</h3>
                  <span>Smart | Secure | Simple</span>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          <button className="btn btn-link productTour">
            <img src={playIcon} alt="" />
            Product Tour
          </button>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="signInBox">
            <div className="loginContainer">
              {/* <h1 className="loginTitle">
                IMC <span>Intelligent Modular Content</span>
              </h1> */}
              <img src={logo_module_manager} alt="login" />
              {stage === 0 && (
                <Formik
                  initialValues={{ email: "", password: "" }}
                  //validationSchema={validateLogin}
                  validate={validateLogin}
                  onSubmit={(values) => {
                    authenticate(values.email, values.password)
                      .then((data) => {
                        console.log("Logged in!", data);
                        setLoggedIn(true);
                        const getPeningIds = async () => {
                          const ids = await getInProgressIds();
                          addProcessIds(ids);
                        };
                        getPeningIds();
                      })
                      .catch((err) => {
                        setErrorMsg(err.message);
                        setshowErrorMsg(true);
                        console.error("Failed to login!", err);
                        setTimeout(() => {
                          setshowErrorMsg(false);
                          setErrorMsg("");
                        }, 10000);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form
                      onSubmit={handleSubmit}
                      className="mt-4"
                      autoComplete="off"
                    >
                      {showErrorMsg ? (
                        <div
                          className="alert alert-warning alert-text"
                          role="alert"
                        >
                          {errorMsg}
                        </div>
                      ) : (
                        ""
                      )}
                      <TextField
                        name="email"
                        id="email"
                        label={t("login.loginFormLabel.username")}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-3"
                        fullWidth={true}
                        autoComplete="off"
                        value={values.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        error={errors.email && touched.email ? true : false}
                      />
                      <div className="position-relative">
                        <TextField
                          name="password"
                          id="password"
                          label={t("login.loginFormLabel.password")}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth={true}
                          type={showHideLoginPassword ? "text" : "password"}
                          value={values.password}
                          autoComplete="off"
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          error={
                            errors.password && touched.password ? true : false
                          }
                        />
                        {showHideLoginPassBtn && (
                          <button
                            type="button"
                            className="btn btn-link shoHidePassword"
                            onClick={() =>
                              setShowHideLoginPassword(!showHideLoginPassword)
                            }
                          >
                            {showHideLoginPassword ? "Hide" : "Show"}
                          </button>
                        )}
                      </div>

                      <div className="form-group form-check text-left">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="savePassword"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="savePassword"
                        >
                          {t("login.loginFormLabel.remeber")}
                        </label>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {t("login.loginFormLabel.login")}
                        </button>
                      </div>
                      <div className="form-group text-center">
                        <button
                          onClick={passwordReset}
                          className="btn btn-link link-text"
                        >
                          {t("login.loginFormLabel.forgot")}
                        </button>
                      </div>
                      {/* <div className="form-group text-center">
                      <a href="/signup" className="link-text">
                        Sign Up
                      </a>
                    </div> */}
                    </Form>
                  )}
                </Formik>
              )}
              {stage === 1 && (
                <Formik
                  initialValues={{ resetEmail: "" }}
                  validationSchema={validationSchemaSendEmail}
                  onSubmit={(values) => {
                    const user = values.resetEmail;
                    getUser(user).forgotPassword({
                      onSuccess: (data) => {
                        console.log("onSuccess:", data);
                      },
                      onFailure: (err) => {
                        console.error("onFailure:", err);
                        setErrorMsg(err.message);
                        setShowForgotErrorMsg(true);
                        setTimeout(() => {
                          setShowForgotErrorMsg(false);
                          setErrorMsg("");
                        }, 5000);
                      },
                      inputVerificationCode: (data) => {
                        console.log("Input code:", data);
                        setStage(2);
                      },
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    status,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {showForgotErrorMsg ? (
                        <div
                          className="alert alert-warning alert-text"
                          role="alert"
                        >
                          {errorMsg}
                        </div>
                      ) : (
                        ""
                      )}
                      <h1 className="forgotTitle col-md-12 mb-3">
                        {t("login.forgotPassword.forgotPass")}
                      </h1>
                      <p className="forgotText mb-4">
                        {t("login.forgotPassword.forgotText")}
                      </p>
                      <TextField
                        id="resetEmail"
                        label={t("login.loginFormLabel.username")}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mb-3"
                        fullWidth={true}
                        value={values.resetEmail}
                        helperText={
                          errors.resetEmail &&
                          touched.resetEmail &&
                          errors.resetEmail
                        }
                        error={
                          errors.resetEmail && touched.resetEmail ? true : false
                        }
                      />
                      <div className="form-group">
                        <p className="forgotText">
                          {t("login.forgotPassword.forgotText2")}
                        </p>
                        <div className="form-group text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {t("login.forgotPassword.resetLabel")}
                          </button>
                        </div>
                        <div className="form-group text-center">
                          <button
                            onClick={backToSignIn}
                            className="m-auto btn btn-link link-text"
                          >
                            {t("login.forgotPassword.backToSignIn")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {stage === 2 && (
                <>
                  <h1 className="forgotTitle mb-3">
                    {t("login.resetPassword.resetPassLabel")}
                  </h1>

                  <div className="pwdStrengthIndicator">
                    {email && (
                      <>
                        <h1 className="forgotTitle mb-3">
                          {t("login.resetPassword.regEmail")}
                        </h1>
                        <p className="emailText">{email}</p>
                      </>
                    )}
                    <PasswordStrengthIndicator validity={passwordValidity} />
                  </div>
                  <Formik
                    initialValues={{
                      verificationCode: "",
                      resetPassword: "",
                      confResetPassword: "",
                    }}
                    validate={validate}
                    // validationSchema={validationSchemaReset}
                    onSubmit={(values) => {
                      getUser(email).confirmPassword(
                        values.verificationCode,
                        values.resetPassword,
                        {
                          onSuccess: (data) => {
                            console.log("onSuccess:", data);
                            setStage(0);
                          },
                          onFailure: (err) => {
                            console.error("onFailure:", err);
                            setErrorMsg(err.message);
                            setshowErrorMsg(true);
                            setTimeout(() => {
                              setshowErrorMsg(false);
                              setErrorMsg("");
                            }, 5000);
                          },
                        }
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      status,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        {showErrorMsg ? (
                          <div
                            className="alert alert-warning alert-text"
                            role="alert"
                          >
                            {errorMsg}
                          </div>
                        ) : (
                          ""
                        )}
                        <TextField
                          id="verificationCode"
                          label={t("login.resetPassword.verificationCode")}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mb-3"
                          fullWidth={true}
                          value={values.verificationCode}
                          helperText={
                            errors.verificationCode &&
                            touched.verificationCode &&
                            errors.verificationCode
                          }
                          error={
                            errors.verificationCode && touched.verificationCode
                              ? true
                              : false
                          }
                        />

                        <TextField
                          type="password"
                          id="resetPassword"
                          label={t("login.resetPassword.newPassword")}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="mb-3"
                          fullWidth={true}
                          value={values.resetPassword}
                          helperText={
                            errors.resetPassword &&
                            touched.resetPassword &&
                            errors.resetPassword
                          }
                          error={
                            errors.resetPassword && touched.resetPassword
                              ? true
                              : false
                          }
                        />
                        <FormControl
                          fullWidth={true}
                          className="forgotPassStep3"
                        >
                          <TextField
                            type={showHidePassword ? "text" : "password"}
                            id="confResetPassword"
                            label={t("login.resetPassword.confirmPassword")}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="mb-3"
                            fullWidth={true}
                            value={values.confResetPassword}
                            helperText={
                              errors.confResetPassword &&
                              touched.confResetPassword &&
                              errors.confResetPassword
                            }
                            error={
                              errors.confResetPassword &&
                              touched.confResetPassword
                                ? true
                                : false
                            }
                          />

                          {showHidePassBtn && (
                            <button
                              type="button"
                              className="btn btn-link shoHidePassword"
                              onClick={() =>
                                setShowHidePassword(!showHidePassword)
                              }
                            >
                              {showHidePassword ? "Hide" : "Show"}
                            </button>
                          )}
                        </FormControl>
                        <div className="form-group mt-1">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block mt-1"
                          >
                            {t("login.resetPassword.changePassword")}
                          </button>
                        </div>
                        <div className="form-group text-center">
                          <button
                            onClick={backToSignIn}
                            className="m-auto btn btn-link link-text"
                          >
                            {t("login.forgotPassword.backToSignIn")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
            <img
              src={IndegeneLogo}
              alt="Indegene Logo"
              className="loginIndegenLogo"
            />
          </div>
        </div>
      </div>
      {/* {loggedIn && (
        <Redirect
          to={`${
            history.location.state === "dashboard-translate"
              ? "/dashboard-translate"
              : "/dashboard"
          }`}
        />
      )} */}
      {loggedIn &&
      history.location.state?.pathname === routes.translatorDashboard ? (
        <Redirect to={routes.translatorDashboard} />
      ) : loggedIn &&
        history.location.state?.pathname === routes.templatePreview ? (
        <Redirect
          to={`${routes.templatePreview}${history.location.state.search}`}
        />
      ) : (
        loggedIn && <Redirect to="/dashboard" />
      )}
    </div>
  );
};

export default Home;
