import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../../styles/pages/MLRSubmissionPackage.scss";

const SupportingVisualsImage = (props) => {
  const { t } = useTranslation();
  const [module, setModule] = useState(undefined);
  const [pageJsx, setPageJsx] = useState(undefined);

  useEffect(() => {
    if (props.module) {
      if (module && module.id && module.id !== props.module.id) {
        setPageJsx(undefined);
      }
      setModule(props.module);
    }
  }, [props]);

  useEffect(() => {
    if (props.setJsx) {
      props.setJsx(pageJsx);
    }
  }, [pageJsx]);

  const renderVisualVarientsImages = (visual, visualIndex) => {
    let varients = visual.visualVariants;
    if (varients && varients.length > 1) {
      return (
        <div style={{ width: "80%" }}>
          {varients.map((varient, index) => {
            return (
              <div
                className={`visual-varient firstImagePage `}
                style={{
                  pageBreakBefore:
                    visualIndex === 0 && index === 0 ? "" : "always",
                }}
                key={varient.id}
              >
                {(visualIndex > 0 || (visualIndex === 0 && index > 0)) && (
                  <div
                    className="pdfHeader"
                    style={{
                      paddingBottom: "15px",
                      fontFamily: " Arial, Helvetica, sans-serif;",
                      fontSize: " 16px",
                      color: "#707070",
                    }}
                  >
                    {module && module.name}
                  </div>
                )}

                <div
                  style={{
                    paddingBottom: "15px",
                    display: "block",
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "50px",
                  }}
                  className="visualsImageListHeading"
                >
                  Component ID : {visual.componentId}
                </div>
                <img
                  style={{
                    paddingBottom: "15px",
                    width: "100%",
                    display: "block",
                  }}
                  src={varient.visualUrl}
                ></img>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        varients &&
        varients.length === 1 && (
          <div
            style={{
              pageBreakBefore: visualIndex > 0 ? "always" : "",
              width: "80%",
            }}
            className="firstImagePage"
          >
            {visualIndex > 0 && (
              <div
                className="pdfHeader"
                style={{
                  paddingBottom: "15px",
                  fontFamily: " Arial, Helvetica, sans-serif;",
                  fontSize: " 16px",
                  color: "#707070",
                }}
              >
                {module && module.name}
              </div>
            )}

            <div
              style={{
                paddingBottom: "15px",
                display: "block",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "50px",
              }}
              className="visualsImageListHeading"
            >
              Component ID : {visual.componentId}
            </div>
            <img
              style={{
                width: "100%",
                display: "block",
                marginTop: "15px",
              }}
              src={varients[0].visualUrl}
            ></img>
          </div>
        )
      );
    }
  };

  const renderVisualVarients = (visual, visualIndex) => {
    return (
      <div>
        {/* */}
        {renderVisualVarientsImages(visual, visualIndex)}
      </div>
    );
  };

  const renderSupportingVisuals = () => {
    if (!module) {
      return;
    }
    let moduleVisuals = module.moduleVisuals;
    if (moduleVisuals && moduleVisuals.length > 0) {
      return moduleVisuals.map((visual, index) => {
        return <div key={visual.id}>{renderVisualVarients(visual, index)}</div>;
      });
    }
  };

  const renderData = () => {
    if (!module) {
      return <div></div>;
    }
    const jsx = (
      //style = {{tableLayout : 'fixed'}}
      <div className="allow-border-in-first-row">
        <div
          className="pdfHeader"
          style={{
            paddingBottom: "15px",
            fontFamily: " Arial, Helvetica, sans-serif;",
            fontSize: " 16px",
            color: "#707070",
            pageBreakBefore: "always",
          }}
        >
          {module && module.name}
        </div>
        <div
          style={{
            marginBottom: "20px",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop: "50px",
            fontFamily: " Arial, Helvetica, sans-serif;",
          }}
        >
          Images
        </div>
        {renderSupportingVisuals()}
      </div>
    );

    if (!pageJsx) {
      setPageJsx(jsx);
    }
    return jsx;
  };

  return <div>{renderData()}</div>;
};

export default SupportingVisualsImage;
