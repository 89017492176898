import React, { useEffect } from "react";

//Import custom compoennents
import TabPanel from "../../Uitls/TabPanel";
import {
  channelTypesAndAttributes,
  getChannelAttributes,
} from "../../Uitls/Util";
import CheckBox from "../../Uitls/CheckBox";
import SelectableMarketingMessageCard from "./SelectableMarketingMessageCard";

const SelectableMarketingMessages = ({
  marketingMessages,
  onSelectMarketingMessage,
  selectedMarketingMessages,
  channelType,
  isAllSubjectLineChecked,
  isAllPreHeaderChecked,
  handleSelecteAll,
  handleClearOptOutSelection,
}) => {
  const renderColumns = () => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let optouts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    let tagLines = marketingMessages?.marketingMsgChannels?.tagLines || [];

    if (
      marketingMessages?.marketingMsgChannels.channelType.trim() ===
      channelTypesAndAttributes.email.name
    ) {
      return (
        <div>
          <div
            className="row slctble-marketing-sg-cols-head customScrollBar"
            style={{ overflowY: "scroll" }}
          >
            <div
              className="col-md-4 pl-0 marketing-msg-column mmColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll" }}
            >
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllSubjectLineChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "subjectline");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>
              <div
                style={{ marginLeft: "25px" }}
              >{`Subject Line (${selectedMarketingMessages?.subjectLines.length}/${subjectLines.length})`}</div>
            </div>
            <div
              className="col-md-4 marketing-msg-column mmColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll" }}
            >
              <div style={{ marginLeft: "14px", paddingTop: "2px" }}>
                <CheckBox
                  isChecked={isAllPreHeaderChecked}
                  handleChange={(e) => {
                    handleSelecteAll(e, "preheader");
                  }}
                  labelClassName=""
                  checkboxClassName="form-check-input slctble-mrktng-msg-chk-bx"
                />
              </div>
              <div style={{ marginLeft: "25px" }}>
                {`PreHeader (${selectedMarketingMessages?.preHeaders.length}/${preheaders.length})`}
              </div>
            </div>
            <div
              className="col-md-4 mmColumnPdng customScrollBar d-flex"
              style={{ overflowY: "scroll", justifyContent: "space-between" }}
            >
              <div style={{ marginLeft: "0px" }}>
                Opt-out <span className="lb-optional">(Optional)</span>
              </div>
              <button
                className="btn btn-clear-selection pt-1 pr-0"
                disabled={selectedMarketingMessages?.optOuts.length === 0}
                onClick={handleClearOptOutSelection}
              >
                Clear selection
              </button>
            </div>
          </div>
          <div className="row selectable-marketing-messages customScrollBar m-0">
            {renderMarketingMessages(subjectLines, false, 4, "pl-0")}
            {renderMarketingMessages(preheaders, false, 4)}
            {renderMarketingMessages(optouts, true, 4)}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-md-6 pl-0 marketing-msg-column">
              {" "}
              {`Tagline (${tagLines.length})`}
            </div>

            <div className="col-md-6">{`Opt-out (${optouts.length})`}</div>
          </div>
          <div className="row marketing-messages customScrollBar m-0">
            {renderMarketingMessages(tagLines, false, 6, "pl-0")}
            {renderMarketingMessages(optouts, true, 6)}
          </div>
        </div>
      );
    }
  };

  const checkIfMarketingMessageSelected = (marketingMessage) => {
    let subjectLines = selectedMarketingMessages?.subjectLines || [];
    let preheaders = selectedMarketingMessages?.preHeaders || [];
    let tagLines = selectedMarketingMessages?.tagLines || [];
    let optOuts = selectedMarketingMessages?.optOuts || [];
    const attribute = marketingMessage.attribute.toLowerCase();
    const channelAttributes = getChannelAttributes(channelType?.toLowerCase());
    let index = -1;
    if (attribute === channelAttributes?.subjectLine?.toLowerCase()) {
      index = subjectLines.findIndex((subjectLine) => {
        return subjectLine.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.preheader?.toLowerCase()) {
      index = preheaders.findIndex((preheader) => {
        return preheader.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.optOut?.toLowerCase()) {
      index = optOuts.findIndex((optOut) => {
        return optOut.id === marketingMessage.id;
      });
    }
    if (attribute === channelAttributes?.tagLine?.toLowerCase()) {
      index = tagLines.findIndex((tagLine) => {
        return tagLine.id === marketingMessage.id;
      });
    }

    return index > -1;
  };
  const renderMarketingMessages = (
    messages,
    isLast,
    columnWidth,
    firstcolwidth
  ) => {
    return (
      <div
        className={`${firstcolwidth} col-md-${columnWidth} ${
          isLast ? "" : "marketing-msg-column"
        }`}
      >
        {messages.map((marketingMessage) => {
          return (
            <SelectableMarketingMessageCard
              marketingMessage={marketingMessage}
              isSelected={checkIfMarketingMessageSelected(marketingMessage)}
              onSelectMarketingMessage={onSelectMarketingMessage}
            />
          );
        })}
      </div>
    );
  };
  return (
    <>
      <div>
        {!marketingMessages && (
          <div
            className={`marketing-messages-body-in-modal marketingMsgBg `}
          ></div>
        )}

        {marketingMessages && renderColumns()}
      </div>
    </>
  );
};

export default SelectableMarketingMessages;
