import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import infoIcon from "../../images/infoIcon.svg";
import backButton from "../../images/chevron-left-matrix.svg";

import SubjectLinePreheaderPreview from "./SubjectLinePreHeaderPreview";
import SelectedModulesPreview from "./SelectedModulesPreview";
import PreviewForCTA from "./PreviewCTA";
import OptOutPreview from "./OptOutPreview";
import TemplateEmailPreview from "./TemplateEmailPreview";
import CustomModal from "../customModal";
import TemplateDetail from "./Templates/TemplateDetail";
import { templateTypes } from "../../Uitls/Util";

const PreviewChannelGrid = ({
  brand,
  campaignName,
  channelType,
  cancelCreatingGrid,
  backToPrviousScreen,
  selectedMMessges,
  selectedModule,
  initateChannelGrid,
  selectedTemplate,
  selectedSample,
  stepNumber,
  channelGridStepCount,
}) => {
  const [isloading, setIsloading] = useState(false);
  const { t } = useTranslation();
  const [showTemplateInFullScreen, setShowTemplateInFullScreen] =
    useState(false);
  const [showTemlpateType, setShowTemlpateType] = useState(undefined);

  const showTemplateDetailsModal = (flag) => {
    setShowTemplateInFullScreen(flag);
  };

  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <div
        className="row mt-3"
        style={{
          borderBottom: "1px solid #e1e1e1",
          paddingBottom: "15px",
        }}
      >
        <div className="col-md-5 matrixSearchRowTitle">
          <div className="d-flex pl-2">
            <div>
              <img
                className="grid-header-back-button mt-0"
                src={backButton}
                alt=""
                width="10"
                onClick={() => backToPrviousScreen()}
              />
            </div>
            <div className="pl-2">
              <strong>Create</strong> Submission Document ({stepNumber}/
              {channelGridStepCount}){" "}
              <div className="subtitle-text">
                <img
                  className="grid-header-subtitle-icon"
                  src={infoIcon}
                  alt=""
                />
                Preview & Generate Submission Document
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 matrixSearchRowBrand">
          <div className="d-flex justify-content-end  pr-2">
            <div className="mr-5">
              <span>Product/Brand</span>
              {brand}
            </div>
            <div className="mr-5">
              <span>Campaign</span>
              {campaignName}
            </div>
            <div>
              <span>Channel</span>
              {channelType}
            </div>
          </div>
        </div>
      </div>
      <div className="row pl-2 pr-2">
        <div className="col-md-12 channel-grid-preview customScrollBar">
          <SelectedModulesPreview
            title="Selected Modules"
            data={selectedModule}
          />
          <SubjectLinePreheaderPreview
            title="Subject Line"
            data={selectedMMessges?.subjectLines}
            attribute="subjectline"
          />
          <SubjectLinePreheaderPreview
            title="Preheader"
            data={selectedMMessges?.preHeaders}
            attribute="preheader"
          />
          <PreviewForCTA
            title="CTA"
            data={selectedMMessges?.ctas}
            attribute="preheader"
          />
          <OptOutPreview title="Opt-Out" data={selectedMMessges?.optOuts} />
          <TemplateEmailPreview
            title="Approved Template"
            data={selectedTemplate}
            attribute="templateid"
            onClick={() => {
              showTemplateDetailsModal(true);
              setShowTemlpateType("template");
            }}
          />
          <TemplateEmailPreview
            title="Sample Email"
            data={selectedSample}
            attribute="emailid"
            onClick={() => {
              showTemplateDetailsModal(true);
              setShowTemlpateType("samplemail");
            }}
          />
        </div>
      </div>

      <div className="row pt-3 createGroupResultBtnRow">
        <div className="col-md-4 pl-3"></div>
        <div className="col-md-8 text-right pr-4">
          <button
            className="btn btn-link text-uppercase"
            onClick={() => {
              cancelCreatingGrid();
            }}
            style={{ marginRight: "64px" }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary text-uppercase"
            //disabled=""
            // onClick={() => {
            //   handleContentMatrixModal();
            // }}
            onClick={() => {
              initateChannelGrid();
            }}
            //style={{ width: "136px" }}
          >
            Generate Submission Document
          </button>
        </div>
      </div>
      <CustomModal
        displayModal={showTemplateInFullScreen}
        hideModal={showTemplateDetailsModal}
        title={""}
        size="lg"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <TemplateDetail
          // templateType={templateTypes.emailTemplate}
          template={
            showTemlpateType === "template" ? selectedTemplate : selectedSample
          }
          closeTemplateDetail={() => {
            showTemplateDetailsModal(false);
            setShowTemlpateType(undefined);
          }}
          fromPreviewPage={true}
        />
      </CustomModal>
    </div>
  );
};
export default PreviewChannelGrid;
