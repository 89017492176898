import React, { useEffect } from "react";
import { logout } from "../Uitls";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HeaderCreateModule from "../components/HeaderCreateModule";
import "../styles/pages/Dashboard.scss";
import LogoutCTA from "../Pages/LogoutCTA";
import { removeModuleInfo } from "../Uitls/Util";
import dashboard from "../images/dashboardIcon.svg"
function Search() {
  const history = useHistory();

  useEffect(() => {
      removeModuleInfo()
  }, [])

  const onLogout = () => {
    logout();
    history.push("/");
  };
  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }

  return (
    <>
    <div className="headeBgWhite">
        <HeaderCreateModule />
      </div>
      <div className="dasboardContent text-center">
        <div className="mb-5">
          <img src={dashboard} alt="Dashboard" className="img-fluid" />
        </div>
        <h1 className="dasboardTitle">Dashboard</h1>
      </div>
     
      <LogoutCTA/>
     
       
    </>
  );
}

export default Search;
