import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import LogoutCTA from "../Pages/LogoutCTA";

const NotFound = (props) => {
  return (
    <>
      <LogoutCTA />
      <div
        className="trasnlateHeaderBg"
        style={{ backgroundColor: "#ccc", height: "200px", width: "300px" }}
      >
        <h1>Page Not Found</h1>
      </div>
    </>
  );
};

export default NotFound;
