//Import react dependencies
import React from "react";

//Import Custom components
import TabPanel from "../../../Uitls/TabPanel";
import Optouts from "./Optouts";
import Preheaders from "./Preheaders";
import SubjectLines from "./SubjectLines";
import Footer from "./Footer";
import { isModularContent } from "../../../Uitls/Util";

const ChannelTabs = ({ channelAttrElements, assetDataType }) => {
  const subjectlines = (
    <div>
      <SubjectLines
        subjectlinesElements={channelAttrElements?.subjectLines || []}
        assetDataType={assetDataType}
      />
    </div>
  );

  const preheaders = (
    <div>
      <Preheaders
        preheaders={channelAttrElements?.preHeaders || []}
        assetDataType={assetDataType}
      />
    </div>
  );

  const formatedOptOuts = () => {
    let optOuts = [];
    if (isModularContent(assetDataType)) {
      optOuts = JSON.parse(JSON.stringify(channelAttrElements?.optOuts)).map(
        (optout) => {
          optout.text = optout.moduleChannelSubtexts[0].text;
          optout.id = optout.moduleChannelSubtexts[0].cmDocumentNumber;
          return optout;
        }
      );
    }
    return optOuts || [];
  };

  const optouts = (
    <div>
      <Optouts
        optoutsData={
          !isModularContent(assetDataType)
            ? channelAttrElements?.optOuts
            : formatedOptOuts()
        }
        assetDataType={assetDataType}
      />
    </div>
  );

  const footer = (
    <div>
      <Footer
        optouts={channelAttrElements?.footer || []}
        assetDataType={assetDataType}
      />
    </div>
  );

  const getCount = (data) => {
    if (data?.length > 0) {
      return `(${data?.length})`;
    } else {
      return "";
    }
  };

  const renderTabs = () => {
    let titles = [];
    let children = [];
    titles = [
      `Subject Line ${getCount(channelAttrElements?.subjectLines)}`,
      `PreHeader ${getCount(channelAttrElements?.preHeaders)}`,
      `Opt-out ${getCount(channelAttrElements?.optOuts)}`,
      //`Footer ${getCount(channelAttrElements?.footer)}`, not required, sowjanya asked to remove this section
    ];
    children = [subjectlines, preheaders, optouts, footer];
    return <TabPanel titles={titles} children={children} />;
  };

  return <>{renderTabs()}</>;
};

export default ChannelTabs;
