import React, { useState } from "react";
import parse from "html-react-parser";
import CustomModal from "../customModal";
import expandIcon from "../../images/fullScreenIconSmall.svg";
import modalCloseIcon from "../../images/modalCloseIcon.svg";

const OptOutPreview = ({ title, data }) => {
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageUrl, setImagetUrl] = useState("");
  const showExpandedAssetModal = (image) => {
    setImagetUrl(image);
    setViewImageModal(true);
  };

  const hideModal = () => {
    setImagetUrl("");
    setViewImageModal(false);
  };
  const renderExpandedImageModal = () => {
    return (
      <CustomModal
        displayModal={viewImageModal}
        hideModal={hideModal}
        title={"View Opt-out Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop="false"
      >
        <button
          className="btn btn-link pl-0 assetModalCloseBtn"
          onClick={hideModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
        <div className="assetImageModalBlock custonScrollBar">
          <img src={imageUrl} alt="" className="img-fluid" />
        </div>
      </CustomModal>
    );
  };
  return (
    <div className="Channel-grid-sub-pre-preview">
      <span className="channel-type-head">
        {title} ({`${data.length}`})
      </span>
      <table className="table-style">
        <tbody>
          <tr className="header">
            <td>MODULE MANAGER ID</td>
            <td>DAMS ID</td>
            <td>MLR STATUS</td>
            <td>IMAGE</td>
            <td>TEXT</td>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={item.id}>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.id}{" "}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.documentNumber || "-"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.mlrStatus || "Not Submitted"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "25%" }}>
                  {item.visual ? (
                    <div className="image-with-expand">
                      <img className="img-fluid" src={item.visual} alt="" />
                      <div className="optout-image-expand">
                        <img
                          src={expandIcon}
                          alt=""
                          onClick={() => {
                            showExpandedAssetModal(item.visual);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "30%" }}>
                  {parse(item.text || "-")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {renderExpandedImageModal()}
    </div>
  );
};
export default OptOutPreview;
