import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SomeServerError(props) {
  const { t } = useTranslation();
  return <p className="notificationText">{t("apiErrors.err500")}</p>;
}

export default SomeServerError;
