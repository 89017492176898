import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";

import LogoutCTA from "./LogoutCTA";
import HeaderDefaultDashboard from "../components/Headers/HeaderDefaultDashboard";
import {
  getTrnaslateDashboardDetails,
  translatePendingStatus,
} from "../services/apis";
import { routes } from "../Uitls/Util";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import InstructionsOrGuidelines from "../components/Instructions/Guidelines/InstructionsOrGuidelines";

import CircularProgressBar from "../Uitls/CircularProgressBar";
import searchIcon from "../images/masterTacticSearchIcon.svg";
import selfTranslator from "../images/selfTranslator.svg";
import viewInstructions from "../images/view-instructions.svg";

import "../styles/pages/DashboardTrasnlate.scss";

const DashboardReviewer = () => {
  const [pendingToggle, setPendingToggle] = useState(false);
  const [translateRowData, setTranslateRowData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [clickedJobId, setClickedjobId] = useState(undefined);

  let history = useHistory();

  const tranlateDashboardErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getTranlateDashboardData = () => {
    setIsloading(true);
    getTrnaslateDashboardDetails((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        tranlateDashboardErr(errCoponent);
        setIsloading(false);
      } else {
        setIsloading(false);
        setTranslateRowData(response.data);
      }
    });
  };

  useEffect(() => {
    getTranlateDashboardData();
  }, []);

  const tranformDate = (inputDate) => {
    let result = "";
    let UpdatedInputDate = inputDate.split(" ")[0];
    if (UpdatedInputDate) {
      let parts = UpdatedInputDate.split("-");
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return result;
  };

  const tranformDueDate = (inputDate) => {
    let result = "";
    if (inputDate) {
      let parts = inputDate.split("-");
      result = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return result;
  };

  const getClickedRowId = (id) => {
    setClickedjobId(id);
  };

  const columns = [
    {
      field: "jobCode",
      headerName: "Job ID",
      sortable: false,
      //   width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="translateJobId">{params.row.jobCode}</span>
      ),
    },
    {
      field: "createdDateTime",
      headerName: "Date Created",
      sortable: false,
      //   width: 150,
      flex: 1,
      renderCell: (params) => tranformDate(params.row.createdDateTime),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      // type: "number",
      sortable: false,
      //   width: 150,
      flex: 1,
      renderCell: (params) => tranformDueDate(params.row.dueDate),
    },
    {
      field: "sourceLanguage",
      headerName: "Original Language",
      // type: "number",
      sortable: false,
      //width: 150,
      flex: 1,
    },
    {
      field: "targetLanguage",
      headerName: "Translate to",
      description: "",
      sortable: false,
      //width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div style={{ display: "inline-block", width: "65px" }}>
              {params.row.targetLanguage}{" "}
            </div>
            <div style={{ display: "inline-block" }}>
              {params.row.instructions?.length > 0 && (
                <OverlayTrigger
                  trigger="click"
                  placement="auto"
                  rootClose={true}
                  onEnter={() => {
                    getClickedRowId(params.row.id);
                  }}
                  overlay={
                    <Popover id="tranlateDasboardPopover">
                      {params.row.id === clickedJobId && (
                        <InstructionsOrGuidelines
                          instructions={params.row.instructions}
                        />
                      )}
                    </Popover>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <img
                      src={viewInstructions}
                      alt=""
                      className="view-guidelines"
                      width="12"
                      {...triggerHandler}
                      ref={ref}
                    />
                  )}
                </OverlayTrigger>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "assignedToName",
      headerName: "Assigned To",
      description: "",
      sortable: false,
      // width: 150,
      flex: 1.5,
      renderCell: (params) => (
        <>
          {params.row.selfTranslate === true && (
            <img src={selfTranslator} alt="" className="mr-2" />
          )}
          {params.row.assignedToName}
        </>
      ),
    },
    {
      field: "reviewerStatus",
      headerName: "Status",
      description: "",
      sortable: false,
      //width: 150,
      flex: 1.5,
      renderCell: (params) => (
        <span
          className={
            params.row.reviewerStatus === "Pending"
              ? "transStatusPending"
              : params.row.reviewerStatus === "Approved"
              ? "transStatusSuccess"
              : "transStatusInprogress"
          }
        >
          {params.row.reviewerStatus}
        </span>
      ),
    },
    {
      field: "",
      headerName: "Action",
      description: "",
      sortable: false,
      // width: 150,
      flex: 1,
      renderCell: (params) => (
        <button
          className="btn btn-link pl-0 pr-0 transBtnSize"
          size="small"
          onClick={() => {
            const translateModuleinfo = {
              translateModuleId: params.row.tragetModuleId,
              translateComponents: params.row.translateComponents,
              sourceLanguage: params.row.sourceLanguage,
              targetLanguage: params.row.targetLanguage,
              jobId: params.row.id,
              translateType: params.row.selfTranslate
                ? "translator"
                : "reviewer",
              jobType: "Self",
            };
            navigateToTranslate(
              params.row.reviewerStatus,
              params.row.id,
              params.row.tragetModuleId,
              params.row.selfTranslate ? true : false,
              translateModuleinfo
            );
          }}
        >
          <span
            className={
              params.row.reviewerStatus === "Ready For Review" ||
              (params.row.selfTranslate === true &&
                params.row.reviewerStatus === "Pending") ||
              (params.row.selfTranslate === true &&
                params.row.reviewerStatus === "In Progress")
                ? ""
                : "translateComplete"
            }
          >
            {params.row.selfTranslate ? "Translate" : "Review"}
          </span>
        </button>
      ),
    },
  ];

  const navigateToTranslate = (
    status,
    pendingId,
    translateId,
    translateType,
    translateModuleinfo
  ) => {
    if (
      (status !== "Ready For Review" && translateType === false) ||
      status === "Approved"
    ) {
      return true;
    } else if (status === "Pending") {
      const formData = new FormData();
      formData.append("jobId", pendingId);

      translatePendingStatus(formData, (response, err, errCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errCode);
          tranlateDashboardErr(errCoponent);
        } else {
          translateRedirect(translateModuleinfo);
        }
      });
    } else {
      translateRedirect(translateModuleinfo);
    }
  };

  const translateRedirect = (translateModuleinfo) => {
    window.localStorage.removeItem("translateModuleInfo");
    window.localStorage.setItem(
      "translateModuleInfo",
      JSON.stringify(translateModuleinfo)
    );
    if (translateModuleinfo.translateComponents.includes("claims")) {
      history.push({
        pathname: routes.translateClaims,
        state: { moduleId: translateModuleinfo.translateModuleId },
      });
    } else if (translateModuleinfo.translateComponents.includes("channel")) {
      history.push({
        pathname: routes.translateChannel,
        state: { moduleId: translateModuleinfo.translateModuleId },
      });
    } else {
      history.push({
        pathname: routes.translateAdditionalInfo,
        state: { moduleId: translateModuleinfo.translateModuleId },
      });
    }
  };

  const toggleBtnPending = () => {
    const newToggleState = !pendingToggle;
    if (newToggleState) {
      setPendingToggle(newToggleState);
    } else {
      setPendingToggle(newToggleState);
    }
  };

  const searchedTextData = translateRowData.filter((column) => {
    if (searchedText === "" || searchedText.trim() === "") {
      return true;
    }
    return (
      column &&
      ((column.sourceLanguage &&
        column.sourceLanguage
          .toLowerCase()
          .includes(searchedText.toLowerCase())) ||
        (column.targetLanguage &&
          column.targetLanguage
            .toLowerCase()
            .includes(searchedText.toLowerCase())) ||
        (column.jobCode &&
          column.jobCode.toLowerCase().includes(searchedText.toLowerCase())) ||
        (column.assignedToName &&
          column.assignedToName
            .toLowerCase()
            .includes(searchedText.toLowerCase())) ||
        (column.reviewerStatus &&
          column.reviewerStatus
            .toLowerCase()
            .includes(searchedText.toLowerCase())))
    );
  });

  const pendingTranslatetData = translateRowData.filter((column) => {
    if (pendingToggle) {
      const toggelText = "Pending";
      return (
        column &&
        column.reviewerStatus &&
        column.reviewerStatus.toLowerCase().includes(toggelText.toLowerCase())
      );
    }
  });

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <LogoutCTA />
      <div className="mt-2">
        <HeaderDefaultDashboard />
      </div>
      <div className="container-fluid">
        <div className="row align-items-center mt-4 mb-3">
          <div className="col-md-6 translateTitle">Translate Jobs</div>
          <div className="col-md-2 text-right">
            Show Pending
            <Switch
              className="filterRightMargin mr-2"
              inputProps={{ "aria-label": "primary checkbox" }}
              checked={pendingToggle}
              onChange={toggleBtnPending}
            />
          </div>
          <div className="col-md-4 text-right">
            <div className="translateModuleSearch">
              <div className="d-flex">
                <img
                  src={searchIcon}
                  alt=""
                  className="translateSearchIcon"
                  width="20"
                />
                <input
                  type="text"
                  placeholder="Search by ID, date, name, status"
                  className="translateSearchInput"
                  value={searchedText}
                  onChange={(e) => setSearchedText(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row align-itmes-center">
          <div className="col-md-12">
            <div className="translateTableBg">
              <DataGrid
                rows={
                  searchedText
                    ? searchedTextData
                    : pendingToggle
                    ? pendingTranslatetData
                    : translateRowData
                }
                columns={columns}
                //pageSize={5}
                disableColumnFilter
                disableColumnMenu={true}
                showColumnRightBorder={false}
                disableExtendRowFullWidth={false}
                hideFooterPagination={true}
                disableSelectionOnClick
                autoHeight={translateRowData}
                headerHeight={40}
                rowHeight={40}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardReviewer;
