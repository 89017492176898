import React, { useEffect, useState, useRef } from "react";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
const PersonaToneModal = (props) => {
  const {
    hidePersonaToneModal,
    savePersonaToneData,
    selectedComponent,
    selectedIndex,
  } = props;
  const validRefTone = useRef(null);
  const validRefPersona = useRef(null);
  const [tmpData, setTmpData] = useState({ persona: "", tone: "" });
  useEffect(() => {
    setTmpData({ ...selectedComponent });
  }, [selectedComponent]);

  const handlePersonaChange = (TextChanged, type) => {
    const tmp = { ...tmpData };
    if (type === "persona") {
      tmp.persona = TextChanged;
    } else {
      tmp.tone = TextChanged;
    }
    setTmpData(tmp);
  };

  const shouldEnableSave = () => {
    if (
      (tmpData.persona !== null && tmpData.persona !== "") ||
      (tmpData.tone !== null && tmpData.tone !== "")
    ) {
      validRefPersona.current.innerHTML = tmpData.persona;
      let personaText = validRefPersona.current.innerText;
      validRefTone.current.innerHTML = tmpData.tone;
      let toneText = validRefTone.current.innerText;
      if (
        (personaText && personaText.trim().length) ||
        (toneText && toneText.trim().length)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="personaModalTitle">
        <span>Add persona | Tone</span>
      </div>
      <div className="personaContentBlock">
        {tmpData.persona && <p className="personaLabel">Persona</p>}
        <FroalaEditor
          placeHolder="Enter Persona"
          variant="outlined"
          tag="textarea"
          handleModelChange={(text) => handlePersonaChange(text, "persona")}
          selectedAtom={tmpData.persona || ""}
        />
      </div>
      <div className="d-none" ref={validRefPersona}></div>
      <div className="personaContentBlock mt-4 mb-4">
        {tmpData.tone && <p className="personaLabel">Tone</p>}
        <FroalaEditor
          placeHolder="Enter Tone"
          variant="outlined"
          tag="textarea"
          handleModelChange={(text) => handlePersonaChange(text, "tone")}
          selectedAtom={tmpData.tone || ""}
        />
      </div>
      <div className="d-none" ref={validRefTone}></div>
      <div className="d-flex justify-content-between">
        <button className="btn btn-link pl-0" onClick={hidePersonaToneModal}>
          CLOSE
        </button>
        <button
          className="btn btn-primary personaSavBtn"
          onClick={() => {
            selectedComponent.persona = tmpData.persona;
            selectedComponent.tone = tmpData.tone;
            savePersonaToneData(selectedComponent, selectedIndex);
          }}
          disabled={!shouldEnableSave()}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};
export default PersonaToneModal;
