import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LockUnlockAlertModal from "./LockUnlockAlertModal";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { lockAssetOrModule } from "../../services/apis";
import CustomModal from "../../components/customModal";
import "../../styles/components/ExistingTatics.scss";
import "../../styles/CustomToastify.scss";

import lockIconBlue from "../../images/lockIconBlue.svg";
import expandIcon from "../../images/fullScreenIcon.svg";
import unlockIcon from "../../images/unlockBlue.svg";
import modalCloseIcon from "../../images/modalCloseIcon.svg";
import historyIcon from "../../images/history.svg";

const getDiffInDays = (d1, d2) => {
  const t1 = d1.getTime();
  const t2 = d2.getTime();
  return (t1 - t2) / (24 * 3600 * 1000);
};

const ExistingTacticsAsset = (props) => {
  const {
    assetAndModuleDetails,
    setSelectedAssetHandler,
    selectedAssetDetails,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [lockUnlockAlertModal, setLockUnlockAlertModal] = useState(false);
  const [approvalData, setApprovalData] = useState(undefined);
  useEffect(() => {
    setPageNumber(0);
    setTimeout(() => {
      setPageNumber(1);
    }, 0);
  }, [selectedAssetDetails]);

  const assetUnclockRequest = (moduleId, lockStatus) => {
    const data = {
      locked: lockStatus === "Locked" ? "Unlocked" : "Locked",
      type: "Asset",
      typeId: moduleId,
    };
    setApprovalData(data);
    setLockUnlockAlertModal(true);
    //unlockModuleAndAsset(data);
  };

  const unlockAssetOrmoduleErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const unlockModuleAndAsset = () => {
    setLockUnlockAlertModal(false);
    lockAssetOrModule(approvalData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        unlockAssetOrmoduleErr(errCoponent);
        setApprovalData(undefined);
      } else {
        toast.success(
          <div style={{ color: "#000" }}>
            {approvalData.locked === "Unlocked"
              ? `Request sent to Un-lock the ${
                  approvalData.type === "Asset" ? "Tactic" : "Module"
                }`
              : `{approvalData.type === "Asset" ? "Tactic" : "Module"}{" "}
            {approvalData.locked}`}
          </div>,
          {
            autoClose: 2000,
            position: "top-center",
          }
        );
        setApprovalData(undefined);
        console.log(response.data);
      }
    });
  };
  const showModal = (image) => {
    setImageUrl(image);
    setViewImageModal(true);
  };

  const hideModal = () => {
    setViewImageModal(false);
  };
  const handleCloseAlertModal = () => {
    setLockUnlockAlertModal(false);
    setApprovalData(undefined);
  };
  return (
    <>
      <h2 className="masterTacticTitle">
        {/* {props.titleType === "initial" ? `All Tactic` : "Search Results"}{" "} */}
        All Tactics
      </h2>
      <p className="search-items">
        {assetAndModuleDetails.length}{" "}
        {assetAndModuleDetails.length === 1 ? "Item" : "Items"}
      </p>
      <div className="masterTacticsContainer custonScrollBar">
        {assetAndModuleDetails &&
          assetAndModuleDetails.map((item, index) => {
            const hash = Date.now();
            const diff = getDiffInDays(
              new Date(),
              new Date(item.createdDateTime || item.retrievedDateTime)
            );
            return (
              <div
                className={
                  (item && item.storageAssetId) ===
                  (selectedAssetDetails && selectedAssetDetails.storageAssetId)
                    ? "row masterAssetBlock no-gutters active align-items-center"
                    : "row masterAssetBlock no-gutters align-items-center"
                }
                key={index}
              >
                <div className="masterAssePdfPreveiw col-md-5">
                  {item.thumbnailUrl && (
                    <>
                      <div className="expandIcon">
                        <img
                          src={expandIcon}
                          alt=""
                          onClick={() => {
                            showModal(item.thumbnailUrl);
                          }}
                        />
                      </div>
                      <div className="mr-3 assetImgThumb">
                        <img
                          src={item.thumbnailUrl}
                          key={hash}
                          alt=""
                          className="img-fluid tacticsAssetThumb"
                          onClick={() => {
                            setSelectedAssetHandler(
                              assetAndModuleDetails,
                              item.storageAssetId
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-7">
                  {diff <= 2 && (
                    <p className="timeChip mb-1 ml-3">
                      <img src={historyIcon} alt="History" /> 2 days Ago
                    </p>
                  )}
                  <p
                    className="masterAssetTitle mb-1 ml-3"
                    onClick={() => {
                      setSelectedAssetHandler(
                        assetAndModuleDetails,
                        item.storageAssetId
                      );
                    }}
                  >
                    {item.assetName}
                  </p>
                  {item.locked && item.locked === "Locked" ? (
                    <button
                      className="btn btn-link p-0 btnApprove ml-3"
                      onClick={() => {
                        assetUnclockRequest(
                          item.modularisedAssetId || item.harvestedAssetId,
                          item.locked
                        );
                      }}
                    >
                      <img
                        src={lockIconBlue}
                        alt=""
                        style={{ marginBottom: "2px" }}
                        width="9"
                      />{" "}
                      Request Access
                    </button>
                  ) : (
                    <button
                      className="btn btn-link p-0 ml-3"
                      onClick={() => {
                        assetUnclockRequest(
                          item.modularisedAssetId || item.harvestedAssetId,
                          item.locked
                        );
                      }}
                    >
                      <img
                        src={unlockIcon}
                        alt=""
                        style={{ marginBottom: "2px" }}
                        width="9"
                      />{" "}
                      Lock Asset
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <CustomModal
        displayModal={viewImageModal}
        hideModal={hideModal}
        title={"View Asset Image"}
        size="md"
        dilogClassName="assetImageModal"
        backdrop={false}
      >
        <div className="assetImageModalBlock custonScrollBar">
          <img src={imageUrl} alt="" className="img-fluid" />
        </div>
        <button
          className="btn btn-link pl-0 imageModalCloseBtn"
          onClick={hideModal}
        >
          <img src={modalCloseIcon} alt="" />
          <span>esc</span>
        </button>
      </CustomModal>
      <CustomModal
        displayModal={lockUnlockAlertModal}
        hideModal={handleCloseAlertModal}
        size="md"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <LockUnlockAlertModal
          approvalData={approvalData}
          handleYes={unlockModuleAndAsset}
          handleNo={handleCloseAlertModal}
        />
      </CustomModal>
    </>
  );
};

export default ExistingTacticsAsset;
