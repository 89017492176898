import i18next from "i18next";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles/components/ModuleDetails.scss";

import {
  getModuleInfoFromLocalStorage,
  getTranslationJobType,
  isThisMLRFeedbackFlow,
} from "../Uitls/Util";
function ModuleDetailsNav(props) {
  const { t } = useTranslation();
  //const { submitForReview, submitForReviewBtn, module } = props;
  const {
    submitForReview,
    module,
    primaryClaimStatus,
    setChannelSelectionModal,
  } = props;

  const goBack = () => {
    window.history.back();
  };

  const getTranslatorButtonTitle = () => {
    const jobType = getTranslationJobType();
    if (jobType && jobType.trim().toLowerCase() === "self") {
      return i18next.t("translated.complete");
    }
    return i18next.t("generalText.submitForReview");
  };

  return (
    <>
      <Container fluid>
        {!props.isTranslatorPreview && (
          <Row className="row moduleDetails align-items-center">
            <Col className="col-md-9">
              <div className="d-flex">
                <div className="d-flex">
                  <div className="d-flex">
                    <div className="metadata-lable">Brand</div>
                    <div className="metadata-value">
                      {module && module.brandName}
                    </div>
                  </div>
                  <div className="d-flex " style={{ marginLeft: "25px" }}>
                    <div className="metadata-lable d-none">Campaign</div>
                    <div
                      className="metadata-value text-truncated d-none"
                      title={module.campaign}
                    >
                      {module && module.campaign}
                    </div>
                  </div>
                  <div className="d-flex" style={{ marginLeft: "25px" }}>
                    <div className="metadata-lable">Module Name</div>
                    <div
                      className="metadata-value text-truncated"
                      title={module.name}
                    >
                      {module && module.name}
                    </div>
                  </div>
                  <div className="d-flex" style={{ marginLeft: "25px" }}>
                    <div className="metadata-lable">Module ID</div>
                    <div className="metadata-value">
                      {module && module.moduleCode}
                    </div>
                  </div>
                  {/* <div className="d-flex" style={{ marginLeft: "25px" }}>
                    <div>
                      <img
                        className="mlr-refresh-icon"
                        src={mlrRefreshIcon}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          props.getVeevaModuleStatus();
                        }}
                      />
                      <span className="metadata-lable">MLR Status</span>
                    </div>
                    {mlrModuleStatus && mlrModuleStatus && (
                      <>
                        <div>
                          <img
                            src={returnMlrIcons(mlrModuleStatus)}
                            alt={props.moduleMlr}
                            className="mlr-status-icon"
                          />
                        </div>
                        <div className="metadata-value" title={mlrModuleStatus}>
                          {mlrModuleStatus && mlrModuleStatus}
                        </div>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </Col>
            {!isThisMLRFeedbackFlow() && (
              <Col className="col-md-3 text-right moduleDetailBtn ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="I want to"
                  className="module-button"
                  disabled={props.isThisModuleLocked}
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      props.modifyModule();
                    }}
                    disabled={props.selectedTabIndex === 0 ? true : false}
                  >
                    Modify Source Module
                  </Dropdown.Item>
                  {module.stylizedModules?.length > 0 ? (
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        props.modifyStylizedModule();
                      }}
                      disabled={props.selectedTabIndex === 0 ? false : true}
                    >
                      {props.selectedTabIndex === 1
                        ? "Create Stylized Module"
                        : "Modify Stylized Module"}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setChannelSelectionModal(true);
                      }}
                    >
                      Create Stylized Module
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as="button"
                    onClick={props.onClickBasedOn}
                    disabled={props.selectedTabIndex === 0 ? true : false}
                  >
                    Based On
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={props.adressMLRFeedback}
                    disabled={
                      props.selectedTabIndex === 0
                        ? true
                        : props.mlrModuleStatus === "Not Submitted"
                        ? true
                        : false
                    }
                  >
                    Address MLR Feedback
                  </Dropdown.Item>
                  {module.groupModulesVeevaPublishFlag && (
                    <Dropdown.Item
                      as="button"
                      onClick={props.publish}
                      disabled={
                        props.selectedTabIndex === 0
                          ? true
                          : primaryClaimStatus === true
                          ? true
                          : false
                      }
                    >
                      Publish Module
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    as="button"
                    disabled={props.selectedTabIndex === 0 ? true : false}
                  >
                    Generate Metadata Matrix
                  </Dropdown.Item>
                  {module.moduleType?.toLowerCase() !== "localized" && (
                    <Dropdown.Item
                      as="button"
                      onClick={props.localizeModuleModal}
                      disabled={props.selectedTabIndex === 0 ? true : false}
                    >
                      {t("generalText.localize")}
                    </Dropdown.Item>
                  )}

                  {module.moduleType?.toLowerCase() === "localized" && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        props.translateModule(
                          [
                            {
                              moduleId: module.id,
                              sourceModuleId: module.sourceModuleId,
                              language: module.language,
                            },
                          ],
                          {
                            groupId:
                              module.translationGroup[0].translationGroupId,
                          }
                        );
                      }}
                    >
                      {t("generalText.translate")}
                    </Dropdown.Item>
                  )}
                  {props.module.moduleMlr &&
                  props.module.moduleMlr.length > 0 ? (
                    <Dropdown.Item
                      as="button"
                      onClick={props.getVeevaDownload}
                      disabled={props.selectedTabIndex === 0 ? true : false}
                    >
                      Download PDF from DAMS
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                </DropdownButton>
              </Col>
            )}
            {isThisMLRFeedbackFlow() && (
              <Col className="col-md-3 text-right moduleDetailBtn">
                <Button
                  as="button"
                  className="address-mlr-resubmit"
                  style={{ float: "right" }}
                  onClick={props.publish}
                >
                  Resubmit
                </Button>
                <Button
                  variant=" moduleDetailBtn mr-4"
                  as="button"
                  className="address-mlr-modify"
                  onClick={() => {
                    props.modifyModule(true);
                  }}
                >
                  Modify
                </Button>
              </Col>
            )}
          </Row>
        )}
        {props.isTranslatorPreview && (
          <Row className="row mt-3 moduleDetails pb-2">
            <div className="col-md-12">
              <Button
                as="button"
                className="submit-for-review"
                style={{ float: "right" }}
                onClick={() => {
                  submitForReview();
                }}
                disabled={!props.submitForReviewBtn()}
              >
                {getTranslatorButtonTitle()}
              </Button>
              <Button
                variant="outline-secondary mr-4 btnClose nav-buttons-margin"
                as="button"
                style={{ float: "right", marginRight: "20px" }}
                onClick={() => {
                  goBack();
                }}
              >
                BACK
              </Button>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
}

export default ModuleDetailsNav;
