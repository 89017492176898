import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import geditorConfig from "./getGrapeJsEditor";
import LeftPanel from "./LeftPanel";
import LeftPanelDenovo from "./LeftPanelDenovo";
import ModuleComponents from "./ModuleComponents";
import TopPanel from "./TopPanel";

import "grapesjs/dist/css/grapes.min.css";
import "../../styles/components/editor.scss";
import {
  capitalizeString,
  getCurrentUser,
  routes,
  showError,
  downloadPdfFromByteArray,
} from "../../Uitls/Util";
import RightPanel from "./RightPanel";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { exportStylizedModule } from "../../services/apis";
import CustomModal from "../customModal";
import PreviewStylizedModule from "./PreviewStylizedModule";
import CtaSelectionScreen from "./CtaSelection";
import ModuleDetails from "../../Pages/ModuleDetails";

const Editor = (props) => {
  const [editor, setEditor] = useState(null);
  const [isOpenPanel, setIsOpenPanel] = useState(true);
  const [moduleData, setModuleData] = useState(undefined);
  const moduleDataRef = useRef(moduleData);
  const options = ["email", "banner"];
  const [selectedOption, setSelectedOption] = useState(0);
  const [stylizedModuleInfo, setStylizedModuleInfo] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [stylizedModule, setStylizedModule] = useState(undefined);
  const [brandStyleGuideline, setBrandStyleHGuideline] = useState(undefined);
  const [openCtaSelectionModal, setOpenCtaSelectionModal] = useState(undefined);
  const [alreadySelectedTextElements, setAlreadySelectedTextElements] =
    useState(undefined);

  useEffect(() => {
    moduleDataRef.current = moduleData;
  }, [moduleData]);
  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };
  const isDenovoModule =
    (getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() ??
      "") === "denovo" || props?.location?.state?.assetDataType === "denovo";

  useEffect(() => {
    if (props && props.location && props.location.state) {
      let module = props?.location?.state?.module || undefined;
      let channel = props?.location?.state?.channelType || undefined;
      let sourceType = props?.location?.state?.sourceType || undefined;
      let assetDataType = props?.location?.state?.assetDataType || undefined;
      let moduleReusableTexts = props?.location?.state?.moduleReusableTexts;

      //let sourceType = props?.location?.state?.sourceType || undefined;
      if (module) {
        setModuleData(() => {
          const editor = geditorConfig(
            module,
            module.id,
            module.campaign,
            module.brandName,
            channel,
            sourceType,
            assetDataType,
            setStylizedModuleInfo,
            stylizedModuleInfo,
            setSavingData,
            setBrandStyleHGuideline,
            setAlreadySelectedTextElements,
            moduleDataRef,
            moduleReusableTexts
          );
          setEditor(editor);
        });
        setModuleData(props?.location?.state || undefined);
      } else {
        window.location.replace(routes.homeScreen);
      }
    } else {
      window.location.replace(routes.homeScreen);
    }
    document.body.style.backgroundColor = "#f3f6fa";
  }, [props?.location?.state?.cmId]);

  useEffect(() => {
    if (moduleData && moduleData && alreadySelectedTextElements) {
      let data = JSON.parse(JSON.stringify(moduleData));
      data.module.textElements = alreadySelectedTextElements;
      setModuleData(data);
    }
  }, [alreadySelectedTextElements]);
  const openPanel = () => {
    setIsOpenPanel(!isOpenPanel);
  };

  const drag = (type, data, e, actualType) => {
    data =
      type === "text" && actualType !== "cta"
        ? `<div style="font-family: Arial, Helvetica, sans-serif; font-size:12px">${data}</div>`
        : data;
    e.dataTransfer.setData(type, data);
  };

  const getStylizedModuleInfo = () => {
    const createdDate = stylizedModuleInfo?.createdDateTime
      ? new Date(stylizedModuleInfo?.createdDateTime)
      : new Date();
    const editedDate = stylizedModuleInfo?.upatedDateTime
      ? new Date(stylizedModuleInfo?.upatedDateTime)
      : new Date();
    return {
      createdDate: moment(createdDate).format("MM/DD/YYYY, HH:mm:ss") + " hr",
      createdBy: stylizedModuleInfo?.cretedByname || getCurrentUser(),
      editedDate: moment(editedDate).format("MM/DD/YYYY, HH:mm:ss") + " hr",
      editedBy: stylizedModuleInfo?.updatedByname || getCurrentUser(),
    };
  };

  const exportModule = (exportTypes, callback) => {
    setIsloading(true);
    exportTypes.forEach((type, index) => {
      const fileName =
        moduleData.module.moduleCode +
        "_" +
        capitalizeString(moduleData?.channelType || "") +
        "_" +
        "Stylized Module";
      const extension = `.${type.toLowerCase()}`;
      const payload = {
        type: type,
        pdfWidth: "600",
        fileName: fileName + extension,
        html: `<!DOCTYPE html> <html><head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /> 
  </head>${editor.runCommand("gjs-get-inlined-html")}</html>`,
      };

      exportStylizedModule(
        payload,
        props?.location?.state?.sourceType,
        (res, err) => {
          setIsloading(false);
          if (res) {
            if (type.toLowerCase() === "pdf") {
              downloadPdfFromByteArray([res.data], fileName);
            }
            if (type.toLowerCase() === "html") {
              var file = new File(["\ufeff" + res.data], fileName + extension, {
                type: "text/plain:charset=UTF-8",
              });

              //create a ObjectURL in order to download the created file
              const url = window.URL.createObjectURL(file);

              //create a hidden link and set the href and click it
              var a = document.createElement("a");
              a.style = "display: none";
              a.href = url;
              a.download = file.name;
              a.click();
              window.URL.revokeObjectURL(url);
            }
          }
          if (index === exportTypes.length - 1) {
            callback();
          }
          if (err) {
            showError(err.response && err.response.status);
          }
        }
      );
    });
  };

  const runEditorCommand = (command, exportTypes, callback) => {
    switch (command) {
      case "saveDb":
        editor.runCommand("saveDb");
        break;
      case "undo":
        editor.runCommand("undo");
        break;
      case "redo":
        editor.runCommand("redo");
        break;
      case "export":
        exportModule(exportTypes, () => {
          callback();
        });
        break;
      case "preview":
        setStylizedModule(editor.runCommand("gjs-get-inlined-html"));
        break;
      case "info":
        return getStylizedModuleInfo();
      case "publish":
        break;
      case "close":
        break;
      default:
        break;
    }
  };

  const editCtas = () => {
    setOpenCtaSelectionModal(true);
  };

  const closeCtaSelectionModal = () => {
    setOpenCtaSelectionModal(false);
  };

  const upDateCtas = (selectedCta) => {
    let data = JSON.parse(JSON.stringify(moduleData));
    data.module.textElements = selectedCta;
    setModuleData(data);
    setOpenCtaSelectionModal(false);
  };

  const getAllCtas = () => {
    let ctas = props?.location?.state?.moduleReusableTexts || [];
    return ctas.filter((item) => item.reusableTextType === "CTA");
  };
  return (
    <>
      <div
        className="container-fluid p-0"
        style={{ backgroundColor: "f3f6fa" }}
      >
        <CircularProgressBar isloading={isloading} />
        <TopPanel
          module={moduleData?.module}
          channelType={capitalizeString(moduleData?.channelType || "")}
          runCommand={runEditorCommand}
          editor={editor}
          savingData={savingData}
        />
        <div className="row editorBg ml-0 mr-0 mt-4">
          <div className="col-md-3 editorPanelBorder">
            <div className="editorLeftContainer">
              {isDenovoModule ? (
                <LeftPanelDenovo
                  module={moduleData?.module}
                  isOpenPanel={isOpenPanel}
                  openPanel={openPanel}
                  onDragContent={drag}
                  editCtas={editCtas}
                  ctaLabels={getAllCtas()}
                />
              ) : (
                <LeftPanel
                  module={moduleData?.module}
                  isOpenPanel={isOpenPanel}
                  openPanel={openPanel}
                  onDragContent={drag}
                  editCtas={editCtas}
                  ctaLabels={getAllCtas()}
                />
              )}
            </div>
          </div>
          <div
            className="col-md-6 pt-4 editorPanelBorder"
            style={{ backgroundColor: "white" }}
          >
            <div className="editorContainer customScrollBar">
              <div className="editorFixedBlock">
                <div id="gjsEditor"></div>
              </div>
            </div>
          </div>
          <div className="col-md-3 editorPanelBorder">
            <RightPanel
              editor={editor}
              module={moduleData?.module}
              brandStyleGuideline={brandStyleGuideline}
            />
          </div>
        </div>
        <CustomModal
          displayModal={stylizedModule}
          hideModal={setStylizedModule}
          title={""}
          size="lg"
          dilogClassName="alertModalSmall modal-with-top-border"
          keyboard={false}
          backdrop="true"
        >
          <PreviewStylizedModule
            stylizedModule={stylizedModule}
            closePreview={(flag) => {
              if (flag) {
                setStylizedModule(undefined);
              }
            }}
          />
        </CustomModal>
        <CustomModal
          displayModal={openCtaSelectionModal}
          hideModal={openCtaSelectionModal}
          size="xl"
          dilogClassName="alertModalSmall"
          backdrop={false}
        >
          <CtaSelectionScreen
            brand={moduleData?.module?.brandName || ""}
            campaignName={moduleData?.module?.campaign || ""}
            channelType={moduleData?.channelType || ""}
            cancelCreatingStylisedModule={closeCtaSelectionModal}
            handleProceed={upDateCtas}
            isStylisedModuleInitiated={openCtaSelectionModal}
            selectedCtasFromEditor={moduleData?.module?.textElements}
            openCtaSelectionModal={openCtaSelectionModal}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default Editor;
