import React, { useState, useRef } from "react";
import parse from "html-react-parser";

import Menu from "../TranslateClaims/Menu";

import translateTick from "../../../images/translateTick.svg";
import translateIcon from "../../../images/translateIcon.svg";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import "../../../styles/pages/TranslateClaims.scss";
import "../../../styles/components/PrimaryClaim.scss";
import Comments from "../Comments";

const TranslateAbbreviations = ({
  abbreviations,
  isAnyToggleOn,
  intiateTranslationFor,
  addComment,
  getCommentBubbleFor,
  primaryComponentId,
  comments,
  showCommentsFlag,
  updateShowCommentsFlag,
  getCommentsData,
}) => {
  const abbreviationRef = useRef([]);
  const [abbreviationId, setAbbreviationId] = useState();
  const renderAbbreviations = () => {
    if (abbreviations.length > 0) {
      return abbreviations.map((abbreviation) => {
        return (
          <div className="" key={abbreviation.id}>
            {
              <div className="translateAbbr alignFootnoteAbbreviations">
                <div className="translateAbbrText">
                  {/* {isAnyToggleOn
                    ? abbreviation.prefix && parse(abbreviation.prefix)
                    : abbreviation.prefix && parse(abbreviation.prefix)} */}
                  {abbreviation.prefix && parse(abbreviation.prefix)}
                </div>
                <div
                  className="footNoteLabelN"
                  id={`abbreviation${abbreviation.id}`}
                  ref={(el) => (abbreviationRef.current[abbreviation.id] = el)}
                  onClick={() => {
                    setAbbreviationId(abbreviation.id);
                  }}
                >
                  {/* {isAnyToggleOn
                    ? abbreviation.originalText &&
                      parse(abbreviation.originalText)
                    : abbreviation.text && parse(abbreviation.text)} */}
                  {abbreviation.text && parse(abbreviation.text)}
                </div>
              </div>
            }
          </div>
        );
      });
    }
    return <div></div>;
  };
  const checkForTranslate = () => {
    if (!isAnyToggleOn && abbreviations.length > 0) {
      let result = true;
      for (var i in abbreviations) {
        if (abbreviations[i].translate !== "2") {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  };

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: primaryComponentId,
      componentId: abbreviationId,
    };
    addComment(commentObject);
  };
  return (
    <div className="translateBox translateBoxType1">
      <p className="translateBox-head d-flex justify-content-between">
        Abbreviations
        {checkForTranslate() && (
          <span className="translatedBoxSuccess">
            <img src={translateTick} alt="Translate" />
          </span>
        )}
      </p>
      <div className="translateClaimsContainer customScrollBar">
        {renderAbbreviations()}
      </div>
      {!isAnyToggleOn &&
        !hasUserLoggedInAsReviewer() &&
        abbreviations.length > 0 &&
        abbreviations[0].translate !== 0 && (
          <div style={{ zIndex: "1" }}>
            <button
              className="btn btn-link translateIcon"
              onClick={() => {
                intiateTranslationFor(abbreviations, "abbreviation");
              }}
            >
              <img src={translateIcon} alt="Translate" />
            </button>
          </div>
        )}
      {!isAnyToggleOn && (
        <Menu
          outerRef={abbreviationRef.current[abbreviationId]}
          compClass={`abbreviation${abbreviationId}`}
        />
      )}
      <div className="floating-buttons">
        {!isAnyToggleOn &&
          !hasUserLoggedInAsReviewer() &&
          abbreviations.length > 0 &&
          abbreviations[0].translate !== 0 && (
            <div style={{ zIndex: "1" }}>
              <button
                className="btn btn-link translate-icon"
                style={{ marginRight: "-12px", bottom: "0" }}
                onClick={() => {
                  intiateTranslationFor(abbreviations, "abbreviation");
                }}
              >
                <img src={translateIcon} alt="Translate" />
              </button>
            </div>
          )}
        {abbreviations.length > 0 &&
          abbreviations[0].translate !== 0 &&
          getCommentBubbleFor &&
          getCommentBubbleFor("abbreviation", primaryComponentId)}
      </div>
      {showCommentsFlag && (
        <Comments
          moduleId={module.id}
          componentId={primaryComponentId}
          childComponentId=""
          comments={comments}
          commentsPopupCurrentState={showCommentsFlag}
          commentsPopupUpdateState={updateShowCommentsFlag}
          getCommentsData={getCommentsData}
        />
      )}
      {!isAnyToggleOn && (
        <Menu
          outerRef={abbreviationRef}
          compClass={`abbreviation${abbreviationId}`}
          addComment={addAComment}
        />
      )}
    </div>
  );
};

export default TranslateAbbreviations;
