import React from "react";
import "../../../styles/components/VeevaApplyBusinessRules.scss";

const UsageGuidelines = ({ handleClose, usageGuidelinesData }) => {
  const openContentModuleRuleSet = (Url) => {
    window.open(Url, "_blank");
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <h1 className="usageModalHeading">Usage Instructions/ Guidelines</h1>
        <button className="btn btn-link" onClick={handleClose}>
          CLOSE
        </button>
      </div>
      <div className="guidelines-wrpapper">
        <table className="guidelines-table">
          <thead>
            <tr className="guidelines-table-row-header">
              <td width="10">Name</td>
              <td width="30">Label</td>
              <td width="10">Must Use</td>
              <td width="30">Ruleset Summary</td>
              <td width="20">Content Module Ruleset</td>
            </tr>
          </thead>
          <tbody>
            {usageGuidelinesData.map((item, index) => {
              return (
                <tr key={index} className="text-black">
                  <td>{item.name}</td>
                  <td>{item.lebel}</td>
                  <td>{item.mustUse === "true" ? "Yes" : "No"}</td>
                  <td>{item.rulesetSummary}</td>
                  <td>
                    <button
                      key={index}
                      className="btn btn-link"
                      type="button"
                      onClick={(e) => {
                        openContentModuleRuleSet(item.rulesetHyperLink);
                      }}
                    >
                      {item.contentModuleRuleSet}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default UsageGuidelines;
