import React, { useEffect, useState } from "react";
import deleteIcon from "../../../images/closeIcon.svg";
const CompositeModuleSelection = ({
  pageTwoData,
  setPageTwoData,
  addBodyModules,
  contentMatrix,
}) => {
  const [moduleOrder, setModuleOrder] = useState([]);

  useEffect(() => {
    let alpha = Array.from(Array(26)).map((e, i) => i + 65);
    let alphabet = alpha.map((x) => {
      return { value: String.fromCharCode(x), isSelected: false };
    });
    setModuleOrder(alphabet.slice(0, 7));
  }, [pageTwoData]);
  const handleDelete = (moduleId) => {
    let data = { ...pageTwoData };
    let index = data.contentModules.findIndex(
      (item) => item.moduleId === moduleId
    );
    if (index > -1) {
      data.contentModules[index].bodyModuleSection = "";
      setPageTwoData(data);
    } else {
      return;
    }
  };
  const checkForModuleAdded = (value) => {
    const valuesOfA = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "A"
    );
    const valuesOfB = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "B"
    );
    const valuesOfC = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "C"
    );
    const valuesOfD = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "D"
    );
    const valuesOfE = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "E"
    );
    const valuesOfF = pageTwoData.contentModules.filter(
      (element) => element.bodyModuleSection === "F"
    );
    switch (value) {
      case "A":
        return false;
      case "B":
        return valuesOfA.length > 0 ? false : true;
      case "C":
        return valuesOfA.length > 0 && valuesOfB.length > 0 ? false : true;
      case "D":
        return valuesOfA.length > 0 &&
          valuesOfB.length > 0 &&
          valuesOfC.length > 0
          ? false
          : true;
      case "E":
        return valuesOfA.length > 0 &&
          valuesOfB.length > 0 &&
          valuesOfC.length > 0 &&
          valuesOfD.length > 0
          ? false
          : true;
      case "F":
        return valuesOfA.length > 0 &&
          valuesOfB.length > 0 &&
          valuesOfC.length > 0 &&
          valuesOfD.length > 0 &&
          valuesOfE.length > 0
          ? false
          : true;
      case "G":
        return valuesOfA.length > 0 &&
          valuesOfB.length > 0 &&
          valuesOfC.length > 0 &&
          valuesOfD.length > 0 &&
          valuesOfE.length > 0 &&
          valuesOfF.length > 0
          ? false
          : true;
      default:
        return true;
    }
  };
  return (
    <>
      <div className="row">
        {moduleOrder.map((item) => (
          <div className="col-md-3 mb-3" key={item.value}>
            <div className="moduleSlectionBlocks">
              <p className="font-weight-bold mb-0">Body Module {item.value}</p>
              <ul className="bodyModuleContainer customScrollBar">
                {pageTwoData.contentModules
                  .filter((element) => element.bodyModuleSection === item.value)
                  .map((module) => (
                    <li className="d-flex justify-content-between">
                      <span>
                        {module.moduleName}
                        {/* <span className="d-block">{module.moduleCode}</span> */}
                      </span>

                      <button
                        className="btn btn-link p-0 m-0 deleteBodyModule"
                        onClick={() => handleDelete(module.moduleId)}
                      >
                        <img src={deleteIcon} alt="" width="32" />
                      </button>
                    </li>
                  ))}
              </ul>

              <div className="addModulesBtn">
                <button
                  className="btn text-nowrap btn-outline-secondary"
                  disabled={checkForModuleAdded(item.value)}
                  onClick={() => {
                    addBodyModules(item.value);
                  }}
                >
                  + Add Body Modules
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default CompositeModuleSelection;
