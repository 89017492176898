import React from "react";

const StylizedModuleInfo = ({ data }) => {
  return (
    <>
      <div className="styleModuleInfo">
        <span className="stylized-module-info-label">Created date</span>
        <span className="stylized-module-info-value">{data?.createdDate}</span>
      </div>
      <div className="styleModuleInfo">
        <span className="stylized-module-info-label">Created by</span>
        <span className="stylized-module-info-value">{data?.createdBy}</span>
      </div>
      <div className="styleModuleInfo">
        <span className="stylized-module-info-label">Last edited date</span>
        <span className="stylized-module-info-value">{data?.editedDate}</span>
      </div>
      <div className="styleModuleInfo mb-0">
        <span className="stylized-module-info-label">Last edited by</span>
        <span className="stylized-module-info-value">{data?.editedBy}</span>
      </div>
    </>
  );
};

export default StylizedModuleInfo;
