import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import i18next from "i18next";
//Import custom components
import SearchComponent from "../Veeva/SearchModules";
import BusineesRulesCards from "../Veeva/BusinesRulesCard";
import doubleClickIcon from "../../../images/doubleClickIcon.svg";
import expandIcon from "../../../images/fullScreenIcon.svg";
import "../../../styles/components/VeevaApplyBusinessRules.scss";
import "../../../styles/components/ModulePlaceholder.scss";

const ApplyBusinessrules = ({
  campaignName,
  handleCloseCreateModuleModal,
  type,
  asset,
  modulesData = [],
  addedGenericInfo, // for denovo only
  hideBusinessRulesModal,
  moduleNameAndCampaign,
  saveBusinessRules,
  handleUsageGuidelineModal,
  updateAssetModules,
  modules,
  ...props
}) => {
  const [selectedBox, setSelectedBox] = useState("");
  const [contentExists, setContentExists] = useState("");
  const [businessRulesModules, setBusinessRulesModules] = useState(undefined);
  const [veevaBRulesSet, setVeevaBRulesSet] = useState([]);
  const [cannotExistWith, setCannotExistWith] = useState(undefined);

  useEffect(() => {
    setBusinessRulesModules(modulesData[0].cmBusinessRuleList[0]);
    let usageOrGuidelines =
      modulesData[0]?.cmBusinessRuleList[0]?.usageInstructionsGuidelines.filter(
        (rule) => {
          return true;
        }
      );
    setVeevaBRulesSet(usageOrGuidelines);
  }, []);

  const getAddedBusinessRules = (condition) => {
    if (businessRulesModules?.ruleConditions?.length > 0) {
      return businessRulesModules.ruleConditions?.filter((rule) => {
        return rule.ruleCondition === condition;
      });
    }
    return [];
  };

  const addBusinessRule = (bRule) => {
    let modules = { ...businessRulesModules };
    let businessRules = modules.ruleConditions;
    const index = businessRules.findIndex(
      (rule) => rule.ruleCondition === bRule.condition
    );
    if (index > -1) {
      let bRuleModules = businessRules[index].ruleModules;
      const result = bRuleModules.findIndex(
        (element) => element.cmId === bRule.targetModuleId
      );
      if (result < 0) {
        let bRuleModuleObject = {
          claim: bRule.claim,
          cmId: bRule.targetModuleId,
          moduleName: bRule.name,
        };
        bRuleModules.push(bRuleModuleObject);
        businessRules[index].ruleModules = bRuleModules;
        modules.ruleConditions = businessRules;
        setBusinessRulesModules(modules);
      }
    } else {
      let BRuleObject = {
        ruleCondition: bRule.condition,
        ruleModules: [
          {
            claim: bRule.claim,
            cmId: bRule.targetModuleId,
            moduleName: bRule.name,
          },
        ],
      };
      businessRules.push(BRuleObject);
      modules.ruleConditions = businessRules;
      setBusinessRulesModules(modules);
    }
  };

  const deleteBusinessRule = (bRule, condition) => {
    let modules = { ...businessRulesModules };
    let businessRules = modules.ruleConditions;
    const index = businessRules.findIndex(
      (rule) => rule.ruleCondition === condition
    );
    if (index > -1) {
      let bRuleModules = businessRules[index].ruleModules;
      let toBeFilteredBusinessRules = [...bRuleModules].filter((rule) => {
        return rule.cmId !== bRule.cmId;
      });
      if (toBeFilteredBusinessRules.length > 0) {
        businessRules[index].ruleModules = toBeFilteredBusinessRules;
      } else {
        businessRules.splice(index, 1);
      }
      modules.ruleConditions = businessRules;
      setBusinessRulesModules(modules);
    }
  };

  const handleSave = () => {
    let payLoad = getPayload(businessRulesModules.ruleConditions);
    saveBusinessRules(payLoad, "save");
    let InCompatibleWithModuleIds = payLoad.filter(
      (item) => item.condition === "InCompatibleWith"
    );
    let assetModules = [...modules];
    const index = assetModules.findIndex(
      (item) => item.cmId === moduleNameAndCampaign.cmId
    );
    let connotExistsIds = [...assetModules[index].cannotExistRuleList];
    if (index > -1) {
      connotExistsIds.forEach((item, index) => {
        let indexResult = InCompatibleWithModuleIds.findIndex(
          (list) => list.targetCmId === item
        );
        if (indexResult < 0) {
          connotExistsIds.splice(index, 1);
        }
      });
    }
    updateAssetModules(InCompatibleWithModuleIds, connotExistsIds);
  };

  const getPayload = (businessRulesData) => {
    let businessRules = [];
    businessRulesData.forEach((rule) => {
      let resultArr = rule.ruleModules?.map((item) => {
        return {
          condition: rule.ruleCondition,
          targetCmId: item.cmId,
        };
      });
      businessRules.push.apply(businessRules, resultArr);
    });
    return businessRules;
  };

  return (
    <>
      <Row>
        <Col xs="12" md="6" lg="6">
          <div className="businessRulesHeading">Define Business Rules</div>
        </Col>
        <Col xs="12" md="6" lg="6" className="doubleClick">
          <img
            src={doubleClickIcon}
            alt="Double Click"
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          Double Click on the module to apply business rules
        </Col>
      </Row>
      <Row className="mb-3 mt-1" style={{ borderBottom: "solid 1px #e1e1e1" }}>
        <Col xs="12" md="6" lg="6">
          {moduleNameAndCampaign.name}{" "}
          {moduleNameAndCampaign && moduleNameAndCampaign.campaign ? "|" : ""}
          {moduleNameAndCampaign.campaign}
        </Col>
      </Row>
      <Row className="mb-3 businessRulesModalHeight customScrollBar">
        <Col xs="12" md="3" lg="3" className="businessRulesBrdr">
          <SearchComponent
            modules={(modulesData && modulesData[0]?.cmFilterList) || []}
            businessRules={
              (modulesData &&
                modulesData[0]?.cmBusinessRuleList[0]?.ruleConditions) ||
              []
            }
            addBusinessRule={addBusinessRule}
            selectedBox={selectedBox}
            //allowedToAddRule={!isThisModuleStandalone()}
            setContentExists={setContentExists}
            setCannotExistWith={setCannotExistWith}
          />
        </Col>
        <Col xs="12" md="9" lg="9">
          <Row className="bsRulesBoxContainer">
            <BusineesRulesCards
              boxKey="MustExistWith"
              boxTitle="Must Exist With (Mandatory)"
              data={getAddedBusinessRules("MustExistWith")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              //allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="InCompatibleWith"
              boxTitle="Cannot Exist With"
              data={getAddedBusinessRules("InCompatibleWith")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              //allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="Precedes"
              boxTitle="Precedes"
              data={getAddedBusinessRules("Precedes")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              //allowedToAddRule={!isThisModuleStandalone()}
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="Succeeds"
              boxTitle="Succeeds"
              data={getAddedBusinessRules("Succeeds")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              //allowedToAddRule={!isThisModuleStandalone()}
              customCName="rulexBox0"
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />
            <BusineesRulesCards
              boxKey="RelatedTo"
              boxTitle="Related Modules (Optional)"
              data={getAddedBusinessRules("RelatedTo")}
              selectedBoxIndex={selectedBox}
              setSelectedBox={setSelectedBox}
              deleteBusinessRule={deleteBusinessRule}
              //allowedToAddRule={!isThisModuleStandalone()}
              customCName="rulexBox0"
              contentExists={contentExists}
              setContentExists={setContentExists}
              setCannotExistWith={setCannotExistWith}
              cannotExistWith={cannotExistWith}
            />

            <div
              id="instructions-guidelines"
              className="instructions-box rulexBox0 mb-3  col-lg-4 col-md-4 col-12"
            >
              {veevaBRulesSet.length === 0 && (
                <span className="boxLableVerticalMiddle moduleCountPlaceHolder">
                  {i18next.t("additionalInfo.usageOrGuidelines")}
                </span>
              )}
              <div className="veevaBusinessRulesSetBox">
                {veevaBRulesSet.length !== 0 && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="usage-guideline">
                      {i18next.t("additionalInfo.usageOrGuidelines")}
                    </div>
                    <div>
                      <img
                        src={expandIcon}
                        class="rulesBoxImage"
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() => {
                          handleUsageGuidelineModal();
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="rulesSetBoxScorll custonScrollBar">
                  {veevaBRulesSet &&
                    veevaBRulesSet.map((item, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#666666",
                              margin: "auto",
                              marginTop: "",
                            }}
                          >
                            {item.name}{" "}
                            <span style={{ color: "red" }}>
                              {item.mustUse === "true"
                                ? "[Must Use]"
                                : "[Optional]"}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#666666",
                              margin: "auto",
                              marginTop: "",
                            }}
                          >
                            {item.contentModuleRuleSet}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#666666",
                              margin: "auto",
                              marginTop: "",
                            }}
                          >
                            {item.lebel}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#666666",
                              margin: "auto",
                              marginTop: "",
                              paddingBottom: "5px",
                            }}
                          >
                            {item.rulesetSummary}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <div className="dmodalBtnRow">
        <button
          className="btn btn-link btn-add-another-placeholder"
          onClick={() => {
            hideBusinessRulesModal();
          }}
        >
          CLOSE
        </button>
        <div style={{ float: "right", marginRight: "8px" }}>
          <button
            className="btn btn-primary"
            onClick={(event) => {
              handleSave();
            }}
          >
            SAVE
          </button>
        </div>
      </div>
    </>
  );
};
export default ApplyBusinessrules;
