import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import TranslateFootnotes from "./TranslateFootnotes";
import TranslateAbbreviations from "./TranslateAbbreviations";
import TranslateReferences from "./TraslateReferences";
import CustomModal from "../../customModal";
import TranslateClaimsGenericModal from "../../TranslateFlow/TranslateClaims/TranslateClaimGenericModal";
import Menu from "../TranslateClaims/Menu";
import Comments from "../Comments";

import arrowpolygonRight from "../../../images/arrowpolygon-right.svg";
import translateIcon from "../../../images/translateIcon.svg";
import translateTick from "../../../images/translateTick.svg";
import commentIcon from "../../../images/icon-comment.svg";

import "../../../styles/pages/TranslateClaims.scss";
import "../../../styles/components/PrimaryClaim.scss";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

const TranslateSupportingClaims = ({
  module,
  isAnyToggleOn,
  saveTranslated,
  discardTranslated,
  addComment,
}) => {
  const { t } = useTranslation();
  const [showGenericTranslateModal, setShowGenericTranslateModal] =
    useState(false);
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);
  const [dataTypeSelectedForTranslation, setDtaTypeSelectedForTranslation] =
    useState("claim");
  //let froalaInstance = React.createRef();
  const supportingClaimRef = useRef([]);
  const [supportingClaimId, setSupportingClaimId] = useState();

  //For Comments
  const [boxComments, setBoxComments] = useState([]);
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showFootnotesCommentsDialog, setShowFootnotesCommentsDialog] =
    useState(false);
  const [showAbbreviationsCommentsDialog, setShowAbbreviationsCommentsDialog] =
    useState(false);
  const [claimRowIndex, setClaimRowIndex] = useState(-1);

  // useEffect(() => {
  //   if (isAnyToggleOn && froalaInstance && froalaInstance.current) {
  //     froalaInstance.current.editor.edit.off();
  //   } else {
  //     if (froalaInstance && froalaInstance.current) {
  //       froalaInstance.current.editor.edit.on();
  //     }
  //   }
  // }, [isAnyToggleOn]);

  const getSupportingClaims = () => {
    if (module && module.moduleClaims) {
      const allClaims = module.moduleClaims;
      const supportingClaims = allClaims.filter((claim) => {
        return (
          claim.type.trim().toLowerCase() === "supporting" &&
          claim.translate !== "0"
        );
      });
      return supportingClaims;
    }
    return [];
  };

  const getFootnotes = (claim) => {
    if (claim) {
      if (claim.supportingTextComponents.length > 0) {
        return claim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "footnote";
        });
      }
    }
    return [];
  };

  const getAbbreviations = (claim) => {
    if (claim) {
      if (claim.supportingTextComponents.length > 0) {
        return claim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "abbreviation";
        });
      }
    }
    return [];
  };

  const getReferences = (claim) => {
    if (claim) {
      if (claim.supportingTextComponents.length > 0) {
        return claim.supportingTextComponents.filter((supText) => {
          return supText.componentType.trim().toLowerCase() === "reference";
        });
      }
    }
    return [];
  };

  const intiateTranslationFor = (data, type) => {
    setDataInputForTranslation(() => {
      openGenericTranslateModal();
    });
    setDataInputForTranslation(data);
    setDtaTypeSelectedForTranslation(type);
  };

  const handleCloseGenericModal = () => {
    setShowGenericTranslateModal(false);
    discardTranslated();
  };
  const openGenericTranslateModal = () => {
    setShowGenericTranslateModal(true);
  };

  const handleSave = () => {
    setShowGenericTranslateModal(false);
    saveTranslated();
  };

  const consolidateComments = (boxName, primaryComponentId) => {
    let comments = [];
    const allComments = module.comments;
    let supportingClaim = getSupportingClaims().filter((claim) => {
      return claim.id === primaryComponentId;
    })[0];

    switch (boxName) {
      case "supportingClaim":
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === primaryComponentId &&
            comment.componentId === primaryComponentId
          );
        });
        break;
      case "footnote":
        const footnotes = supportingClaim.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "footnote";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            footnotes.findIndex((footnote) => {
              return (
                comment.primaryComponentId === primaryComponentId &&
                footnote.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      case "abbreviation":
        const abbreviations = supportingClaim.supportingTextComponents.filter(
          (comp) => {
            return comp.componentType.toLowerCase() === "abbreviation";
          }
        );
        comments = allComments.filter(function (comment) {
          return (
            abbreviations.findIndex((abbreviation) => {
              return (
                comment.primaryComponentId === primaryComponentId &&
                abbreviation.id === comment.componentId
              );
            }) !== -1
          );
        });
        break;
      default:
        break;
    }
    return comments;
  };
  const handleShowCommentsDialog = (boxName, showFlag) => {
    switch (boxName) {
      case "abbreviation":
        setShowAbbreviationsCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
      case "footnote":
        setShowFootnotesCommentsDialog(showFlag);
        setShowCommentsDialog(false);
        setShowAbbreviationsCommentsDialog(false);
        break;
      default:
        setShowCommentsDialog(showFlag);
        setShowAbbreviationsCommentsDialog(false);
        setShowFootnotesCommentsDialog(false);
        break;
    }
  };

  const getCommentBubbleFor = (boxName, primaryComponentId) => {
    const comments = consolidateComments(boxName, primaryComponentId);
    if (!comments || comments.length === 0) {
      return;
    }
    return (
      <div
        style={{
          float: "right",
          marginRight: `${hasUserLoggedInAsReviewer() ? "0px" : "50px"}`,
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          className="commentIcon"
          onClick={() => {
            if (comments && comments.length > 0) {
              const rowIndex = getSupportingClaims().findIndex((claim) => {
                return claim.id === primaryComponentId;
              });

              setBoxComments(() => {
                //hide the comments on click if already opened
                let flag =
                  rowIndex === claimRowIndex ? !showCommentsDialog : true;
                if (boxName === "abbreviation") {
                  flag =
                    rowIndex === claimRowIndex
                      ? !showAbbreviationsCommentsDialog
                      : true;
                }
                if (boxName === "footnote") {
                  flag =
                    rowIndex === claimRowIndex
                      ? !showFootnotesCommentsDialog
                      : true;
                }
                if (flag) {
                  setClaimRowIndex(rowIndex);
                } else {
                  setClaimRowIndex(-1);
                }
                handleShowCommentsDialog(boxName, flag);
              });
              setBoxComments(comments);
            }
          }}
        />
      </div>
    );
  };

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: supportingClaimId,
      componentId: supportingClaimId,
    };
    addComment(commentObject);
  };
  const getCommentsData = () => {
    setShowCommentsDialog(false);
    setShowAbbreviationsCommentsDialog(false);
    setShowFootnotesCommentsDialog(false);
    discardTranslated();
  };
  return (
    <>
      <div ref={supportingClaimRef}>
        {getSupportingClaims().length > 0 && (
          <div>
            {getSupportingClaims().map((claim, index) => {
              return (
                <div key={claim.id}>
                  <Row className="mb-4 align-items-center no-gutters mt-4">
                    <Col className="col-md-3">
                      <img
                        src={arrowpolygonRight}
                        alt=""
                        className="claimTitleImg"
                      />
                      <span className="claimTitle">
                        {t("supportingClaim.name")}{" "}
                        {getSupportingClaims().length > 1 ? index + 1 : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className="align-items-center translateRow"
                    key={claim.id}
                  >
                    <Col className="col-12 col-md-3">
                      <div className="translateBox translateBoxType1 translateBoxSelected custonScrollBar">
                        <p className="translateBox-head d-flex justify-content-between">
                          {t("supportingClaim.name")}
                          {!isAnyToggleOn && claim.translate === "2" && (
                            <span className="translatedBoxSuccess">
                              <img src={translateTick} alt="Translate" />
                            </span>
                          )}
                        </p>
                        <div
                          className="translateClaimsContainer customScrollBar"
                          id={`supporting-claim${claim.id}`}
                          onClick={() => setSupportingClaimId(claim.id)}
                        >
                          <div className="translateClaimText">
                            {/* {isAnyToggleOn
                              ? claim.originalClaim &&
                                parse(claim.originalClaim)
                              : claim.claim && parse(claim.claim)} */}
                            {claim.claim && parse(claim.claim)}
                          </div>

                          <div className="floating-buttons">
                            {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                              <button
                                className="btn btn-link translate-icon"
                                style={{ marginRight: "-12px", bottom: "0" }}
                                onClick={() => {
                                  intiateTranslationFor([claim], "claim");
                                }}
                              >
                                <img src={translateIcon} alt="Translate" />
                              </button>
                            )}
                            {getCommentBubbleFor("supportingClaim", claim.id)}
                          </div>
                          {showCommentsDialog && index === claimRowIndex && (
                            <Comments
                              moduleId={module.id}
                              componentId={claim.id}
                              childComponentId=""
                              comments={boxComments}
                              commentsPopupCurrentState={showCommentsDialog}
                              commentsPopupUpdateState={setShowCommentsDialog}
                              getCommentsData={getCommentsData}
                            />
                          )}
                        </div>
                      </div>
                      {!isAnyToggleOn && (
                        <Menu
                          outerRef={supportingClaimRef}
                          compClass={`supporting-claim${supportingClaimId}`}
                          addComment={addAComment}
                        />
                      )}
                    </Col>
                    <Col className="col-12 col-md-3">
                      <TranslateFootnotes
                        footnotes={getFootnotes(claim)}
                        isAnyToggleOn={isAnyToggleOn}
                        targetLanguage={module.language}
                        intiateTranslationFor={intiateTranslationFor}
                        addComment={addComment}
                        getCommentBubbleFor={getCommentBubbleFor}
                        primaryComponentId={claim.id}
                        comments={boxComments}
                        showCommentsFlag={
                          index === claimRowIndex
                            ? showFootnotesCommentsDialog
                            : false
                        }
                        updateShowCommentsFlag={setShowFootnotesCommentsDialog}
                        getCommentsData={getCommentsData}
                      />
                    </Col>

                    <Col className="col-12 col-md-3">
                      <TranslateAbbreviations
                        abbreviations={getAbbreviations(claim)}
                        isAnyToggleOn={isAnyToggleOn}
                        targetLanguage={module.language}
                        intiateTranslationFor={intiateTranslationFor}
                        addComment={addComment}
                        getCommentBubbleFor={getCommentBubbleFor}
                        primaryComponentId={claim.id}
                        comments={boxComments}
                        showCommentsFlag={
                          index === claimRowIndex
                            ? showAbbreviationsCommentsDialog
                            : false
                        }
                        updateShowCommentsFlag={
                          setShowAbbreviationsCommentsDialog
                        }
                        getCommentsData={getCommentsData}
                      />
                    </Col>
                    <Col className="col-12 col-md-3" disabled={true}>
                      <TranslateReferences
                        references={getReferences(claim)}
                        isAnyToggleOn={isAnyToggleOn}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        )}

        <CustomModal
          displayModal={showGenericTranslateModal}
          hideModal={handleCloseGenericModal}
          title="Select the components you would like to translate"
          size="xl"
        >
          <TranslateClaimsGenericModal
            handleClose={handleCloseGenericModal}
            sourceLanguage={module.translatedFrom}
            targetLanguage={module.language}
            dataInput={dataInputForTranslation}
            dataType={dataTypeSelectedForTranslation}
            saveTranslated={handleSave}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default TranslateSupportingClaims;
