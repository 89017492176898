import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import Menu from "../TranslateClaims/Menu";
import translateTick from "../../../images/translateTick.svg";
import translateIcon from "../../../images/translateIcon.svg";
import "../../../styles/pages/TranslateClaims.scss";
import "../../../styles/components/PrimaryClaim.scss";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import Comments from "../Comments";

const TranslateFootnotes = ({
  footnotes,
  isAnyToggleOn,
  containerClass,
  classBoxType,
  intiateTranslationFor,
  addComment,
  getCommentBubbleFor,
  primaryComponentId,
  comments,
  showCommentsFlag,
  updateShowCommentsFlag,
  getCommentsData,
}) => {
  const footnoteRef = useRef([]);
  const [footNoteId, setFootNoteId] = useState();
  const renderFootnotes = () => {
    if (footnotes.length > 0) {
      return footnotes.map((footnote) => {
        return (
          <div className="d-flex" key={footnote.id}>
            {
              <div className="footBoxIndex">
                <span className="iconDisplay" style={{ display: "block" }}>
                  {footnote.prefix && parse(footnote.prefix)}
                </span>
              </div>
            }
            {
              <div
                className="footNoteLabelN translateClaimText alignFootnoteAbbreviations"
                ref={(el) => (footnoteRef.current[footnote.id] = el)}
                id={`footNote${footnote.id}`}
                onClick={() => {
                  setFootNoteId(footnote.id);
                }}
              >
                {/* {isAnyToggleOn
                  ? footnote.originalText && parse(footnote.originalText)
                  : footnote.text && parse(footnote.text)} */}
                {footnote.text && parse(footnote.text)}
              </div>
            }
          </div>
        );
      });
    }
    return <div></div>;
  };
  const checkForTranslate = () => {
    if (!isAnyToggleOn && footnotes.length > 0) {
      let result = true;
      for (var i in footnotes) {
        if (footnotes[i].translate !== "2") {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  };

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: primaryComponentId,
      componentId: footNoteId,
    };
    addComment(commentObject);
  };

  return (
    <>
      <div className={`translateBox ${classBoxType || "translateBoxType1"}`}>
        <p className="translateBox-head d-flex justify-content-between">
          Footnote / Disclaimer
          {checkForTranslate() && (
            <span className="translatedBoxSuccess">
              <img src={translateTick} alt="Translate" />
            </span>
          )}
        </p>
        <div
          className={`${
            containerClass || "translateClaimsContainer"
          }customScrollBar`}
        >
          {renderFootnotes()}
          <div className="floating-buttons">
            {!isAnyToggleOn &&
              !hasUserLoggedInAsReviewer() &&
              footnotes.length > 0 &&
              footnotes[0].translate !== 0 && (
                <div style={{ zIndex: "1" }}>
                  <button
                    className="btn btn-link translate-icon"
                    style={{ marginRight: "-12px", bottom: "0" }}
                    onClick={() => {
                      intiateTranslationFor(footnotes, "footnote");
                    }}
                  >
                    <img src={translateIcon} alt="Translate" />
                  </button>
                </div>
              )}
            {footnotes.length > 0 &&
              footnotes[0].translate !== 0 &&
              getCommentBubbleFor &&
              getCommentBubbleFor("footnote", primaryComponentId)}
          </div>
        </div>
        {!isAnyToggleOn && (
          <Menu
            outerRef={footnoteRef.current[footNoteId]}
            compClass={`footNote${footNoteId}`}
            addComment={addAComment}
          />
        )}
      </div>
      {showCommentsFlag && (
        <Comments
          moduleId={module.id}
          componentId={primaryComponentId}
          childComponentId=""
          comments={comments}
          currentClass={
            hasUserLoggedInAsReviewer()
              ? "commentsPosition3"
              : "commentsPosition3-translator"
          }
          commentsPopupCurrentState={showCommentsFlag}
          commentsPopupUpdateState={updateShowCommentsFlag}
          getCommentsData={getCommentsData}
        />
      )}
    </>
  );
};

export default TranslateFootnotes;
