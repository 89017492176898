import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import Menu from "../TranslateClaims/Menu";
import translateIcon from "../../../images/translateIcon.svg";
import translateTick from "../../../images/translateTick.svg";

import "../../../styles/components/emailHeader.scss";
import "../../../styles/pages/TranslateClaims.scss";
import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";
import Comments from "../Comments";

const EmailHeader = ({
  emailHeadersData,
  isAnyToggleOn,
  initiateTranslationFor,
  getCommentBubbleFor,
  addComment,
  comments,
  showCommentsFlag,
  setShowCommentsFlag,
  rowIndex,
  boxPosition,
  getCommentsData,
}) => {
  const referencesValid = useRef(null);
  const { t } = useTranslation();

  const getBubble = (text, primaryComponentId, componentId) => {
    return (
      <div
        style={{
          position: "absolute",
          right: `${hasUserLoggedInAsReviewer() ? "20px" : "72px"}`,
          bottom: "4px",
        }}
      >
        {getCommentBubbleFor(text, primaryComponentId, componentId)}
      </div>
    );
  };

  return (
    <>
      {emailHeadersData &&
        emailHeadersData.map((row, index) => {
          const subTexts = row.moduleChannelSubtexts;
          let headerText = [...subTexts].filter((subText) => {
            return (
              subText.subtextType?.trim().toLowerCase() === "text" ||
              subText.subTextType?.trim().toLowerCase() === "text"
            );
          });
          if (headerText.length > 0) {
            headerText = headerText[0];
          }
          let restriction = [...subTexts].filter((subText) => {
            return (
              subText.subtextType?.trim().toLowerCase() === "restriction" ||
              subText.subTextType?.trim().toLowerCase() === "restriction"
            );
          });
          if (restriction.length > 0) {
            restriction = restriction[0];
          }
          let rule = [...subTexts].filter((subText) => {
            return (
              subText.subtextType?.trim().toLowerCase() === "rule" ||
              subText.subTextType?.trim().toLowerCase() === "rule"
            );
          });
          if (rule.length > 0) {
            rule = rule[0];
          }
          return (
            <div>
              <Row
                className={`mt-4 align-items-center ${
                  index === emailHeadersData.length - 1
                    ? ""
                    : "emailHeaderRowDivider"
                }`}
                style={{ paddingBottom: "15px" }}
              >
                <Col xs md="4" lg="4">
                  <HeaderBox
                    input={headerText}
                    shouldDisbaleEditing={true}
                    title={t("emailHeaders.headerTitle")}
                    isAnyToggleOn={isAnyToggleOn}
                    addComment={addComment}
                    primaryComponentId={row.id}
                  />
                  {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link translateIcon"
                      onClick={() => {
                        initiateTranslationFor([headerText], "tagline");
                      }}
                      style={{ right: "24px" }}
                    >
                      <img src={translateIcon} alt="Translate" />
                    </button>
                  )}
                  {getBubble("text", row.id, headerText.id)}
                  {showCommentsFlag &&
                    rowIndex === index &&
                    boxPosition === 0 && (
                      <Comments
                        moduleId={module.id}
                        componentId={row.id}
                        childComponentId=""
                        comments={comments}
                        currentClass={
                          hasUserLoggedInAsReviewer()
                            ? "commentsCatchline"
                            : "commentsheaders-translator"
                        }
                        commentsPopupCurrentState={showCommentsFlag}
                        commentsPopupUpdateState={setShowCommentsFlag}
                        getCommentsData={getCommentsData}
                      />
                    )}
                </Col>
                <Col xs md="4" lg="4">
                  <HeaderBox
                    input={restriction}
                    shouldDisbaleEditing={true}
                    title={t("emailHeaders.restrictionTitle")}
                    isAnyToggleOn={isAnyToggleOn}
                    addComment={addComment}
                    primaryComponentId={row.id}
                  />
                  {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link translateIcon"
                      onClick={() => {
                        initiateTranslationFor([restriction], "tagline");
                      }}
                      style={{ right: "24px" }}
                    >
                      <img src={translateIcon} alt="Translate" />
                    </button>
                  )}
                  {getBubble("restriction", row.id, restriction.id)}
                  {showCommentsFlag &&
                    rowIndex === index &&
                    boxPosition === 1 && (
                      <Comments
                        moduleId={module.id}
                        componentId={row.id}
                        childComponentId=""
                        comments={comments}
                        currentClass={
                          hasUserLoggedInAsReviewer()
                            ? "commentsCatchline"
                            : "commentsheaders-translator"
                        }
                        commentsPopupCurrentState={showCommentsFlag}
                        commentsPopupUpdateState={setShowCommentsFlag}
                        getCommentsData={getCommentsData}
                      />
                    )}
                </Col>
                <Col xs md="4" lg="4">
                  <HeaderBox
                    input={rule}
                    shouldDisbaleEditing={true}
                    title={t("emailHeaders.rulesTitle")}
                    isAnyToggleOn={isAnyToggleOn}
                    addComment={addComment}
                    primaryComponentId={row.id}
                  />
                  {!isAnyToggleOn && !hasUserLoggedInAsReviewer() && (
                    <button
                      className="btn btn-link translateIcon"
                      onClick={() => {
                        initiateTranslationFor([rule], "tagline");
                      }}
                      style={{ right: "24px" }}
                    >
                      <img src={translateIcon} alt="Translate" />
                    </button>
                  )}
                  {getBubble("rule", row.id, rule.id)}
                  {showCommentsFlag &&
                    rowIndex === index &&
                    boxPosition === 2 && (
                      <Comments
                        moduleId={module.id}
                        componentId={row.id}
                        childComponentId=""
                        comments={comments}
                        currentClass={
                          hasUserLoggedInAsReviewer()
                            ? "commentsCatchline"
                            : "commentsheaders-translator"
                        }
                        commentsPopupCurrentState={showCommentsFlag}
                        commentsPopupUpdateState={setShowCommentsFlag}
                        getCommentsData={getCommentsData}
                      />
                    )}
                </Col>
              </Row>
            </div>
          );
        })}
      <div className="d-none" ref={referencesValid}></div>
    </>
  );
};
export default EmailHeader;

const HeaderBox = ({
  input,
  shouldDisbaleEditing,
  title,
  isAnyToggleOn,
  addComment,
  primaryComponentId,
}) => {
  const emailHeaderRef = useRef(null);

  const addAComment = (selectedText) => {
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: primaryComponentId,
      componentId: input.id,
    };
    addComment(commentObject);
  };

  return (
    <>
      <div
        className="emailHeaderBox custonScrollBar"
        style={{ overflowY: "scroll", paddingBottom: "10px" }}
        ref={emailHeaderRef}
      >
        <span className="editorHeaderActive-heading d-block mt-2">
          {title}{" "}
          {!isAnyToggleOn && input.translate === "2" && (
            <img
              src={translateTick}
              alt="Translate"
              style={{ float: "right", marginRight: "5px" }}
            />
          )}
        </span>
        <div
          className="translateClaimText"
          style={{ marginTop: "10px", marginLeft: "9px" }}
          id={`email-header${input.id}`}
        >
          {/* {isAnyToggleOn
            ? input.originalText && parse(input.originalText)
            : input.text && parse(input.text)} */}
          {input.text && parse(input.text)}
        </div>
      </div>
      {!isAnyToggleOn && (
        <Menu
          outerRef={emailHeaderRef}
          compClass={`email-header${input.id}`}
          addComment={addAComment}
        />
      )}
    </>
  );
};
