import React, { useEffect, useState } from "react";
import ModuleCard from "./LocalizedModuleCard";
import CustomModal from "../../components/customModal";
import LocalizeModules from "./BulkLocalizationModal";
import TranslatorSelectionModal from "../translateModule";
import {
  getAssetDetails,
  createTranslationGroup,
  createTranslationDuplicationCheck,
  getGroupsModulesLangauge,
  getSourceModuleLanguages,
  createTranslationJob,
} from "../../services/apis";

import "../../styles/components/LocalizedModules.scss";
import GenericBussinessRules from "./genericBussinessModal";
import BulkModulesValidationModal from "./BulkModulesValidationModal";
import SelectSourceModuleLanguageModal from "./SelectSourceModuleLanguageModal";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import BusinessRulesValidationForMLRModal from "./BusinessRulesValidationForMLRModal";
import {
  moduleTypes,
  removeModuleInfo,
  routes,
  updateModuleInfo,
} from "../../Uitls/Util";
import { useHistory } from "react-router-dom";
const LocalizedModules = ({
  titleType,
  localizedModules,
  localizedModuleGroups,
  data,
  searchGroupsOnly,
  clearAllSearch,
  searchLocalizedModules,
}) => {
  const history = useHistory();
  const [selectModuleIds, setSelectedModuleIds] = useState([]);

  const [localizeBusinsessrulsModal, setLocalizeBusinsessrulsModal] =
    useState(false);
  const [showBulkValidationModal, setShowBulkValidationModal] = useState(false);
  const [showSelectLanguageModal, setShowSelectLanguageModal] = useState(false);

  const [cannotExistModuleIdsCount, setCannotModulesIdsCount] = useState([]);

  const [bulkLocalizationModal, setBulkLocalizationModal] = useState(false);

  const [isloading, setIsloading] = useState(false);

  const [singleSelectedModuleId, setSingleSelectedModuleId] =
    useState(undefined);

  const [genericInfo, setGenericInfo] = useState(undefined);
  const [prevSelectedData, setPrevSelectedData] = useState(undefined);
  const [dataForTranslationCreationGroup, setDataForTranslationCreationGroup] =
    useState(undefined);
  const [
    selectedModuleIdForGroupCreation,
    setSelectedModuleIdForGroupCreation,
  ] = useState([]);
  const [showTranslatorSelectionMdal, setShowTranslatorSelectionMdal] =
    useState(false);
  //For Translation
  const [moduleIdsTobeTranslated, setModuleIdsTobeTranslated] =
    useState(undefined);
  const [sourceModules, setSourceModules] = useState(undefined);
  const [groupToBeTranslatedData, setGroupToBeTranslatedData] = useState([]);
  const [modulesWithSourceLanguages, setModulesWithSourceLanguages] = useState(
    []
  );
  const [validtionModalData, setValidtionModalData] = useState([]);
  const [selectedGroupInfo, setSelectedGroupInfo] = useState(undefined);
  //MLR SUBMISSION
  const [showBruleModalForMLR, toggleBruleMOdalForMLR] = useState(false);

  useEffect(() => {
    setSelectedModuleIds([]);
  }, [localizedModules]);

  const selectModule = (module) => {
    if (
      selectModuleIds.findIndex((moduleId) => {
        return moduleId === module.moduleId;
      }) > -1
    ) {
      const selectedIds = [...selectModuleIds].filter((moduleId) => {
        return moduleId !== module.moduleId;
      });
      setSelectedModuleIds(selectedIds);
      const allSelectedModules = getSelectedModules(selectedIds);
      if (allSelectedModules.length > 0) {
        let cannotExistModuleIds = [];
        allSelectedModules.forEach((module) => {
          cannotExistModuleIds = cannotExistModuleIds.concat(
            module.cannotExistRuleList || []
          );
        });
        let result = cannotExistModuleIdsCount.filter((entry1) => {
          return cannotExistModuleIds.some((entry2) => {
            return entry1 === entry2.Student;
          });
        });
        setCannotModulesIdsCount(result);
      }
    } else {
      const selectedIds = [...selectModuleIds].concat(module.moduleId);
      setSelectedModuleIds(selectedIds);
    }
  };

  const getSelectedModules = (selectedIds) => {
    let selectedModules = [];
    const allModules = [...localizedModules];
    selectedIds.forEach((selectedId) => {
      const modules = allModules.filter((module) => {
        return module.moduleId === selectedId;
      });
      selectedModules = selectedModules.concat(modules);
    });
    return selectedModules;
  };

  const isSelectedModule = (currentModuleId) => {
    if (singleSelectedModuleId) {
      return false;
    }
    if (
      selectModuleIds.length > 0 &&
      selectModuleIds.indexOf(currentModuleId) > -1
    ) {
      return true;
    }
    return false;
  };

  const cannotExistWithOtherSelectedModules = (currentModuleId) => {
    if (isSelectedModule(currentModuleId)) {
      const allSelectedModules = getSelectedModules(selectModuleIds);
      if (allSelectedModules.length > 0) {
        let cannotExistModuleIds = [];
        allSelectedModules.forEach((module) => {
          cannotExistModuleIds = cannotExistModuleIds.concat(
            module.cannotExistRuleList || []
          );
        });
        let result = cannotExistModuleIds.indexOf(currentModuleId);
        if (result > -1) {
          let extractId = cannotExistModuleIds[result];
          if (cannotExistModuleIdsCount.indexOf(extractId) < 0) {
            cannotExistModuleIdsCount.push(extractId);
            setCannotModulesIdsCount(cannotExistModuleIdsCount);
          }
        }
        return cannotExistModuleIds.indexOf(currentModuleId) > -1;
      }
    }
    return false;
  };

  const hideLocalizeBusinsessrulsModal = () => {
    setLocalizeBusinsessrulsModal(false);
    setGenericInfo(undefined);
    setPrevSelectedData(undefined);
    setDataForTranslationCreationGroup(undefined);
  };

  const createLocalizeGroup = () => {
    setLocalizeBusinsessrulsModal(true);
  };

  const localizeModule = (moduleId) => {
    setSingleSelectedModuleId(moduleId);
    createLocalizeGroup();
  };

  const fetchSourceLanguageModules = (moduleIds, cb) => {
    const payload = moduleIds.map((obj) => {
      return obj.sourceModuleId;
    });
    setIsloading(true);
    getSourceModuleLanguages(payload, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        let sourceModules = response.data;
        let allowToSelectSourceModule = false;
        sourceModules = sourceModules.map((sourceModule) => {
          if (sourceModule.languages.length > 0) {
            allowToSelectSourceModule = true;
          }
          sourceModule.languages.unshift({
            language: sourceModule.language,
            moduleId: sourceModule.moduleId,
            isSelected: true,
          });
          return sourceModule;
        });

        cb(sourceModules, allowToSelectSourceModule);
        return;
      }
      const errCoponent = errComponentToRender(errCode);
      toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      cb(undefined);
    });
  };

  const translateModuleGroup = (moduleIds, groupInfo) => {
    //Go to the modal where source module displayed
    setSelectedGroupInfo(groupInfo);
    fetchSourceLanguageModules(
      moduleIds,
      async (sourceModules, allowToSelectSourceModule) => {
        await setModuleIdsTobeTranslated(moduleIds);
        if (
          sourceModules &&
          sourceModules.length > 0 &&
          allowToSelectSourceModule
        ) {
          await setSourceModules(sourceModules);
          handleShowSourceLanguageModal(true);
        } else {
          let moduleDetails = [];
          moduleIds.forEach((element) => {
            const module = {
              moduleId: element.moduleId,
              language: element.language,
              sourceModuleId: element.sourceModuleId,
            };
            moduleDetails.push(module);
          });
          handleShowTranslatorSelection(moduleIds, moduleDetails);
        }
      }
    );
  };

  const translateModule = (moduleId, groupInfo) => {
    translateModuleGroup([moduleId], groupInfo);
  };

  const handleBulkLocalizationModal = (
    flag = false,
    genericInfoData,
    selectedData,
    finalFilteredModuleIds
  ) => {
    if (flag) {
      setLocalizeBusinsessrulsModal(false);
      setGenericInfo(genericInfoData);
      setPrevSelectedData(selectedData);
      setSelectedModuleIdForGroupCreation(() => {
        setBulkLocalizationModal(flag);
      });
      setSelectedModuleIdForGroupCreation(finalFilteredModuleIds);
    } else {
      hideLocalizeBusinsessrulsModal();
      setGenericInfo(undefined);
      setPrevSelectedData(undefined);
      setDataForTranslationCreationGroup(undefined);
      if (singleSelectedModuleId) {
        setSingleSelectedModuleId(undefined);
      } else {
        setSelectedModuleIds([]);
      }
      setBulkLocalizationModal(flag);
    }
  };

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const goToPrimaryClaimPage = (module, filteredPages = []) => {
    let pageCount = "";
    removeModuleInfo();
    updateModuleInfo({
      assetId: module.storageAssetId,
      moduleId: module.moduleId,
      moduleName: module.moduleName,
      moduleCode: module.moduleCode,
      moduleType: "editModule",
      numberOfPages: pageCount,
      creationMethodType: module.storageAssetId
        ? "deconstructed"
        : moduleTypes.denovo,
    });
    history.push({
      pathname: routes.addPrimaryClaim,
      state: {
        filteredPages,
        brandName: module.brandName,
      },
    });
  };

  const modifyModule = (module) => {
    if (!module.storageAssetId) {
      goToPrimaryClaimPage(module);
      return;
    }
    setIsloading(true);
    getAssetDetails((response, err, errCode) => {
      setIsloading(false);
      if (err || errCode) {
        const errCoponent = errComponentToRender(errCode);
        showError(errCoponent);
        return;
      }
      const children = response.data.children;
      if (children) {
        let pages = [];
        pages = children.filter((child) => {
          return child.type === "Page";
        });
        pages = pages.map((page) => {
          const atoms = children.filter((child) => {
            return child.type === "Atom" && child.parent === page._id;
          });
          page = { ...page, atoms };
          return page;
        });
        //Sort the array
        pages.sort((page1, page2) => {
          return page1.MetaData.page - page2.MetaData.page;
        });
        goToPrimaryClaimPage(module, pages);
      }
    }, module.storageAssetId);
  };
  const showBulkModulesValidaionScreen = (data, validationData) => {
    if (validationData.length < 1) {
      setBulkLocalizationModal(false);
      return;
    }
    setDataForTranslationCreationGroup(data);
    createTranslationDuplicationCheck(
      validationData,
      (response, err, errCode) => {
        if (err) {
          const errCoponent = errComponentToRender(errCode);
          showError(errCoponent);
        } else {
          if (response.data.length > 0) {
            setValidtionModalData(response.data);
            setBulkLocalizationModal(false);
            setShowBulkValidationModal(true);
          } else {
            createNewTranslationGroup(data);
          }
          setPrevSelectedData(undefined);
        }
      }
    );
  };

  const backToGenericInfoSlectionModal = () => {
    setBulkLocalizationModal(true);
    setShowBulkValidationModal(false);
  };
  const bulkModulesValidationModalClose = () => {
    setShowBulkValidationModal(false);
    hideLocalizeBusinsessrulsModal();
    setGenericInfo(undefined);
    setPrevSelectedData(undefined);
    setDataForTranslationCreationGroup(undefined);
    if (singleSelectedModuleId) {
      setSingleSelectedModuleId(undefined);
    } else {
      setSelectedModuleIds([]);
    }
    setBulkLocalizationModal(false);
  };
  const handleBackToBussinessRulesModal = () => {
    setLocalizeBusinsessrulsModal(true);
    setBulkLocalizationModal(false);
    setDataForTranslationCreationGroup(undefined);
  };
  const handleShowSourceLanguageModal = (flag) => {
    setShowSelectLanguageModal(flag);
    if (!flag) {
      setModuleIdsTobeTranslated(undefined);
      setSourceModules([]);
    }
  };
  const renderModuleList = () => {
    return (
      <div>
        <div>
          <p className="searched-title">
            Search Results ({localizedModules.length})
          </p>
        </div>

        <div className="row localized-modules existingTacticsModDetails custonScrollBar">
          {localizedModules.map((module) => {
            return (
              <ModuleCard
                module={module}
                colWidth={4}
                selectModule={selectModule}
                isLocalizedModule={true}
                isThisModuleSelected={isSelectedModule(module.moduleId)}
                cannotExistWithOtherModules={cannotExistWithOtherSelectedModules(
                  module.moduleId
                )}
                localizeModule={localizeModule}
                modifyModule={modifyModule}
                isSelectable={true}
              />
            );
          })}
        </div>
        <div className="row pt-3 pb-3 createGroupResultBtnRow">
          <div className="col-md-6">
            <button
              className="btn btn-link text-uppercase"
              onClick={createLocalizeGroup}
              disabled={selectModuleIds.length > 0 ? false : true}
            >
              Create Group
            </button>
          </div>
          <div className="col-md-6 text-right">
            <p>
              {selectModuleIds.length > 0 &&
                `${selectModuleIds.length} Module${
                  selectModuleIds.length > 1 ? "s" : ""
                } Selected`}
              {cannotExistModuleIdsCount.length > 0 ? (
                <span className="complianceError">
                  {cannotExistModuleIdsCount.length} Conflict detected. This can
                  result in compliance issues
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleBruleModalForMLR = (flag) => {
    toggleBruleMOdalForMLR(flag);
  };
  const openBrulesValidationForMLR = async (moduleIds) => {
    await setModuleIdsTobeTranslated(moduleIds);
    handleBruleModalForMLR(true);
  };

  const renderModuleGroups = () => {
    return (
      <div className="existingTacticsModDetails customScrollBar">
        {localizedModuleGroups.map((group) => {
          const groupInfo = {
            groupId: group.groupId,
            groupCode: group.groupCode,
          };
          return (
            <div>
              <div
                className="row"
                style={{
                  marginRight: "10px",
                  marginLeft: "0px",
                  marginBottom: "15px",
                }}
              >
                <div className="localized-modules-heading">
                  <div
                    className="localized-group-title"
                    style={{ paddingLeft: "0px" }}
                  >
                    Group Name
                  </div>
                  <div class="localized-group-name">
                    {group.groupCode} ({group.modules.length} modules)
                  </div>
                </div>

                <button
                  className="btn btn-primary localized-group-mlr-submit"
                  onClick={() => {
                    if (group.groupStatus.toLowerCase() === "translate") {
                      let moduleIds = group.modules
                        .filter((module) => {
                          return (
                            module.moduleType.toLowerCase() !== "translated"
                          );
                        })
                        .map((module) => {
                          return {
                            moduleId: module.moduleId,
                            sourceModuleId: module.sourceModuleId,
                            language: module.language,
                          };
                        });

                      translateModuleGroup(moduleIds, groupInfo);
                    }
                  }}
                  disabled={group.groupStatus.toLowerCase() !== "translate"}
                >
                  {/* {group.groupStatus} */}
                  Translate
                </button>
              </div>
              <div className="row localized-modules">
                {group.modules.map((module) => {
                  return (
                    <ModuleCard
                      module={module}
                      colWidth={4}
                      selectModule={selectModule}
                      isLocalizedModule={true}
                      isThisModuleSelected={isSelectedModule(module.moduleId)}
                      cannotExistWithOtherModules={cannotExistWithOtherSelectedModules(
                        module.moduleId
                      )}
                      translateModule={
                        module.moduleType.toLowerCase() !== "translated"
                          ? translateModule
                          : undefined
                      }
                      localizeModule={
                        module.moduleType.toLowerCase() === "translated"
                          ? localizeModule
                          : undefined
                      }
                      modifyModule={modifyModule}
                      isSelectable={false}
                      groupInfo={groupInfo}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const createNewTranslationGroup = (payload) => {
    createTranslationGroup(payload, (response, err, errCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errCode);
        showError(errCoponent);
      } else {
        clearAllSearch();
        setShowBulkValidationModal(false);
        setBulkLocalizationModal(false);
        setDataForTranslationCreationGroup(undefined); //Remove this once bulkvalidation screen is available
        searchLocalizedModules();
      }
    });
  };
  const handleShowTranslatorSelection = (
    moduleIds = moduleIdsTobeTranslated,
    modulesDetailsWithSourceLanguages
  ) => {
    let ModuleIds = [];
    moduleIds.forEach((element) => {
      ModuleIds.push(element.moduleId);
    });
    getGroupsModulesLangauge(ModuleIds, async (response, err, errorCode) => {
      if (err) {
        console.log(err);
      } else {
        await setGroupToBeTranslatedData(response.data);
        await setModulesWithSourceLanguages(modulesDetailsWithSourceLanguages);
        setShowTranslatorSelectionMdal(true);
        setShowSelectLanguageModal(false);
      }
    });
  };
  const handleCloseTranslateModal = () => {
    setShowTranslatorSelectionMdal(false);
    setGroupToBeTranslatedData([]);
    setModuleIdsTobeTranslated([]);
    setSourceModules([]);
    setSelectedGroupInfo(undefined);
  };
  const handleTranslationJobCreation = (data) => {
    const payload = {
      groupId: selectedGroupInfo.groupId,
      groupCode: selectedGroupInfo.groupCode,
      jobCreateDetails: data,
    };
    createTranslationJob(payload, (response, err, errorCode) => {
      if (err) {
        setIsloading(false);
        setShowTranslatorSelectionMdal(false);
        setGroupToBeTranslatedData([]);
        setModuleIdsTobeTranslated([]);
        const errCoponent = errComponentToRender(errorCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      } else {
        setIsloading(false);
        notifyTranslateJobCreatedSucess();
        setShowTranslatorSelectionMdal(false);
        setGroupToBeTranslatedData([]);
        setModuleIdsTobeTranslated([]);
      }
      setSelectedGroupInfo(undefined);
    });
  };
  const notifyTranslateJobCreatedSucess = () => {
    toast.success(
      <>
        <p className="notificationHeading">
          Translation Job created Successfully
        </p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };
  const goToSourceLanguageModal = () => {
    if (sourceModules && sourceModules.length > 0) {
      setShowTranslatorSelectionMdal(false);
      setShowSelectLanguageModal(true);
    } else {
      setShowTranslatorSelectionMdal(false);
      setShowSelectLanguageModal(false);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <CircularProgressBar isloading={isloading} />
      {searchGroupsOnly && renderModuleGroups()}

      {!searchGroupsOnly && renderModuleList()}

      {localizeBusinsessrulsModal && (
        <CustomModal
          displayModal={localizeBusinsessrulsModal}
          hideModal={hideLocalizeBusinsessrulsModal}
          title={"Rate the Translator/Agency"}
          size="xl"
          dilogClassName="alertModalSmall"
          keyboard={false}
        >
          <GenericBussinessRules
            hideLocalizeBusinsessrulsModal={hideLocalizeBusinsessrulsModal}
            selectModuleIds={
              singleSelectedModuleId
                ? [singleSelectedModuleId]
                : selectModuleIds
            }
            handleProceed={handleBulkLocalizationModal}
            prevSelectedData={prevSelectedData}
          />
        </CustomModal>
      )}
      <CustomModal
        displayModal={bulkLocalizationModal}
        hideModal={handleBulkLocalizationModal}
        title={"Rate the Translator/Agency"}
        size="sm"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <LocalizeModules
          hideModal={handleBulkLocalizationModal}
          marketDataSource={data.marketDataSource || []}
          campaigns={data.campaigns || []}
          languageDataSource={data.languageDataSource || []}
          audienceDataSource={data.audience || []}
          handleBack={handleBackToBussinessRulesModal}
          showBulkModulesValidaionScreen={showBulkModulesValidaionScreen}
          genericInformation={genericInfo}
          selectedModuleIdForGroupCreation={selectedModuleIdForGroupCreation}
          dataForTranslationCreationGroup={dataForTranslationCreationGroup}
        />
      </CustomModal>

      <CustomModal
        displayModal={showBulkValidationModal}
        hideModal={hideLocalizeBusinsessrulsModal}
        title={"Existing Modules detected"}
        size="xl"
        dilogClassName="alertModalSmall error"
        keyboard={false}
      >
        <BulkModulesValidationModal
          backToGenericInfoSlectionModal={backToGenericInfoSlectionModal}
          bulkModulesValidationModalClose={bulkModulesValidationModalClose}
          createNewTranslationGroup={createNewTranslationGroup}
          validationData={validtionModalData}
          dataForTranslationCreationGroup={dataForTranslationCreationGroup}
        />
      </CustomModal>
      <CustomModal
        displayModal={showSelectLanguageModal}
        hideModal={handleShowSourceLanguageModal}
        title={"Select Source Language"}
        size="md"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <SelectSourceModuleLanguageModal
          moduleIds={moduleIdsTobeTranslated}
          sourceModulesList={sourceModules}
          showModal={handleShowSourceLanguageModal}
          handleProceed={handleShowTranslatorSelection}
        />
      </CustomModal>
      <CustomModal
        displayModal={showTranslatorSelectionMdal}
        hideModal={handleCloseTranslateModal}
        title={"Filter By Alert"}
        size="lg"
        dilogClassName="alertModalSmall"
      >
        <TranslatorSelectionModal
          marketDataSource={data.marketDataSource}
          groupToBeTranslatedData={groupToBeTranslatedData}
          hideModal={handleCloseTranslateModal}
          modulesDetailsWithSourceLanguages={modulesWithSourceLanguages}
          languageDataSource={data.languageDataSource || []}
          handleSaveTranslation={handleTranslationJobCreation}
          goToComponentSelection={goToSourceLanguageModal}
        />
      </CustomModal>

      {/*-------------------- MLR Submission --------------*/}

      <CustomModal
        displayModal={showBruleModalForMLR}
        hideModal={handleBruleModalForMLR}
        title={""}
        size="xl"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <BusinessRulesValidationForMLRModal
          showModal={handleBruleModalForMLR}
          moduleIds={moduleIdsTobeTranslated}
        />
      </CustomModal>
    </div>
  );
};

export default LocalizedModules;
