import React, { useState, useEffect } from "react";

import TabPanel from "../../../Uitls/TabPanel";
import EmailHeader from "../../TranslateFlow/Channels/TranslateEmailHeaders";
import EmailSubjectLine from "../../TranslateFlow/Channels/TranslateSubjectLines";
import PreHeader from "../Channels/TranslateCatchlines";

import { hasUserLoggedInAsReviewer } from "../../../Uitls/Util";

import commentIcon from "../../../images/icon-comment.svg";

import "../../../styles/pages/CreateModuleStep4.scss";
import "../../../styles/pages/TranslateChannel.scss";

const Email = ({
  emailElements,
  isAnyToggleOn,
  initiateTranslationFor,
  addComment,
  module,
  getCommentsData,
}) => {
  const [subjectLines, setSubjectLines] = useState([]);
  const [preHeaders, setPreHeaders] = useState([]);
  const [emailHeaders, setEmailHeaders] = useState([]);

  //For Comments
  const [comments, setComments] = useState([]);
  const [showSubjectlinesCommentsDialog, setShowSubjectlinesCommentsDialog] =
    useState(false);
  const [showPreheadersCommentsDialog, setShowPreheadersCommentsDialog] =
    useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  //For comments for headers
  const [showHeadersCommentsDialog, setShowHeadersCommentsDialog] =
    useState(false);
  const [headerBoxPosition, setHeaderBoxPosition] = useState(-1);

  useEffect(() => {
    if (emailElements) {
      setSubjectLines(emailElements.subjectLines || []);
      setPreHeaders(emailElements.preheaders || []);
      setEmailHeaders(emailElements.emailHeaders || []);
    }
  }, [emailElements]);

  const consolidateComments = (boxName, primaryComponentId, componentId) => {
    let comments = [];
    const allComments = module.comments;
    if (allComments && allComments.length > 0) {
      if (
        boxName === "text" ||
        boxName === "restriction" ||
        boxName === "rule"
      ) {
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === primaryComponentId &&
            comment.componentId === componentId
          );
        });
      } else {
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === primaryComponentId &&
            comment.componentId === primaryComponentId
          );
        });
      }
    }
    return comments;
  };

  const getCommentBubbleFor = (boxName, primaryComponentId, componentId) => {
    const comments = consolidateComments(
      boxName,
      primaryComponentId,
      componentId
    );
    if (!comments || comments.length === 0) {
      return;
    }
    return (
      <div
        style={{
          float: "right",
          marginRight: `${hasUserLoggedInAsReviewer() ? "0px" : "0px"}`,
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          style={{ margin: "-5px", cursor: "pointer" }}
          onClick={() => {
            if (comments && comments.length > 0) {
              let rowIndex = -1;
              let flag = false;
              switch (boxName) {
                case "subjectline":
                  rowIndex = subjectLines.findIndex((item) => {
                    return item.id === primaryComponentId;
                  });
                  setShowPreheadersCommentsDialog(false);
                  flag =
                    rowIndex === selectedRowIndex
                      ? !showSubjectlinesCommentsDialog
                      : true;
                  setHeaderBoxPosition(-1);
                  break;
                case "catchline":
                  rowIndex = preHeaders.findIndex((item) => {
                    return item.id === primaryComponentId;
                  });
                  setShowSubjectlinesCommentsDialog(false);
                  flag =
                    rowIndex === selectedRowIndex
                      ? !showPreheadersCommentsDialog
                      : true;
                  setHeaderBoxPosition(-1);
                  break;
                case "text":
                  rowIndex = emailHeaders.findIndex((item) => {
                    return item.id === primaryComponentId;
                  });
                  setHeaderBoxPosition(0);
                  flag =
                    headerBoxPosition === 0 && rowIndex === selectedRowIndex
                      ? !showHeadersCommentsDialog
                      : true;
                  break;
                case "restriction":
                  rowIndex = emailHeaders.findIndex((item) => {
                    return item.id === primaryComponentId;
                  });
                  setHeaderBoxPosition(1);
                  flag =
                    headerBoxPosition === 1 && rowIndex === selectedRowIndex
                      ? !showHeadersCommentsDialog
                      : true;
                  break;
                case "rule":
                  rowIndex = emailHeaders.findIndex((item) => {
                    return item.id === primaryComponentId;
                  });
                  setHeaderBoxPosition(2);
                  flag =
                    headerBoxPosition === 2 && rowIndex === selectedRowIndex
                      ? !showHeadersCommentsDialog
                      : true;
                  break;
                default:
                  setHeaderBoxPosition(-1);
                  break;
              }
              setSelectedRowIndex(rowIndex);
              setComments(() => {
                handleShowCommentsDialog(boxName, flag);
              });
              setComments(comments);
            }
          }}
        />
      </div>
    );
  };

  const handleShowCommentsDialog = (boxName, showFlag) => {
    switch (boxName) {
      case "subjectline":
        setShowSubjectlinesCommentsDialog(showFlag);
        setShowPreheadersCommentsDialog(false);
        setShowHeadersCommentsDialog(false);
        break;
      case "catchline":
        setShowSubjectlinesCommentsDialog(false);
        setShowPreheadersCommentsDialog(showFlag);
        setShowHeadersCommentsDialog(false);
        break;
      case "text":
      case "restriction":
      case "rule":
        setShowSubjectlinesCommentsDialog(false);
        setShowPreheadersCommentsDialog(false);
        setShowHeadersCommentsDialog(showFlag);
        break;
      default:
        setShowSubjectlinesCommentsDialog(false);
        setShowPreheadersCommentsDialog(false);
        setShowHeadersCommentsDialog(false);
        break;
    }
  };

  const tabSubjectLines = (
    <EmailSubjectLine
      subjectLines={subjectLines}
      isAnyToggleOn={isAnyToggleOn}
      initiateTranslationFor={initiateTranslationFor}
      getCommentBubbleFor={getCommentBubbleFor}
      addComment={addComment}
      comments={comments}
      showCommentsFlag={showSubjectlinesCommentsDialog}
      setShowCommentsFlag={setShowSubjectlinesCommentsDialog}
      rowIndex={selectedRowIndex}
      getCommentsData={getCommentsData}
    />
  );
  const tabHeaders = (
    <PreHeader
      catchlines={preHeaders}
      isAnyToggleOn={isAnyToggleOn}
      initiateTranslationFor={initiateTranslationFor}
      getCommentBubbleFor={getCommentBubbleFor}
      addComment={addComment}
      comments={comments}
      showCommentsFlag={showPreheadersCommentsDialog}
      setShowCommentsFlag={setShowPreheadersCommentsDialog}
      rowIndex={selectedRowIndex}
      getCommentsData={getCommentsData}
    />
  );
  const tabEmailHeaders = (
    <EmailHeader
      emailHeadersData={emailHeaders}
      isAnyToggleOn={isAnyToggleOn}
      initiateTranslationFor={initiateTranslationFor}
      getCommentBubbleFor={getCommentBubbleFor}
      addComment={addComment}
      comments={comments}
      showCommentsFlag={showHeadersCommentsDialog}
      setShowCommentsFlag={setShowHeadersCommentsDialog}
      rowIndex={selectedRowIndex}
      boxPosition={headerBoxPosition}
      getCommentsData={getCommentsData}
    />
  );

  return (
    <div className="emails">
      <span className="title">Email Elements</span>
      <div className="tabs">
        <TabPanel
          titles={[
            `Subject Line (${subjectLines?.length})`,
            `PreHeader (${preHeaders?.length})`,
            `Header (${emailHeaders?.length})`,
          ]}
          children={[tabSubjectLines, tabHeaders, tabEmailHeaders]}
        />
      </div>
    </div>
  );
};
export default Email;
