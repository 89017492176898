//Import React dependencies
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { IndeterminateCheckBox } from "@material-ui/icons";
import backButton from "../../../images/chevron-left-matrix.svg";
import infoIcon from "../../../images/infoIcon.svg";
import "../../../styles/components/ChannelMatrixContentBlock.scss";
import "../../../styles/components/Headers/ModalHeader.scss";
import OverlayPopOver from "../../../Uitls/OverlayPopOver";
import CompositeAssetView from "./CompositeAssetView";
import { useRef } from "react";

const CompositeAssetList = (props) => {
  const {
    publishMatrixToDams,
    closeModal,
    sourceType,
    previousPage,
    generateChannelMatrixSpreadSheet,
    assetDataType,
  } = props;
  const { t } = useTranslation();
  const { compositeAssetsDetails, viewCompositeAsset, moduleDetails } = props;
  const [currentPageActive, setCurrentPageActive] = useState("1");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [finalMatrixCreationInProgress, setFinalMatrixCreationInProgress] =
    useState(false);
  const [compositeAssetToView, setCompositeAssetToView] = useState(undefined);

  useEffect(() => {
    setList(compositeAssetsDetails?.compositeDetails);
    setIsCheck(
      compositeAssetsDetails?.compositeDetails.map((li) => li.compositeId)
    );
    setIsCheckAll(true);
  }, [compositeAssetsDetails]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    console.log(list.map((li) => li.compositeId));
    setIsCheck(list.map((li) => li.compositeId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck?.filter((item) => item !== id));
    }
  };

  const getCompositeAssetsToDisplay = () => {
    if (currentPageActive === "2") {
      if (
        compositeAssetsDetails &&
        compositeAssetsDetails.compositeDetails &&
        isCheck
      ) {
        const selectedIds = [...isCheck];

        let compositeAssets = JSON.parse(
          JSON.stringify(compositeAssetsDetails.compositeDetails)
        );
        let finalTactics = [];
        selectedIds.forEach((selectedId) => {
          const found = compositeAssets.find((compositeAsset) => {
            return compositeAsset.compositeId === selectedId;
          });
          if (found) {
            finalTactics = finalTactics.concat(found);
          }
        });

        return finalTactics;
      }
    }
    return compositeAssetsDetails?.compositeDetails || [];
  };

  useEffect(() => {
    let compositeIdsList = compositeAssetsDetails?.compositeDetails.map(
      (li) => li.compositeId
    );
    if (compositeIdsList?.length === isCheck?.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const firstDivRef = useRef();
  const secondDivRef = useRef();

  const handleScrollFirst = (scroll) => {
    secondDivRef.current.scrollTop = scroll.target.scrollTop;
  };

  const handleScrollSecond = (scroll) => {
    firstDivRef.current.scrollTop = scroll.target.scrollTop;
  };

  const matrixContentBlock = () => {
    return (
      <>
        <div className="d-flex mb-3">
          <div className="matrixIdTitle">
            Template ID&nbsp;&nbsp;
            <span>{compositeAssetsDetails?.templateId}</span>
          </div>
          {compositeAssetsDetails?.optOutId && (
            <div className="matrixIdTitle">
              Opt-Out ID&nbsp;&nbsp;
              <span>{compositeAssetsDetails?.optOutId}</span>
            </div>
          )}
          {/* <div className="matrixIdTitle">
            ISI ID&nbsp;&nbsp;
            <span>{compositeAssetsDetails?.isiId || "-"}</span>
          </div> */}
        </div>
        <div className="channelMatrixIdsDisplay">
          <Row className="no-gutters">
            <Col className="col-md-6 matrixCol1Brdr">
              <div
                onScroll={handleScrollFirst}
                ref={firstDivRef}
                // style={{
                //   height: "calc(100 - 6px)",
                //   overflowY: "scroll",
                // }}
                className="matrixTableLeftCol customScrollBar0"
              >
                <div className="matrixTableContentBlock">
                  <table>
                    <tr>
                      <th className="checkbox-color">
                        <input
                          type="checkbox"
                          // value="selectAll"
                          name="selectAll"
                          onClick={handleSelectAll}
                          checked={isCheckAll}
                          disabled={currentPageActive === "2"}
                        />
                      </th>
                      <th className="ColumneHeaderSize">
                        {moduleDetails.channel} Variant ID
                      </th>
                      <th className="ColumneHeaderSize">Subject Line ID</th>
                      <th className="ColumneHeaderSize">Pre-header ID</th>
                    </tr>
                    <tbody>
                      {getCompositeAssetsToDisplay()?.map((item, index) => {
                        if (!item) {
                          return;
                        }
                        return (
                          <tr key={index}>
                            <td className="checkbox-color">
                              <input
                                id={item.compositeId}
                                type="checkbox"
                                name={item.compositeId}
                                onClick={handleClick}
                                checked={isCheck?.includes(item.compositeId)}
                                disabled={currentPageActive === "2"}
                              />
                            </td>
                            <td className="ColumneTextSize">
                              {item.compositeId}
                            </td>
                            <td className="ColumneTextSize">
                              <OverlayPopOver
                                element={
                                  <span className="composite-column-text">
                                    {item.subjectLineId}
                                  </span>
                                }
                                overlayData={parse(item.subjectLineText || "")}
                                trigger={"hover"}
                                classId="composite-asset-hover"
                                placement="bottom"
                              />
                            </td>
                            <td className="ColumneTextSize">
                              <OverlayPopOver
                                element={
                                  <span className="composite-column-text">
                                    {item.preHeaderId}
                                  </span>
                                }
                                overlayData={parse(item.preHeaderText || "")}
                                trigger={"hover"}
                                classId="composite-asset-hover"
                                placement="bottom"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>

            <Col className="col-md-6">
              <div
                className="matrixTablerightCol customScrollBar"
                onScroll={handleScrollSecond}
                ref={secondDivRef}
                // style={{
                //   height: "100%",
                //   overflow: "scroll",
                // }}
              >
                <div className="matrixTableContentBlock">
                  <table>
                    <tr>
                      {getCompositeAssetsToDisplay()[0]?.modules.map(
                        (module, index) => {
                          if (!module) {
                            return;
                          }
                          return (
                            <>
                              <th className="ColumneHeaderSize">
                                Module-{module.bodyModuleSection} ID
                              </th>
                            </>
                          );
                        }
                      )}
                    </tr>
                    <tbody>
                      {getCompositeAssetsToDisplay()?.map((item, index) => {
                        if (!item) {
                          return;
                        }
                        return (
                          <tr>
                            {item.modules.map((module, index) => {
                              return (
                                <>
                                  <td className="ColumneTextSize">
                                    <OverlayPopOver
                                      element={
                                        <span className="composite-column-text">
                                          {module.promoClaimCmDocNumber}
                                        </span>
                                      }
                                      overlayData={parse(
                                        module.promoClaimText || ""
                                      )}
                                      trigger={"hover"}
                                      classId="composite-asset-hover"
                                      placement="bottom"
                                    />
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const compAssetViewBlock = () => {
    return (
      <>
        <Row
          className="mb-3 composite-assets-main-content"
          style={{ fontSize: "14px", marginTop: "24px", marginBottom: "8px" }}
        >
          <Col className="col-md-8 truncated-text">
            <span className="mr-4">
              Template type&nbsp;&nbsp;
              <span className="font-weight-bold">
                {compositeAssetsDetails?.templateType}
              </span>
            </span>
            {"  "}
            <span className="mr-4">
              Template ID&nbsp;&nbsp;
              <span className="font-weight-bold">
                {compositeAssetsDetails?.templateId}
              </span>
            </span>
            <span className="mr-4">
              Template Name&nbsp;&nbsp;
              <span className="font-weight-bold">
                {compositeAssetsDetails?.templateName}
              </span>
            </span>
          </Col>
          <Col className="col-md-4 text-right">
            <button
              type="button"
              className="btn btn-link"
              style={{ padding: "0" }}
              onClick={() => {
                window.open(compositeAssetsDetails.dowonloadUrl);
              }}
            >
              {t("generalText.download").toUpperCase()} (.XLSX)
            </button>{" "}
          </Col>
        </Row>
        <div className="list-composite-assets composite-assets-main-content">
          <Row className="heading">
            <Col className="text-left" style={{ paddingLeft: "30px" }}>
              <div>
                {t("channelMatrix.tacticVariant")} (
                {compositeAssetsDetails?.compositeDetails?.length})
              </div>
            </Col>
            <Col className="text-right" style={{ paddingRight: "36px" }}>
              <div>Click to View</div>
            </Col>
          </Row>

          <div className="composite-asset-rows customScrollBar">
            {compositeAssetsDetails?.compositeDetails?.map((asset) => {
              return (
                <Row className="asset-row" style={{ paddingLeft: "36px" }}>
                  <Col className="text-left" style={{ paddingLeft: "8px" }}>
                    <div>{asset.compositeId || ""}</div>
                  </Col>
                  <Col className="text-right" style={{ paddingRight: "36px" }}>
                    <button
                      onClick={() => {
                        setCompositeAssetToView(asset);
                      }}
                      style={{ paddingRight: "12px" }}
                    >
                      View
                    </button>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const handleMatrixBackbutton = () => {
    if (currentPageActive === "1") {
      props.proceedToNextScreen("pageOne");
    } else if (currentPageActive === "2") {
      secondDivRef.current.scrollTop = 0;
      setCurrentPageActive("1");
    } else if (currentPageActive === "3") {
      setCurrentPageActive("2");
    }
  };

  const isThisRemoveInvalidRowsScreen = () => {
    return (
      currentPageActive === "1" ||
      currentPageActive === "2" ||
      currentPageActive === "3"
    );
  };

  const closeCompositeAssetView = () => {
    setCurrentPageActive("3");
  };

  useEffect(() => {
    if (compositeAssetToView) {
      setCurrentPageActive("4");
    }
  }, [compositeAssetToView]);

  const getPageSubtitle = () => {
    if (currentPageActive === "1") {
      return "Select required Email Variants";
    } else if (currentPageActive === "2") {
      return "Review Email Variants and Proceed to Generate Channel Matrix";
    } else {
      return "";
    }
  };

  return (
    <>
      {isThisRemoveInvalidRowsScreen() && (
        <>
          <div className="composite-asset-mb">
            <Row className="align-items-center comp-asset-list-page-head composite-assets-main-content">
              <Col>
                <div className="d-flex">
                  {currentPageActive !== "3" && (
                    <div>
                      <img
                        className="modal-header-back-button"
                        src={backButton}
                        alt=""
                        width="10"
                        // onClick={() => {
                        //   handleBack(previous);
                        // }}
                        onClick={() => handleMatrixBackbutton()}
                      />
                    </div>
                  )}
                  <div className="pl-2">
                    <div className="matrixIdTitle">
                      Channel Matrix ID{" "}
                      {compositeAssetsDetails?.contentMatrix?.damsUrl && (
                        <button
                          className="btn btn-link pl-0"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(
                              compositeAssetsDetails?.contentMatrix?.damsUrl,
                              "_blank"
                            );
                          }}
                        >
                          {compositeAssetsDetails?.contentMatrixId}
                        </button>
                      )}
                      {!compositeAssetsDetails?.contentMatrix?.damsUrl && (
                        <span>{compositeAssetsDetails?.contentMatrixId}</span>
                      )}
                    </div>
                    {currentPageActive !== "3" && (
                      <span className="d-block pl-1 modal-header-subtitle">
                        <img
                          className="modal-header-subtitle-icon"
                          src={infoIcon}
                          alt=""
                        />
                        {getPageSubtitle()}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              {moduleDetails && (
                <Col className="col-md-7 matrixSearchRowBrand">
                  <div className="d-flex justify-content-end">
                    <div className="mr-5">
                      <span>Product/Brand</span>
                      {moduleDetails.brandName}
                    </div>
                    {moduleDetails.campaign && (
                      <div className="mr-5 truncated-text">
                        <span>Campaign</span>
                        {moduleDetails.campaign}
                      </div>
                    )}
                    <div>
                      <span>Channel</span>
                      {moduleDetails.channel}
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <div
              className="composite-assets-screen-border"
              style={{ marginBottom: "24px" }}
            ></div>

            {currentPageActive === "1" || currentPageActive === "2"
              ? matrixContentBlock()
              : compAssetViewBlock()}
          </div>
          <Row className="composite-assets-screen-border row align-items-center pt-3">
            <Col>
              {(currentPageActive === "1" || currentPageActive === "2") && (
                <>
                  <span style={{ fontSize: "14px" }}>
                    <strong>Selected</strong> ({isCheck?.length}/{list?.length})
                  </span>
                </>
              )}
            </Col>
            <Col className="text-right">
              {(currentPageActive === "3" || currentPageActive === "2") && (
                <button
                  className="btn btn-link text-uppercase pl-0 btn-cancel-rhs"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {currentPageActive === "3" ? "CLOSE" : "CANCEL"}
                </button>
              )}
              <button
                className={`btn btn-primary text-uppercase ${
                  currentPageActive === "1" ? "defaultBtnWidth" : ""
                }`}
                onClick={() => {
                  if (currentPageActive === "1") {
                    secondDivRef.current.scrollTop = 0;
                    setCurrentPageActive("2");
                  } else if (currentPageActive === "2") {
                    setFinalMatrixCreationInProgress(true);
                    generateChannelMatrixSpreadSheet(
                      [...isCheck],
                      (isGenerated) => {
                        if (isGenerated) {
                          setFinalMatrixCreationInProgress(false);
                          setCurrentPageActive("3");
                        }
                      }
                    );
                  } else if (currentPageActive === "3") {
                    publishMatrixToDams();
                  }
                }}
                disabled={
                  (currentPageActive === "2" || currentPageActive === "1") &&
                  isCheck?.length === 0
                    ? true
                    : false
                }
              >
                {currentPageActive === "1"
                  ? "Preview"
                  : currentPageActive === "2"
                  ? "Generate Channel Matrix"
                  : t("channelMatrix.publishMatrix", { dams: sourceType })}
              </button>
            </Col>
          </Row>
        </>
      )}
      {!isThisRemoveInvalidRowsScreen() && (
        <>
          <CompositeAssetView
            proceedToNextScreen={closeCompositeAssetView}
            data={compositeAssetToView}
            previousPage={previousPage}
            sourceType={sourceType}
            assetDataType={assetDataType}
          />

          <div class="row align-items-center martrixModalFooter matrxiFooterBrdr no-gutters">
            <div class="col-md-5"></div>
            <div class="col-md-7 text-right">
              <button
                class="btn btn-link text-uppercase"
                onClick={() => {
                  setCompositeAssetToView(undefined);
                  closeCompositeAssetView();
                }}
              >
                CLOSE
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CompositeAssetList;
