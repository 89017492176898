import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../../styles/pages/MLRSubmissionPackage.scss";
import { renderToString } from "react-dom/server";
import parse from "html-react-parser";
import { moduleTypes, damsSources, capitalizeString } from "../../Uitls/Util";

const ModuleSpecificInfo = (props) => {
  const [module, setModule] = useState(undefined);
  const [pageJsx, setPageJsx] = useState(undefined);

  useEffect(() => {
    if (props.module) {
      if (module && module.id && module.id !== props.module.id) {
        setPageJsx(undefined);
      }
      setModule(props.module);
    }
  }, [props]);

  useEffect(() => {
    if (props.setJsx) {
      props.setJsx(pageJsx);
    }
  }, [pageJsx]);

  const getCoreClaimCategories = () => {
    if (!module) {
      return "";
    }
    const coreClaimCategories = module.moduleCoreCategories;
    if (!coreClaimCategories || coreClaimCategories.length <= 0) {
      return "";
    }
    const categories = coreClaimCategories.map((category) => {
      return category.coreCategory;
    });
    return categories.toString();
  };

  const getCoreClaim = () => {
    if (!module) {
      return;
    }
    const coreClaims = module.moduleClaims;
    if (!coreClaims || coreClaims.length <= 0) {
      return "";
    }
    let claims = coreClaims.filter((claim) => {
      return claim.type === "Core";
    });
    claims = claims.map((claim) => {
      return claim.claim;
    });
    return claims.toString();
  };

  const getRecommendationOnModuleUsage = () => {
    if (!module) {
      return "";
    }
    const addInfo = module.additionalInformations;
    if (addInfo && addInfo.length > 0) {
      const info = addInfo[0];
      if (info && info.information) {
        const recommendationOnModuleUsage =
          info.information.moduleUsageRecommendation;
        if (recommendationOnModuleUsage) {
          return parse(recommendationOnModuleUsage);
        }
      }
    }
    return "";
  };

  // const getGenericBusinessRules = () => {
  //   if (!module) {
  //     return "";
  //   }
  //   const addInfo = module.additionalInformations;
  //   if (addInfo && addInfo.length > 0) {
  //     const info = addInfo[0];
  //     if (info && info.information) {
  //       const genericBusinessRules = info.information.genericBusinessRules;
  //       if (genericBusinessRules) {
  //         return parse(genericBusinessRules);
  //       }
  //     }
  //   }
  //   return "";
  // };

  const getPageNumber = () => {
    if (!module) {
      return "";
    }
    if (module.pageNumber) {
      return module.pageNumber;
    }
  };

  const getAudience = () => {
    if (!module) {
      return;
    }
    const assetDetails = module.assetDetails;
    if (assetDetails) {
      const audience = assetDetails.audience;
      if (audience && audience.length > 0) {
        return audience.toString().replace(/,[s]*/g, ", ");
      }
    }
  };

  const getDosage = () => {
    if (!module) {
      return;
    }
    const addInfo = module.additionalInformations;
    if (addInfo && addInfo.length > 0) {
      const desiredAddInfo = addInfo[0];
      if (desiredAddInfo) {
        const information = desiredAddInfo.information;
        if (information && information.dosage) {
          return parse(information.dosage);
        }
      }
    }
    const assetDetails = module.assetDetails;
    if (assetDetails && assetDetails.dosing) {
      return parse(assetDetails.dosing);
    }
  };

  const getModuleRating = () => {
    if (!module) {
      return "";
    }
    const addInfo = module.additionalInformations;
    if (addInfo && addInfo.length > 0) {
      const desiredAddInfo = addInfo[0];
      if (desiredAddInfo) {
        const information = desiredAddInfo.information;
        if (information && information.moduleRating) {
          return information.moduleRating;
        }
      }
    }
    return "";
  };

  const getInformationData = (type) => {
    if (
      !module ||
      module.additionalInformations.length === 0 ||
      !module.additionalInformations[0].information
    ) {
      return "";
    }
    if (type === "dosage") {
      return module.additionalInformations[0].information.dosage;
    } else {
      return module.additionalInformations[0].information.audienceSegment;
    }
  };

  const renderData = () => {
    if (!module) {
      return <div></div>;
    }
    const jsx = (
      <div>
        <div
          className="pdfHeader"
          style={{
            marginBottom: "15px",
            fontFamily: " Arial, Helvetica, sans-serif;",
            fontSize: " 16px",
            color: "#707070",
          }}
        >
          {module && module.name}
        </div>
        <table
          className="module-specific table"
          style={{ marginBottom: "50px" }}
        >
          <tbody>
            <tr className="header">
              <td>General information</td>
              <td></td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
                Brand
              </td>
              <td className="text-black">{module.assetDetails.brand}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Campaign</td>
              <td className="text-black">{module.assetDetails.campaign}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Market</td>
              <td className="text-black">{module.assetDetails.market}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Description</td>
              <td className="text-black">{module.assetDetails.descripton}</td>
            </tr>
            {module &&
              module.creationMethodType.toLowerCase() !==
                moduleTypes.denovo && (
                <tr>
                  <td className="tbl-row-txt-bold">Master asset name</td>
                  <td className="text-black">
                    {module.assetDetails.assetName}
                  </td>
                </tr>
              )}
            <tr>
              <td className="tbl-row-txt-bold">
                DAMS link to ISI used as reference
              </td>
              <td className="text-black" style={{ wordBreak: "break-all" }}>
                {module.assetDetails.isi}
              </td>
            </tr>

            <tr>
              <td className="tbl-row-txt-bold">
                DAMS link to PI used as reference
              </td>
              <td className="text-black" style={{ wordBreak: "break-all" }}>
                {module.assetDetails.pi}
              </td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">
                DAMS link to MG used as reference
              </td>
              <td className="text-black" style={{ wordBreak: "break-all" }}>
                {module.assetDetails.mg}
              </td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Therapeutic area</td>
              <td className="text-black">
                {module.assetDetails.therapeuticArea}
              </td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Audience</td>
              <td className="text-black">{getAudience()}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Dosing</td>
              <td className="text-black">{getInformationData("dosage")}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Audience Segment</td>
              <td className="text-black">
                {getInformationData("audienceSegment")}
              </td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">
                General business rules
                <p>when developing tactics</p>
              </td>
              <td className="text-black">
                {module.assetDetails.genericBusinessRules}
              </td>
            </tr>
          </tbody>
        </table>

        <span
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "black",
            fontFamily: "Arial, Arial Unicode MS, Helvetica, sans-serif",
            whiteSpace: "nowrap",
          }}
        >
          Module-specific information
        </span>

        <table
          className="module-specific table"
          style={{ marginBottom: "0px" }}
        >
          <tbody>
            <tr className="header">
              <td>Module information</td>
              <td></td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
                Name
              </td>
              <td className="text-black">{module.name}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">Module Manager ID</td>
              <td className="text-black">{module.moduleCode}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">
                {/* {module?.assetDetails?.sourceType?.toLowerCase() ===
                damsSources.veeva.name.toLowerCase()
                  ? "Veeva"
                  : "Aprimo"}{" "} */}
                {capitalizeString(module?.assetDetails?.sourceType) ||
                  (module.creationMethodType?.toLowerCase() ===
                  moduleTypes.denovo
                    ? damsSources.veeva.name
                    : "")}{" "}
                Document Number
              </td>
              <td className="text-black">{module.veevaDocNumber}</td>
            </tr>
            {module &&
              module.creationMethodType.toLowerCase() !==
                moduleTypes.denovo && (
                <tr>
                  <td className="tbl-row-txt-bold">
                    Page number in master asset
                  </td>
                  <td className="text-black">{getPageNumber()}</td>
                </tr>
              )}
            <tr>
              <td className="tbl-row-txt-bold">
                {i18next.t("coreClaim.coreClaimCategory")}
              </td>
              <td className="text-black">{getCoreClaimCategories()}</td>
            </tr>
            <tr>
              <td className="tbl-row-txt-bold">
                {" "}
                {i18next.t("coreClaim.coreClaim")}
              </td>
              <td className="text-black">{getCoreClaim()}</td>
            </tr>

            {/* <tr>
              <td className="tbl-row-txt-bold">Module Rating</td>
              <td className="text-black">{getModuleRating()}</td>
            </tr> */}
            {/* <tr>
              <td className="tbl-row-txt-bold">Generic business rules</td>
              <td className="text-black">{getGenericBusinessRules()}</td>
            </tr> */}
            {/* <tr>
              <td className="tbl-row-txt-bold">
                Recommendation on module usage
              </td>
              <td className="text-black">{getRecommendationOnModuleUsage()}</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    );
    if (!pageJsx) {
      setPageJsx(jsx);
    }
    return jsx;
  };

  return <div>{renderData()}</div>;
};

export default ModuleSpecificInfo;
