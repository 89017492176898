//Import React dependencies

import React from "react";
import ContentModuleCard from "./ContentModuleCard";

//Import Styles
import "../../../styles/components/ExistingTatics.scss";

const ContentModules = ({
  modules,
  isCmSelectable = true,
  selectedContentModuleIds,
  buttonList,
  moduleCardBtnActions,
  onModuleSelect,
  cannotExistModuleIds,
  searchInDams,
}) => {
  const checkForCannotExist = (cmId) => {
    if (cannotExistModuleIds?.length > 0) {
      return cannotExistModuleIds.indexOf(cmId) > -1;
    }
    return false;
  };

  const getModuleCard = (module) => {
    return (
      <ContentModuleCard
        module={module}
        buttonList={buttonList}
        moduleCardBtnActions={moduleCardBtnActions}
        isSelectable={isCmSelectable}
        onModuleSelect={onModuleSelect}
        isSelected={selectedContentModuleIds?.indexOf(module.id) > -1}
        cannotExistWith={checkForCannotExist(module.cmId)}
      />
    );
  };

  return (
    <>
      <div
        className={`row pl-3 ${
          searchInDams ? "content-modules" : "existingTacticsModDetails"
        } custonScrollBar`}
      >
        {modules &&
          modules.map((module, index) => {
            return getModuleCard(module);
          })}
      </div>
    </>
  );
};

export default ContentModules;
