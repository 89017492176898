import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import ReactStars from "react-rating-stars-component";
import parseHtml from "html-react-parser";
import {
  htmlToString,
  routes,
  hasUserLoggedInAsReviewer,
  getTranslationJobType,
} from "../Uitls/Util";
import LogoutCTA from "../Pages/LogoutCTA";
import TranslateHeader from "../components/Headers/HeaderTranslateModule";
import TranslateModuleNav from "../components/Headers/TranslateModuleNav";
import errComponentToRender from "../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import CustomModal from "../components/customModal";
import TranslateClaimsGenericModal from "../components/TranslateFlow/TranslateClaims/TranslateClaimGenericModal";
import Menu from "../components/TranslateFlow/TranslateClaims/Menu";
import translateTick from "../images/translateTick.svg";
import translateIcon from "../images/translateIcon.svg";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import AddComment from "../components/TranslateFlow/AddComment";
import Comments from "../components/TranslateFlow/Comments";

import {
  getModuleDetailsById,
  addNewComment,
  saveTranslatedModule,
} from "../services/apis";

import commentIcon from "../images/icon-comment.svg";

import "../styles/pages/TranslateClaims.scss";
import "../styles/pages/TranslateAdditionalInfo.scss";

const TranslateAdditionalInfo = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  // const outerRef = useRef(null);
  // const otherBussinessRef = useRef(null);
  const dosageRef = useRef(null);
  const recommendationRef = useRef(null);
  const stageInCustomerJourneyRef = useRef(null);
  const audienceSegmentRef = useRef(null);
  const [showOriginalToggle, setShowOriginalToggle] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [module, setModule] = useState({});
  const [information, setInformation] = useState({});
  const [originalInfo, setOriginalInfo] = useState({});
  const [showGenericTranslateModal, setShowGenericTranslateModal] =
    useState(false);
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);
  const [dataTypeSelectedForTranslation, setDtaTypeSelectedForTranslation] =
    useState(undefined);

  //For Comment
  const [showAddCommentBox, setShowAddCommentBox] = useState(false);
  const [commentObject, setCommentObject] = useState(undefined);
  const [boxComments, setBoxComments] = useState([]);
  // const [showGenRuleCommentsDialog, setShowGenRuleCommentsDialog] =
  //   useState(false);
  // const [showOthRuleCommentsDialog, setShowOthRuleCommentsDialog] =
  //   useState(false);
  const [showmoduleUsageCommentsDialog, setShowmoduleUsageCommentsDialog] =
    useState(false);
  const [showDosageCommentsDialog, setDosageCommentsDialog] = useState(false);
  const [showStageCommentsDialog, setStageCommentsDialog] = useState(false);
  const [showAudienceSegmentDialog, setAudienceSegmentDialog] = useState(false);

  //Source module toggle
  const [sourceModuleToggle, updateSourceModuleToggle] = useState(false);
  const [hasSourceRefModuleId, setHasSourceRefModuleId] = useState(false);

  const getModuleIdFromLocalStorage = () => {
    //Fetch the Module Details here
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let moduleId = undefined;
    if (props && props.location && props.location.state) {
      moduleId = props.location.state.moduleId;
    } else {
      moduleId =
        moduleInfoFromLocalStorage &&
        moduleInfoFromLocalStorage.translateModuleId;
    }
    return moduleId;
  };

  useEffect(() => {
    const moduleId = getModuleIdFromLocalStorage();
    if (!moduleId) {
      return;
    }
    fetchModuleDetails(moduleId);
  }, []);

  const fetchModuleDetails = (moduleId, isCurrent = true) => {
    setIsLoading(true);
    getModuleDetailsById(moduleId, (response, err, errCode) => {
      setIsLoading(false);
      if (response) {
        setModule(response.data);
        if (isCurrent && response.data.translationRefModuleId) {
          setHasSourceRefModuleId(true);
        }
        const additionalInformations = response.data.additionalInformations;

        if (additionalInformations.length > 0) {
          const addInfo = additionalInformations[0];
          if (addInfo) {
            if (addInfo.information) {
              setInformation(addInfo.information);
            }
            if (addInfo.originalInfo) {
              setOriginalInfo(addInfo.originalInfo);
            } else {
              setOriginalInfo({});
            }
          }
        } else {
          setInformation({});
          setOriginalInfo({});
        }
        return;
      }
      const errCoponent = errComponentToRender(errCode);
      toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
    });
  };

  const getModuleDetails = () => {
    fetchModuleDetails(module.id);
  };
  const handleProceed = (option) => {
    if (option) {
      return;
    }
    const moduleId = getModuleIdFromLocalStorage();
    if (moduleId) {
      history.push({
        pathname: routes.moduleDetails,
        state: {
          moduleId: moduleId,
          flowType: "previewTranslate",
        },
      });
    }
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleSave = (data) => {
    switch (dataTypeSelectedForTranslation) {
      case "dosage":
        information.dosage = data[0].text;
        information.dosageTranslate = data[0].translate;
        break;
      // case "genericBusinessRules":
      //   information.genericBusinessRules = data[0].text;
      //   information.genericBusinessRulesTranslate = data[0].translate;
      //   break;
      case "moduleUsageRecommendation":
        information.moduleUsageRecommendation = data[0].text;
        information.moduleUsageRecommendationTranslate = data[0].translate;
        break;
      // case "usageInstructionsGuidelines":
      //   information.usageInstructionsGuidelines = data[0].text;
      //   information.usageInstructionsGuidelinesTranslate = data[0].translate;
      //   break;
      case "stageInCustomerJourney":
        information.stageInCustomerJourney = data[0].text;
        information.stageInCustomerJourneyTranslate = data[0].translate;
        break;
      case "audienceSegment":
        information.audienceSegment = data[0].text;
        information.audienceSegmentTranslate = data[0].translate;
        break;
      default:
        break;
    }
    setShowGenericTranslateModal(false);
    saveTranslated();
  };

  const handleLanguageToggleBtn = () => {
    const newToggleState = !showOriginalToggle;
    setShowOriginalToggle(newToggleState);
    if (newToggleState) {
      fetchModuleDetails(module.moduleIdEnglish, false);
    } else {
      fetchModuleDetails(getModuleIdFromLocalStorage());
    }
  };
  const handleCloseGenericModal = () => {
    setShowGenericTranslateModal(false);
    fetchModuleDetails(module.id);
  };
  const intiateTranslationFor = (data, type) => {
    setDataInputForTranslation(() => {
      openGenericTranslateModal();
    });
    setDataInputForTranslation(data);
    setDtaTypeSelectedForTranslation(type);
  };
  const openGenericTranslateModal = () => {
    setShowGenericTranslateModal(true);
  };
  const getData = (type) => {
    let data = [];
    let object = {};
    switch (type) {
      case "dosage":
        object = {
          originalText: originalInfo.dosage,
          text: information.dosage,
          translate: information.dosageTranslate,
        };
        return data.concat(object);
      case "moduleUsageRecommendation":
        object = {
          originalText: originalInfo.moduleUsageRecommendation,
          text: information.moduleUsageRecommendation,
          translate: information.moduleUsageRecommendationTranslate,
        };
        return data.concat(object);
      case "usageInstructionsGuidelines":
        object = {
          originalText: originalInfo.usageInstructionsGuidelines,
          text: information.usageInstructionsGuidelines,
          translate: information.usageInstructionsGuidelinesTranslate,
        };
        return data.concat(object);
      case "stageInCustomerJourney":
        object = {
          originalText: originalInfo.stageInCustomerJourney,
          text: information.stageInCustomerJourney,
          translate: information.stageInCustomerJourneyTranslate,
        };
        return data.concat(object);
      case "audienceSegment":
        object = {
          originalText: originalInfo.audienceSegment,
          text: information.audienceSegment,
          translate: information.audienceSegmentTranslate,
        };
        return data.concat(object);
      default:
        return [];
    }
  };
  const saveTranslated = () => {
    const addInfo = getAdditionalInfo();
    if (!addInfo) {
      return;
    }

    if (information.moduleRating) {
      information.moduleRatingTranslate = "2";
    }
    if (information.pageNumber) {
      information.pageNumberTranslate = "2";
    }
    let payload = {
      information: information,
      originalInfo: originalInfo,
    };
    let isSetAll = true;
    if (
      information.dosageTranslate === "1" ||
      information.usageInstructionsGuidelinesTranslate === "1" ||
      information.moduleUsageRecommendationTranslate === "1" ||
      information.stageInCustomerJourneyTranslate === "1" ||
      information.audienceSegmentTranslate === "1"
      //information.genericBusinessRulesTranslate === "1"
    ) {
      isSetAll = false;
    } else {
      isSetAll = true;
    }
    if (isSetAll) {
      payload.translate = "2";
    } else {
      payload.translate = "1";
    }

    addInfo.information = payload.information;
    addInfo.originalInfo = payload.originalInfo;
    addInfo.translate = payload.translate;
    payload = {
      id: module.id,
      name: module.name,
      pageNumber: module.pageNumber,
      additionalInformations: [addInfo],
    };

    if (payload) {
      setIsLoading(true);
      saveTranslatedModule(module.id, payload, (response, err, errorCode) => {
        setIsLoading(false);
        if (err) {
          const errCoponent = errComponentToRender(errorCode);
          toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
          fetchModuleDetails(module.id);
        } else {
          toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
            autoClose: 2000,
            position: "top-center",
          });
        }
      });
    }
  };

  const isLastPageToReviewOrTranslate = () => {
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    ).translateComponents;
    if (selectedPages[selectedPages.length - 1] === "additionalInfo") {
      return true;
    }
    return false;
  };

  const getProceedButtonTitle = () => {
    if (isLastPageToReviewOrTranslate()) {
      const jobType = getTranslationJobType();
      if (jobType && jobType.trim().toLowerCase() === "self") {
        return i18next.t("translated.preview").toUpperCase();
      }
    }
    return undefined;
  };

  const consolidateComments = (boxName) => {
    let comments = [];
    if (module && Object.keys(module).length > 0) {
      let additionalInfo = getAdditionalInfo();
      if (additionalInfo) {
        const allComments = module.comments;
        comments = allComments.filter((comment) => {
          return (
            comment.primaryComponentId === additionalInfo.id &&
            comment.componentId === boxName
          );
        });
      }
    }
    return comments;
  };

  const getCommentBubbleFor = (boxName) => {
    const comments = consolidateComments(boxName);
    if (!comments || comments.length === 0) {
      return;
    }
    const marginRight = hasUserLoggedInAsReviewer() ? "6px" : "54px";
    let bottom = "-3px";
    if (
      boxName === "dosage" ||
      boxName === "stageInCustomerJourney" ||
      boxName === "audienceSegment"
    ) {
      bottom = "3px";
    } else if (boxName === "moduleUsage") {
      bottom = "7px";
    }
    return (
      <div
        className="comment-icon"
        style={{
          marginRight: marginRight,
          bottom: bottom,
          cursor: "pointer",
        }}
      >
        <img
          src={commentIcon}
          alt="Comment"
          onClick={() => {
            if (comments && comments.length > 0) {
              setBoxComments(() => {
                handleShowCommentsDialog(boxName);
              });
              setBoxComments(comments);
            }
          }}
        />
      </div>
    );
  };
  const handleShowCommentsDialog = (boxName) => {
    switch (boxName) {
      // case "generic":
      //   setShowGenRuleCommentsDialog(!showGenRuleCommentsDialog);
      //   setShowOthRuleCommentsDialog(false);
      //   setShowmoduleUsageCommentsDialog(false);
      //   setDosageCommentsDialog(false);
      //   setStageCommentsDialog(false);
      //   setAudienceSegmentDialog(false);
      //   break;
      // case "other":
      //   setShowGenRuleCommentsDialog(false);
      //   setShowOthRuleCommentsDialog(!showOthRuleCommentsDialog);
      //   setShowmoduleUsageCommentsDialog(false);
      //   setDosageCommentsDialog(false);
      //   setStageCommentsDialog(false);
      //   setAudienceSegmentDialog(false);
      //   break;
      case "dosage":
        // setShowGenRuleCommentsDialog(false);
        // setShowOthRuleCommentsDialog(false);
        setShowmoduleUsageCommentsDialog(false);
        setDosageCommentsDialog(!showDosageCommentsDialog);
        setStageCommentsDialog(false);
        setAudienceSegmentDialog(false);
        break;
      case "moduleUsage":
        // setShowGenRuleCommentsDialog(false);
        // setShowOthRuleCommentsDialog(false);
        setShowmoduleUsageCommentsDialog(!showmoduleUsageCommentsDialog);
        setDosageCommentsDialog(false);
        setStageCommentsDialog(false);
        setAudienceSegmentDialog(false);
        break;
      case "stageInCustomerJourney":
        // setShowGenRuleCommentsDialog(false);
        // setShowOthRuleCommentsDialog(false);
        setShowmoduleUsageCommentsDialog(false);
        setDosageCommentsDialog(false);
        setStageCommentsDialog(!showStageCommentsDialog);
        setAudienceSegmentDialog(false);
        break;
      case "audienceSegment":
        // setShowGenRuleCommentsDialog(false);
        // setShowOthRuleCommentsDialog(false);
        setShowmoduleUsageCommentsDialog(false);
        setDosageCommentsDialog(false);
        setStageCommentsDialog(false);
        setAudienceSegmentDialog(!showAudienceSegmentDialog);
        break;
      default:
        // setShowGenRuleCommentsDialog(false);
        // setShowOthRuleCommentsDialog(false);
        setShowmoduleUsageCommentsDialog(false);
        setDosageCommentsDialog(false);
        setStageCommentsDialog(false);
        setAudienceSegmentDialog(false);
        break;
    }
  };

  const addComment = (selectedText, componentType) => {
    let additionalInfo = getAdditionalInfo();
    const commentObject = {
      selectedText: selectedText,
      primaryComponentId: additionalInfo?.id,
      componentId: componentType,
    };
    setCommentObject(() => {
      showAddCommentsModal(true);
    });
    setCommentObject(commentObject);
  };

  const addCommentToModuleUsage = (selectedText) => {
    addComment(selectedText, "moduleUsage");
  };
  const addCommentToDosage = (selectedText) => {
    addComment(selectedText, "dosage");
  };
  const addCommentToStageInCustomerJourney = (selectedText) => {
    addComment(selectedText, "stageInCustomerJourney");
  };
  const addCommentToaudienceSegment = (selectedText) => {
    addComment(selectedText, "audienceSegment");
  };
  const showAddCommentsModal = (show) => {
    setShowAddCommentBox(show);
    if (!show) {
      setCommentObject(undefined);
    }
  };
  const saveComment = (commentText) => {
    if (commentObject) {
      commentObject["comment"] = commentText;
      commentObject["moduleId"] = module.id;
    }
    if (commentObject) {
      setIsLoading(true);
      addNewComment(commentObject, (response, errMessage, errCode) => {
        setIsLoading(false);
        if (response) {
          fetchModuleDetails(module.id);
        } else {
          const errCoponent = errComponentToRender(errCode);
          toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
        }
        showAddCommentsModal(false);
      });
    }
  };

  const getAdditionalInfo = () => {
    if (
      module &&
      Object.keys(module).length > 0 &&
      module.additionalInformations.length > 0
    ) {
      return module.additionalInformations[0];
    }
    return undefined;
  };

  const handleSourceModuleToggle = () => {
    const updatedToggleState = !sourceModuleToggle;
    updateSourceModuleToggle(updatedToggleState);
    if (updatedToggleState) {
      fetchModuleDetails(module.translationRefModuleId, false);
    } else {
      fetchModuleDetails(getModuleIdFromLocalStorage());
    }
  };
  return (
    <>
      <LogoutCTA />
      <div className="trasnlateHeaderBg">
        <TranslateHeader />
        <TranslateModuleNav
          btnProceed={
            getProceedButtonTitle() || i18next.t("generalText.proceed")
          }
          btnClose={i18next.t("generalText.close")}
          btnSave={i18next.t("generalText.save")}
          disableProceed={false}
          handleProceed={handleProceed}
          handlePageClose={handleClose}
          handlePageSave={saveTranslated}
          pageIndex={3}
          toggle={showOriginalToggle}
          handleLanguageToggleBtn={handleLanguageToggleBtn}
          moduleName={module.name || ""}
          moduleCode={module.moduleCode || ""}
          sourceLanguage={module.translatedFrom || ""}
          targetLanguage={module.language || ""}
          shouldHaveMultipleOptions={
            isLastPageToReviewOrTranslate() ? true : false
          }
          proceedOptions={
            isLastPageToReviewOrTranslate()
              ? ["Revision Required", "Approve"]
              : undefined
          }
          hasSourceModuleId={hasSourceRefModuleId}
          toggleSourceModule={sourceModuleToggle}
          handleSourceModuleToggle={handleSourceModuleToggle}
        />
      </div>
      <div className="container-fluid">
        <CircularProgressBar isloading={isloading} />
        <div className="row align-items-center">
          <Container fluid id="div-content">
            <Row className="row mt-3 ml-0 mr-0 mb-4">
              <div className="add-info-content-page-translate">
                <Row className="add-info-row-translate">
                  <div className="add-info-title-translate">
                    {i18next.t("additionalInfo.addAdditionalInfo")}
                  </div>
                </Row>
                <Row className="add-info-row-translate row-margins-translate">
                  <Col className="col-md-6">
                    <Col className="col-md-12">
                      <div>
                        <div
                          className="translate-dosage translate-dosage-border"
                          ref={stageInCustomerJourneyRef}
                          id="stageInCustomerJourney"
                        >
                          <TextField
                            id="stageInCustomerJourney"
                            label={t("additionalInfo.stageJourney")}
                            variant="outlined"
                            value={htmlToString(
                              information.stageInCustomerJourney || ""
                            )}
                            fullWidth={true}
                            disabled={true}
                          />
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            information.stageInCustomerJourneyTranslate ===
                              "2" && (
                              <div className="addCheckIcon position-absolute dosageTickIcon">
                                <img src={translateTick} alt="Translate" />
                              </div>
                            )}
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            !hasUserLoggedInAsReviewer() &&
                            originalInfo.stageInCustomerJourney &&
                            originalInfo.stageInCustomerJourney !== "" && (
                              <div style={{ zIndex: "1" }}>
                                <button
                                  className="btn btn-link translate-icon"
                                  onClick={() => {
                                    intiateTranslationFor(
                                      getData("stageInCustomerJourney"),
                                      "stageInCustomerJourney"
                                    );
                                  }}
                                >
                                  <img src={translateIcon} alt="Translate" />
                                </button>
                              </div>
                            )}
                          {getCommentBubbleFor("stageInCustomerJourney")}
                          {showStageCommentsDialog && (
                            <Comments
                              moduleId={module.id}
                              componentId={getAdditionalInfo()?.id}
                              childComponentId=""
                              comments={boxComments}
                              commentsPopupCurrentState={
                                showStageCommentsDialog
                              }
                              commentsPopupUpdateState={setStageCommentsDialog}
                              currentClass={
                                hasUserLoggedInAsReviewer()
                                  ? "commentsDosage"
                                  : "commentsDosage-translator"
                              }
                              getCommentsData={getModuleDetails}
                            />
                          )}
                        </div>
                        {!showOriginalToggle && !sourceModuleToggle && (
                          <Menu
                            outerRef={stageInCustomerJourneyRef}
                            compClass="stageInCustomerJourney"
                            addComment={addCommentToStageInCustomerJourney}
                          />
                        )}
                      </div>
                    </Col>
                    <Col className="col-md-12 col-margins-translate">
                      <div>
                        <div
                          className="translate-dosage translate-dosage-border"
                          ref={audienceSegmentRef}
                          id="audienceSegment"
                        >
                          <TextField
                            id="audienceSegment"
                            label={t("additionalInfo.audienceSegment")}
                            variant="outlined"
                            value={htmlToString(
                              information.audienceSegment || ""
                            )}
                            fullWidth={true}
                            disabled={true}
                          />
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            information.audienceSegmentTranslate === "2" && (
                              <div className="addCheckIcon position-absolute dosageTickIcon">
                                <img src={translateTick} alt="Translate" />
                              </div>
                            )}
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            !hasUserLoggedInAsReviewer() &&
                            originalInfo.audienceSegment &&
                            originalInfo.audienceSegment !== "" && (
                              <div style={{ zIndex: "1" }}>
                                <button
                                  className="btn btn-link translate-icon"
                                  onClick={() => {
                                    intiateTranslationFor(
                                      getData("audienceSegment"),
                                      "audienceSegment"
                                    );
                                  }}
                                >
                                  <img src={translateIcon} alt="Translate" />
                                </button>
                              </div>
                            )}
                          {getCommentBubbleFor("audienceSegment")}
                          {showAudienceSegmentDialog && (
                            <Comments
                              moduleId={module.id}
                              componentId={getAdditionalInfo()?.id}
                              childComponentId=""
                              comments={boxComments}
                              commentsPopupCurrentState={
                                showAudienceSegmentDialog
                              }
                              commentsPopupUpdateState={
                                setAudienceSegmentDialog
                              }
                              currentClass={
                                hasUserLoggedInAsReviewer()
                                  ? "commentsDosage"
                                  : "commentsDosage-translator"
                              }
                              getCommentsData={getModuleDetails}
                            />
                          )}
                        </div>
                        {!showOriginalToggle && !sourceModuleToggle && (
                          <Menu
                            outerRef={audienceSegmentRef}
                            compClass="audienceSegment"
                            addComment={addCommentToaudienceSegment}
                          />
                        )}
                      </div>
                    </Col>
                    <Col className="col-md-12 col-margins-translate">
                      <div>
                        <div
                          className="translate-dosage translate-dosage-border"
                          ref={dosageRef}
                          id="dosage"
                        >
                          <TextField
                            id="dosage"
                            label={t("additionalInfo.dosage")}
                            variant="outlined"
                            value={htmlToString(information.dosage || "")}
                            fullWidth={true}
                            disabled={true}
                          />
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            information.dosageTranslate === "2" && (
                              <div className="addCheckIcon position-absolute dosageTickIcon">
                                <img src={translateTick} alt="Translate" />
                              </div>
                            )}
                          {!showOriginalToggle &&
                            !sourceModuleToggle &&
                            !hasUserLoggedInAsReviewer() &&
                            originalInfo.dosage &&
                            originalInfo.dosage !== "" && (
                              <div style={{ zIndex: "1" }}>
                                <button
                                  className="btn btn-link translate-icon"
                                  onClick={() => {
                                    intiateTranslationFor(
                                      getData("dosage"),
                                      "dosage"
                                    );
                                  }}
                                >
                                  <img src={translateIcon} alt="Translate" />
                                </button>
                              </div>
                            )}
                          {getCommentBubbleFor("dosage")}
                          {showDosageCommentsDialog && (
                            <Comments
                              moduleId={module.id}
                              componentId={getAdditionalInfo()?.id}
                              childComponentId=""
                              comments={boxComments}
                              commentsPopupCurrentState={
                                showDosageCommentsDialog
                              }
                              commentsPopupUpdateState={setDosageCommentsDialog}
                              currentClass={
                                hasUserLoggedInAsReviewer()
                                  ? "commentsDosage"
                                  : "commentsDosage-translator"
                              }
                              getCommentsData={getModuleDetails}
                            />
                          )}
                        </div>
                        {!showOriginalToggle && !sourceModuleToggle && (
                          <Menu
                            outerRef={dosageRef}
                            compClass="dosage"
                            addComment={addCommentToDosage}
                          />
                        )}
                      </div>
                    </Col>
                  </Col>
                  <Col className="col-md-6">
                    {information.moduleUsageRecommendation &&
                    information.moduleUsageRecommendation !== "" ? (
                      <span className="translate-label">
                        {i18next.t("additionalInfo.moduleUses")}
                      </span>
                    ) : (
                      <span className="translateAdditionalPlaceHolderAlign">
                        {i18next.t("additionalInfo.moduleUses")}
                      </span>
                    )}
                    <div id="module-uses" className="translator-box">
                      {!showOriginalToggle &&
                        !sourceModuleToggle &&
                        information.moduleUsageRecommendationTranslate ===
                          "2" && (
                          <div className="addCheckIcon position-absolute">
                            <img src={translateTick} alt="Translate" />
                          </div>
                        )}
                      <div
                        className="translateClaimText alignTextAdditionalInfo customScrollBar"
                        ref={recommendationRef}
                        style={{
                          overflow: "scroll",
                          maxHeight: "200px",
                        }}
                        id="module-usage"
                      >
                        {information.moduleUsageRecommendation &&
                          parseHtml(information.moduleUsageRecommendation)}
                      </div>
                    </div>
                    {!showOriginalToggle &&
                      !sourceModuleToggle &&
                      !hasUserLoggedInAsReviewer() &&
                      originalInfo.moduleUsageRecommendation &&
                      originalInfo.moduleUsageRecommendation !== "" && (
                        <div style={{ zIndex: "1" }}>
                          <button
                            className="btn btn-link translate-icon icon-usage"
                            onClick={() => {
                              intiateTranslationFor(
                                getData("moduleUsageRecommendation"),
                                "moduleUsageRecommendation"
                              );
                            }}
                          >
                            <img src={translateIcon} alt="Translate" />
                          </button>
                        </div>
                      )}
                    {getCommentBubbleFor("moduleUsage")}
                    {showmoduleUsageCommentsDialog && (
                      <Comments
                        moduleId={module.id}
                        componentId={getAdditionalInfo()?.id}
                        childComponentId=""
                        comments={boxComments}
                        commentsPopupCurrentState={
                          showmoduleUsageCommentsDialog
                        }
                        commentsPopupUpdateState={
                          setShowmoduleUsageCommentsDialog
                        }
                        currentClass={
                          hasUserLoggedInAsReviewer()
                            ? "commentsModuleUsage"
                            : "commentsModuleUsage-translator"
                        }
                        getCommentsData={getModuleDetails}
                      />
                    )}
                    {!showOriginalToggle && !sourceModuleToggle && (
                      <Menu
                        outerRef={recommendationRef}
                        compClass="module-usage"
                        addComment={addCommentToModuleUsage}
                      />
                    )}
                  </Col>
                </Row>
                {
                  <Row className="add-info-row-translate row-margins-translate">
                    <Col className="col-md-6 d-flex">
                      <span className="translate-module-rating">
                        Module Rating
                      </span>
                      {Object.keys(information).length > 0 && (
                        <ReactStars
                          classNames="stars"
                          count={5}
                          size={24}
                          value={information.moduleRating}
                          isHalf={true}
                          edit={false}
                          activeColor="#1c75bb"
                        />
                      )}
                    </Col>
                    <Col className="col-md-6">
                      <div>
                        <div className="page-number page-border translatePageNumber">
                          <TextField
                            id="page-number"
                            label={t("additionalInfo.pageNumber")}
                            variant="outlined"
                            fullWidth={true}
                            value={module.pageNumber || ""}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </Row>
          </Container>
        </div>
      </div>
      <CustomModal
        displayModal={showGenericTranslateModal}
        hideModal={handleCloseGenericModal}
        title="Select the components you would like to translate"
        size="xl"
      >
        <TranslateClaimsGenericModal
          handleClose={handleCloseGenericModal}
          sourceLanguage={module.translatedFrom}
          targetLanguage={module.language}
          dataInput={dataInputForTranslation}
          dataType={dataTypeSelectedForTranslation}
          saveTranslated={handleSave}
        />
      </CustomModal>
      <CustomModal
        displayModal={showAddCommentBox}
        hideModal={showAddCommentsModal}
        title=""
        size="lg"
      >
        <AddComment
          selectedText={commentObject?.selectedText}
          handleClose={showAddCommentsModal}
          saveComment={saveComment}
        />
      </CustomModal>
    </>
  );
};

export default TranslateAdditionalInfo;
