import { Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";

//Import custom compoennents
import TabPanel from "../../Uitls/TabPanel";
import { channelTypesAndAttributes } from "../../Uitls/Util";
import DropDownWithLabel from "../DropDownWithLabel";
import MarketingMessageCard from "./MarketingMessageCard";
import CustomModal from "../customModal";
import ViewChannelAttribute from "./view/ViewChannelAttribute";
import modalCloseIcon from "../../images/closeIcon.svg";

const MarketingMessages = ({
  marketingMessages,
  startEditingMessage,
  defaultAttributeTabIndex,
  startPublishing,
  isCreatedByOthersToggleOn,
  filterByCreatedByOthersToggle,
  setDefaultAttributeIndex,
}) => {
  const tabPanelTabChange = React.useRef(null);
  const [channelAttributeToBeViewed, setChanneelAttributeToBeViewed] =
    useState(undefined);

  useEffect(() => {
    if (marketingMessages && tabPanelTabChange) {
      tabPanelTabChange.current(undefined, defaultAttributeTabIndex);
    }
  }, [marketingMessages, defaultAttributeTabIndex]);

  const renderTabs = () => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let headers = marketingMessages?.marketingMsgChannels?.headers || [];
    let optouts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    let tagLines = marketingMessages?.marketingMsgChannels?.tagLines || [];
    let titles = [];
    let children = [];
    if (
      marketingMessages?.marketingMsgChannels?.channelType?.trim() ===
      channelTypesAndAttributes.email.name
    ) {
      titles = [
        `Subject Line(${subjectLines.length})`,
        `PreHeader(${preheaders.length})`,
        `Opt-out(${optouts.length})`,
        `Header(${headers.length})`,
      ];
      children = [
        renderMarketingMessages(subjectLines),
        renderMarketingMessages(preheaders),
        renderMarketingMessages(optouts),
        renderMarketingMessages(headers),
      ];
    } else if (
      marketingMessages?.marketingMsgChannels?.channelType?.trim() ===
      channelTypesAndAttributes.alert.name
    ) {
      titles = [`Tagline(${tagLines.length})`, `Opt-out(${optouts.length})`];
      children = [
        renderMarketingMessages(tagLines),
        renderMarketingMessages(optouts),
      ];
    } else if (
      marketingMessages?.marketingMsgChannels?.channelType?.trim() ===
      channelTypesAndAttributes.banner.name
    ) {
      titles = [`Tagline(${tagLines.length})`, `Opt-out(${optouts.length})`];
      children = [
        renderMarketingMessages(tagLines),
        renderMarketingMessages(optouts),
      ];
    }
    titles = titles.concat(
      `Global Attribute - CTA(${marketingMessages?.ctas?.length})`
    );
    children = children.concat(
      renderMarketingMessages(marketingMessages?.ctas)
    );

    return (
      <div className="searched-msgs-cntns">
        <div className="marketing-msg-filter">
          <div className="created-by-me-toggle">
            <div className="rb-created-by-options">
              <span className="lb-shw-creted-msgs">Created by</span>
              <div className="rb-created-by">
                <input
                  id="rb-created-by-me"
                  type="radio"
                  name="rb-created-by-me"
                  className="form-check-input"
                  checked={isCreatedByOthersToggleOn ? false : true}
                  onChange={(e) => {
                    filterByCreatedByOthersToggle(
                      e.target.checked ? false : true
                    );
                  }}
                />
                <label htmlFor="rb-created-by-me" className="form-check-label">
                  Me
                </label>
              </div>
              <div className="rb-created-by">
                <input
                  id="rb-created-by-others"
                  type="radio"
                  name="rb-created-by-others"
                  className="form-check-input"
                  checked={isCreatedByOthersToggleOn ? true : false}
                  onChange={(e) => {
                    filterByCreatedByOthersToggle(
                      e.target.checked ? true : false
                    );
                  }}
                />
                <label
                  htmlFor="rb-created-by-others"
                  className="form-check-label"
                >
                  Others
                </label>
              </div>
            </div>
          </div>
          <div className="status-drp-dwn">
            <DropDownWithLabel
              class="mt-0"
              options={{
                name: "Status",
                label: "Status",
                value: "All",
                items: ["All"],
                disabled: false,
              }}
            />
          </div>
        </div>
        <TabPanel
          titles={titles}
          children={children}
          tabPanelTabChange={tabPanelTabChange}
          notifyParentForTabChange={(newTabIndex) => {
            setDefaultAttributeIndex(newTabIndex);
          }}
        />
      </div>
    );
  };

  const renderMarketingMessages = (messages) => {
    return (
      <div className="row marketing-messages customScrollBar">
        <div className="col-md-6">
          {messages
            .slice(0, Math.ceil(messages.length / 2))
            .map((marketingMessage) => {
              return (
                <MarketingMessageCard
                  marketingMessage={marketingMessage}
                  startEditingMessage={startEditingMessage}
                  startPublishing={startPublishing}
                  view={(channelAttribute) => {
                    setChanneelAttributeToBeViewed(channelAttribute);
                  }}
                />
              );
            })}
        </div>
        <div className="col-md-6" style={{ paddingRight: "5px" }}>
          {messages
            .slice(Math.ceil(messages.length / 2))
            .map((marketingMessage) => {
              return (
                <MarketingMessageCard
                  marketingMessage={marketingMessage}
                  startEditingMessage={startEditingMessage}
                  startPublishing={startPublishing}
                  view={(channelAttribute) => {
                    setChanneelAttributeToBeViewed(() => {
                      return channelAttribute;
                    });
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        {!marketingMessages && (
          <div className={`marketingMessagesBody marketingMsgBg `}>
            <p className="text-center" style={{ opacity: "0.4" }}>
              Select Product, Campaign and Channel to begin
            </p>
          </div>
        )}

        {marketingMessages && renderTabs()}
        <CustomModal
          displayModal={channelAttributeToBeViewed !== undefined}
          hideModal={() => {
            setChanneelAttributeToBeViewed(undefined);
          }}
          title={"View Channel Attribute"}
          size="md"
          dilogClassName="assetImageModal"
          backdrop="false"
        >
          <button
            className="btn btn-link pl-0 btn-view-channel-attribute-close"
            onClick={() => {
              setChanneelAttributeToBeViewed(undefined);
            }}
          >
            <img src={modalCloseIcon} alt="" />
          </button>
          <div className="assetImageModalBlock custonScrollBar">
            <ViewChannelAttribute
              marketingMessage={channelAttributeToBeViewed}
            />
          </div>
        </CustomModal>
      </div>
    </>
  );
};

export default MarketingMessages;
