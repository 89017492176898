//Import React dependecies
import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

//Import styles
import "../../../styles/components/dams.scss";
import "../../../styles/components/ExistingTatics.scss";

const ContentModuleCard = (props) => {
  const { t } = useTranslation();
  const {
    module,
    colWidth = 3,
    moduleCardBtnActions,
    buttonList,
    isSelected = false,
    onModuleSelect,
    cannotExistWith,
  } = props;

  const getCardCssClass = () => {
    if (isSelected) {
      return "currentModuleTactics denovo-module-card-selected";
    } else if (cannotExistWith) {
      return "currentModuleTactics localized-module-card-error";
    } else {
      return "currentModuleTactics";
    }
  };

  return (
    <>
      <div className={`col-md-${colWidth} `} key={module.id}>
        <div
          className={getCardCssClass()}
          onClick={() => {
            if (onModuleSelect) {
              onModuleSelect(module);
            }
          }}
        >
          <div className="justify-content-between cm-modulename">
            <p className="modNameTitle mb-0">Module Name</p>
            <p className="moduleCodeBox">{module.moduleName}</p>
          </div>
          {/* {module.campaign && (
            <div>
              {" "}
              <p className="modNameTitle mb-0">Campaign</p>
              <p className="modNameText modNameTextTitle">{module.campaign}</p>
            </div>
          )} */}
          {module.coreCategory && (
            <p className="modCategoryText cm-category">
              <span>{module.coreCategory}</span>
            </p>
          )}

          {module.claim && (
            <div className="cm-claim">
              <p className="modNameTitle mb-0">{t("primaryClaim.name")}</p>
              <div className="modNameText mb-0">
                {module.claim && parse(module.claim)}
              </div>{" "}
            </div>
          )}

          <div className="moduleDetailsBrdr">
            <div
              className={`d-flex ${
                buttonList?.length > 1
                  ? "justify-content-between"
                  : "justify-content-center"
              } moduleDetailsBtnRow`}
            >
              {buttonList &&
                buttonList?.map((btn, index) => (
                  <button
                    key={index}
                    className="btn btn-link btnApprove pl-0 moduleActionBtn pr-0"
                    type="button"
                    disabled={!module.contentModuleUiUrl}
                    onClick={(e) => {
                      e.stopPropagation();
                      moduleCardBtnActions(btn.type, module);
                    }}
                  >
                    {btn.btnLabel}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentModuleCard;
