//import react dependency

import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

//import custom components
import CheckBox from "../../Uitls/CheckBox";

//import apis and utils

//import styles
import "../../styles/components/ExistingTatics.scss";

//import assets
import searchIcon from "../../images/masterTacticSearchIcon.svg";

const DamsFilter = ({
  brands,
  channels,
  markets,
  campaigns,
  selectedBrands,
  selectedChannels,
  selectedMarkets,
  selectedCampaigns,
  setSelectedBrands,
  setSelectedChannels,
  setSelectedMarkets,
  setSelectedCampaigns,
  applyFilters,
  resetFilters,
  setSearchMarketTerm,
  searchMarketTerm,
  removeSelectedCampaignforRemovedBrand,
  channelsRequired,
  shouldMarketAndCampaignFiltersBeHidden,
}) => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState("");
  //const [searchMarketTerm, setSearchMarketTerm] = useState("");
  const filterOptions = [
    {
      id: "brands",
      category: "Product/Brand",
      subCategory: brands || [],
    },
    channelsRequired
      ? {
          id: "channels",
          category: "Channel",
          subCategory: channels || [],
        }
      : undefined,
    !shouldMarketAndCampaignFiltersBeHidden()
      ? {
          id: "markets",
          category: "Market",
          subCategory: [],
        }
      : undefined,
    !shouldMarketAndCampaignFiltersBeHidden()
      ? {
          id: "campaigns",
          category: "Campaign",
          subCategory: campaigns || [],
        }
      : undefined,
  ];

  const marketSearchResults = !searchMarketTerm
    ? []
    : markets
        .map((item) => item.displayName)
        .filter((market) => {
          return market.toLowerCase().includes(searchMarketTerm.toLowerCase());
        });
  const selectedPanel = (selectedPanel) => {
    if (activePanel === selectedPanel) {
      setActivePanel("");
    } else {
      setActivePanel(selectedPanel);
    }
  };
  const onFilterUpdate = (event) => {
    const isSelected = event.target.checked;
    const panel = event.target.name;
    switch (panel) {
      case "brands":
        let brands = [...selectedBrands];
        if (isSelected) {
          brands.push(event.target.value);
        } else {
          const index = brands.indexOf(event.target.value);
          if (index > -1) {
            brands.splice(index, 1);
            setSelectedCampaigns(
              removeSelectedCampaignforRemovedBrand(event.target.value)
            );
          }
        }
        setSelectedBrands(brands);

        break;
      case "channels":
        let channels = [...selectedChannels];
        if (isSelected) {
          channels.push(event.target.value);
        } else {
          const index = channels.indexOf(event.target.value);
          if (index > -1) {
            channels.splice(index, 1);
          }
        }
        setSelectedChannels(channels);
        break;
      case "markets":
        let markets = [...selectedMarkets];
        if (isSelected) {
          markets.push(event.target.value);
        } else {
          const index = markets.indexOf(event.target.value);
          if (index > -1) {
            markets.splice(index, 1);
          }
        }
        setSelectedMarkets(markets);
        break;
      case "campaigns":
        let campaigns = [...selectedCampaigns];
        if (isSelected) {
          campaigns.push(event.target.value);
        } else {
          const index = campaigns.indexOf(event.target.value);
          if (index > -1) {
            campaigns.splice(index, 1);
          }
        }
        setSelectedCampaigns(campaigns);
        break;
      default:
        break;
    }
  };
  const shouldDisableFilterResetButton = () => {
    if (
      selectedBrands.length > 0 ||
      selectedChannels.length > 0 ||
      selectedMarkets.length > 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="filter-options custonScrollBar">
      <h1 className="filterBy">Filter By</h1>
      <Accordion>
        {filterOptions.map((filterOption) => {
          if (!filterOption) {
            return <></>;
          }
          let activeCount = 0;
          switch (filterOption.id) {
            case "brands":
              activeCount = selectedBrands.length;
              break;
            case "channels":
              activeCount = selectedChannels.length;
              break;
            case "markets":
              activeCount = selectedMarkets.length;
              break;
            case "campaigns":
              activeCount = selectedCampaigns.length;
              break;
            default:
              break;
          }
          return (
            <div id={filterOption.id}>
              {
                <div className="accordionTitle">
                  <Accordion.Toggle
                    variant="link"
                    eventKey={filterOption.id}
                    onClick={() => {
                      selectedPanel(filterOption.id);
                    }}
                  >
                    <span
                      className={
                        activePanel === filterOption.id
                          ? "displayActiveIcon activCategory"
                          : "displayActiveIcon inActivCategory"
                      }
                    >
                      {activePanel === filterOption.id ? "-" : "+"}
                    </span>
                    {
                      <span
                        className={
                          activePanel === filterOption.id
                            ? "activCategory"
                            : "inActivCategory"
                        }
                      >
                        {filterOption.category}
                      </span>
                    }
                    {activeCount === 0 ? "" : ` (${activeCount})`}
                  </Accordion.Toggle>
                </div>
              }
              <Accordion.Collapse eventKey={filterOption.id}>
                <Card.Body>
                  {/* For brands and channels */}

                  {(filterOption.id === "brands" ||
                    filterOption.id === "channels") &&
                    filterOption.subCategory.map((subCategory, index) => {
                      let isChecked;
                      if (filterOption.id === "brands") {
                        isChecked = selectedBrands.indexOf(subCategory) > -1;
                      } else {
                        isChecked = selectedChannels.indexOf(subCategory) > -1;
                      }

                      return (
                        <div className="contentBlock form-check" key={index}>
                          <CheckBox
                            label={subCategory}
                            isChecked={isChecked}
                            name={filterOption.id}
                            handleChange={(e) => {
                              onFilterUpdate(e);
                            }}
                            labelClassName="form-check-label filter-option"
                            checkboxClassName="form-check-input"
                          />
                        </div>
                      );
                    })}

                  {/* For markets */}
                  {filterOption.id === "markets" && (
                    <>
                      <div className="marketTacticsSearch searchTactic">
                        <div className="d-flex">
                          <img
                            src={searchIcon}
                            alt=""
                            className="searchIconTactic"
                          />
                          <input
                            id="search-market"
                            type="text"
                            placeholder="Search Market"
                            className="searchInputTactic"
                            value={searchMarketTerm}
                            onChange={(ev) => {
                              setSearchMarketTerm(ev.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          marketSearchResults.length > 0 ? " masterBox" : ""
                        }
                      >
                        <div className="marketCheckBox custonScrollBar">
                          {marketSearchResults.map((item) => {
                            let isChecked = selectedMarkets.indexOf(item) > -1;
                            return (
                              <div className="form-check">
                                <CheckBox
                                  label={item}
                                  isChecked={isChecked}
                                  name={filterOption.id}
                                  handleChange={(e) => {
                                    onFilterUpdate(e);
                                  }}
                                  labelClassName="form-check-label filter-option"
                                  checkboxClassName="form-check-input"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                  {/* For campaigns */}
                  {filterOption.id === "campaigns" &&
                    filterOption.subCategory.map((subCategory, index) => {
                      let isChecked;

                      isChecked = selectedCampaigns.indexOf(subCategory) > -1;

                      return (
                        <div className="contentBlock form-check" key={index}>
                          <CheckBox
                            label={subCategory}
                            isChecked={isChecked}
                            name={filterOption.id}
                            handleChange={(e) => {
                              onFilterUpdate(e);
                            }}
                            labelClassName="form-check-label filter-option"
                            checkboxClassName="form-check-input"
                          />
                        </div>
                      );
                    })}
                </Card.Body>
              </Accordion.Collapse>
              {<div className="lineHorizontal"></div>}
            </div>
          );
        })}
      </Accordion>

      <button
        className="resetFilterBtn"
        disabled={shouldDisableFilterResetButton()}
        onClick={() => {
          resetFilters();
        }}
      >
        {t("generalText.clearFilters").toUpperCase()}
      </button>
    </div>
  );
};

export default DamsFilter;
