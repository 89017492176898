import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import parse from "html-react-parser";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "../../Uitls/CheckBoxTwo";

const SupportingVisualLink = (props) => {
  const { t } = useTranslation();
  const {
    primaryClaim,
    supportingClaims,
    updateVisulLinkData,
    dependency,
    selectedLinkClaims,
    setVisualLinkModal,
    supportingVisualLinkModal,
  } = props;

  const [userSelectedDependency, setDependency] = useState(dependency);

  //const claims = selectedLinkClaims.map((claim) => claim);

  const [visulaLinkData, setVisualLinkData] = useState(selectedLinkClaims);
  const [showInfoClaims, setShowInfoClaims] = useState(false);

  const [hasUserSelectedPrimaryClaim, setHasUserSelectedPrimaryClaim] =
    useState(
      selectedLinkClaims.length !== 0 &&
        selectedLinkClaims.indexOf(primaryClaim.id) === -1
        ? false
        : true
    );

  const [hasUserSelectedSupportingClaim, setHasUserSelectedSupportingClaim] =
    useState(selectedLinkClaims.indexOf(primaryClaim.id) > -1 ? false : true);

  const wrapperRef = useRef(null);

  const handleChange = (e, claimID, type) => {
    if (e.target.checked) {
      if (type === "primary") {
        setHasUserSelectedPrimaryClaim(true);
        setHasUserSelectedSupportingClaim(false);
      } else if (type === "supporting") {
        setHasUserSelectedPrimaryClaim(false);
        setHasUserSelectedSupportingClaim(true);
      }
      const newDataVisualLink = [...visulaLinkData];
      if (
        newDataVisualLink.findIndex((link) => {
          return link === claimID;
        }) === -1
      ) {
        setVisualLinkData(newDataVisualLink.concat(claimID));
      }
    } else {
      //const value = e.target.value;
      const index = visulaLinkData.indexOf(claimID);
      const newVisulaLinkData = [...visulaLinkData];
      newVisulaLinkData.splice(index, 1);
      setVisualLinkData(newVisulaLinkData);

      if (type === "primary") {
        setHasUserSelectedPrimaryClaim(true);
        setHasUserSelectedSupportingClaim(true);
      } else if (type === "supporting") {
        if (
          newVisulaLinkData.length === 1 &&
          newVisulaLinkData[0].id === primaryClaim.id
        ) {
          setHasUserSelectedPrimaryClaim(true);
          setHasUserSelectedSupportingClaim(false);
        } else if (newVisulaLinkData.length === 0) {
          setHasUserSelectedPrimaryClaim(true);
          setHasUserSelectedSupportingClaim(true);
        }
      }
    }
  };

  const handleRadioButtonChange = (e) => {
    const dependency = e.target.value;
    setDependency(dependency);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisualLinkModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const showAllClaims = () => {
    setShowInfoClaims(!showInfoClaims);
  };
  //console.log(showInfoClaims);

  let isShowSupportingClaims = false;
  supportingClaims.forEach((item) => {
    const text = item.rowData[0].data[0] && item.rowData[0].data[0].atomText;
    if (text) {
      isShowSupportingClaims = true;
    }
  });

  return (
    <div
      className={
        showInfoClaims && showInfoClaims
          ? "visualLinkModal claimModalOpen"
          : "visualLinkModal"
      }
      ref={wrapperRef}
      style={
        showInfoClaims && showInfoClaims
          ? { width: "600px" }
          : { width: "265px" }
      }
    >
      <div className="row">
        <div
          className={
            showInfoClaims && showInfoClaims ? "col-6 pb-2" : "col-12 pb-2"
          }
        >
          <div className="dependencyBox">
            <p className="mb-1 dependTitle">Dependency</p>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="dependency"
                name="dependency"
                value={userSelectedDependency}
                onChange={(e) => handleRadioButtonChange(e)}
              >
                <FormControlLabel
                  value="Optional"
                  control={<Radio color="primary" />}
                  label="Optional"
                />
                <FormControlLabel
                  value="Mandatory"
                  control={<Radio color="primary" />}
                  label="Mandatory"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="linkBoxList custonScrollBar">
            <p className="dependTitle">Link to</p>
            <div className="linkListBlock">
              <div>
                {primaryClaim && (
                  <div>
                    <p className="dependTitle modalTitleMargin">
                      {t("primaryClaim.name")}
                    </p>
                    <Checkbox
                      isChecked={
                        visulaLinkData.findIndex((link) => {
                          return link === primaryClaim.id;
                        }) > -1
                      }
                      disabled={!hasUserSelectedPrimaryClaim === true}
                      label={primaryClaim.claim?.toString()}
                      handleChange={(e) =>
                        handleChange(e, primaryClaim.id, "primary")
                      }
                      checkboxClassName="form-check-input mt-1"
                      labelClassName={
                        !hasUserSelectedPrimaryClaim === true
                          ? "visualLinkLabelDisabled"
                          : ""
                      }
                    />
                  </div>
                )}
              </div>

              <div>
                {isShowSupportingClaims && (
                  <p className="dependTitle modalTitleMargin">
                    Supporting Claim(s)
                  </p>
                )}

                {supportingClaims.map((item, index) => {
                  // const text =
                  //   item.rowData[0].data[0] && item.rowData[0].data[0].atomText;
                  const supClaimId = item.rowData[4].id;
                  const checked =
                    visulaLinkData.findIndex((linkedId) => {
                      return linkedId === supClaimId;
                    }) > -1;
                  return (
                    <React.Fragment key={index}>
                      {item.rowData[0].data.map((item, index) => {
                        return (
                          item.atomText && (
                            <div key={index}>
                              <Checkbox
                                isChecked={checked}
                                disabled={
                                  !hasUserSelectedSupportingClaim === true
                                }
                                label={item.atomText}
                                handleChange={(e) =>
                                  handleChange(e, supClaimId, "supporting")
                                }
                                checkboxClassName="form-check-input mt-1"
                                labelClassName={
                                  !hasUserSelectedSupportingClaim === true
                                    ? "visualLinkLabelDisabled"
                                    : ""
                                }
                              />
                            </div>
                          )
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              // const data = visulaLinkData.map((visualLink) => {

              //   // selectedLinkClaims.forEach((claim) => {
              //   //   if (claim.claimText === visualLink) {
              //   //     moduleClaimId = claim.moduleClaimId;
              //   //   }
              //   // });
              //   return {
              //     moduleClaimId: visualLink,
              //     claimText: visualLink,
              //   };
              // });

              updateVisulLinkData(visulaLinkData, userSelectedDependency);
              supportingVisualLinkModal();
            }}
          >
            APPLY
          </button>
          <button
            className="btn btn-link btn-block"
            onClick={() => {
              showAllClaims();
            }}
          >
            {showInfoClaims && showInfoClaims ? "Hide Info" : "Show Info"}
          </button>
        </div>
        {showInfoClaims && (
          <div className="RightColumn col-6 pb-2">
            <div className="righColumnClaimsScroll custonScrollBar">
              {primaryClaim && (
                <div>
                  <p className="dependTitle modalTitleMargin">
                    {t("primaryClaim.name")}
                  </p>
                  {parse(primaryClaim.claim)}
                </div>
              )}

              {supportingClaims.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.rowData[0].data[0] &&
                      item.rowData[0].data[0].atomText && (
                        <p className="dependTitle modalTitleMargin">
                          Supporting Claim {index + 1}
                        </p>
                      )}
                    {item.rowData[0].data.map((item, index) => (
                      <div key={index}>{parse(item.atomText)}</div>
                    ))}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SupportingVisualLink;
