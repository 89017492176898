import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import LogoutCTA from "../Pages/LogoutCTA";
import TranslateHeader from "../components/Headers/HeaderTranslateModule";
import TranslateModuleNav from "../components/Headers/TranslateModuleNav";
import errComponentToRender from "../Uitls/apiErrorHandling";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import Email from "../components/TranslateFlow/Channels/TranslateEmailElements";
import Banners from "../components/TranslateFlow/Channels/TranslateBanners";
import Alerts from "../components/TranslateFlow/Channels/TranslateAlerts";
import CTA from "../components/TranslateFlow/Channels/TranslateCTAs";
import TranslateClaimsGenericModal from "../components/TranslateFlow/TranslateClaims/TranslateClaimGenericModal";
import CustomModal from "../components/customModal";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import AddComment from "../components/TranslateFlow/AddComment";

import { getTranslationJobType, routes } from "../Uitls/Util";
import {
  getModuleDetailsById,
  addNewComment,
  saveTranslatedModule,
} from "../services/apis";

import "../styles/pages/CreateModuleStep4.scss";
import "../styles/pages/TranslateClaims.scss";

const TranslateChannel = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [showOriginalToggle, setShowOriginalToggle] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [module, setModule] = useState({});
  const [options, setOptions] = useState({});
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [emailElements, setEmailElements] = useState(undefined);
  const [banners, setBanners] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [ctas, setCtas] = useState([]);
  const [showTranslateModal, setShowTranslateModal] = useState(false);
  const [dataTypeSelectedForTranslation, setDtaTypeSelectedForTranslation] =
    useState("channel");
  const [dataInputForTranslation, setDataInputForTranslation] = useState([]);

  //For Comment
  const [showAddCommentBox, setShowAddCommentBox] = useState(false);
  const [commentObject, setCommentObject] = useState(undefined);

  //Source module toggle
  const [sourceModuleToggle, updateSourceModuleToggle] = useState(false);
  const [hasSourceRefModuleId, setHasSourceRefModuleId] = useState(false);

  const getModuleIdFromLocalStorage = () => {
    //Fetch the Module Details here
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let moduleId = undefined;
    if (props && props.location && props.location.state) {
      moduleId = props.location.state.moduleId;
    } else {
      moduleId =
        moduleInfoFromLocalStorage &&
        moduleInfoFromLocalStorage.translateModuleId;
    }
    return moduleId;
  };
  useEffect(() => {
    const moduleId = getModuleIdFromLocalStorage();
    if (!moduleId) {
      return;
    }
    setOptions({ channels: ["Email", "Banner", "Alert"], others: ["CTA"] });
    setSelectedOption("email");
    fetchModuleDetails(moduleId);
  }, []);

  const fetchModuleDetails = (moduleId, isCurrent = true) => {
    setIsLoading(true);
    getModuleDetailsById(moduleId || module.id, (response, err, errCode) => {
      setIsLoading(false);
      if (response) {
        setModule(response.data);
        handleModuleData(response.data);
        if (isCurrent && response.data.translationRefModuleId) {
          setHasSourceRefModuleId(true);
        }
        return;
      }
      const errCoponent = errComponentToRender(errCode);
      toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
    });
  };

  const handleModuleData = (response) => {
    if (!response) {
      return;
    }

    const channelsData = response.moduleChannels;
    if (channelsData) {
      let subjectLines = [];
      let preheaders = [];
      let banners = [];
      let alerts = [];
      let emailHeaders = [];
      if (channelsData.length <= 0) {
        setEmailElements({ subjectLines, preheaders, emailHeaders });
        setBanners(banners);
        setAlerts(alerts);
      } else {
        for (const channel of channelsData) {
          switch (channel.channelType) {
            case "Email":
              subjectLines = [...channelsData].filter((channel) => {
                return channel.textType === "Subject";
              });

              preheaders = [...channelsData].filter((channel) => {
                return channel.textType === "PreHeader";
              });

              emailHeaders = [...channelsData].filter((channel) => {
                return channel.textType === "Header";
              });
              break;
            case "Banner":
              banners = banners.concat(channel);
              break;
            case "Alert":
              alerts = alerts.concat(channel);
              break;
            default:
              break;
          }
        }
        setEmailElements({ subjectLines, preheaders, emailHeaders });
        setBanners(banners);
        setAlerts(alerts);
      }
    } else {
      setEmailElements({ subjectLines: [], preheaders: [], emailHeaders: [] });
      setBanners([]);
      setAlerts([]);
    }
    const ctaData = response.moduleCtas;
    if (ctaData && ctaData.length > 0) {
      handleCTAdata(ctaData);
    } else {
      setCtas([]);
    }
  };

  const handleCTAdata = (ctaData = []) => {
    setCtas(ctaData);
  };

  const handleProceed = (option) => {
    if (option) {
      return;
    }
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    // if (selectedPages.translateComponents.includes("business-rules")) {
    if (true) {
      //remove it once api businessrules added to trans;ateCOmponents array in dashboard api
      pageRedirect(routes.translateAdditionalInfo);
    } else {
      pageRedirect(routes.moduleDetails);
    }
  };

  const pageRedirect = (pageURL) => {
    history.push({
      pathname: pageURL,
      state: {
        moduleId: getModuleIdFromLocalStorage(),
        flowType: "previewTranslate",
      },
    });
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleLanguageToggleBtn = () => {
    const newToggleState = !showOriginalToggle;
    setShowOriginalToggle(newToggleState);
    if (newToggleState) {
      fetchModuleDetails(module.moduleIdEnglish, false);
    } else {
      fetchModuleDetails(getModuleIdFromLocalStorage());
    }
  };

  const removeEmptyObjects = (newArray) => {
    let data = [...newArray];
    let idices = [];
    data.forEach((element, index) => {
      if (
        element.headerText === "" &&
        element.restrictionForUse === "" &&
        element.rules === ""
      ) {
        return idices.push(index);
      }
    });
    let filteredData = data.filter((val, index) => !idices.includes(index));
    return filteredData;
  };

  const getItemsCount = (type) => {
    switch (type.toLowerCase()) {
      case "email":
        let count = 0;
        if (emailElements) {
          if (emailElements.subjectLines) {
            count += emailElements.subjectLines.length;
          }
          if (emailElements.preheaders) {
            count += emailElements.preheaders.length;
          }
          if (emailElements.emailHeaders) {
            let filteredArray = removeEmptyObjects(emailElements.emailHeaders);
            count += filteredArray.length;
          }
        }
        return count;
      case "banner":
        return banners.length;
      case "alert":
        return alerts.length;
      case "cta":
        const actualCTAs = ctas.filter((cta) => {
          return cta.url && cta.url.trim() !== "";
        });
        return actualCTAs.length;
      default:
        return 0;
    }
  };

  const setAppropriateCssClass = (index, parentType) => {
    var sectionChanels = document.getElementById(parentType);
    var lis = sectionChanels.getElementsByClassName("channel");
    for (var i = 0; i < lis.length; i++) {
      var current = document.getElementsByClassName("selected-channel");
      if (current && current.length > 0) {
        current[0].className = current[0].className.replace(
          "selected-channel",
          ""
        );
      }
    }
    lis[index].className = "channel selected-channel";
  };

  const handleSelect = (type) => {
    if (!type) {
      return;
    }
    if (options.channels.length > 0 || options.channels.others.length > 0) {
      let index = options.channels.indexOf(type);
      if (index !== -1 && index < options.channels.length) {
        setAppropriateCssClass(index, "channels");
      } else {
        index = options.others.indexOf(type);
        if (index !== -1 && index < options.others.length) {
          setAppropriateCssClass(index, "others");
        }
      }
    }
    setSelectedOption(type.toString().toLowerCase());
  };

  const renderChannels = () => {
    const channels = options.channels || [];
    return (
      channels.length > 0 &&
      channels.map((option, index) => {
        let classToBeApplied = "channel";
        if (index === 0) {
          classToBeApplied = "channel selected-channel";
        }
        return (
          <li
            className={classToBeApplied}
            data-toggle="list"
            role="tab"
            key={option}
            onClick={() => {
              handleSelect(option);
            }}
          >
            <Row className="align-items-center mt-1 mb-1 row-channel">
              <Col>
                <div>{option}</div>
              </Col>
              <Col>
                <div className="count-catch-line">
                  {" "}
                  {getItemsCount(option)} Items
                </div>
              </Col>
            </Row>
          </li>
        );
      })
    );
  };

  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const saveTranslated = async () => {
    let channelsPayload = []
      .concat(emailElements.subjectLines)
      .concat(emailElements.preheaders)
      .concat(emailElements.emailHeaders)
      .concat(banners)
      .concat(alerts);
    channelsPayload = [...channelsPayload].map((channel) => {
      let isAllSelected = true;
      if (
        channel.channelType.toLowerCase() === "email" &&
        channel.textType.toLowerCase() === "header"
      ) {
        channel.moduleChannelSubtexts = [...channel.moduleChannelSubtexts].map(
          (header) => {
            header.subTextType = header.subtextType;
            return header;
          }
        );
        channel.moduleChannelSubtexts.forEach((subText) => {
          if (subText.translate === "1") {
            isAllSelected = false;
          }
        });
        if (isAllSelected) {
          channel.translate = "2";
        } else {
          channel.translate = "1";
        }
      }
      return channel;
    });
    const ctaPayload = ctas;
    const payload = {
      id: module.id,
      name: module.name,
      pageNumber: module.pageNumber,
      moduleChannels: channelsPayload,
      moduleCtas: ctaPayload,
    };
    setIsLoading(true);
    saveTranslatedModule(module.id, payload, (response, err, errorCode) => {
      setIsLoading(false);
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        showError(errCoponent);
        fetchModuleDetails();
      } else {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
      }
    });
  };

  const handleCloseGenericModal = () => {
    setShowTranslateModal(false);
    fetchModuleDetails();
  };

  const handleSave = () => {
    setShowTranslateModal(false);
    saveTranslated();
  };
  const initiateTranslationFor = (data, type) => {
    setDataInputForTranslation(() => {
      setShowTranslateModal(true);
    });
    setDataInputForTranslation(data);
    setDtaTypeSelectedForTranslation(type);
  };

  const renderOthers = () => {
    const others = options.others || [];
    return (
      others.length > 0 &&
      others.map((option) => {
        return (
          <li
            className="channel"
            data-toggle="list"
            role="tab"
            key={option}
            onClick={() => {
              handleSelect(option);
            }}
          >
            <Row className="align-items-center mt-1 mb-1 row-channel">
              <Col>
                <div>{option}</div>
              </Col>
              <Col>
                <div className="count-catch-line">
                  {" "}
                  {getItemsCount(option)} Items
                </div>
              </Col>
            </Row>
          </li>
        );
      })
    );
  };

  const isAnyToggleOn = () => {
    return showOriginalToggle || sourceModuleToggle;
  };
  const renderRightPane = () => {
    switch (selectedOption) {
      case "email":
        return (
          <Email
            emailElements={emailElements}
            isAnyToggleOn={isAnyToggleOn()}
            initiateTranslationFor={initiateTranslationFor}
            addComment={addComment}
            module={module}
            getCommentsData={fetchModuleDetails}
          />
        );
      case "banner":
        return (
          <Banners
            banners={banners}
            isAnyToggleOn={isAnyToggleOn()}
            initiateTranslationFor={initiateTranslationFor}
            addComment={addComment}
            module={module}
            getCommentsData={fetchModuleDetails}
          />
        );
      case "alert":
        return (
          <Alerts
            alerts={alerts}
            isAnyToggleOn={isAnyToggleOn()}
            initiateTranslationFor={initiateTranslationFor}
            addComment={addComment}
            module={module}
            getCommentsData={fetchModuleDetails}
          />
        );
      case "cta":
        return (
          <CTA
            ctas={ctas}
            isAnyToggleOn={isAnyToggleOn()}
            initiateTranslationFor={initiateTranslationFor}
            addComment={addComment}
            module={module}
            getCommentsData={fetchModuleDetails}
          ></CTA>
        );
      default:
        return <div />;
    }
  };

  const isLastPageToReviewOrTranslate = () => {
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    ).translateComponents;
    if (selectedPages[selectedPages.length - 1] === "channel") {
      return true;
    }
    return false;
  };

  const getProceedButtonTitle = () => {
    if (isLastPageToReviewOrTranslate()) {
      const jobType = getTranslationJobType();
      if (jobType && jobType.trim().toLowerCase() === "self") {
        return i18next.t("translated.preview").toUpperCase();
      }
    }
    return undefined;
  };

  const showAddCommentsModal = (show) => {
    setShowAddCommentBox(show);
    if (!show) {
      setCommentObject(undefined);
    }
  };

  const addComment = (commentObject) => {
    setCommentObject(() => {
      showAddCommentsModal(true);
    });
    setCommentObject(commentObject);
  };

  const saveComment = (commentText) => {
    if (commentObject) {
      commentObject["comment"] = commentText;
      commentObject["moduleId"] = module.id;
    }
    if (commentObject) {
      setIsLoading(true);
      addNewComment(commentObject, (response, errMessage, errCode) => {
        setIsLoading(false);
        if (response) {
          fetchModuleDetails();
        } else {
          const errCoponent = ErrComponentToRender(errCode);
          toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
        }
        showAddCommentsModal(false);
      });
    }
  };
  const handleSourceModuleToggle = () => {
    const updatedToggleState = !sourceModuleToggle;
    updateSourceModuleToggle(updatedToggleState);
    if (updatedToggleState) {
      fetchModuleDetails(module.translationRefModuleId, false);
    } else {
      fetchModuleDetails(getModuleIdFromLocalStorage());
    }
  };
  return (
    <>
      <LogoutCTA />
      <div className="trasnlateHeaderBg">
        <TranslateHeader />
        <TranslateModuleNav
          btnProceed={
            getProceedButtonTitle() || i18next.t("generalText.proceed")
          }
          btnClose={i18next.t("generalText.close")}
          btnSave={i18next.t("generalText.save")}
          disableProceed={false}
          handleProceed={handleProceed}
          handlePageClose={handleClose}
          handlePageSave={handleSave}
          pageIndex={2}
          toggle={showOriginalToggle}
          handleLanguageToggleBtn={handleLanguageToggleBtn}
          moduleName={module.name || ""}
          moduleCode={module.moduleCode || ""}
          sourceLanguage={module.translatedFrom || ""}
          targetLanguage={module.language || ""}
          shouldHaveMultipleOptions={
            isLastPageToReviewOrTranslate() ? true : false
          }
          proceedOptions={
            isLastPageToReviewOrTranslate()
              ? ["Revision Required", "Approve"]
              : undefined
          }
          hasSourceModuleId={hasSourceRefModuleId}
          toggleSourceModule={sourceModuleToggle}
          handleSourceModuleToggle={handleSourceModuleToggle}
        />
      </div>
      <Container fluid>
        <CircularProgressBar isloading={isloading} />
        <Row className=" row mt-3 ml-0 mr-0 mb-4 add-channels-content">
          <Col className="col-md-3 pageChannelsCol">
            <Row className="mt-3 ml-0 mr-0 mb-4">
              <span className="section-head">Channels</span>
            </Row>
            <Row>
              <div className="section">
                <ul id="channels">{renderChannels()}</ul>
              </div>
            </Row>

            <Row className="mt-3 ml-0 mr-0 mb-4">
              <span className="section-head">Others</span>
            </Row>
            <Row>
              <div className="section">
                <ul id="others">{renderOthers()}</ul>
              </div>
            </Row>
          </Col>

          <Col className="pageContentCol">
            <div>{renderRightPane()}</div>
          </Col>
        </Row>
        <CustomModal
          displayModal={showTranslateModal}
          hideModal={handleCloseGenericModal}
          size="xl"
        >
          <TranslateClaimsGenericModal
            handleClose={handleCloseGenericModal}
            sourceLanguage={module.translatedFrom}
            targetLanguage={module.language}
            dataInput={dataInputForTranslation}
            saveTranslated={handleSave}
            dataType={dataTypeSelectedForTranslation}
          />
        </CustomModal>
        <CustomModal
          displayModal={showAddCommentBox}
          hideModal={showAddCommentsModal}
          title=""
          size="lg"
        >
          <AddComment
            selectedText={commentObject?.selectedText}
            handleClose={showAddCommentsModal}
            saveComment={saveComment}
          />
        </CustomModal>
      </Container>
    </>
  );
};

export default TranslateChannel;
