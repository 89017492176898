import React, { useState, useEffect } from "react";
import "../../styles/components/MarketingMessages.scss";
import MarketingMessagesSearch from "./MarketingMessagesSearch";
import MarketingMessages from "./MarketingMessages";
import MarketingMessagesFooter from "./MarketingMessagesFooter";
import {
  channelTypesAndAttributes,
  damsSources,
  marketingMessageSourceType,
  getCurrentUser,
  getAllChannelAttributes,
  getCurrentUserID,
} from "../../Uitls/Util";
import CustomModal from "../customModal";
import CreateNewMarketingMessage from "./CreateNewMarketingMessage";
import {
  getBrandsAndCampaigns,
  searchMarketingMessages,
  publishMarketingMessageToDAMS,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { isCTA } from "./MarketingMessageCard";
import { isOptOut } from "../ContentMatrix/SelectableMarketingMessageCard";

const MarketingMessagesHome = (props) => {
  const [openCreateMarketMessageModal, setOpenCreateMarketMessageModal] =
    useState(false);
  const [marketingMsgTypeToBeCreated, setMarketingMsgTypeToBeCreated] =
    useState(undefined);
  const [brandsAndCampaignsData, setBrandsAndCampaignsData] = useState({});
  const [productList, setProductList] = useState([]);
  const [selectedChannelType, setSelectedChannelType] = useState(undefined);
  const [isCampaignSpecific, toggleIsCampaignSpecific] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const openCreateNewMessageModal = (type) => {
    setMarketingMsgTypeToBeCreated(type);
    setOpenCreateMarketMessageModal(true);
  };
  const [damsSource, setDamsSource] = useState(damsSources.veeva.name);
  const [marketingMessages, setMarketingMessages] = useState(undefined);
  const [filteredMarketingMessages, setFilteredMarketingMessages] =
    useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [defaultAttributeTabIndex, setDefaultAttributeIndex] = useState(0);

  //EDit Marketing Message
  const [marketingMsgToBeEdited, setMarketingMsgToBeEdited] =
    useState(undefined);
  const [isCreatedByOthersToggleOn, setCreatedByOthersToggleOn] =
    useState(false);

  useEffect(() => {
    fetchBrandsAndCampaigns();
  }, []);

  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        setBrandsAndCampaignsData(response.data);
        const data = response.data.map((item) => item.brandName);
        setProductList(data);
      }
    });
  };

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const highlightTab = (selectedChannelType, selectedChannelAttribute) => {
    let attributes = [];
    switch (selectedChannelType) {
      case channelTypesAndAttributes.email.name:
        attributes = Object.values(channelTypesAndAttributes.email.attributes);
        break;
      case channelTypesAndAttributes.alert.name:
        attributes = Object.values(channelTypesAndAttributes.alert.attributes);
        break;
      case channelTypesAndAttributes.banner.name:
        attributes = Object.values(channelTypesAndAttributes.banner.attributes);
        break;
      default:
        break;
    }
    let index = attributes.findIndex((attribute) => {
      return attribute === selectedChannelAttribute;
    });
    if (index === -1) {
      index = 0;
    }
    setDefaultAttributeIndex(index);
  };

  const hideopenCreateNewMessageModal = (
    updateOnClose,
    selectedProduct,
    selectedCampaign,
    selectedChannelType,
    selectedChannelAttribute
  ) => {
    if (marketingMsgToBeEdited) {
      startEditingMessage(undefined, false);
    }
    if (updateOnClose) {
      setSelectedProduct(selectedProduct);
      if (selectedCampaign && selectedCampaign) {
        toggleIsCampaignSpecific(true);
        setTimeout(function () {
          setSelectedCampaign(selectedCampaign);
        }, 0);
      } else {
        toggleIsCampaignSpecific(false);
      }
      setSelectedChannelType(selectedChannelType);
      startSearchingMarketingMessages(
        selectedProduct,
        selectedCampaign,
        selectedChannelType,
        "",
        updateOnClose
      );
      highlightTab(selectedChannelType, selectedChannelAttribute);
    }
    setOpenCreateMarketMessageModal(undefined);
  };

  const startSearchingMarketingMessages = (
    brand,
    campaign,
    channelType,
    searchText,
    shouldRetainLastHighlightTab = false
  ) => {
    setIsloading(true);
    const payload = {
      brand,
      campaign,
      channelType,
      searchText,
    };
    searchMarketingMessages(payload, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        if (!shouldRetainLastHighlightTab) {
          setDefaultAttributeIndex(0);
        }
        setMarketingMessages(response.data);
        filterByCreatedByOthersToggle(isCreatedByOthersToggleOn, response.data);
        return;
      }
      const errCoponent = ErrComponentToRender(errCode);
      showError(errCoponent);
    });
  };

  const resetMarketingMessagesSearch = () => {
    setMarketingMessages(undefined);
    setFilteredMarketingMessages(undefined);
  };

  // ___________________________ EDIT MESSAGE ______________________________
  const startEditingMessage = (marketingMessage, editFlag = true) => {
    setMarketingMsgToBeEdited(() => {
      openCreateNewMessageModal(editFlag);
    });
    setMarketingMsgToBeEdited(marketingMessage);
  };

  //FILTER BY ME/OTHERS
  const filterByCreatedByOthersToggle = (
    isCreatedByOthersOn = isCreatedByOthersToggleOn,
    messages = marketingMessages
  ) => {
    setCreatedByOthersToggleOn(isCreatedByOthersOn);
    let data = JSON.parse(JSON.stringify(messages));
    let ctas = data.ctas;
    ctas = [...ctas]?.filter((cta) => {
      return isCreatedByOthersOn
        ? cta.createdBy !== getCurrentUserID()
        : cta.createdBy === getCurrentUserID();
    });
    data.ctas = ctas;
    let channelAttributes = data.marketingMsgChannels;
    let subjectlines = [...channelAttributes.subjectLines]?.filter(
      (subjectLine) => {
        return isCreatedByOthersOn
          ? subjectLine.createdBy !== getCurrentUserID()
          : subjectLine.createdBy === getCurrentUserID();
      }
    );
    channelAttributes.subjectLines = subjectlines;
    let headers = [...channelAttributes.headers]?.filter((header) => {
      return isCreatedByOthersOn
        ? header.createdBy !== getCurrentUserID()
        : header.createdBy === getCurrentUserID();
    });
    channelAttributes.headers = headers;
    let preheaders = [...channelAttributes.preHeaders]?.filter((preheader) => {
      return isCreatedByOthersOn
        ? preheader.createdBy !== getCurrentUserID()
        : preheader.createdBy === getCurrentUserID();
    });
    channelAttributes.preHeaders = preheaders;
    let optOuts = [...channelAttributes.optOuts]?.filter((optout) => {
      return isCreatedByOthersOn
        ? optout.createdBy !== getCurrentUserID()
        : optout.createdBy === getCurrentUserID();
    });
    channelAttributes.optOuts = optOuts;
    let tagLines = [...channelAttributes.tagLines]?.filter((tagLine) => {
      return isCreatedByOthersOn
        ? tagLine.createdBy !== getCurrentUserID()
        : tagLine.createdBy === getCurrentUserID();
    });
    channelAttributes.tagLines = tagLines;
    setFilteredMarketingMessages(data);
  };

  //UPDATE CARD AFTER PUBLISHING TO DAMS
  const updateCardAfterPublishing = (marketingMessage, newUpdates) => {
    const data = JSON.parse(JSON.stringify(marketingMessages));
    if (!isCTA(marketingMessage)) {
      const messages = data.marketingMsgChannels;
      let key = "";
      let messagesToBeUpdated = [];
      switch (marketingMessage.attribute.toLowerCase()) {
        case getAllChannelAttributes().subjectLine.toLowerCase():
          messagesToBeUpdated = messages.subjectLines;
          key = "subjectLines";
          break;
        case getAllChannelAttributes().preheader.toLowerCase():
          messagesToBeUpdated = messages.preHeaders;
          key = "preHeaders";
          break;
        case getAllChannelAttributes().header.toLowerCase():
          messagesToBeUpdated = messages.headers;
          key = "headers";
          break;
        case getAllChannelAttributes().optOut.toLowerCase():
          messagesToBeUpdated = messages.optOuts;
          key = "optOuts";
          break;
        case getAllChannelAttributes().tagLine.toLowerCase():
          messagesToBeUpdated = messages.tagLines;
          key = "tagLines";
          break;
        default:
          break;
      }
      let found = messagesToBeUpdated.find((message) => {
        return marketingMessage.mktMsgId === message.mktMsgId;
      });
      if (found) {
        found.damsId = newUpdates.damsId;
        found.documentNumber = newUpdates.documentNumber;
        found.mlrStatus = newUpdates.mlrStatus;
        messages[key] = messagesToBeUpdated;
      }
    } else {
      const ctas = data.ctas;
      let found = ctas.find((message) => {
        return marketingMessage.mktMsgId === message.mktMsgId;
      });
      if (found) {
        found.damsId = newUpdates.damsId;
        found.documentNumber = newUpdates.documentNumber;
        found.mlrStatus = newUpdates.mlrStatus;
      }
    }
    setMarketingMessages(data);
    filterByCreatedByOthersToggle(isCreatedByOthersToggleOn, data);
  };

  //---------------------------- PUBLISH MARKETING MESSAGE -------------------
  const startPublishing = (marketingMessage) => {
    setIsloading(true);
    let marketingMessageType =
      marketingMessage.channel.toLowerCase() ===
        channelTypesAndAttributes.email.name.toLowerCase() ||
      marketingMessage.channel.toLowerCase() ===
        channelTypesAndAttributes.banner.name.toLowerCase() ||
      marketingMessage.channel.toLowerCase() ===
        channelTypesAndAttributes.alert.name.toLowerCase()
        ? "channel"
        : "global";
    publishMarketingMessageToDAMS(
      marketingMessage.mktMsgId,
      marketingMessageType,
      marketingMessage.dams,
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          notifyPublishSucess(
            marketingMessage,
            marketingMessageType,
            response.data
          );
          updateCardAfterPublishing(marketingMessage, response.data);
          highlightTab(
            selectedChannelType,
            isOptOut()
              ? getAllChannelAttributes().optOut
              : marketingMessage.attribute
          );
          return;
        }
        notifyPublishFailure();
      }
    );
  };

  const notifyPublishSucess = (
    marketingMessage,
    marketingMessageType,
    data
  ) => {
    const date = new Date();
    toast.success(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationTitle">
          Submission to{" "}
          {marketingMessage.dams.charAt(0).toUpperCase() +
            marketingMessage.dams.slice(1)}{" "}
          Successful
        </p>
        <p className="notificationText">
          Veeva Document ID : {data.documentNumber}
        </p>
        <p className="notificationText">
          {" "}
          {marketingMessageType.charAt(0).toUpperCase() +
            marketingMessageType.slice(1)}{" "}
          Attribute ID : {marketingMessage.id}
        </p>
        <p className="notificationText">Submitted on : {date.toString()}</p>
        <p className="notificationText">Submitted by : {getCurrentUser()}</p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: 5000,
      }
    );
  };

  const notifyPublishFailure = () => {
    toast.error(
      <>
        <p className="notificationHeading"></p>
        <p className="notificationText">DAMS Submission Un-successful</p>
        <p className="notificationText">Please, try again</p>
      </>,
      {
        position: "top-right",
        autoClose: 3000,
      }
    );
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div className="mmContentContainer">
        <div style={{ textAlign: "end", marginRight: "-16px" }}>
          By Campaign
          <Switch
            className="filterRightMargin mr-2"
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={isCampaignSpecific}
            onChange={(e) => {
              toggleIsCampaignSpecific(e.target.checked);
            }}
          />
        </div>
        <MarketingMessagesSearch
          setSelectedChannelType={setSelectedChannelType}
          selectedChannelType={selectedChannelType}
          isCampaignSpecific={isCampaignSpecific}
          startSearchingMarketingMessages={startSearchingMarketingMessages}
          resetMarketingMessagesSearch={resetMarketingMessagesSearch}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
        />
        <div className="divider"></div>
        <MarketingMessages
          marketingMessages={filteredMarketingMessages}
          startEditingMessage={startEditingMessage}
          defaultAttributeTabIndex={defaultAttributeTabIndex}
          setDefaultAttributeIndex={setDefaultAttributeIndex}
          startPublishing={startPublishing}
          isCreatedByOthersToggleOn={isCreatedByOthersToggleOn}
          setCreatedByOthersToggleOn={setCreatedByOthersToggleOn}
          filterByCreatedByOthersToggle={filterByCreatedByOthersToggle}
        />
        <MarketingMessagesFooter
          hadnleCreateNewMessage={openCreateNewMessageModal}
          setDamsSource={setDamsSource}
          damsSource={damsSource}
        />
      </div>
      <CustomModal
        displayModal={openCreateMarketMessageModal}
        hideModal={hideopenCreateNewMessageModal}
        title={""}
        size="lg"
        dilogClassName="alertModalSmall"
        keyboard={false}
        enforceFocus={false}
      >
        <CreateNewMarketingMessage
          hideopenCreateNewMessageModal={hideopenCreateNewMessageModal}
          productList={productList}
          brandsAndCampaignsData={brandsAndCampaignsData}
          damsSource={damsSource}
          marketingMessage={marketingMsgToBeEdited}
          marketingMsgTypeToBeCreated={marketingMsgTypeToBeCreated}
        />
      </CustomModal>
    </>
  );
};

export default MarketingMessagesHome;
