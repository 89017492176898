import React, { useEffect, useState } from "react";
import parseHtml from "html-react-parser";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

//Import custom components
import CircularProgressBar from "../../Uitls/CircularProgressBar";

//import utils and apis
import { getSourceModuleLanguages } from "../../services/apis";
import CustomModal from "../customModal";
import TranslateModule from "../translateModule";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import { toast } from "react-toastify";
import { htmlToString } from "../../Uitls/Util";

const SelectSourceModuleLanguage = ({
  moduleIds,
  sourceModulesList,
  showModal,
  handleProceed,
}) => {
  const [sourceModules, setSourceModules] = useState([]);

  useEffect(() => {
    setSourceModules(sourceModulesList);
  }, [sourceModulesList]);

  const handleModuleSourceLang = (sourceModuleId, languageIndex) => {
    let modules = [...sourceModules];
    modules = [...modules].map((module) => {
      if (module.moduleId === sourceModuleId) {
        module.languages = module.languages.map((lang, index) => {
          if (index === languageIndex) {
            lang.isSelected = true;
          } else {
            lang.isSelected = false;
          }
          return lang;
        });
      }
      return module;
    });

    setSourceModules(modules);
  };

  const getModulesDetails = () => {
    if (
      !moduleIds ||
      moduleIds.length === 0 ||
      !sourceModules ||
      sourceModules.length === 0
    ) {
      return;
    }
    let moduleDetails = [];
    moduleIds.forEach((obj) => [
      sourceModules.forEach((sourceModule) => {
        if (obj.sourceModuleId === sourceModule.moduleId) {
          const selectedLanguage = sourceModule.languages.filter((lang) => {
            return lang.isSelected === true;
          })[0];
          const module = {
            moduleId: obj.moduleId,
            refSourceModId: selectedLanguage.moduleId,
            language: selectedLanguage.language,
            sourceModuleId: obj.sourceModuleId,
          };
          moduleDetails.push(module);
        }
      }),
    ]);
    return moduleDetails;
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <p className="sourceLanguageHeading">
            {i18next.t("generalText.selectSourceModuleLanguage")} ({" "}
            {sourceModules.length} modules)
            <span>{i18next.t("generalText.modulesWithMultipleLangFound")}</span>
          </p>
        </div>
      </div>
      <div className="sourceLangContainer customScrollBar">
        {sourceModules.map((sourceModule, sourceModuleIndex) => (
          <div
            className="row no-gutters align-items-center sourceLanguageCardBrdr"
            key={sourceModule.moduleId}
          >
            <div className="col-md-7">
              <div className="sourceLanguageCard">
                <p className="LanguageCardMdCode">{sourceModule.moduleCode}</p>
                <p className="LanguageCardMdName">
                  Module name <span>{sourceModule.moduleName}</span>
                </p>
                {sourceModule.promotionalClaim && (
                  <div className="LanguageCardMdClaim">
                    Promotional Claim
                    <div className="LanguageCardMdClaimText">
                      {sourceModule.promotionalClaim &&
                        htmlToString(sourceModule.promotionalClaim)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-5 p-4">
              {sourceModule.languages.map((language, langIndex) => {
                return (
                  <div class="form-check" key={language.moduleId}>
                    <input
                      class="form-check-input"
                      type="radio"
                      checked={language.isSelected}
                      value={language.language}
                      onChange={(e) => {
                        handleModuleSourceLang(
                          sourceModule.moduleId,
                          langIndex
                        );
                      }}
                    />
                    <label
                      class="form-check-label"
                      for={`${language.language}`}
                    >
                      {language.language}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="row bsRulesBtnRow align-items-center">
        <div className="col-md-6">
          <button
            className="btn btn-link p-0"
            onClick={() => {
              showModal(false);
            }}
          >
            CANCEL
          </button>
        </div>
        <div className="col-md-6 text-right">
          <button
            className="btn btn-primary btnNextModule"
            onClick={() => {
              handleProceed(moduleIds, getModulesDetails());
            }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </div>
  );
};
export default SelectSourceModuleLanguage;
