import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useState } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({
  titles,
  children,
  colWidth = 12,
  needBottomBorder = false,
  otherTabs,
  tabPanelTabChange,
  notifyParentForTabChange,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (tabPanelTabChange) {
      tabPanelTabChange.current = handleChange;
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (notifyParentForTabChange) {
      notifyParentForTabChange(newValue);
    }
  };

  return (
    <div className={`${classes.root} ${colWidth}`}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{
            borderBottom: `${needBottomBorder ? "solid 1px #e1e1e1" : "none"}`,
          }}
        >
          {titles &&
            titles.map((title) => {
              return <Tab label={title} />;
            })}
          {otherTabs &&
            otherTabs.length > 0 &&
            otherTabs.map((tab) => {
              return tab;
            })}
        </Tabs>
      </AppBar>
      {children &&
        children.map((child, index) => {
          return (
            <TabPanel value={value} index={index}>
              {child}
            </TabPanel>
          );
        })}
    </div>
  );
}
