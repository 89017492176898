import React, { useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { routes } from "../Uitls/Util";

import "../styles/pages/Dashboard.scss";
import ChannelGridpreview from "../components/ChannelGrid/ChannelGridpreview";

function TemplatePreivew() {
  useEffect(() => {}, []);
  //const navigate = useNavigate();
  const history = useHistory();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const obj = {
    name: useQuery().get("name") || "",
    brand: useQuery().get("brand") || "",
    pdfURL: useQuery().get("pdfUrl"),
    mlrId: useQuery().get("mlrId"),
    campaign: useQuery().get("campaign"),
  };

  const { t } = useTranslation();

  const goToDamsScreen = () => {
    history.push({
      pathname: routes.uploadAsset,
    });
  };

  return (
    <>
      {useQuery().get("name") && (
        <ChannelGridpreview
          pdfPreviewData={obj}
          goToDamsScreen={goToDamsScreen}
        />
      )}
    </>
  );
}

export default TemplatePreivew;
