import React, { useState, useRef, useEffect, useCallback } from "react";
import { Form, Formik } from "formik";
import i18next from "i18next";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import "../styles/components/DropDownWithLabel.scss";

const DropDownWithLabel = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.options);
  }, [props]);
  const getValue = () => {
    if (
      options &&
      options.items &&
      options.items.length > 0 &&
      options.value &&
      options.items.indexOf(options.value) !== -1
    ) {
      return options.value;
    } else if (
      options &&
      options.isDataTypeObject &&
      options.items &&
      options.items.length > 0 &&
      options.value &&
      options.items.findIndex((el) => el.value === options.value) !== -1
    ) {
      return options.value;
    }
    return "";
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className} />;
  };
  const [selectedValues, setSelectedValues] = useState(getValue());

  return (
    <FormControl
      disabled={options.disabled || false}
      className={`${props.dropdownclass || "drop-down-with-label"}`}
      variant="outlined"
      fullWidth={true}
    >
      <InputLabel htmlFor={options.name}>{options.name}</InputLabel>
      <Select
        value={getValue()}
        className={props.class}
        onChange={options.handleChange}
        renderValue=""
        label={options.label}
        name={options.name}
        id={options.name}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
            pointerEvents: "none",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={iconComponent}
      >
        {options.items &&
          options.items.length > 0 &&
          options.items.map((item) => {
            return (
              <MenuItem
                value={options.isDataTypeObject ? item.value : item}
                id={options.isDataTypeObject ? item.value : item}
                key={options.isDataTypeObject ? item.value : item}
                disabled={options.isDataTypeObject && item.isSelected}
              >
                {/* {props.tick === "denuovoCampaign" && (
                  <Checkbox
                    key={item.value}
                    checked={checkedState[item.value]}
                    onChange={(event) => handleChange(event, item)}
                  />
                )} */}

                {options.isDataTypeObject ? item.value : item}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default DropDownWithLabel;
