import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomModal from "../customModal";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import { createGrid, getCSEmailCognitoToken } from "../../services/apis";

import "../../styles/components/ChannelGridHome.scss";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import ModulesSelection from "./ModulesSelection";
import ChannelGridMarketingMessages from "./ChannelGridMessages";
import PreviewChannelGrid from "./PreviewChannelGrid";
import ChannelGridpreview from "./ChannelGridpreview";
import ChannelGridViewPdf from "./ChannelGridViewPdf";
import { RepeatOne } from "@material-ui/icons";
import ChannelTemplates from "./Templates/ChannelTemplates";
import { showError, templateTypes } from "../../Uitls/Util";

export const channelGridStepCount = 6;

const ChannelGridHome = (props) => {
  const {
    modules,
    selectedAssetDetails,
    selectedChannel,
    cancelCreatingGrid,
    assetDataType,
    sourceType,
    setactivePage,
    activePage,
    channelGridToView,
    updateGridCout,
  } = props;
  const { t } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [selectedMMessges, setSelectedMMessages] = useState(undefined);
  const [selectedModule, setSelectedModule] = useState(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [selectedSample, setSelectedSample] = useState(undefined);
  useEffect(() => {
    setSelectedModule(modules);
  }, []);

  const generatePayloadForPDF = () => {
    setactivePage("3");
  };
  const [pdfGenerate, setPdfGenerate] = useState(false);
  const [channelGridId, setChannelGridId] = useState("");

  const showSucessModal = () => {
    setPdfGenerate(true);
  };

  const hideHideSucessModal = () => {
    setPdfGenerate(false);
  };
  const generatePdfErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  const initateChannelGrid = () => {
    setIsloading(true);
    let subjectLines = selectedMMessges?.subjectLines || [];
    let preheaders = selectedMMessges?.preHeaders || [];
    let ctas = selectedMMessges?.ctas || [];
    let optOuts = selectedMMessges?.optOuts || [];
    let subjectLineIds = [];
    subjectLines.forEach((element) => subjectLineIds.push(element.mktMsgId));
    let preheaderIds = [];
    preheaders.forEach((element) => preheaderIds.push(element.mktMsgId));
    let optoutIds = [];
    optOuts.forEach((element) => optoutIds.push(element.mktMsgId));
    let ctaIds = [];
    ctas.forEach((element) => ctaIds.push(element.mktMsgId));
    let moduleIds = [];
    selectedModule.forEach((element) => moduleIds.push(element.id));

    let payload = {
      userEmailId: window.localStorage.getItem("userEmail"),
      channelType: selectedChannel,
      brand: selectedAssetDetails.brand,
      campaign: selectedAssetDetails.campaignName,
      moduleIds: moduleIds,
      subjectLineIds: subjectLineIds,
      preheaderIds: preheaderIds,
      optoutIds: optoutIds,
      ctaIds: ctaIds,
      template: {
        // templateId: selectedTemplate.template_id,
        templateName: selectedTemplate.name,
        templateImgUrl: selectedTemplate.fullscreen || selectedTemplate.image,
        damsId: selectedTemplate.dams?.pdf_no,
        nextId: selectedTemplate.serial_no,
        damsUrl: selectedTemplate.dams?.pdf_link,
      },
      email: {
        //emailId: selectedSample.dams?.template_id,
        emailName: selectedSample.name,
        emailImgUrl: selectedSample.fullscreen || selectedSample.image,
        damsId: selectedSample.dams?.html_no,
        nextId: selectedSample.serial_no,
        damsUrl: selectedSample.dams?.html_link,
      },
    };

    createGrid(
      payload,
      assetDataType,
      sourceType,
      (response, err, errorCode) => {
        if (err) {
          setIsloading(false);
          const errCoponent = errComponentToRender(errorCode);
          generatePdfErr(errCoponent);
        } else {
          setIsloading(false);
          setChannelGridId(response.data);
          showSucessModal();
          updateGridCout();
        }
      }
    );
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div className="channelGridHome">
        {activePage === "1" && (
          <ModulesSelection
            modules={modules}
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            cancelCreatingGrid={cancelCreatingGrid}
            proceedToMarketingMessages={() => {
              setactivePage("2");
            }}
            selectedModule={selectedModule}
            setSelectedModule={setSelectedModule}
            channelGridStepCount={channelGridStepCount}
            stepNumber={2}
            categoriesFilterList={selectedAssetDetails.categoriesFilterList.map(
              (el) => {
                return { displayName: el };
              }
            )}
          />
        )}
        {activePage === "2" && (
          <ChannelGridMarketingMessages
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            sourceType={sourceType}
            backToPrviousScreen={() => {
              setSelectedMMessages(undefined);
              setactivePage("1");
            }}
            setSelectedMMessages={setSelectedMMessages}
            assetDataType={assetDataType}
            selectedMMessges={selectedMMessges}
            cancelCreatingGrid={cancelCreatingGrid}
            handleProceed={() => {
              setactivePage("3");
            }}
            channelGridStepCount={channelGridStepCount}
            stepNumber={3}
          />
        )}
        {activePage === "3" && (
          <ChannelTemplates
            templateType={templateTypes.emailTemplate}
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            backToPrviousScreen={() => {
              setactivePage("2");
              setSelectedTemplate(undefined);
            }}
            cancelCreatingGrid={cancelCreatingGrid}
            selectedMMessges={selectedMMessges}
            selectedModule={selectedModule}
            handleProceed={() => {
              setactivePage("4");
            }}
            channelGridStepCount={channelGridStepCount}
            stepNumber={4}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        {activePage === "4" && (
          <ChannelTemplates
            templateType={templateTypes.sampleEmail}
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            backToPrviousScreen={() => {
              setactivePage("3");
              setSelectedSample(undefined);
            }}
            cancelCreatingGrid={cancelCreatingGrid}
            handleProceed={() => {
              setactivePage("5");
            }}
            channelGridStepCount={channelGridStepCount}
            stepNumber={5}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            selectedSample={selectedSample}
            setSelectedSample={setSelectedSample}
          />
        )}
        {activePage === "5" && (
          <PreviewChannelGrid
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            backToPrviousScreen={() => {
              setactivePage("4");
            }}
            cancelCreatingGrid={cancelCreatingGrid}
            selectedMMessges={selectedMMessges}
            selectedModule={selectedModule}
            initateChannelGrid={initateChannelGrid}
            selectedTemplate={selectedTemplate}
            selectedSample={selectedSample}
            channelGridStepCount={channelGridStepCount}
            stepNumber={6}
          />
        )}

        {activePage === "6" && (
          <ChannelGridpreview
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            channelType={selectedChannel}
            backToPrviousScreen={() => {
              setactivePage("4");
            }}
            cancelCreatingGrid={cancelCreatingGrid}
            selectedMMessges={selectedMMessges}
            selectedModule={selectedModule}
          />
        )}
        {activePage === "7" && (
          <ChannelGridViewPdf
            brand={selectedAssetDetails?.brand}
            campaignName={selectedAssetDetails?.campaignName}
            cancelCreatingGrid={cancelCreatingGrid}
            channelGridToView={channelGridToView}
          />
        )}
      </div>
      <CustomModal
        displayModal={pdfGenerate}
        hideModal={hideHideSucessModal}
        title={""}
        size="sm"
        dilogClassName="alertModalSmall"
        keyboard={false}
      >
        <div className="channelGriMsgRow1">Generation is in Progress !</div>
        <div className="channelGriMsgRow2">
          Submission Document ID <strong>{channelGridId}</strong>
        </div>
        <div className="channelGriMsgRow3">
          You will be notified via email once it is completed
        </div>
        <div>Clicking on 'GOT IT' will re-direct you to Home Screen.</div>
        <div className="text-right mt-4">
          <button
            className="btn btn-primary channelGriMsgRowBtn"
            onClick={() => cancelCreatingGrid()}
          >
            Got it
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ChannelGridHome;
