//Import react dependencies
import React from "react";

//Import Custom components
import TabPanel from "../../../Uitls/TabPanel";
import Optouts from "./Optouts";
import Preheaders from "./Preheaders";
import SubjectLines from "./SubjectLines";

const ChannelAttrsTabs = ({ channelAttrElements }) => {
  console.log("channnel attrs", channelAttrElements);
  const subjectlines = (
    <div>
      <SubjectLines subjectlinesElements={channelAttrElements.subjectLines} />
    </div>
  );

  const preheaders = (
    <div>
      <Preheaders preheaders={channelAttrElements.preHeaders} />
    </div>
  );

  const optouts = (
    <div>
      <Optouts optouts={channelAttrElements.optOuts} />
    </div>
  );

  const getCount = (data) => {
    if (data?.length > 0) {
      return `(${data?.length})`;
    } else {
      return "";
    }
  };

  const renderTabs = () => {
    let titles = [];
    let children = [];
    titles = [
      `Subject Line ${getCount(channelAttrElements?.subjectLines)}`,
      `PreHeader ${getCount(channelAttrElements?.preHeaders)}`,
      `Opt-out ${getCount(channelAttrElements?.optOuts)}`,
    ];
    children = [subjectlines, preheaders, optouts];

    return <TabPanel titles={titles} children={children} />;
  };

  return <>{renderTabs()}</>;
};

export default ChannelAttrsTabs;
