import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import errComponentToRender from "../Uitls/apiErrorHandling";
import CustomizedSelects from "./DropDown";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCoreClaimCategories } from "../services/apis";

import "../styles/components/CoreClaimCategoryList.scss";
import CustomChip from "./Chip";
import CoreClaim from "./CoreClaim";
import CircularProgressBar from "../Uitls/CircularProgressBar";

const CoreClaimCategoryList = (props) => {
  const { t } = useTranslation();
  const { isloading, setIsloading } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [coreClaimCategories, setcoreClaimCategories] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState("");
  //const [topCategoryMessages, setTopCategoryMessages] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [shouldUpdateParent, setShouldUpdateParent] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [shouldfetchCoreClaimcategories, setShouldfetchCoreClaimcategories] = useState(false)

  //Fetch All the CoreClaim Categories and Claims for a specific brand
  const fetchcoreClaimCategories = (brand, sourceType) => {
    setIsloading(true);
    getCoreClaimCategories(
      (response, err, errorCode) => {
        if (err) {
          //const errCoponent = errComponentToRender(errorCode);
          //setShowErrorMsg(errCoponent);
          // categoriesErr(errCoponent);
          // setErrorMsg(true);
        } else {
          setcoreClaimCategories(response.data);
          setLoaded(true);
        }
        setIsloading(false);
      },
      brand,
      sourceType
    );
  };

  // useEffect(() => {
  //   setTopCategoryMessages(props.coreClaimCategories);
  // }, []);

  const categoriesErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useEffect(() => {
    // else{
    //   setcoreClaimCategories(props.selectedCoreClaimCategories);
    // }
    if (props.selectedCoreClaimCategories) {
      //setSelectedCategories(addIdsToDefaultToBeSelectedCategories(props.selectedCoreClaimCategories));
      setSelectedCategories(props.selectedCoreClaimCategories);
    }
    if (props.selectedClaim) {
      setSelectedClaim(props.selectedClaim);
    }
  }, [props]);

  useEffect(() => {
    //fetchcoreClaimCategories(props.brand, props.sourceType);
    if (
      !loaded &&
      !isloading &&
      props.brand
      //props.coreClaimCategories.length === 0
      //&& (!props.coreClaimCategories || props.coreClaimCategories.length <= 0)
    ) {
      fetchcoreClaimCategories(props.brand, props.sourceType);
    }
  }, [props.brand]); //[props.brand, props.coreClaimCategories]);

  useEffect(() => {
    removeCommonCategories();
  }, [selectedCategories, coreClaimCategories]);

  //If top Message category i.e. default selected categories and core claim categories
  //have something in common
  //then replace common with the one which are available in coreCliam Categories
  //because they have ids associated and default selected does not have ids associated
  const removeCommonCategories = () => {
    if (selectedCategories.length > 0 && coreClaimCategories.length > 0) {
      for (const selCatIndex in selectedCategories) {
        const commonCategories = coreClaimCategories.filter((cat) => {
          return (
            cat.claimCategory === selectedCategories[selCatIndex].claimCategory
          );
        });
        if (commonCategories && commonCategories.length > 0) {
          const common = commonCategories[0];
          const extSelCats = selectedCategories;
          extSelCats[selCatIndex] = common;
          setSelectedCategories(extSelCats);
        }
      }
    }
  };

  // useEffect(() => {
  //     updateParent()
  // }, [selectedClaim])

  useEffect(() => {
    updateParent();
  }, [selectedClaim, shouldUpdateParent]);

  // const addIdsToDefaultToBeSelectedCategories = (categories) => {
  //     //Check if they already have ids
  //     let catsWithId =  categories.map((cat) => {
  //         if(cat.claimCategoryId === undefined || cat.claimCategoryId === null){
  //           return {claimCategoryId : '', claimCategory : cat}
  //         }
  //         return cat
  //     })

  //     return catsWithId

  // }
  const onCategorySelect = (selectedCategory) => {
    if (selectedCategory && selectedCategories && selectedCategory !== "base") {
      const categoryToBeAdded = {
        //claimCategoryId: selectedCategory.id,
        claimCategory: selectedCategory.name,
      };
      //Check if already added
      let alreadyAdded = false;
      for (const categoryAdded of selectedCategories) {
        if (
          // categoryToBeAdded.claimCategoryId === "" &&
          categoryAdded.claimCategory === categoryToBeAdded.claimCategory
        ) {
          alreadyAdded = true;
          break;
        }
        // else if (
        //   categoryAdded.claimCategoryId === categoryToBeAdded.claimCategoryId
        // ) {
        //   alreadyAdded = true;
        //   break;
        // }
      }
      if (!alreadyAdded) {
        setSelectedCategories(selectedCategories.concat(categoryToBeAdded));
      }

      setShouldUpdateParent(!shouldUpdateParent);
    }
  };

  const onRemoveSelectedCategory = (cat) => {
    const categoryToBeDeleted = {
      claimCategoryId: cat.id,
      claimCategory: cat.name,
    };

    const cats = selectedCategories.filter((category) => {
      if (
        !categoryToBeDeleted.claimCategoryId ||
        categoryToBeDeleted.claimCategoryId === ""
      ) {
        return category.claimCategory !== categoryToBeDeleted.claimCategory;
      }
      return category.claimCategoryId !== categoryToBeDeleted.claimCategoryId;
    });
    setSelectedCategories(cats);

    // if(getCoreClaims(cats).indexOf(selectedClaim) == -1){
    //   setSelectedClaim({})
    // }
    const foundClaims = getCoreClaims(cats)
      .filter((claim) => {
        if (claim) {
          return claim;
        }
      })
      .filter((claim) => {
        return claim.id === selectedClaim.id; //|| claim.claim === selectedClaim.claim
      });
    if (!foundClaims || foundClaims.length <= 0) {
      setSelectedClaim({});
    }
    setShouldUpdateParent(!shouldUpdateParent);
  };

  const onCoreClaimSelect = (selectedCoreClaim) => {
    setSelectedClaim(selectedCoreClaim);
    setShouldUpdateParent(!shouldUpdateParent);
  };

  const updateParent = () => {
    if (props.onCoreClaimSelection) {
      props.onCoreClaimSelection({
        categories: selectedCategories,
        claim: selectedClaim,
      });
    }
  };

  // const resetCoreClaimCategorySelection = () => {
  //   if (selectedCategories && selectedCategories.length > 0) {
  //     setSelectedCategories([]);
  //   }
  //   setSelectedCategories(topCategoryMessages);
  // };

  const getCoreClaims = (categories = selectedCategories) => {
    //let claims = [props.selectedClaim] || []
    let claims = [];
    if (categories && categories.length > 0) {
      for (const selectedCat of categories) {
        if (coreClaimCategories && coreClaimCategories.length > 0) {
          claims = claims.concat(
            coreClaimCategories
              .filter((claimCategory) => {
                return (
                  // claimCategory.claimCategoryId ===
                  //   selectedCat.claimCategoryId ||
                  claimCategory.claimCategory === selectedCat.claimCategory
                );
              })
              .map((category) => {
                return category.claims;
              })[0]
          );
        }
      }
    }

    // const claims = coreClaimCategories[0].coreclaims
    //   .filter((claim) => {
    //     return selectedCategories.indexOf(claim.category) !== -1;
    //     //return (selectedCategories && selectedCategories.length > 0) ? selectedCategories.indexOf(claim.category) !== -1 : undefined
    //   })
    //   .map((claim) => {
    //     return claim.claims;
    //   });

    // if (claims.length > 0) {
    //   return claims;
    // }
    return claims;
  };

  const renderCategories = () => {
    return (
      <CustomChip
        data={selectedCategories.map((obj) => {
          return { id: obj.claimCategoryId, name: obj.claimCategory };
        })}
        handleDelete={onRemoveSelectedCategory}
      />
    );
  };
  // array.replace(/,/g, ", ");

  const renderCategoryDropDown = () => {
    // if (props.shouldFreeze) {
    //   const categories = [...selectedCategories]
    //     .map(cat => {
    //       return cat.claimCategory;
    //     })
    //     .toString()
    //     .replace(/,/g, ", ");
    //   return <div className="frozen-categories">{categories}</div>;
    // } else {
    return (
      <CustomizedSelects
        data={coreClaimCategories.map((coreClaimCategory) => {
          return {
            //id: coreClaimCategory.claimCategoryId,
            name: coreClaimCategory.claimCategory,
          };
        })}
        default={i18next.t("coreClaim.selectCoreClaimCategory")}
        handleSelection={onCategorySelect}
      />
    );
    //}
  };

  return (
    // coreClaimCategories.length > 0 &&
    <>
      <CircularProgressBar isloading={isloading} />
      <Row className="align-items-center">
        <Col md={2} className="catLabelTitle">
          {i18next.t("coreClaim.coreClaimCategory")}(Theme)
        </Col>
        <Col md={10}>{renderCategoryDropDown()}</Col>
      </Row>
      <Row>
        <Col md={2}></Col>
        <Col md={10}>
          <div>
            {selectedCategories &&
              selectedCategories.length > 0 &&
              renderCategories()}
          </div>
        </Col>
      </Row>

      <CoreClaim
        data={getCoreClaims()}
        // shouldFreeze={props.shouldFreeze}
        onCoreClaimSelect={onCoreClaimSelect}
        selectedCoreClaim={selectedClaim}
      />
    </>
  );
};
export default CoreClaimCategoryList;
