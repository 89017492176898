import React, { useRef, useEffect, useState } from "react";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ViewPdf from "../../components/MlrFeedback/RenderMlrFeedback";

const ViewReferencePdf = (props) => {
  const { pdfUrl, getPdfwidth } = props;
  const viewer = useRef(null);
  const [isloading, setIsloading] = useState(true);

  const getNumberOfPages = (totalPages) => {
    props.upDatePageNumber(totalPages);
  };
  return (
    <div>
      {/* <CircularProgressBar isloading={isloading} /> */}
      <ViewPdf
        pdfFileUrl={pdfUrl} //{bufferArray}
        pdfWidth={getPdfwidth}
        showPageNumber={true}
        getNumberOfPages={getNumberOfPages}
        referenceFlag={true}
      />
    </div>
  );
};

export default ViewReferencePdf;
