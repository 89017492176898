import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ExistingTacticsAsset from "../ExistingTacticsAsset";
import ModuleGenericDetails from "./ModuleGenericDetails";
import Modules from "./Modules";
import TabPanel from "../../../Uitls/TabPanel";
import {
  getCurrentUser,
  getCurrentUserID,
  routes,
  showError,
} from "../../../Uitls/Util";
import GeenricInformationModule from "../GeenricInformationModule";
import CustomModal from "../../../components/customModal";
import ModuleCard from "../ModuleCard";
import ModulePlaceHolder from "../../ModulePlaceHolder/ModulePlaceHolder";
import ContentMatrixTab from "../../ContentMatrix/ContentMatrixTab";
import ChannelGrid from "../../ChannelGrid/ChannelGrid";
import HarvestedGenericDetails from "../Harvested/HarvestedGenericDetails";
import BusinessRulesValidation from "../../ContentMatrix/BusinessRulesValidation";
import ChannelSelectionComponent from "../../ContentMatrix/ChannelSelectionModal";

import InfoIcon from "../../../images/Info-selection.svg";
import attachBrandStyleGuide from "../../../images/attach_brand_style_guide.svg";
import attachedBrandStyleGuide from "../../../images/attached_brand_style_guide.svg";
import removeBrandStyleGuide from "../../../images/red_delete.svg";
import {
  getModuleDetailsById,
  uploadBrandStyleGuideline,
  deleStyleGuidelines,
} from "../../../services/apis";
import CircularProgressBar from "../../../Uitls/CircularProgressBar";
import ViewBrandStyleGuideline from "../../ModuleEditor/ViewBrandStyleGuideline";

const ModularisedTactics = ({
  titleType,
  assetAndModuleDetails,
  selectedAssetDetails = {},
  setSelectedAssetDetails,
  setSelectedAssetHandler,
  filteredPages,
  brandsList,
  getCampaignList,
  refreshSelectAsset,
  shouldShowOnlyOriginalModules,
  isDamsFlow,
  saveGenericInfo,
  genericData,
  addLatestMatrixData,
  setIschannelGrid,
  selectedChannel,
  setSelectedChannel,
  setactivePage,
  setChannelGridToView,
  startStylizingModule,
  closestylizeChannelSelectionModal,
  proceedToCtaSelectionScreen,
  setAssetsAndModules,
}) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [selectedModule, setSelectedModule] = useState(undefined);
  const [isBRulesValidationOpen, setBRulesValidationOpen] = useState(false);

  //For newly introduced channel selection modal
  const [channelSelectionModal, setChannelSelectionModal] = useState(false);
  const [channelGridSelectionModal, setChannelGridSelectionModal] =
    useState(false);

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    setSelectedModule(undefined);
    setCreatedByMeToggleOn(true);
  }, [selectedAssetDetails]);
  const [isCreatedByMeToggleOn, setCreatedByMeToggleOn] = useState(true);
  const [brandStyleGuidelineToBeViewed, setBrandStyleGuidelineToBeViewed] =
    useState(undefined);
  const [showDeleteGuidelinesPopup, setShowDeleteGuidelinesPopup] =
    useState(false);
  const selectModule = (module) => {
    if (module) {
      if (module.moduleType.trim().toLowerCase() === "original") {
        module["audience"] = selectedAssetDetails.audience;
        module["assetName"] = selectedAssetDetails.assetName;
        module["channelType"] = selectedAssetDetails.channelType;
        module["campaignName"] = selectedAssetDetails.campaignName;
        module["market"] = selectedAssetDetails.market;
        module["description"] = selectedAssetDetails.description;
        module["dosing"] = selectedAssetDetails.dosing;
        module["isi"] = selectedAssetDetails.isi;
        module["pi"] = selectedAssetDetails.pi;
        module["mg"] = selectedAssetDetails.mg;
        if (isDamsFlow) {
          module["assetType"] = selectedAssetDetails.assetType;
        }
      } else if (
        module.moduleType.trim().toLowerCase() === "cloned" ||
        module.moduleType.trim().toLowerCase() === "localized"
      ) {
        module["campaignName"] = module.campaign;
        module["assetName"] = selectedAssetDetails.assetName;
      }
      module["audienceSegment"] = selectedAssetDetails.audienceSegment;
      setSelectedModule(module);
    }
  };

  const closeGenericDetails = () => {
    setSelectedModule(undefined);
  };

  const [createModuleList, setCreateModuleList] = useState([]);

  const onClickCreateCard = () => {
    setCreateModuleList(
      filterModules("original").filter(
        (module) => module.moduleType.trim().toLowerCase() === "original"
      )
    );

    setCreateModuleModal(true);
  };
  const [createModuleModal, setCreateModuleModal] = useState(false);
  const handleCloseCreateModuleModal = () => {
    setCreateModuleModal(false);
  };
  const assets = useMemo(
    () => (
      <div className="col-md-3 masterTactic">
        <ExistingTacticsAsset
          titleType={titleType}
          assetAndModuleDetails={assetAndModuleDetails}
          selectedAssetDetails={selectedAssetDetails}
          setSelectedAssetHandler={setSelectedAssetHandler}
        />
      </div>
    ),
    [assetAndModuleDetails, selectedAssetDetails, titleType]
  );

  const filterModules = (parameter = "original") => {
    const modules = selectedAssetDetails.modules;
    let filteredModules = [];
    if (modules && modules.length > 0) {
      filteredModules = modules.filter((module) => {
        const moduleType = module.moduleType.trim().toLowerCase();
        if (parameter === "original") {
          if (shouldShowOnlyOriginalModules) {
            return moduleType === "original";
          }
          return moduleType === "original" || moduleType === "cloned";
        }
        //return only approved translated modules
        if (moduleType.toLowerCase() === "translated") {
          return (
            moduleType === parameter &&
            module.translationStatus?.toLowerCase() === "complete"
          );
        }

        return moduleType === parameter;
      });
    }
    return filteredModules;
  };

  const startBRulesValidation = (flag) => {
    // setBRulesValidationOpen(() => {
    //   tabPanelTabChange.current(undefined, flag ? 0 : 2);
    // });
    setBRulesValidationOpen(flag);
  };

  const filterByCreatedByMeToggle = (modules) => {
    return modules.filter((module) => {
      return isCreatedByMeToggleOn
        ? module.createdBy === getCurrentUserID()
        : module.createdBy !== getCurrentUserID();
    });
  };

  const showCreatedByFilters = () => {
    return (
      <div className="row">
        <div className="col-md-6"></div>

        <div className="col-md-6">
          <div className="rb-modules-created-by-options">
            <span className="lb-shw-creted-msgs">Show Modules created by</span>
            <div className="rb-created-by">
              <input
                id="rb-created-by-me"
                type="radio"
                name="rb-created-by-me"
                className="form-check-input"
                checked={isCreatedByMeToggleOn ? true : false}
                onChange={(e) => {
                  setCreatedByMeToggleOn(true);
                }}
              />
              <label htmlFor="rb-created-by-me" className="form-check-label">
                Me
              </label>
            </div>
            <div className="rb-created-by">
              <input
                id="rb-created-by-others"
                type="radio"
                name="rb-created-by-others"
                className="form-check-input"
                checked={!isCreatedByMeToggleOn ? true : false}
                onChange={(e) => {
                  setCreatedByMeToggleOn(false);
                }}
              />
              <label
                htmlFor="rb-created-by-others"
                className="form-check-label"
              >
                Others
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const originalAndBasedOnModules = useMemo(
    () => (
      <>
        {!isBRulesValidationOpen && (
          <>
            {showCreatedByFilters()}
            <div
              className={`${
                selectedModule ? "col-md-12 denovo-modules-v-list" : "col-md-12"
              } tacticsModules`}
            >
              <Modules
                asset={selectedAssetDetails}
                assetModules={filterByCreatedByMeToggle(
                  filterModules("original")
                )}
                createNewModule={onClickCreateCard}
                selected={selectedModule}
                selectModule={selectModule}
                mainModules={filterByCreatedByMeToggle(
                  filterModules("original").filter(
                    (module) =>
                      module.moduleType.trim().toLowerCase() === "original"
                  )
                )}
                auxModules={filterByCreatedByMeToggle(filterModules("cloned"))}
                title="Modules"
                auxTitle="Based On Modules"
                filteredPages={filteredPages}
                typeofModule="original"
                disableCreateNew={
                  isDamsFlow && !selectedAssetDetails.genericInfoSavedFlag
                    ? true
                    : false
                }
                createNewCardMessage={
                  isDamsFlow && !selectedAssetDetails.genericInfoSavedFlag
                    ? "Please fill up the Generic information to begin creating modules"
                    : undefined
                }
                stylizeModule={startStylizingModule}
                assetDataType={selectedAssetDetails?.assetDataType}
              />
            </div>
          </>
        )}
      </>
    ),
    [
      selectedAssetDetails,
      filteredPages,
      selectedModule,
      isBRulesValidationOpen,
      isCreatedByMeToggleOn,
    ]
  );
  const translatedModules = useMemo(
    () => (
      <div
        className={`${
          selectedModule ? "col-md-12 denovo-modules-v-list" : "col-md-12"
        } tacticsModules`}
      >
        <Modules
          asset={selectedAssetDetails}
          assetModules={filterModules("translated")}
          createNewModule={onClickCreateCard}
          selected={selectedModule}
          selectModule={selectModule}
          title="Translated Modules"
          filteredPages={filteredPages}
          typeofModule="translated"
          assetDataType={selectedAssetDetails?.assetDataType}
        />
      </div>
    ),
    [selectedAssetDetails, filteredPages, selectedModule]
  );
  const localizedOnModules = useMemo(
    () => (
      <div
        className={`${
          selectedModule ? "col-md-12 denovo-modules-v-list" : "col-md-12"
        } tacticsModules`}
      >
        <Modules
          asset={selectedAssetDetails}
          assetModules={filterModules("localized")}
          createNewModule={onClickCreateCard}
          selected={selectedModule}
          selectModule={selectModule}
          title="Localized Modules"
          filteredPages={filteredPages}
          typeofModule="Localize"
          assetDataType={selectedAssetDetails?.assetDataType}
        />
      </div>
    ),
    [selectedAssetDetails, filteredPages, selectedModule]
  );

  const contentMatrices = useMemo(
    () => (
      <>
        <ContentMatrixTab
          matrixData={
            selectedAssetDetails && selectedAssetDetails.contentMatrices
          }
          brand={selectedAssetDetails?.brand}
          campaignName={selectedAssetDetails?.campaignName}
          modules={selectedAssetDetails.modules}
          assetDataType={selectedAssetDetails?.assetDataType}
          sourceType={selectedAssetDetails?.sourceType}
          setChannelSelectionModal={setChannelSelectionModal}
          isBRulesValidationOpen={isBRulesValidationOpen}
        />
      </>
    ),
    [selectedAssetDetails, selectedModule]
  );

  const channelGridBlock = useMemo(
    () => (
      <>
        <ChannelGrid
          modulesAvail={filterModules("original").length > 0}
          setChannelSelectionModal={setChannelSelectionModal}
          setChannelGridSelectionModal={setChannelGridSelectionModal}
          selectedAssetDetails={selectedAssetDetails}
          setactivePage={setactivePage}
          setIschannelGrid={setIschannelGrid}
          setChannelGridToView={setChannelGridToView}
        />
      </>
    ),
    [selectedAssetDetails, selectedModule]
  );

  const genericInfo = (
    <div className="row pl-3">
      {isDamsFlow && !selectedAssetDetails.genericInfoSavedFlag && (
        <HarvestedGenericDetails
          data={genericData}
          selectedTactic={selectedAssetDetails}
          onClickSubmit={saveGenericInfo}
          isDamsFlow={isDamsFlow}
        />
      )}
      {(!isDamsFlow ||
        (isDamsFlow && selectedAssetDetails.genericInfoSavedFlag)) && (
        <GeenricInformationModule
          assetDetails={selectedAssetDetails}
          brands={brandsList}
          campaigns={getCampaignList([selectedAssetDetails?.brand])}
          isdisabled={true}
          refreshSelectAsset={refreshSelectAsset}
          isDamsFlow={isDamsFlow}
        />
      )}
    </div>
  );

  const renderTabSelectedModules = () => {
    const moduleType = selectedModule.moduleType.trim().toLowerCase();
    switch (moduleType) {
      case "original":
        return originalAndBasedOnModules;
      case "translated":
        return translatedModules;
      case "localized":
        return localizedOnModules;
      default:
        return originalAndBasedOnModules;
    }
  };

  const saveBrandStyleGuideline = (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    setIsloading(true);
    uploadBrandStyleGuideline(
      selectedAssetDetails.harvestedAssetId,
      formData,
      (res, err) => {
        setIsloading(false);
        if (err) {
          showError();
          return;
        }
        upDateMasterAssetList(res.data);
      }
    );
  };

  const drawModulesHeading = () => {
    const moduleType = selectedModule.moduleType.trim().toLowerCase();
    let title = "Modules";
    if (moduleType === "translated") {
      title = "Translated Modules";
    } else if (moduleType === "localized") {
      title = "Localized Modules";
    }
    let finalModules = filterModules(moduleType);
    if (moduleType === "original" || moduleType === "cloned") {
      finalModules = filterModules("original");
      finalModules = [...finalModules].filter(
        (module) => module.moduleType.trim().toLowerCase() === "original"
      );
    }
    return (
      <h2
        className="masterTacticTitle pl-3 assetDetailColTitle "
        style={{ marginTop: "20px" }}
      >
        <span className="moduleNormalShow" style={{ fontWeight: "600" }}>
          {title} ({finalModules.length})
        </span>
        <p
          class="search-items"
          style={{ paddingLeft: "0px", marginTop: "5px" }}
        >
          Click on module card to view the generic information
        </p>
      </h2>
    );
  };

  const showCreateModal = () => {
    return (
      <CustomModal
        displayModal={createModuleModal}
        hideModal={handleCloseCreateModuleModal}
        size="xl"
        dilogClassName="createPlaceholderModal"
        backdrop={false}
      >
        {createModuleList && (
          <ModulePlaceHolder
            asset={selectedAssetDetails}
            orignalModules={createModuleList}
            handleCloseCreateModuleModal={handleCloseCreateModuleModal}
            sourceType={selectedAssetDetails?.sourceType}
          />
        )}
      </CustomModal>
    );
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const tabPanelTabChange = React.useRef(null);
  const renderTabs = () => {
    let titles = [];
    let children = [];
    if (shouldShowOnlyOriginalModules) {
      titles = [
        `Modules (${filterModules("original").length})`,
        "Metadata Information",
        `Submission Documents (${selectedAssetDetails?.gridCount || "0"})`,
        `${t("channelMatrix.channelMatrices")} (${
          (selectedAssetDetails &&
            selectedAssetDetails.contentMatrices.length) ||
          "0"
        })`,
        //"Grids",
      ];
      children = [
        originalAndBasedOnModules,
        genericInfo,
        channelGridBlock,
        contentMatrices,
        // grids,
      ];
    } else {
      titles = [
        `Modules (${filterModules("original").length})`,
        `Translated (${filterModules("translated").length})`,
        `Localized (${filterModules("localized").length})`,
        "Generic Information",
        "Channel Grid (0)",
        `${t("channelMatrix.ChannelMatrices(0)")}`,
      ];
      children = [
        originalAndBasedOnModules,
        translatedModules,
        localizedOnModules,
        genericInfo,
        channelGridBlock,
        contentMatrices,
      ];
    }
    return (
      <TabPanel
        titles={titles}
        tabPanelTabChange={tabPanelTabChange}
        children={children}
        notifyParentForTabChange={(newTabPaosition) => {
          setBRulesValidationOpen(false);
          setSelectedTab(newTabPaosition);
        }}
      />
    );
  };

  const [moduleIdToCreateMatrix, setModuleIdToCreateMatrix] =
    useState(undefined);
  const handleProceedForChannelSelection = (selectedModuleId) => {
    setBRulesValidationOpen(false);
    setModuleIdToCreateMatrix(() => {
      setChannelSelectionModal(true);
    });
    setModuleIdToCreateMatrix(selectedModuleId);
  };

  const closeChannelSelectionModal = () => {
    setSelectedChannel(undefined);
    setChannelSelectionModal(false);
    setModuleIdToCreateMatrix(undefined);
    setChannelGridSelectionModal(false);
  };

  const cancelCreatingChannelMatrix = (newMatrix) => {
    closeChannelSelectionModal();
    startBRulesValidation(false);
    setModuleIdToCreateMatrix(undefined);
    addLatestMatrixData(newMatrix);
  };

  const renderBrandStyleGuideAttachment = () => {
    let jsx = <></>;
    if (selectedTab === 1 && !selectedAssetDetails.styleGuideline) {
      jsx = (
        <div className="brand-style-upload">
          <span className="link">
            <label
              for="upload-file"
              style={{
                cursor: "pointer",
                opacity: selectedAssetDetails.genericInfoSavedFlag ? "" : "0.4",
              }}
            >
              <img src={attachBrandStyleGuide} alt="" className="attach" />
              <span>Upload</span>
            </label>
            <input
              type="file"
              name="pdf"
              accept="application/pdf"
              id="upload-file"
              onChange={(event) => {
                const files = event.target.files;
                if (files?.length > 0) {
                  saveBrandStyleGuideline(files[0]);
                }
              }}
              disabled={
                selectedAssetDetails.genericInfoSavedFlag ? false : true
              }
            />
          </span>
          <span className="label">Brand Style Guidelines</span>
        </div>
      );
    } else if (selectedTab === 1 && selectedAssetDetails.styleGuideline) {
      jsx = (
        <div className="brand-style-view">
          <img src={attachedBrandStyleGuide} alt="" className="attach" />
          <span className="label">Brand Style Guidelines</span>
          <span
            className="link"
            onClick={() => {
              setBrandStyleGuidelineToBeViewed(
                selectedAssetDetails.styleGuideline
              );
            }}
          >
            <span>View</span>
          </span>
          <img
            src={removeBrandStyleGuide}
            alt=""
            className="remove"
            onClick={() => {
              setShowDeleteGuidelinesPopup(true);
            }}
          />
        </div>
      );
    }
    return jsx;
  };

  const hideDeleteStyleGuidelinesModal = () => {
    setShowDeleteGuidelinesPopup(false);
  };

  const deleteStyleGuidelines = () => {
    deleStyleGuidelines(selectedAssetDetails.harvestedAssetId, (res, err) => {
      if (err) {
        showError(err);
        return;
      }
      upDateMasterAssetList("");
      setShowDeleteGuidelinesPopup(false);
      setIsloading(false);
    });
  };

  const upDateMasterAssetList = (data) => {
    const tempAsset = JSON.parse(JSON.stringify(assetAndModuleDetails));
    let index = tempAsset.findIndex(
      (el, index) =>
        el.harvestedAssetId === selectedAssetDetails.harvestedAssetId
    );
    tempAsset[index].styleGuideline = data;
    setAssetsAndModules(tempAsset);

    const selectedTempAsset = JSON.parse(JSON.stringify(selectedAssetDetails));
    selectedTempAsset.styleGuideline = data;
    setSelectedAssetDetails(selectedTempAsset);
  };

  return (
    <div className="row">
      <CircularProgressBar isloading={isloading} />
      {!selectedModule && assets}

      {selectedAssetDetails && Object.keys(selectedAssetDetails).length > 0 && (
        <div className={`${selectedModule ? "col-md-4" : "col-md-9"}`}>
          {!selectedModule && (
            <div className="asset-title">
              <p className="moduleAssetTitle " style={{ marginBottom: "5px" }}>
                {selectedAssetDetails.assetName}
              </p>
              <div className="brand-campaign">
                <span className="brand-label">Product</span>{" "}
                <span className="value">{selectedAssetDetails.brand}</span>
                <span className="campaign-label">Campaign</span>{" "}
                <span className="value">
                  {selectedAssetDetails.campaignName}
                </span>
                {renderBrandStyleGuideAttachment()}
              </div>
              {selectedTab === 0 && (
                <p
                  class="search-items"
                  style={{ paddingLeft: "15px", marginTop: "-25px" }}
                >
                  <img
                    src={InfoIcon}
                    alt=""
                    style={{ marginBottom: "3px", marginRight: "5px" }}
                  />
                  Click on module card to view the generic information
                </p>
              )}
            </div>
          )}
          {!selectedModule && renderTabs()}
          {selectedModule && drawModulesHeading()}
          {selectedModule && renderTabSelectedModules()}
        </div>
      )}

      {selectedModule && (
        <div className="col-md-8">
          <ModuleGenericDetails
            module={selectedModule}
            asset={selectedAssetDetails}
            close={closeGenericDetails}
          />
        </div>
      )}
      {selectedAssetDetails && showCreateModal()}
      <CustomModal
        displayModal={isBRulesValidationOpen}
        hideModal={startBRulesValidation}
        size="xl"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <BusinessRulesValidation
          setBRulesValidationOpen={startBRulesValidation}
          modules={filterModules("original")}
          assetDataType={selectedAssetDetails?.assetDataType}
          sourceType={selectedAssetDetails?.sourceType}
          brand={selectedAssetDetails?.brand}
          campaignName={selectedAssetDetails?.campaignName}
          channelType={selectedChannel}
          cancelCreatingChannelMatrix={cancelCreatingChannelMatrix}
          //handleProceedForChannelSelection={handleProceedForChannelSelection}
        />
      </CustomModal>
      <CustomModal
        displayModal={channelSelectionModal}
        hideModal={closeChannelSelectionModal}
        size="sm"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <ChannelSelectionComponent
          closeChannelSelectionModal={closeChannelSelectionModal}
          sourceType={selectedAssetDetails?.sourceType}
          brand={selectedAssetDetails?.brand}
          campaignName={selectedAssetDetails?.campaignName}
          selectedChannel={selectedChannel}
          setSelectedChannel={setSelectedChannel}
          cancelCreatingChannelMatrix={cancelCreatingChannelMatrix}
          assetDataType={selectedAssetDetails?.assetDataType}
          startBusinessRulesValidation={(flag) => {
            if (channelGridSelectionModal) {
              setIschannelGrid(flag);
              setactivePage("1");
            } else {
              setBRulesValidationOpen(flag);
            }
          }}
          channelGridSelectionModal={channelGridSelectionModal}
        />
      </CustomModal>
      <CustomModal
        displayModal={brandStyleGuidelineToBeViewed}
        hideModal={() => {
          setBrandStyleGuidelineToBeViewed(undefined);
        }}
        size="lg"
        dilogClassName="alertModalSmall"
        backdrop={false}
      >
        <ViewBrandStyleGuideline
          brandStyleGuideline={brandStyleGuidelineToBeViewed}
          closeView={() => {
            setBrandStyleGuidelineToBeViewed(undefined);
          }}
        />
      </CustomModal>
      <CustomModal
        displayModal={showDeleteGuidelinesPopup}
        hideModal={hideDeleteStyleGuidelinesModal}
        title={""}
        size="sm"
        dilogClassName="alertModalSmall modal-with-top-border"
        keyboard={false}
        backdrop="true"
      >
        <div className="export-row1 ml-2">
          <span>
            Are you sure you want to{" "}
            <span style={{ color: "#e32a34", fontWeight: "600" }}>
              Delete ?
            </span>
          </span>
        </div>

        <div className="row align-items-center mt-5">
          <div className="col-md-6 pl-2 pb-2">
            <button
              type="button"
              className="btn btn-link text-uppercase"
              style={{
                color: "#2162d5 ",
                fontWeight: 600,
              }}
              onClick={() => hideDeleteStyleGuidelinesModal()}
            >
              CANCEL
            </button>
          </div>
          <div className="col-md-6 text-right pr-4 pb-2">
            <button
              type="button"
              className="btn btn-clone  export-proceed"
              onClick={() => {
                deleteStyleGuidelines();
              }}
            >
              YES, DELETE
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ModularisedTactics;
