import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import ChannelTabs from "./MarketingMessages/ChannelTabs";
import ContentMatrixModalHeader from "./ContentMatrixModalHeader";
import ContentMatrixModalFooter from "./ContentMatrixModalFooter";
import MarketingMessageSelection from "./MarketingMessageSelection";

import {
  getActiveClassStatus,
  isModularContent,
  removeDuplicates,
  showError,
} from "../../Uitls/Util";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import {
  getTemplateTypes,
  getContentmatrix,
  generateChannelMatrix,
  publishMatrix,
  getSmartTemplatesFromCSEmail,
  getTemplateUsingTemplateDamsId,
} from "../../services/apis";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import ModuleSelectionComponent from "./ModuleSelection/ModuleSelection";

import CompositeAssetView from "./CompositeAssets/CompositeAssetView";
import CustomModal from "../../components/customModal";
//Import styles
import "../../styles/components/ChannelMatrixContentBlock.scss";
//Import images
import infoIcon from "../../images/infoIcon.svg";
import searchIcon from "../../images/search.svg";
import GeneratedCompositeAssets from "./CompositeAssets/GeneratedCompositeAssets";
import ChannelMatrixGenerationToast from "./ChannelMatrixGenerationToast";
import ModalHeader from "../../Uitls/ModalHeader";
import { Col, Row } from "react-bootstrap";
import { capitalizeString } from "../../Uitls/Util";

const CreateChannelMatrix = (props) => {
  const {
    moduleDetails,
    sourceType,
    assetDataType,
    setShowChannelMatrix,
    selectedMarketingMessges,
    cancelCreateChannelMatrixModal,
  } = props;
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTamplate] = useState({});
  const [previousPage, setPreviousPage] = useState("gotoModule");

  const [isloading, setIsloading] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [matrixModalData, setMatrixModalData] = useState([]);
  const [pageSwitch, setPageSwitch] = useState({
    pageOne: false,
    pageTwo: true,
    pageThree: false,
    pageFour: false,
  });
  const [addedBodyModules, setAddedBodyModules] = useState([]);
  const [footNoteRefCheckBox, setFootNoteRefCheckBox] = useState(true);
  const [generatedCompositeAssetDetails, setGeneratedCompositeAssetDetails] =
    useState(undefined);

  const [compositeAssetToView, setCompositeAssetToView] = useState(undefined);

  const [showSourceModule, setShowSourceModule] = useState(false);
  const [activeClass, setActiveClass] = useState(null);
  const [contentMatrixModules, setContentMatrixModules] = useState([]);
  const [selectedMMessges, setSelectedMMessages] = useState(undefined);
  const [selectedTemplateDetails, setSelectedTemplateDetails] =
    useState(undefined);

  // const getmoduleDetailsErr = (value) =>
  //   toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getmoduleDetailsErr = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };
  useEffect(() => {
    getModuleListData();
  }, []);

  useEffect(() => {
    setContentMatrixModules(
      matrixModalData.map((module) => {
        module["bodyModuleSection"] = "";
        return module;
      })
    );
    if (
      isModularContent(assetDataType) &&
      matrixModalData &&
      matrixModalData.length > 0
    ) {
      const defaultData = filteredModules("Mandatory");
      if (defaultData.length > 0) {
        showChannelAttributes(defaultData[0]);
      }
      // } else {
      //   setContentMatrixModules(
      //     matrixModalData.map((module) => {
      //       module["bodyModuleSection"] = "";
      //       return module;
      //     })
      //   );
    }
  }, [matrixModalData]);

  const getModuleListData = () => {
    setIsloading(true);
    getContentmatrix(
      moduleDetails.moduleId,
      sourceType,
      assetDataType,
      (response, err, errCode) => {
        if (err) {
          setIsloading(false);
          const errCoponent = ErrComponentToRender(errCode);
          getmoduleDetailsErr(errCoponent);
        } else {
          setMatrixModalData(response.data);
          getTemplates();
        }
      }
    );
  };

  const getTemplates = () => {
    getSmartTemplatesFromCSEmail(moduleDetails.brandName, (res, err) => {
      setIsloading(false);
      if (!res) {
        showError(err);
        return;
      }
      const data = res.data;
      const rteTemplates = data.filter((temp) => temp.templateType === "RTE");
      const hqeTemplates = data.filter(
        (temp) =>
          temp.templateType === "HQE" || temp.templateType === "SFMC Newsletter"
      );
      let templates = [];
      if (rteTemplates && rteTemplates.length > 0) {
        templates.push({
          templateType: "RTE",
          templates: rteTemplates,
        });
      }
      if (hqeTemplates && hqeTemplates.length > 0) {
        templates.push({
          templateType: "HQE",
          templates: hqeTemplates,
        });
      }

      setTemplates(templates);
      setTemplateList(templates.map((template) => template.templateType));
    });
  };

  const onTemplateChange = (templateName) => {
    const selected = JSON.parse(JSON.stringify(templates)).find((template) => {
      return template.templateType === templateName;
    });
    setSelectedTamplate(selected);
    setSelectedTemplateDetails(undefined);
  };

  const getTemplateIds = () => {
    let templates = selectedTemplate?.templates;
    templates = templates?.map((template) => {
      const date = template.approvalDateTime;
      template.aprovalTimeStamp =
        moment(date).format("DD/MM/YYYY HH:mm:ss") + " Hrs";
      //template.isSelected = false;
      // return {
      //   isSelected: false,
      //   value: template.templateId,
      // };
      return template;
    });
    return templates || [];
  };
  const onTemplateIdChange = (templateId) => {
    const template = { ...selectedTemplate };
    const associatedTemplates = template.templates.map((template) => {
      if (template.templateId === templateId) {
        template.isSelected = true;
      } else {
        template.isSelected = false;
      }

      return template;
    });
    template.templates = associatedTemplates;
    setSelectedTamplate(template);
    getSmartTemplateDetails(templateId);
  };

  const getSmartTemplateDetails = (templateId) => {
    getTemplateUsingTemplateDamsId(templateId, (res, err) => {
      if (err) {
        showError(err);
        return;
      } else {
        setSelectedTemplateDetails(res.data);
      }
    });
  };

  const getSelectedTemplateId = () => {
    const templates = selectedTemplate?.templates;
    const selected = templates?.find((template) => template.isSelected);
    if (selected) {
      return selected.templateId;
    }
    return undefined;
  };

  const getSelectedTemplateName = () => {
    const templates = selectedTemplate?.templates;
    const selected = templates?.find((template) => template.isSelected);
    if (selected && selected.templateName !== "null") {
      return selected.templateName;
    }
    return undefined;
  };

  const filteredModules = (moduleType) => {
    let filtered = matrixModalData?.filter(
      (item) =>
        item.contentModuleType.toLowerCase() === moduleType.toLowerCase()
    );
    return filtered;
  };

  const backToPrviousScreen = (previous) => {
    if (pageSwitch.pageOne) {
      proceedToNextScreen("pageOne");
    } else if (pageSwitch.pageTwo) {
      setShowChannelMatrix(false);
      // goToTemplateSelection();
      return;
    } else if (pageSwitch.pageThree) {
      return;
    }
    setShowChannelMatrix(false);
  };

  const goToTemplateSelection = () => {
    proceedToNextScreen("pageOne");
    setSelectedTamplate({});
    setFootNoteRefCheckBox(true);
    setContentMatrixModules([]);
    setAddedBodyModules([]);
  };

  const handleCheckBox = (e) => {
    let checked = e.target.checked;
    if (!selectedTemplate?.templateType) return;
    setFootNoteRefCheckBox(checked);
  };
  const showMatrixGenerationToast = (compositeAssetDetails) => {
    if (compositeAssetDetails && moduleDetails) {
      const data = {
        channelMatrixId: compositeAssetDetails?.contentMatrixId,
        brand: moduleDetails?.brandName,
        campaign: moduleDetails?.campaign,
      };
      toast.success(
        <ChannelMatrixGenerationToast
          data={data}
          closeButtonClicked={() => {
            toast.dismiss();
          }}
          toastType="update"
        />,
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
    }
  };
  const generateChannelMatrixSpreadSheet = (finalMatrixRows, cb) => {
    let bodyModules = [];

    addedBodyModules.forEach((module) => {
      let modules = module.linkedModules.map((linkedModule) => {
        const moduleFromMatrixData = matrixModalData.find((el) => {
          return el.moduleId === linkedModule.moduleId;
        });
        return {
          ...linkedModule,
          bodyModuleSection: module.bodyModuleSection,
          moduleCode: isModularContent(assetDataType)
            ? moduleFromMatrixData.moduleCode
            : undefined,
          moduleName: isModularContent(assetDataType)
            ? moduleFromMatrixData.moduleName
            : undefined,
          subjectlines: isModularContent(assetDataType)
            ? moduleFromMatrixData.subjectlines
            : undefined,
          preheaders: isModularContent(assetDataType)
            ? moduleFromMatrixData.preheaders
            : undefined,
          optOut: isModularContent(assetDataType)
            ? moduleFromMatrixData.optOut
            : undefined,
        };
      });
      bodyModules = bodyModules.concat(modules);
    });
    let payload = {
      brand: moduleDetails.brandName,
      templateType: selectedTemplate.templateType,
      campaign: moduleDetails.campaign,
      templateId: selectedTemplate?.templates.find((temp) => {
        return temp.isSelected;
      })?.templateId,
      templateName: selectedTemplate?.templates.find((temp) => {
        return temp.isSelected;
      })?.templateName,
      templateHtml: selectedTemplateDetails.html,
      refernceFootNote: footNoteRefCheckBox,
      channelType: moduleDetails.channel,
      compositeIdList: finalMatrixRows,
      marketingMessages: isModularContent(assetDataType)
        ? undefined
        : {
            subjectLines: selectedMMessges.subjectLines.map((subjectline) => {
              return {
                id: subjectline.id,
                text: subjectline.text,
              };
            }),
            preHeaders: selectedMMessges.preHeaders.map((preheader) => {
              return { id: preheader.id, text: preheader.text };
            }),
            optOuts:
              selectedMMessges.optOuts.length > 0
                ? selectedMMessges.optOuts.map((optout) => {
                    return { id: optout.id, text: optout.text };
                  })
                : undefined,
            footers: [],
          },
      contentModules: bodyModules,
    };
    setIsloading(true);
    generateChannelMatrix(
      payload,
      sourceType,
      assetDataType,
      (res, err, errCode) => {
        setIsloading(false);
        if (res) {
          setGeneratedCompositeAssetDetails(() => {
            proceedToNextScreen("pageThree");
          });
          setGeneratedCompositeAssetDetails({
            compositeAssetDetails: res.data,
          });
          if (finalMatrixRows && finalMatrixRows.length > 0) {
            showMatrixGenerationToast(res.data);
            cb(true);
          }

          return;
        }
        getmoduleDetailsErr(errCode);
      }
    );
  };
  const proceedToNextScreen = (pageNum) => {
    let pages = { ...pageSwitch };
    const keys = Object.keys(pages);
    keys.forEach((page) => {
      if (pageNum.trim().toLowerCase() === page.trim().toLowerCase()) {
        pages[page] = true;
      } else {
        pages[page] = false;
      }
    });
    const currentIndex = keys.findIndex(
      (key) => key.trim().toLowerCase() === pageNum.trim().toLowerCase()
    );
    if (currentIndex > -1) {
      const prev = currentIndex - 1;
      if (prev > -1) {
        setPreviousPage(keys[prev]);
      } else {
        setPreviousPage(keys[0]);
      }
    }
    setPageSwitch(pages);
  };
  const notifyPublishMatrixToDams = (error) => {
    const dialog = error ? toast.error : toast.success;
    dialog(
      <>
        <p className="notificationHeading">
          {error
            ? `${capitalizeString(sourceType)} Upload Un-successful`
            : `${capitalizeString(sourceType)} Upload successful`}
        </p>
        <div className="btn-close-toast">
          <button
            className="btn btn-link pl-0 btn-close-toast"
            onClick={() => {
              toast.dismiss();
            }}
          >
            Close
          </button>
        </div>
      </>,
      {
        position: "top-right",
        autoClose: true,
      }
    );
  };

  const publishMatrixToDams = () => {
    setIsloading(true);
    publishMatrix(
      sourceType,
      assetDataType,
      generatedCompositeAssetDetails?.compositeAssetDetails.contentMatrixId,
      (response, err, errorCode) => {
        if (err) {
          notifyPublishMatrixToDams(err ? true : false);
          setIsloading(false);
        } else {
          notifyPublishMatrixToDams(err ? true : false);
          setIsloading(false);
          let data = JSON.parse(JSON.stringify(generatedCompositeAssetDetails));

          data.compositeAssetDetails.contentMatrix = response.data;
          setGeneratedCompositeAssetDetails(data);
        }
      }
    );
  };

  const getHeaderData = () => {
    let obj = {};
    obj["Product/Brand"] = moduleDetails.brandName;
    if (moduleDetails.campaign) {
      obj["Campaign"] = moduleDetails.campaign;
    }
    obj["Channel"] = moduleDetails.channel;
    return obj;
  };
  const uniqueChannelAttributes = (moduleId) => {
    let channelAttributes = { subjectLines: [], preHeaders: [], optOuts: [] };
    if (matrixModalData && matrixModalData.length > 0) {
      const selectedModule = matrixModalData.find((module) => {
        return module.moduleId === activeClass;
      });
      if (selectedModule) {
        channelAttributes.subjectLines = selectedModule.subjectlines;
        channelAttributes.preHeaders = selectedModule.preheaders;
        channelAttributes.optOuts = selectedModule.optOut;
      }
    }
    return channelAttributes;
  };

  const showChannelAttributes = (data) => {
    if (data) {
      //setChannelAttrElements(data);
      setActiveClass(data.moduleId);
    } else {
      //setChannelAttrElements([]);
      setActiveClass(null);
    }
  };

  const handleCurrentModuleChannelAttr = (event) => {
    //send current Module data - Channel  Attribute Elements data
    let modules = [];
    if (event.target.checked) {
      modules = filteredModules("original");
    } else {
      modules = filteredModules("mandatory");
    }
    setShowSourceModule(event.target.checked);
    if (modules.length > 0) {
      showChannelAttributes(modules[0]);
    } else {
      showChannelAttributes(undefined);
    }
  };

  const getSelectedModule = () => {
    return matrixModalData.find((module) => {
      return module.moduleId === activeClass;
    });
  };

  const checkForChannelAttributes = () => {
    let channlAttrs = { subjectLines: [], preHeaders: [], optOuts: [] };
    let sourceModule = undefined;
    matrixModalData.forEach((module) => {
      channlAttrs.subjectLines = channlAttrs.subjectLines?.concat(
        module.subjectlines || []
      );
      channlAttrs.preHeaders = channlAttrs.preHeaders?.concat(
        module.preheaders || []
      );
      if (moduleDetails.moduleId === module.moduleId) {
        sourceModule = module;
      }
    });

    channlAttrs.optOuts = sourceModule?.optOut || [];
    if (
      channlAttrs.subjectLines?.length > 0 &&
      channlAttrs.preHeaders?.length > 0
      //channlAttrs.optOuts?.length > 0
    ) {
      return false;
    }
    return true;
  };
  return (
    <div>
      {pageSwitch.pageOne && (
        <>
          {!isModularContent(assetDataType) && (
            <MarketingMessageSelection
              brand={moduleDetails.brandName}
              campaignName={moduleDetails.campaign}
              channelType={moduleDetails.channel}
              sourceType={sourceType}
              backToPrviousScreen={() => {
                proceedToNextScreen("pageTwo");
                setSelectedMMessages(undefined);
              }}
              // closeMarketingMsgSelectionModal={(previous) => {
              //   backToPrviousScreen("pageTwo");
              // }}
              setSelectedMMessages={setSelectedMMessages}
              cancelCreatingChannelMatrix={cancelCreateChannelMatrixModal}
              assetDataType={assetDataType}
              moduleId={moduleDetails.moduleId}
              generateChannelMatrixSpreadSheet={
                generateChannelMatrixSpreadSheet
              }
              selectedMMessges={selectedMMessges}
            />
          )}
          {isModularContent(assetDataType) && (
            <>
              <ModalHeader
                title={t("channelMatrix.createChannelMatrixStepHead", {
                  currentStep: 4,
                  totalSteps: 4,
                })}
                subtitle={
                  pageSwitch.pageTwo
                    ? t("channelMatrix.selectModuleSequence")
                    : t("channelMatrix.selectModuleRvwChnlAttrs")
                }
                subtitleIcon={infoIcon}
                //previous={1}
                headerData={getHeaderData()}
                handleBack={() => {
                  proceedToNextScreen("pageTwo");
                }}
              />
              <div className="channelMatrixContentBlock customScrollBar">
                <div className="row">
                  <div className="col-md-6 channelBlockBrdrRight">
                    <div className="associatedModulesContainer associateModalHeight customScrollBar">
                      <div className="moduleListPanelTitle">
                        Mandatory (Must Exist with)
                      </div>
                      {filteredModules("Mandatory").map((item) => (
                        <div
                          className={`d-block moduleCodeRow ${
                            isModularContent(assetDataType) &&
                            getActiveClassStatus(item.moduleId, activeClass)
                              ? "moduleCodeRowSelected"
                              : "inactive"
                          }`}
                          key={item.moduleId}
                          onClick={() => {
                            if (!isModularContent(assetDataType)) {
                              return;
                            }
                            showChannelAttributes(item);
                            setShowSourceModule(false);
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <span>{item.moduleName}</span>{" "}
                            <button className="btn btn-link p-0 m-0">
                              View
                            </button>
                          </div>
                        </div>
                      ))}

                      <div className="moduleListPanelTitle mt-3">
                        Optional (Related)
                      </div>
                      {filteredModules("optional").map((item) => (
                        <div
                          className={`d-block moduleCodeRow ${
                            isModularContent(assetDataType) &&
                            getActiveClassStatus(item.moduleId, activeClass)
                              ? "moduleCodeRowSelected"
                              : "inactive"
                          }`}
                          key={item.moduleId}
                          onClick={() => {
                            if (!isModularContent(assetDataType)) {
                              return;
                            }
                            showChannelAttributes(item);
                            setShowSourceModule(false);
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <span>{item.moduleName}</span>{" "}
                            <button className="btn btn-link p-0 m-0">
                              View
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Row
                      className="mb-2 align-items-center"
                      style={{ paddingTop: "24px" }}
                    >
                      <Col className="col-md-7 ">
                        <span className="font-weight-bold">
                          Channel Attribute Elements |{" "}
                          {getSelectedModule()?.moduleCode}
                        </span>
                        <span className="text-nowrap pl-1 modal-header-subtitle">
                          <img
                            className="modal-header-subtitle-icon"
                            src={infoIcon}
                            alt=""
                            style={{ marginRight: "10px" }}
                          />
                          Atleast one message from each attribute is required
                        </span>
                      </Col>
                      <Col className="text-right col-md-5 pl-0 pr-0">
                        Show for Source Module
                        <Switch
                          className="filterRightMargin mr-1"
                          inputProps={{ "aria-label": "primary checkbox" }}
                          toggle={showSourceModule}
                          checked={showSourceModule}
                          onChange={handleCurrentModuleChannelAttr}
                        />
                      </Col>
                    </Row>

                    <ChannelTabs
                      channelAttrElements={
                        isModularContent(assetDataType)
                          ? uniqueChannelAttributes(activeClass)
                          : selectedMMessges
                      }
                      assetDataType={assetDataType}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8  text-right">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      cancelCreateChannelMatrixModal();
                    }}
                    style={{ marginRight: "24px" }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="btn btn-primary defaultBtnWidth"
                    onClick={() => {
                      generateChannelMatrixSpreadSheet();
                      // setContentMatrixModules(() => {
                      //   proceedToNextScreen("pageTwo");
                      // });
                      // setContentMatrixModules(
                      //   matrixModalData.map((module) => {
                      //     module["bodyModuleSection"] = "";
                      //     return module;
                      //   })
                      // );
                    }}
                    disabled={
                      isModularContent(assetDataType)
                        ? checkForChannelAttributes()
                        : false
                    }
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {pageSwitch.pageTwo && matrixModalData && (
        <>
          <ModalHeader
            title={t("channelMatrix.createChannelMatrixStepHead", {
              currentStep: 3,
              totalSteps: 4,
            })}
            subtitle={
              pageSwitch.pageTwo
                ? t("channelMatrix.selectModuleSequence")
                : t("channelMatrix.selectModuleRvwChnlAttrs")
            }
            subtitleIcon={infoIcon}
            previous={1}
            headerData={getHeaderData()}
            handleBack={(previous) => {
              backToPrviousScreen(previous);
              setSelectedTemplateDetails(undefined);
            }}
          />
          <ModuleSelectionComponent
            brand={moduleDetails.brandName}
            campaignName={moduleDetails.campaign}
            channelType={moduleDetails.channel}
            contentModules={matrixModalData}
            addedBodyModules={addedBodyModules}
            setAddedBodyModules={setAddedBodyModules}
            goToTemplateSelection={goToTemplateSelection}
            handleClose={cancelCreateChannelMatrixModal}
            generateChannelMatrixSpreadSheet={generateChannelMatrixSpreadSheet}
            selectedTemplate={selectedTemplate}
            templateList={templateList}
            onTemplateChange={onTemplateChange}
            getSelectedTemplateId={getSelectedTemplateId}
            getTemplateIds={getTemplateIds}
            onTemplateIdChange={onTemplateIdChange}
            getSelectedTemplateName={getSelectedTemplateName}
            handleCheckBox={handleCheckBox}
            footNoteRefCheckBox={footNoteRefCheckBox}
            contentMatrixModules={contentMatrixModules}
            setContentMatrixModules={setContentMatrixModules}
            goToNextScreen={() => proceedToNextScreen("pageOne")}
            selectedTemplateDetails={selectedTemplateDetails}
          />
        </>
      )}
      {pageSwitch.pageThree && (
        <>
          <GeneratedCompositeAssets
            proceedToNextScreen={proceedToNextScreen}
            previousPage={previousPage}
            generatedCompositeAssetDetails={generatedCompositeAssetDetails}
            setCompositeAssetToView={setCompositeAssetToView}
            moduleDetails={moduleDetails}
            sourceType={sourceType}
            publishMatrixToDams={publishMatrixToDams}
            closeModal={() =>
              cancelCreateChannelMatrixModal(
                generatedCompositeAssetDetails?.compositeAssetDetails
                  .contentMatrix
              )
            }
            generateChannelMatrixSpreadSheet={generateChannelMatrixSpreadSheet}
            assetDataType={assetDataType}
          />
        </>
      )}

      {pageSwitch.pageFour && (
        <>
          <CompositeAssetView
            proceedToNextScreen={proceedToNextScreen}
            data={compositeAssetToView}
            previousPage={previousPage}
            sourceType={sourceType}
            assetDataType={assetDataType}
          />
        </>
      )}
      {pageSwitch.pageFour && (
        <div class="row align-items-center martrixModalFooter matrxiFooterBrdr no-gutters">
          <div class="col-md-5"></div>
          <div class="col-md-7 text-right">
            <button
              class="btn btn-link text-uppercase"
              onClick={() => {
                setCompositeAssetToView(undefined);
                proceedToNextScreen(previousPage);
              }}
            >
              CLOSE
            </button>
          </div>
        </div>
      )}

      <CircularProgressBar isloading={isloading} />
    </div>
  );
};

export default CreateChannelMatrix;
