import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { logout } from "../Uitls";

function UnAuthorizeModal(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const onLogout = () => {
    logout();
    history.push("/");
  };
  useEffect(() => {
    setTimeout(() => {
      logout();
      history.push("/");
    }, 6000);
  });
  return (
    <>
      <p className="notificationHeading">IMC</p>
      <p className="notificationText">{t("apiErrors.err403")}</p>
      <button onClick={onLogout} className="btn btn-link pl-0">
        {t("generalText.loginAgain")}
      </button>
    </>
  );
}

export default UnAuthorizeModal;
