import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import HeaderCreateModule from "../components/HeaderCreateModule";
import CreateModuleNav from "../components/CreateModuleNav";
import {
  routes,
  getModuleInfoFromLocalStorage,
  isThisMLRFeedbackFlow,
  moduleTypes,
} from "../Uitls/Util";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import ReactStars from "react-rating-stars-component";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/pages/AdditionalInfo.scss";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import CustomModal from "../components/customModal";

import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import {
  getModuleDetailsById,
  saveAdditionalInfo,
  getLatestAnnotatedPdfFromVeeva,
} from "../services/apis";
import LogoutCTA from "../Pages/LogoutCTA";
import MlrFeedbackNav from "../components/MlrFeedback/MlrFeedbackNav";
import fullScreenIcon from "../images/fullScreenIcon.svg";
import RenderMlrFeedback from "../components/MlrFeedback/RenderMlrFeedback";
import DropDownWithLabel from "../components/DropDownWithLabel";
const AdditionalInfo = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const [moduleId, setModuleId] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(routes.additionalInfo);
  const frInstanceUsage = React.createRef();
  const [additionalInfo, setAdditionalInfo] = useState({ information: {} });
  const [pageNumber, setPageNumber] = useState("");
  const [moduleCode, setModuleCode] = useState("");
  const [isMLRClicked, setIsMLRClicked] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [module, setModule] = useState(undefined);
  const [mlrModuleStatus, setMlrModuleStatus] = useState("");
  const [veevaDocId, setVeevaDocId] = useState(undefined);
  //For Addressing MLR Feedback scenarios only
  const [isMainContentExpanded, setMaincontentExpanded] = useState(false);
  const [isPdfContentExpanded, setPdfcontentExpanded] = useState(false);
  const pdfElementRef = useRef(null);
  const [pdfwidth, setpdfWidth] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(undefined);
  //For save button enable disable while network in progress
  const [enableSave, setEnableSave] = useState(true);
  useEffect(() => {
    if (isMainContentExpanded) {
      setPdfcontentExpanded(false);
    } else if (isPdfContentExpanded) {
      setMaincontentExpanded(false);
    }
    calculatePdfWidth();
  }, [isMainContentExpanded, isPdfContentExpanded]);

  const calculatePdfWidth = () => {
    if (pdfElementRef && pdfElementRef.current) {
      setpdfWidth(pdfElementRef.current.getBoundingClientRect().width - 50);
    }
  };

  const fetchAnnotatedPdf = (module) => {
    const veevaDocId = module.moduleMlr[0].mlrReviews[0].veevaDocId;
    if (veevaDocId) {
      getLatestAnnotatedPdfFromVeeva(veevaDocId, (res, err, errCode) => {
        if (res) {
          setPdfUrl(res.data);
          return;
        }
        const errCoponent = ErrComponentToRender(errCode);
        claimsSaveErr(errCoponent);
      });
    }
  };

  const claimsSaveErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  useEffect(() => {
    // setBtnOptions(buttonOptions())
    const moduleInfo = getModuleInfoFromLocalStorage();
    if (moduleInfo && moduleInfo.moduleId) {
      setModuleId(moduleInfo.moduleId);
    }
  }, []);

  useEffect(() => {
    if (moduleId) {
      getModuleDetails();
    }
  }, [moduleId]);

  const getModuleDetails = () => {
    if (!moduleId) {
      return;
    }
    setIsloading(true);
    getModuleDetailsById(moduleId, (response, error, errorCode) => {
      setIsloading(false);
      if (response && response.data && !error && !errorCode) {
        setModuleStatus(response.data);
        handleModuleData(response.data);
        if (isThisMLRFeedbackFlow()) {
          //setModule(response.data);
          fetchAnnotatedPdf(response.data);
          calculatePdfWidth();
        }
      }
    });
  };
  const handleModuleData = (response) => {
    if (!response) {
      return;
    }
    setModule(response);
    if (response.moduleCode) {
      setModuleCode(response.moduleCode);
    }
    if (response.pageNumber) {
      setPageNumber(response.pageNumber);
    }
    if (response.additionalInformations) {
      const additionalInformations = response.additionalInformations;
      if (additionalInformations.length > 0) {
        //setAdditionalInfo(response.additionalInformations[0])
        const addInfo = response.additionalInformations[0];
        if (addInfo) {
          if (addInfo.information) {
            setAdditionalInfo(addInfo);
            return;
          }
        }
      }
      // else{
      //     setAdditionalInfo({information : {}})
      // }
    }
    //else{
    setAdditionalInfo({ information: {} });
    //}
  };
  const setModuleStatus = (module) => {
    let status = "";
    if (module) {
      const moduleMlr = module.moduleMlr;
      if (moduleMlr && moduleMlr.length > 0) {
        const mlrReviews = moduleMlr[0].mlrReviews;
        if (mlrReviews && mlrReviews.length > 0) {
          if (mlrReviews[0].reviewStatus) {
            status = mlrReviews[0].reviewStatus;
            setVeevaDocId(mlrReviews[0].veevaDocId);
          }
        }
      }
    }
    setMlrModuleStatus(status);
  };

  const onChangeModuleuses = (event) => {
    let information = additionalInfo.information;
    information.moduleUsageRecommendation = event;
    setAdditionalInfo({ ...additionalInfo, information });
  };
  const onChangeStageCustomerJourney = (event) => {
    let information = additionalInfo.information;
    information.stageInCustomerJourney = event.target.value;
    setAdditionalInfo({ ...additionalInfo, information });
  };
  const getRatingValue = () => {
    if (Object.keys(additionalInfo.information).length > 0) {
      return additionalInfo.information.moduleRating;
    }
  };
  const ratingChanged = (newRating) => {
    let information = additionalInfo.information;
    information.moduleRating = newRating;
    setAdditionalInfo({ ...additionalInfo, information });
  };
  const showError = (errorCode) => {
    const errCoponent = ErrComponentToRender(errorCode);
    toast.error(errCoponent);
  };
  const handleDosageChange = (event) => {
    if (!event || !event.target) {
      return;
    }
    let information = additionalInfo.information;
    information.dosage = event.target.value;
    setAdditionalInfo({ ...additionalInfo, information });
  };

  const handleAudienceSegmentChange = (event) => {
    if (!event || !event.target) {
      return;
    }
    let information = additionalInfo.information;
    information.audienceSegment = event.target.value;
    setAdditionalInfo({ ...additionalInfo, information });
  };

  const postDataToServer = async () => {
    const payload = { information: additionalInfo.information };
    setIsloading(true);
    setEnableSave(false);
    await saveAdditionalInfo(moduleId, payload)
      .then((response) => {
        //cb(response);
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
        setIsloading(false);
        setEnableSave(true);
        //handleCreateChannels(response.data)
      })
      .catch((err) => {
        setIsloading(false);
        const errorCode = err.response && err.response.status;
        showError(errorCode);
        setEnableSave(true);
        //cb("", err, errorCode);
      });
  };
  const handleProceed = async () => {
    //goToPublishModule();
    await postDataToServer();
    goToPublishModule();
  };

  const goToPublishModule = () => {
    if (moduleId) {
      history.push({
        pathname: isThisMLRFeedbackFlow()
          ? routes.mlrFeedbackPreview
          : routes.moduleDetails,
        state: {
          moduleId: moduleId,
        },
      });
    }
  };
  const handleClose = () => {
    window.history.back();
  };

  const handleSave = () => {
    postDataToServer();
  };

  const onBoxSelected = (event, id) => {
    if (id && id !== null) {
      setTimeout(() => {
        setCssOnSelectedBox(id);
      }, 1);
    }
  };

  const setCssOnSelectedBox = (boxId) => {
    const genRules = document.getElementById("gen-rules");
    const othRules = document.getElementById("oth-rules");
    const moduleUses = document.getElementById("module-uses");
    switch (boxId) {
      case "gen-rules":
        if (genRules !== null) {
          genRules.className = "editor-box selected-editor-box";
        }
        if (othRules !== null) {
          othRules.className = "editor-box";
        }
        if (moduleUses !== null) {
          moduleUses.className = "editor-box";
        }

        break;
      case "oth-rules":
        if (genRules !== null) {
          genRules.className = "editor-box";
        }
        if (othRules !== null) {
          othRules.className = "editor-box selected-editor-box";
        }
        if (moduleUses !== null) {
          moduleUses.className = "editor-box";
        }
        break;
      case "module-uses":
        if (genRules !== null) {
          genRules.className = "editor-box";
        }
        if (othRules !== null) {
          othRules.className = "editor-box";
        }
        if (moduleUses !== null) {
          moduleUses.className = "editor-box selected-editor-box";
        }
        break;
      default:
        if (genRules !== null) {
          genRules.className = "editor-box";
        }
        if (othRules !== null) {
          othRules.className = "editor-box";
        }
        if (moduleUses !== null) {
          moduleUses.className = "editor-box";
        }
        break;
    }
  };

  document.addEventListener("click", (event) => {
    if (event) {
      //const clickedId = event.target.id
      setCssOnSelectedBox("");
    }
  });

  const hideModal = () => {
    setIsMLRClicked(false);
  };

  const onClickpopupYes = async () => {
    hideModal();
    await postDataToServer();
    history.push(routes.assetDetails);
  };

  const onclickpopupProceed = async () => {
    hideModal();
    await postDataToServer();
    history.push(routes.mlrSubmission);
  };

  const renderMLRpopup = () => {
    return (
      <CustomModal
        displayModal={isMLRClicked}
        hideModal={hideModal}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <div className="col-md-12 mlr-pop-up">
          <p className="title">
            <strong>{i18next.t("generalText.confirmCreateMoreModules")}</strong>
          </p>
          <button
            className="btn btn-primary btn-cnfrm"
            onClick={onClickpopupYes}
          >
            {i18next.t("generalText.yes").toUpperCase()}
          </button>
          <div className="btn-div">
            <button
              className="btn btn-link pl-0 "
              onClick={onclickpopupProceed}
            >
              {i18next.t("generalText.proceedToMLR")}
            </button>
          </div>
          <div className="btn-div">
            <button className="btn btn-link pl-0 " onClick={hideModal}>
              {i18next.t("generalText.close")}
            </button>
          </div>
        </div>
      </CustomModal>
    );
  };
  //Un-used code
  // const getDosingOptions = () => {
  //   if (!module || !module.assetDetails) {
  //     return [];
  //   }
  //   let dosingOptions = module.assetDetails.dosing.split(",");
  //   dosingOptions = [...dosingOptions].map((option) => {
  //     return option.trim();
  //   });
  //   return dosingOptions;
  // };

  const getDropdownOptions = (option) => {
    if (!module || !module.assetDetails) {
      return [];
    }
    let dropdownOptions;
    if (option === "dosage") {
      dropdownOptions = module.assetDetails.dosing.split(",");
    } else {
      dropdownOptions = module.assetDetails.audienceSegment.split(",");
    }
    dropdownOptions = [...dropdownOptions].map((option) => {
      return option.trim();
    });
    return dropdownOptions;
  };

  const getDosageAndCJourneyFroDenovo = () => {
    return (
      <>
        <Col className="col-md-12 ">
          <div className="customer-stage-journey">
            <TextField
              id="stage-journey"
              label={t("additionalInfo.stageJourney")}
              variant="outlined"
              fullWidth={true}
              value={additionalInfo.information.stageInCustomerJourney || ""}
              onChange={(event) => {
                onChangeStageCustomerJourney(event);
              }}
            />
          </div>
        </Col>
        <Col className="col-md-12 row-margins">
          <DropDownWithLabel
            id="dosage"
            dropdownclass="add-info-dosing"
            options={{
              value: additionalInfo.information.dosage || "",
              name: t("additionalInfo.dosage"),
              label: t("additionalInfo.dosage"),
              items: getDropdownOptions("dosage"),
              handleChange: handleDosageChange,
            }}
          />
        </Col>
      </>
    );
  };

  const isDenovoModule = () => {
    if (
      module &&
      module.creationMethodType.toLowerCase() === moduleTypes.denovo
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <LogoutCTA />
      <div className="headeBgWhite" id="div-header">
        <HeaderCreateModule />
        {/* {renderUpperHead} */}
        {!isThisMLRFeedbackFlow() && (
          <CreateModuleNav
            currentPage={currentPage}
            moduleName={module ? module.name : ""}
            moduleCode={moduleCode}
            btnProceed={i18next.t("generalText.publish")}
            btnClose={i18next.t("generalText.close")}
            btnSave={i18next.t("generalText.save")}
            MLRStatatus="true"
            // disableProceed = {shouldDisableProceedButton}
            handleProceed={handleProceed}
            handlePageClose={handleClose}
            handlePageSave={handleSave}
            shouldHaveMultipleOptions={true}
            proceedOptions={[i18next.t("generalText.submitToMlr")]}
            moduleStatus={mlrModuleStatus}
            moduleId={moduleId}
            veevaDocId={veevaDocId}
            setMlrModuleStatus={setMlrModuleStatus}
            enableSave={enableSave}
            isNotDenovoModule={
              getModuleInfoFromLocalStorage()?.creationMethodType?.toLowerCase() !==
              moduleTypes.denovo
            }
          />
        )}
        {isThisMLRFeedbackFlow() && (
          <MlrFeedbackNav
            module={module}
            currentPage={currentPage}
            handleDone={handleProceed}
            handlePageClose={handleClose}
          />
        )}
        {/* {renderUpperHead} */}
      </div>
      <Container fluid id="div-content">
        <Row className="row mt-3 ml-0 mr-0 mb-4">
          <Col
            className={
              isThisMLRFeedbackFlow()
                ? isMainContentExpanded
                  ? "col-12 col-md-12"
                  : "col-12 col-md-8"
                : "col-12 col-md-12"
            }
            style={{
              display:
                isThisMLRFeedbackFlow() && isPdfContentExpanded ? "none" : "",
            }}
          >
            <div className="add-info-content-page">
              {isThisMLRFeedbackFlow() && (
                <div style={{ float: "right" }}>
                  <img
                    src={fullScreenIcon}
                    alt=""
                    className="expandGroupsIcon"
                    onClick={() =>
                      setMaincontentExpanded(!isMainContentExpanded)
                    }
                  />
                </div>
              )}
              <Row className="add-info-row">
                <div className="add-info-title">
                  {i18next.t("additionalInfo.addAdditionalInfo")}
                  <span className="optional">
                    {" "}
                    ({i18next.t("additionalInfo.optional")})
                  </span>
                </div>
              </Row>
              <Row className="add-info-row row-margins">
                <CircularProgressBar isloading={isloading} />
                <Col className="col-md-6">
                  {!isDenovoModule() && (
                    <>
                      <Col className="col-md-12">
                        {/* <div> */}
                        <div className="page-number page-border">
                          <TextField
                            //className = 'page-number'
                            id="page-number"
                            label={t("additionalInfo.pageNumber")}
                            variant="outlined"
                            fullWidth={true}
                            value={
                              additionalInfo.information.pageNumber ||
                              pageNumber
                            }
                            disabled={true}
                          />
                        </div>

                        {/* </div> */}
                      </Col>
                      {!isDenovoModule() && (
                        <Col className="col-md-12 row-margins">
                          <div className="customer-stage-journey">
                            <TextField
                              id="stage-journey"
                              label={t("additionalInfo.stageJourney")}
                              variant="outlined"
                              fullWidth={true}
                              value={
                                additionalInfo.information
                                  .stageInCustomerJourney || ""
                              }
                              onChange={(event) => {
                                onChangeStageCustomerJourney(event);
                              }}
                            />
                          </div>
                        </Col>
                      )}
                      <Col className="col-md-12 row-margins">
                        <DropDownWithLabel
                          id="dosage"
                          dropdownclass="add-info-dosing"
                          options={{
                            value: additionalInfo.information.dosage || "",
                            name: t("additionalInfo.dosage"),
                            label: t("additionalInfo.dosage"),
                            items: getDropdownOptions("dosage"),
                            handleChange: handleDosageChange,
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {isDenovoModule() && getDosageAndCJourneyFroDenovo()}
                  <Col className="col-md-12 row-margins">
                    <DropDownWithLabel
                      id="audienceSegment"
                      dropdownclass="add-info-dosing"
                      options={{
                        value: additionalInfo.information.audienceSegment || "",
                        name: "Audience Segment",
                        label: "Audience Segment",
                        items: getDropdownOptions("audienceSegment"),
                        handleChange: handleAudienceSegmentChange,
                      }}
                    />
                  </Col>
                  {isDenovoModule() && (
                    <Col className="col-md-12 d-flex row-margins">
                      <span className="module-rating custom-rating">
                        Module Rating
                      </span>
                      {/* {this is for modify flow} */}
                      {Object.keys(additionalInfo.information).length > 0 && (
                        <ReactStars
                          classNames="stars"
                          count={5}
                          size={24}
                          value={getRatingValue()}
                          isHalf={true}
                          activeColor="#1c75bb"
                          onChange={ratingChanged}
                        />
                      )}
                      {/* {this is for create flow} */}
                      {Object.keys(additionalInfo.information).length <= 0 && (
                        <ReactStars
                          classNames="stars"
                          count={5}
                          size={24}
                          isHalf={true}
                          activeColor="#1c75bb"
                          onChange={ratingChanged}
                        />
                      )}
                    </Col>
                  )}
                </Col>
                <Col
                  className="col-md-6"
                  onClick={(event) => {
                    onBoxSelected(event, "module-uses");
                  }}
                >
                  <div>
                    {additionalInfo.information.moduleUsageRecommendation &&
                      additionalInfo.information.moduleUsageRecommendation !==
                        "" && (
                        <span className="editor-label">
                          {i18next.t("additionalInfo.moduleUses")}
                        </span>
                      )}
                    <div id="module-uses" className="editor-box">
                      <FroalaEditor
                        forwardedRef={frInstanceUsage}
                        placeHolder={i18next.t("additionalInfo.moduleUses")}
                        selectedAtom={
                          additionalInfo.information.moduleUsageRecommendation
                        }
                        handleModelChange={onChangeModuleuses}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {!isDenovoModule() && (
                <Row className="add-info-row row-margins">
                  <Col className="col-md-6 d-flex">
                    <span className="module-rating">Module Rating</span>
                    {/* {this is for modify flow} */}
                    {Object.keys(additionalInfo.information).length > 0 && (
                      <ReactStars
                        classNames="stars"
                        count={5}
                        size={24}
                        value={getRatingValue()}
                        isHalf={true}
                        activeColor="#1c75bb"
                        onChange={ratingChanged}
                      />
                    )}
                    {/* {this is for create flow} */}
                    {Object.keys(additionalInfo.information).length <= 0 && (
                      <ReactStars
                        classNames="stars"
                        count={5}
                        size={24}
                        isHalf={true}
                        activeColor="#1c75bb"
                        onChange={ratingChanged}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          {isThisMLRFeedbackFlow() ? (
            <Col
              className={` ${
                isPdfContentExpanded ? "col-md-12" : "col-12 col-md-4"
              }`}
              style={{ display: isMainContentExpanded ? "none" : "" }}
              ref={pdfElementRef}
            >
              <img
                src={fullScreenIcon}
                alt=""
                className="groupPdfExpand"
                onClick={() => setPdfcontentExpanded(!isPdfContentExpanded)}
              />
              <div className="previewFeedbackAdditionalInfoScroll customScrollBar mlr-groups-pdf">
                <RenderMlrFeedback pdfFileUrl={pdfUrl} pdfWidth={pdfwidth} />
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {renderMLRpopup()}
      </Container>
    </>
  );
};
export default AdditionalInfo;
