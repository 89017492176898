import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import infoIcon from "../../images/infoIcon.svg";
import searchIcon from "../../images/search.svg";
import fullScreenIcon from "../../images/fullScreenIcon.svg";

const BusinessRulesSearch = (props) => {
  const [searchedText, setSearchedText] = useState("");
  const {
    supportingClaimAtoms,
    selectedBox,
    businessRulesData,
    setBusinessRulesData,
    setSupportingClaimAtoms,
    setContentExists,
    allowedToAddRule,
    setCannotExistWith,
  } = props;

  const filteredSupportingClaimAtoms = supportingClaimAtoms.filter((module) => {
    if (searchedText === "" || searchedText.trim() === "") {
      return true;
    }
    // const text = searchedText.trim();
    return module &&
      ((module.name &&
        module.name.toLowerCase().indexOf(searchedText.toLowerCase()) > -1) ||
        (module.moduleCode &&
          module.moduleCode.toLowerCase().indexOf(searchedText.toLowerCase()) >
            -1))
      ? true
      : false;
  });

  const checkIfAlreadyExists = (modules, id, keyToCheck) => {
    let arr = [];
    modules.forEach((module) => {
      if (module.condition === keyToCheck) {
        arr.push(module.targetModuleId);
      }
    });

    return arr.indexOf(id) === -1 ? false : true;
  };

  const onSuppAtomDoubleClick = (item) => {
    if (!allowedToAddRule) {
      return;
    }
    const { id, name, moduleCode } = item;

    if (selectedBox.length === 0) {
      return;
    }

    if (checkIfAlreadyExists(businessRulesData, id, selectedBox)) {
      setContentExists("");
      setCannotExistWith(undefined);
      return;
    }

    if (selectedBox === "MustExistWith") {
      if (
        checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith") ||
        checkIfAlreadyExists(businessRulesData, id, "RelatedTo")
      ) {
        setContentExists("MustExistWith");
        if (checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (checkIfAlreadyExists(businessRulesData, id, "RelatedTo")) {
          setCannotExistWith("Module already added under Optional");
        }

        return;
      }
    }

    if (selectedBox === "InCompatibleWith") {
      if (
        checkIfAlreadyExists(businessRulesData, id, "MustExistWith") ||
        checkIfAlreadyExists(businessRulesData, id, "Succeeds") ||
        checkIfAlreadyExists(businessRulesData, id, "Precedes") ||
        checkIfAlreadyExists(businessRulesData, id, "RelatedTo")
      ) {
        setContentExists("InCompatibleWith");
        setCannotExistWith("Module already added under other rules");
        return;
      }
    }

    if (selectedBox === "Precedes") {
      if (
        checkIfAlreadyExists(businessRulesData, id, "Succeeds") ||
        checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")
      ) {
        setContentExists("Precedes");
        if (checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (checkIfAlreadyExists(businessRulesData, id, "Succeeds")) {
          setCannotExistWith("Module already added under Succeeds");
        }
        return;
      }
    }

    if (selectedBox === "Succeeds") {
      if (
        checkIfAlreadyExists(businessRulesData, id, "Precedes") ||
        checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")
      ) {
        setContentExists("Succeeds");
        if (checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (checkIfAlreadyExists(businessRulesData, id, "Precedes")) {
          setCannotExistWith("Module already added under Precedes");
        }
        return;
      }
    }
    if (selectedBox === "RelatedTo") {
      if (
        checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith") ||
        checkIfAlreadyExists(businessRulesData, id, "MustExistWith")
      ) {
        setContentExists("RelatedTo");
        if (checkIfAlreadyExists(businessRulesData, id, "InCompatibleWith")) {
          setCannotExistWith("Module already added under ‘Cannot Exist With’");
        } else if (
          checkIfAlreadyExists(businessRulesData, id, "MustExistWith")
        ) {
          setCannotExistWith("Module already added under Mandatory");
        }
        return;
      }
    }

    const updatedData = [
      ...businessRulesData,
      {
        requestId: uuidv4(),
        category: "Module-To-Module",
        condition: selectedBox,
        targetModuleId: id,
        status: "Active",
        name: name,
        moduleCode: moduleCode,
      },
    ];

    setBusinessRulesData(updatedData);

    const formattedData = supportingClaimAtoms.map((claim) => {
      let count = 0;
      updatedData.forEach((supportingClaim) => {
        if (supportingClaim.targetModuleId === claim.id) {
          count++;
        }
      });
      return { ...claim, count };
    });

    setSupportingClaimAtoms(formattedData);
    setContentExists("");
    setCannotExistWith(undefined);
  };

  return (
    <>
      <p className="groupSupportClaimTitle">Browse Business Rules</p>
      <img src={fullScreenIcon} alt="" className="expandBusinessRules" />
      <p className="moduleToModuleTitle">Module to Module</p>
      <div className="suppClaimSearch">
        <div className="d-flex">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            type="text"
            placeholder="Search module"
            className="globalSearchInput"
            value={searchedText}
            onChange={(e) => setSearchedText(e.target.value)}
          />
        </div>
      </div>
      <div className="businessRulesModuleList mt-4 customScrollBar">
        <ul
          className={`supClaimList  ${
            allowedToAddRule ? "" : "frozen-brule-box"
          }`}
        >
          {filteredSupportingClaimAtoms.map((item, index) => (
            <>
              {!item.standalone && item.primaryClaim ? (
                <li
                  key={item.id}
                  onDoubleClick={() => onSuppAtomDoubleClick(item)}
                  className={
                    props?.moduleCode === item.moduleCode
                      ? "d-none"
                      : "moduleListBusiness"
                  }
                >
                  <div className="leftAtomsList">
                    <p className="listModuleName">{item.name}</p>{" "}
                    {item.moduleCode && (
                      <p className="listModuleId">
                        <span>Module ID</span>
                        {item.moduleCode}
                      </p>
                    )}{" "}
                    {item.count === 0 ? (
                      ""
                    ) : (
                      <>
                        {item.count && (
                          <span className="usedModuleCount">
                            Used in {item.count} rules
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </li>
              ) : null}
            </>
          ))}
        </ul>
        <p className="mt-3">
          Promotional claim field is empty, cannot be added to business rule
          <img
            src={infoIcon}
            alt="Promotional claim field is empty"
            class="ml-2"
          />
        </p>
        <ul className="supClaimList standAloneModulesList mt-3">
          {filteredSupportingClaimAtoms.map((item) => (
            <>
              {!item.standalone && !item.primaryClaim ? (
                <li>
                  <div className="leftAtomsList">
                    <p className="listModuleName">{item.name}</p>{" "}
                    {item.moduleCode && (
                      <p className="listModuleId">
                        <span>Module ID</span> {item.moduleCode}
                      </p>
                    )}
                  </div>
                </li>
              ) : null}
            </>
          ))}
        </ul>
        <p className="mt-3">
          Stand Alone Modules{" "}
          <img src={infoIcon} alt="Stand Alone Modules" class="ml-2" />
        </p>
        <ul className="supClaimList standAloneModulesList mt-3">
          {filteredSupportingClaimAtoms.map((item) => (
            <>
              {item.standalone && (
                <li>
                  <div className="leftAtomsList">
                    <p className="listModuleName">{item.name}</p>{" "}
                    {item.moduleCode && (
                      <p className="listModuleId">
                        <span>Module ID</span> {item.moduleCode}
                      </p>
                    )}
                  </div>
                </li>
              )}
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BusinessRulesSearch;
