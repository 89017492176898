import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import filterIcon from "../images/filterIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import "../styles/components/FilterByCategory.scss";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: "9999",
    display: "inline-block"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export default function MenuListComposition(props) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([]);
  //const [categoryList, setCategoryList] = useState(props.catlistitems);
  const classes = useStyles();
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClosePopup = () => {
    setOpen(false);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    //   let filterData = []
    //   for (var key in state) {
    //     if (state.hasOwnProperty(key) && state[key] === true) {
    //         filterData.push(key)
    //     }
    //     if(props.handleFilter){
    //       props.handleFilter(filterData)
    //     }
    // }
    props.handleFilter(state);
    setOpen(false);
  };

  const handleChange = event => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    if (state.indexOf(event.target.name) !== -1) {
      //Remove
      setState(
        state.filter(item => {
          return item !== event.target.name;
        })
      );
    } else {
      //Add
      setState(state.concat(event.target.name));
    }
  };
  const uncheckAll = () => {
    setState([]);
    props.handleFilter([]);
    // let inputs = document.getElementsByClassName('checkBox');
    // for (var i = 0; i < inputs.length; i++) {
    //     inputs[i].checked = false;
    // }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    console.log(`apply filter on, ${props}`);
    // setCategoryList(props.catlistitems);
  }, []);

  const setPreviousSelections = () => {
    // let defaultSelectedItems = []
    // for (const filter of props.filterData){
    //     defaultSelectedItems.push({[filter] : true})
    // }
    // setState(defaultSelectedItems)

    setState(props.filterData);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    if (open) {
      setPreviousSelections();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`filterContainer ${classes.root}`}>
      <button
        className="btn btn-link pd-0"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <img src={filterIcon} className="filterIconSelectPage" alt="" />
      </button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="filterOuterContainer"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            // style={{
            //   transformOrigin: placement === "bottom-end",
            // }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopup}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <div className="categoryDivScroll">
                    <div className="filterTittlebtn">
                      <p>Filter Categories</p>
                      <button
                        type="button"
                        onClick={uncheckAll}
                        className="filterResetBtn"
                      >
                        Reset All
                      </button>
                    </div>
                    {props.catlistitems.map(option => (
                      <MenuItem key={option}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={option}
                              className="checkBox"
                              onChange={handleChange}
                              name={option}
                              color="primary"
                              size="small"
                              checked={
                                state.indexOf(option) !== -1 ? true : false
                              }
                            />
                          }
                          label={option}
                        />
                      </MenuItem>
                    ))}
                  </div>
                  <div className="filterHorizontalLine"></div>
                  <button
                    type="button"
                    color="primary"
                    className="filterDoneBtn"
                    onClick={handleClose}
                  >
                    APPLY FILTERS
                  </button>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
