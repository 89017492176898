import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  getAssetsAndModules,
  getAssetDetails,
  fetchLocalizedModules,
} from "../../services/apis";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ExistingTacticsFilter from "./ExistingTacticsFilter";

import "../../styles/components/ExistingTatics.scss";
import "../../styles/CustomToastify.scss";
import CustomModal from "../../components/customModal";
import SearchTacticsAndModules from "./SearchTacticsAndModules";

//Harversted and modularised tactics related imports
import HarvestedTactics from "./Harvested/HarvestedTactics";
import ModularisedTactics from "./Modularised/ModularisedTactics";
import LocalizedModules from "../Localized/LocalizedModules";

const ExistingTactics = (props) => {
  const { t } = useTranslation();
  const [assetAndModuleDetails, setAssetsAndModules] = useState([]);
  const [selectedAssetDetails, setSelectedAssetDetails] = useState({});
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [filteredChannelList, setFilteredChannelList] = useState([]);
  const [moduleTactic, setModuleTactic] = useState([]);
  const [alertForBrands, setAlertForBrands] = useState(false);
  const [filteredMarket, setFilteredMarket] = useState([]);
  const [filteredMarketList, setFilteredMarketList] = useState([]);
  const [assetDetailsById, setAssetDetailsById] = useState({});
  const [pages, setPages] = useState([]);
  const [filteredPages, setFiletredPages] = useState([]);
  const [isModularisedTacticsSelected, setIsModularisedTacticsSelected] =
    useState(true);

  const [isICBSearchStarted, setIsICBSearchStarted] = useState(false);
  const [isHarvestedsearchReset, setIsHarvestedSearchReset] = useState(false);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [campaignFilterList, setCampaignFilterList] = useState([]);
  const [titleType, setTitleType] = useState("initial");
  const [brandsList, setBrandsList] = useState([]);
  const [marketSearchTeram, setMarketSearchTeram] = useState("");

  // For Localized/Translated
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [localizedModules, setLocalizedModules] = useState([]);
  const [localizedModuleGroups, setLocalizedModuleGroups] = useState([]);
  const [brandErrorFlag, setBrandErrorFlag] = useState(false);
  const [isLocalizedModulesSelected, setIsLocalizedModulesSelected] =
    useState(false);
  const [isSearchGroupChecked, setIsSearchGroupChecked] = useState(false);

  useEffect(() => {
    if (isLocalizedModulesSelected) {
      if (filteredBrands.length > 0 && filteredCampaigns.length > 0) {
        setBrandErrorFlag(false);
      } else {
        setBrandErrorFlag(true);
      }
    }
  }, [filteredBrands, filteredCampaigns, isLocalizedModulesSelected]);

  useEffect(() => {
    setCampaigns(getCampaignList());
  }, [filteredBrands]);

  useEffect(() => {
    clearAllSearch();
    if (!isLocalizedModulesSelected) {
      setIsSearchGroupChecked(false);
    }
    if (isLocalizedModulesSelected) {
      searchLocalizedModules(false, "Grouped");
    }
  }, [isModularisedTacticsSelected, isLocalizedModulesSelected]);

  const getCampaignList = (brands = [...filteredBrands]) => {
    let campaignList = [];
    brands.forEach((brand) => {
      let brandAndCampaign = brandsAndCampaigns.find(
        (el) => el.brandName === brand
      );
      if (brandAndCampaign) {
        campaignList = campaignList.concat(
          brandAndCampaign.campaigns.map((el) => el.campaignName)
        );
      }
    });
    return campaignList;
  };

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getCreatedAssetDetails = (callback, isReset = false) => {
    let searchParams = {
      brands: filteredBrands || [],
      channelTypes: filteredChannels || [],
      markets: filteredMarket || [],
      campaigns: filteredCampaigns || [],
      searchText: document
        .getElementById("search-tactics-from-icb")
        ?.value.trim(),
    };

    if (isReset) {
      searchParams = {
        brands: [],
        channelTypes: [],
        markets: [],
        campaigns: [],
        searchText: "",
      };
    }

    setIsloading(true);
    getAssetsAndModules(searchParams, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
        setIsloading(false);
      } else {
        const assets = response.data;

        //sort based on deconstruction time
        assets.sort(function (a, b) {
          return new Date(b.createdDateTime) - new Date(a.createdDateTime);
        });

        callback(assets);
        setIsloading(false);
        setAssetsAndModules(assets);
      }
    });
  };

  const getCreatedAssetDetailsById = (assetId, callback) => {
    setIsloading(true);
    getAssetDetails((response, err, errorCode) => {
      setIsloading(false);
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
        callback(false);
      } else {
        const children = response.data.children;
        if (children) {
          let pages = [];
          pages = children.filter((child) => {
            return child.type === "Page";
          });
          pages = pages.map((page) => {
            const atoms = children.filter((child) => {
              return child.type === "Atom" && child.parent === page._id;
            });
            page = { ...page, atoms };
            return page;
          });
          //Sort the array
          pages.sort((page1, page2) => {
            return page1.MetaData.page - page2.MetaData.page;
          });
          setPages(pages);
          setFiletredPages(pages);
        }
        setAssetDetailsById(response.data);
        callback(true);
      }
    }, assetId);
  };

  const setSelectedAssetHandler = (assets, assetId) => {
    // console.log("Harvested Asset ID", assetId);
    if (!assetId) {
      const selectedAsset = assets[0];
      if (selectedAsset) {
        getCreatedAssetDetailsById(assets[0].storageAssetId, (loaded) => {
          setSelectedAssetDetails(loaded ? selectedAsset : {});
        });
      }

      return;
    }

    const result = assets.find(function (element) {
      if (element.storageAssetId === assetId) {
        return true;
      } else {
        return false;
      }
    });
    getCreatedAssetDetailsById(assetId, (loaded) => {
      setSelectedAssetDetails(loaded ? result : {});
    });
  };

  const removeFromFilteredLanguageList = (language) => {
    const position = filteredLanguages.indexOf(language);
    const newFilteredLanguages = [...filteredLanguages];
    newFilteredLanguages.splice(position, 1);
    setFilteredLanguages(newFilteredLanguages);
  };

  const removeitemFromFilteredList = (item) => {
    const index = filteredBrands.indexOf(item);
    const newFilteredBrands = [...filteredBrands];
    newFilteredBrands.splice(index, 1);
    setFilteredBrands(newFilteredBrands);
    setFilteredList(newFilteredBrands);
  };

  const removeitemFromFilteredCampaignList = (item) => {
    const position = filteredCampaigns.indexOf(item);
    const newFilteredCampaigns = [...filteredCampaigns];
    newFilteredCampaigns.splice(position, 1);
    setFilteredCampaigns(newFilteredCampaigns);
    setCampaignFilterList(newFilteredCampaigns);
  };

  const removeitemFromFilteredListChannel = (item) => {
    const position = filteredChannels.indexOf(item);
    const newFilteredChannels = [...filteredChannels];
    newFilteredChannels.splice(position, 1);
    setFilteredChannels(newFilteredChannels);
    setFilteredChannelList(newFilteredChannels);
  };

  const removeitemFromFilteredListMarket = (item) => {
    const position = filteredMarket.indexOf(item);
    const newFilteredMarket = [...filteredMarket];
    newFilteredMarket.splice(position, 1);
    setFilteredMarket(newFilteredMarket);
    setFilteredMarketList(newFilteredMarket);
  };
  const handleModal = () => {
    setAlertForBrands(false);
  };
  const dialogMessage = () => {
    return "Product/Brand cannot be blank";
  };

  const searchLocalizedModules = (
    isReset = false,
    responseType = "List",
    originalResults = false
  ) => {
    //setBrandErrorFlag(false);
    let payload = {
      searchText: originalResults ? "" : getSearchText(),
      brands: originalResults ? [] : filteredBrands || [],
      channelTypes: filteredChannels || [],
      markets: filteredMarket || [],
      campaigns: originalResults ? [] : filteredCampaigns || [],
      languages: filteredLanguages || [],
    };
    if (isReset) {
      setLocalizedModules([]);
      setLocalizedModuleGroups([]);
      //return;
      payload = {
        searchText: "",
        brands: [],
        channelTypes: [],
        markets: [],
        campaigns: [],
        languages: [],
      };
    }

    setIsloading(true);
    fetchLocalizedModules(
      payload,
      responseType,
      "Deconstructed",
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          const resObj = response.data;
          if (resObj) {
            if (responseType === "Grouped") {
              if (resObj.groups) {
                setLocalizedModuleGroups([].concat(resObj.groups));
              } else {
                setLocalizedModuleGroups([]);
              }
            } else {
              setLocalizedModules([].concat(resObj.modules));
            }
          }

          return;
        }
        const errCoponent = ErrComponentToRender(errCode);
        getmoduleDetailsErr(errCoponent);
      }
    );
  };

  const searchTactics = (event) => {
    setTitleType("searched");

    if (isModularisedTacticsSelected) {
      getCreatedAssetDetails((assets) => {
        setSelectedAssetHandler(assets);
      });
      setBrandErrorFlag(false);
    } else if (isLocalizedModulesSelected) {
      if (filteredBrands.length < 1 || filteredCampaigns.length < 1) {
        setBrandErrorFlag(true);
      } else {
        setBrandErrorFlag(false);
        searchLocalizedModules(
          false,
          isSearchGroupChecked ? "Grouped" : "List"
        );
      }
    } else {
      setIsICBSearchStarted(true);

      setBrandErrorFlag(false);
    }
  };

  const resetModularisedTacticsData = () => {
    setAssetsAndModules([]);
    setSelectedAssetDetails({});
  };

  const clearAllSearch = (event) => {
    const element = document.getElementById("search-tactics-from-icb");
    if (
      event &&
      element.value === "" &&
      filteredBrands.length === 0 &&
      filteredChannels.length === 0 &&
      filteredMarket.length === 0 &&
      filteredCampaigns.length === 0 &&
      filteredLanguages.length === 0
    ) {
      return;
    }
    if (element) {
      element.value = "";
      setTitleType("initial");
    }
    setFilteredBrands([]);
    setFilteredChannels([]);
    setFilteredCampaigns([]);
    setFilteredMarket([]);
    setFilteredList([]);
    setFilteredChannelList([]);
    setFilteredMarketList([]);
    setFilteredCampaigns([]);
    setCampaignFilterList([]);
    setMarketSearchTeram("");
    setFilteredLanguages([]);
    setBrandErrorFlag(false);
    if (event && titleType === "initial") {
      return;
    }
    if (!isModularisedTacticsSelected && !isLocalizedModulesSelected) {
      resetModularisedTacticsData();
      setIsHarvestedSearchReset(true);
      setBrandErrorFlag(false);
    } else if (isLocalizedModulesSelected && !isModularisedTacticsSelected) {
      resetModularisedTacticsData();
      searchLocalizedModules(true, "Grouped");
      setBrandErrorFlag(true);
    } else {
      getCreatedAssetDetails((assets) => {
        setSelectedAssetHandler(assets);
      }, true);
      setBrandErrorFlag(false);
    }
  };

  const resetFilters = () => {
    setFilteredBrands([]);
    setFilteredChannels([]);
    setFilteredCampaigns([]);
    setFilteredMarket([]);
    setFilteredList([]);
    setFilteredChannelList([]);
    setFilteredMarketList([]);
    setFilteredCampaigns([]);
    setCampaignFilterList([]);
    setMarketSearchTeram("");
    setFilteredLanguages([]);
  };

  const getSearchText = () => {
    const element = document.getElementById("search-tactics-from-icb");
    if (element) {
      return element.value.trim();
    }
  };

  const refreshSelectAsset = () => {
    getCreatedAssetDetails((assets) => {
      setSelectedAssetHandler(assets, selectedAssetDetails.storageAssetId);
    });
  };

  useEffect(() => {
    filterResults();
  }, [
    filteredList,
    filteredChannelList,
    filteredMarketList,
    filteredCampaigns,
    filteredLanguages,
    filteredBrands,
    isSearchGroupChecked,
  ]);

  const filterResults = () => {
    if (isModularisedTacticsSelected) {
      getCreatedAssetDetails((assets) => {
        setSelectedAssetHandler(assets);
      });
      setBrandErrorFlag(false);
    } else if (isLocalizedModulesSelected) {
      if (filteredBrands.length < 1 || filteredCampaigns.length < 1) {
        if (
          filteredBrands.length < 1 &&
          filteredCampaigns.length < 1 &&
          titleType !== "searched"
        ) {
          searchLocalizedModules(
            false,
            isSearchGroupChecked || titleType !== "searched"
              ? "Grouped"
              : "List",
            true
          );
        }
      } else {
        searchLocalizedModules(
          false,
          isSearchGroupChecked || titleType !== "searched" ? "Grouped" : "List"
        );
      }
    } else {
      setBrandErrorFlag(false);
    }
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <CircularProgressBar isloading={isloading} />
          <div className="contentContainer existingTacticsContainer">
            <div className="row">
              <div className="col-md-2 existingSearhPanel">
                <ExistingTacticsFilter
                  filteredBrands={filteredBrands}
                  setFilteredBrands={setFilteredBrands}
                  setFilteredList={setFilteredList}
                  filteredChannels={filteredChannels}
                  setFilteredChannels={setFilteredChannels}
                  setFilteredChannelList={setFilteredChannelList}
                  setModuleTactic={setModuleTactic}
                  marketDataSource={props.data.marketDataSource}
                  languageDataSource={props.data.languageDataSource}
                  setFilteredMarket={setFilteredMarket}
                  filteredMarket={filteredMarket}
                  setFilteredMarketList={setFilteredMarketList}
                  isModularisedTacticsSelected={isModularisedTacticsSelected}
                  setIsModularisedTacticsSelected={
                    setIsModularisedTacticsSelected
                  }
                  isLocalizedModulesSelected={isLocalizedModulesSelected}
                  setIsLocalizedModulesSelected={setIsLocalizedModulesSelected}
                  setBrandsAndCampaigns={setBrandsAndCampaigns}
                  campaigns={campaigns}
                  filteredCampaigns={filteredCampaigns}
                  setFilteredCampaigns={setFilteredCampaigns}
                  setCampaignFilterList={setCampaignFilterList}
                  resetFilters={resetFilters}
                  brandsList={brandsList}
                  marketSearchTeram={marketSearchTeram}
                  setMarketSearchTeram={setMarketSearchTeram}
                  filteredLanguageList={filteredLanguages}
                  setFilteredLanguages={setFilteredLanguages}
                  brandErrorFlag={brandErrorFlag}
                />
              </div>
              <div className="col-md-10">
                <SearchTacticsAndModules
                  setTitleType={setTitleType}
                  startSearching={searchTactics}
                  searchBarPlaceholder={
                    isModularisedTacticsSelected
                      ? t("tacticSource.modularisedPlaceHolder")
                      : isLocalizedModulesSelected
                      ? t("tacticSource.searchLocalizedPlaceHolder")
                      : t("tacticSource.harvestedPlaceHolder")
                  }
                  isModularisedTacticsSelected={isModularisedTacticsSelected}
                  clearAllSearch={clearAllSearch}
                  filteredBrandList={filteredList}
                  filteredChannelList={filteredChannelList}
                  filteredMarketList={filteredMarketList}
                  campaignFilterList={campaignFilterList}
                  languageFilterList={filteredLanguages}
                  removeFromFilteredLanguageList={
                    removeFromFilteredLanguageList
                  }
                  removeitemFromBrandFilteredList={removeitemFromFilteredList}
                  removeitemFromFilteredListChannel={
                    removeitemFromFilteredListChannel
                  }
                  removeitemFromFilteredListMarket={
                    removeitemFromFilteredListMarket
                  }
                  removeitemFromFilteredCampaignList={
                    removeitemFromFilteredCampaignList
                  }
                  brandErrorFlag={brandErrorFlag}
                  isLocalizedModulesSelected={isLocalizedModulesSelected}
                  isSearchGroupChecked={isSearchGroupChecked}
                  setIsSearchGroupChecked={setIsSearchGroupChecked}
                />
                <div className="masterTacticModule"></div>
                {isModularisedTacticsSelected &&
                  !isLocalizedModulesSelected && (
                    <ModularisedTactics
                      titleType={titleType}
                      assetAndModuleDetails={assetAndModuleDetails}
                      selectedAssetDetails={selectedAssetDetails}
                      setSelectedAssetHandler={setSelectedAssetHandler}
                      filteredPages={filteredPages}
                      brandsList={brandsList}
                      getCampaignList={getCampaignList}
                      refreshSelectAsset={refreshSelectAsset}
                    />
                  )}
                {!isModularisedTacticsSelected &&
                  !isLocalizedModulesSelected && (
                    <HarvestedTactics
                      data={props.data}
                      titleType={titleType}
                      isICBSearchStarted={isICBSearchStarted}
                      setIsICBSearchStarted={setIsICBSearchStarted}
                      isHarvestedsearchReset={isHarvestedsearchReset}
                      setIsHarvestedSearchReset={setIsHarvestedSearchReset}
                      brandsList={filteredList}
                      allBrands={brandsList}
                      searchText={getSearchText()}
                      clearAllSearch={clearAllSearch}
                    />
                  )}
                {!isModularisedTacticsSelected &&
                  isLocalizedModulesSelected && (
                    <LocalizedModules
                      titleType={titleType}
                      localizedModules={localizedModules}
                      localizedModuleGroups={localizedModuleGroups}
                      data={props.data}
                      clearAllSearch={clearAllSearch}
                      searchGroupsOnly={
                        isLocalizedModulesSelected &&
                        (isSearchGroupChecked || titleType !== "searched")
                      }
                      searchLocalizedModules={searchLocalizedModules}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        displayModal={alertForBrands}
        hideModal={handleModal}
        title={"Filter By Alert"}
        size="sm"
        dilogClassName="alertModalSmall"
      >
        <p
          style={{
            textAlign: "center",
          }}
        >
          {dialogMessage()}
        </p>
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-link pl-0"
            style={{
              marginLeft: "49%",
            }}
            onClick={handleModal}
          >
            OK
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ExistingTactics;
