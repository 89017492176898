import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import FormControl from "@material-ui/core/FormControl";
import EventIcon from "@material-ui/icons/Event";
import TextField from "@material-ui/core/TextField";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import Switch from "@material-ui/core/Switch";
import { Form, Formik } from "formik";
import DropDownWithSearch from "../Uitls/DropDownWithSearch";
import "../styles/components/translateModule.scss";
import ReactStars from "react-rating-stars-component";
import searchIcon from "../images/masterTacticSearchIcon.svg";
import clearTextIcon from "../images/close_icon.svg";
import { getTranslatorsAndAgencies } from "../services/apis";
import FroalaEditor from "../Uitls/CommonFroalEditorComponent";
import { getMonth } from "../Uitls/Util";

const TranslateModule = ({
  module,
  marketDataSource,
  languageDataSource,
  goToComponentSelection,
  hideModal,
  handleSaveTranslation,
  jobInfo,
  groupToBeTranslatedData,
  modulesDetailsWithSourceLanguages,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [disbleProceed, setDisableProceed] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [othersfilled, setOthersFilled] = useState(false);
  const [isTranslateInfo, setIsTranslateInfo] = useState(false);
  const [dueDate, setDueDate] = useState(undefined);
  const [jobType, setJobType] = useState("");
  const [isSearchBarEmpty, setIsSearchBarEmpty] = useState(true);
  const [masterTranslatorAndAgencies, setMasterTranslatorAndAgencies] =
    useState([]);
  const [traslators, setTraslators] = useState([]);
  const [agency, setAgency] = useState([]);
  const [searchText, setSearchText] = useState(undefined);
  const [helper, setHelper] = useState(false);
  const [languageTabs, setLanguageTabs] = useState([]);
  useEffect(() => {
    fetchTranslatorsAndAgencies();
  }, []);
  useEffect(() => {
    if (!jobInfo) {
      let languages = [...groupToBeTranslatedData].map((el, index) => {
        let moduleDetails = [];
        let modulesToBeTranslated = el.modules;
        modulesToBeTranslated.forEach((module) => {
          modulesDetailsWithSourceLanguages.filter((item) => {
            if (
              item.sourceModuleId === module.sourceModuleId &&
              item.moduleId === module.id
            ) {
              let itemObject = {
                language: item.language,
                moduleId: item.moduleId,
                refSourceModId: item.refSourceModId,
              };
              moduleDetails.push(itemObject);
            }
          });
        });
        const selectedMarket = getSelectedMarket();
        let selectedTabIndex = getSelectedLanguageTab();
        selectedTabIndex = selectedTabIndex - 1;
        let languageData = [...languageTabs];
        let selectedTab = languageData[selectedTabIndex];
        return {
          language: el.language,
          isSelected: index === 0 ? true : false,
          languageCode: getLanguageCode(el.language),
          market: selectedMarket ? selectedMarket.displayName : "",
          marketCode: selectedMarket ? selectedMarket.code : "",
          dueDate: selectedTab ? selectedTab.dueDate : "",
          instruction: "",
          jobCreator: localStorage.getItem("currentUserName"),
          jobType: "",
          jobDetails: {},
          moduleDetails: moduleDetails,
        };
      });

      setLanguageTabs(languages);
    } else {
      let dateArray = jobInfo.dueDate.split("-"); // ["29", "1", "2016"]
      let dueDate = new Date(
        parseInt(dateArray[0]),
        parseInt(dateArray[1]) - 1,
        parseInt(dateArray[2])
      );
      let marketObj = marketDataSource.find((market) => {
        return market.displayName === jobInfo.market;
      });
      let languageObject = {
        language: jobInfo.targetLanguage,
        isSelected: true,
        market: marketObj.name,
        marketCode: marketObj.code,
        dueDate: dueDate,
        jobDetails: {},
        jobType: "",
        languageCode: getLanguageCode(jobInfo.targetLanguage),
        jobCreator: jobInfo.reviewerName,
      };
      let translatorObj = traslators.find((item) => {
        return item.userId === jobInfo.translatorId;
      });
      if (translatorObj) {
        let jobDetailsObj = {
          id: translatorObj.id,
          name: translatorObj.name,
          email: translatorObj.email,
          userId: translatorObj.userId,
        };
        languageObject.jobDetails = jobDetailsObj;
        languageObject.jobType = "Translator";
        setIsTranslateInfo(false);
      }
      let agencyObj = agency.find((item) => {
        return item.userId === jobInfo.translatorId;
      });
      if (agencyObj) {
        let jobDetailsObj = {
          id: agencyObj.id,
          name: agencyObj.name,
          email: agencyObj.email,
          userId: agencyObj.userId,
        };
        languageObject.jobDetails = jobDetailsObj;
        languageObject.jobType = "Agency";
        setIsTranslateInfo(true);
      }
      let languageTabData = [];
      languageTabData.push(languageObject);
      setLanguageTabs(languageTabData);
    }
  }, [groupToBeTranslatedData]);

  useEffect(() => {
    if (jobInfo) {
      let dateArray = jobInfo.dueDate.split("-"); // ["29", "1", "2016"]
      let dueDate = new Date(
        parseInt(dateArray[0]),
        parseInt(dateArray[1]) - 1,
        parseInt(dateArray[2])
      );
      let marketObj = marketDataSource.find((market) => {
        return market.displayName === jobInfo.market;
      });
      let languageObject = {
        language: jobInfo.targetLanguage,
        isSelected: true,
        market: marketObj.displayName,
        marketCode: marketObj.code,
        dueDate: dueDate,
        jobDetails: {},
        jobType: "",
        languageCode: getLanguageCode(jobInfo.targetLanguage),
        jobCreator: jobInfo.reviewerName,
      };
      let translatorObj = traslators.find((item) => {
        return item.userId === jobInfo.translatorId;
      });
      if (translatorObj) {
        let jobDetailsObj = {
          id: translatorObj.id,
          name: translatorObj.name,
          email: translatorObj.email,
          userId: translatorObj.userId,
        };
        languageObject.jobDetails = jobDetailsObj;
        languageObject.jobType = "Translator";
        setIsTranslateInfo(false);
      }
      let agencyObj = agency.find((item) => {
        return item.userId === jobInfo.translatorId;
      });
      if (agencyObj) {
        let jobDetailsObj = {
          id: agencyObj.id,
          name: agencyObj.name,
          email: agencyObj.email,
          userId: agencyObj.userId,
        };
        languageObject.jobDetails = jobDetailsObj;
        languageObject.jobType = "Agency";
        setIsTranslateInfo(true);
      }
      let languageTabData = [];
      languageTabData.push(languageObject);
      setLanguageTabs(languageTabData);
    }
  }, [jobInfo, agency, traslators]);

  const getLanguageCode = (languageToBeSearched) => {
    let languageObj = languageDataSource.find((language) => {
      return language.language === languageToBeSearched;
    });
    return languageObj.languageCode;
  };
  useEffect(() => {
    filterTranslatorsOrAgencies(searchText);
  }, [searchText]);

  const filterTranslatorsOrAgencies = async () => {
    const selectedMarket = getSelectedMarket();
    let selectedTabIndex = getSelectedLanguageTab() - 1;
    if (selectedTabIndex <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (!selectedMarket && !searchText) {
      let translators = [];
      masterTranslatorAndAgencies.forEach((el) => {
        translators = translators.concat(el.translators);
      });
      let resultTranslators = filterDuplicate(translators);

      let agencies = [];
      masterTranslatorAndAgencies.forEach((el) => {
        agencies = agencies.concat(el.agencies);
      });
      let resultAgency = filterDuplicate(agencies);
      setHelper(false);
      await setLanguageTabs(languageData);
      setTraslators(resultTranslators);
      setAgency(resultAgency);
    } else {
      if (selectedMarket) {
        const found = masterTranslatorAndAgencies.find((el) => {
          return el.market === selectedMarket.displayName;
        });
        if (found) {
          if (searchText) {
            let translators = found.translators;
            translators = [...translators].filter((translator) => {
              return (
                translator.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                translator.email
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            });
            setHelper(true);
            let resultTranslators = filterDuplicate(translators);

            //setTraslators(translators);
            let agencies = found.agencies;
            agencies = [...agencies].filter((agency) => {
              return (
                agency.name.toLowerCase().includes(searchText.toLowerCase()) ||
                agency.email.toLowerCase().includes(searchText.toLowerCase())
              );
            });
            let resultAgency = filterDuplicate(agencies);

            if (resultTranslators.length > 0 && !isTranslateInfo) {
              setJobType("Translator");

              languageData[selectedTabIndex].jobType = "Translator";
              let jobDetailsObj = {
                id: resultTranslators[0].id,
                name: resultTranslators[0].name,
                email: resultTranslators[0].email,
                userId: resultTranslators[0].userId,
              };
              languageData[selectedTabIndex].jobDetails = jobDetailsObj;
            } else if (resultAgency.length > 0 && isTranslateInfo) {
              setJobType("Agency");

              languageData[selectedTabIndex].jobType = "Agency";
              let jobDetailsObj = {
                id: resultAgency[0].id,
                name: resultAgency[0].name,
                email: resultAgency[0].email,
                userId: resultAgency[0].userId,
              };
              languageData[selectedTabIndex].jobDetails = jobDetailsObj;
            }
            await setLanguageTabs(languageData);
            setTraslators(resultTranslators);
            setAgency(resultAgency);
          } else {
            setHelper(false);
            await setLanguageTabs(languageData);
            setTraslators(found.translators);
            setAgency(found.agencies);
          }
        } else {
          setHelper(false);
          await setLanguageTabs(languageData);
          setTraslators([]);
          setAgency([]);
        }
      } else {
        let translators = [];
        masterTranslatorAndAgencies.forEach((el) => {
          translators = translators.concat(el.translators);
        });
        translators = [...translators].filter((translator) => {
          return (
            translator.name.toLowerCase().includes(searchText.toLowerCase()) ||
            translator.email.toLowerCase().includes(searchText.toLowerCase())
          );
        });
        let resultTranslators = filterDuplicate(translators);

        let agencies = [];
        masterTranslatorAndAgencies.forEach((el) => {
          agencies = agencies.concat(el.agencies);
        });
        agencies = [...agencies].filter((agency) => {
          return (
            agency.name.toLowerCase().includes(searchText.toLowerCase()) ||
            agency.email.toLowerCase().includes(searchText.toLowerCase())
          );
        });
        let resultAgency = filterDuplicate(agencies);

        setHelper(true);
        let selectedTabIndex = getSelectedLanguageTab() - 1;
        let languageData = [...languageTabs];
        if (resultTranslators.length > 0 && !isTranslateInfo) {
          //setJobType("Translator");

          languageData[selectedTabIndex].jobType = "Translator";
          let jobDetailsObj = {
            id: resultTranslators[0].id,
            name: resultTranslators[0].name,
            email: resultTranslators[0].email,
            userId: resultTranslators[0].userId,
          };
          languageData[selectedTabIndex].jobDetails = jobDetailsObj;
        } else if (resultAgency.length > 0 && isTranslateInfo) {
          //setJobType("Agency");

          languageData[selectedTabIndex].jobType = "Agency";
          let jobDetailsObj = {
            id: resultAgency[0].id,
            name: resultAgency[0].name,
            email: resultAgency[0].email,
            userId: resultAgency[0].userId,
          };
          languageData[selectedTabIndex].jobDetails = jobDetailsObj;
        }
        await setLanguageTabs(languageData);
        setTraslators(resultTranslators);
        setAgency(resultAgency);
      }
    }
  };
  const fetchTranslatorsAndAgencies = () => {
    setIsloading(true);
    getTranslatorsAndAgencies((response, err, errCode) => {
      setIsloading(false);
      if (err || errCode) {
        return;
      }
      setMasterTranslatorAndAgencies(response.data);
      let translators = [];
      response.data.forEach((el) => {
        translators = translators.concat(el.translators);
      });
      let resultTranslators = filterDuplicate(translators);
      setTraslators(resultTranslators);
      let agencies = [];
      response.data.forEach((el) => {
        agencies = agencies.concat(el.agencies);
      });
      let resultAgency = filterDuplicate(agencies);
      setAgency(resultAgency);
    });
  };
  const filterDuplicate = (data) => {
    if (data.length > 0) {
      let resultTranslators = data.filter(
        (item, i, array) =>
          array.findIndex((element) => element.email === item.email) === i
      );
      return resultTranslators;
    }
    return [];
  };
  const validateMarket = () => {
    if (!selectedMarket) {
      return false;
    }
    return true;
  };
  const validate = (values) => {
    const errors = {};
    if (!values.moduleName) {
      // errors.campaignUsed = "Campaign it was used required";
      errors.moduleName = i18next.t("cloneModule.moduleNameError");
    }

    const campaignErr = i18next.t("uploadAssetLabel.campaignError");
    if (!values.Campaign) {
      errors.campaign = campaignErr;
    } else if (values.Campaign.trim() === "") {
      errors.campaign = campaignErr;
    }

    if (Object.keys(errors).length === 0) {
      setOthersFilled(true);
      if (validateMarket()) {
        setDisableProceed(false);
      } else {
        setDisableProceed(true);
      }
    } else {
      setOthersFilled(false);
      setDisableProceed(true);
    }
    if (Object.keys(errors).length === 0) {
      setOthersFilled(true);
    } else {
      setOthersFilled(false);
      setDisableProceed(true);
    }
    return errors;
  };

  const getInitialvalues = () => {
    let initailValues = {
      Campaign: getDefaultValue("campaign"),
      market: getDefaultValue("market") || "",
    };
    return initailValues;
  };

  const getDefaultValue = (type) => {
    if (!module) {
      return undefined;
    }
    const tactic = module;
    let value = "";
    switch (type) {
      case "market":
        value = tactic.assetDetails.market || "";
        break;
      case "language":
        value = tactic.assetDetails.language || "";
        break;
      default:
        value = "";
    }
    return value;
  };

  const getMarketByName = (marketName) => {
    const marketDataSourceData = marketDataSource;
    let selectedMarket = undefined;
    if (marketDataSourceData && marketDataSourceData.length > 0 && marketName) {
      selectedMarket = marketDataSourceData.find((market) => {
        return (
          market.displayName === marketName ||
          market.name === marketName ||
          market.code === marketName ||
          market.shortName === marketName
        );
      });
    }
    return selectedMarket;
  };
  const getLanguageData = (languageName) => {
    const languageDataSourceData = marketDataSource;
    let selectedLanguage = undefined;
    if (
      languageDataSourceData &&
      languageDataSourceData.length > 0 &&
      languageName
    ) {
      selectedLanguage = languageDataSourceData.find((language) => {
        return (
          language.languageCode === languageName ||
          language.icbCode === languageName ||
          language.language === languageName ||
          language.market === languageName
        );
      });
    }
    return selectedLanguage;
  };
  const handleMarketChange = async (market) => {
    if (!market) {
      return;
    }
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    languageData[index].market = market.displayName;
    languageData[index].marketCode = market.code;

    await setLanguageTabs(languageData);
    filterTranslatorsOrAgencies();
  };
  const agencyInfo = () => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (!isSearchBarEmpty && helper) {
      if (agency.length > 0) {
        languageData[index].jobType = "Agency";
        let jobDetailsObj = {
          id: agency[0].id,
          name: agency[0].name,
          email: agency[0].email,
          userId: agency[0].userId,
        };
        languageData[index].jobDetails = jobDetailsObj;
        setLanguageTabs(languageData);
      }
    }
    setIsTranslateInfo(true);
  };
  const translatorInfo = () => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (!isSearchBarEmpty && helper) {
      if (traslators.length > 0) {
        languageData[index].jobType = "Translator";
        let jobDetailsObj = {
          id: traslators[0].id,
          name: traslators[0].name,
          email: traslators[0].email,
          userId: traslators[0].userId,
        };
        languageData[index].jobDetails = jobDetailsObj;
        setLanguageTabs(languageData);
      }
    }
    setIsTranslateInfo(false);
  };
  const CustomInput = ({ value, onClick }) => {
    let date;
    if (languageTabs.length < 1) {
      date = "";
    } else {
      let index = getSelectedLanguageTab();
      index = index - 1;
      if (index <= -1) {
        return <></>;
      }
      let languageData = [...languageTabs];
      date = languageData[index].dueDate;
    }

    return (
      <FormControl fullWidth>
        <TextField
          label="Due Date"
          variant="outlined"
          onClick={onClick}
          value={value}
          InputProps={{
            endAdornment: (
              <EventIcon
                className={date ? "selectedDate" : "notSelectedDate"}
              />
            ),
          }}
        />
      </FormControl>
    );
  };
  const handleTranslatorRadio = (event, translator) => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (event.target.value) {
      languageData[index].jobType = event.target.value;
      let jobDetailsObj = {
        id: translator.id,
        name: translator.name,
        email: translator.email,
        userId: translator.userId,
      };
      languageData[index].jobDetails = jobDetailsObj;
      setHelper(false);
      if (jobInfo && jobInfo) {
        let currentTime = new Date();
        let currentDate = dateFormatter(currentTime);
        currentDate = new Date(currentDate);
        if (dueDate < currentDate) {
          languageData[index].dueDate = "";
        }
      }
      setLanguageTabs(languageData);
    } else {
      return;
    }
  };
  const handleAgencyRadio = (event, agency) => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (event.target.value) {
      languageData[index].jobType = event.target.value;
      let jobDetailsObj = {
        id: agency.id,
        name: agency.name,
        email: agency.email,
        userId: agency.userId,
      };
      languageData[index].jobDetails = jobDetailsObj;
      if (jobInfo && jobInfo) {
        let currentTime = new Date();
        let currentDate = dateFormatter(currentTime);
        currentDate = new Date(currentDate);
        if (dueDate < currentDate) {
          languageData[index].dueDate = "";
        }
      }
      setLanguageTabs(languageData);
      setHelper(false);
    } else {
      return;
    }
  };
  const checkForAllValues = () => {
    if (languageTabs && languageTabs.length > 0) {
      let result = true;
      for (var i in languageTabs) {
        if (
          languageTabs[i].jobType === "" ||
          languageTabs[i].marketCode === "" ||
          (Object.keys(languageTabs[i]?.jobDetails)?.length === 0 &&
            languageTabs[i].jobType !== "Self") ||
          languageTabs[i].dueDate === ""
        ) {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  };
  const handleFinishCta = () => {
    let data = languageTabs.map((element) => {
      let date = dateFormatter(element.dueDate);
      return {
        language: element.language,
        languageCode: element.languageCode,
        market: element.market,
        marketCode: element.marketCode,
        dueDate: date,
        jobCreator: element.jobCreator,
        jobType: element.jobType,
        jobDetails: element.jobType === "Self" ? {} : element.jobDetails,
        moduleDetails: element.moduleDetails,
        instruction: element.instruction,
        jobCreatorEmail: localStorage.getItem("userEmail"),
      };
    });
    handleSaveTranslation(data);
  };
  const clearSearchBar = () => {
    const element = document.getElementById("search-translator-agency");
    if (element) {
      element.value = "";
      setIsSearchBarEmpty(true);
      setSearchText(undefined);
    }
  };

  const drawTranslatorData = (translator, index, type) => {
    let jobDetails;
    if (languageTabs.length < 1) {
      jobDetails = "";
    } else {
      let index = getSelectedLanguageTab();
      index = index - 1;
      if (index <= -1) {
        return "";
      }
      let languageData = [...languageTabs];
      jobDetails = languageData[index].jobDetails;
    }
    return (
      <Row className="translator-agency">
        <Col className="col-12 col-md-4">
          <input
            id="radio"
            name={type.toLowerCase()}
            value={type}
            type="radio"
            className="form-check-input"
            checked={jobDetails.id === translator.id}
            disabled={getCheckedStatus() ? "disable" : ""}
            onChange={(e) => {
              if (type === "Translator") {
                handleTranslatorRadio(e, translator);
              } else {
                handleAgencyRadio(e, translator);
              }
            }}
          />
          <label className="form-check-label">{translator.name}</label>
        </Col>
        <Col className="col-12 col-md-5 translater-email">
          {translator.email}
        </Col>
        <Col className="col-12 col-md-3">
          {type.toLowerCase() === "translator" && (
            <ReactStars
              classNames="starsTranslate"
              count={5}
              size={16}
              isHalf={true}
              activeColor="#1C75BB"
              value={translator.rating}
              edit={false}
            />
          )}
          {type.toLowerCase() === "agency" && (
            <ReactStars
              classNames="starsTranslate"
              count={5}
              size={16}
              isHalf={true}
              activeColor="#1C75BB"
              value={translator.rating}
              edit={false}
            />
          )}
        </Col>
        <Col className="col-12 col-md-12">
          {jobInfo && jobInfo.translatorId === translator.userId && (
            <span className="Curent-translator">Current translator</span>
          )}
        </Col>
      </Row>
    );
  };
  const dateFormatter = (dateToBeFormatted) => {
    let str = dateToBeFormatted.toString();
    let parts = str.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    let formatedDate = parts[3] + "-" + months[parts[1]] + "-" + parts[2];
    return formatedDate;
  };

  const selectedLanguageTab = async (languageTab) => {
    let languages = [...languageTabs].map((lang) => {
      lang.isSelected = false;
      return lang;
    });
    languages.forEach((lang) => {
      if (lang.language === languageTab.language) {
        lang.isSelected = true;
      }
    });
    await setLanguageTabs(languages);
    filterTranslatorsOrAgencies();
  };

  const getSelectedLanguageTab = () => {
    const selectedLangIndex = [...languageTabs].findIndex((language) => {
      return language.isSelected;
    });
    return selectedLangIndex + 1;
  };
  const getSelectedDate = () => {
    let date;
    if (languageTabs.length < 1) {
      date = "";
      return date;
    } else {
      let index = getSelectedLanguageTab();
      index = index - 1;
      if (index <= -1) {
        return "";
      }
      let languageData = [...languageTabs];
      date = languageData[index].dueDate;
      return date;
    }
  };
  const getSelectedMarket = () => {
    let market;
    if (languageTabs.length < 1) {
      market = "";
      return market;
    } else {
      let index = getSelectedLanguageTab();
      index = index - 1;
      if (index <= -1) {
        return "";
      }
      let languageData = [...languageTabs];
      let marketCode = languageData[index].marketCode;
      market = marketDataSource.find((element) => {
        return element.code === marketCode;
      });

      return market;
    }
  };
  const handleSelfTranslate = (isChecked) => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    if (index <= -1) {
      return;
    }
    let languageData = [...languageTabs];
    if (isChecked) {
      languageData[index].jobType = "Self";
      let jobDetailsObj = {
        id: "",
        name: "",
        email: "",
        userId: "",
      };
      languageData[index].jobDetails = jobDetailsObj;
      setLanguageTabs(languageData);
      clearSearchBar();
    } else {
      languageData[index].jobType = "";
      setLanguageTabs(languageData);
      clearSearchBar();
    }
  };
  const getCheckedStatus = () => {
    let index = getSelectedLanguageTab() - 1;
    if (index >= 0 && languageTabs[index].jobType === "Self") {
      return true;
    } else {
      return false;
    }
  };
  const getSwitchButton = () => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    return (
      <div className="d-flex select-all-translator">
        <span className="select-all-translator-text">Self Translate</span>
        <span className="switch-button-translator">
          <Switch
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={
              index >= 0 && languageTabs[index].jobType === "Self"
                ? true
                : false
            }
            onChange={(event) => {
              handleSelfTranslate(event.target.checked);
            }}
            disabled={jobInfo ? true : false}
          />
        </span>
      </div>
    );
  };
  const getGuidelinesValue = () => {
    let guidelines;
    if (languageTabs.length < 1) {
      guidelines = "";
      return guidelines;
    } else {
      let index = getSelectedLanguageTab();
      index = index - 1;
      if (index <= -1) {
        return "";
      }
      let languageData = [...languageTabs];
      guidelines = languageData[index].instruction;
      return guidelines;
    }
  };
  const handleGuidelines = (guidelinesText) => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    let languageData = [...languageTabs];
    if (index >= 0) {
      languageData[index].instruction = guidelinesText;
      setLanguageTabs(languageData);
    } else {
      return;
    }
  };

  const checkforJobDetails = () => {
    let index = getSelectedLanguageTab();
    index = index - 1;
    let languageData = [...languageTabs];
    if (
      index >= 0 &&
      languageData[index].jobType &&
      languageData[index].jobType === "Self"
    ) {
      return true;
    } else if (
      index >= 0 &&
      languageData[index].jobType &&
      languageData[index].jobType !== "Self" &&
      languageData[index].jobDetails !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDateCreated = (dateString) => {
    const date = new Date(dateString);
    const dateStr = `${date.getDate()} ${getMonth(
      date.getMonth()
    )} ${date.getFullYear()}`;
    const time = moment(`${date.getHours()}:${date.getMinutes()}`, [
      "HH.mm",
    ]).format("hh:mm a");
    return `${dateStr}, ${time.toUpperCase()}`;
  };

  return (
    <>
      <div>
        <div className="row translated-module-pop-title customClass">
          <div
            className="col-md-6 "
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <span>{i18next.t("generalText.translatedModule")}</span>
          </div>
          <div
            className="col-md-6 "
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <p className="sel-lang-index">
              {getSelectedLanguageTab()} of {languageTabs.length}
            </p>
          </div>
          <div class="row col-md-12">
            <div className="languages-tab col-md-8">
              {languageTabs.map((languageTab) => {
                return (
                  <button
                    key={languageTab.language}
                    className={`btn-language ${
                      languageTab.isSelected ? "btn-sel-language" : ""
                    }`}
                    onClick={() => {
                      selectedLanguageTab(languageTab);
                    }}
                  >
                    {" "}
                    {languageTab.language}
                  </button>
                );
              })}
            </div>
            <div className="col-md-4">{getSwitchButton()}</div>
          </div>
        </div>
        <div className="row">
          <CircularProgressBar isloading={isloading} />
          <div className="col-md-4 verticle-line">
            <Formik
              initialValues={getInitialvalues()}
              validate={validate}
              onSubmit={(values, { resetForm }, initialValues) => {
                const formData = new FormData();
                formData.append(
                  "name",
                  values.moduleName.replace(/\s+/g, " ").trim()
                );
                const options = {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    // Authorization: "Bearer " + token,
                    "Accept-Language": "en-US,en;q=0.9",
                    "Access-Control-Allow-Origin": "*",
                  },
                };
                setIsloading(true);
              }}
              innerRef={formRef}
            >
              {({
                handleSubmit,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="row">
                    {/* <div className="col-md-12">{getSwitchButton()}</div> */}
                    <div className="col-md-12">
                      <DropDownWithSearch
                        options={{
                          value: getSelectedMarket(),
                          name: t("uploadAssetLabel.market"),
                          label: t("uploadAssetLabel.market"),
                          items: marketDataSource,
                          handleChange: handleMarketChange,
                          disabled: jobInfo ? true : false,
                        }}
                      />
                    </div>
                    <div className="col-md-12 translateSelectDate">
                      <DatePicker
                        selected={getSelectedDate()}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Due Date"
                        onChange={(date) => {
                          let index = getSelectedLanguageTab();
                          index = index - 1;
                          if (index <= -1) {
                            return;
                          }
                          let languageData = [...languageTabs];
                          languageData[index].dueDate = date;
                          setLanguageTabs(languageData);
                          //setDueDate(date);
                        }}
                        minDate={new Date()}
                        customInput={<CustomInput />}
                      />
                    </div>
                    <div className="col-md-12 ">
                      {getGuidelinesValue() ? (
                        <div className="guidelines">
                          Instructions / Guidelines
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="guidelines-box customScrollBar">
                        {checkforJobDetails() ? (
                          ""
                        ) : (
                          <div className="instruction-label">
                            Instructions / Guidelines
                          </div>
                        )}
                        {checkforJobDetails() && (
                          <FroalaEditor
                            placeHolder={
                              jobInfo &&
                              jobInfo.instructions &&
                              jobInfo.instructions.length > 0
                                ? "Enter New Instructions/Guidelines"
                                : "Enter Instructions/Guidelines"
                            }
                            variant="outlined"
                            tag="textarea"
                            handleModelChange={handleGuidelines}
                            selectedAtom={getGuidelinesValue()}
                          />
                        )}
                        {jobInfo &&
                          jobInfo.instructions &&
                          jobInfo.instructions.map((item) => (
                            <div key={item.id} class="p-2">
                              <div className="instructionVersion">
                                <span class="instructionsVNumber">
                                  {item.version}
                                </span>
                                <span class="instructionsUser">
                                  {`${item.reviewer} | ${getDateCreated(
                                    item.createdDateTime
                                  )}`}
                                </span>
                              </div>
                              <div className="instructionText">
                                {parse(item.instruction || "")}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-md-8">
            <div className="col-md-12">
              <div className="search-translate-tactics search-translate-tactics">
                <div className="d-flex">
                  <img
                    src={searchIcon}
                    alt=""
                    className="search-icon-translate"
                  />
                  <input
                    id="search-translator-agency"
                    type="text"
                    className="search-input-icb-tactic"
                    autoComplete="off"
                    placeholder="Search Translator / Agency"
                    disabled={getCheckedStatus() ? "disable" : ""}
                    onKeyUp={(e) => {
                      const value = e.target.value;
                      if (
                        e.key === "Enter" &&
                        value &&
                        value.trim().length > 0
                      ) {
                        setSearchText(e.target.value);
                      }
                    }}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value && value.trim().length > 0) {
                        setIsSearchBarEmpty(false);
                        return;
                      }
                      setSearchText(undefined);
                      setIsSearchBarEmpty(true);
                    }}
                  />
                  {!isSearchBarEmpty && (
                    <img
                      src={clearTextIcon}
                      style={{ marginTop: "4px !important" }}
                      alt=""
                      className="cross-icon"
                      onClick={(event) => {
                        clearSearchBar();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row translatedAgency-title">
                <div className="col-md-4 ">
                  <div
                    className={
                      isTranslateInfo === false
                        ? "translatorTab"
                        : "moduleNormalShow"
                    }
                    onClick={translatorInfo}
                  >
                    {i18next.t("generalText.translator")} ({traslators.length})
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div
                    className={
                      isTranslateInfo === false
                        ? "moduleNormalShow"
                        : "agencyTab"
                    }
                    onClick={agencyInfo}
                  >
                    {i18next.t("generalText.agency")} ({agency.length})
                  </div>
                </div>
              </div>
            </div>
            {isTranslateInfo === false ? (
              <div className="translation-parentDiv customScrollBar">
                {traslators.map((translator, index) => {
                  return drawTranslatorData(translator, index, "Translator");
                })}
              </div>
            ) : (
              <div className="translation-parentDiv customScrollBar">
                {agency.map((agency, index) => {
                  return drawTranslatorData(agency, index, "Agency");
                })}
              </div>
            )}
          </div>
        </div>
        <Row>
          <Col
            className="col-12 col-md-6"
            style={{ borderTop: "solid 1px #E1E1E1" }}
          >
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-link closeAnchor"
                style={{
                  marginLeft: "0px",
                  fontSize: "12px",
                  float: "right",
                  marginRight: "20px",
                  marginTop: "26px",
                  fontWeight: "600",
                }}
                onClick={() => hideModal()}
              >
                CLOSE
              </button>
            </div>
          </Col>
          <Col
            className="col-12 col-md-6 align-items-right"
            style={{ borderTop: "solid 1px #E1E1E1" }}
          >
            <div className="row align-items-right">
              <div className="col-12 col-md-6">
                {!jobInfo && (
                  <button
                    type="button"
                    className="btn btn-link closeAnchor"
                    style={{
                      //marginLeft: "0px",
                      fontSize: "12px",
                      float: "right",
                      marginTop: "26px",
                      fontWeight: "600",
                    }}
                    onClick={() => goToComponentSelection()}
                  >
                    BACK
                  </button>
                )}
              </div>
              <div className="col-12 col-md-6">
                <button
                  type="button"
                  className="btn btn-primary btn-lg refAnchorNext"
                  style={{
                    float: "right",
                    marginRight: "0px",
                    width: "157px",
                    fontWeight: "bold",
                    marginTop: "20px",
                  }}
                  disabled={checkForAllValues() ? "" : "disable"}
                  onClick={handleFinishCta}
                >
                  FINISH
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default TranslateModule;
