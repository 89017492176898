//Import React dependency
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

//Import custom compoenents
import DropDownWithLabel from "../DropDownWithLabel";
import ModuleCardGeneric from "../ModuleCardGeneric";
import AddModulePlaceHolder from "./AddModulePlaceHolder";
import ShowBussinessRules from "./ShowBussinessRules";
import AddBussinessRules from "./AddBussinessRules";
//Import Util functions and apis
import errComponentToRender from "../../Uitls/apiErrorHandling";
import {
  getAllOrSearchDenovoModules,
  getBusinessRulesForView,
} from "../../services/apis";

//import styles
import "../../styles/components/ModulePlaceholder.scss";
import { moduleTypes, routes } from "../../Uitls/Util";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import ViewModule from "./ViewModule";

//import images

const ModulePlaceHolder = (props) => {
  let history = useHistory();
  const {
    asset,
    orignalModules,
    handleCloseCreateModuleModal,
    type,
    addedGenericInfo,
    sourceType,
  } = props;
  const [isDropDownActive, setIsDropDownActive] = useState(true);
  const [isCreateModuleActive, setCreateModuleActive] = useState(false);
  const [buttonList, setButtonList] = useState([
    { btnLabel: "View Module", type: "ViewModule" },
    { btnLabel: "Show Business Rules", type: "ShowBusinessRules" },
  ]);
  const [createModulePlaceHolderActive, toggleCreateModulePlaceHolderActive] =
    useState(false);
  const [isBusinseRulesViewActive, setIsBusinseRulesViewActive] =
    useState(false);
  const [isViewModuleActive, toggleViewModuleActive] = useState(false);
  const [isAddBusinseRulesActive, setisAddBusinseRulesActive] = useState(false);
  const [businessRulesData, setBusinessRulesData] = useState(undefined);
  const [moduleNameAndCampaign, setModuleNameAndCampaign] = useState(undefined);
  const [bsrulesModData, setBsrulesModData] = useState([]);
  const [bsrulesModal, setBsrulesModal] = useState(false);

  //For denovo
  const [denovoModules, setDenovoModules] = useState([]);
  const [isloading, setIsloading] = useState(false);

  //For module details
  const [selectedModuleIdToView, setSelectedModuleIdToView] =
    useState(undefined);

  const handleCreateModulePlaceHolderModal = (flag) => {
    toggleCreateModulePlaceHolderActive(flag);
    keepTheParentActive(!flag);
  };

  const keepTheParentActive = (flag) => {
    setIsDropDownActive(flag);
  };

  const moduleCardBtnActions = (actionType, module) => {
    const moduleId = module.id;
    const moduleName = module.name;
    const moduleCampaign = module.campaign;
    const moduleCode = module.moduleCode;
    const lockstatus = module.locked === "Locked";
    switch (actionType) {
      case "ViewModule":
        viewModuleDetail(actionType, moduleId, lockstatus);
        break;
      case "ShowBusinessRules":
        getBusinessRules(moduleId, moduleName, moduleCampaign, moduleCode);
        break;
      default:
        break;
    }
  };
  const handleBusinessRulesBackButton = () => {
    setModuleNameAndCampaign(undefined);
    setIsBusinseRulesViewActive(false);
    setIsDropDownActive(true);
  };
  const getBusinessRules = (
    moduleId,
    moduleName,
    moduleCampaign,
    moduleCode
  ) => {
    getBusinessRulesForView(moduleId, (response, err, errorCode) => {
      if (err) {
        if (errorCode) {
          showError(errorCode);
        }
      } else {
        const data = response.data;
        setModuleNameAndCampaign({
          name: moduleName,
          campaign: moduleCampaign,
          moduleCode: moduleCode,
        });
        setBusinessRulesData(data);
        setIsBusinseRulesViewActive(true);
        setIsDropDownActive(false);
      }
    });
  };
  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const viewModuleDetail = (actionType, moduleId, isModuleLocked) => {
    toggleViewModuleActive(true);
    keepTheParentActive(false);
    setSelectedModuleIdToView(moduleId);
    // history.push({
    //   pathname: routes.moduleDetails,
    //   state: {
    //     moduleId: moduleId,
    //     isLocked: isModuleLocked,
    //   },
    // });
  };

  const handleApplyBusinessRules = (data) => {
    setBsrulesModData(data);
    setBsrulesModal(true);
    setisAddBusinseRulesActive(true);
    setIsBusinseRulesViewActive(false);
    setIsDropDownActive(false);
    toggleCreateModulePlaceHolderActive(false);
  };

  useEffect(() => {
    if (
      type &&
      type === moduleTypes.denovo &&
      addedGenericInfo &&
      (!denovoModules || denovoModules.length === 0)
    ) {
      getDenovoModules();
    }
  }, [type, addedGenericInfo]);

  const getmoduleDetailsErr = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const getDenovoModules = () => {
    const searchParams = {
      brands: [addedGenericInfo.brand],
      campaigns: [], //[addedGenericInfo.campaign],
    };
    //setIsloading(true);
    getAllOrSearchDenovoModules(searchParams, (response, err, errorCode) => {
      //setIsloading(false)
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        getmoduleDetailsErr(errCoponent);
      } else {
        const modules = response.data;
        //sort based on deconstruction time
        modules.sort(function (a, b) {
          return new Date(b.createdDateTime) - new Date(a.createdDateTime);
        });
        setDenovoModules(modules);
      }
    });
  };

  const getTargetModules = () => {
    let modules =
      type && type === moduleTypes.denovo ? denovoModules : orignalModules;
    return modules;
  };

  const hideViewModuleModal = () => {
    setSelectedModuleIdToView(undefined);
    toggleViewModuleActive(false);
    keepTheParentActive(true);
  };

  return (
    <>
      {isDropDownActive && (
        <>
          <h1 className="createModalHeading">Create Module</h1>
          {type === moduleTypes.denovo && (
            <div
              className="campaign-name"
              style={{ fontSize: "14px", marginBottom: "16px" }}
            >
              Campaign Name :{" "}
              {addedGenericInfo ? addedGenericInfo.campaign : ""}
            </div>
          )}
          {type !== moduleTypes.denovo && (
            <div>
              <h2 className="createModalSubHeading">Select Campaign</h2>
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: "Campaign",
                  label: "Campaign",
                  value:
                    type === moduleTypes.denovo && addedGenericInfo
                      ? addedGenericInfo.campaign
                      : asset && asset.campaignName,
                  items: [
                    type === moduleTypes.denovo && addedGenericInfo
                      ? addedGenericInfo.campaign
                      : asset && asset.campaignName,
                  ],
                  disabled: false,
                }}
              />
            </div>
          )}
          <CircularProgressBar isloading={isloading} />
          <div className="placeholdersModuleList customScrollBar">
            {getTargetModules() && (
              <div className="row">
                {getTargetModules().map((module, index) => (
                  <ModuleCardGeneric
                    module={module}
                    key={index}
                    colWidth={4}
                    buttonList={buttonList}
                    moduleCardBtnActions={moduleCardBtnActions}
                    showLockIcon={false}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="dmodalBtnRow row align-items-center">
            <div className="col-md-6"> </div>
            <div className="col-md-6 text-right">
              <button
                className="btn btn-link text-uppercase"
                onClick={() => handleCloseCreateModuleModal()}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleCreateModulePlaceHolderModal(true);
                }}
                disabled={
                  type === moduleTypes.denovo
                    ? false
                    : asset && asset.campaignName
                    ? false
                    : true
                }
              >
                Add module
              </button>
            </div>
          </div>
        </>
      )}

      {createModulePlaceHolderActive && (
        <AddModulePlaceHolder
          brandName={
            type && type === moduleTypes.denovo
              ? addedGenericInfo.brand
              : asset.brand
          }
          assetId={undefined}
          assetData={{
            harvestedAssetId: asset.harvestedAssetId,
            storageAssetId: asset.storageAssetId,
            brandId: asset.brandId,
            brandName: asset.brand,
            campaignName: asset.campaignName,
            campaignId: asset.campaignId,
          }}
          campaignName={
            type && type === moduleTypes.denovo
              ? addedGenericInfo.campaign
              : asset?.campaignName
          }
          handleCreateModulePlaceHolderModal={
            handleCreateModulePlaceHolderModal
          }
          applyBusinessRules={handleApplyBusinessRules}
          type={type}
          addedGenericInfo={addedGenericInfo}
          sourceType={sourceType}
        />
      )}
      {isBusinseRulesViewActive && (
        <ShowBussinessRules
          handleBack={handleBusinessRulesBackButton}
          businessRulesData={businessRulesData}
          campaign={
            type && type === moduleTypes.denovo
              ? addedGenericInfo.campaign
              : asset && asset.campaignName
          }
          moduleNameAndCampaign={moduleNameAndCampaign}
          handleCloseCreateModuleModal={handleCloseCreateModuleModal}
        />
      )}
      {isAddBusinseRulesActive && bsrulesModal && (
        <AddBussinessRules
          campaignName={
            type && type === moduleTypes.denovo
              ? addedGenericInfo.campaign
              : asset && asset.campaignName
          }
          handleCloseCreateModuleModal={handleCloseCreateModuleModal}
          type={type}
          asset={asset}
          modulesData={bsrulesModData}
          addedGenericInfo={addedGenericInfo}
        />
      )}

      {isViewModuleActive && (
        <ViewModule
          moduleID={selectedModuleIdToView}
          campaign={
            type && type === moduleTypes.denovo
              ? addedGenericInfo.campaign
              : asset && asset.campaignName
          }
          handleClose={hideViewModuleModal}
        />
      )}
    </>
  );
};

export default ModulePlaceHolder;
