import React from "react";
import parse from "html-react-parser";
import OverlayPopOver from "../../Uitls/OverlayPopOver";
const SubjectLinePreheaderPreview = ({ title, data, attribute }) => {
  return (
    <div className="Channel-grid-sub-pre-preview">
      <span className="channel-type-head">
        {title} ({`${data.length}`})
      </span>
      <table className="table-style">
        <tbody>
          <tr className="header">
            <td>MODULE MANAGER ID</td>
            <td>DAMS ID</td>
            <td>MLR STATUS</td>
            <td>
              {attribute === "subjectline" ? "SUBJECT LINE" : "PREHEADER"}
            </td>
          </tr>
          {data.map((item, index) => {
            return (
              <tr key={item.id}>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.id}{" "}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.documentNumber || "-"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "15%" }}>
                  {item.mlrStatus || "Not Submitted"}
                </td>
                <td className="tbl-row-txt-bold" style={{ width: "55%" }}>
                  {/* {item.text.length > 0 ? (
                    <OverlayPopOver
                      element={
                        <div className="ellipsis">{parse(item.text || "")}</div>
                      }
                      overlayData={parse(item.text || "")}
                      trigger={"hover"}
                      classId="composite-asset-hover"
                      placement="bottom"
                    />
                  ) : (
                    "-"
                  )} */}
                  {parse(item.text || "-")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default SubjectLinePreheaderPreview;
