import React, { useState, useEffect, useRef } from "react";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import TabPanel from "../../Uitls/TabPanel";
import TextField from "@material-ui/core/TextField";
import {
  channelTypesAndAttributes,
  getAllChannelAttributes,
  getChannelAttributes,
  globalAttributes,
} from "../../Uitls/Util";
import CreateHeader from "./CreateHeader";
import GenericTextField from "./GenericTextBox";
import CreateGlobalAttributeCTA from "./CreateGlobalAttributeCTA";
import OptoutComponent from "./OptoutComponent";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import {
  saveMarketingMessages,
  validateOptout,
  updateMarketingMessages,
} from "../../services/apis";
import { toast } from "react-toastify";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CreateMarketingMessageToast from "./CreateMarketingMessageToast";
import { useTranslation } from "react-i18next";
import infoIcon from "../../images/infoIcon.svg";
import ImportImageFromDams from "./ImportImageModal";
import Switch from "@material-ui/core/Switch";
import CreateSubjectLines from "./CreateSubjectLines";

const CreateNewMarketingMessage = ({
  hideopenCreateNewMessageModal,
  productList,
  brandsAndCampaignsData,
  damsSource,
  marketingMessage,
  type,
  otherPreDefinedMetaData,
  marketingMsgTypeToBeCreated,
}) => {
  const { t } = useTranslation();
  const [campaignList, setCampaignList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const tabPanelTabChange = React.useRef(null);
  const [channelTypeList, setChannelTypeList] = useState([]);
  const [channelAttributeList, setChannelAttributeList] = useState([]);
  const [selectedChannelType, setSelectedChannelType] = useState("");
  const [selectedChannelAttribute, setSelectedChannelAttribute] = useState("");
  const [subjectLines, setSubjectLines] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const [isCampaignSpecific, toggleIsCampaignSpecific] = useState(true);

  //For header
  const [headerData, setHeaderData] = useState([]);

  //For CTA
  const [ctaData, setCTAData] = useState({
    havingError: false,
    cta: { url: "", labels: [""] },
  });

  //Pre-Header
  const [preheader, setPreheader] = useState([]);

  //AlertTagline
  const [alertTagline, setAlertTagline] = useState([]);

  //BannerTagline
  const [bannerTagline, setBannerTagline] = useState([]);

  //For optout
  const [optOutData, setOptOutData] = useState({
    channelType: "",
    optOutVisual: "",
    optOutText: "",
  });
  const [uploadError, setUploadError] = useState(undefined);

  //For Import Image from Dams Modal
  const [openImportImageFromDamsModal, setOpenImportImageFromDamsModal] =
    useState(false);

  //Scroll to bottom
  const marketingMessages = useRef(null);

  const scrollToBottom = () => {
    if (marketingMessages && marketingMessages.current) {
      marketingMessages.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [subjectLines, preheader, alertTagline, bannerTagline, headerData]);
  const ChannelAttributeAndType = [
    {
      channelType: channelTypesAndAttributes.email.name,
      attributes: [
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.email.name.toLowerCase()
          ).subjectLine,
        },
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.email.name.toLowerCase()
          ).preheader,
        },
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.email.name.toLowerCase()
          ).optOut,
        },
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.email.name.toLowerCase()
          ).header,
        },
      ],
    },
    {
      channelType: channelTypesAndAttributes.banner.name,
      attributes: [
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.banner.name.toLowerCase()
          ).tagLine,
        },
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.banner.name.toLowerCase()
          ).optOut,
        },
      ],
    },
    {
      channelType: channelTypesAndAttributes.alert.name,
      attributes: [
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.alert.name.toLowerCase()
          ).tagLine,
        },
        {
          attributeName: getChannelAttributes(
            channelTypesAndAttributes.alert.name.toLowerCase()
          ).optOut,
        },
      ],
    },
  ];

  useEffect(() => {
    const data = ChannelAttributeAndType.map((item) => item.channelType);
    setChannelTypeList(data);
    if (marketingMessage || otherPreDefinedMetaData) {
      prePopulateData();
    }
  }, []);

  const renderTabs = () => {
    let titles = [`Channel Specific`, `Generic`];
    let children = [ChannelTypeAndAttributes, gloablAttributeCTA];

    return (
      <TabPanel
        titles={titles}
        children={children}
        tabPanelTabChange={tabPanelTabChange}
        notifyParentForTabChange={(selectedIndex) => {
          setSelectedTabIndex(selectedIndex);
        }}
      />
    );
  };

  const gloablAttributeCTA = () => {
    return (
      <CreateGlobalAttributeCTA
        setCTAData={setCTAData}
        ctaData={ctaData}
        selectedChannelAttribute={selectedChannelAttribute}
        selectedTabIndex={selectedTabIndex}
        infoIcon={infoIcon}
        editMode={marketingMessage}
      />
    );
  };

  const ChannelTypeAndAttributes = () => {
    return (
      <>
        <div
          className={`row ${marketingMessage ? "disbaled-marketing-msg" : ""}`}
          style={{ marginTop: marketingMessage ? "20px" : "" }}
        >
          <div
            className={`col-md-5 pr-0 ${
              otherPreDefinedMetaData?.channel ? "disbaled-marketing-msg" : ""
            }`}
          >
            <DropDownWithLabel
              class="mt-0"
              options={{
                name: "Channel Type",
                label: "Channel Type",
                value: selectedChannelType,
                items: channelTypeList,
                handleChange: (e) => {
                  onDropDownListChange(e.target.value, "channel");
                },
                disabled: marketingMessage || otherPreDefinedMetaData?.channel,
              }}
            />
          </div>
          <div className="col-md-7">
            <DropDownWithLabel
              class="mt-0"
              options={{
                name: "Attribute",
                label: "Attribute",
                value: selectedChannelAttribute,
                items: channelAttributeList,
                handleChange: (e) => {
                  onDropDownListChange(e.target.value, "attribute");
                },
                disabled: marketingMessage,
              }}
            />
          </div>
        </div>
        <div className="max-char-limit">
          {" "}
          <img
            src={infoIcon}
            alt="Max char limit"
            className="img-max-char-limit"
          />
          {t("marketingMessages.maxCharLimit")}
          {selectedChannelAttribute ===
            (getChannelAttributes(
              channelTypesAndAttributes.email.name.toLowerCase()
            ).optOut ||
              getChannelAttributes(
                channelTypesAndAttributes.alert.name.toLowerCase()
              ).optOut ||
              getChannelAttributes(
                channelTypesAndAttributes.banner.name.toLowerCase()
              ).optOut) && selectedTabIndex === 0
            ? ` | ${t("marketingMessagesOptout.imageSizeLimit")}`
            : ""}
        </div>
        <div className="markentingMessageTextBlock custonScrollBar">
          {loadComponent()}
          <div ref={marketingMessages}></div>
        </div>
      </>
    );
  };

  const onDropDownListChange = (value, type) => {
    if (type === "product") {
      const listOfCampagin = brandsAndCampaignsData.filter((brand) => {
        return brand.brandName === value;
      });
      const finalList = listOfCampagin[0].campaigns.map(
        (item) => item.campaignName
      );
      setCampaignList(finalList);
      setSelectedProduct(value);
      setSelectedCampaign("");
      toggleCanCreateOptout(true);
      setUploadError(undefined);
    } else if (type === "campaign") {
      setSelectedCampaign(value);
      toggleCanCreateOptout(true);
      setUploadError(undefined);
    } else if (type === "channel") {
      const listOfAttributes = ChannelAttributeAndType.filter((item) => {
        return item.channelType === value;
      });
      const finalList = listOfAttributes[0].attributes.map(
        (item) => item.attributeName
      );
      setChannelAttributeList(finalList);
      setSelectedChannelType(value);
      setSelectedChannelAttribute("");
      setHeaderData(undefined);
      setUploadError(undefined);
    } else if (type === "attribute") {
      setSelectedChannelAttribute(value);
      if (!marketingMessage) {
        setHeaderData(undefined);
        setSubjectLines([]);
        setPreheader([]);
        setBannerTagline([]);
        setAlertTagline([]);
        setOptOutData({
          channelType: "",
          optOutVisual: "",
          optOutText: "",
        });
      }
      setUploadError(undefined);
    }
  };

  useEffect(() => {
    if (
      !marketingMessage &&
      isCampaignSpecific &&
      selectedChannelAttribute ===
        getChannelAttributes(selectedChannelType?.toLowerCase())?.optOut
    ) {
      checkForOptout();
    }
  }, [
    selectedProduct,
    selectedCampaign,
    selectedChannelType,
    selectedChannelAttribute,
  ]);

  const [canCreateOptout, toggleCanCreateOptout] = useState(true);
  const checkForOptout = () => {
    if (
      selectedProduct &&
      selectedProduct.length > 0 &&
      selectedCampaign &&
      selectedCampaign.length > 0 &&
      selectedChannelType &&
      selectedChannelType.length > 0
    ) {
      const payload = {
        brand: selectedProduct,
        channelType: selectedChannelType,
        campaign: selectedCampaign,
      };
      setIsloading(true);
      validateOptout(payload, (res, err) => {
        setIsloading(false);
        if (err && err.response.data.message === "OPT_OUT_EXIST_EXCEPTION") {
          toggleCanCreateOptout(false);
        } else {
          toggleCanCreateOptout(true);
        }
      });
    }
  };

  const loadComponent = () => {
    let channelAttributes = {};
    switch (selectedChannelType) {
      case channelTypesAndAttributes.email.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.email.name.toLowerCase()
        );
        switch (selectedChannelAttribute) {
          case channelAttributes.subjectLine:
            return (
              <CreateSubjectLines
                subjectLines={subjectLines}
                setSubjectLines={setSubjectLines}
                editMode={marketingMessage}
              />
            );
          case channelAttributes.preheader:
            return (
              <GenericTextField
                label="Enter Pre-header"
                data={preheader}
                setData={setPreheader}
                editMode={marketingMessage}
                title="PreHeader"
              />
            );
          case channelAttributes.header:
            return (
              <CreateHeader
                headerData={headerData}
                setHeaderData={setHeaderData}
                editMode={marketingMessage}
              />
            );
          case channelAttributes.optOut:
            return (
              <OptoutComponent
                setOptOutData={setOptOutData}
                optOutData={optOutData}
                selectedChannelType={selectedChannelType}
                setIsloading={setIsloading}
                canCreateOptout={canCreateOptout}
                setOpenImportImageFromDamsModal={
                  setOpenImportImageFromDamsModal
                }
                selectedProduct={selectedProduct}
                setUploadError={setUploadError}
                uploadError={uploadError}
                damsSource={damsSource}
              />
            );
          default:
            break;
        }
        break;
      case channelTypesAndAttributes.banner.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.banner.name.toLowerCase()
        );
        switch (selectedChannelAttribute) {
          case channelAttributes.tagLine:
            return (
              <GenericTextField
                label="Enter Tagline"
                data={bannerTagline}
                setData={setBannerTagline}
                editMode={marketingMessage}
                title="Tagline"
              />
            );
          case channelAttributes.optOut:
            return (
              <OptoutComponent
                setOptOutData={setOptOutData}
                optOutData={optOutData}
                selectedChannelType={selectedChannelType}
                setIsloading={setIsloading}
                setOpenImportImageFromDamsModal={
                  setOpenImportImageFromDamsModal
                }
                selectedProduct={selectedProduct}
                canCreateOptout={canCreateOptout}
                setUploadError={setUploadError}
                uploadError={uploadError}
                damsSource={damsSource}
              />
            );
          default:
            break;
        }
        break;
      case channelTypesAndAttributes.alert.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.alert.name.toLowerCase()
        );
        switch (selectedChannelAttribute) {
          case channelAttributes.tagLine:
            return (
              <GenericTextField
                label="Enter Tagline"
                data={alertTagline}
                setData={setAlertTagline}
                editMode={marketingMessage}
                title="Tagline"
              />
            );
          case channelAttributes.optOut:
            return (
              <OptoutComponent
                setOptOutData={setOptOutData}
                optOutData={optOutData}
                selectedChannelType={selectedChannelType}
                setIsloading={setIsloading}
                setOpenImportImageFromDamsModal={
                  setOpenImportImageFromDamsModal
                }
                selectedProduct={selectedProduct}
                canCreateOptout={canCreateOptout}
                setUploadError={setUploadError}
                uploadError={uploadError}
                damsSource={damsSource}
              />
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const checkForMandatoryData = () => {
    //Add your respective attribute condition here, OR(||) condition with Subjectline
    if (selectedProduct) {
      if (isCampaignSpecific && !selectedCampaign) {
        return true;
      }
      if (selectedTabIndex === 1) {
        if (ctaData && !ctaData.havingError && ctaData.cta.url.length > 0) {
          return false;
        }
      } else if (selectedTabIndex === 0) {
        let channelAttributes = {};
        switch (selectedChannelType) {
          case channelTypesAndAttributes.email.name:
            channelAttributes = getChannelAttributes(
              channelTypesAndAttributes.email.name.toLowerCase()
            );
            switch (selectedChannelAttribute) {
              case channelAttributes.subjectLine:
                let allEntered = true;
                subjectLines.forEach((subjectline) => {
                  if (
                    !subjectline.text ||
                    subjectline.text.trim().length === 0
                  ) {
                    allEntered = false;
                  }
                });
                return !allEntered;
              case channelAttributes.preheader:
                let preheaderflag = true;
                preheader.forEach((item) => {
                  if (!item.text || item.text.trim().length === 0) {
                    preheaderflag = false;
                  }
                });
                return !preheaderflag;
              case channelAttributes.header:
                let headerFlag = true;
                headerData &&
                  headerData.forEach((header) => {
                    if (!header.message || header.message.trim().length === 0) {
                      headerFlag = false;
                    }
                  });
                return !headerFlag;
              case channelAttributes.optOut:
                if (
                  (optOutData &&
                    optOutData.optOutVisual.trim().length === 0 &&
                    optOutData.optOutText.trim().length === 0) ||
                  !canCreateOptout
                ) {
                  return true;
                }
                return false;
              default:
                break;
            }
            break;
          case channelTypesAndAttributes.banner.name:
            channelAttributes = getChannelAttributes(
              channelTypesAndAttributes.banner.name.toLowerCase()
            );
            switch (selectedChannelAttribute) {
              case channelAttributes.tagLine:
                let bannerTagLineflag = true;
                bannerTagline.forEach((item) => {
                  if (!item.text || item.text.trim().length === 0) {
                    bannerTagLineflag = false;
                  }
                });
                return !bannerTagLineflag;
              case channelAttributes.optOut:
                if (
                  (optOutData &&
                    optOutData.optOutVisual.trim().length === 0 &&
                    optOutData.optOutText.trim().length === 0) ||
                  !canCreateOptout
                ) {
                  return true;
                }
                return false;
              default:
                break;
            }
            break;
          case channelTypesAndAttributes.alert.name:
            channelAttributes = getChannelAttributes(
              channelTypesAndAttributes.alert.name.toLowerCase()
            );
            switch (selectedChannelAttribute) {
              case channelAttributes.tagLine:
                let alertTaglineflag = true;
                alertTagline.forEach((item) => {
                  if (!item.text || item.text.trim().length === 0) {
                    alertTaglineflag = false;
                  }
                });
                return !alertTaglineflag;
              case channelAttributes.optOut:
                if (
                  (optOutData &&
                    optOutData.optOutVisual.trim().length === 0 &&
                    optOutData.optOutText.trim().length === 0) ||
                  !canCreateOptout
                ) {
                  return true;
                }
                return false;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
    return true;
  };

  const getSelectedProduct = () => {
    const product = brandsAndCampaignsData.find((product) => {
      return product.brandName === selectedProduct;
    });
    return product
      ? {
          brandId: product.brandId,
          brandName: product.brandName,
        }
      : undefined;
  };
  const getSelectedcampaign = () => {
    const selectedproduct = getSelectedProduct();

    const campaigns = brandsAndCampaignsData.find((brand) => {
      return brand.brandId === selectedproduct.brandId;
    }).campaigns;
    if (campaigns && campaigns.length > 0) {
      return campaigns.find((campaign) => {
        return campaign.campaignName === selectedCampaign;
      });
    }
    return undefined;
  };
  const createCTAsPayload = () => {
    const cta = ctaData.cta;
    if (cta && cta.url && cta.url.trim().length > 0) {
      const product = getSelectedProduct();
      const campaign = getSelectedcampaign();
      let payload = {
        brandId: product?.brandId,
        brandName: product?.brandName,
        campaignId: campaign?.campaignId,
        campaignName: campaign?.campaignName,
        assetId: "",
        channelSource: "modulemanager", // selected dams source modulemanager if no dams selected
        type: marketingMsgTypeToBeCreated,
        ctaLink: cta.url,
        dams: damsSource?.toLowerCase(),
      };
      let labels = [];
      cta.labels.forEach((label) => {
        if (label && label.trim().length > 0) {
          labels.push({ label: label });
        }
      });
      payload.ctaLabels = labels;
      return [payload];
    } else {
      return undefined;
    }
  };

  const createChannnelsPayload = () => {
    const finalPayload = [];
    const product = getSelectedProduct();
    const campaign = getSelectedcampaign();
    const commonPayload = {
      brandId: product?.brandId,
      brandName: product?.brandName,
      campaignId: campaign?.campaignId,
      campaignName: campaign?.campaignName,
      assetId: "",
      channelSource: "modulemanager",
      type: marketingMsgTypeToBeCreated,
      dams: damsSource?.toLowerCase(),
    };
    if (subjectLines && subjectLines.length > 0) {
      subjectLines.forEach((subjectline) => {
        let payload = { ...commonPayload };
        payload.channelType = channelTypesAndAttributes.email.name;
        payload.attributeType = "SubjectLine";
        payload.disclaimer = subjectline.disclaimer;
        payload.text = subjectline.text;
        finalPayload.push(payload);
      });
    }
    if (preheader && preheader.length > 0) {
      preheader.forEach((item) => {
        let payload = { ...commonPayload };
        payload.channelType = channelTypesAndAttributes.email.name;
        payload.attributeType = getChannelAttributes(
          channelTypesAndAttributes.email.name.toLowerCase()
        ).preheader;
        payload.text = item.text;
        finalPayload.push(payload);
      });
    }
    if (headerData && headerData.length > 0) {
      headerData.forEach((header) => {
        let payload = { ...commonPayload };
        payload.channelType = channelTypesAndAttributes.email.name;
        payload.attributeType = getChannelAttributes(
          channelTypesAndAttributes.email.name.toLowerCase()
        ).header;
        let headerAttributes = [];
        headerAttributes.push({
          attributeType: "Text",
          text: header.message.trim(),
        });
        if (header.restriction && header.restriction?.trim().length > 0) {
          headerAttributes.push({
            attributeType: "Restriction",
            text: header.restriction.trim(),
          });
        }
        if (header.rule && header.rule?.trim().length > 0) {
          headerAttributes.push({
            attributeType: "Rule",
            text: header.rule.trim(),
          });
        }
        payload.channelSubAttribute = headerAttributes;
        finalPayload.push(payload);
      });
    }
    if (bannerTagline && bannerTagline.length > 0) {
      bannerTagline.forEach((item) => {
        let payload = { ...commonPayload };
        payload.channelType = channelTypesAndAttributes.banner.name;
        payload.attributeType = getChannelAttributes(
          channelTypesAndAttributes.banner.name.toLowerCase()
        ).tagLine;
        payload.text = item.text;
        finalPayload.push(payload);
      });
    }

    if (alertTagline && alertTagline.length > 0) {
      alertTagline.forEach((item) => {
        let payload = { ...commonPayload };
        payload.channelType = channelTypesAndAttributes.alert.name;
        payload.attributeType = getChannelAttributes(
          channelTypesAndAttributes.alert.name.toLowerCase()
        ).tagLine;
        payload.text = item.text;
        finalPayload.push(payload);
      });
    }
    if (
      (optOutData && optOutData?.optOutVisual.trim().length > 0) ||
      optOutData?.optOutText.trim().length > 0
    ) {
      let payload = { ...commonPayload };
      payload.channelType = optOutData.channelType;
      payload.attributeType = "OptOut";
      let optOutAttributes = [];
      optOutAttributes.push(
        {
          attributeType: "OptOutText",
          text: optOutData?.optOutText,
        },
        {
          attributeType: "OptOutVisual",
          text: optOutData?.optOutVisual,
        }
      );
      payload.channelSubAttribute = optOutAttributes;
      finalPayload.push(payload);
    }
    return finalPayload;
  };

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });

  const saveData = () => {
    const ctaPayload = createCTAsPayload();
    const channelsPayload = createChannnelsPayload();
    const payload = {};
    if (channelsPayload && channelsPayload.length > 0) {
      payload["marketingMsgChannels"] = channelsPayload;
    }
    if (ctaPayload && ctaPayload.length > 0) {
      payload["ctas"] = ctaPayload;
    }
    setIsloading(true);

    saveMarketingMessages(payload, (res, err, errCode) => {
      setIsloading(false);
      if (err || errCode) {
        const errCoponent = errComponentToRender(errCode);
        showError(errCoponent);

        return;
      }
      //show toast
      toast.success(
        <CreateMarketingMessageToast
          data={res.data}
          closeButtonClicked={() => {
            toast.dismiss();
          }}
        />,
        {
          position: "top-right",
          autoClose: 2000,
        }
      );

      hideopenCreateNewMessageModal(
        true,
        selectedProduct,
        selectedCampaign,
        selectedChannelType,
        selectedChannelAttribute,
        res.data
      );
    });
  };
  const editCTAPayload = () => {
    let payload = {
      ctaLink: ctaData.cta.url,
    };
    let labels = [];
    ctaData.cta.labels.forEach((label) => {
      if (label && label.trim().length > 0) {
        labels.push({ label: label });
      }
    });
    payload.ctaLabels = labels;
    return payload;
  };
  const updateMarketingMessage = () => {
    let payload = editChannnelsPayload();
    if (
      marketingMessage.attribute?.toLowerCase() ===
      globalAttributes.cta.toLowerCase()
    ) {
      payload = editCTAPayload();
    }
    setIsloading(true);
    updateMarketingMessages(
      marketingMessage.mktMsgId,
      payload,
      (res, err, errCode) => {
        setIsloading(false);
        if (err || errCode) {
          const errCoponent = errComponentToRender(errCode);
          showError(errCoponent);
          return;
        }
        //show toast
        toast.success(
          <CreateMarketingMessageToast
            data={res.data}
            closeButtonClicked={() => {
              toast.dismiss();
            }}
            toastType="update"
          />,
          {
            position: "top-right",
            autoClose: 2000,
          }
        );

        hideopenCreateNewMessageModal(
          true,
          selectedProduct,
          selectedCampaign,
          selectedChannelType,
          selectedChannelAttribute,
          res.data
        );
      },
      marketingMessage.attribute.toLowerCase() ===
        globalAttributes.cta.toLowerCase()
        ? "globalattribute"
        : "marketingmessage"
    );
  };

  const updateImportImageUrl = (visualUrl) => {
    setOptOutData({
      ...optOutData,
      channelType: selectedChannelType,
      optOutVisual: visualUrl,
    });
    setUploadError(undefined);
    setOpenImportImageFromDamsModal(false);
  };

  // ----------------------------- EDIT MESSAGE -----------------------------

  const prePopulateData = () => {
    let product = marketingMessage?.brand || otherPreDefinedMetaData?.brand;
    let campaign = otherPreDefinedMetaData?.campaign;
    if (marketingMessage) {
      campaign = marketingMessage.campaign;
    }
    onDropDownListChange(product, "product");
    if (campaign && campaign) {
      onDropDownListChange(campaign, "campaign");
    } else {
      toggleIsCampaignSpecific(false);
    }
    onDropDownListChange(
      marketingMessage?.channel || otherPreDefinedMetaData?.channel,
      "channel"
    );
    if (!marketingMessage) {
      return;
    }
    onDropDownListChange(marketingMessage.attribute, "attribute");
    let channelAttributes = {};
    switch (marketingMessage.channel) {
      case channelTypesAndAttributes.email.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.email.name.toLowerCase()
        );
        switch (marketingMessage.attribute) {
          case channelAttributes.subjectLine:
            setSubjectLines([marketingMessage]);
            break;
          case channelAttributes.preheader:
            setPreheader([marketingMessage]);
            break;
          case channelAttributes.optOut:
            const optout = { ...optOutData };
            optout.channelType = marketingMessage.channel;
            optout.optOutText = marketingMessage.text;
            optout.optOutVisual = marketingMessage.visual;
            setOptOutData(optout);
            break;
          case channelAttributes.header:
            // const header = { ...headerData };
            // const data = {
            //   message: marketingMessage.message,
            //   restriction: marketingMessage.restriction,
            //   rules: marketingMessage.rule,
            // };
            // header.havingError = undefined;
            // header.header = data;
            setHeaderData([marketingMessage]);
            break;
          default:
            break;
        }
        break;
      case channelTypesAndAttributes.alert.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.alert.name.toLowerCase()
        );
        switch (marketingMessage.attribute) {
          case channelAttributes.tagLine:
            setAlertTagline([marketingMessage]);
            break;
          case channelAttributes.optOut:
            const optout = { ...optOutData };
            optout.channelType = marketingMessage.channel;
            optout.optOutText = marketingMessage.text;
            optout.optOutVisual = marketingMessage.visual;
            setOptOutData(optout);
            break;
          default:
            break;
        }
        break;
      case channelTypesAndAttributes.banner.name:
        channelAttributes = getChannelAttributes(
          channelTypesAndAttributes.banner.name.toLowerCase()
        );
        switch (marketingMessage.attribute) {
          case channelAttributes.tagLine:
            setBannerTagline([marketingMessage]);
            break;
          case channelAttributes.optOut:
            const optout = { ...optOutData };
            optout.channelType = marketingMessage.channel;
            optout.optOutText = marketingMessage.text;
            optout.optOutVisual = marketingMessage.visual;
            setOptOutData(optout);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    if (
      marketingMessage.attribute.toLowerCase() ===
      globalAttributes.cta.toLowerCase()
    ) {
      const obj = {
        cta: {
          url: marketingMessage.url,
          labels: marketingMessage.labels,
        },
        havingError: false,
      };
      setSelectedTabIndex(1);
      setCTAData(obj);
    }
  };
  const loadComponentsToEdit = () => {
    if (marketingMessage) {
      if (
        marketingMessage.attribute?.toLowerCase() ===
        globalAttributes.cta.toLowerCase()
      ) {
        return gloablAttributeCTA();
      }
      return ChannelTypeAndAttributes();
    }
  };

  //---------------Edit Message Payload----------

  const editChannnelsPayload = () => {
    const commonPayload = {};
    if (subjectLines && subjectLines.length > 0) {
      subjectLines.forEach((subjectline) => {
        commonPayload.disclaimer = subjectline.disclaimer;
        commonPayload.text = subjectline.text;
      });
    }
    if (preheader && preheader.length > 0) {
      preheader.forEach((item) => {
        commonPayload.text = item.text;
      });
    }
    if (headerData && headerData.length > 0) {
      headerData.forEach((header) => {
        let headerAttributes = [];
        headerAttributes.push({
          attributeType: "Text",
          text: header.message.trim(),
        });
        if (header.restriction && header.restriction?.trim().length > 0) {
          headerAttributes.push({
            attributeType: "Restriction",
            text: header.restriction.trim(),
          });
        }
        if (header.rule && header.rule?.trim().length > 0) {
          headerAttributes.push({
            attributeType: "Rule",
            text: header.rule.trim(),
          });
        }
        commonPayload.channelSubAttribute = headerAttributes;
      });
    }
    if (bannerTagline && bannerTagline.length > 0) {
      bannerTagline.forEach((item) => {
        commonPayload.text = item.text;
      });
    }

    if (alertTagline && alertTagline.length > 0) {
      alertTagline.forEach((item) => {
        commonPayload.text = item.text;
      });
    }
    if (
      (optOutData && optOutData?.optOutVisual.trim().length > 0) ||
      optOutData?.optOutText.trim().length > 0
    ) {
      let channelSubAttribute = [];
      channelSubAttribute.push(
        {
          attributeType: "OptOutText",
          text: optOutData?.optOutText,
        },
        {
          attributeType: "OptOutVisual",
          text: optOutData?.optOutVisual,
        }
      );
      commonPayload.channelSubAttribute = channelSubAttribute;
    }
    return commonPayload;
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      {!openImportImageFromDamsModal ? (
        <>
          <div>
            <div className="row pb-2">
              <div className="CreateNewMessageTitle col col-md-6 d-flex align-items-center">
                {marketingMessage
                  ? "Edit Channel Attribute"
                  : "Create New Channel Attribute"}
              </div>
              {
                <div
                  className={`col col-md-6 ${
                    marketingMessage ? "disbaled-marketing-msg" : ""
                  }`}
                  style={{ textAlign: "end", paddingRight: "0" }}
                >
                  Campaign Specific
                  <Switch
                    className="filterRightMargin mr-2"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={isCampaignSpecific}
                    onChange={(e) => {
                      toggleIsCampaignSpecific(e.target.checked);
                      if (!e.target.checked) {
                        toggleCanCreateOptout(true);
                        setSelectedCampaign("");
                      } else {
                        if (otherPreDefinedMetaData?.campaign) {
                          onDropDownListChange(
                            otherPreDefinedMetaData?.campaign,
                            "campaign"
                          );
                        }
                      }
                    }}
                    disabled={marketingMessage}
                  />
                </div>
              }
            </div>
            <div
              className={`row dropDrownMarginTop ${
                marketingMessage ? "disbaled-marketing-msg" : ""
              }`}
              style={{
                borderBottom: "1px solid #e1e1e1",
                paddingBottom: "15px",
              }}
            >
              <div
                className={`col-md-5 pr-0 ${
                  otherPreDefinedMetaData?.brand ? "disbaled-marketing-msg" : ""
                }`}
              >
                <DropDownWithLabel
                  class="read mt-0"
                  options={{
                    name: "Product",
                    label: "Product",
                    value: selectedProduct,
                    items: productList,
                    handleChange: (e) => {
                      onDropDownListChange(e.target.value, "product");
                    },
                    disabled:
                      marketingMessage || otherPreDefinedMetaData?.brand,
                  }}
                />
              </div>
              <div
                className={`col-md-7 ${
                  otherPreDefinedMetaData?.brand ? "disbaled-marketing-msg" : ""
                }`}
              >
                {isCampaignSpecific && (
                  <DropDownWithLabel
                    class="read mt-0"
                    options={{
                      name: "Campaign",
                      label: "Campaign",
                      value: selectedCampaign,
                      items: campaignList,
                      handleChange: (e) => {
                        onDropDownListChange(e.target.value, "campaign");
                      },
                      disabled:
                        marketingMessage || otherPreDefinedMetaData?.campaign,
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="max-char-limit">
              {" "}
              <img
                src={infoIcon}
                alt="Max char limit"
                className="img-max-char-limit"
              />
              {t("marketingMessages.maxCharLimit")}
              {selectedChannelAttribute ===
                (getChannelAttributes(
                  channelTypesAndAttributes.email.name.toLowerCase()
                ).optOut ||
                  getChannelAttributes(
                    channelTypesAndAttributes.alert.name.toLowerCase()
                  ).optOut ||
                  getChannelAttributes(
                    channelTypesAndAttributes.banner.name.toLowerCase()
                  ).optOut) && selectedTabIndex === 0
                ? ` | ${t("marketingMessagesOptout.imageSizeLimit")}`
                : ""}
            </div> */}
            <div
              className={`${
                marketingMessage ? "editMarketingMessageBody" : ""
              } markentingMessageBodyBlock`}
            >
              {!marketingMessage && renderTabs()}
              {marketingMessage && loadComponentsToEdit()}
            </div>
          </div>
          <div className="row mmBrdrTop align-items-center">
            {/* <div className="col-md-6"></div> */}
            <div className="col-md-12 text-right">
              <button
                type="button"
                className="btn btn-link text-uppercase"
                onClick={() => {
                  hideopenCreateNewMessageModal();
                }}
                style={{ marginRight: "44px" }}
              >
                CANCEL
              </button>
              <button
                type="button"
                className={`btnMinWidthCreate btn btn-primary text-uppercase ${
                  marketingMessage ? "btn-width" : ""
                } `}
                disabled={checkForMandatoryData()}
                onClick={() => {
                  if (!marketingMessage) {
                    saveData();
                  } else {
                    //Function call for edit
                    updateMarketingMessage();
                  }
                }}
              >
                {marketingMessage ? "UPDATE" : "Create"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <ImportImageFromDams
          setOpenImportImageFromDamsModa={setOpenImportImageFromDamsModal}
          selectedCampaign={selectedCampaign}
          selectedProduct={selectedProduct}
          setIsloading={setIsloading}
          handleProceedCTA={updateImportImageUrl}
          damsSource={damsSource}
        />
      )}
    </>
  );
};
export default CreateNewMarketingMessage;
