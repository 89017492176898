//Import react dependencies
import React, { useState, useEffect, createRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
//Import custom components
import SearchComponent from "./SearchComponent";
import ModuleSelectionCard from "./ModuleSelectionCard";
import DropDownWithLabel from "../../DropDownWithLabel";
import "../../../styles/components/ModuleSelection.scss";
import DropDownWithSearch from "../../../Uitls/DropDownWithSearch";
import TemplateSelection from "../TemplateSelectionWithSearch";
import OverlayPopOver from "../../../Uitls/OverlayPopOver";
import { renderChevron } from "../../../Uitls/Util";
import { useOuterClick } from "../../../Uitls/CustomHooks";
import SmartTemplateEmpty from "../../../images/SmartTemplateEmpty.svg";

const ModuleSelectionComponent = ({
  contentModules,
  addedBodyModules,
  setAddedBodyModules,
  handleClose,
  goToTemplateSelection,
  generateChannelMatrixSpreadSheet,
  selectedTemplate,
  templateList,
  onTemplateChange,
  getSelectedTemplateId,
  getTemplateIds,
  onTemplateIdChange,
  getSelectedTemplateName,
  handleCheckBox,
  footNoteRefCheckBox,
  contentMatrixModules,
  setContentMatrixModules,
  goToNextScreen,
  selectedTemplateDetails,
}) => {
  const [selectedBox, setSelectedBox] = useState("");
  const { t } = useTranslation();
  const [moduleOrder, setModuleOrder] = useState([]);
  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const map = {};
  const [popoverOpen, togglePopoverOpen] = useState(false);
  const templateSelectionOuterRef = useOuterClick((e) => {
    togglePopoverOpen(false);
  });
  useEffect(() => {
    const templateSelection = document.getElementById(
      "template-selection-with-search"
    );
    if (!templateSelection) {
      togglePopoverOpen(false);
    }
  }, []);
  useEffect(() => {
    let alpha = Array.from(Array(26)).map((e, i) =>
      String.fromCharCode(i + 65)
    );
    setModuleOrder(
      alpha.slice(0, selectedTemplateDetails?.modules_tags?.length)
    );
  }, [selectedTemplateDetails]);

  const addBodyModuleSection = (module) => {
    let index = contentModules.findIndex(
      (item) => item.moduleId === module.moduleId
    );
    if (index > -1 && selectedBox !== "") {
      updateAddedBodyModules(module, selectedBox, "update");
      let tempArray = [...contentMatrixModules];
      tempArray[index].bodyModuleSection = selectedBox;
      setContentMatrixModules(tempArray);
    }
  };

  const getAddedBodyModules = (bodyModuleType) => {
    let result = contentMatrixModules.filter(
      (item) => item.bodyModuleSection === bodyModuleType
    );
    return result;
  };

  const deleteBodyModule = (module, boxKey) => {
    let index = contentMatrixModules.findIndex(
      (item) => item.moduleId === module.moduleId
    );
    if (index > -1) {
      updateAddedBodyModules(module, boxKey, "delete");
      let tempArray = [...contentMatrixModules];
      tempArray[index].bodyModuleSection = "";
      setContentMatrixModules(tempArray);
    }
  };

  const checkForModuleAdded = (moduleOrderValue) => {
    let index = moduleOrder.findIndex((item) => item === moduleOrderValue);
    if (index > -1 && index === 0) {
      return true;
    } else {
      let prevOrder = moduleOrder[index - 1];
      const valuesOfPrevOrder = contentMatrixModules.filter(
        (element) => element.bodyModuleSection === prevOrder
      );
      return valuesOfPrevOrder.length > 0 ? true : false;
    }
  };

  const updateAddedBodyModules = (module, alphabetOrder, actionType) => {
    let index = addedBodyModules.findIndex(
      (item) => item.bodyModuleSection === alphabetOrder
    );
    let moduleToBeLinked = {
      moduleId: module.moduleId,
      contentModuleType: module.contentModuleType,
      promoClaimCmDocNumber: module.promoClaimCmDocNumber,
      //id: module.moduleId,
    };
    let addedModulesArray = [...addedBodyModules];
    if (actionType === "update") {
      if (index < 0) {
        let linkedModules = [];
        linkedModules.push(moduleToBeLinked);
        let object = {
          bodyModuleSection: alphabetOrder,
          linkedModules: linkedModules,
        };
        addedModulesArray.push(object);
        setAddedBodyModules(addedModulesArray);
      } else {
        let linkedModules = [...addedModulesArray[index].linkedModules];
        linkedModules.push(moduleToBeLinked);
        addedModulesArray[index].linkedModules = linkedModules;
        setAddedBodyModules(addedModulesArray);
      }
    } else {
      let linkedModules = [...addedModulesArray[index].linkedModules];
      let filteredLinkModules = linkedModules.filter(
        (item) => item.moduleId !== moduleToBeLinked.moduleId
      );
      addedModulesArray[index].linkedModules = filteredLinkModules;
      setAddedBodyModules(addedModulesArray);
    }
  };

  const checkForAllModulesAdded = () => {
    if (getSelectedTemplateId() == undefined) {
      return true;
    } else {
      if (addedBodyModules.length > 0) {
        let result = false;
        let combinedModuleList = [];
        for (var i = 0; i < addedBodyModules.length; i++) {
          let temp = [...addedBodyModules[i].linkedModules];
          if (temp.length > 0) {
            for (var j = 0; j < temp.length; j++) {
              combinedModuleList.push(temp[j]);
            }
          }
        }
        let addedMandatoryModules = combinedModuleList.filter((item) => {
          return item.contentModuleType.toLowerCase() !== "optional";
        });
        let sourceMandatoryModules = contentMatrixModules.filter((item) => {
          return item.contentModuleType.toLowerCase() !== "optional";
        });
        if (
          addedMandatoryModules.length === sourceMandatoryModules.length &&
          getErroredBucketIds().length === 0
        ) {
          result = false;
        } else {
          result = true;
        }
        return result;
      }
    }
    return true;
  };

  const getErroredBucketIds = (moduleOrderIndex) => {
    contentMatrixModules &&
      contentMatrixModules.forEach((contentMatricModule) => {
        const bucketId = contentMatricModule.bodyModuleSection;

        if (bucketId) {
          if (map[bucketId]) {
            map[bucketId] = map[bucketId] + 1;
          } else {
            map[bucketId] = 1;
          }
        }
      });

    let erroredBucketIds = [];
    const totalBuckets = contentMatrixModules && contentMatrixModules.length;
    // a data is there in a ----yes ---- no need to run the logic
    // ----no ---- [next bucket till the last bucket =] -----  all empty no error  else error
    for (let i = 0; i < totalBuckets; i++) {
      const currentBucketId = alphabets[i];

      if (map[currentBucketId]) {
        continue;
      } else {
        for (let j = i + 1; j < totalBuckets; j++) {
          const bucketId = alphabets[j];
          if (map[bucketId]) {
            erroredBucketIds = [...erroredBucketIds, currentBucketId];
            break;
          }
        }
      }
    }

    return erroredBucketIds;

    // console.log(erroredBucketIds);

    // debugger;
    // if (
    //   moduleOrderIndex > -1 &&
    //   moduleOrderIndex !== 0 &&
    //   moduleOrderIndex !== contentMatrixModules.length - 1
    // ) {
    //   let prevOrder = moduleOrder[moduleOrderIndex - 1];
    //   const valuesOfPrevOrder = contentMatrixModules.filter(
    //     (element) => element.bodyModuleSection === prevOrder
    //   );
    //   let nextOrder = moduleOrder[moduleOrderIndex + 1];
    //   const valuesOfNextOrder = contentMatrixModules.filter(
    //     (element) => element.bodyModuleSection === nextOrder
    //   );
    //   let CurrentOrder = moduleOrder[moduleOrderIndex];
    //   const valuesOfCurrentOrder = contentMatrixModules.filter(
    //     (element) => element.bodyModuleSection === CurrentOrder
    //   );
    //   return valuesOfPrevOrder.length > 0 &&
    //     valuesOfNextOrder.length > 0 &&
    //     valuesOfCurrentOrder.length === 0
    //     ? true
    //     : false;
    // } else {
    //   return false;
    // }
  };
  const clearBodyModuleSelection = () => {
    let tempArray = [...contentMatrixModules].map((item) => {
      item.bodyModuleSection = "";
      return item;
    });
    setContentMatrixModules(tempArray);
    setAddedBodyModules([]);
    setSelectedBox("");
  };
  useEffect(() => {
    clearBodyModuleSelection();
  }, [selectedTemplate?.templateType, getSelectedTemplateName()]);
  return (
    <>
      <div className="row pb-3 horizontalBorder">
        <div className="col-md-3">
          <DropDownWithLabel
            class="read mt-0"
            disabled="disabled"
            options={{
              name: "Template Type",
              label: "Template Type",
              value: selectedTemplate?.templateType,
              items: templateList,
              handleChange: (e) => {
                onTemplateChange(e.target.value);
              },
            }}
          />
        </div>
        <div className="col-md-4">
          {/* <DropDownWithLabel
            class="read mt-0"
            disabled="disabled"
            options={{
              name: "Template ID",
              label: "Template ID",
              value: getSelectedTemplateId(),
              items: getTemplateIds(),
              handleChange: (e) => {
                onTemplateIdChange(e.target.value);
              },
              isDataTypeObject: true,
            }}
          /> */}

          {getSelectedTemplateName() && (
            <span className="label-select-template">Select Template</span>
          )}
          {
            <OverlayPopOver
              element={
                <div
                  className="drpdwn-select-template templateIdpopup"
                  ref={templateSelectionOuterRef}
                >
                  <div className="subtitle-text">
                    {getSelectedTemplateName() || "Select Template"}{" "}
                    {renderChevron(popoverOpen ? "up" : "down")}
                  </div>
                </div>
              }
              overlayData={
                <TemplateSelection
                  templates={getTemplateIds()}
                  handleChnage={(template) => {
                    onTemplateIdChange(template.templateId);
                    togglePopoverOpen(false);
                    document.body.click();
                  }}
                  togglePopoverOpen={togglePopoverOpen}
                />
              }
              trigger={"click"}
              classId="template-search-popover"
              placement="bottom"
            />
          }
        </div>
        <div className="col-md-2 template-field">
          <TextField
            className="templateName"
            id="TemplateId"
            label="Template ID"
            variant="outlined"
            disabled={true}
            fullWidth={true}
            value={getSelectedTemplateId() || "----"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 verticleBorder">
          <SearchComponent
            modules={contentMatrixModules || []}
            addBodyModuleSection={addBodyModuleSection}
            selectedBox={selectedBox}
          />
        </div>
        <div className="col-md-8">
          <div className="moduleSectionHeadings" style={{ marginTop: "16px" }}>
            Module Sections
          </div>
          <div className="row moduleSelectionBody customScrollBar">
            {selectedTemplateDetails?.html &&
            selectedTemplateDetails?.modules_tags.length > 0 ? (
              moduleOrder.map((item, index) => (
                <ModuleSelectionCard
                  key={index}
                  boxIndex={index}
                  boxId={alphabets[index]}
                  boxKey={item}
                  boxTitle={item}
                  data={getAddedBodyModules(item) || []}
                  selectedBoxIndex={selectedBox}
                  setSelectedBox={setSelectedBox}
                  deleteBodyModule={deleteBodyModule}
                  checkForModuleAdded={checkForModuleAdded}
                  getErroredBucketIds={getErroredBucketIds}
                />
              ))
            ) : !selectedTemplateDetails ? (
              ""
            ) : (
              <>
                <div className="no-smart-template">
                  <div>
                    <img
                      className="img-fluid"
                      src={SmartTemplateEmpty}
                      alt=""
                    />
                  </div>
                  <div className="smart-template-not-selected">
                    Module sections are not defined for selected template.The
                    selected template is not a smart template. Please select a
                    smart template to proceed
                  </div>
                </div>
              </>
            )}
            {/* {moduleOrder.map((item, index) => (
              <ModuleSelectionCard
                key={index}
                boxIndex={index}
                boxId={alphabets[index]}
                boxKey={item}
                boxTitle={item}
                data={getAddedBodyModules(item) || []}
                selectedBoxIndex={selectedBox}
                setSelectedBox={setSelectedBox}
                deleteBodyModule={deleteBodyModule}
                checkForModuleAdded={checkForModuleAdded}
                getErroredBucketIds={getErroredBucketIds}
              />
            ))} */}
          </div>
        </div>
      </div>
      <div className="row pt-3 createGroupResultBtnRow">
        <div className="col-md-4">
          {/* <button
            className="btn btn-link text-uppercase"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </button> */}
        </div>
        <div className="col-md-8 text-right">
          <button
            className="btn btn-link text-uppercase"
            onClick={() => {
              handleClose();
            }}
            style={{ marginRight: "24px" }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary text-uppercase defaultBtnWidth"
            disabled={checkForAllModulesAdded()}
            onClick={() => {
              goToNextScreen();
              // generateChannelMatrixSpreadSheet();
            }}
            //style={{ width: "136px" }}
          >
            PROCEED
          </button>
        </div>
      </div>
    </>
  );
};
export default ModuleSelectionComponent;
