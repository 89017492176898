import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import lockIconGrey from "../images/lockIconGrey.svg";
import unlockGreyIcon from "../images/unlockGrey.svg";
import mlrStatusInDraft from "../images/mlr_status_draft.svg";
import mlrStatusApproved from "../images/mlr_status_approved.svg";
import mlrStatusAppChanges from "../images/mlr_status_approvedwithchanges.svg";
import mlrStatusReSubmit from "../images/mlr_status_revise_resubmit.svg";
import mlrStatusContentCreatorUpload from "../images/mlr_status_contentcreatorupload.svg";
import mlrStatusInReview from "../images/mlr_status_inreview.svg";
import mlrStatusDistribution from "../images/mrl_status_approved_distribution.svg";
import "../styles/components/ExistingTatics.scss";

const ModuleCardGeneric = (props) => {
  const { t } = useTranslation();
  const {
    module,
    colWidth,
    moduleCardBtnActions,
    buttonList,
    moduleCardlockBtnActions,
    showLockIcon,
    isSelected = false,
    onModuleSelect,
    cannotExistBusssinessRule,
  } = props;

  const getCardCssClass = () => {
    if (isSelected) {
      return "currentModuleTactics denovo-module-card-selected";
    } else if (cannotExistBusssinessRule) {
      return "currentModuleTactics localized-module-card-error";
    } else {
      return "currentModuleTactics";
    }
  };

  return (
    <>
      <div className={`col-md-${colWidth} `} key={module.id}>
        <div
          className={getCardCssClass()}
          onClick={() => {
            if (onModuleSelect) {
              onModuleSelect(module);
            }
          }}
        >
          <div className="d-flex justify-content-between">
            <span className="moduleCodeBox">{module.moduleCode}</span>

            {module.mlrStatus === "In Draft" || module.mlrStatus === "Draft" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusInDraft}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Content creator uploading" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusContentCreatorUpload}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "In-review" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusInReview}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Revise & Resubmit-MLR Review" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusReSubmit}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "MLR Approved with changes" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusAppChanges}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "MLR Approved" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusApproved}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : module.mlrStatus === "Approved for Distribution" ? (
              <span
                className={`mlrStatusText ${
                  showLockIcon ? "" : "text-right pl-0 pr-4"
                }`}
              >
                <img
                  src={mlrStatusDistribution}
                  alt={module.mlrStatus}
                  className="mlrStatusIconMr"
                />
                {module.mlrStatus}
              </span>
            ) : (
              <>
                {module.mlrStatus && (
                  <>
                    <span className="mlrStatusText text-right pl-0 pr-4">
                      {module.mlrStatus}
                    </span>
                  </>
                )}
              </>
            )}
            {showLockIcon && (
              <>
                {module.locked === "Locked" ? (
                  <button
                    className="btn btn-link moduleLockStatus"
                    type="button"
                  >
                    <img src={lockIconGrey} alt="" />
                  </button>
                ) : (
                  <button
                    className="btn btn-link moduleLockStatus"
                    type="button"
                  >
                    <img src={unlockGreyIcon} alt="" />
                  </button>
                )}
              </>
            )}
          </div>
          <p className="modNameTitle mt-1 mb-0">Campaign Name</p>
          <p className="modNameText modCampName">{module.campaign}</p>
          <p className="modNameTitle mb-0">Module Name</p>
          <p className="modNameText modNameTextTitle">{module.name}</p>
          <p className="modCategoryText">
            {module.coreCategories &&
              module.coreCategories.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
          </p>

          <p className="modNameTitle mb-0">{t("primaryClaim.name")}</p>
          <div className="modNameText mb-0">
            {module.primaryClaim && parse(module.primaryClaim)}
          </div>

          <div className="moduleDetailsBrdr">
            <div
              className={`d-flex ${
                buttonList.length > 1
                  ? "justify-content-between"
                  : "justify-content-center"
              } moduleDetailsBtnRow`}
            >
              {buttonList &&
                buttonList.map((btn, index) => (
                  <button
                    key={index}
                    className="btn btn-link btnApprove pl-0 moduleActionBtn pr-0"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      moduleCardBtnActions(btn.type, module);
                    }}
                  >
                    {btn.btnLabel}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleCardGeneric;
