//Import React Dependecies
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

//Import custom components
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import DropDownWithLabel from "../DropDownWithLabel";
import {
  channelTypesAndAttributes,
  getChannelAttributes,
  marketingMessageSourceType,
} from "../../Uitls/Util";
//Import apis
import { searchMarketingMessages } from "../../services/apis";
import SelectableMarketingMessages from "./SelectableMarketingMessages";
import CreateChannelMatrix from "./CreateChannelMatrix";
import CustomModal from "../customModal";

import backButton from "../../images/chevron-left-matrix.svg";

const MarketingMessageSelection = ({
  brand,
  campaignName,
  channelType,
  sourceType,
  closeMarketingMsgSelectionModal,
  cancelCreatingChannelMatrix,
  handleContentMatrixModal1,
  assetDataType,
  moduleId,
  backToPrviousScreen,
  setSelectedMMessages,
  generateChannelMatrixSpreadSheet,
  selectedMMessges,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [marketingMessages, setMarketingMessages] = useState(undefined);
  const msgSources = [
    marketingMessageSourceType[sourceType].name,
    marketingMessageSourceType.moduleManager.name,
  ];
  const [selectedMsgSource, setSelectedMsgSource] = useState(
    marketingMessageSourceType.moduleManager.name
  );
  const [selectedMarketingMessges, setSelectedMarketingMessages] =
    useState(undefined);
  const [filteredMarketingMessages, setFilteredMarketingMessages] =
    useState(undefined);
  const [isAllSubjectLineChecked, setIsAllSubjectLineChecked] = useState(false);
  const [isAllPreHeaderChecked, setIsAllPreHeaderChecked] = useState(false);

  useEffect(() => {
    if ((brand, campaignName, channelType)) {
      search();
    }
  }, [brand, campaignName, channelType]);

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });

  const setupDefaultSelected = (marketingMessages) => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    let optouts = marketingMessages?.marketingMsgChannels?.optOuts || [];
    let tagLines = marketingMessages?.marketingMsgChannels?.tagLines || [];
    if (channelType.trim() === channelTypesAndAttributes.email.name) {
      setSelectedMarketingMessages({
        subjectLines: subjectLines,
        preHeaders: preheaders,
        optOuts: [], //optouts,
      });
      setSelectedMMessages({
        subjectLines: subjectLines,
        preHeaders: preheaders,
        optOuts: [], //optouts,
      });
    } else {
      setSelectedMarketingMessages({
        tagLines: tagLines,
        optOuts: [], //optouts,
      });
      setSelectedMMessages({
        tagLines: tagLines,
        optOuts: [], //optouts,
      });
    }
  };
  const handleClearOptOutSelection = () => {
    let data = JSON.parse(JSON.stringify(selectedMarketingMessges));
    data.optOuts = [];
    setSelectedMarketingMessages(data);
    setSelectedMMessages(data);
  };
  const search = () => {
    const payload = {
      brand: brand,
      campaign: campaignName,
      channelType: channelType,
      searchText: searchText,
      channelSource: selectedMsgSource,
    };
    setIsloading(true);
    searchMarketingMessages(payload, (response, err, errCode) => {
      setIsloading(false);
      if (response) {
        const data = response.data;
        setMarketingMessages(data);
        setFilteredMarketingMessages(data);
        if (selectedMMessges && selectedMMessges) {
          setSelectedMarketingMessages(selectedMMessges);
        } else {
          setupDefaultSelected(data);
        }
        return;
      }
      const errCoponent = ErrComponentToRender(errCode);
      showError(errCoponent);
    });
  };

  const onSelectMarketingMessage = (marketingMessage, selected) => {
    let data = JSON.parse(JSON.stringify(selectedMarketingMessges));
    let subjectLines = data?.subjectLines || [];
    let preheaders = data?.preHeaders || [];
    let tagLines = data?.tagLines || [];
    let optOuts = data?.optOuts || [];
    const attribute = marketingMessage.attribute.toLowerCase();
    const channelAttributes = getChannelAttributes(channelType.toLowerCase());
    if (attribute === channelAttributes?.subjectLine?.toLowerCase()) {
      const index = subjectLines.findIndex((subjectLine) => {
        return subjectLine.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        subjectLines = subjectLines.concat(marketingMessage);
      } else if (index > -1) {
        subjectLines = subjectLines.filter((subjectline) => {
          return subjectline.id !== marketingMessage.id;
        });
      }
      data.subjectLines = subjectLines;
    }
    if (attribute === channelAttributes?.preheader?.toLowerCase()) {
      const index = preheaders.findIndex((preheader) => {
        return preheader.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        preheaders = preheaders.concat(marketingMessage);
      } else if (index > -1) {
        preheaders = preheaders.filter((preheader) => {
          return preheader.id !== marketingMessage.id;
        });
      }
      data.preHeaders = preheaders;
    }
    if (attribute === channelAttributes?.optOut?.toLowerCase()) {
      const index = optOuts.findIndex((optOut) => {
        return optOut.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        optOuts = [marketingMessage]; //optOuts.concat(marketingMessage);
      } else if (index > -1) {
        optOuts = [];
      }
      data.optOuts = optOuts;
    }
    if (attribute === channelAttributes?.tagLine?.toLowerCase()) {
      const index = tagLines.findIndex((tagLine) => {
        return tagLine.id === marketingMessage.id;
      });
      if (selected && index === -1) {
        tagLines = tagLines.concat(marketingMessage);
      } else if (index > -1) {
        tagLines = tagLines.filter((tagLine) => {
          return tagLine.id !== marketingMessage.id;
        });
      }
      data.tagLines = tagLines;
    }
    setSelectedMarketingMessages(data);
    setSelectedMMessages(data);
  };

  const shouldProceedButtonBeEnabled = () => {
    if (searchText.trim().length > 0) {
      return false;
    }
    let subjectLines = selectedMarketingMessges?.subjectLines || [];
    let preheaders = selectedMarketingMessges?.preHeaders || [];
    let tagLines = selectedMarketingMessges?.tagLines || [];
    let optOuts = selectedMarketingMessges?.optOuts || [];
    if (channelType?.trim() === channelTypesAndAttributes.email.name) {
      if (
        subjectLines.length > 0 &&
        preheaders.length > 0
        //optOuts.length > 0
      ) {
        return true;
      }
      return false;
    } else {
      if (
        tagLines.length > 0
        //optOuts.length > 0
      ) {
        return true;
      }
      return false;
    }
  };

  const filterByText = (text = searchText) => {
    let data = JSON.parse(JSON.stringify(marketingMessages)); //Object.assign({}, marketingMessages);
    let subjectLines = data?.marketingMsgChannels?.subjectLines;
    let preHeaders = data?.marketingMsgChannels?.preHeaders;
    let tagLines = data?.marketingMsgChannels?.tagLines;
    let optOuts = data?.marketingMsgChannels?.optOuts;
    const searchParam = text.toLowerCase();
    subjectLines = subjectLines.filter((subjectLine) => {
      return (
        subjectLine.id.toLowerCase().includes(searchParam) ||
        subjectLine.text.toLowerCase().includes(searchParam)
      );
    });
    preHeaders = preHeaders.filter((preheader) => {
      return (
        preheader.id.toLowerCase().includes(searchParam) ||
        preheader.text.toLowerCase().includes(searchParam)
      );
    });
    tagLines = tagLines.filter((tagLine) => {
      return (
        tagLine.id.toLowerCase().includes(searchParam) ||
        tagLine.text.toLowerCase().includes(searchParam)
      );
    });
    optOuts = optOuts.filter((optOut) => {
      return (
        optOut.id.toLowerCase().includes(searchParam) ||
        optOut.text.toLowerCase().includes(searchParam)
      );
    });
    data.marketingMsgChannels.subjectLines = subjectLines;
    data.marketingMsgChannels.preHeaders = preHeaders;
    data.marketingMsgChannels.tagLines = tagLines;
    data.marketingMsgChannels.optOuts = optOuts;
    setFilteredMarketingMessages(data);
  };

  const [showChannelMatrix, setShowChannelMatrix] = useState(false);

  const handleContentMatrixModal = () => {
    setShowChannelMatrix(true);
  };

  useEffect(() => {
    let subjectLines =
      marketingMessages?.marketingMsgChannels?.subjectLines || [];
    let preheaders = marketingMessages?.marketingMsgChannels?.preHeaders || [];
    if (selectedMarketingMessges?.subjectLines.length === subjectLines.length) {
      setIsAllSubjectLineChecked(true);
    } else {
      setIsAllSubjectLineChecked(false);
    }
    if (selectedMarketingMessges?.preHeaders.length === preheaders.length) {
      setIsAllPreHeaderChecked(true);
    } else {
      setIsAllPreHeaderChecked(false);
    }
  }, [selectedMarketingMessges]);

  const handleSelectAll = (event, type) => {
    let checked = event.target.checked;
    let data = JSON.parse(JSON.stringify(selectedMarketingMessges));
    if (type === "subjectline") {
      if (checked) {
        let subjectLines =
          marketingMessages?.marketingMsgChannels?.subjectLines || [];
        data.subjectLines = subjectLines;
      } else {
        data.subjectLines = [];
      }
    } else {
      if (checked) {
        let preheaders =
          marketingMessages?.marketingMsgChannels?.preHeaders || [];
        data.preHeaders = preheaders;
      } else {
        data.preHeaders = [];
      }
    }
    setSelectedMarketingMessages(data);
    setSelectedMMessages(data);
  };
  return (
    <div>
      <CircularProgressBar isloading={isloading} />
      <>
        <div className="row">
          <div className="col-md-5 matrixSearchRowTitle">
            <div className="d-flex">
              <div>
                <img
                  className="modal-header-back-button mt-0"
                  src={backButton}
                  alt=""
                  width="10"
                  onClick={() => backToPrviousScreen()}
                />
              </div>
              <div className="pl-2">
                <strong>Create</strong> Channel Matrix (4/4){" "}
                <span>{t("channelMatrix.selectMarketingMessage")}</span>
                <div className="note">
                  {t("channelMatrix.selectMarketingMessageNote")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 matrixSearchRowBrand">
            <div className="d-flex justify-content-end">
              <div className="mr-5">
                <span>Product/Brand</span>
                {brand}
              </div>
              <div className="mr-5">
                <span>Campaign</span>
                {campaignName}
              </div>
              <div>
                <span>Channel</span>
                {channelType}
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mt-4 align-items-center"
          style={{
            borderBottom: "1px solid #e1e1e1",
            paddingBottom: "15px",
          }}
        >
          <div className="col-md-3 pr-0 channelTypeDropDown">
            <DropDownWithLabel
              class="mt-0 tf-and-drp-dwn-with-label-disabled mb-0"
              options={{
                name: "Source",
                label: "Source",
                value: selectedMsgSource,
                items: msgSources,
                handleChange: (e) => {
                  setSelectedMsgSource(e.target.value);
                },
                disabled: true,
                isDataTypeObject: false,
              }}
            />
          </div>
          <div className="col-md-7 searchMsg">
            <TextField
              label="Attribute ID/Attribute Text"
              variant="outlined"
              fullWidth={true}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                //  filterByText(event.target.value);
              }}
              autocomplete="new-password"
              autoComplete="off"
              classes="p-0"
            />
          </div>
          <div className="col-md-2 pl-0  ">
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary btn-search-tactic search-marketing-msg-in-modal"
                onClick={(event) => {
                  filterByText();
                }}
                disabled={!searchText.trim()}
              >
                search
              </button>
              <button
                className="btn btn-link p-0"
                onClick={(event) => {
                  setSearchText("");
                  setFilteredMarketingMessages(marketingMessages);
                }}
                //disabled={!searchText.trim()}
              >
                {t("coreClaim.reset").toUpperCase()}
              </button>
            </div>
          </div>
        </div>
        <SelectableMarketingMessages
          marketingMessages={filteredMarketingMessages}
          onSelectMarketingMessage={onSelectMarketingMessage}
          selectedMarketingMessages={selectedMarketingMessges}
          channelType={channelType}
          isAllSubjectLineChecked={isAllSubjectLineChecked}
          isAllPreHeaderChecked={isAllPreHeaderChecked}
          handleSelecteAll={handleSelectAll}
          handleClearOptOutSelection={handleClearOptOutSelection}
        />
        <div className="row pt-3 createGroupResultBtnRow">
          <div className="col-md-4 pl-0">
            {/* <button
              className="btn btn-link text-uppercase"
              onClick={() => {
                cancelCreatingChannelMatrix();
              }}
            >
              Cancel
            </button> */}
          </div>
          <div className="col-md-8 text-right">
            <button
              className="btn btn-link text-uppercase"
              onClick={() => {
                cancelCreatingChannelMatrix();
              }}
              style={{ marginRight: "24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary text-uppercase"
              disabled={!shouldProceedButtonBeEnabled()}
              // onClick={() => {
              //   handleContentMatrixModal();
              // }}
              onClick={() => {
                generateChannelMatrixSpreadSheet();
              }}
              style={{ width: "136px" }}
            >
              Proceed
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default MarketingMessageSelection;
