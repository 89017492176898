import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "../../styles/components/ExistingTatics.scss";
import "../../styles/CustomToastify.scss";
import DropDownWithLabel from "../../components/DropDownWithLabel";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import BrowseGenericInfoDocument from "../ExistingTactics/Harvested/BrowseGenericInfoDocument";
import { upDateModuleAssetLinks } from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CustomModal from "../../components/customModal";
import closeIcon from "../../images/clear_text.svg";
import { validateYupSchema } from "formik";
import { Form } from "react-bootstrap";
const GeenricInformationModule = (props) => {
  const { t } = useTranslation();
  const {
    assetDetails = {},
    brands,
    campaigns,
    isdisabled,
    isDamsFlow,
  } = props;

  const [channels, setChannels] = useState([]);
  const [audienceDataSource, setAudienceDataSource] = useState([
    "Consumer",
    "HCP",
    "Payer",
  ]);
  const [genreicInfoData, setGenericInfoData] = useState({
    isi: "",
    pi: "",
    mg: "",
    masa: "",
    genericBusinessRules: "",
    therapeuticArea: "",
    assetType: undefined,
  });
  const [showBrowseGenInfoDocModal, setShowBrowseGenInfoDocModal] =
    useState(false);
  const [browseDocType, setBrowseDocType] = useState("");

  useEffect(() => {
    setChannelsData();
  }, []);
  useEffect(() => {
    setGenericInfoData({
      isi: props.assetDetails.isi,
      pi: props.assetDetails.pi,
      mg: props.assetDetails.mg,
      masa: props.assetDetails.masterAssetLink,
      genericBusinessRules: props.assetDetails.genericBusinessRules,
      therapeuticArea: props.assetDetails.therapeuticArea,
      assetType: assetDetails.assetType,
    });
  }, [props]);
  const setChannelsData = () => {
    let channelDataSource = [];
    channelDataSource.push(i18next.t("channelTypeSelect.detailingAid"));
    channelDataSource.push(i18next.t("channelTypeSelect.email"));
    channelDataSource.push(i18next.t("channelTypeSelect.banner"));
    channelDataSource.push(i18next.t("channelTypeSelect.socialMediaAdv"));
    channelDataSource.push(i18next.t("channelTypeSelect.print"));
    channelDataSource.push(i18next.t("channelTypeSelect.alert"));
    setChannels(channelDataSource);
  };

  const isThisAudienceAdded = (audience) => {
    if (audience) {
      const addedAudience = assetDetails?.audience;
      if (
        addedAudience &&
        addedAudience.findIndex(
          (aud) => aud.trim().toLowerCase() === audience.trim().toLowerCase()
        ) > -1
      ) {
        return true;
      }
    }
    return false;
  };
  const handleChangeOtherGenInfo = (event, type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: event.target.value });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: event.target.value });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: event.target.value });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: event.target.value });
    } else if (type === "genericBusinessRules") {
      setGenericInfoData({
        ...genreicInfoData,
        genericBusinessRules: event.target.value,
      });
    } else if (type === "therapeuticArea") {
      setGenericInfoData({
        ...genreicInfoData,
        therapeuticArea: event.target.value,
      });
    } else if (type === "assetType") {
      setGenericInfoData({
        ...genreicInfoData,
        assetType: event.target.value,
      });
    }
  };

  const browseOtherGenInfo = (type) => {
    setBrowseDocType(type);
    setShowBrowseGenInfoDocModal(true);
  };

  const clearOtherGenInfo = (type) => {
    if (type === "isi") {
      setGenericInfoData({ ...genreicInfoData, isi: "" });
    } else if (type === "pi") {
      setGenericInfoData({ ...genreicInfoData, pi: "" });
    } else if (type === "mg") {
      setGenericInfoData({ ...genreicInfoData, mg: "" });
    } else if (type === "masa") {
      setGenericInfoData({ ...genreicInfoData, masa: "" });
    }
  };
  const hideBrowseGenInfoDocModal = () => {
    setShowBrowseGenInfoDocModal(false);
  };
  const updateGenericInfoUrl = (type, documentLink) => {
    setShowBrowseGenInfoDocModal(false);
    const obj = { ...genreicInfoData };
    obj[type.toLowerCase()] = documentLink;
    setGenericInfoData(obj);
  };
  const handleUpdate = () => {
    const payload = {
      harvestedAssetId: props.assetDetails.harvestedAssetId,
      isi: genreicInfoData.isi,
      pi: genreicInfoData.pi,
      mg: genreicInfoData.mg,
      genericBusinessRules: genreicInfoData.genericBusinessRules,
      therapeuticArea: genreicInfoData.therapeuticArea,
      assetType: genreicInfoData.assetType,
    };
    upDateModuleAssetLinks(payload, (response, err, errorCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        toast.error(errCoponent, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.success(<div style={{ color: "#000" }}>Save Successful</div>, {
          autoClose: 2000,
          position: "top-center",
        });
        if (props.refreshSelectAsset) {
          props.refreshSelectAsset();
        }
      }
    });
  };
  return (
    <>
      <div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 ">
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.brand"),
                  label: t("uploadAssetLabel.brand"),
                  value: assetDetails.brand,
                  items: brands,
                  disabled: { isdisabled },
                }}
                dropdownclass="generic-info-generic-class"
              />
              <TextField
                className="generic-info-generic-class master-asset-name"
                id="masterAssetName"
                label={t("uploadAssetLabel.masterAssetName")}
                variant="outlined"
                fullWidth={true}
                disabled={isdisabled}
                value={[`${assetDetails.assetName}`]}
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.channelTypeItWasUsed"),
                  label: t("uploadAssetLabel.channelTypeItWasUsed"),
                  value: assetDetails.channelType,
                  items: channels,
                  disabled: { isdisabled },
                }}
                dropdownclass="generic-info-generic-class"
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.campaignItWasUsed"),
                  label: t("uploadAssetLabel.campaignItWasUsed"),
                  value: assetDetails.campaignName,
                  items: campaigns,
                  disabled: { isdisabled },
                }}
                dropdownclass="generic-info-generic-class"
              />
              <DropDownWithLabel
                class="tf-and-drp-dwn-with-label-disabled"
                options={{
                  name: t("uploadAssetLabel.market"),
                  label: t("uploadAssetLabel.market"),
                  value: assetDetails.market,
                  items: [assetDetails.market],
                  disabled: { isdisabled },
                }}
                dropdownclass="generic-info-generic-class"
              />
              <TextField
                className="generic-info-generic-class tf-and-drp-dwn-with-label-disabled"
                id="dosing"
                label={t("uploadAssetLabel.dosing")}
                variant="outlined"
                fullWidth={true}
                value={[`${assetDetails.dosing}`]}
                disabled={isdisabled}
              />
              <TextField
                className="generic-info-generic-class tf-and-drp-dwn-with-label-disabled"
                id="masterAssetLink"
                label="Master Asset Link"
                variant="outlined"
                fullWidth={true}
                value={genreicInfoData.masa || ""}
                disabled={true}
              />
              <TextField
                className="generic-info-generic-class tf-and-drp-dwn-with-label-disabled"
                id="audienceSegment"
                label={t("uploadAssetLabel.audienceSegment")}
                variant="outlined"
                fullWidth={true}
                value={[`${assetDetails.audienceSegment}`]}
                disabled={isdisabled}
              />
            </div>

            <div className="col-md-6 mt-0.5">
              <TextField
                className="generic-info-generic-class description text-area-field-height denovoDescription tf-and-drp-dwn-with-label-disabled"
                id="description"
                label={t("uploadAssetLabel.description")}
                variant="outlined"
                fullWidth={true}
                rows={9}
                rowsMax={9}
                multiline
                value={[`${assetDetails.description}`]}
                disabled={isdisabled}
              />

              <TextField
                className="generic-info-generic-class description text-area-field-height denovoDescription "
                id="genericBusinessRules"
                label="Generic Business Rules"
                variant="outlined"
                fullWidth={true}
                rows={9}
                rowsMax={9}
                multiline
                value={genreicInfoData.genericBusinessRules}
                disabled={
                  props.assetDetails.locked === "Locked" ? "disable" : ""
                }
                onChange={(e) => {
                  handleChangeOtherGenInfo(e, "genericBusinessRules");
                }}
              />
              <div className="genricInfoBtnContainer">
                <TextField
                  className={`generic-info-generic-class ${
                    props.assetDetails.locked === "Locked"
                      ? "tf-and-drp-dwn-with-label-disabled"
                      : "master-asset-name asset-link-color"
                  }`}
                  id="isi"
                  label="ISI"
                  variant="outlined"
                  fullWidth={true}
                  value={genreicInfoData.isi}
                  onChange={(e) => {
                    handleChangeOtherGenInfo(e, "isi");
                  }}
                  disabled={
                    props.assetDetails.locked === "Locked" ? "disable" : ""
                  }
                />
                {genreicInfoData.isi.length <= 0 ? (
                  props.assetDetails.locked === "Locked" ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                      onClick={() => {
                        browseOtherGenInfo("ISI");
                      }}
                    >
                      Browse
                    </button>
                  )
                ) : props.assetDetails.locked === "Locked" ? (
                  ""
                ) : (
                  <button
                    type="button"
                    className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                    onClick={() => {
                      clearOtherGenInfo("isi");
                    }}
                  >
                    <img src={closeIcon} alt="" width="12" />
                  </button>
                )}
              </div>
              <div className="genricInfoBtnContainer">
                <TextField
                  className={`generic-info-generic-class ${
                    props.assetDetails.locked === "Locked"
                      ? "tf-and-drp-dwn-with-label-disabled"
                      : "master-asset-name asset-link-color"
                  }`}
                  id="pi"
                  label="PI"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    handleChangeOtherGenInfo(e, "pi");
                  }}
                  value={genreicInfoData.pi}
                  disabled={
                    props.assetDetails.locked === "Locked" ? "disable" : ""
                  }
                />
                {genreicInfoData.pi.length <= 0 ? (
                  props.assetDetails.locked === "Locked" ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                      onClick={() => {
                        browseOtherGenInfo("PI");
                      }}
                    >
                      Browse
                    </button>
                  )
                ) : props.assetDetails.locked === "Locked" ? (
                  ""
                ) : (
                  <button
                    type="button"
                    className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                    onClick={() => {
                      clearOtherGenInfo("pi");
                    }}
                  >
                    <img src={closeIcon} alt="" width="12" />
                  </button>
                )}
              </div>
              <div className="genricInfoBtnContainer">
                <TextField
                  className={`generic-info-generic-class ${
                    props.assetDetails.locked === "Locked"
                      ? "tf-and-drp-dwn-with-label-disabled"
                      : "master-asset-name asset-link-color"
                  }`}
                  id="MG"
                  label="MG"
                  variant="outlined"
                  fullWidth={true}
                  onChange={(e) => {
                    handleChangeOtherGenInfo(e, "mg");
                  }}
                  value={genreicInfoData.mg}
                  disabled={
                    props.assetDetails.locked === "Locked" ? "disable" : ""
                  }
                />
                {genreicInfoData.mg.length <= 0 ? (
                  props.assetDetails.locked === "Locked" ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                      onClick={() => {
                        browseOtherGenInfo("MG");
                      }}
                    >
                      Browse
                    </button>
                  )
                ) : props.assetDetails.locked === "Locked" ? (
                  ""
                ) : (
                  <button
                    type="button"
                    className="btn btn-link genricInfoBtn genricInfoBrowseButtonTopMargin"
                    onClick={() => {
                      clearOtherGenInfo("mg");
                    }}
                  >
                    <img src={closeIcon} alt="" width="12" />
                  </button>
                )}
              </div>
              <TextField
                className="generic-info-generic-class tf-and-drp-dwn-with-label-disabled"
                id="therapeuticArea"
                label="Therapeutic Area"
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                  handleChangeOtherGenInfo(e, "therapeuticArea");
                }}
                value={genreicInfoData.therapeuticArea}
                disabled={true}
              />
            </div>

            <div className="col-md-6">
              <div className="audience">
                <span className="audience">
                  {t("uploadAssetLabel.audience")}
                </span>
              </div>

              <div>
                {audienceDataSource.map((item) => {
                  return (
                    <div
                      className="custom-control custom-checkbox custom-control-inline aud-check-box-with-label"
                      key={item}
                    >
                      <input
                        type="checkbox"
                        name={item}
                        className="custom-control-input audience-check-box"
                        id={item}
                        checked={isThisAudienceAdded(item)}
                        disabled={isdisabled}
                      ></input>
                      <label
                        className="custom-control-label aud-check-label"
                        htmlFor={item}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            {isDamsFlow && (
              <div className="col-md-6">
                <div className="audience">
                  <span className="audience">Asset Type</span>
                </div>
                <div>
                  <Form>
                    {["radio"].map((type) => (
                      <div
                        key={`inline-${type}`}
                        className="form-check form-check-inline mb-2"
                      >
                        {/* <span
                          className="title mr-5"
                          style={{
                            opacity:
                              props.assetDetails.locked === "Locked"
                                ? "0.5"
                                : "1",
                          }}
                        >
                          Asset Type
                        </span> */}
                        <Form.Check
                          inline
                          label="Branded"
                          name="assetTypeOption"
                          type={type}
                          id={`inline-${type}-1`}
                          value="branded"
                          checked={genreicInfoData.assetType === "branded"}
                          onChange={(e) => {
                            handleChangeOtherGenInfo(e, "assetType");
                          }}
                          disabled={props.assetDetails.locked === "Locked"}
                        />
                        <Form.Check
                          inline
                          label="Unbranded"
                          name="assetTypeOption"
                          type={type}
                          id={`inline-${type}-2`}
                          value="unbranded"
                          checked={genreicInfoData.assetType === "unbranded"}
                          onChange={(e) => {
                            handleChangeOtherGenInfo(e, "assetType");
                          }}
                          disabled={props.assetDetails.locked === "Locked"}
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            )}
            {props.assetDetails.locked === "Locked" ? (
              ""
            ) : (
              <div className="col-md-12">
                <div className="form-group mt-4 pl-10 pr-10">
                  <button
                    type="submit"
                    className="btn btn-primary text-uppercase btn-block harvested-btn-create-module"
                    onClick={handleUpdate}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        displayModal={showBrowseGenInfoDocModal}
        hideModal={hideBrowseGenInfoDocModal}
        title={"Existing Modules detected"}
        size="xl"
        dilogClassName="alertModalSmall modalNoborder"
      >
        <BrowseGenericInfoDocument
          browseDocType={browseDocType}
          hideBrowseGenInfoDocModal={hideBrowseGenInfoDocModal}
          updateGenericInfoUrl={updateGenericInfoUrl}
          sourceType={props?.assetDetails?.sourceType}
        />
      </CustomModal>
    </>
  );
};

export default GeenricInformationModule;
