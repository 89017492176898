import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import i18next from "i18next";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Import custom components
import errComponentToRender from "../Uitls/apiErrorHandling";
import CircularProgressBar from "../Uitls/CircularProgressBar";
import { useHistory } from "react-router-dom";
import LogoutCTA from "../Pages/LogoutCTA";
import TranslateHeader from "../components/Headers/HeaderTranslateModule";
import TranslateModuleNav from "../components/Headers/TranslateModuleNav";

import BusinessRules from "../components/TranslateFlow/BusinessRules/TranslateBusinessRules";

//Import Util and apis
import {
  routes,
  moduleTypes,
  getTranslationJobType,
  hasUserLoggedInAsReviewer,
} from "../Uitls/Util";
import {
  getExcludeModulesBusinessRules,
  getModuleDetailsById,
  getDenovoModulesByBrandAndCampaign,
} from "../services/apis";

//Import styles
import "../styles/pages/CreateModuleBusinessRules.scss";

function TranslateBusinessRules(props) {
  let history = useHistory();

  const [businessRulesData, setBusinessRulesData] = useState([]);
  const [usageInstructions, setUsageInstructions] = useState(undefined);
  const [isloading, setIsloading] = useState(false);

  const [module, setModule] = useState({});
  const [showOriginalToggle, setShowOriginalToggle] = useState(false);
  const [hasSourceRefModuleId, setHasSourceRefModuleId] = useState(false);
  const [sourceModuleToggle, updateSourceModuleToggle] = useState(false);

  const getModuleIdFromLocalStorage = () => {
    //Fetch the Module Details here
    let moduleInfoFromLocalStorage = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    );
    let moduleId = undefined;
    if (props && props.location && props.location.state) {
      moduleId = props.location.state.moduleId;
    } else {
      moduleId =
        moduleInfoFromLocalStorage &&
        moduleInfoFromLocalStorage.translateModuleId;
    }
    return moduleId;
  };

  useEffect(() => {
    const moduleId = getModuleIdFromLocalStorage();
    if (moduleId) {
      getModuleDetails(moduleId);
    }
  }, []);

  const getTacticBusinessRuleModels = (
    brandName,
    campaign,
    businessRules,
    moduleType,
    sourceModuleId,
    currentModuleId
  ) => {
    let payLoad = {
      brandName: brandName,
      campaign: campaign,
      moduleType: moduleType,
      excludeModules: [currentModuleId],
    };
    if (moduleType !== "Original") {
      payLoad.sourceModuleId = sourceModuleId;
    }
    if (payLoad) {
      getExcludeModulesBusinessRules(
        payLoad,
        currentModuleId,
        (response, err, errorCode) => {
          setIsloading(false);
          if (err) {
            showError(errorCode);
          } else {
            parseAndSaveBusinessRules(businessRules, response.data);
          }
        }
      );
    } else {
      setIsloading(false);
    }
  };

  const denovoBusinessRuleModules = (
    brand,
    campaign,
    market,
    businessRules,
    currentModuleId
  ) => {
    if (!brand || !campaign || !market) {
      setIsloading(false);
      return;
    }
    getDenovoModulesByBrandAndCampaign(
      { brand, campaign, market, currentModuleId },
      (response, err, errCode) => {
        setIsloading(false);
        if (response) {
          parseAndSaveBusinessRules(businessRules, response.data);
          return;
        }
        showError(errCode);
      }
    );
  };

  const getModuleDetails = (moduleId, isCurrent = true) => {
    setIsloading(true);
    getModuleDetailsById(moduleId, (response, err, errorCode) => {
      if (err) {
        //const errCoponent = errComponentToRender(errorCode);
        setIsloading(false);

        if (errorCode) {
          showError(errorCode);
        }
      } else {
        const data = response.data;
        if (data) {
          setModule(data);
          if (isCurrent && response.data.translationRefModuleId) {
            setHasSourceRefModuleId(true);
          }
          const businessRulesNotes = data.businessRuleNotes.filter(function (
            item
          ) {
            return item.noteCondition === "UsageInstructionsGuidelines";
          });
          if (businessRulesNotes.length > 0) {
            setUsageInstructions(businessRulesNotes[0]);
          } else {
            setUsageInstructions(undefined);
          }
          const businessRules = data.moduleBusinessRules;
          const moduleCreationMethod = data.creationMethodType;
          const moduleType = data.moduleType;
          const sourceModuleId = data.sourceModuleId;
          if (
            moduleCreationMethod &&
            moduleCreationMethod.toLowerCase() === moduleTypes.denovo
          ) {
            denovoBusinessRuleModules(
              data.brandName,
              data.campaign,
              data.assetDetails.market,
              businessRules,
              data.id
            );
          } else {
            getTacticBusinessRuleModels(
              data.brandName,
              data.campaign,
              businessRules,
              moduleType,
              sourceModuleId,
              data.id
            );
          }
        } else {
          setIsloading(false);
        }
      }
    });
  };

  const filterLeftSideDataById = (id, alreadyAddedModules) => {
    return alreadyAddedModules.filter((item) => {
      return id === item.id;
    });
  };

  const parseAndSaveBusinessRules = (data, alreadyAddedModules) => {
    let businessRules = [];
    if (data) {
      businessRules = data.map((item) => {
        let filtered = filterLeftSideDataById(
          item.targetModuleId,
          alreadyAddedModules
        );
        if (filtered && filtered.length > 0) {
          filtered = filtered[0];
        } else {
          filtered = {};
        }
        return {
          id: item.id,
          createdBy: item.createdBy,
          createdDateTime: item.createdDateTime,
          requestId: "",
          category: item.ruleCategory,
          condition: item.ruleCondition,
          targetModuleId: item.targetModuleId,
          status: item.status,
          moduleId: item.moduleId,
          name: filtered.name,
          moduleCode: filtered.moduleCode,
        };
      });
    }
    setBusinessRulesData(businessRules);
  };

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  const handleProceed = async () => {
    history.push(routes.translateAdditionalInfo);
  };

  const handleClose = () => {
    window.history.back();
  };

  const isLastPageToReviewOrTranslate = () => {
    const selectedPages = JSON.parse(
      window.localStorage.getItem("translateModuleInfo")
    ).translateComponents;
    if (selectedPages[selectedPages.length - 1] === "businessrules") {
      return true;
    }
    return false;
  };

  const getProceedButtonTitle = () => {
    if (isLastPageToReviewOrTranslate()) {
      const jobType = getTranslationJobType();
      if (jobType && jobType.trim().toLowerCase() === "self") {
        return i18next.t("translated.preview").toUpperCase();
      }
    }
    return undefined;
  };

  const handleLanguageToggleBtn = () => {
    const newToggleState = !showOriginalToggle;
    setShowOriginalToggle(newToggleState);
    if (newToggleState) {
      getModuleDetails(module.moduleIdEnglish, false);
    } else {
      getModuleDetails(getModuleIdFromLocalStorage());
    }
  };

  const handleSourceModuleToggle = () => {
    const updatedToggleState = !sourceModuleToggle;
    updateSourceModuleToggle(updatedToggleState);
    if (updatedToggleState) {
      getModuleDetails(module.translationRefModuleId, false);
    } else {
      getModuleDetails(getModuleIdFromLocalStorage());
    }
  };

  return (
    <>
      <LogoutCTA />
      <div className="trasnlateHeaderBg">
        <TranslateHeader />
        <TranslateModuleNav
          btnProceed={
            getProceedButtonTitle() || i18next.t("generalText.proceed")
          }
          btnClose={i18next.t("generalText.close")}
          btnSave={i18next.t("generalText.save")}
          disableProceed={false}
          handleProceed={handleProceed}
          handlePageClose={handleClose}
          //handlePageSave={saveTranslated}
          pageIndex={3}
          toggle={showOriginalToggle}
          handleLanguageToggleBtn={handleLanguageToggleBtn}
          moduleName={module.name || ""}
          moduleCode={module.moduleCode || ""}
          sourceLanguage={module.translatedFrom || ""}
          targetLanguage={module.language || ""}
          shouldHaveMultipleOptions={
            isLastPageToReviewOrTranslate() ? true : false
          }
          proceedOptions={
            isLastPageToReviewOrTranslate()
              ? ["Revision Required", "Approve"]
              : undefined
          }
          hasSourceModuleId={hasSourceRefModuleId}
          toggleSourceModule={sourceModuleToggle}
          handleSourceModuleToggle={handleSourceModuleToggle}
        />
      </div>
      <Container fluid>
        <CircularProgressBar isloading={isloading} />
        <Row className="mt-3 ml-0 mr-0 mb-4">
          <Col className="col-12 col-md-12">
            <Row>
              <Col className="pageContentCol">
                <div>
                  <Row className="">
                    <Col>
                      <h1
                        id="title-business-rule"
                        className="rulesTitle translate-b-rule-title"
                      >
                        <span>Business Rules</span>{" "}
                      </h1>
                    </Col>
                  </Row>
                  {/* <Row
                    className="mt-4"
                    style={{ marginLeft: `${getBrulesBoxesLeftMargin()}` }}
                  > */}
                  <BusinessRules
                    businessRules={businessRulesData}
                    sourceModuleToggle={sourceModuleToggle}
                    showOriginalToggle={showOriginalToggle}
                    usageInstructions={usageInstructions}
                  />

                  {/* </Row> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default TranslateBusinessRules;
