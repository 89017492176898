import { getDeConstStatus } from "../services/apis";
import { notify, apiAuthError } from "../App";
let processIds = [];
let timer;

export const addProcessIds = (ids = []) => {
  processIds = [];
  processIds.push(...ids);
  if (!timer) {
    startPolling();
  }
};

export const startPolling = () => {
  timer = setInterval(() => {
    if (processIds.length === 0) {
      stopPolling();
    }

    getDeConstStatus(processIds, (response, err, errorCode) => {
      //  console.log(response);
      // notify('8caeed93-c526-4635-af39-8b576da04501', 'status', "success", 'assetName');
      if (err) {
        //apiAuthError(errorCode);
      } else {
        response.data &&
          response.data.forEach((element) => {
            if (element.status === "Success" || element.status === "Failed") {
              removeProcessId(
                element.harvestedAssetId,
                element.status,
                element.storageAssetId,
                element.assetName
              );
            }
          });
      }
    });
  }, 3000);
};

export const stopPolling = () => {
  clearInterval(timer);
  timer = null;
};

const removeProcessId = (id, status, assetId, assetName) => {
  const index = processIds.indexOf(id);
  if (index > -1) {
    processIds.splice(index, 1);
    if (status === "Success") {
      notify(assetId, status, "success", assetName);
    } else if (status === "Failed") {
      notify(id, status, "error", assetName);
    }
  }
};

export const clearProcessIds = () => {
  processIds.length = 0;
};
