import React from "react";

import useContextMenu from "./useContextMenu";
import "../../../styles/components/useContextMenu.scss";

const Menu = ({ outerRef, compClass, addComment }) => {
  const { xPos, yPos, menu, selectedText } = useContextMenu(
    outerRef,
    compClass
  );
  if (menu && selectedText) {
    return (
      <div className="contextMenu" style={{ top: yPos, left: xPos }}>
        <div className="contextMenu--option contextMenu--option__disabled">
          Action
        </div>
        <div
          className="contextMenu--option"
          onClick={(event) => {
            addComment(selectedText);
          }}
        >
          Comment
        </div>
      </div>
    );
  }
  return <></>;
};

export default Menu;
