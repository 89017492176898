import React, { useState, useEffect } from "react";

import "../styles/pages/CreateModuleStep4.scss";
import AddCatchline from "./AddCatchline";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Banner = props => {
  const { t } = useTranslation();
  const [catchlines, setCatchlines] = useState([]);

  useEffect(() => {
    setCatchlines(props.banners);
  }, [props]);

  const onCatchlineChange = catchlines => {
    setCatchlines(catchlines);
    //update parent
    if (props.onDataChange) {
      props.onDataChange(catchlines);
    }
  };

  const removeBanner = banner => {
    if (props.onDeleteBanner) {
      props.onDeleteBanner([banner.id]);
    }
  };

  return (
    <div className="banner">
      <span className="title">Banner</span>

      <div className="section-head">
        <span className="catclineTitle">
          {catchlines.length <= 0
            ? i18next.t("channels.tagline")
            : `${i18next.t("channels.tagline")} (${catchlines.length})`}
        </span>
        {/* <div className='divider'></div> */}
      </div>
      <AddCatchline
        onCatchlineChange={onCatchlineChange}
        removeCatchline={removeBanner}
        catchlines={catchlines}
        placeholder={`Enter ${i18next.t("channels.tagline")}`}
      />
    </div>
  );
};

export default Banner;
