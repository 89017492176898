import React, { useEffect, useState } from "react";
import "../../styles/pages/MLRSubmissionPackage.scss";
import parse from "html-react-parser";
import i18next from "i18next";

const BusinessRulesSpecificInfo = (props) => {
  const [module, setModule] = useState(undefined);
  const [bModules, setBModules] = useState(undefined);
  const [pageJsx, setPageJsx] = useState(undefined);

  useEffect(() => {
    if (props.module && props.bModules) {
      if (module && module.id && module.id !== props.module.id) {
        setPageJsx(undefined);
      }
      setModule(props.module);
      setBModules(props.bModules);
    }
  }, [props]);

  useEffect(() => {
    if (props.setJsx) {
      props.setJsx(pageJsx);
    }
  }, [pageJsx]);

  const filterRules = (filterParam) => {
    if (!module || !bModules) {
      return [];
    }

    const businessRules = module.moduleBusinessRules;
    if (!businessRules) {
      return [];
    }
    const rules = businessRules.filter((rule) => {
      return rule.ruleCondition === filterParam;
    });

    let finalRules = [];
    for (const rule of rules) {
      finalRules = finalRules.concat(
        bModules.find((item) => {
          return rule.targetModuleId === item.id;
        })
      );
    }

    return finalRules;
  };

  const renderRules = (param) => {
    const rules = filterRules(param);

    if (rules && rules.length > 0) {
      return (
        <ol>
          {rules.map((rule) => {
            return (
              rule &&
              rule.id &&
              rule.name && (
                <li
                  className="text-blue"
                  key={rule ? rule.id : ""}
                  style={{ marginBottom: "5px" }}
                >
                  <a
                    href={rule.moduleDocUrl}
                    style={{
                      color: "#1c75bb",
                      pointerEvents: "none",
                      cursor: "default",
                      textUnderlineOffset: "1.5px",
                    }}
                  >
                    {" "}
                    {rule ? rule.name : ""}
                  </a>
                </li>
              )
            );
          })}
        </ol>
      );
    }
    return (
      <span className="text-black" style={{ paddingLeft: "27px" }}>
        N/A
      </span>
    );
  };
  const renderMustExistsWith = () => {
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          This module must exist with (Mandatory)
        </td>
        <td>{renderRules("MustExistWith")}</td>
      </tr>
    );
  };
  const renderCannotExistWith = () => {
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          This module cannot exist with
        </td>
        <td>{renderRules("InCompatibleWith")}</td>
      </tr>
    );
  };
  const renderPrecedes = () => {
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          This module should precede
        </td>
        <td>{renderRules("Precedes")}</td>
      </tr>
    );
  };

  const renderOtherBusinessRules = () => {
    if (!module) {
      return;
    }
    let othRule = "";
    const guideLinesInfo = module.businessRuleNotes;
    if (guideLinesInfo && guideLinesInfo.length > 0) {
      const info = guideLinesInfo[0];
      if (info.note) {
        othRule = info.note;
      }
    }
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          {i18next.t("additionalInfo.usageOrGuidelines")}
        </td>
        <td
          className={othRule ? "text-blue" : "text-black"}
          style={{ paddingLeft: "37px" }}
        >
          {othRule && parse(othRule)}
          {!othRule && "N/A"}
        </td>
      </tr>
    );
  };
  const renderSucceeds = () => {
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          This module should succeed
        </td>
        <td>{renderRules("Succeeds")}</td>
      </tr>
    );
  };

  const renderRelatedModules = () => {
    return (
      <tr>
        <td className="tbl-row-txt-bold" style={{ width: "20%" }}>
          Related modules (Optional)
        </td>
        <td>{renderRules("RelatedTo")}</td>
      </tr>
    );
  };

  const renderData = () => {
    if (!module) {
      return <div></div>;
    }
    const jsx = (
      <div>
        <table
          className="module-specific table business-rules-specific"
          style={{
            pageBreakAfter: "always",
            marginTop: "0px",
          }}
        >
          <tbody>
            <tr className="header">
              <td>Mandatory Business Rules</td>
              <td></td>
            </tr>
            {renderMustExistsWith()}
            {renderCannotExistWith()}
            {renderPrecedes()}
            {renderSucceeds()}
            {renderOtherBusinessRules()}
            {renderRelatedModules()}
          </tbody>
        </table>
      </div>
    );

    if (!pageJsx) {
      setPageJsx(jsx);
    }
    return jsx;
  };

  return <div>{renderData()}</div>;
};

export default BusinessRulesSpecificInfo;
