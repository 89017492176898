import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import FroalaEditor from "../../../Uitls/CommonFroalEditorComponent";
import FootnoteAndAbbreviationColumn from "./FootnoteAndAbbreviationColumn";
import SubjectlineAndDisclaimerColumn from "./SubjectAndDisclaimerColumn";

import arrowLeft from "../../../images/arrowLeft.svg";
import arrowRight from "../../../images/arrowRight.svg";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";

import "../../../styles/components/TranslateClaimsGenericModal.scss";

const TranslateClaimsGenericModal = ({
  handleClose,
  dataInput,
  sourceLanguage,
  targetLanguage,
  dataType,
  saveTranslated,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const referencesValid = useRef(null);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [classForCss, setClassForCss] = useState("");
  useEffect(() => {
    setSaveDisabled(!checkIfAllFilled());
  }, [dataInput]);

  const getInputDataToRender = (source) => {
    if (dataInput && dataInput.length) {
      let toBeRendered = parse(
        dataInput[0].originalClaim || dataInput[0].originalText
      );
      if (selectedIndex > 0) {
        toBeRendered = parse(
          dataInput[selectedIndex].originalModuleClaim ||
            dataInput[selectedIndex].originalText
        );
      }
      return <div className="original-text">{toBeRendered}</div>;
    }
    return "";
  };
  const setVariantIndex = (stepValue) => {
    if (selectedIndex >= 0 && selectedIndex <= dataInput.length - 1) {
      if (selectedIndex === 0 && stepValue === -1) {
        return;
      } else if (selectedIndex === dataInput.length - 1 && stepValue === 1) {
        return;
      }
      setSelectedIndex(selectedIndex + stepValue);
    }
  };
  const getClaimAndVariantsCount = () => {
    if (dataInput.length) {
      return dataInput.length;
    }
  };

  const checkIfAllFilled = () => {
    let allFilled = true;
    dataInput.forEach((item) => {
      if (
        !(item.claim || item.moduleClaim || item.text) ||
        item.translate !== "2"
      ) {
        allFilled = false;
      }
    });
    return allFilled;
  };
  const handleTextChange = (event, index) => {
    if (index < 0) {
      return;
    } else {
      referencesValid.current.innerHTML = event;
      const text = referencesValid.current.innerText;
      if (text && text.trim().length > 0) {
        const obj = dataInput[index];
        if (obj.claim !== undefined) {
          obj.claim = event;
          obj.translate = "2";
        } else if (obj.moduleClaim !== undefined) {
          obj.moduleClaim = event;
          obj.translate = "2";
        } else if (obj.text !== undefined) {
          obj.text = dataType === "tagline" ? text : event;
          obj.translate = "2";
        }
      } else {
        const obj = dataInput[index];
        obj.translate = "1";
        if (obj.claim) {
          obj.claim = "";
        } else if (obj.moduleClaim) {
          obj.moduleClaim = "";
        } else if (obj.text) {
          obj.text = "";
        }
      }
      setSaveDisabled(!checkIfAllFilled());
    }
  };
  const getAppropriateRenders = (type) => {
    switch (type) {
      case "footnote":
        return getFootNotesToRender();
      case "abbreviation":
        return getAbbreviationRender();
      case "subjectline":
      case "tagline":
        return getSubjectLineRender();
      default:
        return getInputDataToRender();
    }
  };
  const getFootNotesToRender = () => {
    if (dataInput.length > 0) {
      return dataInput.map((footnote) => {
        return (
          <div className="d-flex original-text" key={footnote.id}>
            {
              <div className="footBoxIndex">
                <span className="iconDisplay" style={{ display: "block" }}>
                  {parse(footnote.prefix)}
                </span>
              </div>
            }
            <div>{parse(footnote.originalText)}</div>
          </div>
        );
      });
    }
    return <div></div>;
  };
  const getAbbreviationRender = () => {
    if (dataInput.length > 0) {
      return dataInput.map((abbreviation) => {
        return (
          <div className="original-text" key={abbreviation.id}>
            {
              <div className="translateAbbr">
                <span className="translateAbbrText">
                  {parse(abbreviation.prefix)}
                </span>
                <div className="footNoteLabelN">
                  {parse(abbreviation.originalText)}{" "}
                </div>
              </div>
            }
          </div>
        );
      });
    }
    return <div></div>;
  };
  const getSubjectLineRender = () => {
    if (dataInput.length > 0) {
      return dataInput.map((subjectline) => {
        return (
          <div className="original-text" key={subjectline.id}>
            {
              <div className="translateAbbr">
                <span className="translateAbbrText">
                  {subjectline.originalText
                    ? parse(subjectline.originalText)
                    : subjectline.originalText}
                </span>
                {subjectline.originalDisclaimer && (
                  <div
                    className="footNoteLabelN"
                    style={{ marginTop: "5px", fontStyle: "italic" }}
                  >
                    Disclaimer : {subjectline.originalDisclaimer}
                  </div>
                )}
              </div>
            }
          </div>
        );
      });
    }
    return <div></div>;
  };

  const getPendingVariants = () => {
    if (
      dataType !== "abbreviation" &&
      dataType !== "footnote" &&
      dataInput.length > 1
    ) {
      const pendingVariants = [...dataInput].filter((item) => {
        return item.translate === "1";
      });
      return pendingVariants.length;
    }
    return 0;
  };
  const onBoxSelected = (event, id) => {
    if (id && id !== null) {
      setTimeout(() => {
        setCssOnSelectedBox(id);
      }, 1);
    }
  };
  const setCssOnSelectedBox = (boxId) => {
    if (boxId === "translate-box") {
      setClassForCss("translate-box-border");
    } else {
      setClassForCss("");
    }
  };

  document.addEventListener("click", (event) => {
    if (event) {
      setCssOnSelectedBox("");
    }
  });
  return (
    <div style={{ zIndex: "999" }}>
      <Row>
        <Col className="headerHrLine pb-2">
          <div className="d-flex align-items-center pl-2 modal-headerText">
            Translate
          </div>
          <div className="pl-2">
            {" "}
            {sourceLanguage} to {targetLanguage}
          </div>
        </Col>
      </Row>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex align-items-center pl-2 language-text">
              {sourceLanguage}
            </div>
          </Col>
          <Col>
            <div className="d-flex align-items-center pl-2 language-text">
              {targetLanguage}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="translate-box">
            <div style={{ marginTop: "15px" }}>
              {getAppropriateRenders(dataType)}
              {dataInput.length > 1 &&
                dataType !== "footnote" &&
                dataType !== "abbreviation" &&
                dataType !== "subjectline" && (
                  <div className="button-row" style={{ zIndex: "100" }}>
                    <button
                      className="leftBtn"
                      onClick={() => {
                        setVariantIndex(-1);
                      }}
                    >
                      <img
                        src={arrowLeft}
                        alt="Previous"
                        className="opacity50"
                      />
                    </button>
                    <span className="slideCount">
                      {selectedIndex + 1}/{getClaimAndVariantsCount()}
                    </span>
                    <button
                      className="rightBtn"
                      onClick={() => {
                        setVariantIndex(1);
                      }}
                    >
                      <img src={arrowRight} alt="Next" className="opacity50" />
                    </button>
                  </div>
                )}
              {getPendingVariants() > 0 && (
                <div className="pending-warning">
                  <ErrorRoundedIcon />
                  <span>{getPendingVariants()} Variants pending</span>
                </div>
              )}
            </div>
          </Col>
          {dataType !== "abbreviation" &&
            dataType !== "footnote" &&
            dataType !== "subjectline" && (
              <Col
                className={`translate-box ${classForCss}`}
                onClick={(event) => {
                  onBoxSelected(event, "translate-box");
                }}
                id="translate-box"
              >
                <FroalaEditor
                  tag="textarea"
                  selectedAtom={
                    dataInput[selectedIndex].translate === "2"
                      ? dataInput[selectedIndex].claim ||
                        dataInput[selectedIndex].moduleClaim ||
                        dataInput[selectedIndex].text
                      : ""
                  }
                  handleModelChange={(event) => {
                    handleTextChange(event, selectedIndex);
                  }}
                />
              </Col>
            )}
          {(dataType === "abbreviation" || dataType === "footnote") && (
            <Col className="translateModalScroll customScrollBar">
              <FootnoteAndAbbreviationColumn
                dataItems={dataInput}
                disableSave={setSaveDisabled}
                checkIfAllFilled={checkIfAllFilled}
              />
            </Col>
          )}
          {dataType === "subjectline" && (
            <Col className="translateModalScroll customScrollBar">
              <SubjectlineAndDisclaimerColumn
                dataItems={dataInput}
                disableSave={setSaveDisabled}
              />
            </Col>
          )}
        </Row>
      </Container>
      <Row>
        <Col className="col-12 col-md-6 footer-line">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-link closeAnchor"
              style={{
                marginLeft: "0px",
                fontSize: "12px",
                float: "right",
                marginRight: "20px",
                marginTop: "30px",
                fontWeight: "600",
              }}
              onClick={() => handleClose()}
            >
              CLOSE
            </button>
          </div>
        </Col>
        <Col className="col-12 col-md-6 align-items-right footerLine">
          <div className="align-items-right">
            <button
              type="button"
              className="btn btn-primary btn-lg refAnchorNext"
              style={{
                float: "right",
                marginRight: "30px",
                width: "157px",
                fontWeight: "bold",
                marginTop: "20px",
              }}
              // disabled={
              //   dataType === "subjectline" ||
              //   dataType === "tagline" ||
              //   dataType === "dosage" ||
              //   dataType === "genericBusinessRules" ||
              //   dataType === "moduleUsageRecommendation" ||
              //   dataType === "otherBusinessRules"
              //     ? saveDisabled
              //     : false
              // }
              disabled={saveDisabled}
              onClick={() => {
                saveTranslated(dataInput);
              }}
            >
              SAVE
            </button>
          </div>
        </Col>
      </Row>
      <div className="d-none" ref={referencesValid}></div>
    </div>
  );
};
export default TranslateClaimsGenericModal;
