//Import React dependencies

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import DropDownWithLabel from "../../../components/DropDownWithLabel";
import { getBrandsAndCampaigns } from "../../../services/apis";
import ErrComponentToRender from "../../../Uitls/apiErrorHandling";
//Import Styles
import "../../../styles/components/ExistingTatics.scss";
import DropDownWithSearch from "../../../Uitls/DropDownWithSearch";

const ModularContentMatrices = (props) => {
  const { t } = useTranslation();
  const {
    setChannelSelectionModal,
    modules,
    filterContentMatricesBasedOnMatrixId,
    selectedContentMatrixID,
    setSelectedContentMatrixID,
    matrixIdSource,
  } = props;
  const [productList, setProductList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [contentMatrixList, setcontentMatrixList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [brandsAndCampaignsData, setBrandsAndCampaignsData] = useState({});

  const showError = (value) =>
    toast.error(value, { position: toast.POSITION.BOTTOM_RIGHT });

  const fetchBrandsAndCampaigns = () => {
    getBrandsAndCampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        showError(errCoponent);
      } else {
        setBrandsAndCampaignsData(response.data);
        const data = response.data.map((item) => item.brandName);
        setProductList(data);
      }
    });
  };

  useEffect(() => {
    fetchBrandsAndCampaigns();
  }, []);

  useEffect(() => {
    if (!selectedProduct) {
      setcontentMatrixList([]);
    } else {
      let contentMatrices = [];
      let data;
      if (selectedProduct && selectedCampaign) {
        data = modules.filter((item) => {
          return (
            item.brand === selectedProduct && item.campaign === selectedCampaign
          );
        });
      } else {
        data = modules.filter((item) => {
          return item.brand === selectedProduct;
        });
      }
      data.forEach((module) => {
        contentMatrices = contentMatrices.concat(module.contentMatrices);
      });
      const contentMatrixIdList = contentMatrices.map(
        (item) => item.contentMatrixId
      );
      setcontentMatrixList(contentMatrixIdList);
    }
  }, [selectedProduct, selectedCampaign]);

  const onDropDownListChange = (value, type) => {
    if (type === "product") {
      setSelectedCampaign("");
      const listOfCampagin = brandsAndCampaignsData.filter((brand) => {
        return brand.brandName === value;
      });
      const finalList = listOfCampagin[0].campaigns.map(
        (item) => item.campaignName
      );
      setCampaignList(finalList);
      setSelectedProduct(value);
      setSelectedCampaign("");
      setSelectedContentMatrixID("");
    } else if (type === "campaign") {
      setSelectedCampaign(value);
      setSelectedContentMatrixID("");
    } else {
      setSelectedContentMatrixID(value);
      filterContentMatricesBasedOnMatrixId(value?.displayName);
    }
  };

  return (
    <>
      <div className="row mb-4 mt-3">
        <div className="col-md-12">
          <div className="row align-items-center matrixFilterRow">
            <div className="col-md-3">
              <DropDownWithLabel
                class="read mt-0"
                options={{
                  name: "Product",
                  label: "Product",
                  value: selectedProduct,
                  items: productList,
                  handleChange: (e) => {
                    onDropDownListChange(e.target.value, "product");
                  },
                  disabled: false,
                }}
              />
            </div>
            <div className="col-md-3">
              <DropDownWithLabel
                class="read mt-0"
                options={{
                  name: "Campaign",
                  label: "Campaign",
                  value: selectedCampaign,
                  items: campaignList,
                  handleChange: (e) => {
                    onDropDownListChange(e.target.value, "campaign");
                  },
                  disabled: false,
                }}
              />
            </div>
            <div className="col-md-3" style={{ marginTop: "10px" }}>
              <DropDownWithSearch
                class="read mt-0 drp-dwn-channel-matrix"
                options={{
                  value: selectedContentMatrixID,
                  name: t("channelMatrix.channelMatrixId"),
                  label: t("channelMatrix.channelMatrixId"),
                  items: matrixIdSource,
                  handleChange: (channelMatrix) => {
                    onDropDownListChange(channelMatrix, "contentmatrixid");
                  },
                  disabled: false,
                }}
              />
            </div>
            <div className="col-md-3 text-right">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setChannelSelectionModal(true);
                }}
              >
                {t("channelMatrix.createChannelMatrix")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModularContentMatrices;
