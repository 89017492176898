import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TextField from "@material-ui/core/TextField";
import searchIcon from "../images/search.svg";
import { toast } from "react-toastify";
import "../styles/components/ReusableText.scss";
import DropDownWithLabel from "./DropDownWithLabel";
import Table from "./TableComponent";
import { addReusableTextList } from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import CircularProgressBar from "../Uitls/CircularProgressBar";
const ReusableTextModal = (props) => {
  const { t } = useTranslation();
  const {
    hideReusableTextModal,

    addedReusableTextIds,
    addReusableTextrow,
    reusableTextOption,
    setReusableTextOption,
    searchInput,
    setSearchInput,
    setSelectedRowsDetails,
    selectedRowsDetails,
    initialReusableTexts,
    reusableTextAdded,
    isloading,
    filteredReusableTexts,
    setFilteredReusableTexts,
    setSelectedReusableTextIds,
    selectedReusableTextIds,
  } = props;

  const [shouldPopulateSearchBar, setShouldPopulateSearchBar] = useState(false);

  const reusableTexttypes = ["Pre Header", "Subject Line", "CTA"];

  useEffect(() => {
    setFilteredReusableTexts([...initialReusableTexts]);
  }, [initialReusableTexts]);

  const handleReusabletext = (e) => {
    const { name, value } = e.target;
    setFilteredReusableTexts([]);
    setReusableTextOption(value);
    executeSearch(searchInput, value);
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);

    // if (e.target.value === "") {
    //   setFilteredReusableTexts([...initialReusableTexts]);
    // }
    if (e.target.value === "" && reusableTextOption === "") {
      executeSearch(e.target.value, reusableTextOption);
      setFilteredReusableTexts([...initialReusableTexts]);
    } else {
      executeSearch(e.target.value, reusableTextOption);
    }
  };
  const executeSearch = (
    searchValue,
    reusableTextValue = reusableTextOption
  ) => {
    const lowerSearchValue = searchValue?.toLowerCase();
    const lowerReusableTextValue = reusableTextValue?.toLowerCase();

    const lowerSearchValues = lowerSearchValue
      .split(",")
      .map((value) => value.trim().toLowerCase());

    const filteredData = initialReusableTexts.filter((item) => {
      const lowerDescription = item.description?.toLowerCase();
      const lowerLink = item.link?.toLowerCase();

      if (item.reusableTextType === "CTA") {
        const lowerLabels = item.labels
          ?.map((label) => label.toLowerCase())
          .join(" ");

        return (
          lowerSearchValues.every((searchValue) =>
            [
              lowerLabels,
              lowerDescription,
              lowerLink,
              item.reusableTextType.toLowerCase(),
            ].some((text) => text?.includes(searchValue))
          ) &&
          item.reusableTextType.toLowerCase().includes(lowerReusableTextValue)
        );
      } else {
        return (
          lowerSearchValues.every((searchValue) =>
            [
              item.reusableTextType?.toLowerCase(),
              lowerDescription,
              lowerLink,
            ].some((text) => text?.includes(searchValue))
          ) &&
          item.reusableTextType.toLowerCase().includes(lowerReusableTextValue)
        );
      }
    });

    setFilteredReusableTexts(filteredData);
  };

  // const executeSearch = (
  //   searchValue,
  //   reusableTextValue = reusableTextOption
  // ) => {
  //   const lowerSearchValue = searchValue?.toLowerCase();
  //   const lowerReusableTextValue = reusableTextValue?.toLowerCase();

  //   const lowerSearchValues = lowerSearchValue
  //     .split(",")
  //     .map((value) => value.trim().toLowerCase());

  //   const filteredData = initialReusableTexts.filter((item) => {
  //     const lowerDescription = item.description?.toLowerCase();
  //     const lowerLink = item.link?.toLowerCase();

  //     if (item.reusableTextType === "CTA") {
  //       const lowerLabels = item.labels
  //         ?.map((label) => label.toLowerCase())
  //         .join(" ");

  //       return (
  //         lowerSearchValues.some(
  //           (searchValue) =>
  //             lowerLabels?.includes(searchValue) ||
  //             lowerDescription?.includes(searchValue) ||
  //             lowerLink?.includes(searchValue) ||
  //             item.reusableTextType.toLowerCase().includes(searchValue)
  //         ) &&
  //         item.reusableTextType.toLowerCase().includes(lowerReusableTextValue)
  //       );
  //     } else {
  //       return (
  //         lowerSearchValues.some(
  //           (searchValue) =>
  //             item.reusableTextType?.toLowerCase()?.includes(searchValue) ||
  //             lowerDescription?.includes(searchValue) ||
  //             lowerLink?.includes(searchValue)
  //         ) &&
  //         item.reusableTextType.toLowerCase().includes(lowerReusableTextValue)
  //       );
  //     }
  //   });

  //   setFilteredReusableTexts(filteredData);
  // };

  let columnHeaders = [
    {
      lable: "Reusable Text Type",
      field: "reusableTextId",
      width: "10rem",
    },
    {
      lable: "Description",
      field: "description",
    },
    {
      lable: "Link",
      field: "link",
      width: "12rem",
    },
  ];

  const handleReusabletextSelection = (id, isSelected) => {
    let newIds = selectedReusableTextIds ? [...selectedReusableTextIds] : [];
    let selectedData = [...selectedRowsDetails];

    if (isSelected) {
      newIds.push(id);
      const newData = filteredReusableTexts.find(
        (item) => item.reusableTextId === id
      );
      selectedData.push(newData);
    } else {
      newIds = newIds.filter((reusableTextId) => reusableTextId !== id);
      selectedData = selectedData.filter((item) => item.reusableTextId !== id);
    }

    setSelectedReusableTextIds(newIds);
    setSelectedRowsDetails(selectedData);
  };

  const handleSelectAll = (checked) => {
    const allNonDisabledIds = filteredReusableTexts
      .filter((item) => !addedReusableTextIds?.includes(item.reusableTextId))
      .map((ele) => ele.reusableTextId);
    setSelectedReusableTextIds(allNonDisabledIds);
    if (checked) {
      const newlySelectedRows = filteredReusableTexts.filter(
        (item) => !addedReusableTextIds?.includes(item.reusableTextId)
      );
      setSelectedRowsDetails((prevDetails) => {
        const newDetails = [
          ...prevDetails,
          ...newlySelectedRows.filter(
            (row) =>
              !prevDetails.some(
                (prevRow) => prevRow.reusableTextId === row.reusableTextId
              )
          ),
        ];
        return newDetails;
      });
    } else {
      setSelectedRowsDetails([]);
    }
  };

  const resetAll = () => {
    setSearchInput("");
    setReusableTextOption("");
    setFilteredReusableTexts([...initialReusableTexts]);
  };
  console.log("selectedReusableTextIds", selectedReusableTextIds);
  return (
    <>
      <div className=" reusModal custonScrollBar">
        <div className="headerconAnchor">
          <CircularProgressBar isloading={isloading} />
          <div className="row">
            <div className="col-md-12 uploadModaltitle">Add Reusable Text</div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="searchColAnchor reusablesearchBox d-flex">
                <div className="input-group mb-3 ">
                  <img src={searchIcon} alt="" className="searchIconAnchor " />
                  <input
                    id="input-search-ref-documents"
                    type="text"
                    name="searchInput"
                    className="form-control searchInputAnchor inputText "
                    placeholder="Search Reusable Text"
                    aria-describedby="button-addon2"
                    onChange={(e) => handleSearch(e)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        executeSearch(searchInput);
                      }
                    }}
                    value={searchInput}
                  />
                  {/* <button
                    className="anchorSearchBtn searcbtn"
                    type="button"
                    id="button-addon2"
                    onClick={() => executeSearch(searchInput)}
                    disabled={searchInput?.length <= 0 ? true : false}
                  >
                    SEARCH
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-md-3 reusabletypeDropdown">
              <DropDownWithLabel
                options={{
                  value: reusableTextOption,
                  name: t("Reusable Text Type"),
                  label: t("Reusable Text Type"),
                  items: reusableTexttypes,
                  handleChange: handleReusabletext,
                }}
              />
            </div>
            <div className="col-md-2 ">
              <button
                className="resetbtn"
                type="button"
                id="button-addon2"
                onClick={() => resetAll()}
                disabled={
                  searchInput?.length > 0 || reusableTextOption.length > 0
                    ? false
                    : true
                }
              >
                Reset All
              </button>
            </div>
          </div>
        </div>

        <div class="body-Select custonScrollBar">
          <Table
            initialReusableTexts={initialReusableTexts}
            rows={filteredReusableTexts}
            headers={columnHeaders}
            onCheckboxChange={(reusableText, isSelected) => {
              handleReusabletextSelection(
                reusableText?.reusableTextId,
                isSelected
              );
            }}
            unselectAll={(checked) => {
              if (checked) {
                handleSelectAll(checked);
              } else {
                setSelectedReusableTextIds(undefined);
                setSelectedRowsDetails([]);
              }
            }}
            selectedRows={selectedReusableTextIds}
            disabledIds={addedReusableTextIds}
          />
        </div>
        <div className="headerLinebottom"></div>
        <div className="footerButtonAnchor">
          <button
            type="button"
            className="btn btn-link closeAnchor"
            onClick={() => hideReusableTextModal()}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn btn-primary btn-lg uploadBtn"
            style={{
              // marginLeft: "435px",
              fontSize: "12px",
              float: "right",
              marginRight: "20px",
              marginTop: "3px",
              fontWeight: "600",
            }}
            disabled={
              !selectedReusableTextIds || selectedReusableTextIds.length === 0
            }
            onClick={addReusableTextrow}
          >
            ADD
          </button>
        </div>
      </div>
    </>
  );
};

export default ReusableTextModal;
