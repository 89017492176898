// Import react dependencies

import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

//Import utils
import {
  validateUrl,
  channelTypesAndAttributes,
  getChannelAttributes,
  globalAttributes,
} from "../../Uitls/Util";
import DropDownWithLabel from "../../components/DropDownWithLabel";

//Import styles
import "../../styles/components/MarketingMessages.scss";
import { Col, Row } from "react-bootstrap";

const CreateGlobalAttributeCTA = ({
  setCTAData,
  ctaData,
  selectedChannelAttribute,
  selectedTabIndex,
  infoIcon,
  editMode,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ disableAddLabel: true });
  //const [cta, setCTA] = useState({ url: "", labels: [""] });

  const onTextChange = (e, type, index) => {
    const text = e.target.value;
    let ctaObj = { ...ctaData };
    ctaObj = ctaData?.cta;
    if (type === "url") {
      ctaObj[type] = text;
    } else {
      let labels = ctaObj?.labels;
      labels[index] = text;
      ctaObj["labels"] = labels;
    }
    //setCTA(ctaObj);
    let err = { ...error };
    let validUrl = true;
    if (ctaObj.url.trim().length > 0) {
      validUrl = validateUrl(ctaObj.url);
      if (validUrl) {
        err.message = undefined;
        setError(err);
      }
    } else {
      if (ctaObj.url.trim().length === 0) {
        err.message = undefined;
        setError(err);
        validUrl = false;
      }
    }

    setCTAData({ havingError: !validUrl, cta: ctaObj });
  };

  const afterInputUrl = () => {
    if (ctaData?.cta?.url?.trim().length === 0) {
      return;
    }
    let err = { ...error };
    if (!validateUrl(ctaData?.cta?.url)) {
      err.message = "Invalid url";
    }
    setError(err);
  };

  const addCtaLabel = (text) => {
    let ctaObj = { ...ctaData };
    let labels = ctaObj?.cta?.labels;
    labels = labels.concat(text);
    ctaObj.cta.labels = labels;
    setCTAData(ctaObj);
    document.getElementById("input-cta-label").value = "";
    let err = { ...error };
    err.disableAddLabel = true;
    setError(err);
  };

  const removeLabel = (cta, label, labelIndex) => {
    let ctaLabels = [...cta.labels];
    ctaLabels.splice(labelIndex, 1);
    cta.labels = ctaLabels;
    let ctaObj = { ctaData };
    ctaObj.cta = cta;
    setCTAData(ctaObj);
  };

  const renderCTALabels = (cta) => {
    const items = cta?.labels || [];
    return (
      //(items.length > 0) && (

      items.map((item, index) => {
        return (
          item &&
          item.trim() !== "" && (
            <li className="catchline-item" key={item}>
              <Row className="align-items-center mt-1 mb-1">
                <Col className="col-md-10">
                  <div className="catch-line-item">{item}</div>
                </Col>
                <Col className="btn-remove">
                  <button
                    className="btn-remove"
                    id={index}
                    key={index}
                    onClick={() => removeLabel(cta, item, index)}
                  >
                    {t("uploadAssetText.remove")}
                  </button>
                </Col>
              </Row>
            </li>
          )
        );
      })

      //)
    );
  };

  return (
    <>
      <div className="row">
        <div className={`col-md-5 ${editMode ? "disbaled-marketing-msg" : ""}`}>
          <DropDownWithLabel
            class="mt-0"
            options={{
              name: "Generic Attribute Type",
              label: "Generic Attribute Type",
              value: globalAttributes.cta,
              items: Object.values(globalAttributes),
              handleChange: (e) => {
                // onDropDownListChange(e.target.value, "channel");
              },
              disabled: editMode,
            }}
          />
        </div>
      </div>
      <div className="max-char-limit">
        {" "}
        <img
          src={infoIcon}
          alt="Max char limit"
          className="img-max-char-limit"
        />
        {t("marketingMessages.maxCharLimit")}
        {selectedChannelAttribute ===
          (getChannelAttributes(
            channelTypesAndAttributes.email.name.toLowerCase()
          ).optOut ||
            getChannelAttributes(
              channelTypesAndAttributes.alert.name.toLowerCase()
            ).optOut ||
            getChannelAttributes(
              channelTypesAndAttributes.banner.name.toLowerCase()
            ).optOut) && selectedTabIndex === 0
          ? ` | ${t("marketingMessagesOptout.imageSizeLimit")}`
          : ""}
      </div>

      <div className="global-attr-cta customScrollBar">
        <div>
          <TextField
            className="tf-cta"
            id="cta-link"
            value={ctaData?.cta?.url}
            label={t("marketingMessages.ctaLink")}
            variant="outlined"
            fullWidth={true}
            autocomplete="new-password"
            autoComplete="off"
            type="url"
            inputProps={{ maxLength: 250 }}
            onChange={(e) => {
              onTextChange(e, "url");
            }}
            onBlur={(e) => {
              afterInputUrl();
            }}
            error={error?.message ? true : false}
            helperText={error?.message}
          />
          <div>
            <span className="cta-labels-title">
              {t("marketingMessages.labels")}
            </span>
            <div className="cta-labels-box ">
              <div className="input-group mb-3 catch-line add-cta-label">
                <input
                  id="input-cta-label"
                  type="text"
                  className="form-control input-catchline"
                  placeholder={t("marketingMessages.addLabel")}
                  aria-label="catchline"
                  aria-describedby="button-addon2"
                  maxLength={250}
                  onChange={(e) => {
                    const text = e.target.value;
                    let err = { ...error };
                    err.disableAddLabel = !(text && text.trim().length > 0);
                    setError(err);
                  }}
                />

                <button
                  type="button"
                  className="btn btn-link add-cta-label" //"button-add"
                  id="add-catchline"
                  style={{ paddingRight: "2px" }}
                  disabled={
                    ctaData?.cta?.url?.trim().length > 0 &&
                    validateUrl(ctaData?.cta?.url?.trim()) &&
                    !error?.disableAddLabel
                      ? false
                      : true
                  }
                  onClick={() => {
                    const text =
                      document.getElementById("input-cta-label").value;
                    if (text.trim().length > 0) {
                      addCtaLabel(text.trim());
                    }
                  }}
                >
                  {t("marketingMessages.addLabel")}
                </button>
              </div>
              <ul className="cta-labels">{renderCTALabels(ctaData?.cta)}</ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGlobalAttributeCTA;
