//Import react dependencies
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

//Import Custom components
import errComponentToRender from "../../Uitls/apiErrorHandling";
import CircularProgressBar from "../../Uitls/CircularProgressBar";
import BusinessRulesSpecificInfo from "../MlrSubmission/BusinessRulesSpecificInfo";
import ChannelSpecificInfo from "../MlrSubmission/ChannelSpecificInfo";
import ClaimAndVisualsSpecificInfo from "../MlrSubmission/ClaimAndVisualsSpecificInfo";
import ModuleSpecificInfo from "../MlrSubmission/ModuleSpecificInfo";
import SupportingVisualsImage from "../MlrSubmission/SupportingVisualsImage";
//Import apis and Utils
import { moduleTypes } from "../../Uitls/Util";
import {
  getDenovoModulesByBrandAndCampaign,
  getExcludeModulesBusinessRules,
  getModuleDetailsById,
} from "../../services/apis";
import { sortClaimsAndVisualsByRecordIndex } from "../../Pages/ModuleDetails";

const ViewModule = ({ moduleID, handleClose }) => {
  const [module, setModule] = useState(undefined);
  const [isloading, setIsloading] = useState(false);
  const [bModules, setbModules] = useState(undefined); // Business rule modules

  const showError = (errorCode) => {
    const errCoponent = errComponentToRender(errorCode);
    toast.error(errCoponent);
  };

  useEffect(() => {
    if (moduleID && !bModules) {
      getModuleDetails((isLoaded, module) => {
        if (isLoaded && module) {
          fetchModulesEligibleForBusinessRules(module);
        }
      });
    }
  }, [moduleID]);

  const getModuleDetails = (callback) => {
    setIsloading(true);

    getModuleDetailsById(moduleID, (response, err, errCode) => {
      if (err) {
        setIsloading(false);
        showError(errCode);
        callback(false);
      } else {
        setModule(response.data);
        callback(true, response.data);
      }
    });
  };

  const fetchModulesEligibleForBusinessRules = (module) => {
    let payload;
    if (module.creationMethodType.toLowerCase() === moduleTypes.denovo) {
      setIsloading(true);
      getDenovoModulesByBrandAndCampaign(
        {
          brand: module.brandName,
          campaign: module.campaign,
          moduleId: module.id,
        },
        (response, err, errCode) => {
          setIsloading(false);
          if (response) {
            setbModules([].concat(response.data));
            return;
          }
          showError(errCode);
        }
      );
    } else {
      payload = {
        brandName: module.brandName,
        campaign: module.campaign,
        moduleType: "Original",
        excludeModules: [module.id],
      };
      if (payload) {
        setIsloading(true);
        getExcludeModulesBusinessRules(
          payload,
          module.id,
          (response, err, errorCode) => {
            setIsloading(false);
            if (err) {
              showError(errorCode);
            } else {
              setbModules([].concat(response.data));
            }
          }
        );
      }
    }
  };
  const getModuleInfoFromLocalStorage = () => {
    let moduleInfo = window.localStorage.getItem("moduleInfo");
    if (moduleInfo && moduleInfo !== "undefined") {
      moduleInfo = JSON.parse(moduleInfo);
    }
    return moduleInfo;
  };
  const renderModuleSpecificInfo = () => {
    if (!module || Object.keys(module).length <= 0) {
      return <div></div>;
    }
    const jsx = (
      <div>
        {module && Object.keys(module).length > 0 && (
          <div className="module-specific-info">
            {/* <span className="head">Module-specific information</span> */}
            <ModuleSpecificInfo
              module={module}
              getModuleInfoFromLocalStorage={getModuleInfoFromLocalStorage}
              moduleTypes={moduleTypes}
            />
            {module && module.moduleBusinessRules && (
              <BusinessRulesSpecificInfo
                module={module}
                bModules={bModules}
                getModuleInfoFromLocalStorage={getModuleInfoFromLocalStorage}
                moduleTypes={moduleTypes}
                //setJsx={setBusinessSpecificJsx}
              />
            )}
            {module &&
              ((module.moduleClaims && module.moduleClaims.length > 0) ||
                (module.moduleVisuals && module.moduleVisuals.length > 0)) && (
                <ClaimAndVisualsSpecificInfo
                  module={module}
                  sortClaimsAndVisualsByRecordIndex={
                    sortClaimsAndVisualsByRecordIndex
                  }
                  getModuleInfoFromLocalStorage={getModuleInfoFromLocalStorage}
                  moduleTypes={moduleTypes}
                />
              )}
            {/* 
            {module &&
              ((module.moduleChannels && module.moduleChannels.length > 0) ||
                (module.moduleCtas && module.moduleCtas.length > 0)) && (
                <ChannelSpecificInfo
                  module={module}
                  //setJsx={setChannelSpecificJsx}
                />
              )} */}

            {module &&
              module.moduleVisuals &&
              module.moduleVisuals.length > 0 && (
                <SupportingVisualsImage
                  module={module}
                  //setJsx={setvisualImageSpecificJsx}
                />
              )}
          </div>
        )}
      </div>
    );
    return jsx;
  };

  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <h1 className="createModalHeading view-modal-heading">
        <span>{module && module.name}</span>
        <h2 className="businessRulesSubTitle view-modal-moduleId">
          {module && module.moduleCode}
        </h2>
        <button
          className="btn btn-link text-uppercase bussinessrules-btn-close view-modal-close-btn"
          onClick={() => handleClose()}
        >
          CLOSE
        </button>
      </h1>
      <div className="mlr-main-content view-module view-modal-custom-class customScrollBar">
        {renderModuleSpecificInfo()}
      </div>
      {/* <div className="dmodalBtnRow">
        <div style={{ float: "right", marginRight: "8px" }}>
          <button
            className="btn btn-link"
            onClick={(event) => {
              handleClose();
            }}
          >
            CLOSE
          </button>
        </div>
      </div> */}
    </>
  );
};

export default ViewModule;
