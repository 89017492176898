import React from "react";
import "../styles/components/contextMenu.scss";
export default class ContextMenu extends React.Component {
  state = {
    visible: false
  };
  componentDidMount() {
    document
      .getElementById(this.props.compClass)
      .addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }
  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
    console.log("Will close");
  }

  _handleContextMenu = event => {
    event.preventDefault();
    document.querySelector(".pageContentCol").click();
    this.setState({ visible: true });
    this.props.handleCont(event);
    let s = window.getSelection();
    let oRange = s.getRangeAt(0); //get the text range
    let oRect = oRange.getBoundingClientRect();
    const posX = oRect.left + oRect.width / 2 - 86; //Keeping the context menu pointer center of the text.
    const clickX = posX < 0 ? event.clientX : posX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;
    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;
    if (right) {
      this.root.style.left = `${clickX}px`;
    }
    if (left) {
      this.root.style.left = `${clickX - rootW - 5}px`;
    }
    if (top) {
      this.root.style.top = `${clickY + 20}px`;
    }
    if (bottom) {
      this.root.style.top = `${clickY - rootH - 5}px`;
    }
  };
  _handleClick = event => {
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && visible) {
      this.setState({ visible: false });
      if (this.props.setRightClickTriggered) {
        this.props.setRightClickTriggered(false);
      }
      if (this.props.setContextMenuOptions) {
        this.props.setContextMenuOptions(undefined);
      }
    }
  };
  _handleScroll = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };
  render() {
    const { visible } = this.state;
    if (this.props.contextMenuOptions) {
      const options = this.props.contextMenuOptions;
      return (
        (visible || null) && (
          <div
            ref={ref => {
              this.root = ref;
            }}
            className="contextMenu"
          >
            <div className="contextMenu--option contextMenu--option__disabled">
              {options.head}
            </div>
            {options.options.map((opt, index) => {
              return (
                <>
                  <div
                    className="contextMenu--option"
                    id={opt}
                    onClick={event => {
                      this.props.onSuperScriptClick(
                        options.docId,
                        opt.toLowerCase(),
                        options.type
                      );
                    }}
                  >
                    {opt}
                  </div>
                  {index + 1 < options.options.length && (
                    <div className="contextMenu--separator" />
                  )}
                </>
              );
            })}
          </div>
        )
      );

      return;
    }
    var disableClass =
      this.props.selectedTextPrimaryClaim  
        ? ""
        : "contextMenu--option__disabled";
    return (
      (visible || null) && (
        <div
          ref={ref => {
            this.root = ref;
          }}
          className="contextMenu"
        >
          <div className="contextMenu--option contextMenu--option__disabled">
            Add a
          </div>
          <div
            className={`contextMenu--option ${ this.props.disableClass === "disable-FootNote" ? "contextMenu--option__disabled" : this.props.selectedTextPrimaryClaim  
            ? ""
            : "contextMenu--option__disabled"}`}
            id={this.props.footNoteId}
            onClick={this.props.handleFootNote}
          >
            Footnote
          </div>
          <div className="contextMenu--separator" />
          <div
            className={`contextMenu--option ${disableClass}`}
            id={this.props.abbriviationId}
            title={this.props.selectedTextPrimaryClaim}
            onClick={this.props.handleAbbriviation}
          >
            Abbreviations
          </div>
          <div className="contextMenu--separator" />
          <div
            className={`contextMenu--option ${disableClass}`}
            id={this.props.referenceId}
            onClick={this.props.handleReference}
          >
            Reference
          </div>
        </div>
      )
    );
  }
}
