//Import react dependecies
import React from "react";
import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import moment from "moment";
import { useTranslation } from "react-i18next";

//Import from Utils
import {
  getClickableLink,
  globalAttributes,
  getAllChannelAttributes,
  getMlrStatusIcon,
  capitalizeString,
  getLockIcon,
} from "../../Uitls/Util";
import { isSubjectline } from "./view/ViewChannelAttribute";
//Import styles
import "../../styles/components/MarketingMessages.scss";

//Import Resources

import menuIcon from "../../images/icon - menu vertical.svg";
import deleteIcon from "../../images/deleteIcon.svg";
import editedIcon from "../../images/pencilIcon.svg";
import { isOptOut } from "../ContentMatrix/SelectableMarketingMessageCard";

export const isMarketingMessageEditable = (marketingMessage) => {
  if (marketingMessage.lockStatus) {
    return false;
  } else if (
    !marketingMessage.mlrStatus ||
    marketingMessage.mlrStatus.trim().toLowerCase() !== "approved"
  ) {
    return true;
  }
  return false;
};

export const isCTA = (marketingMessage) => {
  return (
    marketingMessage?.attribute?.toLowerCase() ===
    globalAttributes.cta.toLowerCase()
  );
};
const MarketingMessageCard = ({
  marketingMessage,
  startEditingMessage,
  startPublishing,
  view,
}) => {
  const { t } = useTranslation();

  const getActionButton = (marketingMessage, type) => {
    switch (type) {
      case "delete":
        if (isMarketingMessageEditable(marketingMessage)) {
          return (
            <img
              src={deleteIcon}
              alt="Delete"
              className="deleteIcon-class"
              onClick={() => {}}
            />
          );
        }
        break;
      case "edit":
        if (isMarketingMessageEditable(marketingMessage)) {
          return (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                if (isCTA(marketingMessage)) {
                  return;
                }
                startEditingMessage(marketingMessage);
              }}
            >
              Edit
            </button>
          );
        }
        break;
      case "publish":
        if (isMarketingMessageEditable(marketingMessage)) {
          return (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                if (isCTA(marketingMessage) || isOptOut(marketingMessage)) {
                  return;
                }
                startPublishing(marketingMessage);
              }}
            >
              {t("generalText.publishToDams", {
                dams: capitalizeString(marketingMessage.dams || "DAMS"),
              })}
            </button>
          );
        }
        break;
      case "view":
        return (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              view(marketingMessage);
            }}
          >
            View
          </button>
        );

      default:
        break;
    }
  };

  const getModifiedStatus = (createdTime, updatedTime) => {
    let edited;
    const beginningTime = moment(createdTime, "YYYY-MM-DD HH:mm:ss");
    const endTime = moment(updatedTime, "YYYY-MM-DD HH:mm:ss");
    edited = beginningTime.isBefore(endTime);
    return edited;
  };

  const mlrStatusIcon = (marketingMessage) => {
    return (
      <img src={getMlrStatusIcon(marketingMessage.mlrStatus)} alt=""></img>
    );
  };

  const ctaCount = (message) =>
    message.length > 3 ? (
      <p className="m-0 w-100 minheightpP18">+{message.length - 3} more</p>
    ) : (
      <p className="m-0 w-100 minheightpP18"></p>
    );
  return (
    <>
      <div
        className={
          (!marketingMessage.mlrStatus
            ? "marketing-msg-card-background-not-submitted-to-dams"
            : "") +
          " " +
          "marketing-msg-card marketing-message-card-margin-top"
        }
      >
        <Row>
          <Col className="col-md-6">
            {marketingMessage.documentNumber ? "Veeva ID:" : ""}{" "}
            <span className="metadata">{marketingMessage.documentNumber}</span>
          </Col>
          <Col className="col-md-6">
            <div className="status-and-lock">
              <span className="mr-4">
                {getModifiedStatus(
                  marketingMessage.createdDateTime,
                  marketingMessage.updatedDateTime
                ) ? (
                  <>
                    <img src={editedIcon} alt="Edited" /> Edited
                  </>
                ) : null}
              </span>
              <span className="mlr-status">
                {mlrStatusIcon(marketingMessage)}{" "}
                {marketingMessage.mlrStatus || "Not Submitted"}
              </span>
              {getActionButton(marketingMessage, "delete")}
              {getLockIcon(marketingMessage)}
              <img src={menuIcon} alt="" className="menu"></img>
            </div>
          </Col>
        </Row>
        {/* <------------------------ FOR CTA ------------------------> */}
        {isCTA(marketingMessage) && (
          <Row className="m-message">
            <a
              href={getClickableLink(marketingMessage.url)}
              className="cta-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {marketingMessage.url}
            </a>

            <ol
              type="1"
              className="cta-labels CTA marketingMsgsMinHeight marketingMsgsCtaellipsis w-100"
            >
              {marketingMessage.labels.slice(0, 3).map((label) => {
                return <li>{label}</li>;
              })}
            </ol>
            {ctaCount(marketingMessage.labels)}
          </Row>
        )}

        {!isCTA(marketingMessage) && !isOptOut(marketingMessage) && (
          <Row className="m-message ">
            {/* <-------------------- Not For Header -------------------------> */}
            {marketingMessage.attribute.toLowerCase() !==
              getAllChannelAttributes().header.toLowerCase() && (
              <Col
                className={`marketingMsgsellipsis ${
                  marketingMessage.visual ? "col-md-9" : "col-md-12"
                }`}
                style={{ padding: "0px" }}
              >
                {parse(marketingMessage.text || "")}
              </Col>
            )}
          </Row>
        )}
        {/* <--------------------  For Optout -------------------------> */}
        {isOptOut(marketingMessage) && (
          <Row className=" marketing-msg-optout">
            {marketingMessage.visual && (
              <Col className="col-md-3">
                <img
                  src={marketingMessage.visual}
                  alt=""
                  className="img-fluid marketingMsgImgHeight"
                ></img>
              </Col>
            )}
            <Col
              className={`marketingMsgImgHeight marketingMsgsellipsis ${
                marketingMessage.visual ? "col-md-8" : "col-md-12"
              }`}
            >
              {marketingMessage.text && parse(marketingMessage.text || "")}
            </Col>
          </Row>
        )}

        {/* <--------------------  For Header -------------------------> */}
        {!isCTA(marketingMessage) &&
          marketingMessage.attribute.toLowerCase() ===
            getAllChannelAttributes().header.toLowerCase() && (
            <div className="marketingMsgsHeaderMinheight">
              <Row className="m-message">
                <div className="metadata" style={{ width: "100%" }}>
                  Message{" "}
                </div>{" "}
                <div className="marketingMsgsellipsis">
                  {parse(marketingMessage.message || "")}
                </div>
              </Row>
              {marketingMessage.restriction &&
                marketingMessage.restriction.length > 0 && (
                  <Row className="m-message">
                    <div className="metadata" style={{ width: "100%" }}>
                      Restrictions for use{" "}
                    </div>{" "}
                    <div className="marketingMsgsellipsis">
                      {parse(marketingMessage.restriction || "")}
                    </div>
                  </Row>
                )}
              {marketingMessage.rule && marketingMessage.rule.length > 0 && (
                <Row className="m-message">
                  <div className="metadata" style={{ width: "100%" }}>
                    Rules for header{" "}
                  </div>{" "}
                  <div className="marketingMsgsellipsis">
                    {parse(marketingMessage.rule || "")}
                  </div>
                </Row>
              )}
            </div>
          )}
        {/* <-------------------- For disclaimer -------------------------> */}
        {isSubjectline(marketingMessage) && (
          <Row className="m-message dmessageMinheight">
            {marketingMessage.disclaimer?.trim().length > 0 && (
              <>
                <span className="metadata" style={{ width: "100%" }}>
                  Disclaimer
                </span>{" "}
                <div className="marketingMsgsellipsis">
                  {parse(marketingMessage.disclaimer || "")}
                </div>
              </>
            )}
          </Row>
        )}

        <div class="d-flex justify-content-between">
          <span className="cardMetaData">
            ID: <strong>{marketingMessage.id}</strong>
          </span>{" "}
          <span className="cardMetaData">
            {isCTA(marketingMessage) ? "Global Attribute: " : "Attribute: "}{" "}
            <strong>{marketingMessage.attribute}</strong>
          </span>
          <span className="cardMetaData">
            Type: <strong>{marketingMessage.type}</strong>
          </span>
        </div>
        {isMarketingMessageEditable(marketingMessage) && (
          <Row>
            <Col className="col-md-6 footer-column"></Col>
            <Col
              className="col-md-6 footer-column"
              style={{ textAlign: "end" }}
            >
              {getActionButton(marketingMessage, "view")}
              {getActionButton(marketingMessage, "edit")}
              {getActionButton(marketingMessage, "publish")}
            </Col>
          </Row>
        )}
        {!isMarketingMessageEditable(marketingMessage) && (
          <Row>
            <Col className="col-md-6 footer-column"></Col>
            <Col
              className="col-md-6 footer-column"
              style={{ textAlign: "end" }}
            >
              {getActionButton(marketingMessage, "view")}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default MarketingMessageCard;
