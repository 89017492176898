//Import react dependencies

import React from "react";
import { getAllChannelAttributes } from "../../Uitls/Util";

const CreateMarketingMessageToast = ({
  data,
  closeButtonClicked,
  toastType,
}) => {
  const getChannelsCount = (channel) => {
    if (channel) {
      let count =
        channel.headerIds.length +
        channel.optoutIds.length +
        channel.preHeaderIds.length +
        channel.subjectLineIds.length +
        channel.tagLineIds.length;
      return count;
    } else {
      return 0;
    }
  };
  const getTotalCount = () => {
    let channels = data.channels;
    let count = 0;
    if (channels && channels.length > 0) {
      channels.forEach((channel) => {
        count += getChannelsCount(channel);
      });
    }
    count += data.ctaIds?.length || 0;
    return count;
  };
  //   const getCTAIds = () => {
  //     let str = " - ";
  //     let ctaIds = data.ctaIds;
  //     ctaIds.forEach((id, index) => {
  //       str = index === ctaIds.length - 1 ? str + id : str + id + ", ";
  //     });
  //     return str;
  //   };
  const getIdsStr = (ids) => {
    let str = " - ";

    ids.forEach((id, index) => {
      str = index === ids.length - 1 ? str + id : str + id + ", ";
    });
    return str;
  };

  const getChannelsIds = (channel) => {
    const headerIds = channel?.headerIds;
    const preHeaderIds = channel?.preHeaderIds;
    const subjectLineIds = channel?.subjectLineIds;
    const optoutIds = channel?.optoutIds;
    const tagLineIds = channel?.tagLineIds;
    return (
      <>
        {subjectLineIds.length > 0 && (
          <p className="sub-attribute">
            {getAllChannelAttributes().subjectLine}({subjectLineIds.length}){" "}
            {getIdsStr(subjectLineIds)}
          </p>
        )}
        {preHeaderIds.length > 0 && (
          <p className="sub-attribute">
            {getAllChannelAttributes().preheader}({preHeaderIds.length}){" "}
            {getIdsStr(preHeaderIds)}
          </p>
        )}
        {headerIds.length > 0 && (
          <p className="sub-attribute">
            {getAllChannelAttributes().header}({headerIds.length}){" "}
            {getIdsStr(headerIds)}
          </p>
        )}
        {tagLineIds.length > 0 && (
          <p className="sub-attribute">
            {getAllChannelAttributes().tagLine}({tagLineIds.length}){" "}
            {getIdsStr(tagLineIds)}
          </p>
        )}
        {optoutIds.length > 0 && (
          <p className="sub-attribute">
            {getAllChannelAttributes().optOut}({optoutIds.length}){" "}
            {getIdsStr(optoutIds)}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="create-message-toast">
      <p className="title">
        <strong>
          {getTotalCount()}{" "}
          {toastType === "update" ? "Attribute Updated" : "Attribute(s) Added"}
        </strong>
      </p>
      {data?.channels?.map((channel) => {
        return (
          <div>
            <p className="subtitle">
              <strong>
                {" "}
                {channel?.channelType}({getChannelsCount(channel)})
              </strong>
            </p>
            {getChannelsIds(channel)}
          </div>
        );
      })}

      {data.ctaIds?.length > 0 && (
        <p className="subtitle">
          <strong> CTA({data.ctaIds.length})</strong> {getIdsStr(data.ctaIds)}
        </p>
      )}
      <p className="subtitle">
        <strong> Brand : </strong> {data.brand}
      </p>
      {data.campaign && (
        <p className="subtitle">
          <strong> Campaign : </strong> {data.campaign}
        </p>
      )}
      <div className="btn-close-toast">
        <button
          className="btn btn-link pl-0 btn-close-toast pr-0"
          onClick={() => {
            closeButtonClicked();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CreateMarketingMessageToast;
